// All the static text used in the project.
import React from 'react';
import ShoppingBagOutlinedIcon from '@mui/icons-material/ShoppingBagOutlined';
import PlaceOutlinedIcon from '@mui/icons-material/PlaceOutlined';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import HubOutlinedIcon from '@mui/icons-material/HubOutlined';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import FormatListNumberedOutlinedIcon from '@mui/icons-material/FormatListNumberedOutlined';
import DonutSmallOutlinedIcon from '@mui/icons-material/DonutSmallOutlined';
import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined';

const commonTabStyles = {
    textTransform: 'none',
    fontSize: '14px',
    fontWeight: 500,
    color: '#001D5B',
    height: '46px',             // Ensures the height of each Tab
    minHeight: '46px',          // Prevents shrinking
    padding: '12px 12px',
    iconSize: {
        width: '20px',
        height: '20px',
    },
};

const content = {
    home_heading: "Willkommen zurück bei Telusio!",
    future_scope_tooltip: "Demnächst verfügbar",
    global_sidebar: [
        { key: "Meine Produkte", icon: <ShoppingBagOutlinedIcon />, path: '/home', available: true },
        { key: "Meine Standorte", icon: <PlaceOutlinedIcon />, path: '', available: false },
        { key: "Meine Lieferanten", icon: <HubOutlinedIcon />, path: '', available: false },
        { key: "Meine Notizen", icon: <DescriptionOutlinedIcon />, path: '', available: false },
        { key: "Ausloggen", icon: < LogoutOutlinedIcon />, path: '/logout', available: true },
    ],
    global_produkt_tabs: [
        {
            key: "Übersicht", icon: <HomeOutlinedIcon sx={commonTabStyles.iconSize} />, styles: commonTabStyles
        },
        {
            key: "Stückliste", icon: <FormatListNumberedOutlinedIcon sx={commonTabStyles.iconSize} />, styles: commonTabStyles
        },
        {
            key: "Scope Analysen", icon: <DonutSmallOutlinedIcon sx={commonTabStyles.iconSize} />, styles: commonTabStyles
        },
        {
            key: "Weitere Details", icon: < AssignmentOutlinedIcon sx={commonTabStyles.iconSize} />, styles: commonTabStyles
        },
    ],
    tooltip_contents: {
        weitere_details_uncertainity: "Ergebnisse der PCF-Berechnung inklusive Unsicherheitsbewertung",
        weitere_details_data_quality_table: "Informationen zur Datenlage und zum Datenaufbereitsprozess",
        weitere_details_assumption_confirmation: "Liste der bestätigten Annahmen zu Gewichten und Materialien",
        weitere_details_assumption_incomplete: "Liste der unvollständigen Einträge",
        scope_1:"Scope 1 umfasst die Emissionen, die bei der Verbrennung fossiler Brennstoffe und bei der mobilen Verbrennung entstanden sind. Außerdem werden diffuse Emissionen durch Kühlmittel oder andere Gase hier dokumentiert",
        scope_2:"Scope 2 umfasst die Emissionen, die durch die eingekaufte Wärme, Dampf und Kühlung für den Eigenbedarf und eingekauften Strom für den Eigenbedarf entstanden sind",
        scope_3:"Scope 3 umfasst die indirekte Emissionen, die innerhalb der Lieferkette entstanden sind",
        scope_3_1_table: "Auflistung der CO2e-intensivsten Komponenten",
        scope_3_3_table:"Übersicht zu den Emissionen aus zusätzlichen brennstoff- und energierelevante Aktivitäten (Scope 3.3)",
        scope_3_5_table:"Übersicht zu den entstandenen Abfallarten und deren CO2e-Emissionen"
    },
    produkt_übersicht:
    {
        pcf: "Product Carbon Footprint",
        total_weight: "Gewicht",
        number_components: "Einzelteile",
        pcf_per_kg: "PCF je kg",
        table_columns: [
            {
                id: 1,
                label: 'Scope',
                align: 'center',
                minWidth: 82,
                values: [1, 2, 3.1, 3.3, 3.4, 3.5],/*  3.9 */
                colour: ['#374A9A', '#E2E7EF', '#C1DFC3', '#C1DFC3', '#C1DFC3', '#C1DFC3', '#C1DFC3']
            },
            {
                id: 2,
                label: 'CO2e Fußabdruck Dimensionen',
                minWidth: 214,
                align: 'left',
                values: ['Direkte Emissionen', 'Indirekte Emissionen', 'Eingekaufte Waren', 'Zusätzliche Aktivitäten', 'Upstream Logistik', 'Abfall']/* , 'Downstream Logistik' */
                /*     format: (value) => value.toLocaleString('en-US'), */
            },
            /*   {
                  id: 3,
                  label: 'Kg CO2e/ Stück',
                  minWidth: 116,
                  align: 'left',
                  format: (values) => values.toFixed(2),
                  values: []
              },
              {
                  id: 4,
                  label: 'CO2e in %',
                  minWidth: 319,
                  align: 'left',
                  values: [],
                  format: (values) => (values.toFixed(2) + ' %'),
              }, */
        ],
        Datenqualität: "Verwertbare Datensätze",
        Download: "PCF-Bericht",
        gesamt: "Gesamt"
    },
    weitere_details: {
        details_subheading1: "Allgemeines",
        details_subheading2: "Details zur Datenlage",
        allgemeines_table: [{ enkey: "Quantification Date", key: "Berechnungsdatum", },
        { enkey: "Type Of Inventory", key: "Systemgrenzen" },
        { enkey: "Production Sites", key: "Produktionsstandorte" },
        { enkey: "Product Description", key: "Produktbeschreibung" },
        { enkey: "Software Version", key: "Software Version" },
        { enkey: "Biogenic Emissions", key: "Biogene Emissionen" }
        ],
        datenlage_uncetainity_attr_1: [{ key: "Gesamte Unsicherheit" },
        { enkey: "Total PCF - Lower Bound", key: "PCF Ergebnis - Untere Begrenzung" },
        { enkey: "Total PCF - Upper Bound", key: "PCF Ergebnis - Obere Begrenzung" },
        ],

        datenlage_uncetainity_attr_2: [{ key: "Scope" },
        { enkey: "Lower Bound", key: "Untere Begrenzung" },
        { enkey: "Upper Bound", key: "Obere Begrenzung" },
        ],
        datenlage_uncetainity_scope_values: [{ key: "Scope 1" },
        { key: "Scope 2" },
        { key: "Scope 3" },
        ],
        datenlage_datenquality_attr: [{ key: "Anteil in %" },
        { key: "Beschreibung" },
        ],
        datenlage_datenquality_values: [{ key: "Initial verwertbare Stücklisteneinträge" }, { key: "Verwertbare Datensätze (Anteil der nach der TELUSIO Datenaufbereitung verwertbaren Einträge)", style: true }, { key: "Unvollständige Einträge" }],
        data_quality_modal_attr: ['Article Id', 'Beschreibung'], /* dynamic lit of materials. append on runtime  ['Article Id', 'Description'], changing the name sto German */
        unvollständige_einträge_modal_attr: ['Article Id', 'Beschreibung', 'Material',],
        modal_title: ["", "Annahmenbestätigung", "Unvollständige Einträge"],
        data_quality_table_title: "Datengrundlage",
        uncertainity_table_title: "Unsicherheiten",
        allgemeines_table_title: "Allgemeine Informationen"

    },
    scope_analysen: {
        headings: {
            ScopesSubheading1: "Zusammenfassung",
            ScopesSubheading2: "Eingekaufte Waren (Scope 3.1)",
            ScopesSubheading3: "Zusätzliche Aktivitäten (Scope 3.3)",
            ScopesSubheading4: "Upstream Logistik (Scope 3.4)",
            ScopesSubheading5: "Abfälle (Scope 3.5)",
            ScopesSubheading6: "Downstream Logistik (Scope 3.9)",
            Scope_3_1_graph: "CO2e und Gewicht je Material (aggregiert)",
            Scope_3_4_graph: "Transporte vom Lieferanten zum Produktionsstandort",
            Scope_3_pie_chart_title: "Scope 3 Aufteilung",
            Map_scope_3_heading: "Lieferanten und Transportemissionen"
        },
        scope_detail: [
            { key: "Scope 1", value: "Direkte Emissionen", color: '#374a9a' },
            { key: "Scope 2", value: "Indirekte Emissionen", color: '#a3c4ef' },
            { key: "Scope 3", value: "Indirekte Emissionen", color: '#c1dfc3' }
        ],
        scope_3_1: {
            title: "Details zu Scope 3.1 in kg CO2e",
            graph_filter: ["Nach Material", "Nach Warengruppe"],
            graph_bar_names: ["CO2 Emission in kg", "Gewicht"],
            value: [
                { key: "Eingekaufte Güter und Dienstleistungen" },
                { key: "Hilfs- und Betriebsstoffe" },
                { key: "Verpackung" },],
            value_array: ["Eingekaufte Güter und Dienstleistungen", "Hilfs- und Betriebsstoffe", "Verpackung"],

            table_title: "Komponenten mit den höchsten CO2e-Emissionen",
            table_values: [
                { key: 'Rang' },
                { key: 'Komponente' },
                { key: 'kg CO2e' }
            ]
        },

        scope_3_3: {
            table_title: "Vorgelagerte Emissionen",
            table_total_value: {
                text: "Gesamte vorgelagerte Emissionen"
            },
            table_values: [
                {
                    key: 'Kategorie'
                },
                { key: 'kg CO2e' }
            ],
            table_content_values: [
                { key: "Vorgelagerte Emissionen gekaufter Brennstoffe" },
                { key: "Vorgelagerte Emissionen von gekaufter Elektrizität" },
                { key: "Verluste bei der Übertragung und Verteilung (T&D)" },
                { key: "Erzeugung von gekaufter Elektrizität, die an Endverbraucher verkauft wird" }]
        },
        scope_3_4: {
            graph_filter: ["Nach Städten", "Nach Transportmittel", "Nach Ländern", "Interne vs. externe Transporte", "Nach Lieferanten"],
            graph_bar_names: ["CO2 Emission in kg"]
        },
        scope_3_5: {
            table_title: "Abfälle und deren Emissionen nach Abfallkategorie",
            table_total_value: {
                text: "Gesamte Emissionen durch Abfälle"
            },
            table_values: [
                { key: 'Kategorie' },
                { key: 'kg CO2e' }
            ]
        }





    }

};
export default content;