import React, { createContext, useContext, useState } from 'react';

// Define the available configuration modes
export const CONFIG_MODES = {
  DUMMY_ONLY: 'dummy_only',
  API_ONLY: 'api_only',
  MIXED: 'mixed',
};

// Create the context for configuration
const ConfigContext = createContext();

// Create a provider component
export const ConfigProvider = ({ children }) => {
  const [configMode, setConfigMode] = useState(CONFIG_MODES.API_ONLY); // Default mode

  return (
    <ConfigContext.Provider value={{ configMode, setConfigMode, CONFIG_MODES }}>
      {children}
    </ConfigContext.Provider>
  );
};

// Custom hook to use the config context
export const useConfig = () => {
  return useContext(ConfigContext);
};
