import dummy_states from '../mock-states';
import analyticsApiConfig from './analytics-api-config';
import { CONFIG_MODES } from './config-context';

const configMode = CONFIG_MODES.MIXED; /* TODO:To be configured globally using useContext */

/* Parameters in the argment: endpoint, fetchOptions, dummyData, configMode, sleepDurationMs, options */
const fetchPCFOverview = async (productName) => {
    const dummyData = dummy_states.fetchPCFOverview;
    return analyticsApiConfig(`analytics/${productName}/pcf-overview`, {}, dummyData, configMode);
}
const fetchTopNPCFByComponent = async (productName, n) => {
    console.log('hit here pcf fetchTopNPCFByComponent');
    const dummyData = dummy_states.topNPCFByComponentData;
    console.log('hit******', dummyData)
    return analyticsApiConfig(`analytics/${productName}/top-x-pcf-by-component/${n}`, {}, dummyData, configMode);
}

const fetchTopNPCFByMaterial = async (productName, n) => {
    console.log('hit here pcf fetchTopNPCFByComponent');
    const dummyData = dummy_states.topNPCFByComponentData;
    console.log('hit******', dummyData)
    return analyticsApiConfig(`analytics/${productName}/top-x-pcf-by-material/${n}`, {}, dummyData, configMode);
}

const fetchTopNEmissionFactors = async (productName, n) => {
    console.log('hit here pcf fetchTopNPCFByComponent');
    const dummyData = dummy_states.topNPCFByComponentData;
    console.log('hit******', dummyData)
    return analyticsApiConfig(`analytics/${productName}/top-x-emission-factor/${n}`, {}, dummyData, configMode);


}

export default {
    fetchTopNPCFByComponent,
    fetchPCFOverview,
    fetchTopNPCFByMaterial,
    fetchTopNEmissionFactors
}