import { createAsyncThunk, createSlice, createAction } from '@reduxjs/toolkit';
import _ from "lodash";
import { ProductApi } from '../../api/product.api';
import AnalyticsModuleApi from "../../api/analytics-module.api";

export const fetchProductsListing = createAsyncThunk(
    'products/fetchProductsListing',
    async () => {
        return await ProductApi.fetchProducts();
    }
);

export const fetchBomCalculationResult = createAsyncThunk(
    'products/fetchBomCalculationResult',
    async (productName) => {
        return await ProductApi.fetchBoMCalculationResult(productName);
    }
);

export const fetchTop3PCFByComponent = createAsyncThunk(
    'products/fetchTop3PCFByComponent',
    async (productName) => {
        return await AnalyticsModuleApi.fetchTopNPCFByComponent(productName, 3);
    }
);

export const fetchTop5PCFByComponent = createAsyncThunk(
    'products/fetchTop5PCFByComponent',
    async (productName) => {
        return await AnalyticsModuleApi.fetchTopNPCFByComponent(productName, 5);
    }
);

export const fetchTop5PCFByMaterial = createAsyncThunk(
    'products/fetchTop5PCFByMaterial',
    async (productName) => {
        return await AnalyticsModuleApi.fetchTopNPCFByMaterial(productName, 5);
    }
);

export const fetchTop5EmissionFactors = createAsyncThunk(
    'products/fetchTop5EmissionFactors',
    async (productName) => {
        return await AnalyticsModuleApi.fetchTopNEmissionFactors(productName, 5);
    }
);

export const fetchPCFOverview = createAsyncThunk(
    'products/fetchPCFOverview',
    async (productName) => {
        return await AnalyticsModuleApi.fetchPCFOverview(productName);
    }
);

export const selectProduct = createAction('selectedProduct/select');

export const DataStateEnum = {
    UNAVAILABLE: 'UNAVAILABLE',
    FETCHING: 'FETCHING',
    AVAILABLE: 'AVAILABLE'
};

export const productManagerSlice = createSlice({
    name: 'productManager',
    initialState: {
        selectedProduct: null,
        products: {
            dataState: DataStateEnum.UNAVAILABLE,
            data: null
        }, // list of product that is shown in the /products
        top3PCFByComponent: {
            dataState: DataStateEnum.UNAVAILABLE,
            data: null
        },
        top5PCFByComponent: {
            dataState: DataStateEnum.UNAVAILABLE,
            data: null
        },
        top5PCFByMaterial: {
            dataState: DataStateEnum.UNAVAILABLE,
            data: null
        },
        top5EmissionFactors: {
            dataState: DataStateEnum.UNAVAILABLE,
            data: null
        },
        pcfOverview: {
            dataState: DataStateEnum.UNAVAILABLE,
            data: null
        },
        treeViewModule: {
            dataState: DataStateEnum.UNAVAILABLE,
            billOfMaterials: null
        }
    },
    extraReducers: builder => {
        builder
            .addCase(selectProduct, (state, action) => {
                state.selectedProduct = action.payload;
                state.top3PCFByComponent.dataState = DataStateEnum.UNAVAILABLE;
                state.top5PCFByComponent.dataState = DataStateEnum.UNAVAILABLE;
                state.top5PCFByMaterial.dataState = DataStateEnum.UNAVAILABLE;
                state.top5EmissionFactors.dataState = DataStateEnum.UNAVAILABLE;
                state.pcfOverview.dataState = DataStateEnum.UNAVAILABLE;
                state.treeViewModule.dataState = DataStateEnum.UNAVAILABLE;
                state.top3PCFByComponent.data = null;
                state.top5PCFByComponent.data = null;
                state.top5PCFByMaterial.data = null;
                state.top5EmissionFactors.data = null;
                state.pcfOverview.data = null;
                state.treeViewModule.billOfMaterials = null;
            })
            .addCase(fetchProductsListing.pending, (state, action) => {
                state.products.dataState = DataStateEnum.FETCHING;
            })
            .addCase(fetchProductsListing.fulfilled, (state, action) => {
                state.products.data = _.map(action.payload.products, (product) => {


                    return {
                        title: product.name,
                        ...product
                    };
                });
                state.products.dataState = DataStateEnum.AVAILABLE;
            })
            .addCase(fetchProductsListing.rejected, (state, action) => {
                // TODO(properly handle error. Idea: store error message in the state itself (?))
                state.products.dataState = DataStateEnum.UNAVAILABLE;
            })
            .addCase(fetchBomCalculationResult.pending, (state, action) => {
                state.treeViewModule.dataState = DataStateEnum.FETCHING;
            })
            .addCase(fetchBomCalculationResult.fulfilled, (state, action) => {
                state.treeViewModule.data = action.payload.bomTree;
                state.treeViewModule.dataState = DataStateEnum.AVAILABLE;
            })
            .addCase(fetchBomCalculationResult.rejected, (state, action) => {
                // action.error.message
                // TODO(properly handle error. Idea: store error message in the state itself (?))
                state.treeViewModule.dataState = DataStateEnum.UNAVAILABLE;
            })
            .addCase(fetchTop3PCFByComponent.pending, (state, action) => {
                state.top3PCFByComponent.dataState = DataStateEnum.FETCHING;
            })
            .addCase(fetchTop3PCFByComponent.fulfilled, (state, action) => {
                state.top3PCFByComponent.data = action.payload.result;
                state.top3PCFByComponent.dataState = DataStateEnum.AVAILABLE;
            })
            .addCase(fetchTop3PCFByComponent.rejected, (state, action) => {
                // action.error.message
                // TODO(properly handle error. Idea: store error message in the state itself (?))
                state.top3PCFByComponent.dataState = DataStateEnum.UNAVAILABLE;
            })
            .addCase(fetchTop5PCFByComponent.pending, (state, action) => {
                state.top5PCFByComponent.dataState = DataStateEnum.FETCHING;
            })
            .addCase(fetchTop5PCFByComponent.fulfilled, (state, action) => {
                state.top5PCFByComponent.data = action.payload.result;
                state.top5PCFByComponent.dataState = DataStateEnum.AVAILABLE;
            })
            .addCase(fetchTop5PCFByComponent.rejected, (state, action) => {
                // action.error.message
                // TODO(properly handle error. Idea: store error message in the state itself (?))
                state.top5PCFByComponent.dataState = DataStateEnum.UNAVAILABLE;
            })
            .addCase(fetchTop5PCFByMaterial.pending, (state, action) => {
                state.top5PCFByMaterial.dataState = DataStateEnum.FETCHING;
            })
            .addCase(fetchTop5PCFByMaterial.fulfilled, (state, action) => {
                state.top5PCFByMaterial.data = action.payload.result;
                state.top5PCFByMaterial.dataState = DataStateEnum.AVAILABLE;
            })
            .addCase(fetchTop5PCFByMaterial.rejected, (state, action) => {
                // action.error.message
                // TODO(properly handle error. Idea: store error message in the state itself (?))
                state.top5PCFByMaterial.dataState = DataStateEnum.UNAVAILABLE;
            })
            .addCase(fetchTop5EmissionFactors.pending, (state, action) => {
                state.top5EmissionFactors.dataState = DataStateEnum.FETCHING;
            })
            .addCase(fetchTop5EmissionFactors.fulfilled, (state, action) => {
                state.top5EmissionFactors.data = action.payload.result;
                state.top5EmissionFactors.dataState = DataStateEnum.AVAILABLE;
            })
            .addCase(fetchTop5EmissionFactors.rejected, (state, action) => {
                // action.error.message
                // TODO(properly handle error. Idea: store error message in the state itself (?))
                state.top5EmissionFactors.dataState = DataStateEnum.UNAVAILABLE;
            })
            .addCase(fetchPCFOverview.pending, (state, action) => {
                state.pcfOverview.dataState = DataStateEnum.FETCHING;
            })
            .addCase(fetchPCFOverview.fulfilled, (state, action) => {
                state.pcfOverview.data = action.payload.result;
                state.pcfOverview.dataState = DataStateEnum.AVAILABLE;
            })
            .addCase(fetchPCFOverview.rejected, (state, action) => {
                // action.error.message
                // TODO(properly handle error. Idea: store error message in the state itself (?))
                state.pcfOverview.dataState = DataStateEnum.UNAVAILABLE;
            })
    }
});

export const { setSelectedProduct } = productManagerSlice.actions;

export default productManagerSlice.reducer;

export const selectSelectedProduct = state => state.productManager.selectedProduct;
export const selectProducts = state => state.productManager.products.data;
export const selectProductsAvailable = state => state.productManager.products.dataState;
export const selectTreeViewAvailable = state => state.productManager.treeViewModule.dataState;
export const selectTreeViewData = state => state.productManager.treeViewModule.data;
export const selectTop3PCFByComponentAvailable = state => state.productManager.top3PCFByComponent.dataState;
export const selectTop3PCFByComponentData = state => state.productManager.top3PCFByComponent.data;
export const selectTop5PCFByComponentAvailable = state => state.productManager.top5PCFByComponent.dataState;
export const selectTop5PCFByComponentData = state => state.productManager.top5PCFByComponent.data;
export const selectTop5PCFByMaterialAvailable = state => state.productManager.top5PCFByMaterial.dataState;
export const selectTop5PCFByMaterialData = state => state.productManager.top5PCFByMaterial.data;
export const selectTop5EmissionFactorsAvailable = state => state.productManager.top5EmissionFactors.dataState;
export const selectTop5EmissionFactorsData = state => state.productManager.top5EmissionFactors.data;
export const selectPCFOverviewAvailable = state => state.productManager.pcfOverview.dataState;
export const selectPCFOverviewData = state => state.productManager.pcfOverview.data;