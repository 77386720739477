
import apiConfig from './api-config';
import { CONFIG_MODES, useConfig } from './config-context';
import dummy_states from '../mock-states';

const configMode = CONFIG_MODES.MIXED;
const type = 'Product';
export const ProductApi = {
    // Get the config mode (api_only, dummy_only, mixed)

    fetchProducts: async () => {
        console.log('hit here')
        const dummyData = dummy_states.fetchProductsListing;  // Import your dummy data
        return apiConfig('product', {}, type, dummyData, configMode);  // Pass endpoint, dummy data, and configMode
    },

    fetchBoMCalculationResult: async (productName) => {
        const dummyData = dummy_states.selectTreeViewData;  // Dummy data for BoM Calculation
        return apiConfig(`product/${productName}/bom`, {}, type, dummyData, configMode);
    }

};

/* 
example for POST request in case needed in future

export const createProduct = (productData) => {
        return apiConfig('/products', {
          method: 'POST',
          body: productData,
        });
      };
 */



