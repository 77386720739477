import battery from "./resources/product-img/telusio/Batterypack.png"
import etti from "./resources/product-img/telusio/Ettiketiermaschine.png"
import luft from "./resources/product-img/telusio/Luftpumpe.png"



const dummy_states = {
    fetchProductsListing: {
        products: [
            {
                id: 1,
                name: 'Produkt1',
                image_object: battery,
                total_pcf: 277,
                category: 'Reinraumtechnik'
            },
            {
                id: 2,
                name: 'Produkt 2',
                image_object: etti,
                total_pcf: 72,
                category: 'Druckmaschine'
            },
            {
                id: 3,
                name: 'Produkt 3',
                image_object: luft,
                total_pcf: 27,
                category: 'Reinraumtechnik'
            },
            {
                id: 4,
                name: 'Produkt 1',
                image_object: etti,
                total_pcf: 277,
                category: 'Pumpe'
            },
            {
                id: 5,
                name: 'Produkt 5',
                image_object: battery,
                total_pcf: 340,
                category: 'Pumpe'
            },
        ],
    },

    fetchPCFOverview: {
        result:
        {

            image_object: etti,
            total_weight: 2.594599,
            total_pcf: 27.87104169995507,
            number_components: 34,
            pcf_per_kg: 10.74194574959563,

            type_of_inventory: 'Cradle-to-gate',
            /*       type_of_inventory: 'Cradle-to-customer', */
            scope_1: 3.1,
            scope_2: 1.2006000000000001,
            scope_3_1: 21.30044169995507,
            scope_3_3: 1.3,
            scope_3_4: 5.00,
            scope_3_5: 0.97,
            scope_3_9: 1.97,
            /* Allgemeines */
            pcf_quantification_date: "2024-07-12T00:00:00.000Z",
            pcf_production_sites: ['München, Landshut'],
            pcf_product_description: "Das Produkt besteht u.A. aus einem Metallteil und einer Pumpe.",
            software_version: '1.0.0',
            pcf_waste_biogenic_emissions: 0.15,
            /* Datenlage  table 2*/

            pcf_data_quality_final_usable: 98,

            /* Datenlage  table 1*/
            pcf_total_uncertainty: 1.05,
            pcf_total_uncertainty_LB: 160.01,
            pcf_total_uncertainty_HB: 176.86,
            pcf_uncertainty_scope1_LB: 21.70,
            pcf_uncertainty_scope1_UB: 23.98,
            pcf_uncertainty_scope2_LB: 7.2,
            pcf_uncertainty_scope2_UB: 7.8,
            pcf_uncertainty_scope3_LB: 135.11,
            pcf_uncertainty_scope3_UB: 140.63,

            /* Scope analysen */
            total_mcf: 20.1,
            pcf_auxiliary_and_operating_material: 0.2,
            pcf_packaging: 1,


            /* PDF EXPORT */
            name: 'Maschine 123',
            company: {
                company_name: 'Maschinenfabrik AG',
                company_logo: '',
                street: 'Straße 123',
                city: '80805 München',
                website: 'www.maschinenfabrik.de'
            }

        }
    },

    selectTreeViewData: {
        bomTree:
            [
                {
                    "rowNumber": 0,
                    "parentRowNumber": null,
                    "articleId": null,
                    "description": "Ettiketiermaschine",
                    "hierarchy": 1,
                    "quantity": 1,
                    "weight": 1693.6630999999998,
                    "childrenWeight": 1693.6630999999998,
                    "totalQuantityWeight": 1693.6630999999998,
                    "singleQuantityMcf": 2826.530152948005,
                    "mcf": 2826.530152948005,
                    "singleQuantityLcf": 0,
                    "lcf": 0,
                    "totalQuantityMcf": 2826.530152948005,
                    "expandable": false,
                    "children": [
                        {
                            "rowNumber": 1,
                            "parentRowNumber": 0,
                            "articleId": "33847",
                            "description": "33847-00-00-Allgemein",
                            "hierarchy": 2,
                            "quantity": 1,
                            "weight": 1124.5876000000003,
                            "childrenWeight": 1124.5876000000003,
                            "totalQuantityWeight": 1124.5876000000003,
                            "singleQuantityMcf": 1269.5495944579995,
                            "mcf": 1269.5495944579995,
                            "singleQuantityLcf": 0,
                            "lcf": 0,
                            "totalQuantityMcf": 1269.5495944579995,
                            "expandable": false,
                            "children": [
                                {
                                    "rowNumber": 2,
                                    "parentRowNumber": 1,
                                    "articleId": "33847",
                                    "description": "33847-00AA-00-Grundaufbau",
                                    "hierarchy": 3,
                                    "quantity": 1,
                                    "weight": 639.0523000000001,
                                    "childrenWeight": 639.0523000000001,
                                    "totalQuantityWeight": 639.0523000000001,
                                    "singleQuantityMcf": 237.542336,
                                    "mcf": 237.542336,
                                    "singleQuantityLcf": 0,
                                    "lcf": 0,
                                    "totalQuantityMcf": 237.542336,
                                    "expandable": false,
                                    "children": [
                                        {
                                            "rowNumber": 3,
                                            "parentRowNumber": 2,
                                            "articleId": "33847",
                                            "description": "33847-00AA00-01-Grundgestell",
                                            "hierarchy": 4,
                                            "quantity": 1,
                                            "weight": 222.9439,
                                            "childrenWeight": 222.9439,
                                            "totalQuantityWeight": 222.9439,
                                            "singleQuantityMcf": 13.724928000000002,
                                            "mcf": 13.724928000000002,
                                            "singleQuantityLcf": 0,
                                            "lcf": 0,
                                            "totalQuantityMcf": 13.724928000000002,
                                            "expandable": false,
                                            "children": [
                                                {
                                                    "rowNumber": 4,
                                                    "parentRowNumber": 3,
                                                    "articleId": "MM0000003611",
                                                    "description": "MM0000003611-01-Grundgestell",
                                                    "hierarchy": 5,
                                                    "quantity": 1,
                                                    "weight": 222.9439,
                                                    "childrenWeight": 222.9439,
                                                    "totalQuantityWeight": 222.9439,
                                                    "singleQuantityMcf": 13.724928000000002,
                                                    "mcf": 13.724928000000002,
                                                    "singleQuantityLcf": 0,
                                                    "lcf": 0,
                                                    "totalQuantityMcf": 13.724928000000002,
                                                    "expandable": false,
                                                    "children": [
                                                        {
                                                            "rowNumber": 5,
                                                            "parentRowNumber": 4,
                                                            "articleId": "47072760",
                                                            "description": "47072760-01-Grundaufbau",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 222.9439,
                                                            "childrenWeight": 222.9439,
                                                            "totalQuantityWeight": 222.9439,
                                                            "singleQuantityMcf": 13.724928000000002,
                                                            "mcf": 13.724928000000002,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 13.724928000000002,
                                                            "expandable": false,
                                                            "children": [
                                                                {
                                                                    "rowNumber": 6,
                                                                    "parentRowNumber": 5,
                                                                    "articleId": "20120420",
                                                                    "description": "20120420-00-Sechskantschraube x 4",
                                                                    "hierarchy": 7,
                                                                    "quantity": 4,
                                                                    "weight": 0.0116,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0116,
                                                                    "singleQuantityMcf": 0.015022,
                                                                    "mcf": 0.060088,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.060088,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 7,
                                                                    "parentRowNumber": 5,
                                                                    "articleId": "20120520",
                                                                    "description": "20120520-00-Sechskantschraube x 4",
                                                                    "hierarchy": 7,
                                                                    "quantity": 4,
                                                                    "weight": 0.018,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.018,
                                                                    "singleQuantityMcf": 0.02331,
                                                                    "mcf": 0.09324,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.09324,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 8,
                                                                    "parentRowNumber": 5,
                                                                    "articleId": "20120612",
                                                                    "description": "20120612-00-Sechskantschraube x 4",
                                                                    "hierarchy": 7,
                                                                    "quantity": 4,
                                                                    "weight": 0.0212,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0212,
                                                                    "singleQuantityMcf": 0.027454,
                                                                    "mcf": 0.109816,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.109816,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 9,
                                                                    "parentRowNumber": 5,
                                                                    "articleId": "20120620",
                                                                    "description": "20120620-00-Sechskantschraube x 2",
                                                                    "hierarchy": 7,
                                                                    "quantity": 2,
                                                                    "weight": 0.014,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.014,
                                                                    "singleQuantityMcf": 0.03626,
                                                                    "mcf": 0.07252,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.07252,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 10,
                                                                    "parentRowNumber": 5,
                                                                    "articleId": "20120630",
                                                                    "description": "20120630-00-Sechskantschraube x 10",
                                                                    "hierarchy": 7,
                                                                    "quantity": 10,
                                                                    "weight": 0.094,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.094,
                                                                    "singleQuantityMcf": 0.048692,
                                                                    "mcf": 0.48692,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.48692,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 11,
                                                                    "parentRowNumber": 5,
                                                                    "articleId": "20120835",
                                                                    "description": "20120835-00-Sechskantschraube x 33",
                                                                    "hierarchy": 7,
                                                                    "quantity": 33,
                                                                    "weight": 0.6633,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.6633,
                                                                    "singleQuantityMcf": 0.104118,
                                                                    "mcf": 3.435894,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 3.435894,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 12,
                                                                    "parentRowNumber": 5,
                                                                    "articleId": "20120840",
                                                                    "description": "20120840-00-Sechskantschraube x 16",
                                                                    "hierarchy": 7,
                                                                    "quantity": 16,
                                                                    "weight": 0.3536,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.3536,
                                                                    "singleQuantityMcf": 0.114478,
                                                                    "mcf": 1.831648,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 1.831648,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 13,
                                                                    "parentRowNumber": 5,
                                                                    "articleId": "20120870",
                                                                    "description": "20120870-00-Sechskantschraube x 9",
                                                                    "hierarchy": 7,
                                                                    "quantity": 9,
                                                                    "weight": 0.3078,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.3078,
                                                                    "singleQuantityMcf": 0.177156,
                                                                    "mcf": 1.5944040000000002,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 1.5944040000000002,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 14,
                                                                    "parentRowNumber": 5,
                                                                    "articleId": "20220520",
                                                                    "description": "20220520-00-Zylinderschraube x 6",
                                                                    "hierarchy": 7,
                                                                    "quantity": 6,
                                                                    "weight": 0.0264,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0264,
                                                                    "singleQuantityMcf": 0.022792,
                                                                    "mcf": 0.13675199999999998,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.13675199999999998,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 15,
                                                                    "parentRowNumber": 5,
                                                                    "articleId": "20220830",
                                                                    "description": "20220830-00-Zylinderschraube x 9",
                                                                    "hierarchy": 7,
                                                                    "quantity": 9,
                                                                    "weight": 0.14400000000000002,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.14400000000000002,
                                                                    "singleQuantityMcf": 0.08288,
                                                                    "mcf": 0.7459199999999999,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.7459199999999999,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 16,
                                                                    "parentRowNumber": 5,
                                                                    "articleId": "20420410",
                                                                    "description": "20420410-00-Senkschraube x 2",
                                                                    "hierarchy": 7,
                                                                    "quantity": 2,
                                                                    "weight": 0.0022,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0022,
                                                                    "singleQuantityMcf": 0.005698,
                                                                    "mcf": 0.011396,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.011396,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 17,
                                                                    "parentRowNumber": 5,
                                                                    "articleId": "208204",
                                                                    "description": "208204-00-Scheibe x 4",
                                                                    "hierarchy": 7,
                                                                    "quantity": 4,
                                                                    "weight": 0.0012,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0012,
                                                                    "singleQuantityMcf": 0.0015539999999999998,
                                                                    "mcf": 0.006215999999999999,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.006215999999999999,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 18,
                                                                    "parentRowNumber": 5,
                                                                    "articleId": "208205",
                                                                    "description": "208205-00-Scheibe x 4",
                                                                    "hierarchy": 7,
                                                                    "quantity": 4,
                                                                    "weight": 0.0016,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0016,
                                                                    "singleQuantityMcf": 0.002072,
                                                                    "mcf": 0.008288,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.008288,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 19,
                                                                    "parentRowNumber": 5,
                                                                    "articleId": "208206",
                                                                    "description": "208206-00-Scheibe x 6",
                                                                    "hierarchy": 7,
                                                                    "quantity": 6,
                                                                    "weight": 0.0054,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0054,
                                                                    "singleQuantityMcf": 0.004662,
                                                                    "mcf": 0.027972,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.027972,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 20,
                                                                    "parentRowNumber": 5,
                                                                    "articleId": "208208",
                                                                    "description": "208208-00-Scheibe x 58",
                                                                    "hierarchy": 7,
                                                                    "quantity": 58,
                                                                    "weight": 0.0986,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0986,
                                                                    "singleQuantityMcf": 0.008806,
                                                                    "mcf": 0.510748,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.510748,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 21,
                                                                    "parentRowNumber": 5,
                                                                    "articleId": "21220620",
                                                                    "description": "21220620-00-Zylinderstift x 6",
                                                                    "hierarchy": 7,
                                                                    "quantity": 6,
                                                                    "weight": 0.028200000000000003,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.028200000000000003,
                                                                    "singleQuantityMcf": 0.024346,
                                                                    "mcf": 0.14607599999999998,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.14607599999999998,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 22,
                                                                    "parentRowNumber": 5,
                                                                    "articleId": "442018",
                                                                    "description": "442018-00-Scheibe x 10",
                                                                    "hierarchy": 7,
                                                                    "quantity": 10,
                                                                    "weight": 0.043,
                                                                    "childrenWeight": 0.0043,
                                                                    "totalQuantityWeight": 0.043,
                                                                    "singleQuantityMcf": 0.022274,
                                                                    "mcf": 0.22274,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.22274,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 23,
                                                                            "parentRowNumber": 22,
                                                                            "articleId": "1260020",
                                                                            "description": "1260020-00-Rundstahl",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0043,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.043,
                                                                            "singleQuantityMcf": 0.022274,
                                                                            "mcf": 0.022274,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.22274,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 24,
                                                                    "parentRowNumber": 5,
                                                                    "articleId": "450820",
                                                                    "description": "450820-01-Anschlagwinkel x 2",
                                                                    "hierarchy": 7,
                                                                    "quantity": 2,
                                                                    "weight": 0.0576,
                                                                    "childrenWeight": 0.0288,
                                                                    "totalQuantityWeight": 0.0576,
                                                                    "singleQuantityMcf": 0.14918399999999998,
                                                                    "mcf": 0.298368,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.298368,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 25,
                                                                            "parentRowNumber": 24,
                                                                            "articleId": "142604",
                                                                            "description": "142604-00-Edelstahlblech",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0288,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0576,
                                                                            "singleQuantityMcf": 0.14918399999999998,
                                                                            "mcf": 0.14918399999999998,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.298368,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 26,
                                                                    "parentRowNumber": 5,
                                                                    "articleId": "46795884",
                                                                    "description": "46795884-00-Schlauchhalter",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.1003,
                                                                    "childrenWeight": 0.1003,
                                                                    "totalQuantityWeight": 0.1003,
                                                                    "singleQuantityMcf": 0.519554,
                                                                    "mcf": 0.519554,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.519554,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 27,
                                                                            "parentRowNumber": 26,
                                                                            "articleId": "142607",
                                                                            "description": "142607-00-Edelstahlblech",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.1003,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.1003,
                                                                            "singleQuantityMcf": 0.519554,
                                                                            "mcf": 0.519554,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.519554,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 28,
                                                                    "parentRowNumber": 5,
                                                                    "articleId": "46795904",
                                                                    "description": "46795904-00-Schlauchhalter",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0595,
                                                                    "childrenWeight": 0.0595,
                                                                    "totalQuantityWeight": 0.0595,
                                                                    "singleQuantityMcf": 0.30821,
                                                                    "mcf": 0.30821,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.30821,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 29,
                                                                            "parentRowNumber": 28,
                                                                            "articleId": "142607",
                                                                            "description": "142607-00-Edelstahlblech",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0595,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0595,
                                                                            "singleQuantityMcf": 0.30821,
                                                                            "mcf": 0.30821,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.30821,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 30,
                                                                    "parentRowNumber": 5,
                                                                    "articleId": "46795916",
                                                                    "description": "46795916-00-Schlauchhalter",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0429,
                                                                    "childrenWeight": 0.0429,
                                                                    "totalQuantityWeight": 0.0429,
                                                                    "singleQuantityMcf": 0.222222,
                                                                    "mcf": 0.222222,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.222222,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 31,
                                                                            "parentRowNumber": 30,
                                                                            "articleId": "142607",
                                                                            "description": "142607-00-Edelstahlblech",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0429,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0429,
                                                                            "singleQuantityMcf": 0.222222,
                                                                            "mcf": 0.222222,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.222222,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 32,
                                                                    "parentRowNumber": 5,
                                                                    "articleId": "47072824",
                                                                    "description": "47072824-00-Platte",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 16.6934,
                                                                    "childrenWeight": 16.6934,
                                                                    "totalQuantityWeight": 16.6934,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 33,
                                                                            "parentRowNumber": 32,
                                                                            "articleId": "141719",
                                                                            "description": "141719-00-Platte",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 16.6934,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 16.6934,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 34,
                                                                    "parentRowNumber": 5,
                                                                    "articleId": "47073170",
                                                                    "description": "47073170-03-Grundplatte",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 92.4469,
                                                                    "childrenWeight": 92.4469,
                                                                    "totalQuantityWeight": 92.4469,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 35,
                                                                            "parentRowNumber": 34,
                                                                            "articleId": "141719",
                                                                            "description": "141719-00-Platte",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 92.4469,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 92.4469,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 36,
                                                                    "parentRowNumber": 5,
                                                                    "articleId": "47073214",
                                                                    "description": "47073214-01-Platte",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 1.8548,
                                                                    "childrenWeight": 1.8548,
                                                                    "totalQuantityWeight": 1.8548,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 37,
                                                                            "parentRowNumber": 36,
                                                                            "articleId": "141719",
                                                                            "description": "141719-00-Platte",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 1.8548,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 1.8548,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 38,
                                                                    "parentRowNumber": 5,
                                                                    "articleId": "47073223",
                                                                    "description": "47073223-05-Platte",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 26.9959,
                                                                    "childrenWeight": 26.9959,
                                                                    "totalQuantityWeight": 26.9959,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 39,
                                                                            "parentRowNumber": 38,
                                                                            "articleId": "141719",
                                                                            "description": "141719-00-Platte",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 26.9959,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 26.9959,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 40,
                                                                    "parentRowNumber": 5,
                                                                    "articleId": "47073230",
                                                                    "description": "47073230-02-Platte",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 45.2026,
                                                                    "childrenWeight": 45.2026,
                                                                    "totalQuantityWeight": 45.2026,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 41,
                                                                            "parentRowNumber": 40,
                                                                            "articleId": "141719",
                                                                            "description": "141719-00-Platte",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 45.2026,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 45.2026,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 42,
                                                                    "parentRowNumber": 5,
                                                                    "articleId": "47073493",
                                                                    "description": "47073493-02-Platte",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 24.0187,
                                                                    "childrenWeight": 24.0187,
                                                                    "totalQuantityWeight": 24.0187,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 43,
                                                                            "parentRowNumber": 42,
                                                                            "articleId": "141719",
                                                                            "description": "141719-00-Platte",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 24.0187,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 24.0187,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 44,
                                                                    "parentRowNumber": 5,
                                                                    "articleId": "47098028",
                                                                    "description": "47098028-00-Platte",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 9.0501,
                                                                    "childrenWeight": 9.0501,
                                                                    "totalQuantityWeight": 9.0501,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 45,
                                                                            "parentRowNumber": 44,
                                                                            "articleId": "141719",
                                                                            "description": "141719-00-Platte",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 9.0501,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 9.0501,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 46,
                                                                    "parentRowNumber": 5,
                                                                    "articleId": "47098137",
                                                                    "description": "47098137-00-Platte",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 2.633,
                                                                    "childrenWeight": 2.633,
                                                                    "totalQuantityWeight": 2.633,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 47,
                                                                            "parentRowNumber": 46,
                                                                            "articleId": "141718",
                                                                            "description": "141718-00-Platte",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 2.633,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 2.633,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 48,
                                                                    "parentRowNumber": 5,
                                                                    "articleId": "47117441",
                                                                    "description": "47117441-00-Blech",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.5552,
                                                                    "childrenWeight": 0.5552,
                                                                    "totalQuantityWeight": 0.5552,
                                                                    "singleQuantityMcf": 2.875936,
                                                                    "mcf": 2.875936,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 2.875936,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 49,
                                                                            "parentRowNumber": 48,
                                                                            "articleId": "142603",
                                                                            "description": "142603-00-Edelstahlblech",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.5552,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.5552,
                                                                            "singleQuantityMcf": 2.875936,
                                                                            "mcf": 2.875936,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 2.875936,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 50,
                                                                    "parentRowNumber": 5,
                                                                    "articleId": "56617001",
                                                                    "description": "56617001-04-Schlauchverschraub.",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.49,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.49,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 51,
                                                                    "parentRowNumber": 5,
                                                                    "articleId": "56651115",
                                                                    "description": "56651115-03-Schlauchverschraub.",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0491,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0491,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 52,
                                                                    "parentRowNumber": 5,
                                                                    "articleId": "56651135",
                                                                    "description": "56651135-06-Schlauchverschraub.",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.2549,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.2549,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 53,
                                                                    "parentRowNumber": 5,
                                                                    "articleId": "57555031",
                                                                    "description": "57555031-02-Gegenmutter",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0146,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0146,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 54,
                                                                    "parentRowNumber": 5,
                                                                    "articleId": "57555061",
                                                                    "description": "57555061-02-Gegenmutter",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0523,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0523,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 55,
                                                                    "parentRowNumber": 5,
                                                                    "articleId": "57555073",
                                                                    "description": "57555073-02-Gegenmutter",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.1156,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.1156,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 56,
                                                                    "parentRowNumber": 5,
                                                                    "articleId": "57585176",
                                                                    "description": "57585176-02-Zugentlastungsleiste x 3",
                                                                    "hierarchy": 7,
                                                                    "quantity": 3,
                                                                    "weight": 0.4224,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.4224,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": []
                                                                }
                                                            ]
                                                        }
                                                    ]
                                                }
                                            ]
                                        },
                                        {
                                            "rowNumber": 57,
                                            "parentRowNumber": 2,
                                            "articleId": "33847",
                                            "description": "33847-00AA01-03-Tischplatte",
                                            "hierarchy": 4,
                                            "quantity": 1,
                                            "weight": 195.3048,
                                            "childrenWeight": 195.3048,
                                            "totalQuantityWeight": 195.3048,
                                            "singleQuantityMcf": 196.758146,
                                            "mcf": 196.758146,
                                            "singleQuantityLcf": 0,
                                            "lcf": 0,
                                            "totalQuantityMcf": 196.758146,
                                            "expandable": false,
                                            "children": [
                                                {
                                                    "rowNumber": 58,
                                                    "parentRowNumber": 57,
                                                    "articleId": "MM0000003704",
                                                    "description": "MM0000003704-04-Tischplatte",
                                                    "hierarchy": 5,
                                                    "quantity": 1,
                                                    "weight": 195.3048,
                                                    "childrenWeight": 195.3048,
                                                    "totalQuantityWeight": 195.3048,
                                                    "singleQuantityMcf": 196.758146,
                                                    "mcf": 196.758146,
                                                    "singleQuantityLcf": 0,
                                                    "lcf": 0,
                                                    "totalQuantityMcf": 196.758146,
                                                    "expandable": false,
                                                    "children": [
                                                        {
                                                            "rowNumber": 59,
                                                            "parentRowNumber": 58,
                                                            "articleId": "47074945",
                                                            "description": "47074945-03-Tischplatte",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 155.998,
                                                            "childrenWeight": 155.998,
                                                            "totalQuantityWeight": 155.998,
                                                            "singleQuantityMcf": 0,
                                                            "mcf": 0,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0,
                                                            "expandable": false,
                                                            "children": [
                                                                {
                                                                    "rowNumber": 60,
                                                                    "parentRowNumber": 59,
                                                                    "articleId": "141719",
                                                                    "description": "141719-00-Platte",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 155.998,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 155.998,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": []
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            "rowNumber": 61,
                                                            "parentRowNumber": 58,
                                                            "articleId": "47074946",
                                                            "description": "47074946-02-Tischblech",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 37.6107,
                                                            "childrenWeight": 37.6107,
                                                            "totalQuantityWeight": 37.6107,
                                                            "singleQuantityMcf": 194.823426,
                                                            "mcf": 194.823426,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 194.823426,
                                                            "expandable": false,
                                                            "children": [
                                                                {
                                                                    "rowNumber": 62,
                                                                    "parentRowNumber": 61,
                                                                    "articleId": "142603",
                                                                    "description": "142603-00-Edelstahlblech",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 37.6107,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 37.6107,
                                                                    "singleQuantityMcf": 194.823426,
                                                                    "mcf": 194.823426,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 194.823426,
                                                                    "expandable": false,
                                                                    "children": []
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            "rowNumber": 63,
                                                            "parentRowNumber": 58,
                                                            "articleId": "47124099",
                                                            "description": "47124099-00-Platte",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.3795,
                                                            "childrenWeight": 0.3795,
                                                            "totalQuantityWeight": 0.3795,
                                                            "singleQuantityMcf": 1.18304,
                                                            "mcf": 1.18304,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 1.18304,
                                                            "expandable": false,
                                                            "children": [
                                                                {
                                                                    "rowNumber": 64,
                                                                    "parentRowNumber": 63,
                                                                    "articleId": "162538",
                                                                    "description": "162538-01-Platte",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.3697,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.3697,
                                                                    "singleQuantityMcf": 1.18304,
                                                                    "mcf": 1.18304,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 1.18304,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 65,
                                                                    "parentRowNumber": 63,
                                                                    "articleId": "347204",
                                                                    "description": "347204-00-Ensat-Gewindebuchse",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0049,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0049,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 66,
                                                                    "parentRowNumber": 63,
                                                                    "articleId": "347204",
                                                                    "description": "347204-00-Ensat-Gewindebuchse",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0049,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0049,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": []
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            "rowNumber": 67,
                                                            "parentRowNumber": 58,
                                                            "articleId": "47183496",
                                                            "description": "47183496-00-Platte",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.2447,
                                                            "childrenWeight": 0.2447,
                                                            "totalQuantityWeight": 0.2447,
                                                            "singleQuantityMcf": 0.75168,
                                                            "mcf": 0.75168,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.75168,
                                                            "expandable": false,
                                                            "children": [
                                                                {
                                                                    "rowNumber": 68,
                                                                    "parentRowNumber": 67,
                                                                    "articleId": "162538",
                                                                    "description": "162538-01-Platte",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.2349,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.2349,
                                                                    "singleQuantityMcf": 0.75168,
                                                                    "mcf": 0.75168,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.75168,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 69,
                                                                    "parentRowNumber": 67,
                                                                    "articleId": "347204",
                                                                    "description": "347204-00-Ensat-Gewindebuchse",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0049,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0049,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 70,
                                                                    "parentRowNumber": 67,
                                                                    "articleId": "347204",
                                                                    "description": "347204-00-Ensat-Gewindebuchse",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0049,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0049,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": []
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            "rowNumber": 71,
                                                            "parentRowNumber": 58,
                                                            "articleId": "57550271",
                                                            "description": "57550271-01-Verschraubungskörper",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.3573,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.3573,
                                                            "singleQuantityMcf": 0,
                                                            "mcf": 0,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 72,
                                                            "parentRowNumber": 58,
                                                            "articleId": "57550271",
                                                            "description": "57550271-01-Verschraubungskörper",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.3573,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.3573,
                                                            "singleQuantityMcf": 0,
                                                            "mcf": 0,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 73,
                                                            "parentRowNumber": 58,
                                                            "articleId": "57550271",
                                                            "description": "57550271-01-Verschraubungskörper",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.3573,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.3573,
                                                            "singleQuantityMcf": 0,
                                                            "mcf": 0,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0,
                                                            "expandable": false,
                                                            "children": []
                                                        }
                                                    ]
                                                }
                                            ]
                                        },
                                        {
                                            "rowNumber": 74,
                                            "parentRowNumber": 2,
                                            "articleId": "33847",
                                            "description": "33847-00AA05-02-Füße",
                                            "hierarchy": 4,
                                            "quantity": 1,
                                            "weight": 3.5496000000000008,
                                            "childrenWeight": 3.5496000000000008,
                                            "totalQuantityWeight": 3.5496000000000008,
                                            "singleQuantityMcf": 10.195771999999998,
                                            "mcf": 10.195771999999998,
                                            "singleQuantityLcf": 0,
                                            "lcf": 0,
                                            "totalQuantityMcf": 10.195771999999998,
                                            "expandable": false,
                                            "children": [
                                                {
                                                    "rowNumber": 75,
                                                    "parentRowNumber": 74,
                                                    "articleId": "MM0000000048",
                                                    "description": "MM0000000048-01-Füße",
                                                    "hierarchy": 5,
                                                    "quantity": 1,
                                                    "weight": 0.8874000000000002,
                                                    "childrenWeight": 0.8874000000000002,
                                                    "totalQuantityWeight": 0.8874000000000002,
                                                    "singleQuantityMcf": 2.5489429999999995,
                                                    "mcf": 2.5489429999999995,
                                                    "singleQuantityLcf": 0,
                                                    "lcf": 0,
                                                    "totalQuantityMcf": 2.5489429999999995,
                                                    "expandable": false,
                                                    "children": [
                                                        {
                                                            "rowNumber": 76,
                                                            "parentRowNumber": 75,
                                                            "articleId": "46976855",
                                                            "description": "46976855-02-Fuss",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.8874000000000002,
                                                            "childrenWeight": 0.8874000000000002,
                                                            "totalQuantityWeight": 0.8874000000000002,
                                                            "singleQuantityMcf": 2.5489429999999995,
                                                            "mcf": 2.5489429999999995,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 2.5489429999999995,
                                                            "expandable": false,
                                                            "children": [
                                                                {
                                                                    "rowNumber": 77,
                                                                    "parentRowNumber": 76,
                                                                    "articleId": "20120830",
                                                                    "description": "20120830-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0181,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0181,
                                                                    "singleQuantityMcf": 0.093758,
                                                                    "mcf": 0.093758,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.093758,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 78,
                                                                    "parentRowNumber": 76,
                                                                    "articleId": "20120830",
                                                                    "description": "20120830-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0181,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0181,
                                                                    "singleQuantityMcf": 0.093758,
                                                                    "mcf": 0.093758,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.093758,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 79,
                                                                    "parentRowNumber": 76,
                                                                    "articleId": "20120830",
                                                                    "description": "20120830-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0181,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0181,
                                                                    "singleQuantityMcf": 0.093758,
                                                                    "mcf": 0.093758,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.093758,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 80,
                                                                    "parentRowNumber": 76,
                                                                    "articleId": "208208",
                                                                    "description": "208208-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0017,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0017,
                                                                    "singleQuantityMcf": 0.008806,
                                                                    "mcf": 0.008806,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.008806,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 81,
                                                                    "parentRowNumber": 76,
                                                                    "articleId": "208208",
                                                                    "description": "208208-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0017,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0017,
                                                                    "singleQuantityMcf": 0.008806,
                                                                    "mcf": 0.008806,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.008806,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 82,
                                                                    "parentRowNumber": 76,
                                                                    "articleId": "208208",
                                                                    "description": "208208-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0017,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0017,
                                                                    "singleQuantityMcf": 0.008806,
                                                                    "mcf": 0.008806,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.008806,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 83,
                                                                    "parentRowNumber": 76,
                                                                    "articleId": "239902",
                                                                    "description": "239902-02-Fussplatte",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0631,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0631,
                                                                    "singleQuantityMcf": 0.109163,
                                                                    "mcf": 0.109163,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.109163,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 84,
                                                                    "parentRowNumber": 76,
                                                                    "articleId": "441003",
                                                                    "description": "441003-00-Mutter",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0289,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0289,
                                                                    "singleQuantityMcf": 0.14970199999999995,
                                                                    "mcf": 0.14970199999999995,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.14970199999999995,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 85,
                                                                    "parentRowNumber": 76,
                                                                    "articleId": "46971678",
                                                                    "description": "46971678-00-Gewindebuchse",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.3827,
                                                                    "childrenWeight": 0.3827,
                                                                    "totalQuantityWeight": 0.3827,
                                                                    "singleQuantityMcf": 1.982386,
                                                                    "mcf": 1.982386,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 1.982386,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 86,
                                                                            "parentRowNumber": 85,
                                                                            "articleId": "1260070",
                                                                            "description": "1260070-00-Rundstahl",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.3827,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.3827,
                                                                            "singleQuantityMcf": 1.982386,
                                                                            "mcf": 1.982386,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 1.982386,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 87,
                                                                    "parentRowNumber": 76,
                                                                    "articleId": "46971679",
                                                                    "description": "46971679-00-Schraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.3533,
                                                                    "childrenWeight": 0.3533,
                                                                    "totalQuantityWeight": 0.3533,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 88,
                                                                            "parentRowNumber": 87,
                                                                            "articleId": "132524",
                                                                            "description": "132524-02-Sechskantstahl",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.3533,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.3533,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                }
                                                            ]
                                                        }
                                                    ]
                                                },
                                                {
                                                    "rowNumber": 89,
                                                    "parentRowNumber": 74,
                                                    "articleId": "MM0000000048",
                                                    "description": "MM0000000048-01-Füße",
                                                    "hierarchy": 5,
                                                    "quantity": 1,
                                                    "weight": 0.8874000000000002,
                                                    "childrenWeight": 0.8874000000000002,
                                                    "totalQuantityWeight": 0.8874000000000002,
                                                    "singleQuantityMcf": 2.5489429999999995,
                                                    "mcf": 2.5489429999999995,
                                                    "singleQuantityLcf": 0,
                                                    "lcf": 0,
                                                    "totalQuantityMcf": 2.5489429999999995,
                                                    "expandable": false,
                                                    "children": [
                                                        {
                                                            "rowNumber": 90,
                                                            "parentRowNumber": 89,
                                                            "articleId": "46976855",
                                                            "description": "46976855-02-Fuss",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.8874000000000002,
                                                            "childrenWeight": 0.8874000000000002,
                                                            "totalQuantityWeight": 0.8874000000000002,
                                                            "singleQuantityMcf": 2.5489429999999995,
                                                            "mcf": 2.5489429999999995,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 2.5489429999999995,
                                                            "expandable": false,
                                                            "children": [
                                                                {
                                                                    "rowNumber": 91,
                                                                    "parentRowNumber": 90,
                                                                    "articleId": "20120830",
                                                                    "description": "20120830-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0181,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0181,
                                                                    "singleQuantityMcf": 0.093758,
                                                                    "mcf": 0.093758,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.093758,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 92,
                                                                    "parentRowNumber": 90,
                                                                    "articleId": "20120830",
                                                                    "description": "20120830-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0181,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0181,
                                                                    "singleQuantityMcf": 0.093758,
                                                                    "mcf": 0.093758,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.093758,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 93,
                                                                    "parentRowNumber": 90,
                                                                    "articleId": "20120830",
                                                                    "description": "20120830-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0181,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0181,
                                                                    "singleQuantityMcf": 0.093758,
                                                                    "mcf": 0.093758,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.093758,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 94,
                                                                    "parentRowNumber": 90,
                                                                    "articleId": "208208",
                                                                    "description": "208208-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0017,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0017,
                                                                    "singleQuantityMcf": 0.008806,
                                                                    "mcf": 0.008806,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.008806,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 95,
                                                                    "parentRowNumber": 90,
                                                                    "articleId": "208208",
                                                                    "description": "208208-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0017,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0017,
                                                                    "singleQuantityMcf": 0.008806,
                                                                    "mcf": 0.008806,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.008806,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 96,
                                                                    "parentRowNumber": 90,
                                                                    "articleId": "208208",
                                                                    "description": "208208-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0017,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0017,
                                                                    "singleQuantityMcf": 0.008806,
                                                                    "mcf": 0.008806,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.008806,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 97,
                                                                    "parentRowNumber": 90,
                                                                    "articleId": "239902",
                                                                    "description": "239902-02-Fussplatte",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0631,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0631,
                                                                    "singleQuantityMcf": 0.109163,
                                                                    "mcf": 0.109163,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.109163,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 98,
                                                                    "parentRowNumber": 90,
                                                                    "articleId": "441003",
                                                                    "description": "441003-00-Mutter",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0289,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0289,
                                                                    "singleQuantityMcf": 0.14970199999999995,
                                                                    "mcf": 0.14970199999999995,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.14970199999999995,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 99,
                                                                    "parentRowNumber": 90,
                                                                    "articleId": "46971678",
                                                                    "description": "46971678-00-Gewindebuchse",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.3827,
                                                                    "childrenWeight": 0.3827,
                                                                    "totalQuantityWeight": 0.3827,
                                                                    "singleQuantityMcf": 1.982386,
                                                                    "mcf": 1.982386,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 1.982386,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 100,
                                                                            "parentRowNumber": 99,
                                                                            "articleId": "1260070",
                                                                            "description": "1260070-00-Rundstahl",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.3827,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.3827,
                                                                            "singleQuantityMcf": 1.982386,
                                                                            "mcf": 1.982386,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 1.982386,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 101,
                                                                    "parentRowNumber": 90,
                                                                    "articleId": "46971679",
                                                                    "description": "46971679-00-Schraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.3533,
                                                                    "childrenWeight": 0.3533,
                                                                    "totalQuantityWeight": 0.3533,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 102,
                                                                            "parentRowNumber": 101,
                                                                            "articleId": "132524",
                                                                            "description": "132524-02-Sechskantstahl",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.3533,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.3533,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                }
                                                            ]
                                                        }
                                                    ]
                                                },
                                                {
                                                    "rowNumber": 103,
                                                    "parentRowNumber": 74,
                                                    "articleId": "MM0000000048",
                                                    "description": "MM0000000048-01-Füße",
                                                    "hierarchy": 5,
                                                    "quantity": 1,
                                                    "weight": 0.8874000000000002,
                                                    "childrenWeight": 0.8874000000000002,
                                                    "totalQuantityWeight": 0.8874000000000002,
                                                    "singleQuantityMcf": 2.5489429999999995,
                                                    "mcf": 2.5489429999999995,
                                                    "singleQuantityLcf": 0,
                                                    "lcf": 0,
                                                    "totalQuantityMcf": 2.5489429999999995,
                                                    "expandable": false,
                                                    "children": [
                                                        {
                                                            "rowNumber": 104,
                                                            "parentRowNumber": 103,
                                                            "articleId": "46976855",
                                                            "description": "46976855-02-Fuss",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.8874000000000002,
                                                            "childrenWeight": 0.8874000000000002,
                                                            "totalQuantityWeight": 0.8874000000000002,
                                                            "singleQuantityMcf": 2.5489429999999995,
                                                            "mcf": 2.5489429999999995,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 2.5489429999999995,
                                                            "expandable": false,
                                                            "children": [
                                                                {
                                                                    "rowNumber": 105,
                                                                    "parentRowNumber": 104,
                                                                    "articleId": "20120830",
                                                                    "description": "20120830-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0181,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0181,
                                                                    "singleQuantityMcf": 0.093758,
                                                                    "mcf": 0.093758,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.093758,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 106,
                                                                    "parentRowNumber": 104,
                                                                    "articleId": "20120830",
                                                                    "description": "20120830-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0181,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0181,
                                                                    "singleQuantityMcf": 0.093758,
                                                                    "mcf": 0.093758,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.093758,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 107,
                                                                    "parentRowNumber": 104,
                                                                    "articleId": "20120830",
                                                                    "description": "20120830-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0181,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0181,
                                                                    "singleQuantityMcf": 0.093758,
                                                                    "mcf": 0.093758,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.093758,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 108,
                                                                    "parentRowNumber": 104,
                                                                    "articleId": "208208",
                                                                    "description": "208208-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0017,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0017,
                                                                    "singleQuantityMcf": 0.008806,
                                                                    "mcf": 0.008806,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.008806,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 109,
                                                                    "parentRowNumber": 104,
                                                                    "articleId": "208208",
                                                                    "description": "208208-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0017,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0017,
                                                                    "singleQuantityMcf": 0.008806,
                                                                    "mcf": 0.008806,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.008806,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 110,
                                                                    "parentRowNumber": 104,
                                                                    "articleId": "208208",
                                                                    "description": "208208-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0017,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0017,
                                                                    "singleQuantityMcf": 0.008806,
                                                                    "mcf": 0.008806,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.008806,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 111,
                                                                    "parentRowNumber": 104,
                                                                    "articleId": "239902",
                                                                    "description": "239902-02-Fussplatte",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0631,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0631,
                                                                    "singleQuantityMcf": 0.109163,
                                                                    "mcf": 0.109163,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.109163,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 112,
                                                                    "parentRowNumber": 104,
                                                                    "articleId": "441003",
                                                                    "description": "441003-00-Mutter",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0289,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0289,
                                                                    "singleQuantityMcf": 0.14970199999999995,
                                                                    "mcf": 0.14970199999999995,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.14970199999999995,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 113,
                                                                    "parentRowNumber": 104,
                                                                    "articleId": "46971678",
                                                                    "description": "46971678-00-Gewindebuchse",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.3827,
                                                                    "childrenWeight": 0.3827,
                                                                    "totalQuantityWeight": 0.3827,
                                                                    "singleQuantityMcf": 1.982386,
                                                                    "mcf": 1.982386,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 1.982386,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 114,
                                                                            "parentRowNumber": 113,
                                                                            "articleId": "1260070",
                                                                            "description": "1260070-00-Rundstahl",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.3827,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.3827,
                                                                            "singleQuantityMcf": 1.982386,
                                                                            "mcf": 1.982386,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 1.982386,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 115,
                                                                    "parentRowNumber": 104,
                                                                    "articleId": "46971679",
                                                                    "description": "46971679-00-Schraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.3533,
                                                                    "childrenWeight": 0.3533,
                                                                    "totalQuantityWeight": 0.3533,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 116,
                                                                            "parentRowNumber": 115,
                                                                            "articleId": "132524",
                                                                            "description": "132524-02-Sechskantstahl",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.3533,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.3533,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                }
                                                            ]
                                                        }
                                                    ]
                                                },
                                                {
                                                    "rowNumber": 117,
                                                    "parentRowNumber": 74,
                                                    "articleId": "MM0000000048",
                                                    "description": "MM0000000048-01-Füße",
                                                    "hierarchy": 5,
                                                    "quantity": 1,
                                                    "weight": 0.8874000000000002,
                                                    "childrenWeight": 0.8874000000000002,
                                                    "totalQuantityWeight": 0.8874000000000002,
                                                    "singleQuantityMcf": 2.5489429999999995,
                                                    "mcf": 2.5489429999999995,
                                                    "singleQuantityLcf": 0,
                                                    "lcf": 0,
                                                    "totalQuantityMcf": 2.5489429999999995,
                                                    "expandable": false,
                                                    "children": [
                                                        {
                                                            "rowNumber": 118,
                                                            "parentRowNumber": 117,
                                                            "articleId": "46976855",
                                                            "description": "46976855-02-Fuss",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.8874000000000002,
                                                            "childrenWeight": 0.8874000000000002,
                                                            "totalQuantityWeight": 0.8874000000000002,
                                                            "singleQuantityMcf": 2.5489429999999995,
                                                            "mcf": 2.5489429999999995,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 2.5489429999999995,
                                                            "expandable": false,
                                                            "children": [
                                                                {
                                                                    "rowNumber": 119,
                                                                    "parentRowNumber": 118,
                                                                    "articleId": "20120830",
                                                                    "description": "20120830-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0181,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0181,
                                                                    "singleQuantityMcf": 0.093758,
                                                                    "mcf": 0.093758,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.093758,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 120,
                                                                    "parentRowNumber": 118,
                                                                    "articleId": "20120830",
                                                                    "description": "20120830-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0181,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0181,
                                                                    "singleQuantityMcf": 0.093758,
                                                                    "mcf": 0.093758,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.093758,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 121,
                                                                    "parentRowNumber": 118,
                                                                    "articleId": "20120830",
                                                                    "description": "20120830-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0181,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0181,
                                                                    "singleQuantityMcf": 0.093758,
                                                                    "mcf": 0.093758,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.093758,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 122,
                                                                    "parentRowNumber": 118,
                                                                    "articleId": "208208",
                                                                    "description": "208208-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0017,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0017,
                                                                    "singleQuantityMcf": 0.008806,
                                                                    "mcf": 0.008806,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.008806,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 123,
                                                                    "parentRowNumber": 118,
                                                                    "articleId": "208208",
                                                                    "description": "208208-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0017,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0017,
                                                                    "singleQuantityMcf": 0.008806,
                                                                    "mcf": 0.008806,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.008806,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 124,
                                                                    "parentRowNumber": 118,
                                                                    "articleId": "208208",
                                                                    "description": "208208-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0017,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0017,
                                                                    "singleQuantityMcf": 0.008806,
                                                                    "mcf": 0.008806,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.008806,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 125,
                                                                    "parentRowNumber": 118,
                                                                    "articleId": "239902",
                                                                    "description": "239902-02-Fussplatte",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0631,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0631,
                                                                    "singleQuantityMcf": 0.109163,
                                                                    "mcf": 0.109163,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.109163,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 126,
                                                                    "parentRowNumber": 118,
                                                                    "articleId": "441003",
                                                                    "description": "441003-00-Mutter",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0289,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0289,
                                                                    "singleQuantityMcf": 0.14970199999999995,
                                                                    "mcf": 0.14970199999999995,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.14970199999999995,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 127,
                                                                    "parentRowNumber": 118,
                                                                    "articleId": "46971678",
                                                                    "description": "46971678-00-Gewindebuchse",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.3827,
                                                                    "childrenWeight": 0.3827,
                                                                    "totalQuantityWeight": 0.3827,
                                                                    "singleQuantityMcf": 1.982386,
                                                                    "mcf": 1.982386,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 1.982386,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 128,
                                                                            "parentRowNumber": 127,
                                                                            "articleId": "1260070",
                                                                            "description": "1260070-00-Rundstahl",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.3827,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.3827,
                                                                            "singleQuantityMcf": 1.982386,
                                                                            "mcf": 1.982386,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 1.982386,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 129,
                                                                    "parentRowNumber": 118,
                                                                    "articleId": "46971679",
                                                                    "description": "46971679-00-Schraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.3533,
                                                                    "childrenWeight": 0.3533,
                                                                    "totalQuantityWeight": 0.3533,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 130,
                                                                            "parentRowNumber": 129,
                                                                            "articleId": "132524",
                                                                            "description": "132524-02-Sechskantstahl",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.3533,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.3533,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                }
                                                            ]
                                                        }
                                                    ]
                                                }
                                            ]
                                        },
                                        {
                                            "rowNumber": 131,
                                            "parentRowNumber": 2,
                                            "articleId": "33847",
                                            "description": "33847-00AA30-01-Leitungsführungen",
                                            "hierarchy": 4,
                                            "quantity": 1,
                                            "weight": 213.9143000000001,
                                            "childrenWeight": 213.9143000000001,
                                            "totalQuantityWeight": 213.9143000000001,
                                            "singleQuantityMcf": 0.045584,
                                            "mcf": 0.045584,
                                            "singleQuantityLcf": 0,
                                            "lcf": 0,
                                            "totalQuantityMcf": 0.045584,
                                            "expandable": false,
                                            "children": [
                                                {
                                                    "rowNumber": 132,
                                                    "parentRowNumber": 131,
                                                    "articleId": "MM0000004703",
                                                    "description": "MM0000004703-01-Leitungsführungen",
                                                    "hierarchy": 5,
                                                    "quantity": 1,
                                                    "weight": 213.9143000000001,
                                                    "childrenWeight": 213.9143000000001,
                                                    "totalQuantityWeight": 213.9143000000001,
                                                    "singleQuantityMcf": 0.045584,
                                                    "mcf": 0.045584,
                                                    "singleQuantityLcf": 0,
                                                    "lcf": 0,
                                                    "totalQuantityMcf": 0.045584,
                                                    "expandable": false,
                                                    "children": [
                                                        {
                                                            "rowNumber": 133,
                                                            "parentRowNumber": 132,
                                                            "articleId": "20220520",
                                                            "description": "20220520-00-Zylinderschraube",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0044,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0044,
                                                            "singleQuantityMcf": 0.022792,
                                                            "mcf": 0.022792,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.022792,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 134,
                                                            "parentRowNumber": 132,
                                                            "articleId": "20220520",
                                                            "description": "20220520-00-Zylinderschraube",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0044,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0044,
                                                            "singleQuantityMcf": 0.022792,
                                                            "mcf": 0.022792,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.022792,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 135,
                                                            "parentRowNumber": 132,
                                                            "articleId": "57423131",
                                                            "description": "57423131-01-Verdrahtungskanal",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 6.219399999999999,
                                                            "childrenWeight": 6.219399999999999,
                                                            "totalQuantityWeight": 6.219399999999999,
                                                            "singleQuantityMcf": 0,
                                                            "mcf": 0,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0,
                                                            "expandable": false,
                                                            "children": [
                                                                {
                                                                    "rowNumber": 136,
                                                                    "parentRowNumber": 135,
                                                                    "articleId": "57423131_01",
                                                                    "description": "57423131_01-00",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 5.5638,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 5.5638,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 137,
                                                                    "parentRowNumber": 135,
                                                                    "articleId": "57423131_02",
                                                                    "description": "57423131_02-00",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.6556,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.6556,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": []
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            "rowNumber": 138,
                                                            "parentRowNumber": 132,
                                                            "articleId": "57423131",
                                                            "description": "57423131-01-Verdrahtungskanal",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 6.219399999999999,
                                                            "childrenWeight": 6.219399999999999,
                                                            "totalQuantityWeight": 6.219399999999999,
                                                            "singleQuantityMcf": 0,
                                                            "mcf": 0,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0,
                                                            "expandable": false,
                                                            "children": [
                                                                {
                                                                    "rowNumber": 139,
                                                                    "parentRowNumber": 138,
                                                                    "articleId": "57423131_01",
                                                                    "description": "57423131_01-00",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 5.5638,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 5.5638,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 140,
                                                                    "parentRowNumber": 138,
                                                                    "articleId": "57423131_02",
                                                                    "description": "57423131_02-00",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.6556,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.6556,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": []
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            "rowNumber": 141,
                                                            "parentRowNumber": 132,
                                                            "articleId": "57423132",
                                                            "description": "57423132-01-Verdrahtungskanal",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 9.9542,
                                                            "childrenWeight": 9.9542,
                                                            "totalQuantityWeight": 9.9542,
                                                            "singleQuantityMcf": 0,
                                                            "mcf": 0,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0,
                                                            "expandable": false,
                                                            "children": [
                                                                {
                                                                    "rowNumber": 142,
                                                                    "parentRowNumber": 141,
                                                                    "articleId": "57423132_01",
                                                                    "description": "57423132_01-00",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 8.6075,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 8.6075,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 143,
                                                                    "parentRowNumber": 141,
                                                                    "articleId": "57423132_02",
                                                                    "description": "57423132_02-00",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 1.3467,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 1.3467,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": []
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            "rowNumber": 144,
                                                            "parentRowNumber": 132,
                                                            "articleId": "57423132",
                                                            "description": "57423132-01-Verdrahtungskanal",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 9.9542,
                                                            "childrenWeight": 9.9542,
                                                            "totalQuantityWeight": 9.9542,
                                                            "singleQuantityMcf": 0,
                                                            "mcf": 0,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0,
                                                            "expandable": false,
                                                            "children": [
                                                                {
                                                                    "rowNumber": 145,
                                                                    "parentRowNumber": 144,
                                                                    "articleId": "57423132_01",
                                                                    "description": "57423132_01-00",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 8.6075,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 8.6075,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 146,
                                                                    "parentRowNumber": 144,
                                                                    "articleId": "57423132_02",
                                                                    "description": "57423132_02-00",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 1.3467,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 1.3467,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": []
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            "rowNumber": 147,
                                                            "parentRowNumber": 132,
                                                            "articleId": "57423132",
                                                            "description": "57423132-01-Verdrahtungskanal",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 9.9542,
                                                            "childrenWeight": 9.9542,
                                                            "totalQuantityWeight": 9.9542,
                                                            "singleQuantityMcf": 0,
                                                            "mcf": 0,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0,
                                                            "expandable": false,
                                                            "children": [
                                                                {
                                                                    "rowNumber": 148,
                                                                    "parentRowNumber": 147,
                                                                    "articleId": "57423132_01",
                                                                    "description": "57423132_01-00",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 8.6075,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 8.6075,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 149,
                                                                    "parentRowNumber": 147,
                                                                    "articleId": "57423132_02",
                                                                    "description": "57423132_02-00",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 1.3467,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 1.3467,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": []
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            "rowNumber": 150,
                                                            "parentRowNumber": 132,
                                                            "articleId": "57423132",
                                                            "description": "57423132-01-Verdrahtungskanal",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 9.9542,
                                                            "childrenWeight": 9.9542,
                                                            "totalQuantityWeight": 9.9542,
                                                            "singleQuantityMcf": 0,
                                                            "mcf": 0,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0,
                                                            "expandable": false,
                                                            "children": [
                                                                {
                                                                    "rowNumber": 151,
                                                                    "parentRowNumber": 150,
                                                                    "articleId": "57423132_01",
                                                                    "description": "57423132_01-00",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 8.6075,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 8.6075,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 152,
                                                                    "parentRowNumber": 150,
                                                                    "articleId": "57423132_02",
                                                                    "description": "57423132_02-00",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 1.3467,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 1.3467,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": []
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            "rowNumber": 153,
                                                            "parentRowNumber": 132,
                                                            "articleId": "57423132",
                                                            "description": "57423132-01-Verdrahtungskanal",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 9.9542,
                                                            "childrenWeight": 9.9542,
                                                            "totalQuantityWeight": 9.9542,
                                                            "singleQuantityMcf": 0,
                                                            "mcf": 0,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0,
                                                            "expandable": false,
                                                            "children": [
                                                                {
                                                                    "rowNumber": 154,
                                                                    "parentRowNumber": 153,
                                                                    "articleId": "57423132_01",
                                                                    "description": "57423132_01-00",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 8.6075,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 8.6075,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 155,
                                                                    "parentRowNumber": 153,
                                                                    "articleId": "57423132_02",
                                                                    "description": "57423132_02-00",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 1.3467,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 1.3467,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": []
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            "rowNumber": 156,
                                                            "parentRowNumber": 132,
                                                            "articleId": "57423132",
                                                            "description": "57423132-01-Verdrahtungskanal",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 9.9542,
                                                            "childrenWeight": 9.9542,
                                                            "totalQuantityWeight": 9.9542,
                                                            "singleQuantityMcf": 0,
                                                            "mcf": 0,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0,
                                                            "expandable": false,
                                                            "children": [
                                                                {
                                                                    "rowNumber": 157,
                                                                    "parentRowNumber": 156,
                                                                    "articleId": "57423132_01",
                                                                    "description": "57423132_01-00",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 8.6075,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 8.6075,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 158,
                                                                    "parentRowNumber": 156,
                                                                    "articleId": "57423132_02",
                                                                    "description": "57423132_02-00",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 1.3467,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 1.3467,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": []
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            "rowNumber": 159,
                                                            "parentRowNumber": 132,
                                                            "articleId": "57423132",
                                                            "description": "57423132-01-Verdrahtungskanal",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 9.9542,
                                                            "childrenWeight": 9.9542,
                                                            "totalQuantityWeight": 9.9542,
                                                            "singleQuantityMcf": 0,
                                                            "mcf": 0,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0,
                                                            "expandable": false,
                                                            "children": [
                                                                {
                                                                    "rowNumber": 160,
                                                                    "parentRowNumber": 159,
                                                                    "articleId": "57423132_01",
                                                                    "description": "57423132_01-00",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 8.6075,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 8.6075,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 161,
                                                                    "parentRowNumber": 159,
                                                                    "articleId": "57423132_02",
                                                                    "description": "57423132_02-00",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 1.3467,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 1.3467,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": []
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            "rowNumber": 162,
                                                            "parentRowNumber": 132,
                                                            "articleId": "57423132",
                                                            "description": "57423132-01-Verdrahtungskanal",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 9.9542,
                                                            "childrenWeight": 9.9542,
                                                            "totalQuantityWeight": 9.9542,
                                                            "singleQuantityMcf": 0,
                                                            "mcf": 0,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0,
                                                            "expandable": false,
                                                            "children": [
                                                                {
                                                                    "rowNumber": 163,
                                                                    "parentRowNumber": 162,
                                                                    "articleId": "57423132_01",
                                                                    "description": "57423132_01-00",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 8.6075,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 8.6075,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 164,
                                                                    "parentRowNumber": 162,
                                                                    "articleId": "57423132_02",
                                                                    "description": "57423132_02-00",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 1.3467,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 1.3467,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": []
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            "rowNumber": 165,
                                                            "parentRowNumber": 132,
                                                            "articleId": "57423132",
                                                            "description": "57423132-01-Verdrahtungskanal",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 9.9542,
                                                            "childrenWeight": 9.9542,
                                                            "totalQuantityWeight": 9.9542,
                                                            "singleQuantityMcf": 0,
                                                            "mcf": 0,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0,
                                                            "expandable": false,
                                                            "children": [
                                                                {
                                                                    "rowNumber": 166,
                                                                    "parentRowNumber": 165,
                                                                    "articleId": "57423132_01",
                                                                    "description": "57423132_01-00",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 8.6075,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 8.6075,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 167,
                                                                    "parentRowNumber": 165,
                                                                    "articleId": "57423132_02",
                                                                    "description": "57423132_02-00",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 1.3467,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 1.3467,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": []
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            "rowNumber": 168,
                                                            "parentRowNumber": 132,
                                                            "articleId": "57423132",
                                                            "description": "57423132-01-Verdrahtungskanal",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 9.9542,
                                                            "childrenWeight": 9.9542,
                                                            "totalQuantityWeight": 9.9542,
                                                            "singleQuantityMcf": 0,
                                                            "mcf": 0,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0,
                                                            "expandable": false,
                                                            "children": [
                                                                {
                                                                    "rowNumber": 169,
                                                                    "parentRowNumber": 168,
                                                                    "articleId": "57423132_01",
                                                                    "description": "57423132_01-00",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 8.6075,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 8.6075,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 170,
                                                                    "parentRowNumber": 168,
                                                                    "articleId": "57423132_02",
                                                                    "description": "57423132_02-00",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 1.3467,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 1.3467,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": []
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            "rowNumber": 171,
                                                            "parentRowNumber": 132,
                                                            "articleId": "57423132",
                                                            "description": "57423132-01-Verdrahtungskanal",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 9.9542,
                                                            "childrenWeight": 9.9542,
                                                            "totalQuantityWeight": 9.9542,
                                                            "singleQuantityMcf": 0,
                                                            "mcf": 0,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0,
                                                            "expandable": false,
                                                            "children": [
                                                                {
                                                                    "rowNumber": 172,
                                                                    "parentRowNumber": 171,
                                                                    "articleId": "57423132_01",
                                                                    "description": "57423132_01-00",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 8.6075,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 8.6075,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 173,
                                                                    "parentRowNumber": 171,
                                                                    "articleId": "57423132_02",
                                                                    "description": "57423132_02-00",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 1.3467,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 1.3467,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": []
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            "rowNumber": 174,
                                                            "parentRowNumber": 132,
                                                            "articleId": "57423132",
                                                            "description": "57423132-01-Verdrahtungskanal",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 9.9542,
                                                            "childrenWeight": 9.9542,
                                                            "totalQuantityWeight": 9.9542,
                                                            "singleQuantityMcf": 0,
                                                            "mcf": 0,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0,
                                                            "expandable": false,
                                                            "children": [
                                                                {
                                                                    "rowNumber": 175,
                                                                    "parentRowNumber": 174,
                                                                    "articleId": "57423132_01",
                                                                    "description": "57423132_01-00",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 8.6075,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 8.6075,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 176,
                                                                    "parentRowNumber": 174,
                                                                    "articleId": "57423132_02",
                                                                    "description": "57423132_02-00",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 1.3467,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 1.3467,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": []
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            "rowNumber": 177,
                                                            "parentRowNumber": 132,
                                                            "articleId": "57423134",
                                                            "description": "57423134-01-Verdrahtungskanal",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 11.6965,
                                                            "childrenWeight": 11.6965,
                                                            "totalQuantityWeight": 11.6965,
                                                            "singleQuantityMcf": 0,
                                                            "mcf": 0,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0,
                                                            "expandable": false,
                                                            "children": [
                                                                {
                                                                    "rowNumber": 178,
                                                                    "parentRowNumber": 177,
                                                                    "articleId": "57423134_01",
                                                                    "description": "57423134_01-00",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 9.7218,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 9.7218,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 179,
                                                                    "parentRowNumber": 177,
                                                                    "articleId": "57423134_02",
                                                                    "description": "57423134_02-00",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 1.9747,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 1.9747,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": []
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            "rowNumber": 180,
                                                            "parentRowNumber": 132,
                                                            "articleId": "57423134",
                                                            "description": "57423134-01-Verdrahtungskanal",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 11.6965,
                                                            "childrenWeight": 11.6965,
                                                            "totalQuantityWeight": 11.6965,
                                                            "singleQuantityMcf": 0,
                                                            "mcf": 0,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0,
                                                            "expandable": false,
                                                            "children": [
                                                                {
                                                                    "rowNumber": 181,
                                                                    "parentRowNumber": 180,
                                                                    "articleId": "57423134_01",
                                                                    "description": "57423134_01-00",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 9.7218,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 9.7218,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 182,
                                                                    "parentRowNumber": 180,
                                                                    "articleId": "57423134_02",
                                                                    "description": "57423134_02-00",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 1.9747,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 1.9747,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": []
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            "rowNumber": 183,
                                                            "parentRowNumber": 132,
                                                            "articleId": "57423134",
                                                            "description": "57423134-01-Verdrahtungskanal",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 11.6965,
                                                            "childrenWeight": 11.6965,
                                                            "totalQuantityWeight": 11.6965,
                                                            "singleQuantityMcf": 0,
                                                            "mcf": 0,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0,
                                                            "expandable": false,
                                                            "children": [
                                                                {
                                                                    "rowNumber": 184,
                                                                    "parentRowNumber": 183,
                                                                    "articleId": "57423134_01",
                                                                    "description": "57423134_01-00",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 9.7218,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 9.7218,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 185,
                                                                    "parentRowNumber": 183,
                                                                    "articleId": "57423134_02",
                                                                    "description": "57423134_02-00",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 1.9747,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 1.9747,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": []
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            "rowNumber": 186,
                                                            "parentRowNumber": 132,
                                                            "articleId": "57423134",
                                                            "description": "57423134-01-Verdrahtungskanal",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 11.6965,
                                                            "childrenWeight": 11.6965,
                                                            "totalQuantityWeight": 11.6965,
                                                            "singleQuantityMcf": 0,
                                                            "mcf": 0,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0,
                                                            "expandable": false,
                                                            "children": [
                                                                {
                                                                    "rowNumber": 187,
                                                                    "parentRowNumber": 186,
                                                                    "articleId": "57423134_01",
                                                                    "description": "57423134_01-00",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 9.7218,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 9.7218,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 188,
                                                                    "parentRowNumber": 186,
                                                                    "articleId": "57423134_02",
                                                                    "description": "57423134_02-00",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 1.9747,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 1.9747,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": []
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            "rowNumber": 189,
                                                            "parentRowNumber": 132,
                                                            "articleId": "57423134",
                                                            "description": "57423134-01-Verdrahtungskanal",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 11.6965,
                                                            "childrenWeight": 11.6965,
                                                            "totalQuantityWeight": 11.6965,
                                                            "singleQuantityMcf": 0,
                                                            "mcf": 0,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0,
                                                            "expandable": false,
                                                            "children": [
                                                                {
                                                                    "rowNumber": 190,
                                                                    "parentRowNumber": 189,
                                                                    "articleId": "57423134_01",
                                                                    "description": "57423134_01-00",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 9.7218,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 9.7218,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 191,
                                                                    "parentRowNumber": 189,
                                                                    "articleId": "57423134_02",
                                                                    "description": "57423134_02-00",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 1.9747,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 1.9747,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": []
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            "rowNumber": 192,
                                                            "parentRowNumber": 132,
                                                            "articleId": "57423134",
                                                            "description": "57423134-01-Verdrahtungskanal",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 11.6965,
                                                            "childrenWeight": 11.6965,
                                                            "totalQuantityWeight": 11.6965,
                                                            "singleQuantityMcf": 0,
                                                            "mcf": 0,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0,
                                                            "expandable": false,
                                                            "children": [
                                                                {
                                                                    "rowNumber": 193,
                                                                    "parentRowNumber": 192,
                                                                    "articleId": "57423134_01",
                                                                    "description": "57423134_01-00",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 9.7218,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 9.7218,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 194,
                                                                    "parentRowNumber": 192,
                                                                    "articleId": "57423134_02",
                                                                    "description": "57423134_02-00",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 1.9747,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 1.9747,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": []
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            "rowNumber": 195,
                                                            "parentRowNumber": 132,
                                                            "articleId": "57423134",
                                                            "description": "57423134-01-Verdrahtungskanal",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 11.6965,
                                                            "childrenWeight": 11.6965,
                                                            "totalQuantityWeight": 11.6965,
                                                            "singleQuantityMcf": 0,
                                                            "mcf": 0,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0,
                                                            "expandable": false,
                                                            "children": [
                                                                {
                                                                    "rowNumber": 196,
                                                                    "parentRowNumber": 195,
                                                                    "articleId": "57423134_01",
                                                                    "description": "57423134_01-00",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 9.7218,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 9.7218,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 197,
                                                                    "parentRowNumber": 195,
                                                                    "articleId": "57423134_02",
                                                                    "description": "57423134_02-00",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 1.9747,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 1.9747,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": []
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            "rowNumber": 198,
                                                            "parentRowNumber": 132,
                                                            "articleId": "57585176",
                                                            "description": "57585176-02-Zugentlastungsleiste",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.1408,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.1408,
                                                            "singleQuantityMcf": 0,
                                                            "mcf": 0,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0,
                                                            "expandable": false,
                                                            "children": []
                                                        }
                                                    ]
                                                }
                                            ]
                                        },
                                        {
                                            "rowNumber": 199,
                                            "parentRowNumber": 2,
                                            "articleId": "33847",
                                            "description": "33847-00AA61-02-Eckwinkel",
                                            "hierarchy": 4,
                                            "quantity": 1,
                                            "weight": 3.3397000000000023,
                                            "childrenWeight": 3.3397000000000023,
                                            "totalQuantityWeight": 3.3397000000000023,
                                            "singleQuantityMcf": 16.817905999999983,
                                            "mcf": 16.817905999999983,
                                            "singleQuantityLcf": 0,
                                            "lcf": 0,
                                            "totalQuantityMcf": 16.817905999999983,
                                            "expandable": false,
                                            "children": [
                                                {
                                                    "rowNumber": 200,
                                                    "parentRowNumber": 199,
                                                    "articleId": "MM0000004784",
                                                    "description": "MM0000004784-02-Eckwinkel",
                                                    "hierarchy": 5,
                                                    "quantity": 1,
                                                    "weight": 3.3397000000000023,
                                                    "childrenWeight": 3.3397000000000023,
                                                    "totalQuantityWeight": 3.3397000000000023,
                                                    "singleQuantityMcf": 16.817905999999983,
                                                    "mcf": 16.817905999999983,
                                                    "singleQuantityLcf": 0,
                                                    "lcf": 0,
                                                    "totalQuantityMcf": 16.817905999999983,
                                                    "expandable": false,
                                                    "children": [
                                                        {
                                                            "rowNumber": 201,
                                                            "parentRowNumber": 200,
                                                            "articleId": "47100881",
                                                            "description": "47100881-02-Eckwinkel",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 3.3397000000000023,
                                                            "childrenWeight": 3.3397000000000023,
                                                            "totalQuantityWeight": 3.3397000000000023,
                                                            "singleQuantityMcf": 16.817905999999983,
                                                            "mcf": 16.817905999999983,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 16.817905999999983,
                                                            "expandable": false,
                                                            "children": [
                                                                {
                                                                    "rowNumber": 202,
                                                                    "parentRowNumber": 201,
                                                                    "articleId": "205204",
                                                                    "description": "205204-00-Sechskantmutter",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0008,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0008,
                                                                    "singleQuantityMcf": 0.004144,
                                                                    "mcf": 0.004144,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.004144,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 203,
                                                                    "parentRowNumber": 201,
                                                                    "articleId": "205204",
                                                                    "description": "205204-00-Sechskantmutter",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0008,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0008,
                                                                    "singleQuantityMcf": 0.004144,
                                                                    "mcf": 0.004144,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.004144,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 204,
                                                                    "parentRowNumber": 201,
                                                                    "articleId": "205204",
                                                                    "description": "205204-00-Sechskantmutter",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0008,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0008,
                                                                    "singleQuantityMcf": 0.004144,
                                                                    "mcf": 0.004144,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.004144,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 205,
                                                                    "parentRowNumber": 201,
                                                                    "articleId": "205204",
                                                                    "description": "205204-00-Sechskantmutter",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0008,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0008,
                                                                    "singleQuantityMcf": 0.004144,
                                                                    "mcf": 0.004144,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.004144,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 206,
                                                                    "parentRowNumber": 201,
                                                                    "articleId": "205204",
                                                                    "description": "205204-00-Sechskantmutter",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0008,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0008,
                                                                    "singleQuantityMcf": 0.004144,
                                                                    "mcf": 0.004144,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.004144,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 207,
                                                                    "parentRowNumber": 201,
                                                                    "articleId": "205204",
                                                                    "description": "205204-00-Sechskantmutter",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0008,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0008,
                                                                    "singleQuantityMcf": 0.004144,
                                                                    "mcf": 0.004144,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.004144,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 208,
                                                                    "parentRowNumber": 201,
                                                                    "articleId": "205204",
                                                                    "description": "205204-00-Sechskantmutter",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0008,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0008,
                                                                    "singleQuantityMcf": 0.004144,
                                                                    "mcf": 0.004144,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.004144,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 209,
                                                                    "parentRowNumber": 201,
                                                                    "articleId": "205204",
                                                                    "description": "205204-00-Sechskantmutter",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0008,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0008,
                                                                    "singleQuantityMcf": 0.004144,
                                                                    "mcf": 0.004144,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.004144,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 210,
                                                                    "parentRowNumber": 201,
                                                                    "articleId": "205206",
                                                                    "description": "205206-00-Sechskantmutter",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0026,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0026,
                                                                    "singleQuantityMcf": 0.013468,
                                                                    "mcf": 0.013468,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.013468,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 211,
                                                                    "parentRowNumber": 201,
                                                                    "articleId": "205206",
                                                                    "description": "205206-00-Sechskantmutter",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0026,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0026,
                                                                    "singleQuantityMcf": 0.013468,
                                                                    "mcf": 0.013468,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.013468,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 212,
                                                                    "parentRowNumber": 201,
                                                                    "articleId": "205206",
                                                                    "description": "205206-00-Sechskantmutter",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0026,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0026,
                                                                    "singleQuantityMcf": 0.013468,
                                                                    "mcf": 0.013468,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.013468,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 213,
                                                                    "parentRowNumber": 201,
                                                                    "articleId": "205206",
                                                                    "description": "205206-00-Sechskantmutter",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0026,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0026,
                                                                    "singleQuantityMcf": 0.013468,
                                                                    "mcf": 0.013468,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.013468,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 214,
                                                                    "parentRowNumber": 201,
                                                                    "articleId": "208204",
                                                                    "description": "208204-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0003,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0003,
                                                                    "singleQuantityMcf": 0.0015539999999999998,
                                                                    "mcf": 0.0015539999999999998,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.0015539999999999998,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 215,
                                                                    "parentRowNumber": 201,
                                                                    "articleId": "208204",
                                                                    "description": "208204-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0003,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0003,
                                                                    "singleQuantityMcf": 0.0015539999999999998,
                                                                    "mcf": 0.0015539999999999998,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.0015539999999999998,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 216,
                                                                    "parentRowNumber": 201,
                                                                    "articleId": "208204",
                                                                    "description": "208204-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0003,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0003,
                                                                    "singleQuantityMcf": 0.0015539999999999998,
                                                                    "mcf": 0.0015539999999999998,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.0015539999999999998,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 217,
                                                                    "parentRowNumber": 201,
                                                                    "articleId": "208204",
                                                                    "description": "208204-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0003,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0003,
                                                                    "singleQuantityMcf": 0.0015539999999999998,
                                                                    "mcf": 0.0015539999999999998,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.0015539999999999998,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 218,
                                                                    "parentRowNumber": 201,
                                                                    "articleId": "208204",
                                                                    "description": "208204-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0003,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0003,
                                                                    "singleQuantityMcf": 0.0015539999999999998,
                                                                    "mcf": 0.0015539999999999998,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.0015539999999999998,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 219,
                                                                    "parentRowNumber": 201,
                                                                    "articleId": "208204",
                                                                    "description": "208204-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0003,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0003,
                                                                    "singleQuantityMcf": 0.0015539999999999998,
                                                                    "mcf": 0.0015539999999999998,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.0015539999999999998,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 220,
                                                                    "parentRowNumber": 201,
                                                                    "articleId": "208204",
                                                                    "description": "208204-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0003,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0003,
                                                                    "singleQuantityMcf": 0.0015539999999999998,
                                                                    "mcf": 0.0015539999999999998,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.0015539999999999998,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 221,
                                                                    "parentRowNumber": 201,
                                                                    "articleId": "208204",
                                                                    "description": "208204-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0003,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0003,
                                                                    "singleQuantityMcf": 0.0015539999999999998,
                                                                    "mcf": 0.0015539999999999998,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.0015539999999999998,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 222,
                                                                    "parentRowNumber": 201,
                                                                    "articleId": "208206",
                                                                    "description": "208206-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0009,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0009,
                                                                    "singleQuantityMcf": 0.004662,
                                                                    "mcf": 0.004662,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.004662,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 223,
                                                                    "parentRowNumber": 201,
                                                                    "articleId": "208206",
                                                                    "description": "208206-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0009,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0009,
                                                                    "singleQuantityMcf": 0.004662,
                                                                    "mcf": 0.004662,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.004662,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 224,
                                                                    "parentRowNumber": 201,
                                                                    "articleId": "208206",
                                                                    "description": "208206-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0009,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0009,
                                                                    "singleQuantityMcf": 0.004662,
                                                                    "mcf": 0.004662,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.004662,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 225,
                                                                    "parentRowNumber": 201,
                                                                    "articleId": "208206",
                                                                    "description": "208206-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0009,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0009,
                                                                    "singleQuantityMcf": 0.004662,
                                                                    "mcf": 0.004662,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.004662,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 226,
                                                                    "parentRowNumber": 201,
                                                                    "articleId": "208206",
                                                                    "description": "208206-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0009,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0009,
                                                                    "singleQuantityMcf": 0.004662,
                                                                    "mcf": 0.004662,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.004662,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 227,
                                                                    "parentRowNumber": 201,
                                                                    "articleId": "208206",
                                                                    "description": "208206-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0009,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0009,
                                                                    "singleQuantityMcf": 0.004662,
                                                                    "mcf": 0.004662,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.004662,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 228,
                                                                    "parentRowNumber": 201,
                                                                    "articleId": "247206",
                                                                    "description": "247206-00-Sechskantmutter",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0029,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0029,
                                                                    "singleQuantityMcf": 0.015022,
                                                                    "mcf": 0.015022,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.015022,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 229,
                                                                    "parentRowNumber": 201,
                                                                    "articleId": "247206",
                                                                    "description": "247206-00-Sechskantmutter",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0029,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0029,
                                                                    "singleQuantityMcf": 0.015022,
                                                                    "mcf": 0.015022,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.015022,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 230,
                                                                    "parentRowNumber": 201,
                                                                    "articleId": "47074794",
                                                                    "description": "47074794-01-Blech",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 2.4504,
                                                                    "childrenWeight": 2.4504,
                                                                    "totalQuantityWeight": 2.4504,
                                                                    "singleQuantityMcf": 12.693072,
                                                                    "mcf": 12.693072,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 12.693072,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 231,
                                                                            "parentRowNumber": 230,
                                                                            "articleId": "142603",
                                                                            "description": "142603-00-Edelstahlblech",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 2.4232,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 2.4232,
                                                                            "singleQuantityMcf": 12.552176,
                                                                            "mcf": 12.552176,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 12.552176,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        },
                                                                        {
                                                                            "rowNumber": 232,
                                                                            "parentRowNumber": 230,
                                                                            "articleId": "21320630",
                                                                            "description": "21320630-01-Gewindestift",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0068,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0068,
                                                                            "singleQuantityMcf": 0.035224,
                                                                            "mcf": 0.035224,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.035224,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        },
                                                                        {
                                                                            "rowNumber": 233,
                                                                            "parentRowNumber": 230,
                                                                            "articleId": "21320630",
                                                                            "description": "21320630-01-Gewindestift",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0068,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0068,
                                                                            "singleQuantityMcf": 0.035224,
                                                                            "mcf": 0.035224,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.035224,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        },
                                                                        {
                                                                            "rowNumber": 234,
                                                                            "parentRowNumber": 230,
                                                                            "articleId": "21320630",
                                                                            "description": "21320630-01-Gewindestift",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0068,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0068,
                                                                            "singleQuantityMcf": 0.035224,
                                                                            "mcf": 0.035224,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.035224,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        },
                                                                        {
                                                                            "rowNumber": 235,
                                                                            "parentRowNumber": 230,
                                                                            "articleId": "21320630",
                                                                            "description": "21320630-01-Gewindestift",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0068,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0068,
                                                                            "singleQuantityMcf": 0.035224,
                                                                            "mcf": 0.035224,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.035224,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 236,
                                                                    "parentRowNumber": 201,
                                                                    "articleId": "47074795",
                                                                    "description": "47074795-01-Blech",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.7659,
                                                                    "childrenWeight": 0.7659,
                                                                    "totalQuantityWeight": 0.7659,
                                                                    "singleQuantityMcf": 3.967362,
                                                                    "mcf": 3.967362,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 3.967362,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 237,
                                                                            "parentRowNumber": 236,
                                                                            "articleId": "142603",
                                                                            "description": "142603-00-Edelstahlblech",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.7523000000000001,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.7523000000000001,
                                                                            "singleQuantityMcf": 3.896914,
                                                                            "mcf": 3.896914,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 3.896914,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        },
                                                                        {
                                                                            "rowNumber": 238,
                                                                            "parentRowNumber": 236,
                                                                            "articleId": "21320630",
                                                                            "description": "21320630-01-Gewindestift",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0068,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0068,
                                                                            "singleQuantityMcf": 0.035224,
                                                                            "mcf": 0.035224,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.035224,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        },
                                                                        {
                                                                            "rowNumber": 239,
                                                                            "parentRowNumber": 236,
                                                                            "articleId": "21320630",
                                                                            "description": "21320630-01-Gewindestift",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0068,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0068,
                                                                            "singleQuantityMcf": 0.035224,
                                                                            "mcf": 0.035224,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.035224,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 240,
                                                                    "parentRowNumber": 201,
                                                                    "articleId": "57555061",
                                                                    "description": "57555061-02-Gegenmutter",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0523,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0523,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 241,
                                                                    "parentRowNumber": 201,
                                                                    "articleId": "57555561",
                                                                    "description": "57555561-02-Blindstopfen",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0407,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0407,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": []
                                                                }
                                                            ]
                                                        }
                                                    ]
                                                }
                                            ]
                                        }
                                    ]
                                },
                                {
                                    "rowNumber": 242,
                                    "parentRowNumber": 1,
                                    "articleId": "33847",
                                    "description": "33847-00CA-00-Medienversorgung Elektro",
                                    "hierarchy": 3,
                                    "quantity": 1,
                                    "weight": 3.1728,
                                    "childrenWeight": 3.1728,
                                    "totalQuantityWeight": 3.1728,
                                    "singleQuantityMcf": 0,
                                    "mcf": 0,
                                    "singleQuantityLcf": 0,
                                    "lcf": 0,
                                    "totalQuantityMcf": 0,
                                    "expandable": false,
                                    "children": [
                                        {
                                            "rowNumber": 243,
                                            "parentRowNumber": 242,
                                            "articleId": "33847",
                                            "description": "33847-00CA00-01-Potentialausgleichschiene",
                                            "hierarchy": 4,
                                            "quantity": 1,
                                            "weight": 1.2744,
                                            "childrenWeight": 1.2744,
                                            "totalQuantityWeight": 1.2744,
                                            "singleQuantityMcf": 0,
                                            "mcf": 0,
                                            "singleQuantityLcf": 0,
                                            "lcf": 0,
                                            "totalQuantityMcf": 0,
                                            "expandable": false,
                                            "children": [
                                                {
                                                    "rowNumber": 244,
                                                    "parentRowNumber": 243,
                                                    "articleId": "MM0000008246",
                                                    "description": "MM0000008246-00-Potentialausgleich",
                                                    "hierarchy": 5,
                                                    "quantity": 1,
                                                    "weight": 1.2744,
                                                    "childrenWeight": 1.2744,
                                                    "totalQuantityWeight": 1.2744,
                                                    "singleQuantityMcf": 0,
                                                    "mcf": 0,
                                                    "singleQuantityLcf": 0,
                                                    "lcf": 0,
                                                    "totalQuantityMcf": 0,
                                                    "expandable": false,
                                                    "children": [
                                                        {
                                                            "rowNumber": 245,
                                                            "parentRowNumber": 244,
                                                            "articleId": "55334501",
                                                            "description": "55334501-02-Sammelschienenträger",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.3848,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.3848,
                                                            "singleQuantityMcf": 0,
                                                            "mcf": 0,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 246,
                                                            "parentRowNumber": 244,
                                                            "articleId": "55334501",
                                                            "description": "55334501-02-Sammelschienenträger",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.3848,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.3848,
                                                            "singleQuantityMcf": 0,
                                                            "mcf": 0,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 247,
                                                            "parentRowNumber": 244,
                                                            "articleId": "55335002",
                                                            "description": "55335002-01-Leiteranschlußklemme",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0247,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0247,
                                                            "singleQuantityMcf": 0,
                                                            "mcf": 0,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 248,
                                                            "parentRowNumber": 244,
                                                            "articleId": "55335002",
                                                            "description": "55335002-01-Leiteranschlußklemme",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0247,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0247,
                                                            "singleQuantityMcf": 0,
                                                            "mcf": 0,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 249,
                                                            "parentRowNumber": 244,
                                                            "articleId": "55335002",
                                                            "description": "55335002-01-Leiteranschlußklemme",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0247,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0247,
                                                            "singleQuantityMcf": 0,
                                                            "mcf": 0,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 250,
                                                            "parentRowNumber": 244,
                                                            "articleId": "55335002",
                                                            "description": "55335002-01-Leiteranschlußklemme",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0247,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0247,
                                                            "singleQuantityMcf": 0,
                                                            "mcf": 0,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 251,
                                                            "parentRowNumber": 244,
                                                            "articleId": "55335002",
                                                            "description": "55335002-01-Leiteranschlußklemme",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0247,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0247,
                                                            "singleQuantityMcf": 0,
                                                            "mcf": 0,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 252,
                                                            "parentRowNumber": 244,
                                                            "articleId": "55335002",
                                                            "description": "55335002-01-Leiteranschlußklemme",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0247,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0247,
                                                            "singleQuantityMcf": 0,
                                                            "mcf": 0,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 253,
                                                            "parentRowNumber": 244,
                                                            "articleId": "55335002",
                                                            "description": "55335002-01-Leiteranschlußklemme",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0247,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0247,
                                                            "singleQuantityMcf": 0,
                                                            "mcf": 0,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 254,
                                                            "parentRowNumber": 244,
                                                            "articleId": "55335002",
                                                            "description": "55335002-01-Leiteranschlußklemme",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0247,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0247,
                                                            "singleQuantityMcf": 0,
                                                            "mcf": 0,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 255,
                                                            "parentRowNumber": 244,
                                                            "articleId": "55335002",
                                                            "description": "55335002-01-Leiteranschlußklemme",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0247,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0247,
                                                            "singleQuantityMcf": 0,
                                                            "mcf": 0,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 256,
                                                            "parentRowNumber": 244,
                                                            "articleId": "55335002",
                                                            "description": "55335002-01-Leiteranschlußklemme",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0247,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0247,
                                                            "singleQuantityMcf": 0,
                                                            "mcf": 0,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 257,
                                                            "parentRowNumber": 244,
                                                            "articleId": "55335002",
                                                            "description": "55335002-01-Leiteranschlußklemme",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0247,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0247,
                                                            "singleQuantityMcf": 0,
                                                            "mcf": 0,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 258,
                                                            "parentRowNumber": 244,
                                                            "articleId": "55335002",
                                                            "description": "55335002-01-Leiteranschlußklemme",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0247,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0247,
                                                            "singleQuantityMcf": 0,
                                                            "mcf": 0,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 259,
                                                            "parentRowNumber": 244,
                                                            "articleId": "55335002",
                                                            "description": "55335002-01-Leiteranschlußklemme",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0247,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0247,
                                                            "singleQuantityMcf": 0,
                                                            "mcf": 0,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 260,
                                                            "parentRowNumber": 244,
                                                            "articleId": "55335002",
                                                            "description": "55335002-01-Leiteranschlußklemme",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0247,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0247,
                                                            "singleQuantityMcf": 0,
                                                            "mcf": 0,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 261,
                                                            "parentRowNumber": 244,
                                                            "articleId": "55335002",
                                                            "description": "55335002-01-Leiteranschlußklemme",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0247,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0247,
                                                            "singleQuantityMcf": 0,
                                                            "mcf": 0,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 262,
                                                            "parentRowNumber": 244,
                                                            "articleId": "55335002",
                                                            "description": "55335002-01-Leiteranschlußklemme",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0247,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0247,
                                                            "singleQuantityMcf": 0,
                                                            "mcf": 0,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 263,
                                                            "parentRowNumber": 244,
                                                            "articleId": "55335002",
                                                            "description": "55335002-01-Leiteranschlußklemme",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0247,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0247,
                                                            "singleQuantityMcf": 0,
                                                            "mcf": 0,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 264,
                                                            "parentRowNumber": 244,
                                                            "articleId": "55335002",
                                                            "description": "55335002-01-Leiteranschlußklemme",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0247,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0247,
                                                            "singleQuantityMcf": 0,
                                                            "mcf": 0,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 265,
                                                            "parentRowNumber": 244,
                                                            "articleId": "55335002",
                                                            "description": "55335002-01-Leiteranschlußklemme",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0247,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0247,
                                                            "singleQuantityMcf": 0,
                                                            "mcf": 0,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 266,
                                                            "parentRowNumber": 244,
                                                            "articleId": "55335002",
                                                            "description": "55335002-01-Leiteranschlußklemme",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0247,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0247,
                                                            "singleQuantityMcf": 0,
                                                            "mcf": 0,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 267,
                                                            "parentRowNumber": 244,
                                                            "articleId": "565348",
                                                            "description": "565348-01-Rohrkabelschuh",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0108,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0108,
                                                            "singleQuantityMcf": 0,
                                                            "mcf": 0,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0,
                                                            "expandable": false,
                                                            "children": []
                                                        }
                                                    ]
                                                }
                                            ]
                                        },
                                        {
                                            "rowNumber": 268,
                                            "parentRowNumber": 242,
                                            "articleId": "33847",
                                            "description": "33847-00CA10-00-Haupteinspeisung",
                                            "hierarchy": 4,
                                            "quantity": 1,
                                            "weight": 1.8984,
                                            "childrenWeight": 1.8984,
                                            "totalQuantityWeight": 1.8984,
                                            "singleQuantityMcf": 0,
                                            "mcf": 0,
                                            "singleQuantityLcf": 0,
                                            "lcf": 0,
                                            "totalQuantityMcf": 0,
                                            "expandable": false,
                                            "children": [
                                                {
                                                    "rowNumber": 269,
                                                    "parentRowNumber": 268,
                                                    "articleId": "MM0000005467",
                                                    "description": "MM0000005467-00-Haupteinspeisung",
                                                    "hierarchy": 5,
                                                    "quantity": 1,
                                                    "weight": 1.8984,
                                                    "childrenWeight": 1.8984,
                                                    "totalQuantityWeight": 1.8984,
                                                    "singleQuantityMcf": 0,
                                                    "mcf": 0,
                                                    "singleQuantityLcf": 0,
                                                    "lcf": 0,
                                                    "totalQuantityMcf": 0,
                                                    "expandable": false,
                                                    "children": [
                                                        {
                                                            "rowNumber": 270,
                                                            "parentRowNumber": 269,
                                                            "articleId": "53240602",
                                                            "description": "53240602-03-Leistungsschalter",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 1.6494,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 1.6494,
                                                            "singleQuantityMcf": 0,
                                                            "mcf": 0,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 271,
                                                            "parentRowNumber": 269,
                                                            "articleId": "53240671",
                                                            "description": "53240671-02-Klemmenabdeckung",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0195,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0195,
                                                            "singleQuantityMcf": 0,
                                                            "mcf": 0,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 272,
                                                            "parentRowNumber": 269,
                                                            "articleId": "53240672",
                                                            "description": "53240672-01-Klemmenabdeckung",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0,
                                                            "singleQuantityMcf": 0,
                                                            "mcf": 0,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 273,
                                                            "parentRowNumber": 269,
                                                            "articleId": "54227890",
                                                            "description": "54227890-02-Leuchtmelder",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.1181,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.1181,
                                                            "singleQuantityMcf": 0,
                                                            "mcf": 0,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 274,
                                                            "parentRowNumber": 269,
                                                            "articleId": "54227901",
                                                            "description": "54227901-03-Befestigungsadapter",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0364,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0364,
                                                            "singleQuantityMcf": 0,
                                                            "mcf": 0,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 275,
                                                            "parentRowNumber": 269,
                                                            "articleId": "54227920",
                                                            "description": "54227920-02-LED-Element",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.075,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.075,
                                                            "singleQuantityMcf": 0,
                                                            "mcf": 0,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0,
                                                            "expandable": false,
                                                            "children": []
                                                        }
                                                    ]
                                                }
                                            ]
                                        }
                                    ]
                                },
                                {
                                    "rowNumber": 276,
                                    "parentRowNumber": 1,
                                    "articleId": "33847",
                                    "description": "33847-00CF-00-Medienversorgung PNE",
                                    "hierarchy": 3,
                                    "quantity": 1,
                                    "weight": 5.3697,
                                    "childrenWeight": 5.3697,
                                    "totalQuantityWeight": 5.3697,
                                    "singleQuantityMcf": 8.4492996,
                                    "mcf": 8.4492996,
                                    "singleQuantityLcf": 0,
                                    "lcf": 0,
                                    "totalQuantityMcf": 8.4492996,
                                    "expandable": false,
                                    "children": [
                                        {
                                            "rowNumber": 277,
                                            "parentRowNumber": 276,
                                            "articleId": "33847",
                                            "description": "33847-00CF10-02-Einspeisung",
                                            "hierarchy": 4,
                                            "quantity": 1,
                                            "weight": 5.3697,
                                            "childrenWeight": 5.3697,
                                            "totalQuantityWeight": 5.3697,
                                            "singleQuantityMcf": 8.4492996,
                                            "mcf": 8.4492996,
                                            "singleQuantityLcf": 0,
                                            "lcf": 0,
                                            "totalQuantityMcf": 8.4492996,
                                            "expandable": false,
                                            "children": [
                                                {
                                                    "rowNumber": 278,
                                                    "parentRowNumber": 277,
                                                    "articleId": "MM0000005421",
                                                    "description": "MM0000005421-02-Einspeisung",
                                                    "hierarchy": 5,
                                                    "quantity": 1,
                                                    "weight": 5.3697,
                                                    "childrenWeight": 5.3697,
                                                    "totalQuantityWeight": 5.3697,
                                                    "singleQuantityMcf": 8.4492996,
                                                    "mcf": 8.4492996,
                                                    "singleQuantityLcf": 0,
                                                    "lcf": 0,
                                                    "totalQuantityMcf": 8.4492996,
                                                    "expandable": false,
                                                    "children": [
                                                        {
                                                            "rowNumber": 279,
                                                            "parentRowNumber": 278,
                                                            "articleId": "47117923",
                                                            "description": "47117923-02-Pneumatik",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 5.3697,
                                                            "childrenWeight": 5.3697,
                                                            "totalQuantityWeight": 5.3697,
                                                            "singleQuantityMcf": 8.4492996,
                                                            "mcf": 8.4492996,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 8.4492996,
                                                            "expandable": false,
                                                            "children": [
                                                                {
                                                                    "rowNumber": 280,
                                                                    "parentRowNumber": 279,
                                                                    "articleId": "20120670",
                                                                    "description": "20120670-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0184,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0184,
                                                                    "singleQuantityMcf": 0.095312,
                                                                    "mcf": 0.095312,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.095312,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 281,
                                                                    "parentRowNumber": 279,
                                                                    "articleId": "20120670",
                                                                    "description": "20120670-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0184,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0184,
                                                                    "singleQuantityMcf": 0.095312,
                                                                    "mcf": 0.095312,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.095312,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 282,
                                                                    "parentRowNumber": 279,
                                                                    "articleId": "20220412",
                                                                    "description": "20220412-00-Zylinderschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0019,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0019,
                                                                    "singleQuantityMcf": 0.009842,
                                                                    "mcf": 0.009842,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.009842,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 283,
                                                                    "parentRowNumber": 279,
                                                                    "articleId": "20220412",
                                                                    "description": "20220412-00-Zylinderschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0019,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0019,
                                                                    "singleQuantityMcf": 0.009842,
                                                                    "mcf": 0.009842,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.009842,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 284,
                                                                    "parentRowNumber": 279,
                                                                    "articleId": "20220412",
                                                                    "description": "20220412-00-Zylinderschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0019,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0019,
                                                                    "singleQuantityMcf": 0.009842,
                                                                    "mcf": 0.009842,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.009842,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 285,
                                                                    "parentRowNumber": 279,
                                                                    "articleId": "20220412",
                                                                    "description": "20220412-00-Zylinderschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0019,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0019,
                                                                    "singleQuantityMcf": 0.009842,
                                                                    "mcf": 0.009842,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.009842,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 286,
                                                                    "parentRowNumber": 279,
                                                                    "articleId": "20220640",
                                                                    "description": "20220640-00-Zylinderschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0109,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0109,
                                                                    "singleQuantityMcf": 0.056462,
                                                                    "mcf": 0.056462,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.056462,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 287,
                                                                    "parentRowNumber": 279,
                                                                    "articleId": "20220640",
                                                                    "description": "20220640-00-Zylinderschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0109,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0109,
                                                                    "singleQuantityMcf": 0.056462,
                                                                    "mcf": 0.056462,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.056462,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 288,
                                                                    "parentRowNumber": 279,
                                                                    "articleId": "20220640",
                                                                    "description": "20220640-00-Zylinderschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0109,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0109,
                                                                    "singleQuantityMcf": 0.056462,
                                                                    "mcf": 0.056462,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.056462,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 289,
                                                                    "parentRowNumber": 279,
                                                                    "articleId": "20220640",
                                                                    "description": "20220640-00-Zylinderschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0109,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0109,
                                                                    "singleQuantityMcf": 0.056462,
                                                                    "mcf": 0.056462,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.056462,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 290,
                                                                    "parentRowNumber": 279,
                                                                    "articleId": "208206",
                                                                    "description": "208206-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0009,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0009,
                                                                    "singleQuantityMcf": 0.004662,
                                                                    "mcf": 0.004662,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.004662,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 291,
                                                                    "parentRowNumber": 279,
                                                                    "articleId": "208206",
                                                                    "description": "208206-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0009,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0009,
                                                                    "singleQuantityMcf": 0.004662,
                                                                    "mcf": 0.004662,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.004662,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 292,
                                                                    "parentRowNumber": 279,
                                                                    "articleId": "208206",
                                                                    "description": "208206-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0009,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0009,
                                                                    "singleQuantityMcf": 0.004662,
                                                                    "mcf": 0.004662,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.004662,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 293,
                                                                    "parentRowNumber": 279,
                                                                    "articleId": "208206",
                                                                    "description": "208206-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0009,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0009,
                                                                    "singleQuantityMcf": 0.004662,
                                                                    "mcf": 0.004662,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.004662,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 294,
                                                                    "parentRowNumber": 279,
                                                                    "articleId": "208206",
                                                                    "description": "208206-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0009,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0009,
                                                                    "singleQuantityMcf": 0.004662,
                                                                    "mcf": 0.004662,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.004662,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 295,
                                                                    "parentRowNumber": 279,
                                                                    "articleId": "208206",
                                                                    "description": "208206-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0009,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0009,
                                                                    "singleQuantityMcf": 0.004662,
                                                                    "mcf": 0.004662,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.004662,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 296,
                                                                    "parentRowNumber": 279,
                                                                    "articleId": "208404",
                                                                    "description": "208404-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0008,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0008,
                                                                    "singleQuantityMcf": 0.004144,
                                                                    "mcf": 0.004144,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.004144,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 297,
                                                                    "parentRowNumber": 279,
                                                                    "articleId": "208404",
                                                                    "description": "208404-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0008,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0008,
                                                                    "singleQuantityMcf": 0.004144,
                                                                    "mcf": 0.004144,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.004144,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 298,
                                                                    "parentRowNumber": 279,
                                                                    "articleId": "208404",
                                                                    "description": "208404-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0008,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0008,
                                                                    "singleQuantityMcf": 0.004144,
                                                                    "mcf": 0.004144,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.004144,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 299,
                                                                    "parentRowNumber": 279,
                                                                    "articleId": "208404",
                                                                    "description": "208404-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0008,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0008,
                                                                    "singleQuantityMcf": 0.004144,
                                                                    "mcf": 0.004144,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.004144,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 300,
                                                                    "parentRowNumber": 279,
                                                                    "articleId": "249909",
                                                                    "description": "249909-00-Dichtung",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0004,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0004,
                                                                    "singleQuantityMcf": 0.000692,
                                                                    "mcf": 0.000692,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.000692,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 301,
                                                                    "parentRowNumber": 279,
                                                                    "articleId": "249909",
                                                                    "description": "249909-00-Dichtung",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0004,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0004,
                                                                    "singleQuantityMcf": 0.000692,
                                                                    "mcf": 0.000692,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.000692,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 302,
                                                                    "parentRowNumber": 279,
                                                                    "articleId": "249909",
                                                                    "description": "249909-00-Dichtung",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0004,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0004,
                                                                    "singleQuantityMcf": 0.000692,
                                                                    "mcf": 0.000692,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.000692,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 303,
                                                                    "parentRowNumber": 279,
                                                                    "articleId": "249909",
                                                                    "description": "249909-00-Dichtung",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0004,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0004,
                                                                    "singleQuantityMcf": 0.000692,
                                                                    "mcf": 0.000692,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.000692,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 304,
                                                                    "parentRowNumber": 279,
                                                                    "articleId": "249909",
                                                                    "description": "249909-00-Dichtung",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0004,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0004,
                                                                    "singleQuantityMcf": 0.000692,
                                                                    "mcf": 0.000692,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.000692,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 305,
                                                                    "parentRowNumber": 279,
                                                                    "articleId": "249909",
                                                                    "description": "249909-00-Dichtung",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0004,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0004,
                                                                    "singleQuantityMcf": 0.000692,
                                                                    "mcf": 0.000692,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.000692,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 306,
                                                                    "parentRowNumber": 279,
                                                                    "articleId": "249909",
                                                                    "description": "249909-00-Dichtung",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0004,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0004,
                                                                    "singleQuantityMcf": 0.000692,
                                                                    "mcf": 0.000692,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.000692,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 307,
                                                                    "parentRowNumber": 279,
                                                                    "articleId": "249909",
                                                                    "description": "249909-00-Dichtung",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0004,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0004,
                                                                    "singleQuantityMcf": 0.000692,
                                                                    "mcf": 0.000692,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.000692,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 308,
                                                                    "parentRowNumber": 279,
                                                                    "articleId": "249909",
                                                                    "description": "249909-00-Dichtung",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0004,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0004,
                                                                    "singleQuantityMcf": 0.000692,
                                                                    "mcf": 0.000692,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.000692,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 309,
                                                                    "parentRowNumber": 279,
                                                                    "articleId": "249909",
                                                                    "description": "249909-00-Dichtung",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0004,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0004,
                                                                    "singleQuantityMcf": 0.000692,
                                                                    "mcf": 0.000692,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.000692,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 310,
                                                                    "parentRowNumber": 279,
                                                                    "articleId": "37209505",
                                                                    "description": "37209505-00-Blindstopfen",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0161,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0161,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 311,
                                                                    "parentRowNumber": 279,
                                                                    "articleId": "37209505",
                                                                    "description": "37209505-00-Blindstopfen",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0161,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0161,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 312,
                                                                    "parentRowNumber": 279,
                                                                    "articleId": "372450",
                                                                    "description": "372450-01-Verteiler",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.1638,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.1638,
                                                                    "singleQuantityMcf": 1.9498752,
                                                                    "mcf": 1.9498752,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 1.9498752,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 313,
                                                                    "parentRowNumber": 279,
                                                                    "articleId": "372450",
                                                                    "description": "372450-01-Verteiler",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.1638,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.1638,
                                                                    "singleQuantityMcf": 1.9498752,
                                                                    "mcf": 1.9498752,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 1.9498752,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 314,
                                                                    "parentRowNumber": 279,
                                                                    "articleId": "372450",
                                                                    "description": "372450-01-Verteiler",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.1638,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.1638,
                                                                    "singleQuantityMcf": 1.9498752,
                                                                    "mcf": 1.9498752,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 1.9498752,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 315,
                                                                    "parentRowNumber": 279,
                                                                    "articleId": "37285172",
                                                                    "description": "37285172-00-Kupplungsdose",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 316,
                                                                    "parentRowNumber": 279,
                                                                    "articleId": "37288904",
                                                                    "description": "37288904-00-Steckverschraubung",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.019,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.019,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 317,
                                                                    "parentRowNumber": 279,
                                                                    "articleId": "373186263",
                                                                    "description": "373186263-00-Mehrfachverteiler",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.1344,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.1344,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 318,
                                                                    "parentRowNumber": 279,
                                                                    "articleId": "373186263",
                                                                    "description": "373186263-00-Mehrfachverteiler",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.1344,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.1344,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 319,
                                                                    "parentRowNumber": 279,
                                                                    "articleId": "373186263",
                                                                    "description": "373186263-00-Mehrfachverteiler",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.1344,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.1344,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 320,
                                                                    "parentRowNumber": 279,
                                                                    "articleId": "373193786",
                                                                    "description": "373193786-01-Buegelschloss",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.1518,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.1518,
                                                                    "singleQuantityMcf": 0.3486846,
                                                                    "mcf": 0.3486846,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.3486846,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 321,
                                                                    "parentRowNumber": 279,
                                                                    "articleId": "373526069",
                                                                    "description": "373526069-02-Anschlussplatten",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0993,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0993,
                                                                    "singleQuantityMcf": 1.1820672,
                                                                    "mcf": 1.1820672,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 1.1820672,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 322,
                                                                    "parentRowNumber": 279,
                                                                    "articleId": "373541259",
                                                                    "description": "373541259-01-Einschaltventil",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0838,
                                                                    "childrenWeight": 0.0838,
                                                                    "totalQuantityWeight": 0.0838,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 323,
                                                                            "parentRowNumber": 322,
                                                                            "articleId": "373541259_1",
                                                                            "description": "373541259_1-00-Schalldaempfer",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0838,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0838,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 324,
                                                                    "parentRowNumber": 279,
                                                                    "articleId": "3738030217",
                                                                    "description": "3738030217-00-L-Verschraubung",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0343,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0343,
                                                                    "singleQuantityMcf": 0.0787871,
                                                                    "mcf": 0.0787871,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.0787871,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 325,
                                                                    "parentRowNumber": 279,
                                                                    "articleId": "3738030217",
                                                                    "description": "3738030217-00-L-Verschraubung",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0343,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0343,
                                                                    "singleQuantityMcf": 0.0787871,
                                                                    "mcf": 0.0787871,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.0787871,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 326,
                                                                    "parentRowNumber": 279,
                                                                    "articleId": "3855218",
                                                                    "description": "3855218-01-Schlauch",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 327,
                                                                    "parentRowNumber": 279,
                                                                    "articleId": "444028L",
                                                                    "description": "444028L-04-Schlauchnippel",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 328,
                                                                            "parentRowNumber": 327,
                                                                            "articleId": "47163750",
                                                                            "description": "47163750-00-Schlauchnippel",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": [
                                                                                {
                                                                                    "rowNumber": 329,
                                                                                    "parentRowNumber": 328,
                                                                                    "articleId": "132717",
                                                                                    "description": "132717-00-Sechskantstahl",
                                                                                    "hierarchy": 9,
                                                                                    "quantity": 1,
                                                                                    "weight": 0,
                                                                                    "childrenWeight": 0,
                                                                                    "totalQuantityWeight": 0,
                                                                                    "singleQuantityMcf": 0,
                                                                                    "mcf": 0,
                                                                                    "singleQuantityLcf": 0,
                                                                                    "lcf": 0,
                                                                                    "totalQuantityMcf": 0,
                                                                                    "expandable": false,
                                                                                    "children": []
                                                                                }
                                                                            ]
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 330,
                                                                    "parentRowNumber": 279,
                                                                    "articleId": "444028L",
                                                                    "description": "444028L-04-Schlauchnippel",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 331,
                                                                            "parentRowNumber": 330,
                                                                            "articleId": "47163750",
                                                                            "description": "47163750-00-Schlauchnippel",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": [
                                                                                {
                                                                                    "rowNumber": 332,
                                                                                    "parentRowNumber": 331,
                                                                                    "articleId": "132717",
                                                                                    "description": "132717-00-Sechskantstahl",
                                                                                    "hierarchy": 9,
                                                                                    "quantity": 1,
                                                                                    "weight": 0,
                                                                                    "childrenWeight": 0,
                                                                                    "totalQuantityWeight": 0,
                                                                                    "singleQuantityMcf": 0,
                                                                                    "mcf": 0,
                                                                                    "singleQuantityLcf": 0,
                                                                                    "lcf": 0,
                                                                                    "totalQuantityMcf": 0,
                                                                                    "expandable": false,
                                                                                    "children": []
                                                                                }
                                                                            ]
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 333,
                                                                    "parentRowNumber": 279,
                                                                    "articleId": "444028L",
                                                                    "description": "444028L-04-Schlauchnippel",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 334,
                                                                            "parentRowNumber": 333,
                                                                            "articleId": "47163750",
                                                                            "description": "47163750-00-Schlauchnippel",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": [
                                                                                {
                                                                                    "rowNumber": 335,
                                                                                    "parentRowNumber": 334,
                                                                                    "articleId": "132717",
                                                                                    "description": "132717-00-Sechskantstahl",
                                                                                    "hierarchy": 9,
                                                                                    "quantity": 1,
                                                                                    "weight": 0,
                                                                                    "childrenWeight": 0,
                                                                                    "totalQuantityWeight": 0,
                                                                                    "singleQuantityMcf": 0,
                                                                                    "mcf": 0,
                                                                                    "singleQuantityLcf": 0,
                                                                                    "lcf": 0,
                                                                                    "totalQuantityMcf": 0,
                                                                                    "expandable": false,
                                                                                    "children": []
                                                                                }
                                                                            ]
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 336,
                                                                    "parentRowNumber": 279,
                                                                    "articleId": "444028L",
                                                                    "description": "444028L-04-Schlauchnippel",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 337,
                                                                            "parentRowNumber": 336,
                                                                            "articleId": "47163750",
                                                                            "description": "47163750-00-Schlauchnippel",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": [
                                                                                {
                                                                                    "rowNumber": 338,
                                                                                    "parentRowNumber": 337,
                                                                                    "articleId": "132717",
                                                                                    "description": "132717-00-Sechskantstahl",
                                                                                    "hierarchy": 9,
                                                                                    "quantity": 1,
                                                                                    "weight": 0,
                                                                                    "childrenWeight": 0,
                                                                                    "totalQuantityWeight": 0,
                                                                                    "singleQuantityMcf": 0,
                                                                                    "mcf": 0,
                                                                                    "singleQuantityLcf": 0,
                                                                                    "lcf": 0,
                                                                                    "totalQuantityMcf": 0,
                                                                                    "expandable": false,
                                                                                    "children": []
                                                                                }
                                                                            ]
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 339,
                                                                    "parentRowNumber": 279,
                                                                    "articleId": "444028L",
                                                                    "description": "444028L-04-Schlauchnippel",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 340,
                                                                            "parentRowNumber": 339,
                                                                            "articleId": "47163750",
                                                                            "description": "47163750-00-Schlauchnippel",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": [
                                                                                {
                                                                                    "rowNumber": 341,
                                                                                    "parentRowNumber": 340,
                                                                                    "articleId": "132717",
                                                                                    "description": "132717-00-Sechskantstahl",
                                                                                    "hierarchy": 9,
                                                                                    "quantity": 1,
                                                                                    "weight": 0,
                                                                                    "childrenWeight": 0,
                                                                                    "totalQuantityWeight": 0,
                                                                                    "singleQuantityMcf": 0,
                                                                                    "mcf": 0,
                                                                                    "singleQuantityLcf": 0,
                                                                                    "lcf": 0,
                                                                                    "totalQuantityMcf": 0,
                                                                                    "expandable": false,
                                                                                    "children": []
                                                                                }
                                                                            ]
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 342,
                                                                    "parentRowNumber": 279,
                                                                    "articleId": "444028L",
                                                                    "description": "444028L-04-Schlauchnippel",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 343,
                                                                            "parentRowNumber": 342,
                                                                            "articleId": "47163750",
                                                                            "description": "47163750-00-Schlauchnippel",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": [
                                                                                {
                                                                                    "rowNumber": 344,
                                                                                    "parentRowNumber": 343,
                                                                                    "articleId": "132717",
                                                                                    "description": "132717-00-Sechskantstahl",
                                                                                    "hierarchy": 9,
                                                                                    "quantity": 1,
                                                                                    "weight": 0,
                                                                                    "childrenWeight": 0,
                                                                                    "totalQuantityWeight": 0,
                                                                                    "singleQuantityMcf": 0,
                                                                                    "mcf": 0,
                                                                                    "singleQuantityLcf": 0,
                                                                                    "lcf": 0,
                                                                                    "totalQuantityMcf": 0,
                                                                                    "expandable": false,
                                                                                    "children": []
                                                                                }
                                                                            ]
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 345,
                                                                    "parentRowNumber": 279,
                                                                    "articleId": "444028L",
                                                                    "description": "444028L-04-Schlauchnippel",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 346,
                                                                            "parentRowNumber": 345,
                                                                            "articleId": "47163750",
                                                                            "description": "47163750-00-Schlauchnippel",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": [
                                                                                {
                                                                                    "rowNumber": 347,
                                                                                    "parentRowNumber": 346,
                                                                                    "articleId": "132717",
                                                                                    "description": "132717-00-Sechskantstahl",
                                                                                    "hierarchy": 9,
                                                                                    "quantity": 1,
                                                                                    "weight": 0,
                                                                                    "childrenWeight": 0,
                                                                                    "totalQuantityWeight": 0,
                                                                                    "singleQuantityMcf": 0,
                                                                                    "mcf": 0,
                                                                                    "singleQuantityLcf": 0,
                                                                                    "lcf": 0,
                                                                                    "totalQuantityMcf": 0,
                                                                                    "expandable": false,
                                                                                    "children": []
                                                                                }
                                                                            ]
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 348,
                                                                    "parentRowNumber": 279,
                                                                    "articleId": "444028L",
                                                                    "description": "444028L-04-Schlauchnippel",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 349,
                                                                            "parentRowNumber": 348,
                                                                            "articleId": "47163750",
                                                                            "description": "47163750-00-Schlauchnippel",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": [
                                                                                {
                                                                                    "rowNumber": 350,
                                                                                    "parentRowNumber": 349,
                                                                                    "articleId": "132717",
                                                                                    "description": "132717-00-Sechskantstahl",
                                                                                    "hierarchy": 9,
                                                                                    "quantity": 1,
                                                                                    "weight": 0,
                                                                                    "childrenWeight": 0,
                                                                                    "totalQuantityWeight": 0,
                                                                                    "singleQuantityMcf": 0,
                                                                                    "mcf": 0,
                                                                                    "singleQuantityLcf": 0,
                                                                                    "lcf": 0,
                                                                                    "totalQuantityMcf": 0,
                                                                                    "expandable": false,
                                                                                    "children": []
                                                                                }
                                                                            ]
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 351,
                                                                    "parentRowNumber": 279,
                                                                    "articleId": "444028L",
                                                                    "description": "444028L-04-Schlauchnippel",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 352,
                                                                            "parentRowNumber": 351,
                                                                            "articleId": "47163750",
                                                                            "description": "47163750-00-Schlauchnippel",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": [
                                                                                {
                                                                                    "rowNumber": 353,
                                                                                    "parentRowNumber": 352,
                                                                                    "articleId": "132717",
                                                                                    "description": "132717-00-Sechskantstahl",
                                                                                    "hierarchy": 9,
                                                                                    "quantity": 1,
                                                                                    "weight": 0,
                                                                                    "childrenWeight": 0,
                                                                                    "totalQuantityWeight": 0,
                                                                                    "singleQuantityMcf": 0,
                                                                                    "mcf": 0,
                                                                                    "singleQuantityLcf": 0,
                                                                                    "lcf": 0,
                                                                                    "totalQuantityMcf": 0,
                                                                                    "expandable": false,
                                                                                    "children": []
                                                                                }
                                                                            ]
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 354,
                                                                    "parentRowNumber": 279,
                                                                    "articleId": "444028L",
                                                                    "description": "444028L-04-Schlauchnippel",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 355,
                                                                            "parentRowNumber": 354,
                                                                            "articleId": "47163750",
                                                                            "description": "47163750-00-Schlauchnippel",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": [
                                                                                {
                                                                                    "rowNumber": 356,
                                                                                    "parentRowNumber": 355,
                                                                                    "articleId": "132717",
                                                                                    "description": "132717-00-Sechskantstahl",
                                                                                    "hierarchy": 9,
                                                                                    "quantity": 1,
                                                                                    "weight": 0,
                                                                                    "childrenWeight": 0,
                                                                                    "totalQuantityWeight": 0,
                                                                                    "singleQuantityMcf": 0,
                                                                                    "mcf": 0,
                                                                                    "singleQuantityLcf": 0,
                                                                                    "lcf": 0,
                                                                                    "totalQuantityMcf": 0,
                                                                                    "expandable": false,
                                                                                    "children": []
                                                                                }
                                                                            ]
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 357,
                                                                    "parentRowNumber": 279,
                                                                    "articleId": "444028L",
                                                                    "description": "444028L-04-Schlauchnippel",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 358,
                                                                            "parentRowNumber": 357,
                                                                            "articleId": "47163750",
                                                                            "description": "47163750-00-Schlauchnippel",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": [
                                                                                {
                                                                                    "rowNumber": 359,
                                                                                    "parentRowNumber": 358,
                                                                                    "articleId": "132717",
                                                                                    "description": "132717-00-Sechskantstahl",
                                                                                    "hierarchy": 9,
                                                                                    "quantity": 1,
                                                                                    "weight": 0,
                                                                                    "childrenWeight": 0,
                                                                                    "totalQuantityWeight": 0,
                                                                                    "singleQuantityMcf": 0,
                                                                                    "mcf": 0,
                                                                                    "singleQuantityLcf": 0,
                                                                                    "lcf": 0,
                                                                                    "totalQuantityMcf": 0,
                                                                                    "expandable": false,
                                                                                    "children": []
                                                                                }
                                                                            ]
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 360,
                                                                    "parentRowNumber": 279,
                                                                    "articleId": "444028L",
                                                                    "description": "444028L-04-Schlauchnippel",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 361,
                                                                            "parentRowNumber": 360,
                                                                            "articleId": "47163750",
                                                                            "description": "47163750-00-Schlauchnippel",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": [
                                                                                {
                                                                                    "rowNumber": 362,
                                                                                    "parentRowNumber": 361,
                                                                                    "articleId": "132717",
                                                                                    "description": "132717-00-Sechskantstahl",
                                                                                    "hierarchy": 9,
                                                                                    "quantity": 1,
                                                                                    "weight": 0,
                                                                                    "childrenWeight": 0,
                                                                                    "totalQuantityWeight": 0,
                                                                                    "singleQuantityMcf": 0,
                                                                                    "mcf": 0,
                                                                                    "singleQuantityLcf": 0,
                                                                                    "lcf": 0,
                                                                                    "totalQuantityMcf": 0,
                                                                                    "expandable": false,
                                                                                    "children": []
                                                                                }
                                                                            ]
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 363,
                                                                    "parentRowNumber": 279,
                                                                    "articleId": "46992767",
                                                                    "description": "46992767-01-Halter",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.039,
                                                                    "childrenWeight": 0.039,
                                                                    "totalQuantityWeight": 0.039,
                                                                    "singleQuantityMcf": 0.20202,
                                                                    "mcf": 0.20202,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.20202,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 364,
                                                                            "parentRowNumber": 363,
                                                                            "articleId": "20270345",
                                                                            "description": "20270345-00-Zylinderschraube",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.003,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.003,
                                                                            "singleQuantityMcf": 0.01554,
                                                                            "mcf": 0.01554,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.01554,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        },
                                                                        {
                                                                            "rowNumber": 365,
                                                                            "parentRowNumber": 363,
                                                                            "articleId": "373532798",
                                                                            "description": "373532798-01-Modulverbinder",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.036,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.036,
                                                                            "singleQuantityMcf": 0.18648,
                                                                            "mcf": 0.18648,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.18648,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 366,
                                                                    "parentRowNumber": 279,
                                                                    "articleId": "46992767",
                                                                    "description": "46992767-01-Halter",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.039,
                                                                    "childrenWeight": 0.039,
                                                                    "totalQuantityWeight": 0.039,
                                                                    "singleQuantityMcf": 0.20202,
                                                                    "mcf": 0.20202,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.20202,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 367,
                                                                            "parentRowNumber": 366,
                                                                            "articleId": "20270345",
                                                                            "description": "20270345-00-Zylinderschraube",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.003,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.003,
                                                                            "singleQuantityMcf": 0.01554,
                                                                            "mcf": 0.01554,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.01554,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        },
                                                                        {
                                                                            "rowNumber": 368,
                                                                            "parentRowNumber": 366,
                                                                            "articleId": "373532798",
                                                                            "description": "373532798-01-Modulverbinder",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.036,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.036,
                                                                            "singleQuantityMcf": 0.18648,
                                                                            "mcf": 0.18648,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.18648,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 369,
                                                                    "parentRowNumber": 279,
                                                                    "articleId": "52333005",
                                                                    "description": "52333005-02-Ventilstecker",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0173,
                                                                    "childrenWeight": 0.0173,
                                                                    "totalQuantityWeight": 0.0173,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 370,
                                                                            "parentRowNumber": 369,
                                                                            "articleId": "PUDY00000067",
                                                                            "description": "PUDY00000067-00-Kabel D6",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0173,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0173,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 371,
                                                                    "parentRowNumber": 279,
                                                                    "articleId": "52371003",
                                                                    "description": "52371003-02-Wartungskombination",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 3.8139,
                                                                    "childrenWeight": 3.8139,
                                                                    "totalQuantityWeight": 3.8139,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 372,
                                                                            "parentRowNumber": 371,
                                                                            "articleId": "PUDY00000401",
                                                                            "description": "PUDY00000401-00-Befestigungswinkel",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.1031,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.1031,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        },
                                                                        {
                                                                            "rowNumber": 373,
                                                                            "parentRowNumber": 371,
                                                                            "articleId": "PUDY00000401",
                                                                            "description": "PUDY00000401-00-Befestigungswinkel",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.1031,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.1031,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        },
                                                                        {
                                                                            "rowNumber": 374,
                                                                            "parentRowNumber": 371,
                                                                            "articleId": "PUDY00000402",
                                                                            "description": "PUDY00000402-00-Einsachaltventil",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.1584,
                                                                            "childrenWeight": 0.1584,
                                                                            "totalQuantityWeight": 0.1584,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": [
                                                                                {
                                                                                    "rowNumber": 375,
                                                                                    "parentRowNumber": 374,
                                                                                    "articleId": "PUDY00004287",
                                                                                    "description": "PUDY00004287-00-Einschaltventil_2",
                                                                                    "hierarchy": 9,
                                                                                    "quantity": 1,
                                                                                    "weight": 0.1584,
                                                                                    "childrenWeight": 0,
                                                                                    "totalQuantityWeight": 0.1584,
                                                                                    "singleQuantityMcf": 0,
                                                                                    "mcf": 0,
                                                                                    "singleQuantityLcf": 0,
                                                                                    "lcf": 0,
                                                                                    "totalQuantityMcf": 0,
                                                                                    "expandable": false,
                                                                                    "children": []
                                                                                }
                                                                            ]
                                                                        },
                                                                        {
                                                                            "rowNumber": 376,
                                                                            "parentRowNumber": 371,
                                                                            "articleId": "PUDY00000403",
                                                                            "description": "PUDY00000403-00-Filterregelventil",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 2.1008,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 2.1008,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        },
                                                                        {
                                                                            "rowNumber": 377,
                                                                            "parentRowNumber": 371,
                                                                            "articleId": "PUDY00000404",
                                                                            "description": "PUDY00000404-00-Abzweigmodul inkl. Druckschalter",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 1.3485,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 1.3485,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 378,
                                                                    "parentRowNumber": 279,
                                                                    "articleId": "58236610",
                                                                    "description": "58236610-02-Kabeldose",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0106,
                                                                    "childrenWeight": 0.0106,
                                                                    "totalQuantityWeight": 0.0106,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 379,
                                                                            "parentRowNumber": 378,
                                                                            "articleId": "PUDY00000066",
                                                                            "description": "PUDY00000066-00-Kabel D5",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0106,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0106,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                }
                                                            ]
                                                        }
                                                    ]
                                                }
                                            ]
                                        }
                                    ]
                                },
                                {
                                    "rowNumber": 380,
                                    "parentRowNumber": 1,
                                    "articleId": "33847",
                                    "description": "33847-00EA-00-Schaltschrank Hauptstrom A",
                                    "hierarchy": 3,
                                    "quantity": 1,
                                    "weight": 56.82320000000001,
                                    "childrenWeight": 56.82320000000001,
                                    "totalQuantityWeight": 56.82320000000001,
                                    "singleQuantityMcf": 194.397868,
                                    "mcf": 194.397868,
                                    "singleQuantityLcf": 0,
                                    "lcf": 0,
                                    "totalQuantityMcf": 194.397868,
                                    "expandable": false,
                                    "children": [
                                        {
                                            "rowNumber": 381,
                                            "parentRowNumber": 380,
                                            "articleId": "33847",
                                            "description": "33847-00EA00-01-Grundgestell",
                                            "hierarchy": 4,
                                            "quantity": 1,
                                            "weight": 50.0853,
                                            "childrenWeight": 50.0853,
                                            "totalQuantityWeight": 50.0853,
                                            "singleQuantityMcf": 191.819782,
                                            "mcf": 191.819782,
                                            "singleQuantityLcf": 0,
                                            "lcf": 0,
                                            "totalQuantityMcf": 191.819782,
                                            "expandable": false,
                                            "children": [
                                                {
                                                    "rowNumber": 382,
                                                    "parentRowNumber": 381,
                                                    "articleId": "47190909",
                                                    "description": "47190909-00-Grundgestell",
                                                    "hierarchy": 5,
                                                    "quantity": 1,
                                                    "weight": 50.0853,
                                                    "childrenWeight": 50.0853,
                                                    "totalQuantityWeight": 50.0853,
                                                    "singleQuantityMcf": 191.819782,
                                                    "mcf": 191.819782,
                                                    "singleQuantityLcf": 0,
                                                    "lcf": 0,
                                                    "totalQuantityMcf": 191.819782,
                                                    "expandable": false,
                                                    "children": [
                                                        {
                                                            "rowNumber": 383,
                                                            "parentRowNumber": 382,
                                                            "articleId": "47118709",
                                                            "description": "47118709-00-SK-Montageplatte",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 12.2137,
                                                            "childrenWeight": 12.2137,
                                                            "totalQuantityWeight": 12.2137,
                                                            "singleQuantityMcf": 63.266966,
                                                            "mcf": 63.266966,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 63.266966,
                                                            "expandable": false,
                                                            "children": [
                                                                {
                                                                    "rowNumber": 384,
                                                                    "parentRowNumber": 383,
                                                                    "articleId": "142608",
                                                                    "description": "142608-00-Edelstahlblech",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 12.2137,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 12.2137,
                                                                    "singleQuantityMcf": 63.266966,
                                                                    "mcf": 63.266966,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 63.266966,
                                                                    "expandable": false,
                                                                    "children": []
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            "rowNumber": 385,
                                                            "parentRowNumber": 382,
                                                            "articleId": "47118788",
                                                            "description": "47118788-00-SK-Montageplatte",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 6.4511,
                                                            "childrenWeight": 6.4511,
                                                            "totalQuantityWeight": 6.4511,
                                                            "singleQuantityMcf": 33.416698,
                                                            "mcf": 33.416698,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 33.416698,
                                                            "expandable": false,
                                                            "children": [
                                                                {
                                                                    "rowNumber": 386,
                                                                    "parentRowNumber": 385,
                                                                    "articleId": "142608",
                                                                    "description": "142608-00-Edelstahlblech",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 6.4511,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 6.4511,
                                                                    "singleQuantityMcf": 33.416698,
                                                                    "mcf": 33.416698,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 33.416698,
                                                                    "expandable": false,
                                                                    "children": []
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            "rowNumber": 387,
                                                            "parentRowNumber": 382,
                                                            "articleId": "47122491",
                                                            "description": "47122491-00-SK-Montageplatte",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 5.0573,
                                                            "childrenWeight": 5.0573,
                                                            "totalQuantityWeight": 5.0573,
                                                            "singleQuantityMcf": 26.196814,
                                                            "mcf": 26.196814,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 26.196814,
                                                            "expandable": false,
                                                            "children": [
                                                                {
                                                                    "rowNumber": 388,
                                                                    "parentRowNumber": 387,
                                                                    "articleId": "142608",
                                                                    "description": "142608-00-Edelstahlblech",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 5.0573,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 5.0573,
                                                                    "singleQuantityMcf": 26.196814,
                                                                    "mcf": 26.196814,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 26.196814,
                                                                    "expandable": false,
                                                                    "children": []
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            "rowNumber": 389,
                                                            "parentRowNumber": 382,
                                                            "articleId": "47147196",
                                                            "description": "47147196-01-Schaltschrank",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0114,
                                                            "childrenWeight": 0.0114,
                                                            "totalQuantityWeight": 0.0114,
                                                            "singleQuantityMcf": 0.05905200000000001,
                                                            "mcf": 0.05905200000000001,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.05905200000000001,
                                                            "expandable": false,
                                                            "children": [
                                                                {
                                                                    "rowNumber": 390,
                                                                    "parentRowNumber": 389,
                                                                    "articleId": "47194238",
                                                                    "description": "47194238-00-Schaltschrank Hauptstrom",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0114,
                                                                    "childrenWeight": 0.0114,
                                                                    "totalQuantityWeight": 0.0114,
                                                                    "singleQuantityMcf": 0.05905200000000001,
                                                                    "mcf": 0.05905200000000001,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.05905200000000001,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 391,
                                                                            "parentRowNumber": 390,
                                                                            "articleId": "21320512",
                                                                            "description": "21320512-01-Gewindestift",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0019,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0019,
                                                                            "singleQuantityMcf": 0.009842,
                                                                            "mcf": 0.009842,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.009842,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        },
                                                                        {
                                                                            "rowNumber": 392,
                                                                            "parentRowNumber": 390,
                                                                            "articleId": "21320512",
                                                                            "description": "21320512-01-Gewindestift",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0019,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0019,
                                                                            "singleQuantityMcf": 0.009842,
                                                                            "mcf": 0.009842,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.009842,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        },
                                                                        {
                                                                            "rowNumber": 393,
                                                                            "parentRowNumber": 390,
                                                                            "articleId": "21320512",
                                                                            "description": "21320512-01-Gewindestift",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0019,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0019,
                                                                            "singleQuantityMcf": 0.009842,
                                                                            "mcf": 0.009842,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.009842,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        },
                                                                        {
                                                                            "rowNumber": 394,
                                                                            "parentRowNumber": 390,
                                                                            "articleId": "21320512",
                                                                            "description": "21320512-01-Gewindestift",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0019,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0019,
                                                                            "singleQuantityMcf": 0.009842,
                                                                            "mcf": 0.009842,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.009842,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        },
                                                                        {
                                                                            "rowNumber": 395,
                                                                            "parentRowNumber": 390,
                                                                            "articleId": "21320512",
                                                                            "description": "21320512-01-Gewindestift",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0019,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0019,
                                                                            "singleQuantityMcf": 0.009842,
                                                                            "mcf": 0.009842,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.009842,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        },
                                                                        {
                                                                            "rowNumber": 396,
                                                                            "parentRowNumber": 390,
                                                                            "articleId": "21320512",
                                                                            "description": "21320512-01-Gewindestift",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0019,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0019,
                                                                            "singleQuantityMcf": 0.009842,
                                                                            "mcf": 0.009842,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.009842,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            "rowNumber": 397,
                                                            "parentRowNumber": 382,
                                                            "articleId": "47191437",
                                                            "description": "47191437-00-Montageblech",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 6.7894,
                                                            "childrenWeight": 6.7894,
                                                            "totalQuantityWeight": 6.7894,
                                                            "singleQuantityMcf": 35.169092,
                                                            "mcf": 35.169092,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 35.169092,
                                                            "expandable": false,
                                                            "children": [
                                                                {
                                                                    "rowNumber": 398,
                                                                    "parentRowNumber": 397,
                                                                    "articleId": "142608",
                                                                    "description": "142608-00-Edelstahlblech",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 6.7894,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 6.7894,
                                                                    "singleQuantityMcf": 35.169092,
                                                                    "mcf": 35.169092,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 35.169092,
                                                                    "expandable": false,
                                                                    "children": []
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            "rowNumber": 399,
                                                            "parentRowNumber": 382,
                                                            "articleId": "47192413",
                                                            "description": "47192413-00-Montageblech",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 6.3803,
                                                            "childrenWeight": 6.3803,
                                                            "totalQuantityWeight": 6.3803,
                                                            "singleQuantityMcf": 33.049954,
                                                            "mcf": 33.049954,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 33.049954,
                                                            "expandable": false,
                                                            "children": [
                                                                {
                                                                    "rowNumber": 400,
                                                                    "parentRowNumber": 399,
                                                                    "articleId": "142608",
                                                                    "description": "142608-00-Edelstahlblech",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 6.3803,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 6.3803,
                                                                    "singleQuantityMcf": 33.049954,
                                                                    "mcf": 33.049954,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 33.049954,
                                                                    "expandable": false,
                                                                    "children": []
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            "rowNumber": 401,
                                                            "parentRowNumber": 382,
                                                            "articleId": "54415301",
                                                            "description": "54415301-01-Sicherheitszuhaltung",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 2.4458,
                                                            "childrenWeight": 2.4458,
                                                            "totalQuantityWeight": 2.4458,
                                                            "singleQuantityMcf": 0,
                                                            "mcf": 0,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0,
                                                            "expandable": false,
                                                            "children": [
                                                                {
                                                                    "rowNumber": 402,
                                                                    "parentRowNumber": 401,
                                                                    "articleId": "PUDY00003083",
                                                                    "description": "PUDY00003083-01-Sicherheitszuhaltung",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 2.2393,
                                                                    "childrenWeight": 2.2393,
                                                                    "totalQuantityWeight": 2.2393,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 403,
                                                                            "parentRowNumber": 402,
                                                                            "articleId": "PUDY00003087",
                                                                            "description": "PUDY00003087-00-Sicherheitszuhaltung_2",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.2323,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.2323,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        },
                                                                        {
                                                                            "rowNumber": 404,
                                                                            "parentRowNumber": 402,
                                                                            "articleId": "PUDY00003088",
                                                                            "description": "PUDY00003088-01-Sicherheitszuhaltung_1",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 2.007,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 2.007,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 405,
                                                                    "parentRowNumber": 401,
                                                                    "articleId": "PUDY00003084",
                                                                    "description": "PUDY00003084-00-Halter",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 406,
                                                                    "parentRowNumber": 401,
                                                                    "articleId": "PUDY00003085",
                                                                    "description": "PUDY00003085-00-Montageplatte",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.1311,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.1311,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 407,
                                                                    "parentRowNumber": 401,
                                                                    "articleId": "PUDY00003086",
                                                                    "description": "PUDY00003086-01-Radiusbetätiger",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0754,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0754,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": []
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            "rowNumber": 408,
                                                            "parentRowNumber": 382,
                                                            "articleId": "54415301",
                                                            "description": "54415301-01-Sicherheitszuhaltung",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 2.4458,
                                                            "childrenWeight": 2.4458,
                                                            "totalQuantityWeight": 2.4458,
                                                            "singleQuantityMcf": 0,
                                                            "mcf": 0,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0,
                                                            "expandable": false,
                                                            "children": [
                                                                {
                                                                    "rowNumber": 409,
                                                                    "parentRowNumber": 408,
                                                                    "articleId": "PUDY00003083",
                                                                    "description": "PUDY00003083-01-Sicherheitszuhaltung",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 2.2393,
                                                                    "childrenWeight": 2.2393,
                                                                    "totalQuantityWeight": 2.2393,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 410,
                                                                            "parentRowNumber": 409,
                                                                            "articleId": "PUDY00003087",
                                                                            "description": "PUDY00003087-00-Sicherheitszuhaltung_2",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.2323,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.2323,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        },
                                                                        {
                                                                            "rowNumber": 411,
                                                                            "parentRowNumber": 409,
                                                                            "articleId": "PUDY00003088",
                                                                            "description": "PUDY00003088-01-Sicherheitszuhaltung_1",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 2.007,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 2.007,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 412,
                                                                    "parentRowNumber": 408,
                                                                    "articleId": "PUDY00003084",
                                                                    "description": "PUDY00003084-00-Halter",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 413,
                                                                    "parentRowNumber": 408,
                                                                    "articleId": "PUDY00003085",
                                                                    "description": "PUDY00003085-00-Montageplatte",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.1311,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.1311,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 414,
                                                                    "parentRowNumber": 408,
                                                                    "articleId": "PUDY00003086",
                                                                    "description": "PUDY00003086-01-Radiusbetätiger",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0754,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0754,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": []
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            "rowNumber": 415,
                                                            "parentRowNumber": 382,
                                                            "articleId": "55724140EVE001",
                                                            "description": "55724140EVE001-02-Schaltschrankleuchte",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.1094,
                                                            "childrenWeight": 0.1094,
                                                            "totalQuantityWeight": 0.1094,
                                                            "singleQuantityMcf": 0,
                                                            "mcf": 0,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0,
                                                            "expandable": false,
                                                            "children": [
                                                                {
                                                                    "rowNumber": 416,
                                                                    "parentRowNumber": 415,
                                                                    "articleId": "55724140",
                                                                    "description": "55724140-03-Schaltschrankleuchte",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.07,
                                                                    "childrenWeight": 0.07,
                                                                    "totalQuantityWeight": 0.07,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 417,
                                                                            "parentRowNumber": 416,
                                                                            "articleId": "PUDY00002281",
                                                                            "description": "PUDY00002281-00-Halteclip",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.035,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.035,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        },
                                                                        {
                                                                            "rowNumber": 418,
                                                                            "parentRowNumber": 416,
                                                                            "articleId": "PUDY00002281",
                                                                            "description": "PUDY00002281-00-Halteclip",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.035,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.035,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 419,
                                                                    "parentRowNumber": 415,
                                                                    "articleId": "55724143",
                                                                    "description": "55724143-01-Anschlusskabel",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0197,
                                                                    "childrenWeight": 0.0197,
                                                                    "totalQuantityWeight": 0.0197,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 420,
                                                                            "parentRowNumber": 419,
                                                                            "articleId": "PUDY00000075",
                                                                            "description": "PUDY00000075-00-Kabel D7",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0197,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0197,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 421,
                                                                    "parentRowNumber": 415,
                                                                    "articleId": "55724146",
                                                                    "description": "55724146-01-Steckverbinder",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0197,
                                                                    "childrenWeight": 0.0197,
                                                                    "totalQuantityWeight": 0.0197,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 422,
                                                                            "parentRowNumber": 421,
                                                                            "articleId": "PUDY00000075",
                                                                            "description": "PUDY00000075-00-Kabel D7",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0197,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0197,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            "rowNumber": 423,
                                                            "parentRowNumber": 382,
                                                            "articleId": "56651125",
                                                            "description": "56651125-04-Schlauchverschraub.",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.1589,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.1589,
                                                            "singleQuantityMcf": 0,
                                                            "mcf": 0,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 424,
                                                            "parentRowNumber": 382,
                                                            "articleId": "56651125",
                                                            "description": "56651125-04-Schlauchverschraub.",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.1589,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.1589,
                                                            "singleQuantityMcf": 0,
                                                            "mcf": 0,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 425,
                                                            "parentRowNumber": 382,
                                                            "articleId": "56651125",
                                                            "description": "56651125-04-Schlauchverschraub.",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.1589,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.1589,
                                                            "singleQuantityMcf": 0,
                                                            "mcf": 0,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 426,
                                                            "parentRowNumber": 382,
                                                            "articleId": "56651125",
                                                            "description": "56651125-04-Schlauchverschraub.",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.1589,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.1589,
                                                            "singleQuantityMcf": 0,
                                                            "mcf": 0,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 427,
                                                            "parentRowNumber": 382,
                                                            "articleId": "56651207",
                                                            "description": "56651207-02-Verschraubungshalter",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0518,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0518,
                                                            "singleQuantityMcf": 0,
                                                            "mcf": 0,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 428,
                                                            "parentRowNumber": 382,
                                                            "articleId": "56651207",
                                                            "description": "56651207-02-Verschraubungshalter",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0518,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0518,
                                                            "singleQuantityMcf": 0,
                                                            "mcf": 0,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 429,
                                                            "parentRowNumber": 382,
                                                            "articleId": "56651207",
                                                            "description": "56651207-02-Verschraubungshalter",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0518,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0518,
                                                            "singleQuantityMcf": 0,
                                                            "mcf": 0,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 430,
                                                            "parentRowNumber": 382,
                                                            "articleId": "56651207",
                                                            "description": "56651207-02-Verschraubungshalter",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0518,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0518,
                                                            "singleQuantityMcf": 0,
                                                            "mcf": 0,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 431,
                                                            "parentRowNumber": 382,
                                                            "articleId": "574020",
                                                            "description": "574020-01-Verdrahtungskanal",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 5.1725,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 5.1725,
                                                            "singleQuantityMcf": 0,
                                                            "mcf": 0,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 432,
                                                            "parentRowNumber": 382,
                                                            "articleId": "57585382",
                                                            "description": "57585382-03-Kabeldurchf.platte",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.1274,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.1274,
                                                            "singleQuantityMcf": 0.220402,
                                                            "mcf": 0.220402,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.220402,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 433,
                                                            "parentRowNumber": 382,
                                                            "articleId": "57585382",
                                                            "description": "57585382-03-Kabeldurchf.platte",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.1274,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.1274,
                                                            "singleQuantityMcf": 0.220402,
                                                            "mcf": 0.220402,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.220402,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 434,
                                                            "parentRowNumber": 382,
                                                            "articleId": "57585382",
                                                            "description": "57585382-03-Kabeldurchf.platte",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.1274,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.1274,
                                                            "singleQuantityMcf": 0.220402,
                                                            "mcf": 0.220402,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.220402,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 435,
                                                            "parentRowNumber": 382,
                                                            "articleId": "57585420",
                                                            "description": "57585420-02-Kabeldurchführplatte",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.4581,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.4581,
                                                            "singleQuantityMcf": 0,
                                                            "mcf": 0,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 436,
                                                            "parentRowNumber": 382,
                                                            "articleId": "57585420",
                                                            "description": "57585420-02-Kabeldurchführplatte",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.4581,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.4581,
                                                            "singleQuantityMcf": 0,
                                                            "mcf": 0,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 437,
                                                            "parentRowNumber": 382,
                                                            "articleId": "57585420",
                                                            "description": "57585420-02-Kabeldurchführplatte",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.4581,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.4581,
                                                            "singleQuantityMcf": 0,
                                                            "mcf": 0,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 438,
                                                            "parentRowNumber": 382,
                                                            "articleId": "57585423",
                                                            "description": "57585423-00-EMV Dichtung",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0676,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0676,
                                                            "singleQuantityMcf": 0,
                                                            "mcf": 0,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 439,
                                                            "parentRowNumber": 382,
                                                            "articleId": "57585423",
                                                            "description": "57585423-00-EMV Dichtung",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0676,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0676,
                                                            "singleQuantityMcf": 0,
                                                            "mcf": 0,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 440,
                                                            "parentRowNumber": 382,
                                                            "articleId": "57585423",
                                                            "description": "57585423-00-EMV Dichtung",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0676,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0676,
                                                            "singleQuantityMcf": 0,
                                                            "mcf": 0,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 441,
                                                            "parentRowNumber": 382,
                                                            "articleId": "PUDY00003085",
                                                            "description": "PUDY00003085-00-Montageplatte",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.1311,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.1311,
                                                            "singleQuantityMcf": 0,
                                                            "mcf": 0,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 442,
                                                            "parentRowNumber": 382,
                                                            "articleId": "PUDY00003086",
                                                            "description": "PUDY00003086-01-Radiusbetätiger",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0754,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0754,
                                                            "singleQuantityMcf": 0,
                                                            "mcf": 0,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0,
                                                            "expandable": false,
                                                            "children": []
                                                        }
                                                    ]
                                                }
                                            ]
                                        },
                                        {
                                            "rowNumber": 443,
                                            "parentRowNumber": 380,
                                            "articleId": "33847",
                                            "description": "33847-00EA10-00-Energieverteilung",
                                            "hierarchy": 4,
                                            "quantity": 1,
                                            "weight": 0,
                                            "childrenWeight": 0,
                                            "totalQuantityWeight": 0,
                                            "singleQuantityMcf": 0,
                                            "mcf": 0,
                                            "singleQuantityLcf": 0,
                                            "lcf": 0,
                                            "totalQuantityMcf": 0,
                                            "expandable": false,
                                            "children": [
                                                {
                                                    "rowNumber": 444,
                                                    "parentRowNumber": 443,
                                                    "articleId": "MM0000006482",
                                                    "description": "MM0000006482-00-Energieverteilung",
                                                    "hierarchy": 5,
                                                    "quantity": 1,
                                                    "weight": 0,
                                                    "childrenWeight": 0,
                                                    "totalQuantityWeight": 0,
                                                    "singleQuantityMcf": 0,
                                                    "mcf": 0,
                                                    "singleQuantityLcf": 0,
                                                    "lcf": 0,
                                                    "totalQuantityMcf": 0,
                                                    "expandable": false,
                                                    "children": []
                                                }
                                            ]
                                        },
                                        {
                                            "rowNumber": 445,
                                            "parentRowNumber": 380,
                                            "articleId": "33847",
                                            "description": "33847-00EA30-01-Beleuchtung",
                                            "hierarchy": 4,
                                            "quantity": 1,
                                            "weight": 6.737900000000001,
                                            "childrenWeight": 6.737900000000001,
                                            "totalQuantityWeight": 6.737900000000001,
                                            "singleQuantityMcf": 2.578086,
                                            "mcf": 2.578086,
                                            "singleQuantityLcf": 0,
                                            "lcf": 0,
                                            "totalQuantityMcf": 2.578086,
                                            "expandable": false,
                                            "children": [
                                                {
                                                    "rowNumber": 446,
                                                    "parentRowNumber": 445,
                                                    "articleId": "MM0000006484",
                                                    "description": "MM0000006484-01-Beleuchtung",
                                                    "hierarchy": 5,
                                                    "quantity": 1,
                                                    "weight": 6.737900000000001,
                                                    "childrenWeight": 6.737900000000001,
                                                    "totalQuantityWeight": 6.737900000000001,
                                                    "singleQuantityMcf": 2.578086,
                                                    "mcf": 2.578086,
                                                    "singleQuantityLcf": 0,
                                                    "lcf": 0,
                                                    "totalQuantityMcf": 2.578086,
                                                    "expandable": false,
                                                    "children": [
                                                        {
                                                            "rowNumber": 447,
                                                            "parentRowNumber": 446,
                                                            "articleId": "47120863",
                                                            "description": "47120863-00-Halter",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.4977,
                                                            "childrenWeight": 0.4977,
                                                            "totalQuantityWeight": 0.4977,
                                                            "singleQuantityMcf": 2.578086,
                                                            "mcf": 2.578086,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 2.578086,
                                                            "expandable": false,
                                                            "children": [
                                                                {
                                                                    "rowNumber": 448,
                                                                    "parentRowNumber": 447,
                                                                    "articleId": "142607",
                                                                    "description": "142607-00-Edelstahlblech",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.4977,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.4977,
                                                                    "singleQuantityMcf": 2.578086,
                                                                    "mcf": 2.578086,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 2.578086,
                                                                    "expandable": false,
                                                                    "children": []
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            "rowNumber": 449,
                                                            "parentRowNumber": 446,
                                                            "articleId": "57126080",
                                                            "description": "57126080-00-Systemleuchte LED",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 6.2129,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 6.2129,
                                                            "singleQuantityMcf": 0,
                                                            "mcf": 0,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 450,
                                                            "parentRowNumber": 446,
                                                            "articleId": "57126083",
                                                            "description": "57126083-00-Anschlussleitung 3m",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0273,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0273,
                                                            "singleQuantityMcf": 0,
                                                            "mcf": 0,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 451,
                                                            "parentRowNumber": 446,
                                                            "articleId": "57126085",
                                                            "description": "57126085-00-Stecker",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0,
                                                            "singleQuantityMcf": 0,
                                                            "mcf": 0,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0,
                                                            "expandable": false,
                                                            "children": []
                                                        }
                                                    ]
                                                }
                                            ]
                                        },
                                        {
                                            "rowNumber": 452,
                                            "parentRowNumber": 380,
                                            "articleId": "33847",
                                            "description": "33847-00EA40-00-Kühlung",
                                            "hierarchy": 4,
                                            "quantity": 1,
                                            "weight": 0,
                                            "childrenWeight": 0,
                                            "totalQuantityWeight": 0,
                                            "singleQuantityMcf": 0,
                                            "mcf": 0,
                                            "singleQuantityLcf": 0,
                                            "lcf": 0,
                                            "totalQuantityMcf": 0,
                                            "expandable": false,
                                            "children": [
                                                {
                                                    "rowNumber": 453,
                                                    "parentRowNumber": 452,
                                                    "articleId": "MM0000006475",
                                                    "description": "MM0000006475-00-Kühlung",
                                                    "hierarchy": 5,
                                                    "quantity": 1,
                                                    "weight": 0,
                                                    "childrenWeight": 0,
                                                    "totalQuantityWeight": 0,
                                                    "singleQuantityMcf": 0,
                                                    "mcf": 0,
                                                    "singleQuantityLcf": 0,
                                                    "lcf": 0,
                                                    "totalQuantityMcf": 0,
                                                    "expandable": false,
                                                    "children": [
                                                        {
                                                            "rowNumber": 454,
                                                            "parentRowNumber": 453,
                                                            "articleId": "51526105",
                                                            "description": "51526105-00-Filterlüfter Plus",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0,
                                                            "singleQuantityMcf": 0,
                                                            "mcf": 0,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 455,
                                                            "parentRowNumber": 453,
                                                            "articleId": "51526106",
                                                            "description": "51526106-00-Filterlüfter",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0,
                                                            "singleQuantityMcf": 0,
                                                            "mcf": 0,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0,
                                                            "expandable": false,
                                                            "children": []
                                                        }
                                                    ]
                                                }
                                            ]
                                        }
                                    ]
                                },
                                {
                                    "rowNumber": 456,
                                    "parentRowNumber": 1,
                                    "articleId": "33847",
                                    "description": "33847-00EF-00-Schaltschrank Steuerstrom A",
                                    "hierarchy": 3,
                                    "quantity": 1,
                                    "weight": 97.3978,
                                    "childrenWeight": 97.3978,
                                    "totalQuantityWeight": 97.3978,
                                    "singleQuantityMcf": 160.947514,
                                    "mcf": 160.947514,
                                    "singleQuantityLcf": 0,
                                    "lcf": 0,
                                    "totalQuantityMcf": 160.947514,
                                    "expandable": false,
                                    "children": [
                                        {
                                            "rowNumber": 457,
                                            "parentRowNumber": 456,
                                            "articleId": "33847",
                                            "description": "33847-00EF00-00-Grundgestell",
                                            "hierarchy": 4,
                                            "quantity": 1,
                                            "weight": 90.6599,
                                            "childrenWeight": 90.6599,
                                            "totalQuantityWeight": 90.6599,
                                            "singleQuantityMcf": 158.369428,
                                            "mcf": 158.369428,
                                            "singleQuantityLcf": 0,
                                            "lcf": 0,
                                            "totalQuantityMcf": 158.369428,
                                            "expandable": false,
                                            "children": [
                                                {
                                                    "rowNumber": 458,
                                                    "parentRowNumber": 457,
                                                    "articleId": "MM0000005435",
                                                    "description": "MM0000005435-00-Grungestell",
                                                    "hierarchy": 5,
                                                    "quantity": 1,
                                                    "weight": 90.6599,
                                                    "childrenWeight": 90.6599,
                                                    "totalQuantityWeight": 90.6599,
                                                    "singleQuantityMcf": 158.369428,
                                                    "mcf": 158.369428,
                                                    "singleQuantityLcf": 0,
                                                    "lcf": 0,
                                                    "totalQuantityMcf": 158.369428,
                                                    "expandable": false,
                                                    "children": [
                                                        {
                                                            "rowNumber": 459,
                                                            "parentRowNumber": 458,
                                                            "articleId": "46795904",
                                                            "description": "46795904-00-Schlauchhalter",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0595,
                                                            "childrenWeight": 0.0595,
                                                            "totalQuantityWeight": 0.0595,
                                                            "singleQuantityMcf": 0.30821,
                                                            "mcf": 0.30821,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.30821,
                                                            "expandable": false,
                                                            "children": [
                                                                {
                                                                    "rowNumber": 460,
                                                                    "parentRowNumber": 459,
                                                                    "articleId": "142607",
                                                                    "description": "142607-00-Edelstahlblech",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0595,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0595,
                                                                    "singleQuantityMcf": 0.30821,
                                                                    "mcf": 0.30821,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.30821,
                                                                    "expandable": false,
                                                                    "children": []
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            "rowNumber": 461,
                                                            "parentRowNumber": 458,
                                                            "articleId": "46795904",
                                                            "description": "46795904-00-Schlauchhalter",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0595,
                                                            "childrenWeight": 0.0595,
                                                            "totalQuantityWeight": 0.0595,
                                                            "singleQuantityMcf": 0.30821,
                                                            "mcf": 0.30821,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.30821,
                                                            "expandable": false,
                                                            "children": [
                                                                {
                                                                    "rowNumber": 462,
                                                                    "parentRowNumber": 461,
                                                                    "articleId": "142607",
                                                                    "description": "142607-00-Edelstahlblech",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0595,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0595,
                                                                    "singleQuantityMcf": 0.30821,
                                                                    "mcf": 0.30821,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.30821,
                                                                    "expandable": false,
                                                                    "children": []
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            "rowNumber": 463,
                                                            "parentRowNumber": 458,
                                                            "articleId": "46795904",
                                                            "description": "46795904-00-Schlauchhalter",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0595,
                                                            "childrenWeight": 0.0595,
                                                            "totalQuantityWeight": 0.0595,
                                                            "singleQuantityMcf": 0.30821,
                                                            "mcf": 0.30821,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.30821,
                                                            "expandable": false,
                                                            "children": [
                                                                {
                                                                    "rowNumber": 464,
                                                                    "parentRowNumber": 463,
                                                                    "articleId": "142607",
                                                                    "description": "142607-00-Edelstahlblech",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0595,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0595,
                                                                    "singleQuantityMcf": 0.30821,
                                                                    "mcf": 0.30821,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.30821,
                                                                    "expandable": false,
                                                                    "children": []
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            "rowNumber": 465,
                                                            "parentRowNumber": 458,
                                                            "articleId": "46795904",
                                                            "description": "46795904-00-Schlauchhalter",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0595,
                                                            "childrenWeight": 0.0595,
                                                            "totalQuantityWeight": 0.0595,
                                                            "singleQuantityMcf": 0.30821,
                                                            "mcf": 0.30821,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.30821,
                                                            "expandable": false,
                                                            "children": [
                                                                {
                                                                    "rowNumber": 466,
                                                                    "parentRowNumber": 465,
                                                                    "articleId": "142607",
                                                                    "description": "142607-00-Edelstahlblech",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0595,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0595,
                                                                    "singleQuantityMcf": 0.30821,
                                                                    "mcf": 0.30821,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.30821,
                                                                    "expandable": false,
                                                                    "children": []
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            "rowNumber": 467,
                                                            "parentRowNumber": 458,
                                                            "articleId": "47119240",
                                                            "description": "47119240-00-SK-Montageplatte",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 8.9026,
                                                            "childrenWeight": 8.9026,
                                                            "totalQuantityWeight": 8.9026,
                                                            "singleQuantityMcf": 46.11546799999999,
                                                            "mcf": 46.11546799999999,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 46.11546799999999,
                                                            "expandable": false,
                                                            "children": [
                                                                {
                                                                    "rowNumber": 468,
                                                                    "parentRowNumber": 467,
                                                                    "articleId": "142608",
                                                                    "description": "142608-00-Edelstahlblech",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 8.9026,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 8.9026,
                                                                    "singleQuantityMcf": 46.11546799999999,
                                                                    "mcf": 46.11546799999999,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 46.11546799999999,
                                                                    "expandable": false,
                                                                    "children": []
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            "rowNumber": 469,
                                                            "parentRowNumber": 458,
                                                            "articleId": "47122479",
                                                            "description": "47122479-00-SK-Montageplatte",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 7.6343,
                                                            "childrenWeight": 7.6343,
                                                            "totalQuantityWeight": 7.6343,
                                                            "singleQuantityMcf": 39.545674,
                                                            "mcf": 39.545674,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 39.545674,
                                                            "expandable": false,
                                                            "children": [
                                                                {
                                                                    "rowNumber": 470,
                                                                    "parentRowNumber": 469,
                                                                    "articleId": "142608",
                                                                    "description": "142608-00-Edelstahlblech",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 7.6343,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 7.6343,
                                                                    "singleQuantityMcf": 39.545674,
                                                                    "mcf": 39.545674,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 39.545674,
                                                                    "expandable": false,
                                                                    "children": []
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            "rowNumber": 471,
                                                            "parentRowNumber": 458,
                                                            "articleId": "47145819",
                                                            "description": "47145819-00-SK-Montageplatte",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 13.7558,
                                                            "childrenWeight": 13.7558,
                                                            "totalQuantityWeight": 13.7558,
                                                            "singleQuantityMcf": 71.255044,
                                                            "mcf": 71.255044,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 71.255044,
                                                            "expandable": false,
                                                            "children": [
                                                                {
                                                                    "rowNumber": 472,
                                                                    "parentRowNumber": 471,
                                                                    "articleId": "142608",
                                                                    "description": "142608-00-Edelstahlblech",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 13.7558,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 13.7558,
                                                                    "singleQuantityMcf": 71.255044,
                                                                    "mcf": 71.255044,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 71.255044,
                                                                    "expandable": false,
                                                                    "children": []
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            "rowNumber": 473,
                                                            "parentRowNumber": 458,
                                                            "articleId": "47147198",
                                                            "description": "47147198-00-Schaltschrank",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 53.1758,
                                                            "childrenWeight": 53.1758,
                                                            "totalQuantityWeight": 53.1758,
                                                            "singleQuantityMcf": 0,
                                                            "mcf": 0,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0,
                                                            "expandable": false,
                                                            "children": [
                                                                {
                                                                    "rowNumber": 474,
                                                                    "parentRowNumber": 473,
                                                                    "articleId": "47117619",
                                                                    "description": "47117619-01-Schaltschrank Steuerstrom",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 53.1758,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 53.1758,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": []
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            "rowNumber": 475,
                                                            "parentRowNumber": 458,
                                                            "articleId": "56651135",
                                                            "description": "56651135-06-Schlauchverschraub.",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.2549,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.2549,
                                                            "singleQuantityMcf": 0,
                                                            "mcf": 0,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 476,
                                                            "parentRowNumber": 458,
                                                            "articleId": "56651135",
                                                            "description": "56651135-06-Schlauchverschraub.",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.2549,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.2549,
                                                            "singleQuantityMcf": 0,
                                                            "mcf": 0,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 477,
                                                            "parentRowNumber": 458,
                                                            "articleId": "56651135",
                                                            "description": "56651135-06-Schlauchverschraub.",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.2549,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.2549,
                                                            "singleQuantityMcf": 0,
                                                            "mcf": 0,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 478,
                                                            "parentRowNumber": 458,
                                                            "articleId": "56651135",
                                                            "description": "56651135-06-Schlauchverschraub.",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.2549,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.2549,
                                                            "singleQuantityMcf": 0,
                                                            "mcf": 0,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 479,
                                                            "parentRowNumber": 458,
                                                            "articleId": "57585382",
                                                            "description": "57585382-03-Kabeldurchf.platte",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.1274,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.1274,
                                                            "singleQuantityMcf": 0.220402,
                                                            "mcf": 0.220402,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.220402,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 480,
                                                            "parentRowNumber": 458,
                                                            "articleId": "57585384",
                                                            "description": "57585384-03-Kabeldurchf.platte",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.7979,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.7979,
                                                            "singleQuantityMcf": 0,
                                                            "mcf": 0,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 481,
                                                            "parentRowNumber": 458,
                                                            "articleId": "57585384",
                                                            "description": "57585384-03-Kabeldurchf.platte",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.7979,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.7979,
                                                            "singleQuantityMcf": 0,
                                                            "mcf": 0,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 482,
                                                            "parentRowNumber": 458,
                                                            "articleId": "57585420",
                                                            "description": "57585420-02-Kabeldurchführplatte",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.4581,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.4581,
                                                            "singleQuantityMcf": 0,
                                                            "mcf": 0,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 483,
                                                            "parentRowNumber": 458,
                                                            "articleId": "57585420",
                                                            "description": "57585420-02-Kabeldurchführplatte",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.4581,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.4581,
                                                            "singleQuantityMcf": 0,
                                                            "mcf": 0,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 484,
                                                            "parentRowNumber": 458,
                                                            "articleId": "57585420",
                                                            "description": "57585420-02-Kabeldurchführplatte",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.4581,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.4581,
                                                            "singleQuantityMcf": 0,
                                                            "mcf": 0,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 485,
                                                            "parentRowNumber": 458,
                                                            "articleId": "57585423",
                                                            "description": "57585423-00-EMV Dichtung",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0676,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0676,
                                                            "singleQuantityMcf": 0,
                                                            "mcf": 0,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 486,
                                                            "parentRowNumber": 458,
                                                            "articleId": "57585423",
                                                            "description": "57585423-00-EMV Dichtung",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0676,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0676,
                                                            "singleQuantityMcf": 0,
                                                            "mcf": 0,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 487,
                                                            "parentRowNumber": 458,
                                                            "articleId": "57585423",
                                                            "description": "57585423-00-EMV Dichtung",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0676,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0676,
                                                            "singleQuantityMcf": 0,
                                                            "mcf": 0,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 488,
                                                            "parentRowNumber": 458,
                                                            "articleId": "57585510",
                                                            "description": "57585510-00-Kabeldurchf.leiste",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 2.6335,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 2.6335,
                                                            "singleQuantityMcf": 0,
                                                            "mcf": 0,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0,
                                                            "expandable": false,
                                                            "children": []
                                                        }
                                                    ]
                                                }
                                            ]
                                        },
                                        {
                                            "rowNumber": 489,
                                            "parentRowNumber": 456,
                                            "articleId": "33847",
                                            "description": "33847-00EF10-00-Enegieverteilung",
                                            "hierarchy": 4,
                                            "quantity": 1,
                                            "weight": 0,
                                            "childrenWeight": 0,
                                            "totalQuantityWeight": 0,
                                            "singleQuantityMcf": 0,
                                            "mcf": 0,
                                            "singleQuantityLcf": 0,
                                            "lcf": 0,
                                            "totalQuantityMcf": 0,
                                            "expandable": false,
                                            "children": [
                                                {
                                                    "rowNumber": 490,
                                                    "parentRowNumber": 489,
                                                    "articleId": "MM0000006483",
                                                    "description": "MM0000006483-00-Energieverteilung",
                                                    "hierarchy": 5,
                                                    "quantity": 1,
                                                    "weight": 0,
                                                    "childrenWeight": 0,
                                                    "totalQuantityWeight": 0,
                                                    "singleQuantityMcf": 0,
                                                    "mcf": 0,
                                                    "singleQuantityLcf": 0,
                                                    "lcf": 0,
                                                    "totalQuantityMcf": 0,
                                                    "expandable": false,
                                                    "children": []
                                                }
                                            ]
                                        },
                                        {
                                            "rowNumber": 491,
                                            "parentRowNumber": 456,
                                            "articleId": "33847",
                                            "description": "33847-00EF30-01-Beleuchtung",
                                            "hierarchy": 4,
                                            "quantity": 1,
                                            "weight": 6.737900000000001,
                                            "childrenWeight": 6.737900000000001,
                                            "totalQuantityWeight": 6.737900000000001,
                                            "singleQuantityMcf": 2.578086,
                                            "mcf": 2.578086,
                                            "singleQuantityLcf": 0,
                                            "lcf": 0,
                                            "totalQuantityMcf": 2.578086,
                                            "expandable": false,
                                            "children": [
                                                {
                                                    "rowNumber": 492,
                                                    "parentRowNumber": 491,
                                                    "articleId": "MM0000007040",
                                                    "description": "MM0000007040-00-Beleuchtung",
                                                    "hierarchy": 5,
                                                    "quantity": 1,
                                                    "weight": 6.737900000000001,
                                                    "childrenWeight": 6.737900000000001,
                                                    "totalQuantityWeight": 6.737900000000001,
                                                    "singleQuantityMcf": 2.578086,
                                                    "mcf": 2.578086,
                                                    "singleQuantityLcf": 0,
                                                    "lcf": 0,
                                                    "totalQuantityMcf": 2.578086,
                                                    "expandable": false,
                                                    "children": [
                                                        {
                                                            "rowNumber": 493,
                                                            "parentRowNumber": 492,
                                                            "articleId": "47120863",
                                                            "description": "47120863-00-Halter",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.4977,
                                                            "childrenWeight": 0.4977,
                                                            "totalQuantityWeight": 0.4977,
                                                            "singleQuantityMcf": 2.578086,
                                                            "mcf": 2.578086,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 2.578086,
                                                            "expandable": false,
                                                            "children": [
                                                                {
                                                                    "rowNumber": 494,
                                                                    "parentRowNumber": 493,
                                                                    "articleId": "142607",
                                                                    "description": "142607-00-Edelstahlblech",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.4977,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.4977,
                                                                    "singleQuantityMcf": 2.578086,
                                                                    "mcf": 2.578086,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 2.578086,
                                                                    "expandable": false,
                                                                    "children": []
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            "rowNumber": 495,
                                                            "parentRowNumber": 492,
                                                            "articleId": "57126080",
                                                            "description": "57126080-00-Systemleuchte LED",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 6.2129,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 6.2129,
                                                            "singleQuantityMcf": 0,
                                                            "mcf": 0,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 496,
                                                            "parentRowNumber": 492,
                                                            "articleId": "57126083",
                                                            "description": "57126083-00-Anschlussleitung 3m",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0273,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0273,
                                                            "singleQuantityMcf": 0,
                                                            "mcf": 0,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0,
                                                            "expandable": false,
                                                            "children": []
                                                        }
                                                    ]
                                                }
                                            ]
                                        },
                                        {
                                            "rowNumber": 497,
                                            "parentRowNumber": 456,
                                            "articleId": "33847",
                                            "description": "33847-00EF40-00-Kühlung",
                                            "hierarchy": 4,
                                            "quantity": 1,
                                            "weight": 0,
                                            "childrenWeight": 0,
                                            "totalQuantityWeight": 0,
                                            "singleQuantityMcf": 0,
                                            "mcf": 0,
                                            "singleQuantityLcf": 0,
                                            "lcf": 0,
                                            "totalQuantityMcf": 0,
                                            "expandable": false,
                                            "children": [
                                                {
                                                    "rowNumber": 498,
                                                    "parentRowNumber": 497,
                                                    "articleId": "MM0000006485",
                                                    "description": "MM0000006485-00-Kühlung",
                                                    "hierarchy": 5,
                                                    "quantity": 1,
                                                    "weight": 0,
                                                    "childrenWeight": 0,
                                                    "totalQuantityWeight": 0,
                                                    "singleQuantityMcf": 0,
                                                    "mcf": 0,
                                                    "singleQuantityLcf": 0,
                                                    "lcf": 0,
                                                    "totalQuantityMcf": 0,
                                                    "expandable": false,
                                                    "children": [
                                                        {
                                                            "rowNumber": 499,
                                                            "parentRowNumber": 498,
                                                            "articleId": "51526105",
                                                            "description": "51526105-00-Filterlüfter Plus",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0,
                                                            "singleQuantityMcf": 0,
                                                            "mcf": 0,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 500,
                                                            "parentRowNumber": 498,
                                                            "articleId": "51526106",
                                                            "description": "51526106-00-Filterlüfter",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0,
                                                            "singleQuantityMcf": 0,
                                                            "mcf": 0,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0,
                                                            "expandable": false,
                                                            "children": []
                                                        }
                                                    ]
                                                }
                                            ]
                                        }
                                    ]
                                },
                                {
                                    "rowNumber": 501,
                                    "parentRowNumber": 1,
                                    "articleId": "33847",
                                    "description": "33847-00GA-00-Hauptsteuerung",
                                    "hierarchy": 3,
                                    "quantity": 1,
                                    "weight": 1.8597,
                                    "childrenWeight": 1.8597,
                                    "totalQuantityWeight": 1.8597,
                                    "singleQuantityMcf": 0,
                                    "mcf": 0,
                                    "singleQuantityLcf": 0,
                                    "lcf": 0,
                                    "totalQuantityMcf": 0,
                                    "expandable": false,
                                    "children": [
                                        {
                                            "rowNumber": 502,
                                            "parentRowNumber": 501,
                                            "articleId": "33847",
                                            "description": "33847-00GA70-00-Hauptsteuerung",
                                            "hierarchy": 4,
                                            "quantity": 1,
                                            "weight": 1.8597,
                                            "childrenWeight": 1.8597,
                                            "totalQuantityWeight": 1.8597,
                                            "singleQuantityMcf": 0,
                                            "mcf": 0,
                                            "singleQuantityLcf": 0,
                                            "lcf": 0,
                                            "totalQuantityMcf": 0,
                                            "expandable": false,
                                            "children": [
                                                {
                                                    "rowNumber": 503,
                                                    "parentRowNumber": 502,
                                                    "articleId": "MM0000007070",
                                                    "description": "MM0000007070-00-DC Achsverstärker 1",
                                                    "hierarchy": 5,
                                                    "quantity": 1,
                                                    "weight": 1.8597,
                                                    "childrenWeight": 1.8597,
                                                    "totalQuantityWeight": 1.8597,
                                                    "singleQuantityMcf": 0,
                                                    "mcf": 0,
                                                    "singleQuantityLcf": 0,
                                                    "lcf": 0,
                                                    "totalQuantityMcf": 0,
                                                    "expandable": false,
                                                    "children": [
                                                        {
                                                            "rowNumber": 504,
                                                            "parentRowNumber": 503,
                                                            "articleId": "51160926",
                                                            "description": "51160926-00-ECDriveS Steuermodul",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 1.8597,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 1.8597,
                                                            "singleQuantityMcf": 0,
                                                            "mcf": 0,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0,
                                                            "expandable": false,
                                                            "children": []
                                                        }
                                                    ]
                                                }
                                            ]
                                        }
                                    ]
                                },
                                {
                                    "rowNumber": 505,
                                    "parentRowNumber": 1,
                                    "articleId": "33847",
                                    "description": "33847-00KA-00-Mehrkanalige Kontroll- und Steuereinrichtungen",
                                    "hierarchy": 3,
                                    "quantity": 1,
                                    "weight": 14.091700000000005,
                                    "childrenWeight": 14.091700000000005,
                                    "totalQuantityWeight": 14.091700000000005,
                                    "singleQuantityMcf": 3.4875124,
                                    "mcf": 3.4875124,
                                    "singleQuantityLcf": 0,
                                    "lcf": 0,
                                    "totalQuantityMcf": 3.4875124,
                                    "expandable": false,
                                    "children": [
                                        {
                                            "rowNumber": 506,
                                            "parentRowNumber": 505,
                                            "articleId": "33847",
                                            "description": "33847-00KA10-01-Ventilinsel 1",
                                            "hierarchy": 4,
                                            "quantity": 1,
                                            "weight": 10.417400000000006,
                                            "childrenWeight": 10.417400000000006,
                                            "totalQuantityWeight": 10.417400000000006,
                                            "singleQuantityMcf": 2.5036074000000004,
                                            "mcf": 2.5036074000000004,
                                            "singleQuantityLcf": 0,
                                            "lcf": 0,
                                            "totalQuantityMcf": 2.5036074000000004,
                                            "expandable": false,
                                            "children": [
                                                {
                                                    "rowNumber": 507,
                                                    "parentRowNumber": 506,
                                                    "articleId": "MM0000005441",
                                                    "description": "MM0000005441-01-Ventilinsel 1",
                                                    "hierarchy": 5,
                                                    "quantity": 1,
                                                    "weight": 10.417400000000006,
                                                    "childrenWeight": 10.417400000000006,
                                                    "totalQuantityWeight": 10.417400000000006,
                                                    "singleQuantityMcf": 2.5036074000000004,
                                                    "mcf": 2.5036074000000004,
                                                    "singleQuantityLcf": 0,
                                                    "lcf": 0,
                                                    "totalQuantityMcf": 2.5036074000000004,
                                                    "expandable": false,
                                                    "children": [
                                                        {
                                                            "rowNumber": 508,
                                                            "parentRowNumber": 507,
                                                            "articleId": "20120520",
                                                            "description": "20120520-00-Sechskantschraube",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0045,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0045,
                                                            "singleQuantityMcf": 0.02331,
                                                            "mcf": 0.02331,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.02331,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 509,
                                                            "parentRowNumber": 507,
                                                            "articleId": "20120520",
                                                            "description": "20120520-00-Sechskantschraube",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0045,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0045,
                                                            "singleQuantityMcf": 0.02331,
                                                            "mcf": 0.02331,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.02331,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 510,
                                                            "parentRowNumber": 507,
                                                            "articleId": "20270412",
                                                            "description": "20270412-01-Zylinderschraube",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0022,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0022,
                                                            "singleQuantityMcf": 0.011396,
                                                            "mcf": 0.011396,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.011396,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 511,
                                                            "parentRowNumber": 507,
                                                            "articleId": "20270412",
                                                            "description": "20270412-01-Zylinderschraube",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0022,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0022,
                                                            "singleQuantityMcf": 0.011396,
                                                            "mcf": 0.011396,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.011396,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 512,
                                                            "parentRowNumber": 507,
                                                            "articleId": "20270412",
                                                            "description": "20270412-01-Zylinderschraube",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0022,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0022,
                                                            "singleQuantityMcf": 0.011396,
                                                            "mcf": 0.011396,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.011396,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 513,
                                                            "parentRowNumber": 507,
                                                            "articleId": "20270412",
                                                            "description": "20270412-01-Zylinderschraube",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0022,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0022,
                                                            "singleQuantityMcf": 0.011396,
                                                            "mcf": 0.011396,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.011396,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 514,
                                                            "parentRowNumber": 507,
                                                            "articleId": "208205",
                                                            "description": "208205-00-Scheibe",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0004,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0004,
                                                            "singleQuantityMcf": 0.002072,
                                                            "mcf": 0.002072,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.002072,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 515,
                                                            "parentRowNumber": 507,
                                                            "articleId": "208205",
                                                            "description": "208205-00-Scheibe",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0004,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0004,
                                                            "singleQuantityMcf": 0.002072,
                                                            "mcf": 0.002072,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.002072,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 516,
                                                            "parentRowNumber": 507,
                                                            "articleId": "208404",
                                                            "description": "208404-00-Scheibe",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0008,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0008,
                                                            "singleQuantityMcf": 0.004144,
                                                            "mcf": 0.004144,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.004144,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 517,
                                                            "parentRowNumber": 507,
                                                            "articleId": "208404",
                                                            "description": "208404-00-Scheibe",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0008,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0008,
                                                            "singleQuantityMcf": 0.004144,
                                                            "mcf": 0.004144,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.004144,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 518,
                                                            "parentRowNumber": 507,
                                                            "articleId": "208404",
                                                            "description": "208404-00-Scheibe",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0008,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0008,
                                                            "singleQuantityMcf": 0.004144,
                                                            "mcf": 0.004144,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.004144,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 519,
                                                            "parentRowNumber": 507,
                                                            "articleId": "208404",
                                                            "description": "208404-00-Scheibe",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0008,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0008,
                                                            "singleQuantityMcf": 0.004144,
                                                            "mcf": 0.004144,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.004144,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 520,
                                                            "parentRowNumber": 507,
                                                            "articleId": "372080",
                                                            "description": "372080-00-Schalldaempfer",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0335,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0335,
                                                            "singleQuantityMcf": 0,
                                                            "mcf": 0,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 521,
                                                            "parentRowNumber": 507,
                                                            "articleId": "372080",
                                                            "description": "372080-00-Schalldaempfer",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0335,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0335,
                                                            "singleQuantityMcf": 0,
                                                            "mcf": 0,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 522,
                                                            "parentRowNumber": 507,
                                                            "articleId": "372450",
                                                            "description": "372450-01-Verteiler",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.1638,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.1638,
                                                            "singleQuantityMcf": 1.9498752,
                                                            "mcf": 1.9498752,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 1.9498752,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 523,
                                                            "parentRowNumber": 507,
                                                            "articleId": "372835",
                                                            "description": "372835-01-Manometer",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.2844,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.2844,
                                                            "singleQuantityMcf": 0,
                                                            "mcf": 0,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 524,
                                                            "parentRowNumber": 507,
                                                            "articleId": "37288905",
                                                            "description": "37288905-00-Steckverschraubung",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0195,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0195,
                                                            "singleQuantityMcf": 0,
                                                            "mcf": 0,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 525,
                                                            "parentRowNumber": 507,
                                                            "articleId": "37288905",
                                                            "description": "37288905-00-Steckverschraubung",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0195,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0195,
                                                            "singleQuantityMcf": 0,
                                                            "mcf": 0,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 526,
                                                            "parentRowNumber": 507,
                                                            "articleId": "37288921",
                                                            "description": "37288921-02-L-Steckverschraubung",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0218,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0218,
                                                            "singleQuantityMcf": 0,
                                                            "mcf": 0,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 527,
                                                            "parentRowNumber": 507,
                                                            "articleId": "37288924",
                                                            "description": "37288924-02-L-Steckverschraubung",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0372,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0372,
                                                            "singleQuantityMcf": 0,
                                                            "mcf": 0,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 528,
                                                            "parentRowNumber": 507,
                                                            "articleId": "37288924",
                                                            "description": "37288924-02-L-Steckverschraubung",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0372,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0372,
                                                            "singleQuantityMcf": 0,
                                                            "mcf": 0,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 529,
                                                            "parentRowNumber": 507,
                                                            "articleId": "37288924",
                                                            "description": "37288924-02-L-Steckverschraubung",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0372,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0372,
                                                            "singleQuantityMcf": 0,
                                                            "mcf": 0,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 530,
                                                            "parentRowNumber": 507,
                                                            "articleId": "37288924",
                                                            "description": "37288924-02-L-Steckverschraubung",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0372,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0372,
                                                            "singleQuantityMcf": 0,
                                                            "mcf": 0,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 531,
                                                            "parentRowNumber": 507,
                                                            "articleId": "37288974",
                                                            "description": "37288974-00-Y-Steckverschraubung",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.086,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.086,
                                                            "singleQuantityMcf": 0,
                                                            "mcf": 0,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 532,
                                                            "parentRowNumber": 507,
                                                            "articleId": "3731205862",
                                                            "description": "3731205862-00-Schalldaempfer",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0526,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0526,
                                                            "singleQuantityMcf": 0.1208222,
                                                            "mcf": 0.1208222,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.1208222,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 533,
                                                            "parentRowNumber": 507,
                                                            "articleId": "3731205862",
                                                            "description": "3731205862-00-Schalldaempfer",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0526,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0526,
                                                            "singleQuantityMcf": 0.1208222,
                                                            "mcf": 0.1208222,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.1208222,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 534,
                                                            "parentRowNumber": 507,
                                                            "articleId": "373159503",
                                                            "description": "373159503-00-Haltewinkel",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0361,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0361,
                                                            "singleQuantityMcf": 0.0618032,
                                                            "mcf": 0.0618032,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.0618032,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 535,
                                                            "parentRowNumber": 507,
                                                            "articleId": "373162834",
                                                            "description": "373162834-00-Druck-Regelventil",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 1.3117,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 1.3117,
                                                            "singleQuantityMcf": 0,
                                                            "mcf": 0,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 536,
                                                            "parentRowNumber": 507,
                                                            "articleId": "3738030312",
                                                            "description": "3738030312-00-Reduziernippel",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0299,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0299,
                                                            "singleQuantityMcf": 0.0686803,
                                                            "mcf": 0.0686803,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.0686803,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 537,
                                                            "parentRowNumber": 507,
                                                            "articleId": "3738030312",
                                                            "description": "3738030312-00-Reduziernippel",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0299,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0299,
                                                            "singleQuantityMcf": 0.0686803,
                                                            "mcf": 0.0686803,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.0686803,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 538,
                                                            "parentRowNumber": 507,
                                                            "articleId": "3855218",
                                                            "description": "3855218-01-Schlauch",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0,
                                                            "singleQuantityMcf": 0,
                                                            "mcf": 0,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 539,
                                                            "parentRowNumber": 507,
                                                            "articleId": "444028L",
                                                            "description": "444028L-04-Schlauchnippel",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0,
                                                            "singleQuantityMcf": 0,
                                                            "mcf": 0,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0,
                                                            "expandable": false,
                                                            "children": [
                                                                {
                                                                    "rowNumber": 540,
                                                                    "parentRowNumber": 539,
                                                                    "articleId": "47163750",
                                                                    "description": "47163750-00-Schlauchnippel",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 541,
                                                                            "parentRowNumber": 540,
                                                                            "articleId": "132717",
                                                                            "description": "132717-00-Sechskantstahl",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            "rowNumber": 542,
                                                            "parentRowNumber": 507,
                                                            "articleId": "444028L",
                                                            "description": "444028L-04-Schlauchnippel",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0,
                                                            "singleQuantityMcf": 0,
                                                            "mcf": 0,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0,
                                                            "expandable": false,
                                                            "children": [
                                                                {
                                                                    "rowNumber": 543,
                                                                    "parentRowNumber": 542,
                                                                    "articleId": "47163750",
                                                                    "description": "47163750-00-Schlauchnippel",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 544,
                                                                            "parentRowNumber": 543,
                                                                            "articleId": "132717",
                                                                            "description": "132717-00-Sechskantstahl",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            "rowNumber": 545,
                                                            "parentRowNumber": 507,
                                                            "articleId": "444028L",
                                                            "description": "444028L-04-Schlauchnippel",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0,
                                                            "singleQuantityMcf": 0,
                                                            "mcf": 0,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0,
                                                            "expandable": false,
                                                            "children": [
                                                                {
                                                                    "rowNumber": 546,
                                                                    "parentRowNumber": 545,
                                                                    "articleId": "47163750",
                                                                    "description": "47163750-00-Schlauchnippel",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 547,
                                                                            "parentRowNumber": 546,
                                                                            "articleId": "132717",
                                                                            "description": "132717-00-Sechskantstahl",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            "rowNumber": 548,
                                                            "parentRowNumber": 507,
                                                            "articleId": "58236154",
                                                            "description": "58236154-02-Kabeldose",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0106,
                                                            "childrenWeight": 0.0106,
                                                            "totalQuantityWeight": 0.0106,
                                                            "singleQuantityMcf": 0,
                                                            "mcf": 0,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0,
                                                            "expandable": false,
                                                            "children": [
                                                                {
                                                                    "rowNumber": 549,
                                                                    "parentRowNumber": 548,
                                                                    "articleId": "PUDY00000066",
                                                                    "description": "PUDY00000066-00-Kabel D5",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0106,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0106,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": []
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            "rowNumber": 550,
                                                            "parentRowNumber": 507,
                                                            "articleId": "58706154",
                                                            "description": "58706154-00-Ventilkopf CPV14 IO-Link",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 1.9217,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 1.9217,
                                                            "singleQuantityMcf": 0,
                                                            "mcf": 0,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 551,
                                                            "parentRowNumber": 507,
                                                            "articleId": "58706241",
                                                            "description": "58706241-01-Ventilblock",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 6.1397,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 6.1397,
                                                            "singleQuantityMcf": 0,
                                                            "mcf": 0,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0,
                                                            "expandable": false,
                                                            "children": []
                                                        }
                                                    ]
                                                }
                                            ]
                                        },
                                        {
                                            "rowNumber": 552,
                                            "parentRowNumber": 505,
                                            "articleId": "33847",
                                            "description": "33847-00KA40-00-Sensor/Aktor Hub 1",
                                            "hierarchy": 4,
                                            "quantity": 1,
                                            "weight": 2.984499999999999,
                                            "childrenWeight": 2.984499999999999,
                                            "totalQuantityWeight": 2.984499999999999,
                                            "singleQuantityMcf": 0.19166,
                                            "mcf": 0.19166,
                                            "singleQuantityLcf": 0,
                                            "lcf": 0,
                                            "totalQuantityMcf": 0.19166,
                                            "expandable": false,
                                            "children": [
                                                {
                                                    "rowNumber": 553,
                                                    "parentRowNumber": 552,
                                                    "articleId": "MM0000007073",
                                                    "description": "MM0000007073-00-Sensor/Aktor Hub 1",
                                                    "hierarchy": 5,
                                                    "quantity": 1,
                                                    "weight": 2.984499999999999,
                                                    "childrenWeight": 2.984499999999999,
                                                    "totalQuantityWeight": 2.984499999999999,
                                                    "singleQuantityMcf": 0.19166,
                                                    "mcf": 0.19166,
                                                    "singleQuantityLcf": 0,
                                                    "lcf": 0,
                                                    "totalQuantityMcf": 0.19166,
                                                    "expandable": false,
                                                    "children": [
                                                        {
                                                            "rowNumber": 554,
                                                            "parentRowNumber": 553,
                                                            "articleId": "20220430",
                                                            "description": "20220430-00-Zylinderschraube",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0037,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0037,
                                                            "singleQuantityMcf": 0.019166,
                                                            "mcf": 0.019166,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.019166,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 555,
                                                            "parentRowNumber": 553,
                                                            "articleId": "20220430",
                                                            "description": "20220430-00-Zylinderschraube",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0037,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0037,
                                                            "singleQuantityMcf": 0.019166,
                                                            "mcf": 0.019166,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.019166,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 556,
                                                            "parentRowNumber": 553,
                                                            "articleId": "20220430",
                                                            "description": "20220430-00-Zylinderschraube",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0037,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0037,
                                                            "singleQuantityMcf": 0.019166,
                                                            "mcf": 0.019166,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.019166,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 557,
                                                            "parentRowNumber": 553,
                                                            "articleId": "20220430",
                                                            "description": "20220430-00-Zylinderschraube",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0037,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0037,
                                                            "singleQuantityMcf": 0.019166,
                                                            "mcf": 0.019166,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.019166,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 558,
                                                            "parentRowNumber": 553,
                                                            "articleId": "20220430",
                                                            "description": "20220430-00-Zylinderschraube",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0037,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0037,
                                                            "singleQuantityMcf": 0.019166,
                                                            "mcf": 0.019166,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.019166,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 559,
                                                            "parentRowNumber": 553,
                                                            "articleId": "20220430",
                                                            "description": "20220430-00-Zylinderschraube",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0037,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0037,
                                                            "singleQuantityMcf": 0.019166,
                                                            "mcf": 0.019166,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.019166,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 560,
                                                            "parentRowNumber": 553,
                                                            "articleId": "20220430",
                                                            "description": "20220430-00-Zylinderschraube",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0037,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0037,
                                                            "singleQuantityMcf": 0.019166,
                                                            "mcf": 0.019166,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.019166,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 561,
                                                            "parentRowNumber": 553,
                                                            "articleId": "20220430",
                                                            "description": "20220430-00-Zylinderschraube",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0037,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0037,
                                                            "singleQuantityMcf": 0.019166,
                                                            "mcf": 0.019166,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.019166,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 562,
                                                            "parentRowNumber": 553,
                                                            "articleId": "20220430",
                                                            "description": "20220430-00-Zylinderschraube",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0037,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0037,
                                                            "singleQuantityMcf": 0.019166,
                                                            "mcf": 0.019166,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.019166,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 563,
                                                            "parentRowNumber": 553,
                                                            "articleId": "20220430",
                                                            "description": "20220430-00-Zylinderschraube",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0037,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0037,
                                                            "singleQuantityMcf": 0.019166,
                                                            "mcf": 0.019166,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.019166,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 564,
                                                            "parentRowNumber": 553,
                                                            "articleId": "58236123",
                                                            "description": "58236123-02-Kabeldose",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0173,
                                                            "childrenWeight": 0.0173,
                                                            "totalQuantityWeight": 0.0173,
                                                            "singleQuantityMcf": 0,
                                                            "mcf": 0,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0,
                                                            "expandable": false,
                                                            "children": [
                                                                {
                                                                    "rowNumber": 565,
                                                                    "parentRowNumber": 564,
                                                                    "articleId": "PUDY00000067",
                                                                    "description": "PUDY00000067-00-Kabel D6",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0173,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0173,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": []
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            "rowNumber": 566,
                                                            "parentRowNumber": 553,
                                                            "articleId": "58236123",
                                                            "description": "58236123-02-Kabeldose",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0173,
                                                            "childrenWeight": 0.0173,
                                                            "totalQuantityWeight": 0.0173,
                                                            "singleQuantityMcf": 0,
                                                            "mcf": 0,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0,
                                                            "expandable": false,
                                                            "children": [
                                                                {
                                                                    "rowNumber": 567,
                                                                    "parentRowNumber": 566,
                                                                    "articleId": "PUDY00000067",
                                                                    "description": "PUDY00000067-00-Kabel D6",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0173,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0173,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": []
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            "rowNumber": 568,
                                                            "parentRowNumber": 553,
                                                            "articleId": "58236123",
                                                            "description": "58236123-02-Kabeldose",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0173,
                                                            "childrenWeight": 0.0173,
                                                            "totalQuantityWeight": 0.0173,
                                                            "singleQuantityMcf": 0,
                                                            "mcf": 0,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0,
                                                            "expandable": false,
                                                            "children": [
                                                                {
                                                                    "rowNumber": 569,
                                                                    "parentRowNumber": 568,
                                                                    "articleId": "PUDY00000067",
                                                                    "description": "PUDY00000067-00-Kabel D6",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0173,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0173,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": []
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            "rowNumber": 570,
                                                            "parentRowNumber": 553,
                                                            "articleId": "58236123",
                                                            "description": "58236123-02-Kabeldose",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0173,
                                                            "childrenWeight": 0.0173,
                                                            "totalQuantityWeight": 0.0173,
                                                            "singleQuantityMcf": 0,
                                                            "mcf": 0,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0,
                                                            "expandable": false,
                                                            "children": [
                                                                {
                                                                    "rowNumber": 571,
                                                                    "parentRowNumber": 570,
                                                                    "articleId": "PUDY00000067",
                                                                    "description": "PUDY00000067-00-Kabel D6",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0173,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0173,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": []
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            "rowNumber": 572,
                                                            "parentRowNumber": 553,
                                                            "articleId": "58236123",
                                                            "description": "58236123-02-Kabeldose",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0173,
                                                            "childrenWeight": 0.0173,
                                                            "totalQuantityWeight": 0.0173,
                                                            "singleQuantityMcf": 0,
                                                            "mcf": 0,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0,
                                                            "expandable": false,
                                                            "children": [
                                                                {
                                                                    "rowNumber": 573,
                                                                    "parentRowNumber": 572,
                                                                    "articleId": "PUDY00000067",
                                                                    "description": "PUDY00000067-00-Kabel D6",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0173,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0173,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": []
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            "rowNumber": 574,
                                                            "parentRowNumber": 553,
                                                            "articleId": "58702102",
                                                            "description": "58702102-00-IO-Link-Sensor-/Aktorhub",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.5722,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.5722,
                                                            "singleQuantityMcf": 0,
                                                            "mcf": 0,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 575,
                                                            "parentRowNumber": 553,
                                                            "articleId": "58702102",
                                                            "description": "58702102-00-IO-Link-Sensor-/Aktorhub",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.5722,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.5722,
                                                            "singleQuantityMcf": 0,
                                                            "mcf": 0,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 576,
                                                            "parentRowNumber": 553,
                                                            "articleId": "58702102",
                                                            "description": "58702102-00-IO-Link-Sensor-/Aktorhub",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.5722,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.5722,
                                                            "singleQuantityMcf": 0,
                                                            "mcf": 0,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 577,
                                                            "parentRowNumber": 553,
                                                            "articleId": "58702102",
                                                            "description": "58702102-00-IO-Link-Sensor-/Aktorhub",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.5722,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.5722,
                                                            "singleQuantityMcf": 0,
                                                            "mcf": 0,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 578,
                                                            "parentRowNumber": 553,
                                                            "articleId": "58702102",
                                                            "description": "58702102-00-IO-Link-Sensor-/Aktorhub",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.5722,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.5722,
                                                            "singleQuantityMcf": 0,
                                                            "mcf": 0,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0,
                                                            "expandable": false,
                                                            "children": []
                                                        }
                                                    ]
                                                }
                                            ]
                                        },
                                        {
                                            "rowNumber": 579,
                                            "parentRowNumber": 505,
                                            "articleId": "33847",
                                            "description": "33847-00KA50-01-Nockenschaltwerk",
                                            "hierarchy": 4,
                                            "quantity": 1,
                                            "weight": 0.6898,
                                            "childrenWeight": 0.6898,
                                            "totalQuantityWeight": 0.6898,
                                            "singleQuantityMcf": 0.7922449999999998,
                                            "mcf": 0.7922449999999998,
                                            "singleQuantityLcf": 0,
                                            "lcf": 0,
                                            "totalQuantityMcf": 0.7922449999999998,
                                            "expandable": false,
                                            "children": [
                                                {
                                                    "rowNumber": 580,
                                                    "parentRowNumber": 579,
                                                    "articleId": "MM0000005423",
                                                    "description": "MM0000005423-01-Nockenschaltwerk",
                                                    "hierarchy": 5,
                                                    "quantity": 1,
                                                    "weight": 0.6898,
                                                    "childrenWeight": 0.6898,
                                                    "totalQuantityWeight": 0.6898,
                                                    "singleQuantityMcf": 0.7922449999999998,
                                                    "mcf": 0.7922449999999998,
                                                    "singleQuantityLcf": 0,
                                                    "lcf": 0,
                                                    "totalQuantityMcf": 0.7922449999999998,
                                                    "expandable": false,
                                                    "children": [
                                                        {
                                                            "rowNumber": 581,
                                                            "parentRowNumber": 580,
                                                            "articleId": "20120540",
                                                            "description": "20120540-00-Sechskantschraube",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0077,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0077,
                                                            "singleQuantityMcf": 0.039886,
                                                            "mcf": 0.039886,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.039886,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 582,
                                                            "parentRowNumber": 580,
                                                            "articleId": "20120820",
                                                            "description": "20120820-00-Sechskantschraube",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0141,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0141,
                                                            "singleQuantityMcf": 0.07303799999999999,
                                                            "mcf": 0.07303799999999999,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.07303799999999999,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 583,
                                                            "parentRowNumber": 580,
                                                            "articleId": "20120820",
                                                            "description": "20120820-00-Sechskantschraube",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0141,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0141,
                                                            "singleQuantityMcf": 0.07303799999999999,
                                                            "mcf": 0.07303799999999999,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.07303799999999999,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 584,
                                                            "parentRowNumber": 580,
                                                            "articleId": "20720508",
                                                            "description": "20720508-00-Gewindestift",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0011,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0011,
                                                            "singleQuantityMcf": 0.005698,
                                                            "mcf": 0.005698,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.005698,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 585,
                                                            "parentRowNumber": 580,
                                                            "articleId": "208205",
                                                            "description": "208205-00-Scheibe",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0004,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0004,
                                                            "singleQuantityMcf": 0.002072,
                                                            "mcf": 0.002072,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.002072,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 586,
                                                            "parentRowNumber": 580,
                                                            "articleId": "208208",
                                                            "description": "208208-00-Scheibe",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0017,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0017,
                                                            "singleQuantityMcf": 0.008806,
                                                            "mcf": 0.008806,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.008806,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 587,
                                                            "parentRowNumber": 580,
                                                            "articleId": "208208",
                                                            "description": "208208-00-Scheibe",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0017,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0017,
                                                            "singleQuantityMcf": 0.008806,
                                                            "mcf": 0.008806,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.008806,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 588,
                                                            "parentRowNumber": 580,
                                                            "articleId": "46279864",
                                                            "description": "46279864-00-Distanzbolzen",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.1192,
                                                            "childrenWeight": 0.1192,
                                                            "totalQuantityWeight": 0.1192,
                                                            "singleQuantityMcf": 0.11771,
                                                            "mcf": 0.11771,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.11771,
                                                            "expandable": false,
                                                            "children": [
                                                                {
                                                                    "rowNumber": 589,
                                                                    "parentRowNumber": 588,
                                                                    "articleId": "132217",
                                                                    "description": "132217-00-Sechskantstahl",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.1192,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.1192,
                                                                    "singleQuantityMcf": 0.11771,
                                                                    "mcf": 0.11771,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.11771,
                                                                    "expandable": false,
                                                                    "children": []
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            "rowNumber": 590,
                                                            "parentRowNumber": 580,
                                                            "articleId": "46279864",
                                                            "description": "46279864-00-Distanzbolzen",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.1192,
                                                            "childrenWeight": 0.1192,
                                                            "totalQuantityWeight": 0.1192,
                                                            "singleQuantityMcf": 0.11771,
                                                            "mcf": 0.11771,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.11771,
                                                            "expandable": false,
                                                            "children": [
                                                                {
                                                                    "rowNumber": 591,
                                                                    "parentRowNumber": 590,
                                                                    "articleId": "132217",
                                                                    "description": "132217-00-Sechskantstahl",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.1192,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.1192,
                                                                    "singleQuantityMcf": 0.11771,
                                                                    "mcf": 0.11771,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.11771,
                                                                    "expandable": false,
                                                                    "children": []
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            "rowNumber": 592,
                                                            "parentRowNumber": 580,
                                                            "articleId": "46279878",
                                                            "description": "46279878-01-Lagerbock",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.1518,
                                                            "childrenWeight": 0.1518,
                                                            "totalQuantityWeight": 0.1518,
                                                            "singleQuantityMcf": 0,
                                                            "mcf": 0,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0,
                                                            "expandable": false,
                                                            "children": [
                                                                {
                                                                    "rowNumber": 593,
                                                                    "parentRowNumber": 592,
                                                                    "articleId": "141713",
                                                                    "description": "141713-00-Platte",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.1518,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.1518,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": []
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            "rowNumber": 594,
                                                            "parentRowNumber": 580,
                                                            "articleId": "46279879",
                                                            "description": "46279879-00-Stirnrad",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.1997,
                                                            "childrenWeight": 0.1997,
                                                            "totalQuantityWeight": 0.1997,
                                                            "singleQuantityMcf": 0.345481,
                                                            "mcf": 0.345481,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.345481,
                                                            "expandable": false,
                                                            "children": [
                                                                {
                                                                    "rowNumber": 595,
                                                                    "parentRowNumber": 594,
                                                                    "articleId": "161307030",
                                                                    "description": "161307030-00-Rundstab",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.1997,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.1997,
                                                                    "singleQuantityMcf": 0.345481,
                                                                    "mcf": 0.345481,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.345481,
                                                                    "expandable": false,
                                                                    "children": []
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            "rowNumber": 596,
                                                            "parentRowNumber": 580,
                                                            "articleId": "54599505",
                                                            "description": "54599505-03-Geberstecker",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0591,
                                                            "childrenWeight": 0.0591,
                                                            "totalQuantityWeight": 0.0591,
                                                            "singleQuantityMcf": 0,
                                                            "mcf": 0,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0,
                                                            "expandable": false,
                                                            "children": [
                                                                {
                                                                    "rowNumber": 597,
                                                                    "parentRowNumber": 596,
                                                                    "articleId": "PUDY00000069",
                                                                    "description": "PUDY00000069-00-Kabel D10",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0591,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0591,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": []
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            "rowNumber": 598,
                                                            "parentRowNumber": 580,
                                                            "articleId": "58262056",
                                                            "description": "58262056-02-Absolutwertgeber",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0,
                                                            "singleQuantityMcf": 0,
                                                            "mcf": 0,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0,
                                                            "expandable": false,
                                                            "children": []
                                                        }
                                                    ]
                                                }
                                            ]
                                        }
                                    ]
                                },
                                {
                                    "rowNumber": 599,
                                    "parentRowNumber": 1,
                                    "articleId": "33847",
                                    "description": "33847-00MA-00-HMI A",
                                    "hierarchy": 3,
                                    "quantity": 1,
                                    "weight": 34.054299999999984,
                                    "childrenWeight": 34.054299999999984,
                                    "totalQuantityWeight": 34.054299999999984,
                                    "singleQuantityMcf": 147.00939765800004,
                                    "mcf": 147.00939765800004,
                                    "singleQuantityLcf": 0,
                                    "lcf": 0,
                                    "totalQuantityMcf": 147.00939765800004,
                                    "expandable": false,
                                    "children": [
                                        {
                                            "rowNumber": 600,
                                            "parentRowNumber": 599,
                                            "articleId": "33847",
                                            "description": "33847-00MA00-01-Halterung",
                                            "hierarchy": 4,
                                            "quantity": 1,
                                            "weight": 27.611099999999983,
                                            "childrenWeight": 27.611099999999983,
                                            "totalQuantityWeight": 27.611099999999983,
                                            "singleQuantityMcf": 144.39639765800004,
                                            "mcf": 144.39639765800004,
                                            "singleQuantityLcf": 0,
                                            "lcf": 0,
                                            "totalQuantityMcf": 144.39639765800004,
                                            "expandable": false,
                                            "children": [
                                                {
                                                    "rowNumber": 601,
                                                    "parentRowNumber": 600,
                                                    "articleId": "MM0000004855",
                                                    "description": "MM0000004855-01-Halterung",
                                                    "hierarchy": 5,
                                                    "quantity": 1,
                                                    "weight": 27.611099999999983,
                                                    "childrenWeight": 27.611099999999983,
                                                    "totalQuantityWeight": 27.611099999999983,
                                                    "singleQuantityMcf": 144.39639765800004,
                                                    "mcf": 144.39639765800004,
                                                    "singleQuantityLcf": 0,
                                                    "lcf": 0,
                                                    "totalQuantityMcf": 144.39639765800004,
                                                    "expandable": false,
                                                    "children": [
                                                        {
                                                            "rowNumber": 602,
                                                            "parentRowNumber": 601,
                                                            "articleId": "46613206",
                                                            "description": "46613206-03-Bedienterminal",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 5.881099999999998,
                                                            "childrenWeight": 5.881099999999998,
                                                            "totalQuantityWeight": 5.881099999999998,
                                                            "singleQuantityMcf": 30.147528,
                                                            "mcf": 30.147528,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 30.147528,
                                                            "expandable": false,
                                                            "children": [
                                                                {
                                                                    "rowNumber": 603,
                                                                    "parentRowNumber": 602,
                                                                    "articleId": "20120520",
                                                                    "description": "20120520-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0045,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0045,
                                                                    "singleQuantityMcf": 0.02331,
                                                                    "mcf": 0.02331,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.02331,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 604,
                                                                    "parentRowNumber": 602,
                                                                    "articleId": "20120520",
                                                                    "description": "20120520-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0045,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0045,
                                                                    "singleQuantityMcf": 0.02331,
                                                                    "mcf": 0.02331,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.02331,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 605,
                                                                    "parentRowNumber": 602,
                                                                    "articleId": "20220512",
                                                                    "description": "20220512-00-Zylinderschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0031,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0031,
                                                                    "singleQuantityMcf": 0.016058,
                                                                    "mcf": 0.016058,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.016058,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 606,
                                                                    "parentRowNumber": 602,
                                                                    "articleId": "20220512",
                                                                    "description": "20220512-00-Zylinderschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0031,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0031,
                                                                    "singleQuantityMcf": 0.016058,
                                                                    "mcf": 0.016058,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.016058,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 607,
                                                                    "parentRowNumber": 602,
                                                                    "articleId": "20220512",
                                                                    "description": "20220512-00-Zylinderschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0031,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0031,
                                                                    "singleQuantityMcf": 0.016058,
                                                                    "mcf": 0.016058,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.016058,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 608,
                                                                    "parentRowNumber": 602,
                                                                    "articleId": "20220512",
                                                                    "description": "20220512-00-Zylinderschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0031,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0031,
                                                                    "singleQuantityMcf": 0.016058,
                                                                    "mcf": 0.016058,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.016058,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 609,
                                                                    "parentRowNumber": 602,
                                                                    "articleId": "205206",
                                                                    "description": "205206-00-Sechskantmutter",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0026,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0026,
                                                                    "singleQuantityMcf": 0.013468,
                                                                    "mcf": 0.013468,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.013468,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 610,
                                                                    "parentRowNumber": 602,
                                                                    "articleId": "205206",
                                                                    "description": "205206-00-Sechskantmutter",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0026,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0026,
                                                                    "singleQuantityMcf": 0.013468,
                                                                    "mcf": 0.013468,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.013468,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 611,
                                                                    "parentRowNumber": 602,
                                                                    "articleId": "205206",
                                                                    "description": "205206-00-Sechskantmutter",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0026,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0026,
                                                                    "singleQuantityMcf": 0.013468,
                                                                    "mcf": 0.013468,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.013468,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 612,
                                                                    "parentRowNumber": 602,
                                                                    "articleId": "205206",
                                                                    "description": "205206-00-Sechskantmutter",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0026,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0026,
                                                                    "singleQuantityMcf": 0.013468,
                                                                    "mcf": 0.013468,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.013468,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 613,
                                                                    "parentRowNumber": 602,
                                                                    "articleId": "205206",
                                                                    "description": "205206-00-Sechskantmutter",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0026,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0026,
                                                                    "singleQuantityMcf": 0.013468,
                                                                    "mcf": 0.013468,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.013468,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 614,
                                                                    "parentRowNumber": 602,
                                                                    "articleId": "205206",
                                                                    "description": "205206-00-Sechskantmutter",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0026,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0026,
                                                                    "singleQuantityMcf": 0.013468,
                                                                    "mcf": 0.013468,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.013468,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 615,
                                                                    "parentRowNumber": 602,
                                                                    "articleId": "208204",
                                                                    "description": "208204-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0003,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0003,
                                                                    "singleQuantityMcf": 0.0015539999999999998,
                                                                    "mcf": 0.0015539999999999998,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.0015539999999999998,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 616,
                                                                    "parentRowNumber": 602,
                                                                    "articleId": "208204",
                                                                    "description": "208204-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0003,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0003,
                                                                    "singleQuantityMcf": 0.0015539999999999998,
                                                                    "mcf": 0.0015539999999999998,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.0015539999999999998,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 617,
                                                                    "parentRowNumber": 602,
                                                                    "articleId": "208206",
                                                                    "description": "208206-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0009,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0009,
                                                                    "singleQuantityMcf": 0.004662,
                                                                    "mcf": 0.004662,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.004662,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 618,
                                                                    "parentRowNumber": 602,
                                                                    "articleId": "208206",
                                                                    "description": "208206-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0009,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0009,
                                                                    "singleQuantityMcf": 0.004662,
                                                                    "mcf": 0.004662,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.004662,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 619,
                                                                    "parentRowNumber": 602,
                                                                    "articleId": "208206",
                                                                    "description": "208206-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0009,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0009,
                                                                    "singleQuantityMcf": 0.004662,
                                                                    "mcf": 0.004662,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.004662,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 620,
                                                                    "parentRowNumber": 602,
                                                                    "articleId": "208206",
                                                                    "description": "208206-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0009,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0009,
                                                                    "singleQuantityMcf": 0.004662,
                                                                    "mcf": 0.004662,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.004662,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 621,
                                                                    "parentRowNumber": 602,
                                                                    "articleId": "208206",
                                                                    "description": "208206-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0009,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0009,
                                                                    "singleQuantityMcf": 0.004662,
                                                                    "mcf": 0.004662,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.004662,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 622,
                                                                    "parentRowNumber": 602,
                                                                    "articleId": "208206",
                                                                    "description": "208206-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0009,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0009,
                                                                    "singleQuantityMcf": 0.004662,
                                                                    "mcf": 0.004662,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.004662,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 623,
                                                                    "parentRowNumber": 602,
                                                                    "articleId": "46507251",
                                                                    "description": "46507251-00-Flansch",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.251,
                                                                    "childrenWeight": 0.251,
                                                                    "totalQuantityWeight": 0.251,
                                                                    "singleQuantityMcf": 1.30018,
                                                                    "mcf": 1.30018,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 1.30018,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 624,
                                                                            "parentRowNumber": 623,
                                                                            "articleId": "1260090",
                                                                            "description": "1260090-00-Rundstahl",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.251,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.251,
                                                                            "singleQuantityMcf": 1.30018,
                                                                            "mcf": 1.30018,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 1.30018,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 625,
                                                                    "parentRowNumber": 602,
                                                                    "articleId": "46507256",
                                                                    "description": "46507256-00-Anschlag",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.1108,
                                                                    "childrenWeight": 0.1108,
                                                                    "totalQuantityWeight": 0.1108,
                                                                    "singleQuantityMcf": 0.5739439999999999,
                                                                    "mcf": 0.5739439999999999,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.5739439999999999,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 626,
                                                                            "parentRowNumber": 625,
                                                                            "articleId": "142613",
                                                                            "description": "142613-00-Edelstahlblech",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.1108,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.1108,
                                                                            "singleQuantityMcf": 0.5739439999999999,
                                                                            "mcf": 0.5739439999999999,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.5739439999999999,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 627,
                                                                    "parentRowNumber": 602,
                                                                    "articleId": "46507256",
                                                                    "description": "46507256-00-Anschlag",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.1108,
                                                                    "childrenWeight": 0.1108,
                                                                    "totalQuantityWeight": 0.1108,
                                                                    "singleQuantityMcf": 0.5739439999999999,
                                                                    "mcf": 0.5739439999999999,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.5739439999999999,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 628,
                                                                            "parentRowNumber": 627,
                                                                            "articleId": "142613",
                                                                            "description": "142613-00-Edelstahlblech",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.1108,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.1108,
                                                                            "singleQuantityMcf": 0.5739439999999999,
                                                                            "mcf": 0.5739439999999999,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.5739439999999999,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 629,
                                                                    "parentRowNumber": 602,
                                                                    "articleId": "46613179",
                                                                    "description": "46613179-03-Bedienterminal",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 5.365499999999998,
                                                                    "childrenWeight": 5.365499999999998,
                                                                    "totalQuantityWeight": 5.365499999999998,
                                                                    "singleQuantityMcf": 27.476720000000004,
                                                                    "mcf": 27.476720000000004,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 27.476720000000004,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 630,
                                                                            "parentRowNumber": 629,
                                                                            "articleId": "20120680",
                                                                            "description": "20120680-00-Sechskantschraube",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0207,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0207,
                                                                            "singleQuantityMcf": 0.107226,
                                                                            "mcf": 0.107226,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.107226,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        },
                                                                        {
                                                                            "rowNumber": 631,
                                                                            "parentRowNumber": 629,
                                                                            "articleId": "208206",
                                                                            "description": "208206-00-Scheibe",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0009,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0009,
                                                                            "singleQuantityMcf": 0.004662,
                                                                            "mcf": 0.004662,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.004662,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        },
                                                                        {
                                                                            "rowNumber": 632,
                                                                            "parentRowNumber": 629,
                                                                            "articleId": "2745210",
                                                                            "description": "2745210-00-Gleitlager",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0398,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0398,
                                                                            "singleQuantityMcf": 0.068854,
                                                                            "mcf": 0.068854,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.068854,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        },
                                                                        {
                                                                            "rowNumber": 633,
                                                                            "parentRowNumber": 629,
                                                                            "articleId": "32880700PU90",
                                                                            "description": "32880700PU90-01-Doppelabstreifer",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0047,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0047,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        },
                                                                        {
                                                                            "rowNumber": 634,
                                                                            "parentRowNumber": 629,
                                                                            "articleId": "46326342",
                                                                            "description": "46326342-00-Buchse",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0422,
                                                                            "childrenWeight": 0.0422,
                                                                            "totalQuantityWeight": 0.0422,
                                                                            "singleQuantityMcf": 0.073006,
                                                                            "mcf": 0.073006,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.073006,
                                                                            "expandable": false,
                                                                            "children": [
                                                                                {
                                                                                    "rowNumber": 635,
                                                                                    "parentRowNumber": 634,
                                                                                    "articleId": "273270",
                                                                                    "description": "273270-00-Gleitlager",
                                                                                    "hierarchy": 9,
                                                                                    "quantity": 1,
                                                                                    "weight": 0.0422,
                                                                                    "childrenWeight": 0,
                                                                                    "totalQuantityWeight": 0.0422,
                                                                                    "singleQuantityMcf": 0.073006,
                                                                                    "mcf": 0.073006,
                                                                                    "singleQuantityLcf": 0,
                                                                                    "lcf": 0,
                                                                                    "totalQuantityMcf": 0.073006,
                                                                                    "expandable": false,
                                                                                    "children": []
                                                                                }
                                                                            ]
                                                                        },
                                                                        {
                                                                            "rowNumber": 636,
                                                                            "parentRowNumber": 629,
                                                                            "articleId": "46408185",
                                                                            "description": "46408185-02-Montageblech",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.9154,
                                                                            "childrenWeight": 0.9154,
                                                                            "totalQuantityWeight": 0.9154,
                                                                            "singleQuantityMcf": 4.741772,
                                                                            "mcf": 4.741772,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 4.741772,
                                                                            "expandable": false,
                                                                            "children": [
                                                                                {
                                                                                    "rowNumber": 637,
                                                                                    "parentRowNumber": 636,
                                                                                    "articleId": "20420450",
                                                                                    "description": "20420450-00-Senkschraube",
                                                                                    "hierarchy": 9,
                                                                                    "quantity": 1,
                                                                                    "weight": 0.005,
                                                                                    "childrenWeight": 0,
                                                                                    "totalQuantityWeight": 0.005,
                                                                                    "singleQuantityMcf": 0.0259,
                                                                                    "mcf": 0.0259,
                                                                                    "singleQuantityLcf": 0,
                                                                                    "lcf": 0,
                                                                                    "totalQuantityMcf": 0.0259,
                                                                                    "expandable": false,
                                                                                    "children": []
                                                                                },
                                                                                {
                                                                                    "rowNumber": 638,
                                                                                    "parentRowNumber": 636,
                                                                                    "articleId": "20420450",
                                                                                    "description": "20420450-00-Senkschraube",
                                                                                    "hierarchy": 9,
                                                                                    "quantity": 1,
                                                                                    "weight": 0.005,
                                                                                    "childrenWeight": 0,
                                                                                    "totalQuantityWeight": 0.005,
                                                                                    "singleQuantityMcf": 0.0259,
                                                                                    "mcf": 0.0259,
                                                                                    "singleQuantityLcf": 0,
                                                                                    "lcf": 0,
                                                                                    "totalQuantityMcf": 0.0259,
                                                                                    "expandable": false,
                                                                                    "children": []
                                                                                },
                                                                                {
                                                                                    "rowNumber": 639,
                                                                                    "parentRowNumber": 636,
                                                                                    "articleId": "46405555",
                                                                                    "description": "46405555-00-Halter",
                                                                                    "hierarchy": 9,
                                                                                    "quantity": 1,
                                                                                    "weight": 0.054,
                                                                                    "childrenWeight": 0.054,
                                                                                    "totalQuantityWeight": 0.054,
                                                                                    "singleQuantityMcf": 0.27972,
                                                                                    "mcf": 0.27972,
                                                                                    "singleQuantityLcf": 0,
                                                                                    "lcf": 0,
                                                                                    "totalQuantityMcf": 0.27972,
                                                                                    "expandable": false,
                                                                                    "children": [
                                                                                        {
                                                                                            "rowNumber": 640,
                                                                                            "parentRowNumber": 639,
                                                                                            "articleId": "142615",
                                                                                            "description": "142615-00-Edelstahlblech",
                                                                                            "hierarchy": 10,
                                                                                            "quantity": 1,
                                                                                            "weight": 0.054,
                                                                                            "childrenWeight": 0,
                                                                                            "totalQuantityWeight": 0.054,
                                                                                            "singleQuantityMcf": 0.27972,
                                                                                            "mcf": 0.27972,
                                                                                            "singleQuantityLcf": 0,
                                                                                            "lcf": 0,
                                                                                            "totalQuantityMcf": 0.27972,
                                                                                            "expandable": false,
                                                                                            "children": []
                                                                                        }
                                                                                    ]
                                                                                },
                                                                                {
                                                                                    "rowNumber": 641,
                                                                                    "parentRowNumber": 636,
                                                                                    "articleId": "46405596",
                                                                                    "description": "46405596-00-Blech",
                                                                                    "hierarchy": 9,
                                                                                    "quantity": 1,
                                                                                    "weight": 0.7298,
                                                                                    "childrenWeight": 0.7298,
                                                                                    "totalQuantityWeight": 0.7298,
                                                                                    "singleQuantityMcf": 3.780364,
                                                                                    "mcf": 3.780364,
                                                                                    "singleQuantityLcf": 0,
                                                                                    "lcf": 0,
                                                                                    "totalQuantityMcf": 3.780364,
                                                                                    "expandable": false,
                                                                                    "children": [
                                                                                        {
                                                                                            "rowNumber": 642,
                                                                                            "parentRowNumber": 641,
                                                                                            "articleId": "142612",
                                                                                            "description": "142612-00-Edelstahlblech",
                                                                                            "hierarchy": 10,
                                                                                            "quantity": 1,
                                                                                            "weight": 0.7298,
                                                                                            "childrenWeight": 0,
                                                                                            "totalQuantityWeight": 0.7298,
                                                                                            "singleQuantityMcf": 3.780364,
                                                                                            "mcf": 3.780364,
                                                                                            "singleQuantityLcf": 0,
                                                                                            "lcf": 0,
                                                                                            "totalQuantityMcf": 3.780364,
                                                                                            "expandable": false,
                                                                                            "children": []
                                                                                        }
                                                                                    ]
                                                                                },
                                                                                {
                                                                                    "rowNumber": 643,
                                                                                    "parentRowNumber": 636,
                                                                                    "articleId": "46405598",
                                                                                    "description": "46405598-00-Anschweisslasche",
                                                                                    "hierarchy": 9,
                                                                                    "quantity": 1,
                                                                                    "weight": 0.0206,
                                                                                    "childrenWeight": 0.0206,
                                                                                    "totalQuantityWeight": 0.0206,
                                                                                    "singleQuantityMcf": 0.106708,
                                                                                    "mcf": 0.106708,
                                                                                    "singleQuantityLcf": 0,
                                                                                    "lcf": 0,
                                                                                    "totalQuantityMcf": 0.106708,
                                                                                    "expandable": false,
                                                                                    "children": [
                                                                                        {
                                                                                            "rowNumber": 644,
                                                                                            "parentRowNumber": 643,
                                                                                            "articleId": "1110166",
                                                                                            "description": "1110166-00-Flachstahl",
                                                                                            "hierarchy": 10,
                                                                                            "quantity": 1,
                                                                                            "weight": 0.0206,
                                                                                            "childrenWeight": 0,
                                                                                            "totalQuantityWeight": 0.0206,
                                                                                            "singleQuantityMcf": 0.106708,
                                                                                            "mcf": 0.106708,
                                                                                            "singleQuantityLcf": 0,
                                                                                            "lcf": 0,
                                                                                            "totalQuantityMcf": 0.106708,
                                                                                            "expandable": false,
                                                                                            "children": []
                                                                                        }
                                                                                    ]
                                                                                },
                                                                                {
                                                                                    "rowNumber": 645,
                                                                                    "parentRowNumber": 636,
                                                                                    "articleId": "46408250",
                                                                                    "description": "46408250-01-Halter",
                                                                                    "hierarchy": 9,
                                                                                    "quantity": 1,
                                                                                    "weight": 0.0505,
                                                                                    "childrenWeight": 0.0505,
                                                                                    "totalQuantityWeight": 0.0505,
                                                                                    "singleQuantityMcf": 0.26159,
                                                                                    "mcf": 0.26159,
                                                                                    "singleQuantityLcf": 0,
                                                                                    "lcf": 0,
                                                                                    "totalQuantityMcf": 0.26159,
                                                                                    "expandable": false,
                                                                                    "children": [
                                                                                        {
                                                                                            "rowNumber": 646,
                                                                                            "parentRowNumber": 645,
                                                                                            "articleId": "142615",
                                                                                            "description": "142615-00-Edelstahlblech",
                                                                                            "hierarchy": 10,
                                                                                            "quantity": 1,
                                                                                            "weight": 0.0505,
                                                                                            "childrenWeight": 0,
                                                                                            "totalQuantityWeight": 0.0505,
                                                                                            "singleQuantityMcf": 0.26159,
                                                                                            "mcf": 0.26159,
                                                                                            "singleQuantityLcf": 0,
                                                                                            "lcf": 0,
                                                                                            "totalQuantityMcf": 0.26159,
                                                                                            "expandable": false,
                                                                                            "children": []
                                                                                        }
                                                                                    ]
                                                                                },
                                                                                {
                                                                                    "rowNumber": 647,
                                                                                    "parentRowNumber": 636,
                                                                                    "articleId": "46408250",
                                                                                    "description": "46408250-01-Halter",
                                                                                    "hierarchy": 9,
                                                                                    "quantity": 1,
                                                                                    "weight": 0.0505,
                                                                                    "childrenWeight": 0.0505,
                                                                                    "totalQuantityWeight": 0.0505,
                                                                                    "singleQuantityMcf": 0.26159,
                                                                                    "mcf": 0.26159,
                                                                                    "singleQuantityLcf": 0,
                                                                                    "lcf": 0,
                                                                                    "totalQuantityMcf": 0.26159,
                                                                                    "expandable": false,
                                                                                    "children": [
                                                                                        {
                                                                                            "rowNumber": 648,
                                                                                            "parentRowNumber": 647,
                                                                                            "articleId": "142615",
                                                                                            "description": "142615-00-Edelstahlblech",
                                                                                            "hierarchy": 10,
                                                                                            "quantity": 1,
                                                                                            "weight": 0.0505,
                                                                                            "childrenWeight": 0,
                                                                                            "totalQuantityWeight": 0.0505,
                                                                                            "singleQuantityMcf": 0.26159,
                                                                                            "mcf": 0.26159,
                                                                                            "singleQuantityLcf": 0,
                                                                                            "lcf": 0,
                                                                                            "totalQuantityMcf": 0.26159,
                                                                                            "expandable": false,
                                                                                            "children": []
                                                                                        }
                                                                                    ]
                                                                                }
                                                                            ]
                                                                        },
                                                                        {
                                                                            "rowNumber": 649,
                                                                            "parentRowNumber": 629,
                                                                            "articleId": "46507671",
                                                                            "description": "46507671-02-Fuehrung",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 4.341799999999999,
                                                                            "childrenWeight": 4.341799999999999,
                                                                            "totalQuantityWeight": 4.341799999999999,
                                                                            "singleQuantityMcf": 22.4812,
                                                                            "mcf": 22.4812,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 22.4812,
                                                                            "expandable": false,
                                                                            "children": [
                                                                                {
                                                                                    "rowNumber": 650,
                                                                                    "parentRowNumber": 649,
                                                                                    "articleId": "46507672",
                                                                                    "description": "46507672-00-Fuehrung",
                                                                                    "hierarchy": 9,
                                                                                    "quantity": 1,
                                                                                    "weight": 2.9456999999999995,
                                                                                    "childrenWeight": 2.9456999999999995,
                                                                                    "totalQuantityWeight": 2.9456999999999995,
                                                                                    "singleQuantityMcf": 15.249402,
                                                                                    "mcf": 15.249402,
                                                                                    "singleQuantityLcf": 0,
                                                                                    "lcf": 0,
                                                                                    "totalQuantityMcf": 15.249402,
                                                                                    "expandable": false,
                                                                                    "children": [
                                                                                        {
                                                                                            "rowNumber": 651,
                                                                                            "parentRowNumber": 650,
                                                                                            "articleId": "2302040320",
                                                                                            "description": "2302040320-00-Passfeder",
                                                                                            "hierarchy": 10,
                                                                                            "quantity": 1,
                                                                                            "weight": 0.0018,
                                                                                            "childrenWeight": 0,
                                                                                            "totalQuantityWeight": 0.0018,
                                                                                            "singleQuantityMcf": 0,
                                                                                            "mcf": 0,
                                                                                            "singleQuantityLcf": 0,
                                                                                            "lcf": 0,
                                                                                            "totalQuantityMcf": 0,
                                                                                            "expandable": false,
                                                                                            "children": []
                                                                                        },
                                                                                        {
                                                                                            "rowNumber": 652,
                                                                                            "parentRowNumber": 650,
                                                                                            "articleId": "46405592",
                                                                                            "description": "46405592-00-Deckel",
                                                                                            "hierarchy": 10,
                                                                                            "quantity": 1,
                                                                                            "weight": 0.0763,
                                                                                            "childrenWeight": 0.0763,
                                                                                            "totalQuantityWeight": 0.0763,
                                                                                            "singleQuantityMcf": 0.395234,
                                                                                            "mcf": 0.395234,
                                                                                            "singleQuantityLcf": 0,
                                                                                            "lcf": 0,
                                                                                            "totalQuantityMcf": 0.395234,
                                                                                            "expandable": false,
                                                                                            "children": [
                                                                                                {
                                                                                                    "rowNumber": 653,
                                                                                                    "parentRowNumber": 652,
                                                                                                    "articleId": "142603",
                                                                                                    "description": "142603-00-Edelstahlblech",
                                                                                                    "hierarchy": 11,
                                                                                                    "quantity": 1,
                                                                                                    "weight": 0.0763,
                                                                                                    "childrenWeight": 0,
                                                                                                    "totalQuantityWeight": 0.0763,
                                                                                                    "singleQuantityMcf": 0.395234,
                                                                                                    "mcf": 0.395234,
                                                                                                    "singleQuantityLcf": 0,
                                                                                                    "lcf": 0,
                                                                                                    "totalQuantityMcf": 0.395234,
                                                                                                    "expandable": false,
                                                                                                    "children": []
                                                                                                }
                                                                                            ]
                                                                                        },
                                                                                        {
                                                                                            "rowNumber": 654,
                                                                                            "parentRowNumber": 650,
                                                                                            "articleId": "46507677",
                                                                                            "description": "46507677-00-Rohr",
                                                                                            "hierarchy": 10,
                                                                                            "quantity": 1,
                                                                                            "weight": 2.8676,
                                                                                            "childrenWeight": 2.8676,
                                                                                            "totalQuantityWeight": 2.8676,
                                                                                            "singleQuantityMcf": 14.854168,
                                                                                            "mcf": 14.854168,
                                                                                            "singleQuantityLcf": 0,
                                                                                            "lcf": 0,
                                                                                            "totalQuantityMcf": 14.854168,
                                                                                            "expandable": false,
                                                                                            "children": [
                                                                                                {
                                                                                                    "rowNumber": 655,
                                                                                                    "parentRowNumber": 654,
                                                                                                    "articleId": "1255353",
                                                                                                    "description": "1255353-00-Rohr",
                                                                                                    "hierarchy": 11,
                                                                                                    "quantity": 1,
                                                                                                    "weight": 2.8676,
                                                                                                    "childrenWeight": 0,
                                                                                                    "totalQuantityWeight": 2.8676,
                                                                                                    "singleQuantityMcf": 14.854168,
                                                                                                    "mcf": 14.854168,
                                                                                                    "singleQuantityLcf": 0,
                                                                                                    "lcf": 0,
                                                                                                    "totalQuantityMcf": 14.854168,
                                                                                                    "expandable": false,
                                                                                                    "children": []
                                                                                                }
                                                                                            ]
                                                                                        }
                                                                                    ]
                                                                                },
                                                                                {
                                                                                    "rowNumber": 656,
                                                                                    "parentRowNumber": 649,
                                                                                    "articleId": "46997332",
                                                                                    "description": "46997332-01-Gehaeuse",
                                                                                    "hierarchy": 9,
                                                                                    "quantity": 1,
                                                                                    "weight": 1.0821,
                                                                                    "childrenWeight": 1.0821,
                                                                                    "totalQuantityWeight": 1.0821,
                                                                                    "singleQuantityMcf": 5.605278,
                                                                                    "mcf": 5.605278,
                                                                                    "singleQuantityLcf": 0,
                                                                                    "lcf": 0,
                                                                                    "totalQuantityMcf": 5.605278,
                                                                                    "expandable": false,
                                                                                    "children": [
                                                                                        {
                                                                                            "rowNumber": 657,
                                                                                            "parentRowNumber": 656,
                                                                                            "articleId": "142604",
                                                                                            "description": "142604-00-Edelstahlblech",
                                                                                            "hierarchy": 10,
                                                                                            "quantity": 1,
                                                                                            "weight": 1.0821,
                                                                                            "childrenWeight": 0,
                                                                                            "totalQuantityWeight": 1.0821,
                                                                                            "singleQuantityMcf": 5.605278,
                                                                                            "mcf": 5.605278,
                                                                                            "singleQuantityLcf": 0,
                                                                                            "lcf": 0,
                                                                                            "totalQuantityMcf": 5.605278,
                                                                                            "expandable": false,
                                                                                            "children": []
                                                                                        }
                                                                                    ]
                                                                                },
                                                                                {
                                                                                    "rowNumber": 658,
                                                                                    "parentRowNumber": 649,
                                                                                    "articleId": "46997339",
                                                                                    "description": "46997339-02-Rahmen",
                                                                                    "hierarchy": 9,
                                                                                    "quantity": 1,
                                                                                    "weight": 0.314,
                                                                                    "childrenWeight": 0.314,
                                                                                    "totalQuantityWeight": 0.314,
                                                                                    "singleQuantityMcf": 1.62652,
                                                                                    "mcf": 1.62652,
                                                                                    "singleQuantityLcf": 0,
                                                                                    "lcf": 0,
                                                                                    "totalQuantityMcf": 1.62652,
                                                                                    "expandable": false,
                                                                                    "children": [
                                                                                        {
                                                                                            "rowNumber": 659,
                                                                                            "parentRowNumber": 658,
                                                                                            "articleId": "1260005",
                                                                                            "description": "1260005-00-Rundstahl",
                                                                                            "hierarchy": 10,
                                                                                            "quantity": 1,
                                                                                            "weight": 0,
                                                                                            "childrenWeight": 0,
                                                                                            "totalQuantityWeight": 0,
                                                                                            "singleQuantityMcf": 0,
                                                                                            "mcf": 0,
                                                                                            "singleQuantityLcf": 0,
                                                                                            "lcf": 0,
                                                                                            "totalQuantityMcf": 0,
                                                                                            "expandable": false,
                                                                                            "children": []
                                                                                        },
                                                                                        {
                                                                                            "rowNumber": 660,
                                                                                            "parentRowNumber": 658,
                                                                                            "articleId": "1260005",
                                                                                            "description": "1260005-00-Rundstahl",
                                                                                            "hierarchy": 10,
                                                                                            "quantity": 1,
                                                                                            "weight": 0,
                                                                                            "childrenWeight": 0,
                                                                                            "totalQuantityWeight": 0,
                                                                                            "singleQuantityMcf": 0,
                                                                                            "mcf": 0,
                                                                                            "singleQuantityLcf": 0,
                                                                                            "lcf": 0,
                                                                                            "totalQuantityMcf": 0,
                                                                                            "expandable": false,
                                                                                            "children": []
                                                                                        },
                                                                                        {
                                                                                            "rowNumber": 661,
                                                                                            "parentRowNumber": 658,
                                                                                            "articleId": "142612",
                                                                                            "description": "142612-00-Edelstahlblech",
                                                                                            "hierarchy": 10,
                                                                                            "quantity": 1,
                                                                                            "weight": 0.2788,
                                                                                            "childrenWeight": 0,
                                                                                            "totalQuantityWeight": 0.2788,
                                                                                            "singleQuantityMcf": 1.444184,
                                                                                            "mcf": 1.444184,
                                                                                            "singleQuantityLcf": 0,
                                                                                            "lcf": 0,
                                                                                            "totalQuantityMcf": 1.444184,
                                                                                            "expandable": false,
                                                                                            "children": []
                                                                                        },
                                                                                        {
                                                                                            "rowNumber": 662,
                                                                                            "parentRowNumber": 658,
                                                                                            "articleId": "46397904_1",
                                                                                            "description": "46397904_1-01-Welle",
                                                                                            "hierarchy": 10,
                                                                                            "quantity": 1,
                                                                                            "weight": 0.0176,
                                                                                            "childrenWeight": 0,
                                                                                            "totalQuantityWeight": 0.0176,
                                                                                            "singleQuantityMcf": 0.091168,
                                                                                            "mcf": 0.091168,
                                                                                            "singleQuantityLcf": 0,
                                                                                            "lcf": 0,
                                                                                            "totalQuantityMcf": 0.091168,
                                                                                            "expandable": false,
                                                                                            "children": []
                                                                                        },
                                                                                        {
                                                                                            "rowNumber": 663,
                                                                                            "parentRowNumber": 658,
                                                                                            "articleId": "46397904_1",
                                                                                            "description": "46397904_1-01-Welle",
                                                                                            "hierarchy": 10,
                                                                                            "quantity": 1,
                                                                                            "weight": 0.0176,
                                                                                            "childrenWeight": 0,
                                                                                            "totalQuantityWeight": 0.0176,
                                                                                            "singleQuantityMcf": 0.091168,
                                                                                            "mcf": 0.091168,
                                                                                            "singleQuantityLcf": 0,
                                                                                            "lcf": 0,
                                                                                            "totalQuantityMcf": 0.091168,
                                                                                            "expandable": false,
                                                                                            "children": []
                                                                                        }
                                                                                    ]
                                                                                }
                                                                            ]
                                                                        }
                                                                    ]
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            "rowNumber": 664,
                                                            "parentRowNumber": 601,
                                                            "articleId": "47103188",
                                                            "description": "47103188-01-Bedienterminal",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 21.72999999999999,
                                                            "childrenWeight": 21.72999999999999,
                                                            "totalQuantityWeight": 21.72999999999999,
                                                            "singleQuantityMcf": 114.24886965800005,
                                                            "mcf": 114.24886965800005,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 114.24886965800005,
                                                            "expandable": false,
                                                            "children": [
                                                                {
                                                                    "rowNumber": 665,
                                                                    "parentRowNumber": 664,
                                                                    "articleId": "20121040",
                                                                    "description": "20121040-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0384,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0384,
                                                                    "singleQuantityMcf": 0.198912,
                                                                    "mcf": 0.198912,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.198912,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 666,
                                                                    "parentRowNumber": 664,
                                                                    "articleId": "20121040",
                                                                    "description": "20121040-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0384,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0384,
                                                                    "singleQuantityMcf": 0.198912,
                                                                    "mcf": 0.198912,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.198912,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 667,
                                                                    "parentRowNumber": 664,
                                                                    "articleId": "20121040",
                                                                    "description": "20121040-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0384,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0384,
                                                                    "singleQuantityMcf": 0.198912,
                                                                    "mcf": 0.198912,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.198912,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 668,
                                                                    "parentRowNumber": 664,
                                                                    "articleId": "20121040",
                                                                    "description": "20121040-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0384,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0384,
                                                                    "singleQuantityMcf": 0.198912,
                                                                    "mcf": 0.198912,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.198912,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 669,
                                                                    "parentRowNumber": 664,
                                                                    "articleId": "208208",
                                                                    "description": "208208-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0017,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0017,
                                                                    "singleQuantityMcf": 0.008806,
                                                                    "mcf": 0.008806,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.008806,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 670,
                                                                    "parentRowNumber": 664,
                                                                    "articleId": "208208",
                                                                    "description": "208208-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0017,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0017,
                                                                    "singleQuantityMcf": 0.008806,
                                                                    "mcf": 0.008806,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.008806,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 671,
                                                                    "parentRowNumber": 664,
                                                                    "articleId": "208210",
                                                                    "description": "208210-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0033,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0033,
                                                                    "singleQuantityMcf": 0.017093999999999998,
                                                                    "mcf": 0.017093999999999998,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.017093999999999998,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 672,
                                                                    "parentRowNumber": 664,
                                                                    "articleId": "208210",
                                                                    "description": "208210-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0033,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0033,
                                                                    "singleQuantityMcf": 0.017093999999999998,
                                                                    "mcf": 0.017093999999999998,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.017093999999999998,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 673,
                                                                    "parentRowNumber": 664,
                                                                    "articleId": "208210",
                                                                    "description": "208210-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0033,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0033,
                                                                    "singleQuantityMcf": 0.017093999999999998,
                                                                    "mcf": 0.017093999999999998,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.017093999999999998,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 674,
                                                                    "parentRowNumber": 664,
                                                                    "articleId": "208210",
                                                                    "description": "208210-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0033,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0033,
                                                                    "singleQuantityMcf": 0.017093999999999998,
                                                                    "mcf": 0.017093999999999998,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.017093999999999998,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 675,
                                                                    "parentRowNumber": 664,
                                                                    "articleId": "221408",
                                                                    "description": "221408-00-Hutmutter",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0098,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0098,
                                                                    "singleQuantityMcf": 0.050764,
                                                                    "mcf": 0.050764,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.050764,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 676,
                                                                    "parentRowNumber": 664,
                                                                    "articleId": "221408",
                                                                    "description": "221408-00-Hutmutter",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0098,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0098,
                                                                    "singleQuantityMcf": 0.050764,
                                                                    "mcf": 0.050764,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.050764,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 677,
                                                                    "parentRowNumber": 664,
                                                                    "articleId": "46114461",
                                                                    "description": "46114461-01-Buchse",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.4977,
                                                                    "childrenWeight": 0.4977,
                                                                    "totalQuantityWeight": 0.4977,
                                                                    "singleQuantityMcf": 3.498776253,
                                                                    "mcf": 3.498776253,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 3.498776253,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 678,
                                                                            "parentRowNumber": 677,
                                                                            "articleId": "1229090",
                                                                            "description": "1229090-00-Rundstange",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.4977,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.4977,
                                                                            "singleQuantityMcf": 3.498776253,
                                                                            "mcf": 3.498776253,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 3.498776253,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 679,
                                                                    "parentRowNumber": 664,
                                                                    "articleId": "46114462",
                                                                    "description": "46114462-01-Buchse",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.4145,
                                                                    "childrenWeight": 0.4145,
                                                                    "totalQuantityWeight": 0.4145,
                                                                    "singleQuantityMcf": 2.913889405,
                                                                    "mcf": 2.913889405,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 2.913889405,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 680,
                                                                            "parentRowNumber": 679,
                                                                            "articleId": "1229090",
                                                                            "description": "1229090-00-Rundstange",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.4145,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.4145,
                                                                            "singleQuantityMcf": 2.913889405,
                                                                            "mcf": 2.913889405,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 2.913889405,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 681,
                                                                    "parentRowNumber": 664,
                                                                    "articleId": "46791410",
                                                                    "description": "46791410-00-Bolzen",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.05,
                                                                    "childrenWeight": 0.05,
                                                                    "totalQuantityWeight": 0.05,
                                                                    "singleQuantityMcf": 0.259,
                                                                    "mcf": 0.259,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.259,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 682,
                                                                            "parentRowNumber": 681,
                                                                            "articleId": "132717",
                                                                            "description": "132717-00-Sechskantstahl",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.05,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.05,
                                                                            "singleQuantityMcf": 0.259,
                                                                            "mcf": 0.259,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.259,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 683,
                                                                    "parentRowNumber": 664,
                                                                    "articleId": "46791410",
                                                                    "description": "46791410-00-Bolzen",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.05,
                                                                    "childrenWeight": 0.05,
                                                                    "totalQuantityWeight": 0.05,
                                                                    "singleQuantityMcf": 0.259,
                                                                    "mcf": 0.259,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.259,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 684,
                                                                            "parentRowNumber": 683,
                                                                            "articleId": "132717",
                                                                            "description": "132717-00-Sechskantstahl",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.05,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.05,
                                                                            "singleQuantityMcf": 0.259,
                                                                            "mcf": 0.259,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.259,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 685,
                                                                    "parentRowNumber": 664,
                                                                    "articleId": "47095983",
                                                                    "description": "47095983-00-Rohrhalter",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 10.6449,
                                                                    "childrenWeight": 10.6449,
                                                                    "totalQuantityWeight": 10.6449,
                                                                    "singleQuantityMcf": 55.140582,
                                                                    "mcf": 55.140582,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 55.140582,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 686,
                                                                            "parentRowNumber": 685,
                                                                            "articleId": "1261332",
                                                                            "description": "1261332-00-Rohr",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 6.0454,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 6.0454,
                                                                            "singleQuantityMcf": 31.315172,
                                                                            "mcf": 31.315172,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 31.315172,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        },
                                                                        {
                                                                            "rowNumber": 687,
                                                                            "parentRowNumber": 685,
                                                                            "articleId": "1261332",
                                                                            "description": "1261332-00-Rohr",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        },
                                                                        {
                                                                            "rowNumber": 688,
                                                                            "parentRowNumber": 685,
                                                                            "articleId": "2382228",
                                                                            "description": "2382228-01-Rohrbogen 90 Grad",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.4295,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.4295,
                                                                            "singleQuantityMcf": 2.22481,
                                                                            "mcf": 2.22481,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 2.22481,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        },
                                                                        {
                                                                            "rowNumber": 689,
                                                                            "parentRowNumber": 685,
                                                                            "articleId": "46101413",
                                                                            "description": "46101413-00-Anschlag",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.1094,
                                                                            "childrenWeight": 0.1094,
                                                                            "totalQuantityWeight": 0.1094,
                                                                            "singleQuantityMcf": 0.566692,
                                                                            "mcf": 0.566692,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.566692,
                                                                            "expandable": false,
                                                                            "children": [
                                                                                {
                                                                                    "rowNumber": 690,
                                                                                    "parentRowNumber": 689,
                                                                                    "articleId": "142613",
                                                                                    "description": "142613-00-Edelstahlblech",
                                                                                    "hierarchy": 9,
                                                                                    "quantity": 1,
                                                                                    "weight": 0.1094,
                                                                                    "childrenWeight": 0,
                                                                                    "totalQuantityWeight": 0.1094,
                                                                                    "singleQuantityMcf": 0.566692,
                                                                                    "mcf": 0.566692,
                                                                                    "singleQuantityLcf": 0,
                                                                                    "lcf": 0,
                                                                                    "totalQuantityMcf": 0.566692,
                                                                                    "expandable": false,
                                                                                    "children": []
                                                                                }
                                                                            ]
                                                                        },
                                                                        {
                                                                            "rowNumber": 691,
                                                                            "parentRowNumber": 685,
                                                                            "articleId": "46326360",
                                                                            "description": "46326360-03-Rohr",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 1.2521,
                                                                            "childrenWeight": 1.2521,
                                                                            "totalQuantityWeight": 1.2521,
                                                                            "singleQuantityMcf": 6.485878,
                                                                            "mcf": 6.485878,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 6.485878,
                                                                            "expandable": false,
                                                                            "children": [
                                                                                {
                                                                                    "rowNumber": 692,
                                                                                    "parentRowNumber": 691,
                                                                                    "articleId": "1255333",
                                                                                    "description": "1255333-00-Rohr",
                                                                                    "hierarchy": 9,
                                                                                    "quantity": 1,
                                                                                    "weight": 1.0907,
                                                                                    "childrenWeight": 0,
                                                                                    "totalQuantityWeight": 1.0907,
                                                                                    "singleQuantityMcf": 5.649826,
                                                                                    "mcf": 5.649826,
                                                                                    "singleQuantityLcf": 0,
                                                                                    "lcf": 0,
                                                                                    "totalQuantityMcf": 5.649826,
                                                                                    "expandable": false,
                                                                                    "children": []
                                                                                },
                                                                                {
                                                                                    "rowNumber": 693,
                                                                                    "parentRowNumber": 691,
                                                                                    "articleId": "46107830",
                                                                                    "description": "46107830-01-Ring",
                                                                                    "hierarchy": 9,
                                                                                    "quantity": 1,
                                                                                    "weight": 0.1614,
                                                                                    "childrenWeight": 0.1614,
                                                                                    "totalQuantityWeight": 0.1614,
                                                                                    "singleQuantityMcf": 0.8360519999999999,
                                                                                    "mcf": 0.8360519999999999,
                                                                                    "singleQuantityLcf": 0,
                                                                                    "lcf": 0,
                                                                                    "totalQuantityMcf": 0.8360519999999999,
                                                                                    "expandable": false,
                                                                                    "children": [
                                                                                        {
                                                                                            "rowNumber": 694,
                                                                                            "parentRowNumber": 693,
                                                                                            "articleId": "1260070",
                                                                                            "description": "1260070-00-Rundstahl",
                                                                                            "hierarchy": 10,
                                                                                            "quantity": 1,
                                                                                            "weight": 0.1614,
                                                                                            "childrenWeight": 0,
                                                                                            "totalQuantityWeight": 0.1614,
                                                                                            "singleQuantityMcf": 0.8360519999999999,
                                                                                            "mcf": 0.8360519999999999,
                                                                                            "singleQuantityLcf": 0,
                                                                                            "lcf": 0,
                                                                                            "totalQuantityMcf": 0.8360519999999999,
                                                                                            "expandable": false,
                                                                                            "children": []
                                                                                        }
                                                                                    ]
                                                                                }
                                                                            ]
                                                                        },
                                                                        {
                                                                            "rowNumber": 695,
                                                                            "parentRowNumber": 685,
                                                                            "articleId": "46513235",
                                                                            "description": "46513235-03-Rohr",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 1.7493,
                                                                            "childrenWeight": 1.7493,
                                                                            "totalQuantityWeight": 1.7493,
                                                                            "singleQuantityMcf": 9.061374,
                                                                            "mcf": 9.061374,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 9.061374,
                                                                            "expandable": false,
                                                                            "children": [
                                                                                {
                                                                                    "rowNumber": 696,
                                                                                    "parentRowNumber": 695,
                                                                                    "articleId": "1255333",
                                                                                    "description": "1255333-00-Rohr",
                                                                                    "hierarchy": 9,
                                                                                    "quantity": 1,
                                                                                    "weight": 1.7493,
                                                                                    "childrenWeight": 0,
                                                                                    "totalQuantityWeight": 1.7493,
                                                                                    "singleQuantityMcf": 9.061374,
                                                                                    "mcf": 9.061374,
                                                                                    "singleQuantityLcf": 0,
                                                                                    "lcf": 0,
                                                                                    "totalQuantityMcf": 9.061374,
                                                                                    "expandable": false,
                                                                                    "children": []
                                                                                }
                                                                            ]
                                                                        },
                                                                        {
                                                                            "rowNumber": 697,
                                                                            "parentRowNumber": 685,
                                                                            "articleId": "46513254",
                                                                            "description": "46513254-01-Rippe",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.5063,
                                                                            "childrenWeight": 0.5063,
                                                                            "totalQuantityWeight": 0.5063,
                                                                            "singleQuantityMcf": 2.622634,
                                                                            "mcf": 2.622634,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 2.622634,
                                                                            "expandable": false,
                                                                            "children": [
                                                                                {
                                                                                    "rowNumber": 698,
                                                                                    "parentRowNumber": 697,
                                                                                    "articleId": "142613",
                                                                                    "description": "142613-00-Edelstahlblech",
                                                                                    "hierarchy": 9,
                                                                                    "quantity": 1,
                                                                                    "weight": 0.5063,
                                                                                    "childrenWeight": 0,
                                                                                    "totalQuantityWeight": 0.5063,
                                                                                    "singleQuantityMcf": 2.622634,
                                                                                    "mcf": 2.622634,
                                                                                    "singleQuantityLcf": 0,
                                                                                    "lcf": 0,
                                                                                    "totalQuantityMcf": 2.622634,
                                                                                    "expandable": false,
                                                                                    "children": []
                                                                                }
                                                                            ]
                                                                        },
                                                                        {
                                                                            "rowNumber": 699,
                                                                            "parentRowNumber": 685,
                                                                            "articleId": "46720161",
                                                                            "description": "46720161-00-Rohrbogen",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.4295,
                                                                            "childrenWeight": 0.4295,
                                                                            "totalQuantityWeight": 0.4295,
                                                                            "singleQuantityMcf": 2.22481,
                                                                            "mcf": 2.22481,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 2.22481,
                                                                            "expandable": false,
                                                                            "children": [
                                                                                {
                                                                                    "rowNumber": 700,
                                                                                    "parentRowNumber": 699,
                                                                                    "articleId": "2382228",
                                                                                    "description": "2382228-01-Rohrbogen 90 Grad",
                                                                                    "hierarchy": 9,
                                                                                    "quantity": 1,
                                                                                    "weight": 0.4295,
                                                                                    "childrenWeight": 0,
                                                                                    "totalQuantityWeight": 0.4295,
                                                                                    "singleQuantityMcf": 2.22481,
                                                                                    "mcf": 2.22481,
                                                                                    "singleQuantityLcf": 0,
                                                                                    "lcf": 0,
                                                                                    "totalQuantityMcf": 2.22481,
                                                                                    "expandable": false,
                                                                                    "children": []
                                                                                }
                                                                            ]
                                                                        },
                                                                        {
                                                                            "rowNumber": 701,
                                                                            "parentRowNumber": 685,
                                                                            "articleId": "46720164",
                                                                            "description": "46720164-01-Rohr",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.1234,
                                                                            "childrenWeight": 0.1234,
                                                                            "totalQuantityWeight": 0.1234,
                                                                            "singleQuantityMcf": 0.6392119999999999,
                                                                            "mcf": 0.6392119999999999,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.6392119999999999,
                                                                            "expandable": false,
                                                                            "children": [
                                                                                {
                                                                                    "rowNumber": 702,
                                                                                    "parentRowNumber": 701,
                                                                                    "articleId": "125519",
                                                                                    "description": "125519-00-Rohr",
                                                                                    "hierarchy": 9,
                                                                                    "quantity": 1,
                                                                                    "weight": 0.1234,
                                                                                    "childrenWeight": 0,
                                                                                    "totalQuantityWeight": 0.1234,
                                                                                    "singleQuantityMcf": 0.6392119999999999,
                                                                                    "mcf": 0.6392119999999999,
                                                                                    "singleQuantityLcf": 0,
                                                                                    "lcf": 0,
                                                                                    "totalQuantityMcf": 0.6392119999999999,
                                                                                    "expandable": false,
                                                                                    "children": []
                                                                                }
                                                                            ]
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 703,
                                                                    "parentRowNumber": 664,
                                                                    "articleId": "47122299",
                                                                    "description": "47122299-00-Saeule",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 9.8831,
                                                                    "childrenWeight": 9.8831,
                                                                    "totalQuantityWeight": 9.8831,
                                                                    "singleQuantityMcf": 51.194458,
                                                                    "mcf": 51.194458,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 51.194458,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 704,
                                                                            "parentRowNumber": 703,
                                                                            "articleId": "46101372",
                                                                            "description": "46101372-01-Flansch",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 1.8346,
                                                                            "childrenWeight": 1.8346,
                                                                            "totalQuantityWeight": 1.8346,
                                                                            "singleQuantityMcf": 9.503228,
                                                                            "mcf": 9.503228,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 9.503228,
                                                                            "expandable": false,
                                                                            "children": [
                                                                                {
                                                                                    "rowNumber": 705,
                                                                                    "parentRowNumber": 704,
                                                                                    "articleId": "1250135",
                                                                                    "description": "1250135-00-Rundstahl",
                                                                                    "hierarchy": 9,
                                                                                    "quantity": 1,
                                                                                    "weight": 1.8346,
                                                                                    "childrenWeight": 0,
                                                                                    "totalQuantityWeight": 1.8346,
                                                                                    "singleQuantityMcf": 9.503228,
                                                                                    "mcf": 9.503228,
                                                                                    "singleQuantityLcf": 0,
                                                                                    "lcf": 0,
                                                                                    "totalQuantityMcf": 9.503228,
                                                                                    "expandable": false,
                                                                                    "children": []
                                                                                }
                                                                            ]
                                                                        },
                                                                        {
                                                                            "rowNumber": 706,
                                                                            "parentRowNumber": 703,
                                                                            "articleId": "46791409",
                                                                            "description": "46791409-00-Ring",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.7775,
                                                                            "childrenWeight": 0.7775,
                                                                            "totalQuantityWeight": 0.7775,
                                                                            "singleQuantityMcf": 4.02745,
                                                                            "mcf": 4.02745,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 4.02745,
                                                                            "expandable": false,
                                                                            "children": [
                                                                                {
                                                                                    "rowNumber": 707,
                                                                                    "parentRowNumber": 706,
                                                                                    "articleId": "1250135",
                                                                                    "description": "1250135-00-Rundstahl",
                                                                                    "hierarchy": 9,
                                                                                    "quantity": 1,
                                                                                    "weight": 0.7775,
                                                                                    "childrenWeight": 0,
                                                                                    "totalQuantityWeight": 0.7775,
                                                                                    "singleQuantityMcf": 4.02745,
                                                                                    "mcf": 4.02745,
                                                                                    "singleQuantityLcf": 0,
                                                                                    "lcf": 0,
                                                                                    "totalQuantityMcf": 4.02745,
                                                                                    "expandable": false,
                                                                                    "children": []
                                                                                }
                                                                            ]
                                                                        },
                                                                        {
                                                                            "rowNumber": 708,
                                                                            "parentRowNumber": 703,
                                                                            "articleId": "47122300",
                                                                            "description": "47122300-00-Saeule",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 7.271,
                                                                            "childrenWeight": 7.271,
                                                                            "totalQuantityWeight": 7.271,
                                                                            "singleQuantityMcf": 37.66378,
                                                                            "mcf": 37.66378,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 37.66378,
                                                                            "expandable": false,
                                                                            "children": [
                                                                                {
                                                                                    "rowNumber": 709,
                                                                                    "parentRowNumber": 708,
                                                                                    "articleId": "126136",
                                                                                    "description": "126136-00-Rohr",
                                                                                    "hierarchy": 9,
                                                                                    "quantity": 1,
                                                                                    "weight": 7.271,
                                                                                    "childrenWeight": 0,
                                                                                    "totalQuantityWeight": 7.271,
                                                                                    "singleQuantityMcf": 37.66378,
                                                                                    "mcf": 37.66378,
                                                                                    "singleQuantityLcf": 0,
                                                                                    "lcf": 0,
                                                                                    "totalQuantityMcf": 37.66378,
                                                                                    "expandable": false,
                                                                                    "children": []
                                                                                }
                                                                            ]
                                                                        }
                                                                    ]
                                                                }
                                                            ]
                                                        }
                                                    ]
                                                },
                                                {
                                                    "rowNumber": 710,
                                                    "parentRowNumber": 600,
                                                    "articleId": "P",
                                                    "description": "P-ESA1025-22-001-00-Durchtransport",
                                                    "hierarchy": 5,
                                                    "quantity": 1,
                                                    "weight": 0,
                                                    "childrenWeight": 0,
                                                    "totalQuantityWeight": 0,
                                                    "singleQuantityMcf": 0,
                                                    "mcf": 0,
                                                    "singleQuantityLcf": 0,
                                                    "lcf": 0,
                                                    "totalQuantityMcf": 0,
                                                    "expandable": false,
                                                    "children": []
                                                }
                                            ]
                                        },
                                        {
                                            "rowNumber": 711,
                                            "parentRowNumber": 599,
                                            "articleId": "33847",
                                            "description": "33847-00MA15-00-Bedienterminal",
                                            "hierarchy": 4,
                                            "quantity": 1,
                                            "weight": 0.3024,
                                            "childrenWeight": 0.3024,
                                            "totalQuantityWeight": 0.3024,
                                            "singleQuantityMcf": 0,
                                            "mcf": 0,
                                            "singleQuantityLcf": 0,
                                            "lcf": 0,
                                            "totalQuantityMcf": 0,
                                            "expandable": false,
                                            "children": [
                                                {
                                                    "rowNumber": 712,
                                                    "parentRowNumber": 711,
                                                    "articleId": "MM0000000005",
                                                    "description": "MM0000000005-01-Bedienterminal",
                                                    "hierarchy": 5,
                                                    "quantity": 1,
                                                    "weight": 0.3024,
                                                    "childrenWeight": 0.3024,
                                                    "totalQuantityWeight": 0.3024,
                                                    "singleQuantityMcf": 0,
                                                    "mcf": 0,
                                                    "singleQuantityLcf": 0,
                                                    "lcf": 0,
                                                    "totalQuantityMcf": 0,
                                                    "expandable": false,
                                                    "children": [
                                                        {
                                                            "rowNumber": 713,
                                                            "parentRowNumber": 712,
                                                            "articleId": "54538798",
                                                            "description": "54538798-01-Steckverbinder M12 Buchse",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0591,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0591,
                                                            "singleQuantityMcf": 0,
                                                            "mcf": 0,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 714,
                                                            "parentRowNumber": 712,
                                                            "articleId": "56488410",
                                                            "description": "56488410-00-Anschlußleitung",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0921,
                                                            "childrenWeight": 0.0921,
                                                            "totalQuantityWeight": 0.0921,
                                                            "singleQuantityMcf": 0,
                                                            "mcf": 0,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0,
                                                            "expandable": false,
                                                            "children": [
                                                                {
                                                                    "rowNumber": 715,
                                                                    "parentRowNumber": 714,
                                                                    "articleId": "PUDY00000071",
                                                                    "description": "PUDY00000071-00-Kabel D12",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0921,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0921,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": []
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            "rowNumber": 716,
                                                            "parentRowNumber": 712,
                                                            "articleId": "56488422",
                                                            "description": "56488422-00-CP-Link 4 Anschlusskabel",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0591,
                                                            "childrenWeight": 0.0591,
                                                            "totalQuantityWeight": 0.0591,
                                                            "singleQuantityMcf": 0,
                                                            "mcf": 0,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0,
                                                            "expandable": false,
                                                            "children": [
                                                                {
                                                                    "rowNumber": 717,
                                                                    "parentRowNumber": 716,
                                                                    "articleId": "PUDY00000069",
                                                                    "description": "PUDY00000069-00-Kabel D10",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0591,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0591,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": []
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            "rowNumber": 718,
                                                            "parentRowNumber": 712,
                                                            "articleId": "58593045",
                                                            "description": "58593045-03-Bedienterminal",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0921,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0921,
                                                            "singleQuantityMcf": 0,
                                                            "mcf": 0,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0,
                                                            "expandable": false,
                                                            "children": []
                                                        }
                                                    ]
                                                }
                                            ]
                                        },
                                        {
                                            "rowNumber": 719,
                                            "parentRowNumber": 599,
                                            "articleId": "33847",
                                            "description": "33847-00MA30-00-Meldeeinrichtung",
                                            "hierarchy": 4,
                                            "quantity": 1,
                                            "weight": 6.1408000000000005,
                                            "childrenWeight": 6.1408000000000005,
                                            "totalQuantityWeight": 6.1408000000000005,
                                            "singleQuantityMcf": 2.613,
                                            "mcf": 2.613,
                                            "singleQuantityLcf": 0,
                                            "lcf": 0,
                                            "totalQuantityMcf": 2.613,
                                            "expandable": false,
                                            "children": [
                                                {
                                                    "rowNumber": 720,
                                                    "parentRowNumber": 719,
                                                    "articleId": "MM0000001241",
                                                    "description": "MM0000001241-00-Optisches Signal",
                                                    "hierarchy": 5,
                                                    "quantity": 1,
                                                    "weight": 6.1408000000000005,
                                                    "childrenWeight": 6.1408000000000005,
                                                    "totalQuantityWeight": 6.1408000000000005,
                                                    "singleQuantityMcf": 2.613,
                                                    "mcf": 2.613,
                                                    "singleQuantityLcf": 0,
                                                    "lcf": 0,
                                                    "totalQuantityMcf": 2.613,
                                                    "expandable": false,
                                                    "children": [
                                                        {
                                                            "rowNumber": 721,
                                                            "parentRowNumber": 720,
                                                            "articleId": "264476",
                                                            "description": "264476-00-Spannsatz",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.19,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.19,
                                                            "singleQuantityMcf": 0.9842,
                                                            "mcf": 0.9842,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.9842,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 722,
                                                            "parentRowNumber": 720,
                                                            "articleId": "46997269",
                                                            "description": "46997269-00-Halter",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.509,
                                                            "childrenWeight": 0.509,
                                                            "totalQuantityWeight": 0.509,
                                                            "singleQuantityMcf": 1.6288,
                                                            "mcf": 1.6288,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 1.6288,
                                                            "expandable": false,
                                                            "children": [
                                                                {
                                                                    "rowNumber": 723,
                                                                    "parentRowNumber": 722,
                                                                    "articleId": "162551",
                                                                    "description": "162551-01-Platte",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.509,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.509,
                                                                    "singleQuantityMcf": 1.6288,
                                                                    "mcf": 1.6288,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 1.6288,
                                                                    "expandable": false,
                                                                    "children": []
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            "rowNumber": 724,
                                                            "parentRowNumber": 720,
                                                            "articleId": "47008452",
                                                            "description": "47008452-00-Abstreifer",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.006,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.006,
                                                            "singleQuantityMcf": 0,
                                                            "mcf": 0,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 725,
                                                            "parentRowNumber": 720,
                                                            "articleId": "54349165",
                                                            "description": "54349165-00-Signalleuchte",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 5.4185,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 5.4185,
                                                            "singleQuantityMcf": 0,
                                                            "mcf": 0,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 726,
                                                            "parentRowNumber": 720,
                                                            "articleId": "58236122",
                                                            "description": "58236122-01-Kabeldose",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0173,
                                                            "childrenWeight": 0.0173,
                                                            "totalQuantityWeight": 0.0173,
                                                            "singleQuantityMcf": 0,
                                                            "mcf": 0,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0,
                                                            "expandable": false,
                                                            "children": [
                                                                {
                                                                    "rowNumber": 727,
                                                                    "parentRowNumber": 726,
                                                                    "articleId": "PUDY00000067",
                                                                    "description": "PUDY00000067-00-Kabel D6",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0173,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0173,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": []
                                                                }
                                                            ]
                                                        }
                                                    ]
                                                },
                                                {
                                                    "rowNumber": 728,
                                                    "parentRowNumber": 719,
                                                    "articleId": "P",
                                                    "description": "P-ESA1025-22-001-00-Durchtransport",
                                                    "hierarchy": 5,
                                                    "quantity": 1,
                                                    "weight": 0,
                                                    "childrenWeight": 0,
                                                    "totalQuantityWeight": 0,
                                                    "singleQuantityMcf": 0,
                                                    "mcf": 0,
                                                    "singleQuantityLcf": 0,
                                                    "lcf": 0,
                                                    "totalQuantityMcf": 0,
                                                    "expandable": false,
                                                    "children": []
                                                }
                                            ]
                                        }
                                    ]
                                },
                                {
                                    "rowNumber": 729,
                                    "parentRowNumber": 1,
                                    "articleId": "33847",
                                    "description": "33847-00MX-00-HMI Allgemein",
                                    "hierarchy": 3,
                                    "quantity": 1,
                                    "weight": 1.6652999999999998,
                                    "childrenWeight": 1.6652999999999998,
                                    "totalQuantityWeight": 1.6652999999999998,
                                    "singleQuantityMcf": 0.033152,
                                    "mcf": 0.033152,
                                    "singleQuantityLcf": 0,
                                    "lcf": 0,
                                    "totalQuantityMcf": 0.033152,
                                    "expandable": false,
                                    "children": [
                                        {
                                            "rowNumber": 730,
                                            "parentRowNumber": 729,
                                            "articleId": "33847",
                                            "description": "33847-00MX30-02-Meldeeinrichtung",
                                            "hierarchy": 4,
                                            "quantity": 1,
                                            "weight": 1.6652999999999998,
                                            "childrenWeight": 1.6652999999999998,
                                            "totalQuantityWeight": 1.6652999999999998,
                                            "singleQuantityMcf": 0.033152,
                                            "mcf": 0.033152,
                                            "singleQuantityLcf": 0,
                                            "lcf": 0,
                                            "totalQuantityMcf": 0.033152,
                                            "expandable": false,
                                            "children": [
                                                {
                                                    "rowNumber": 731,
                                                    "parentRowNumber": 730,
                                                    "articleId": "MM0000005443",
                                                    "description": "MM0000005443-02-Meldeeinrichtung",
                                                    "hierarchy": 5,
                                                    "quantity": 1,
                                                    "weight": 1.6652999999999998,
                                                    "childrenWeight": 1.6652999999999998,
                                                    "totalQuantityWeight": 1.6652999999999998,
                                                    "singleQuantityMcf": 0.033152,
                                                    "mcf": 0.033152,
                                                    "singleQuantityLcf": 0,
                                                    "lcf": 0,
                                                    "totalQuantityMcf": 0.033152,
                                                    "expandable": false,
                                                    "children": [
                                                        {
                                                            "rowNumber": 732,
                                                            "parentRowNumber": 731,
                                                            "articleId": "20120420",
                                                            "description": "20120420-00-Sechskantschraube",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0029,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0029,
                                                            "singleQuantityMcf": 0.015022,
                                                            "mcf": 0.015022,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.015022,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 733,
                                                            "parentRowNumber": 731,
                                                            "articleId": "20120420",
                                                            "description": "20120420-00-Sechskantschraube",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0029,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0029,
                                                            "singleQuantityMcf": 0.015022,
                                                            "mcf": 0.015022,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.015022,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 734,
                                                            "parentRowNumber": 731,
                                                            "articleId": "208204",
                                                            "description": "208204-00-Scheibe",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0003,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0003,
                                                            "singleQuantityMcf": 0.0015539999999999998,
                                                            "mcf": 0.0015539999999999998,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.0015539999999999998,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 735,
                                                            "parentRowNumber": 731,
                                                            "articleId": "208204",
                                                            "description": "208204-00-Scheibe",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0003,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0003,
                                                            "singleQuantityMcf": 0.0015539999999999998,
                                                            "mcf": 0.0015539999999999998,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.0015539999999999998,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 736,
                                                            "parentRowNumber": 731,
                                                            "articleId": "543511",
                                                            "description": "543511-01-Elektron. Summer",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 1.6589,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 1.6589,
                                                            "singleQuantityMcf": 0,
                                                            "mcf": 0,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0,
                                                            "expandable": false,
                                                            "children": []
                                                        }
                                                    ]
                                                }
                                            ]
                                        }
                                    ]
                                },
                                {
                                    "rowNumber": 737,
                                    "parentRowNumber": 1,
                                    "articleId": "33847",
                                    "description": "33847-00PA-00-Visionsystem A",
                                    "hierarchy": 3,
                                    "quantity": 1,
                                    "weight": 8.406500000000003,
                                    "childrenWeight": 8.406500000000003,
                                    "totalQuantityWeight": 8.406500000000003,
                                    "singleQuantityMcf": 23.18775199999999,
                                    "mcf": 23.18775199999999,
                                    "singleQuantityLcf": 0,
                                    "lcf": 0,
                                    "totalQuantityMcf": 23.18775199999999,
                                    "expandable": false,
                                    "children": [
                                        {
                                            "rowNumber": 738,
                                            "parentRowNumber": 737,
                                            "articleId": "33847",
                                            "description": "33847-00PA00-01-Halterung Bedienterminal",
                                            "hierarchy": 4,
                                            "quantity": 1,
                                            "weight": 8.371800000000002,
                                            "childrenWeight": 8.371800000000002,
                                            "totalQuantityWeight": 8.371800000000002,
                                            "singleQuantityMcf": 23.18775199999999,
                                            "mcf": 23.18775199999999,
                                            "singleQuantityLcf": 0,
                                            "lcf": 0,
                                            "totalQuantityMcf": 23.18775199999999,
                                            "expandable": false,
                                            "children": [
                                                {
                                                    "rowNumber": 739,
                                                    "parentRowNumber": 738,
                                                    "articleId": "MM0000005525",
                                                    "description": "MM0000005525-01-Halterung Bedienterminal",
                                                    "hierarchy": 5,
                                                    "quantity": 1,
                                                    "weight": 8.371800000000002,
                                                    "childrenWeight": 8.371800000000002,
                                                    "totalQuantityWeight": 8.371800000000002,
                                                    "singleQuantityMcf": 23.18775199999999,
                                                    "mcf": 23.18775199999999,
                                                    "singleQuantityLcf": 0,
                                                    "lcf": 0,
                                                    "totalQuantityMcf": 23.18775199999999,
                                                    "expandable": false,
                                                    "children": [
                                                        {
                                                            "rowNumber": 740,
                                                            "parentRowNumber": 739,
                                                            "articleId": "20120412",
                                                            "description": "20120412-00-Sechskantschraube",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0021,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0021,
                                                            "singleQuantityMcf": 0.010878,
                                                            "mcf": 0.010878,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.010878,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 741,
                                                            "parentRowNumber": 739,
                                                            "articleId": "20120412",
                                                            "description": "20120412-00-Sechskantschraube",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0021,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0021,
                                                            "singleQuantityMcf": 0.010878,
                                                            "mcf": 0.010878,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.010878,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 742,
                                                            "parentRowNumber": 739,
                                                            "articleId": "20120515",
                                                            "description": "20120515-00-Sechskantschraube",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0039,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0039,
                                                            "singleQuantityMcf": 0.020202,
                                                            "mcf": 0.020202,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.020202,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 743,
                                                            "parentRowNumber": 739,
                                                            "articleId": "20120515",
                                                            "description": "20120515-00-Sechskantschraube",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0039,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0039,
                                                            "singleQuantityMcf": 0.020202,
                                                            "mcf": 0.020202,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.020202,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 744,
                                                            "parentRowNumber": 739,
                                                            "articleId": "20120515",
                                                            "description": "20120515-00-Sechskantschraube",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0039,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0039,
                                                            "singleQuantityMcf": 0.020202,
                                                            "mcf": 0.020202,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.020202,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 745,
                                                            "parentRowNumber": 739,
                                                            "articleId": "20120515",
                                                            "description": "20120515-00-Sechskantschraube",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0039,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0039,
                                                            "singleQuantityMcf": 0.020202,
                                                            "mcf": 0.020202,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.020202,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 746,
                                                            "parentRowNumber": 739,
                                                            "articleId": "20120515",
                                                            "description": "20120515-00-Sechskantschraube",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0039,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0039,
                                                            "singleQuantityMcf": 0.020202,
                                                            "mcf": 0.020202,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.020202,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 747,
                                                            "parentRowNumber": 739,
                                                            "articleId": "20120515",
                                                            "description": "20120515-00-Sechskantschraube",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0039,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0039,
                                                            "singleQuantityMcf": 0.020202,
                                                            "mcf": 0.020202,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.020202,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 748,
                                                            "parentRowNumber": 739,
                                                            "articleId": "20120515",
                                                            "description": "20120515-00-Sechskantschraube",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0039,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0039,
                                                            "singleQuantityMcf": 0.020202,
                                                            "mcf": 0.020202,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.020202,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 749,
                                                            "parentRowNumber": 739,
                                                            "articleId": "20120515",
                                                            "description": "20120515-00-Sechskantschraube",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0039,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0039,
                                                            "singleQuantityMcf": 0.020202,
                                                            "mcf": 0.020202,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.020202,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 750,
                                                            "parentRowNumber": 739,
                                                            "articleId": "20120840",
                                                            "description": "20120840-00-Sechskantschraube",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0221,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0221,
                                                            "singleQuantityMcf": 0.114478,
                                                            "mcf": 0.114478,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.114478,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 751,
                                                            "parentRowNumber": 739,
                                                            "articleId": "20120840",
                                                            "description": "20120840-00-Sechskantschraube",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0221,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0221,
                                                            "singleQuantityMcf": 0.114478,
                                                            "mcf": 0.114478,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.114478,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 752,
                                                            "parentRowNumber": 739,
                                                            "articleId": "20120840",
                                                            "description": "20120840-00-Sechskantschraube",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0221,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0221,
                                                            "singleQuantityMcf": 0.114478,
                                                            "mcf": 0.114478,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.114478,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 753,
                                                            "parentRowNumber": 739,
                                                            "articleId": "20120840",
                                                            "description": "20120840-00-Sechskantschraube",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0221,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0221,
                                                            "singleQuantityMcf": 0.114478,
                                                            "mcf": 0.114478,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.114478,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 754,
                                                            "parentRowNumber": 739,
                                                            "articleId": "208204",
                                                            "description": "208204-00-Scheibe",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0003,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0003,
                                                            "singleQuantityMcf": 0.0015539999999999998,
                                                            "mcf": 0.0015539999999999998,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.0015539999999999998,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 755,
                                                            "parentRowNumber": 739,
                                                            "articleId": "208204",
                                                            "description": "208204-00-Scheibe",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0003,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0003,
                                                            "singleQuantityMcf": 0.0015539999999999998,
                                                            "mcf": 0.0015539999999999998,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.0015539999999999998,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 756,
                                                            "parentRowNumber": 739,
                                                            "articleId": "208205",
                                                            "description": "208205-00-Scheibe",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0004,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0004,
                                                            "singleQuantityMcf": 0.002072,
                                                            "mcf": 0.002072,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.002072,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 757,
                                                            "parentRowNumber": 739,
                                                            "articleId": "208205",
                                                            "description": "208205-00-Scheibe",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0004,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0004,
                                                            "singleQuantityMcf": 0.002072,
                                                            "mcf": 0.002072,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.002072,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 758,
                                                            "parentRowNumber": 739,
                                                            "articleId": "208205",
                                                            "description": "208205-00-Scheibe",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0004,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0004,
                                                            "singleQuantityMcf": 0.002072,
                                                            "mcf": 0.002072,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.002072,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 759,
                                                            "parentRowNumber": 739,
                                                            "articleId": "208205",
                                                            "description": "208205-00-Scheibe",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0004,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0004,
                                                            "singleQuantityMcf": 0.002072,
                                                            "mcf": 0.002072,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.002072,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 760,
                                                            "parentRowNumber": 739,
                                                            "articleId": "208205",
                                                            "description": "208205-00-Scheibe",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0004,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0004,
                                                            "singleQuantityMcf": 0.002072,
                                                            "mcf": 0.002072,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.002072,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 761,
                                                            "parentRowNumber": 739,
                                                            "articleId": "208205",
                                                            "description": "208205-00-Scheibe",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0004,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0004,
                                                            "singleQuantityMcf": 0.002072,
                                                            "mcf": 0.002072,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.002072,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 762,
                                                            "parentRowNumber": 739,
                                                            "articleId": "208205",
                                                            "description": "208205-00-Scheibe",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0004,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0004,
                                                            "singleQuantityMcf": 0.002072,
                                                            "mcf": 0.002072,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.002072,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 763,
                                                            "parentRowNumber": 739,
                                                            "articleId": "208205",
                                                            "description": "208205-00-Scheibe",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0004,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0004,
                                                            "singleQuantityMcf": 0.002072,
                                                            "mcf": 0.002072,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.002072,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 764,
                                                            "parentRowNumber": 739,
                                                            "articleId": "208208",
                                                            "description": "208208-00-Scheibe",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0017,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0017,
                                                            "singleQuantityMcf": 0.008806,
                                                            "mcf": 0.008806,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.008806,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 765,
                                                            "parentRowNumber": 739,
                                                            "articleId": "208208",
                                                            "description": "208208-00-Scheibe",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0017,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0017,
                                                            "singleQuantityMcf": 0.008806,
                                                            "mcf": 0.008806,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.008806,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 766,
                                                            "parentRowNumber": 739,
                                                            "articleId": "208208",
                                                            "description": "208208-00-Scheibe",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0017,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0017,
                                                            "singleQuantityMcf": 0.008806,
                                                            "mcf": 0.008806,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.008806,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 767,
                                                            "parentRowNumber": 739,
                                                            "articleId": "208208",
                                                            "description": "208208-00-Scheibe",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0017,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0017,
                                                            "singleQuantityMcf": 0.008806,
                                                            "mcf": 0.008806,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.008806,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 768,
                                                            "parentRowNumber": 739,
                                                            "articleId": "39900138",
                                                            "description": "39900138-00-CP-Gehaeusekupplung",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 1.3233,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 1.3233,
                                                            "singleQuantityMcf": 6.854693999999999,
                                                            "mcf": 6.854693999999999,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 6.854693999999999,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 769,
                                                            "parentRowNumber": 739,
                                                            "articleId": "39900438",
                                                            "description": "39900438-00-Halter",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 3.8954,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 3.8954,
                                                            "singleQuantityMcf": 0,
                                                            "mcf": 0,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 770,
                                                            "parentRowNumber": 739,
                                                            "articleId": "46737035",
                                                            "description": "46737035-00-Blech",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.2965,
                                                            "childrenWeight": 0.2965,
                                                            "totalQuantityWeight": 0.2965,
                                                            "singleQuantityMcf": 1.5358699999999998,
                                                            "mcf": 1.5358699999999998,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 1.5358699999999998,
                                                            "expandable": false,
                                                            "children": [
                                                                {
                                                                    "rowNumber": 771,
                                                                    "parentRowNumber": 770,
                                                                    "articleId": "142603",
                                                                    "description": "142603-00-Edelstahlblech",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.2965,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.2965,
                                                                    "singleQuantityMcf": 1.5358699999999998,
                                                                    "mcf": 1.5358699999999998,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 1.5358699999999998,
                                                                    "expandable": false,
                                                                    "children": []
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            "rowNumber": 772,
                                                            "parentRowNumber": 739,
                                                            "articleId": "46940047",
                                                            "description": "46940047-00-Gehaeuse",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.6818,
                                                            "childrenWeight": 0.6818,
                                                            "totalQuantityWeight": 0.6818,
                                                            "singleQuantityMcf": 3.531724,
                                                            "mcf": 3.531724,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 3.531724,
                                                            "expandable": false,
                                                            "children": [
                                                                {
                                                                    "rowNumber": 773,
                                                                    "parentRowNumber": 772,
                                                                    "articleId": "142604",
                                                                    "description": "142604-00-Edelstahlblech",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.6786,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.6786,
                                                                    "singleQuantityMcf": 3.5151479999999995,
                                                                    "mcf": 3.5151479999999995,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 3.5151479999999995,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 774,
                                                                    "parentRowNumber": 772,
                                                                    "articleId": "243331",
                                                                    "description": "243331-00-Einnietmutter",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0016,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0016,
                                                                    "singleQuantityMcf": 0.008288,
                                                                    "mcf": 0.008288,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.008288,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 775,
                                                                    "parentRowNumber": 772,
                                                                    "articleId": "243331",
                                                                    "description": "243331-00-Einnietmutter",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0016,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0016,
                                                                    "singleQuantityMcf": 0.008288,
                                                                    "mcf": 0.008288,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.008288,
                                                                    "expandable": false,
                                                                    "children": []
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            "rowNumber": 776,
                                                            "parentRowNumber": 739,
                                                            "articleId": "47097376",
                                                            "description": "47097376-00-Rohr",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 2.0404,
                                                            "childrenWeight": 2.0404,
                                                            "totalQuantityWeight": 2.0404,
                                                            "singleQuantityMcf": 10.569272000000002,
                                                            "mcf": 10.569272000000002,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 10.569272000000002,
                                                            "expandable": false,
                                                            "children": [
                                                                {
                                                                    "rowNumber": 777,
                                                                    "parentRowNumber": 776,
                                                                    "articleId": "1261283",
                                                                    "description": "1261283-00-Rohr",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 1.6236000000000002,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 1.6236000000000002,
                                                                    "singleQuantityMcf": 8.410248000000001,
                                                                    "mcf": 8.410248000000001,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 8.410248000000001,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 778,
                                                                    "parentRowNumber": 776,
                                                                    "articleId": "1261283",
                                                                    "description": "1261283-00-Rohr",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 779,
                                                                    "parentRowNumber": 776,
                                                                    "articleId": "1261283",
                                                                    "description": "1261283-00-Rohr",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 780,
                                                                    "parentRowNumber": 776,
                                                                    "articleId": "2382223",
                                                                    "description": "2382223-00-Rohrbogen 90 Grad",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.2084,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.2084,
                                                                    "singleQuantityMcf": 1.079512,
                                                                    "mcf": 1.079512,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 1.079512,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 781,
                                                                    "parentRowNumber": 776,
                                                                    "articleId": "2382223",
                                                                    "description": "2382223-00-Rohrbogen 90 Grad",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.2084,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.2084,
                                                                    "singleQuantityMcf": 1.079512,
                                                                    "mcf": 1.079512,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 1.079512,
                                                                    "expandable": false,
                                                                    "children": []
                                                                }
                                                            ]
                                                        }
                                                    ]
                                                }
                                            ]
                                        },
                                        {
                                            "rowNumber": 782,
                                            "parentRowNumber": 737,
                                            "articleId": "33847",
                                            "description": "33847-00PA15-00-Bedienterminal",
                                            "hierarchy": 4,
                                            "quantity": 1,
                                            "weight": 0.0347,
                                            "childrenWeight": 0.0347,
                                            "totalQuantityWeight": 0.0347,
                                            "singleQuantityMcf": 0,
                                            "mcf": 0,
                                            "singleQuantityLcf": 0,
                                            "lcf": 0,
                                            "totalQuantityMcf": 0,
                                            "expandable": false,
                                            "children": [
                                                {
                                                    "rowNumber": 783,
                                                    "parentRowNumber": 782,
                                                    "articleId": "MM0000005526",
                                                    "description": "MM0000005526-00-Bedienterminal",
                                                    "hierarchy": 5,
                                                    "quantity": 1,
                                                    "weight": 0.0347,
                                                    "childrenWeight": 0.0347,
                                                    "totalQuantityWeight": 0.0347,
                                                    "singleQuantityMcf": 0,
                                                    "mcf": 0,
                                                    "singleQuantityLcf": 0,
                                                    "lcf": 0,
                                                    "totalQuantityMcf": 0,
                                                    "expandable": false,
                                                    "children": [
                                                        {
                                                            "rowNumber": 784,
                                                            "parentRowNumber": 783,
                                                            "articleId": "54539005",
                                                            "description": "54539005-03-Kaltgerätekupplung",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0347,
                                                            "childrenWeight": 0.0347,
                                                            "totalQuantityWeight": 0.0347,
                                                            "singleQuantityMcf": 0,
                                                            "mcf": 0,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0,
                                                            "expandable": false,
                                                            "children": [
                                                                {
                                                                    "rowNumber": 785,
                                                                    "parentRowNumber": 784,
                                                                    "articleId": "PUDY00000068",
                                                                    "description": "PUDY00000068-00-Kabel D8",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0347,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0347,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": []
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            "rowNumber": 786,
                                                            "parentRowNumber": 783,
                                                            "articleId": "58197110",
                                                            "description": "58197110-01-Touchmonitor 15Zoll Standard",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0,
                                                            "singleQuantityMcf": 0,
                                                            "mcf": 0,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 787,
                                                            "parentRowNumber": 783,
                                                            "articleId": "58197116",
                                                            "description": "58197116-00-VGA Kabel",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0,
                                                            "singleQuantityMcf": 0,
                                                            "mcf": 0,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0,
                                                            "expandable": false,
                                                            "children": []
                                                        }
                                                    ]
                                                }
                                            ]
                                        }
                                    ]
                                },
                                {
                                    "rowNumber": 788,
                                    "parentRowNumber": 1,
                                    "articleId": "33847",
                                    "description": "33847-00SB-00-Sicherheit Maschinenbereich",
                                    "hierarchy": 3,
                                    "quantity": 1,
                                    "weight": 17.5953,
                                    "childrenWeight": 17.5953,
                                    "totalQuantityWeight": 17.5953,
                                    "singleQuantityMcf": 86.710902,
                                    "mcf": 86.710902,
                                    "singleQuantityLcf": 0,
                                    "lcf": 0,
                                    "totalQuantityMcf": 86.710902,
                                    "expandable": false,
                                    "children": [
                                        {
                                            "rowNumber": 789,
                                            "parentRowNumber": 788,
                                            "articleId": "33847",
                                            "description": "33847-00SB30-00-Not-Halt 1",
                                            "hierarchy": 4,
                                            "quantity": 1,
                                            "weight": 0,
                                            "childrenWeight": 0,
                                            "totalQuantityWeight": 0,
                                            "singleQuantityMcf": 0,
                                            "mcf": 0,
                                            "singleQuantityLcf": 0,
                                            "lcf": 0,
                                            "totalQuantityMcf": 0,
                                            "expandable": false,
                                            "children": []
                                        },
                                        {
                                            "rowNumber": 790,
                                            "parentRowNumber": 788,
                                            "articleId": "33847",
                                            "description": "33847-00SB31-01-Not-Halt 2",
                                            "hierarchy": 4,
                                            "quantity": 1,
                                            "weight": 0.8077000000000001,
                                            "childrenWeight": 0.8077000000000001,
                                            "totalQuantityWeight": 0.8077000000000001,
                                            "singleQuantityMcf": 3.265764,
                                            "mcf": 3.265764,
                                            "singleQuantityLcf": 0,
                                            "lcf": 0,
                                            "totalQuantityMcf": 3.265764,
                                            "expandable": false,
                                            "children": [
                                                {
                                                    "rowNumber": 791,
                                                    "parentRowNumber": 790,
                                                    "articleId": "MM0000005424",
                                                    "description": "MM0000005424-01-Not-Halt 2",
                                                    "hierarchy": 5,
                                                    "quantity": 1,
                                                    "weight": 0.8077000000000001,
                                                    "childrenWeight": 0.8077000000000001,
                                                    "totalQuantityWeight": 0.8077000000000001,
                                                    "singleQuantityMcf": 3.265764,
                                                    "mcf": 3.265764,
                                                    "singleQuantityLcf": 0,
                                                    "lcf": 0,
                                                    "totalQuantityMcf": 3.265764,
                                                    "expandable": false,
                                                    "children": [
                                                        {
                                                            "rowNumber": 792,
                                                            "parentRowNumber": 791,
                                                            "articleId": "46522353",
                                                            "description": "46522353-03-Not-Aus Halter",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.6816000000000001,
                                                            "childrenWeight": 0.6816000000000001,
                                                            "totalQuantityWeight": 0.6816000000000001,
                                                            "singleQuantityMcf": 3.265764,
                                                            "mcf": 3.265764,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 3.265764,
                                                            "expandable": false,
                                                            "children": [
                                                                {
                                                                    "rowNumber": 793,
                                                                    "parentRowNumber": 792,
                                                                    "articleId": "20720505",
                                                                    "description": "20720505-00-Gewindestift",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0006,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0006,
                                                                    "singleQuantityMcf": 0.003108,
                                                                    "mcf": 0.003108,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.003108,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 794,
                                                                    "parentRowNumber": 792,
                                                                    "articleId": "46317649",
                                                                    "description": "46317649-02-Deckel",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0326,
                                                                    "childrenWeight": 0.0326,
                                                                    "totalQuantityWeight": 0.0326,
                                                                    "singleQuantityMcf": 0.10432,
                                                                    "mcf": 0.10432,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.10432,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 795,
                                                                            "parentRowNumber": 794,
                                                                            "articleId": "1617063",
                                                                            "description": "1617063-00-Rundstab",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0326,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0326,
                                                                            "singleQuantityMcf": 0.10432,
                                                                            "mcf": 0.10432,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.10432,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 796,
                                                                    "parentRowNumber": 792,
                                                                    "articleId": "46317652",
                                                                    "description": "46317652-00-Gehaeuse",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.1012,
                                                                    "childrenWeight": 0.1012,
                                                                    "totalQuantityWeight": 0.1012,
                                                                    "singleQuantityMcf": 0.32384,
                                                                    "mcf": 0.32384,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.32384,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 797,
                                                                            "parentRowNumber": 796,
                                                                            "articleId": "1617063",
                                                                            "description": "1617063-00-Rundstab",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.1012,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.1012,
                                                                            "singleQuantityMcf": 0.32384,
                                                                            "mcf": 0.32384,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.32384,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 798,
                                                                    "parentRowNumber": 792,
                                                                    "articleId": "46522360",
                                                                    "description": "46522360-01-Rohrhalter",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.5472000000000001,
                                                                    "childrenWeight": 0.5472000000000001,
                                                                    "totalQuantityWeight": 0.5472000000000001,
                                                                    "singleQuantityMcf": 2.834496,
                                                                    "mcf": 2.834496,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 2.834496,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 799,
                                                                            "parentRowNumber": 798,
                                                                            "articleId": "20120650",
                                                                            "description": "20120650-00-Sechskantschraube",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0139,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0139,
                                                                            "singleQuantityMcf": 0.072002,
                                                                            "mcf": 0.072002,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.072002,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        },
                                                                        {
                                                                            "rowNumber": 800,
                                                                            "parentRowNumber": 798,
                                                                            "articleId": "20120650",
                                                                            "description": "20120650-00-Sechskantschraube",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0139,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0139,
                                                                            "singleQuantityMcf": 0.072002,
                                                                            "mcf": 0.072002,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.072002,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        },
                                                                        {
                                                                            "rowNumber": 801,
                                                                            "parentRowNumber": 798,
                                                                            "articleId": "208206",
                                                                            "description": "208206-00-Scheibe",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0009,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0009,
                                                                            "singleQuantityMcf": 0.004662,
                                                                            "mcf": 0.004662,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.004662,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        },
                                                                        {
                                                                            "rowNumber": 802,
                                                                            "parentRowNumber": 798,
                                                                            "articleId": "208206",
                                                                            "description": "208206-00-Scheibe",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0009,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0009,
                                                                            "singleQuantityMcf": 0.004662,
                                                                            "mcf": 0.004662,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.004662,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        },
                                                                        {
                                                                            "rowNumber": 803,
                                                                            "parentRowNumber": 798,
                                                                            "articleId": "46522361",
                                                                            "description": "46522361-00-Klotz",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.2979,
                                                                            "childrenWeight": 0.2979,
                                                                            "totalQuantityWeight": 0.2979,
                                                                            "singleQuantityMcf": 1.543122,
                                                                            "mcf": 1.543122,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 1.543122,
                                                                            "expandable": false,
                                                                            "children": [
                                                                                {
                                                                                    "rowNumber": 804,
                                                                                    "parentRowNumber": 803,
                                                                                    "articleId": "1110284",
                                                                                    "description": "1110284-00-Flachstahl",
                                                                                    "hierarchy": 9,
                                                                                    "quantity": 1,
                                                                                    "weight": 0.2979,
                                                                                    "childrenWeight": 0,
                                                                                    "totalQuantityWeight": 0.2979,
                                                                                    "singleQuantityMcf": 1.543122,
                                                                                    "mcf": 1.543122,
                                                                                    "singleQuantityLcf": 0,
                                                                                    "lcf": 0,
                                                                                    "totalQuantityMcf": 1.543122,
                                                                                    "expandable": false,
                                                                                    "children": []
                                                                                }
                                                                            ]
                                                                        },
                                                                        {
                                                                            "rowNumber": 805,
                                                                            "parentRowNumber": 798,
                                                                            "articleId": "46522363",
                                                                            "description": "46522363-01-Rohr",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.2197,
                                                                            "childrenWeight": 0.2197,
                                                                            "totalQuantityWeight": 0.2197,
                                                                            "singleQuantityMcf": 1.138046,
                                                                            "mcf": 1.138046,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 1.138046,
                                                                            "expandable": false,
                                                                            "children": [
                                                                                {
                                                                                    "rowNumber": 806,
                                                                                    "parentRowNumber": 805,
                                                                                    "articleId": "125515",
                                                                                    "description": "125515-00-Rohr",
                                                                                    "hierarchy": 9,
                                                                                    "quantity": 1,
                                                                                    "weight": 0.2197,
                                                                                    "childrenWeight": 0,
                                                                                    "totalQuantityWeight": 0.2197,
                                                                                    "singleQuantityMcf": 1.138046,
                                                                                    "mcf": 1.138046,
                                                                                    "singleQuantityLcf": 0,
                                                                                    "lcf": 0,
                                                                                    "totalQuantityMcf": 1.138046,
                                                                                    "expandable": false,
                                                                                    "children": []
                                                                                }
                                                                            ]
                                                                        }
                                                                    ]
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            "rowNumber": 807,
                                                            "parentRowNumber": 791,
                                                            "articleId": "54227626",
                                                            "description": "54227626-01-Not-Halt Taste",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.1261,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.1261,
                                                            "singleQuantityMcf": 0,
                                                            "mcf": 0,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0,
                                                            "expandable": false,
                                                            "children": []
                                                        }
                                                    ]
                                                }
                                            ]
                                        },
                                        {
                                            "rowNumber": 808,
                                            "parentRowNumber": 788,
                                            "articleId": "33847",
                                            "description": "33847-00SB40-00-Zustimmungsschalter",
                                            "hierarchy": 4,
                                            "quantity": 1,
                                            "weight": 0.1193,
                                            "childrenWeight": 0.1193,
                                            "totalQuantityWeight": 0.1193,
                                            "singleQuantityMcf": 0,
                                            "mcf": 0,
                                            "singleQuantityLcf": 0,
                                            "lcf": 0,
                                            "totalQuantityMcf": 0,
                                            "expandable": false,
                                            "children": [
                                                {
                                                    "rowNumber": 809,
                                                    "parentRowNumber": 808,
                                                    "articleId": "MM0000005427",
                                                    "description": "MM0000005427-00-Zustimmungsschalter",
                                                    "hierarchy": 5,
                                                    "quantity": 1,
                                                    "weight": 0.1193,
                                                    "childrenWeight": 0.1193,
                                                    "totalQuantityWeight": 0.1193,
                                                    "singleQuantityMcf": 0,
                                                    "mcf": 0,
                                                    "singleQuantityLcf": 0,
                                                    "lcf": 0,
                                                    "totalQuantityMcf": 0,
                                                    "expandable": false,
                                                    "children": [
                                                        {
                                                            "rowNumber": 810,
                                                            "parentRowNumber": 809,
                                                            "articleId": "54599045",
                                                            "description": "54599045-00-Klappdeckel für LEMO",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0155,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0155,
                                                            "singleQuantityMcf": 0,
                                                            "mcf": 0,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 811,
                                                            "parentRowNumber": 809,
                                                            "articleId": "56489885",
                                                            "description": "56489885-00-Einbauapparatedose",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.1038,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.1038,
                                                            "singleQuantityMcf": 0,
                                                            "mcf": 0,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0,
                                                            "expandable": false,
                                                            "children": []
                                                        }
                                                    ]
                                                }
                                            ]
                                        },
                                        {
                                            "rowNumber": 812,
                                            "parentRowNumber": 788,
                                            "articleId": "33847",
                                            "description": "33847-00SB60-00-Verkleidung",
                                            "hierarchy": 4,
                                            "quantity": 1,
                                            "weight": 16.668300000000002,
                                            "childrenWeight": 16.668300000000002,
                                            "totalQuantityWeight": 16.668300000000002,
                                            "singleQuantityMcf": 83.445138,
                                            "mcf": 83.445138,
                                            "singleQuantityLcf": 0,
                                            "lcf": 0,
                                            "totalQuantityMcf": 83.445138,
                                            "expandable": false,
                                            "children": [
                                                {
                                                    "rowNumber": 813,
                                                    "parentRowNumber": 812,
                                                    "articleId": "MM0000003628",
                                                    "description": "MM0000003628-00-Verkleidung",
                                                    "hierarchy": 5,
                                                    "quantity": 1,
                                                    "weight": 16.668300000000002,
                                                    "childrenWeight": 16.668300000000002,
                                                    "totalQuantityWeight": 16.668300000000002,
                                                    "singleQuantityMcf": 83.445138,
                                                    "mcf": 83.445138,
                                                    "singleQuantityLcf": 0,
                                                    "lcf": 0,
                                                    "totalQuantityMcf": 83.445138,
                                                    "expandable": false,
                                                    "children": [
                                                        {
                                                            "rowNumber": 814,
                                                            "parentRowNumber": 813,
                                                            "articleId": "47073198",
                                                            "description": "47073198-00-Verkleidung",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 16.668300000000002,
                                                            "childrenWeight": 16.668300000000002,
                                                            "totalQuantityWeight": 16.668300000000002,
                                                            "singleQuantityMcf": 83.445138,
                                                            "mcf": 83.445138,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 83.445138,
                                                            "expandable": false,
                                                            "children": [
                                                                {
                                                                    "rowNumber": 815,
                                                                    "parentRowNumber": 814,
                                                                    "articleId": "47074036",
                                                                    "description": "47074036-00-Stecktuere 2.0",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 7.071700000000001,
                                                                    "childrenWeight": 7.071700000000001,
                                                                    "totalQuantityWeight": 7.071700000000001,
                                                                    "singleQuantityMcf": 35.665854,
                                                                    "mcf": 35.665854,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 35.665854,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 816,
                                                                            "parentRowNumber": 815,
                                                                            "articleId": "142603",
                                                                            "description": "142603-00-Edelstahlblech",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 6.7757000000000005,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 6.7757000000000005,
                                                                            "singleQuantityMcf": 35.098126,
                                                                            "mcf": 35.098126,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 35.098126,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        },
                                                                        {
                                                                            "rowNumber": 817,
                                                                            "parentRowNumber": 815,
                                                                            "articleId": "240788",
                                                                            "description": "240788-00-Vorreiber",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0932,
                                                                            "childrenWeight": 0.0932,
                                                                            "totalQuantityWeight": 0.0932,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": [
                                                                                {
                                                                                    "rowNumber": 818,
                                                                                    "parentRowNumber": 817,
                                                                                    "articleId": "240788_1",
                                                                                    "description": "240788_1-00-Gehause",
                                                                                    "hierarchy": 9,
                                                                                    "quantity": 1,
                                                                                    "weight": 0.0333,
                                                                                    "childrenWeight": 0,
                                                                                    "totalQuantityWeight": 0.0333,
                                                                                    "singleQuantityMcf": 0,
                                                                                    "mcf": 0,
                                                                                    "singleQuantityLcf": 0,
                                                                                    "lcf": 0,
                                                                                    "totalQuantityMcf": 0,
                                                                                    "expandable": false,
                                                                                    "children": []
                                                                                },
                                                                                {
                                                                                    "rowNumber": 819,
                                                                                    "parentRowNumber": 817,
                                                                                    "articleId": "240788_2",
                                                                                    "description": "240788_2-00-Zunge",
                                                                                    "hierarchy": 9,
                                                                                    "quantity": 1,
                                                                                    "weight": 0.0274,
                                                                                    "childrenWeight": 0,
                                                                                    "totalQuantityWeight": 0.0274,
                                                                                    "singleQuantityMcf": 0,
                                                                                    "mcf": 0,
                                                                                    "singleQuantityLcf": 0,
                                                                                    "lcf": 0,
                                                                                    "totalQuantityMcf": 0,
                                                                                    "expandable": false,
                                                                                    "children": []
                                                                                },
                                                                                {
                                                                                    "rowNumber": 820,
                                                                                    "parentRowNumber": 817,
                                                                                    "articleId": "240788_3",
                                                                                    "description": "240788_3-00-Flachdichtung",
                                                                                    "hierarchy": 9,
                                                                                    "quantity": 1,
                                                                                    "weight": 0.0009,
                                                                                    "childrenWeight": 0,
                                                                                    "totalQuantityWeight": 0.0009,
                                                                                    "singleQuantityMcf": 0,
                                                                                    "mcf": 0,
                                                                                    "singleQuantityLcf": 0,
                                                                                    "lcf": 0,
                                                                                    "totalQuantityMcf": 0,
                                                                                    "expandable": false,
                                                                                    "children": []
                                                                                },
                                                                                {
                                                                                    "rowNumber": 821,
                                                                                    "parentRowNumber": 817,
                                                                                    "articleId": "240788_4",
                                                                                    "description": "240788_4-00-Betaetigung",
                                                                                    "hierarchy": 9,
                                                                                    "quantity": 1,
                                                                                    "weight": 0.0226,
                                                                                    "childrenWeight": 0,
                                                                                    "totalQuantityWeight": 0.0226,
                                                                                    "singleQuantityMcf": 0,
                                                                                    "mcf": 0,
                                                                                    "singleQuantityLcf": 0,
                                                                                    "lcf": 0,
                                                                                    "totalQuantityMcf": 0,
                                                                                    "expandable": false,
                                                                                    "children": []
                                                                                },
                                                                                {
                                                                                    "rowNumber": 822,
                                                                                    "parentRowNumber": 817,
                                                                                    "articleId": "240788_5",
                                                                                    "description": "240788_5-00-Mutter",
                                                                                    "hierarchy": 9,
                                                                                    "quantity": 1,
                                                                                    "weight": 0.009,
                                                                                    "childrenWeight": 0,
                                                                                    "totalQuantityWeight": 0.009,
                                                                                    "singleQuantityMcf": 0,
                                                                                    "mcf": 0,
                                                                                    "singleQuantityLcf": 0,
                                                                                    "lcf": 0,
                                                                                    "totalQuantityMcf": 0,
                                                                                    "expandable": false,
                                                                                    "children": []
                                                                                }
                                                                            ]
                                                                        },
                                                                        {
                                                                            "rowNumber": 823,
                                                                            "parentRowNumber": 815,
                                                                            "articleId": "240788",
                                                                            "description": "240788-00-Vorreiber",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0932,
                                                                            "childrenWeight": 0.0932,
                                                                            "totalQuantityWeight": 0.0932,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": [
                                                                                {
                                                                                    "rowNumber": 824,
                                                                                    "parentRowNumber": 823,
                                                                                    "articleId": "240788_1",
                                                                                    "description": "240788_1-00-Gehause",
                                                                                    "hierarchy": 9,
                                                                                    "quantity": 1,
                                                                                    "weight": 0.0333,
                                                                                    "childrenWeight": 0,
                                                                                    "totalQuantityWeight": 0.0333,
                                                                                    "singleQuantityMcf": 0,
                                                                                    "mcf": 0,
                                                                                    "singleQuantityLcf": 0,
                                                                                    "lcf": 0,
                                                                                    "totalQuantityMcf": 0,
                                                                                    "expandable": false,
                                                                                    "children": []
                                                                                },
                                                                                {
                                                                                    "rowNumber": 825,
                                                                                    "parentRowNumber": 823,
                                                                                    "articleId": "240788_2",
                                                                                    "description": "240788_2-00-Zunge",
                                                                                    "hierarchy": 9,
                                                                                    "quantity": 1,
                                                                                    "weight": 0.0274,
                                                                                    "childrenWeight": 0,
                                                                                    "totalQuantityWeight": 0.0274,
                                                                                    "singleQuantityMcf": 0,
                                                                                    "mcf": 0,
                                                                                    "singleQuantityLcf": 0,
                                                                                    "lcf": 0,
                                                                                    "totalQuantityMcf": 0,
                                                                                    "expandable": false,
                                                                                    "children": []
                                                                                },
                                                                                {
                                                                                    "rowNumber": 826,
                                                                                    "parentRowNumber": 823,
                                                                                    "articleId": "240788_3",
                                                                                    "description": "240788_3-00-Flachdichtung",
                                                                                    "hierarchy": 9,
                                                                                    "quantity": 1,
                                                                                    "weight": 0.0009,
                                                                                    "childrenWeight": 0,
                                                                                    "totalQuantityWeight": 0.0009,
                                                                                    "singleQuantityMcf": 0,
                                                                                    "mcf": 0,
                                                                                    "singleQuantityLcf": 0,
                                                                                    "lcf": 0,
                                                                                    "totalQuantityMcf": 0,
                                                                                    "expandable": false,
                                                                                    "children": []
                                                                                },
                                                                                {
                                                                                    "rowNumber": 827,
                                                                                    "parentRowNumber": 823,
                                                                                    "articleId": "240788_4",
                                                                                    "description": "240788_4-00-Betaetigung",
                                                                                    "hierarchy": 9,
                                                                                    "quantity": 1,
                                                                                    "weight": 0.0226,
                                                                                    "childrenWeight": 0,
                                                                                    "totalQuantityWeight": 0.0226,
                                                                                    "singleQuantityMcf": 0,
                                                                                    "mcf": 0,
                                                                                    "singleQuantityLcf": 0,
                                                                                    "lcf": 0,
                                                                                    "totalQuantityMcf": 0,
                                                                                    "expandable": false,
                                                                                    "children": []
                                                                                },
                                                                                {
                                                                                    "rowNumber": 828,
                                                                                    "parentRowNumber": 823,
                                                                                    "articleId": "240788_5",
                                                                                    "description": "240788_5-00-Mutter",
                                                                                    "hierarchy": 9,
                                                                                    "quantity": 1,
                                                                                    "weight": 0.009,
                                                                                    "childrenWeight": 0,
                                                                                    "totalQuantityWeight": 0.009,
                                                                                    "singleQuantityMcf": 0,
                                                                                    "mcf": 0,
                                                                                    "singleQuantityLcf": 0,
                                                                                    "lcf": 0,
                                                                                    "totalQuantityMcf": 0,
                                                                                    "expandable": false,
                                                                                    "children": []
                                                                                }
                                                                            ]
                                                                        },
                                                                        {
                                                                            "rowNumber": 829,
                                                                            "parentRowNumber": 815,
                                                                            "articleId": "450927",
                                                                            "description": "450927-01-Winkel",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0548,
                                                                            "childrenWeight": 0.0548,
                                                                            "totalQuantityWeight": 0.0548,
                                                                            "singleQuantityMcf": 0.283864,
                                                                            "mcf": 0.283864,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.283864,
                                                                            "expandable": false,
                                                                            "children": [
                                                                                {
                                                                                    "rowNumber": 830,
                                                                                    "parentRowNumber": 829,
                                                                                    "articleId": "142607",
                                                                                    "description": "142607-00-Edelstahlblech",
                                                                                    "hierarchy": 9,
                                                                                    "quantity": 1,
                                                                                    "weight": 0.0548,
                                                                                    "childrenWeight": 0,
                                                                                    "totalQuantityWeight": 0.0548,
                                                                                    "singleQuantityMcf": 0.283864,
                                                                                    "mcf": 0.283864,
                                                                                    "singleQuantityLcf": 0,
                                                                                    "lcf": 0,
                                                                                    "totalQuantityMcf": 0.283864,
                                                                                    "expandable": false,
                                                                                    "children": []
                                                                                }
                                                                            ]
                                                                        },
                                                                        {
                                                                            "rowNumber": 831,
                                                                            "parentRowNumber": 815,
                                                                            "articleId": "450927",
                                                                            "description": "450927-01-Winkel",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0548,
                                                                            "childrenWeight": 0.0548,
                                                                            "totalQuantityWeight": 0.0548,
                                                                            "singleQuantityMcf": 0.283864,
                                                                            "mcf": 0.283864,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.283864,
                                                                            "expandable": false,
                                                                            "children": [
                                                                                {
                                                                                    "rowNumber": 832,
                                                                                    "parentRowNumber": 831,
                                                                                    "articleId": "142607",
                                                                                    "description": "142607-00-Edelstahlblech",
                                                                                    "hierarchy": 9,
                                                                                    "quantity": 1,
                                                                                    "weight": 0.0548,
                                                                                    "childrenWeight": 0,
                                                                                    "totalQuantityWeight": 0.0548,
                                                                                    "singleQuantityMcf": 0.283864,
                                                                                    "mcf": 0.283864,
                                                                                    "singleQuantityLcf": 0,
                                                                                    "lcf": 0,
                                                                                    "totalQuantityMcf": 0.283864,
                                                                                    "expandable": false,
                                                                                    "children": []
                                                                                }
                                                                            ]
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 833,
                                                                    "parentRowNumber": 814,
                                                                    "articleId": "47074053",
                                                                    "description": "47074053-00-Stecktuere 2.0",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 4.705100000000001,
                                                                    "childrenWeight": 4.705100000000001,
                                                                    "totalQuantityWeight": 4.705100000000001,
                                                                    "singleQuantityMcf": 23.406866,
                                                                    "mcf": 23.406866,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 23.406866,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 834,
                                                                            "parentRowNumber": 833,
                                                                            "articleId": "142603",
                                                                            "description": "142603-00-Edelstahlblech",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 4.4091000000000005,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 4.4091000000000005,
                                                                            "singleQuantityMcf": 22.839138,
                                                                            "mcf": 22.839138,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 22.839138,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        },
                                                                        {
                                                                            "rowNumber": 835,
                                                                            "parentRowNumber": 833,
                                                                            "articleId": "240788",
                                                                            "description": "240788-00-Vorreiber",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0932,
                                                                            "childrenWeight": 0.0932,
                                                                            "totalQuantityWeight": 0.0932,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": [
                                                                                {
                                                                                    "rowNumber": 836,
                                                                                    "parentRowNumber": 835,
                                                                                    "articleId": "240788_1",
                                                                                    "description": "240788_1-00-Gehause",
                                                                                    "hierarchy": 9,
                                                                                    "quantity": 1,
                                                                                    "weight": 0.0333,
                                                                                    "childrenWeight": 0,
                                                                                    "totalQuantityWeight": 0.0333,
                                                                                    "singleQuantityMcf": 0,
                                                                                    "mcf": 0,
                                                                                    "singleQuantityLcf": 0,
                                                                                    "lcf": 0,
                                                                                    "totalQuantityMcf": 0,
                                                                                    "expandable": false,
                                                                                    "children": []
                                                                                },
                                                                                {
                                                                                    "rowNumber": 837,
                                                                                    "parentRowNumber": 835,
                                                                                    "articleId": "240788_2",
                                                                                    "description": "240788_2-00-Zunge",
                                                                                    "hierarchy": 9,
                                                                                    "quantity": 1,
                                                                                    "weight": 0.0274,
                                                                                    "childrenWeight": 0,
                                                                                    "totalQuantityWeight": 0.0274,
                                                                                    "singleQuantityMcf": 0,
                                                                                    "mcf": 0,
                                                                                    "singleQuantityLcf": 0,
                                                                                    "lcf": 0,
                                                                                    "totalQuantityMcf": 0,
                                                                                    "expandable": false,
                                                                                    "children": []
                                                                                },
                                                                                {
                                                                                    "rowNumber": 838,
                                                                                    "parentRowNumber": 835,
                                                                                    "articleId": "240788_3",
                                                                                    "description": "240788_3-00-Flachdichtung",
                                                                                    "hierarchy": 9,
                                                                                    "quantity": 1,
                                                                                    "weight": 0.0009,
                                                                                    "childrenWeight": 0,
                                                                                    "totalQuantityWeight": 0.0009,
                                                                                    "singleQuantityMcf": 0,
                                                                                    "mcf": 0,
                                                                                    "singleQuantityLcf": 0,
                                                                                    "lcf": 0,
                                                                                    "totalQuantityMcf": 0,
                                                                                    "expandable": false,
                                                                                    "children": []
                                                                                },
                                                                                {
                                                                                    "rowNumber": 839,
                                                                                    "parentRowNumber": 835,
                                                                                    "articleId": "240788_4",
                                                                                    "description": "240788_4-00-Betaetigung",
                                                                                    "hierarchy": 9,
                                                                                    "quantity": 1,
                                                                                    "weight": 0.0226,
                                                                                    "childrenWeight": 0,
                                                                                    "totalQuantityWeight": 0.0226,
                                                                                    "singleQuantityMcf": 0,
                                                                                    "mcf": 0,
                                                                                    "singleQuantityLcf": 0,
                                                                                    "lcf": 0,
                                                                                    "totalQuantityMcf": 0,
                                                                                    "expandable": false,
                                                                                    "children": []
                                                                                },
                                                                                {
                                                                                    "rowNumber": 840,
                                                                                    "parentRowNumber": 835,
                                                                                    "articleId": "240788_5",
                                                                                    "description": "240788_5-00-Mutter",
                                                                                    "hierarchy": 9,
                                                                                    "quantity": 1,
                                                                                    "weight": 0.009,
                                                                                    "childrenWeight": 0,
                                                                                    "totalQuantityWeight": 0.009,
                                                                                    "singleQuantityMcf": 0,
                                                                                    "mcf": 0,
                                                                                    "singleQuantityLcf": 0,
                                                                                    "lcf": 0,
                                                                                    "totalQuantityMcf": 0,
                                                                                    "expandable": false,
                                                                                    "children": []
                                                                                }
                                                                            ]
                                                                        },
                                                                        {
                                                                            "rowNumber": 841,
                                                                            "parentRowNumber": 833,
                                                                            "articleId": "240788",
                                                                            "description": "240788-00-Vorreiber",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0932,
                                                                            "childrenWeight": 0.0932,
                                                                            "totalQuantityWeight": 0.0932,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": [
                                                                                {
                                                                                    "rowNumber": 842,
                                                                                    "parentRowNumber": 841,
                                                                                    "articleId": "240788_1",
                                                                                    "description": "240788_1-00-Gehause",
                                                                                    "hierarchy": 9,
                                                                                    "quantity": 1,
                                                                                    "weight": 0.0333,
                                                                                    "childrenWeight": 0,
                                                                                    "totalQuantityWeight": 0.0333,
                                                                                    "singleQuantityMcf": 0,
                                                                                    "mcf": 0,
                                                                                    "singleQuantityLcf": 0,
                                                                                    "lcf": 0,
                                                                                    "totalQuantityMcf": 0,
                                                                                    "expandable": false,
                                                                                    "children": []
                                                                                },
                                                                                {
                                                                                    "rowNumber": 843,
                                                                                    "parentRowNumber": 841,
                                                                                    "articleId": "240788_2",
                                                                                    "description": "240788_2-00-Zunge",
                                                                                    "hierarchy": 9,
                                                                                    "quantity": 1,
                                                                                    "weight": 0.0274,
                                                                                    "childrenWeight": 0,
                                                                                    "totalQuantityWeight": 0.0274,
                                                                                    "singleQuantityMcf": 0,
                                                                                    "mcf": 0,
                                                                                    "singleQuantityLcf": 0,
                                                                                    "lcf": 0,
                                                                                    "totalQuantityMcf": 0,
                                                                                    "expandable": false,
                                                                                    "children": []
                                                                                },
                                                                                {
                                                                                    "rowNumber": 844,
                                                                                    "parentRowNumber": 841,
                                                                                    "articleId": "240788_3",
                                                                                    "description": "240788_3-00-Flachdichtung",
                                                                                    "hierarchy": 9,
                                                                                    "quantity": 1,
                                                                                    "weight": 0.0009,
                                                                                    "childrenWeight": 0,
                                                                                    "totalQuantityWeight": 0.0009,
                                                                                    "singleQuantityMcf": 0,
                                                                                    "mcf": 0,
                                                                                    "singleQuantityLcf": 0,
                                                                                    "lcf": 0,
                                                                                    "totalQuantityMcf": 0,
                                                                                    "expandable": false,
                                                                                    "children": []
                                                                                },
                                                                                {
                                                                                    "rowNumber": 845,
                                                                                    "parentRowNumber": 841,
                                                                                    "articleId": "240788_4",
                                                                                    "description": "240788_4-00-Betaetigung",
                                                                                    "hierarchy": 9,
                                                                                    "quantity": 1,
                                                                                    "weight": 0.0226,
                                                                                    "childrenWeight": 0,
                                                                                    "totalQuantityWeight": 0.0226,
                                                                                    "singleQuantityMcf": 0,
                                                                                    "mcf": 0,
                                                                                    "singleQuantityLcf": 0,
                                                                                    "lcf": 0,
                                                                                    "totalQuantityMcf": 0,
                                                                                    "expandable": false,
                                                                                    "children": []
                                                                                },
                                                                                {
                                                                                    "rowNumber": 846,
                                                                                    "parentRowNumber": 841,
                                                                                    "articleId": "240788_5",
                                                                                    "description": "240788_5-00-Mutter",
                                                                                    "hierarchy": 9,
                                                                                    "quantity": 1,
                                                                                    "weight": 0.009,
                                                                                    "childrenWeight": 0,
                                                                                    "totalQuantityWeight": 0.009,
                                                                                    "singleQuantityMcf": 0,
                                                                                    "mcf": 0,
                                                                                    "singleQuantityLcf": 0,
                                                                                    "lcf": 0,
                                                                                    "totalQuantityMcf": 0,
                                                                                    "expandable": false,
                                                                                    "children": []
                                                                                }
                                                                            ]
                                                                        },
                                                                        {
                                                                            "rowNumber": 847,
                                                                            "parentRowNumber": 833,
                                                                            "articleId": "450927",
                                                                            "description": "450927-01-Winkel",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0548,
                                                                            "childrenWeight": 0.0548,
                                                                            "totalQuantityWeight": 0.0548,
                                                                            "singleQuantityMcf": 0.283864,
                                                                            "mcf": 0.283864,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.283864,
                                                                            "expandable": false,
                                                                            "children": [
                                                                                {
                                                                                    "rowNumber": 848,
                                                                                    "parentRowNumber": 847,
                                                                                    "articleId": "142607",
                                                                                    "description": "142607-00-Edelstahlblech",
                                                                                    "hierarchy": 9,
                                                                                    "quantity": 1,
                                                                                    "weight": 0.0548,
                                                                                    "childrenWeight": 0,
                                                                                    "totalQuantityWeight": 0.0548,
                                                                                    "singleQuantityMcf": 0.283864,
                                                                                    "mcf": 0.283864,
                                                                                    "singleQuantityLcf": 0,
                                                                                    "lcf": 0,
                                                                                    "totalQuantityMcf": 0.283864,
                                                                                    "expandable": false,
                                                                                    "children": []
                                                                                }
                                                                            ]
                                                                        },
                                                                        {
                                                                            "rowNumber": 849,
                                                                            "parentRowNumber": 833,
                                                                            "articleId": "450927",
                                                                            "description": "450927-01-Winkel",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0548,
                                                                            "childrenWeight": 0.0548,
                                                                            "totalQuantityWeight": 0.0548,
                                                                            "singleQuantityMcf": 0.283864,
                                                                            "mcf": 0.283864,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.283864,
                                                                            "expandable": false,
                                                                            "children": [
                                                                                {
                                                                                    "rowNumber": 850,
                                                                                    "parentRowNumber": 849,
                                                                                    "articleId": "142607",
                                                                                    "description": "142607-00-Edelstahlblech",
                                                                                    "hierarchy": 9,
                                                                                    "quantity": 1,
                                                                                    "weight": 0.0548,
                                                                                    "childrenWeight": 0,
                                                                                    "totalQuantityWeight": 0.0548,
                                                                                    "singleQuantityMcf": 0.283864,
                                                                                    "mcf": 0.283864,
                                                                                    "singleQuantityLcf": 0,
                                                                                    "lcf": 0,
                                                                                    "totalQuantityMcf": 0.283864,
                                                                                    "expandable": false,
                                                                                    "children": []
                                                                                }
                                                                            ]
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 851,
                                                                    "parentRowNumber": 814,
                                                                    "articleId": "47074053",
                                                                    "description": "47074053-00-Stecktuere 2.0",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 4.8915,
                                                                    "childrenWeight": 4.8915,
                                                                    "totalQuantityWeight": 4.8915,
                                                                    "singleQuantityMcf": 24.372418,
                                                                    "mcf": 24.372418,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 24.372418,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 852,
                                                                            "parentRowNumber": 851,
                                                                            "articleId": "142603",
                                                                            "description": "142603-00-Edelstahlblech",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 4.5955,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 4.5955,
                                                                            "singleQuantityMcf": 23.80469,
                                                                            "mcf": 23.80469,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 23.80469,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        },
                                                                        {
                                                                            "rowNumber": 853,
                                                                            "parentRowNumber": 851,
                                                                            "articleId": "240788",
                                                                            "description": "240788-00-Vorreiber",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0932,
                                                                            "childrenWeight": 0.0932,
                                                                            "totalQuantityWeight": 0.0932,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": [
                                                                                {
                                                                                    "rowNumber": 854,
                                                                                    "parentRowNumber": 853,
                                                                                    "articleId": "240788_1",
                                                                                    "description": "240788_1-00-Gehause",
                                                                                    "hierarchy": 9,
                                                                                    "quantity": 1,
                                                                                    "weight": 0.0333,
                                                                                    "childrenWeight": 0,
                                                                                    "totalQuantityWeight": 0.0333,
                                                                                    "singleQuantityMcf": 0,
                                                                                    "mcf": 0,
                                                                                    "singleQuantityLcf": 0,
                                                                                    "lcf": 0,
                                                                                    "totalQuantityMcf": 0,
                                                                                    "expandable": false,
                                                                                    "children": []
                                                                                },
                                                                                {
                                                                                    "rowNumber": 855,
                                                                                    "parentRowNumber": 853,
                                                                                    "articleId": "240788_2",
                                                                                    "description": "240788_2-00-Zunge",
                                                                                    "hierarchy": 9,
                                                                                    "quantity": 1,
                                                                                    "weight": 0.0274,
                                                                                    "childrenWeight": 0,
                                                                                    "totalQuantityWeight": 0.0274,
                                                                                    "singleQuantityMcf": 0,
                                                                                    "mcf": 0,
                                                                                    "singleQuantityLcf": 0,
                                                                                    "lcf": 0,
                                                                                    "totalQuantityMcf": 0,
                                                                                    "expandable": false,
                                                                                    "children": []
                                                                                },
                                                                                {
                                                                                    "rowNumber": 856,
                                                                                    "parentRowNumber": 853,
                                                                                    "articleId": "240788_3",
                                                                                    "description": "240788_3-00-Flachdichtung",
                                                                                    "hierarchy": 9,
                                                                                    "quantity": 1,
                                                                                    "weight": 0.0009,
                                                                                    "childrenWeight": 0,
                                                                                    "totalQuantityWeight": 0.0009,
                                                                                    "singleQuantityMcf": 0,
                                                                                    "mcf": 0,
                                                                                    "singleQuantityLcf": 0,
                                                                                    "lcf": 0,
                                                                                    "totalQuantityMcf": 0,
                                                                                    "expandable": false,
                                                                                    "children": []
                                                                                },
                                                                                {
                                                                                    "rowNumber": 857,
                                                                                    "parentRowNumber": 853,
                                                                                    "articleId": "240788_4",
                                                                                    "description": "240788_4-00-Betaetigung",
                                                                                    "hierarchy": 9,
                                                                                    "quantity": 1,
                                                                                    "weight": 0.0226,
                                                                                    "childrenWeight": 0,
                                                                                    "totalQuantityWeight": 0.0226,
                                                                                    "singleQuantityMcf": 0,
                                                                                    "mcf": 0,
                                                                                    "singleQuantityLcf": 0,
                                                                                    "lcf": 0,
                                                                                    "totalQuantityMcf": 0,
                                                                                    "expandable": false,
                                                                                    "children": []
                                                                                },
                                                                                {
                                                                                    "rowNumber": 858,
                                                                                    "parentRowNumber": 853,
                                                                                    "articleId": "240788_5",
                                                                                    "description": "240788_5-00-Mutter",
                                                                                    "hierarchy": 9,
                                                                                    "quantity": 1,
                                                                                    "weight": 0.009,
                                                                                    "childrenWeight": 0,
                                                                                    "totalQuantityWeight": 0.009,
                                                                                    "singleQuantityMcf": 0,
                                                                                    "mcf": 0,
                                                                                    "singleQuantityLcf": 0,
                                                                                    "lcf": 0,
                                                                                    "totalQuantityMcf": 0,
                                                                                    "expandable": false,
                                                                                    "children": []
                                                                                }
                                                                            ]
                                                                        },
                                                                        {
                                                                            "rowNumber": 859,
                                                                            "parentRowNumber": 851,
                                                                            "articleId": "240788",
                                                                            "description": "240788-00-Vorreiber",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0932,
                                                                            "childrenWeight": 0.0932,
                                                                            "totalQuantityWeight": 0.0932,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": [
                                                                                {
                                                                                    "rowNumber": 860,
                                                                                    "parentRowNumber": 859,
                                                                                    "articleId": "240788_1",
                                                                                    "description": "240788_1-00-Gehause",
                                                                                    "hierarchy": 9,
                                                                                    "quantity": 1,
                                                                                    "weight": 0.0333,
                                                                                    "childrenWeight": 0,
                                                                                    "totalQuantityWeight": 0.0333,
                                                                                    "singleQuantityMcf": 0,
                                                                                    "mcf": 0,
                                                                                    "singleQuantityLcf": 0,
                                                                                    "lcf": 0,
                                                                                    "totalQuantityMcf": 0,
                                                                                    "expandable": false,
                                                                                    "children": []
                                                                                },
                                                                                {
                                                                                    "rowNumber": 861,
                                                                                    "parentRowNumber": 859,
                                                                                    "articleId": "240788_2",
                                                                                    "description": "240788_2-00-Zunge",
                                                                                    "hierarchy": 9,
                                                                                    "quantity": 1,
                                                                                    "weight": 0.0274,
                                                                                    "childrenWeight": 0,
                                                                                    "totalQuantityWeight": 0.0274,
                                                                                    "singleQuantityMcf": 0,
                                                                                    "mcf": 0,
                                                                                    "singleQuantityLcf": 0,
                                                                                    "lcf": 0,
                                                                                    "totalQuantityMcf": 0,
                                                                                    "expandable": false,
                                                                                    "children": []
                                                                                },
                                                                                {
                                                                                    "rowNumber": 862,
                                                                                    "parentRowNumber": 859,
                                                                                    "articleId": "240788_3",
                                                                                    "description": "240788_3-00-Flachdichtung",
                                                                                    "hierarchy": 9,
                                                                                    "quantity": 1,
                                                                                    "weight": 0.0009,
                                                                                    "childrenWeight": 0,
                                                                                    "totalQuantityWeight": 0.0009,
                                                                                    "singleQuantityMcf": 0,
                                                                                    "mcf": 0,
                                                                                    "singleQuantityLcf": 0,
                                                                                    "lcf": 0,
                                                                                    "totalQuantityMcf": 0,
                                                                                    "expandable": false,
                                                                                    "children": []
                                                                                },
                                                                                {
                                                                                    "rowNumber": 863,
                                                                                    "parentRowNumber": 859,
                                                                                    "articleId": "240788_4",
                                                                                    "description": "240788_4-00-Betaetigung",
                                                                                    "hierarchy": 9,
                                                                                    "quantity": 1,
                                                                                    "weight": 0.0226,
                                                                                    "childrenWeight": 0,
                                                                                    "totalQuantityWeight": 0.0226,
                                                                                    "singleQuantityMcf": 0,
                                                                                    "mcf": 0,
                                                                                    "singleQuantityLcf": 0,
                                                                                    "lcf": 0,
                                                                                    "totalQuantityMcf": 0,
                                                                                    "expandable": false,
                                                                                    "children": []
                                                                                },
                                                                                {
                                                                                    "rowNumber": 864,
                                                                                    "parentRowNumber": 859,
                                                                                    "articleId": "240788_5",
                                                                                    "description": "240788_5-00-Mutter",
                                                                                    "hierarchy": 9,
                                                                                    "quantity": 1,
                                                                                    "weight": 0.009,
                                                                                    "childrenWeight": 0,
                                                                                    "totalQuantityWeight": 0.009,
                                                                                    "singleQuantityMcf": 0,
                                                                                    "mcf": 0,
                                                                                    "singleQuantityLcf": 0,
                                                                                    "lcf": 0,
                                                                                    "totalQuantityMcf": 0,
                                                                                    "expandable": false,
                                                                                    "children": []
                                                                                }
                                                                            ]
                                                                        },
                                                                        {
                                                                            "rowNumber": 865,
                                                                            "parentRowNumber": 851,
                                                                            "articleId": "450927",
                                                                            "description": "450927-01-Winkel",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0548,
                                                                            "childrenWeight": 0.0548,
                                                                            "totalQuantityWeight": 0.0548,
                                                                            "singleQuantityMcf": 0.283864,
                                                                            "mcf": 0.283864,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.283864,
                                                                            "expandable": false,
                                                                            "children": [
                                                                                {
                                                                                    "rowNumber": 866,
                                                                                    "parentRowNumber": 865,
                                                                                    "articleId": "142607",
                                                                                    "description": "142607-00-Edelstahlblech",
                                                                                    "hierarchy": 9,
                                                                                    "quantity": 1,
                                                                                    "weight": 0.0548,
                                                                                    "childrenWeight": 0,
                                                                                    "totalQuantityWeight": 0.0548,
                                                                                    "singleQuantityMcf": 0.283864,
                                                                                    "mcf": 0.283864,
                                                                                    "singleQuantityLcf": 0,
                                                                                    "lcf": 0,
                                                                                    "totalQuantityMcf": 0.283864,
                                                                                    "expandable": false,
                                                                                    "children": []
                                                                                }
                                                                            ]
                                                                        },
                                                                        {
                                                                            "rowNumber": 867,
                                                                            "parentRowNumber": 851,
                                                                            "articleId": "450927",
                                                                            "description": "450927-01-Winkel",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0548,
                                                                            "childrenWeight": 0.0548,
                                                                            "totalQuantityWeight": 0.0548,
                                                                            "singleQuantityMcf": 0.283864,
                                                                            "mcf": 0.283864,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.283864,
                                                                            "expandable": false,
                                                                            "children": [
                                                                                {
                                                                                    "rowNumber": 868,
                                                                                    "parentRowNumber": 867,
                                                                                    "articleId": "142607",
                                                                                    "description": "142607-00-Edelstahlblech",
                                                                                    "hierarchy": 9,
                                                                                    "quantity": 1,
                                                                                    "weight": 0.0548,
                                                                                    "childrenWeight": 0,
                                                                                    "totalQuantityWeight": 0.0548,
                                                                                    "singleQuantityMcf": 0.283864,
                                                                                    "mcf": 0.283864,
                                                                                    "singleQuantityLcf": 0,
                                                                                    "lcf": 0,
                                                                                    "totalQuantityMcf": 0.283864,
                                                                                    "expandable": false,
                                                                                    "children": []
                                                                                }
                                                                            ]
                                                                        }
                                                                    ]
                                                                }
                                                            ]
                                                        }
                                                    ]
                                                }
                                            ]
                                        }
                                    ]
                                },
                                {
                                    "rowNumber": 869,
                                    "parentRowNumber": 1,
                                    "articleId": "33847",
                                    "description": "33847-00SM-00-Sicherheit Maschinenschutz M",
                                    "hierarchy": 3,
                                    "quantity": 1,
                                    "weight": 244.98520000000025,
                                    "childrenWeight": 244.98520000000025,
                                    "totalQuantityWeight": 244.98520000000025,
                                    "singleQuantityMcf": 407.7838607999996,
                                    "mcf": 407.7838607999996,
                                    "singleQuantityLcf": 0,
                                    "lcf": 0,
                                    "totalQuantityMcf": 407.7838607999996,
                                    "expandable": false,
                                    "children": [
                                        {
                                            "rowNumber": 870,
                                            "parentRowNumber": 869,
                                            "articleId": "33847",
                                            "description": "33847-00SM10-03-Verkleidung",
                                            "hierarchy": 4,
                                            "quantity": 1,
                                            "weight": 244.98520000000025,
                                            "childrenWeight": 244.98520000000025,
                                            "totalQuantityWeight": 244.98520000000025,
                                            "singleQuantityMcf": 407.7838607999996,
                                            "mcf": 407.7838607999996,
                                            "singleQuantityLcf": 0,
                                            "lcf": 0,
                                            "totalQuantityMcf": 407.7838607999996,
                                            "expandable": false,
                                            "children": [
                                                {
                                                    "rowNumber": 871,
                                                    "parentRowNumber": 870,
                                                    "articleId": "MM0000005663",
                                                    "description": "MM0000005663-03-Verkleidung",
                                                    "hierarchy": 5,
                                                    "quantity": 1,
                                                    "weight": 244.98520000000025,
                                                    "childrenWeight": 244.98520000000025,
                                                    "totalQuantityWeight": 244.98520000000025,
                                                    "singleQuantityMcf": 407.7838607999996,
                                                    "mcf": 407.7838607999996,
                                                    "singleQuantityLcf": 0,
                                                    "lcf": 0,
                                                    "totalQuantityMcf": 407.7838607999996,
                                                    "expandable": false,
                                                    "children": [
                                                        {
                                                            "rowNumber": 872,
                                                            "parentRowNumber": 871,
                                                            "articleId": "200605015",
                                                            "description": "200605015-00-Sechskantschraube",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.004,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.004,
                                                            "singleQuantityMcf": 0.02072,
                                                            "mcf": 0.02072,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.02072,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 873,
                                                            "parentRowNumber": 871,
                                                            "articleId": "200605015",
                                                            "description": "200605015-00-Sechskantschraube",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.004,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.004,
                                                            "singleQuantityMcf": 0.02072,
                                                            "mcf": 0.02072,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.02072,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 874,
                                                            "parentRowNumber": 871,
                                                            "articleId": "20120512",
                                                            "description": "20120512-00-Sechskantschraube",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0033,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0033,
                                                            "singleQuantityMcf": 0.017093999999999998,
                                                            "mcf": 0.017093999999999998,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.017093999999999998,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 875,
                                                            "parentRowNumber": 871,
                                                            "articleId": "20120512",
                                                            "description": "20120512-00-Sechskantschraube",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0033,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0033,
                                                            "singleQuantityMcf": 0.017093999999999998,
                                                            "mcf": 0.017093999999999998,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.017093999999999998,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 876,
                                                            "parentRowNumber": 871,
                                                            "articleId": "20120515",
                                                            "description": "20120515-00-Sechskantschraube",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0039,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0039,
                                                            "singleQuantityMcf": 0.020202,
                                                            "mcf": 0.020202,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.020202,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 877,
                                                            "parentRowNumber": 871,
                                                            "articleId": "20120515",
                                                            "description": "20120515-00-Sechskantschraube",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0039,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0039,
                                                            "singleQuantityMcf": 0.020202,
                                                            "mcf": 0.020202,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.020202,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 878,
                                                            "parentRowNumber": 871,
                                                            "articleId": "20120520",
                                                            "description": "20120520-00-Sechskantschraube",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0045,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0045,
                                                            "singleQuantityMcf": 0.02331,
                                                            "mcf": 0.02331,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.02331,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 879,
                                                            "parentRowNumber": 871,
                                                            "articleId": "20120520",
                                                            "description": "20120520-00-Sechskantschraube",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0045,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0045,
                                                            "singleQuantityMcf": 0.02331,
                                                            "mcf": 0.02331,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.02331,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 880,
                                                            "parentRowNumber": 871,
                                                            "articleId": "20120630",
                                                            "description": "20120630-00-Sechskantschraube",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0094,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0094,
                                                            "singleQuantityMcf": 0.048692,
                                                            "mcf": 0.048692,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.048692,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 881,
                                                            "parentRowNumber": 871,
                                                            "articleId": "20120630",
                                                            "description": "20120630-00-Sechskantschraube",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0094,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0094,
                                                            "singleQuantityMcf": 0.048692,
                                                            "mcf": 0.048692,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.048692,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 882,
                                                            "parentRowNumber": 871,
                                                            "articleId": "20121020",
                                                            "description": "20121020-00-Sechskantschraube",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0258,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0258,
                                                            "singleQuantityMcf": 0.133644,
                                                            "mcf": 0.133644,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.133644,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 883,
                                                            "parentRowNumber": 871,
                                                            "articleId": "20121020",
                                                            "description": "20121020-00-Sechskantschraube",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0258,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0258,
                                                            "singleQuantityMcf": 0.133644,
                                                            "mcf": 0.133644,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.133644,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 884,
                                                            "parentRowNumber": 871,
                                                            "articleId": "20121020",
                                                            "description": "20121020-00-Sechskantschraube",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0258,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0258,
                                                            "singleQuantityMcf": 0.133644,
                                                            "mcf": 0.133644,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.133644,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 885,
                                                            "parentRowNumber": 871,
                                                            "articleId": "20121020",
                                                            "description": "20121020-00-Sechskantschraube",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0258,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0258,
                                                            "singleQuantityMcf": 0.133644,
                                                            "mcf": 0.133644,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.133644,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 886,
                                                            "parentRowNumber": 871,
                                                            "articleId": "20420616",
                                                            "description": "20420616-00-Senkschraube",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0042,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0042,
                                                            "singleQuantityMcf": 0.021756,
                                                            "mcf": 0.021756,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.021756,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 887,
                                                            "parentRowNumber": 871,
                                                            "articleId": "20420616",
                                                            "description": "20420616-00-Senkschraube",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0042,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0042,
                                                            "singleQuantityMcf": 0.021756,
                                                            "mcf": 0.021756,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.021756,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 888,
                                                            "parentRowNumber": 871,
                                                            "articleId": "20420616",
                                                            "description": "20420616-00-Senkschraube",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0042,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0042,
                                                            "singleQuantityMcf": 0.021756,
                                                            "mcf": 0.021756,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.021756,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 889,
                                                            "parentRowNumber": 871,
                                                            "articleId": "20420616",
                                                            "description": "20420616-00-Senkschraube",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0042,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0042,
                                                            "singleQuantityMcf": 0.021756,
                                                            "mcf": 0.021756,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.021756,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 890,
                                                            "parentRowNumber": 871,
                                                            "articleId": "205206",
                                                            "description": "205206-00-Sechskantmutter",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0026,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0026,
                                                            "singleQuantityMcf": 0.013468,
                                                            "mcf": 0.013468,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.013468,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 891,
                                                            "parentRowNumber": 871,
                                                            "articleId": "205206",
                                                            "description": "205206-00-Sechskantmutter",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0026,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0026,
                                                            "singleQuantityMcf": 0.013468,
                                                            "mcf": 0.013468,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.013468,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 892,
                                                            "parentRowNumber": 871,
                                                            "articleId": "205206",
                                                            "description": "205206-00-Sechskantmutter",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0026,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0026,
                                                            "singleQuantityMcf": 0.013468,
                                                            "mcf": 0.013468,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.013468,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 893,
                                                            "parentRowNumber": 871,
                                                            "articleId": "205206",
                                                            "description": "205206-00-Sechskantmutter",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0026,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0026,
                                                            "singleQuantityMcf": 0.013468,
                                                            "mcf": 0.013468,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.013468,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 894,
                                                            "parentRowNumber": 871,
                                                            "articleId": "205206",
                                                            "description": "205206-00-Sechskantmutter",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0026,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0026,
                                                            "singleQuantityMcf": 0.013468,
                                                            "mcf": 0.013468,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.013468,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 895,
                                                            "parentRowNumber": 871,
                                                            "articleId": "205206",
                                                            "description": "205206-00-Sechskantmutter",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0026,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0026,
                                                            "singleQuantityMcf": 0.013468,
                                                            "mcf": 0.013468,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.013468,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 896,
                                                            "parentRowNumber": 871,
                                                            "articleId": "208205",
                                                            "description": "208205-00-Scheibe",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0004,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0004,
                                                            "singleQuantityMcf": 0.002072,
                                                            "mcf": 0.002072,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.002072,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 897,
                                                            "parentRowNumber": 871,
                                                            "articleId": "208205",
                                                            "description": "208205-00-Scheibe",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0004,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0004,
                                                            "singleQuantityMcf": 0.002072,
                                                            "mcf": 0.002072,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.002072,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 898,
                                                            "parentRowNumber": 871,
                                                            "articleId": "208205",
                                                            "description": "208205-00-Scheibe",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0004,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0004,
                                                            "singleQuantityMcf": 0.002072,
                                                            "mcf": 0.002072,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.002072,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 899,
                                                            "parentRowNumber": 871,
                                                            "articleId": "208205",
                                                            "description": "208205-00-Scheibe",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0004,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0004,
                                                            "singleQuantityMcf": 0.002072,
                                                            "mcf": 0.002072,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.002072,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 900,
                                                            "parentRowNumber": 871,
                                                            "articleId": "208205",
                                                            "description": "208205-00-Scheibe",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0004,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0004,
                                                            "singleQuantityMcf": 0.002072,
                                                            "mcf": 0.002072,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.002072,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 901,
                                                            "parentRowNumber": 871,
                                                            "articleId": "208205",
                                                            "description": "208205-00-Scheibe",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0004,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0004,
                                                            "singleQuantityMcf": 0.002072,
                                                            "mcf": 0.002072,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.002072,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 902,
                                                            "parentRowNumber": 871,
                                                            "articleId": "208205",
                                                            "description": "208205-00-Scheibe",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0004,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0004,
                                                            "singleQuantityMcf": 0.002072,
                                                            "mcf": 0.002072,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.002072,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 903,
                                                            "parentRowNumber": 871,
                                                            "articleId": "208205",
                                                            "description": "208205-00-Scheibe",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0004,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0004,
                                                            "singleQuantityMcf": 0.002072,
                                                            "mcf": 0.002072,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.002072,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 904,
                                                            "parentRowNumber": 871,
                                                            "articleId": "208205",
                                                            "description": "208205-00-Scheibe",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0004,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0004,
                                                            "singleQuantityMcf": 0.002072,
                                                            "mcf": 0.002072,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.002072,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 905,
                                                            "parentRowNumber": 871,
                                                            "articleId": "208205",
                                                            "description": "208205-00-Scheibe",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0004,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0004,
                                                            "singleQuantityMcf": 0.002072,
                                                            "mcf": 0.002072,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.002072,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 906,
                                                            "parentRowNumber": 871,
                                                            "articleId": "208205",
                                                            "description": "208205-00-Scheibe",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0004,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0004,
                                                            "singleQuantityMcf": 0.002072,
                                                            "mcf": 0.002072,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.002072,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 907,
                                                            "parentRowNumber": 871,
                                                            "articleId": "208205",
                                                            "description": "208205-00-Scheibe",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0004,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0004,
                                                            "singleQuantityMcf": 0.002072,
                                                            "mcf": 0.002072,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.002072,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 908,
                                                            "parentRowNumber": 871,
                                                            "articleId": "208205",
                                                            "description": "208205-00-Scheibe",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0004,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0004,
                                                            "singleQuantityMcf": 0.002072,
                                                            "mcf": 0.002072,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.002072,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 909,
                                                            "parentRowNumber": 871,
                                                            "articleId": "208206",
                                                            "description": "208206-00-Scheibe",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0009,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0009,
                                                            "singleQuantityMcf": 0.004662,
                                                            "mcf": 0.004662,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.004662,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 910,
                                                            "parentRowNumber": 871,
                                                            "articleId": "208206",
                                                            "description": "208206-00-Scheibe",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0009,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0009,
                                                            "singleQuantityMcf": 0.004662,
                                                            "mcf": 0.004662,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.004662,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 911,
                                                            "parentRowNumber": 871,
                                                            "articleId": "208206",
                                                            "description": "208206-00-Scheibe",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0009,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0009,
                                                            "singleQuantityMcf": 0.004662,
                                                            "mcf": 0.004662,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.004662,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 912,
                                                            "parentRowNumber": 871,
                                                            "articleId": "208206",
                                                            "description": "208206-00-Scheibe",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0009,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0009,
                                                            "singleQuantityMcf": 0.004662,
                                                            "mcf": 0.004662,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.004662,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 913,
                                                            "parentRowNumber": 871,
                                                            "articleId": "208206",
                                                            "description": "208206-00-Scheibe",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0009,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0009,
                                                            "singleQuantityMcf": 0.004662,
                                                            "mcf": 0.004662,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.004662,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 914,
                                                            "parentRowNumber": 871,
                                                            "articleId": "208206",
                                                            "description": "208206-00-Scheibe",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0009,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0009,
                                                            "singleQuantityMcf": 0.004662,
                                                            "mcf": 0.004662,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.004662,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 915,
                                                            "parentRowNumber": 871,
                                                            "articleId": "208206",
                                                            "description": "208206-00-Scheibe",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0009,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0009,
                                                            "singleQuantityMcf": 0.004662,
                                                            "mcf": 0.004662,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.004662,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 916,
                                                            "parentRowNumber": 871,
                                                            "articleId": "208206",
                                                            "description": "208206-00-Scheibe",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0009,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0009,
                                                            "singleQuantityMcf": 0.004662,
                                                            "mcf": 0.004662,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.004662,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 917,
                                                            "parentRowNumber": 871,
                                                            "articleId": "208210",
                                                            "description": "208210-00-Scheibe",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0033,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0033,
                                                            "singleQuantityMcf": 0.017093999999999998,
                                                            "mcf": 0.017093999999999998,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.017093999999999998,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 918,
                                                            "parentRowNumber": 871,
                                                            "articleId": "208210",
                                                            "description": "208210-00-Scheibe",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0033,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0033,
                                                            "singleQuantityMcf": 0.017093999999999998,
                                                            "mcf": 0.017093999999999998,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.017093999999999998,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 919,
                                                            "parentRowNumber": 871,
                                                            "articleId": "208210",
                                                            "description": "208210-00-Scheibe",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0033,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0033,
                                                            "singleQuantityMcf": 0.017093999999999998,
                                                            "mcf": 0.017093999999999998,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.017093999999999998,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 920,
                                                            "parentRowNumber": 871,
                                                            "articleId": "208210",
                                                            "description": "208210-00-Scheibe",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0033,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0033,
                                                            "singleQuantityMcf": 0.017093999999999998,
                                                            "mcf": 0.017093999999999998,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.017093999999999998,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 921,
                                                            "parentRowNumber": 871,
                                                            "articleId": "457007",
                                                            "description": "457007-03-Klemmstueck",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0465,
                                                            "childrenWeight": 0.0465,
                                                            "totalQuantityWeight": 0.0465,
                                                            "singleQuantityMcf": 0.24087,
                                                            "mcf": 0.24087,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.24087,
                                                            "expandable": false,
                                                            "children": [
                                                                {
                                                                    "rowNumber": 922,
                                                                    "parentRowNumber": 921,
                                                                    "articleId": "1260038",
                                                                    "description": "1260038-00-Rundstahl",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0465,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0465,
                                                                    "singleQuantityMcf": 0.24087,
                                                                    "mcf": 0.24087,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.24087,
                                                                    "expandable": false,
                                                                    "children": []
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            "rowNumber": 923,
                                                            "parentRowNumber": 871,
                                                            "articleId": "457007",
                                                            "description": "457007-03-Klemmstueck",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0465,
                                                            "childrenWeight": 0.0465,
                                                            "totalQuantityWeight": 0.0465,
                                                            "singleQuantityMcf": 0.24087,
                                                            "mcf": 0.24087,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.24087,
                                                            "expandable": false,
                                                            "children": [
                                                                {
                                                                    "rowNumber": 924,
                                                                    "parentRowNumber": 923,
                                                                    "articleId": "1260038",
                                                                    "description": "1260038-00-Rundstahl",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0465,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0465,
                                                                    "singleQuantityMcf": 0.24087,
                                                                    "mcf": 0.24087,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.24087,
                                                                    "expandable": false,
                                                                    "children": []
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            "rowNumber": 925,
                                                            "parentRowNumber": 871,
                                                            "articleId": "457007",
                                                            "description": "457007-03-Klemmstueck",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0465,
                                                            "childrenWeight": 0.0465,
                                                            "totalQuantityWeight": 0.0465,
                                                            "singleQuantityMcf": 0.24087,
                                                            "mcf": 0.24087,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.24087,
                                                            "expandable": false,
                                                            "children": [
                                                                {
                                                                    "rowNumber": 926,
                                                                    "parentRowNumber": 925,
                                                                    "articleId": "1260038",
                                                                    "description": "1260038-00-Rundstahl",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0465,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0465,
                                                                    "singleQuantityMcf": 0.24087,
                                                                    "mcf": 0.24087,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.24087,
                                                                    "expandable": false,
                                                                    "children": []
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            "rowNumber": 927,
                                                            "parentRowNumber": 871,
                                                            "articleId": "457007",
                                                            "description": "457007-03-Klemmstueck",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0465,
                                                            "childrenWeight": 0.0465,
                                                            "totalQuantityWeight": 0.0465,
                                                            "singleQuantityMcf": 0.24087,
                                                            "mcf": 0.24087,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.24087,
                                                            "expandable": false,
                                                            "children": [
                                                                {
                                                                    "rowNumber": 928,
                                                                    "parentRowNumber": 927,
                                                                    "articleId": "1260038",
                                                                    "description": "1260038-00-Rundstahl",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0465,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0465,
                                                                    "singleQuantityMcf": 0.24087,
                                                                    "mcf": 0.24087,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.24087,
                                                                    "expandable": false,
                                                                    "children": []
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            "rowNumber": 929,
                                                            "parentRowNumber": 871,
                                                            "articleId": "457007",
                                                            "description": "457007-03-Klemmstueck",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0465,
                                                            "childrenWeight": 0.0465,
                                                            "totalQuantityWeight": 0.0465,
                                                            "singleQuantityMcf": 0.24087,
                                                            "mcf": 0.24087,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.24087,
                                                            "expandable": false,
                                                            "children": [
                                                                {
                                                                    "rowNumber": 930,
                                                                    "parentRowNumber": 929,
                                                                    "articleId": "1260038",
                                                                    "description": "1260038-00-Rundstahl",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0465,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0465,
                                                                    "singleQuantityMcf": 0.24087,
                                                                    "mcf": 0.24087,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.24087,
                                                                    "expandable": false,
                                                                    "children": []
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            "rowNumber": 931,
                                                            "parentRowNumber": 871,
                                                            "articleId": "457007",
                                                            "description": "457007-03-Klemmstueck",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0465,
                                                            "childrenWeight": 0.0465,
                                                            "totalQuantityWeight": 0.0465,
                                                            "singleQuantityMcf": 0.24087,
                                                            "mcf": 0.24087,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.24087,
                                                            "expandable": false,
                                                            "children": [
                                                                {
                                                                    "rowNumber": 932,
                                                                    "parentRowNumber": 931,
                                                                    "articleId": "1260038",
                                                                    "description": "1260038-00-Rundstahl",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0465,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0465,
                                                                    "singleQuantityMcf": 0.24087,
                                                                    "mcf": 0.24087,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.24087,
                                                                    "expandable": false,
                                                                    "children": []
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            "rowNumber": 933,
                                                            "parentRowNumber": 871,
                                                            "articleId": "457007",
                                                            "description": "457007-03-Klemmstueck",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0465,
                                                            "childrenWeight": 0.0465,
                                                            "totalQuantityWeight": 0.0465,
                                                            "singleQuantityMcf": 0.24087,
                                                            "mcf": 0.24087,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.24087,
                                                            "expandable": false,
                                                            "children": [
                                                                {
                                                                    "rowNumber": 934,
                                                                    "parentRowNumber": 933,
                                                                    "articleId": "1260038",
                                                                    "description": "1260038-00-Rundstahl",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0465,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0465,
                                                                    "singleQuantityMcf": 0.24087,
                                                                    "mcf": 0.24087,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.24087,
                                                                    "expandable": false,
                                                                    "children": []
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            "rowNumber": 935,
                                                            "parentRowNumber": 871,
                                                            "articleId": "457190",
                                                            "description": "457190-01-Distanzplatte",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0012,
                                                            "childrenWeight": 0.0012,
                                                            "totalQuantityWeight": 0.0012,
                                                            "singleQuantityMcf": 0.004079999999999999,
                                                            "mcf": 0.004079999999999999,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.004079999999999999,
                                                            "expandable": false,
                                                            "children": [
                                                                {
                                                                    "rowNumber": 936,
                                                                    "parentRowNumber": 935,
                                                                    "articleId": "162431",
                                                                    "description": "162431-00-Platte",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0012,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0012,
                                                                    "singleQuantityMcf": 0.004079999999999999,
                                                                    "mcf": 0.004079999999999999,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.004079999999999999,
                                                                    "expandable": false,
                                                                    "children": []
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            "rowNumber": 937,
                                                            "parentRowNumber": 871,
                                                            "articleId": "457190",
                                                            "description": "457190-01-Distanzplatte",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0012,
                                                            "childrenWeight": 0.0012,
                                                            "totalQuantityWeight": 0.0012,
                                                            "singleQuantityMcf": 0.004079999999999999,
                                                            "mcf": 0.004079999999999999,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.004079999999999999,
                                                            "expandable": false,
                                                            "children": [
                                                                {
                                                                    "rowNumber": 938,
                                                                    "parentRowNumber": 937,
                                                                    "articleId": "162431",
                                                                    "description": "162431-00-Platte",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0012,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0012,
                                                                    "singleQuantityMcf": 0.004079999999999999,
                                                                    "mcf": 0.004079999999999999,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.004079999999999999,
                                                                    "expandable": false,
                                                                    "children": []
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            "rowNumber": 939,
                                                            "parentRowNumber": 871,
                                                            "articleId": "457190",
                                                            "description": "457190-01-Distanzplatte",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0012,
                                                            "childrenWeight": 0.0012,
                                                            "totalQuantityWeight": 0.0012,
                                                            "singleQuantityMcf": 0.004079999999999999,
                                                            "mcf": 0.004079999999999999,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.004079999999999999,
                                                            "expandable": false,
                                                            "children": [
                                                                {
                                                                    "rowNumber": 940,
                                                                    "parentRowNumber": 939,
                                                                    "articleId": "162431",
                                                                    "description": "162431-00-Platte",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0012,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0012,
                                                                    "singleQuantityMcf": 0.004079999999999999,
                                                                    "mcf": 0.004079999999999999,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.004079999999999999,
                                                                    "expandable": false,
                                                                    "children": []
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            "rowNumber": 941,
                                                            "parentRowNumber": 871,
                                                            "articleId": "457190",
                                                            "description": "457190-01-Distanzplatte",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0012,
                                                            "childrenWeight": 0.0012,
                                                            "totalQuantityWeight": 0.0012,
                                                            "singleQuantityMcf": 0.004079999999999999,
                                                            "mcf": 0.004079999999999999,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.004079999999999999,
                                                            "expandable": false,
                                                            "children": [
                                                                {
                                                                    "rowNumber": 942,
                                                                    "parentRowNumber": 941,
                                                                    "articleId": "162431",
                                                                    "description": "162431-00-Platte",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0012,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0012,
                                                                    "singleQuantityMcf": 0.004079999999999999,
                                                                    "mcf": 0.004079999999999999,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.004079999999999999,
                                                                    "expandable": false,
                                                                    "children": []
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            "rowNumber": 943,
                                                            "parentRowNumber": 871,
                                                            "articleId": "457190",
                                                            "description": "457190-01-Distanzplatte",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0012,
                                                            "childrenWeight": 0.0012,
                                                            "totalQuantityWeight": 0.0012,
                                                            "singleQuantityMcf": 0.004079999999999999,
                                                            "mcf": 0.004079999999999999,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.004079999999999999,
                                                            "expandable": false,
                                                            "children": [
                                                                {
                                                                    "rowNumber": 944,
                                                                    "parentRowNumber": 943,
                                                                    "articleId": "162431",
                                                                    "description": "162431-00-Platte",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0012,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0012,
                                                                    "singleQuantityMcf": 0.004079999999999999,
                                                                    "mcf": 0.004079999999999999,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.004079999999999999,
                                                                    "expandable": false,
                                                                    "children": []
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            "rowNumber": 945,
                                                            "parentRowNumber": 871,
                                                            "articleId": "457190",
                                                            "description": "457190-01-Distanzplatte",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0012,
                                                            "childrenWeight": 0.0012,
                                                            "totalQuantityWeight": 0.0012,
                                                            "singleQuantityMcf": 0.004079999999999999,
                                                            "mcf": 0.004079999999999999,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.004079999999999999,
                                                            "expandable": false,
                                                            "children": [
                                                                {
                                                                    "rowNumber": 946,
                                                                    "parentRowNumber": 945,
                                                                    "articleId": "162431",
                                                                    "description": "162431-00-Platte",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0012,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0012,
                                                                    "singleQuantityMcf": 0.004079999999999999,
                                                                    "mcf": 0.004079999999999999,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.004079999999999999,
                                                                    "expandable": false,
                                                                    "children": []
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            "rowNumber": 947,
                                                            "parentRowNumber": 871,
                                                            "articleId": "457190",
                                                            "description": "457190-01-Distanzplatte",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0012,
                                                            "childrenWeight": 0.0012,
                                                            "totalQuantityWeight": 0.0012,
                                                            "singleQuantityMcf": 0.004079999999999999,
                                                            "mcf": 0.004079999999999999,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.004079999999999999,
                                                            "expandable": false,
                                                            "children": [
                                                                {
                                                                    "rowNumber": 948,
                                                                    "parentRowNumber": 947,
                                                                    "articleId": "162431",
                                                                    "description": "162431-00-Platte",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0012,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0012,
                                                                    "singleQuantityMcf": 0.004079999999999999,
                                                                    "mcf": 0.004079999999999999,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.004079999999999999,
                                                                    "expandable": false,
                                                                    "children": []
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            "rowNumber": 949,
                                                            "parentRowNumber": 871,
                                                            "articleId": "457194",
                                                            "description": "457194-01-Distanzplatte",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0008,
                                                            "childrenWeight": 0.0008,
                                                            "totalQuantityWeight": 0.0008,
                                                            "singleQuantityMcf": 0.00272,
                                                            "mcf": 0.00272,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.00272,
                                                            "expandable": false,
                                                            "children": [
                                                                {
                                                                    "rowNumber": 950,
                                                                    "parentRowNumber": 949,
                                                                    "articleId": "162431",
                                                                    "description": "162431-00-Platte",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0008,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0008,
                                                                    "singleQuantityMcf": 0.00272,
                                                                    "mcf": 0.00272,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.00272,
                                                                    "expandable": false,
                                                                    "children": []
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            "rowNumber": 951,
                                                            "parentRowNumber": 871,
                                                            "articleId": "457194",
                                                            "description": "457194-01-Distanzplatte",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0008,
                                                            "childrenWeight": 0.0008,
                                                            "totalQuantityWeight": 0.0008,
                                                            "singleQuantityMcf": 0.00272,
                                                            "mcf": 0.00272,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.00272,
                                                            "expandable": false,
                                                            "children": [
                                                                {
                                                                    "rowNumber": 952,
                                                                    "parentRowNumber": 951,
                                                                    "articleId": "162431",
                                                                    "description": "162431-00-Platte",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0008,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0008,
                                                                    "singleQuantityMcf": 0.00272,
                                                                    "mcf": 0.00272,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.00272,
                                                                    "expandable": false,
                                                                    "children": []
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            "rowNumber": 953,
                                                            "parentRowNumber": 871,
                                                            "articleId": "457194",
                                                            "description": "457194-01-Distanzplatte",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0008,
                                                            "childrenWeight": 0.0008,
                                                            "totalQuantityWeight": 0.0008,
                                                            "singleQuantityMcf": 0.00272,
                                                            "mcf": 0.00272,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.00272,
                                                            "expandable": false,
                                                            "children": [
                                                                {
                                                                    "rowNumber": 954,
                                                                    "parentRowNumber": 953,
                                                                    "articleId": "162431",
                                                                    "description": "162431-00-Platte",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0008,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0008,
                                                                    "singleQuantityMcf": 0.00272,
                                                                    "mcf": 0.00272,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.00272,
                                                                    "expandable": false,
                                                                    "children": []
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            "rowNumber": 955,
                                                            "parentRowNumber": 871,
                                                            "articleId": "457194",
                                                            "description": "457194-01-Distanzplatte",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0008,
                                                            "childrenWeight": 0.0008,
                                                            "totalQuantityWeight": 0.0008,
                                                            "singleQuantityMcf": 0.00272,
                                                            "mcf": 0.00272,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.00272,
                                                            "expandable": false,
                                                            "children": [
                                                                {
                                                                    "rowNumber": 956,
                                                                    "parentRowNumber": 955,
                                                                    "articleId": "162431",
                                                                    "description": "162431-00-Platte",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0008,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0008,
                                                                    "singleQuantityMcf": 0.00272,
                                                                    "mcf": 0.00272,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.00272,
                                                                    "expandable": false,
                                                                    "children": []
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            "rowNumber": 957,
                                                            "parentRowNumber": 871,
                                                            "articleId": "457195",
                                                            "description": "457195-01-Distanz",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0,
                                                            "singleQuantityMcf": 0,
                                                            "mcf": 0,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0,
                                                            "expandable": false,
                                                            "children": [
                                                                {
                                                                    "rowNumber": 958,
                                                                    "parentRowNumber": 957,
                                                                    "articleId": "3859213",
                                                                    "description": "3859213-00-Schlauch",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": []
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            "rowNumber": 959,
                                                            "parentRowNumber": 871,
                                                            "articleId": "457195",
                                                            "description": "457195-01-Distanz",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0,
                                                            "singleQuantityMcf": 0,
                                                            "mcf": 0,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0,
                                                            "expandable": false,
                                                            "children": [
                                                                {
                                                                    "rowNumber": 960,
                                                                    "parentRowNumber": 959,
                                                                    "articleId": "3859213",
                                                                    "description": "3859213-00-Schlauch",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": []
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            "rowNumber": 961,
                                                            "parentRowNumber": 871,
                                                            "articleId": "457195",
                                                            "description": "457195-01-Distanz",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0,
                                                            "singleQuantityMcf": 0,
                                                            "mcf": 0,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0,
                                                            "expandable": false,
                                                            "children": [
                                                                {
                                                                    "rowNumber": 962,
                                                                    "parentRowNumber": 961,
                                                                    "articleId": "3859213",
                                                                    "description": "3859213-00-Schlauch",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": []
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            "rowNumber": 963,
                                                            "parentRowNumber": 871,
                                                            "articleId": "457195",
                                                            "description": "457195-01-Distanz",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0,
                                                            "singleQuantityMcf": 0,
                                                            "mcf": 0,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0,
                                                            "expandable": false,
                                                            "children": [
                                                                {
                                                                    "rowNumber": 964,
                                                                    "parentRowNumber": 963,
                                                                    "articleId": "3859213",
                                                                    "description": "3859213-00-Schlauch",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": []
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            "rowNumber": 965,
                                                            "parentRowNumber": 871,
                                                            "articleId": "457195",
                                                            "description": "457195-01-Distanz",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0,
                                                            "singleQuantityMcf": 0,
                                                            "mcf": 0,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0,
                                                            "expandable": false,
                                                            "children": [
                                                                {
                                                                    "rowNumber": 966,
                                                                    "parentRowNumber": 965,
                                                                    "articleId": "3859213",
                                                                    "description": "3859213-00-Schlauch",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": []
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            "rowNumber": 967,
                                                            "parentRowNumber": 871,
                                                            "articleId": "457195",
                                                            "description": "457195-01-Distanz",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0,
                                                            "singleQuantityMcf": 0,
                                                            "mcf": 0,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0,
                                                            "expandable": false,
                                                            "children": [
                                                                {
                                                                    "rowNumber": 968,
                                                                    "parentRowNumber": 967,
                                                                    "articleId": "3859213",
                                                                    "description": "3859213-00-Schlauch",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": []
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            "rowNumber": 969,
                                                            "parentRowNumber": 871,
                                                            "articleId": "457195",
                                                            "description": "457195-01-Distanz",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0,
                                                            "singleQuantityMcf": 0,
                                                            "mcf": 0,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0,
                                                            "expandable": false,
                                                            "children": [
                                                                {
                                                                    "rowNumber": 970,
                                                                    "parentRowNumber": 969,
                                                                    "articleId": "3859213",
                                                                    "description": "3859213-00-Schlauch",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": []
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            "rowNumber": 971,
                                                            "parentRowNumber": 871,
                                                            "articleId": "46024147",
                                                            "description": "46024147-00-Scheibe",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0011,
                                                            "childrenWeight": 0.0011,
                                                            "totalQuantityWeight": 0.0011,
                                                            "singleQuantityMcf": 0,
                                                            "mcf": 0,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0,
                                                            "expandable": false,
                                                            "children": [
                                                                {
                                                                    "rowNumber": 972,
                                                                    "parentRowNumber": 971,
                                                                    "articleId": "163201",
                                                                    "description": "163201-00-Gummiplatte",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0011,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0011,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": []
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            "rowNumber": 973,
                                                            "parentRowNumber": 871,
                                                            "articleId": "46029594",
                                                            "description": "46029594-01-Scheibe",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0007,
                                                            "childrenWeight": 0.0007,
                                                            "totalQuantityWeight": 0.0007,
                                                            "singleQuantityMcf": 0,
                                                            "mcf": 0,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0,
                                                            "expandable": false,
                                                            "children": [
                                                                {
                                                                    "rowNumber": 974,
                                                                    "parentRowNumber": 973,
                                                                    "articleId": "163201",
                                                                    "description": "163201-00-Gummiplatte",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0007,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0007,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": []
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            "rowNumber": 975,
                                                            "parentRowNumber": 871,
                                                            "articleId": "46029594",
                                                            "description": "46029594-01-Scheibe",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0007,
                                                            "childrenWeight": 0.0007,
                                                            "totalQuantityWeight": 0.0007,
                                                            "singleQuantityMcf": 0,
                                                            "mcf": 0,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0,
                                                            "expandable": false,
                                                            "children": [
                                                                {
                                                                    "rowNumber": 976,
                                                                    "parentRowNumber": 975,
                                                                    "articleId": "163201",
                                                                    "description": "163201-00-Gummiplatte",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0007,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0007,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": []
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            "rowNumber": 977,
                                                            "parentRowNumber": 871,
                                                            "articleId": "46029594",
                                                            "description": "46029594-01-Scheibe",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0007,
                                                            "childrenWeight": 0.0007,
                                                            "totalQuantityWeight": 0.0007,
                                                            "singleQuantityMcf": 0,
                                                            "mcf": 0,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0,
                                                            "expandable": false,
                                                            "children": [
                                                                {
                                                                    "rowNumber": 978,
                                                                    "parentRowNumber": 977,
                                                                    "articleId": "163201",
                                                                    "description": "163201-00-Gummiplatte",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0007,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0007,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": []
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            "rowNumber": 979,
                                                            "parentRowNumber": 871,
                                                            "articleId": "46029594",
                                                            "description": "46029594-01-Scheibe",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0007,
                                                            "childrenWeight": 0.0007,
                                                            "totalQuantityWeight": 0.0007,
                                                            "singleQuantityMcf": 0,
                                                            "mcf": 0,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0,
                                                            "expandable": false,
                                                            "children": [
                                                                {
                                                                    "rowNumber": 980,
                                                                    "parentRowNumber": 979,
                                                                    "articleId": "163201",
                                                                    "description": "163201-00-Gummiplatte",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0007,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0007,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": []
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            "rowNumber": 981,
                                                            "parentRowNumber": 871,
                                                            "articleId": "46125780",
                                                            "description": "46125780-00-Sechskantschraube",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0079,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0079,
                                                            "singleQuantityMcf": 0.040922,
                                                            "mcf": 0.040922,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.040922,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 982,
                                                            "parentRowNumber": 871,
                                                            "articleId": "46125780",
                                                            "description": "46125780-00-Sechskantschraube",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0079,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0079,
                                                            "singleQuantityMcf": 0.040922,
                                                            "mcf": 0.040922,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.040922,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 983,
                                                            "parentRowNumber": 871,
                                                            "articleId": "46125780",
                                                            "description": "46125780-00-Sechskantschraube",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0079,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0079,
                                                            "singleQuantityMcf": 0.040922,
                                                            "mcf": 0.040922,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.040922,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 984,
                                                            "parentRowNumber": 871,
                                                            "articleId": "46125780",
                                                            "description": "46125780-00-Sechskantschraube",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0079,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0079,
                                                            "singleQuantityMcf": 0.040922,
                                                            "mcf": 0.040922,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.040922,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 985,
                                                            "parentRowNumber": 871,
                                                            "articleId": "46125780",
                                                            "description": "46125780-00-Sechskantschraube",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0079,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0079,
                                                            "singleQuantityMcf": 0.040922,
                                                            "mcf": 0.040922,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.040922,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 986,
                                                            "parentRowNumber": 871,
                                                            "articleId": "46320312",
                                                            "description": "46320312-04-Schutzpfosten oben",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 1.5921000000000003,
                                                            "childrenWeight": 1.5921000000000003,
                                                            "totalQuantityWeight": 1.5921000000000003,
                                                            "singleQuantityMcf": 7.716785800000004,
                                                            "mcf": 7.716785800000004,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 7.716785800000004,
                                                            "expandable": false,
                                                            "children": [
                                                                {
                                                                    "rowNumber": 987,
                                                                    "parentRowNumber": 986,
                                                                    "articleId": "20270690",
                                                                    "description": "20270690-00-Zylinderschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0234,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0234,
                                                                    "singleQuantityMcf": 0.121212,
                                                                    "mcf": 0.121212,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.121212,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 988,
                                                                    "parentRowNumber": 986,
                                                                    "articleId": "208205",
                                                                    "description": "208205-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0004,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0004,
                                                                    "singleQuantityMcf": 0.002072,
                                                                    "mcf": 0.002072,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.002072,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 989,
                                                                    "parentRowNumber": 986,
                                                                    "articleId": "208205",
                                                                    "description": "208205-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0004,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0004,
                                                                    "singleQuantityMcf": 0.002072,
                                                                    "mcf": 0.002072,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.002072,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 990,
                                                                    "parentRowNumber": 986,
                                                                    "articleId": "208205",
                                                                    "description": "208205-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0004,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0004,
                                                                    "singleQuantityMcf": 0.002072,
                                                                    "mcf": 0.002072,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.002072,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 991,
                                                                    "parentRowNumber": 986,
                                                                    "articleId": "208205",
                                                                    "description": "208205-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0004,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0004,
                                                                    "singleQuantityMcf": 0.002072,
                                                                    "mcf": 0.002072,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.002072,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 992,
                                                                    "parentRowNumber": 986,
                                                                    "articleId": "216232",
                                                                    "description": "216232-00-Sicherungsring",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0023,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0023,
                                                                    "singleQuantityMcf": 0.0039376,
                                                                    "mcf": 0.0039376,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.0039376,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 993,
                                                                    "parentRowNumber": 986,
                                                                    "articleId": "216232",
                                                                    "description": "216232-00-Sicherungsring",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0023,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0023,
                                                                    "singleQuantityMcf": 0.0039376,
                                                                    "mcf": 0.0039376,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.0039376,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 994,
                                                                    "parentRowNumber": 986,
                                                                    "articleId": "216232",
                                                                    "description": "216232-00-Sicherungsring",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0023,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0023,
                                                                    "singleQuantityMcf": 0.0039376,
                                                                    "mcf": 0.0039376,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.0039376,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 995,
                                                                    "parentRowNumber": 986,
                                                                    "articleId": "457007",
                                                                    "description": "457007-03-Klemmstueck",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0465,
                                                                    "childrenWeight": 0.0465,
                                                                    "totalQuantityWeight": 0.0465,
                                                                    "singleQuantityMcf": 0.24087,
                                                                    "mcf": 0.24087,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.24087,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 996,
                                                                            "parentRowNumber": 995,
                                                                            "articleId": "1260038",
                                                                            "description": "1260038-00-Rundstahl",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0465,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0465,
                                                                            "singleQuantityMcf": 0.24087,
                                                                            "mcf": 0.24087,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.24087,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 997,
                                                                    "parentRowNumber": 986,
                                                                    "articleId": "457007",
                                                                    "description": "457007-03-Klemmstueck",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0465,
                                                                    "childrenWeight": 0.0465,
                                                                    "totalQuantityWeight": 0.0465,
                                                                    "singleQuantityMcf": 0.24087,
                                                                    "mcf": 0.24087,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.24087,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 998,
                                                                            "parentRowNumber": 997,
                                                                            "articleId": "1260038",
                                                                            "description": "1260038-00-Rundstahl",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0465,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0465,
                                                                            "singleQuantityMcf": 0.24087,
                                                                            "mcf": 0.24087,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.24087,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 999,
                                                                    "parentRowNumber": 986,
                                                                    "articleId": "457007",
                                                                    "description": "457007-03-Klemmstueck",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0465,
                                                                    "childrenWeight": 0.0465,
                                                                    "totalQuantityWeight": 0.0465,
                                                                    "singleQuantityMcf": 0.24087,
                                                                    "mcf": 0.24087,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.24087,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 1000,
                                                                            "parentRowNumber": 999,
                                                                            "articleId": "1260038",
                                                                            "description": "1260038-00-Rundstahl",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0465,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0465,
                                                                            "singleQuantityMcf": 0.24087,
                                                                            "mcf": 0.24087,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.24087,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 1001,
                                                                    "parentRowNumber": 986,
                                                                    "articleId": "457007",
                                                                    "description": "457007-03-Klemmstueck",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0465,
                                                                    "childrenWeight": 0.0465,
                                                                    "totalQuantityWeight": 0.0465,
                                                                    "singleQuantityMcf": 0.24087,
                                                                    "mcf": 0.24087,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.24087,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 1002,
                                                                            "parentRowNumber": 1001,
                                                                            "articleId": "1260038",
                                                                            "description": "1260038-00-Rundstahl",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0465,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0465,
                                                                            "singleQuantityMcf": 0.24087,
                                                                            "mcf": 0.24087,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.24087,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 1003,
                                                                    "parentRowNumber": 986,
                                                                    "articleId": "457051",
                                                                    "description": "457051-03-Distanz",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0077,
                                                                    "childrenWeight": 0.0077,
                                                                    "totalQuantityWeight": 0.0077,
                                                                    "singleQuantityMcf": 0.013321,
                                                                    "mcf": 0.013321,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.013321,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 1004,
                                                                            "parentRowNumber": 1003,
                                                                            "articleId": "164710",
                                                                            "description": "164710-00-Hohlstab",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0077,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0077,
                                                                            "singleQuantityMcf": 0.013321,
                                                                            "mcf": 0.013321,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.013321,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 1005,
                                                                    "parentRowNumber": 986,
                                                                    "articleId": "457051",
                                                                    "description": "457051-03-Distanz",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0077,
                                                                    "childrenWeight": 0.0077,
                                                                    "totalQuantityWeight": 0.0077,
                                                                    "singleQuantityMcf": 0.013321,
                                                                    "mcf": 0.013321,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.013321,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 1006,
                                                                            "parentRowNumber": 1005,
                                                                            "articleId": "164710",
                                                                            "description": "164710-00-Hohlstab",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0077,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0077,
                                                                            "singleQuantityMcf": 0.013321,
                                                                            "mcf": 0.013321,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.013321,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 1007,
                                                                    "parentRowNumber": 986,
                                                                    "articleId": "457051",
                                                                    "description": "457051-03-Distanz",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0077,
                                                                    "childrenWeight": 0.0077,
                                                                    "totalQuantityWeight": 0.0077,
                                                                    "singleQuantityMcf": 0.013321,
                                                                    "mcf": 0.013321,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.013321,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 1008,
                                                                            "parentRowNumber": 1007,
                                                                            "articleId": "164710",
                                                                            "description": "164710-00-Hohlstab",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0077,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0077,
                                                                            "singleQuantityMcf": 0.013321,
                                                                            "mcf": 0.013321,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.013321,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 1009,
                                                                    "parentRowNumber": 986,
                                                                    "articleId": "457190",
                                                                    "description": "457190-01-Distanzplatte",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0012,
                                                                    "childrenWeight": 0.0012,
                                                                    "totalQuantityWeight": 0.0012,
                                                                    "singleQuantityMcf": 0.004079999999999999,
                                                                    "mcf": 0.004079999999999999,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.004079999999999999,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 1010,
                                                                            "parentRowNumber": 1009,
                                                                            "articleId": "162431",
                                                                            "description": "162431-00-Platte",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0012,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0012,
                                                                            "singleQuantityMcf": 0.004079999999999999,
                                                                            "mcf": 0.004079999999999999,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.004079999999999999,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 1011,
                                                                    "parentRowNumber": 986,
                                                                    "articleId": "457190",
                                                                    "description": "457190-01-Distanzplatte",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0012,
                                                                    "childrenWeight": 0.0012,
                                                                    "totalQuantityWeight": 0.0012,
                                                                    "singleQuantityMcf": 0.004079999999999999,
                                                                    "mcf": 0.004079999999999999,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.004079999999999999,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 1012,
                                                                            "parentRowNumber": 1011,
                                                                            "articleId": "162431",
                                                                            "description": "162431-00-Platte",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0012,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0012,
                                                                            "singleQuantityMcf": 0.004079999999999999,
                                                                            "mcf": 0.004079999999999999,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.004079999999999999,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 1013,
                                                                    "parentRowNumber": 986,
                                                                    "articleId": "457190",
                                                                    "description": "457190-01-Distanzplatte",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0012,
                                                                    "childrenWeight": 0.0012,
                                                                    "totalQuantityWeight": 0.0012,
                                                                    "singleQuantityMcf": 0.004079999999999999,
                                                                    "mcf": 0.004079999999999999,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.004079999999999999,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 1014,
                                                                            "parentRowNumber": 1013,
                                                                            "articleId": "162431",
                                                                            "description": "162431-00-Platte",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0012,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0012,
                                                                            "singleQuantityMcf": 0.004079999999999999,
                                                                            "mcf": 0.004079999999999999,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.004079999999999999,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 1015,
                                                                    "parentRowNumber": 986,
                                                                    "articleId": "457190",
                                                                    "description": "457190-01-Distanzplatte",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0012,
                                                                    "childrenWeight": 0.0012,
                                                                    "totalQuantityWeight": 0.0012,
                                                                    "singleQuantityMcf": 0.004079999999999999,
                                                                    "mcf": 0.004079999999999999,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.004079999999999999,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 1016,
                                                                            "parentRowNumber": 1015,
                                                                            "articleId": "162431",
                                                                            "description": "162431-00-Platte",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0012,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0012,
                                                                            "singleQuantityMcf": 0.004079999999999999,
                                                                            "mcf": 0.004079999999999999,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.004079999999999999,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 1017,
                                                                    "parentRowNumber": 986,
                                                                    "articleId": "457193",
                                                                    "description": "457193-01-Distanzplatte",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0021,
                                                                    "childrenWeight": 0.0021,
                                                                    "totalQuantityWeight": 0.0021,
                                                                    "singleQuantityMcf": 0.00714,
                                                                    "mcf": 0.00714,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.00714,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 1018,
                                                                            "parentRowNumber": 1017,
                                                                            "articleId": "162431",
                                                                            "description": "162431-00-Platte",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0021,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0021,
                                                                            "singleQuantityMcf": 0.00714,
                                                                            "mcf": 0.00714,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.00714,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 1019,
                                                                    "parentRowNumber": 986,
                                                                    "articleId": "457193",
                                                                    "description": "457193-01-Distanzplatte",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0021,
                                                                    "childrenWeight": 0.0021,
                                                                    "totalQuantityWeight": 0.0021,
                                                                    "singleQuantityMcf": 0.00714,
                                                                    "mcf": 0.00714,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.00714,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 1020,
                                                                            "parentRowNumber": 1019,
                                                                            "articleId": "162431",
                                                                            "description": "162431-00-Platte",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0021,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0021,
                                                                            "singleQuantityMcf": 0.00714,
                                                                            "mcf": 0.00714,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.00714,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 1021,
                                                                    "parentRowNumber": 986,
                                                                    "articleId": "457193",
                                                                    "description": "457193-01-Distanzplatte",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0021,
                                                                    "childrenWeight": 0.0021,
                                                                    "totalQuantityWeight": 0.0021,
                                                                    "singleQuantityMcf": 0.00714,
                                                                    "mcf": 0.00714,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.00714,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 1022,
                                                                            "parentRowNumber": 1021,
                                                                            "articleId": "162431",
                                                                            "description": "162431-00-Platte",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0021,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0021,
                                                                            "singleQuantityMcf": 0.00714,
                                                                            "mcf": 0.00714,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.00714,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 1023,
                                                                    "parentRowNumber": 986,
                                                                    "articleId": "457193",
                                                                    "description": "457193-01-Distanzplatte",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0021,
                                                                    "childrenWeight": 0.0021,
                                                                    "totalQuantityWeight": 0.0021,
                                                                    "singleQuantityMcf": 0.00714,
                                                                    "mcf": 0.00714,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.00714,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 1024,
                                                                            "parentRowNumber": 1023,
                                                                            "articleId": "162431",
                                                                            "description": "162431-00-Platte",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0021,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0021,
                                                                            "singleQuantityMcf": 0.00714,
                                                                            "mcf": 0.00714,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.00714,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 1025,
                                                                    "parentRowNumber": 986,
                                                                    "articleId": "457194",
                                                                    "description": "457194-01-Distanzplatte",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0008,
                                                                    "childrenWeight": 0.0008,
                                                                    "totalQuantityWeight": 0.0008,
                                                                    "singleQuantityMcf": 0.00272,
                                                                    "mcf": 0.00272,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.00272,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 1026,
                                                                            "parentRowNumber": 1025,
                                                                            "articleId": "162431",
                                                                            "description": "162431-00-Platte",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0008,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0008,
                                                                            "singleQuantityMcf": 0.00272,
                                                                            "mcf": 0.00272,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.00272,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 1027,
                                                                    "parentRowNumber": 986,
                                                                    "articleId": "457194",
                                                                    "description": "457194-01-Distanzplatte",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0008,
                                                                    "childrenWeight": 0.0008,
                                                                    "totalQuantityWeight": 0.0008,
                                                                    "singleQuantityMcf": 0.00272,
                                                                    "mcf": 0.00272,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.00272,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 1028,
                                                                            "parentRowNumber": 1027,
                                                                            "articleId": "162431",
                                                                            "description": "162431-00-Platte",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0008,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0008,
                                                                            "singleQuantityMcf": 0.00272,
                                                                            "mcf": 0.00272,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.00272,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 1029,
                                                                    "parentRowNumber": 986,
                                                                    "articleId": "457194",
                                                                    "description": "457194-01-Distanzplatte",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0008,
                                                                    "childrenWeight": 0.0008,
                                                                    "totalQuantityWeight": 0.0008,
                                                                    "singleQuantityMcf": 0.00272,
                                                                    "mcf": 0.00272,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.00272,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 1030,
                                                                            "parentRowNumber": 1029,
                                                                            "articleId": "162431",
                                                                            "description": "162431-00-Platte",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0008,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0008,
                                                                            "singleQuantityMcf": 0.00272,
                                                                            "mcf": 0.00272,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.00272,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 1031,
                                                                    "parentRowNumber": 986,
                                                                    "articleId": "457194",
                                                                    "description": "457194-01-Distanzplatte",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0008,
                                                                    "childrenWeight": 0.0008,
                                                                    "totalQuantityWeight": 0.0008,
                                                                    "singleQuantityMcf": 0.00272,
                                                                    "mcf": 0.00272,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.00272,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 1032,
                                                                            "parentRowNumber": 1031,
                                                                            "articleId": "162431",
                                                                            "description": "162431-00-Platte",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0008,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0008,
                                                                            "singleQuantityMcf": 0.00272,
                                                                            "mcf": 0.00272,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.00272,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 1033,
                                                                    "parentRowNumber": 986,
                                                                    "articleId": "457195",
                                                                    "description": "457195-01-Distanz",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 1034,
                                                                            "parentRowNumber": 1033,
                                                                            "articleId": "3859213",
                                                                            "description": "3859213-00-Schlauch",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 1035,
                                                                    "parentRowNumber": 986,
                                                                    "articleId": "457195",
                                                                    "description": "457195-01-Distanz",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 1036,
                                                                            "parentRowNumber": 1035,
                                                                            "articleId": "3859213",
                                                                            "description": "3859213-00-Schlauch",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 1037,
                                                                    "parentRowNumber": 986,
                                                                    "articleId": "457195",
                                                                    "description": "457195-01-Distanz",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 1038,
                                                                            "parentRowNumber": 1037,
                                                                            "articleId": "3859213",
                                                                            "description": "3859213-00-Schlauch",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 1039,
                                                                    "parentRowNumber": 986,
                                                                    "articleId": "457195",
                                                                    "description": "457195-01-Distanz",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 1040,
                                                                            "parentRowNumber": 1039,
                                                                            "articleId": "3859213",
                                                                            "description": "3859213-00-Schlauch",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 1041,
                                                                    "parentRowNumber": 986,
                                                                    "articleId": "46024147",
                                                                    "description": "46024147-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0011,
                                                                    "childrenWeight": 0.0011,
                                                                    "totalQuantityWeight": 0.0011,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 1042,
                                                                            "parentRowNumber": 1041,
                                                                            "articleId": "163201",
                                                                            "description": "163201-00-Gummiplatte",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0011,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0011,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 1043,
                                                                    "parentRowNumber": 986,
                                                                    "articleId": "46024147",
                                                                    "description": "46024147-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0011,
                                                                    "childrenWeight": 0.0011,
                                                                    "totalQuantityWeight": 0.0011,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 1044,
                                                                            "parentRowNumber": 1043,
                                                                            "articleId": "163201",
                                                                            "description": "163201-00-Gummiplatte",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0011,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0011,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 1045,
                                                                    "parentRowNumber": 986,
                                                                    "articleId": "46024147",
                                                                    "description": "46024147-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0011,
                                                                    "childrenWeight": 0.0011,
                                                                    "totalQuantityWeight": 0.0011,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 1046,
                                                                            "parentRowNumber": 1045,
                                                                            "articleId": "163201",
                                                                            "description": "163201-00-Gummiplatte",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0011,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0011,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 1047,
                                                                    "parentRowNumber": 986,
                                                                    "articleId": "46024147",
                                                                    "description": "46024147-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0011,
                                                                    "childrenWeight": 0.0011,
                                                                    "totalQuantityWeight": 0.0011,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 1048,
                                                                            "parentRowNumber": 1047,
                                                                            "articleId": "163201",
                                                                            "description": "163201-00-Gummiplatte",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0011,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0011,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 1049,
                                                                    "parentRowNumber": 986,
                                                                    "articleId": "46029594",
                                                                    "description": "46029594-01-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0007,
                                                                    "childrenWeight": 0.0007,
                                                                    "totalQuantityWeight": 0.0007,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 1050,
                                                                            "parentRowNumber": 1049,
                                                                            "articleId": "163201",
                                                                            "description": "163201-00-Gummiplatte",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0007,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0007,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 1051,
                                                                    "parentRowNumber": 986,
                                                                    "articleId": "46029594",
                                                                    "description": "46029594-01-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0007,
                                                                    "childrenWeight": 0.0007,
                                                                    "totalQuantityWeight": 0.0007,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 1052,
                                                                            "parentRowNumber": 1051,
                                                                            "articleId": "163201",
                                                                            "description": "163201-00-Gummiplatte",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0007,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0007,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 1053,
                                                                    "parentRowNumber": 986,
                                                                    "articleId": "46029594",
                                                                    "description": "46029594-01-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0007,
                                                                    "childrenWeight": 0.0007,
                                                                    "totalQuantityWeight": 0.0007,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 1054,
                                                                            "parentRowNumber": 1053,
                                                                            "articleId": "163201",
                                                                            "description": "163201-00-Gummiplatte",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0007,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0007,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 1055,
                                                                    "parentRowNumber": 986,
                                                                    "articleId": "46029594",
                                                                    "description": "46029594-01-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0007,
                                                                    "childrenWeight": 0.0007,
                                                                    "totalQuantityWeight": 0.0007,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 1056,
                                                                            "parentRowNumber": 1055,
                                                                            "articleId": "163201",
                                                                            "description": "163201-00-Gummiplatte",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0007,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0007,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 1057,
                                                                    "parentRowNumber": 986,
                                                                    "articleId": "46101957",
                                                                    "description": "46101957-01-Buchse",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0174,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0174,
                                                                    "singleQuantityMcf": 0.030102,
                                                                    "mcf": 0.030102,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.030102,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 1058,
                                                                    "parentRowNumber": 986,
                                                                    "articleId": "46101957",
                                                                    "description": "46101957-01-Buchse",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0174,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0174,
                                                                    "singleQuantityMcf": 0.030102,
                                                                    "mcf": 0.030102,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.030102,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 1059,
                                                                    "parentRowNumber": 986,
                                                                    "articleId": "46101957",
                                                                    "description": "46101957-01-Buchse",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0174,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0174,
                                                                    "singleQuantityMcf": 0.030102,
                                                                    "mcf": 0.030102,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.030102,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 1060,
                                                                    "parentRowNumber": 986,
                                                                    "articleId": "46101957",
                                                                    "description": "46101957-01-Buchse",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0174,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0174,
                                                                    "singleQuantityMcf": 0.030102,
                                                                    "mcf": 0.030102,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.030102,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 1061,
                                                                    "parentRowNumber": 986,
                                                                    "articleId": "46101957",
                                                                    "description": "46101957-01-Buchse",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0174,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0174,
                                                                    "singleQuantityMcf": 0.030102,
                                                                    "mcf": 0.030102,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.030102,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 1062,
                                                                    "parentRowNumber": 986,
                                                                    "articleId": "46101957",
                                                                    "description": "46101957-01-Buchse",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0174,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0174,
                                                                    "singleQuantityMcf": 0.030102,
                                                                    "mcf": 0.030102,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.030102,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 1063,
                                                                    "parentRowNumber": 986,
                                                                    "articleId": "46102540",
                                                                    "description": "46102540-03-Scharnier",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.1022,
                                                                    "childrenWeight": 0.1022,
                                                                    "totalQuantityWeight": 0.1022,
                                                                    "singleQuantityMcf": 0.529396,
                                                                    "mcf": 0.529396,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.529396,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 1064,
                                                                            "parentRowNumber": 1063,
                                                                            "articleId": "46102518",
                                                                            "description": "46102518-00-Lasche",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0462,
                                                                            "childrenWeight": 0.0462,
                                                                            "totalQuantityWeight": 0.0462,
                                                                            "singleQuantityMcf": 0.239316,
                                                                            "mcf": 0.239316,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.239316,
                                                                            "expandable": false,
                                                                            "children": [
                                                                                {
                                                                                    "rowNumber": 1065,
                                                                                    "parentRowNumber": 1064,
                                                                                    "articleId": "142612",
                                                                                    "description": "142612-00-Edelstahlblech",
                                                                                    "hierarchy": 9,
                                                                                    "quantity": 1,
                                                                                    "weight": 0.0462,
                                                                                    "childrenWeight": 0,
                                                                                    "totalQuantityWeight": 0.0462,
                                                                                    "singleQuantityMcf": 0.239316,
                                                                                    "mcf": 0.239316,
                                                                                    "singleQuantityLcf": 0,
                                                                                    "lcf": 0,
                                                                                    "totalQuantityMcf": 0.239316,
                                                                                    "expandable": false,
                                                                                    "children": []
                                                                                }
                                                                            ]
                                                                        },
                                                                        {
                                                                            "rowNumber": 1066,
                                                                            "parentRowNumber": 1063,
                                                                            "articleId": "46102539",
                                                                            "description": "46102539-00-Distanz",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.056,
                                                                            "childrenWeight": 0.056,
                                                                            "totalQuantityWeight": 0.056,
                                                                            "singleQuantityMcf": 0.29008,
                                                                            "mcf": 0.29008,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.29008,
                                                                            "expandable": false,
                                                                            "children": [
                                                                                {
                                                                                    "rowNumber": 1067,
                                                                                    "parentRowNumber": 1066,
                                                                                    "articleId": "125524",
                                                                                    "description": "125524-00-Rohr",
                                                                                    "hierarchy": 9,
                                                                                    "quantity": 1,
                                                                                    "weight": 0.056,
                                                                                    "childrenWeight": 0,
                                                                                    "totalQuantityWeight": 0.056,
                                                                                    "singleQuantityMcf": 0.29008,
                                                                                    "mcf": 0.29008,
                                                                                    "singleQuantityLcf": 0,
                                                                                    "lcf": 0,
                                                                                    "totalQuantityMcf": 0.29008,
                                                                                    "expandable": false,
                                                                                    "children": []
                                                                                }
                                                                            ]
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 1068,
                                                                    "parentRowNumber": 986,
                                                                    "articleId": "46102540",
                                                                    "description": "46102540-03-Scharnier",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.1022,
                                                                    "childrenWeight": 0.1022,
                                                                    "totalQuantityWeight": 0.1022,
                                                                    "singleQuantityMcf": 0.529396,
                                                                    "mcf": 0.529396,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.529396,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 1069,
                                                                            "parentRowNumber": 1068,
                                                                            "articleId": "46102518",
                                                                            "description": "46102518-00-Lasche",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0462,
                                                                            "childrenWeight": 0.0462,
                                                                            "totalQuantityWeight": 0.0462,
                                                                            "singleQuantityMcf": 0.239316,
                                                                            "mcf": 0.239316,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.239316,
                                                                            "expandable": false,
                                                                            "children": [
                                                                                {
                                                                                    "rowNumber": 1070,
                                                                                    "parentRowNumber": 1069,
                                                                                    "articleId": "142612",
                                                                                    "description": "142612-00-Edelstahlblech",
                                                                                    "hierarchy": 9,
                                                                                    "quantity": 1,
                                                                                    "weight": 0.0462,
                                                                                    "childrenWeight": 0,
                                                                                    "totalQuantityWeight": 0.0462,
                                                                                    "singleQuantityMcf": 0.239316,
                                                                                    "mcf": 0.239316,
                                                                                    "singleQuantityLcf": 0,
                                                                                    "lcf": 0,
                                                                                    "totalQuantityMcf": 0.239316,
                                                                                    "expandable": false,
                                                                                    "children": []
                                                                                }
                                                                            ]
                                                                        },
                                                                        {
                                                                            "rowNumber": 1071,
                                                                            "parentRowNumber": 1068,
                                                                            "articleId": "46102539",
                                                                            "description": "46102539-00-Distanz",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.056,
                                                                            "childrenWeight": 0.056,
                                                                            "totalQuantityWeight": 0.056,
                                                                            "singleQuantityMcf": 0.29008,
                                                                            "mcf": 0.29008,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.29008,
                                                                            "expandable": false,
                                                                            "children": [
                                                                                {
                                                                                    "rowNumber": 1072,
                                                                                    "parentRowNumber": 1071,
                                                                                    "articleId": "125524",
                                                                                    "description": "125524-00-Rohr",
                                                                                    "hierarchy": 9,
                                                                                    "quantity": 1,
                                                                                    "weight": 0.056,
                                                                                    "childrenWeight": 0,
                                                                                    "totalQuantityWeight": 0.056,
                                                                                    "singleQuantityMcf": 0.29008,
                                                                                    "mcf": 0.29008,
                                                                                    "singleQuantityLcf": 0,
                                                                                    "lcf": 0,
                                                                                    "totalQuantityMcf": 0.29008,
                                                                                    "expandable": false,
                                                                                    "children": []
                                                                                }
                                                                            ]
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 1073,
                                                                    "parentRowNumber": 986,
                                                                    "articleId": "46102540",
                                                                    "description": "46102540-03-Scharnier",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.1022,
                                                                    "childrenWeight": 0.1022,
                                                                    "totalQuantityWeight": 0.1022,
                                                                    "singleQuantityMcf": 0.529396,
                                                                    "mcf": 0.529396,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.529396,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 1074,
                                                                            "parentRowNumber": 1073,
                                                                            "articleId": "46102518",
                                                                            "description": "46102518-00-Lasche",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0462,
                                                                            "childrenWeight": 0.0462,
                                                                            "totalQuantityWeight": 0.0462,
                                                                            "singleQuantityMcf": 0.239316,
                                                                            "mcf": 0.239316,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.239316,
                                                                            "expandable": false,
                                                                            "children": [
                                                                                {
                                                                                    "rowNumber": 1075,
                                                                                    "parentRowNumber": 1074,
                                                                                    "articleId": "142612",
                                                                                    "description": "142612-00-Edelstahlblech",
                                                                                    "hierarchy": 9,
                                                                                    "quantity": 1,
                                                                                    "weight": 0.0462,
                                                                                    "childrenWeight": 0,
                                                                                    "totalQuantityWeight": 0.0462,
                                                                                    "singleQuantityMcf": 0.239316,
                                                                                    "mcf": 0.239316,
                                                                                    "singleQuantityLcf": 0,
                                                                                    "lcf": 0,
                                                                                    "totalQuantityMcf": 0.239316,
                                                                                    "expandable": false,
                                                                                    "children": []
                                                                                }
                                                                            ]
                                                                        },
                                                                        {
                                                                            "rowNumber": 1076,
                                                                            "parentRowNumber": 1073,
                                                                            "articleId": "46102539",
                                                                            "description": "46102539-00-Distanz",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.056,
                                                                            "childrenWeight": 0.056,
                                                                            "totalQuantityWeight": 0.056,
                                                                            "singleQuantityMcf": 0.29008,
                                                                            "mcf": 0.29008,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.29008,
                                                                            "expandable": false,
                                                                            "children": [
                                                                                {
                                                                                    "rowNumber": 1077,
                                                                                    "parentRowNumber": 1076,
                                                                                    "articleId": "125524",
                                                                                    "description": "125524-00-Rohr",
                                                                                    "hierarchy": 9,
                                                                                    "quantity": 1,
                                                                                    "weight": 0.056,
                                                                                    "childrenWeight": 0,
                                                                                    "totalQuantityWeight": 0.056,
                                                                                    "singleQuantityMcf": 0.29008,
                                                                                    "mcf": 0.29008,
                                                                                    "singleQuantityLcf": 0,
                                                                                    "lcf": 0,
                                                                                    "totalQuantityMcf": 0.29008,
                                                                                    "expandable": false,
                                                                                    "children": []
                                                                                }
                                                                            ]
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 1078,
                                                                    "parentRowNumber": 986,
                                                                    "articleId": "46102540",
                                                                    "description": "46102540-03-Scharnier",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.1022,
                                                                    "childrenWeight": 0.1022,
                                                                    "totalQuantityWeight": 0.1022,
                                                                    "singleQuantityMcf": 0.529396,
                                                                    "mcf": 0.529396,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.529396,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 1079,
                                                                            "parentRowNumber": 1078,
                                                                            "articleId": "46102518",
                                                                            "description": "46102518-00-Lasche",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0462,
                                                                            "childrenWeight": 0.0462,
                                                                            "totalQuantityWeight": 0.0462,
                                                                            "singleQuantityMcf": 0.239316,
                                                                            "mcf": 0.239316,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.239316,
                                                                            "expandable": false,
                                                                            "children": [
                                                                                {
                                                                                    "rowNumber": 1080,
                                                                                    "parentRowNumber": 1079,
                                                                                    "articleId": "142612",
                                                                                    "description": "142612-00-Edelstahlblech",
                                                                                    "hierarchy": 9,
                                                                                    "quantity": 1,
                                                                                    "weight": 0.0462,
                                                                                    "childrenWeight": 0,
                                                                                    "totalQuantityWeight": 0.0462,
                                                                                    "singleQuantityMcf": 0.239316,
                                                                                    "mcf": 0.239316,
                                                                                    "singleQuantityLcf": 0,
                                                                                    "lcf": 0,
                                                                                    "totalQuantityMcf": 0.239316,
                                                                                    "expandable": false,
                                                                                    "children": []
                                                                                }
                                                                            ]
                                                                        },
                                                                        {
                                                                            "rowNumber": 1081,
                                                                            "parentRowNumber": 1078,
                                                                            "articleId": "46102539",
                                                                            "description": "46102539-00-Distanz",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.056,
                                                                            "childrenWeight": 0.056,
                                                                            "totalQuantityWeight": 0.056,
                                                                            "singleQuantityMcf": 0.29008,
                                                                            "mcf": 0.29008,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.29008,
                                                                            "expandable": false,
                                                                            "children": [
                                                                                {
                                                                                    "rowNumber": 1082,
                                                                                    "parentRowNumber": 1081,
                                                                                    "articleId": "125524",
                                                                                    "description": "125524-00-Rohr",
                                                                                    "hierarchy": 9,
                                                                                    "quantity": 1,
                                                                                    "weight": 0.056,
                                                                                    "childrenWeight": 0,
                                                                                    "totalQuantityWeight": 0.056,
                                                                                    "singleQuantityMcf": 0.29008,
                                                                                    "mcf": 0.29008,
                                                                                    "singleQuantityLcf": 0,
                                                                                    "lcf": 0,
                                                                                    "totalQuantityMcf": 0.29008,
                                                                                    "expandable": false,
                                                                                    "children": []
                                                                                }
                                                                            ]
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 1083,
                                                                    "parentRowNumber": 986,
                                                                    "articleId": "46102546",
                                                                    "description": "46102546-01-Distanz",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.7827,
                                                                    "childrenWeight": 0.7827,
                                                                    "totalQuantityWeight": 0.7827,
                                                                    "singleQuantityMcf": 4.054385999999999,
                                                                    "mcf": 4.054385999999999,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 4.054385999999999,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 1084,
                                                                            "parentRowNumber": 1083,
                                                                            "articleId": "1261242",
                                                                            "description": "1261242-00-Rohr",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.7827,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.7827,
                                                                            "singleQuantityMcf": 4.054385999999999,
                                                                            "mcf": 4.054385999999999,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 4.054385999999999,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 1085,
                                                                    "parentRowNumber": 986,
                                                                    "articleId": "46125780",
                                                                    "description": "46125780-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0079,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0079,
                                                                    "singleQuantityMcf": 0.040922,
                                                                    "mcf": 0.040922,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.040922,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 1086,
                                                                    "parentRowNumber": 986,
                                                                    "articleId": "46125780",
                                                                    "description": "46125780-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0079,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0079,
                                                                    "singleQuantityMcf": 0.040922,
                                                                    "mcf": 0.040922,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.040922,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 1087,
                                                                    "parentRowNumber": 986,
                                                                    "articleId": "46125780",
                                                                    "description": "46125780-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0079,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0079,
                                                                    "singleQuantityMcf": 0.040922,
                                                                    "mcf": 0.040922,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.040922,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 1088,
                                                                    "parentRowNumber": 986,
                                                                    "articleId": "46125780",
                                                                    "description": "46125780-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0079,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0079,
                                                                    "singleQuantityMcf": 0.040922,
                                                                    "mcf": 0.040922,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.040922,
                                                                    "expandable": false,
                                                                    "children": []
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            "rowNumber": 1089,
                                                            "parentRowNumber": 871,
                                                            "articleId": "46320312",
                                                            "description": "46320312-04-Schutzpfosten oben",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 1.5921000000000003,
                                                            "childrenWeight": 1.5921000000000003,
                                                            "totalQuantityWeight": 1.5921000000000003,
                                                            "singleQuantityMcf": 7.716785800000004,
                                                            "mcf": 7.716785800000004,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 7.716785800000004,
                                                            "expandable": false,
                                                            "children": [
                                                                {
                                                                    "rowNumber": 1090,
                                                                    "parentRowNumber": 1089,
                                                                    "articleId": "20270690",
                                                                    "description": "20270690-00-Zylinderschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0234,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0234,
                                                                    "singleQuantityMcf": 0.121212,
                                                                    "mcf": 0.121212,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.121212,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 1091,
                                                                    "parentRowNumber": 1089,
                                                                    "articleId": "208205",
                                                                    "description": "208205-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0004,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0004,
                                                                    "singleQuantityMcf": 0.002072,
                                                                    "mcf": 0.002072,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.002072,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 1092,
                                                                    "parentRowNumber": 1089,
                                                                    "articleId": "208205",
                                                                    "description": "208205-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0004,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0004,
                                                                    "singleQuantityMcf": 0.002072,
                                                                    "mcf": 0.002072,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.002072,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 1093,
                                                                    "parentRowNumber": 1089,
                                                                    "articleId": "208205",
                                                                    "description": "208205-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0004,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0004,
                                                                    "singleQuantityMcf": 0.002072,
                                                                    "mcf": 0.002072,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.002072,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 1094,
                                                                    "parentRowNumber": 1089,
                                                                    "articleId": "208205",
                                                                    "description": "208205-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0004,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0004,
                                                                    "singleQuantityMcf": 0.002072,
                                                                    "mcf": 0.002072,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.002072,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 1095,
                                                                    "parentRowNumber": 1089,
                                                                    "articleId": "216232",
                                                                    "description": "216232-00-Sicherungsring",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0023,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0023,
                                                                    "singleQuantityMcf": 0.0039376,
                                                                    "mcf": 0.0039376,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.0039376,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 1096,
                                                                    "parentRowNumber": 1089,
                                                                    "articleId": "216232",
                                                                    "description": "216232-00-Sicherungsring",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0023,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0023,
                                                                    "singleQuantityMcf": 0.0039376,
                                                                    "mcf": 0.0039376,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.0039376,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 1097,
                                                                    "parentRowNumber": 1089,
                                                                    "articleId": "216232",
                                                                    "description": "216232-00-Sicherungsring",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0023,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0023,
                                                                    "singleQuantityMcf": 0.0039376,
                                                                    "mcf": 0.0039376,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.0039376,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 1098,
                                                                    "parentRowNumber": 1089,
                                                                    "articleId": "457007",
                                                                    "description": "457007-03-Klemmstueck",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0465,
                                                                    "childrenWeight": 0.0465,
                                                                    "totalQuantityWeight": 0.0465,
                                                                    "singleQuantityMcf": 0.24087,
                                                                    "mcf": 0.24087,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.24087,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 1099,
                                                                            "parentRowNumber": 1098,
                                                                            "articleId": "1260038",
                                                                            "description": "1260038-00-Rundstahl",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0465,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0465,
                                                                            "singleQuantityMcf": 0.24087,
                                                                            "mcf": 0.24087,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.24087,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 1100,
                                                                    "parentRowNumber": 1089,
                                                                    "articleId": "457007",
                                                                    "description": "457007-03-Klemmstueck",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0465,
                                                                    "childrenWeight": 0.0465,
                                                                    "totalQuantityWeight": 0.0465,
                                                                    "singleQuantityMcf": 0.24087,
                                                                    "mcf": 0.24087,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.24087,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 1101,
                                                                            "parentRowNumber": 1100,
                                                                            "articleId": "1260038",
                                                                            "description": "1260038-00-Rundstahl",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0465,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0465,
                                                                            "singleQuantityMcf": 0.24087,
                                                                            "mcf": 0.24087,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.24087,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 1102,
                                                                    "parentRowNumber": 1089,
                                                                    "articleId": "457007",
                                                                    "description": "457007-03-Klemmstueck",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0465,
                                                                    "childrenWeight": 0.0465,
                                                                    "totalQuantityWeight": 0.0465,
                                                                    "singleQuantityMcf": 0.24087,
                                                                    "mcf": 0.24087,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.24087,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 1103,
                                                                            "parentRowNumber": 1102,
                                                                            "articleId": "1260038",
                                                                            "description": "1260038-00-Rundstahl",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0465,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0465,
                                                                            "singleQuantityMcf": 0.24087,
                                                                            "mcf": 0.24087,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.24087,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 1104,
                                                                    "parentRowNumber": 1089,
                                                                    "articleId": "457007",
                                                                    "description": "457007-03-Klemmstueck",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0465,
                                                                    "childrenWeight": 0.0465,
                                                                    "totalQuantityWeight": 0.0465,
                                                                    "singleQuantityMcf": 0.24087,
                                                                    "mcf": 0.24087,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.24087,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 1105,
                                                                            "parentRowNumber": 1104,
                                                                            "articleId": "1260038",
                                                                            "description": "1260038-00-Rundstahl",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0465,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0465,
                                                                            "singleQuantityMcf": 0.24087,
                                                                            "mcf": 0.24087,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.24087,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 1106,
                                                                    "parentRowNumber": 1089,
                                                                    "articleId": "457051",
                                                                    "description": "457051-03-Distanz",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0077,
                                                                    "childrenWeight": 0.0077,
                                                                    "totalQuantityWeight": 0.0077,
                                                                    "singleQuantityMcf": 0.013321,
                                                                    "mcf": 0.013321,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.013321,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 1107,
                                                                            "parentRowNumber": 1106,
                                                                            "articleId": "164710",
                                                                            "description": "164710-00-Hohlstab",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0077,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0077,
                                                                            "singleQuantityMcf": 0.013321,
                                                                            "mcf": 0.013321,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.013321,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 1108,
                                                                    "parentRowNumber": 1089,
                                                                    "articleId": "457051",
                                                                    "description": "457051-03-Distanz",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0077,
                                                                    "childrenWeight": 0.0077,
                                                                    "totalQuantityWeight": 0.0077,
                                                                    "singleQuantityMcf": 0.013321,
                                                                    "mcf": 0.013321,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.013321,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 1109,
                                                                            "parentRowNumber": 1108,
                                                                            "articleId": "164710",
                                                                            "description": "164710-00-Hohlstab",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0077,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0077,
                                                                            "singleQuantityMcf": 0.013321,
                                                                            "mcf": 0.013321,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.013321,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 1110,
                                                                    "parentRowNumber": 1089,
                                                                    "articleId": "457051",
                                                                    "description": "457051-03-Distanz",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0077,
                                                                    "childrenWeight": 0.0077,
                                                                    "totalQuantityWeight": 0.0077,
                                                                    "singleQuantityMcf": 0.013321,
                                                                    "mcf": 0.013321,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.013321,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 1111,
                                                                            "parentRowNumber": 1110,
                                                                            "articleId": "164710",
                                                                            "description": "164710-00-Hohlstab",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0077,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0077,
                                                                            "singleQuantityMcf": 0.013321,
                                                                            "mcf": 0.013321,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.013321,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 1112,
                                                                    "parentRowNumber": 1089,
                                                                    "articleId": "457190",
                                                                    "description": "457190-01-Distanzplatte",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0012,
                                                                    "childrenWeight": 0.0012,
                                                                    "totalQuantityWeight": 0.0012,
                                                                    "singleQuantityMcf": 0.004079999999999999,
                                                                    "mcf": 0.004079999999999999,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.004079999999999999,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 1113,
                                                                            "parentRowNumber": 1112,
                                                                            "articleId": "162431",
                                                                            "description": "162431-00-Platte",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0012,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0012,
                                                                            "singleQuantityMcf": 0.004079999999999999,
                                                                            "mcf": 0.004079999999999999,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.004079999999999999,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 1114,
                                                                    "parentRowNumber": 1089,
                                                                    "articleId": "457190",
                                                                    "description": "457190-01-Distanzplatte",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0012,
                                                                    "childrenWeight": 0.0012,
                                                                    "totalQuantityWeight": 0.0012,
                                                                    "singleQuantityMcf": 0.004079999999999999,
                                                                    "mcf": 0.004079999999999999,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.004079999999999999,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 1115,
                                                                            "parentRowNumber": 1114,
                                                                            "articleId": "162431",
                                                                            "description": "162431-00-Platte",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0012,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0012,
                                                                            "singleQuantityMcf": 0.004079999999999999,
                                                                            "mcf": 0.004079999999999999,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.004079999999999999,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 1116,
                                                                    "parentRowNumber": 1089,
                                                                    "articleId": "457190",
                                                                    "description": "457190-01-Distanzplatte",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0012,
                                                                    "childrenWeight": 0.0012,
                                                                    "totalQuantityWeight": 0.0012,
                                                                    "singleQuantityMcf": 0.004079999999999999,
                                                                    "mcf": 0.004079999999999999,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.004079999999999999,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 1117,
                                                                            "parentRowNumber": 1116,
                                                                            "articleId": "162431",
                                                                            "description": "162431-00-Platte",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0012,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0012,
                                                                            "singleQuantityMcf": 0.004079999999999999,
                                                                            "mcf": 0.004079999999999999,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.004079999999999999,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 1118,
                                                                    "parentRowNumber": 1089,
                                                                    "articleId": "457190",
                                                                    "description": "457190-01-Distanzplatte",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0012,
                                                                    "childrenWeight": 0.0012,
                                                                    "totalQuantityWeight": 0.0012,
                                                                    "singleQuantityMcf": 0.004079999999999999,
                                                                    "mcf": 0.004079999999999999,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.004079999999999999,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 1119,
                                                                            "parentRowNumber": 1118,
                                                                            "articleId": "162431",
                                                                            "description": "162431-00-Platte",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0012,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0012,
                                                                            "singleQuantityMcf": 0.004079999999999999,
                                                                            "mcf": 0.004079999999999999,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.004079999999999999,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 1120,
                                                                    "parentRowNumber": 1089,
                                                                    "articleId": "457193",
                                                                    "description": "457193-01-Distanzplatte",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0021,
                                                                    "childrenWeight": 0.0021,
                                                                    "totalQuantityWeight": 0.0021,
                                                                    "singleQuantityMcf": 0.00714,
                                                                    "mcf": 0.00714,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.00714,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 1121,
                                                                            "parentRowNumber": 1120,
                                                                            "articleId": "162431",
                                                                            "description": "162431-00-Platte",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0021,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0021,
                                                                            "singleQuantityMcf": 0.00714,
                                                                            "mcf": 0.00714,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.00714,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 1122,
                                                                    "parentRowNumber": 1089,
                                                                    "articleId": "457193",
                                                                    "description": "457193-01-Distanzplatte",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0021,
                                                                    "childrenWeight": 0.0021,
                                                                    "totalQuantityWeight": 0.0021,
                                                                    "singleQuantityMcf": 0.00714,
                                                                    "mcf": 0.00714,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.00714,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 1123,
                                                                            "parentRowNumber": 1122,
                                                                            "articleId": "162431",
                                                                            "description": "162431-00-Platte",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0021,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0021,
                                                                            "singleQuantityMcf": 0.00714,
                                                                            "mcf": 0.00714,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.00714,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 1124,
                                                                    "parentRowNumber": 1089,
                                                                    "articleId": "457193",
                                                                    "description": "457193-01-Distanzplatte",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0021,
                                                                    "childrenWeight": 0.0021,
                                                                    "totalQuantityWeight": 0.0021,
                                                                    "singleQuantityMcf": 0.00714,
                                                                    "mcf": 0.00714,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.00714,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 1125,
                                                                            "parentRowNumber": 1124,
                                                                            "articleId": "162431",
                                                                            "description": "162431-00-Platte",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0021,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0021,
                                                                            "singleQuantityMcf": 0.00714,
                                                                            "mcf": 0.00714,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.00714,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 1126,
                                                                    "parentRowNumber": 1089,
                                                                    "articleId": "457193",
                                                                    "description": "457193-01-Distanzplatte",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0021,
                                                                    "childrenWeight": 0.0021,
                                                                    "totalQuantityWeight": 0.0021,
                                                                    "singleQuantityMcf": 0.00714,
                                                                    "mcf": 0.00714,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.00714,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 1127,
                                                                            "parentRowNumber": 1126,
                                                                            "articleId": "162431",
                                                                            "description": "162431-00-Platte",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0021,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0021,
                                                                            "singleQuantityMcf": 0.00714,
                                                                            "mcf": 0.00714,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.00714,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 1128,
                                                                    "parentRowNumber": 1089,
                                                                    "articleId": "457194",
                                                                    "description": "457194-01-Distanzplatte",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0008,
                                                                    "childrenWeight": 0.0008,
                                                                    "totalQuantityWeight": 0.0008,
                                                                    "singleQuantityMcf": 0.00272,
                                                                    "mcf": 0.00272,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.00272,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 1129,
                                                                            "parentRowNumber": 1128,
                                                                            "articleId": "162431",
                                                                            "description": "162431-00-Platte",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0008,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0008,
                                                                            "singleQuantityMcf": 0.00272,
                                                                            "mcf": 0.00272,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.00272,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 1130,
                                                                    "parentRowNumber": 1089,
                                                                    "articleId": "457194",
                                                                    "description": "457194-01-Distanzplatte",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0008,
                                                                    "childrenWeight": 0.0008,
                                                                    "totalQuantityWeight": 0.0008,
                                                                    "singleQuantityMcf": 0.00272,
                                                                    "mcf": 0.00272,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.00272,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 1131,
                                                                            "parentRowNumber": 1130,
                                                                            "articleId": "162431",
                                                                            "description": "162431-00-Platte",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0008,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0008,
                                                                            "singleQuantityMcf": 0.00272,
                                                                            "mcf": 0.00272,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.00272,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 1132,
                                                                    "parentRowNumber": 1089,
                                                                    "articleId": "457194",
                                                                    "description": "457194-01-Distanzplatte",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0008,
                                                                    "childrenWeight": 0.0008,
                                                                    "totalQuantityWeight": 0.0008,
                                                                    "singleQuantityMcf": 0.00272,
                                                                    "mcf": 0.00272,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.00272,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 1133,
                                                                            "parentRowNumber": 1132,
                                                                            "articleId": "162431",
                                                                            "description": "162431-00-Platte",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0008,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0008,
                                                                            "singleQuantityMcf": 0.00272,
                                                                            "mcf": 0.00272,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.00272,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 1134,
                                                                    "parentRowNumber": 1089,
                                                                    "articleId": "457194",
                                                                    "description": "457194-01-Distanzplatte",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0008,
                                                                    "childrenWeight": 0.0008,
                                                                    "totalQuantityWeight": 0.0008,
                                                                    "singleQuantityMcf": 0.00272,
                                                                    "mcf": 0.00272,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.00272,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 1135,
                                                                            "parentRowNumber": 1134,
                                                                            "articleId": "162431",
                                                                            "description": "162431-00-Platte",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0008,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0008,
                                                                            "singleQuantityMcf": 0.00272,
                                                                            "mcf": 0.00272,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.00272,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 1136,
                                                                    "parentRowNumber": 1089,
                                                                    "articleId": "457195",
                                                                    "description": "457195-01-Distanz",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 1137,
                                                                            "parentRowNumber": 1136,
                                                                            "articleId": "3859213",
                                                                            "description": "3859213-00-Schlauch",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 1138,
                                                                    "parentRowNumber": 1089,
                                                                    "articleId": "457195",
                                                                    "description": "457195-01-Distanz",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 1139,
                                                                            "parentRowNumber": 1138,
                                                                            "articleId": "3859213",
                                                                            "description": "3859213-00-Schlauch",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 1140,
                                                                    "parentRowNumber": 1089,
                                                                    "articleId": "457195",
                                                                    "description": "457195-01-Distanz",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 1141,
                                                                            "parentRowNumber": 1140,
                                                                            "articleId": "3859213",
                                                                            "description": "3859213-00-Schlauch",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 1142,
                                                                    "parentRowNumber": 1089,
                                                                    "articleId": "457195",
                                                                    "description": "457195-01-Distanz",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 1143,
                                                                            "parentRowNumber": 1142,
                                                                            "articleId": "3859213",
                                                                            "description": "3859213-00-Schlauch",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 1144,
                                                                    "parentRowNumber": 1089,
                                                                    "articleId": "46024147",
                                                                    "description": "46024147-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0011,
                                                                    "childrenWeight": 0.0011,
                                                                    "totalQuantityWeight": 0.0011,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 1145,
                                                                            "parentRowNumber": 1144,
                                                                            "articleId": "163201",
                                                                            "description": "163201-00-Gummiplatte",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0011,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0011,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 1146,
                                                                    "parentRowNumber": 1089,
                                                                    "articleId": "46024147",
                                                                    "description": "46024147-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0011,
                                                                    "childrenWeight": 0.0011,
                                                                    "totalQuantityWeight": 0.0011,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 1147,
                                                                            "parentRowNumber": 1146,
                                                                            "articleId": "163201",
                                                                            "description": "163201-00-Gummiplatte",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0011,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0011,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 1148,
                                                                    "parentRowNumber": 1089,
                                                                    "articleId": "46024147",
                                                                    "description": "46024147-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0011,
                                                                    "childrenWeight": 0.0011,
                                                                    "totalQuantityWeight": 0.0011,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 1149,
                                                                            "parentRowNumber": 1148,
                                                                            "articleId": "163201",
                                                                            "description": "163201-00-Gummiplatte",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0011,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0011,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 1150,
                                                                    "parentRowNumber": 1089,
                                                                    "articleId": "46024147",
                                                                    "description": "46024147-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0011,
                                                                    "childrenWeight": 0.0011,
                                                                    "totalQuantityWeight": 0.0011,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 1151,
                                                                            "parentRowNumber": 1150,
                                                                            "articleId": "163201",
                                                                            "description": "163201-00-Gummiplatte",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0011,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0011,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 1152,
                                                                    "parentRowNumber": 1089,
                                                                    "articleId": "46029594",
                                                                    "description": "46029594-01-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0007,
                                                                    "childrenWeight": 0.0007,
                                                                    "totalQuantityWeight": 0.0007,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 1153,
                                                                            "parentRowNumber": 1152,
                                                                            "articleId": "163201",
                                                                            "description": "163201-00-Gummiplatte",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0007,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0007,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 1154,
                                                                    "parentRowNumber": 1089,
                                                                    "articleId": "46029594",
                                                                    "description": "46029594-01-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0007,
                                                                    "childrenWeight": 0.0007,
                                                                    "totalQuantityWeight": 0.0007,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 1155,
                                                                            "parentRowNumber": 1154,
                                                                            "articleId": "163201",
                                                                            "description": "163201-00-Gummiplatte",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0007,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0007,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 1156,
                                                                    "parentRowNumber": 1089,
                                                                    "articleId": "46029594",
                                                                    "description": "46029594-01-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0007,
                                                                    "childrenWeight": 0.0007,
                                                                    "totalQuantityWeight": 0.0007,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 1157,
                                                                            "parentRowNumber": 1156,
                                                                            "articleId": "163201",
                                                                            "description": "163201-00-Gummiplatte",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0007,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0007,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 1158,
                                                                    "parentRowNumber": 1089,
                                                                    "articleId": "46029594",
                                                                    "description": "46029594-01-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0007,
                                                                    "childrenWeight": 0.0007,
                                                                    "totalQuantityWeight": 0.0007,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 1159,
                                                                            "parentRowNumber": 1158,
                                                                            "articleId": "163201",
                                                                            "description": "163201-00-Gummiplatte",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0007,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0007,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 1160,
                                                                    "parentRowNumber": 1089,
                                                                    "articleId": "46101957",
                                                                    "description": "46101957-01-Buchse",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0174,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0174,
                                                                    "singleQuantityMcf": 0.030102,
                                                                    "mcf": 0.030102,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.030102,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 1161,
                                                                    "parentRowNumber": 1089,
                                                                    "articleId": "46101957",
                                                                    "description": "46101957-01-Buchse",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0174,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0174,
                                                                    "singleQuantityMcf": 0.030102,
                                                                    "mcf": 0.030102,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.030102,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 1162,
                                                                    "parentRowNumber": 1089,
                                                                    "articleId": "46101957",
                                                                    "description": "46101957-01-Buchse",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0174,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0174,
                                                                    "singleQuantityMcf": 0.030102,
                                                                    "mcf": 0.030102,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.030102,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 1163,
                                                                    "parentRowNumber": 1089,
                                                                    "articleId": "46101957",
                                                                    "description": "46101957-01-Buchse",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0174,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0174,
                                                                    "singleQuantityMcf": 0.030102,
                                                                    "mcf": 0.030102,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.030102,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 1164,
                                                                    "parentRowNumber": 1089,
                                                                    "articleId": "46101957",
                                                                    "description": "46101957-01-Buchse",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0174,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0174,
                                                                    "singleQuantityMcf": 0.030102,
                                                                    "mcf": 0.030102,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.030102,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 1165,
                                                                    "parentRowNumber": 1089,
                                                                    "articleId": "46101957",
                                                                    "description": "46101957-01-Buchse",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0174,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0174,
                                                                    "singleQuantityMcf": 0.030102,
                                                                    "mcf": 0.030102,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.030102,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 1166,
                                                                    "parentRowNumber": 1089,
                                                                    "articleId": "46102540",
                                                                    "description": "46102540-03-Scharnier",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.1022,
                                                                    "childrenWeight": 0.1022,
                                                                    "totalQuantityWeight": 0.1022,
                                                                    "singleQuantityMcf": 0.529396,
                                                                    "mcf": 0.529396,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.529396,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 1167,
                                                                            "parentRowNumber": 1166,
                                                                            "articleId": "46102518",
                                                                            "description": "46102518-00-Lasche",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0462,
                                                                            "childrenWeight": 0.0462,
                                                                            "totalQuantityWeight": 0.0462,
                                                                            "singleQuantityMcf": 0.239316,
                                                                            "mcf": 0.239316,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.239316,
                                                                            "expandable": false,
                                                                            "children": [
                                                                                {
                                                                                    "rowNumber": 1168,
                                                                                    "parentRowNumber": 1167,
                                                                                    "articleId": "142612",
                                                                                    "description": "142612-00-Edelstahlblech",
                                                                                    "hierarchy": 9,
                                                                                    "quantity": 1,
                                                                                    "weight": 0.0462,
                                                                                    "childrenWeight": 0,
                                                                                    "totalQuantityWeight": 0.0462,
                                                                                    "singleQuantityMcf": 0.239316,
                                                                                    "mcf": 0.239316,
                                                                                    "singleQuantityLcf": 0,
                                                                                    "lcf": 0,
                                                                                    "totalQuantityMcf": 0.239316,
                                                                                    "expandable": false,
                                                                                    "children": []
                                                                                }
                                                                            ]
                                                                        },
                                                                        {
                                                                            "rowNumber": 1169,
                                                                            "parentRowNumber": 1166,
                                                                            "articleId": "46102539",
                                                                            "description": "46102539-00-Distanz",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.056,
                                                                            "childrenWeight": 0.056,
                                                                            "totalQuantityWeight": 0.056,
                                                                            "singleQuantityMcf": 0.29008,
                                                                            "mcf": 0.29008,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.29008,
                                                                            "expandable": false,
                                                                            "children": [
                                                                                {
                                                                                    "rowNumber": 1170,
                                                                                    "parentRowNumber": 1169,
                                                                                    "articleId": "125524",
                                                                                    "description": "125524-00-Rohr",
                                                                                    "hierarchy": 9,
                                                                                    "quantity": 1,
                                                                                    "weight": 0.056,
                                                                                    "childrenWeight": 0,
                                                                                    "totalQuantityWeight": 0.056,
                                                                                    "singleQuantityMcf": 0.29008,
                                                                                    "mcf": 0.29008,
                                                                                    "singleQuantityLcf": 0,
                                                                                    "lcf": 0,
                                                                                    "totalQuantityMcf": 0.29008,
                                                                                    "expandable": false,
                                                                                    "children": []
                                                                                }
                                                                            ]
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 1171,
                                                                    "parentRowNumber": 1089,
                                                                    "articleId": "46102540",
                                                                    "description": "46102540-03-Scharnier",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.1022,
                                                                    "childrenWeight": 0.1022,
                                                                    "totalQuantityWeight": 0.1022,
                                                                    "singleQuantityMcf": 0.529396,
                                                                    "mcf": 0.529396,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.529396,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 1172,
                                                                            "parentRowNumber": 1171,
                                                                            "articleId": "46102518",
                                                                            "description": "46102518-00-Lasche",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0462,
                                                                            "childrenWeight": 0.0462,
                                                                            "totalQuantityWeight": 0.0462,
                                                                            "singleQuantityMcf": 0.239316,
                                                                            "mcf": 0.239316,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.239316,
                                                                            "expandable": false,
                                                                            "children": [
                                                                                {
                                                                                    "rowNumber": 1173,
                                                                                    "parentRowNumber": 1172,
                                                                                    "articleId": "142612",
                                                                                    "description": "142612-00-Edelstahlblech",
                                                                                    "hierarchy": 9,
                                                                                    "quantity": 1,
                                                                                    "weight": 0.0462,
                                                                                    "childrenWeight": 0,
                                                                                    "totalQuantityWeight": 0.0462,
                                                                                    "singleQuantityMcf": 0.239316,
                                                                                    "mcf": 0.239316,
                                                                                    "singleQuantityLcf": 0,
                                                                                    "lcf": 0,
                                                                                    "totalQuantityMcf": 0.239316,
                                                                                    "expandable": false,
                                                                                    "children": []
                                                                                }
                                                                            ]
                                                                        },
                                                                        {
                                                                            "rowNumber": 1174,
                                                                            "parentRowNumber": 1171,
                                                                            "articleId": "46102539",
                                                                            "description": "46102539-00-Distanz",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.056,
                                                                            "childrenWeight": 0.056,
                                                                            "totalQuantityWeight": 0.056,
                                                                            "singleQuantityMcf": 0.29008,
                                                                            "mcf": 0.29008,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.29008,
                                                                            "expandable": false,
                                                                            "children": [
                                                                                {
                                                                                    "rowNumber": 1175,
                                                                                    "parentRowNumber": 1174,
                                                                                    "articleId": "125524",
                                                                                    "description": "125524-00-Rohr",
                                                                                    "hierarchy": 9,
                                                                                    "quantity": 1,
                                                                                    "weight": 0.056,
                                                                                    "childrenWeight": 0,
                                                                                    "totalQuantityWeight": 0.056,
                                                                                    "singleQuantityMcf": 0.29008,
                                                                                    "mcf": 0.29008,
                                                                                    "singleQuantityLcf": 0,
                                                                                    "lcf": 0,
                                                                                    "totalQuantityMcf": 0.29008,
                                                                                    "expandable": false,
                                                                                    "children": []
                                                                                }
                                                                            ]
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 1176,
                                                                    "parentRowNumber": 1089,
                                                                    "articleId": "46102540",
                                                                    "description": "46102540-03-Scharnier",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.1022,
                                                                    "childrenWeight": 0.1022,
                                                                    "totalQuantityWeight": 0.1022,
                                                                    "singleQuantityMcf": 0.529396,
                                                                    "mcf": 0.529396,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.529396,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 1177,
                                                                            "parentRowNumber": 1176,
                                                                            "articleId": "46102518",
                                                                            "description": "46102518-00-Lasche",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0462,
                                                                            "childrenWeight": 0.0462,
                                                                            "totalQuantityWeight": 0.0462,
                                                                            "singleQuantityMcf": 0.239316,
                                                                            "mcf": 0.239316,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.239316,
                                                                            "expandable": false,
                                                                            "children": [
                                                                                {
                                                                                    "rowNumber": 1178,
                                                                                    "parentRowNumber": 1177,
                                                                                    "articleId": "142612",
                                                                                    "description": "142612-00-Edelstahlblech",
                                                                                    "hierarchy": 9,
                                                                                    "quantity": 1,
                                                                                    "weight": 0.0462,
                                                                                    "childrenWeight": 0,
                                                                                    "totalQuantityWeight": 0.0462,
                                                                                    "singleQuantityMcf": 0.239316,
                                                                                    "mcf": 0.239316,
                                                                                    "singleQuantityLcf": 0,
                                                                                    "lcf": 0,
                                                                                    "totalQuantityMcf": 0.239316,
                                                                                    "expandable": false,
                                                                                    "children": []
                                                                                }
                                                                            ]
                                                                        },
                                                                        {
                                                                            "rowNumber": 1179,
                                                                            "parentRowNumber": 1176,
                                                                            "articleId": "46102539",
                                                                            "description": "46102539-00-Distanz",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.056,
                                                                            "childrenWeight": 0.056,
                                                                            "totalQuantityWeight": 0.056,
                                                                            "singleQuantityMcf": 0.29008,
                                                                            "mcf": 0.29008,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.29008,
                                                                            "expandable": false,
                                                                            "children": [
                                                                                {
                                                                                    "rowNumber": 1180,
                                                                                    "parentRowNumber": 1179,
                                                                                    "articleId": "125524",
                                                                                    "description": "125524-00-Rohr",
                                                                                    "hierarchy": 9,
                                                                                    "quantity": 1,
                                                                                    "weight": 0.056,
                                                                                    "childrenWeight": 0,
                                                                                    "totalQuantityWeight": 0.056,
                                                                                    "singleQuantityMcf": 0.29008,
                                                                                    "mcf": 0.29008,
                                                                                    "singleQuantityLcf": 0,
                                                                                    "lcf": 0,
                                                                                    "totalQuantityMcf": 0.29008,
                                                                                    "expandable": false,
                                                                                    "children": []
                                                                                }
                                                                            ]
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 1181,
                                                                    "parentRowNumber": 1089,
                                                                    "articleId": "46102540",
                                                                    "description": "46102540-03-Scharnier",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.1022,
                                                                    "childrenWeight": 0.1022,
                                                                    "totalQuantityWeight": 0.1022,
                                                                    "singleQuantityMcf": 0.529396,
                                                                    "mcf": 0.529396,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.529396,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 1182,
                                                                            "parentRowNumber": 1181,
                                                                            "articleId": "46102518",
                                                                            "description": "46102518-00-Lasche",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0462,
                                                                            "childrenWeight": 0.0462,
                                                                            "totalQuantityWeight": 0.0462,
                                                                            "singleQuantityMcf": 0.239316,
                                                                            "mcf": 0.239316,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.239316,
                                                                            "expandable": false,
                                                                            "children": [
                                                                                {
                                                                                    "rowNumber": 1183,
                                                                                    "parentRowNumber": 1182,
                                                                                    "articleId": "142612",
                                                                                    "description": "142612-00-Edelstahlblech",
                                                                                    "hierarchy": 9,
                                                                                    "quantity": 1,
                                                                                    "weight": 0.0462,
                                                                                    "childrenWeight": 0,
                                                                                    "totalQuantityWeight": 0.0462,
                                                                                    "singleQuantityMcf": 0.239316,
                                                                                    "mcf": 0.239316,
                                                                                    "singleQuantityLcf": 0,
                                                                                    "lcf": 0,
                                                                                    "totalQuantityMcf": 0.239316,
                                                                                    "expandable": false,
                                                                                    "children": []
                                                                                }
                                                                            ]
                                                                        },
                                                                        {
                                                                            "rowNumber": 1184,
                                                                            "parentRowNumber": 1181,
                                                                            "articleId": "46102539",
                                                                            "description": "46102539-00-Distanz",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.056,
                                                                            "childrenWeight": 0.056,
                                                                            "totalQuantityWeight": 0.056,
                                                                            "singleQuantityMcf": 0.29008,
                                                                            "mcf": 0.29008,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.29008,
                                                                            "expandable": false,
                                                                            "children": [
                                                                                {
                                                                                    "rowNumber": 1185,
                                                                                    "parentRowNumber": 1184,
                                                                                    "articleId": "125524",
                                                                                    "description": "125524-00-Rohr",
                                                                                    "hierarchy": 9,
                                                                                    "quantity": 1,
                                                                                    "weight": 0.056,
                                                                                    "childrenWeight": 0,
                                                                                    "totalQuantityWeight": 0.056,
                                                                                    "singleQuantityMcf": 0.29008,
                                                                                    "mcf": 0.29008,
                                                                                    "singleQuantityLcf": 0,
                                                                                    "lcf": 0,
                                                                                    "totalQuantityMcf": 0.29008,
                                                                                    "expandable": false,
                                                                                    "children": []
                                                                                }
                                                                            ]
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 1186,
                                                                    "parentRowNumber": 1089,
                                                                    "articleId": "46102546",
                                                                    "description": "46102546-01-Distanz",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.7827,
                                                                    "childrenWeight": 0.7827,
                                                                    "totalQuantityWeight": 0.7827,
                                                                    "singleQuantityMcf": 4.054385999999999,
                                                                    "mcf": 4.054385999999999,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 4.054385999999999,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 1187,
                                                                            "parentRowNumber": 1186,
                                                                            "articleId": "1261242",
                                                                            "description": "1261242-00-Rohr",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.7827,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.7827,
                                                                            "singleQuantityMcf": 4.054385999999999,
                                                                            "mcf": 4.054385999999999,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 4.054385999999999,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 1188,
                                                                    "parentRowNumber": 1089,
                                                                    "articleId": "46125780",
                                                                    "description": "46125780-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0079,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0079,
                                                                    "singleQuantityMcf": 0.040922,
                                                                    "mcf": 0.040922,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.040922,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 1189,
                                                                    "parentRowNumber": 1089,
                                                                    "articleId": "46125780",
                                                                    "description": "46125780-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0079,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0079,
                                                                    "singleQuantityMcf": 0.040922,
                                                                    "mcf": 0.040922,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.040922,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 1190,
                                                                    "parentRowNumber": 1089,
                                                                    "articleId": "46125780",
                                                                    "description": "46125780-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0079,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0079,
                                                                    "singleQuantityMcf": 0.040922,
                                                                    "mcf": 0.040922,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.040922,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 1191,
                                                                    "parentRowNumber": 1089,
                                                                    "articleId": "46125780",
                                                                    "description": "46125780-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0079,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0079,
                                                                    "singleQuantityMcf": 0.040922,
                                                                    "mcf": 0.040922,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.040922,
                                                                    "expandable": false,
                                                                    "children": []
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            "rowNumber": 1192,
                                                            "parentRowNumber": 871,
                                                            "articleId": "46320313",
                                                            "description": "46320313-04-Schutzpfosten unten",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 1.4121000000000006,
                                                            "childrenWeight": 1.4121000000000006,
                                                            "totalQuantityWeight": 1.4121000000000006,
                                                            "singleQuantityMcf": 7.006772600000002,
                                                            "mcf": 7.006772600000002,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 7.006772600000002,
                                                            "expandable": false,
                                                            "children": [
                                                                {
                                                                    "rowNumber": 1193,
                                                                    "parentRowNumber": 1192,
                                                                    "articleId": "208205",
                                                                    "description": "208205-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0004,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0004,
                                                                    "singleQuantityMcf": 0.002072,
                                                                    "mcf": 0.002072,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.002072,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 1194,
                                                                    "parentRowNumber": 1192,
                                                                    "articleId": "208205",
                                                                    "description": "208205-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0004,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0004,
                                                                    "singleQuantityMcf": 0.002072,
                                                                    "mcf": 0.002072,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.002072,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 1195,
                                                                    "parentRowNumber": 1192,
                                                                    "articleId": "216232",
                                                                    "description": "216232-00-Sicherungsring",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0023,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0023,
                                                                    "singleQuantityMcf": 0.0039376,
                                                                    "mcf": 0.0039376,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.0039376,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 1196,
                                                                    "parentRowNumber": 1192,
                                                                    "articleId": "457007",
                                                                    "description": "457007-03-Klemmstueck",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0465,
                                                                    "childrenWeight": 0.0465,
                                                                    "totalQuantityWeight": 0.0465,
                                                                    "singleQuantityMcf": 0.24087,
                                                                    "mcf": 0.24087,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.24087,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 1197,
                                                                            "parentRowNumber": 1196,
                                                                            "articleId": "1260038",
                                                                            "description": "1260038-00-Rundstahl",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0465,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0465,
                                                                            "singleQuantityMcf": 0.24087,
                                                                            "mcf": 0.24087,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.24087,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 1198,
                                                                    "parentRowNumber": 1192,
                                                                    "articleId": "457007",
                                                                    "description": "457007-03-Klemmstueck",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0465,
                                                                    "childrenWeight": 0.0465,
                                                                    "totalQuantityWeight": 0.0465,
                                                                    "singleQuantityMcf": 0.24087,
                                                                    "mcf": 0.24087,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.24087,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 1199,
                                                                            "parentRowNumber": 1198,
                                                                            "articleId": "1260038",
                                                                            "description": "1260038-00-Rundstahl",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0465,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0465,
                                                                            "singleQuantityMcf": 0.24087,
                                                                            "mcf": 0.24087,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.24087,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 1200,
                                                                    "parentRowNumber": 1192,
                                                                    "articleId": "457051",
                                                                    "description": "457051-03-Distanz",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0077,
                                                                    "childrenWeight": 0.0077,
                                                                    "totalQuantityWeight": 0.0077,
                                                                    "singleQuantityMcf": 0.013321,
                                                                    "mcf": 0.013321,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.013321,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 1201,
                                                                            "parentRowNumber": 1200,
                                                                            "articleId": "164710",
                                                                            "description": "164710-00-Hohlstab",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0077,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0077,
                                                                            "singleQuantityMcf": 0.013321,
                                                                            "mcf": 0.013321,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.013321,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 1202,
                                                                    "parentRowNumber": 1192,
                                                                    "articleId": "457190",
                                                                    "description": "457190-01-Distanzplatte",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0012,
                                                                    "childrenWeight": 0.0012,
                                                                    "totalQuantityWeight": 0.0012,
                                                                    "singleQuantityMcf": 0.004079999999999999,
                                                                    "mcf": 0.004079999999999999,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.004079999999999999,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 1203,
                                                                            "parentRowNumber": 1202,
                                                                            "articleId": "162431",
                                                                            "description": "162431-00-Platte",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0012,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0012,
                                                                            "singleQuantityMcf": 0.004079999999999999,
                                                                            "mcf": 0.004079999999999999,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.004079999999999999,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 1204,
                                                                    "parentRowNumber": 1192,
                                                                    "articleId": "457190",
                                                                    "description": "457190-01-Distanzplatte",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0012,
                                                                    "childrenWeight": 0.0012,
                                                                    "totalQuantityWeight": 0.0012,
                                                                    "singleQuantityMcf": 0.004079999999999999,
                                                                    "mcf": 0.004079999999999999,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.004079999999999999,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 1205,
                                                                            "parentRowNumber": 1204,
                                                                            "articleId": "162431",
                                                                            "description": "162431-00-Platte",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0012,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0012,
                                                                            "singleQuantityMcf": 0.004079999999999999,
                                                                            "mcf": 0.004079999999999999,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.004079999999999999,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 1206,
                                                                    "parentRowNumber": 1192,
                                                                    "articleId": "457193",
                                                                    "description": "457193-01-Distanzplatte",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0021,
                                                                    "childrenWeight": 0.0021,
                                                                    "totalQuantityWeight": 0.0021,
                                                                    "singleQuantityMcf": 0.00714,
                                                                    "mcf": 0.00714,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.00714,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 1207,
                                                                            "parentRowNumber": 1206,
                                                                            "articleId": "162431",
                                                                            "description": "162431-00-Platte",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0021,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0021,
                                                                            "singleQuantityMcf": 0.00714,
                                                                            "mcf": 0.00714,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.00714,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 1208,
                                                                    "parentRowNumber": 1192,
                                                                    "articleId": "457193",
                                                                    "description": "457193-01-Distanzplatte",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0021,
                                                                    "childrenWeight": 0.0021,
                                                                    "totalQuantityWeight": 0.0021,
                                                                    "singleQuantityMcf": 0.00714,
                                                                    "mcf": 0.00714,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.00714,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 1209,
                                                                            "parentRowNumber": 1208,
                                                                            "articleId": "162431",
                                                                            "description": "162431-00-Platte",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0021,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0021,
                                                                            "singleQuantityMcf": 0.00714,
                                                                            "mcf": 0.00714,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.00714,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 1210,
                                                                    "parentRowNumber": 1192,
                                                                    "articleId": "457194",
                                                                    "description": "457194-01-Distanzplatte",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0008,
                                                                    "childrenWeight": 0.0008,
                                                                    "totalQuantityWeight": 0.0008,
                                                                    "singleQuantityMcf": 0.00272,
                                                                    "mcf": 0.00272,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.00272,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 1211,
                                                                            "parentRowNumber": 1210,
                                                                            "articleId": "162431",
                                                                            "description": "162431-00-Platte",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0008,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0008,
                                                                            "singleQuantityMcf": 0.00272,
                                                                            "mcf": 0.00272,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.00272,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 1212,
                                                                    "parentRowNumber": 1192,
                                                                    "articleId": "457194",
                                                                    "description": "457194-01-Distanzplatte",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0008,
                                                                    "childrenWeight": 0.0008,
                                                                    "totalQuantityWeight": 0.0008,
                                                                    "singleQuantityMcf": 0.00272,
                                                                    "mcf": 0.00272,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.00272,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 1213,
                                                                            "parentRowNumber": 1212,
                                                                            "articleId": "162431",
                                                                            "description": "162431-00-Platte",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0008,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0008,
                                                                            "singleQuantityMcf": 0.00272,
                                                                            "mcf": 0.00272,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.00272,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 1214,
                                                                    "parentRowNumber": 1192,
                                                                    "articleId": "457195",
                                                                    "description": "457195-01-Distanz",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 1215,
                                                                            "parentRowNumber": 1214,
                                                                            "articleId": "3859213",
                                                                            "description": "3859213-00-Schlauch",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 1216,
                                                                    "parentRowNumber": 1192,
                                                                    "articleId": "457195",
                                                                    "description": "457195-01-Distanz",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 1217,
                                                                            "parentRowNumber": 1216,
                                                                            "articleId": "3859213",
                                                                            "description": "3859213-00-Schlauch",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 1218,
                                                                    "parentRowNumber": 1192,
                                                                    "articleId": "46024147",
                                                                    "description": "46024147-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0011,
                                                                    "childrenWeight": 0.0011,
                                                                    "totalQuantityWeight": 0.0011,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 1219,
                                                                            "parentRowNumber": 1218,
                                                                            "articleId": "163201",
                                                                            "description": "163201-00-Gummiplatte",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0011,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0011,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 1220,
                                                                    "parentRowNumber": 1192,
                                                                    "articleId": "46024147",
                                                                    "description": "46024147-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0011,
                                                                    "childrenWeight": 0.0011,
                                                                    "totalQuantityWeight": 0.0011,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 1221,
                                                                            "parentRowNumber": 1220,
                                                                            "articleId": "163201",
                                                                            "description": "163201-00-Gummiplatte",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0011,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0011,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 1222,
                                                                    "parentRowNumber": 1192,
                                                                    "articleId": "46029594",
                                                                    "description": "46029594-01-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0007,
                                                                    "childrenWeight": 0.0007,
                                                                    "totalQuantityWeight": 0.0007,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 1223,
                                                                            "parentRowNumber": 1222,
                                                                            "articleId": "163201",
                                                                            "description": "163201-00-Gummiplatte",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0007,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0007,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 1224,
                                                                    "parentRowNumber": 1192,
                                                                    "articleId": "46029594",
                                                                    "description": "46029594-01-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0007,
                                                                    "childrenWeight": 0.0007,
                                                                    "totalQuantityWeight": 0.0007,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 1225,
                                                                            "parentRowNumber": 1224,
                                                                            "articleId": "163201",
                                                                            "description": "163201-00-Gummiplatte",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0007,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0007,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 1226,
                                                                    "parentRowNumber": 1192,
                                                                    "articleId": "46101957",
                                                                    "description": "46101957-01-Buchse",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0174,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0174,
                                                                    "singleQuantityMcf": 0.030102,
                                                                    "mcf": 0.030102,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.030102,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 1227,
                                                                    "parentRowNumber": 1192,
                                                                    "articleId": "46101957",
                                                                    "description": "46101957-01-Buchse",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0174,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0174,
                                                                    "singleQuantityMcf": 0.030102,
                                                                    "mcf": 0.030102,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.030102,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 1228,
                                                                    "parentRowNumber": 1192,
                                                                    "articleId": "46101957",
                                                                    "description": "46101957-01-Buchse",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0174,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0174,
                                                                    "singleQuantityMcf": 0.030102,
                                                                    "mcf": 0.030102,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.030102,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 1229,
                                                                    "parentRowNumber": 1192,
                                                                    "articleId": "46101957",
                                                                    "description": "46101957-01-Buchse",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0174,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0174,
                                                                    "singleQuantityMcf": 0.030102,
                                                                    "mcf": 0.030102,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.030102,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 1230,
                                                                    "parentRowNumber": 1192,
                                                                    "articleId": "46102540",
                                                                    "description": "46102540-03-Scharnier",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.1022,
                                                                    "childrenWeight": 0.1022,
                                                                    "totalQuantityWeight": 0.1022,
                                                                    "singleQuantityMcf": 0.529396,
                                                                    "mcf": 0.529396,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.529396,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 1231,
                                                                            "parentRowNumber": 1230,
                                                                            "articleId": "46102518",
                                                                            "description": "46102518-00-Lasche",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0462,
                                                                            "childrenWeight": 0.0462,
                                                                            "totalQuantityWeight": 0.0462,
                                                                            "singleQuantityMcf": 0.239316,
                                                                            "mcf": 0.239316,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.239316,
                                                                            "expandable": false,
                                                                            "children": [
                                                                                {
                                                                                    "rowNumber": 1232,
                                                                                    "parentRowNumber": 1231,
                                                                                    "articleId": "142612",
                                                                                    "description": "142612-00-Edelstahlblech",
                                                                                    "hierarchy": 9,
                                                                                    "quantity": 1,
                                                                                    "weight": 0.0462,
                                                                                    "childrenWeight": 0,
                                                                                    "totalQuantityWeight": 0.0462,
                                                                                    "singleQuantityMcf": 0.239316,
                                                                                    "mcf": 0.239316,
                                                                                    "singleQuantityLcf": 0,
                                                                                    "lcf": 0,
                                                                                    "totalQuantityMcf": 0.239316,
                                                                                    "expandable": false,
                                                                                    "children": []
                                                                                }
                                                                            ]
                                                                        },
                                                                        {
                                                                            "rowNumber": 1233,
                                                                            "parentRowNumber": 1230,
                                                                            "articleId": "46102539",
                                                                            "description": "46102539-00-Distanz",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.056,
                                                                            "childrenWeight": 0.056,
                                                                            "totalQuantityWeight": 0.056,
                                                                            "singleQuantityMcf": 0.29008,
                                                                            "mcf": 0.29008,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.29008,
                                                                            "expandable": false,
                                                                            "children": [
                                                                                {
                                                                                    "rowNumber": 1234,
                                                                                    "parentRowNumber": 1233,
                                                                                    "articleId": "125524",
                                                                                    "description": "125524-00-Rohr",
                                                                                    "hierarchy": 9,
                                                                                    "quantity": 1,
                                                                                    "weight": 0.056,
                                                                                    "childrenWeight": 0,
                                                                                    "totalQuantityWeight": 0.056,
                                                                                    "singleQuantityMcf": 0.29008,
                                                                                    "mcf": 0.29008,
                                                                                    "singleQuantityLcf": 0,
                                                                                    "lcf": 0,
                                                                                    "totalQuantityMcf": 0.29008,
                                                                                    "expandable": false,
                                                                                    "children": []
                                                                                }
                                                                            ]
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 1235,
                                                                    "parentRowNumber": 1192,
                                                                    "articleId": "46102540",
                                                                    "description": "46102540-03-Scharnier",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.1022,
                                                                    "childrenWeight": 0.1022,
                                                                    "totalQuantityWeight": 0.1022,
                                                                    "singleQuantityMcf": 0.529396,
                                                                    "mcf": 0.529396,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.529396,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 1236,
                                                                            "parentRowNumber": 1235,
                                                                            "articleId": "46102518",
                                                                            "description": "46102518-00-Lasche",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0462,
                                                                            "childrenWeight": 0.0462,
                                                                            "totalQuantityWeight": 0.0462,
                                                                            "singleQuantityMcf": 0.239316,
                                                                            "mcf": 0.239316,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.239316,
                                                                            "expandable": false,
                                                                            "children": [
                                                                                {
                                                                                    "rowNumber": 1237,
                                                                                    "parentRowNumber": 1236,
                                                                                    "articleId": "142612",
                                                                                    "description": "142612-00-Edelstahlblech",
                                                                                    "hierarchy": 9,
                                                                                    "quantity": 1,
                                                                                    "weight": 0.0462,
                                                                                    "childrenWeight": 0,
                                                                                    "totalQuantityWeight": 0.0462,
                                                                                    "singleQuantityMcf": 0.239316,
                                                                                    "mcf": 0.239316,
                                                                                    "singleQuantityLcf": 0,
                                                                                    "lcf": 0,
                                                                                    "totalQuantityMcf": 0.239316,
                                                                                    "expandable": false,
                                                                                    "children": []
                                                                                }
                                                                            ]
                                                                        },
                                                                        {
                                                                            "rowNumber": 1238,
                                                                            "parentRowNumber": 1235,
                                                                            "articleId": "46102539",
                                                                            "description": "46102539-00-Distanz",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.056,
                                                                            "childrenWeight": 0.056,
                                                                            "totalQuantityWeight": 0.056,
                                                                            "singleQuantityMcf": 0.29008,
                                                                            "mcf": 0.29008,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.29008,
                                                                            "expandable": false,
                                                                            "children": [
                                                                                {
                                                                                    "rowNumber": 1239,
                                                                                    "parentRowNumber": 1238,
                                                                                    "articleId": "125524",
                                                                                    "description": "125524-00-Rohr",
                                                                                    "hierarchy": 9,
                                                                                    "quantity": 1,
                                                                                    "weight": 0.056,
                                                                                    "childrenWeight": 0,
                                                                                    "totalQuantityWeight": 0.056,
                                                                                    "singleQuantityMcf": 0.29008,
                                                                                    "mcf": 0.29008,
                                                                                    "singleQuantityLcf": 0,
                                                                                    "lcf": 0,
                                                                                    "totalQuantityMcf": 0.29008,
                                                                                    "expandable": false,
                                                                                    "children": []
                                                                                }
                                                                            ]
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 1240,
                                                                    "parentRowNumber": 1192,
                                                                    "articleId": "46125780",
                                                                    "description": "46125780-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0079,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0079,
                                                                    "singleQuantityMcf": 0.040922,
                                                                    "mcf": 0.040922,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.040922,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 1241,
                                                                    "parentRowNumber": 1192,
                                                                    "articleId": "46125780",
                                                                    "description": "46125780-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0079,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0079,
                                                                    "singleQuantityMcf": 0.040922,
                                                                    "mcf": 0.040922,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.040922,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 1242,
                                                                    "parentRowNumber": 1192,
                                                                    "articleId": "46316696",
                                                                    "description": "46316696-04-Distanz",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0848,
                                                                    "childrenWeight": 0.0848,
                                                                    "totalQuantityWeight": 0.0848,
                                                                    "singleQuantityMcf": 0.439264,
                                                                    "mcf": 0.439264,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.439264,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 1243,
                                                                            "parentRowNumber": 1242,
                                                                            "articleId": "1260038",
                                                                            "description": "1260038-00-Rundstahl",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0848,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0848,
                                                                            "singleQuantityMcf": 0.439264,
                                                                            "mcf": 0.439264,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.439264,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 1244,
                                                                    "parentRowNumber": 1192,
                                                                    "articleId": "46321808",
                                                                    "description": "46321808-01-Distanz",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.9219,
                                                                    "childrenWeight": 0.9219,
                                                                    "totalQuantityWeight": 0.9219,
                                                                    "singleQuantityMcf": 4.775442,
                                                                    "mcf": 4.775442,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 4.775442,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 1245,
                                                                            "parentRowNumber": 1244,
                                                                            "articleId": "125524",
                                                                            "description": "125524-00-Rohr",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.9219,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.9219,
                                                                            "singleQuantityMcf": 4.775442,
                                                                            "mcf": 4.775442,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 4.775442,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            "rowNumber": 1246,
                                                            "parentRowNumber": 871,
                                                            "articleId": "46320313",
                                                            "description": "46320313-04-Schutzpfosten unten",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 1.4121000000000006,
                                                            "childrenWeight": 1.4121000000000006,
                                                            "totalQuantityWeight": 1.4121000000000006,
                                                            "singleQuantityMcf": 7.006772600000002,
                                                            "mcf": 7.006772600000002,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 7.006772600000002,
                                                            "expandable": false,
                                                            "children": [
                                                                {
                                                                    "rowNumber": 1247,
                                                                    "parentRowNumber": 1246,
                                                                    "articleId": "208205",
                                                                    "description": "208205-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0004,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0004,
                                                                    "singleQuantityMcf": 0.002072,
                                                                    "mcf": 0.002072,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.002072,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 1248,
                                                                    "parentRowNumber": 1246,
                                                                    "articleId": "208205",
                                                                    "description": "208205-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0004,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0004,
                                                                    "singleQuantityMcf": 0.002072,
                                                                    "mcf": 0.002072,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.002072,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 1249,
                                                                    "parentRowNumber": 1246,
                                                                    "articleId": "216232",
                                                                    "description": "216232-00-Sicherungsring",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0023,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0023,
                                                                    "singleQuantityMcf": 0.0039376,
                                                                    "mcf": 0.0039376,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.0039376,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 1250,
                                                                    "parentRowNumber": 1246,
                                                                    "articleId": "457007",
                                                                    "description": "457007-03-Klemmstueck",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0465,
                                                                    "childrenWeight": 0.0465,
                                                                    "totalQuantityWeight": 0.0465,
                                                                    "singleQuantityMcf": 0.24087,
                                                                    "mcf": 0.24087,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.24087,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 1251,
                                                                            "parentRowNumber": 1250,
                                                                            "articleId": "1260038",
                                                                            "description": "1260038-00-Rundstahl",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0465,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0465,
                                                                            "singleQuantityMcf": 0.24087,
                                                                            "mcf": 0.24087,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.24087,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 1252,
                                                                    "parentRowNumber": 1246,
                                                                    "articleId": "457007",
                                                                    "description": "457007-03-Klemmstueck",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0465,
                                                                    "childrenWeight": 0.0465,
                                                                    "totalQuantityWeight": 0.0465,
                                                                    "singleQuantityMcf": 0.24087,
                                                                    "mcf": 0.24087,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.24087,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 1253,
                                                                            "parentRowNumber": 1252,
                                                                            "articleId": "1260038",
                                                                            "description": "1260038-00-Rundstahl",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0465,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0465,
                                                                            "singleQuantityMcf": 0.24087,
                                                                            "mcf": 0.24087,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.24087,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 1254,
                                                                    "parentRowNumber": 1246,
                                                                    "articleId": "457051",
                                                                    "description": "457051-03-Distanz",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0077,
                                                                    "childrenWeight": 0.0077,
                                                                    "totalQuantityWeight": 0.0077,
                                                                    "singleQuantityMcf": 0.013321,
                                                                    "mcf": 0.013321,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.013321,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 1255,
                                                                            "parentRowNumber": 1254,
                                                                            "articleId": "164710",
                                                                            "description": "164710-00-Hohlstab",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0077,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0077,
                                                                            "singleQuantityMcf": 0.013321,
                                                                            "mcf": 0.013321,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.013321,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 1256,
                                                                    "parentRowNumber": 1246,
                                                                    "articleId": "457190",
                                                                    "description": "457190-01-Distanzplatte",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0012,
                                                                    "childrenWeight": 0.0012,
                                                                    "totalQuantityWeight": 0.0012,
                                                                    "singleQuantityMcf": 0.004079999999999999,
                                                                    "mcf": 0.004079999999999999,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.004079999999999999,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 1257,
                                                                            "parentRowNumber": 1256,
                                                                            "articleId": "162431",
                                                                            "description": "162431-00-Platte",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0012,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0012,
                                                                            "singleQuantityMcf": 0.004079999999999999,
                                                                            "mcf": 0.004079999999999999,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.004079999999999999,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 1258,
                                                                    "parentRowNumber": 1246,
                                                                    "articleId": "457190",
                                                                    "description": "457190-01-Distanzplatte",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0012,
                                                                    "childrenWeight": 0.0012,
                                                                    "totalQuantityWeight": 0.0012,
                                                                    "singleQuantityMcf": 0.004079999999999999,
                                                                    "mcf": 0.004079999999999999,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.004079999999999999,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 1259,
                                                                            "parentRowNumber": 1258,
                                                                            "articleId": "162431",
                                                                            "description": "162431-00-Platte",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0012,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0012,
                                                                            "singleQuantityMcf": 0.004079999999999999,
                                                                            "mcf": 0.004079999999999999,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.004079999999999999,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 1260,
                                                                    "parentRowNumber": 1246,
                                                                    "articleId": "457193",
                                                                    "description": "457193-01-Distanzplatte",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0021,
                                                                    "childrenWeight": 0.0021,
                                                                    "totalQuantityWeight": 0.0021,
                                                                    "singleQuantityMcf": 0.00714,
                                                                    "mcf": 0.00714,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.00714,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 1261,
                                                                            "parentRowNumber": 1260,
                                                                            "articleId": "162431",
                                                                            "description": "162431-00-Platte",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0021,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0021,
                                                                            "singleQuantityMcf": 0.00714,
                                                                            "mcf": 0.00714,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.00714,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 1262,
                                                                    "parentRowNumber": 1246,
                                                                    "articleId": "457193",
                                                                    "description": "457193-01-Distanzplatte",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0021,
                                                                    "childrenWeight": 0.0021,
                                                                    "totalQuantityWeight": 0.0021,
                                                                    "singleQuantityMcf": 0.00714,
                                                                    "mcf": 0.00714,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.00714,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 1263,
                                                                            "parentRowNumber": 1262,
                                                                            "articleId": "162431",
                                                                            "description": "162431-00-Platte",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0021,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0021,
                                                                            "singleQuantityMcf": 0.00714,
                                                                            "mcf": 0.00714,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.00714,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 1264,
                                                                    "parentRowNumber": 1246,
                                                                    "articleId": "457194",
                                                                    "description": "457194-01-Distanzplatte",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0008,
                                                                    "childrenWeight": 0.0008,
                                                                    "totalQuantityWeight": 0.0008,
                                                                    "singleQuantityMcf": 0.00272,
                                                                    "mcf": 0.00272,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.00272,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 1265,
                                                                            "parentRowNumber": 1264,
                                                                            "articleId": "162431",
                                                                            "description": "162431-00-Platte",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0008,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0008,
                                                                            "singleQuantityMcf": 0.00272,
                                                                            "mcf": 0.00272,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.00272,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 1266,
                                                                    "parentRowNumber": 1246,
                                                                    "articleId": "457194",
                                                                    "description": "457194-01-Distanzplatte",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0008,
                                                                    "childrenWeight": 0.0008,
                                                                    "totalQuantityWeight": 0.0008,
                                                                    "singleQuantityMcf": 0.00272,
                                                                    "mcf": 0.00272,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.00272,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 1267,
                                                                            "parentRowNumber": 1266,
                                                                            "articleId": "162431",
                                                                            "description": "162431-00-Platte",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0008,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0008,
                                                                            "singleQuantityMcf": 0.00272,
                                                                            "mcf": 0.00272,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.00272,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 1268,
                                                                    "parentRowNumber": 1246,
                                                                    "articleId": "457195",
                                                                    "description": "457195-01-Distanz",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 1269,
                                                                            "parentRowNumber": 1268,
                                                                            "articleId": "3859213",
                                                                            "description": "3859213-00-Schlauch",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 1270,
                                                                    "parentRowNumber": 1246,
                                                                    "articleId": "457195",
                                                                    "description": "457195-01-Distanz",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 1271,
                                                                            "parentRowNumber": 1270,
                                                                            "articleId": "3859213",
                                                                            "description": "3859213-00-Schlauch",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 1272,
                                                                    "parentRowNumber": 1246,
                                                                    "articleId": "46024147",
                                                                    "description": "46024147-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0011,
                                                                    "childrenWeight": 0.0011,
                                                                    "totalQuantityWeight": 0.0011,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 1273,
                                                                            "parentRowNumber": 1272,
                                                                            "articleId": "163201",
                                                                            "description": "163201-00-Gummiplatte",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0011,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0011,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 1274,
                                                                    "parentRowNumber": 1246,
                                                                    "articleId": "46024147",
                                                                    "description": "46024147-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0011,
                                                                    "childrenWeight": 0.0011,
                                                                    "totalQuantityWeight": 0.0011,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 1275,
                                                                            "parentRowNumber": 1274,
                                                                            "articleId": "163201",
                                                                            "description": "163201-00-Gummiplatte",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0011,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0011,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 1276,
                                                                    "parentRowNumber": 1246,
                                                                    "articleId": "46029594",
                                                                    "description": "46029594-01-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0007,
                                                                    "childrenWeight": 0.0007,
                                                                    "totalQuantityWeight": 0.0007,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 1277,
                                                                            "parentRowNumber": 1276,
                                                                            "articleId": "163201",
                                                                            "description": "163201-00-Gummiplatte",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0007,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0007,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 1278,
                                                                    "parentRowNumber": 1246,
                                                                    "articleId": "46029594",
                                                                    "description": "46029594-01-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0007,
                                                                    "childrenWeight": 0.0007,
                                                                    "totalQuantityWeight": 0.0007,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 1279,
                                                                            "parentRowNumber": 1278,
                                                                            "articleId": "163201",
                                                                            "description": "163201-00-Gummiplatte",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0007,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0007,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 1280,
                                                                    "parentRowNumber": 1246,
                                                                    "articleId": "46101957",
                                                                    "description": "46101957-01-Buchse",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0174,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0174,
                                                                    "singleQuantityMcf": 0.030102,
                                                                    "mcf": 0.030102,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.030102,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 1281,
                                                                    "parentRowNumber": 1246,
                                                                    "articleId": "46101957",
                                                                    "description": "46101957-01-Buchse",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0174,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0174,
                                                                    "singleQuantityMcf": 0.030102,
                                                                    "mcf": 0.030102,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.030102,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 1282,
                                                                    "parentRowNumber": 1246,
                                                                    "articleId": "46101957",
                                                                    "description": "46101957-01-Buchse",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0174,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0174,
                                                                    "singleQuantityMcf": 0.030102,
                                                                    "mcf": 0.030102,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.030102,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 1283,
                                                                    "parentRowNumber": 1246,
                                                                    "articleId": "46101957",
                                                                    "description": "46101957-01-Buchse",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0174,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0174,
                                                                    "singleQuantityMcf": 0.030102,
                                                                    "mcf": 0.030102,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.030102,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 1284,
                                                                    "parentRowNumber": 1246,
                                                                    "articleId": "46102540",
                                                                    "description": "46102540-03-Scharnier",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.1022,
                                                                    "childrenWeight": 0.1022,
                                                                    "totalQuantityWeight": 0.1022,
                                                                    "singleQuantityMcf": 0.529396,
                                                                    "mcf": 0.529396,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.529396,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 1285,
                                                                            "parentRowNumber": 1284,
                                                                            "articleId": "46102518",
                                                                            "description": "46102518-00-Lasche",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0462,
                                                                            "childrenWeight": 0.0462,
                                                                            "totalQuantityWeight": 0.0462,
                                                                            "singleQuantityMcf": 0.239316,
                                                                            "mcf": 0.239316,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.239316,
                                                                            "expandable": false,
                                                                            "children": [
                                                                                {
                                                                                    "rowNumber": 1286,
                                                                                    "parentRowNumber": 1285,
                                                                                    "articleId": "142612",
                                                                                    "description": "142612-00-Edelstahlblech",
                                                                                    "hierarchy": 9,
                                                                                    "quantity": 1,
                                                                                    "weight": 0.0462,
                                                                                    "childrenWeight": 0,
                                                                                    "totalQuantityWeight": 0.0462,
                                                                                    "singleQuantityMcf": 0.239316,
                                                                                    "mcf": 0.239316,
                                                                                    "singleQuantityLcf": 0,
                                                                                    "lcf": 0,
                                                                                    "totalQuantityMcf": 0.239316,
                                                                                    "expandable": false,
                                                                                    "children": []
                                                                                }
                                                                            ]
                                                                        },
                                                                        {
                                                                            "rowNumber": 1287,
                                                                            "parentRowNumber": 1284,
                                                                            "articleId": "46102539",
                                                                            "description": "46102539-00-Distanz",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.056,
                                                                            "childrenWeight": 0.056,
                                                                            "totalQuantityWeight": 0.056,
                                                                            "singleQuantityMcf": 0.29008,
                                                                            "mcf": 0.29008,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.29008,
                                                                            "expandable": false,
                                                                            "children": [
                                                                                {
                                                                                    "rowNumber": 1288,
                                                                                    "parentRowNumber": 1287,
                                                                                    "articleId": "125524",
                                                                                    "description": "125524-00-Rohr",
                                                                                    "hierarchy": 9,
                                                                                    "quantity": 1,
                                                                                    "weight": 0.056,
                                                                                    "childrenWeight": 0,
                                                                                    "totalQuantityWeight": 0.056,
                                                                                    "singleQuantityMcf": 0.29008,
                                                                                    "mcf": 0.29008,
                                                                                    "singleQuantityLcf": 0,
                                                                                    "lcf": 0,
                                                                                    "totalQuantityMcf": 0.29008,
                                                                                    "expandable": false,
                                                                                    "children": []
                                                                                }
                                                                            ]
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 1289,
                                                                    "parentRowNumber": 1246,
                                                                    "articleId": "46102540",
                                                                    "description": "46102540-03-Scharnier",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.1022,
                                                                    "childrenWeight": 0.1022,
                                                                    "totalQuantityWeight": 0.1022,
                                                                    "singleQuantityMcf": 0.529396,
                                                                    "mcf": 0.529396,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.529396,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 1290,
                                                                            "parentRowNumber": 1289,
                                                                            "articleId": "46102518",
                                                                            "description": "46102518-00-Lasche",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0462,
                                                                            "childrenWeight": 0.0462,
                                                                            "totalQuantityWeight": 0.0462,
                                                                            "singleQuantityMcf": 0.239316,
                                                                            "mcf": 0.239316,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.239316,
                                                                            "expandable": false,
                                                                            "children": [
                                                                                {
                                                                                    "rowNumber": 1291,
                                                                                    "parentRowNumber": 1290,
                                                                                    "articleId": "142612",
                                                                                    "description": "142612-00-Edelstahlblech",
                                                                                    "hierarchy": 9,
                                                                                    "quantity": 1,
                                                                                    "weight": 0.0462,
                                                                                    "childrenWeight": 0,
                                                                                    "totalQuantityWeight": 0.0462,
                                                                                    "singleQuantityMcf": 0.239316,
                                                                                    "mcf": 0.239316,
                                                                                    "singleQuantityLcf": 0,
                                                                                    "lcf": 0,
                                                                                    "totalQuantityMcf": 0.239316,
                                                                                    "expandable": false,
                                                                                    "children": []
                                                                                }
                                                                            ]
                                                                        },
                                                                        {
                                                                            "rowNumber": 1292,
                                                                            "parentRowNumber": 1289,
                                                                            "articleId": "46102539",
                                                                            "description": "46102539-00-Distanz",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.056,
                                                                            "childrenWeight": 0.056,
                                                                            "totalQuantityWeight": 0.056,
                                                                            "singleQuantityMcf": 0.29008,
                                                                            "mcf": 0.29008,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.29008,
                                                                            "expandable": false,
                                                                            "children": [
                                                                                {
                                                                                    "rowNumber": 1293,
                                                                                    "parentRowNumber": 1292,
                                                                                    "articleId": "125524",
                                                                                    "description": "125524-00-Rohr",
                                                                                    "hierarchy": 9,
                                                                                    "quantity": 1,
                                                                                    "weight": 0.056,
                                                                                    "childrenWeight": 0,
                                                                                    "totalQuantityWeight": 0.056,
                                                                                    "singleQuantityMcf": 0.29008,
                                                                                    "mcf": 0.29008,
                                                                                    "singleQuantityLcf": 0,
                                                                                    "lcf": 0,
                                                                                    "totalQuantityMcf": 0.29008,
                                                                                    "expandable": false,
                                                                                    "children": []
                                                                                }
                                                                            ]
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 1294,
                                                                    "parentRowNumber": 1246,
                                                                    "articleId": "46125780",
                                                                    "description": "46125780-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0079,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0079,
                                                                    "singleQuantityMcf": 0.040922,
                                                                    "mcf": 0.040922,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.040922,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 1295,
                                                                    "parentRowNumber": 1246,
                                                                    "articleId": "46125780",
                                                                    "description": "46125780-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0079,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0079,
                                                                    "singleQuantityMcf": 0.040922,
                                                                    "mcf": 0.040922,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.040922,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 1296,
                                                                    "parentRowNumber": 1246,
                                                                    "articleId": "46316696",
                                                                    "description": "46316696-04-Distanz",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0848,
                                                                    "childrenWeight": 0.0848,
                                                                    "totalQuantityWeight": 0.0848,
                                                                    "singleQuantityMcf": 0.439264,
                                                                    "mcf": 0.439264,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.439264,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 1297,
                                                                            "parentRowNumber": 1296,
                                                                            "articleId": "1260038",
                                                                            "description": "1260038-00-Rundstahl",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0848,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0848,
                                                                            "singleQuantityMcf": 0.439264,
                                                                            "mcf": 0.439264,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.439264,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 1298,
                                                                    "parentRowNumber": 1246,
                                                                    "articleId": "46321808",
                                                                    "description": "46321808-01-Distanz",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.9219,
                                                                    "childrenWeight": 0.9219,
                                                                    "totalQuantityWeight": 0.9219,
                                                                    "singleQuantityMcf": 4.775442,
                                                                    "mcf": 4.775442,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 4.775442,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 1299,
                                                                            "parentRowNumber": 1298,
                                                                            "articleId": "125524",
                                                                            "description": "125524-00-Rohr",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.9219,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.9219,
                                                                            "singleQuantityMcf": 4.775442,
                                                                            "mcf": 4.775442,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 4.775442,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            "rowNumber": 1300,
                                                            "parentRowNumber": 871,
                                                            "articleId": "46320423",
                                                            "description": "46320423-05-Schutzpfosten oben",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 1.3527000000000002,
                                                            "childrenWeight": 1.3527000000000002,
                                                            "totalQuantityWeight": 1.3527000000000002,
                                                            "singleQuantityMcf": 6.629997800000003,
                                                            "mcf": 6.629997800000003,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 6.629997800000003,
                                                            "expandable": false,
                                                            "children": [
                                                                {
                                                                    "rowNumber": 1301,
                                                                    "parentRowNumber": 1300,
                                                                    "articleId": "20270690",
                                                                    "description": "20270690-00-Zylinderschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0234,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0234,
                                                                    "singleQuantityMcf": 0.121212,
                                                                    "mcf": 0.121212,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.121212,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 1302,
                                                                    "parentRowNumber": 1300,
                                                                    "articleId": "208205",
                                                                    "description": "208205-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0004,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0004,
                                                                    "singleQuantityMcf": 0.002072,
                                                                    "mcf": 0.002072,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.002072,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 1303,
                                                                    "parentRowNumber": 1300,
                                                                    "articleId": "208205",
                                                                    "description": "208205-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0004,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0004,
                                                                    "singleQuantityMcf": 0.002072,
                                                                    "mcf": 0.002072,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.002072,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 1304,
                                                                    "parentRowNumber": 1300,
                                                                    "articleId": "216232",
                                                                    "description": "216232-00-Sicherungsring",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0023,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0023,
                                                                    "singleQuantityMcf": 0.0039376,
                                                                    "mcf": 0.0039376,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.0039376,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 1305,
                                                                    "parentRowNumber": 1300,
                                                                    "articleId": "216232",
                                                                    "description": "216232-00-Sicherungsring",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0023,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0023,
                                                                    "singleQuantityMcf": 0.0039376,
                                                                    "mcf": 0.0039376,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.0039376,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 1306,
                                                                    "parentRowNumber": 1300,
                                                                    "articleId": "216232",
                                                                    "description": "216232-00-Sicherungsring",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0023,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0023,
                                                                    "singleQuantityMcf": 0.0039376,
                                                                    "mcf": 0.0039376,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.0039376,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 1307,
                                                                    "parentRowNumber": 1300,
                                                                    "articleId": "457007",
                                                                    "description": "457007-03-Klemmstueck",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0465,
                                                                    "childrenWeight": 0.0465,
                                                                    "totalQuantityWeight": 0.0465,
                                                                    "singleQuantityMcf": 0.24087,
                                                                    "mcf": 0.24087,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.24087,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 1308,
                                                                            "parentRowNumber": 1307,
                                                                            "articleId": "1260038",
                                                                            "description": "1260038-00-Rundstahl",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0465,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0465,
                                                                            "singleQuantityMcf": 0.24087,
                                                                            "mcf": 0.24087,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.24087,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 1309,
                                                                    "parentRowNumber": 1300,
                                                                    "articleId": "457007",
                                                                    "description": "457007-03-Klemmstueck",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0465,
                                                                    "childrenWeight": 0.0465,
                                                                    "totalQuantityWeight": 0.0465,
                                                                    "singleQuantityMcf": 0.24087,
                                                                    "mcf": 0.24087,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.24087,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 1310,
                                                                            "parentRowNumber": 1309,
                                                                            "articleId": "1260038",
                                                                            "description": "1260038-00-Rundstahl",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0465,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0465,
                                                                            "singleQuantityMcf": 0.24087,
                                                                            "mcf": 0.24087,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.24087,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 1311,
                                                                    "parentRowNumber": 1300,
                                                                    "articleId": "457051",
                                                                    "description": "457051-03-Distanz",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0077,
                                                                    "childrenWeight": 0.0077,
                                                                    "totalQuantityWeight": 0.0077,
                                                                    "singleQuantityMcf": 0.013321,
                                                                    "mcf": 0.013321,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.013321,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 1312,
                                                                            "parentRowNumber": 1311,
                                                                            "articleId": "164710",
                                                                            "description": "164710-00-Hohlstab",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0077,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0077,
                                                                            "singleQuantityMcf": 0.013321,
                                                                            "mcf": 0.013321,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.013321,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 1313,
                                                                    "parentRowNumber": 1300,
                                                                    "articleId": "457051",
                                                                    "description": "457051-03-Distanz",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0077,
                                                                    "childrenWeight": 0.0077,
                                                                    "totalQuantityWeight": 0.0077,
                                                                    "singleQuantityMcf": 0.013321,
                                                                    "mcf": 0.013321,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.013321,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 1314,
                                                                            "parentRowNumber": 1313,
                                                                            "articleId": "164710",
                                                                            "description": "164710-00-Hohlstab",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0077,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0077,
                                                                            "singleQuantityMcf": 0.013321,
                                                                            "mcf": 0.013321,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.013321,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 1315,
                                                                    "parentRowNumber": 1300,
                                                                    "articleId": "457051",
                                                                    "description": "457051-03-Distanz",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0077,
                                                                    "childrenWeight": 0.0077,
                                                                    "totalQuantityWeight": 0.0077,
                                                                    "singleQuantityMcf": 0.013321,
                                                                    "mcf": 0.013321,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.013321,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 1316,
                                                                            "parentRowNumber": 1315,
                                                                            "articleId": "164710",
                                                                            "description": "164710-00-Hohlstab",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0077,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0077,
                                                                            "singleQuantityMcf": 0.013321,
                                                                            "mcf": 0.013321,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.013321,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 1317,
                                                                    "parentRowNumber": 1300,
                                                                    "articleId": "457190",
                                                                    "description": "457190-01-Distanzplatte",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0012,
                                                                    "childrenWeight": 0.0012,
                                                                    "totalQuantityWeight": 0.0012,
                                                                    "singleQuantityMcf": 0.004079999999999999,
                                                                    "mcf": 0.004079999999999999,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.004079999999999999,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 1318,
                                                                            "parentRowNumber": 1317,
                                                                            "articleId": "162431",
                                                                            "description": "162431-00-Platte",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0012,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0012,
                                                                            "singleQuantityMcf": 0.004079999999999999,
                                                                            "mcf": 0.004079999999999999,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.004079999999999999,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 1319,
                                                                    "parentRowNumber": 1300,
                                                                    "articleId": "457190",
                                                                    "description": "457190-01-Distanzplatte",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0012,
                                                                    "childrenWeight": 0.0012,
                                                                    "totalQuantityWeight": 0.0012,
                                                                    "singleQuantityMcf": 0.004079999999999999,
                                                                    "mcf": 0.004079999999999999,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.004079999999999999,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 1320,
                                                                            "parentRowNumber": 1319,
                                                                            "articleId": "162431",
                                                                            "description": "162431-00-Platte",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0012,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0012,
                                                                            "singleQuantityMcf": 0.004079999999999999,
                                                                            "mcf": 0.004079999999999999,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.004079999999999999,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 1321,
                                                                    "parentRowNumber": 1300,
                                                                    "articleId": "457193",
                                                                    "description": "457193-01-Distanzplatte",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0021,
                                                                    "childrenWeight": 0.0021,
                                                                    "totalQuantityWeight": 0.0021,
                                                                    "singleQuantityMcf": 0.00714,
                                                                    "mcf": 0.00714,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.00714,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 1322,
                                                                            "parentRowNumber": 1321,
                                                                            "articleId": "162431",
                                                                            "description": "162431-00-Platte",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0021,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0021,
                                                                            "singleQuantityMcf": 0.00714,
                                                                            "mcf": 0.00714,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.00714,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 1323,
                                                                    "parentRowNumber": 1300,
                                                                    "articleId": "457193",
                                                                    "description": "457193-01-Distanzplatte",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0021,
                                                                    "childrenWeight": 0.0021,
                                                                    "totalQuantityWeight": 0.0021,
                                                                    "singleQuantityMcf": 0.00714,
                                                                    "mcf": 0.00714,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.00714,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 1324,
                                                                            "parentRowNumber": 1323,
                                                                            "articleId": "162431",
                                                                            "description": "162431-00-Platte",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0021,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0021,
                                                                            "singleQuantityMcf": 0.00714,
                                                                            "mcf": 0.00714,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.00714,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 1325,
                                                                    "parentRowNumber": 1300,
                                                                    "articleId": "457194",
                                                                    "description": "457194-01-Distanzplatte",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0008,
                                                                    "childrenWeight": 0.0008,
                                                                    "totalQuantityWeight": 0.0008,
                                                                    "singleQuantityMcf": 0.00272,
                                                                    "mcf": 0.00272,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.00272,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 1326,
                                                                            "parentRowNumber": 1325,
                                                                            "articleId": "162431",
                                                                            "description": "162431-00-Platte",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0008,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0008,
                                                                            "singleQuantityMcf": 0.00272,
                                                                            "mcf": 0.00272,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.00272,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 1327,
                                                                    "parentRowNumber": 1300,
                                                                    "articleId": "457194",
                                                                    "description": "457194-01-Distanzplatte",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0008,
                                                                    "childrenWeight": 0.0008,
                                                                    "totalQuantityWeight": 0.0008,
                                                                    "singleQuantityMcf": 0.00272,
                                                                    "mcf": 0.00272,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.00272,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 1328,
                                                                            "parentRowNumber": 1327,
                                                                            "articleId": "162431",
                                                                            "description": "162431-00-Platte",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0008,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0008,
                                                                            "singleQuantityMcf": 0.00272,
                                                                            "mcf": 0.00272,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.00272,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 1329,
                                                                    "parentRowNumber": 1300,
                                                                    "articleId": "457195",
                                                                    "description": "457195-01-Distanz",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 1330,
                                                                            "parentRowNumber": 1329,
                                                                            "articleId": "3859213",
                                                                            "description": "3859213-00-Schlauch",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 1331,
                                                                    "parentRowNumber": 1300,
                                                                    "articleId": "457195",
                                                                    "description": "457195-01-Distanz",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 1332,
                                                                            "parentRowNumber": 1331,
                                                                            "articleId": "3859213",
                                                                            "description": "3859213-00-Schlauch",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 1333,
                                                                    "parentRowNumber": 1300,
                                                                    "articleId": "46024147",
                                                                    "description": "46024147-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0011,
                                                                    "childrenWeight": 0.0011,
                                                                    "totalQuantityWeight": 0.0011,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 1334,
                                                                            "parentRowNumber": 1333,
                                                                            "articleId": "163201",
                                                                            "description": "163201-00-Gummiplatte",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0011,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0011,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 1335,
                                                                    "parentRowNumber": 1300,
                                                                    "articleId": "46024147",
                                                                    "description": "46024147-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0011,
                                                                    "childrenWeight": 0.0011,
                                                                    "totalQuantityWeight": 0.0011,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 1336,
                                                                            "parentRowNumber": 1335,
                                                                            "articleId": "163201",
                                                                            "description": "163201-00-Gummiplatte",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0011,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0011,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 1337,
                                                                    "parentRowNumber": 1300,
                                                                    "articleId": "46029594",
                                                                    "description": "46029594-01-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0007,
                                                                    "childrenWeight": 0.0007,
                                                                    "totalQuantityWeight": 0.0007,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 1338,
                                                                            "parentRowNumber": 1337,
                                                                            "articleId": "163201",
                                                                            "description": "163201-00-Gummiplatte",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0007,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0007,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 1339,
                                                                    "parentRowNumber": 1300,
                                                                    "articleId": "46029594",
                                                                    "description": "46029594-01-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0007,
                                                                    "childrenWeight": 0.0007,
                                                                    "totalQuantityWeight": 0.0007,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 1340,
                                                                            "parentRowNumber": 1339,
                                                                            "articleId": "163201",
                                                                            "description": "163201-00-Gummiplatte",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0007,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0007,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 1341,
                                                                    "parentRowNumber": 1300,
                                                                    "articleId": "46101957",
                                                                    "description": "46101957-01-Buchse",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0174,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0174,
                                                                    "singleQuantityMcf": 0.030102,
                                                                    "mcf": 0.030102,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.030102,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 1342,
                                                                    "parentRowNumber": 1300,
                                                                    "articleId": "46101957",
                                                                    "description": "46101957-01-Buchse",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0174,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0174,
                                                                    "singleQuantityMcf": 0.030102,
                                                                    "mcf": 0.030102,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.030102,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 1343,
                                                                    "parentRowNumber": 1300,
                                                                    "articleId": "46101957",
                                                                    "description": "46101957-01-Buchse",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0174,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0174,
                                                                    "singleQuantityMcf": 0.030102,
                                                                    "mcf": 0.030102,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.030102,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 1344,
                                                                    "parentRowNumber": 1300,
                                                                    "articleId": "46101957",
                                                                    "description": "46101957-01-Buchse",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0174,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0174,
                                                                    "singleQuantityMcf": 0.030102,
                                                                    "mcf": 0.030102,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.030102,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 1345,
                                                                    "parentRowNumber": 1300,
                                                                    "articleId": "46102546",
                                                                    "description": "46102546-01-Distanz",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.7827,
                                                                    "childrenWeight": 0.7827,
                                                                    "totalQuantityWeight": 0.7827,
                                                                    "singleQuantityMcf": 4.054385999999999,
                                                                    "mcf": 4.054385999999999,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 4.054385999999999,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 1346,
                                                                            "parentRowNumber": 1345,
                                                                            "articleId": "1261242",
                                                                            "description": "1261242-00-Rohr",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.7827,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.7827,
                                                                            "singleQuantityMcf": 4.054385999999999,
                                                                            "mcf": 4.054385999999999,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 4.054385999999999,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 1347,
                                                                    "parentRowNumber": 1300,
                                                                    "articleId": "46110661",
                                                                    "description": "46110661-04-Scharnier",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.1628,
                                                                    "childrenWeight": 0.1628,
                                                                    "totalQuantityWeight": 0.1628,
                                                                    "singleQuantityMcf": 0.8433039999999999,
                                                                    "mcf": 0.8433039999999999,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.8433039999999999,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 1348,
                                                                            "parentRowNumber": 1347,
                                                                            "articleId": "46110659",
                                                                            "description": "46110659-00-Distanz",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.1159,
                                                                            "childrenWeight": 0.1159,
                                                                            "totalQuantityWeight": 0.1159,
                                                                            "singleQuantityMcf": 0.600362,
                                                                            "mcf": 0.600362,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.600362,
                                                                            "expandable": false,
                                                                            "children": [
                                                                                {
                                                                                    "rowNumber": 1349,
                                                                                    "parentRowNumber": 1348,
                                                                                    "articleId": "125524",
                                                                                    "description": "125524-00-Rohr",
                                                                                    "hierarchy": 9,
                                                                                    "quantity": 1,
                                                                                    "weight": 0.1159,
                                                                                    "childrenWeight": 0,
                                                                                    "totalQuantityWeight": 0.1159,
                                                                                    "singleQuantityMcf": 0.600362,
                                                                                    "mcf": 0.600362,
                                                                                    "singleQuantityLcf": 0,
                                                                                    "lcf": 0,
                                                                                    "totalQuantityMcf": 0.600362,
                                                                                    "expandable": false,
                                                                                    "children": []
                                                                                }
                                                                            ]
                                                                        },
                                                                        {
                                                                            "rowNumber": 1350,
                                                                            "parentRowNumber": 1347,
                                                                            "articleId": "46110660",
                                                                            "description": "46110660-00-Lasche",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0469,
                                                                            "childrenWeight": 0.0469,
                                                                            "totalQuantityWeight": 0.0469,
                                                                            "singleQuantityMcf": 0.242942,
                                                                            "mcf": 0.242942,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.242942,
                                                                            "expandable": false,
                                                                            "children": [
                                                                                {
                                                                                    "rowNumber": 1351,
                                                                                    "parentRowNumber": 1350,
                                                                                    "articleId": "142612",
                                                                                    "description": "142612-00-Edelstahlblech",
                                                                                    "hierarchy": 9,
                                                                                    "quantity": 1,
                                                                                    "weight": 0.0469,
                                                                                    "childrenWeight": 0,
                                                                                    "totalQuantityWeight": 0.0469,
                                                                                    "singleQuantityMcf": 0.242942,
                                                                                    "mcf": 0.242942,
                                                                                    "singleQuantityLcf": 0,
                                                                                    "lcf": 0,
                                                                                    "totalQuantityMcf": 0.242942,
                                                                                    "expandable": false,
                                                                                    "children": []
                                                                                }
                                                                            ]
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 1352,
                                                                    "parentRowNumber": 1300,
                                                                    "articleId": "46110661",
                                                                    "description": "46110661-04-Scharnier",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.1628,
                                                                    "childrenWeight": 0.1628,
                                                                    "totalQuantityWeight": 0.1628,
                                                                    "singleQuantityMcf": 0.8433039999999999,
                                                                    "mcf": 0.8433039999999999,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.8433039999999999,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 1353,
                                                                            "parentRowNumber": 1352,
                                                                            "articleId": "46110659",
                                                                            "description": "46110659-00-Distanz",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.1159,
                                                                            "childrenWeight": 0.1159,
                                                                            "totalQuantityWeight": 0.1159,
                                                                            "singleQuantityMcf": 0.600362,
                                                                            "mcf": 0.600362,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.600362,
                                                                            "expandable": false,
                                                                            "children": [
                                                                                {
                                                                                    "rowNumber": 1354,
                                                                                    "parentRowNumber": 1353,
                                                                                    "articleId": "125524",
                                                                                    "description": "125524-00-Rohr",
                                                                                    "hierarchy": 9,
                                                                                    "quantity": 1,
                                                                                    "weight": 0.1159,
                                                                                    "childrenWeight": 0,
                                                                                    "totalQuantityWeight": 0.1159,
                                                                                    "singleQuantityMcf": 0.600362,
                                                                                    "mcf": 0.600362,
                                                                                    "singleQuantityLcf": 0,
                                                                                    "lcf": 0,
                                                                                    "totalQuantityMcf": 0.600362,
                                                                                    "expandable": false,
                                                                                    "children": []
                                                                                }
                                                                            ]
                                                                        },
                                                                        {
                                                                            "rowNumber": 1355,
                                                                            "parentRowNumber": 1352,
                                                                            "articleId": "46110660",
                                                                            "description": "46110660-00-Lasche",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0469,
                                                                            "childrenWeight": 0.0469,
                                                                            "totalQuantityWeight": 0.0469,
                                                                            "singleQuantityMcf": 0.242942,
                                                                            "mcf": 0.242942,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.242942,
                                                                            "expandable": false,
                                                                            "children": [
                                                                                {
                                                                                    "rowNumber": 1356,
                                                                                    "parentRowNumber": 1355,
                                                                                    "articleId": "142612",
                                                                                    "description": "142612-00-Edelstahlblech",
                                                                                    "hierarchy": 9,
                                                                                    "quantity": 1,
                                                                                    "weight": 0.0469,
                                                                                    "childrenWeight": 0,
                                                                                    "totalQuantityWeight": 0.0469,
                                                                                    "singleQuantityMcf": 0.242942,
                                                                                    "mcf": 0.242942,
                                                                                    "singleQuantityLcf": 0,
                                                                                    "lcf": 0,
                                                                                    "totalQuantityMcf": 0.242942,
                                                                                    "expandable": false,
                                                                                    "children": []
                                                                                }
                                                                            ]
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 1357,
                                                                    "parentRowNumber": 1300,
                                                                    "articleId": "46125780",
                                                                    "description": "46125780-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0079,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0079,
                                                                    "singleQuantityMcf": 0.040922,
                                                                    "mcf": 0.040922,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.040922,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 1358,
                                                                    "parentRowNumber": 1300,
                                                                    "articleId": "46125780",
                                                                    "description": "46125780-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0079,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0079,
                                                                    "singleQuantityMcf": 0.040922,
                                                                    "mcf": 0.040922,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.040922,
                                                                    "expandable": false,
                                                                    "children": []
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            "rowNumber": 1359,
                                                            "parentRowNumber": 871,
                                                            "articleId": "46320423",
                                                            "description": "46320423-05-Schutzpfosten oben",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 1.3527000000000002,
                                                            "childrenWeight": 1.3527000000000002,
                                                            "totalQuantityWeight": 1.3527000000000002,
                                                            "singleQuantityMcf": 6.629997800000003,
                                                            "mcf": 6.629997800000003,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 6.629997800000003,
                                                            "expandable": false,
                                                            "children": [
                                                                {
                                                                    "rowNumber": 1360,
                                                                    "parentRowNumber": 1359,
                                                                    "articleId": "20270690",
                                                                    "description": "20270690-00-Zylinderschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0234,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0234,
                                                                    "singleQuantityMcf": 0.121212,
                                                                    "mcf": 0.121212,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.121212,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 1361,
                                                                    "parentRowNumber": 1359,
                                                                    "articleId": "208205",
                                                                    "description": "208205-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0004,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0004,
                                                                    "singleQuantityMcf": 0.002072,
                                                                    "mcf": 0.002072,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.002072,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 1362,
                                                                    "parentRowNumber": 1359,
                                                                    "articleId": "208205",
                                                                    "description": "208205-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0004,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0004,
                                                                    "singleQuantityMcf": 0.002072,
                                                                    "mcf": 0.002072,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.002072,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 1363,
                                                                    "parentRowNumber": 1359,
                                                                    "articleId": "216232",
                                                                    "description": "216232-00-Sicherungsring",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0023,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0023,
                                                                    "singleQuantityMcf": 0.0039376,
                                                                    "mcf": 0.0039376,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.0039376,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 1364,
                                                                    "parentRowNumber": 1359,
                                                                    "articleId": "216232",
                                                                    "description": "216232-00-Sicherungsring",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0023,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0023,
                                                                    "singleQuantityMcf": 0.0039376,
                                                                    "mcf": 0.0039376,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.0039376,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 1365,
                                                                    "parentRowNumber": 1359,
                                                                    "articleId": "216232",
                                                                    "description": "216232-00-Sicherungsring",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0023,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0023,
                                                                    "singleQuantityMcf": 0.0039376,
                                                                    "mcf": 0.0039376,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.0039376,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 1366,
                                                                    "parentRowNumber": 1359,
                                                                    "articleId": "457007",
                                                                    "description": "457007-03-Klemmstueck",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0465,
                                                                    "childrenWeight": 0.0465,
                                                                    "totalQuantityWeight": 0.0465,
                                                                    "singleQuantityMcf": 0.24087,
                                                                    "mcf": 0.24087,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.24087,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 1367,
                                                                            "parentRowNumber": 1366,
                                                                            "articleId": "1260038",
                                                                            "description": "1260038-00-Rundstahl",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0465,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0465,
                                                                            "singleQuantityMcf": 0.24087,
                                                                            "mcf": 0.24087,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.24087,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 1368,
                                                                    "parentRowNumber": 1359,
                                                                    "articleId": "457007",
                                                                    "description": "457007-03-Klemmstueck",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0465,
                                                                    "childrenWeight": 0.0465,
                                                                    "totalQuantityWeight": 0.0465,
                                                                    "singleQuantityMcf": 0.24087,
                                                                    "mcf": 0.24087,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.24087,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 1369,
                                                                            "parentRowNumber": 1368,
                                                                            "articleId": "1260038",
                                                                            "description": "1260038-00-Rundstahl",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0465,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0465,
                                                                            "singleQuantityMcf": 0.24087,
                                                                            "mcf": 0.24087,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.24087,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 1370,
                                                                    "parentRowNumber": 1359,
                                                                    "articleId": "457051",
                                                                    "description": "457051-03-Distanz",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0077,
                                                                    "childrenWeight": 0.0077,
                                                                    "totalQuantityWeight": 0.0077,
                                                                    "singleQuantityMcf": 0.013321,
                                                                    "mcf": 0.013321,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.013321,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 1371,
                                                                            "parentRowNumber": 1370,
                                                                            "articleId": "164710",
                                                                            "description": "164710-00-Hohlstab",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0077,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0077,
                                                                            "singleQuantityMcf": 0.013321,
                                                                            "mcf": 0.013321,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.013321,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 1372,
                                                                    "parentRowNumber": 1359,
                                                                    "articleId": "457051",
                                                                    "description": "457051-03-Distanz",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0077,
                                                                    "childrenWeight": 0.0077,
                                                                    "totalQuantityWeight": 0.0077,
                                                                    "singleQuantityMcf": 0.013321,
                                                                    "mcf": 0.013321,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.013321,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 1373,
                                                                            "parentRowNumber": 1372,
                                                                            "articleId": "164710",
                                                                            "description": "164710-00-Hohlstab",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0077,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0077,
                                                                            "singleQuantityMcf": 0.013321,
                                                                            "mcf": 0.013321,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.013321,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 1374,
                                                                    "parentRowNumber": 1359,
                                                                    "articleId": "457051",
                                                                    "description": "457051-03-Distanz",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0077,
                                                                    "childrenWeight": 0.0077,
                                                                    "totalQuantityWeight": 0.0077,
                                                                    "singleQuantityMcf": 0.013321,
                                                                    "mcf": 0.013321,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.013321,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 1375,
                                                                            "parentRowNumber": 1374,
                                                                            "articleId": "164710",
                                                                            "description": "164710-00-Hohlstab",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0077,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0077,
                                                                            "singleQuantityMcf": 0.013321,
                                                                            "mcf": 0.013321,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.013321,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 1376,
                                                                    "parentRowNumber": 1359,
                                                                    "articleId": "457190",
                                                                    "description": "457190-01-Distanzplatte",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0012,
                                                                    "childrenWeight": 0.0012,
                                                                    "totalQuantityWeight": 0.0012,
                                                                    "singleQuantityMcf": 0.004079999999999999,
                                                                    "mcf": 0.004079999999999999,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.004079999999999999,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 1377,
                                                                            "parentRowNumber": 1376,
                                                                            "articleId": "162431",
                                                                            "description": "162431-00-Platte",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0012,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0012,
                                                                            "singleQuantityMcf": 0.004079999999999999,
                                                                            "mcf": 0.004079999999999999,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.004079999999999999,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 1378,
                                                                    "parentRowNumber": 1359,
                                                                    "articleId": "457190",
                                                                    "description": "457190-01-Distanzplatte",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0012,
                                                                    "childrenWeight": 0.0012,
                                                                    "totalQuantityWeight": 0.0012,
                                                                    "singleQuantityMcf": 0.004079999999999999,
                                                                    "mcf": 0.004079999999999999,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.004079999999999999,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 1379,
                                                                            "parentRowNumber": 1378,
                                                                            "articleId": "162431",
                                                                            "description": "162431-00-Platte",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0012,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0012,
                                                                            "singleQuantityMcf": 0.004079999999999999,
                                                                            "mcf": 0.004079999999999999,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.004079999999999999,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 1380,
                                                                    "parentRowNumber": 1359,
                                                                    "articleId": "457193",
                                                                    "description": "457193-01-Distanzplatte",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0021,
                                                                    "childrenWeight": 0.0021,
                                                                    "totalQuantityWeight": 0.0021,
                                                                    "singleQuantityMcf": 0.00714,
                                                                    "mcf": 0.00714,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.00714,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 1381,
                                                                            "parentRowNumber": 1380,
                                                                            "articleId": "162431",
                                                                            "description": "162431-00-Platte",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0021,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0021,
                                                                            "singleQuantityMcf": 0.00714,
                                                                            "mcf": 0.00714,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.00714,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 1382,
                                                                    "parentRowNumber": 1359,
                                                                    "articleId": "457193",
                                                                    "description": "457193-01-Distanzplatte",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0021,
                                                                    "childrenWeight": 0.0021,
                                                                    "totalQuantityWeight": 0.0021,
                                                                    "singleQuantityMcf": 0.00714,
                                                                    "mcf": 0.00714,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.00714,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 1383,
                                                                            "parentRowNumber": 1382,
                                                                            "articleId": "162431",
                                                                            "description": "162431-00-Platte",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0021,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0021,
                                                                            "singleQuantityMcf": 0.00714,
                                                                            "mcf": 0.00714,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.00714,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 1384,
                                                                    "parentRowNumber": 1359,
                                                                    "articleId": "457194",
                                                                    "description": "457194-01-Distanzplatte",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0008,
                                                                    "childrenWeight": 0.0008,
                                                                    "totalQuantityWeight": 0.0008,
                                                                    "singleQuantityMcf": 0.00272,
                                                                    "mcf": 0.00272,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.00272,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 1385,
                                                                            "parentRowNumber": 1384,
                                                                            "articleId": "162431",
                                                                            "description": "162431-00-Platte",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0008,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0008,
                                                                            "singleQuantityMcf": 0.00272,
                                                                            "mcf": 0.00272,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.00272,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 1386,
                                                                    "parentRowNumber": 1359,
                                                                    "articleId": "457194",
                                                                    "description": "457194-01-Distanzplatte",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0008,
                                                                    "childrenWeight": 0.0008,
                                                                    "totalQuantityWeight": 0.0008,
                                                                    "singleQuantityMcf": 0.00272,
                                                                    "mcf": 0.00272,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.00272,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 1387,
                                                                            "parentRowNumber": 1386,
                                                                            "articleId": "162431",
                                                                            "description": "162431-00-Platte",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0008,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0008,
                                                                            "singleQuantityMcf": 0.00272,
                                                                            "mcf": 0.00272,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.00272,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 1388,
                                                                    "parentRowNumber": 1359,
                                                                    "articleId": "457195",
                                                                    "description": "457195-01-Distanz",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 1389,
                                                                            "parentRowNumber": 1388,
                                                                            "articleId": "3859213",
                                                                            "description": "3859213-00-Schlauch",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 1390,
                                                                    "parentRowNumber": 1359,
                                                                    "articleId": "457195",
                                                                    "description": "457195-01-Distanz",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 1391,
                                                                            "parentRowNumber": 1390,
                                                                            "articleId": "3859213",
                                                                            "description": "3859213-00-Schlauch",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 1392,
                                                                    "parentRowNumber": 1359,
                                                                    "articleId": "46024147",
                                                                    "description": "46024147-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0011,
                                                                    "childrenWeight": 0.0011,
                                                                    "totalQuantityWeight": 0.0011,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 1393,
                                                                            "parentRowNumber": 1392,
                                                                            "articleId": "163201",
                                                                            "description": "163201-00-Gummiplatte",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0011,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0011,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 1394,
                                                                    "parentRowNumber": 1359,
                                                                    "articleId": "46024147",
                                                                    "description": "46024147-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0011,
                                                                    "childrenWeight": 0.0011,
                                                                    "totalQuantityWeight": 0.0011,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 1395,
                                                                            "parentRowNumber": 1394,
                                                                            "articleId": "163201",
                                                                            "description": "163201-00-Gummiplatte",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0011,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0011,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 1396,
                                                                    "parentRowNumber": 1359,
                                                                    "articleId": "46029594",
                                                                    "description": "46029594-01-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0007,
                                                                    "childrenWeight": 0.0007,
                                                                    "totalQuantityWeight": 0.0007,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 1397,
                                                                            "parentRowNumber": 1396,
                                                                            "articleId": "163201",
                                                                            "description": "163201-00-Gummiplatte",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0007,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0007,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 1398,
                                                                    "parentRowNumber": 1359,
                                                                    "articleId": "46029594",
                                                                    "description": "46029594-01-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0007,
                                                                    "childrenWeight": 0.0007,
                                                                    "totalQuantityWeight": 0.0007,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 1399,
                                                                            "parentRowNumber": 1398,
                                                                            "articleId": "163201",
                                                                            "description": "163201-00-Gummiplatte",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0007,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0007,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 1400,
                                                                    "parentRowNumber": 1359,
                                                                    "articleId": "46101957",
                                                                    "description": "46101957-01-Buchse",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0174,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0174,
                                                                    "singleQuantityMcf": 0.030102,
                                                                    "mcf": 0.030102,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.030102,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 1401,
                                                                    "parentRowNumber": 1359,
                                                                    "articleId": "46101957",
                                                                    "description": "46101957-01-Buchse",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0174,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0174,
                                                                    "singleQuantityMcf": 0.030102,
                                                                    "mcf": 0.030102,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.030102,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 1402,
                                                                    "parentRowNumber": 1359,
                                                                    "articleId": "46101957",
                                                                    "description": "46101957-01-Buchse",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0174,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0174,
                                                                    "singleQuantityMcf": 0.030102,
                                                                    "mcf": 0.030102,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.030102,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 1403,
                                                                    "parentRowNumber": 1359,
                                                                    "articleId": "46101957",
                                                                    "description": "46101957-01-Buchse",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0174,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0174,
                                                                    "singleQuantityMcf": 0.030102,
                                                                    "mcf": 0.030102,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.030102,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 1404,
                                                                    "parentRowNumber": 1359,
                                                                    "articleId": "46102546",
                                                                    "description": "46102546-01-Distanz",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.7827,
                                                                    "childrenWeight": 0.7827,
                                                                    "totalQuantityWeight": 0.7827,
                                                                    "singleQuantityMcf": 4.054385999999999,
                                                                    "mcf": 4.054385999999999,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 4.054385999999999,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 1405,
                                                                            "parentRowNumber": 1404,
                                                                            "articleId": "1261242",
                                                                            "description": "1261242-00-Rohr",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.7827,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.7827,
                                                                            "singleQuantityMcf": 4.054385999999999,
                                                                            "mcf": 4.054385999999999,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 4.054385999999999,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 1406,
                                                                    "parentRowNumber": 1359,
                                                                    "articleId": "46110661",
                                                                    "description": "46110661-04-Scharnier",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.1628,
                                                                    "childrenWeight": 0.1628,
                                                                    "totalQuantityWeight": 0.1628,
                                                                    "singleQuantityMcf": 0.8433039999999999,
                                                                    "mcf": 0.8433039999999999,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.8433039999999999,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 1407,
                                                                            "parentRowNumber": 1406,
                                                                            "articleId": "46110659",
                                                                            "description": "46110659-00-Distanz",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.1159,
                                                                            "childrenWeight": 0.1159,
                                                                            "totalQuantityWeight": 0.1159,
                                                                            "singleQuantityMcf": 0.600362,
                                                                            "mcf": 0.600362,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.600362,
                                                                            "expandable": false,
                                                                            "children": [
                                                                                {
                                                                                    "rowNumber": 1408,
                                                                                    "parentRowNumber": 1407,
                                                                                    "articleId": "125524",
                                                                                    "description": "125524-00-Rohr",
                                                                                    "hierarchy": 9,
                                                                                    "quantity": 1,
                                                                                    "weight": 0.1159,
                                                                                    "childrenWeight": 0,
                                                                                    "totalQuantityWeight": 0.1159,
                                                                                    "singleQuantityMcf": 0.600362,
                                                                                    "mcf": 0.600362,
                                                                                    "singleQuantityLcf": 0,
                                                                                    "lcf": 0,
                                                                                    "totalQuantityMcf": 0.600362,
                                                                                    "expandable": false,
                                                                                    "children": []
                                                                                }
                                                                            ]
                                                                        },
                                                                        {
                                                                            "rowNumber": 1409,
                                                                            "parentRowNumber": 1406,
                                                                            "articleId": "46110660",
                                                                            "description": "46110660-00-Lasche",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0469,
                                                                            "childrenWeight": 0.0469,
                                                                            "totalQuantityWeight": 0.0469,
                                                                            "singleQuantityMcf": 0.242942,
                                                                            "mcf": 0.242942,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.242942,
                                                                            "expandable": false,
                                                                            "children": [
                                                                                {
                                                                                    "rowNumber": 1410,
                                                                                    "parentRowNumber": 1409,
                                                                                    "articleId": "142612",
                                                                                    "description": "142612-00-Edelstahlblech",
                                                                                    "hierarchy": 9,
                                                                                    "quantity": 1,
                                                                                    "weight": 0.0469,
                                                                                    "childrenWeight": 0,
                                                                                    "totalQuantityWeight": 0.0469,
                                                                                    "singleQuantityMcf": 0.242942,
                                                                                    "mcf": 0.242942,
                                                                                    "singleQuantityLcf": 0,
                                                                                    "lcf": 0,
                                                                                    "totalQuantityMcf": 0.242942,
                                                                                    "expandable": false,
                                                                                    "children": []
                                                                                }
                                                                            ]
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 1411,
                                                                    "parentRowNumber": 1359,
                                                                    "articleId": "46110661",
                                                                    "description": "46110661-04-Scharnier",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.1628,
                                                                    "childrenWeight": 0.1628,
                                                                    "totalQuantityWeight": 0.1628,
                                                                    "singleQuantityMcf": 0.8433039999999999,
                                                                    "mcf": 0.8433039999999999,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.8433039999999999,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 1412,
                                                                            "parentRowNumber": 1411,
                                                                            "articleId": "46110659",
                                                                            "description": "46110659-00-Distanz",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.1159,
                                                                            "childrenWeight": 0.1159,
                                                                            "totalQuantityWeight": 0.1159,
                                                                            "singleQuantityMcf": 0.600362,
                                                                            "mcf": 0.600362,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.600362,
                                                                            "expandable": false,
                                                                            "children": [
                                                                                {
                                                                                    "rowNumber": 1413,
                                                                                    "parentRowNumber": 1412,
                                                                                    "articleId": "125524",
                                                                                    "description": "125524-00-Rohr",
                                                                                    "hierarchy": 9,
                                                                                    "quantity": 1,
                                                                                    "weight": 0.1159,
                                                                                    "childrenWeight": 0,
                                                                                    "totalQuantityWeight": 0.1159,
                                                                                    "singleQuantityMcf": 0.600362,
                                                                                    "mcf": 0.600362,
                                                                                    "singleQuantityLcf": 0,
                                                                                    "lcf": 0,
                                                                                    "totalQuantityMcf": 0.600362,
                                                                                    "expandable": false,
                                                                                    "children": []
                                                                                }
                                                                            ]
                                                                        },
                                                                        {
                                                                            "rowNumber": 1414,
                                                                            "parentRowNumber": 1411,
                                                                            "articleId": "46110660",
                                                                            "description": "46110660-00-Lasche",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0469,
                                                                            "childrenWeight": 0.0469,
                                                                            "totalQuantityWeight": 0.0469,
                                                                            "singleQuantityMcf": 0.242942,
                                                                            "mcf": 0.242942,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.242942,
                                                                            "expandable": false,
                                                                            "children": [
                                                                                {
                                                                                    "rowNumber": 1415,
                                                                                    "parentRowNumber": 1414,
                                                                                    "articleId": "142612",
                                                                                    "description": "142612-00-Edelstahlblech",
                                                                                    "hierarchy": 9,
                                                                                    "quantity": 1,
                                                                                    "weight": 0.0469,
                                                                                    "childrenWeight": 0,
                                                                                    "totalQuantityWeight": 0.0469,
                                                                                    "singleQuantityMcf": 0.242942,
                                                                                    "mcf": 0.242942,
                                                                                    "singleQuantityLcf": 0,
                                                                                    "lcf": 0,
                                                                                    "totalQuantityMcf": 0.242942,
                                                                                    "expandable": false,
                                                                                    "children": []
                                                                                }
                                                                            ]
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 1416,
                                                                    "parentRowNumber": 1359,
                                                                    "articleId": "46125780",
                                                                    "description": "46125780-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0079,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0079,
                                                                    "singleQuantityMcf": 0.040922,
                                                                    "mcf": 0.040922,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.040922,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 1417,
                                                                    "parentRowNumber": 1359,
                                                                    "articleId": "46125780",
                                                                    "description": "46125780-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0079,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0079,
                                                                    "singleQuantityMcf": 0.040922,
                                                                    "mcf": 0.040922,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.040922,
                                                                    "expandable": false,
                                                                    "children": []
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            "rowNumber": 1418,
                                                            "parentRowNumber": 871,
                                                            "articleId": "46320437",
                                                            "description": "46320437-05-Schutzpfosten unten",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 1.2924000000000004,
                                                            "childrenWeight": 1.2924000000000004,
                                                            "totalQuantityWeight": 1.2924000000000004,
                                                            "singleQuantityMcf": 6.463378600000001,
                                                            "mcf": 6.463378600000001,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 6.463378600000001,
                                                            "expandable": false,
                                                            "children": [
                                                                {
                                                                    "rowNumber": 1419,
                                                                    "parentRowNumber": 1418,
                                                                    "articleId": "208205",
                                                                    "description": "208205-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0004,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0004,
                                                                    "singleQuantityMcf": 0.002072,
                                                                    "mcf": 0.002072,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.002072,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 1420,
                                                                    "parentRowNumber": 1418,
                                                                    "articleId": "216232",
                                                                    "description": "216232-00-Sicherungsring",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0023,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0023,
                                                                    "singleQuantityMcf": 0.0039376,
                                                                    "mcf": 0.0039376,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.0039376,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 1421,
                                                                    "parentRowNumber": 1418,
                                                                    "articleId": "457007",
                                                                    "description": "457007-03-Klemmstueck",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0465,
                                                                    "childrenWeight": 0.0465,
                                                                    "totalQuantityWeight": 0.0465,
                                                                    "singleQuantityMcf": 0.24087,
                                                                    "mcf": 0.24087,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.24087,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 1422,
                                                                            "parentRowNumber": 1421,
                                                                            "articleId": "1260038",
                                                                            "description": "1260038-00-Rundstahl",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0465,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0465,
                                                                            "singleQuantityMcf": 0.24087,
                                                                            "mcf": 0.24087,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.24087,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 1423,
                                                                    "parentRowNumber": 1418,
                                                                    "articleId": "457051",
                                                                    "description": "457051-03-Distanz",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0077,
                                                                    "childrenWeight": 0.0077,
                                                                    "totalQuantityWeight": 0.0077,
                                                                    "singleQuantityMcf": 0.013321,
                                                                    "mcf": 0.013321,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.013321,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 1424,
                                                                            "parentRowNumber": 1423,
                                                                            "articleId": "164710",
                                                                            "description": "164710-00-Hohlstab",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0077,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0077,
                                                                            "singleQuantityMcf": 0.013321,
                                                                            "mcf": 0.013321,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.013321,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 1425,
                                                                    "parentRowNumber": 1418,
                                                                    "articleId": "457190",
                                                                    "description": "457190-01-Distanzplatte",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0012,
                                                                    "childrenWeight": 0.0012,
                                                                    "totalQuantityWeight": 0.0012,
                                                                    "singleQuantityMcf": 0.004079999999999999,
                                                                    "mcf": 0.004079999999999999,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.004079999999999999,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 1426,
                                                                            "parentRowNumber": 1425,
                                                                            "articleId": "162431",
                                                                            "description": "162431-00-Platte",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0012,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0012,
                                                                            "singleQuantityMcf": 0.004079999999999999,
                                                                            "mcf": 0.004079999999999999,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.004079999999999999,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 1427,
                                                                    "parentRowNumber": 1418,
                                                                    "articleId": "457193",
                                                                    "description": "457193-01-Distanzplatte",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0021,
                                                                    "childrenWeight": 0.0021,
                                                                    "totalQuantityWeight": 0.0021,
                                                                    "singleQuantityMcf": 0.00714,
                                                                    "mcf": 0.00714,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.00714,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 1428,
                                                                            "parentRowNumber": 1427,
                                                                            "articleId": "162431",
                                                                            "description": "162431-00-Platte",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0021,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0021,
                                                                            "singleQuantityMcf": 0.00714,
                                                                            "mcf": 0.00714,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.00714,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 1429,
                                                                    "parentRowNumber": 1418,
                                                                    "articleId": "457194",
                                                                    "description": "457194-01-Distanzplatte",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0008,
                                                                    "childrenWeight": 0.0008,
                                                                    "totalQuantityWeight": 0.0008,
                                                                    "singleQuantityMcf": 0.00272,
                                                                    "mcf": 0.00272,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.00272,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 1430,
                                                                            "parentRowNumber": 1429,
                                                                            "articleId": "162431",
                                                                            "description": "162431-00-Platte",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0008,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0008,
                                                                            "singleQuantityMcf": 0.00272,
                                                                            "mcf": 0.00272,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.00272,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 1431,
                                                                    "parentRowNumber": 1418,
                                                                    "articleId": "457195",
                                                                    "description": "457195-01-Distanz",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 1432,
                                                                            "parentRowNumber": 1431,
                                                                            "articleId": "3859213",
                                                                            "description": "3859213-00-Schlauch",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 1433,
                                                                    "parentRowNumber": 1418,
                                                                    "articleId": "46024147",
                                                                    "description": "46024147-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0011,
                                                                    "childrenWeight": 0.0011,
                                                                    "totalQuantityWeight": 0.0011,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 1434,
                                                                            "parentRowNumber": 1433,
                                                                            "articleId": "163201",
                                                                            "description": "163201-00-Gummiplatte",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0011,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0011,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 1435,
                                                                    "parentRowNumber": 1418,
                                                                    "articleId": "46029594",
                                                                    "description": "46029594-01-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0007,
                                                                    "childrenWeight": 0.0007,
                                                                    "totalQuantityWeight": 0.0007,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 1436,
                                                                            "parentRowNumber": 1435,
                                                                            "articleId": "163201",
                                                                            "description": "163201-00-Gummiplatte",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0007,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0007,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 1437,
                                                                    "parentRowNumber": 1418,
                                                                    "articleId": "46101957",
                                                                    "description": "46101957-01-Buchse",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0174,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0174,
                                                                    "singleQuantityMcf": 0.030102,
                                                                    "mcf": 0.030102,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.030102,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 1438,
                                                                    "parentRowNumber": 1418,
                                                                    "articleId": "46101957",
                                                                    "description": "46101957-01-Buchse",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0174,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0174,
                                                                    "singleQuantityMcf": 0.030102,
                                                                    "mcf": 0.030102,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.030102,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 1439,
                                                                    "parentRowNumber": 1418,
                                                                    "articleId": "46101957",
                                                                    "description": "46101957-01-Buchse",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0174,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0174,
                                                                    "singleQuantityMcf": 0.030102,
                                                                    "mcf": 0.030102,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.030102,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 1440,
                                                                    "parentRowNumber": 1418,
                                                                    "articleId": "46110661",
                                                                    "description": "46110661-04-Scharnier",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.1628,
                                                                    "childrenWeight": 0.1628,
                                                                    "totalQuantityWeight": 0.1628,
                                                                    "singleQuantityMcf": 0.8433039999999999,
                                                                    "mcf": 0.8433039999999999,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.8433039999999999,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 1441,
                                                                            "parentRowNumber": 1440,
                                                                            "articleId": "46110659",
                                                                            "description": "46110659-00-Distanz",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.1159,
                                                                            "childrenWeight": 0.1159,
                                                                            "totalQuantityWeight": 0.1159,
                                                                            "singleQuantityMcf": 0.600362,
                                                                            "mcf": 0.600362,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.600362,
                                                                            "expandable": false,
                                                                            "children": [
                                                                                {
                                                                                    "rowNumber": 1442,
                                                                                    "parentRowNumber": 1441,
                                                                                    "articleId": "125524",
                                                                                    "description": "125524-00-Rohr",
                                                                                    "hierarchy": 9,
                                                                                    "quantity": 1,
                                                                                    "weight": 0.1159,
                                                                                    "childrenWeight": 0,
                                                                                    "totalQuantityWeight": 0.1159,
                                                                                    "singleQuantityMcf": 0.600362,
                                                                                    "mcf": 0.600362,
                                                                                    "singleQuantityLcf": 0,
                                                                                    "lcf": 0,
                                                                                    "totalQuantityMcf": 0.600362,
                                                                                    "expandable": false,
                                                                                    "children": []
                                                                                }
                                                                            ]
                                                                        },
                                                                        {
                                                                            "rowNumber": 1443,
                                                                            "parentRowNumber": 1440,
                                                                            "articleId": "46110660",
                                                                            "description": "46110660-00-Lasche",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0469,
                                                                            "childrenWeight": 0.0469,
                                                                            "totalQuantityWeight": 0.0469,
                                                                            "singleQuantityMcf": 0.242942,
                                                                            "mcf": 0.242942,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.242942,
                                                                            "expandable": false,
                                                                            "children": [
                                                                                {
                                                                                    "rowNumber": 1444,
                                                                                    "parentRowNumber": 1443,
                                                                                    "articleId": "142612",
                                                                                    "description": "142612-00-Edelstahlblech",
                                                                                    "hierarchy": 9,
                                                                                    "quantity": 1,
                                                                                    "weight": 0.0469,
                                                                                    "childrenWeight": 0,
                                                                                    "totalQuantityWeight": 0.0469,
                                                                                    "singleQuantityMcf": 0.242942,
                                                                                    "mcf": 0.242942,
                                                                                    "singleQuantityLcf": 0,
                                                                                    "lcf": 0,
                                                                                    "totalQuantityMcf": 0.242942,
                                                                                    "expandable": false,
                                                                                    "children": []
                                                                                }
                                                                            ]
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 1445,
                                                                    "parentRowNumber": 1418,
                                                                    "articleId": "46125780",
                                                                    "description": "46125780-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0079,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0079,
                                                                    "singleQuantityMcf": 0.040922,
                                                                    "mcf": 0.040922,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.040922,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 1446,
                                                                    "parentRowNumber": 1418,
                                                                    "articleId": "46316696",
                                                                    "description": "46316696-04-Distanz",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0848,
                                                                    "childrenWeight": 0.0848,
                                                                    "totalQuantityWeight": 0.0848,
                                                                    "singleQuantityMcf": 0.439264,
                                                                    "mcf": 0.439264,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.439264,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 1447,
                                                                            "parentRowNumber": 1446,
                                                                            "articleId": "1260038",
                                                                            "description": "1260038-00-Rundstahl",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0848,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0848,
                                                                            "singleQuantityMcf": 0.439264,
                                                                            "mcf": 0.439264,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.439264,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 1448,
                                                                    "parentRowNumber": 1418,
                                                                    "articleId": "46321808",
                                                                    "description": "46321808-01-Distanz",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.9219,
                                                                    "childrenWeight": 0.9219,
                                                                    "totalQuantityWeight": 0.9219,
                                                                    "singleQuantityMcf": 4.775442,
                                                                    "mcf": 4.775442,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 4.775442,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 1449,
                                                                            "parentRowNumber": 1448,
                                                                            "articleId": "125524",
                                                                            "description": "125524-00-Rohr",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.9219,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.9219,
                                                                            "singleQuantityMcf": 4.775442,
                                                                            "mcf": 4.775442,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 4.775442,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            "rowNumber": 1450,
                                                            "parentRowNumber": 871,
                                                            "articleId": "46320437",
                                                            "description": "46320437-05-Schutzpfosten unten",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 1.2924000000000004,
                                                            "childrenWeight": 1.2924000000000004,
                                                            "totalQuantityWeight": 1.2924000000000004,
                                                            "singleQuantityMcf": 6.463378600000001,
                                                            "mcf": 6.463378600000001,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 6.463378600000001,
                                                            "expandable": false,
                                                            "children": [
                                                                {
                                                                    "rowNumber": 1451,
                                                                    "parentRowNumber": 1450,
                                                                    "articleId": "208205",
                                                                    "description": "208205-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0004,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0004,
                                                                    "singleQuantityMcf": 0.002072,
                                                                    "mcf": 0.002072,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.002072,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 1452,
                                                                    "parentRowNumber": 1450,
                                                                    "articleId": "216232",
                                                                    "description": "216232-00-Sicherungsring",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0023,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0023,
                                                                    "singleQuantityMcf": 0.0039376,
                                                                    "mcf": 0.0039376,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.0039376,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 1453,
                                                                    "parentRowNumber": 1450,
                                                                    "articleId": "457007",
                                                                    "description": "457007-03-Klemmstueck",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0465,
                                                                    "childrenWeight": 0.0465,
                                                                    "totalQuantityWeight": 0.0465,
                                                                    "singleQuantityMcf": 0.24087,
                                                                    "mcf": 0.24087,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.24087,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 1454,
                                                                            "parentRowNumber": 1453,
                                                                            "articleId": "1260038",
                                                                            "description": "1260038-00-Rundstahl",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0465,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0465,
                                                                            "singleQuantityMcf": 0.24087,
                                                                            "mcf": 0.24087,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.24087,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 1455,
                                                                    "parentRowNumber": 1450,
                                                                    "articleId": "457051",
                                                                    "description": "457051-03-Distanz",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0077,
                                                                    "childrenWeight": 0.0077,
                                                                    "totalQuantityWeight": 0.0077,
                                                                    "singleQuantityMcf": 0.013321,
                                                                    "mcf": 0.013321,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.013321,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 1456,
                                                                            "parentRowNumber": 1455,
                                                                            "articleId": "164710",
                                                                            "description": "164710-00-Hohlstab",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0077,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0077,
                                                                            "singleQuantityMcf": 0.013321,
                                                                            "mcf": 0.013321,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.013321,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 1457,
                                                                    "parentRowNumber": 1450,
                                                                    "articleId": "457190",
                                                                    "description": "457190-01-Distanzplatte",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0012,
                                                                    "childrenWeight": 0.0012,
                                                                    "totalQuantityWeight": 0.0012,
                                                                    "singleQuantityMcf": 0.004079999999999999,
                                                                    "mcf": 0.004079999999999999,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.004079999999999999,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 1458,
                                                                            "parentRowNumber": 1457,
                                                                            "articleId": "162431",
                                                                            "description": "162431-00-Platte",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0012,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0012,
                                                                            "singleQuantityMcf": 0.004079999999999999,
                                                                            "mcf": 0.004079999999999999,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.004079999999999999,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 1459,
                                                                    "parentRowNumber": 1450,
                                                                    "articleId": "457193",
                                                                    "description": "457193-01-Distanzplatte",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0021,
                                                                    "childrenWeight": 0.0021,
                                                                    "totalQuantityWeight": 0.0021,
                                                                    "singleQuantityMcf": 0.00714,
                                                                    "mcf": 0.00714,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.00714,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 1460,
                                                                            "parentRowNumber": 1459,
                                                                            "articleId": "162431",
                                                                            "description": "162431-00-Platte",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0021,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0021,
                                                                            "singleQuantityMcf": 0.00714,
                                                                            "mcf": 0.00714,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.00714,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 1461,
                                                                    "parentRowNumber": 1450,
                                                                    "articleId": "457194",
                                                                    "description": "457194-01-Distanzplatte",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0008,
                                                                    "childrenWeight": 0.0008,
                                                                    "totalQuantityWeight": 0.0008,
                                                                    "singleQuantityMcf": 0.00272,
                                                                    "mcf": 0.00272,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.00272,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 1462,
                                                                            "parentRowNumber": 1461,
                                                                            "articleId": "162431",
                                                                            "description": "162431-00-Platte",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0008,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0008,
                                                                            "singleQuantityMcf": 0.00272,
                                                                            "mcf": 0.00272,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.00272,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 1463,
                                                                    "parentRowNumber": 1450,
                                                                    "articleId": "457195",
                                                                    "description": "457195-01-Distanz",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 1464,
                                                                            "parentRowNumber": 1463,
                                                                            "articleId": "3859213",
                                                                            "description": "3859213-00-Schlauch",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 1465,
                                                                    "parentRowNumber": 1450,
                                                                    "articleId": "46024147",
                                                                    "description": "46024147-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0011,
                                                                    "childrenWeight": 0.0011,
                                                                    "totalQuantityWeight": 0.0011,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 1466,
                                                                            "parentRowNumber": 1465,
                                                                            "articleId": "163201",
                                                                            "description": "163201-00-Gummiplatte",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0011,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0011,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 1467,
                                                                    "parentRowNumber": 1450,
                                                                    "articleId": "46029594",
                                                                    "description": "46029594-01-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0007,
                                                                    "childrenWeight": 0.0007,
                                                                    "totalQuantityWeight": 0.0007,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 1468,
                                                                            "parentRowNumber": 1467,
                                                                            "articleId": "163201",
                                                                            "description": "163201-00-Gummiplatte",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0007,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0007,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 1469,
                                                                    "parentRowNumber": 1450,
                                                                    "articleId": "46101957",
                                                                    "description": "46101957-01-Buchse",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0174,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0174,
                                                                    "singleQuantityMcf": 0.030102,
                                                                    "mcf": 0.030102,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.030102,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 1470,
                                                                    "parentRowNumber": 1450,
                                                                    "articleId": "46101957",
                                                                    "description": "46101957-01-Buchse",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0174,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0174,
                                                                    "singleQuantityMcf": 0.030102,
                                                                    "mcf": 0.030102,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.030102,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 1471,
                                                                    "parentRowNumber": 1450,
                                                                    "articleId": "46101957",
                                                                    "description": "46101957-01-Buchse",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0174,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0174,
                                                                    "singleQuantityMcf": 0.030102,
                                                                    "mcf": 0.030102,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.030102,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 1472,
                                                                    "parentRowNumber": 1450,
                                                                    "articleId": "46110661",
                                                                    "description": "46110661-04-Scharnier",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.1628,
                                                                    "childrenWeight": 0.1628,
                                                                    "totalQuantityWeight": 0.1628,
                                                                    "singleQuantityMcf": 0.8433039999999999,
                                                                    "mcf": 0.8433039999999999,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.8433039999999999,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 1473,
                                                                            "parentRowNumber": 1472,
                                                                            "articleId": "46110659",
                                                                            "description": "46110659-00-Distanz",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.1159,
                                                                            "childrenWeight": 0.1159,
                                                                            "totalQuantityWeight": 0.1159,
                                                                            "singleQuantityMcf": 0.600362,
                                                                            "mcf": 0.600362,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.600362,
                                                                            "expandable": false,
                                                                            "children": [
                                                                                {
                                                                                    "rowNumber": 1474,
                                                                                    "parentRowNumber": 1473,
                                                                                    "articleId": "125524",
                                                                                    "description": "125524-00-Rohr",
                                                                                    "hierarchy": 9,
                                                                                    "quantity": 1,
                                                                                    "weight": 0.1159,
                                                                                    "childrenWeight": 0,
                                                                                    "totalQuantityWeight": 0.1159,
                                                                                    "singleQuantityMcf": 0.600362,
                                                                                    "mcf": 0.600362,
                                                                                    "singleQuantityLcf": 0,
                                                                                    "lcf": 0,
                                                                                    "totalQuantityMcf": 0.600362,
                                                                                    "expandable": false,
                                                                                    "children": []
                                                                                }
                                                                            ]
                                                                        },
                                                                        {
                                                                            "rowNumber": 1475,
                                                                            "parentRowNumber": 1472,
                                                                            "articleId": "46110660",
                                                                            "description": "46110660-00-Lasche",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0469,
                                                                            "childrenWeight": 0.0469,
                                                                            "totalQuantityWeight": 0.0469,
                                                                            "singleQuantityMcf": 0.242942,
                                                                            "mcf": 0.242942,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.242942,
                                                                            "expandable": false,
                                                                            "children": [
                                                                                {
                                                                                    "rowNumber": 1476,
                                                                                    "parentRowNumber": 1475,
                                                                                    "articleId": "142612",
                                                                                    "description": "142612-00-Edelstahlblech",
                                                                                    "hierarchy": 9,
                                                                                    "quantity": 1,
                                                                                    "weight": 0.0469,
                                                                                    "childrenWeight": 0,
                                                                                    "totalQuantityWeight": 0.0469,
                                                                                    "singleQuantityMcf": 0.242942,
                                                                                    "mcf": 0.242942,
                                                                                    "singleQuantityLcf": 0,
                                                                                    "lcf": 0,
                                                                                    "totalQuantityMcf": 0.242942,
                                                                                    "expandable": false,
                                                                                    "children": []
                                                                                }
                                                                            ]
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 1477,
                                                                    "parentRowNumber": 1450,
                                                                    "articleId": "46125780",
                                                                    "description": "46125780-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0079,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0079,
                                                                    "singleQuantityMcf": 0.040922,
                                                                    "mcf": 0.040922,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.040922,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 1478,
                                                                    "parentRowNumber": 1450,
                                                                    "articleId": "46316696",
                                                                    "description": "46316696-04-Distanz",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0848,
                                                                    "childrenWeight": 0.0848,
                                                                    "totalQuantityWeight": 0.0848,
                                                                    "singleQuantityMcf": 0.439264,
                                                                    "mcf": 0.439264,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.439264,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 1479,
                                                                            "parentRowNumber": 1478,
                                                                            "articleId": "1260038",
                                                                            "description": "1260038-00-Rundstahl",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0848,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0848,
                                                                            "singleQuantityMcf": 0.439264,
                                                                            "mcf": 0.439264,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.439264,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 1480,
                                                                    "parentRowNumber": 1450,
                                                                    "articleId": "46321808",
                                                                    "description": "46321808-01-Distanz",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.9219,
                                                                    "childrenWeight": 0.9219,
                                                                    "totalQuantityWeight": 0.9219,
                                                                    "singleQuantityMcf": 4.775442,
                                                                    "mcf": 4.775442,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 4.775442,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 1481,
                                                                            "parentRowNumber": 1480,
                                                                            "articleId": "125524",
                                                                            "description": "125524-00-Rohr",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.9219,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.9219,
                                                                            "singleQuantityMcf": 4.775442,
                                                                            "mcf": 4.775442,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 4.775442,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            "rowNumber": 1482,
                                                            "parentRowNumber": 871,
                                                            "articleId": "46320437",
                                                            "description": "46320437-05-Schutzpfosten unten",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 1.2924000000000004,
                                                            "childrenWeight": 1.2924000000000004,
                                                            "totalQuantityWeight": 1.2924000000000004,
                                                            "singleQuantityMcf": 6.463378600000001,
                                                            "mcf": 6.463378600000001,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 6.463378600000001,
                                                            "expandable": false,
                                                            "children": [
                                                                {
                                                                    "rowNumber": 1483,
                                                                    "parentRowNumber": 1482,
                                                                    "articleId": "208205",
                                                                    "description": "208205-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0004,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0004,
                                                                    "singleQuantityMcf": 0.002072,
                                                                    "mcf": 0.002072,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.002072,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 1484,
                                                                    "parentRowNumber": 1482,
                                                                    "articleId": "216232",
                                                                    "description": "216232-00-Sicherungsring",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0023,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0023,
                                                                    "singleQuantityMcf": 0.0039376,
                                                                    "mcf": 0.0039376,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.0039376,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 1485,
                                                                    "parentRowNumber": 1482,
                                                                    "articleId": "457007",
                                                                    "description": "457007-03-Klemmstueck",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0465,
                                                                    "childrenWeight": 0.0465,
                                                                    "totalQuantityWeight": 0.0465,
                                                                    "singleQuantityMcf": 0.24087,
                                                                    "mcf": 0.24087,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.24087,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 1486,
                                                                            "parentRowNumber": 1485,
                                                                            "articleId": "1260038",
                                                                            "description": "1260038-00-Rundstahl",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0465,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0465,
                                                                            "singleQuantityMcf": 0.24087,
                                                                            "mcf": 0.24087,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.24087,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 1487,
                                                                    "parentRowNumber": 1482,
                                                                    "articleId": "457051",
                                                                    "description": "457051-03-Distanz",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0077,
                                                                    "childrenWeight": 0.0077,
                                                                    "totalQuantityWeight": 0.0077,
                                                                    "singleQuantityMcf": 0.013321,
                                                                    "mcf": 0.013321,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.013321,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 1488,
                                                                            "parentRowNumber": 1487,
                                                                            "articleId": "164710",
                                                                            "description": "164710-00-Hohlstab",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0077,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0077,
                                                                            "singleQuantityMcf": 0.013321,
                                                                            "mcf": 0.013321,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.013321,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 1489,
                                                                    "parentRowNumber": 1482,
                                                                    "articleId": "457190",
                                                                    "description": "457190-01-Distanzplatte",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0012,
                                                                    "childrenWeight": 0.0012,
                                                                    "totalQuantityWeight": 0.0012,
                                                                    "singleQuantityMcf": 0.004079999999999999,
                                                                    "mcf": 0.004079999999999999,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.004079999999999999,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 1490,
                                                                            "parentRowNumber": 1489,
                                                                            "articleId": "162431",
                                                                            "description": "162431-00-Platte",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0012,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0012,
                                                                            "singleQuantityMcf": 0.004079999999999999,
                                                                            "mcf": 0.004079999999999999,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.004079999999999999,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 1491,
                                                                    "parentRowNumber": 1482,
                                                                    "articleId": "457193",
                                                                    "description": "457193-01-Distanzplatte",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0021,
                                                                    "childrenWeight": 0.0021,
                                                                    "totalQuantityWeight": 0.0021,
                                                                    "singleQuantityMcf": 0.00714,
                                                                    "mcf": 0.00714,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.00714,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 1492,
                                                                            "parentRowNumber": 1491,
                                                                            "articleId": "162431",
                                                                            "description": "162431-00-Platte",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0021,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0021,
                                                                            "singleQuantityMcf": 0.00714,
                                                                            "mcf": 0.00714,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.00714,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 1493,
                                                                    "parentRowNumber": 1482,
                                                                    "articleId": "457194",
                                                                    "description": "457194-01-Distanzplatte",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0008,
                                                                    "childrenWeight": 0.0008,
                                                                    "totalQuantityWeight": 0.0008,
                                                                    "singleQuantityMcf": 0.00272,
                                                                    "mcf": 0.00272,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.00272,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 1494,
                                                                            "parentRowNumber": 1493,
                                                                            "articleId": "162431",
                                                                            "description": "162431-00-Platte",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0008,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0008,
                                                                            "singleQuantityMcf": 0.00272,
                                                                            "mcf": 0.00272,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.00272,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 1495,
                                                                    "parentRowNumber": 1482,
                                                                    "articleId": "457195",
                                                                    "description": "457195-01-Distanz",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 1496,
                                                                            "parentRowNumber": 1495,
                                                                            "articleId": "3859213",
                                                                            "description": "3859213-00-Schlauch",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 1497,
                                                                    "parentRowNumber": 1482,
                                                                    "articleId": "46024147",
                                                                    "description": "46024147-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0011,
                                                                    "childrenWeight": 0.0011,
                                                                    "totalQuantityWeight": 0.0011,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 1498,
                                                                            "parentRowNumber": 1497,
                                                                            "articleId": "163201",
                                                                            "description": "163201-00-Gummiplatte",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0011,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0011,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 1499,
                                                                    "parentRowNumber": 1482,
                                                                    "articleId": "46029594",
                                                                    "description": "46029594-01-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0007,
                                                                    "childrenWeight": 0.0007,
                                                                    "totalQuantityWeight": 0.0007,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 1500,
                                                                            "parentRowNumber": 1499,
                                                                            "articleId": "163201",
                                                                            "description": "163201-00-Gummiplatte",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0007,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0007,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 1501,
                                                                    "parentRowNumber": 1482,
                                                                    "articleId": "46101957",
                                                                    "description": "46101957-01-Buchse",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0174,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0174,
                                                                    "singleQuantityMcf": 0.030102,
                                                                    "mcf": 0.030102,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.030102,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 1502,
                                                                    "parentRowNumber": 1482,
                                                                    "articleId": "46101957",
                                                                    "description": "46101957-01-Buchse",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0174,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0174,
                                                                    "singleQuantityMcf": 0.030102,
                                                                    "mcf": 0.030102,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.030102,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 1503,
                                                                    "parentRowNumber": 1482,
                                                                    "articleId": "46101957",
                                                                    "description": "46101957-01-Buchse",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0174,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0174,
                                                                    "singleQuantityMcf": 0.030102,
                                                                    "mcf": 0.030102,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.030102,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 1504,
                                                                    "parentRowNumber": 1482,
                                                                    "articleId": "46110661",
                                                                    "description": "46110661-04-Scharnier",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.1628,
                                                                    "childrenWeight": 0.1628,
                                                                    "totalQuantityWeight": 0.1628,
                                                                    "singleQuantityMcf": 0.8433039999999999,
                                                                    "mcf": 0.8433039999999999,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.8433039999999999,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 1505,
                                                                            "parentRowNumber": 1504,
                                                                            "articleId": "46110659",
                                                                            "description": "46110659-00-Distanz",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.1159,
                                                                            "childrenWeight": 0.1159,
                                                                            "totalQuantityWeight": 0.1159,
                                                                            "singleQuantityMcf": 0.600362,
                                                                            "mcf": 0.600362,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.600362,
                                                                            "expandable": false,
                                                                            "children": [
                                                                                {
                                                                                    "rowNumber": 1506,
                                                                                    "parentRowNumber": 1505,
                                                                                    "articleId": "125524",
                                                                                    "description": "125524-00-Rohr",
                                                                                    "hierarchy": 9,
                                                                                    "quantity": 1,
                                                                                    "weight": 0.1159,
                                                                                    "childrenWeight": 0,
                                                                                    "totalQuantityWeight": 0.1159,
                                                                                    "singleQuantityMcf": 0.600362,
                                                                                    "mcf": 0.600362,
                                                                                    "singleQuantityLcf": 0,
                                                                                    "lcf": 0,
                                                                                    "totalQuantityMcf": 0.600362,
                                                                                    "expandable": false,
                                                                                    "children": []
                                                                                }
                                                                            ]
                                                                        },
                                                                        {
                                                                            "rowNumber": 1507,
                                                                            "parentRowNumber": 1504,
                                                                            "articleId": "46110660",
                                                                            "description": "46110660-00-Lasche",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0469,
                                                                            "childrenWeight": 0.0469,
                                                                            "totalQuantityWeight": 0.0469,
                                                                            "singleQuantityMcf": 0.242942,
                                                                            "mcf": 0.242942,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.242942,
                                                                            "expandable": false,
                                                                            "children": [
                                                                                {
                                                                                    "rowNumber": 1508,
                                                                                    "parentRowNumber": 1507,
                                                                                    "articleId": "142612",
                                                                                    "description": "142612-00-Edelstahlblech",
                                                                                    "hierarchy": 9,
                                                                                    "quantity": 1,
                                                                                    "weight": 0.0469,
                                                                                    "childrenWeight": 0,
                                                                                    "totalQuantityWeight": 0.0469,
                                                                                    "singleQuantityMcf": 0.242942,
                                                                                    "mcf": 0.242942,
                                                                                    "singleQuantityLcf": 0,
                                                                                    "lcf": 0,
                                                                                    "totalQuantityMcf": 0.242942,
                                                                                    "expandable": false,
                                                                                    "children": []
                                                                                }
                                                                            ]
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 1509,
                                                                    "parentRowNumber": 1482,
                                                                    "articleId": "46125780",
                                                                    "description": "46125780-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0079,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0079,
                                                                    "singleQuantityMcf": 0.040922,
                                                                    "mcf": 0.040922,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.040922,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 1510,
                                                                    "parentRowNumber": 1482,
                                                                    "articleId": "46316696",
                                                                    "description": "46316696-04-Distanz",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0848,
                                                                    "childrenWeight": 0.0848,
                                                                    "totalQuantityWeight": 0.0848,
                                                                    "singleQuantityMcf": 0.439264,
                                                                    "mcf": 0.439264,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.439264,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 1511,
                                                                            "parentRowNumber": 1510,
                                                                            "articleId": "1260038",
                                                                            "description": "1260038-00-Rundstahl",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0848,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0848,
                                                                            "singleQuantityMcf": 0.439264,
                                                                            "mcf": 0.439264,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.439264,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 1512,
                                                                    "parentRowNumber": 1482,
                                                                    "articleId": "46321808",
                                                                    "description": "46321808-01-Distanz",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.9219,
                                                                    "childrenWeight": 0.9219,
                                                                    "totalQuantityWeight": 0.9219,
                                                                    "singleQuantityMcf": 4.775442,
                                                                    "mcf": 4.775442,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 4.775442,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 1513,
                                                                            "parentRowNumber": 1512,
                                                                            "articleId": "125524",
                                                                            "description": "125524-00-Rohr",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.9219,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.9219,
                                                                            "singleQuantityMcf": 4.775442,
                                                                            "mcf": 4.775442,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 4.775442,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            "rowNumber": 1514,
                                                            "parentRowNumber": 871,
                                                            "articleId": "46370641",
                                                            "description": "46370641-01-Stopfen",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0012,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0012,
                                                            "singleQuantityMcf": 0,
                                                            "mcf": 0,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 1515,
                                                            "parentRowNumber": 871,
                                                            "articleId": "46370641",
                                                            "description": "46370641-01-Stopfen",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0012,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0012,
                                                            "singleQuantityMcf": 0,
                                                            "mcf": 0,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 1516,
                                                            "parentRowNumber": 871,
                                                            "articleId": "46370641",
                                                            "description": "46370641-01-Stopfen",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0012,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0012,
                                                            "singleQuantityMcf": 0,
                                                            "mcf": 0,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 1517,
                                                            "parentRowNumber": 871,
                                                            "articleId": "46668519",
                                                            "description": "46668519-00-Halter",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.2604,
                                                            "childrenWeight": 0.2604,
                                                            "totalQuantityWeight": 0.2604,
                                                            "singleQuantityMcf": 1.348872,
                                                            "mcf": 1.348872,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 1.348872,
                                                            "expandable": false,
                                                            "children": [
                                                                {
                                                                    "rowNumber": 1518,
                                                                    "parentRowNumber": 1517,
                                                                    "articleId": "1110231",
                                                                    "description": "1110231-00-Flachstahl",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.2604,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.2604,
                                                                    "singleQuantityMcf": 1.348872,
                                                                    "mcf": 1.348872,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 1.348872,
                                                                    "expandable": false,
                                                                    "children": []
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            "rowNumber": 1519,
                                                            "parentRowNumber": 871,
                                                            "articleId": "46687439",
                                                            "description": "46687439-00-Distanz",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.6118,
                                                            "childrenWeight": 0.6118,
                                                            "totalQuantityWeight": 0.6118,
                                                            "singleQuantityMcf": 3.169124,
                                                            "mcf": 3.169124,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 3.169124,
                                                            "expandable": false,
                                                            "children": [
                                                                {
                                                                    "rowNumber": 1520,
                                                                    "parentRowNumber": 1519,
                                                                    "articleId": "1261242",
                                                                    "description": "1261242-00-Rohr",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.6118,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.6118,
                                                                    "singleQuantityMcf": 3.169124,
                                                                    "mcf": 3.169124,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 3.169124,
                                                                    "expandable": false,
                                                                    "children": []
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            "rowNumber": 1521,
                                                            "parentRowNumber": 871,
                                                            "articleId": "46687439",
                                                            "description": "46687439-00-Distanz",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.6118,
                                                            "childrenWeight": 0.6118,
                                                            "totalQuantityWeight": 0.6118,
                                                            "singleQuantityMcf": 3.169124,
                                                            "mcf": 3.169124,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 3.169124,
                                                            "expandable": false,
                                                            "children": [
                                                                {
                                                                    "rowNumber": 1522,
                                                                    "parentRowNumber": 1521,
                                                                    "articleId": "1261242",
                                                                    "description": "1261242-00-Rohr",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.6118,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.6118,
                                                                    "singleQuantityMcf": 3.169124,
                                                                    "mcf": 3.169124,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 3.169124,
                                                                    "expandable": false,
                                                                    "children": []
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            "rowNumber": 1523,
                                                            "parentRowNumber": 871,
                                                            "articleId": "46687439",
                                                            "description": "46687439-00-Distanz",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.6118,
                                                            "childrenWeight": 0.6118,
                                                            "totalQuantityWeight": 0.6118,
                                                            "singleQuantityMcf": 3.169124,
                                                            "mcf": 3.169124,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 3.169124,
                                                            "expandable": false,
                                                            "children": [
                                                                {
                                                                    "rowNumber": 1524,
                                                                    "parentRowNumber": 1523,
                                                                    "articleId": "1261242",
                                                                    "description": "1261242-00-Rohr",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.6118,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.6118,
                                                                    "singleQuantityMcf": 3.169124,
                                                                    "mcf": 3.169124,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 3.169124,
                                                                    "expandable": false,
                                                                    "children": []
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            "rowNumber": 1525,
                                                            "parentRowNumber": 871,
                                                            "articleId": "46687439",
                                                            "description": "46687439-00-Distanz",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.6118,
                                                            "childrenWeight": 0.6118,
                                                            "totalQuantityWeight": 0.6118,
                                                            "singleQuantityMcf": 3.169124,
                                                            "mcf": 3.169124,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 3.169124,
                                                            "expandable": false,
                                                            "children": [
                                                                {
                                                                    "rowNumber": 1526,
                                                                    "parentRowNumber": 1525,
                                                                    "articleId": "1261242",
                                                                    "description": "1261242-00-Rohr",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.6118,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.6118,
                                                                    "singleQuantityMcf": 3.169124,
                                                                    "mcf": 3.169124,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 3.169124,
                                                                    "expandable": false,
                                                                    "children": []
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            "rowNumber": 1527,
                                                            "parentRowNumber": 871,
                                                            "articleId": "46687439",
                                                            "description": "46687439-00-Distanz",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.6118,
                                                            "childrenWeight": 0.6118,
                                                            "totalQuantityWeight": 0.6118,
                                                            "singleQuantityMcf": 3.169124,
                                                            "mcf": 3.169124,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 3.169124,
                                                            "expandable": false,
                                                            "children": [
                                                                {
                                                                    "rowNumber": 1528,
                                                                    "parentRowNumber": 1527,
                                                                    "articleId": "1261242",
                                                                    "description": "1261242-00-Rohr",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.6118,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.6118,
                                                                    "singleQuantityMcf": 3.169124,
                                                                    "mcf": 3.169124,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 3.169124,
                                                                    "expandable": false,
                                                                    "children": []
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            "rowNumber": 1529,
                                                            "parentRowNumber": 871,
                                                            "articleId": "46689756",
                                                            "description": "46689756-00-Welle",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 2.9161,
                                                            "childrenWeight": 2.9161,
                                                            "totalQuantityWeight": 2.9161,
                                                            "singleQuantityMcf": 15.105398,
                                                            "mcf": 15.105398,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 15.105398,
                                                            "expandable": false,
                                                            "children": [
                                                                {
                                                                    "rowNumber": 1530,
                                                                    "parentRowNumber": 1529,
                                                                    "articleId": "1260020",
                                                                    "description": "1260020-00-Rundstahl",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 2.9161,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 2.9161,
                                                                    "singleQuantityMcf": 15.105398,
                                                                    "mcf": 15.105398,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 15.105398,
                                                                    "expandable": false,
                                                                    "children": []
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            "rowNumber": 1531,
                                                            "parentRowNumber": 871,
                                                            "articleId": "46689756",
                                                            "description": "46689756-00-Welle",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 2.9161,
                                                            "childrenWeight": 2.9161,
                                                            "totalQuantityWeight": 2.9161,
                                                            "singleQuantityMcf": 15.105398,
                                                            "mcf": 15.105398,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 15.105398,
                                                            "expandable": false,
                                                            "children": [
                                                                {
                                                                    "rowNumber": 1532,
                                                                    "parentRowNumber": 1531,
                                                                    "articleId": "1260020",
                                                                    "description": "1260020-00-Rundstahl",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 2.9161,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 2.9161,
                                                                    "singleQuantityMcf": 15.105398,
                                                                    "mcf": 15.105398,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 15.105398,
                                                                    "expandable": false,
                                                                    "children": []
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            "rowNumber": 1533,
                                                            "parentRowNumber": 871,
                                                            "articleId": "46689756",
                                                            "description": "46689756-00-Welle",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 2.9161,
                                                            "childrenWeight": 2.9161,
                                                            "totalQuantityWeight": 2.9161,
                                                            "singleQuantityMcf": 15.105398,
                                                            "mcf": 15.105398,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 15.105398,
                                                            "expandable": false,
                                                            "children": [
                                                                {
                                                                    "rowNumber": 1534,
                                                                    "parentRowNumber": 1533,
                                                                    "articleId": "1260020",
                                                                    "description": "1260020-00-Rundstahl",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 2.9161,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 2.9161,
                                                                    "singleQuantityMcf": 15.105398,
                                                                    "mcf": 15.105398,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 15.105398,
                                                                    "expandable": false,
                                                                    "children": []
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            "rowNumber": 1535,
                                                            "parentRowNumber": 871,
                                                            "articleId": "46689756",
                                                            "description": "46689756-00-Welle",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 2.9161,
                                                            "childrenWeight": 2.9161,
                                                            "totalQuantityWeight": 2.9161,
                                                            "singleQuantityMcf": 15.105398,
                                                            "mcf": 15.105398,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 15.105398,
                                                            "expandable": false,
                                                            "children": [
                                                                {
                                                                    "rowNumber": 1536,
                                                                    "parentRowNumber": 1535,
                                                                    "articleId": "1260020",
                                                                    "description": "1260020-00-Rundstahl",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 2.9161,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 2.9161,
                                                                    "singleQuantityMcf": 15.105398,
                                                                    "mcf": 15.105398,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 15.105398,
                                                                    "expandable": false,
                                                                    "children": []
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            "rowNumber": 1537,
                                                            "parentRowNumber": 871,
                                                            "articleId": "46689756",
                                                            "description": "46689756-00-Welle",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 2.9161,
                                                            "childrenWeight": 2.9161,
                                                            "totalQuantityWeight": 2.9161,
                                                            "singleQuantityMcf": 15.105398,
                                                            "mcf": 15.105398,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 15.105398,
                                                            "expandable": false,
                                                            "children": [
                                                                {
                                                                    "rowNumber": 1538,
                                                                    "parentRowNumber": 1537,
                                                                    "articleId": "1260020",
                                                                    "description": "1260020-00-Rundstahl",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 2.9161,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 2.9161,
                                                                    "singleQuantityMcf": 15.105398,
                                                                    "mcf": 15.105398,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 15.105398,
                                                                    "expandable": false,
                                                                    "children": []
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            "rowNumber": 1539,
                                                            "parentRowNumber": 871,
                                                            "articleId": "46689756",
                                                            "description": "46689756-00-Welle",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 2.9161,
                                                            "childrenWeight": 2.9161,
                                                            "totalQuantityWeight": 2.9161,
                                                            "singleQuantityMcf": 15.105398,
                                                            "mcf": 15.105398,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 15.105398,
                                                            "expandable": false,
                                                            "children": [
                                                                {
                                                                    "rowNumber": 1540,
                                                                    "parentRowNumber": 1539,
                                                                    "articleId": "1260020",
                                                                    "description": "1260020-00-Rundstahl",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 2.9161,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 2.9161,
                                                                    "singleQuantityMcf": 15.105398,
                                                                    "mcf": 15.105398,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 15.105398,
                                                                    "expandable": false,
                                                                    "children": []
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            "rowNumber": 1541,
                                                            "parentRowNumber": 871,
                                                            "articleId": "47080174",
                                                            "description": "47080174-00-Schutzscheibe",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 13.8367,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 13.8367,
                                                            "singleQuantityMcf": 0,
                                                            "mcf": 0,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 1542,
                                                            "parentRowNumber": 871,
                                                            "articleId": "47081009",
                                                            "description": "47081009-01-Schutzscheibe",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 33.997,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 33.997,
                                                            "singleQuantityMcf": 0,
                                                            "mcf": 0,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 1543,
                                                            "parentRowNumber": 871,
                                                            "articleId": "47081014",
                                                            "description": "47081014-00-Schutzscheibe",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 18.2855,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 18.2855,
                                                            "singleQuantityMcf": 0,
                                                            "mcf": 0,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 1544,
                                                            "parentRowNumber": 871,
                                                            "articleId": "47081021",
                                                            "description": "47081021-00-Schutzscheibe",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 28.2492,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 28.2492,
                                                            "singleQuantityMcf": 0,
                                                            "mcf": 0,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 1545,
                                                            "parentRowNumber": 871,
                                                            "articleId": "47092219",
                                                            "description": "47092219-00-Schutzscheibe",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 14.2118,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 14.2118,
                                                            "singleQuantityMcf": 0,
                                                            "mcf": 0,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 1546,
                                                            "parentRowNumber": 871,
                                                            "articleId": "47092372",
                                                            "description": "47092372-00-Schutzscheibe",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 14.089,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 14.089,
                                                            "singleQuantityMcf": 0,
                                                            "mcf": 0,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 1547,
                                                            "parentRowNumber": 871,
                                                            "articleId": "47092552",
                                                            "description": "47092552-00-Schutzscheibe",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 7.7118,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 7.7118,
                                                            "singleQuantityMcf": 0,
                                                            "mcf": 0,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 1548,
                                                            "parentRowNumber": 871,
                                                            "articleId": "47092780",
                                                            "description": "47092780-00-Distanz",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.9332,
                                                            "childrenWeight": 0.9332,
                                                            "totalQuantityWeight": 0.9332,
                                                            "singleQuantityMcf": 4.833976,
                                                            "mcf": 4.833976,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 4.833976,
                                                            "expandable": false,
                                                            "children": [
                                                                {
                                                                    "rowNumber": 1549,
                                                                    "parentRowNumber": 1548,
                                                                    "articleId": "1260015",
                                                                    "description": "1260015-00-Rundstahl",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.9332,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.9332,
                                                                    "singleQuantityMcf": 4.833976,
                                                                    "mcf": 4.833976,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 4.833976,
                                                                    "expandable": false,
                                                                    "children": []
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            "rowNumber": 1550,
                                                            "parentRowNumber": 871,
                                                            "articleId": "47093618",
                                                            "description": "47093618-01-Schutzpfosten",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 1.5822000000000005,
                                                            "childrenWeight": 1.5822000000000005,
                                                            "totalQuantityWeight": 1.5822000000000005,
                                                            "singleQuantityMcf": 7.696528200000003,
                                                            "mcf": 7.696528200000003,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 7.696528200000003,
                                                            "expandable": false,
                                                            "children": [
                                                                {
                                                                    "rowNumber": 1551,
                                                                    "parentRowNumber": 1550,
                                                                    "articleId": "20270690",
                                                                    "description": "20270690-00-Zylinderschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0234,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0234,
                                                                    "singleQuantityMcf": 0.121212,
                                                                    "mcf": 0.121212,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.121212,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 1552,
                                                                    "parentRowNumber": 1550,
                                                                    "articleId": "208205",
                                                                    "description": "208205-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0004,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0004,
                                                                    "singleQuantityMcf": 0.002072,
                                                                    "mcf": 0.002072,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.002072,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 1553,
                                                                    "parentRowNumber": 1550,
                                                                    "articleId": "208205",
                                                                    "description": "208205-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0004,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0004,
                                                                    "singleQuantityMcf": 0.002072,
                                                                    "mcf": 0.002072,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.002072,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 1554,
                                                                    "parentRowNumber": 1550,
                                                                    "articleId": "208205",
                                                                    "description": "208205-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0004,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0004,
                                                                    "singleQuantityMcf": 0.002072,
                                                                    "mcf": 0.002072,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.002072,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 1555,
                                                                    "parentRowNumber": 1550,
                                                                    "articleId": "208205",
                                                                    "description": "208205-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0004,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0004,
                                                                    "singleQuantityMcf": 0.002072,
                                                                    "mcf": 0.002072,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.002072,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 1556,
                                                                    "parentRowNumber": 1550,
                                                                    "articleId": "216232",
                                                                    "description": "216232-00-Sicherungsring",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0023,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0023,
                                                                    "singleQuantityMcf": 0.0039376,
                                                                    "mcf": 0.0039376,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.0039376,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 1557,
                                                                    "parentRowNumber": 1550,
                                                                    "articleId": "216232",
                                                                    "description": "216232-00-Sicherungsring",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0023,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0023,
                                                                    "singleQuantityMcf": 0.0039376,
                                                                    "mcf": 0.0039376,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.0039376,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 1558,
                                                                    "parentRowNumber": 1550,
                                                                    "articleId": "457007",
                                                                    "description": "457007-03-Klemmstueck",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0465,
                                                                    "childrenWeight": 0.0465,
                                                                    "totalQuantityWeight": 0.0465,
                                                                    "singleQuantityMcf": 0.24087,
                                                                    "mcf": 0.24087,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.24087,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 1559,
                                                                            "parentRowNumber": 1558,
                                                                            "articleId": "1260038",
                                                                            "description": "1260038-00-Rundstahl",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0465,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0465,
                                                                            "singleQuantityMcf": 0.24087,
                                                                            "mcf": 0.24087,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.24087,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 1560,
                                                                    "parentRowNumber": 1550,
                                                                    "articleId": "457007",
                                                                    "description": "457007-03-Klemmstueck",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0465,
                                                                    "childrenWeight": 0.0465,
                                                                    "totalQuantityWeight": 0.0465,
                                                                    "singleQuantityMcf": 0.24087,
                                                                    "mcf": 0.24087,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.24087,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 1561,
                                                                            "parentRowNumber": 1560,
                                                                            "articleId": "1260038",
                                                                            "description": "1260038-00-Rundstahl",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0465,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0465,
                                                                            "singleQuantityMcf": 0.24087,
                                                                            "mcf": 0.24087,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.24087,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 1562,
                                                                    "parentRowNumber": 1550,
                                                                    "articleId": "457007",
                                                                    "description": "457007-03-Klemmstueck",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0465,
                                                                    "childrenWeight": 0.0465,
                                                                    "totalQuantityWeight": 0.0465,
                                                                    "singleQuantityMcf": 0.24087,
                                                                    "mcf": 0.24087,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.24087,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 1563,
                                                                            "parentRowNumber": 1562,
                                                                            "articleId": "1260038",
                                                                            "description": "1260038-00-Rundstahl",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0465,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0465,
                                                                            "singleQuantityMcf": 0.24087,
                                                                            "mcf": 0.24087,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.24087,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 1564,
                                                                    "parentRowNumber": 1550,
                                                                    "articleId": "457007",
                                                                    "description": "457007-03-Klemmstueck",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0465,
                                                                    "childrenWeight": 0.0465,
                                                                    "totalQuantityWeight": 0.0465,
                                                                    "singleQuantityMcf": 0.24087,
                                                                    "mcf": 0.24087,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.24087,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 1565,
                                                                            "parentRowNumber": 1564,
                                                                            "articleId": "1260038",
                                                                            "description": "1260038-00-Rundstahl",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0465,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0465,
                                                                            "singleQuantityMcf": 0.24087,
                                                                            "mcf": 0.24087,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.24087,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 1566,
                                                                    "parentRowNumber": 1550,
                                                                    "articleId": "457051",
                                                                    "description": "457051-03-Distanz",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0077,
                                                                    "childrenWeight": 0.0077,
                                                                    "totalQuantityWeight": 0.0077,
                                                                    "singleQuantityMcf": 0.013321,
                                                                    "mcf": 0.013321,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.013321,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 1567,
                                                                            "parentRowNumber": 1566,
                                                                            "articleId": "164710",
                                                                            "description": "164710-00-Hohlstab",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0077,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0077,
                                                                            "singleQuantityMcf": 0.013321,
                                                                            "mcf": 0.013321,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.013321,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 1568,
                                                                    "parentRowNumber": 1550,
                                                                    "articleId": "457051",
                                                                    "description": "457051-03-Distanz",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0077,
                                                                    "childrenWeight": 0.0077,
                                                                    "totalQuantityWeight": 0.0077,
                                                                    "singleQuantityMcf": 0.013321,
                                                                    "mcf": 0.013321,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.013321,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 1569,
                                                                            "parentRowNumber": 1568,
                                                                            "articleId": "164710",
                                                                            "description": "164710-00-Hohlstab",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0077,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0077,
                                                                            "singleQuantityMcf": 0.013321,
                                                                            "mcf": 0.013321,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.013321,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 1570,
                                                                    "parentRowNumber": 1550,
                                                                    "articleId": "457051",
                                                                    "description": "457051-03-Distanz",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0077,
                                                                    "childrenWeight": 0.0077,
                                                                    "totalQuantityWeight": 0.0077,
                                                                    "singleQuantityMcf": 0.013321,
                                                                    "mcf": 0.013321,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.013321,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 1571,
                                                                            "parentRowNumber": 1570,
                                                                            "articleId": "164710",
                                                                            "description": "164710-00-Hohlstab",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0077,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0077,
                                                                            "singleQuantityMcf": 0.013321,
                                                                            "mcf": 0.013321,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.013321,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 1572,
                                                                    "parentRowNumber": 1550,
                                                                    "articleId": "457193",
                                                                    "description": "457193-01-Distanzplatte",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0021,
                                                                    "childrenWeight": 0.0021,
                                                                    "totalQuantityWeight": 0.0021,
                                                                    "singleQuantityMcf": 0.00714,
                                                                    "mcf": 0.00714,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.00714,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 1573,
                                                                            "parentRowNumber": 1572,
                                                                            "articleId": "162431",
                                                                            "description": "162431-00-Platte",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0021,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0021,
                                                                            "singleQuantityMcf": 0.00714,
                                                                            "mcf": 0.00714,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.00714,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 1574,
                                                                    "parentRowNumber": 1550,
                                                                    "articleId": "457193",
                                                                    "description": "457193-01-Distanzplatte",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0021,
                                                                    "childrenWeight": 0.0021,
                                                                    "totalQuantityWeight": 0.0021,
                                                                    "singleQuantityMcf": 0.00714,
                                                                    "mcf": 0.00714,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.00714,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 1575,
                                                                            "parentRowNumber": 1574,
                                                                            "articleId": "162431",
                                                                            "description": "162431-00-Platte",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0021,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0021,
                                                                            "singleQuantityMcf": 0.00714,
                                                                            "mcf": 0.00714,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.00714,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 1576,
                                                                    "parentRowNumber": 1550,
                                                                    "articleId": "457193",
                                                                    "description": "457193-01-Distanzplatte",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0021,
                                                                    "childrenWeight": 0.0021,
                                                                    "totalQuantityWeight": 0.0021,
                                                                    "singleQuantityMcf": 0.00714,
                                                                    "mcf": 0.00714,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.00714,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 1577,
                                                                            "parentRowNumber": 1576,
                                                                            "articleId": "162431",
                                                                            "description": "162431-00-Platte",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0021,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0021,
                                                                            "singleQuantityMcf": 0.00714,
                                                                            "mcf": 0.00714,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.00714,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 1578,
                                                                    "parentRowNumber": 1550,
                                                                    "articleId": "457193",
                                                                    "description": "457193-01-Distanzplatte",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0021,
                                                                    "childrenWeight": 0.0021,
                                                                    "totalQuantityWeight": 0.0021,
                                                                    "singleQuantityMcf": 0.00714,
                                                                    "mcf": 0.00714,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.00714,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 1579,
                                                                            "parentRowNumber": 1578,
                                                                            "articleId": "162431",
                                                                            "description": "162431-00-Platte",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0021,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0021,
                                                                            "singleQuantityMcf": 0.00714,
                                                                            "mcf": 0.00714,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.00714,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 1580,
                                                                    "parentRowNumber": 1550,
                                                                    "articleId": "457194",
                                                                    "description": "457194-01-Distanzplatte",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0008,
                                                                    "childrenWeight": 0.0008,
                                                                    "totalQuantityWeight": 0.0008,
                                                                    "singleQuantityMcf": 0.00272,
                                                                    "mcf": 0.00272,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.00272,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 1581,
                                                                            "parentRowNumber": 1580,
                                                                            "articleId": "162431",
                                                                            "description": "162431-00-Platte",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0008,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0008,
                                                                            "singleQuantityMcf": 0.00272,
                                                                            "mcf": 0.00272,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.00272,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 1582,
                                                                    "parentRowNumber": 1550,
                                                                    "articleId": "457194",
                                                                    "description": "457194-01-Distanzplatte",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0008,
                                                                    "childrenWeight": 0.0008,
                                                                    "totalQuantityWeight": 0.0008,
                                                                    "singleQuantityMcf": 0.00272,
                                                                    "mcf": 0.00272,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.00272,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 1583,
                                                                            "parentRowNumber": 1582,
                                                                            "articleId": "162431",
                                                                            "description": "162431-00-Platte",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0008,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0008,
                                                                            "singleQuantityMcf": 0.00272,
                                                                            "mcf": 0.00272,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.00272,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 1584,
                                                                    "parentRowNumber": 1550,
                                                                    "articleId": "457194",
                                                                    "description": "457194-01-Distanzplatte",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0008,
                                                                    "childrenWeight": 0.0008,
                                                                    "totalQuantityWeight": 0.0008,
                                                                    "singleQuantityMcf": 0.00272,
                                                                    "mcf": 0.00272,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.00272,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 1585,
                                                                            "parentRowNumber": 1584,
                                                                            "articleId": "162431",
                                                                            "description": "162431-00-Platte",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0008,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0008,
                                                                            "singleQuantityMcf": 0.00272,
                                                                            "mcf": 0.00272,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.00272,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 1586,
                                                                    "parentRowNumber": 1550,
                                                                    "articleId": "457194",
                                                                    "description": "457194-01-Distanzplatte",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0008,
                                                                    "childrenWeight": 0.0008,
                                                                    "totalQuantityWeight": 0.0008,
                                                                    "singleQuantityMcf": 0.00272,
                                                                    "mcf": 0.00272,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.00272,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 1587,
                                                                            "parentRowNumber": 1586,
                                                                            "articleId": "162431",
                                                                            "description": "162431-00-Platte",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0008,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0008,
                                                                            "singleQuantityMcf": 0.00272,
                                                                            "mcf": 0.00272,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.00272,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 1588,
                                                                    "parentRowNumber": 1550,
                                                                    "articleId": "457195",
                                                                    "description": "457195-01-Distanz",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 1589,
                                                                            "parentRowNumber": 1588,
                                                                            "articleId": "3859213",
                                                                            "description": "3859213-00-Schlauch",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 1590,
                                                                    "parentRowNumber": 1550,
                                                                    "articleId": "457195",
                                                                    "description": "457195-01-Distanz",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 1591,
                                                                            "parentRowNumber": 1590,
                                                                            "articleId": "3859213",
                                                                            "description": "3859213-00-Schlauch",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 1592,
                                                                    "parentRowNumber": 1550,
                                                                    "articleId": "457195",
                                                                    "description": "457195-01-Distanz",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 1593,
                                                                            "parentRowNumber": 1592,
                                                                            "articleId": "3859213",
                                                                            "description": "3859213-00-Schlauch",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 1594,
                                                                    "parentRowNumber": 1550,
                                                                    "articleId": "457195",
                                                                    "description": "457195-01-Distanz",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 1595,
                                                                            "parentRowNumber": 1594,
                                                                            "articleId": "3859213",
                                                                            "description": "3859213-00-Schlauch",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 1596,
                                                                    "parentRowNumber": 1550,
                                                                    "articleId": "46024147",
                                                                    "description": "46024147-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0011,
                                                                    "childrenWeight": 0.0011,
                                                                    "totalQuantityWeight": 0.0011,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 1597,
                                                                            "parentRowNumber": 1596,
                                                                            "articleId": "163201",
                                                                            "description": "163201-00-Gummiplatte",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0011,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0011,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 1598,
                                                                    "parentRowNumber": 1550,
                                                                    "articleId": "46024147",
                                                                    "description": "46024147-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0011,
                                                                    "childrenWeight": 0.0011,
                                                                    "totalQuantityWeight": 0.0011,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 1599,
                                                                            "parentRowNumber": 1598,
                                                                            "articleId": "163201",
                                                                            "description": "163201-00-Gummiplatte",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0011,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0011,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 1600,
                                                                    "parentRowNumber": 1550,
                                                                    "articleId": "46024147",
                                                                    "description": "46024147-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0011,
                                                                    "childrenWeight": 0.0011,
                                                                    "totalQuantityWeight": 0.0011,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 1601,
                                                                            "parentRowNumber": 1600,
                                                                            "articleId": "163201",
                                                                            "description": "163201-00-Gummiplatte",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0011,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0011,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 1602,
                                                                    "parentRowNumber": 1550,
                                                                    "articleId": "46024147",
                                                                    "description": "46024147-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0011,
                                                                    "childrenWeight": 0.0011,
                                                                    "totalQuantityWeight": 0.0011,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 1603,
                                                                            "parentRowNumber": 1602,
                                                                            "articleId": "163201",
                                                                            "description": "163201-00-Gummiplatte",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0011,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0011,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 1604,
                                                                    "parentRowNumber": 1550,
                                                                    "articleId": "46101957",
                                                                    "description": "46101957-01-Buchse",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0174,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0174,
                                                                    "singleQuantityMcf": 0.030102,
                                                                    "mcf": 0.030102,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.030102,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 1605,
                                                                    "parentRowNumber": 1550,
                                                                    "articleId": "46101957",
                                                                    "description": "46101957-01-Buchse",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0174,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0174,
                                                                    "singleQuantityMcf": 0.030102,
                                                                    "mcf": 0.030102,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.030102,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 1606,
                                                                    "parentRowNumber": 1550,
                                                                    "articleId": "46101957",
                                                                    "description": "46101957-01-Buchse",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0174,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0174,
                                                                    "singleQuantityMcf": 0.030102,
                                                                    "mcf": 0.030102,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.030102,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 1607,
                                                                    "parentRowNumber": 1550,
                                                                    "articleId": "46101957",
                                                                    "description": "46101957-01-Buchse",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0174,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0174,
                                                                    "singleQuantityMcf": 0.030102,
                                                                    "mcf": 0.030102,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.030102,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 1608,
                                                                    "parentRowNumber": 1550,
                                                                    "articleId": "46101957",
                                                                    "description": "46101957-01-Buchse",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0174,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0174,
                                                                    "singleQuantityMcf": 0.030102,
                                                                    "mcf": 0.030102,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.030102,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 1609,
                                                                    "parentRowNumber": 1550,
                                                                    "articleId": "46101957",
                                                                    "description": "46101957-01-Buchse",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0174,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0174,
                                                                    "singleQuantityMcf": 0.030102,
                                                                    "mcf": 0.030102,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.030102,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 1610,
                                                                    "parentRowNumber": 1550,
                                                                    "articleId": "46102540",
                                                                    "description": "46102540-03-Scharnier",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.1022,
                                                                    "childrenWeight": 0.1022,
                                                                    "totalQuantityWeight": 0.1022,
                                                                    "singleQuantityMcf": 0.529396,
                                                                    "mcf": 0.529396,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.529396,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 1611,
                                                                            "parentRowNumber": 1610,
                                                                            "articleId": "46102518",
                                                                            "description": "46102518-00-Lasche",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0462,
                                                                            "childrenWeight": 0.0462,
                                                                            "totalQuantityWeight": 0.0462,
                                                                            "singleQuantityMcf": 0.239316,
                                                                            "mcf": 0.239316,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.239316,
                                                                            "expandable": false,
                                                                            "children": [
                                                                                {
                                                                                    "rowNumber": 1612,
                                                                                    "parentRowNumber": 1611,
                                                                                    "articleId": "142612",
                                                                                    "description": "142612-00-Edelstahlblech",
                                                                                    "hierarchy": 9,
                                                                                    "quantity": 1,
                                                                                    "weight": 0.0462,
                                                                                    "childrenWeight": 0,
                                                                                    "totalQuantityWeight": 0.0462,
                                                                                    "singleQuantityMcf": 0.239316,
                                                                                    "mcf": 0.239316,
                                                                                    "singleQuantityLcf": 0,
                                                                                    "lcf": 0,
                                                                                    "totalQuantityMcf": 0.239316,
                                                                                    "expandable": false,
                                                                                    "children": []
                                                                                }
                                                                            ]
                                                                        },
                                                                        {
                                                                            "rowNumber": 1613,
                                                                            "parentRowNumber": 1610,
                                                                            "articleId": "46102539",
                                                                            "description": "46102539-00-Distanz",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.056,
                                                                            "childrenWeight": 0.056,
                                                                            "totalQuantityWeight": 0.056,
                                                                            "singleQuantityMcf": 0.29008,
                                                                            "mcf": 0.29008,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.29008,
                                                                            "expandable": false,
                                                                            "children": [
                                                                                {
                                                                                    "rowNumber": 1614,
                                                                                    "parentRowNumber": 1613,
                                                                                    "articleId": "125524",
                                                                                    "description": "125524-00-Rohr",
                                                                                    "hierarchy": 9,
                                                                                    "quantity": 1,
                                                                                    "weight": 0.056,
                                                                                    "childrenWeight": 0,
                                                                                    "totalQuantityWeight": 0.056,
                                                                                    "singleQuantityMcf": 0.29008,
                                                                                    "mcf": 0.29008,
                                                                                    "singleQuantityLcf": 0,
                                                                                    "lcf": 0,
                                                                                    "totalQuantityMcf": 0.29008,
                                                                                    "expandable": false,
                                                                                    "children": []
                                                                                }
                                                                            ]
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 1615,
                                                                    "parentRowNumber": 1550,
                                                                    "articleId": "46102540",
                                                                    "description": "46102540-03-Scharnier",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.1022,
                                                                    "childrenWeight": 0.1022,
                                                                    "totalQuantityWeight": 0.1022,
                                                                    "singleQuantityMcf": 0.529396,
                                                                    "mcf": 0.529396,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.529396,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 1616,
                                                                            "parentRowNumber": 1615,
                                                                            "articleId": "46102518",
                                                                            "description": "46102518-00-Lasche",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0462,
                                                                            "childrenWeight": 0.0462,
                                                                            "totalQuantityWeight": 0.0462,
                                                                            "singleQuantityMcf": 0.239316,
                                                                            "mcf": 0.239316,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.239316,
                                                                            "expandable": false,
                                                                            "children": [
                                                                                {
                                                                                    "rowNumber": 1617,
                                                                                    "parentRowNumber": 1616,
                                                                                    "articleId": "142612",
                                                                                    "description": "142612-00-Edelstahlblech",
                                                                                    "hierarchy": 9,
                                                                                    "quantity": 1,
                                                                                    "weight": 0.0462,
                                                                                    "childrenWeight": 0,
                                                                                    "totalQuantityWeight": 0.0462,
                                                                                    "singleQuantityMcf": 0.239316,
                                                                                    "mcf": 0.239316,
                                                                                    "singleQuantityLcf": 0,
                                                                                    "lcf": 0,
                                                                                    "totalQuantityMcf": 0.239316,
                                                                                    "expandable": false,
                                                                                    "children": []
                                                                                }
                                                                            ]
                                                                        },
                                                                        {
                                                                            "rowNumber": 1618,
                                                                            "parentRowNumber": 1615,
                                                                            "articleId": "46102539",
                                                                            "description": "46102539-00-Distanz",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.056,
                                                                            "childrenWeight": 0.056,
                                                                            "totalQuantityWeight": 0.056,
                                                                            "singleQuantityMcf": 0.29008,
                                                                            "mcf": 0.29008,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.29008,
                                                                            "expandable": false,
                                                                            "children": [
                                                                                {
                                                                                    "rowNumber": 1619,
                                                                                    "parentRowNumber": 1618,
                                                                                    "articleId": "125524",
                                                                                    "description": "125524-00-Rohr",
                                                                                    "hierarchy": 9,
                                                                                    "quantity": 1,
                                                                                    "weight": 0.056,
                                                                                    "childrenWeight": 0,
                                                                                    "totalQuantityWeight": 0.056,
                                                                                    "singleQuantityMcf": 0.29008,
                                                                                    "mcf": 0.29008,
                                                                                    "singleQuantityLcf": 0,
                                                                                    "lcf": 0,
                                                                                    "totalQuantityMcf": 0.29008,
                                                                                    "expandable": false,
                                                                                    "children": []
                                                                                }
                                                                            ]
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 1620,
                                                                    "parentRowNumber": 1550,
                                                                    "articleId": "46102543",
                                                                    "description": "46102543-02-Scharnier",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.1022,
                                                                    "childrenWeight": 0.1022,
                                                                    "totalQuantityWeight": 0.1022,
                                                                    "singleQuantityMcf": 0.529396,
                                                                    "mcf": 0.529396,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.529396,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 1621,
                                                                            "parentRowNumber": 1620,
                                                                            "articleId": "46102533",
                                                                            "description": "46102533-00-Lasche",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0462,
                                                                            "childrenWeight": 0.0462,
                                                                            "totalQuantityWeight": 0.0462,
                                                                            "singleQuantityMcf": 0.239316,
                                                                            "mcf": 0.239316,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.239316,
                                                                            "expandable": false,
                                                                            "children": [
                                                                                {
                                                                                    "rowNumber": 1622,
                                                                                    "parentRowNumber": 1621,
                                                                                    "articleId": "142612",
                                                                                    "description": "142612-00-Edelstahlblech",
                                                                                    "hierarchy": 9,
                                                                                    "quantity": 1,
                                                                                    "weight": 0.0462,
                                                                                    "childrenWeight": 0,
                                                                                    "totalQuantityWeight": 0.0462,
                                                                                    "singleQuantityMcf": 0.239316,
                                                                                    "mcf": 0.239316,
                                                                                    "singleQuantityLcf": 0,
                                                                                    "lcf": 0,
                                                                                    "totalQuantityMcf": 0.239316,
                                                                                    "expandable": false,
                                                                                    "children": []
                                                                                }
                                                                            ]
                                                                        },
                                                                        {
                                                                            "rowNumber": 1623,
                                                                            "parentRowNumber": 1620,
                                                                            "articleId": "46102539",
                                                                            "description": "46102539-00-Distanz",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.056,
                                                                            "childrenWeight": 0.056,
                                                                            "totalQuantityWeight": 0.056,
                                                                            "singleQuantityMcf": 0.29008,
                                                                            "mcf": 0.29008,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.29008,
                                                                            "expandable": false,
                                                                            "children": [
                                                                                {
                                                                                    "rowNumber": 1624,
                                                                                    "parentRowNumber": 1623,
                                                                                    "articleId": "125524",
                                                                                    "description": "125524-00-Rohr",
                                                                                    "hierarchy": 9,
                                                                                    "quantity": 1,
                                                                                    "weight": 0.056,
                                                                                    "childrenWeight": 0,
                                                                                    "totalQuantityWeight": 0.056,
                                                                                    "singleQuantityMcf": 0.29008,
                                                                                    "mcf": 0.29008,
                                                                                    "singleQuantityLcf": 0,
                                                                                    "lcf": 0,
                                                                                    "totalQuantityMcf": 0.29008,
                                                                                    "expandable": false,
                                                                                    "children": []
                                                                                }
                                                                            ]
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 1625,
                                                                    "parentRowNumber": 1550,
                                                                    "articleId": "46102543",
                                                                    "description": "46102543-02-Scharnier",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.1022,
                                                                    "childrenWeight": 0.1022,
                                                                    "totalQuantityWeight": 0.1022,
                                                                    "singleQuantityMcf": 0.529396,
                                                                    "mcf": 0.529396,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.529396,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 1626,
                                                                            "parentRowNumber": 1625,
                                                                            "articleId": "46102533",
                                                                            "description": "46102533-00-Lasche",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0462,
                                                                            "childrenWeight": 0.0462,
                                                                            "totalQuantityWeight": 0.0462,
                                                                            "singleQuantityMcf": 0.239316,
                                                                            "mcf": 0.239316,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.239316,
                                                                            "expandable": false,
                                                                            "children": [
                                                                                {
                                                                                    "rowNumber": 1627,
                                                                                    "parentRowNumber": 1626,
                                                                                    "articleId": "142612",
                                                                                    "description": "142612-00-Edelstahlblech",
                                                                                    "hierarchy": 9,
                                                                                    "quantity": 1,
                                                                                    "weight": 0.0462,
                                                                                    "childrenWeight": 0,
                                                                                    "totalQuantityWeight": 0.0462,
                                                                                    "singleQuantityMcf": 0.239316,
                                                                                    "mcf": 0.239316,
                                                                                    "singleQuantityLcf": 0,
                                                                                    "lcf": 0,
                                                                                    "totalQuantityMcf": 0.239316,
                                                                                    "expandable": false,
                                                                                    "children": []
                                                                                }
                                                                            ]
                                                                        },
                                                                        {
                                                                            "rowNumber": 1628,
                                                                            "parentRowNumber": 1625,
                                                                            "articleId": "46102539",
                                                                            "description": "46102539-00-Distanz",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.056,
                                                                            "childrenWeight": 0.056,
                                                                            "totalQuantityWeight": 0.056,
                                                                            "singleQuantityMcf": 0.29008,
                                                                            "mcf": 0.29008,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.29008,
                                                                            "expandable": false,
                                                                            "children": [
                                                                                {
                                                                                    "rowNumber": 1629,
                                                                                    "parentRowNumber": 1628,
                                                                                    "articleId": "125524",
                                                                                    "description": "125524-00-Rohr",
                                                                                    "hierarchy": 9,
                                                                                    "quantity": 1,
                                                                                    "weight": 0.056,
                                                                                    "childrenWeight": 0,
                                                                                    "totalQuantityWeight": 0.056,
                                                                                    "singleQuantityMcf": 0.29008,
                                                                                    "mcf": 0.29008,
                                                                                    "singleQuantityLcf": 0,
                                                                                    "lcf": 0,
                                                                                    "totalQuantityMcf": 0.29008,
                                                                                    "expandable": false,
                                                                                    "children": []
                                                                                }
                                                                            ]
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 1630,
                                                                    "parentRowNumber": 1550,
                                                                    "articleId": "46102546",
                                                                    "description": "46102546-01-Distanz",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.7827,
                                                                    "childrenWeight": 0.7827,
                                                                    "totalQuantityWeight": 0.7827,
                                                                    "singleQuantityMcf": 4.054385999999999,
                                                                    "mcf": 4.054385999999999,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 4.054385999999999,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 1631,
                                                                            "parentRowNumber": 1630,
                                                                            "articleId": "1261242",
                                                                            "description": "1261242-00-Rohr",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.7827,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.7827,
                                                                            "singleQuantityMcf": 4.054385999999999,
                                                                            "mcf": 4.054385999999999,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 4.054385999999999,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 1632,
                                                                    "parentRowNumber": 1550,
                                                                    "articleId": "46125780",
                                                                    "description": "46125780-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0079,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0079,
                                                                    "singleQuantityMcf": 0.040922,
                                                                    "mcf": 0.040922,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.040922,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 1633,
                                                                    "parentRowNumber": 1550,
                                                                    "articleId": "46125780",
                                                                    "description": "46125780-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0079,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0079,
                                                                    "singleQuantityMcf": 0.040922,
                                                                    "mcf": 0.040922,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.040922,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 1634,
                                                                    "parentRowNumber": 1550,
                                                                    "articleId": "46125780",
                                                                    "description": "46125780-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0079,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0079,
                                                                    "singleQuantityMcf": 0.040922,
                                                                    "mcf": 0.040922,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.040922,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 1635,
                                                                    "parentRowNumber": 1550,
                                                                    "articleId": "46125780",
                                                                    "description": "46125780-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0079,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0079,
                                                                    "singleQuantityMcf": 0.040922,
                                                                    "mcf": 0.040922,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.040922,
                                                                    "expandable": false,
                                                                    "children": []
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            "rowNumber": 1636,
                                                            "parentRowNumber": 871,
                                                            "articleId": "47093768",
                                                            "description": "47093768-00-Leiste",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 7.933899999999999,
                                                            "childrenWeight": 7.933899999999999,
                                                            "totalQuantityWeight": 7.933899999999999,
                                                            "singleQuantityMcf": 41.097602,
                                                            "mcf": 41.097602,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 41.097602,
                                                            "expandable": false,
                                                            "children": [
                                                                {
                                                                    "rowNumber": 1637,
                                                                    "parentRowNumber": 1636,
                                                                    "articleId": "131411",
                                                                    "description": "131411-00-Winkelstahl",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 6.5435,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 6.5435,
                                                                    "singleQuantityMcf": 33.895329999999994,
                                                                    "mcf": 33.895329999999994,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 33.895329999999994,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 1638,
                                                                    "parentRowNumber": 1636,
                                                                    "articleId": "21320612",
                                                                    "description": "21320612-00-Gewindestift",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0027,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0027,
                                                                    "singleQuantityMcf": 0.013986,
                                                                    "mcf": 0.013986,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.013986,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 1639,
                                                                    "parentRowNumber": 1636,
                                                                    "articleId": "21320612",
                                                                    "description": "21320612-00-Gewindestift",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0027,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0027,
                                                                    "singleQuantityMcf": 0.013986,
                                                                    "mcf": 0.013986,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.013986,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 1640,
                                                                    "parentRowNumber": 1636,
                                                                    "articleId": "21320612",
                                                                    "description": "21320612-00-Gewindestift",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0027,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0027,
                                                                    "singleQuantityMcf": 0.013986,
                                                                    "mcf": 0.013986,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.013986,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 1641,
                                                                    "parentRowNumber": 1636,
                                                                    "articleId": "21320612",
                                                                    "description": "21320612-00-Gewindestift",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0027,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0027,
                                                                    "singleQuantityMcf": 0.013986,
                                                                    "mcf": 0.013986,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.013986,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 1642,
                                                                    "parentRowNumber": 1636,
                                                                    "articleId": "21320615",
                                                                    "description": "21320615-00-Gewindestift",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0034,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0034,
                                                                    "singleQuantityMcf": 0.017612,
                                                                    "mcf": 0.017612,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.017612,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 1643,
                                                                    "parentRowNumber": 1636,
                                                                    "articleId": "46089481",
                                                                    "description": "46089481-04-Klemmstueck",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.6881,
                                                                    "childrenWeight": 0.6881,
                                                                    "totalQuantityWeight": 0.6881,
                                                                    "singleQuantityMcf": 3.564358,
                                                                    "mcf": 3.564358,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 3.564358,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 1644,
                                                                            "parentRowNumber": 1643,
                                                                            "articleId": "1260038",
                                                                            "description": "1260038-00-Rundstahl",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.6881,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.6881,
                                                                            "singleQuantityMcf": 3.564358,
                                                                            "mcf": 3.564358,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 3.564358,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 1645,
                                                                    "parentRowNumber": 1636,
                                                                    "articleId": "46089481",
                                                                    "description": "46089481-04-Klemmstueck",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.6881,
                                                                    "childrenWeight": 0.6881,
                                                                    "totalQuantityWeight": 0.6881,
                                                                    "singleQuantityMcf": 3.564358,
                                                                    "mcf": 3.564358,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 3.564358,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 1646,
                                                                            "parentRowNumber": 1645,
                                                                            "articleId": "1260038",
                                                                            "description": "1260038-00-Rundstahl",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.6881,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.6881,
                                                                            "singleQuantityMcf": 3.564358,
                                                                            "mcf": 3.564358,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 3.564358,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            "rowNumber": 1647,
                                                            "parentRowNumber": 871,
                                                            "articleId": "47096033",
                                                            "description": "47096033-00-Schutz",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.4066,
                                                            "childrenWeight": 0.4066,
                                                            "totalQuantityWeight": 0.4066,
                                                            "singleQuantityMcf": 1.38244,
                                                            "mcf": 1.38244,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 1.38244,
                                                            "expandable": false,
                                                            "children": [
                                                                {
                                                                    "rowNumber": 1648,
                                                                    "parentRowNumber": 1647,
                                                                    "articleId": "165936",
                                                                    "description": "165936-00-Platte",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.4066,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.4066,
                                                                    "singleQuantityMcf": 1.38244,
                                                                    "mcf": 1.38244,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 1.38244,
                                                                    "expandable": false,
                                                                    "children": []
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            "rowNumber": 1649,
                                                            "parentRowNumber": 871,
                                                            "articleId": "47096052",
                                                            "description": "47096052-01-Deckel",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 7.9199,
                                                            "childrenWeight": 7.9199,
                                                            "totalQuantityWeight": 7.9199,
                                                            "singleQuantityMcf": 26.92766,
                                                            "mcf": 26.92766,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 26.92766,
                                                            "expandable": false,
                                                            "children": [
                                                                {
                                                                    "rowNumber": 1650,
                                                                    "parentRowNumber": 1649,
                                                                    "articleId": "165936",
                                                                    "description": "165936-00-Platte",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 7.9199,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 7.9199,
                                                                    "singleQuantityMcf": 26.92766,
                                                                    "mcf": 26.92766,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 26.92766,
                                                                    "expandable": false,
                                                                    "children": []
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            "rowNumber": 1651,
                                                            "parentRowNumber": 871,
                                                            "articleId": "47096053",
                                                            "description": "47096053-01-Schutz",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.1972,
                                                            "childrenWeight": 0.1972,
                                                            "totalQuantityWeight": 0.1972,
                                                            "singleQuantityMcf": 0.67048,
                                                            "mcf": 0.67048,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.67048,
                                                            "expandable": false,
                                                            "children": [
                                                                {
                                                                    "rowNumber": 1652,
                                                                    "parentRowNumber": 1651,
                                                                    "articleId": "165936",
                                                                    "description": "165936-00-Platte",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.1972,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.1972,
                                                                    "singleQuantityMcf": 0.67048,
                                                                    "mcf": 0.67048,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.67048,
                                                                    "expandable": false,
                                                                    "children": []
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            "rowNumber": 1653,
                                                            "parentRowNumber": 871,
                                                            "articleId": "47099635",
                                                            "description": "47099635-00-Leiste",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 14.3934,
                                                            "childrenWeight": 14.3934,
                                                            "totalQuantityWeight": 14.3934,
                                                            "singleQuantityMcf": 74.557812,
                                                            "mcf": 74.557812,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 74.557812,
                                                            "expandable": false,
                                                            "children": [
                                                                {
                                                                    "rowNumber": 1654,
                                                                    "parentRowNumber": 1653,
                                                                    "articleId": "131411",
                                                                    "description": "131411-00-Winkelstahl",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 11.0052,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 11.0052,
                                                                    "singleQuantityMcf": 57.006936,
                                                                    "mcf": 57.006936,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 57.006936,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 1655,
                                                                    "parentRowNumber": 1653,
                                                                    "articleId": "21320615",
                                                                    "description": "21320615-00-Gewindestift",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0034,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0034,
                                                                    "singleQuantityMcf": 0.017612,
                                                                    "mcf": 0.017612,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.017612,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 1656,
                                                                    "parentRowNumber": 1653,
                                                                    "articleId": "46089481",
                                                                    "description": "46089481-04-Klemmstueck",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.6881,
                                                                    "childrenWeight": 0.6881,
                                                                    "totalQuantityWeight": 0.6881,
                                                                    "singleQuantityMcf": 3.564358,
                                                                    "mcf": 3.564358,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 3.564358,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 1657,
                                                                            "parentRowNumber": 1656,
                                                                            "articleId": "1260038",
                                                                            "description": "1260038-00-Rundstahl",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.6881,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.6881,
                                                                            "singleQuantityMcf": 3.564358,
                                                                            "mcf": 3.564358,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 3.564358,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 1658,
                                                                    "parentRowNumber": 1653,
                                                                    "articleId": "46089481",
                                                                    "description": "46089481-04-Klemmstueck",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.6881,
                                                                    "childrenWeight": 0.6881,
                                                                    "totalQuantityWeight": 0.6881,
                                                                    "singleQuantityMcf": 3.564358,
                                                                    "mcf": 3.564358,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 3.564358,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 1659,
                                                                            "parentRowNumber": 1658,
                                                                            "articleId": "1260038",
                                                                            "description": "1260038-00-Rundstahl",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.6881,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.6881,
                                                                            "singleQuantityMcf": 3.564358,
                                                                            "mcf": 3.564358,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 3.564358,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 1660,
                                                                    "parentRowNumber": 1653,
                                                                    "articleId": "46089481",
                                                                    "description": "46089481-04-Klemmstueck",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.6881,
                                                                    "childrenWeight": 0.6881,
                                                                    "totalQuantityWeight": 0.6881,
                                                                    "singleQuantityMcf": 3.564358,
                                                                    "mcf": 3.564358,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 3.564358,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 1661,
                                                                            "parentRowNumber": 1660,
                                                                            "articleId": "1260038",
                                                                            "description": "1260038-00-Rundstahl",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.6881,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.6881,
                                                                            "singleQuantityMcf": 3.564358,
                                                                            "mcf": 3.564358,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 3.564358,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 1662,
                                                                    "parentRowNumber": 1653,
                                                                    "articleId": "46089481",
                                                                    "description": "46089481-04-Klemmstueck",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.6881,
                                                                    "childrenWeight": 0.6881,
                                                                    "totalQuantityWeight": 0.6881,
                                                                    "singleQuantityMcf": 3.564358,
                                                                    "mcf": 3.564358,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 3.564358,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 1663,
                                                                            "parentRowNumber": 1662,
                                                                            "articleId": "1260038",
                                                                            "description": "1260038-00-Rundstahl",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.6881,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.6881,
                                                                            "singleQuantityMcf": 3.564358,
                                                                            "mcf": 3.564358,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 3.564358,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 1664,
                                                                    "parentRowNumber": 1653,
                                                                    "articleId": "46321977",
                                                                    "description": "46321977-00-Lasche",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.1637,
                                                                    "childrenWeight": 0.1637,
                                                                    "totalQuantityWeight": 0.1637,
                                                                    "singleQuantityMcf": 0.847966,
                                                                    "mcf": 0.847966,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.847966,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 1665,
                                                                            "parentRowNumber": 1664,
                                                                            "articleId": "1110118",
                                                                            "description": "1110118-00-Flachstahl",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.1637,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.1637,
                                                                            "singleQuantityMcf": 0.847966,
                                                                            "mcf": 0.847966,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.847966,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 1666,
                                                                    "parentRowNumber": 1653,
                                                                    "articleId": "46348219",
                                                                    "description": "46348219-00-Lasche",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0701,
                                                                    "childrenWeight": 0.0701,
                                                                    "totalQuantityWeight": 0.0701,
                                                                    "singleQuantityMcf": 0.363118,
                                                                    "mcf": 0.363118,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.363118,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 1667,
                                                                            "parentRowNumber": 1666,
                                                                            "articleId": "1110145",
                                                                            "description": "1110145-00-Flachstahl",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0701,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0701,
                                                                            "singleQuantityMcf": 0.363118,
                                                                            "mcf": 0.363118,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.363118,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 1668,
                                                                    "parentRowNumber": 1653,
                                                                    "articleId": "46348219",
                                                                    "description": "46348219-00-Lasche",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0701,
                                                                    "childrenWeight": 0.0701,
                                                                    "totalQuantityWeight": 0.0701,
                                                                    "singleQuantityMcf": 0.363118,
                                                                    "mcf": 0.363118,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.363118,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 1669,
                                                                            "parentRowNumber": 1668,
                                                                            "articleId": "1110145",
                                                                            "description": "1110145-00-Flachstahl",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0701,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0701,
                                                                            "singleQuantityMcf": 0.363118,
                                                                            "mcf": 0.363118,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.363118,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 1670,
                                                                    "parentRowNumber": 1653,
                                                                    "articleId": "46348219",
                                                                    "description": "46348219-00-Lasche",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0701,
                                                                    "childrenWeight": 0.0701,
                                                                    "totalQuantityWeight": 0.0701,
                                                                    "singleQuantityMcf": 0.363118,
                                                                    "mcf": 0.363118,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.363118,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 1671,
                                                                            "parentRowNumber": 1670,
                                                                            "articleId": "1110145",
                                                                            "description": "1110145-00-Flachstahl",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0701,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0701,
                                                                            "singleQuantityMcf": 0.363118,
                                                                            "mcf": 0.363118,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.363118,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 1672,
                                                                    "parentRowNumber": 1653,
                                                                    "articleId": "46348219",
                                                                    "description": "46348219-00-Lasche",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0701,
                                                                    "childrenWeight": 0.0701,
                                                                    "totalQuantityWeight": 0.0701,
                                                                    "singleQuantityMcf": 0.363118,
                                                                    "mcf": 0.363118,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.363118,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 1673,
                                                                            "parentRowNumber": 1672,
                                                                            "articleId": "1110145",
                                                                            "description": "1110145-00-Flachstahl",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0701,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0701,
                                                                            "singleQuantityMcf": 0.363118,
                                                                            "mcf": 0.363118,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.363118,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 1674,
                                                                    "parentRowNumber": 1653,
                                                                    "articleId": "47099761",
                                                                    "description": "47099761-00-Lasche",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.1883,
                                                                    "childrenWeight": 0.1883,
                                                                    "totalQuantityWeight": 0.1883,
                                                                    "singleQuantityMcf": 0.975394,
                                                                    "mcf": 0.975394,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.975394,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 1675,
                                                                            "parentRowNumber": 1674,
                                                                            "articleId": "1110118",
                                                                            "description": "1110118-00-Flachstahl",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.1883,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.1883,
                                                                            "singleQuantityMcf": 0.975394,
                                                                            "mcf": 0.975394,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.975394,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            "rowNumber": 1676,
                                                            "parentRowNumber": 871,
                                                            "articleId": "47099895",
                                                            "description": "47099895-01-Schutzpfosten unten",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 1.4547000000000003,
                                                            "childrenWeight": 1.4547000000000003,
                                                            "totalQuantityWeight": 1.4547000000000003,
                                                            "singleQuantityMcf": 7.240502600000003,
                                                            "mcf": 7.240502600000003,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 7.240502600000003,
                                                            "expandable": false,
                                                            "children": [
                                                                {
                                                                    "rowNumber": 1677,
                                                                    "parentRowNumber": 1676,
                                                                    "articleId": "208205",
                                                                    "description": "208205-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0004,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0004,
                                                                    "singleQuantityMcf": 0.002072,
                                                                    "mcf": 0.002072,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.002072,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 1678,
                                                                    "parentRowNumber": 1676,
                                                                    "articleId": "208205",
                                                                    "description": "208205-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0004,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0004,
                                                                    "singleQuantityMcf": 0.002072,
                                                                    "mcf": 0.002072,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.002072,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 1679,
                                                                    "parentRowNumber": 1676,
                                                                    "articleId": "216232",
                                                                    "description": "216232-00-Sicherungsring",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0023,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0023,
                                                                    "singleQuantityMcf": 0.0039376,
                                                                    "mcf": 0.0039376,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.0039376,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 1680,
                                                                    "parentRowNumber": 1676,
                                                                    "articleId": "457007",
                                                                    "description": "457007-03-Klemmstueck",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0465,
                                                                    "childrenWeight": 0.0465,
                                                                    "totalQuantityWeight": 0.0465,
                                                                    "singleQuantityMcf": 0.24087,
                                                                    "mcf": 0.24087,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.24087,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 1681,
                                                                            "parentRowNumber": 1680,
                                                                            "articleId": "1260038",
                                                                            "description": "1260038-00-Rundstahl",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0465,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0465,
                                                                            "singleQuantityMcf": 0.24087,
                                                                            "mcf": 0.24087,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.24087,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 1682,
                                                                    "parentRowNumber": 1676,
                                                                    "articleId": "457007",
                                                                    "description": "457007-03-Klemmstueck",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0465,
                                                                    "childrenWeight": 0.0465,
                                                                    "totalQuantityWeight": 0.0465,
                                                                    "singleQuantityMcf": 0.24087,
                                                                    "mcf": 0.24087,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.24087,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 1683,
                                                                            "parentRowNumber": 1682,
                                                                            "articleId": "1260038",
                                                                            "description": "1260038-00-Rundstahl",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0465,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0465,
                                                                            "singleQuantityMcf": 0.24087,
                                                                            "mcf": 0.24087,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.24087,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 1684,
                                                                    "parentRowNumber": 1676,
                                                                    "articleId": "457007",
                                                                    "description": "457007-03-Klemmstueck",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0465,
                                                                    "childrenWeight": 0.0465,
                                                                    "totalQuantityWeight": 0.0465,
                                                                    "singleQuantityMcf": 0.24087,
                                                                    "mcf": 0.24087,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.24087,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 1685,
                                                                            "parentRowNumber": 1684,
                                                                            "articleId": "1260038",
                                                                            "description": "1260038-00-Rundstahl",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0465,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0465,
                                                                            "singleQuantityMcf": 0.24087,
                                                                            "mcf": 0.24087,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.24087,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 1686,
                                                                    "parentRowNumber": 1676,
                                                                    "articleId": "457051",
                                                                    "description": "457051-03-Distanz",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0077,
                                                                    "childrenWeight": 0.0077,
                                                                    "totalQuantityWeight": 0.0077,
                                                                    "singleQuantityMcf": 0.013321,
                                                                    "mcf": 0.013321,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.013321,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 1687,
                                                                            "parentRowNumber": 1686,
                                                                            "articleId": "164710",
                                                                            "description": "164710-00-Hohlstab",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0077,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0077,
                                                                            "singleQuantityMcf": 0.013321,
                                                                            "mcf": 0.013321,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.013321,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 1688,
                                                                    "parentRowNumber": 1676,
                                                                    "articleId": "457190",
                                                                    "description": "457190-01-Distanzplatte",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0012,
                                                                    "childrenWeight": 0.0012,
                                                                    "totalQuantityWeight": 0.0012,
                                                                    "singleQuantityMcf": 0.004079999999999999,
                                                                    "mcf": 0.004079999999999999,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.004079999999999999,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 1689,
                                                                            "parentRowNumber": 1688,
                                                                            "articleId": "162431",
                                                                            "description": "162431-00-Platte",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0012,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0012,
                                                                            "singleQuantityMcf": 0.004079999999999999,
                                                                            "mcf": 0.004079999999999999,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.004079999999999999,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 1690,
                                                                    "parentRowNumber": 1676,
                                                                    "articleId": "457190",
                                                                    "description": "457190-01-Distanzplatte",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0012,
                                                                    "childrenWeight": 0.0012,
                                                                    "totalQuantityWeight": 0.0012,
                                                                    "singleQuantityMcf": 0.004079999999999999,
                                                                    "mcf": 0.004079999999999999,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.004079999999999999,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 1691,
                                                                            "parentRowNumber": 1690,
                                                                            "articleId": "162431",
                                                                            "description": "162431-00-Platte",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0012,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0012,
                                                                            "singleQuantityMcf": 0.004079999999999999,
                                                                            "mcf": 0.004079999999999999,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.004079999999999999,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 1692,
                                                                    "parentRowNumber": 1676,
                                                                    "articleId": "457193",
                                                                    "description": "457193-01-Distanzplatte",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0021,
                                                                    "childrenWeight": 0.0021,
                                                                    "totalQuantityWeight": 0.0021,
                                                                    "singleQuantityMcf": 0.00714,
                                                                    "mcf": 0.00714,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.00714,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 1693,
                                                                            "parentRowNumber": 1692,
                                                                            "articleId": "162431",
                                                                            "description": "162431-00-Platte",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0021,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0021,
                                                                            "singleQuantityMcf": 0.00714,
                                                                            "mcf": 0.00714,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.00714,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 1694,
                                                                    "parentRowNumber": 1676,
                                                                    "articleId": "457194",
                                                                    "description": "457194-01-Distanzplatte",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0008,
                                                                    "childrenWeight": 0.0008,
                                                                    "totalQuantityWeight": 0.0008,
                                                                    "singleQuantityMcf": 0.00272,
                                                                    "mcf": 0.00272,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.00272,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 1695,
                                                                            "parentRowNumber": 1694,
                                                                            "articleId": "162431",
                                                                            "description": "162431-00-Platte",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0008,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0008,
                                                                            "singleQuantityMcf": 0.00272,
                                                                            "mcf": 0.00272,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.00272,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 1696,
                                                                    "parentRowNumber": 1676,
                                                                    "articleId": "457194",
                                                                    "description": "457194-01-Distanzplatte",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0008,
                                                                    "childrenWeight": 0.0008,
                                                                    "totalQuantityWeight": 0.0008,
                                                                    "singleQuantityMcf": 0.00272,
                                                                    "mcf": 0.00272,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.00272,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 1697,
                                                                            "parentRowNumber": 1696,
                                                                            "articleId": "162431",
                                                                            "description": "162431-00-Platte",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0008,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0008,
                                                                            "singleQuantityMcf": 0.00272,
                                                                            "mcf": 0.00272,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.00272,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 1698,
                                                                    "parentRowNumber": 1676,
                                                                    "articleId": "457195",
                                                                    "description": "457195-01-Distanz",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 1699,
                                                                            "parentRowNumber": 1698,
                                                                            "articleId": "3859213",
                                                                            "description": "3859213-00-Schlauch",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 1700,
                                                                    "parentRowNumber": 1676,
                                                                    "articleId": "457195",
                                                                    "description": "457195-01-Distanz",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 1701,
                                                                            "parentRowNumber": 1700,
                                                                            "articleId": "3859213",
                                                                            "description": "3859213-00-Schlauch",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 1702,
                                                                    "parentRowNumber": 1676,
                                                                    "articleId": "46024147",
                                                                    "description": "46024147-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0011,
                                                                    "childrenWeight": 0.0011,
                                                                    "totalQuantityWeight": 0.0011,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 1703,
                                                                            "parentRowNumber": 1702,
                                                                            "articleId": "163201",
                                                                            "description": "163201-00-Gummiplatte",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0011,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0011,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 1704,
                                                                    "parentRowNumber": 1676,
                                                                    "articleId": "46029594",
                                                                    "description": "46029594-01-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0007,
                                                                    "childrenWeight": 0.0007,
                                                                    "totalQuantityWeight": 0.0007,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 1705,
                                                                            "parentRowNumber": 1704,
                                                                            "articleId": "163201",
                                                                            "description": "163201-00-Gummiplatte",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0007,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0007,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 1706,
                                                                    "parentRowNumber": 1676,
                                                                    "articleId": "46101957",
                                                                    "description": "46101957-01-Buchse",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0174,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0174,
                                                                    "singleQuantityMcf": 0.030102,
                                                                    "mcf": 0.030102,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.030102,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 1707,
                                                                    "parentRowNumber": 1676,
                                                                    "articleId": "46101957",
                                                                    "description": "46101957-01-Buchse",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0174,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0174,
                                                                    "singleQuantityMcf": 0.030102,
                                                                    "mcf": 0.030102,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.030102,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 1708,
                                                                    "parentRowNumber": 1676,
                                                                    "articleId": "46101957",
                                                                    "description": "46101957-01-Buchse",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0174,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0174,
                                                                    "singleQuantityMcf": 0.030102,
                                                                    "mcf": 0.030102,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.030102,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 1709,
                                                                    "parentRowNumber": 1676,
                                                                    "articleId": "46101957",
                                                                    "description": "46101957-01-Buchse",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0174,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0174,
                                                                    "singleQuantityMcf": 0.030102,
                                                                    "mcf": 0.030102,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.030102,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 1710,
                                                                    "parentRowNumber": 1676,
                                                                    "articleId": "46102540",
                                                                    "description": "46102540-03-Scharnier",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.1022,
                                                                    "childrenWeight": 0.1022,
                                                                    "totalQuantityWeight": 0.1022,
                                                                    "singleQuantityMcf": 0.529396,
                                                                    "mcf": 0.529396,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.529396,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 1711,
                                                                            "parentRowNumber": 1710,
                                                                            "articleId": "46102518",
                                                                            "description": "46102518-00-Lasche",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0462,
                                                                            "childrenWeight": 0.0462,
                                                                            "totalQuantityWeight": 0.0462,
                                                                            "singleQuantityMcf": 0.239316,
                                                                            "mcf": 0.239316,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.239316,
                                                                            "expandable": false,
                                                                            "children": [
                                                                                {
                                                                                    "rowNumber": 1712,
                                                                                    "parentRowNumber": 1711,
                                                                                    "articleId": "142612",
                                                                                    "description": "142612-00-Edelstahlblech",
                                                                                    "hierarchy": 9,
                                                                                    "quantity": 1,
                                                                                    "weight": 0.0462,
                                                                                    "childrenWeight": 0,
                                                                                    "totalQuantityWeight": 0.0462,
                                                                                    "singleQuantityMcf": 0.239316,
                                                                                    "mcf": 0.239316,
                                                                                    "singleQuantityLcf": 0,
                                                                                    "lcf": 0,
                                                                                    "totalQuantityMcf": 0.239316,
                                                                                    "expandable": false,
                                                                                    "children": []
                                                                                }
                                                                            ]
                                                                        },
                                                                        {
                                                                            "rowNumber": 1713,
                                                                            "parentRowNumber": 1710,
                                                                            "articleId": "46102539",
                                                                            "description": "46102539-00-Distanz",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.056,
                                                                            "childrenWeight": 0.056,
                                                                            "totalQuantityWeight": 0.056,
                                                                            "singleQuantityMcf": 0.29008,
                                                                            "mcf": 0.29008,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.29008,
                                                                            "expandable": false,
                                                                            "children": [
                                                                                {
                                                                                    "rowNumber": 1714,
                                                                                    "parentRowNumber": 1713,
                                                                                    "articleId": "125524",
                                                                                    "description": "125524-00-Rohr",
                                                                                    "hierarchy": 9,
                                                                                    "quantity": 1,
                                                                                    "weight": 0.056,
                                                                                    "childrenWeight": 0,
                                                                                    "totalQuantityWeight": 0.056,
                                                                                    "singleQuantityMcf": 0.29008,
                                                                                    "mcf": 0.29008,
                                                                                    "singleQuantityLcf": 0,
                                                                                    "lcf": 0,
                                                                                    "totalQuantityMcf": 0.29008,
                                                                                    "expandable": false,
                                                                                    "children": []
                                                                                }
                                                                            ]
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 1715,
                                                                    "parentRowNumber": 1676,
                                                                    "articleId": "46102543",
                                                                    "description": "46102543-02-Scharnier",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.1022,
                                                                    "childrenWeight": 0.1022,
                                                                    "totalQuantityWeight": 0.1022,
                                                                    "singleQuantityMcf": 0.529396,
                                                                    "mcf": 0.529396,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.529396,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 1716,
                                                                            "parentRowNumber": 1715,
                                                                            "articleId": "46102533",
                                                                            "description": "46102533-00-Lasche",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0462,
                                                                            "childrenWeight": 0.0462,
                                                                            "totalQuantityWeight": 0.0462,
                                                                            "singleQuantityMcf": 0.239316,
                                                                            "mcf": 0.239316,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.239316,
                                                                            "expandable": false,
                                                                            "children": [
                                                                                {
                                                                                    "rowNumber": 1717,
                                                                                    "parentRowNumber": 1716,
                                                                                    "articleId": "142612",
                                                                                    "description": "142612-00-Edelstahlblech",
                                                                                    "hierarchy": 9,
                                                                                    "quantity": 1,
                                                                                    "weight": 0.0462,
                                                                                    "childrenWeight": 0,
                                                                                    "totalQuantityWeight": 0.0462,
                                                                                    "singleQuantityMcf": 0.239316,
                                                                                    "mcf": 0.239316,
                                                                                    "singleQuantityLcf": 0,
                                                                                    "lcf": 0,
                                                                                    "totalQuantityMcf": 0.239316,
                                                                                    "expandable": false,
                                                                                    "children": []
                                                                                }
                                                                            ]
                                                                        },
                                                                        {
                                                                            "rowNumber": 1718,
                                                                            "parentRowNumber": 1715,
                                                                            "articleId": "46102539",
                                                                            "description": "46102539-00-Distanz",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.056,
                                                                            "childrenWeight": 0.056,
                                                                            "totalQuantityWeight": 0.056,
                                                                            "singleQuantityMcf": 0.29008,
                                                                            "mcf": 0.29008,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.29008,
                                                                            "expandable": false,
                                                                            "children": [
                                                                                {
                                                                                    "rowNumber": 1719,
                                                                                    "parentRowNumber": 1718,
                                                                                    "articleId": "125524",
                                                                                    "description": "125524-00-Rohr",
                                                                                    "hierarchy": 9,
                                                                                    "quantity": 1,
                                                                                    "weight": 0.056,
                                                                                    "childrenWeight": 0,
                                                                                    "totalQuantityWeight": 0.056,
                                                                                    "singleQuantityMcf": 0.29008,
                                                                                    "mcf": 0.29008,
                                                                                    "singleQuantityLcf": 0,
                                                                                    "lcf": 0,
                                                                                    "totalQuantityMcf": 0.29008,
                                                                                    "expandable": false,
                                                                                    "children": []
                                                                                }
                                                                            ]
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 1720,
                                                                    "parentRowNumber": 1676,
                                                                    "articleId": "46125780",
                                                                    "description": "46125780-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0079,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0079,
                                                                    "singleQuantityMcf": 0.040922,
                                                                    "mcf": 0.040922,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.040922,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 1721,
                                                                    "parentRowNumber": 1676,
                                                                    "articleId": "46125780",
                                                                    "description": "46125780-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0079,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0079,
                                                                    "singleQuantityMcf": 0.040922,
                                                                    "mcf": 0.040922,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.040922,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 1722,
                                                                    "parentRowNumber": 1676,
                                                                    "articleId": "46316696",
                                                                    "description": "46316696-04-Distanz",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0848,
                                                                    "childrenWeight": 0.0848,
                                                                    "totalQuantityWeight": 0.0848,
                                                                    "singleQuantityMcf": 0.439264,
                                                                    "mcf": 0.439264,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.439264,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 1723,
                                                                            "parentRowNumber": 1722,
                                                                            "articleId": "1260038",
                                                                            "description": "1260038-00-Rundstahl",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0848,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0848,
                                                                            "singleQuantityMcf": 0.439264,
                                                                            "mcf": 0.439264,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.439264,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 1724,
                                                                    "parentRowNumber": 1676,
                                                                    "articleId": "46321808",
                                                                    "description": "46321808-01-Distanz",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.9219,
                                                                    "childrenWeight": 0.9219,
                                                                    "totalQuantityWeight": 0.9219,
                                                                    "singleQuantityMcf": 4.775442,
                                                                    "mcf": 4.775442,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 4.775442,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 1725,
                                                                            "parentRowNumber": 1724,
                                                                            "articleId": "125524",
                                                                            "description": "125524-00-Rohr",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.9219,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.9219,
                                                                            "singleQuantityMcf": 4.775442,
                                                                            "mcf": 4.775442,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 4.775442,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            "rowNumber": 1726,
                                                            "parentRowNumber": 871,
                                                            "articleId": "47099902",
                                                            "description": "47099902-01-Schutzpfosten",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 1.9449000000000007,
                                                            "childrenWeight": 1.9449000000000007,
                                                            "totalQuantityWeight": 1.9449000000000007,
                                                            "singleQuantityMcf": 9.4171098,
                                                            "mcf": 9.4171098,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 9.4171098,
                                                            "expandable": false,
                                                            "children": [
                                                                {
                                                                    "rowNumber": 1727,
                                                                    "parentRowNumber": 1726,
                                                                    "articleId": "20270690",
                                                                    "description": "20270690-00-Zylinderschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0234,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0234,
                                                                    "singleQuantityMcf": 0.121212,
                                                                    "mcf": 0.121212,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.121212,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 1728,
                                                                    "parentRowNumber": 1726,
                                                                    "articleId": "208205",
                                                                    "description": "208205-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0004,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0004,
                                                                    "singleQuantityMcf": 0.002072,
                                                                    "mcf": 0.002072,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.002072,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 1729,
                                                                    "parentRowNumber": 1726,
                                                                    "articleId": "208205",
                                                                    "description": "208205-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0004,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0004,
                                                                    "singleQuantityMcf": 0.002072,
                                                                    "mcf": 0.002072,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.002072,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 1730,
                                                                    "parentRowNumber": 1726,
                                                                    "articleId": "208205",
                                                                    "description": "208205-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0004,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0004,
                                                                    "singleQuantityMcf": 0.002072,
                                                                    "mcf": 0.002072,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.002072,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 1731,
                                                                    "parentRowNumber": 1726,
                                                                    "articleId": "208205",
                                                                    "description": "208205-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0004,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0004,
                                                                    "singleQuantityMcf": 0.002072,
                                                                    "mcf": 0.002072,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.002072,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 1732,
                                                                    "parentRowNumber": 1726,
                                                                    "articleId": "208205",
                                                                    "description": "208205-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0004,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0004,
                                                                    "singleQuantityMcf": 0.002072,
                                                                    "mcf": 0.002072,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.002072,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 1733,
                                                                    "parentRowNumber": 1726,
                                                                    "articleId": "208205",
                                                                    "description": "208205-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0004,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0004,
                                                                    "singleQuantityMcf": 0.002072,
                                                                    "mcf": 0.002072,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.002072,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 1734,
                                                                    "parentRowNumber": 1726,
                                                                    "articleId": "216232",
                                                                    "description": "216232-00-Sicherungsring",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0023,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0023,
                                                                    "singleQuantityMcf": 0.0039376,
                                                                    "mcf": 0.0039376,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.0039376,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 1735,
                                                                    "parentRowNumber": 1726,
                                                                    "articleId": "216232",
                                                                    "description": "216232-00-Sicherungsring",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0023,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0023,
                                                                    "singleQuantityMcf": 0.0039376,
                                                                    "mcf": 0.0039376,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.0039376,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 1736,
                                                                    "parentRowNumber": 1726,
                                                                    "articleId": "216232",
                                                                    "description": "216232-00-Sicherungsring",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0023,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0023,
                                                                    "singleQuantityMcf": 0.0039376,
                                                                    "mcf": 0.0039376,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.0039376,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 1737,
                                                                    "parentRowNumber": 1726,
                                                                    "articleId": "457007",
                                                                    "description": "457007-03-Klemmstueck",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0465,
                                                                    "childrenWeight": 0.0465,
                                                                    "totalQuantityWeight": 0.0465,
                                                                    "singleQuantityMcf": 0.24087,
                                                                    "mcf": 0.24087,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.24087,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 1738,
                                                                            "parentRowNumber": 1737,
                                                                            "articleId": "1260038",
                                                                            "description": "1260038-00-Rundstahl",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0465,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0465,
                                                                            "singleQuantityMcf": 0.24087,
                                                                            "mcf": 0.24087,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.24087,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 1739,
                                                                    "parentRowNumber": 1726,
                                                                    "articleId": "457007",
                                                                    "description": "457007-03-Klemmstueck",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0465,
                                                                    "childrenWeight": 0.0465,
                                                                    "totalQuantityWeight": 0.0465,
                                                                    "singleQuantityMcf": 0.24087,
                                                                    "mcf": 0.24087,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.24087,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 1740,
                                                                            "parentRowNumber": 1739,
                                                                            "articleId": "1260038",
                                                                            "description": "1260038-00-Rundstahl",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0465,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0465,
                                                                            "singleQuantityMcf": 0.24087,
                                                                            "mcf": 0.24087,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.24087,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 1741,
                                                                    "parentRowNumber": 1726,
                                                                    "articleId": "457007",
                                                                    "description": "457007-03-Klemmstueck",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0465,
                                                                    "childrenWeight": 0.0465,
                                                                    "totalQuantityWeight": 0.0465,
                                                                    "singleQuantityMcf": 0.24087,
                                                                    "mcf": 0.24087,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.24087,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 1742,
                                                                            "parentRowNumber": 1741,
                                                                            "articleId": "1260038",
                                                                            "description": "1260038-00-Rundstahl",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0465,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0465,
                                                                            "singleQuantityMcf": 0.24087,
                                                                            "mcf": 0.24087,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.24087,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 1743,
                                                                    "parentRowNumber": 1726,
                                                                    "articleId": "457007",
                                                                    "description": "457007-03-Klemmstueck",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0465,
                                                                    "childrenWeight": 0.0465,
                                                                    "totalQuantityWeight": 0.0465,
                                                                    "singleQuantityMcf": 0.24087,
                                                                    "mcf": 0.24087,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.24087,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 1744,
                                                                            "parentRowNumber": 1743,
                                                                            "articleId": "1260038",
                                                                            "description": "1260038-00-Rundstahl",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0465,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0465,
                                                                            "singleQuantityMcf": 0.24087,
                                                                            "mcf": 0.24087,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.24087,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 1745,
                                                                    "parentRowNumber": 1726,
                                                                    "articleId": "457007",
                                                                    "description": "457007-03-Klemmstueck",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0465,
                                                                    "childrenWeight": 0.0465,
                                                                    "totalQuantityWeight": 0.0465,
                                                                    "singleQuantityMcf": 0.24087,
                                                                    "mcf": 0.24087,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.24087,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 1746,
                                                                            "parentRowNumber": 1745,
                                                                            "articleId": "1260038",
                                                                            "description": "1260038-00-Rundstahl",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0465,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0465,
                                                                            "singleQuantityMcf": 0.24087,
                                                                            "mcf": 0.24087,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.24087,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 1747,
                                                                    "parentRowNumber": 1726,
                                                                    "articleId": "457007",
                                                                    "description": "457007-03-Klemmstueck",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0465,
                                                                    "childrenWeight": 0.0465,
                                                                    "totalQuantityWeight": 0.0465,
                                                                    "singleQuantityMcf": 0.24087,
                                                                    "mcf": 0.24087,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.24087,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 1748,
                                                                            "parentRowNumber": 1747,
                                                                            "articleId": "1260038",
                                                                            "description": "1260038-00-Rundstahl",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0465,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0465,
                                                                            "singleQuantityMcf": 0.24087,
                                                                            "mcf": 0.24087,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.24087,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 1749,
                                                                    "parentRowNumber": 1726,
                                                                    "articleId": "457051",
                                                                    "description": "457051-03-Distanz",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0077,
                                                                    "childrenWeight": 0.0077,
                                                                    "totalQuantityWeight": 0.0077,
                                                                    "singleQuantityMcf": 0.013321,
                                                                    "mcf": 0.013321,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.013321,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 1750,
                                                                            "parentRowNumber": 1749,
                                                                            "articleId": "164710",
                                                                            "description": "164710-00-Hohlstab",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0077,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0077,
                                                                            "singleQuantityMcf": 0.013321,
                                                                            "mcf": 0.013321,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.013321,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 1751,
                                                                    "parentRowNumber": 1726,
                                                                    "articleId": "457051",
                                                                    "description": "457051-03-Distanz",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0077,
                                                                    "childrenWeight": 0.0077,
                                                                    "totalQuantityWeight": 0.0077,
                                                                    "singleQuantityMcf": 0.013321,
                                                                    "mcf": 0.013321,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.013321,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 1752,
                                                                            "parentRowNumber": 1751,
                                                                            "articleId": "164710",
                                                                            "description": "164710-00-Hohlstab",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0077,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0077,
                                                                            "singleQuantityMcf": 0.013321,
                                                                            "mcf": 0.013321,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.013321,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 1753,
                                                                    "parentRowNumber": 1726,
                                                                    "articleId": "457051",
                                                                    "description": "457051-03-Distanz",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0077,
                                                                    "childrenWeight": 0.0077,
                                                                    "totalQuantityWeight": 0.0077,
                                                                    "singleQuantityMcf": 0.013321,
                                                                    "mcf": 0.013321,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.013321,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 1754,
                                                                            "parentRowNumber": 1753,
                                                                            "articleId": "164710",
                                                                            "description": "164710-00-Hohlstab",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0077,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0077,
                                                                            "singleQuantityMcf": 0.013321,
                                                                            "mcf": 0.013321,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.013321,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 1755,
                                                                    "parentRowNumber": 1726,
                                                                    "articleId": "457190",
                                                                    "description": "457190-01-Distanzplatte",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0012,
                                                                    "childrenWeight": 0.0012,
                                                                    "totalQuantityWeight": 0.0012,
                                                                    "singleQuantityMcf": 0.004079999999999999,
                                                                    "mcf": 0.004079999999999999,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.004079999999999999,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 1756,
                                                                            "parentRowNumber": 1755,
                                                                            "articleId": "162431",
                                                                            "description": "162431-00-Platte",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0012,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0012,
                                                                            "singleQuantityMcf": 0.004079999999999999,
                                                                            "mcf": 0.004079999999999999,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.004079999999999999,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 1757,
                                                                    "parentRowNumber": 1726,
                                                                    "articleId": "457190",
                                                                    "description": "457190-01-Distanzplatte",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0012,
                                                                    "childrenWeight": 0.0012,
                                                                    "totalQuantityWeight": 0.0012,
                                                                    "singleQuantityMcf": 0.004079999999999999,
                                                                    "mcf": 0.004079999999999999,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.004079999999999999,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 1758,
                                                                            "parentRowNumber": 1757,
                                                                            "articleId": "162431",
                                                                            "description": "162431-00-Platte",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0012,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0012,
                                                                            "singleQuantityMcf": 0.004079999999999999,
                                                                            "mcf": 0.004079999999999999,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.004079999999999999,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 1759,
                                                                    "parentRowNumber": 1726,
                                                                    "articleId": "457190",
                                                                    "description": "457190-01-Distanzplatte",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0012,
                                                                    "childrenWeight": 0.0012,
                                                                    "totalQuantityWeight": 0.0012,
                                                                    "singleQuantityMcf": 0.004079999999999999,
                                                                    "mcf": 0.004079999999999999,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.004079999999999999,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 1760,
                                                                            "parentRowNumber": 1759,
                                                                            "articleId": "162431",
                                                                            "description": "162431-00-Platte",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0012,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0012,
                                                                            "singleQuantityMcf": 0.004079999999999999,
                                                                            "mcf": 0.004079999999999999,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.004079999999999999,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 1761,
                                                                    "parentRowNumber": 1726,
                                                                    "articleId": "457190",
                                                                    "description": "457190-01-Distanzplatte",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0012,
                                                                    "childrenWeight": 0.0012,
                                                                    "totalQuantityWeight": 0.0012,
                                                                    "singleQuantityMcf": 0.004079999999999999,
                                                                    "mcf": 0.004079999999999999,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.004079999999999999,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 1762,
                                                                            "parentRowNumber": 1761,
                                                                            "articleId": "162431",
                                                                            "description": "162431-00-Platte",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0012,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0012,
                                                                            "singleQuantityMcf": 0.004079999999999999,
                                                                            "mcf": 0.004079999999999999,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.004079999999999999,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 1763,
                                                                    "parentRowNumber": 1726,
                                                                    "articleId": "457190",
                                                                    "description": "457190-01-Distanzplatte",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0012,
                                                                    "childrenWeight": 0.0012,
                                                                    "totalQuantityWeight": 0.0012,
                                                                    "singleQuantityMcf": 0.004079999999999999,
                                                                    "mcf": 0.004079999999999999,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.004079999999999999,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 1764,
                                                                            "parentRowNumber": 1763,
                                                                            "articleId": "162431",
                                                                            "description": "162431-00-Platte",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0012,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0012,
                                                                            "singleQuantityMcf": 0.004079999999999999,
                                                                            "mcf": 0.004079999999999999,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.004079999999999999,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 1765,
                                                                    "parentRowNumber": 1726,
                                                                    "articleId": "457190",
                                                                    "description": "457190-01-Distanzplatte",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0012,
                                                                    "childrenWeight": 0.0012,
                                                                    "totalQuantityWeight": 0.0012,
                                                                    "singleQuantityMcf": 0.004079999999999999,
                                                                    "mcf": 0.004079999999999999,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.004079999999999999,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 1766,
                                                                            "parentRowNumber": 1765,
                                                                            "articleId": "162431",
                                                                            "description": "162431-00-Platte",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0012,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0012,
                                                                            "singleQuantityMcf": 0.004079999999999999,
                                                                            "mcf": 0.004079999999999999,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.004079999999999999,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 1767,
                                                                    "parentRowNumber": 1726,
                                                                    "articleId": "457193",
                                                                    "description": "457193-01-Distanzplatte",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0021,
                                                                    "childrenWeight": 0.0021,
                                                                    "totalQuantityWeight": 0.0021,
                                                                    "singleQuantityMcf": 0.00714,
                                                                    "mcf": 0.00714,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.00714,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 1768,
                                                                            "parentRowNumber": 1767,
                                                                            "articleId": "162431",
                                                                            "description": "162431-00-Platte",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0021,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0021,
                                                                            "singleQuantityMcf": 0.00714,
                                                                            "mcf": 0.00714,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.00714,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 1769,
                                                                    "parentRowNumber": 1726,
                                                                    "articleId": "457193",
                                                                    "description": "457193-01-Distanzplatte",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0021,
                                                                    "childrenWeight": 0.0021,
                                                                    "totalQuantityWeight": 0.0021,
                                                                    "singleQuantityMcf": 0.00714,
                                                                    "mcf": 0.00714,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.00714,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 1770,
                                                                            "parentRowNumber": 1769,
                                                                            "articleId": "162431",
                                                                            "description": "162431-00-Platte",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0021,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0021,
                                                                            "singleQuantityMcf": 0.00714,
                                                                            "mcf": 0.00714,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.00714,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 1771,
                                                                    "parentRowNumber": 1726,
                                                                    "articleId": "457193",
                                                                    "description": "457193-01-Distanzplatte",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0021,
                                                                    "childrenWeight": 0.0021,
                                                                    "totalQuantityWeight": 0.0021,
                                                                    "singleQuantityMcf": 0.00714,
                                                                    "mcf": 0.00714,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.00714,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 1772,
                                                                            "parentRowNumber": 1771,
                                                                            "articleId": "162431",
                                                                            "description": "162431-00-Platte",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0021,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0021,
                                                                            "singleQuantityMcf": 0.00714,
                                                                            "mcf": 0.00714,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.00714,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 1773,
                                                                    "parentRowNumber": 1726,
                                                                    "articleId": "457193",
                                                                    "description": "457193-01-Distanzplatte",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0021,
                                                                    "childrenWeight": 0.0021,
                                                                    "totalQuantityWeight": 0.0021,
                                                                    "singleQuantityMcf": 0.00714,
                                                                    "mcf": 0.00714,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.00714,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 1774,
                                                                            "parentRowNumber": 1773,
                                                                            "articleId": "162431",
                                                                            "description": "162431-00-Platte",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0021,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0021,
                                                                            "singleQuantityMcf": 0.00714,
                                                                            "mcf": 0.00714,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.00714,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 1775,
                                                                    "parentRowNumber": 1726,
                                                                    "articleId": "457194",
                                                                    "description": "457194-01-Distanzplatte",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0008,
                                                                    "childrenWeight": 0.0008,
                                                                    "totalQuantityWeight": 0.0008,
                                                                    "singleQuantityMcf": 0.00272,
                                                                    "mcf": 0.00272,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.00272,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 1776,
                                                                            "parentRowNumber": 1775,
                                                                            "articleId": "162431",
                                                                            "description": "162431-00-Platte",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0008,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0008,
                                                                            "singleQuantityMcf": 0.00272,
                                                                            "mcf": 0.00272,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.00272,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 1777,
                                                                    "parentRowNumber": 1726,
                                                                    "articleId": "457194",
                                                                    "description": "457194-01-Distanzplatte",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0008,
                                                                    "childrenWeight": 0.0008,
                                                                    "totalQuantityWeight": 0.0008,
                                                                    "singleQuantityMcf": 0.00272,
                                                                    "mcf": 0.00272,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.00272,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 1778,
                                                                            "parentRowNumber": 1777,
                                                                            "articleId": "162431",
                                                                            "description": "162431-00-Platte",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0008,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0008,
                                                                            "singleQuantityMcf": 0.00272,
                                                                            "mcf": 0.00272,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.00272,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 1779,
                                                                    "parentRowNumber": 1726,
                                                                    "articleId": "457194",
                                                                    "description": "457194-01-Distanzplatte",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0008,
                                                                    "childrenWeight": 0.0008,
                                                                    "totalQuantityWeight": 0.0008,
                                                                    "singleQuantityMcf": 0.00272,
                                                                    "mcf": 0.00272,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.00272,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 1780,
                                                                            "parentRowNumber": 1779,
                                                                            "articleId": "162431",
                                                                            "description": "162431-00-Platte",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0008,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0008,
                                                                            "singleQuantityMcf": 0.00272,
                                                                            "mcf": 0.00272,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.00272,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 1781,
                                                                    "parentRowNumber": 1726,
                                                                    "articleId": "457194",
                                                                    "description": "457194-01-Distanzplatte",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0008,
                                                                    "childrenWeight": 0.0008,
                                                                    "totalQuantityWeight": 0.0008,
                                                                    "singleQuantityMcf": 0.00272,
                                                                    "mcf": 0.00272,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.00272,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 1782,
                                                                            "parentRowNumber": 1781,
                                                                            "articleId": "162431",
                                                                            "description": "162431-00-Platte",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0008,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0008,
                                                                            "singleQuantityMcf": 0.00272,
                                                                            "mcf": 0.00272,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.00272,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 1783,
                                                                    "parentRowNumber": 1726,
                                                                    "articleId": "457194",
                                                                    "description": "457194-01-Distanzplatte",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0008,
                                                                    "childrenWeight": 0.0008,
                                                                    "totalQuantityWeight": 0.0008,
                                                                    "singleQuantityMcf": 0.00272,
                                                                    "mcf": 0.00272,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.00272,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 1784,
                                                                            "parentRowNumber": 1783,
                                                                            "articleId": "162431",
                                                                            "description": "162431-00-Platte",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0008,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0008,
                                                                            "singleQuantityMcf": 0.00272,
                                                                            "mcf": 0.00272,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.00272,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 1785,
                                                                    "parentRowNumber": 1726,
                                                                    "articleId": "457194",
                                                                    "description": "457194-01-Distanzplatte",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0008,
                                                                    "childrenWeight": 0.0008,
                                                                    "totalQuantityWeight": 0.0008,
                                                                    "singleQuantityMcf": 0.00272,
                                                                    "mcf": 0.00272,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.00272,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 1786,
                                                                            "parentRowNumber": 1785,
                                                                            "articleId": "162431",
                                                                            "description": "162431-00-Platte",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0008,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0008,
                                                                            "singleQuantityMcf": 0.00272,
                                                                            "mcf": 0.00272,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.00272,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 1787,
                                                                    "parentRowNumber": 1726,
                                                                    "articleId": "457195",
                                                                    "description": "457195-01-Distanz",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 1788,
                                                                            "parentRowNumber": 1787,
                                                                            "articleId": "3859213",
                                                                            "description": "3859213-00-Schlauch",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 1789,
                                                                    "parentRowNumber": 1726,
                                                                    "articleId": "457195",
                                                                    "description": "457195-01-Distanz",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 1790,
                                                                            "parentRowNumber": 1789,
                                                                            "articleId": "3859213",
                                                                            "description": "3859213-00-Schlauch",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 1791,
                                                                    "parentRowNumber": 1726,
                                                                    "articleId": "457195",
                                                                    "description": "457195-01-Distanz",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 1792,
                                                                            "parentRowNumber": 1791,
                                                                            "articleId": "3859213",
                                                                            "description": "3859213-00-Schlauch",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 1793,
                                                                    "parentRowNumber": 1726,
                                                                    "articleId": "457195",
                                                                    "description": "457195-01-Distanz",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 1794,
                                                                            "parentRowNumber": 1793,
                                                                            "articleId": "3859213",
                                                                            "description": "3859213-00-Schlauch",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 1795,
                                                                    "parentRowNumber": 1726,
                                                                    "articleId": "457195",
                                                                    "description": "457195-01-Distanz",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 1796,
                                                                            "parentRowNumber": 1795,
                                                                            "articleId": "3859213",
                                                                            "description": "3859213-00-Schlauch",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 1797,
                                                                    "parentRowNumber": 1726,
                                                                    "articleId": "457195",
                                                                    "description": "457195-01-Distanz",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 1798,
                                                                            "parentRowNumber": 1797,
                                                                            "articleId": "3859213",
                                                                            "description": "3859213-00-Schlauch",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 1799,
                                                                    "parentRowNumber": 1726,
                                                                    "articleId": "46024147",
                                                                    "description": "46024147-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0011,
                                                                    "childrenWeight": 0.0011,
                                                                    "totalQuantityWeight": 0.0011,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 1800,
                                                                            "parentRowNumber": 1799,
                                                                            "articleId": "163201",
                                                                            "description": "163201-00-Gummiplatte",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0011,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0011,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 1801,
                                                                    "parentRowNumber": 1726,
                                                                    "articleId": "46024147",
                                                                    "description": "46024147-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0011,
                                                                    "childrenWeight": 0.0011,
                                                                    "totalQuantityWeight": 0.0011,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 1802,
                                                                            "parentRowNumber": 1801,
                                                                            "articleId": "163201",
                                                                            "description": "163201-00-Gummiplatte",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0011,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0011,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 1803,
                                                                    "parentRowNumber": 1726,
                                                                    "articleId": "46024147",
                                                                    "description": "46024147-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0011,
                                                                    "childrenWeight": 0.0011,
                                                                    "totalQuantityWeight": 0.0011,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 1804,
                                                                            "parentRowNumber": 1803,
                                                                            "articleId": "163201",
                                                                            "description": "163201-00-Gummiplatte",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0011,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0011,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 1805,
                                                                    "parentRowNumber": 1726,
                                                                    "articleId": "46024147",
                                                                    "description": "46024147-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0011,
                                                                    "childrenWeight": 0.0011,
                                                                    "totalQuantityWeight": 0.0011,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 1806,
                                                                            "parentRowNumber": 1805,
                                                                            "articleId": "163201",
                                                                            "description": "163201-00-Gummiplatte",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0011,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0011,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 1807,
                                                                    "parentRowNumber": 1726,
                                                                    "articleId": "46029594",
                                                                    "description": "46029594-01-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0007,
                                                                    "childrenWeight": 0.0007,
                                                                    "totalQuantityWeight": 0.0007,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 1808,
                                                                            "parentRowNumber": 1807,
                                                                            "articleId": "163201",
                                                                            "description": "163201-00-Gummiplatte",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0007,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0007,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 1809,
                                                                    "parentRowNumber": 1726,
                                                                    "articleId": "46029594",
                                                                    "description": "46029594-01-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0007,
                                                                    "childrenWeight": 0.0007,
                                                                    "totalQuantityWeight": 0.0007,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 1810,
                                                                            "parentRowNumber": 1809,
                                                                            "articleId": "163201",
                                                                            "description": "163201-00-Gummiplatte",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0007,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0007,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 1811,
                                                                    "parentRowNumber": 1726,
                                                                    "articleId": "46029594",
                                                                    "description": "46029594-01-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0007,
                                                                    "childrenWeight": 0.0007,
                                                                    "totalQuantityWeight": 0.0007,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 1812,
                                                                            "parentRowNumber": 1811,
                                                                            "articleId": "163201",
                                                                            "description": "163201-00-Gummiplatte",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0007,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0007,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 1813,
                                                                    "parentRowNumber": 1726,
                                                                    "articleId": "46029594",
                                                                    "description": "46029594-01-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0007,
                                                                    "childrenWeight": 0.0007,
                                                                    "totalQuantityWeight": 0.0007,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 1814,
                                                                            "parentRowNumber": 1813,
                                                                            "articleId": "163201",
                                                                            "description": "163201-00-Gummiplatte",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0007,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0007,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 1815,
                                                                    "parentRowNumber": 1726,
                                                                    "articleId": "46101957",
                                                                    "description": "46101957-01-Buchse",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0174,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0174,
                                                                    "singleQuantityMcf": 0.030102,
                                                                    "mcf": 0.030102,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.030102,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 1816,
                                                                    "parentRowNumber": 1726,
                                                                    "articleId": "46101957",
                                                                    "description": "46101957-01-Buchse",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0174,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0174,
                                                                    "singleQuantityMcf": 0.030102,
                                                                    "mcf": 0.030102,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.030102,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 1817,
                                                                    "parentRowNumber": 1726,
                                                                    "articleId": "46101957",
                                                                    "description": "46101957-01-Buchse",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0174,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0174,
                                                                    "singleQuantityMcf": 0.030102,
                                                                    "mcf": 0.030102,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.030102,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 1818,
                                                                    "parentRowNumber": 1726,
                                                                    "articleId": "46101957",
                                                                    "description": "46101957-01-Buchse",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0174,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0174,
                                                                    "singleQuantityMcf": 0.030102,
                                                                    "mcf": 0.030102,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.030102,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 1819,
                                                                    "parentRowNumber": 1726,
                                                                    "articleId": "46101957",
                                                                    "description": "46101957-01-Buchse",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0174,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0174,
                                                                    "singleQuantityMcf": 0.030102,
                                                                    "mcf": 0.030102,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.030102,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 1820,
                                                                    "parentRowNumber": 1726,
                                                                    "articleId": "46101957",
                                                                    "description": "46101957-01-Buchse",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0174,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0174,
                                                                    "singleQuantityMcf": 0.030102,
                                                                    "mcf": 0.030102,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.030102,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 1821,
                                                                    "parentRowNumber": 1726,
                                                                    "articleId": "46101957",
                                                                    "description": "46101957-01-Buchse",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0174,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0174,
                                                                    "singleQuantityMcf": 0.030102,
                                                                    "mcf": 0.030102,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.030102,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 1822,
                                                                    "parentRowNumber": 1726,
                                                                    "articleId": "46101957",
                                                                    "description": "46101957-01-Buchse",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0174,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0174,
                                                                    "singleQuantityMcf": 0.030102,
                                                                    "mcf": 0.030102,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.030102,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 1823,
                                                                    "parentRowNumber": 1726,
                                                                    "articleId": "46102540",
                                                                    "description": "46102540-03-Scharnier",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.1022,
                                                                    "childrenWeight": 0.1022,
                                                                    "totalQuantityWeight": 0.1022,
                                                                    "singleQuantityMcf": 0.529396,
                                                                    "mcf": 0.529396,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.529396,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 1824,
                                                                            "parentRowNumber": 1823,
                                                                            "articleId": "46102518",
                                                                            "description": "46102518-00-Lasche",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0462,
                                                                            "childrenWeight": 0.0462,
                                                                            "totalQuantityWeight": 0.0462,
                                                                            "singleQuantityMcf": 0.239316,
                                                                            "mcf": 0.239316,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.239316,
                                                                            "expandable": false,
                                                                            "children": [
                                                                                {
                                                                                    "rowNumber": 1825,
                                                                                    "parentRowNumber": 1824,
                                                                                    "articleId": "142612",
                                                                                    "description": "142612-00-Edelstahlblech",
                                                                                    "hierarchy": 9,
                                                                                    "quantity": 1,
                                                                                    "weight": 0.0462,
                                                                                    "childrenWeight": 0,
                                                                                    "totalQuantityWeight": 0.0462,
                                                                                    "singleQuantityMcf": 0.239316,
                                                                                    "mcf": 0.239316,
                                                                                    "singleQuantityLcf": 0,
                                                                                    "lcf": 0,
                                                                                    "totalQuantityMcf": 0.239316,
                                                                                    "expandable": false,
                                                                                    "children": []
                                                                                }
                                                                            ]
                                                                        },
                                                                        {
                                                                            "rowNumber": 1826,
                                                                            "parentRowNumber": 1823,
                                                                            "articleId": "46102539",
                                                                            "description": "46102539-00-Distanz",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.056,
                                                                            "childrenWeight": 0.056,
                                                                            "totalQuantityWeight": 0.056,
                                                                            "singleQuantityMcf": 0.29008,
                                                                            "mcf": 0.29008,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.29008,
                                                                            "expandable": false,
                                                                            "children": [
                                                                                {
                                                                                    "rowNumber": 1827,
                                                                                    "parentRowNumber": 1826,
                                                                                    "articleId": "125524",
                                                                                    "description": "125524-00-Rohr",
                                                                                    "hierarchy": 9,
                                                                                    "quantity": 1,
                                                                                    "weight": 0.056,
                                                                                    "childrenWeight": 0,
                                                                                    "totalQuantityWeight": 0.056,
                                                                                    "singleQuantityMcf": 0.29008,
                                                                                    "mcf": 0.29008,
                                                                                    "singleQuantityLcf": 0,
                                                                                    "lcf": 0,
                                                                                    "totalQuantityMcf": 0.29008,
                                                                                    "expandable": false,
                                                                                    "children": []
                                                                                }
                                                                            ]
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 1828,
                                                                    "parentRowNumber": 1726,
                                                                    "articleId": "46102540",
                                                                    "description": "46102540-03-Scharnier",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.1022,
                                                                    "childrenWeight": 0.1022,
                                                                    "totalQuantityWeight": 0.1022,
                                                                    "singleQuantityMcf": 0.529396,
                                                                    "mcf": 0.529396,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.529396,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 1829,
                                                                            "parentRowNumber": 1828,
                                                                            "articleId": "46102518",
                                                                            "description": "46102518-00-Lasche",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0462,
                                                                            "childrenWeight": 0.0462,
                                                                            "totalQuantityWeight": 0.0462,
                                                                            "singleQuantityMcf": 0.239316,
                                                                            "mcf": 0.239316,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.239316,
                                                                            "expandable": false,
                                                                            "children": [
                                                                                {
                                                                                    "rowNumber": 1830,
                                                                                    "parentRowNumber": 1829,
                                                                                    "articleId": "142612",
                                                                                    "description": "142612-00-Edelstahlblech",
                                                                                    "hierarchy": 9,
                                                                                    "quantity": 1,
                                                                                    "weight": 0.0462,
                                                                                    "childrenWeight": 0,
                                                                                    "totalQuantityWeight": 0.0462,
                                                                                    "singleQuantityMcf": 0.239316,
                                                                                    "mcf": 0.239316,
                                                                                    "singleQuantityLcf": 0,
                                                                                    "lcf": 0,
                                                                                    "totalQuantityMcf": 0.239316,
                                                                                    "expandable": false,
                                                                                    "children": []
                                                                                }
                                                                            ]
                                                                        },
                                                                        {
                                                                            "rowNumber": 1831,
                                                                            "parentRowNumber": 1828,
                                                                            "articleId": "46102539",
                                                                            "description": "46102539-00-Distanz",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.056,
                                                                            "childrenWeight": 0.056,
                                                                            "totalQuantityWeight": 0.056,
                                                                            "singleQuantityMcf": 0.29008,
                                                                            "mcf": 0.29008,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.29008,
                                                                            "expandable": false,
                                                                            "children": [
                                                                                {
                                                                                    "rowNumber": 1832,
                                                                                    "parentRowNumber": 1831,
                                                                                    "articleId": "125524",
                                                                                    "description": "125524-00-Rohr",
                                                                                    "hierarchy": 9,
                                                                                    "quantity": 1,
                                                                                    "weight": 0.056,
                                                                                    "childrenWeight": 0,
                                                                                    "totalQuantityWeight": 0.056,
                                                                                    "singleQuantityMcf": 0.29008,
                                                                                    "mcf": 0.29008,
                                                                                    "singleQuantityLcf": 0,
                                                                                    "lcf": 0,
                                                                                    "totalQuantityMcf": 0.29008,
                                                                                    "expandable": false,
                                                                                    "children": []
                                                                                }
                                                                            ]
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 1833,
                                                                    "parentRowNumber": 1726,
                                                                    "articleId": "46102540",
                                                                    "description": "46102540-03-Scharnier",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.1022,
                                                                    "childrenWeight": 0.1022,
                                                                    "totalQuantityWeight": 0.1022,
                                                                    "singleQuantityMcf": 0.529396,
                                                                    "mcf": 0.529396,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.529396,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 1834,
                                                                            "parentRowNumber": 1833,
                                                                            "articleId": "46102518",
                                                                            "description": "46102518-00-Lasche",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0462,
                                                                            "childrenWeight": 0.0462,
                                                                            "totalQuantityWeight": 0.0462,
                                                                            "singleQuantityMcf": 0.239316,
                                                                            "mcf": 0.239316,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.239316,
                                                                            "expandable": false,
                                                                            "children": [
                                                                                {
                                                                                    "rowNumber": 1835,
                                                                                    "parentRowNumber": 1834,
                                                                                    "articleId": "142612",
                                                                                    "description": "142612-00-Edelstahlblech",
                                                                                    "hierarchy": 9,
                                                                                    "quantity": 1,
                                                                                    "weight": 0.0462,
                                                                                    "childrenWeight": 0,
                                                                                    "totalQuantityWeight": 0.0462,
                                                                                    "singleQuantityMcf": 0.239316,
                                                                                    "mcf": 0.239316,
                                                                                    "singleQuantityLcf": 0,
                                                                                    "lcf": 0,
                                                                                    "totalQuantityMcf": 0.239316,
                                                                                    "expandable": false,
                                                                                    "children": []
                                                                                }
                                                                            ]
                                                                        },
                                                                        {
                                                                            "rowNumber": 1836,
                                                                            "parentRowNumber": 1833,
                                                                            "articleId": "46102539",
                                                                            "description": "46102539-00-Distanz",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.056,
                                                                            "childrenWeight": 0.056,
                                                                            "totalQuantityWeight": 0.056,
                                                                            "singleQuantityMcf": 0.29008,
                                                                            "mcf": 0.29008,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.29008,
                                                                            "expandable": false,
                                                                            "children": [
                                                                                {
                                                                                    "rowNumber": 1837,
                                                                                    "parentRowNumber": 1836,
                                                                                    "articleId": "125524",
                                                                                    "description": "125524-00-Rohr",
                                                                                    "hierarchy": 9,
                                                                                    "quantity": 1,
                                                                                    "weight": 0.056,
                                                                                    "childrenWeight": 0,
                                                                                    "totalQuantityWeight": 0.056,
                                                                                    "singleQuantityMcf": 0.29008,
                                                                                    "mcf": 0.29008,
                                                                                    "singleQuantityLcf": 0,
                                                                                    "lcf": 0,
                                                                                    "totalQuantityMcf": 0.29008,
                                                                                    "expandable": false,
                                                                                    "children": []
                                                                                }
                                                                            ]
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 1838,
                                                                    "parentRowNumber": 1726,
                                                                    "articleId": "46102540",
                                                                    "description": "46102540-03-Scharnier",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.1022,
                                                                    "childrenWeight": 0.1022,
                                                                    "totalQuantityWeight": 0.1022,
                                                                    "singleQuantityMcf": 0.529396,
                                                                    "mcf": 0.529396,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.529396,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 1839,
                                                                            "parentRowNumber": 1838,
                                                                            "articleId": "46102518",
                                                                            "description": "46102518-00-Lasche",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0462,
                                                                            "childrenWeight": 0.0462,
                                                                            "totalQuantityWeight": 0.0462,
                                                                            "singleQuantityMcf": 0.239316,
                                                                            "mcf": 0.239316,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.239316,
                                                                            "expandable": false,
                                                                            "children": [
                                                                                {
                                                                                    "rowNumber": 1840,
                                                                                    "parentRowNumber": 1839,
                                                                                    "articleId": "142612",
                                                                                    "description": "142612-00-Edelstahlblech",
                                                                                    "hierarchy": 9,
                                                                                    "quantity": 1,
                                                                                    "weight": 0.0462,
                                                                                    "childrenWeight": 0,
                                                                                    "totalQuantityWeight": 0.0462,
                                                                                    "singleQuantityMcf": 0.239316,
                                                                                    "mcf": 0.239316,
                                                                                    "singleQuantityLcf": 0,
                                                                                    "lcf": 0,
                                                                                    "totalQuantityMcf": 0.239316,
                                                                                    "expandable": false,
                                                                                    "children": []
                                                                                }
                                                                            ]
                                                                        },
                                                                        {
                                                                            "rowNumber": 1841,
                                                                            "parentRowNumber": 1838,
                                                                            "articleId": "46102539",
                                                                            "description": "46102539-00-Distanz",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.056,
                                                                            "childrenWeight": 0.056,
                                                                            "totalQuantityWeight": 0.056,
                                                                            "singleQuantityMcf": 0.29008,
                                                                            "mcf": 0.29008,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.29008,
                                                                            "expandable": false,
                                                                            "children": [
                                                                                {
                                                                                    "rowNumber": 1842,
                                                                                    "parentRowNumber": 1841,
                                                                                    "articleId": "125524",
                                                                                    "description": "125524-00-Rohr",
                                                                                    "hierarchy": 9,
                                                                                    "quantity": 1,
                                                                                    "weight": 0.056,
                                                                                    "childrenWeight": 0,
                                                                                    "totalQuantityWeight": 0.056,
                                                                                    "singleQuantityMcf": 0.29008,
                                                                                    "mcf": 0.29008,
                                                                                    "singleQuantityLcf": 0,
                                                                                    "lcf": 0,
                                                                                    "totalQuantityMcf": 0.29008,
                                                                                    "expandable": false,
                                                                                    "children": []
                                                                                }
                                                                            ]
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 1843,
                                                                    "parentRowNumber": 1726,
                                                                    "articleId": "46102543",
                                                                    "description": "46102543-02-Scharnier",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.1022,
                                                                    "childrenWeight": 0.1022,
                                                                    "totalQuantityWeight": 0.1022,
                                                                    "singleQuantityMcf": 0.529396,
                                                                    "mcf": 0.529396,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.529396,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 1844,
                                                                            "parentRowNumber": 1843,
                                                                            "articleId": "46102533",
                                                                            "description": "46102533-00-Lasche",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0462,
                                                                            "childrenWeight": 0.0462,
                                                                            "totalQuantityWeight": 0.0462,
                                                                            "singleQuantityMcf": 0.239316,
                                                                            "mcf": 0.239316,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.239316,
                                                                            "expandable": false,
                                                                            "children": [
                                                                                {
                                                                                    "rowNumber": 1845,
                                                                                    "parentRowNumber": 1844,
                                                                                    "articleId": "142612",
                                                                                    "description": "142612-00-Edelstahlblech",
                                                                                    "hierarchy": 9,
                                                                                    "quantity": 1,
                                                                                    "weight": 0.0462,
                                                                                    "childrenWeight": 0,
                                                                                    "totalQuantityWeight": 0.0462,
                                                                                    "singleQuantityMcf": 0.239316,
                                                                                    "mcf": 0.239316,
                                                                                    "singleQuantityLcf": 0,
                                                                                    "lcf": 0,
                                                                                    "totalQuantityMcf": 0.239316,
                                                                                    "expandable": false,
                                                                                    "children": []
                                                                                }
                                                                            ]
                                                                        },
                                                                        {
                                                                            "rowNumber": 1846,
                                                                            "parentRowNumber": 1843,
                                                                            "articleId": "46102539",
                                                                            "description": "46102539-00-Distanz",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.056,
                                                                            "childrenWeight": 0.056,
                                                                            "totalQuantityWeight": 0.056,
                                                                            "singleQuantityMcf": 0.29008,
                                                                            "mcf": 0.29008,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.29008,
                                                                            "expandable": false,
                                                                            "children": [
                                                                                {
                                                                                    "rowNumber": 1847,
                                                                                    "parentRowNumber": 1846,
                                                                                    "articleId": "125524",
                                                                                    "description": "125524-00-Rohr",
                                                                                    "hierarchy": 9,
                                                                                    "quantity": 1,
                                                                                    "weight": 0.056,
                                                                                    "childrenWeight": 0,
                                                                                    "totalQuantityWeight": 0.056,
                                                                                    "singleQuantityMcf": 0.29008,
                                                                                    "mcf": 0.29008,
                                                                                    "singleQuantityLcf": 0,
                                                                                    "lcf": 0,
                                                                                    "totalQuantityMcf": 0.29008,
                                                                                    "expandable": false,
                                                                                    "children": []
                                                                                }
                                                                            ]
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 1848,
                                                                    "parentRowNumber": 1726,
                                                                    "articleId": "46102543",
                                                                    "description": "46102543-02-Scharnier",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.1022,
                                                                    "childrenWeight": 0.1022,
                                                                    "totalQuantityWeight": 0.1022,
                                                                    "singleQuantityMcf": 0.529396,
                                                                    "mcf": 0.529396,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.529396,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 1849,
                                                                            "parentRowNumber": 1848,
                                                                            "articleId": "46102533",
                                                                            "description": "46102533-00-Lasche",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0462,
                                                                            "childrenWeight": 0.0462,
                                                                            "totalQuantityWeight": 0.0462,
                                                                            "singleQuantityMcf": 0.239316,
                                                                            "mcf": 0.239316,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.239316,
                                                                            "expandable": false,
                                                                            "children": [
                                                                                {
                                                                                    "rowNumber": 1850,
                                                                                    "parentRowNumber": 1849,
                                                                                    "articleId": "142612",
                                                                                    "description": "142612-00-Edelstahlblech",
                                                                                    "hierarchy": 9,
                                                                                    "quantity": 1,
                                                                                    "weight": 0.0462,
                                                                                    "childrenWeight": 0,
                                                                                    "totalQuantityWeight": 0.0462,
                                                                                    "singleQuantityMcf": 0.239316,
                                                                                    "mcf": 0.239316,
                                                                                    "singleQuantityLcf": 0,
                                                                                    "lcf": 0,
                                                                                    "totalQuantityMcf": 0.239316,
                                                                                    "expandable": false,
                                                                                    "children": []
                                                                                }
                                                                            ]
                                                                        },
                                                                        {
                                                                            "rowNumber": 1851,
                                                                            "parentRowNumber": 1848,
                                                                            "articleId": "46102539",
                                                                            "description": "46102539-00-Distanz",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.056,
                                                                            "childrenWeight": 0.056,
                                                                            "totalQuantityWeight": 0.056,
                                                                            "singleQuantityMcf": 0.29008,
                                                                            "mcf": 0.29008,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.29008,
                                                                            "expandable": false,
                                                                            "children": [
                                                                                {
                                                                                    "rowNumber": 1852,
                                                                                    "parentRowNumber": 1851,
                                                                                    "articleId": "125524",
                                                                                    "description": "125524-00-Rohr",
                                                                                    "hierarchy": 9,
                                                                                    "quantity": 1,
                                                                                    "weight": 0.056,
                                                                                    "childrenWeight": 0,
                                                                                    "totalQuantityWeight": 0.056,
                                                                                    "singleQuantityMcf": 0.29008,
                                                                                    "mcf": 0.29008,
                                                                                    "singleQuantityLcf": 0,
                                                                                    "lcf": 0,
                                                                                    "totalQuantityMcf": 0.29008,
                                                                                    "expandable": false,
                                                                                    "children": []
                                                                                }
                                                                            ]
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 1853,
                                                                    "parentRowNumber": 1726,
                                                                    "articleId": "46102546",
                                                                    "description": "46102546-01-Distanz",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.7827,
                                                                    "childrenWeight": 0.7827,
                                                                    "totalQuantityWeight": 0.7827,
                                                                    "singleQuantityMcf": 4.054385999999999,
                                                                    "mcf": 4.054385999999999,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 4.054385999999999,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 1854,
                                                                            "parentRowNumber": 1853,
                                                                            "articleId": "1261242",
                                                                            "description": "1261242-00-Rohr",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.7827,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.7827,
                                                                            "singleQuantityMcf": 4.054385999999999,
                                                                            "mcf": 4.054385999999999,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 4.054385999999999,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 1855,
                                                                    "parentRowNumber": 1726,
                                                                    "articleId": "46125780",
                                                                    "description": "46125780-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0079,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0079,
                                                                    "singleQuantityMcf": 0.040922,
                                                                    "mcf": 0.040922,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.040922,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 1856,
                                                                    "parentRowNumber": 1726,
                                                                    "articleId": "46125780",
                                                                    "description": "46125780-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0079,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0079,
                                                                    "singleQuantityMcf": 0.040922,
                                                                    "mcf": 0.040922,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.040922,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 1857,
                                                                    "parentRowNumber": 1726,
                                                                    "articleId": "46125780",
                                                                    "description": "46125780-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0079,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0079,
                                                                    "singleQuantityMcf": 0.040922,
                                                                    "mcf": 0.040922,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.040922,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 1858,
                                                                    "parentRowNumber": 1726,
                                                                    "articleId": "46125780",
                                                                    "description": "46125780-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0079,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0079,
                                                                    "singleQuantityMcf": 0.040922,
                                                                    "mcf": 0.040922,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.040922,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 1859,
                                                                    "parentRowNumber": 1726,
                                                                    "articleId": "46125780",
                                                                    "description": "46125780-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0079,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0079,
                                                                    "singleQuantityMcf": 0.040922,
                                                                    "mcf": 0.040922,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.040922,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 1860,
                                                                    "parentRowNumber": 1726,
                                                                    "articleId": "46125780",
                                                                    "description": "46125780-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0079,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0079,
                                                                    "singleQuantityMcf": 0.040922,
                                                                    "mcf": 0.040922,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.040922,
                                                                    "expandable": false,
                                                                    "children": []
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            "rowNumber": 1861,
                                                            "parentRowNumber": 871,
                                                            "articleId": "47099914",
                                                            "description": "47099914-00-Distanz",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.5589,
                                                            "childrenWeight": 0.5589,
                                                            "totalQuantityWeight": 0.5589,
                                                            "singleQuantityMcf": 2.895102,
                                                            "mcf": 2.895102,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 2.895102,
                                                            "expandable": false,
                                                            "children": [
                                                                {
                                                                    "rowNumber": 1862,
                                                                    "parentRowNumber": 1861,
                                                                    "articleId": "1261242",
                                                                    "description": "1261242-00-Rohr",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.5589,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.5589,
                                                                    "singleQuantityMcf": 2.895102,
                                                                    "mcf": 2.895102,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 2.895102,
                                                                    "expandable": false,
                                                                    "children": []
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            "rowNumber": 1863,
                                                            "parentRowNumber": 871,
                                                            "articleId": "47100988",
                                                            "description": "47100988-00-Halter",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 1.179,
                                                            "childrenWeight": 1.179,
                                                            "totalQuantityWeight": 1.179,
                                                            "singleQuantityMcf": 3.5910400000000005,
                                                            "mcf": 3.5910400000000005,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 3.5910400000000005,
                                                            "expandable": false,
                                                            "children": [
                                                                {
                                                                    "rowNumber": 1864,
                                                                    "parentRowNumber": 1863,
                                                                    "articleId": "162553",
                                                                    "description": "162553-00-Platte",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 1.1222,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 1.1222,
                                                                    "singleQuantityMcf": 3.5910400000000005,
                                                                    "mcf": 3.5910400000000005,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 3.5910400000000005,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 1865,
                                                                    "parentRowNumber": 1863,
                                                                    "articleId": "347204",
                                                                    "description": "347204-00-Ensat-Gewindebuchse",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0049,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0049,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 1866,
                                                                    "parentRowNumber": 1863,
                                                                    "articleId": "347204",
                                                                    "description": "347204-00-Ensat-Gewindebuchse",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0049,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0049,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 1867,
                                                                    "parentRowNumber": 1863,
                                                                    "articleId": "347205",
                                                                    "description": "347205-00-Ensat-Gewindebuchse",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0058,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0058,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 1868,
                                                                    "parentRowNumber": 1863,
                                                                    "articleId": "347205",
                                                                    "description": "347205-00-Ensat-Gewindebuchse",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0058,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0058,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 1869,
                                                                    "parentRowNumber": 1863,
                                                                    "articleId": "347205",
                                                                    "description": "347205-00-Ensat-Gewindebuchse",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0058,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0058,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 1870,
                                                                    "parentRowNumber": 1863,
                                                                    "articleId": "347205",
                                                                    "description": "347205-00-Ensat-Gewindebuchse",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0058,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0058,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 1871,
                                                                    "parentRowNumber": 1863,
                                                                    "articleId": "347215",
                                                                    "description": "347215-00-Ensat-Gewindebuchse",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0119,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0119,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 1872,
                                                                    "parentRowNumber": 1863,
                                                                    "articleId": "347215",
                                                                    "description": "347215-00-Ensat-Gewindebuchse",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0119,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0119,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": []
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            "rowNumber": 1873,
                                                            "parentRowNumber": 871,
                                                            "articleId": "47101049",
                                                            "description": "47101049-00-Halter",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 1.1161,
                                                            "childrenWeight": 1.1161,
                                                            "totalQuantityWeight": 1.1161,
                                                            "singleQuantityMcf": 3.5816880000000006,
                                                            "mcf": 3.5816880000000006,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 3.5816880000000006,
                                                            "expandable": false,
                                                            "children": [
                                                                {
                                                                    "rowNumber": 1874,
                                                                    "parentRowNumber": 1873,
                                                                    "articleId": "162555",
                                                                    "description": "162555-00-Platte",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 1.0487000000000002,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 1.0487000000000002,
                                                                    "singleQuantityMcf": 3.3558400000000006,
                                                                    "mcf": 3.3558400000000006,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 3.3558400000000006,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 1875,
                                                                    "parentRowNumber": 1873,
                                                                    "articleId": "20220640",
                                                                    "description": "20220640-00-Zylinderschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0109,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0109,
                                                                    "singleQuantityMcf": 0.056462,
                                                                    "mcf": 0.056462,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.056462,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 1876,
                                                                    "parentRowNumber": 1873,
                                                                    "articleId": "20220640",
                                                                    "description": "20220640-00-Zylinderschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0109,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0109,
                                                                    "singleQuantityMcf": 0.056462,
                                                                    "mcf": 0.056462,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.056462,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 1877,
                                                                    "parentRowNumber": 1873,
                                                                    "articleId": "20220640",
                                                                    "description": "20220640-00-Zylinderschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0109,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0109,
                                                                    "singleQuantityMcf": 0.056462,
                                                                    "mcf": 0.056462,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.056462,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 1878,
                                                                    "parentRowNumber": 1873,
                                                                    "articleId": "20220640",
                                                                    "description": "20220640-00-Zylinderschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0109,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0109,
                                                                    "singleQuantityMcf": 0.056462,
                                                                    "mcf": 0.056462,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.056462,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 1879,
                                                                    "parentRowNumber": 1873,
                                                                    "articleId": "347215",
                                                                    "description": "347215-00-Ensat-Gewindebuchse",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0119,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0119,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 1880,
                                                                    "parentRowNumber": 1873,
                                                                    "articleId": "347215",
                                                                    "description": "347215-00-Ensat-Gewindebuchse",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0119,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0119,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": []
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            "rowNumber": 1881,
                                                            "parentRowNumber": 871,
                                                            "articleId": "47124319",
                                                            "description": "47124319-00-Schutzscheibe",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 13.6974,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 13.6974,
                                                            "singleQuantityMcf": 0,
                                                            "mcf": 0,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 1882,
                                                            "parentRowNumber": 871,
                                                            "articleId": "47124319",
                                                            "description": "47124319-00-Schutzscheibe",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 13.6974,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 13.6974,
                                                            "singleQuantityMcf": 0,
                                                            "mcf": 0,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 1883,
                                                            "parentRowNumber": 871,
                                                            "articleId": "47145376",
                                                            "description": "47145376-01-Gehaeuse",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.3952,
                                                            "childrenWeight": 0.3952,
                                                            "totalQuantityWeight": 0.3952,
                                                            "singleQuantityMcf": 1.4219460000000004,
                                                            "mcf": 1.4219460000000004,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 1.4219460000000004,
                                                            "expandable": false,
                                                            "children": [
                                                                {
                                                                    "rowNumber": 1884,
                                                                    "parentRowNumber": 1883,
                                                                    "articleId": "20120530",
                                                                    "description": "20120530-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0062,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0062,
                                                                    "singleQuantityMcf": 0.032116,
                                                                    "mcf": 0.032116,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.032116,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 1885,
                                                                    "parentRowNumber": 1883,
                                                                    "articleId": "20120530",
                                                                    "description": "20120530-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0062,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0062,
                                                                    "singleQuantityMcf": 0.032116,
                                                                    "mcf": 0.032116,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.032116,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 1886,
                                                                    "parentRowNumber": 1883,
                                                                    "articleId": "20220825",
                                                                    "description": "20220825-00-Zylinderschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.014,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.014,
                                                                    "singleQuantityMcf": 0.07252,
                                                                    "mcf": 0.07252,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.07252,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 1887,
                                                                    "parentRowNumber": 1883,
                                                                    "articleId": "20220825",
                                                                    "description": "20220825-00-Zylinderschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.014,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.014,
                                                                    "singleQuantityMcf": 0.07252,
                                                                    "mcf": 0.07252,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.07252,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 1888,
                                                                    "parentRowNumber": 1883,
                                                                    "articleId": "208205",
                                                                    "description": "208205-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0004,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0004,
                                                                    "singleQuantityMcf": 0.002072,
                                                                    "mcf": 0.002072,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.002072,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 1889,
                                                                    "parentRowNumber": 1883,
                                                                    "articleId": "208205",
                                                                    "description": "208205-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0004,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0004,
                                                                    "singleQuantityMcf": 0.002072,
                                                                    "mcf": 0.002072,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.002072,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 1890,
                                                                    "parentRowNumber": 1883,
                                                                    "articleId": "252953",
                                                                    "description": "252953-00-Stopfen",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0004,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0004,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 1891,
                                                                    "parentRowNumber": 1883,
                                                                    "articleId": "252953",
                                                                    "description": "252953-00-Stopfen",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0004,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0004,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 1892,
                                                                    "parentRowNumber": 1883,
                                                                    "articleId": "341000",
                                                                    "description": "341000-00-Druckstueck",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0005,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0005,
                                                                    "singleQuantityMcf": 0.00259,
                                                                    "mcf": 0.00259,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.00259,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 1893,
                                                                    "parentRowNumber": 1883,
                                                                    "articleId": "341000",
                                                                    "description": "341000-00-Druckstueck",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0005,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0005,
                                                                    "singleQuantityMcf": 0.00259,
                                                                    "mcf": 0.00259,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.00259,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 1894,
                                                                    "parentRowNumber": 1883,
                                                                    "articleId": "47114246",
                                                                    "description": "47114246-01-Blech",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0098,
                                                                    "childrenWeight": 0.0098,
                                                                    "totalQuantityWeight": 0.0098,
                                                                    "singleQuantityMcf": 0.050764,
                                                                    "mcf": 0.050764,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.050764,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 1895,
                                                                            "parentRowNumber": 1894,
                                                                            "articleId": "142604",
                                                                            "description": "142604-00-Edelstahlblech",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0098,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0098,
                                                                            "singleQuantityMcf": 0.050764,
                                                                            "mcf": 0.050764,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.050764,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 1896,
                                                                    "parentRowNumber": 1883,
                                                                    "articleId": "47116591",
                                                                    "description": "47116591-00-Mutter",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0097,
                                                                    "childrenWeight": 0.0097,
                                                                    "totalQuantityWeight": 0.0097,
                                                                    "singleQuantityMcf": 0.050246,
                                                                    "mcf": 0.050246,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.050246,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 1897,
                                                                            "parentRowNumber": 1896,
                                                                            "articleId": "132713",
                                                                            "description": "132713-00-Sechskantstahl",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0097,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0097,
                                                                            "singleQuantityMcf": 0.050246,
                                                                            "mcf": 0.050246,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.050246,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 1898,
                                                                    "parentRowNumber": 1883,
                                                                    "articleId": "47146278",
                                                                    "description": "47146278-00-Verriegelung",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.047,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.047,
                                                                    "singleQuantityMcf": 0.24346,
                                                                    "mcf": 0.24346,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.24346,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 1899,
                                                                    "parentRowNumber": 1883,
                                                                    "articleId": "47151237",
                                                                    "description": "47151237-01-Halter",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.2049,
                                                                    "childrenWeight": 0.2049,
                                                                    "totalQuantityWeight": 0.2049,
                                                                    "singleQuantityMcf": 0.65568,
                                                                    "mcf": 0.65568,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.65568,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 1900,
                                                                            "parentRowNumber": 1899,
                                                                            "articleId": "162544",
                                                                            "description": "162544-01-Platte",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.2049,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.2049,
                                                                            "singleQuantityMcf": 0.65568,
                                                                            "mcf": 0.65568,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.65568,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 1901,
                                                                    "parentRowNumber": 1883,
                                                                    "articleId": "47151292",
                                                                    "description": "47151292-01-Deckel",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0635,
                                                                    "childrenWeight": 0.0635,
                                                                    "totalQuantityWeight": 0.0635,
                                                                    "singleQuantityMcf": 0.2032,
                                                                    "mcf": 0.2032,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.2032,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 1902,
                                                                            "parentRowNumber": 1901,
                                                                            "articleId": "162538",
                                                                            "description": "162538-01-Platte",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0635,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0635,
                                                                            "singleQuantityMcf": 0.2032,
                                                                            "mcf": 0.2032,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.2032,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 1903,
                                                                    "parentRowNumber": 1883,
                                                                    "articleId": "54415240",
                                                                    "description": "54415240-00-Sicherheitszuhaltung",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 1904,
                                                                    "parentRowNumber": 1883,
                                                                    "articleId": "58236177",
                                                                    "description": "58236177-01-Kabeldose",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0173,
                                                                    "childrenWeight": 0.0173,
                                                                    "totalQuantityWeight": 0.0173,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 1905,
                                                                            "parentRowNumber": 1904,
                                                                            "articleId": "PUDY00000067",
                                                                            "description": "PUDY00000067-00-Kabel D6",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0173,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0173,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            "rowNumber": 1906,
                                                            "parentRowNumber": 871,
                                                            "articleId": "47145376",
                                                            "description": "47145376-01-Gehaeuse",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.3952,
                                                            "childrenWeight": 0.3952,
                                                            "totalQuantityWeight": 0.3952,
                                                            "singleQuantityMcf": 1.4219460000000004,
                                                            "mcf": 1.4219460000000004,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 1.4219460000000004,
                                                            "expandable": false,
                                                            "children": [
                                                                {
                                                                    "rowNumber": 1907,
                                                                    "parentRowNumber": 1906,
                                                                    "articleId": "20120530",
                                                                    "description": "20120530-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0062,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0062,
                                                                    "singleQuantityMcf": 0.032116,
                                                                    "mcf": 0.032116,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.032116,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 1908,
                                                                    "parentRowNumber": 1906,
                                                                    "articleId": "20120530",
                                                                    "description": "20120530-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0062,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0062,
                                                                    "singleQuantityMcf": 0.032116,
                                                                    "mcf": 0.032116,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.032116,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 1909,
                                                                    "parentRowNumber": 1906,
                                                                    "articleId": "20220825",
                                                                    "description": "20220825-00-Zylinderschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.014,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.014,
                                                                    "singleQuantityMcf": 0.07252,
                                                                    "mcf": 0.07252,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.07252,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 1910,
                                                                    "parentRowNumber": 1906,
                                                                    "articleId": "20220825",
                                                                    "description": "20220825-00-Zylinderschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.014,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.014,
                                                                    "singleQuantityMcf": 0.07252,
                                                                    "mcf": 0.07252,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.07252,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 1911,
                                                                    "parentRowNumber": 1906,
                                                                    "articleId": "208205",
                                                                    "description": "208205-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0004,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0004,
                                                                    "singleQuantityMcf": 0.002072,
                                                                    "mcf": 0.002072,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.002072,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 1912,
                                                                    "parentRowNumber": 1906,
                                                                    "articleId": "208205",
                                                                    "description": "208205-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0004,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0004,
                                                                    "singleQuantityMcf": 0.002072,
                                                                    "mcf": 0.002072,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.002072,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 1913,
                                                                    "parentRowNumber": 1906,
                                                                    "articleId": "252953",
                                                                    "description": "252953-00-Stopfen",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0004,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0004,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 1914,
                                                                    "parentRowNumber": 1906,
                                                                    "articleId": "252953",
                                                                    "description": "252953-00-Stopfen",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0004,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0004,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 1915,
                                                                    "parentRowNumber": 1906,
                                                                    "articleId": "341000",
                                                                    "description": "341000-00-Druckstueck",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0005,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0005,
                                                                    "singleQuantityMcf": 0.00259,
                                                                    "mcf": 0.00259,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.00259,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 1916,
                                                                    "parentRowNumber": 1906,
                                                                    "articleId": "341000",
                                                                    "description": "341000-00-Druckstueck",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0005,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0005,
                                                                    "singleQuantityMcf": 0.00259,
                                                                    "mcf": 0.00259,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.00259,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 1917,
                                                                    "parentRowNumber": 1906,
                                                                    "articleId": "47114246",
                                                                    "description": "47114246-01-Blech",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0098,
                                                                    "childrenWeight": 0.0098,
                                                                    "totalQuantityWeight": 0.0098,
                                                                    "singleQuantityMcf": 0.050764,
                                                                    "mcf": 0.050764,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.050764,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 1918,
                                                                            "parentRowNumber": 1917,
                                                                            "articleId": "142604",
                                                                            "description": "142604-00-Edelstahlblech",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0098,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0098,
                                                                            "singleQuantityMcf": 0.050764,
                                                                            "mcf": 0.050764,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.050764,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 1919,
                                                                    "parentRowNumber": 1906,
                                                                    "articleId": "47116591",
                                                                    "description": "47116591-00-Mutter",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0097,
                                                                    "childrenWeight": 0.0097,
                                                                    "totalQuantityWeight": 0.0097,
                                                                    "singleQuantityMcf": 0.050246,
                                                                    "mcf": 0.050246,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.050246,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 1920,
                                                                            "parentRowNumber": 1919,
                                                                            "articleId": "132713",
                                                                            "description": "132713-00-Sechskantstahl",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0097,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0097,
                                                                            "singleQuantityMcf": 0.050246,
                                                                            "mcf": 0.050246,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.050246,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 1921,
                                                                    "parentRowNumber": 1906,
                                                                    "articleId": "47146278",
                                                                    "description": "47146278-00-Verriegelung",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.047,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.047,
                                                                    "singleQuantityMcf": 0.24346,
                                                                    "mcf": 0.24346,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.24346,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 1922,
                                                                    "parentRowNumber": 1906,
                                                                    "articleId": "47151237",
                                                                    "description": "47151237-01-Halter",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.2049,
                                                                    "childrenWeight": 0.2049,
                                                                    "totalQuantityWeight": 0.2049,
                                                                    "singleQuantityMcf": 0.65568,
                                                                    "mcf": 0.65568,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.65568,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 1923,
                                                                            "parentRowNumber": 1922,
                                                                            "articleId": "162544",
                                                                            "description": "162544-01-Platte",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.2049,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.2049,
                                                                            "singleQuantityMcf": 0.65568,
                                                                            "mcf": 0.65568,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.65568,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 1924,
                                                                    "parentRowNumber": 1906,
                                                                    "articleId": "47151292",
                                                                    "description": "47151292-01-Deckel",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0635,
                                                                    "childrenWeight": 0.0635,
                                                                    "totalQuantityWeight": 0.0635,
                                                                    "singleQuantityMcf": 0.2032,
                                                                    "mcf": 0.2032,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.2032,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 1925,
                                                                            "parentRowNumber": 1924,
                                                                            "articleId": "162538",
                                                                            "description": "162538-01-Platte",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0635,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0635,
                                                                            "singleQuantityMcf": 0.2032,
                                                                            "mcf": 0.2032,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.2032,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 1926,
                                                                    "parentRowNumber": 1906,
                                                                    "articleId": "54415240",
                                                                    "description": "54415240-00-Sicherheitszuhaltung",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 1927,
                                                                    "parentRowNumber": 1906,
                                                                    "articleId": "58236177",
                                                                    "description": "58236177-01-Kabeldose",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0173,
                                                                    "childrenWeight": 0.0173,
                                                                    "totalQuantityWeight": 0.0173,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 1928,
                                                                            "parentRowNumber": 1927,
                                                                            "articleId": "PUDY00000067",
                                                                            "description": "PUDY00000067-00-Kabel D6",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0173,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0173,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            "rowNumber": 1929,
                                                            "parentRowNumber": 871,
                                                            "articleId": "47145376",
                                                            "description": "47145376-01-Gehaeuse",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.3952,
                                                            "childrenWeight": 0.3952,
                                                            "totalQuantityWeight": 0.3952,
                                                            "singleQuantityMcf": 1.4219460000000004,
                                                            "mcf": 1.4219460000000004,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 1.4219460000000004,
                                                            "expandable": false,
                                                            "children": [
                                                                {
                                                                    "rowNumber": 1930,
                                                                    "parentRowNumber": 1929,
                                                                    "articleId": "20120530",
                                                                    "description": "20120530-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0062,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0062,
                                                                    "singleQuantityMcf": 0.032116,
                                                                    "mcf": 0.032116,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.032116,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 1931,
                                                                    "parentRowNumber": 1929,
                                                                    "articleId": "20120530",
                                                                    "description": "20120530-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0062,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0062,
                                                                    "singleQuantityMcf": 0.032116,
                                                                    "mcf": 0.032116,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.032116,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 1932,
                                                                    "parentRowNumber": 1929,
                                                                    "articleId": "20220825",
                                                                    "description": "20220825-00-Zylinderschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.014,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.014,
                                                                    "singleQuantityMcf": 0.07252,
                                                                    "mcf": 0.07252,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.07252,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 1933,
                                                                    "parentRowNumber": 1929,
                                                                    "articleId": "20220825",
                                                                    "description": "20220825-00-Zylinderschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.014,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.014,
                                                                    "singleQuantityMcf": 0.07252,
                                                                    "mcf": 0.07252,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.07252,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 1934,
                                                                    "parentRowNumber": 1929,
                                                                    "articleId": "208205",
                                                                    "description": "208205-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0004,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0004,
                                                                    "singleQuantityMcf": 0.002072,
                                                                    "mcf": 0.002072,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.002072,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 1935,
                                                                    "parentRowNumber": 1929,
                                                                    "articleId": "208205",
                                                                    "description": "208205-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0004,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0004,
                                                                    "singleQuantityMcf": 0.002072,
                                                                    "mcf": 0.002072,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.002072,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 1936,
                                                                    "parentRowNumber": 1929,
                                                                    "articleId": "252953",
                                                                    "description": "252953-00-Stopfen",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0004,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0004,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 1937,
                                                                    "parentRowNumber": 1929,
                                                                    "articleId": "252953",
                                                                    "description": "252953-00-Stopfen",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0004,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0004,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 1938,
                                                                    "parentRowNumber": 1929,
                                                                    "articleId": "341000",
                                                                    "description": "341000-00-Druckstueck",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0005,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0005,
                                                                    "singleQuantityMcf": 0.00259,
                                                                    "mcf": 0.00259,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.00259,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 1939,
                                                                    "parentRowNumber": 1929,
                                                                    "articleId": "341000",
                                                                    "description": "341000-00-Druckstueck",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0005,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0005,
                                                                    "singleQuantityMcf": 0.00259,
                                                                    "mcf": 0.00259,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.00259,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 1940,
                                                                    "parentRowNumber": 1929,
                                                                    "articleId": "47114246",
                                                                    "description": "47114246-01-Blech",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0098,
                                                                    "childrenWeight": 0.0098,
                                                                    "totalQuantityWeight": 0.0098,
                                                                    "singleQuantityMcf": 0.050764,
                                                                    "mcf": 0.050764,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.050764,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 1941,
                                                                            "parentRowNumber": 1940,
                                                                            "articleId": "142604",
                                                                            "description": "142604-00-Edelstahlblech",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0098,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0098,
                                                                            "singleQuantityMcf": 0.050764,
                                                                            "mcf": 0.050764,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.050764,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 1942,
                                                                    "parentRowNumber": 1929,
                                                                    "articleId": "47116591",
                                                                    "description": "47116591-00-Mutter",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0097,
                                                                    "childrenWeight": 0.0097,
                                                                    "totalQuantityWeight": 0.0097,
                                                                    "singleQuantityMcf": 0.050246,
                                                                    "mcf": 0.050246,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.050246,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 1943,
                                                                            "parentRowNumber": 1942,
                                                                            "articleId": "132713",
                                                                            "description": "132713-00-Sechskantstahl",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0097,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0097,
                                                                            "singleQuantityMcf": 0.050246,
                                                                            "mcf": 0.050246,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.050246,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 1944,
                                                                    "parentRowNumber": 1929,
                                                                    "articleId": "47146278",
                                                                    "description": "47146278-00-Verriegelung",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.047,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.047,
                                                                    "singleQuantityMcf": 0.24346,
                                                                    "mcf": 0.24346,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.24346,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 1945,
                                                                    "parentRowNumber": 1929,
                                                                    "articleId": "47151237",
                                                                    "description": "47151237-01-Halter",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.2049,
                                                                    "childrenWeight": 0.2049,
                                                                    "totalQuantityWeight": 0.2049,
                                                                    "singleQuantityMcf": 0.65568,
                                                                    "mcf": 0.65568,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.65568,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 1946,
                                                                            "parentRowNumber": 1945,
                                                                            "articleId": "162544",
                                                                            "description": "162544-01-Platte",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.2049,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.2049,
                                                                            "singleQuantityMcf": 0.65568,
                                                                            "mcf": 0.65568,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.65568,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 1947,
                                                                    "parentRowNumber": 1929,
                                                                    "articleId": "47151292",
                                                                    "description": "47151292-01-Deckel",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0635,
                                                                    "childrenWeight": 0.0635,
                                                                    "totalQuantityWeight": 0.0635,
                                                                    "singleQuantityMcf": 0.2032,
                                                                    "mcf": 0.2032,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.2032,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 1948,
                                                                            "parentRowNumber": 1947,
                                                                            "articleId": "162538",
                                                                            "description": "162538-01-Platte",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0635,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0635,
                                                                            "singleQuantityMcf": 0.2032,
                                                                            "mcf": 0.2032,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.2032,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 1949,
                                                                    "parentRowNumber": 1929,
                                                                    "articleId": "54415240",
                                                                    "description": "54415240-00-Sicherheitszuhaltung",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 1950,
                                                                    "parentRowNumber": 1929,
                                                                    "articleId": "58236177",
                                                                    "description": "58236177-01-Kabeldose",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0173,
                                                                    "childrenWeight": 0.0173,
                                                                    "totalQuantityWeight": 0.0173,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 1951,
                                                                            "parentRowNumber": 1950,
                                                                            "articleId": "PUDY00000067",
                                                                            "description": "PUDY00000067-00-Kabel D6",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0173,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0173,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            "rowNumber": 1952,
                                                            "parentRowNumber": 871,
                                                            "articleId": "47146182",
                                                            "description": "47146182-00-Griff",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.8341000000000002,
                                                            "childrenWeight": 0.8341000000000002,
                                                            "totalQuantityWeight": 0.8341000000000002,
                                                            "singleQuantityMcf": 4.284326,
                                                            "mcf": 4.284326,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 4.284326,
                                                            "expandable": false,
                                                            "children": [
                                                                {
                                                                    "rowNumber": 1953,
                                                                    "parentRowNumber": 1952,
                                                                    "articleId": "46322133",
                                                                    "description": "46322133-01-Schraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0076,
                                                                    "childrenWeight": 0.0076,
                                                                    "totalQuantityWeight": 0.0076,
                                                                    "singleQuantityMcf": 0.02432,
                                                                    "mcf": 0.02432,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.02432,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 1954,
                                                                            "parentRowNumber": 1953,
                                                                            "articleId": "1617035",
                                                                            "description": "1617035-00-Rundstab",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0076,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0076,
                                                                            "singleQuantityMcf": 0.02432,
                                                                            "mcf": 0.02432,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.02432,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 1955,
                                                                    "parentRowNumber": 1952,
                                                                    "articleId": "46322133",
                                                                    "description": "46322133-01-Schraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0076,
                                                                    "childrenWeight": 0.0076,
                                                                    "totalQuantityWeight": 0.0076,
                                                                    "singleQuantityMcf": 0.02432,
                                                                    "mcf": 0.02432,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.02432,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 1956,
                                                                            "parentRowNumber": 1955,
                                                                            "articleId": "1617035",
                                                                            "description": "1617035-00-Rundstab",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0076,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0076,
                                                                            "singleQuantityMcf": 0.02432,
                                                                            "mcf": 0.02432,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.02432,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 1957,
                                                                    "parentRowNumber": 1952,
                                                                    "articleId": "46545896",
                                                                    "description": "46545896-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0006,
                                                                    "childrenWeight": 0.0006,
                                                                    "totalQuantityWeight": 0.0006,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 1958,
                                                                            "parentRowNumber": 1957,
                                                                            "articleId": "163201",
                                                                            "description": "163201-00-Gummiplatte",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0006,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0006,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 1959,
                                                                    "parentRowNumber": 1952,
                                                                    "articleId": "46545896",
                                                                    "description": "46545896-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0006,
                                                                    "childrenWeight": 0.0006,
                                                                    "totalQuantityWeight": 0.0006,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 1960,
                                                                            "parentRowNumber": 1959,
                                                                            "articleId": "163201",
                                                                            "description": "163201-00-Gummiplatte",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0006,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0006,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 1961,
                                                                    "parentRowNumber": 1952,
                                                                    "articleId": "46545902",
                                                                    "description": "46545902-01-Halter",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 1962,
                                                                            "parentRowNumber": 1961,
                                                                            "articleId": "46545902R",
                                                                            "description": "46545902R-00-Halter",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 1963,
                                                                    "parentRowNumber": 1952,
                                                                    "articleId": "46545913",
                                                                    "description": "46545913-02-Halter",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 1964,
                                                                            "parentRowNumber": 1963,
                                                                            "articleId": "46545913R",
                                                                            "description": "46545913R-00-Halter",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 1965,
                                                                    "parentRowNumber": 1952,
                                                                    "articleId": "47117097",
                                                                    "description": "47117097-00-Griff",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.8177,
                                                                    "childrenWeight": 0.8177,
                                                                    "totalQuantityWeight": 0.8177,
                                                                    "singleQuantityMcf": 4.235685999999999,
                                                                    "mcf": 4.235685999999999,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 4.235685999999999,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 1966,
                                                                            "parentRowNumber": 1965,
                                                                            "articleId": "47117076",
                                                                            "description": "47117076-00-Griff",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.7903,
                                                                            "childrenWeight": 0.7903,
                                                                            "totalQuantityWeight": 0.7903,
                                                                            "singleQuantityMcf": 4.093754,
                                                                            "mcf": 4.093754,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 4.093754,
                                                                            "expandable": false,
                                                                            "children": [
                                                                                {
                                                                                    "rowNumber": 1967,
                                                                                    "parentRowNumber": 1966,
                                                                                    "articleId": "1260018",
                                                                                    "description": "1260018-00-Rundstahl",
                                                                                    "hierarchy": 9,
                                                                                    "quantity": 1,
                                                                                    "weight": 0.7903,
                                                                                    "childrenWeight": 0,
                                                                                    "totalQuantityWeight": 0.7903,
                                                                                    "singleQuantityMcf": 4.093754,
                                                                                    "mcf": 4.093754,
                                                                                    "singleQuantityLcf": 0,
                                                                                    "lcf": 0,
                                                                                    "totalQuantityMcf": 4.093754,
                                                                                    "expandable": false,
                                                                                    "children": []
                                                                                }
                                                                            ]
                                                                        },
                                                                        {
                                                                            "rowNumber": 1968,
                                                                            "parentRowNumber": 1965,
                                                                            "articleId": "47117094",
                                                                            "description": "47117094-00-Bundbolzen",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0274,
                                                                            "childrenWeight": 0.0274,
                                                                            "totalQuantityWeight": 0.0274,
                                                                            "singleQuantityMcf": 0.141932,
                                                                            "mcf": 0.141932,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.141932,
                                                                            "expandable": false,
                                                                            "children": [
                                                                                {
                                                                                    "rowNumber": 1969,
                                                                                    "parentRowNumber": 1968,
                                                                                    "articleId": "1224010",
                                                                                    "description": "1224010-00-Rundstahl",
                                                                                    "hierarchy": 9,
                                                                                    "quantity": 1,
                                                                                    "weight": 0.0274,
                                                                                    "childrenWeight": 0,
                                                                                    "totalQuantityWeight": 0.0274,
                                                                                    "singleQuantityMcf": 0.141932,
                                                                                    "mcf": 0.141932,
                                                                                    "singleQuantityLcf": 0,
                                                                                    "lcf": 0,
                                                                                    "totalQuantityMcf": 0.141932,
                                                                                    "expandable": false,
                                                                                    "children": []
                                                                                }
                                                                            ]
                                                                        }
                                                                    ]
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            "rowNumber": 1970,
                                                            "parentRowNumber": 871,
                                                            "articleId": "47146182",
                                                            "description": "47146182-00-Griff",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.8341000000000002,
                                                            "childrenWeight": 0.8341000000000002,
                                                            "totalQuantityWeight": 0.8341000000000002,
                                                            "singleQuantityMcf": 4.284326,
                                                            "mcf": 4.284326,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 4.284326,
                                                            "expandable": false,
                                                            "children": [
                                                                {
                                                                    "rowNumber": 1971,
                                                                    "parentRowNumber": 1970,
                                                                    "articleId": "46322133",
                                                                    "description": "46322133-01-Schraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0076,
                                                                    "childrenWeight": 0.0076,
                                                                    "totalQuantityWeight": 0.0076,
                                                                    "singleQuantityMcf": 0.02432,
                                                                    "mcf": 0.02432,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.02432,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 1972,
                                                                            "parentRowNumber": 1971,
                                                                            "articleId": "1617035",
                                                                            "description": "1617035-00-Rundstab",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0076,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0076,
                                                                            "singleQuantityMcf": 0.02432,
                                                                            "mcf": 0.02432,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.02432,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 1973,
                                                                    "parentRowNumber": 1970,
                                                                    "articleId": "46322133",
                                                                    "description": "46322133-01-Schraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0076,
                                                                    "childrenWeight": 0.0076,
                                                                    "totalQuantityWeight": 0.0076,
                                                                    "singleQuantityMcf": 0.02432,
                                                                    "mcf": 0.02432,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.02432,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 1974,
                                                                            "parentRowNumber": 1973,
                                                                            "articleId": "1617035",
                                                                            "description": "1617035-00-Rundstab",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0076,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0076,
                                                                            "singleQuantityMcf": 0.02432,
                                                                            "mcf": 0.02432,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.02432,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 1975,
                                                                    "parentRowNumber": 1970,
                                                                    "articleId": "46545896",
                                                                    "description": "46545896-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0006,
                                                                    "childrenWeight": 0.0006,
                                                                    "totalQuantityWeight": 0.0006,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 1976,
                                                                            "parentRowNumber": 1975,
                                                                            "articleId": "163201",
                                                                            "description": "163201-00-Gummiplatte",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0006,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0006,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 1977,
                                                                    "parentRowNumber": 1970,
                                                                    "articleId": "46545896",
                                                                    "description": "46545896-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0006,
                                                                    "childrenWeight": 0.0006,
                                                                    "totalQuantityWeight": 0.0006,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 1978,
                                                                            "parentRowNumber": 1977,
                                                                            "articleId": "163201",
                                                                            "description": "163201-00-Gummiplatte",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0006,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0006,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 1979,
                                                                    "parentRowNumber": 1970,
                                                                    "articleId": "46545902",
                                                                    "description": "46545902-01-Halter",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 1980,
                                                                            "parentRowNumber": 1979,
                                                                            "articleId": "46545902R",
                                                                            "description": "46545902R-00-Halter",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 1981,
                                                                    "parentRowNumber": 1970,
                                                                    "articleId": "46545913",
                                                                    "description": "46545913-02-Halter",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 1982,
                                                                            "parentRowNumber": 1981,
                                                                            "articleId": "46545913R",
                                                                            "description": "46545913R-00-Halter",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 1983,
                                                                    "parentRowNumber": 1970,
                                                                    "articleId": "47117097",
                                                                    "description": "47117097-00-Griff",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.8177,
                                                                    "childrenWeight": 0.8177,
                                                                    "totalQuantityWeight": 0.8177,
                                                                    "singleQuantityMcf": 4.235685999999999,
                                                                    "mcf": 4.235685999999999,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 4.235685999999999,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 1984,
                                                                            "parentRowNumber": 1983,
                                                                            "articleId": "47117076",
                                                                            "description": "47117076-00-Griff",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.7903,
                                                                            "childrenWeight": 0.7903,
                                                                            "totalQuantityWeight": 0.7903,
                                                                            "singleQuantityMcf": 4.093754,
                                                                            "mcf": 4.093754,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 4.093754,
                                                                            "expandable": false,
                                                                            "children": [
                                                                                {
                                                                                    "rowNumber": 1985,
                                                                                    "parentRowNumber": 1984,
                                                                                    "articleId": "1260018",
                                                                                    "description": "1260018-00-Rundstahl",
                                                                                    "hierarchy": 9,
                                                                                    "quantity": 1,
                                                                                    "weight": 0.7903,
                                                                                    "childrenWeight": 0,
                                                                                    "totalQuantityWeight": 0.7903,
                                                                                    "singleQuantityMcf": 4.093754,
                                                                                    "mcf": 4.093754,
                                                                                    "singleQuantityLcf": 0,
                                                                                    "lcf": 0,
                                                                                    "totalQuantityMcf": 4.093754,
                                                                                    "expandable": false,
                                                                                    "children": []
                                                                                }
                                                                            ]
                                                                        },
                                                                        {
                                                                            "rowNumber": 1986,
                                                                            "parentRowNumber": 1983,
                                                                            "articleId": "47117094",
                                                                            "description": "47117094-00-Bundbolzen",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0274,
                                                                            "childrenWeight": 0.0274,
                                                                            "totalQuantityWeight": 0.0274,
                                                                            "singleQuantityMcf": 0.141932,
                                                                            "mcf": 0.141932,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.141932,
                                                                            "expandable": false,
                                                                            "children": [
                                                                                {
                                                                                    "rowNumber": 1987,
                                                                                    "parentRowNumber": 1986,
                                                                                    "articleId": "1224010",
                                                                                    "description": "1224010-00-Rundstahl",
                                                                                    "hierarchy": 9,
                                                                                    "quantity": 1,
                                                                                    "weight": 0.0274,
                                                                                    "childrenWeight": 0,
                                                                                    "totalQuantityWeight": 0.0274,
                                                                                    "singleQuantityMcf": 0.141932,
                                                                                    "mcf": 0.141932,
                                                                                    "singleQuantityLcf": 0,
                                                                                    "lcf": 0,
                                                                                    "totalQuantityMcf": 0.141932,
                                                                                    "expandable": false,
                                                                                    "children": []
                                                                                }
                                                                            ]
                                                                        }
                                                                    ]
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            "rowNumber": 1988,
                                                            "parentRowNumber": 871,
                                                            "articleId": "47146182",
                                                            "description": "47146182-00-Griff",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.8341000000000002,
                                                            "childrenWeight": 0.8341000000000002,
                                                            "totalQuantityWeight": 0.8341000000000002,
                                                            "singleQuantityMcf": 4.284326,
                                                            "mcf": 4.284326,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 4.284326,
                                                            "expandable": false,
                                                            "children": [
                                                                {
                                                                    "rowNumber": 1989,
                                                                    "parentRowNumber": 1988,
                                                                    "articleId": "46322133",
                                                                    "description": "46322133-01-Schraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0076,
                                                                    "childrenWeight": 0.0076,
                                                                    "totalQuantityWeight": 0.0076,
                                                                    "singleQuantityMcf": 0.02432,
                                                                    "mcf": 0.02432,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.02432,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 1990,
                                                                            "parentRowNumber": 1989,
                                                                            "articleId": "1617035",
                                                                            "description": "1617035-00-Rundstab",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0076,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0076,
                                                                            "singleQuantityMcf": 0.02432,
                                                                            "mcf": 0.02432,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.02432,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 1991,
                                                                    "parentRowNumber": 1988,
                                                                    "articleId": "46322133",
                                                                    "description": "46322133-01-Schraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0076,
                                                                    "childrenWeight": 0.0076,
                                                                    "totalQuantityWeight": 0.0076,
                                                                    "singleQuantityMcf": 0.02432,
                                                                    "mcf": 0.02432,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.02432,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 1992,
                                                                            "parentRowNumber": 1991,
                                                                            "articleId": "1617035",
                                                                            "description": "1617035-00-Rundstab",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0076,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0076,
                                                                            "singleQuantityMcf": 0.02432,
                                                                            "mcf": 0.02432,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.02432,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 1993,
                                                                    "parentRowNumber": 1988,
                                                                    "articleId": "46545896",
                                                                    "description": "46545896-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0006,
                                                                    "childrenWeight": 0.0006,
                                                                    "totalQuantityWeight": 0.0006,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 1994,
                                                                            "parentRowNumber": 1993,
                                                                            "articleId": "163201",
                                                                            "description": "163201-00-Gummiplatte",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0006,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0006,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 1995,
                                                                    "parentRowNumber": 1988,
                                                                    "articleId": "46545896",
                                                                    "description": "46545896-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0006,
                                                                    "childrenWeight": 0.0006,
                                                                    "totalQuantityWeight": 0.0006,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 1996,
                                                                            "parentRowNumber": 1995,
                                                                            "articleId": "163201",
                                                                            "description": "163201-00-Gummiplatte",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0006,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0006,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 1997,
                                                                    "parentRowNumber": 1988,
                                                                    "articleId": "46545902",
                                                                    "description": "46545902-01-Halter",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 1998,
                                                                            "parentRowNumber": 1997,
                                                                            "articleId": "46545902R",
                                                                            "description": "46545902R-00-Halter",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 1999,
                                                                    "parentRowNumber": 1988,
                                                                    "articleId": "46545913",
                                                                    "description": "46545913-02-Halter",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 2000,
                                                                            "parentRowNumber": 1999,
                                                                            "articleId": "46545913R",
                                                                            "description": "46545913R-00-Halter",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 2001,
                                                                    "parentRowNumber": 1988,
                                                                    "articleId": "47117097",
                                                                    "description": "47117097-00-Griff",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.8177,
                                                                    "childrenWeight": 0.8177,
                                                                    "totalQuantityWeight": 0.8177,
                                                                    "singleQuantityMcf": 4.235685999999999,
                                                                    "mcf": 4.235685999999999,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 4.235685999999999,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 2002,
                                                                            "parentRowNumber": 2001,
                                                                            "articleId": "47117076",
                                                                            "description": "47117076-00-Griff",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.7903,
                                                                            "childrenWeight": 0.7903,
                                                                            "totalQuantityWeight": 0.7903,
                                                                            "singleQuantityMcf": 4.093754,
                                                                            "mcf": 4.093754,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 4.093754,
                                                                            "expandable": false,
                                                                            "children": [
                                                                                {
                                                                                    "rowNumber": 2003,
                                                                                    "parentRowNumber": 2002,
                                                                                    "articleId": "1260018",
                                                                                    "description": "1260018-00-Rundstahl",
                                                                                    "hierarchy": 9,
                                                                                    "quantity": 1,
                                                                                    "weight": 0.7903,
                                                                                    "childrenWeight": 0,
                                                                                    "totalQuantityWeight": 0.7903,
                                                                                    "singleQuantityMcf": 4.093754,
                                                                                    "mcf": 4.093754,
                                                                                    "singleQuantityLcf": 0,
                                                                                    "lcf": 0,
                                                                                    "totalQuantityMcf": 4.093754,
                                                                                    "expandable": false,
                                                                                    "children": []
                                                                                }
                                                                            ]
                                                                        },
                                                                        {
                                                                            "rowNumber": 2004,
                                                                            "parentRowNumber": 2001,
                                                                            "articleId": "47117094",
                                                                            "description": "47117094-00-Bundbolzen",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0274,
                                                                            "childrenWeight": 0.0274,
                                                                            "totalQuantityWeight": 0.0274,
                                                                            "singleQuantityMcf": 0.141932,
                                                                            "mcf": 0.141932,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.141932,
                                                                            "expandable": false,
                                                                            "children": [
                                                                                {
                                                                                    "rowNumber": 2005,
                                                                                    "parentRowNumber": 2004,
                                                                                    "articleId": "1224010",
                                                                                    "description": "1224010-00-Rundstahl",
                                                                                    "hierarchy": 9,
                                                                                    "quantity": 1,
                                                                                    "weight": 0.0274,
                                                                                    "childrenWeight": 0,
                                                                                    "totalQuantityWeight": 0.0274,
                                                                                    "singleQuantityMcf": 0.141932,
                                                                                    "mcf": 0.141932,
                                                                                    "singleQuantityLcf": 0,
                                                                                    "lcf": 0,
                                                                                    "totalQuantityMcf": 0.141932,
                                                                                    "expandable": false,
                                                                                    "children": []
                                                                                }
                                                                            ]
                                                                        }
                                                                    ]
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            "rowNumber": 2006,
                                                            "parentRowNumber": 871,
                                                            "articleId": "47229884",
                                                            "description": "47229884-00-Deckel",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 9.6815,
                                                            "childrenWeight": 9.6815,
                                                            "totalQuantityWeight": 9.6815,
                                                            "singleQuantityMcf": 32.9171,
                                                            "mcf": 32.9171,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 32.9171,
                                                            "expandable": false,
                                                            "children": [
                                                                {
                                                                    "rowNumber": 2007,
                                                                    "parentRowNumber": 2006,
                                                                    "articleId": "165936",
                                                                    "description": "165936-00-Platte",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 9.6815,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 9.6815,
                                                                    "singleQuantityMcf": 32.9171,
                                                                    "mcf": 32.9171,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 32.9171,
                                                                    "expandable": false,
                                                                    "children": []
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            "rowNumber": 2008,
                                                            "parentRowNumber": 871,
                                                            "articleId": "47230106",
                                                            "description": "47230106-00-Schutz",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.1167,
                                                            "childrenWeight": 0.1167,
                                                            "totalQuantityWeight": 0.1167,
                                                            "singleQuantityMcf": 0.38454,
                                                            "mcf": 0.38454,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.38454,
                                                            "expandable": false,
                                                            "children": [
                                                                {
                                                                    "rowNumber": 2009,
                                                                    "parentRowNumber": 2008,
                                                                    "articleId": "165936",
                                                                    "description": "165936-00-Platte",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.1131,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.1131,
                                                                    "singleQuantityMcf": 0.38454,
                                                                    "mcf": 0.38454,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.38454,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2010,
                                                                    "parentRowNumber": 2008,
                                                                    "articleId": "347201",
                                                                    "description": "347201-00-Ensat-Gewindebuchse",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0018,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0018,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2011,
                                                                    "parentRowNumber": 2008,
                                                                    "articleId": "347201",
                                                                    "description": "347201-00-Ensat-Gewindebuchse",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0018,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0018,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": []
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            "rowNumber": 2012,
                                                            "parentRowNumber": 871,
                                                            "articleId": "47230268",
                                                            "description": "47230268-00-Blech",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0504,
                                                            "childrenWeight": 0.0504,
                                                            "totalQuantityWeight": 0.0504,
                                                            "singleQuantityMcf": 0.261072,
                                                            "mcf": 0.261072,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.261072,
                                                            "expandable": false,
                                                            "children": [
                                                                {
                                                                    "rowNumber": 2013,
                                                                    "parentRowNumber": 2012,
                                                                    "articleId": "142608",
                                                                    "description": "142608-00-Edelstahlblech",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0504,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0504,
                                                                    "singleQuantityMcf": 0.261072,
                                                                    "mcf": 0.261072,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.261072,
                                                                    "expandable": false,
                                                                    "children": []
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            "rowNumber": 2014,
                                                            "parentRowNumber": 871,
                                                            "articleId": "47230268",
                                                            "description": "47230268-00-Blech",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0504,
                                                            "childrenWeight": 0.0504,
                                                            "totalQuantityWeight": 0.0504,
                                                            "singleQuantityMcf": 0.261072,
                                                            "mcf": 0.261072,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.261072,
                                                            "expandable": false,
                                                            "children": [
                                                                {
                                                                    "rowNumber": 2015,
                                                                    "parentRowNumber": 2014,
                                                                    "articleId": "142608",
                                                                    "description": "142608-00-Edelstahlblech",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0504,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0504,
                                                                    "singleQuantityMcf": 0.261072,
                                                                    "mcf": 0.261072,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.261072,
                                                                    "expandable": false,
                                                                    "children": []
                                                                }
                                                            ]
                                                        }
                                                    ]
                                                }
                                            ]
                                        }
                                    ]
                                },
                                {
                                    "rowNumber": 2016,
                                    "parentRowNumber": 1,
                                    "articleId": "33847",
                                    "description": "33847-00UA-00-Schnittstelle",
                                    "hierarchy": 3,
                                    "quantity": 1,
                                    "weight": 0.1138,
                                    "childrenWeight": 0.1138,
                                    "totalQuantityWeight": 0.1138,
                                    "singleQuantityMcf": 0,
                                    "mcf": 0,
                                    "singleQuantityLcf": 0,
                                    "lcf": 0,
                                    "totalQuantityMcf": 0,
                                    "expandable": false,
                                    "children": [
                                        {
                                            "rowNumber": 2017,
                                            "parentRowNumber": 2016,
                                            "articleId": "33847",
                                            "description": "33847-00UA30-00-Schnittstelle",
                                            "hierarchy": 4,
                                            "quantity": 1,
                                            "weight": 0.1138,
                                            "childrenWeight": 0.1138,
                                            "totalQuantityWeight": 0.1138,
                                            "singleQuantityMcf": 0,
                                            "mcf": 0,
                                            "singleQuantityLcf": 0,
                                            "lcf": 0,
                                            "totalQuantityMcf": 0,
                                            "expandable": false,
                                            "children": [
                                                {
                                                    "rowNumber": 2018,
                                                    "parentRowNumber": 2017,
                                                    "articleId": "MM0000007077",
                                                    "description": "MM0000007077-00-Schnittstelle",
                                                    "hierarchy": 5,
                                                    "quantity": 1,
                                                    "weight": 0.1138,
                                                    "childrenWeight": 0.1138,
                                                    "totalQuantityWeight": 0.1138,
                                                    "singleQuantityMcf": 0,
                                                    "mcf": 0,
                                                    "singleQuantityLcf": 0,
                                                    "lcf": 0,
                                                    "totalQuantityMcf": 0,
                                                    "expandable": false,
                                                    "children": [
                                                        {
                                                            "rowNumber": 2019,
                                                            "parentRowNumber": 2018,
                                                            "articleId": "58699221",
                                                            "description": "58699221-02-RJ45 Einbaumodul",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.1138,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.1138,
                                                            "singleQuantityMcf": 0,
                                                            "mcf": 0,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0,
                                                            "expandable": false,
                                                            "children": []
                                                        }
                                                    ]
                                                }
                                            ]
                                        }
                                    ]
                                }
                            ]
                        },
                        {
                            "rowNumber": 2020,
                            "parentRowNumber": 0,
                            "articleId": "33847",
                            "description": "33847-20-00-Einlauf",
                            "hierarchy": 2,
                            "quantity": 1,
                            "weight": 560.6635999999995,
                            "childrenWeight": 560.6635999999995,
                            "totalQuantityWeight": 560.6635999999995,
                            "singleQuantityMcf": 1554.175615490005,
                            "mcf": 1554.175615490005,
                            "singleQuantityLcf": 0,
                            "lcf": 0,
                            "totalQuantityMcf": 1554.175615490005,
                            "expandable": false,
                            "children": [
                                {
                                    "rowNumber": 2021,
                                    "parentRowNumber": 2020,
                                    "articleId": "33847",
                                    "description": "33847-20BA-00-Aufgabeplatte",
                                    "hierarchy": 3,
                                    "quantity": 1,
                                    "weight": 47.399400000000014,
                                    "childrenWeight": 47.399400000000014,
                                    "totalQuantityWeight": 47.399400000000014,
                                    "singleQuantityMcf": 80.16823200000007,
                                    "mcf": 80.16823200000007,
                                    "singleQuantityLcf": 0,
                                    "lcf": 0,
                                    "totalQuantityMcf": 80.16823200000007,
                                    "expandable": false,
                                    "children": [
                                        {
                                            "rowNumber": 2022,
                                            "parentRowNumber": 2021,
                                            "articleId": "33847",
                                            "description": "33847-20BA10-00-Grundeinheit",
                                            "hierarchy": 4,
                                            "quantity": 1,
                                            "weight": 17.76490000000001,
                                            "childrenWeight": 17.76490000000001,
                                            "totalQuantityWeight": 17.76490000000001,
                                            "singleQuantityMcf": 35.28653000000008,
                                            "mcf": 35.28653000000008,
                                            "singleQuantityLcf": 0,
                                            "lcf": 0,
                                            "totalQuantityMcf": 35.28653000000008,
                                            "expandable": false,
                                            "children": [
                                                {
                                                    "rowNumber": 2023,
                                                    "parentRowNumber": 2022,
                                                    "articleId": "MM0000009441",
                                                    "description": "MM0000009441-00-Grundeinheit",
                                                    "hierarchy": 5,
                                                    "quantity": 1,
                                                    "weight": 17.76490000000001,
                                                    "childrenWeight": 17.76490000000001,
                                                    "totalQuantityWeight": 17.76490000000001,
                                                    "singleQuantityMcf": 35.28653000000008,
                                                    "mcf": 35.28653000000008,
                                                    "singleQuantityLcf": 0,
                                                    "lcf": 0,
                                                    "totalQuantityMcf": 35.28653000000008,
                                                    "expandable": false,
                                                    "children": [
                                                        {
                                                            "rowNumber": 2024,
                                                            "parentRowNumber": 2023,
                                                            "articleId": "47232285",
                                                            "description": "47232285-00-Aufgabeplatte",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 17.76490000000001,
                                                            "childrenWeight": 17.76490000000001,
                                                            "totalQuantityWeight": 17.76490000000001,
                                                            "singleQuantityMcf": 35.28653000000008,
                                                            "mcf": 35.28653000000008,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 35.28653000000008,
                                                            "expandable": false,
                                                            "children": [
                                                                {
                                                                    "rowNumber": 2025,
                                                                    "parentRowNumber": 2024,
                                                                    "articleId": "20120512",
                                                                    "description": "20120512-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0033,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0033,
                                                                    "singleQuantityMcf": 0.017093999999999998,
                                                                    "mcf": 0.017093999999999998,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.017093999999999998,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2026,
                                                                    "parentRowNumber": 2024,
                                                                    "articleId": "20120512",
                                                                    "description": "20120512-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0033,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0033,
                                                                    "singleQuantityMcf": 0.017093999999999998,
                                                                    "mcf": 0.017093999999999998,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.017093999999999998,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2027,
                                                                    "parentRowNumber": 2024,
                                                                    "articleId": "20120612",
                                                                    "description": "20120612-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0053,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0053,
                                                                    "singleQuantityMcf": 0.027454,
                                                                    "mcf": 0.027454,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.027454,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2028,
                                                                    "parentRowNumber": 2024,
                                                                    "articleId": "20120612",
                                                                    "description": "20120612-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0053,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0053,
                                                                    "singleQuantityMcf": 0.027454,
                                                                    "mcf": 0.027454,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.027454,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2029,
                                                                    "parentRowNumber": 2024,
                                                                    "articleId": "20120615",
                                                                    "description": "20120615-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0061,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0061,
                                                                    "singleQuantityMcf": 0.031598,
                                                                    "mcf": 0.031598,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.031598,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2030,
                                                                    "parentRowNumber": 2024,
                                                                    "articleId": "20120625",
                                                                    "description": "20120625-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0083,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0083,
                                                                    "singleQuantityMcf": 0.042994,
                                                                    "mcf": 0.042994,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.042994,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2031,
                                                                    "parentRowNumber": 2024,
                                                                    "articleId": "20120625",
                                                                    "description": "20120625-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0083,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0083,
                                                                    "singleQuantityMcf": 0.042994,
                                                                    "mcf": 0.042994,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.042994,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2032,
                                                                    "parentRowNumber": 2024,
                                                                    "articleId": "20120625",
                                                                    "description": "20120625-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0083,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0083,
                                                                    "singleQuantityMcf": 0.042994,
                                                                    "mcf": 0.042994,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.042994,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2033,
                                                                    "parentRowNumber": 2024,
                                                                    "articleId": "20120625",
                                                                    "description": "20120625-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0083,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0083,
                                                                    "singleQuantityMcf": 0.042994,
                                                                    "mcf": 0.042994,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.042994,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2034,
                                                                    "parentRowNumber": 2024,
                                                                    "articleId": "20120625",
                                                                    "description": "20120625-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0083,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0083,
                                                                    "singleQuantityMcf": 0.042994,
                                                                    "mcf": 0.042994,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.042994,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2035,
                                                                    "parentRowNumber": 2024,
                                                                    "articleId": "20120625",
                                                                    "description": "20120625-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0083,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0083,
                                                                    "singleQuantityMcf": 0.042994,
                                                                    "mcf": 0.042994,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.042994,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2036,
                                                                    "parentRowNumber": 2024,
                                                                    "articleId": "20120625",
                                                                    "description": "20120625-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0083,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0083,
                                                                    "singleQuantityMcf": 0.042994,
                                                                    "mcf": 0.042994,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.042994,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2037,
                                                                    "parentRowNumber": 2024,
                                                                    "articleId": "20120625",
                                                                    "description": "20120625-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0083,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0083,
                                                                    "singleQuantityMcf": 0.042994,
                                                                    "mcf": 0.042994,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.042994,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2038,
                                                                    "parentRowNumber": 2024,
                                                                    "articleId": "20120625",
                                                                    "description": "20120625-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0083,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0083,
                                                                    "singleQuantityMcf": 0.042994,
                                                                    "mcf": 0.042994,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.042994,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2039,
                                                                    "parentRowNumber": 2024,
                                                                    "articleId": "20120625",
                                                                    "description": "20120625-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0083,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0083,
                                                                    "singleQuantityMcf": 0.042994,
                                                                    "mcf": 0.042994,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.042994,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2040,
                                                                    "parentRowNumber": 2024,
                                                                    "articleId": "20120830",
                                                                    "description": "20120830-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0181,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0181,
                                                                    "singleQuantityMcf": 0.093758,
                                                                    "mcf": 0.093758,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.093758,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2041,
                                                                    "parentRowNumber": 2024,
                                                                    "articleId": "20120830",
                                                                    "description": "20120830-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0181,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0181,
                                                                    "singleQuantityMcf": 0.093758,
                                                                    "mcf": 0.093758,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.093758,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2042,
                                                                    "parentRowNumber": 2024,
                                                                    "articleId": "20120830",
                                                                    "description": "20120830-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0181,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0181,
                                                                    "singleQuantityMcf": 0.093758,
                                                                    "mcf": 0.093758,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.093758,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2043,
                                                                    "parentRowNumber": 2024,
                                                                    "articleId": "20120830",
                                                                    "description": "20120830-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0181,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0181,
                                                                    "singleQuantityMcf": 0.093758,
                                                                    "mcf": 0.093758,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.093758,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2044,
                                                                    "parentRowNumber": 2024,
                                                                    "articleId": "208206",
                                                                    "description": "208206-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0009,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0009,
                                                                    "singleQuantityMcf": 0.004662,
                                                                    "mcf": 0.004662,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.004662,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2045,
                                                                    "parentRowNumber": 2024,
                                                                    "articleId": "208206",
                                                                    "description": "208206-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0009,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0009,
                                                                    "singleQuantityMcf": 0.004662,
                                                                    "mcf": 0.004662,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.004662,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2046,
                                                                    "parentRowNumber": 2024,
                                                                    "articleId": "208206",
                                                                    "description": "208206-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0009,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0009,
                                                                    "singleQuantityMcf": 0.004662,
                                                                    "mcf": 0.004662,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.004662,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2047,
                                                                    "parentRowNumber": 2024,
                                                                    "articleId": "208206",
                                                                    "description": "208206-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0009,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0009,
                                                                    "singleQuantityMcf": 0.004662,
                                                                    "mcf": 0.004662,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.004662,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2048,
                                                                    "parentRowNumber": 2024,
                                                                    "articleId": "208206",
                                                                    "description": "208206-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0009,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0009,
                                                                    "singleQuantityMcf": 0.004662,
                                                                    "mcf": 0.004662,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.004662,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2049,
                                                                    "parentRowNumber": 2024,
                                                                    "articleId": "208206",
                                                                    "description": "208206-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0009,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0009,
                                                                    "singleQuantityMcf": 0.004662,
                                                                    "mcf": 0.004662,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.004662,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2050,
                                                                    "parentRowNumber": 2024,
                                                                    "articleId": "208206",
                                                                    "description": "208206-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0009,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0009,
                                                                    "singleQuantityMcf": 0.004662,
                                                                    "mcf": 0.004662,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.004662,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2051,
                                                                    "parentRowNumber": 2024,
                                                                    "articleId": "208206",
                                                                    "description": "208206-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0009,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0009,
                                                                    "singleQuantityMcf": 0.004662,
                                                                    "mcf": 0.004662,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.004662,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2052,
                                                                    "parentRowNumber": 2024,
                                                                    "articleId": "208206",
                                                                    "description": "208206-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0009,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0009,
                                                                    "singleQuantityMcf": 0.004662,
                                                                    "mcf": 0.004662,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.004662,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2053,
                                                                    "parentRowNumber": 2024,
                                                                    "articleId": "208206",
                                                                    "description": "208206-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0009,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0009,
                                                                    "singleQuantityMcf": 0.004662,
                                                                    "mcf": 0.004662,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.004662,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2054,
                                                                    "parentRowNumber": 2024,
                                                                    "articleId": "208206",
                                                                    "description": "208206-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0009,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0009,
                                                                    "singleQuantityMcf": 0.004662,
                                                                    "mcf": 0.004662,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.004662,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2055,
                                                                    "parentRowNumber": 2024,
                                                                    "articleId": "208206",
                                                                    "description": "208206-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0009,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0009,
                                                                    "singleQuantityMcf": 0.004662,
                                                                    "mcf": 0.004662,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.004662,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2056,
                                                                    "parentRowNumber": 2024,
                                                                    "articleId": "208206",
                                                                    "description": "208206-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0009,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0009,
                                                                    "singleQuantityMcf": 0.004662,
                                                                    "mcf": 0.004662,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.004662,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2057,
                                                                    "parentRowNumber": 2024,
                                                                    "articleId": "208206",
                                                                    "description": "208206-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0009,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0009,
                                                                    "singleQuantityMcf": 0.004662,
                                                                    "mcf": 0.004662,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.004662,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2058,
                                                                    "parentRowNumber": 2024,
                                                                    "articleId": "208206",
                                                                    "description": "208206-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0009,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0009,
                                                                    "singleQuantityMcf": 0.004662,
                                                                    "mcf": 0.004662,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.004662,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2059,
                                                                    "parentRowNumber": 2024,
                                                                    "articleId": "208206",
                                                                    "description": "208206-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0009,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0009,
                                                                    "singleQuantityMcf": 0.004662,
                                                                    "mcf": 0.004662,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.004662,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2060,
                                                                    "parentRowNumber": 2024,
                                                                    "articleId": "208206",
                                                                    "description": "208206-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0009,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0009,
                                                                    "singleQuantityMcf": 0.004662,
                                                                    "mcf": 0.004662,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.004662,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2061,
                                                                    "parentRowNumber": 2024,
                                                                    "articleId": "208206",
                                                                    "description": "208206-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0009,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0009,
                                                                    "singleQuantityMcf": 0.004662,
                                                                    "mcf": 0.004662,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.004662,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2062,
                                                                    "parentRowNumber": 2024,
                                                                    "articleId": "208206",
                                                                    "description": "208206-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0009,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0009,
                                                                    "singleQuantityMcf": 0.004662,
                                                                    "mcf": 0.004662,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.004662,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2063,
                                                                    "parentRowNumber": 2024,
                                                                    "articleId": "208405",
                                                                    "description": "208405-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0015,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0015,
                                                                    "singleQuantityMcf": 0.00777,
                                                                    "mcf": 0.00777,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.00777,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2064,
                                                                    "parentRowNumber": 2024,
                                                                    "articleId": "208405",
                                                                    "description": "208405-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0015,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0015,
                                                                    "singleQuantityMcf": 0.00777,
                                                                    "mcf": 0.00777,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.00777,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2065,
                                                                    "parentRowNumber": 2024,
                                                                    "articleId": "208408",
                                                                    "description": "208408-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.007,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.007,
                                                                    "singleQuantityMcf": 0.03626,
                                                                    "mcf": 0.03626,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.03626,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2066,
                                                                    "parentRowNumber": 2024,
                                                                    "articleId": "208408",
                                                                    "description": "208408-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.007,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.007,
                                                                    "singleQuantityMcf": 0.03626,
                                                                    "mcf": 0.03626,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.03626,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2067,
                                                                    "parentRowNumber": 2024,
                                                                    "articleId": "208408",
                                                                    "description": "208408-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.007,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.007,
                                                                    "singleQuantityMcf": 0.03626,
                                                                    "mcf": 0.03626,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.03626,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2068,
                                                                    "parentRowNumber": 2024,
                                                                    "articleId": "208408",
                                                                    "description": "208408-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.007,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.007,
                                                                    "singleQuantityMcf": 0.03626,
                                                                    "mcf": 0.03626,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.03626,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2069,
                                                                    "parentRowNumber": 2024,
                                                                    "articleId": "22070610",
                                                                    "description": "22070610-00-Fluegelschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0237,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0237,
                                                                    "singleQuantityMcf": 0.041001,
                                                                    "mcf": 0.041001,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.041001,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2070,
                                                                    "parentRowNumber": 2024,
                                                                    "articleId": "22070610",
                                                                    "description": "22070610-00-Fluegelschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0237,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0237,
                                                                    "singleQuantityMcf": 0.041001,
                                                                    "mcf": 0.041001,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.041001,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2071,
                                                                    "parentRowNumber": 2024,
                                                                    "articleId": "221406",
                                                                    "description": "221406-00-Hutmutter",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0044,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0044,
                                                                    "singleQuantityMcf": 0.022792,
                                                                    "mcf": 0.022792,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.022792,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2072,
                                                                    "parentRowNumber": 2024,
                                                                    "articleId": "221406",
                                                                    "description": "221406-00-Hutmutter",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0044,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0044,
                                                                    "singleQuantityMcf": 0.022792,
                                                                    "mcf": 0.022792,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.022792,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2073,
                                                                    "parentRowNumber": 2024,
                                                                    "articleId": "221406",
                                                                    "description": "221406-00-Hutmutter",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0044,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0044,
                                                                    "singleQuantityMcf": 0.022792,
                                                                    "mcf": 0.022792,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.022792,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2074,
                                                                    "parentRowNumber": 2024,
                                                                    "articleId": "221406",
                                                                    "description": "221406-00-Hutmutter",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0044,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0044,
                                                                    "singleQuantityMcf": 0.022792,
                                                                    "mcf": 0.022792,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.022792,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2075,
                                                                    "parentRowNumber": 2024,
                                                                    "articleId": "221406",
                                                                    "description": "221406-00-Hutmutter",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0044,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0044,
                                                                    "singleQuantityMcf": 0.022792,
                                                                    "mcf": 0.022792,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.022792,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2076,
                                                                    "parentRowNumber": 2024,
                                                                    "articleId": "221406",
                                                                    "description": "221406-00-Hutmutter",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0044,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0044,
                                                                    "singleQuantityMcf": 0.022792,
                                                                    "mcf": 0.022792,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.022792,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2077,
                                                                    "parentRowNumber": 2024,
                                                                    "articleId": "221406",
                                                                    "description": "221406-00-Hutmutter",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0044,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0044,
                                                                    "singleQuantityMcf": 0.022792,
                                                                    "mcf": 0.022792,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.022792,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2078,
                                                                    "parentRowNumber": 2024,
                                                                    "articleId": "22420630",
                                                                    "description": "22420630-00-Passscheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0007,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0007,
                                                                    "singleQuantityMcf": 0.003626,
                                                                    "mcf": 0.003626,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.003626,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2079,
                                                                    "parentRowNumber": 2024,
                                                                    "articleId": "278216",
                                                                    "description": "278216-00-Linearkugellager",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0522,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0522,
                                                                    "singleQuantityMcf": 0.270396,
                                                                    "mcf": 0.270396,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.270396,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2080,
                                                                    "parentRowNumber": 2024,
                                                                    "articleId": "278216",
                                                                    "description": "278216-00-Linearkugellager",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0522,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0522,
                                                                    "singleQuantityMcf": 0.270396,
                                                                    "mcf": 0.270396,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.270396,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2081,
                                                                    "parentRowNumber": 2024,
                                                                    "articleId": "2921626",
                                                                    "description": "2921626-00-Radial-Rillenkugellager",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0107,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0107,
                                                                    "singleQuantityMcf": 0.055426,
                                                                    "mcf": 0.055426,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.055426,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2082,
                                                                    "parentRowNumber": 2024,
                                                                    "articleId": "46000053",
                                                                    "description": "46000053-02-Fuehrung",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.4614,
                                                                    "childrenWeight": 0.4614,
                                                                    "totalQuantityWeight": 0.4614,
                                                                    "singleQuantityMcf": 2.390052,
                                                                    "mcf": 2.390052,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 2.390052,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 2083,
                                                                            "parentRowNumber": 2082,
                                                                            "articleId": "46000031",
                                                                            "description": "46000031-02-Buchse",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.2624,
                                                                            "childrenWeight": 0.2624,
                                                                            "totalQuantityWeight": 0.2624,
                                                                            "singleQuantityMcf": 1.359232,
                                                                            "mcf": 1.359232,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 1.359232,
                                                                            "expandable": false,
                                                                            "children": [
                                                                                {
                                                                                    "rowNumber": 2084,
                                                                                    "parentRowNumber": 2083,
                                                                                    "articleId": "1260035",
                                                                                    "description": "1260035-00-Rundstahl",
                                                                                    "hierarchy": 9,
                                                                                    "quantity": 1,
                                                                                    "weight": 0.2624,
                                                                                    "childrenWeight": 0,
                                                                                    "totalQuantityWeight": 0.2624,
                                                                                    "singleQuantityMcf": 1.359232,
                                                                                    "mcf": 1.359232,
                                                                                    "singleQuantityLcf": 0,
                                                                                    "lcf": 0,
                                                                                    "totalQuantityMcf": 1.359232,
                                                                                    "expandable": false,
                                                                                    "children": []
                                                                                }
                                                                            ]
                                                                        },
                                                                        {
                                                                            "rowNumber": 2085,
                                                                            "parentRowNumber": 2082,
                                                                            "articleId": "46000032",
                                                                            "description": "46000032-01-Halter",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.199,
                                                                            "childrenWeight": 0.199,
                                                                            "totalQuantityWeight": 0.199,
                                                                            "singleQuantityMcf": 1.03082,
                                                                            "mcf": 1.03082,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 1.03082,
                                                                            "expandable": false,
                                                                            "children": [
                                                                                {
                                                                                    "rowNumber": 2086,
                                                                                    "parentRowNumber": 2085,
                                                                                    "articleId": "142613",
                                                                                    "description": "142613-00-Edelstahlblech",
                                                                                    "hierarchy": 9,
                                                                                    "quantity": 1,
                                                                                    "weight": 0.199,
                                                                                    "childrenWeight": 0,
                                                                                    "totalQuantityWeight": 0.199,
                                                                                    "singleQuantityMcf": 1.03082,
                                                                                    "mcf": 1.03082,
                                                                                    "singleQuantityLcf": 0,
                                                                                    "lcf": 0,
                                                                                    "totalQuantityMcf": 1.03082,
                                                                                    "expandable": false,
                                                                                    "children": []
                                                                                }
                                                                            ]
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 2087,
                                                                    "parentRowNumber": 2024,
                                                                    "articleId": "46039391",
                                                                    "description": "46039391-00-Gummi",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0007,
                                                                    "childrenWeight": 0.0007,
                                                                    "totalQuantityWeight": 0.0007,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 2088,
                                                                            "parentRowNumber": 2087,
                                                                            "articleId": "163203",
                                                                            "description": "163203-01-Gummiplatte",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0007,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0007,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 2089,
                                                                    "parentRowNumber": 2024,
                                                                    "articleId": "46039391",
                                                                    "description": "46039391-00-Gummi",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0007,
                                                                    "childrenWeight": 0.0007,
                                                                    "totalQuantityWeight": 0.0007,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 2090,
                                                                            "parentRowNumber": 2089,
                                                                            "articleId": "163203",
                                                                            "description": "163203-01-Gummiplatte",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0007,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0007,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 2091,
                                                                    "parentRowNumber": 2024,
                                                                    "articleId": "46060177",
                                                                    "description": "46060177-00-Welle",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.8742,
                                                                    "childrenWeight": 0.8742,
                                                                    "totalQuantityWeight": 0.8742,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 2092,
                                                                            "parentRowNumber": 2091,
                                                                            "articleId": "1219016",
                                                                            "description": "1219016-00-Rundstahl",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.8742,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.8742,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 2093,
                                                                    "parentRowNumber": 2024,
                                                                    "articleId": "465077",
                                                                    "description": "465077-00-Leiste",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.2746,
                                                                    "childrenWeight": 0.2746,
                                                                    "totalQuantityWeight": 0.2746,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 2094,
                                                                            "parentRowNumber": 2093,
                                                                            "articleId": "119225",
                                                                            "description": "119225-00-Flachstange",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.2746,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.2746,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 2095,
                                                                    "parentRowNumber": 2024,
                                                                    "articleId": "465077",
                                                                    "description": "465077-00-Leiste",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.2746,
                                                                    "childrenWeight": 0.2746,
                                                                    "totalQuantityWeight": 0.2746,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 2096,
                                                                            "parentRowNumber": 2095,
                                                                            "articleId": "119225",
                                                                            "description": "119225-00-Flachstange",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.2746,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.2746,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 2097,
                                                                    "parentRowNumber": 2024,
                                                                    "articleId": "46894408",
                                                                    "description": "46894408-00-Anschlag",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 1.0414,
                                                                    "childrenWeight": 1.0414,
                                                                    "totalQuantityWeight": 1.0414,
                                                                    "singleQuantityMcf": 5.394452,
                                                                    "mcf": 5.394452,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 5.394452,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 2098,
                                                                            "parentRowNumber": 2097,
                                                                            "articleId": "142607",
                                                                            "description": "142607-00-Edelstahlblech",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 1.0414,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 1.0414,
                                                                            "singleQuantityMcf": 5.394452,
                                                                            "mcf": 5.394452,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 5.394452,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 2099,
                                                                    "parentRowNumber": 2024,
                                                                    "articleId": "47133726",
                                                                    "description": "47133726-00-Einlaufkasten",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 3.9829,
                                                                    "childrenWeight": 3.9829,
                                                                    "totalQuantityWeight": 3.9829,
                                                                    "singleQuantityMcf": 20.631422,
                                                                    "mcf": 20.631422,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 20.631422,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 2100,
                                                                            "parentRowNumber": 2099,
                                                                            "articleId": "142603",
                                                                            "description": "142603-00-Edelstahlblech",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 3.9649,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 3.9649,
                                                                            "singleQuantityMcf": 20.538182,
                                                                            "mcf": 20.538182,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 20.538182,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        },
                                                                        {
                                                                            "rowNumber": 2101,
                                                                            "parentRowNumber": 2099,
                                                                            "articleId": "21320620",
                                                                            "description": "21320620-01-Gewindestift",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0045,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0045,
                                                                            "singleQuantityMcf": 0.02331,
                                                                            "mcf": 0.02331,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.02331,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        },
                                                                        {
                                                                            "rowNumber": 2102,
                                                                            "parentRowNumber": 2099,
                                                                            "articleId": "21320620",
                                                                            "description": "21320620-01-Gewindestift",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0045,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0045,
                                                                            "singleQuantityMcf": 0.02331,
                                                                            "mcf": 0.02331,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.02331,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        },
                                                                        {
                                                                            "rowNumber": 2103,
                                                                            "parentRowNumber": 2099,
                                                                            "articleId": "21320620",
                                                                            "description": "21320620-01-Gewindestift",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0045,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0045,
                                                                            "singleQuantityMcf": 0.02331,
                                                                            "mcf": 0.02331,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.02331,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        },
                                                                        {
                                                                            "rowNumber": 2104,
                                                                            "parentRowNumber": 2099,
                                                                            "articleId": "21320620",
                                                                            "description": "21320620-01-Gewindestift",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0045,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0045,
                                                                            "singleQuantityMcf": 0.02331,
                                                                            "mcf": 0.02331,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.02331,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 2105,
                                                                    "parentRowNumber": 2024,
                                                                    "articleId": "47138114",
                                                                    "description": "47138114-00-Lagerklotz",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.2271,
                                                                    "childrenWeight": 0.2271,
                                                                    "totalQuantityWeight": 0.2271,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 2106,
                                                                            "parentRowNumber": 2105,
                                                                            "articleId": "141718",
                                                                            "description": "141718-00-Platte",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.2271,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.2271,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 2107,
                                                                    "parentRowNumber": 2024,
                                                                    "articleId": "47138114",
                                                                    "description": "47138114-00-Lagerklotz",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.2271,
                                                                    "childrenWeight": 0.2271,
                                                                    "totalQuantityWeight": 0.2271,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 2108,
                                                                            "parentRowNumber": 2107,
                                                                            "articleId": "141718",
                                                                            "description": "141718-00-Platte",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.2271,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.2271,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 2109,
                                                                    "parentRowNumber": 2024,
                                                                    "articleId": "47232542",
                                                                    "description": "47232542-00-Halter",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.4425,
                                                                    "childrenWeight": 0.4425,
                                                                    "totalQuantityWeight": 0.4425,
                                                                    "singleQuantityMcf": 2.2921499999999995,
                                                                    "mcf": 2.2921499999999995,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 2.2921499999999995,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 2110,
                                                                            "parentRowNumber": 2109,
                                                                            "articleId": "21320512",
                                                                            "description": "21320512-01-Gewindestift",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0019,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0019,
                                                                            "singleQuantityMcf": 0.009842,
                                                                            "mcf": 0.009842,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.009842,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        },
                                                                        {
                                                                            "rowNumber": 2111,
                                                                            "parentRowNumber": 2109,
                                                                            "articleId": "21320512",
                                                                            "description": "21320512-01-Gewindestift",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0019,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0019,
                                                                            "singleQuantityMcf": 0.009842,
                                                                            "mcf": 0.009842,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.009842,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        },
                                                                        {
                                                                            "rowNumber": 2112,
                                                                            "parentRowNumber": 2109,
                                                                            "articleId": "46244999",
                                                                            "description": "46244999-00-Bolzen",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0278,
                                                                            "childrenWeight": 0.0278,
                                                                            "totalQuantityWeight": 0.0278,
                                                                            "singleQuantityMcf": 0.144004,
                                                                            "mcf": 0.144004,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.144004,
                                                                            "expandable": false,
                                                                            "children": [
                                                                                {
                                                                                    "rowNumber": 2113,
                                                                                    "parentRowNumber": 2112,
                                                                                    "articleId": "1224015",
                                                                                    "description": "1224015-00-Rundstahl",
                                                                                    "hierarchy": 9,
                                                                                    "quantity": 1,
                                                                                    "weight": 0.0278,
                                                                                    "childrenWeight": 0,
                                                                                    "totalQuantityWeight": 0.0278,
                                                                                    "singleQuantityMcf": 0.144004,
                                                                                    "mcf": 0.144004,
                                                                                    "singleQuantityLcf": 0,
                                                                                    "lcf": 0,
                                                                                    "totalQuantityMcf": 0.144004,
                                                                                    "expandable": false,
                                                                                    "children": []
                                                                                }
                                                                            ]
                                                                        },
                                                                        {
                                                                            "rowNumber": 2114,
                                                                            "parentRowNumber": 2109,
                                                                            "articleId": "46244999",
                                                                            "description": "46244999-00-Bolzen",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0278,
                                                                            "childrenWeight": 0.0278,
                                                                            "totalQuantityWeight": 0.0278,
                                                                            "singleQuantityMcf": 0.144004,
                                                                            "mcf": 0.144004,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.144004,
                                                                            "expandable": false,
                                                                            "children": [
                                                                                {
                                                                                    "rowNumber": 2115,
                                                                                    "parentRowNumber": 2114,
                                                                                    "articleId": "1224015",
                                                                                    "description": "1224015-00-Rundstahl",
                                                                                    "hierarchy": 9,
                                                                                    "quantity": 1,
                                                                                    "weight": 0.0278,
                                                                                    "childrenWeight": 0,
                                                                                    "totalQuantityWeight": 0.0278,
                                                                                    "singleQuantityMcf": 0.144004,
                                                                                    "mcf": 0.144004,
                                                                                    "singleQuantityLcf": 0,
                                                                                    "lcf": 0,
                                                                                    "totalQuantityMcf": 0.144004,
                                                                                    "expandable": false,
                                                                                    "children": []
                                                                                }
                                                                            ]
                                                                        },
                                                                        {
                                                                            "rowNumber": 2116,
                                                                            "parentRowNumber": 2109,
                                                                            "articleId": "47232229",
                                                                            "description": "47232229-00-Einschieber",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.3831,
                                                                            "childrenWeight": 0.3831,
                                                                            "totalQuantityWeight": 0.3831,
                                                                            "singleQuantityMcf": 1.984458,
                                                                            "mcf": 1.984458,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 1.984458,
                                                                            "expandable": false,
                                                                            "children": [
                                                                                {
                                                                                    "rowNumber": 2117,
                                                                                    "parentRowNumber": 2116,
                                                                                    "articleId": "142604",
                                                                                    "description": "142604-00-Edelstahlblech",
                                                                                    "hierarchy": 9,
                                                                                    "quantity": 1,
                                                                                    "weight": 0.3831,
                                                                                    "childrenWeight": 0,
                                                                                    "totalQuantityWeight": 0.3831,
                                                                                    "singleQuantityMcf": 1.984458,
                                                                                    "mcf": 1.984458,
                                                                                    "singleQuantityLcf": 0,
                                                                                    "lcf": 0,
                                                                                    "totalQuantityMcf": 1.984458,
                                                                                    "expandable": false,
                                                                                    "children": []
                                                                                }
                                                                            ]
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 2118,
                                                                    "parentRowNumber": 2024,
                                                                    "articleId": "47232920",
                                                                    "description": "47232920-00-Platte",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 4.364,
                                                                    "childrenWeight": 4.364,
                                                                    "totalQuantityWeight": 4.364,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 2119,
                                                                            "parentRowNumber": 2118,
                                                                            "articleId": "141718",
                                                                            "description": "141718-00-Platte",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 4.364,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 4.364,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 2120,
                                                                    "parentRowNumber": 2024,
                                                                    "articleId": "47232921",
                                                                    "description": "47232921-00-Platte",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 4.3656,
                                                                    "childrenWeight": 4.3656,
                                                                    "totalQuantityWeight": 4.3656,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 2121,
                                                                            "parentRowNumber": 2120,
                                                                            "articleId": "141718",
                                                                            "description": "141718-00-Platte",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 4.3656,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 4.3656,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 2122,
                                                                    "parentRowNumber": 2024,
                                                                    "articleId": "47233091",
                                                                    "description": "47233091-00-Distanzplatte",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.8073000000000001,
                                                                    "childrenWeight": 0.8073000000000001,
                                                                    "totalQuantityWeight": 0.8073000000000001,
                                                                    "singleQuantityMcf": 2.5622400000000005,
                                                                    "mcf": 2.5622400000000005,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 2.5622400000000005,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 2123,
                                                                            "parentRowNumber": 2122,
                                                                            "articleId": "162538",
                                                                            "description": "162538-01-Platte",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.8007000000000001,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.8007000000000001,
                                                                            "singleQuantityMcf": 2.5622400000000005,
                                                                            "mcf": 2.5622400000000005,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 2.5622400000000005,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        },
                                                                        {
                                                                            "rowNumber": 2124,
                                                                            "parentRowNumber": 2122,
                                                                            "articleId": "347206",
                                                                            "description": "347206-00-Ensat-Gewindebuchse",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0033,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0033,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        },
                                                                        {
                                                                            "rowNumber": 2125,
                                                                            "parentRowNumber": 2122,
                                                                            "articleId": "347206",
                                                                            "description": "347206-00-Ensat-Gewindebuchse",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0033,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0033,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                }
                                                            ]
                                                        }
                                                    ]
                                                }
                                            ]
                                        },
                                        {
                                            "rowNumber": 2126,
                                            "parentRowNumber": 2021,
                                            "articleId": "33847",
                                            "description": "33847-20BAF1-00-Objektführung",
                                            "hierarchy": 4,
                                            "quantity": 1,
                                            "weight": 6.168499999999999,
                                            "childrenWeight": 6.168499999999999,
                                            "totalQuantityWeight": 6.168499999999999,
                                            "singleQuantityMcf": 13.882814,
                                            "mcf": 13.882814,
                                            "singleQuantityLcf": 0,
                                            "lcf": 0,
                                            "totalQuantityMcf": 13.882814,
                                            "expandable": false,
                                            "children": [
                                                {
                                                    "rowNumber": 2127,
                                                    "parentRowNumber": 2126,
                                                    "articleId": "MM0000009442",
                                                    "description": "MM0000009442-00-Objektführung",
                                                    "hierarchy": 5,
                                                    "quantity": 1,
                                                    "weight": 6.168499999999999,
                                                    "childrenWeight": 6.168499999999999,
                                                    "totalQuantityWeight": 6.168499999999999,
                                                    "singleQuantityMcf": 13.882814,
                                                    "mcf": 13.882814,
                                                    "singleQuantityLcf": 0,
                                                    "lcf": 0,
                                                    "totalQuantityMcf": 13.882814,
                                                    "expandable": false,
                                                    "children": [
                                                        {
                                                            "rowNumber": 2128,
                                                            "parentRowNumber": 2127,
                                                            "articleId": "22070610",
                                                            "description": "22070610-00-Fluegelschraube",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0237,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0237,
                                                            "singleQuantityMcf": 0.041001,
                                                            "mcf": 0.041001,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.041001,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 2129,
                                                            "parentRowNumber": 2127,
                                                            "articleId": "22070610",
                                                            "description": "22070610-00-Fluegelschraube",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0237,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0237,
                                                            "singleQuantityMcf": 0.041001,
                                                            "mcf": 0.041001,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.041001,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 2130,
                                                            "parentRowNumber": 2127,
                                                            "articleId": "47232624",
                                                            "description": "47232624-00-Abstechblech",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.6034,
                                                            "childrenWeight": 0.6034,
                                                            "totalQuantityWeight": 0.6034,
                                                            "singleQuantityMcf": 3.1256120000000003,
                                                            "mcf": 3.1256120000000003,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 3.1256120000000003,
                                                            "expandable": false,
                                                            "children": [
                                                                {
                                                                    "rowNumber": 2131,
                                                                    "parentRowNumber": 2130,
                                                                    "articleId": "142604",
                                                                    "description": "142604-00-Edelstahlblech",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.6034,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.6034,
                                                                    "singleQuantityMcf": 3.1256120000000003,
                                                                    "mcf": 3.1256120000000003,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 3.1256120000000003,
                                                                    "expandable": false,
                                                                    "children": []
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            "rowNumber": 2132,
                                                            "parentRowNumber": 2127,
                                                            "articleId": "47232893",
                                                            "description": "47232893-00-Distanzplatte",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 3.3476,
                                                            "childrenWeight": 3.3476,
                                                            "totalQuantityWeight": 3.3476,
                                                            "singleQuantityMcf": 10.6752,
                                                            "mcf": 10.6752,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 10.6752,
                                                            "expandable": false,
                                                            "children": [
                                                                {
                                                                    "rowNumber": 2133,
                                                                    "parentRowNumber": 2132,
                                                                    "articleId": "162549",
                                                                    "description": "162549-01-Platte",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 3.336,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 3.336,
                                                                    "singleQuantityMcf": 10.6752,
                                                                    "mcf": 10.6752,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 10.6752,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2134,
                                                                    "parentRowNumber": 2132,
                                                                    "articleId": "347205",
                                                                    "description": "347205-00-Ensat-Gewindebuchse",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0058,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0058,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2135,
                                                                    "parentRowNumber": 2132,
                                                                    "articleId": "347205",
                                                                    "description": "347205-00-Ensat-Gewindebuchse",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0058,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0058,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": []
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            "rowNumber": 2136,
                                                            "parentRowNumber": 2127,
                                                            "articleId": "Magazin_33847_2",
                                                            "description": "Magazin_33847_2-00-280x236",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 2.1701,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 2.1701,
                                                            "singleQuantityMcf": 0,
                                                            "mcf": 0,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0,
                                                            "expandable": false,
                                                            "children": []
                                                        }
                                                    ]
                                                }
                                            ]
                                        },
                                        {
                                            "rowNumber": 2137,
                                            "parentRowNumber": 2021,
                                            "articleId": "33847",
                                            "description": "33847-20BAF2-00-Objektführung",
                                            "hierarchy": 4,
                                            "quantity": 1,
                                            "weight": 11.733,
                                            "childrenWeight": 11.733,
                                            "totalQuantityWeight": 11.733,
                                            "singleQuantityMcf": 15.499444,
                                            "mcf": 15.499444,
                                            "singleQuantityLcf": 0,
                                            "lcf": 0,
                                            "totalQuantityMcf": 15.499444,
                                            "expandable": false,
                                            "children": [
                                                {
                                                    "rowNumber": 2138,
                                                    "parentRowNumber": 2137,
                                                    "articleId": "MM0000009701",
                                                    "description": "MM0000009701-00-Objektführung",
                                                    "hierarchy": 5,
                                                    "quantity": 1,
                                                    "weight": 11.733,
                                                    "childrenWeight": 11.733,
                                                    "totalQuantityWeight": 11.733,
                                                    "singleQuantityMcf": 15.499444,
                                                    "mcf": 15.499444,
                                                    "singleQuantityLcf": 0,
                                                    "lcf": 0,
                                                    "totalQuantityMcf": 15.499444,
                                                    "expandable": false,
                                                    "children": [
                                                        {
                                                            "rowNumber": 2139,
                                                            "parentRowNumber": 2138,
                                                            "articleId": "22070610",
                                                            "description": "22070610-00-Fluegelschraube",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0237,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0237,
                                                            "singleQuantityMcf": 0.041001,
                                                            "mcf": 0.041001,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.041001,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 2140,
                                                            "parentRowNumber": 2138,
                                                            "articleId": "22070610",
                                                            "description": "22070610-00-Fluegelschraube",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0237,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0237,
                                                            "singleQuantityMcf": 0.041001,
                                                            "mcf": 0.041001,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.041001,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 2141,
                                                            "parentRowNumber": 2138,
                                                            "articleId": "47238327",
                                                            "description": "47238327-00-Distanzplatte",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 3.5509,
                                                            "childrenWeight": 3.5509,
                                                            "totalQuantityWeight": 3.5509,
                                                            "singleQuantityMcf": 11.32576,
                                                            "mcf": 11.32576,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 11.32576,
                                                            "expandable": false,
                                                            "children": [
                                                                {
                                                                    "rowNumber": 2142,
                                                                    "parentRowNumber": 2141,
                                                                    "articleId": "162549",
                                                                    "description": "162549-01-Platte",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 3.5393,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 3.5393,
                                                                    "singleQuantityMcf": 11.32576,
                                                                    "mcf": 11.32576,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 11.32576,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2143,
                                                                    "parentRowNumber": 2141,
                                                                    "articleId": "347205",
                                                                    "description": "347205-00-Ensat-Gewindebuchse",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0058,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0058,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2144,
                                                                    "parentRowNumber": 2141,
                                                                    "articleId": "347205",
                                                                    "description": "347205-00-Ensat-Gewindebuchse",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0058,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0058,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": []
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            "rowNumber": 2145,
                                                            "parentRowNumber": 2138,
                                                            "articleId": "47238333",
                                                            "description": "47238333-00-Abstechblech",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.7899,
                                                            "childrenWeight": 0.7899,
                                                            "totalQuantityWeight": 0.7899,
                                                            "singleQuantityMcf": 4.091682,
                                                            "mcf": 4.091682,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 4.091682,
                                                            "expandable": false,
                                                            "children": [
                                                                {
                                                                    "rowNumber": 2146,
                                                                    "parentRowNumber": 2145,
                                                                    "articleId": "142604",
                                                                    "description": "142604-00-Edelstahlblech",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.7899,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.7899,
                                                                    "singleQuantityMcf": 4.091682,
                                                                    "mcf": 4.091682,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 4.091682,
                                                                    "expandable": false,
                                                                    "children": []
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            "rowNumber": 2147,
                                                            "parentRowNumber": 2138,
                                                            "articleId": "Magazin_33847_3",
                                                            "description": "Magazin_33847_3-00-549x341",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 7.3448,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 7.3448,
                                                            "singleQuantityMcf": 0,
                                                            "mcf": 0,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0,
                                                            "expandable": false,
                                                            "children": []
                                                        }
                                                    ]
                                                }
                                            ]
                                        },
                                        {
                                            "rowNumber": 2148,
                                            "parentRowNumber": 2021,
                                            "articleId": "33847",
                                            "description": "33847-20BAF3-00-Objektführung",
                                            "hierarchy": 4,
                                            "quantity": 1,
                                            "weight": 11.733,
                                            "childrenWeight": 11.733,
                                            "totalQuantityWeight": 11.733,
                                            "singleQuantityMcf": 15.499444,
                                            "mcf": 15.499444,
                                            "singleQuantityLcf": 0,
                                            "lcf": 0,
                                            "totalQuantityMcf": 15.499444,
                                            "expandable": false,
                                            "children": [
                                                {
                                                    "rowNumber": 2149,
                                                    "parentRowNumber": 2148,
                                                    "articleId": "MM0000009702",
                                                    "description": "MM0000009702-00-Objektführung",
                                                    "hierarchy": 5,
                                                    "quantity": 1,
                                                    "weight": 11.733,
                                                    "childrenWeight": 11.733,
                                                    "totalQuantityWeight": 11.733,
                                                    "singleQuantityMcf": 15.499444,
                                                    "mcf": 15.499444,
                                                    "singleQuantityLcf": 0,
                                                    "lcf": 0,
                                                    "totalQuantityMcf": 15.499444,
                                                    "expandable": false,
                                                    "children": [
                                                        {
                                                            "rowNumber": 2150,
                                                            "parentRowNumber": 2149,
                                                            "articleId": "22070610",
                                                            "description": "22070610-00-Fluegelschraube",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0237,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0237,
                                                            "singleQuantityMcf": 0.041001,
                                                            "mcf": 0.041001,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.041001,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 2151,
                                                            "parentRowNumber": 2149,
                                                            "articleId": "22070610",
                                                            "description": "22070610-00-Fluegelschraube",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0237,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0237,
                                                            "singleQuantityMcf": 0.041001,
                                                            "mcf": 0.041001,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.041001,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 2152,
                                                            "parentRowNumber": 2149,
                                                            "articleId": "47238327",
                                                            "description": "47238327-00-Distanzplatte",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 3.5509,
                                                            "childrenWeight": 3.5509,
                                                            "totalQuantityWeight": 3.5509,
                                                            "singleQuantityMcf": 11.32576,
                                                            "mcf": 11.32576,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 11.32576,
                                                            "expandable": false,
                                                            "children": [
                                                                {
                                                                    "rowNumber": 2153,
                                                                    "parentRowNumber": 2152,
                                                                    "articleId": "162549",
                                                                    "description": "162549-01-Platte",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 3.5393,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 3.5393,
                                                                    "singleQuantityMcf": 11.32576,
                                                                    "mcf": 11.32576,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 11.32576,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2154,
                                                                    "parentRowNumber": 2152,
                                                                    "articleId": "347205",
                                                                    "description": "347205-00-Ensat-Gewindebuchse",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0058,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0058,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2155,
                                                                    "parentRowNumber": 2152,
                                                                    "articleId": "347205",
                                                                    "description": "347205-00-Ensat-Gewindebuchse",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0058,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0058,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": []
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            "rowNumber": 2156,
                                                            "parentRowNumber": 2149,
                                                            "articleId": "47238333",
                                                            "description": "47238333-00-Abstechblech",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.7899,
                                                            "childrenWeight": 0.7899,
                                                            "totalQuantityWeight": 0.7899,
                                                            "singleQuantityMcf": 4.091682,
                                                            "mcf": 4.091682,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 4.091682,
                                                            "expandable": false,
                                                            "children": [
                                                                {
                                                                    "rowNumber": 2157,
                                                                    "parentRowNumber": 2156,
                                                                    "articleId": "142604",
                                                                    "description": "142604-00-Edelstahlblech",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.7899,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.7899,
                                                                    "singleQuantityMcf": 4.091682,
                                                                    "mcf": 4.091682,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 4.091682,
                                                                    "expandable": false,
                                                                    "children": []
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            "rowNumber": 2158,
                                                            "parentRowNumber": 2149,
                                                            "articleId": "Magazin_33847_3",
                                                            "description": "Magazin_33847_3-00-549x341",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 7.3448,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 7.3448,
                                                            "singleQuantityMcf": 0,
                                                            "mcf": 0,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0,
                                                            "expandable": false,
                                                            "children": []
                                                        }
                                                    ]
                                                }
                                            ]
                                        },
                                        {
                                            "rowNumber": 2159,
                                            "parentRowNumber": 2021,
                                            "articleId": "P",
                                            "description": "P-ESA1025-33847-20-00-Einlauf",
                                            "hierarchy": 4,
                                            "quantity": 1,
                                            "weight": 0,
                                            "childrenWeight": 0,
                                            "totalQuantityWeight": 0,
                                            "singleQuantityMcf": 0,
                                            "mcf": 0,
                                            "singleQuantityLcf": 0,
                                            "lcf": 0,
                                            "totalQuantityMcf": 0,
                                            "expandable": false,
                                            "children": [
                                                {
                                                    "rowNumber": 2160,
                                                    "parentRowNumber": 2159,
                                                    "articleId": "P",
                                                    "description": "P-ESA1025-33847-20-0001-00-Einlauf",
                                                    "hierarchy": 5,
                                                    "quantity": 1,
                                                    "weight": 0,
                                                    "childrenWeight": 0,
                                                    "totalQuantityWeight": 0,
                                                    "singleQuantityMcf": 0,
                                                    "mcf": 0,
                                                    "singleQuantityLcf": 0,
                                                    "lcf": 0,
                                                    "totalQuantityMcf": 0,
                                                    "expandable": false,
                                                    "children": []
                                                }
                                            ]
                                        }
                                    ]
                                },
                                {
                                    "rowNumber": 2161,
                                    "parentRowNumber": 2020,
                                    "articleId": "33847",
                                    "description": "33847-20DL-00-Band L",
                                    "hierarchy": 3,
                                    "quantity": 1,
                                    "weight": 181.6597999999997,
                                    "childrenWeight": 181.6597999999997,
                                    "totalQuantityWeight": 181.6597999999997,
                                    "singleQuantityMcf": 836.8677832000054,
                                    "mcf": 836.8677832000054,
                                    "singleQuantityLcf": 0,
                                    "lcf": 0,
                                    "totalQuantityMcf": 836.8677832000054,
                                    "expandable": false,
                                    "children": [
                                        {
                                            "rowNumber": 2162,
                                            "parentRowNumber": 2161,
                                            "articleId": "33847",
                                            "description": "33847-20DL10-03-Grundeinheit",
                                            "hierarchy": 4,
                                            "quantity": 1,
                                            "weight": 123.10499999999972,
                                            "childrenWeight": 123.10499999999972,
                                            "totalQuantityWeight": 123.10499999999972,
                                            "singleQuantityMcf": 741.4914088000054,
                                            "mcf": 741.4914088000054,
                                            "singleQuantityLcf": 0,
                                            "lcf": 0,
                                            "totalQuantityMcf": 741.4914088000054,
                                            "expandable": false,
                                            "children": [
                                                {
                                                    "rowNumber": 2163,
                                                    "parentRowNumber": 2162,
                                                    "articleId": "MM0000005428",
                                                    "description": "MM0000005428-04-Einlaufband",
                                                    "hierarchy": 5,
                                                    "quantity": 1,
                                                    "weight": 123.10499999999972,
                                                    "childrenWeight": 123.10499999999972,
                                                    "totalQuantityWeight": 123.10499999999972,
                                                    "singleQuantityMcf": 741.4914088000054,
                                                    "mcf": 741.4914088000054,
                                                    "singleQuantityLcf": 0,
                                                    "lcf": 0,
                                                    "totalQuantityMcf": 741.4914088000054,
                                                    "expandable": false,
                                                    "children": [
                                                        {
                                                            "rowNumber": 2164,
                                                            "parentRowNumber": 2163,
                                                            "articleId": "47117272",
                                                            "description": "47117272-04-Einlaufband",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 123.10499999999972,
                                                            "childrenWeight": 123.10499999999972,
                                                            "totalQuantityWeight": 123.10499999999972,
                                                            "singleQuantityMcf": 741.4914088000054,
                                                            "mcf": 741.4914088000054,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 741.4914088000054,
                                                            "expandable": false,
                                                            "children": [
                                                                {
                                                                    "rowNumber": 2165,
                                                                    "parentRowNumber": 2164,
                                                                    "articleId": "20120410",
                                                                    "description": "20120410-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0019,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0019,
                                                                    "singleQuantityMcf": 0.009842,
                                                                    "mcf": 0.009842,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.009842,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2166,
                                                                    "parentRowNumber": 2164,
                                                                    "articleId": "20120410",
                                                                    "description": "20120410-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0019,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0019,
                                                                    "singleQuantityMcf": 0.009842,
                                                                    "mcf": 0.009842,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.009842,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2167,
                                                                    "parentRowNumber": 2164,
                                                                    "articleId": "20120410",
                                                                    "description": "20120410-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0019,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0019,
                                                                    "singleQuantityMcf": 0.009842,
                                                                    "mcf": 0.009842,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.009842,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2168,
                                                                    "parentRowNumber": 2164,
                                                                    "articleId": "20120410",
                                                                    "description": "20120410-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0019,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0019,
                                                                    "singleQuantityMcf": 0.009842,
                                                                    "mcf": 0.009842,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.009842,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2169,
                                                                    "parentRowNumber": 2164,
                                                                    "articleId": "20120410",
                                                                    "description": "20120410-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0019,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0019,
                                                                    "singleQuantityMcf": 0.009842,
                                                                    "mcf": 0.009842,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.009842,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2170,
                                                                    "parentRowNumber": 2164,
                                                                    "articleId": "20120410",
                                                                    "description": "20120410-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0019,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0019,
                                                                    "singleQuantityMcf": 0.009842,
                                                                    "mcf": 0.009842,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.009842,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2171,
                                                                    "parentRowNumber": 2164,
                                                                    "articleId": "20120410",
                                                                    "description": "20120410-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0019,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0019,
                                                                    "singleQuantityMcf": 0.009842,
                                                                    "mcf": 0.009842,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.009842,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2172,
                                                                    "parentRowNumber": 2164,
                                                                    "articleId": "20120410",
                                                                    "description": "20120410-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0019,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0019,
                                                                    "singleQuantityMcf": 0.009842,
                                                                    "mcf": 0.009842,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.009842,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2173,
                                                                    "parentRowNumber": 2164,
                                                                    "articleId": "20120410",
                                                                    "description": "20120410-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0019,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0019,
                                                                    "singleQuantityMcf": 0.009842,
                                                                    "mcf": 0.009842,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.009842,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2174,
                                                                    "parentRowNumber": 2164,
                                                                    "articleId": "20120410",
                                                                    "description": "20120410-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0019,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0019,
                                                                    "singleQuantityMcf": 0.009842,
                                                                    "mcf": 0.009842,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.009842,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2175,
                                                                    "parentRowNumber": 2164,
                                                                    "articleId": "20120410",
                                                                    "description": "20120410-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0019,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0019,
                                                                    "singleQuantityMcf": 0.009842,
                                                                    "mcf": 0.009842,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.009842,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2176,
                                                                    "parentRowNumber": 2164,
                                                                    "articleId": "20120508",
                                                                    "description": "20120508-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0027,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0027,
                                                                    "singleQuantityMcf": 0.013986,
                                                                    "mcf": 0.013986,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.013986,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2177,
                                                                    "parentRowNumber": 2164,
                                                                    "articleId": "20120508",
                                                                    "description": "20120508-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0027,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0027,
                                                                    "singleQuantityMcf": 0.013986,
                                                                    "mcf": 0.013986,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.013986,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2178,
                                                                    "parentRowNumber": 2164,
                                                                    "articleId": "20120508",
                                                                    "description": "20120508-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0027,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0027,
                                                                    "singleQuantityMcf": 0.013986,
                                                                    "mcf": 0.013986,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.013986,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2179,
                                                                    "parentRowNumber": 2164,
                                                                    "articleId": "20120508",
                                                                    "description": "20120508-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0027,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0027,
                                                                    "singleQuantityMcf": 0.013986,
                                                                    "mcf": 0.013986,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.013986,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2180,
                                                                    "parentRowNumber": 2164,
                                                                    "articleId": "20120510",
                                                                    "description": "20120510-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.003,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.003,
                                                                    "singleQuantityMcf": 0.01554,
                                                                    "mcf": 0.01554,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.01554,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2181,
                                                                    "parentRowNumber": 2164,
                                                                    "articleId": "20120510",
                                                                    "description": "20120510-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.003,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.003,
                                                                    "singleQuantityMcf": 0.01554,
                                                                    "mcf": 0.01554,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.01554,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2182,
                                                                    "parentRowNumber": 2164,
                                                                    "articleId": "20120510",
                                                                    "description": "20120510-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.003,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.003,
                                                                    "singleQuantityMcf": 0.01554,
                                                                    "mcf": 0.01554,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.01554,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2183,
                                                                    "parentRowNumber": 2164,
                                                                    "articleId": "20120510",
                                                                    "description": "20120510-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.003,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.003,
                                                                    "singleQuantityMcf": 0.01554,
                                                                    "mcf": 0.01554,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.01554,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2184,
                                                                    "parentRowNumber": 2164,
                                                                    "articleId": "20120510",
                                                                    "description": "20120510-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.003,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.003,
                                                                    "singleQuantityMcf": 0.01554,
                                                                    "mcf": 0.01554,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.01554,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2185,
                                                                    "parentRowNumber": 2164,
                                                                    "articleId": "20120510",
                                                                    "description": "20120510-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.003,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.003,
                                                                    "singleQuantityMcf": 0.01554,
                                                                    "mcf": 0.01554,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.01554,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2186,
                                                                    "parentRowNumber": 2164,
                                                                    "articleId": "20120525",
                                                                    "description": "20120525-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0054,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0054,
                                                                    "singleQuantityMcf": 0.027972,
                                                                    "mcf": 0.027972,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.027972,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2187,
                                                                    "parentRowNumber": 2164,
                                                                    "articleId": "20120525",
                                                                    "description": "20120525-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0054,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0054,
                                                                    "singleQuantityMcf": 0.027972,
                                                                    "mcf": 0.027972,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.027972,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2188,
                                                                    "parentRowNumber": 2164,
                                                                    "articleId": "20120612",
                                                                    "description": "20120612-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0053,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0053,
                                                                    "singleQuantityMcf": 0.027454,
                                                                    "mcf": 0.027454,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.027454,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2189,
                                                                    "parentRowNumber": 2164,
                                                                    "articleId": "20120612",
                                                                    "description": "20120612-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0053,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0053,
                                                                    "singleQuantityMcf": 0.027454,
                                                                    "mcf": 0.027454,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.027454,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2190,
                                                                    "parentRowNumber": 2164,
                                                                    "articleId": "20120612",
                                                                    "description": "20120612-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0053,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0053,
                                                                    "singleQuantityMcf": 0.027454,
                                                                    "mcf": 0.027454,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.027454,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2191,
                                                                    "parentRowNumber": 2164,
                                                                    "articleId": "20120612",
                                                                    "description": "20120612-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0053,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0053,
                                                                    "singleQuantityMcf": 0.027454,
                                                                    "mcf": 0.027454,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.027454,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2192,
                                                                    "parentRowNumber": 2164,
                                                                    "articleId": "20120612",
                                                                    "description": "20120612-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0053,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0053,
                                                                    "singleQuantityMcf": 0.027454,
                                                                    "mcf": 0.027454,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.027454,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2193,
                                                                    "parentRowNumber": 2164,
                                                                    "articleId": "20120612",
                                                                    "description": "20120612-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0053,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0053,
                                                                    "singleQuantityMcf": 0.027454,
                                                                    "mcf": 0.027454,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.027454,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2194,
                                                                    "parentRowNumber": 2164,
                                                                    "articleId": "20120612",
                                                                    "description": "20120612-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0053,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0053,
                                                                    "singleQuantityMcf": 0.027454,
                                                                    "mcf": 0.027454,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.027454,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2195,
                                                                    "parentRowNumber": 2164,
                                                                    "articleId": "20120612",
                                                                    "description": "20120612-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0053,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0053,
                                                                    "singleQuantityMcf": 0.027454,
                                                                    "mcf": 0.027454,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.027454,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2196,
                                                                    "parentRowNumber": 2164,
                                                                    "articleId": "20120612",
                                                                    "description": "20120612-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0053,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0053,
                                                                    "singleQuantityMcf": 0.027454,
                                                                    "mcf": 0.027454,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.027454,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2197,
                                                                    "parentRowNumber": 2164,
                                                                    "articleId": "20120612",
                                                                    "description": "20120612-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0053,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0053,
                                                                    "singleQuantityMcf": 0.027454,
                                                                    "mcf": 0.027454,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.027454,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2198,
                                                                    "parentRowNumber": 2164,
                                                                    "articleId": "20120615",
                                                                    "description": "20120615-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0061,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0061,
                                                                    "singleQuantityMcf": 0.031598,
                                                                    "mcf": 0.031598,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.031598,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2199,
                                                                    "parentRowNumber": 2164,
                                                                    "articleId": "20120615",
                                                                    "description": "20120615-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0061,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0061,
                                                                    "singleQuantityMcf": 0.031598,
                                                                    "mcf": 0.031598,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.031598,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2200,
                                                                    "parentRowNumber": 2164,
                                                                    "articleId": "20120615",
                                                                    "description": "20120615-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0061,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0061,
                                                                    "singleQuantityMcf": 0.031598,
                                                                    "mcf": 0.031598,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.031598,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2201,
                                                                    "parentRowNumber": 2164,
                                                                    "articleId": "20120615",
                                                                    "description": "20120615-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0061,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0061,
                                                                    "singleQuantityMcf": 0.031598,
                                                                    "mcf": 0.031598,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.031598,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2202,
                                                                    "parentRowNumber": 2164,
                                                                    "articleId": "20120625",
                                                                    "description": "20120625-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0083,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0083,
                                                                    "singleQuantityMcf": 0.042994,
                                                                    "mcf": 0.042994,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.042994,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2203,
                                                                    "parentRowNumber": 2164,
                                                                    "articleId": "20120625",
                                                                    "description": "20120625-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0083,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0083,
                                                                    "singleQuantityMcf": 0.042994,
                                                                    "mcf": 0.042994,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.042994,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2204,
                                                                    "parentRowNumber": 2164,
                                                                    "articleId": "20120635",
                                                                    "description": "20120635-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0105,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0105,
                                                                    "singleQuantityMcf": 0.05439,
                                                                    "mcf": 0.05439,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.05439,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2205,
                                                                    "parentRowNumber": 2164,
                                                                    "articleId": "20120635",
                                                                    "description": "20120635-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0105,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0105,
                                                                    "singleQuantityMcf": 0.05439,
                                                                    "mcf": 0.05439,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.05439,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2206,
                                                                    "parentRowNumber": 2164,
                                                                    "articleId": "20120635",
                                                                    "description": "20120635-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0105,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0105,
                                                                    "singleQuantityMcf": 0.05439,
                                                                    "mcf": 0.05439,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.05439,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2207,
                                                                    "parentRowNumber": 2164,
                                                                    "articleId": "20120655",
                                                                    "description": "20120655-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.015,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.015,
                                                                    "singleQuantityMcf": 0.07769999999999999,
                                                                    "mcf": 0.07769999999999999,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.07769999999999999,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2208,
                                                                    "parentRowNumber": 2164,
                                                                    "articleId": "20120655",
                                                                    "description": "20120655-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.015,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.015,
                                                                    "singleQuantityMcf": 0.07769999999999999,
                                                                    "mcf": 0.07769999999999999,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.07769999999999999,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2209,
                                                                    "parentRowNumber": 2164,
                                                                    "articleId": "20120820",
                                                                    "description": "20120820-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0141,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0141,
                                                                    "singleQuantityMcf": 0.07303799999999999,
                                                                    "mcf": 0.07303799999999999,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.07303799999999999,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2210,
                                                                    "parentRowNumber": 2164,
                                                                    "articleId": "20120820",
                                                                    "description": "20120820-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0141,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0141,
                                                                    "singleQuantityMcf": 0.07303799999999999,
                                                                    "mcf": 0.07303799999999999,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.07303799999999999,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2211,
                                                                    "parentRowNumber": 2164,
                                                                    "articleId": "20120820",
                                                                    "description": "20120820-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0141,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0141,
                                                                    "singleQuantityMcf": 0.07303799999999999,
                                                                    "mcf": 0.07303799999999999,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.07303799999999999,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2212,
                                                                    "parentRowNumber": 2164,
                                                                    "articleId": "20120820",
                                                                    "description": "20120820-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0141,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0141,
                                                                    "singleQuantityMcf": 0.07303799999999999,
                                                                    "mcf": 0.07303799999999999,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.07303799999999999,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2213,
                                                                    "parentRowNumber": 2164,
                                                                    "articleId": "20120820",
                                                                    "description": "20120820-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0141,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0141,
                                                                    "singleQuantityMcf": 0.07303799999999999,
                                                                    "mcf": 0.07303799999999999,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.07303799999999999,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2214,
                                                                    "parentRowNumber": 2164,
                                                                    "articleId": "20120820",
                                                                    "description": "20120820-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0141,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0141,
                                                                    "singleQuantityMcf": 0.07303799999999999,
                                                                    "mcf": 0.07303799999999999,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.07303799999999999,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2215,
                                                                    "parentRowNumber": 2164,
                                                                    "articleId": "20120840",
                                                                    "description": "20120840-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0221,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0221,
                                                                    "singleQuantityMcf": 0.114478,
                                                                    "mcf": 0.114478,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.114478,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2216,
                                                                    "parentRowNumber": 2164,
                                                                    "articleId": "20120840",
                                                                    "description": "20120840-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0221,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0221,
                                                                    "singleQuantityMcf": 0.114478,
                                                                    "mcf": 0.114478,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.114478,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2217,
                                                                    "parentRowNumber": 2164,
                                                                    "articleId": "20120840",
                                                                    "description": "20120840-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0221,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0221,
                                                                    "singleQuantityMcf": 0.114478,
                                                                    "mcf": 0.114478,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.114478,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2218,
                                                                    "parentRowNumber": 2164,
                                                                    "articleId": "20120840",
                                                                    "description": "20120840-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0221,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0221,
                                                                    "singleQuantityMcf": 0.114478,
                                                                    "mcf": 0.114478,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.114478,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2219,
                                                                    "parentRowNumber": 2164,
                                                                    "articleId": "20120840",
                                                                    "description": "20120840-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0221,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0221,
                                                                    "singleQuantityMcf": 0.114478,
                                                                    "mcf": 0.114478,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.114478,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2220,
                                                                    "parentRowNumber": 2164,
                                                                    "articleId": "20120840",
                                                                    "description": "20120840-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0221,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0221,
                                                                    "singleQuantityMcf": 0.114478,
                                                                    "mcf": 0.114478,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.114478,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2221,
                                                                    "parentRowNumber": 2164,
                                                                    "articleId": "20220612",
                                                                    "description": "20220612-00-Zylinderschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0045,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0045,
                                                                    "singleQuantityMcf": 0.02331,
                                                                    "mcf": 0.02331,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.02331,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2222,
                                                                    "parentRowNumber": 2164,
                                                                    "articleId": "20220612",
                                                                    "description": "20220612-00-Zylinderschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0045,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0045,
                                                                    "singleQuantityMcf": 0.02331,
                                                                    "mcf": 0.02331,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.02331,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2223,
                                                                    "parentRowNumber": 2164,
                                                                    "articleId": "20220612",
                                                                    "description": "20220612-00-Zylinderschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0045,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0045,
                                                                    "singleQuantityMcf": 0.02331,
                                                                    "mcf": 0.02331,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.02331,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2224,
                                                                    "parentRowNumber": 2164,
                                                                    "articleId": "20220612",
                                                                    "description": "20220612-00-Zylinderschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0045,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0045,
                                                                    "singleQuantityMcf": 0.02331,
                                                                    "mcf": 0.02331,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.02331,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2225,
                                                                    "parentRowNumber": 2164,
                                                                    "articleId": "20220612",
                                                                    "description": "20220612-00-Zylinderschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0045,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0045,
                                                                    "singleQuantityMcf": 0.02331,
                                                                    "mcf": 0.02331,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.02331,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2226,
                                                                    "parentRowNumber": 2164,
                                                                    "articleId": "20220612",
                                                                    "description": "20220612-00-Zylinderschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0045,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0045,
                                                                    "singleQuantityMcf": 0.02331,
                                                                    "mcf": 0.02331,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.02331,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2227,
                                                                    "parentRowNumber": 2164,
                                                                    "articleId": "20220612",
                                                                    "description": "20220612-00-Zylinderschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0045,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0045,
                                                                    "singleQuantityMcf": 0.02331,
                                                                    "mcf": 0.02331,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.02331,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2228,
                                                                    "parentRowNumber": 2164,
                                                                    "articleId": "20220612",
                                                                    "description": "20220612-00-Zylinderschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0045,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0045,
                                                                    "singleQuantityMcf": 0.02331,
                                                                    "mcf": 0.02331,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.02331,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2229,
                                                                    "parentRowNumber": 2164,
                                                                    "articleId": "20220612",
                                                                    "description": "20220612-00-Zylinderschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0045,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0045,
                                                                    "singleQuantityMcf": 0.02331,
                                                                    "mcf": 0.02331,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.02331,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2230,
                                                                    "parentRowNumber": 2164,
                                                                    "articleId": "20220612",
                                                                    "description": "20220612-00-Zylinderschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0045,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0045,
                                                                    "singleQuantityMcf": 0.02331,
                                                                    "mcf": 0.02331,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.02331,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2231,
                                                                    "parentRowNumber": 2164,
                                                                    "articleId": "20220612",
                                                                    "description": "20220612-00-Zylinderschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0045,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0045,
                                                                    "singleQuantityMcf": 0.02331,
                                                                    "mcf": 0.02331,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.02331,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2232,
                                                                    "parentRowNumber": 2164,
                                                                    "articleId": "20220612",
                                                                    "description": "20220612-00-Zylinderschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0045,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0045,
                                                                    "singleQuantityMcf": 0.02331,
                                                                    "mcf": 0.02331,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.02331,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2233,
                                                                    "parentRowNumber": 2164,
                                                                    "articleId": "20220612",
                                                                    "description": "20220612-00-Zylinderschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0045,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0045,
                                                                    "singleQuantityMcf": 0.02331,
                                                                    "mcf": 0.02331,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.02331,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2234,
                                                                    "parentRowNumber": 2164,
                                                                    "articleId": "20220612",
                                                                    "description": "20220612-00-Zylinderschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0045,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0045,
                                                                    "singleQuantityMcf": 0.02331,
                                                                    "mcf": 0.02331,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.02331,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2235,
                                                                    "parentRowNumber": 2164,
                                                                    "articleId": "20220625",
                                                                    "description": "20220625-01-Zylinderschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0075,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0075,
                                                                    "singleQuantityMcf": 0.03885,
                                                                    "mcf": 0.03885,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.03885,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2236,
                                                                    "parentRowNumber": 2164,
                                                                    "articleId": "20220625",
                                                                    "description": "20220625-01-Zylinderschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0075,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0075,
                                                                    "singleQuantityMcf": 0.03885,
                                                                    "mcf": 0.03885,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.03885,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2237,
                                                                    "parentRowNumber": 2164,
                                                                    "articleId": "20220625",
                                                                    "description": "20220625-01-Zylinderschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0075,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0075,
                                                                    "singleQuantityMcf": 0.03885,
                                                                    "mcf": 0.03885,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.03885,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2238,
                                                                    "parentRowNumber": 2164,
                                                                    "articleId": "20220625",
                                                                    "description": "20220625-01-Zylinderschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0075,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0075,
                                                                    "singleQuantityMcf": 0.03885,
                                                                    "mcf": 0.03885,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.03885,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2239,
                                                                    "parentRowNumber": 2164,
                                                                    "articleId": "20220625",
                                                                    "description": "20220625-01-Zylinderschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0075,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0075,
                                                                    "singleQuantityMcf": 0.03885,
                                                                    "mcf": 0.03885,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.03885,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2240,
                                                                    "parentRowNumber": 2164,
                                                                    "articleId": "20220625",
                                                                    "description": "20220625-01-Zylinderschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0075,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0075,
                                                                    "singleQuantityMcf": 0.03885,
                                                                    "mcf": 0.03885,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.03885,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2241,
                                                                    "parentRowNumber": 2164,
                                                                    "articleId": "20220625",
                                                                    "description": "20220625-01-Zylinderschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0075,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0075,
                                                                    "singleQuantityMcf": 0.03885,
                                                                    "mcf": 0.03885,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.03885,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2242,
                                                                    "parentRowNumber": 2164,
                                                                    "articleId": "20220625",
                                                                    "description": "20220625-01-Zylinderschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0075,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0075,
                                                                    "singleQuantityMcf": 0.03885,
                                                                    "mcf": 0.03885,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.03885,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2243,
                                                                    "parentRowNumber": 2164,
                                                                    "articleId": "20220625",
                                                                    "description": "20220625-01-Zylinderschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0075,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0075,
                                                                    "singleQuantityMcf": 0.03885,
                                                                    "mcf": 0.03885,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.03885,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2244,
                                                                    "parentRowNumber": 2164,
                                                                    "articleId": "20220625",
                                                                    "description": "20220625-01-Zylinderschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0075,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0075,
                                                                    "singleQuantityMcf": 0.03885,
                                                                    "mcf": 0.03885,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.03885,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2245,
                                                                    "parentRowNumber": 2164,
                                                                    "articleId": "20220625",
                                                                    "description": "20220625-01-Zylinderschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0075,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0075,
                                                                    "singleQuantityMcf": 0.03885,
                                                                    "mcf": 0.03885,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.03885,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2246,
                                                                    "parentRowNumber": 2164,
                                                                    "articleId": "20220625",
                                                                    "description": "20220625-01-Zylinderschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0075,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0075,
                                                                    "singleQuantityMcf": 0.03885,
                                                                    "mcf": 0.03885,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.03885,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2247,
                                                                    "parentRowNumber": 2164,
                                                                    "articleId": "20220625",
                                                                    "description": "20220625-01-Zylinderschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0075,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0075,
                                                                    "singleQuantityMcf": 0.03885,
                                                                    "mcf": 0.03885,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.03885,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2248,
                                                                    "parentRowNumber": 2164,
                                                                    "articleId": "20220625",
                                                                    "description": "20220625-01-Zylinderschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0075,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0075,
                                                                    "singleQuantityMcf": 0.03885,
                                                                    "mcf": 0.03885,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.03885,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2249,
                                                                    "parentRowNumber": 2164,
                                                                    "articleId": "20220625",
                                                                    "description": "20220625-01-Zylinderschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0075,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0075,
                                                                    "singleQuantityMcf": 0.03885,
                                                                    "mcf": 0.03885,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.03885,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2250,
                                                                    "parentRowNumber": 2164,
                                                                    "articleId": "20220625",
                                                                    "description": "20220625-01-Zylinderschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0075,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0075,
                                                                    "singleQuantityMcf": 0.03885,
                                                                    "mcf": 0.03885,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.03885,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2251,
                                                                    "parentRowNumber": 2164,
                                                                    "articleId": "20220625",
                                                                    "description": "20220625-01-Zylinderschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0075,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0075,
                                                                    "singleQuantityMcf": 0.03885,
                                                                    "mcf": 0.03885,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.03885,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2252,
                                                                    "parentRowNumber": 2164,
                                                                    "articleId": "20220625",
                                                                    "description": "20220625-01-Zylinderschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0075,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0075,
                                                                    "singleQuantityMcf": 0.03885,
                                                                    "mcf": 0.03885,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.03885,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2253,
                                                                    "parentRowNumber": 2164,
                                                                    "articleId": "20220625",
                                                                    "description": "20220625-01-Zylinderschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0075,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0075,
                                                                    "singleQuantityMcf": 0.03885,
                                                                    "mcf": 0.03885,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.03885,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2254,
                                                                    "parentRowNumber": 2164,
                                                                    "articleId": "20220625",
                                                                    "description": "20220625-01-Zylinderschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0075,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0075,
                                                                    "singleQuantityMcf": 0.03885,
                                                                    "mcf": 0.03885,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.03885,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2255,
                                                                    "parentRowNumber": 2164,
                                                                    "articleId": "20220625",
                                                                    "description": "20220625-01-Zylinderschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0075,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0075,
                                                                    "singleQuantityMcf": 0.03885,
                                                                    "mcf": 0.03885,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.03885,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2256,
                                                                    "parentRowNumber": 2164,
                                                                    "articleId": "20220625",
                                                                    "description": "20220625-01-Zylinderschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0075,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0075,
                                                                    "singleQuantityMcf": 0.03885,
                                                                    "mcf": 0.03885,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.03885,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2257,
                                                                    "parentRowNumber": 2164,
                                                                    "articleId": "20220625",
                                                                    "description": "20220625-01-Zylinderschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0075,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0075,
                                                                    "singleQuantityMcf": 0.03885,
                                                                    "mcf": 0.03885,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.03885,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2258,
                                                                    "parentRowNumber": 2164,
                                                                    "articleId": "20220625",
                                                                    "description": "20220625-01-Zylinderschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0075,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0075,
                                                                    "singleQuantityMcf": 0.03885,
                                                                    "mcf": 0.03885,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.03885,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2259,
                                                                    "parentRowNumber": 2164,
                                                                    "articleId": "20220625",
                                                                    "description": "20220625-01-Zylinderschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0075,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0075,
                                                                    "singleQuantityMcf": 0.03885,
                                                                    "mcf": 0.03885,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.03885,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2260,
                                                                    "parentRowNumber": 2164,
                                                                    "articleId": "20220625",
                                                                    "description": "20220625-01-Zylinderschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0075,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0075,
                                                                    "singleQuantityMcf": 0.03885,
                                                                    "mcf": 0.03885,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.03885,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2261,
                                                                    "parentRowNumber": 2164,
                                                                    "articleId": "20220625",
                                                                    "description": "20220625-01-Zylinderschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0075,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0075,
                                                                    "singleQuantityMcf": 0.03885,
                                                                    "mcf": 0.03885,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.03885,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2262,
                                                                    "parentRowNumber": 2164,
                                                                    "articleId": "20220625",
                                                                    "description": "20220625-01-Zylinderschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0075,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0075,
                                                                    "singleQuantityMcf": 0.03885,
                                                                    "mcf": 0.03885,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.03885,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2263,
                                                                    "parentRowNumber": 2164,
                                                                    "articleId": "20220625",
                                                                    "description": "20220625-01-Zylinderschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0075,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0075,
                                                                    "singleQuantityMcf": 0.03885,
                                                                    "mcf": 0.03885,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.03885,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2264,
                                                                    "parentRowNumber": 2164,
                                                                    "articleId": "20220625",
                                                                    "description": "20220625-01-Zylinderschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0075,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0075,
                                                                    "singleQuantityMcf": 0.03885,
                                                                    "mcf": 0.03885,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.03885,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2265,
                                                                    "parentRowNumber": 2164,
                                                                    "articleId": "20220625",
                                                                    "description": "20220625-01-Zylinderschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0075,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0075,
                                                                    "singleQuantityMcf": 0.03885,
                                                                    "mcf": 0.03885,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.03885,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2266,
                                                                    "parentRowNumber": 2164,
                                                                    "articleId": "20220625",
                                                                    "description": "20220625-01-Zylinderschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0075,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0075,
                                                                    "singleQuantityMcf": 0.03885,
                                                                    "mcf": 0.03885,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.03885,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2267,
                                                                    "parentRowNumber": 2164,
                                                                    "articleId": "20220625",
                                                                    "description": "20220625-01-Zylinderschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0075,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0075,
                                                                    "singleQuantityMcf": 0.03885,
                                                                    "mcf": 0.03885,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.03885,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2268,
                                                                    "parentRowNumber": 2164,
                                                                    "articleId": "20220625",
                                                                    "description": "20220625-01-Zylinderschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0075,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0075,
                                                                    "singleQuantityMcf": 0.03885,
                                                                    "mcf": 0.03885,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.03885,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2269,
                                                                    "parentRowNumber": 2164,
                                                                    "articleId": "20220820",
                                                                    "description": "20220820-00-Zylinderschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.012,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.012,
                                                                    "singleQuantityMcf": 0.06216,
                                                                    "mcf": 0.06216,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.06216,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2270,
                                                                    "parentRowNumber": 2164,
                                                                    "articleId": "20220820",
                                                                    "description": "20220820-00-Zylinderschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.012,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.012,
                                                                    "singleQuantityMcf": 0.06216,
                                                                    "mcf": 0.06216,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.06216,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2271,
                                                                    "parentRowNumber": 2164,
                                                                    "articleId": "20220820",
                                                                    "description": "20220820-00-Zylinderschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.012,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.012,
                                                                    "singleQuantityMcf": 0.06216,
                                                                    "mcf": 0.06216,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.06216,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2272,
                                                                    "parentRowNumber": 2164,
                                                                    "articleId": "20220820",
                                                                    "description": "20220820-00-Zylinderschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.012,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.012,
                                                                    "singleQuantityMcf": 0.06216,
                                                                    "mcf": 0.06216,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.06216,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2273,
                                                                    "parentRowNumber": 2164,
                                                                    "articleId": "20220820",
                                                                    "description": "20220820-00-Zylinderschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.012,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.012,
                                                                    "singleQuantityMcf": 0.06216,
                                                                    "mcf": 0.06216,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.06216,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2274,
                                                                    "parentRowNumber": 2164,
                                                                    "articleId": "20220820",
                                                                    "description": "20220820-00-Zylinderschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.012,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.012,
                                                                    "singleQuantityMcf": 0.06216,
                                                                    "mcf": 0.06216,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.06216,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2275,
                                                                    "parentRowNumber": 2164,
                                                                    "articleId": "20420305",
                                                                    "description": "20420305-00-Senkschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0003,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0003,
                                                                    "singleQuantityMcf": 0.0015539999999999998,
                                                                    "mcf": 0.0015539999999999998,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.0015539999999999998,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2276,
                                                                    "parentRowNumber": 2164,
                                                                    "articleId": "20420305",
                                                                    "description": "20420305-00-Senkschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0003,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0003,
                                                                    "singleQuantityMcf": 0.0015539999999999998,
                                                                    "mcf": 0.0015539999999999998,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.0015539999999999998,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2277,
                                                                    "parentRowNumber": 2164,
                                                                    "articleId": "20420305",
                                                                    "description": "20420305-00-Senkschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0003,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0003,
                                                                    "singleQuantityMcf": 0.0015539999999999998,
                                                                    "mcf": 0.0015539999999999998,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.0015539999999999998,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2278,
                                                                    "parentRowNumber": 2164,
                                                                    "articleId": "20420305",
                                                                    "description": "20420305-00-Senkschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0003,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0003,
                                                                    "singleQuantityMcf": 0.0015539999999999998,
                                                                    "mcf": 0.0015539999999999998,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.0015539999999999998,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2279,
                                                                    "parentRowNumber": 2164,
                                                                    "articleId": "20420305",
                                                                    "description": "20420305-00-Senkschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0003,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0003,
                                                                    "singleQuantityMcf": 0.0015539999999999998,
                                                                    "mcf": 0.0015539999999999998,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.0015539999999999998,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2280,
                                                                    "parentRowNumber": 2164,
                                                                    "articleId": "20420430",
                                                                    "description": "20420430-00-Senkschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0032,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0032,
                                                                    "singleQuantityMcf": 0.016576,
                                                                    "mcf": 0.016576,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.016576,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2281,
                                                                    "parentRowNumber": 2164,
                                                                    "articleId": "205206",
                                                                    "description": "205206-00-Sechskantmutter",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0026,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0026,
                                                                    "singleQuantityMcf": 0.013468,
                                                                    "mcf": 0.013468,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.013468,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2282,
                                                                    "parentRowNumber": 2164,
                                                                    "articleId": "205206",
                                                                    "description": "205206-00-Sechskantmutter",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0026,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0026,
                                                                    "singleQuantityMcf": 0.013468,
                                                                    "mcf": 0.013468,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.013468,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2283,
                                                                    "parentRowNumber": 2164,
                                                                    "articleId": "205206",
                                                                    "description": "205206-00-Sechskantmutter",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0026,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0026,
                                                                    "singleQuantityMcf": 0.013468,
                                                                    "mcf": 0.013468,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.013468,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2284,
                                                                    "parentRowNumber": 2164,
                                                                    "articleId": "205206",
                                                                    "description": "205206-00-Sechskantmutter",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0026,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0026,
                                                                    "singleQuantityMcf": 0.013468,
                                                                    "mcf": 0.013468,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.013468,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2285,
                                                                    "parentRowNumber": 2164,
                                                                    "articleId": "205206",
                                                                    "description": "205206-00-Sechskantmutter",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0026,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0026,
                                                                    "singleQuantityMcf": 0.013468,
                                                                    "mcf": 0.013468,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.013468,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2286,
                                                                    "parentRowNumber": 2164,
                                                                    "articleId": "205206",
                                                                    "description": "205206-00-Sechskantmutter",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0026,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0026,
                                                                    "singleQuantityMcf": 0.013468,
                                                                    "mcf": 0.013468,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.013468,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2287,
                                                                    "parentRowNumber": 2164,
                                                                    "articleId": "205206",
                                                                    "description": "205206-00-Sechskantmutter",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0026,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0026,
                                                                    "singleQuantityMcf": 0.013468,
                                                                    "mcf": 0.013468,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.013468,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2288,
                                                                    "parentRowNumber": 2164,
                                                                    "articleId": "205206",
                                                                    "description": "205206-00-Sechskantmutter",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0026,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0026,
                                                                    "singleQuantityMcf": 0.013468,
                                                                    "mcf": 0.013468,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.013468,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2289,
                                                                    "parentRowNumber": 2164,
                                                                    "articleId": "208204",
                                                                    "description": "208204-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0003,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0003,
                                                                    "singleQuantityMcf": 0.0015539999999999998,
                                                                    "mcf": 0.0015539999999999998,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.0015539999999999998,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2290,
                                                                    "parentRowNumber": 2164,
                                                                    "articleId": "208204",
                                                                    "description": "208204-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0003,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0003,
                                                                    "singleQuantityMcf": 0.0015539999999999998,
                                                                    "mcf": 0.0015539999999999998,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.0015539999999999998,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2291,
                                                                    "parentRowNumber": 2164,
                                                                    "articleId": "208204",
                                                                    "description": "208204-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0003,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0003,
                                                                    "singleQuantityMcf": 0.0015539999999999998,
                                                                    "mcf": 0.0015539999999999998,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.0015539999999999998,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2292,
                                                                    "parentRowNumber": 2164,
                                                                    "articleId": "208204",
                                                                    "description": "208204-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0003,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0003,
                                                                    "singleQuantityMcf": 0.0015539999999999998,
                                                                    "mcf": 0.0015539999999999998,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.0015539999999999998,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2293,
                                                                    "parentRowNumber": 2164,
                                                                    "articleId": "208204",
                                                                    "description": "208204-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0003,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0003,
                                                                    "singleQuantityMcf": 0.0015539999999999998,
                                                                    "mcf": 0.0015539999999999998,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.0015539999999999998,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2294,
                                                                    "parentRowNumber": 2164,
                                                                    "articleId": "208204",
                                                                    "description": "208204-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0003,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0003,
                                                                    "singleQuantityMcf": 0.0015539999999999998,
                                                                    "mcf": 0.0015539999999999998,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.0015539999999999998,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2295,
                                                                    "parentRowNumber": 2164,
                                                                    "articleId": "208204",
                                                                    "description": "208204-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0003,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0003,
                                                                    "singleQuantityMcf": 0.0015539999999999998,
                                                                    "mcf": 0.0015539999999999998,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.0015539999999999998,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2296,
                                                                    "parentRowNumber": 2164,
                                                                    "articleId": "208204",
                                                                    "description": "208204-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0003,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0003,
                                                                    "singleQuantityMcf": 0.0015539999999999998,
                                                                    "mcf": 0.0015539999999999998,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.0015539999999999998,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2297,
                                                                    "parentRowNumber": 2164,
                                                                    "articleId": "208204",
                                                                    "description": "208204-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0003,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0003,
                                                                    "singleQuantityMcf": 0.0015539999999999998,
                                                                    "mcf": 0.0015539999999999998,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.0015539999999999998,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2298,
                                                                    "parentRowNumber": 2164,
                                                                    "articleId": "208204",
                                                                    "description": "208204-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0003,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0003,
                                                                    "singleQuantityMcf": 0.0015539999999999998,
                                                                    "mcf": 0.0015539999999999998,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.0015539999999999998,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2299,
                                                                    "parentRowNumber": 2164,
                                                                    "articleId": "208204",
                                                                    "description": "208204-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0003,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0003,
                                                                    "singleQuantityMcf": 0.0015539999999999998,
                                                                    "mcf": 0.0015539999999999998,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.0015539999999999998,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2300,
                                                                    "parentRowNumber": 2164,
                                                                    "articleId": "208205",
                                                                    "description": "208205-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0004,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0004,
                                                                    "singleQuantityMcf": 0.002072,
                                                                    "mcf": 0.002072,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.002072,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2301,
                                                                    "parentRowNumber": 2164,
                                                                    "articleId": "208205",
                                                                    "description": "208205-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0004,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0004,
                                                                    "singleQuantityMcf": 0.002072,
                                                                    "mcf": 0.002072,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.002072,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2302,
                                                                    "parentRowNumber": 2164,
                                                                    "articleId": "208205",
                                                                    "description": "208205-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0004,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0004,
                                                                    "singleQuantityMcf": 0.002072,
                                                                    "mcf": 0.002072,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.002072,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2303,
                                                                    "parentRowNumber": 2164,
                                                                    "articleId": "208205",
                                                                    "description": "208205-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0004,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0004,
                                                                    "singleQuantityMcf": 0.002072,
                                                                    "mcf": 0.002072,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.002072,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2304,
                                                                    "parentRowNumber": 2164,
                                                                    "articleId": "208205",
                                                                    "description": "208205-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0004,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0004,
                                                                    "singleQuantityMcf": 0.002072,
                                                                    "mcf": 0.002072,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.002072,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2305,
                                                                    "parentRowNumber": 2164,
                                                                    "articleId": "208205",
                                                                    "description": "208205-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0004,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0004,
                                                                    "singleQuantityMcf": 0.002072,
                                                                    "mcf": 0.002072,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.002072,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2306,
                                                                    "parentRowNumber": 2164,
                                                                    "articleId": "208205",
                                                                    "description": "208205-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0004,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0004,
                                                                    "singleQuantityMcf": 0.002072,
                                                                    "mcf": 0.002072,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.002072,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2307,
                                                                    "parentRowNumber": 2164,
                                                                    "articleId": "208205",
                                                                    "description": "208205-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0004,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0004,
                                                                    "singleQuantityMcf": 0.002072,
                                                                    "mcf": 0.002072,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.002072,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2308,
                                                                    "parentRowNumber": 2164,
                                                                    "articleId": "208206",
                                                                    "description": "208206-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0009,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0009,
                                                                    "singleQuantityMcf": 0.004662,
                                                                    "mcf": 0.004662,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.004662,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2309,
                                                                    "parentRowNumber": 2164,
                                                                    "articleId": "208206",
                                                                    "description": "208206-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0009,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0009,
                                                                    "singleQuantityMcf": 0.004662,
                                                                    "mcf": 0.004662,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.004662,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2310,
                                                                    "parentRowNumber": 2164,
                                                                    "articleId": "208206",
                                                                    "description": "208206-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0009,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0009,
                                                                    "singleQuantityMcf": 0.004662,
                                                                    "mcf": 0.004662,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.004662,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2311,
                                                                    "parentRowNumber": 2164,
                                                                    "articleId": "208206",
                                                                    "description": "208206-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0009,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0009,
                                                                    "singleQuantityMcf": 0.004662,
                                                                    "mcf": 0.004662,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.004662,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2312,
                                                                    "parentRowNumber": 2164,
                                                                    "articleId": "208206",
                                                                    "description": "208206-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0009,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0009,
                                                                    "singleQuantityMcf": 0.004662,
                                                                    "mcf": 0.004662,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.004662,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2313,
                                                                    "parentRowNumber": 2164,
                                                                    "articleId": "208206",
                                                                    "description": "208206-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0009,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0009,
                                                                    "singleQuantityMcf": 0.004662,
                                                                    "mcf": 0.004662,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.004662,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2314,
                                                                    "parentRowNumber": 2164,
                                                                    "articleId": "208206",
                                                                    "description": "208206-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0009,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0009,
                                                                    "singleQuantityMcf": 0.004662,
                                                                    "mcf": 0.004662,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.004662,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2315,
                                                                    "parentRowNumber": 2164,
                                                                    "articleId": "208206",
                                                                    "description": "208206-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0009,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0009,
                                                                    "singleQuantityMcf": 0.004662,
                                                                    "mcf": 0.004662,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.004662,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2316,
                                                                    "parentRowNumber": 2164,
                                                                    "articleId": "208206",
                                                                    "description": "208206-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0009,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0009,
                                                                    "singleQuantityMcf": 0.004662,
                                                                    "mcf": 0.004662,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.004662,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2317,
                                                                    "parentRowNumber": 2164,
                                                                    "articleId": "208206",
                                                                    "description": "208206-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0009,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0009,
                                                                    "singleQuantityMcf": 0.004662,
                                                                    "mcf": 0.004662,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.004662,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2318,
                                                                    "parentRowNumber": 2164,
                                                                    "articleId": "208206",
                                                                    "description": "208206-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0009,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0009,
                                                                    "singleQuantityMcf": 0.004662,
                                                                    "mcf": 0.004662,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.004662,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2319,
                                                                    "parentRowNumber": 2164,
                                                                    "articleId": "208206",
                                                                    "description": "208206-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0009,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0009,
                                                                    "singleQuantityMcf": 0.004662,
                                                                    "mcf": 0.004662,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.004662,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2320,
                                                                    "parentRowNumber": 2164,
                                                                    "articleId": "208206",
                                                                    "description": "208206-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0009,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0009,
                                                                    "singleQuantityMcf": 0.004662,
                                                                    "mcf": 0.004662,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.004662,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2321,
                                                                    "parentRowNumber": 2164,
                                                                    "articleId": "208206",
                                                                    "description": "208206-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0009,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0009,
                                                                    "singleQuantityMcf": 0.004662,
                                                                    "mcf": 0.004662,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.004662,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2322,
                                                                    "parentRowNumber": 2164,
                                                                    "articleId": "208206",
                                                                    "description": "208206-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0009,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0009,
                                                                    "singleQuantityMcf": 0.004662,
                                                                    "mcf": 0.004662,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.004662,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2323,
                                                                    "parentRowNumber": 2164,
                                                                    "articleId": "208206",
                                                                    "description": "208206-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0009,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0009,
                                                                    "singleQuantityMcf": 0.004662,
                                                                    "mcf": 0.004662,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.004662,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2324,
                                                                    "parentRowNumber": 2164,
                                                                    "articleId": "208206",
                                                                    "description": "208206-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0009,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0009,
                                                                    "singleQuantityMcf": 0.004662,
                                                                    "mcf": 0.004662,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.004662,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2325,
                                                                    "parentRowNumber": 2164,
                                                                    "articleId": "208206",
                                                                    "description": "208206-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0009,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0009,
                                                                    "singleQuantityMcf": 0.004662,
                                                                    "mcf": 0.004662,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.004662,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2326,
                                                                    "parentRowNumber": 2164,
                                                                    "articleId": "208206",
                                                                    "description": "208206-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0009,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0009,
                                                                    "singleQuantityMcf": 0.004662,
                                                                    "mcf": 0.004662,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.004662,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2327,
                                                                    "parentRowNumber": 2164,
                                                                    "articleId": "208206",
                                                                    "description": "208206-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0009,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0009,
                                                                    "singleQuantityMcf": 0.004662,
                                                                    "mcf": 0.004662,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.004662,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2328,
                                                                    "parentRowNumber": 2164,
                                                                    "articleId": "208206",
                                                                    "description": "208206-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0009,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0009,
                                                                    "singleQuantityMcf": 0.004662,
                                                                    "mcf": 0.004662,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.004662,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2329,
                                                                    "parentRowNumber": 2164,
                                                                    "articleId": "208206",
                                                                    "description": "208206-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0009,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0009,
                                                                    "singleQuantityMcf": 0.004662,
                                                                    "mcf": 0.004662,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.004662,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2330,
                                                                    "parentRowNumber": 2164,
                                                                    "articleId": "208206",
                                                                    "description": "208206-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0009,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0009,
                                                                    "singleQuantityMcf": 0.004662,
                                                                    "mcf": 0.004662,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.004662,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2331,
                                                                    "parentRowNumber": 2164,
                                                                    "articleId": "208206",
                                                                    "description": "208206-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0009,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0009,
                                                                    "singleQuantityMcf": 0.004662,
                                                                    "mcf": 0.004662,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.004662,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2332,
                                                                    "parentRowNumber": 2164,
                                                                    "articleId": "208206",
                                                                    "description": "208206-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0009,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0009,
                                                                    "singleQuantityMcf": 0.004662,
                                                                    "mcf": 0.004662,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.004662,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2333,
                                                                    "parentRowNumber": 2164,
                                                                    "articleId": "208206",
                                                                    "description": "208206-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0009,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0009,
                                                                    "singleQuantityMcf": 0.004662,
                                                                    "mcf": 0.004662,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.004662,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2334,
                                                                    "parentRowNumber": 2164,
                                                                    "articleId": "208206",
                                                                    "description": "208206-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0009,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0009,
                                                                    "singleQuantityMcf": 0.004662,
                                                                    "mcf": 0.004662,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.004662,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2335,
                                                                    "parentRowNumber": 2164,
                                                                    "articleId": "208206",
                                                                    "description": "208206-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0009,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0009,
                                                                    "singleQuantityMcf": 0.004662,
                                                                    "mcf": 0.004662,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.004662,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2336,
                                                                    "parentRowNumber": 2164,
                                                                    "articleId": "208206",
                                                                    "description": "208206-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0009,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0009,
                                                                    "singleQuantityMcf": 0.004662,
                                                                    "mcf": 0.004662,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.004662,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2337,
                                                                    "parentRowNumber": 2164,
                                                                    "articleId": "208206",
                                                                    "description": "208206-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0009,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0009,
                                                                    "singleQuantityMcf": 0.004662,
                                                                    "mcf": 0.004662,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.004662,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2338,
                                                                    "parentRowNumber": 2164,
                                                                    "articleId": "208206",
                                                                    "description": "208206-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0009,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0009,
                                                                    "singleQuantityMcf": 0.004662,
                                                                    "mcf": 0.004662,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.004662,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2339,
                                                                    "parentRowNumber": 2164,
                                                                    "articleId": "208206",
                                                                    "description": "208206-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0009,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0009,
                                                                    "singleQuantityMcf": 0.004662,
                                                                    "mcf": 0.004662,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.004662,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2340,
                                                                    "parentRowNumber": 2164,
                                                                    "articleId": "208206",
                                                                    "description": "208206-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0009,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0009,
                                                                    "singleQuantityMcf": 0.004662,
                                                                    "mcf": 0.004662,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.004662,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2341,
                                                                    "parentRowNumber": 2164,
                                                                    "articleId": "208208",
                                                                    "description": "208208-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0017,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0017,
                                                                    "singleQuantityMcf": 0.008806,
                                                                    "mcf": 0.008806,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.008806,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2342,
                                                                    "parentRowNumber": 2164,
                                                                    "articleId": "208208",
                                                                    "description": "208208-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0017,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0017,
                                                                    "singleQuantityMcf": 0.008806,
                                                                    "mcf": 0.008806,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.008806,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2343,
                                                                    "parentRowNumber": 2164,
                                                                    "articleId": "208208",
                                                                    "description": "208208-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0017,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0017,
                                                                    "singleQuantityMcf": 0.008806,
                                                                    "mcf": 0.008806,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.008806,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2344,
                                                                    "parentRowNumber": 2164,
                                                                    "articleId": "208208",
                                                                    "description": "208208-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0017,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0017,
                                                                    "singleQuantityMcf": 0.008806,
                                                                    "mcf": 0.008806,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.008806,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2345,
                                                                    "parentRowNumber": 2164,
                                                                    "articleId": "208208",
                                                                    "description": "208208-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0017,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0017,
                                                                    "singleQuantityMcf": 0.008806,
                                                                    "mcf": 0.008806,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.008806,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2346,
                                                                    "parentRowNumber": 2164,
                                                                    "articleId": "208208",
                                                                    "description": "208208-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0017,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0017,
                                                                    "singleQuantityMcf": 0.008806,
                                                                    "mcf": 0.008806,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.008806,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2347,
                                                                    "parentRowNumber": 2164,
                                                                    "articleId": "208208",
                                                                    "description": "208208-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0017,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0017,
                                                                    "singleQuantityMcf": 0.008806,
                                                                    "mcf": 0.008806,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.008806,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2348,
                                                                    "parentRowNumber": 2164,
                                                                    "articleId": "208208",
                                                                    "description": "208208-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0017,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0017,
                                                                    "singleQuantityMcf": 0.008806,
                                                                    "mcf": 0.008806,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.008806,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2349,
                                                                    "parentRowNumber": 2164,
                                                                    "articleId": "208208",
                                                                    "description": "208208-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0017,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0017,
                                                                    "singleQuantityMcf": 0.008806,
                                                                    "mcf": 0.008806,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.008806,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2350,
                                                                    "parentRowNumber": 2164,
                                                                    "articleId": "208208",
                                                                    "description": "208208-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0017,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0017,
                                                                    "singleQuantityMcf": 0.008806,
                                                                    "mcf": 0.008806,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.008806,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2351,
                                                                    "parentRowNumber": 2164,
                                                                    "articleId": "208208",
                                                                    "description": "208208-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0017,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0017,
                                                                    "singleQuantityMcf": 0.008806,
                                                                    "mcf": 0.008806,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.008806,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2352,
                                                                    "parentRowNumber": 2164,
                                                                    "articleId": "208208",
                                                                    "description": "208208-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0017,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0017,
                                                                    "singleQuantityMcf": 0.008806,
                                                                    "mcf": 0.008806,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.008806,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2353,
                                                                    "parentRowNumber": 2164,
                                                                    "articleId": "208405",
                                                                    "description": "208405-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0015,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0015,
                                                                    "singleQuantityMcf": 0.00777,
                                                                    "mcf": 0.00777,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.00777,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2354,
                                                                    "parentRowNumber": 2164,
                                                                    "articleId": "208405",
                                                                    "description": "208405-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0015,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0015,
                                                                    "singleQuantityMcf": 0.00777,
                                                                    "mcf": 0.00777,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.00777,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2355,
                                                                    "parentRowNumber": 2164,
                                                                    "articleId": "208405",
                                                                    "description": "208405-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0015,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0015,
                                                                    "singleQuantityMcf": 0.00777,
                                                                    "mcf": 0.00777,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.00777,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2356,
                                                                    "parentRowNumber": 2164,
                                                                    "articleId": "208405",
                                                                    "description": "208405-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0015,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0015,
                                                                    "singleQuantityMcf": 0.00777,
                                                                    "mcf": 0.00777,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.00777,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2357,
                                                                    "parentRowNumber": 2164,
                                                                    "articleId": "247206",
                                                                    "description": "247206-00-Sechskantmutter",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0029,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0029,
                                                                    "singleQuantityMcf": 0.015022,
                                                                    "mcf": 0.015022,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.015022,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2358,
                                                                    "parentRowNumber": 2164,
                                                                    "articleId": "247206",
                                                                    "description": "247206-00-Sechskantmutter",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0029,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0029,
                                                                    "singleQuantityMcf": 0.015022,
                                                                    "mcf": 0.015022,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.015022,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2359,
                                                                    "parentRowNumber": 2164,
                                                                    "articleId": "247206",
                                                                    "description": "247206-00-Sechskantmutter",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0029,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0029,
                                                                    "singleQuantityMcf": 0.015022,
                                                                    "mcf": 0.015022,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.015022,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2360,
                                                                    "parentRowNumber": 2164,
                                                                    "articleId": "247206",
                                                                    "description": "247206-00-Sechskantmutter",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0029,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0029,
                                                                    "singleQuantityMcf": 0.015022,
                                                                    "mcf": 0.015022,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.015022,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2361,
                                                                    "parentRowNumber": 2164,
                                                                    "articleId": "247206",
                                                                    "description": "247206-00-Sechskantmutter",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0029,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0029,
                                                                    "singleQuantityMcf": 0.015022,
                                                                    "mcf": 0.015022,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.015022,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2362,
                                                                    "parentRowNumber": 2164,
                                                                    "articleId": "247206",
                                                                    "description": "247206-00-Sechskantmutter",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0029,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0029,
                                                                    "singleQuantityMcf": 0.015022,
                                                                    "mcf": 0.015022,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.015022,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2363,
                                                                    "parentRowNumber": 2164,
                                                                    "articleId": "314581",
                                                                    "description": "314581-00-Transportband",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2364,
                                                                    "parentRowNumber": 2164,
                                                                    "articleId": "314581",
                                                                    "description": "314581-BB0007-00-Transportband",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 15.7437,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 15.7437,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2365,
                                                                    "parentRowNumber": 2164,
                                                                    "articleId": "387090",
                                                                    "description": "387090-00-Schwingmetall-Puffer",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0516,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0516,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2366,
                                                                    "parentRowNumber": 2164,
                                                                    "articleId": "457007",
                                                                    "description": "457007-03-Klemmstueck",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0465,
                                                                    "childrenWeight": 0.0465,
                                                                    "totalQuantityWeight": 0.0465,
                                                                    "singleQuantityMcf": 0.24087,
                                                                    "mcf": 0.24087,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.24087,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 2367,
                                                                            "parentRowNumber": 2366,
                                                                            "articleId": "1260038",
                                                                            "description": "1260038-00-Rundstahl",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0465,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0465,
                                                                            "singleQuantityMcf": 0.24087,
                                                                            "mcf": 0.24087,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.24087,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 2368,
                                                                    "parentRowNumber": 2164,
                                                                    "articleId": "457007",
                                                                    "description": "457007-03-Klemmstueck",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0465,
                                                                    "childrenWeight": 0.0465,
                                                                    "totalQuantityWeight": 0.0465,
                                                                    "singleQuantityMcf": 0.24087,
                                                                    "mcf": 0.24087,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.24087,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 2369,
                                                                            "parentRowNumber": 2368,
                                                                            "articleId": "1260038",
                                                                            "description": "1260038-00-Rundstahl",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0465,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0465,
                                                                            "singleQuantityMcf": 0.24087,
                                                                            "mcf": 0.24087,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.24087,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 2370,
                                                                    "parentRowNumber": 2164,
                                                                    "articleId": "457190",
                                                                    "description": "457190-01-Distanzplatte",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0012,
                                                                    "childrenWeight": 0.0012,
                                                                    "totalQuantityWeight": 0.0012,
                                                                    "singleQuantityMcf": 0.004079999999999999,
                                                                    "mcf": 0.004079999999999999,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.004079999999999999,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 2371,
                                                                            "parentRowNumber": 2370,
                                                                            "articleId": "162431",
                                                                            "description": "162431-00-Platte",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0012,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0012,
                                                                            "singleQuantityMcf": 0.004079999999999999,
                                                                            "mcf": 0.004079999999999999,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.004079999999999999,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 2372,
                                                                    "parentRowNumber": 2164,
                                                                    "articleId": "457190",
                                                                    "description": "457190-01-Distanzplatte",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0012,
                                                                    "childrenWeight": 0.0012,
                                                                    "totalQuantityWeight": 0.0012,
                                                                    "singleQuantityMcf": 0.004079999999999999,
                                                                    "mcf": 0.004079999999999999,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.004079999999999999,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 2373,
                                                                            "parentRowNumber": 2372,
                                                                            "articleId": "162431",
                                                                            "description": "162431-00-Platte",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0012,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0012,
                                                                            "singleQuantityMcf": 0.004079999999999999,
                                                                            "mcf": 0.004079999999999999,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.004079999999999999,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 2374,
                                                                    "parentRowNumber": 2164,
                                                                    "articleId": "46090640",
                                                                    "description": "46090640-00-Halter",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0358,
                                                                    "childrenWeight": 0.0358,
                                                                    "totalQuantityWeight": 0.0358,
                                                                    "singleQuantityMcf": 0.185444,
                                                                    "mcf": 0.185444,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.185444,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 2375,
                                                                            "parentRowNumber": 2374,
                                                                            "articleId": "1110169",
                                                                            "description": "1110169-00-Flachstahl",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0358,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0358,
                                                                            "singleQuantityMcf": 0.185444,
                                                                            "mcf": 0.185444,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.185444,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 2376,
                                                                    "parentRowNumber": 2164,
                                                                    "articleId": "46090640",
                                                                    "description": "46090640-00-Halter",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0358,
                                                                    "childrenWeight": 0.0358,
                                                                    "totalQuantityWeight": 0.0358,
                                                                    "singleQuantityMcf": 0.185444,
                                                                    "mcf": 0.185444,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.185444,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 2377,
                                                                            "parentRowNumber": 2376,
                                                                            "articleId": "1110169",
                                                                            "description": "1110169-00-Flachstahl",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0358,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0358,
                                                                            "singleQuantityMcf": 0.185444,
                                                                            "mcf": 0.185444,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.185444,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 2378,
                                                                    "parentRowNumber": 2164,
                                                                    "articleId": "46090644",
                                                                    "description": "46090644-03-Abdeckblech",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.5682,
                                                                    "childrenWeight": 0.5682,
                                                                    "totalQuantityWeight": 0.5682,
                                                                    "singleQuantityMcf": 2.943276,
                                                                    "mcf": 2.943276,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 2.943276,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 2379,
                                                                            "parentRowNumber": 2378,
                                                                            "articleId": "142603",
                                                                            "description": "142603-00-Edelstahlblech",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.5682,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.5682,
                                                                            "singleQuantityMcf": 2.943276,
                                                                            "mcf": 2.943276,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 2.943276,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 2380,
                                                                    "parentRowNumber": 2164,
                                                                    "articleId": "46090651",
                                                                    "description": "46090651-00-Welle",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.6033,
                                                                    "childrenWeight": 0.6033,
                                                                    "totalQuantityWeight": 0.6033,
                                                                    "singleQuantityMcf": 3.125094,
                                                                    "mcf": 3.125094,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 3.125094,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 2381,
                                                                            "parentRowNumber": 2380,
                                                                            "articleId": "1260015",
                                                                            "description": "1260015-00-Rundstahl",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.6033,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.6033,
                                                                            "singleQuantityMcf": 3.125094,
                                                                            "mcf": 3.125094,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 3.125094,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 2382,
                                                                    "parentRowNumber": 2164,
                                                                    "articleId": "46090660",
                                                                    "description": "46090660-02-Umlenkrolle",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.8614,
                                                                    "childrenWeight": 0.8614,
                                                                    "totalQuantityWeight": 0.8614,
                                                                    "singleQuantityMcf": 4.462052,
                                                                    "mcf": 4.462052,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 4.462052,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 2383,
                                                                            "parentRowNumber": 2382,
                                                                            "articleId": "1261271",
                                                                            "description": "1261271-00-Rohr",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.8614,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.8614,
                                                                            "singleQuantityMcf": 4.462052,
                                                                            "mcf": 4.462052,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 4.462052,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 2384,
                                                                    "parentRowNumber": 2164,
                                                                    "articleId": "46090661",
                                                                    "description": "46090661-02-Lagerflansch",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0625,
                                                                    "childrenWeight": 0.0625,
                                                                    "totalQuantityWeight": 0.0625,
                                                                    "singleQuantityMcf": 0.0588928,
                                                                    "mcf": 0.0588928,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.0588928,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 2385,
                                                                            "parentRowNumber": 2384,
                                                                            "articleId": "1232050",
                                                                            "description": "1232050-00-Rundstange",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0281,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0281,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        },
                                                                        {
                                                                            "rowNumber": 2386,
                                                                            "parentRowNumber": 2384,
                                                                            "articleId": "28216002",
                                                                            "description": "28216002-00-Radial-Rillenkugellager",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0344,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0344,
                                                                            "singleQuantityMcf": 0.0588928,
                                                                            "mcf": 0.0588928,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.0588928,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 2387,
                                                                    "parentRowNumber": 2164,
                                                                    "articleId": "46090661",
                                                                    "description": "46090661-02-Lagerflansch",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0625,
                                                                    "childrenWeight": 0.0625,
                                                                    "totalQuantityWeight": 0.0625,
                                                                    "singleQuantityMcf": 0.0588928,
                                                                    "mcf": 0.0588928,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.0588928,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 2388,
                                                                            "parentRowNumber": 2387,
                                                                            "articleId": "1232050",
                                                                            "description": "1232050-00-Rundstange",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0281,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0281,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        },
                                                                        {
                                                                            "rowNumber": 2389,
                                                                            "parentRowNumber": 2387,
                                                                            "articleId": "28216002",
                                                                            "description": "28216002-00-Radial-Rillenkugellager",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0344,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0344,
                                                                            "singleQuantityMcf": 0.0588928,
                                                                            "mcf": 0.0588928,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.0588928,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 2390,
                                                                    "parentRowNumber": 2164,
                                                                    "articleId": "46108067",
                                                                    "description": "46108067-00-Distanz",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0053,
                                                                    "childrenWeight": 0.0053,
                                                                    "totalQuantityWeight": 0.0053,
                                                                    "singleQuantityMcf": 0.027454,
                                                                    "mcf": 0.027454,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.027454,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 2391,
                                                                            "parentRowNumber": 2390,
                                                                            "articleId": "1260020",
                                                                            "description": "1260020-00-Rundstahl",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0053,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0053,
                                                                            "singleQuantityMcf": 0.027454,
                                                                            "mcf": 0.027454,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.027454,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 2392,
                                                                    "parentRowNumber": 2164,
                                                                    "articleId": "46108067",
                                                                    "description": "46108067-00-Distanz",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0053,
                                                                    "childrenWeight": 0.0053,
                                                                    "totalQuantityWeight": 0.0053,
                                                                    "singleQuantityMcf": 0.027454,
                                                                    "mcf": 0.027454,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.027454,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 2393,
                                                                            "parentRowNumber": 2392,
                                                                            "articleId": "1260020",
                                                                            "description": "1260020-00-Rundstahl",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0053,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0053,
                                                                            "singleQuantityMcf": 0.027454,
                                                                            "mcf": 0.027454,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.027454,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 2394,
                                                                    "parentRowNumber": 2164,
                                                                    "articleId": "46607746",
                                                                    "description": "46607746-00-Platte",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.1694,
                                                                    "childrenWeight": 0.1694,
                                                                    "totalQuantityWeight": 0.1694,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 2395,
                                                                            "parentRowNumber": 2394,
                                                                            "articleId": "141718",
                                                                            "description": "141718-00-Platte",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.1694,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.1694,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 2396,
                                                                    "parentRowNumber": 2164,
                                                                    "articleId": "46607746",
                                                                    "description": "46607746-00-Platte",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.1694,
                                                                    "childrenWeight": 0.1694,
                                                                    "totalQuantityWeight": 0.1694,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 2397,
                                                                            "parentRowNumber": 2396,
                                                                            "articleId": "141718",
                                                                            "description": "141718-00-Platte",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.1694,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.1694,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 2398,
                                                                    "parentRowNumber": 2164,
                                                                    "articleId": "46607746",
                                                                    "description": "46607746-00-Platte",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.1694,
                                                                    "childrenWeight": 0.1694,
                                                                    "totalQuantityWeight": 0.1694,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 2399,
                                                                            "parentRowNumber": 2398,
                                                                            "articleId": "141718",
                                                                            "description": "141718-00-Platte",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.1694,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.1694,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 2400,
                                                                    "parentRowNumber": 2164,
                                                                    "articleId": "46723175",
                                                                    "description": "46723175-00-Ueberleitblech",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0395,
                                                                    "childrenWeight": 0.0395,
                                                                    "totalQuantityWeight": 0.0395,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 2401,
                                                                            "parentRowNumber": 2400,
                                                                            "articleId": "166142",
                                                                            "description": "166142-00-Platte",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0395,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0395,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 2402,
                                                                    "parentRowNumber": 2164,
                                                                    "articleId": "46723176",
                                                                    "description": "46723176-00-Blech",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.7738,
                                                                    "childrenWeight": 0.7738,
                                                                    "totalQuantityWeight": 0.7738,
                                                                    "singleQuantityMcf": 4.008284,
                                                                    "mcf": 4.008284,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 4.008284,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 2403,
                                                                            "parentRowNumber": 2402,
                                                                            "articleId": "142608",
                                                                            "description": "142608-00-Edelstahlblech",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.7738,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.7738,
                                                                            "singleQuantityMcf": 4.008284,
                                                                            "mcf": 4.008284,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 4.008284,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 2404,
                                                                    "parentRowNumber": 2164,
                                                                    "articleId": "46869078",
                                                                    "description": "46869078-00-Buerste",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 2405,
                                                                            "parentRowNumber": 2404,
                                                                            "articleId": "308083",
                                                                            "description": "308083-00-Buerstenleiste",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 2406,
                                                                    "parentRowNumber": 2164,
                                                                    "articleId": "47087641",
                                                                    "description": "47087641-00-Blech",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0126,
                                                                    "childrenWeight": 0.0126,
                                                                    "totalQuantityWeight": 0.0126,
                                                                    "singleQuantityMcf": 0.06526799999999999,
                                                                    "mcf": 0.06526799999999999,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.06526799999999999,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 2407,
                                                                            "parentRowNumber": 2406,
                                                                            "articleId": "142604",
                                                                            "description": "142604-00-Edelstahlblech",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0126,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0126,
                                                                            "singleQuantityMcf": 0.06526799999999999,
                                                                            "mcf": 0.06526799999999999,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.06526799999999999,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 2408,
                                                                    "parentRowNumber": 2164,
                                                                    "articleId": "47087641",
                                                                    "description": "47087641-00-Blech",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0126,
                                                                    "childrenWeight": 0.0126,
                                                                    "totalQuantityWeight": 0.0126,
                                                                    "singleQuantityMcf": 0.06526799999999999,
                                                                    "mcf": 0.06526799999999999,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.06526799999999999,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 2409,
                                                                            "parentRowNumber": 2408,
                                                                            "articleId": "142604",
                                                                            "description": "142604-00-Edelstahlblech",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0126,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0126,
                                                                            "singleQuantityMcf": 0.06526799999999999,
                                                                            "mcf": 0.06526799999999999,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.06526799999999999,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 2410,
                                                                    "parentRowNumber": 2164,
                                                                    "articleId": "47106285",
                                                                    "description": "47106285-00-Platte",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 1.5389,
                                                                    "childrenWeight": 1.5389,
                                                                    "totalQuantityWeight": 1.5389,
                                                                    "singleQuantityMcf": 4.92448,
                                                                    "mcf": 4.92448,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 4.92448,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 2411,
                                                                            "parentRowNumber": 2410,
                                                                            "articleId": "162541",
                                                                            "description": "162541-01-Platte",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 1.5389,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 1.5389,
                                                                            "singleQuantityMcf": 4.92448,
                                                                            "mcf": 4.92448,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 4.92448,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 2412,
                                                                    "parentRowNumber": 2164,
                                                                    "articleId": "47106503",
                                                                    "description": "47106503-00-Saeule",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 1.5901,
                                                                    "childrenWeight": 1.5901,
                                                                    "totalQuantityWeight": 1.5901,
                                                                    "singleQuantityMcf": 8.236718,
                                                                    "mcf": 8.236718,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 8.236718,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 2413,
                                                                            "parentRowNumber": 2412,
                                                                            "articleId": "1255291",
                                                                            "description": "1255291-00-Rohr",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 1.5901,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 1.5901,
                                                                            "singleQuantityMcf": 8.236718,
                                                                            "mcf": 8.236718,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 8.236718,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 2414,
                                                                    "parentRowNumber": 2164,
                                                                    "articleId": "47106503",
                                                                    "description": "47106503-00-Saeule",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 1.5901,
                                                                    "childrenWeight": 1.5901,
                                                                    "totalQuantityWeight": 1.5901,
                                                                    "singleQuantityMcf": 8.236718,
                                                                    "mcf": 8.236718,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 8.236718,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 2415,
                                                                            "parentRowNumber": 2414,
                                                                            "articleId": "1255291",
                                                                            "description": "1255291-00-Rohr",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 1.5901,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 1.5901,
                                                                            "singleQuantityMcf": 8.236718,
                                                                            "mcf": 8.236718,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 8.236718,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 2416,
                                                                    "parentRowNumber": 2164,
                                                                    "articleId": "47106503",
                                                                    "description": "47106503-00-Saeule",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 1.5901,
                                                                    "childrenWeight": 1.5901,
                                                                    "totalQuantityWeight": 1.5901,
                                                                    "singleQuantityMcf": 8.236718,
                                                                    "mcf": 8.236718,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 8.236718,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 2417,
                                                                            "parentRowNumber": 2416,
                                                                            "articleId": "1255291",
                                                                            "description": "1255291-00-Rohr",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 1.5901,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 1.5901,
                                                                            "singleQuantityMcf": 8.236718,
                                                                            "mcf": 8.236718,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 8.236718,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 2418,
                                                                    "parentRowNumber": 2164,
                                                                    "articleId": "47106659",
                                                                    "description": "47106659-01-Platte",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.663,
                                                                    "childrenWeight": 0.663,
                                                                    "totalQuantityWeight": 0.663,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 2419,
                                                                            "parentRowNumber": 2418,
                                                                            "articleId": "141718",
                                                                            "description": "141718-00-Platte",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.663,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.663,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 2420,
                                                                    "parentRowNumber": 2164,
                                                                    "articleId": "47106740",
                                                                    "description": "47106740-00-Fuehrung",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 2421,
                                                                            "parentRowNumber": 2420,
                                                                            "articleId": "248942",
                                                                            "description": "248942-00-Profil-Alukern",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        },
                                                                        {
                                                                            "rowNumber": 2422,
                                                                            "parentRowNumber": 2420,
                                                                            "articleId": "248944",
                                                                            "description": "248944-01-Fuehrungsleiste",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 2423,
                                                                    "parentRowNumber": 2164,
                                                                    "articleId": "47108367",
                                                                    "description": "47108367-02-Grundplatte",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 13.5729,
                                                                    "childrenWeight": 13.5729,
                                                                    "totalQuantityWeight": 13.5729,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 2424,
                                                                            "parentRowNumber": 2423,
                                                                            "articleId": "141710",
                                                                            "description": "141710-00-Blech",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 13.5729,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 13.5729,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 2425,
                                                                    "parentRowNumber": 2164,
                                                                    "articleId": "47108381",
                                                                    "description": "47108381-00-Deckel",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 13.773300000000004,
                                                                    "childrenWeight": 13.773300000000004,
                                                                    "totalQuantityWeight": 13.773300000000004,
                                                                    "singleQuantityMcf": 71.34569400000001,
                                                                    "mcf": 71.34569400000001,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 71.34569400000001,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 2426,
                                                                            "parentRowNumber": 2425,
                                                                            "articleId": "142607",
                                                                            "description": "142607-00-Edelstahlblech",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 13.700500000000002,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 13.700500000000002,
                                                                            "singleQuantityMcf": 70.96859,
                                                                            "mcf": 70.96859,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 70.96859,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        },
                                                                        {
                                                                            "rowNumber": 2427,
                                                                            "parentRowNumber": 2425,
                                                                            "articleId": "21320540",
                                                                            "description": "21320540-00-Gewindestift",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0062,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0062,
                                                                            "singleQuantityMcf": 0.032116,
                                                                            "mcf": 0.032116,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.032116,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        },
                                                                        {
                                                                            "rowNumber": 2428,
                                                                            "parentRowNumber": 2425,
                                                                            "articleId": "21320540",
                                                                            "description": "21320540-00-Gewindestift",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0062,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0062,
                                                                            "singleQuantityMcf": 0.032116,
                                                                            "mcf": 0.032116,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.032116,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        },
                                                                        {
                                                                            "rowNumber": 2429,
                                                                            "parentRowNumber": 2425,
                                                                            "articleId": "21320540",
                                                                            "description": "21320540-00-Gewindestift",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0062,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0062,
                                                                            "singleQuantityMcf": 0.032116,
                                                                            "mcf": 0.032116,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.032116,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        },
                                                                        {
                                                                            "rowNumber": 2430,
                                                                            "parentRowNumber": 2425,
                                                                            "articleId": "21320540",
                                                                            "description": "21320540-00-Gewindestift",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0062,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0062,
                                                                            "singleQuantityMcf": 0.032116,
                                                                            "mcf": 0.032116,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.032116,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        },
                                                                        {
                                                                            "rowNumber": 2431,
                                                                            "parentRowNumber": 2425,
                                                                            "articleId": "21320540",
                                                                            "description": "21320540-00-Gewindestift",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0062,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0062,
                                                                            "singleQuantityMcf": 0.032116,
                                                                            "mcf": 0.032116,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.032116,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        },
                                                                        {
                                                                            "rowNumber": 2432,
                                                                            "parentRowNumber": 2425,
                                                                            "articleId": "21320540",
                                                                            "description": "21320540-00-Gewindestift",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0062,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0062,
                                                                            "singleQuantityMcf": 0.032116,
                                                                            "mcf": 0.032116,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.032116,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        },
                                                                        {
                                                                            "rowNumber": 2433,
                                                                            "parentRowNumber": 2425,
                                                                            "articleId": "21320540",
                                                                            "description": "21320540-00-Gewindestift",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0062,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0062,
                                                                            "singleQuantityMcf": 0.032116,
                                                                            "mcf": 0.032116,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.032116,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        },
                                                                        {
                                                                            "rowNumber": 2434,
                                                                            "parentRowNumber": 2425,
                                                                            "articleId": "21320540",
                                                                            "description": "21320540-00-Gewindestift",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0062,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0062,
                                                                            "singleQuantityMcf": 0.032116,
                                                                            "mcf": 0.032116,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.032116,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        },
                                                                        {
                                                                            "rowNumber": 2435,
                                                                            "parentRowNumber": 2425,
                                                                            "articleId": "21320540",
                                                                            "description": "21320540-00-Gewindestift",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0062,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0062,
                                                                            "singleQuantityMcf": 0.032116,
                                                                            "mcf": 0.032116,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.032116,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        },
                                                                        {
                                                                            "rowNumber": 2436,
                                                                            "parentRowNumber": 2425,
                                                                            "articleId": "21320540",
                                                                            "description": "21320540-00-Gewindestift",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0062,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0062,
                                                                            "singleQuantityMcf": 0.032116,
                                                                            "mcf": 0.032116,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.032116,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        },
                                                                        {
                                                                            "rowNumber": 2437,
                                                                            "parentRowNumber": 2425,
                                                                            "articleId": "21320608",
                                                                            "description": "21320608-00-Gewindestift",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0018,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0018,
                                                                            "singleQuantityMcf": 0.009324,
                                                                            "mcf": 0.009324,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.009324,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        },
                                                                        {
                                                                            "rowNumber": 2438,
                                                                            "parentRowNumber": 2425,
                                                                            "articleId": "21320608",
                                                                            "description": "21320608-00-Gewindestift",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0018,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0018,
                                                                            "singleQuantityMcf": 0.009324,
                                                                            "mcf": 0.009324,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.009324,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        },
                                                                        {
                                                                            "rowNumber": 2439,
                                                                            "parentRowNumber": 2425,
                                                                            "articleId": "21320608",
                                                                            "description": "21320608-00-Gewindestift",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0018,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0018,
                                                                            "singleQuantityMcf": 0.009324,
                                                                            "mcf": 0.009324,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.009324,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        },
                                                                        {
                                                                            "rowNumber": 2440,
                                                                            "parentRowNumber": 2425,
                                                                            "articleId": "21320608",
                                                                            "description": "21320608-00-Gewindestift",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0018,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0018,
                                                                            "singleQuantityMcf": 0.009324,
                                                                            "mcf": 0.009324,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.009324,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        },
                                                                        {
                                                                            "rowNumber": 2441,
                                                                            "parentRowNumber": 2425,
                                                                            "articleId": "21320608",
                                                                            "description": "21320608-00-Gewindestift",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0018,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0018,
                                                                            "singleQuantityMcf": 0.009324,
                                                                            "mcf": 0.009324,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.009324,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        },
                                                                        {
                                                                            "rowNumber": 2442,
                                                                            "parentRowNumber": 2425,
                                                                            "articleId": "21320608",
                                                                            "description": "21320608-00-Gewindestift",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0018,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0018,
                                                                            "singleQuantityMcf": 0.009324,
                                                                            "mcf": 0.009324,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.009324,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 2443,
                                                                    "parentRowNumber": 2164,
                                                                    "articleId": "47108401",
                                                                    "description": "47108401-00-Seitenwand",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 5.5145,
                                                                    "childrenWeight": 5.5145,
                                                                    "totalQuantityWeight": 5.5145,
                                                                    "singleQuantityMcf": 28.56511,
                                                                    "mcf": 28.56511,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 28.56511,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 2444,
                                                                            "parentRowNumber": 2443,
                                                                            "articleId": "21320615",
                                                                            "description": "21320615-00-Gewindestift",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0034,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0034,
                                                                            "singleQuantityMcf": 0.017612,
                                                                            "mcf": 0.017612,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.017612,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        },
                                                                        {
                                                                            "rowNumber": 2445,
                                                                            "parentRowNumber": 2443,
                                                                            "articleId": "21320615",
                                                                            "description": "21320615-00-Gewindestift",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0034,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0034,
                                                                            "singleQuantityMcf": 0.017612,
                                                                            "mcf": 0.017612,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.017612,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        },
                                                                        {
                                                                            "rowNumber": 2446,
                                                                            "parentRowNumber": 2443,
                                                                            "articleId": "21320615",
                                                                            "description": "21320615-00-Gewindestift",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0034,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0034,
                                                                            "singleQuantityMcf": 0.017612,
                                                                            "mcf": 0.017612,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.017612,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        },
                                                                        {
                                                                            "rowNumber": 2447,
                                                                            "parentRowNumber": 2443,
                                                                            "articleId": "21320615",
                                                                            "description": "21320615-00-Gewindestift",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0034,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0034,
                                                                            "singleQuantityMcf": 0.017612,
                                                                            "mcf": 0.017612,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.017612,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        },
                                                                        {
                                                                            "rowNumber": 2448,
                                                                            "parentRowNumber": 2443,
                                                                            "articleId": "21320615",
                                                                            "description": "21320615-00-Gewindestift",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0034,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0034,
                                                                            "singleQuantityMcf": 0.017612,
                                                                            "mcf": 0.017612,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.017612,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        },
                                                                        {
                                                                            "rowNumber": 2449,
                                                                            "parentRowNumber": 2443,
                                                                            "articleId": "21320615",
                                                                            "description": "21320615-00-Gewindestift",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0034,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0034,
                                                                            "singleQuantityMcf": 0.017612,
                                                                            "mcf": 0.017612,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.017612,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        },
                                                                        {
                                                                            "rowNumber": 2450,
                                                                            "parentRowNumber": 2443,
                                                                            "articleId": "46090639",
                                                                            "description": "46090639-01-Klotz",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0885,
                                                                            "childrenWeight": 0.0885,
                                                                            "totalQuantityWeight": 0.0885,
                                                                            "singleQuantityMcf": 0.45843,
                                                                            "mcf": 0.45843,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.45843,
                                                                            "expandable": false,
                                                                            "children": [
                                                                                {
                                                                                    "rowNumber": 2451,
                                                                                    "parentRowNumber": 2450,
                                                                                    "articleId": "1110175",
                                                                                    "description": "1110175-00-Flachstahl",
                                                                                    "hierarchy": 9,
                                                                                    "quantity": 1,
                                                                                    "weight": 0.0885,
                                                                                    "childrenWeight": 0,
                                                                                    "totalQuantityWeight": 0.0885,
                                                                                    "singleQuantityMcf": 0.45843,
                                                                                    "mcf": 0.45843,
                                                                                    "singleQuantityLcf": 0,
                                                                                    "lcf": 0,
                                                                                    "totalQuantityMcf": 0.45843,
                                                                                    "expandable": false,
                                                                                    "children": []
                                                                                }
                                                                            ]
                                                                        },
                                                                        {
                                                                            "rowNumber": 2452,
                                                                            "parentRowNumber": 2443,
                                                                            "articleId": "47108402",
                                                                            "description": "47108402-00-Seitenwand",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 5.4056,
                                                                            "childrenWeight": 5.4056,
                                                                            "totalQuantityWeight": 5.4056,
                                                                            "singleQuantityMcf": 28.001008,
                                                                            "mcf": 28.001008,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 28.001008,
                                                                            "expandable": false,
                                                                            "children": [
                                                                                {
                                                                                    "rowNumber": 2453,
                                                                                    "parentRowNumber": 2452,
                                                                                    "articleId": "142608",
                                                                                    "description": "142608-00-Edelstahlblech",
                                                                                    "hierarchy": 9,
                                                                                    "quantity": 1,
                                                                                    "weight": 5.4056,
                                                                                    "childrenWeight": 0,
                                                                                    "totalQuantityWeight": 5.4056,
                                                                                    "singleQuantityMcf": 28.001008,
                                                                                    "mcf": 28.001008,
                                                                                    "singleQuantityLcf": 0,
                                                                                    "lcf": 0,
                                                                                    "totalQuantityMcf": 28.001008,
                                                                                    "expandable": false,
                                                                                    "children": []
                                                                                }
                                                                            ]
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 2454,
                                                                    "parentRowNumber": 2164,
                                                                    "articleId": "47108544",
                                                                    "description": "47108544-00-Seitenwand",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 6.128299999999999,
                                                                    "childrenWeight": 6.128299999999999,
                                                                    "totalQuantityWeight": 6.128299999999999,
                                                                    "singleQuantityMcf": 31.744594,
                                                                    "mcf": 31.744594,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 31.744594,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 2455,
                                                                            "parentRowNumber": 2454,
                                                                            "articleId": "46090639",
                                                                            "description": "46090639-01-Klotz",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0885,
                                                                            "childrenWeight": 0.0885,
                                                                            "totalQuantityWeight": 0.0885,
                                                                            "singleQuantityMcf": 0.45843,
                                                                            "mcf": 0.45843,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.45843,
                                                                            "expandable": false,
                                                                            "children": [
                                                                                {
                                                                                    "rowNumber": 2456,
                                                                                    "parentRowNumber": 2455,
                                                                                    "articleId": "1110175",
                                                                                    "description": "1110175-00-Flachstahl",
                                                                                    "hierarchy": 9,
                                                                                    "quantity": 1,
                                                                                    "weight": 0.0885,
                                                                                    "childrenWeight": 0,
                                                                                    "totalQuantityWeight": 0.0885,
                                                                                    "singleQuantityMcf": 0.45843,
                                                                                    "mcf": 0.45843,
                                                                                    "singleQuantityLcf": 0,
                                                                                    "lcf": 0,
                                                                                    "totalQuantityMcf": 0.45843,
                                                                                    "expandable": false,
                                                                                    "children": []
                                                                                }
                                                                            ]
                                                                        },
                                                                        {
                                                                            "rowNumber": 2457,
                                                                            "parentRowNumber": 2454,
                                                                            "articleId": "47108545",
                                                                            "description": "47108545-00-Seitenwand",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 6.0398,
                                                                            "childrenWeight": 6.0398,
                                                                            "totalQuantityWeight": 6.0398,
                                                                            "singleQuantityMcf": 31.286164,
                                                                            "mcf": 31.286164,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 31.286164,
                                                                            "expandable": false,
                                                                            "children": [
                                                                                {
                                                                                    "rowNumber": 2458,
                                                                                    "parentRowNumber": 2457,
                                                                                    "articleId": "142608",
                                                                                    "description": "142608-00-Edelstahlblech",
                                                                                    "hierarchy": 9,
                                                                                    "quantity": 1,
                                                                                    "weight": 6.0398,
                                                                                    "childrenWeight": 0,
                                                                                    "totalQuantityWeight": 6.0398,
                                                                                    "singleQuantityMcf": 31.286164,
                                                                                    "mcf": 31.286164,
                                                                                    "singleQuantityLcf": 0,
                                                                                    "lcf": 0,
                                                                                    "totalQuantityMcf": 31.286164,
                                                                                    "expandable": false,
                                                                                    "children": []
                                                                                }
                                                                            ]
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 2459,
                                                                    "parentRowNumber": 2164,
                                                                    "articleId": "47219931",
                                                                    "description": "47219931-00-Fuehrung",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 12.6078,
                                                                    "childrenWeight": 12.6078,
                                                                    "totalQuantityWeight": 12.6078,
                                                                    "singleQuantityMcf": 149.4690048,
                                                                    "mcf": 149.4690048,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 149.4690048,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 2460,
                                                                            "parentRowNumber": 2459,
                                                                            "articleId": "28925011000",
                                                                            "description": "28925011000-00-Fuehrungsschiene",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 12.5562,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 12.5562,
                                                                            "singleQuantityMcf": 149.4690048,
                                                                            "mcf": 149.4690048,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 149.4690048,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        },
                                                                        {
                                                                            "rowNumber": 2461,
                                                                            "parentRowNumber": 2459,
                                                                            "articleId": "387090",
                                                                            "description": "387090-00-Schwingmetall-Puffer",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0516,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0516,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 2462,
                                                                    "parentRowNumber": 2164,
                                                                    "articleId": "47219931",
                                                                    "description": "47219931-00-Fuehrung",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 12.6078,
                                                                    "childrenWeight": 12.6078,
                                                                    "totalQuantityWeight": 12.6078,
                                                                    "singleQuantityMcf": 149.4690048,
                                                                    "mcf": 149.4690048,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 149.4690048,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 2463,
                                                                            "parentRowNumber": 2462,
                                                                            "articleId": "28925011000",
                                                                            "description": "28925011000-00-Fuehrungsschiene",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 12.5562,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 12.5562,
                                                                            "singleQuantityMcf": 149.4690048,
                                                                            "mcf": 149.4690048,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 149.4690048,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        },
                                                                        {
                                                                            "rowNumber": 2464,
                                                                            "parentRowNumber": 2462,
                                                                            "articleId": "387090",
                                                                            "description": "387090-00-Schwingmetall-Puffer",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0516,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0516,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 2465,
                                                                    "parentRowNumber": 2164,
                                                                    "articleId": "47233103",
                                                                    "description": "47233103-00-Platte",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 3.1137,
                                                                    "childrenWeight": 3.1137,
                                                                    "totalQuantityWeight": 3.1137,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 2466,
                                                                            "parentRowNumber": 2465,
                                                                            "articleId": "141716",
                                                                            "description": "141716-00-Platte",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 3.1137,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 3.1137,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 2467,
                                                                    "parentRowNumber": 2164,
                                                                    "articleId": "47233108",
                                                                    "description": "47233108-00-Fuehrung",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 10.9522,
                                                                    "childrenWeight": 10.9522,
                                                                    "totalQuantityWeight": 10.9522,
                                                                    "singleQuantityMcf": 130.37498879999998,
                                                                    "mcf": 130.37498879999998,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 130.37498879999998,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 2468,
                                                                            "parentRowNumber": 2467,
                                                                            "articleId": "268110",
                                                                            "description": "268110-00-Welle",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 10.9522,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 10.9522,
                                                                            "singleQuantityMcf": 130.37498879999998,
                                                                            "mcf": 130.37498879999998,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 130.37498879999998,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 2469,
                                                                    "parentRowNumber": 2164,
                                                                    "articleId": "47233108",
                                                                    "description": "47233108-00-Fuehrung",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 10.9522,
                                                                    "childrenWeight": 10.9522,
                                                                    "totalQuantityWeight": 10.9522,
                                                                    "singleQuantityMcf": 130.37498879999998,
                                                                    "mcf": 130.37498879999998,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 130.37498879999998,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 2470,
                                                                            "parentRowNumber": 2469,
                                                                            "articleId": "268110",
                                                                            "description": "268110-00-Welle",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 10.9522,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 10.9522,
                                                                            "singleQuantityMcf": 130.37498879999998,
                                                                            "mcf": 130.37498879999998,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 130.37498879999998,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 2471,
                                                                    "parentRowNumber": 2164,
                                                                    "articleId": "51160925",
                                                                    "description": "51160925-00-EC DriveS",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 6.4058,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 6.4058,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2472,
                                                                    "parentRowNumber": 2164,
                                                                    "articleId": "57423122",
                                                                    "description": "57423122-01-Verdrahtungskanal",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 2473,
                                                                            "parentRowNumber": 2472,
                                                                            "articleId": "57423122_01",
                                                                            "description": "57423122_01-00",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        },
                                                                        {
                                                                            "rowNumber": 2474,
                                                                            "parentRowNumber": 2472,
                                                                            "articleId": "57423122_02",
                                                                            "description": "57423122_02-00",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 2475,
                                                                    "parentRowNumber": 2164,
                                                                    "articleId": "57423122",
                                                                    "description": "57423122-01-Verdrahtungskanal",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 2476,
                                                                            "parentRowNumber": 2475,
                                                                            "articleId": "57423122_01",
                                                                            "description": "57423122_01-00",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        },
                                                                        {
                                                                            "rowNumber": 2477,
                                                                            "parentRowNumber": 2475,
                                                                            "articleId": "57423122_02",
                                                                            "description": "57423122_02-00",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 2478,
                                                                    "parentRowNumber": 2164,
                                                                    "articleId": "57550123",
                                                                    "description": "57550123-02-Verschraubung",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2479,
                                                                    "parentRowNumber": 2164,
                                                                    "articleId": "57550134",
                                                                    "description": "57550134-02-Verschraubung",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0813,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0813,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2480,
                                                                    "parentRowNumber": 2164,
                                                                    "articleId": "575720",
                                                                    "description": "575720-00-Schelle",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0028,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0028,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2481,
                                                                    "parentRowNumber": 2164,
                                                                    "articleId": "575720",
                                                                    "description": "575720-00-Schelle",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0028,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0028,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2482,
                                                                    "parentRowNumber": 2164,
                                                                    "articleId": "575720",
                                                                    "description": "575720-00-Schelle",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0028,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0028,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2483,
                                                                    "parentRowNumber": 2164,
                                                                    "articleId": "575720",
                                                                    "description": "575720-00-Schelle",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0028,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0028,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2484,
                                                                    "parentRowNumber": 2164,
                                                                    "articleId": "575720",
                                                                    "description": "575720-00-Schelle",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0028,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0028,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2485,
                                                                    "parentRowNumber": 2164,
                                                                    "articleId": "575720",
                                                                    "description": "575720-00-Schelle",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0028,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0028,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": []
                                                                }
                                                            ]
                                                        }
                                                    ]
                                                }
                                            ]
                                        },
                                        {
                                            "rowNumber": 2486,
                                            "parentRowNumber": 2161,
                                            "articleId": "33847",
                                            "description": "33847-20DL20-02-Einschiebeeinheit",
                                            "hierarchy": 4,
                                            "quantity": 1,
                                            "weight": 15.442699999999986,
                                            "childrenWeight": 15.442699999999986,
                                            "totalQuantityWeight": 15.442699999999986,
                                            "singleQuantityMcf": 12.971438400000013,
                                            "mcf": 12.971438400000013,
                                            "singleQuantityLcf": 0,
                                            "lcf": 0,
                                            "totalQuantityMcf": 12.971438400000013,
                                            "expandable": false,
                                            "children": [
                                                {
                                                    "rowNumber": 2487,
                                                    "parentRowNumber": 2486,
                                                    "articleId": "MM0000004491",
                                                    "description": "MM0000004491-03-Einschiebeeinheit",
                                                    "hierarchy": 5,
                                                    "quantity": 1,
                                                    "weight": 15.442699999999986,
                                                    "childrenWeight": 15.442699999999986,
                                                    "totalQuantityWeight": 15.442699999999986,
                                                    "singleQuantityMcf": 12.971438400000013,
                                                    "mcf": 12.971438400000013,
                                                    "singleQuantityLcf": 0,
                                                    "lcf": 0,
                                                    "totalQuantityMcf": 12.971438400000013,
                                                    "expandable": false,
                                                    "children": [
                                                        {
                                                            "rowNumber": 2488,
                                                            "parentRowNumber": 2487,
                                                            "articleId": "20120410",
                                                            "description": "20120410-00-Sechskantschraube",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0019,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0019,
                                                            "singleQuantityMcf": 0.009842,
                                                            "mcf": 0.009842,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.009842,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 2489,
                                                            "parentRowNumber": 2487,
                                                            "articleId": "20120410",
                                                            "description": "20120410-00-Sechskantschraube",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0019,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0019,
                                                            "singleQuantityMcf": 0.009842,
                                                            "mcf": 0.009842,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.009842,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 2490,
                                                            "parentRowNumber": 2487,
                                                            "articleId": "20120410",
                                                            "description": "20120410-00-Sechskantschraube",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0019,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0019,
                                                            "singleQuantityMcf": 0.009842,
                                                            "mcf": 0.009842,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.009842,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 2491,
                                                            "parentRowNumber": 2487,
                                                            "articleId": "20120410",
                                                            "description": "20120410-00-Sechskantschraube",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0019,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0019,
                                                            "singleQuantityMcf": 0.009842,
                                                            "mcf": 0.009842,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.009842,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 2492,
                                                            "parentRowNumber": 2487,
                                                            "articleId": "20120410",
                                                            "description": "20120410-00-Sechskantschraube",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0019,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0019,
                                                            "singleQuantityMcf": 0.009842,
                                                            "mcf": 0.009842,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.009842,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 2493,
                                                            "parentRowNumber": 2487,
                                                            "articleId": "20120410",
                                                            "description": "20120410-00-Sechskantschraube",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0019,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0019,
                                                            "singleQuantityMcf": 0.009842,
                                                            "mcf": 0.009842,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.009842,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 2494,
                                                            "parentRowNumber": 2487,
                                                            "articleId": "20120410",
                                                            "description": "20120410-00-Sechskantschraube",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0019,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0019,
                                                            "singleQuantityMcf": 0.009842,
                                                            "mcf": 0.009842,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.009842,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 2495,
                                                            "parentRowNumber": 2487,
                                                            "articleId": "20120410",
                                                            "description": "20120410-00-Sechskantschraube",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0019,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0019,
                                                            "singleQuantityMcf": 0.009842,
                                                            "mcf": 0.009842,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.009842,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 2496,
                                                            "parentRowNumber": 2487,
                                                            "articleId": "20120410",
                                                            "description": "20120410-00-Sechskantschraube",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0019,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0019,
                                                            "singleQuantityMcf": 0.009842,
                                                            "mcf": 0.009842,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.009842,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 2497,
                                                            "parentRowNumber": 2487,
                                                            "articleId": "20120410",
                                                            "description": "20120410-00-Sechskantschraube",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0019,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0019,
                                                            "singleQuantityMcf": 0.009842,
                                                            "mcf": 0.009842,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.009842,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 2498,
                                                            "parentRowNumber": 2487,
                                                            "articleId": "20120512",
                                                            "description": "20120512-00-Sechskantschraube",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0033,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0033,
                                                            "singleQuantityMcf": 0.017093999999999998,
                                                            "mcf": 0.017093999999999998,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.017093999999999998,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 2499,
                                                            "parentRowNumber": 2487,
                                                            "articleId": "20120512",
                                                            "description": "20120512-00-Sechskantschraube",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0033,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0033,
                                                            "singleQuantityMcf": 0.017093999999999998,
                                                            "mcf": 0.017093999999999998,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.017093999999999998,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 2500,
                                                            "parentRowNumber": 2487,
                                                            "articleId": "20120512",
                                                            "description": "20120512-00-Sechskantschraube",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0033,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0033,
                                                            "singleQuantityMcf": 0.017093999999999998,
                                                            "mcf": 0.017093999999999998,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.017093999999999998,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 2501,
                                                            "parentRowNumber": 2487,
                                                            "articleId": "20120520",
                                                            "description": "20120520-00-Sechskantschraube",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0045,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0045,
                                                            "singleQuantityMcf": 0.02331,
                                                            "mcf": 0.02331,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.02331,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 2502,
                                                            "parentRowNumber": 2487,
                                                            "articleId": "20120520",
                                                            "description": "20120520-00-Sechskantschraube",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0045,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0045,
                                                            "singleQuantityMcf": 0.02331,
                                                            "mcf": 0.02331,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.02331,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 2503,
                                                            "parentRowNumber": 2487,
                                                            "articleId": "20120620",
                                                            "description": "20120620-00-Sechskantschraube",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.007,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.007,
                                                            "singleQuantityMcf": 0.03626,
                                                            "mcf": 0.03626,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.03626,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 2504,
                                                            "parentRowNumber": 2487,
                                                            "articleId": "20120620",
                                                            "description": "20120620-00-Sechskantschraube",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.007,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.007,
                                                            "singleQuantityMcf": 0.03626,
                                                            "mcf": 0.03626,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.03626,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 2505,
                                                            "parentRowNumber": 2487,
                                                            "articleId": "20120635",
                                                            "description": "20120635-00-Sechskantschraube",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0105,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0105,
                                                            "singleQuantityMcf": 0.05439,
                                                            "mcf": 0.05439,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.05439,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 2506,
                                                            "parentRowNumber": 2487,
                                                            "articleId": "20120635",
                                                            "description": "20120635-00-Sechskantschraube",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0105,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0105,
                                                            "singleQuantityMcf": 0.05439,
                                                            "mcf": 0.05439,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.05439,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 2507,
                                                            "parentRowNumber": 2487,
                                                            "articleId": "20120840",
                                                            "description": "20120840-00-Sechskantschraube",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0221,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0221,
                                                            "singleQuantityMcf": 0.114478,
                                                            "mcf": 0.114478,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.114478,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 2508,
                                                            "parentRowNumber": 2487,
                                                            "articleId": "20120840",
                                                            "description": "20120840-00-Sechskantschraube",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0221,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0221,
                                                            "singleQuantityMcf": 0.114478,
                                                            "mcf": 0.114478,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.114478,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 2509,
                                                            "parentRowNumber": 2487,
                                                            "articleId": "20220625",
                                                            "description": "20220625-01-Zylinderschraube",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0075,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0075,
                                                            "singleQuantityMcf": 0.03885,
                                                            "mcf": 0.03885,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.03885,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 2510,
                                                            "parentRowNumber": 2487,
                                                            "articleId": "20230330",
                                                            "description": "20230330-01-Zylinderschraube",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0021,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0021,
                                                            "singleQuantityMcf": 0.0035952,
                                                            "mcf": 0.0035952,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.0035952,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 2511,
                                                            "parentRowNumber": 2487,
                                                            "articleId": "20230330",
                                                            "description": "20230330-01-Zylinderschraube",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0021,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0021,
                                                            "singleQuantityMcf": 0.0035952,
                                                            "mcf": 0.0035952,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.0035952,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 2512,
                                                            "parentRowNumber": 2487,
                                                            "articleId": "205206",
                                                            "description": "205206-00-Sechskantmutter",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0026,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0026,
                                                            "singleQuantityMcf": 0.013468,
                                                            "mcf": 0.013468,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.013468,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 2513,
                                                            "parentRowNumber": 2487,
                                                            "articleId": "20720406",
                                                            "description": "20720406-00-Gewindestift",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0005,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0005,
                                                            "singleQuantityMcf": 0.00259,
                                                            "mcf": 0.00259,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.00259,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 2514,
                                                            "parentRowNumber": 2487,
                                                            "articleId": "20720505",
                                                            "description": "20720505-00-Gewindestift",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0006,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0006,
                                                            "singleQuantityMcf": 0.003108,
                                                            "mcf": 0.003108,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.003108,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 2515,
                                                            "parentRowNumber": 2487,
                                                            "articleId": "208204",
                                                            "description": "208204-00-Scheibe",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0003,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0003,
                                                            "singleQuantityMcf": 0.0015539999999999998,
                                                            "mcf": 0.0015539999999999998,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.0015539999999999998,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 2516,
                                                            "parentRowNumber": 2487,
                                                            "articleId": "208204",
                                                            "description": "208204-00-Scheibe",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0003,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0003,
                                                            "singleQuantityMcf": 0.0015539999999999998,
                                                            "mcf": 0.0015539999999999998,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.0015539999999999998,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 2517,
                                                            "parentRowNumber": 2487,
                                                            "articleId": "208204",
                                                            "description": "208204-00-Scheibe",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0003,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0003,
                                                            "singleQuantityMcf": 0.0015539999999999998,
                                                            "mcf": 0.0015539999999999998,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.0015539999999999998,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 2518,
                                                            "parentRowNumber": 2487,
                                                            "articleId": "208204",
                                                            "description": "208204-00-Scheibe",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0003,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0003,
                                                            "singleQuantityMcf": 0.0015539999999999998,
                                                            "mcf": 0.0015539999999999998,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.0015539999999999998,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 2519,
                                                            "parentRowNumber": 2487,
                                                            "articleId": "208204",
                                                            "description": "208204-00-Scheibe",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0003,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0003,
                                                            "singleQuantityMcf": 0.0015539999999999998,
                                                            "mcf": 0.0015539999999999998,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.0015539999999999998,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 2520,
                                                            "parentRowNumber": 2487,
                                                            "articleId": "208204",
                                                            "description": "208204-00-Scheibe",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0003,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0003,
                                                            "singleQuantityMcf": 0.0015539999999999998,
                                                            "mcf": 0.0015539999999999998,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.0015539999999999998,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 2521,
                                                            "parentRowNumber": 2487,
                                                            "articleId": "208204",
                                                            "description": "208204-00-Scheibe",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0003,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0003,
                                                            "singleQuantityMcf": 0.0015539999999999998,
                                                            "mcf": 0.0015539999999999998,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.0015539999999999998,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 2522,
                                                            "parentRowNumber": 2487,
                                                            "articleId": "208204",
                                                            "description": "208204-00-Scheibe",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0003,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0003,
                                                            "singleQuantityMcf": 0.0015539999999999998,
                                                            "mcf": 0.0015539999999999998,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.0015539999999999998,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 2523,
                                                            "parentRowNumber": 2487,
                                                            "articleId": "208204",
                                                            "description": "208204-00-Scheibe",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0003,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0003,
                                                            "singleQuantityMcf": 0.0015539999999999998,
                                                            "mcf": 0.0015539999999999998,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.0015539999999999998,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 2524,
                                                            "parentRowNumber": 2487,
                                                            "articleId": "208204",
                                                            "description": "208204-00-Scheibe",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0003,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0003,
                                                            "singleQuantityMcf": 0.0015539999999999998,
                                                            "mcf": 0.0015539999999999998,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.0015539999999999998,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 2525,
                                                            "parentRowNumber": 2487,
                                                            "articleId": "208205",
                                                            "description": "208205-00-Scheibe",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0004,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0004,
                                                            "singleQuantityMcf": 0.002072,
                                                            "mcf": 0.002072,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.002072,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 2526,
                                                            "parentRowNumber": 2487,
                                                            "articleId": "208205",
                                                            "description": "208205-00-Scheibe",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0004,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0004,
                                                            "singleQuantityMcf": 0.002072,
                                                            "mcf": 0.002072,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.002072,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 2527,
                                                            "parentRowNumber": 2487,
                                                            "articleId": "208205",
                                                            "description": "208205-00-Scheibe",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0004,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0004,
                                                            "singleQuantityMcf": 0.002072,
                                                            "mcf": 0.002072,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.002072,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 2528,
                                                            "parentRowNumber": 2487,
                                                            "articleId": "208205",
                                                            "description": "208205-00-Scheibe",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0004,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0004,
                                                            "singleQuantityMcf": 0.002072,
                                                            "mcf": 0.002072,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.002072,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 2529,
                                                            "parentRowNumber": 2487,
                                                            "articleId": "208205",
                                                            "description": "208205-00-Scheibe",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0004,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0004,
                                                            "singleQuantityMcf": 0.002072,
                                                            "mcf": 0.002072,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.002072,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 2530,
                                                            "parentRowNumber": 2487,
                                                            "articleId": "208206",
                                                            "description": "208206-00-Scheibe",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0009,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0009,
                                                            "singleQuantityMcf": 0.004662,
                                                            "mcf": 0.004662,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.004662,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 2531,
                                                            "parentRowNumber": 2487,
                                                            "articleId": "208206",
                                                            "description": "208206-00-Scheibe",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0009,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0009,
                                                            "singleQuantityMcf": 0.004662,
                                                            "mcf": 0.004662,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.004662,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 2532,
                                                            "parentRowNumber": 2487,
                                                            "articleId": "208208",
                                                            "description": "208208-00-Scheibe",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0017,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0017,
                                                            "singleQuantityMcf": 0.008806,
                                                            "mcf": 0.008806,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.008806,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 2533,
                                                            "parentRowNumber": 2487,
                                                            "articleId": "208208",
                                                            "description": "208208-00-Scheibe",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0017,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0017,
                                                            "singleQuantityMcf": 0.008806,
                                                            "mcf": 0.008806,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.008806,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 2534,
                                                            "parentRowNumber": 2487,
                                                            "articleId": "208210",
                                                            "description": "208210-00-Scheibe",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0033,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0033,
                                                            "singleQuantityMcf": 0.017093999999999998,
                                                            "mcf": 0.017093999999999998,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.017093999999999998,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 2535,
                                                            "parentRowNumber": 2487,
                                                            "articleId": "208210",
                                                            "description": "208210-00-Scheibe",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0033,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0033,
                                                            "singleQuantityMcf": 0.017093999999999998,
                                                            "mcf": 0.017093999999999998,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.017093999999999998,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 2536,
                                                            "parentRowNumber": 2487,
                                                            "articleId": "208210",
                                                            "description": "208210-00-Scheibe",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0033,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0033,
                                                            "singleQuantityMcf": 0.017093999999999998,
                                                            "mcf": 0.017093999999999998,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.017093999999999998,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 2537,
                                                            "parentRowNumber": 2487,
                                                            "articleId": "208210",
                                                            "description": "208210-00-Scheibe",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0033,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0033,
                                                            "singleQuantityMcf": 0.017093999999999998,
                                                            "mcf": 0.017093999999999998,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.017093999999999998,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 2538,
                                                            "parentRowNumber": 2487,
                                                            "articleId": "208210",
                                                            "description": "208210-00-Scheibe",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0033,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0033,
                                                            "singleQuantityMcf": 0.017093999999999998,
                                                            "mcf": 0.017093999999999998,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.017093999999999998,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 2539,
                                                            "parentRowNumber": 2487,
                                                            "articleId": "208210",
                                                            "description": "208210-00-Scheibe",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0033,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0033,
                                                            "singleQuantityMcf": 0.017093999999999998,
                                                            "mcf": 0.017093999999999998,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.017093999999999998,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 2540,
                                                            "parentRowNumber": 2487,
                                                            "articleId": "208406",
                                                            "description": "208406-00-Scheibe",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0028,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0028,
                                                            "singleQuantityMcf": 0.014504,
                                                            "mcf": 0.014504,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.014504,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 2541,
                                                            "parentRowNumber": 2487,
                                                            "articleId": "208406",
                                                            "description": "208406-00-Scheibe",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0028,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0028,
                                                            "singleQuantityMcf": 0.014504,
                                                            "mcf": 0.014504,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.014504,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 2542,
                                                            "parentRowNumber": 2487,
                                                            "articleId": "226210125",
                                                            "description": "226210125-00-Sechskantmutter",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0075,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0075,
                                                            "singleQuantityMcf": 0.03885,
                                                            "mcf": 0.03885,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.03885,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 2543,
                                                            "parentRowNumber": 2487,
                                                            "articleId": "226210125",
                                                            "description": "226210125-00-Sechskantmutter",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0075,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0075,
                                                            "singleQuantityMcf": 0.03885,
                                                            "mcf": 0.03885,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.03885,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 2544,
                                                            "parentRowNumber": 2487,
                                                            "articleId": "226210125",
                                                            "description": "226210125-00-Sechskantmutter",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0075,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0075,
                                                            "singleQuantityMcf": 0.03885,
                                                            "mcf": 0.03885,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.03885,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 2545,
                                                            "parentRowNumber": 2487,
                                                            "articleId": "226210125",
                                                            "description": "226210125-00-Sechskantmutter",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0075,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0075,
                                                            "singleQuantityMcf": 0.03885,
                                                            "mcf": 0.03885,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.03885,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 2546,
                                                            "parentRowNumber": 2487,
                                                            "articleId": "226210125",
                                                            "description": "226210125-00-Sechskantmutter",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0075,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0075,
                                                            "singleQuantityMcf": 0.03885,
                                                            "mcf": 0.03885,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.03885,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 2547,
                                                            "parentRowNumber": 2487,
                                                            "articleId": "226210125",
                                                            "description": "226210125-00-Sechskantmutter",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0075,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0075,
                                                            "singleQuantityMcf": 0.03885,
                                                            "mcf": 0.03885,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.03885,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 2548,
                                                            "parentRowNumber": 2487,
                                                            "articleId": "28925011001",
                                                            "description": "28925011001-02-Fuehrungsrolle",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.1366,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.1366,
                                                            "singleQuantityMcf": 0.707588,
                                                            "mcf": 0.707588,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.707588,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 2549,
                                                            "parentRowNumber": 2487,
                                                            "articleId": "28925011001",
                                                            "description": "28925011001-02-Fuehrungsrolle",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.1366,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.1366,
                                                            "singleQuantityMcf": 0.707588,
                                                            "mcf": 0.707588,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.707588,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 2550,
                                                            "parentRowNumber": 2487,
                                                            "articleId": "28925011001",
                                                            "description": "28925011001-02-Fuehrungsrolle",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.1366,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.1366,
                                                            "singleQuantityMcf": 0.707588,
                                                            "mcf": 0.707588,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.707588,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 2551,
                                                            "parentRowNumber": 2487,
                                                            "articleId": "28925011001",
                                                            "description": "28925011001-02-Fuehrungsrolle",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.1366,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.1366,
                                                            "singleQuantityMcf": 0.707588,
                                                            "mcf": 0.707588,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.707588,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 2552,
                                                            "parentRowNumber": 2487,
                                                            "articleId": "28925011002",
                                                            "description": "28925011002-02-Fuehrungsrolle",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.1366,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.1366,
                                                            "singleQuantityMcf": 0.707588,
                                                            "mcf": 0.707588,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.707588,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 2553,
                                                            "parentRowNumber": 2487,
                                                            "articleId": "28925011002",
                                                            "description": "28925011002-02-Fuehrungsrolle",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.1366,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.1366,
                                                            "singleQuantityMcf": 0.707588,
                                                            "mcf": 0.707588,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.707588,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 2554,
                                                            "parentRowNumber": 2487,
                                                            "articleId": "344150",
                                                            "description": "344150-00-Magnetische Feder",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.7981,
                                                            "childrenWeight": 0.7981,
                                                            "totalQuantityWeight": 0.7981,
                                                            "singleQuantityMcf": 0,
                                                            "mcf": 0,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0,
                                                            "expandable": false,
                                                            "children": [
                                                                {
                                                                    "rowNumber": 2555,
                                                                    "parentRowNumber": 2554,
                                                                    "articleId": "344150_1",
                                                                    "description": "344150_1-00-Schlitten-Linmot",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.3251,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.3251,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2556,
                                                                    "parentRowNumber": 2554,
                                                                    "articleId": "344150_2",
                                                                    "description": "344150_2-00-Stator-Linmot",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.473,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.473,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": []
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            "rowNumber": 2557,
                                                            "parentRowNumber": 2487,
                                                            "articleId": "37200702",
                                                            "description": "37200702-00-Drosselrueckschlagv.",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.019,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.019,
                                                            "singleQuantityMcf": 0,
                                                            "mcf": 0,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 2558,
                                                            "parentRowNumber": 2487,
                                                            "articleId": "37200702",
                                                            "description": "37200702-00-Drosselrueckschlagv.",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.019,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.019,
                                                            "singleQuantityMcf": 0,
                                                            "mcf": 0,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 2559,
                                                            "parentRowNumber": 2487,
                                                            "articleId": "37288921",
                                                            "description": "37288921-01-L-Steckverschraubung",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0346,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0346,
                                                            "singleQuantityMcf": 0,
                                                            "mcf": 0,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 2560,
                                                            "parentRowNumber": 2487,
                                                            "articleId": "37288921",
                                                            "description": "37288921-01-L-Steckverschraubung",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0346,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0346,
                                                            "singleQuantityMcf": 0,
                                                            "mcf": 0,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 2561,
                                                            "parentRowNumber": 2487,
                                                            "articleId": "37288944",
                                                            "description": "37288944-00-L-Steckverschraubung",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.009,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.009,
                                                            "singleQuantityMcf": 0,
                                                            "mcf": 0,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 2562,
                                                            "parentRowNumber": 2487,
                                                            "articleId": "3731205858",
                                                            "description": "3731205858-00-Schalldaempfer",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0022,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0022,
                                                            "singleQuantityMcf": 0.0050534,
                                                            "mcf": 0.0050534,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.0050534,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 2563,
                                                            "parentRowNumber": 2487,
                                                            "articleId": "3731205858",
                                                            "description": "3731205858-00-Schalldaempfer",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0022,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0022,
                                                            "singleQuantityMcf": 0.0050534,
                                                            "mcf": 0.0050534,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.0050534,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 2564,
                                                            "parentRowNumber": 2487,
                                                            "articleId": "373159503",
                                                            "description": "373159503-00-Haltewinkel",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0361,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0361,
                                                            "singleQuantityMcf": 0.0618032,
                                                            "mcf": 0.0618032,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.0618032,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 2565,
                                                            "parentRowNumber": 2487,
                                                            "articleId": "373161418",
                                                            "description": "373161418-00-Schalldaempfer",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0149,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0149,
                                                            "singleQuantityMcf": 0,
                                                            "mcf": 0,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 2566,
                                                            "parentRowNumber": 2487,
                                                            "articleId": "373161418",
                                                            "description": "373161418-00-Schalldaempfer",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0149,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0149,
                                                            "singleQuantityMcf": 0,
                                                            "mcf": 0,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 2567,
                                                            "parentRowNumber": 2487,
                                                            "articleId": "373186353",
                                                            "description": "373186353-00-L-Steckverschraubung",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0108,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0108,
                                                            "singleQuantityMcf": 0,
                                                            "mcf": 0,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 2568,
                                                            "parentRowNumber": 2487,
                                                            "articleId": "373186353",
                                                            "description": "373186353-00-L-Steckverschraubung",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0108,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0108,
                                                            "singleQuantityMcf": 0,
                                                            "mcf": 0,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 2569,
                                                            "parentRowNumber": 2487,
                                                            "articleId": "373186353",
                                                            "description": "373186353-00-L-Steckverschraubung",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0108,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0108,
                                                            "singleQuantityMcf": 0,
                                                            "mcf": 0,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 2570,
                                                            "parentRowNumber": 2487,
                                                            "articleId": "373192299",
                                                            "description": "373192299-00-Druckregelventil",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 1.2261,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 1.2261,
                                                            "singleQuantityMcf": 0,
                                                            "mcf": 0,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 2571,
                                                            "parentRowNumber": 2487,
                                                            "articleId": "37353090705",
                                                            "description": "37353090705-01-Linearantrieb",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 5.5147,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 5.5147,
                                                            "singleQuantityMcf": 0,
                                                            "mcf": 0,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 2572,
                                                            "parentRowNumber": 2487,
                                                            "articleId": "442107",
                                                            "description": "442107-00-Rollierrolle",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0023,
                                                            "childrenWeight": 0.0023,
                                                            "totalQuantityWeight": 0.0023,
                                                            "singleQuantityMcf": 0,
                                                            "mcf": 0,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0,
                                                            "expandable": false,
                                                            "children": [
                                                                {
                                                                    "rowNumber": 2573,
                                                                    "parentRowNumber": 2572,
                                                                    "articleId": "163205",
                                                                    "description": "163205-00-Gummiplatte",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0023,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0023,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": []
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            "rowNumber": 2574,
                                                            "parentRowNumber": 2487,
                                                            "articleId": "442107",
                                                            "description": "442107-00-Rollierrolle",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0023,
                                                            "childrenWeight": 0.0023,
                                                            "totalQuantityWeight": 0.0023,
                                                            "singleQuantityMcf": 0,
                                                            "mcf": 0,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0,
                                                            "expandable": false,
                                                            "children": [
                                                                {
                                                                    "rowNumber": 2575,
                                                                    "parentRowNumber": 2574,
                                                                    "articleId": "163205",
                                                                    "description": "163205-00-Gummiplatte",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0023,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0023,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": []
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            "rowNumber": 2576,
                                                            "parentRowNumber": 2487,
                                                            "articleId": "46057938",
                                                            "description": "46057938-01-Stellring",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0297,
                                                            "childrenWeight": 0.0297,
                                                            "totalQuantityWeight": 0.0297,
                                                            "singleQuantityMcf": 0.15384599999999998,
                                                            "mcf": 0.15384599999999998,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.15384599999999998,
                                                            "expandable": false,
                                                            "children": [
                                                                {
                                                                    "rowNumber": 2577,
                                                                    "parentRowNumber": 2576,
                                                                    "articleId": "206215",
                                                                    "description": "206215-01-Stellring",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0297,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0297,
                                                                    "singleQuantityMcf": 0.15384599999999998,
                                                                    "mcf": 0.15384599999999998,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.15384599999999998,
                                                                    "expandable": false,
                                                                    "children": []
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            "rowNumber": 2578,
                                                            "parentRowNumber": 2487,
                                                            "articleId": "46057938",
                                                            "description": "46057938-01-Stellring",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0297,
                                                            "childrenWeight": 0.0297,
                                                            "totalQuantityWeight": 0.0297,
                                                            "singleQuantityMcf": 0.15384599999999998,
                                                            "mcf": 0.15384599999999998,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.15384599999999998,
                                                            "expandable": false,
                                                            "children": [
                                                                {
                                                                    "rowNumber": 2579,
                                                                    "parentRowNumber": 2578,
                                                                    "articleId": "206215",
                                                                    "description": "206215-01-Stellring",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0297,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0297,
                                                                    "singleQuantityMcf": 0.15384599999999998,
                                                                    "mcf": 0.15384599999999998,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.15384599999999998,
                                                                    "expandable": false,
                                                                    "children": []
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            "rowNumber": 2580,
                                                            "parentRowNumber": 2487,
                                                            "articleId": "46206204",
                                                            "description": "46206204-00-Welle",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.5315,
                                                            "childrenWeight": 0.5315,
                                                            "totalQuantityWeight": 0.5315,
                                                            "singleQuantityMcf": 0,
                                                            "mcf": 0,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0,
                                                            "expandable": false,
                                                            "children": [
                                                                {
                                                                    "rowNumber": 2581,
                                                                    "parentRowNumber": 2580,
                                                                    "articleId": "1219016",
                                                                    "description": "1219016-00-Rundstahl",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.5315,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.5315,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": []
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            "rowNumber": 2582,
                                                            "parentRowNumber": 2487,
                                                            "articleId": "46206204",
                                                            "description": "46206204-00-Welle",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.5315,
                                                            "childrenWeight": 0.5315,
                                                            "totalQuantityWeight": 0.5315,
                                                            "singleQuantityMcf": 0,
                                                            "mcf": 0,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0,
                                                            "expandable": false,
                                                            "children": [
                                                                {
                                                                    "rowNumber": 2583,
                                                                    "parentRowNumber": 2582,
                                                                    "articleId": "1219016",
                                                                    "description": "1219016-00-Rundstahl",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.5315,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.5315,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": []
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            "rowNumber": 2584,
                                                            "parentRowNumber": 2487,
                                                            "articleId": "46317649",
                                                            "description": "46317649-02-Deckel",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0326,
                                                            "childrenWeight": 0.0326,
                                                            "totalQuantityWeight": 0.0326,
                                                            "singleQuantityMcf": 0.10432,
                                                            "mcf": 0.10432,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.10432,
                                                            "expandable": false,
                                                            "children": [
                                                                {
                                                                    "rowNumber": 2585,
                                                                    "parentRowNumber": 2584,
                                                                    "articleId": "1617063",
                                                                    "description": "1617063-00-Rundstab",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0326,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0326,
                                                                    "singleQuantityMcf": 0.10432,
                                                                    "mcf": 0.10432,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.10432,
                                                                    "expandable": false,
                                                                    "children": []
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            "rowNumber": 2586,
                                                            "parentRowNumber": 2487,
                                                            "articleId": "46317652",
                                                            "description": "46317652-00-Gehaeuse",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.1012,
                                                            "childrenWeight": 0.1012,
                                                            "totalQuantityWeight": 0.1012,
                                                            "singleQuantityMcf": 0.32384,
                                                            "mcf": 0.32384,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.32384,
                                                            "expandable": false,
                                                            "children": [
                                                                {
                                                                    "rowNumber": 2587,
                                                                    "parentRowNumber": 2586,
                                                                    "articleId": "1617063",
                                                                    "description": "1617063-00-Rundstab",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.1012,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.1012,
                                                                    "singleQuantityMcf": 0.32384,
                                                                    "mcf": 0.32384,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.32384,
                                                                    "expandable": false,
                                                                    "children": []
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            "rowNumber": 2588,
                                                            "parentRowNumber": 2487,
                                                            "articleId": "46368705",
                                                            "description": "46368705-00-Halter",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.1967,
                                                            "childrenWeight": 0.1967,
                                                            "totalQuantityWeight": 0.1967,
                                                            "singleQuantityMcf": 1.018906,
                                                            "mcf": 1.018906,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 1.018906,
                                                            "expandable": false,
                                                            "children": [
                                                                {
                                                                    "rowNumber": 2589,
                                                                    "parentRowNumber": 2588,
                                                                    "articleId": "125515",
                                                                    "description": "125515-00-Rohr",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.05880000000000002,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.05880000000000002,
                                                                    "singleQuantityMcf": 0.3045840000000001,
                                                                    "mcf": 0.3045840000000001,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.3045840000000001,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2590,
                                                                    "parentRowNumber": 2588,
                                                                    "articleId": "446299",
                                                                    "description": "446299-00-Flansch",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.1379,
                                                                    "childrenWeight": 0.1379,
                                                                    "totalQuantityWeight": 0.1379,
                                                                    "singleQuantityMcf": 0.7143219999999999,
                                                                    "mcf": 0.7143219999999999,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.7143219999999999,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 2591,
                                                                            "parentRowNumber": 2590,
                                                                            "articleId": "1260050",
                                                                            "description": "1260050-00-Rundstahl",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.1379,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.1379,
                                                                            "singleQuantityMcf": 0.7143219999999999,
                                                                            "mcf": 0.7143219999999999,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.7143219999999999,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            "rowNumber": 2592,
                                                            "parentRowNumber": 2487,
                                                            "articleId": "46475170",
                                                            "description": "46475170-01-Aufsatz",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0182,
                                                            "childrenWeight": 0.0182,
                                                            "totalQuantityWeight": 0.0182,
                                                            "singleQuantityMcf": 0.05824000000000001,
                                                            "mcf": 0.05824000000000001,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.05824000000000001,
                                                            "expandable": false,
                                                            "children": [
                                                                {
                                                                    "rowNumber": 2593,
                                                                    "parentRowNumber": 2592,
                                                                    "articleId": "1617030",
                                                                    "description": "1617030-00-Rundstab",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0182,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0182,
                                                                    "singleQuantityMcf": 0.05824000000000001,
                                                                    "mcf": 0.05824000000000001,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.05824000000000001,
                                                                    "expandable": false,
                                                                    "children": []
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            "rowNumber": 2594,
                                                            "parentRowNumber": 2487,
                                                            "articleId": "47106191",
                                                            "description": "47106191-00-Halter",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.9025000000000002,
                                                            "childrenWeight": 0.9025000000000002,
                                                            "totalQuantityWeight": 0.9025000000000002,
                                                            "singleQuantityMcf": 4.67495,
                                                            "mcf": 4.67495,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 4.67495,
                                                            "expandable": false,
                                                            "children": [
                                                                {
                                                                    "rowNumber": 2595,
                                                                    "parentRowNumber": 2594,
                                                                    "articleId": "142603",
                                                                    "description": "142603-00-Edelstahlblech",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.05270000000000008,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.05270000000000008,
                                                                    "singleQuantityMcf": 0.2729860000000004,
                                                                    "mcf": 0.2729860000000004,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.2729860000000004,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2596,
                                                                    "parentRowNumber": 2594,
                                                                    "articleId": "46244999",
                                                                    "description": "46244999-00-Bolzen",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0278,
                                                                    "childrenWeight": 0.0278,
                                                                    "totalQuantityWeight": 0.0278,
                                                                    "singleQuantityMcf": 0.144004,
                                                                    "mcf": 0.144004,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.144004,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 2597,
                                                                            "parentRowNumber": 2596,
                                                                            "articleId": "1224015",
                                                                            "description": "1224015-00-Rundstahl",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0278,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0278,
                                                                            "singleQuantityMcf": 0.144004,
                                                                            "mcf": 0.144004,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.144004,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 2598,
                                                                    "parentRowNumber": 2594,
                                                                    "articleId": "46244999",
                                                                    "description": "46244999-00-Bolzen",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0278,
                                                                    "childrenWeight": 0.0278,
                                                                    "totalQuantityWeight": 0.0278,
                                                                    "singleQuantityMcf": 0.144004,
                                                                    "mcf": 0.144004,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.144004,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 2599,
                                                                            "parentRowNumber": 2598,
                                                                            "articleId": "1224015",
                                                                            "description": "1224015-00-Rundstahl",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0278,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0278,
                                                                            "singleQuantityMcf": 0.144004,
                                                                            "mcf": 0.144004,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.144004,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 2600,
                                                                    "parentRowNumber": 2594,
                                                                    "articleId": "46253598",
                                                                    "description": "46253598-00-Deckel",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0027,
                                                                    "childrenWeight": 0.0027,
                                                                    "totalQuantityWeight": 0.0027,
                                                                    "singleQuantityMcf": 0.013986,
                                                                    "mcf": 0.013986,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.013986,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 2601,
                                                                            "parentRowNumber": 2600,
                                                                            "articleId": "142603",
                                                                            "description": "142603-00-Edelstahlblech",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0027,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0027,
                                                                            "singleQuantityMcf": 0.013986,
                                                                            "mcf": 0.013986,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.013986,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 2602,
                                                                    "parentRowNumber": 2594,
                                                                    "articleId": "46253598",
                                                                    "description": "46253598-00-Deckel",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0027,
                                                                    "childrenWeight": 0.0027,
                                                                    "totalQuantityWeight": 0.0027,
                                                                    "singleQuantityMcf": 0.013986,
                                                                    "mcf": 0.013986,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.013986,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 2603,
                                                                            "parentRowNumber": 2602,
                                                                            "articleId": "142603",
                                                                            "description": "142603-00-Edelstahlblech",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0027,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0027,
                                                                            "singleQuantityMcf": 0.013986,
                                                                            "mcf": 0.013986,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.013986,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 2604,
                                                                    "parentRowNumber": 2594,
                                                                    "articleId": "46253598",
                                                                    "description": "46253598-00-Deckel",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0027,
                                                                    "childrenWeight": 0.0027,
                                                                    "totalQuantityWeight": 0.0027,
                                                                    "singleQuantityMcf": 0.013986,
                                                                    "mcf": 0.013986,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.013986,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 2605,
                                                                            "parentRowNumber": 2604,
                                                                            "articleId": "142603",
                                                                            "description": "142603-00-Edelstahlblech",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0027,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0027,
                                                                            "singleQuantityMcf": 0.013986,
                                                                            "mcf": 0.013986,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.013986,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 2606,
                                                                    "parentRowNumber": 2594,
                                                                    "articleId": "46737181",
                                                                    "description": "46737181-00-Bolzen",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0274,
                                                                    "childrenWeight": 0.0274,
                                                                    "totalQuantityWeight": 0.0274,
                                                                    "singleQuantityMcf": 0.141932,
                                                                    "mcf": 0.141932,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.141932,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 2607,
                                                                            "parentRowNumber": 2606,
                                                                            "articleId": "1224015",
                                                                            "description": "1224015-00-Rundstahl",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0274,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0274,
                                                                            "singleQuantityMcf": 0.141932,
                                                                            "mcf": 0.141932,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.141932,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 2608,
                                                                    "parentRowNumber": 2594,
                                                                    "articleId": "46737181",
                                                                    "description": "46737181-00-Bolzen",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0274,
                                                                    "childrenWeight": 0.0274,
                                                                    "totalQuantityWeight": 0.0274,
                                                                    "singleQuantityMcf": 0.141932,
                                                                    "mcf": 0.141932,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.141932,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 2609,
                                                                            "parentRowNumber": 2608,
                                                                            "articleId": "1224015",
                                                                            "description": "1224015-00-Rundstahl",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0274,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0274,
                                                                            "singleQuantityMcf": 0.141932,
                                                                            "mcf": 0.141932,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.141932,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 2610,
                                                                    "parentRowNumber": 2594,
                                                                    "articleId": "47106192",
                                                                    "description": "47106192-00-Griff",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.7313,
                                                                    "childrenWeight": 0.7313,
                                                                    "totalQuantityWeight": 0.7313,
                                                                    "singleQuantityMcf": 3.788134,
                                                                    "mcf": 3.788134,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 3.788134,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 2611,
                                                                            "parentRowNumber": 2610,
                                                                            "articleId": "1261122",
                                                                            "description": "1261122-00-Rohr",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.7313,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.7313,
                                                                            "singleQuantityMcf": 3.788134,
                                                                            "mcf": 3.788134,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 3.788134,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        },
                                                                        {
                                                                            "rowNumber": 2612,
                                                                            "parentRowNumber": 2610,
                                                                            "articleId": "1261122",
                                                                            "description": "1261122-00-Rohr",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        },
                                                                        {
                                                                            "rowNumber": 2613,
                                                                            "parentRowNumber": 2610,
                                                                            "articleId": "1261122",
                                                                            "description": "1261122-00-Rohr",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            "rowNumber": 2614,
                                                            "parentRowNumber": 2487,
                                                            "articleId": "47106402",
                                                            "description": "47106402-00-Blech",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0493,
                                                            "childrenWeight": 0.0493,
                                                            "totalQuantityWeight": 0.0493,
                                                            "singleQuantityMcf": 0.255374,
                                                            "mcf": 0.255374,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.255374,
                                                            "expandable": false,
                                                            "children": [
                                                                {
                                                                    "rowNumber": 2615,
                                                                    "parentRowNumber": 2614,
                                                                    "articleId": "142604",
                                                                    "description": "142604-00-Edelstahlblech",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0493,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0493,
                                                                    "singleQuantityMcf": 0.255374,
                                                                    "mcf": 0.255374,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.255374,
                                                                    "expandable": false,
                                                                    "children": []
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            "rowNumber": 2616,
                                                            "parentRowNumber": 2487,
                                                            "articleId": "47108400",
                                                            "description": "47108400-00-Lagerplatte",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 2.1756,
                                                            "childrenWeight": 2.1756,
                                                            "totalQuantityWeight": 2.1756,
                                                            "singleQuantityMcf": 0.6437120000000002,
                                                            "mcf": 0.6437120000000002,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.6437120000000002,
                                                            "expandable": false,
                                                            "children": [
                                                                {
                                                                    "rowNumber": 2617,
                                                                    "parentRowNumber": 2616,
                                                                    "articleId": "141729",
                                                                    "description": "141729-00-Platte",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 1.7996000000000003,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 1.7996000000000003,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2618,
                                                                    "parentRowNumber": 2616,
                                                                    "articleId": "216226",
                                                                    "description": "216226-00-Sicherungsring",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0016,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0016,
                                                                    "singleQuantityMcf": 0.0027392,
                                                                    "mcf": 0.0027392,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.0027392,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2619,
                                                                    "parentRowNumber": 2616,
                                                                    "articleId": "216226",
                                                                    "description": "216226-00-Sicherungsring",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0016,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0016,
                                                                    "singleQuantityMcf": 0.0027392,
                                                                    "mcf": 0.0027392,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.0027392,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2620,
                                                                    "parentRowNumber": 2616,
                                                                    "articleId": "216226",
                                                                    "description": "216226-00-Sicherungsring",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0016,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0016,
                                                                    "singleQuantityMcf": 0.0027392,
                                                                    "mcf": 0.0027392,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.0027392,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2621,
                                                                    "parentRowNumber": 2616,
                                                                    "articleId": "216226",
                                                                    "description": "216226-00-Sicherungsring",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0016,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0016,
                                                                    "singleQuantityMcf": 0.0027392,
                                                                    "mcf": 0.0027392,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.0027392,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2622,
                                                                    "parentRowNumber": 2616,
                                                                    "articleId": "278011",
                                                                    "description": "278011-00-Linearkugellager",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0924,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0924,
                                                                    "singleQuantityMcf": 0.1581888,
                                                                    "mcf": 0.1581888,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.1581888,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2623,
                                                                    "parentRowNumber": 2616,
                                                                    "articleId": "278011",
                                                                    "description": "278011-00-Linearkugellager",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0924,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0924,
                                                                    "singleQuantityMcf": 0.1581888,
                                                                    "mcf": 0.1581888,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.1581888,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2624,
                                                                    "parentRowNumber": 2616,
                                                                    "articleId": "278011",
                                                                    "description": "278011-00-Linearkugellager",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0924,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0924,
                                                                    "singleQuantityMcf": 0.1581888,
                                                                    "mcf": 0.1581888,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.1581888,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2625,
                                                                    "parentRowNumber": 2616,
                                                                    "articleId": "278011",
                                                                    "description": "278011-00-Linearkugellager",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0924,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0924,
                                                                    "singleQuantityMcf": 0.1581888,
                                                                    "mcf": 0.1581888,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.1581888,
                                                                    "expandable": false,
                                                                    "children": []
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            "rowNumber": 2626,
                                                            "parentRowNumber": 2487,
                                                            "articleId": "47108593",
                                                            "description": "47108593-00-Halter",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0239,
                                                            "childrenWeight": 0.0239,
                                                            "totalQuantityWeight": 0.0239,
                                                            "singleQuantityMcf": 0.123802,
                                                            "mcf": 0.123802,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.123802,
                                                            "expandable": false,
                                                            "children": [
                                                                {
                                                                    "rowNumber": 2627,
                                                                    "parentRowNumber": 2626,
                                                                    "articleId": "142608",
                                                                    "description": "142608-00-Edelstahlblech",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0239,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0239,
                                                                    "singleQuantityMcf": 0.123802,
                                                                    "mcf": 0.123802,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.123802,
                                                                    "expandable": false,
                                                                    "children": []
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            "rowNumber": 2628,
                                                            "parentRowNumber": 2487,
                                                            "articleId": "47108651",
                                                            "description": "47108651-01-Anschlussstueck",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 1.3716,
                                                            "childrenWeight": 1.3716,
                                                            "totalQuantityWeight": 1.3716,
                                                            "singleQuantityMcf": 0.053872,
                                                            "mcf": 0.053872,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.053872,
                                                            "expandable": false,
                                                            "children": [
                                                                {
                                                                    "rowNumber": 2629,
                                                                    "parentRowNumber": 2628,
                                                                    "articleId": "141722",
                                                                    "description": "141722-00-Platte",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 1.3612,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 1.3612,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2630,
                                                                    "parentRowNumber": 2628,
                                                                    "articleId": "21220824",
                                                                    "description": "21220824-01-Zylinderstift",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0104,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0104,
                                                                    "singleQuantityMcf": 0.053872,
                                                                    "mcf": 0.053872,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.053872,
                                                                    "expandable": false,
                                                                    "children": []
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            "rowNumber": 2631,
                                                            "parentRowNumber": 2487,
                                                            "articleId": "52370102",
                                                            "description": "52370102-01-Magnetventil",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.607,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.607,
                                                            "singleQuantityMcf": 0,
                                                            "mcf": 0,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 2632,
                                                            "parentRowNumber": 2487,
                                                            "articleId": "54227619",
                                                            "description": "54227619-01-Schlagtaste",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0,
                                                            "singleQuantityMcf": 0,
                                                            "mcf": 0,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 2633,
                                                            "parentRowNumber": 2487,
                                                            "articleId": "54227701",
                                                            "description": "54227701-00-Modul-Halter",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.036,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.036,
                                                            "singleQuantityMcf": 0,
                                                            "mcf": 0,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 2634,
                                                            "parentRowNumber": 2487,
                                                            "articleId": "54227705",
                                                            "description": "54227705-01-Kontaktelement",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0,
                                                            "singleQuantityMcf": 0,
                                                            "mcf": 0,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 2635,
                                                            "parentRowNumber": 2487,
                                                            "articleId": "58236515",
                                                            "description": "58236515-02-Kabeldose",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0106,
                                                            "childrenWeight": 0.0106,
                                                            "totalQuantityWeight": 0.0106,
                                                            "singleQuantityMcf": 0,
                                                            "mcf": 0,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0,
                                                            "expandable": false,
                                                            "children": [
                                                                {
                                                                    "rowNumber": 2636,
                                                                    "parentRowNumber": 2635,
                                                                    "articleId": "PUDY00000066",
                                                                    "description": "PUDY00000066-00-Kabel D5",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0106,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0106,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": []
                                                                }
                                                            ]
                                                        }
                                                    ]
                                                }
                                            ]
                                        },
                                        {
                                            "rowNumber": 2637,
                                            "parentRowNumber": 2161,
                                            "articleId": "33847",
                                            "description": "33847-20DL40-00-Niveauerfassung",
                                            "hierarchy": 4,
                                            "quantity": 1,
                                            "weight": 0.0111,
                                            "childrenWeight": 0.0111,
                                            "totalQuantityWeight": 0.0111,
                                            "singleQuantityMcf": 0,
                                            "mcf": 0,
                                            "singleQuantityLcf": 0,
                                            "lcf": 0,
                                            "totalQuantityMcf": 0,
                                            "expandable": false,
                                            "children": [
                                                {
                                                    "rowNumber": 2638,
                                                    "parentRowNumber": 2637,
                                                    "articleId": "MM0000005429",
                                                    "description": "MM0000005429-00-Niveauerfassung",
                                                    "hierarchy": 5,
                                                    "quantity": 1,
                                                    "weight": 0.0111,
                                                    "childrenWeight": 0.0111,
                                                    "totalQuantityWeight": 0.0111,
                                                    "singleQuantityMcf": 0,
                                                    "mcf": 0,
                                                    "singleQuantityLcf": 0,
                                                    "lcf": 0,
                                                    "totalQuantityMcf": 0,
                                                    "expandable": false,
                                                    "children": [
                                                        {
                                                            "rowNumber": 2639,
                                                            "parentRowNumber": 2638,
                                                            "articleId": "58233211",
                                                            "description": "58233211-03-Näherungsschalter",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0005,
                                                            "childrenWeight": 0.0005,
                                                            "totalQuantityWeight": 0.0005,
                                                            "singleQuantityMcf": 0,
                                                            "mcf": 0,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0,
                                                            "expandable": false,
                                                            "children": [
                                                                {
                                                                    "rowNumber": 2640,
                                                                    "parentRowNumber": 2639,
                                                                    "articleId": "PUDY00000884",
                                                                    "description": "PUDY00000884-00-Kabel D2",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0005,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0005,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": []
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            "rowNumber": 2641,
                                                            "parentRowNumber": 2638,
                                                            "articleId": "58236610",
                                                            "description": "58236610-02-Kabeldose",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0106,
                                                            "childrenWeight": 0.0106,
                                                            "totalQuantityWeight": 0.0106,
                                                            "singleQuantityMcf": 0,
                                                            "mcf": 0,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0,
                                                            "expandable": false,
                                                            "children": [
                                                                {
                                                                    "rowNumber": 2642,
                                                                    "parentRowNumber": 2641,
                                                                    "articleId": "PUDY00000066",
                                                                    "description": "PUDY00000066-00-Kabel D5",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0106,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0106,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": []
                                                                }
                                                            ]
                                                        }
                                                    ]
                                                }
                                            ]
                                        },
                                        {
                                            "rowNumber": 2643,
                                            "parentRowNumber": 2161,
                                            "articleId": "33847",
                                            "description": "33847-20DLF1-02-Objektfuehrung",
                                            "hierarchy": 4,
                                            "quantity": 1,
                                            "weight": 7.6935,
                                            "childrenWeight": 7.6935,
                                            "totalQuantityWeight": 7.6935,
                                            "singleQuantityMcf": 3.5459399999999985,
                                            "mcf": 3.5459399999999985,
                                            "singleQuantityLcf": 0,
                                            "lcf": 0,
                                            "totalQuantityMcf": 3.5459399999999985,
                                            "expandable": false,
                                            "children": [
                                                {
                                                    "rowNumber": 2644,
                                                    "parentRowNumber": 2643,
                                                    "articleId": "MM0000004545",
                                                    "description": "MM0000004545-01-Objektfuehrungen",
                                                    "hierarchy": 5,
                                                    "quantity": 1,
                                                    "weight": 7.6935,
                                                    "childrenWeight": 7.6935,
                                                    "totalQuantityWeight": 7.6935,
                                                    "singleQuantityMcf": 3.5459399999999985,
                                                    "mcf": 3.5459399999999985,
                                                    "singleQuantityLcf": 0,
                                                    "lcf": 0,
                                                    "totalQuantityMcf": 3.5459399999999985,
                                                    "expandable": false,
                                                    "children": [
                                                        {
                                                            "rowNumber": 2645,
                                                            "parentRowNumber": 2644,
                                                            "articleId": "47126548",
                                                            "description": "47126548-01-Einlaufband",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 7.6935,
                                                            "childrenWeight": 7.6935,
                                                            "totalQuantityWeight": 7.6935,
                                                            "singleQuantityMcf": 3.5459399999999985,
                                                            "mcf": 3.5459399999999985,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 3.5459399999999985,
                                                            "expandable": false,
                                                            "children": [
                                                                {
                                                                    "rowNumber": 2646,
                                                                    "parentRowNumber": 2645,
                                                                    "articleId": "20120410",
                                                                    "description": "20120410-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0019,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0019,
                                                                    "singleQuantityMcf": 0.009842,
                                                                    "mcf": 0.009842,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.009842,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2647,
                                                                    "parentRowNumber": 2645,
                                                                    "articleId": "20120410",
                                                                    "description": "20120410-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0019,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0019,
                                                                    "singleQuantityMcf": 0.009842,
                                                                    "mcf": 0.009842,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.009842,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2648,
                                                                    "parentRowNumber": 2645,
                                                                    "articleId": "20120615",
                                                                    "description": "20120615-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0061,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0061,
                                                                    "singleQuantityMcf": 0.031598,
                                                                    "mcf": 0.031598,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.031598,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2649,
                                                                    "parentRowNumber": 2645,
                                                                    "articleId": "20120615",
                                                                    "description": "20120615-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0061,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0061,
                                                                    "singleQuantityMcf": 0.031598,
                                                                    "mcf": 0.031598,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.031598,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2650,
                                                                    "parentRowNumber": 2645,
                                                                    "articleId": "20120615",
                                                                    "description": "20120615-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0061,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0061,
                                                                    "singleQuantityMcf": 0.031598,
                                                                    "mcf": 0.031598,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.031598,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2651,
                                                                    "parentRowNumber": 2645,
                                                                    "articleId": "20120615",
                                                                    "description": "20120615-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0061,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0061,
                                                                    "singleQuantityMcf": 0.031598,
                                                                    "mcf": 0.031598,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.031598,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2652,
                                                                    "parentRowNumber": 2645,
                                                                    "articleId": "20220520",
                                                                    "description": "20220520-00-Zylinderschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0044,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0044,
                                                                    "singleQuantityMcf": 0.022792,
                                                                    "mcf": 0.022792,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.022792,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2653,
                                                                    "parentRowNumber": 2645,
                                                                    "articleId": "20220520",
                                                                    "description": "20220520-00-Zylinderschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0044,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0044,
                                                                    "singleQuantityMcf": 0.022792,
                                                                    "mcf": 0.022792,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.022792,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2654,
                                                                    "parentRowNumber": 2645,
                                                                    "articleId": "20220520",
                                                                    "description": "20220520-00-Zylinderschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0044,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0044,
                                                                    "singleQuantityMcf": 0.022792,
                                                                    "mcf": 0.022792,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.022792,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2655,
                                                                    "parentRowNumber": 2645,
                                                                    "articleId": "20220520",
                                                                    "description": "20220520-00-Zylinderschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0044,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0044,
                                                                    "singleQuantityMcf": 0.022792,
                                                                    "mcf": 0.022792,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.022792,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2656,
                                                                    "parentRowNumber": 2645,
                                                                    "articleId": "20420412",
                                                                    "description": "20420412-00-Senkschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0013,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0013,
                                                                    "singleQuantityMcf": 0.006734,
                                                                    "mcf": 0.006734,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.006734,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2657,
                                                                    "parentRowNumber": 2645,
                                                                    "articleId": "20420412",
                                                                    "description": "20420412-00-Senkschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0013,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0013,
                                                                    "singleQuantityMcf": 0.006734,
                                                                    "mcf": 0.006734,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.006734,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2658,
                                                                    "parentRowNumber": 2645,
                                                                    "articleId": "20420412",
                                                                    "description": "20420412-00-Senkschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0013,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0013,
                                                                    "singleQuantityMcf": 0.006734,
                                                                    "mcf": 0.006734,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.006734,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2659,
                                                                    "parentRowNumber": 2645,
                                                                    "articleId": "20420412",
                                                                    "description": "20420412-00-Senkschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0013,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0013,
                                                                    "singleQuantityMcf": 0.006734,
                                                                    "mcf": 0.006734,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.006734,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2660,
                                                                    "parentRowNumber": 2645,
                                                                    "articleId": "20420412",
                                                                    "description": "20420412-00-Senkschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0013,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0013,
                                                                    "singleQuantityMcf": 0.006734,
                                                                    "mcf": 0.006734,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.006734,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2661,
                                                                    "parentRowNumber": 2645,
                                                                    "articleId": "20420412",
                                                                    "description": "20420412-00-Senkschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0013,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0013,
                                                                    "singleQuantityMcf": 0.006734,
                                                                    "mcf": 0.006734,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.006734,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2662,
                                                                    "parentRowNumber": 2645,
                                                                    "articleId": "20720505",
                                                                    "description": "20720505-00-Gewindestift",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0006,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0006,
                                                                    "singleQuantityMcf": 0.003108,
                                                                    "mcf": 0.003108,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.003108,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2663,
                                                                    "parentRowNumber": 2645,
                                                                    "articleId": "20720505",
                                                                    "description": "20720505-00-Gewindestift",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0006,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0006,
                                                                    "singleQuantityMcf": 0.003108,
                                                                    "mcf": 0.003108,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.003108,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2664,
                                                                    "parentRowNumber": 2645,
                                                                    "articleId": "20720505",
                                                                    "description": "20720505-00-Gewindestift",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0006,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0006,
                                                                    "singleQuantityMcf": 0.003108,
                                                                    "mcf": 0.003108,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.003108,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2665,
                                                                    "parentRowNumber": 2645,
                                                                    "articleId": "20720505",
                                                                    "description": "20720505-00-Gewindestift",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0006,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0006,
                                                                    "singleQuantityMcf": 0.003108,
                                                                    "mcf": 0.003108,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.003108,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2666,
                                                                    "parentRowNumber": 2645,
                                                                    "articleId": "20720505",
                                                                    "description": "20720505-00-Gewindestift",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0006,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0006,
                                                                    "singleQuantityMcf": 0.003108,
                                                                    "mcf": 0.003108,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.003108,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2667,
                                                                    "parentRowNumber": 2645,
                                                                    "articleId": "20720505",
                                                                    "description": "20720505-00-Gewindestift",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0006,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0006,
                                                                    "singleQuantityMcf": 0.003108,
                                                                    "mcf": 0.003108,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.003108,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2668,
                                                                    "parentRowNumber": 2645,
                                                                    "articleId": "20720505",
                                                                    "description": "20720505-00-Gewindestift",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0006,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0006,
                                                                    "singleQuantityMcf": 0.003108,
                                                                    "mcf": 0.003108,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.003108,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2669,
                                                                    "parentRowNumber": 2645,
                                                                    "articleId": "20720505",
                                                                    "description": "20720505-00-Gewindestift",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0006,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0006,
                                                                    "singleQuantityMcf": 0.003108,
                                                                    "mcf": 0.003108,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.003108,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2670,
                                                                    "parentRowNumber": 2645,
                                                                    "articleId": "208204",
                                                                    "description": "208204-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0003,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0003,
                                                                    "singleQuantityMcf": 0.0015539999999999998,
                                                                    "mcf": 0.0015539999999999998,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.0015539999999999998,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2671,
                                                                    "parentRowNumber": 2645,
                                                                    "articleId": "208204",
                                                                    "description": "208204-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0003,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0003,
                                                                    "singleQuantityMcf": 0.0015539999999999998,
                                                                    "mcf": 0.0015539999999999998,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.0015539999999999998,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2672,
                                                                    "parentRowNumber": 2645,
                                                                    "articleId": "208406",
                                                                    "description": "208406-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0028,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0028,
                                                                    "singleQuantityMcf": 0.014504,
                                                                    "mcf": 0.014504,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.014504,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2673,
                                                                    "parentRowNumber": 2645,
                                                                    "articleId": "208406",
                                                                    "description": "208406-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0028,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0028,
                                                                    "singleQuantityMcf": 0.014504,
                                                                    "mcf": 0.014504,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.014504,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2674,
                                                                    "parentRowNumber": 2645,
                                                                    "articleId": "208406",
                                                                    "description": "208406-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0028,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0028,
                                                                    "singleQuantityMcf": 0.014504,
                                                                    "mcf": 0.014504,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.014504,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2675,
                                                                    "parentRowNumber": 2645,
                                                                    "articleId": "208406",
                                                                    "description": "208406-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0028,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0028,
                                                                    "singleQuantityMcf": 0.014504,
                                                                    "mcf": 0.014504,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.014504,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2676,
                                                                    "parentRowNumber": 2645,
                                                                    "articleId": "46411269",
                                                                    "description": "46411269-01-Klotz",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.1514,
                                                                    "childrenWeight": 0.1514,
                                                                    "totalQuantityWeight": 0.1514,
                                                                    "singleQuantityMcf": 0.784252,
                                                                    "mcf": 0.784252,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.784252,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 2677,
                                                                            "parentRowNumber": 2676,
                                                                            "articleId": "1110288",
                                                                            "description": "1110288-00-Flachstahl",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.1514,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.1514,
                                                                            "singleQuantityMcf": 0.784252,
                                                                            "mcf": 0.784252,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.784252,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 2678,
                                                                    "parentRowNumber": 2645,
                                                                    "articleId": "46411269",
                                                                    "description": "46411269-01-Klotz",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.1514,
                                                                    "childrenWeight": 0.1514,
                                                                    "totalQuantityWeight": 0.1514,
                                                                    "singleQuantityMcf": 0.784252,
                                                                    "mcf": 0.784252,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.784252,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 2679,
                                                                            "parentRowNumber": 2678,
                                                                            "articleId": "1110288",
                                                                            "description": "1110288-00-Flachstahl",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.1514,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.1514,
                                                                            "singleQuantityMcf": 0.784252,
                                                                            "mcf": 0.784252,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.784252,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 2680,
                                                                    "parentRowNumber": 2645,
                                                                    "articleId": "46411269",
                                                                    "description": "46411269-01-Klotz",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.1514,
                                                                    "childrenWeight": 0.1514,
                                                                    "totalQuantityWeight": 0.1514,
                                                                    "singleQuantityMcf": 0.784252,
                                                                    "mcf": 0.784252,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.784252,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 2681,
                                                                            "parentRowNumber": 2680,
                                                                            "articleId": "1110288",
                                                                            "description": "1110288-00-Flachstahl",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.1514,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.1514,
                                                                            "singleQuantityMcf": 0.784252,
                                                                            "mcf": 0.784252,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.784252,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 2682,
                                                                    "parentRowNumber": 2645,
                                                                    "articleId": "46411269",
                                                                    "description": "46411269-01-Klotz",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.1514,
                                                                    "childrenWeight": 0.1514,
                                                                    "totalQuantityWeight": 0.1514,
                                                                    "singleQuantityMcf": 0.784252,
                                                                    "mcf": 0.784252,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.784252,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 2683,
                                                                            "parentRowNumber": 2682,
                                                                            "articleId": "1110288",
                                                                            "description": "1110288-00-Flachstahl",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.1514,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.1514,
                                                                            "singleQuantityMcf": 0.784252,
                                                                            "mcf": 0.784252,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.784252,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 2684,
                                                                    "parentRowNumber": 2645,
                                                                    "articleId": "46411339",
                                                                    "description": "46411339-00-Blech",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0072,
                                                                    "childrenWeight": 0.0072,
                                                                    "totalQuantityWeight": 0.0072,
                                                                    "singleQuantityMcf": 0.037296,
                                                                    "mcf": 0.037296,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.037296,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 2685,
                                                                            "parentRowNumber": 2684,
                                                                            "articleId": "142604",
                                                                            "description": "142604-00-Edelstahlblech",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0072,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0072,
                                                                            "singleQuantityMcf": 0.037296,
                                                                            "mcf": 0.037296,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.037296,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 2686,
                                                                    "parentRowNumber": 2645,
                                                                    "articleId": "46411436",
                                                                    "description": "46411436-02-Platte",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.002,
                                                                    "childrenWeight": 0.002,
                                                                    "totalQuantityWeight": 0.002,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 2687,
                                                                            "parentRowNumber": 2686,
                                                                            "articleId": "162532",
                                                                            "description": "162532-01-Platte",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        },
                                                                        {
                                                                            "rowNumber": 2688,
                                                                            "parentRowNumber": 2686,
                                                                            "articleId": "347196",
                                                                            "description": "347196-00-Ensat-Gewindebuchse",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.001,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.001,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        },
                                                                        {
                                                                            "rowNumber": 2689,
                                                                            "parentRowNumber": 2686,
                                                                            "articleId": "347196",
                                                                            "description": "347196-00-Ensat-Gewindebuchse",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.001,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.001,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 2690,
                                                                    "parentRowNumber": 2645,
                                                                    "articleId": "46411440",
                                                                    "description": "46411440-01-Platte",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 2691,
                                                                            "parentRowNumber": 2690,
                                                                            "articleId": "162532",
                                                                            "description": "162532-01-Platte",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 2692,
                                                                    "parentRowNumber": 2645,
                                                                    "articleId": "46635740",
                                                                    "description": "46635740-00-Distanzbolzen",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 2693,
                                                                            "parentRowNumber": 2692,
                                                                            "articleId": "1260015",
                                                                            "description": "1260015-00-Rundstahl",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 2694,
                                                                    "parentRowNumber": 2645,
                                                                    "articleId": "46635740",
                                                                    "description": "46635740-00-Distanzbolzen",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 2695,
                                                                            "parentRowNumber": 2694,
                                                                            "articleId": "1260015",
                                                                            "description": "1260015-00-Rundstahl",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 2696,
                                                                    "parentRowNumber": 2645,
                                                                    "articleId": "46635740",
                                                                    "description": "46635740-00-Distanzbolzen",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 2697,
                                                                            "parentRowNumber": 2696,
                                                                            "articleId": "1260015",
                                                                            "description": "1260015-00-Rundstahl",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 2698,
                                                                    "parentRowNumber": 2645,
                                                                    "articleId": "46635740",
                                                                    "description": "46635740-00-Distanzbolzen",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 2699,
                                                                            "parentRowNumber": 2698,
                                                                            "articleId": "1260015",
                                                                            "description": "1260015-00-Rundstahl",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 2700,
                                                                    "parentRowNumber": 2645,
                                                                    "articleId": "46635740",
                                                                    "description": "46635740-00-Distanzbolzen",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 2701,
                                                                            "parentRowNumber": 2700,
                                                                            "articleId": "1260015",
                                                                            "description": "1260015-00-Rundstahl",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 2702,
                                                                    "parentRowNumber": 2645,
                                                                    "articleId": "46635740",
                                                                    "description": "46635740-00-Distanzbolzen",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 2703,
                                                                            "parentRowNumber": 2702,
                                                                            "articleId": "1260015",
                                                                            "description": "1260015-00-Rundstahl",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 2704,
                                                                    "parentRowNumber": 2645,
                                                                    "articleId": "46635740",
                                                                    "description": "46635740-00-Distanzbolzen",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 2705,
                                                                            "parentRowNumber": 2704,
                                                                            "articleId": "1260015",
                                                                            "description": "1260015-00-Rundstahl",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 2706,
                                                                    "parentRowNumber": 2645,
                                                                    "articleId": "46635740",
                                                                    "description": "46635740-00-Distanzbolzen",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 2707,
                                                                            "parentRowNumber": 2706,
                                                                            "articleId": "1260015",
                                                                            "description": "1260015-00-Rundstahl",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 2708,
                                                                    "parentRowNumber": 2645,
                                                                    "articleId": "47126446",
                                                                    "description": "47126446-00-Abstechblech",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 2709,
                                                                            "parentRowNumber": 2708,
                                                                            "articleId": "142603",
                                                                            "description": "142603-00-Edelstahlblech",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 2710,
                                                                    "parentRowNumber": 2645,
                                                                    "articleId": "47126454",
                                                                    "description": "47126454-00-Blech",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 2711,
                                                                            "parentRowNumber": 2710,
                                                                            "articleId": "142603",
                                                                            "description": "142603-00-Edelstahlblech",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 2712,
                                                                    "parentRowNumber": 2645,
                                                                    "articleId": "47126455",
                                                                    "description": "47126455-00-Abstechblech",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 2713,
                                                                            "parentRowNumber": 2712,
                                                                            "articleId": "142604",
                                                                            "description": "142604-00-Edelstahlblech",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 2714,
                                                                    "parentRowNumber": 2645,
                                                                    "articleId": "47126512",
                                                                    "description": "47126512-01-Einlaufklotz",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.11469999999999998,
                                                                    "childrenWeight": 0.11469999999999998,
                                                                    "totalQuantityWeight": 0.11469999999999998,
                                                                    "singleQuantityMcf": 0.007999999999999981,
                                                                    "mcf": 0.007999999999999981,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.007999999999999981,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 2715,
                                                                            "parentRowNumber": 2714,
                                                                            "articleId": "162544",
                                                                            "description": "162544-01-Platte",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.002499999999999994,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.002499999999999994,
                                                                            "singleQuantityMcf": 0.007999999999999981,
                                                                            "mcf": 0.007999999999999981,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.007999999999999981,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        },
                                                                        {
                                                                            "rowNumber": 2716,
                                                                            "parentRowNumber": 2714,
                                                                            "articleId": "323030",
                                                                            "description": "323030-02-Buegelgriff",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.1022,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.1022,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        },
                                                                        {
                                                                            "rowNumber": 2717,
                                                                            "parentRowNumber": 2714,
                                                                            "articleId": "347200",
                                                                            "description": "347200-00-Ensat-Gewindebuchse",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0025,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0025,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        },
                                                                        {
                                                                            "rowNumber": 2718,
                                                                            "parentRowNumber": 2714,
                                                                            "articleId": "347200",
                                                                            "description": "347200-00-Ensat-Gewindebuchse",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0025,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0025,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        },
                                                                        {
                                                                            "rowNumber": 2719,
                                                                            "parentRowNumber": 2714,
                                                                            "articleId": "347200",
                                                                            "description": "347200-00-Ensat-Gewindebuchse",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0025,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0025,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        },
                                                                        {
                                                                            "rowNumber": 2720,
                                                                            "parentRowNumber": 2714,
                                                                            "articleId": "347200",
                                                                            "description": "347200-00-Ensat-Gewindebuchse",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0025,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0025,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 2721,
                                                                    "parentRowNumber": 2645,
                                                                    "articleId": "47126513",
                                                                    "description": "47126513-00-Einlaufklotz",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.002,
                                                                    "childrenWeight": 0.002,
                                                                    "totalQuantityWeight": 0.002,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 2722,
                                                                            "parentRowNumber": 2721,
                                                                            "articleId": "162538",
                                                                            "description": "162538-01-Platte",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        },
                                                                        {
                                                                            "rowNumber": 2723,
                                                                            "parentRowNumber": 2721,
                                                                            "articleId": "347196",
                                                                            "description": "347196-00-Ensat-Gewindebuchse",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.001,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.001,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        },
                                                                        {
                                                                            "rowNumber": 2724,
                                                                            "parentRowNumber": 2721,
                                                                            "articleId": "347196",
                                                                            "description": "347196-00-Ensat-Gewindebuchse",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.001,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.001,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 2725,
                                                                    "parentRowNumber": 2645,
                                                                    "articleId": "47129472",
                                                                    "description": "47129472-00-Fuehrung",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 2726,
                                                                            "parentRowNumber": 2725,
                                                                            "articleId": "248942",
                                                                            "description": "248942-00-Profil-Alukern",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        },
                                                                        {
                                                                            "rowNumber": 2727,
                                                                            "parentRowNumber": 2725,
                                                                            "articleId": "248943",
                                                                            "description": "248943-01-Fuehrungsleiste",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 2728,
                                                                    "parentRowNumber": 2645,
                                                                    "articleId": "47129472",
                                                                    "description": "47129472-00-Fuehrung",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 2729,
                                                                            "parentRowNumber": 2728,
                                                                            "articleId": "248942",
                                                                            "description": "248942-00-Profil-Alukern",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        },
                                                                        {
                                                                            "rowNumber": 2730,
                                                                            "parentRowNumber": 2728,
                                                                            "articleId": "248943",
                                                                            "description": "248943-01-Fuehrungsleiste",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 2731,
                                                                    "parentRowNumber": 2645,
                                                                    "articleId": "Magazin_Master",
                                                                    "description": "Magazin_Master-00-390-152",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 3.4459,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 3.4459,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2732,
                                                                    "parentRowNumber": 2645,
                                                                    "articleId": "Magazin_Master",
                                                                    "description": "Magazin_Master-00-390-152",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 3.4459,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 3.4459,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": []
                                                                }
                                                            ]
                                                        }
                                                    ]
                                                }
                                            ]
                                        },
                                        {
                                            "rowNumber": 2733,
                                            "parentRowNumber": 2161,
                                            "articleId": "33847",
                                            "description": "33847-20DLF2-02-Objektfuehrung",
                                            "hierarchy": 4,
                                            "quantity": 1,
                                            "weight": 8.204100000000006,
                                            "childrenWeight": 8.204100000000006,
                                            "totalQuantityWeight": 8.204100000000006,
                                            "singleQuantityMcf": 25.42046999999998,
                                            "mcf": 25.42046999999998,
                                            "singleQuantityLcf": 0,
                                            "lcf": 0,
                                            "totalQuantityMcf": 25.42046999999998,
                                            "expandable": false,
                                            "children": [
                                                {
                                                    "rowNumber": 2734,
                                                    "parentRowNumber": 2733,
                                                    "articleId": "MM0000005810",
                                                    "description": "MM0000005810-02-Objektfuehrung",
                                                    "hierarchy": 5,
                                                    "quantity": 1,
                                                    "weight": 8.204100000000006,
                                                    "childrenWeight": 8.204100000000006,
                                                    "totalQuantityWeight": 8.204100000000006,
                                                    "singleQuantityMcf": 25.42046999999998,
                                                    "mcf": 25.42046999999998,
                                                    "singleQuantityLcf": 0,
                                                    "lcf": 0,
                                                    "totalQuantityMcf": 25.42046999999998,
                                                    "expandable": false,
                                                    "children": [
                                                        {
                                                            "rowNumber": 2735,
                                                            "parentRowNumber": 2734,
                                                            "articleId": "47127293",
                                                            "description": "47127293-02-Einlaufband",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 8.204100000000006,
                                                            "childrenWeight": 8.204100000000006,
                                                            "totalQuantityWeight": 8.204100000000006,
                                                            "singleQuantityMcf": 25.42046999999998,
                                                            "mcf": 25.42046999999998,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 25.42046999999998,
                                                            "expandable": false,
                                                            "children": [
                                                                {
                                                                    "rowNumber": 2736,
                                                                    "parentRowNumber": 2735,
                                                                    "articleId": "20120410",
                                                                    "description": "20120410-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0019,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0019,
                                                                    "singleQuantityMcf": 0.009842,
                                                                    "mcf": 0.009842,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.009842,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2737,
                                                                    "parentRowNumber": 2735,
                                                                    "articleId": "20120410",
                                                                    "description": "20120410-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0019,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0019,
                                                                    "singleQuantityMcf": 0.009842,
                                                                    "mcf": 0.009842,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.009842,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2738,
                                                                    "parentRowNumber": 2735,
                                                                    "articleId": "20120615",
                                                                    "description": "20120615-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0061,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0061,
                                                                    "singleQuantityMcf": 0.031598,
                                                                    "mcf": 0.031598,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.031598,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2739,
                                                                    "parentRowNumber": 2735,
                                                                    "articleId": "20120615",
                                                                    "description": "20120615-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0061,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0061,
                                                                    "singleQuantityMcf": 0.031598,
                                                                    "mcf": 0.031598,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.031598,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2740,
                                                                    "parentRowNumber": 2735,
                                                                    "articleId": "20120615",
                                                                    "description": "20120615-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0061,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0061,
                                                                    "singleQuantityMcf": 0.031598,
                                                                    "mcf": 0.031598,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.031598,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2741,
                                                                    "parentRowNumber": 2735,
                                                                    "articleId": "20120615",
                                                                    "description": "20120615-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0061,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0061,
                                                                    "singleQuantityMcf": 0.031598,
                                                                    "mcf": 0.031598,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.031598,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2742,
                                                                    "parentRowNumber": 2735,
                                                                    "articleId": "20220520",
                                                                    "description": "20220520-00-Zylinderschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0044,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0044,
                                                                    "singleQuantityMcf": 0.022792,
                                                                    "mcf": 0.022792,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.022792,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2743,
                                                                    "parentRowNumber": 2735,
                                                                    "articleId": "20220520",
                                                                    "description": "20220520-00-Zylinderschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0044,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0044,
                                                                    "singleQuantityMcf": 0.022792,
                                                                    "mcf": 0.022792,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.022792,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2744,
                                                                    "parentRowNumber": 2735,
                                                                    "articleId": "20220520",
                                                                    "description": "20220520-00-Zylinderschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0044,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0044,
                                                                    "singleQuantityMcf": 0.022792,
                                                                    "mcf": 0.022792,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.022792,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2745,
                                                                    "parentRowNumber": 2735,
                                                                    "articleId": "20220520",
                                                                    "description": "20220520-00-Zylinderschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0044,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0044,
                                                                    "singleQuantityMcf": 0.022792,
                                                                    "mcf": 0.022792,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.022792,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2746,
                                                                    "parentRowNumber": 2735,
                                                                    "articleId": "20420412",
                                                                    "description": "20420412-00-Senkschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0013,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0013,
                                                                    "singleQuantityMcf": 0.006734,
                                                                    "mcf": 0.006734,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.006734,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2747,
                                                                    "parentRowNumber": 2735,
                                                                    "articleId": "20420412",
                                                                    "description": "20420412-00-Senkschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0013,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0013,
                                                                    "singleQuantityMcf": 0.006734,
                                                                    "mcf": 0.006734,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.006734,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2748,
                                                                    "parentRowNumber": 2735,
                                                                    "articleId": "20420412",
                                                                    "description": "20420412-00-Senkschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0013,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0013,
                                                                    "singleQuantityMcf": 0.006734,
                                                                    "mcf": 0.006734,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.006734,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2749,
                                                                    "parentRowNumber": 2735,
                                                                    "articleId": "20420412",
                                                                    "description": "20420412-00-Senkschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0013,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0013,
                                                                    "singleQuantityMcf": 0.006734,
                                                                    "mcf": 0.006734,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.006734,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2750,
                                                                    "parentRowNumber": 2735,
                                                                    "articleId": "20420412",
                                                                    "description": "20420412-00-Senkschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0013,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0013,
                                                                    "singleQuantityMcf": 0.006734,
                                                                    "mcf": 0.006734,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.006734,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2751,
                                                                    "parentRowNumber": 2735,
                                                                    "articleId": "20420412",
                                                                    "description": "20420412-00-Senkschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0013,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0013,
                                                                    "singleQuantityMcf": 0.006734,
                                                                    "mcf": 0.006734,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.006734,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2752,
                                                                    "parentRowNumber": 2735,
                                                                    "articleId": "208204",
                                                                    "description": "208204-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0003,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0003,
                                                                    "singleQuantityMcf": 0.0015539999999999998,
                                                                    "mcf": 0.0015539999999999998,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.0015539999999999998,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2753,
                                                                    "parentRowNumber": 2735,
                                                                    "articleId": "208204",
                                                                    "description": "208204-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0003,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0003,
                                                                    "singleQuantityMcf": 0.0015539999999999998,
                                                                    "mcf": 0.0015539999999999998,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.0015539999999999998,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2754,
                                                                    "parentRowNumber": 2735,
                                                                    "articleId": "208406",
                                                                    "description": "208406-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0028,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0028,
                                                                    "singleQuantityMcf": 0.014504,
                                                                    "mcf": 0.014504,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.014504,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2755,
                                                                    "parentRowNumber": 2735,
                                                                    "articleId": "208406",
                                                                    "description": "208406-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0028,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0028,
                                                                    "singleQuantityMcf": 0.014504,
                                                                    "mcf": 0.014504,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.014504,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2756,
                                                                    "parentRowNumber": 2735,
                                                                    "articleId": "208406",
                                                                    "description": "208406-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0028,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0028,
                                                                    "singleQuantityMcf": 0.014504,
                                                                    "mcf": 0.014504,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.014504,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2757,
                                                                    "parentRowNumber": 2735,
                                                                    "articleId": "208406",
                                                                    "description": "208406-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0028,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0028,
                                                                    "singleQuantityMcf": 0.014504,
                                                                    "mcf": 0.014504,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.014504,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2758,
                                                                    "parentRowNumber": 2735,
                                                                    "articleId": "46411269",
                                                                    "description": "46411269-01-Klotz",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.1514,
                                                                    "childrenWeight": 0.1514,
                                                                    "totalQuantityWeight": 0.1514,
                                                                    "singleQuantityMcf": 0.784252,
                                                                    "mcf": 0.784252,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.784252,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 2759,
                                                                            "parentRowNumber": 2758,
                                                                            "articleId": "1110288",
                                                                            "description": "1110288-00-Flachstahl",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.1514,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.1514,
                                                                            "singleQuantityMcf": 0.784252,
                                                                            "mcf": 0.784252,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.784252,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 2760,
                                                                    "parentRowNumber": 2735,
                                                                    "articleId": "46411269",
                                                                    "description": "46411269-01-Klotz",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.1514,
                                                                    "childrenWeight": 0.1514,
                                                                    "totalQuantityWeight": 0.1514,
                                                                    "singleQuantityMcf": 0.784252,
                                                                    "mcf": 0.784252,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.784252,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 2761,
                                                                            "parentRowNumber": 2760,
                                                                            "articleId": "1110288",
                                                                            "description": "1110288-00-Flachstahl",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.1514,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.1514,
                                                                            "singleQuantityMcf": 0.784252,
                                                                            "mcf": 0.784252,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.784252,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 2762,
                                                                    "parentRowNumber": 2735,
                                                                    "articleId": "46411269",
                                                                    "description": "46411269-01-Klotz",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.1514,
                                                                    "childrenWeight": 0.1514,
                                                                    "totalQuantityWeight": 0.1514,
                                                                    "singleQuantityMcf": 0.784252,
                                                                    "mcf": 0.784252,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.784252,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 2763,
                                                                            "parentRowNumber": 2762,
                                                                            "articleId": "1110288",
                                                                            "description": "1110288-00-Flachstahl",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.1514,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.1514,
                                                                            "singleQuantityMcf": 0.784252,
                                                                            "mcf": 0.784252,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.784252,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 2764,
                                                                    "parentRowNumber": 2735,
                                                                    "articleId": "46411269",
                                                                    "description": "46411269-01-Klotz",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.1514,
                                                                    "childrenWeight": 0.1514,
                                                                    "totalQuantityWeight": 0.1514,
                                                                    "singleQuantityMcf": 0.784252,
                                                                    "mcf": 0.784252,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.784252,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 2765,
                                                                            "parentRowNumber": 2764,
                                                                            "articleId": "1110288",
                                                                            "description": "1110288-00-Flachstahl",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.1514,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.1514,
                                                                            "singleQuantityMcf": 0.784252,
                                                                            "mcf": 0.784252,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.784252,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 2766,
                                                                    "parentRowNumber": 2735,
                                                                    "articleId": "46411339",
                                                                    "description": "46411339-00-Blech",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0072,
                                                                    "childrenWeight": 0.0072,
                                                                    "totalQuantityWeight": 0.0072,
                                                                    "singleQuantityMcf": 0.037296,
                                                                    "mcf": 0.037296,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.037296,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 2767,
                                                                            "parentRowNumber": 2766,
                                                                            "articleId": "142604",
                                                                            "description": "142604-00-Edelstahlblech",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0072,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0072,
                                                                            "singleQuantityMcf": 0.037296,
                                                                            "mcf": 0.037296,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.037296,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 2768,
                                                                    "parentRowNumber": 2735,
                                                                    "articleId": "46411741",
                                                                    "description": "46411741-00-Distanzbolzen",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.2114,
                                                                    "childrenWeight": 0.2114,
                                                                    "totalQuantityWeight": 0.2114,
                                                                    "singleQuantityMcf": 1.095052,
                                                                    "mcf": 1.095052,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 1.095052,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 2769,
                                                                            "parentRowNumber": 2768,
                                                                            "articleId": "1260015",
                                                                            "description": "1260015-00-Rundstahl",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.2114,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.2114,
                                                                            "singleQuantityMcf": 1.095052,
                                                                            "mcf": 1.095052,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 1.095052,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 2770,
                                                                    "parentRowNumber": 2735,
                                                                    "articleId": "46411741",
                                                                    "description": "46411741-00-Distanzbolzen",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.2114,
                                                                    "childrenWeight": 0.2114,
                                                                    "totalQuantityWeight": 0.2114,
                                                                    "singleQuantityMcf": 1.095052,
                                                                    "mcf": 1.095052,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 1.095052,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 2771,
                                                                            "parentRowNumber": 2770,
                                                                            "articleId": "1260015",
                                                                            "description": "1260015-00-Rundstahl",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.2114,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.2114,
                                                                            "singleQuantityMcf": 1.095052,
                                                                            "mcf": 1.095052,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 1.095052,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 2772,
                                                                    "parentRowNumber": 2735,
                                                                    "articleId": "46411741",
                                                                    "description": "46411741-00-Distanzbolzen",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.2114,
                                                                    "childrenWeight": 0.2114,
                                                                    "totalQuantityWeight": 0.2114,
                                                                    "singleQuantityMcf": 1.095052,
                                                                    "mcf": 1.095052,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 1.095052,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 2773,
                                                                            "parentRowNumber": 2772,
                                                                            "articleId": "1260015",
                                                                            "description": "1260015-00-Rundstahl",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.2114,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.2114,
                                                                            "singleQuantityMcf": 1.095052,
                                                                            "mcf": 1.095052,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 1.095052,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 2774,
                                                                    "parentRowNumber": 2735,
                                                                    "articleId": "46411741",
                                                                    "description": "46411741-00-Distanzbolzen",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.2114,
                                                                    "childrenWeight": 0.2114,
                                                                    "totalQuantityWeight": 0.2114,
                                                                    "singleQuantityMcf": 1.095052,
                                                                    "mcf": 1.095052,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 1.095052,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 2775,
                                                                            "parentRowNumber": 2774,
                                                                            "articleId": "1260015",
                                                                            "description": "1260015-00-Rundstahl",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.2114,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.2114,
                                                                            "singleQuantityMcf": 1.095052,
                                                                            "mcf": 1.095052,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 1.095052,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 2776,
                                                                    "parentRowNumber": 2735,
                                                                    "articleId": "46411741",
                                                                    "description": "46411741-00-Distanzbolzen",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.2114,
                                                                    "childrenWeight": 0.2114,
                                                                    "totalQuantityWeight": 0.2114,
                                                                    "singleQuantityMcf": 1.095052,
                                                                    "mcf": 1.095052,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 1.095052,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 2777,
                                                                            "parentRowNumber": 2776,
                                                                            "articleId": "1260015",
                                                                            "description": "1260015-00-Rundstahl",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.2114,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.2114,
                                                                            "singleQuantityMcf": 1.095052,
                                                                            "mcf": 1.095052,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 1.095052,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 2778,
                                                                    "parentRowNumber": 2735,
                                                                    "articleId": "46411741",
                                                                    "description": "46411741-00-Distanzbolzen",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.2114,
                                                                    "childrenWeight": 0.2114,
                                                                    "totalQuantityWeight": 0.2114,
                                                                    "singleQuantityMcf": 1.095052,
                                                                    "mcf": 1.095052,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 1.095052,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 2779,
                                                                            "parentRowNumber": 2778,
                                                                            "articleId": "1260015",
                                                                            "description": "1260015-00-Rundstahl",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.2114,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.2114,
                                                                            "singleQuantityMcf": 1.095052,
                                                                            "mcf": 1.095052,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 1.095052,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 2780,
                                                                    "parentRowNumber": 2735,
                                                                    "articleId": "46411741",
                                                                    "description": "46411741-00-Distanzbolzen",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.2114,
                                                                    "childrenWeight": 0.2114,
                                                                    "totalQuantityWeight": 0.2114,
                                                                    "singleQuantityMcf": 1.095052,
                                                                    "mcf": 1.095052,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 1.095052,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 2781,
                                                                            "parentRowNumber": 2780,
                                                                            "articleId": "1260015",
                                                                            "description": "1260015-00-Rundstahl",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.2114,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.2114,
                                                                            "singleQuantityMcf": 1.095052,
                                                                            "mcf": 1.095052,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 1.095052,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 2782,
                                                                    "parentRowNumber": 2735,
                                                                    "articleId": "46411741",
                                                                    "description": "46411741-00-Distanzbolzen",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.2114,
                                                                    "childrenWeight": 0.2114,
                                                                    "totalQuantityWeight": 0.2114,
                                                                    "singleQuantityMcf": 1.095052,
                                                                    "mcf": 1.095052,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 1.095052,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 2783,
                                                                            "parentRowNumber": 2782,
                                                                            "articleId": "1260015",
                                                                            "description": "1260015-00-Rundstahl",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.2114,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.2114,
                                                                            "singleQuantityMcf": 1.095052,
                                                                            "mcf": 1.095052,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 1.095052,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 2784,
                                                                    "parentRowNumber": 2735,
                                                                    "articleId": "47127296",
                                                                    "description": "47127296-00-Blech",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.2063,
                                                                    "childrenWeight": 0.2063,
                                                                    "totalQuantityWeight": 0.2063,
                                                                    "singleQuantityMcf": 1.068634,
                                                                    "mcf": 1.068634,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 1.068634,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 2785,
                                                                            "parentRowNumber": 2784,
                                                                            "articleId": "142603",
                                                                            "description": "142603-00-Edelstahlblech",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.2063,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.2063,
                                                                            "singleQuantityMcf": 1.068634,
                                                                            "mcf": 1.068634,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 1.068634,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 2786,
                                                                    "parentRowNumber": 2735,
                                                                    "articleId": "47127297",
                                                                    "description": "47127297-00-Abstechblech",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.6988,
                                                                    "childrenWeight": 0.6988,
                                                                    "totalQuantityWeight": 0.6988,
                                                                    "singleQuantityMcf": 3.619784,
                                                                    "mcf": 3.619784,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 3.619784,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 2787,
                                                                            "parentRowNumber": 2786,
                                                                            "articleId": "142604",
                                                                            "description": "142604-00-Edelstahlblech",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.6988,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.6988,
                                                                            "singleQuantityMcf": 3.619784,
                                                                            "mcf": 3.619784,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 3.619784,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 2788,
                                                                    "parentRowNumber": 2735,
                                                                    "articleId": "47127299",
                                                                    "description": "47127299-00-Einlaufklotz",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 1.711,
                                                                    "childrenWeight": 1.711,
                                                                    "totalQuantityWeight": 1.711,
                                                                    "singleQuantityMcf": 5.116160000000001,
                                                                    "mcf": 5.116160000000001,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 5.116160000000001,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 2789,
                                                                            "parentRowNumber": 2788,
                                                                            "articleId": "162544",
                                                                            "description": "162544-01-Platte",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 1.5988,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 1.5988,
                                                                            "singleQuantityMcf": 5.116160000000001,
                                                                            "mcf": 5.116160000000001,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 5.116160000000001,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        },
                                                                        {
                                                                            "rowNumber": 2790,
                                                                            "parentRowNumber": 2788,
                                                                            "articleId": "323030",
                                                                            "description": "323030-02-Buegelgriff",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.1022,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.1022,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        },
                                                                        {
                                                                            "rowNumber": 2791,
                                                                            "parentRowNumber": 2788,
                                                                            "articleId": "347200",
                                                                            "description": "347200-00-Ensat-Gewindebuchse",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0025,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0025,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        },
                                                                        {
                                                                            "rowNumber": 2792,
                                                                            "parentRowNumber": 2788,
                                                                            "articleId": "347200",
                                                                            "description": "347200-00-Ensat-Gewindebuchse",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0025,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0025,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        },
                                                                        {
                                                                            "rowNumber": 2793,
                                                                            "parentRowNumber": 2788,
                                                                            "articleId": "347200",
                                                                            "description": "347200-00-Ensat-Gewindebuchse",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0025,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0025,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        },
                                                                        {
                                                                            "rowNumber": 2794,
                                                                            "parentRowNumber": 2788,
                                                                            "articleId": "347200",
                                                                            "description": "347200-00-Ensat-Gewindebuchse",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0025,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0025,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 2795,
                                                                    "parentRowNumber": 2735,
                                                                    "articleId": "47127301",
                                                                    "description": "47127301-00-Einlaufklotz",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.8095,
                                                                    "childrenWeight": 0.8095,
                                                                    "totalQuantityWeight": 0.8095,
                                                                    "singleQuantityMcf": 2.584,
                                                                    "mcf": 2.584,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 2.584,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 2796,
                                                                            "parentRowNumber": 2795,
                                                                            "articleId": "162538",
                                                                            "description": "162538-01-Platte",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.8075,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.8075,
                                                                            "singleQuantityMcf": 2.584,
                                                                            "mcf": 2.584,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 2.584,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        },
                                                                        {
                                                                            "rowNumber": 2797,
                                                                            "parentRowNumber": 2795,
                                                                            "articleId": "347196",
                                                                            "description": "347196-00-Ensat-Gewindebuchse",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.001,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.001,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        },
                                                                        {
                                                                            "rowNumber": 2798,
                                                                            "parentRowNumber": 2795,
                                                                            "articleId": "347196",
                                                                            "description": "347196-00-Ensat-Gewindebuchse",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.001,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.001,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 2799,
                                                                    "parentRowNumber": 2735,
                                                                    "articleId": "47129474",
                                                                    "description": "47129474-00-Fuehrung",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 2800,
                                                                            "parentRowNumber": 2799,
                                                                            "articleId": "248942",
                                                                            "description": "248942-00-Profil-Alukern",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        },
                                                                        {
                                                                            "rowNumber": 2801,
                                                                            "parentRowNumber": 2799,
                                                                            "articleId": "248943",
                                                                            "description": "248943-01-Fuehrungsleiste",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 2802,
                                                                    "parentRowNumber": 2735,
                                                                    "articleId": "47129474",
                                                                    "description": "47129474-00-Fuehrung",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 2803,
                                                                            "parentRowNumber": 2802,
                                                                            "articleId": "248942",
                                                                            "description": "248942-00-Profil-Alukern",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        },
                                                                        {
                                                                            "rowNumber": 2804,
                                                                            "parentRowNumber": 2802,
                                                                            "articleId": "248943",
                                                                            "description": "248943-01-Fuehrungsleiste",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 2805,
                                                                    "parentRowNumber": 2735,
                                                                    "articleId": "47129653",
                                                                    "description": "47129653-00-Platte",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.1374,
                                                                    "childrenWeight": 0.1374,
                                                                    "totalQuantityWeight": 0.1374,
                                                                    "singleQuantityMcf": 0.43328,
                                                                    "mcf": 0.43328,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.43328,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 2806,
                                                                            "parentRowNumber": 2805,
                                                                            "articleId": "162532",
                                                                            "description": "162532-01-Platte",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.1354,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.1354,
                                                                            "singleQuantityMcf": 0.43328,
                                                                            "mcf": 0.43328,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.43328,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        },
                                                                        {
                                                                            "rowNumber": 2807,
                                                                            "parentRowNumber": 2805,
                                                                            "articleId": "347196",
                                                                            "description": "347196-00-Ensat-Gewindebuchse",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.001,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.001,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        },
                                                                        {
                                                                            "rowNumber": 2808,
                                                                            "parentRowNumber": 2805,
                                                                            "articleId": "347196",
                                                                            "description": "347196-00-Ensat-Gewindebuchse",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.001,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.001,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 2809,
                                                                    "parentRowNumber": 2735,
                                                                    "articleId": "47129654",
                                                                    "description": "47129654-00-Platte",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.1016,
                                                                    "childrenWeight": 0.1016,
                                                                    "totalQuantityWeight": 0.1016,
                                                                    "singleQuantityMcf": 0.32512,
                                                                    "mcf": 0.32512,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.32512,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 2810,
                                                                            "parentRowNumber": 2809,
                                                                            "articleId": "162532",
                                                                            "description": "162532-01-Platte",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.1016,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.1016,
                                                                            "singleQuantityMcf": 0.32512,
                                                                            "mcf": 0.32512,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.32512,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 2811,
                                                                    "parentRowNumber": 2735,
                                                                    "articleId": "Magazin_33847_2",
                                                                    "description": "Magazin_33847_2-00-280x236",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 2.1701,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 2.1701,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": []
                                                                }
                                                            ]
                                                        }
                                                    ]
                                                }
                                            ]
                                        },
                                        {
                                            "rowNumber": 2812,
                                            "parentRowNumber": 2161,
                                            "articleId": "33847",
                                            "description": "33847-20DLF3-02-Objektfuehrung",
                                            "hierarchy": 4,
                                            "quantity": 1,
                                            "weight": 7.484299999999998,
                                            "childrenWeight": 7.484299999999998,
                                            "totalQuantityWeight": 7.484299999999998,
                                            "singleQuantityMcf": 0.193214,
                                            "mcf": 0.193214,
                                            "singleQuantityLcf": 0,
                                            "lcf": 0,
                                            "totalQuantityMcf": 0.193214,
                                            "expandable": false,
                                            "children": [
                                                {
                                                    "rowNumber": 2813,
                                                    "parentRowNumber": 2812,
                                                    "articleId": "MM0000005823",
                                                    "description": "MM0000005823-01-Objektfuehrung",
                                                    "hierarchy": 5,
                                                    "quantity": 1,
                                                    "weight": 7.484299999999998,
                                                    "childrenWeight": 7.484299999999998,
                                                    "totalQuantityWeight": 7.484299999999998,
                                                    "singleQuantityMcf": 0.193214,
                                                    "mcf": 0.193214,
                                                    "singleQuantityLcf": 0,
                                                    "lcf": 0,
                                                    "totalQuantityMcf": 0.193214,
                                                    "expandable": false,
                                                    "children": [
                                                        {
                                                            "rowNumber": 2814,
                                                            "parentRowNumber": 2813,
                                                            "articleId": "47127767",
                                                            "description": "47127767-01-Einlaufband",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 7.484299999999998,
                                                            "childrenWeight": 7.484299999999998,
                                                            "totalQuantityWeight": 7.484299999999998,
                                                            "singleQuantityMcf": 0.193214,
                                                            "mcf": 0.193214,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.193214,
                                                            "expandable": false,
                                                            "children": [
                                                                {
                                                                    "rowNumber": 2815,
                                                                    "parentRowNumber": 2814,
                                                                    "articleId": "20120615",
                                                                    "description": "20120615-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0061,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0061,
                                                                    "singleQuantityMcf": 0.031598,
                                                                    "mcf": 0.031598,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.031598,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2816,
                                                                    "parentRowNumber": 2814,
                                                                    "articleId": "20120615",
                                                                    "description": "20120615-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0061,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0061,
                                                                    "singleQuantityMcf": 0.031598,
                                                                    "mcf": 0.031598,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.031598,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2817,
                                                                    "parentRowNumber": 2814,
                                                                    "articleId": "20120615",
                                                                    "description": "20120615-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0061,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0061,
                                                                    "singleQuantityMcf": 0.031598,
                                                                    "mcf": 0.031598,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.031598,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2818,
                                                                    "parentRowNumber": 2814,
                                                                    "articleId": "20120615",
                                                                    "description": "20120615-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0061,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0061,
                                                                    "singleQuantityMcf": 0.031598,
                                                                    "mcf": 0.031598,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.031598,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2819,
                                                                    "parentRowNumber": 2814,
                                                                    "articleId": "20220410",
                                                                    "description": "20220410-00-Zylinderschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0017,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0017,
                                                                    "singleQuantityMcf": 0.008806,
                                                                    "mcf": 0.008806,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.008806,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2820,
                                                                    "parentRowNumber": 2814,
                                                                    "articleId": "20220645",
                                                                    "description": "20220645-00-Zylinderschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2821,
                                                                    "parentRowNumber": 2814,
                                                                    "articleId": "20220645",
                                                                    "description": "20220645-00-Zylinderschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2822,
                                                                    "parentRowNumber": 2814,
                                                                    "articleId": "20420425",
                                                                    "description": "20420425-00-Senkschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2823,
                                                                    "parentRowNumber": 2814,
                                                                    "articleId": "208406",
                                                                    "description": "208406-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0028,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0028,
                                                                    "singleQuantityMcf": 0.014504,
                                                                    "mcf": 0.014504,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.014504,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2824,
                                                                    "parentRowNumber": 2814,
                                                                    "articleId": "208406",
                                                                    "description": "208406-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0028,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0028,
                                                                    "singleQuantityMcf": 0.014504,
                                                                    "mcf": 0.014504,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.014504,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2825,
                                                                    "parentRowNumber": 2814,
                                                                    "articleId": "208406",
                                                                    "description": "208406-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0028,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0028,
                                                                    "singleQuantityMcf": 0.014504,
                                                                    "mcf": 0.014504,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.014504,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2826,
                                                                    "parentRowNumber": 2814,
                                                                    "articleId": "208406",
                                                                    "description": "208406-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0028,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0028,
                                                                    "singleQuantityMcf": 0.014504,
                                                                    "mcf": 0.014504,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.014504,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2827,
                                                                    "parentRowNumber": 2814,
                                                                    "articleId": "226204",
                                                                    "description": "226204-00-Sechskantmutter",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2828,
                                                                    "parentRowNumber": 2814,
                                                                    "articleId": "46099332",
                                                                    "description": "46099332-01-Klotz",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 2829,
                                                                            "parentRowNumber": 2828,
                                                                            "articleId": "162547",
                                                                            "description": "162547-01-Platte",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 2830,
                                                                    "parentRowNumber": 2814,
                                                                    "articleId": "47127784",
                                                                    "description": "47127784-00-Blech",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 2831,
                                                                            "parentRowNumber": 2830,
                                                                            "articleId": "142603",
                                                                            "description": "142603-00-Edelstahlblech",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 2832,
                                                                    "parentRowNumber": 2814,
                                                                    "articleId": "47127785",
                                                                    "description": "47127785-00-Abstechblech",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 2833,
                                                                            "parentRowNumber": 2832,
                                                                            "articleId": "142604",
                                                                            "description": "142604-00-Edelstahlblech",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 2834,
                                                                    "parentRowNumber": 2814,
                                                                    "articleId": "47129559",
                                                                    "description": "47129559-00-Fuehrung",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 2835,
                                                                            "parentRowNumber": 2834,
                                                                            "articleId": "248942",
                                                                            "description": "248942-00-Profil-Alukern",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        },
                                                                        {
                                                                            "rowNumber": 2836,
                                                                            "parentRowNumber": 2834,
                                                                            "articleId": "248944",
                                                                            "description": "248944-01-Fuehrungsleiste",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 2837,
                                                                    "parentRowNumber": 2814,
                                                                    "articleId": "47129618",
                                                                    "description": "47129618-00-Distanzbolzen",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 2838,
                                                                            "parentRowNumber": 2837,
                                                                            "articleId": "132714",
                                                                            "description": "132714-00-Sechskantstahl",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 2839,
                                                                    "parentRowNumber": 2814,
                                                                    "articleId": "47129618",
                                                                    "description": "47129618-00-Distanzbolzen",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 2840,
                                                                            "parentRowNumber": 2839,
                                                                            "articleId": "132714",
                                                                            "description": "132714-00-Sechskantstahl",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 2841,
                                                                    "parentRowNumber": 2814,
                                                                    "articleId": "47129618",
                                                                    "description": "47129618-00-Distanzbolzen",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 2842,
                                                                            "parentRowNumber": 2841,
                                                                            "articleId": "132714",
                                                                            "description": "132714-00-Sechskantstahl",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 2843,
                                                                    "parentRowNumber": 2814,
                                                                    "articleId": "47129618",
                                                                    "description": "47129618-00-Distanzbolzen",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 2844,
                                                                            "parentRowNumber": 2843,
                                                                            "articleId": "132714",
                                                                            "description": "132714-00-Sechskantstahl",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 2845,
                                                                    "parentRowNumber": 2814,
                                                                    "articleId": "47129663",
                                                                    "description": "47129663-01-Einlaufklotz",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.1022,
                                                                    "childrenWeight": 0.1022,
                                                                    "totalQuantityWeight": 0.1022,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 2846,
                                                                            "parentRowNumber": 2845,
                                                                            "articleId": "162547",
                                                                            "description": "162547-01-Platte",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        },
                                                                        {
                                                                            "rowNumber": 2847,
                                                                            "parentRowNumber": 2845,
                                                                            "articleId": "323030",
                                                                            "description": "323030-02-Buegelgriff",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.1022,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.1022,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 2848,
                                                                    "parentRowNumber": 2814,
                                                                    "articleId": "Magazin_33847_3",
                                                                    "description": "Magazin_33847_3-00-549x341",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 7.3448,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 7.3448,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": []
                                                                }
                                                            ]
                                                        }
                                                    ]
                                                }
                                            ]
                                        },
                                        {
                                            "rowNumber": 2849,
                                            "parentRowNumber": 2161,
                                            "articleId": "33847",
                                            "description": "33847-20DLF4-02-Objektfuehrung",
                                            "hierarchy": 4,
                                            "quantity": 1,
                                            "weight": 5.2138,
                                            "childrenWeight": 5.2138,
                                            "totalQuantityWeight": 5.2138,
                                            "singleQuantityMcf": 3.5379399999999985,
                                            "mcf": 3.5379399999999985,
                                            "singleQuantityLcf": 0,
                                            "lcf": 0,
                                            "totalQuantityMcf": 3.5379399999999985,
                                            "expandable": false,
                                            "children": [
                                                {
                                                    "rowNumber": 2850,
                                                    "parentRowNumber": 2849,
                                                    "articleId": "MM0000005824",
                                                    "description": "MM0000005824-01-Objektfuehrung",
                                                    "hierarchy": 5,
                                                    "quantity": 1,
                                                    "weight": 5.2138,
                                                    "childrenWeight": 5.2138,
                                                    "totalQuantityWeight": 5.2138,
                                                    "singleQuantityMcf": 3.5379399999999985,
                                                    "mcf": 3.5379399999999985,
                                                    "singleQuantityLcf": 0,
                                                    "lcf": 0,
                                                    "totalQuantityMcf": 3.5379399999999985,
                                                    "expandable": false,
                                                    "children": [
                                                        {
                                                            "rowNumber": 2851,
                                                            "parentRowNumber": 2850,
                                                            "articleId": "47127772",
                                                            "description": "47127772-01-Einlaufband",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 5.2138,
                                                            "childrenWeight": 5.2138,
                                                            "totalQuantityWeight": 5.2138,
                                                            "singleQuantityMcf": 3.5379399999999985,
                                                            "mcf": 3.5379399999999985,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 3.5379399999999985,
                                                            "expandable": false,
                                                            "children": [
                                                                {
                                                                    "rowNumber": 2852,
                                                                    "parentRowNumber": 2851,
                                                                    "articleId": "20120410",
                                                                    "description": "20120410-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0019,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0019,
                                                                    "singleQuantityMcf": 0.009842,
                                                                    "mcf": 0.009842,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.009842,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2853,
                                                                    "parentRowNumber": 2851,
                                                                    "articleId": "20120410",
                                                                    "description": "20120410-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0019,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0019,
                                                                    "singleQuantityMcf": 0.009842,
                                                                    "mcf": 0.009842,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.009842,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2854,
                                                                    "parentRowNumber": 2851,
                                                                    "articleId": "20120615",
                                                                    "description": "20120615-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0061,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0061,
                                                                    "singleQuantityMcf": 0.031598,
                                                                    "mcf": 0.031598,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.031598,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2855,
                                                                    "parentRowNumber": 2851,
                                                                    "articleId": "20120615",
                                                                    "description": "20120615-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0061,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0061,
                                                                    "singleQuantityMcf": 0.031598,
                                                                    "mcf": 0.031598,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.031598,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2856,
                                                                    "parentRowNumber": 2851,
                                                                    "articleId": "20120615",
                                                                    "description": "20120615-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0061,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0061,
                                                                    "singleQuantityMcf": 0.031598,
                                                                    "mcf": 0.031598,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.031598,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2857,
                                                                    "parentRowNumber": 2851,
                                                                    "articleId": "20120615",
                                                                    "description": "20120615-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0061,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0061,
                                                                    "singleQuantityMcf": 0.031598,
                                                                    "mcf": 0.031598,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.031598,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2858,
                                                                    "parentRowNumber": 2851,
                                                                    "articleId": "20220520",
                                                                    "description": "20220520-00-Zylinderschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0044,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0044,
                                                                    "singleQuantityMcf": 0.022792,
                                                                    "mcf": 0.022792,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.022792,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2859,
                                                                    "parentRowNumber": 2851,
                                                                    "articleId": "20220520",
                                                                    "description": "20220520-00-Zylinderschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0044,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0044,
                                                                    "singleQuantityMcf": 0.022792,
                                                                    "mcf": 0.022792,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.022792,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2860,
                                                                    "parentRowNumber": 2851,
                                                                    "articleId": "20220520",
                                                                    "description": "20220520-00-Zylinderschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0044,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0044,
                                                                    "singleQuantityMcf": 0.022792,
                                                                    "mcf": 0.022792,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.022792,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2861,
                                                                    "parentRowNumber": 2851,
                                                                    "articleId": "20220520",
                                                                    "description": "20220520-00-Zylinderschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0044,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0044,
                                                                    "singleQuantityMcf": 0.022792,
                                                                    "mcf": 0.022792,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.022792,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2862,
                                                                    "parentRowNumber": 2851,
                                                                    "articleId": "20420412",
                                                                    "description": "20420412-00-Senkschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0013,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0013,
                                                                    "singleQuantityMcf": 0.006734,
                                                                    "mcf": 0.006734,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.006734,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2863,
                                                                    "parentRowNumber": 2851,
                                                                    "articleId": "20420412",
                                                                    "description": "20420412-00-Senkschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0013,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0013,
                                                                    "singleQuantityMcf": 0.006734,
                                                                    "mcf": 0.006734,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.006734,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2864,
                                                                    "parentRowNumber": 2851,
                                                                    "articleId": "20420412",
                                                                    "description": "20420412-00-Senkschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0013,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0013,
                                                                    "singleQuantityMcf": 0.006734,
                                                                    "mcf": 0.006734,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.006734,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2865,
                                                                    "parentRowNumber": 2851,
                                                                    "articleId": "20420412",
                                                                    "description": "20420412-00-Senkschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0013,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0013,
                                                                    "singleQuantityMcf": 0.006734,
                                                                    "mcf": 0.006734,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.006734,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2866,
                                                                    "parentRowNumber": 2851,
                                                                    "articleId": "20420412",
                                                                    "description": "20420412-00-Senkschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0013,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0013,
                                                                    "singleQuantityMcf": 0.006734,
                                                                    "mcf": 0.006734,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.006734,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2867,
                                                                    "parentRowNumber": 2851,
                                                                    "articleId": "20420412",
                                                                    "description": "20420412-00-Senkschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0013,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0013,
                                                                    "singleQuantityMcf": 0.006734,
                                                                    "mcf": 0.006734,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.006734,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2868,
                                                                    "parentRowNumber": 2851,
                                                                    "articleId": "20720505",
                                                                    "description": "20720505-00-Gewindestift",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0006,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0006,
                                                                    "singleQuantityMcf": 0.003108,
                                                                    "mcf": 0.003108,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.003108,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2869,
                                                                    "parentRowNumber": 2851,
                                                                    "articleId": "20720505",
                                                                    "description": "20720505-00-Gewindestift",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0006,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0006,
                                                                    "singleQuantityMcf": 0.003108,
                                                                    "mcf": 0.003108,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.003108,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2870,
                                                                    "parentRowNumber": 2851,
                                                                    "articleId": "20720505",
                                                                    "description": "20720505-00-Gewindestift",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0006,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0006,
                                                                    "singleQuantityMcf": 0.003108,
                                                                    "mcf": 0.003108,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.003108,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2871,
                                                                    "parentRowNumber": 2851,
                                                                    "articleId": "20720505",
                                                                    "description": "20720505-00-Gewindestift",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0006,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0006,
                                                                    "singleQuantityMcf": 0.003108,
                                                                    "mcf": 0.003108,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.003108,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2872,
                                                                    "parentRowNumber": 2851,
                                                                    "articleId": "20720505",
                                                                    "description": "20720505-00-Gewindestift",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0006,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0006,
                                                                    "singleQuantityMcf": 0.003108,
                                                                    "mcf": 0.003108,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.003108,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2873,
                                                                    "parentRowNumber": 2851,
                                                                    "articleId": "20720505",
                                                                    "description": "20720505-00-Gewindestift",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0006,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0006,
                                                                    "singleQuantityMcf": 0.003108,
                                                                    "mcf": 0.003108,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.003108,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2874,
                                                                    "parentRowNumber": 2851,
                                                                    "articleId": "20720505",
                                                                    "description": "20720505-00-Gewindestift",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0006,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0006,
                                                                    "singleQuantityMcf": 0.003108,
                                                                    "mcf": 0.003108,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.003108,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2875,
                                                                    "parentRowNumber": 2851,
                                                                    "articleId": "20720505",
                                                                    "description": "20720505-00-Gewindestift",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0006,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0006,
                                                                    "singleQuantityMcf": 0.003108,
                                                                    "mcf": 0.003108,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.003108,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2876,
                                                                    "parentRowNumber": 2851,
                                                                    "articleId": "208204",
                                                                    "description": "208204-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0003,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0003,
                                                                    "singleQuantityMcf": 0.0015539999999999998,
                                                                    "mcf": 0.0015539999999999998,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.0015539999999999998,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2877,
                                                                    "parentRowNumber": 2851,
                                                                    "articleId": "208204",
                                                                    "description": "208204-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0003,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0003,
                                                                    "singleQuantityMcf": 0.0015539999999999998,
                                                                    "mcf": 0.0015539999999999998,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.0015539999999999998,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2878,
                                                                    "parentRowNumber": 2851,
                                                                    "articleId": "208406",
                                                                    "description": "208406-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0028,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0028,
                                                                    "singleQuantityMcf": 0.014504,
                                                                    "mcf": 0.014504,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.014504,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2879,
                                                                    "parentRowNumber": 2851,
                                                                    "articleId": "208406",
                                                                    "description": "208406-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0028,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0028,
                                                                    "singleQuantityMcf": 0.014504,
                                                                    "mcf": 0.014504,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.014504,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2880,
                                                                    "parentRowNumber": 2851,
                                                                    "articleId": "208406",
                                                                    "description": "208406-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0028,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0028,
                                                                    "singleQuantityMcf": 0.014504,
                                                                    "mcf": 0.014504,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.014504,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2881,
                                                                    "parentRowNumber": 2851,
                                                                    "articleId": "208406",
                                                                    "description": "208406-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0028,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0028,
                                                                    "singleQuantityMcf": 0.014504,
                                                                    "mcf": 0.014504,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.014504,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2882,
                                                                    "parentRowNumber": 2851,
                                                                    "articleId": "46411269",
                                                                    "description": "46411269-01-Klotz",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.1514,
                                                                    "childrenWeight": 0.1514,
                                                                    "totalQuantityWeight": 0.1514,
                                                                    "singleQuantityMcf": 0.784252,
                                                                    "mcf": 0.784252,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.784252,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 2883,
                                                                            "parentRowNumber": 2882,
                                                                            "articleId": "1110288",
                                                                            "description": "1110288-00-Flachstahl",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.1514,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.1514,
                                                                            "singleQuantityMcf": 0.784252,
                                                                            "mcf": 0.784252,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.784252,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 2884,
                                                                    "parentRowNumber": 2851,
                                                                    "articleId": "46411269",
                                                                    "description": "46411269-01-Klotz",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.1514,
                                                                    "childrenWeight": 0.1514,
                                                                    "totalQuantityWeight": 0.1514,
                                                                    "singleQuantityMcf": 0.784252,
                                                                    "mcf": 0.784252,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.784252,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 2885,
                                                                            "parentRowNumber": 2884,
                                                                            "articleId": "1110288",
                                                                            "description": "1110288-00-Flachstahl",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.1514,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.1514,
                                                                            "singleQuantityMcf": 0.784252,
                                                                            "mcf": 0.784252,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.784252,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 2886,
                                                                    "parentRowNumber": 2851,
                                                                    "articleId": "46411269",
                                                                    "description": "46411269-01-Klotz",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.1514,
                                                                    "childrenWeight": 0.1514,
                                                                    "totalQuantityWeight": 0.1514,
                                                                    "singleQuantityMcf": 0.784252,
                                                                    "mcf": 0.784252,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.784252,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 2887,
                                                                            "parentRowNumber": 2886,
                                                                            "articleId": "1110288",
                                                                            "description": "1110288-00-Flachstahl",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.1514,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.1514,
                                                                            "singleQuantityMcf": 0.784252,
                                                                            "mcf": 0.784252,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.784252,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 2888,
                                                                    "parentRowNumber": 2851,
                                                                    "articleId": "46411269",
                                                                    "description": "46411269-01-Klotz",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.1514,
                                                                    "childrenWeight": 0.1514,
                                                                    "totalQuantityWeight": 0.1514,
                                                                    "singleQuantityMcf": 0.784252,
                                                                    "mcf": 0.784252,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.784252,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 2889,
                                                                            "parentRowNumber": 2888,
                                                                            "articleId": "1110288",
                                                                            "description": "1110288-00-Flachstahl",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.1514,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.1514,
                                                                            "singleQuantityMcf": 0.784252,
                                                                            "mcf": 0.784252,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.784252,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 2890,
                                                                    "parentRowNumber": 2851,
                                                                    "articleId": "46411339",
                                                                    "description": "46411339-00-Blech",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0072,
                                                                    "childrenWeight": 0.0072,
                                                                    "totalQuantityWeight": 0.0072,
                                                                    "singleQuantityMcf": 0.037296,
                                                                    "mcf": 0.037296,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.037296,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 2891,
                                                                            "parentRowNumber": 2890,
                                                                            "articleId": "142604",
                                                                            "description": "142604-00-Edelstahlblech",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0072,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0072,
                                                                            "singleQuantityMcf": 0.037296,
                                                                            "mcf": 0.037296,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.037296,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 2892,
                                                                    "parentRowNumber": 2851,
                                                                    "articleId": "46411436",
                                                                    "description": "46411436-02-Platte",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.002,
                                                                    "childrenWeight": 0.002,
                                                                    "totalQuantityWeight": 0.002,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 2893,
                                                                            "parentRowNumber": 2892,
                                                                            "articleId": "162532",
                                                                            "description": "162532-01-Platte",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        },
                                                                        {
                                                                            "rowNumber": 2894,
                                                                            "parentRowNumber": 2892,
                                                                            "articleId": "347196",
                                                                            "description": "347196-00-Ensat-Gewindebuchse",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.001,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.001,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        },
                                                                        {
                                                                            "rowNumber": 2895,
                                                                            "parentRowNumber": 2892,
                                                                            "articleId": "347196",
                                                                            "description": "347196-00-Ensat-Gewindebuchse",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.001,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.001,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 2896,
                                                                    "parentRowNumber": 2851,
                                                                    "articleId": "46411440",
                                                                    "description": "46411440-01-Platte",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 2897,
                                                                            "parentRowNumber": 2896,
                                                                            "articleId": "162532",
                                                                            "description": "162532-01-Platte",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 2898,
                                                                    "parentRowNumber": 2851,
                                                                    "articleId": "46726060",
                                                                    "description": "46726060-01-Fuehrung",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 2899,
                                                                            "parentRowNumber": 2898,
                                                                            "articleId": "248942",
                                                                            "description": "248942-00-Profil-Alukern",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        },
                                                                        {
                                                                            "rowNumber": 2900,
                                                                            "parentRowNumber": 2898,
                                                                            "articleId": "248943",
                                                                            "description": "248943-01-Fuehrungsleiste",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 2901,
                                                                    "parentRowNumber": 2851,
                                                                    "articleId": "46726060",
                                                                    "description": "46726060-01-Fuehrung",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 2902,
                                                                            "parentRowNumber": 2901,
                                                                            "articleId": "248942",
                                                                            "description": "248942-00-Profil-Alukern",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        },
                                                                        {
                                                                            "rowNumber": 2903,
                                                                            "parentRowNumber": 2901,
                                                                            "articleId": "248943",
                                                                            "description": "248943-01-Fuehrungsleiste",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 2904,
                                                                    "parentRowNumber": 2851,
                                                                    "articleId": "46798807",
                                                                    "description": "46798807-00-Distanzbolzen",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 2905,
                                                                            "parentRowNumber": 2904,
                                                                            "articleId": "1260015",
                                                                            "description": "1260015-00-Rundstahl",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 2906,
                                                                    "parentRowNumber": 2851,
                                                                    "articleId": "46798807",
                                                                    "description": "46798807-00-Distanzbolzen",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 2907,
                                                                            "parentRowNumber": 2906,
                                                                            "articleId": "1260015",
                                                                            "description": "1260015-00-Rundstahl",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 2908,
                                                                    "parentRowNumber": 2851,
                                                                    "articleId": "46798807",
                                                                    "description": "46798807-00-Distanzbolzen",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 2909,
                                                                            "parentRowNumber": 2908,
                                                                            "articleId": "1260015",
                                                                            "description": "1260015-00-Rundstahl",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 2910,
                                                                    "parentRowNumber": 2851,
                                                                    "articleId": "46798807",
                                                                    "description": "46798807-00-Distanzbolzen",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 2911,
                                                                            "parentRowNumber": 2910,
                                                                            "articleId": "1260015",
                                                                            "description": "1260015-00-Rundstahl",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 2912,
                                                                    "parentRowNumber": 2851,
                                                                    "articleId": "46798807",
                                                                    "description": "46798807-00-Distanzbolzen",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 2913,
                                                                            "parentRowNumber": 2912,
                                                                            "articleId": "1260015",
                                                                            "description": "1260015-00-Rundstahl",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 2914,
                                                                    "parentRowNumber": 2851,
                                                                    "articleId": "46798807",
                                                                    "description": "46798807-00-Distanzbolzen",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 2915,
                                                                            "parentRowNumber": 2914,
                                                                            "articleId": "1260015",
                                                                            "description": "1260015-00-Rundstahl",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 2916,
                                                                    "parentRowNumber": 2851,
                                                                    "articleId": "46798807",
                                                                    "description": "46798807-00-Distanzbolzen",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 2917,
                                                                            "parentRowNumber": 2916,
                                                                            "articleId": "1260015",
                                                                            "description": "1260015-00-Rundstahl",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 2918,
                                                                    "parentRowNumber": 2851,
                                                                    "articleId": "46798807",
                                                                    "description": "46798807-00-Distanzbolzen",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 2919,
                                                                            "parentRowNumber": 2918,
                                                                            "articleId": "1260015",
                                                                            "description": "1260015-00-Rundstahl",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 2920,
                                                                    "parentRowNumber": 2851,
                                                                    "articleId": "47127791",
                                                                    "description": "47127791-00-Blech",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 2921,
                                                                            "parentRowNumber": 2920,
                                                                            "articleId": "142603",
                                                                            "description": "142603-00-Edelstahlblech",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 2922,
                                                                    "parentRowNumber": 2851,
                                                                    "articleId": "47127792",
                                                                    "description": "47127792-00-Abstechblech",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 2923,
                                                                            "parentRowNumber": 2922,
                                                                            "articleId": "142604",
                                                                            "description": "142604-00-Edelstahlblech",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 2924,
                                                                    "parentRowNumber": 2851,
                                                                    "articleId": "47127793",
                                                                    "description": "47127793-01-Einlaufklotz",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.1122,
                                                                    "childrenWeight": 0.1122,
                                                                    "totalQuantityWeight": 0.1122,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 2925,
                                                                            "parentRowNumber": 2924,
                                                                            "articleId": "162544",
                                                                            "description": "162544-01-Platte",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        },
                                                                        {
                                                                            "rowNumber": 2926,
                                                                            "parentRowNumber": 2924,
                                                                            "articleId": "323030",
                                                                            "description": "323030-02-Buegelgriff",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.1022,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.1022,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        },
                                                                        {
                                                                            "rowNumber": 2927,
                                                                            "parentRowNumber": 2924,
                                                                            "articleId": "347200",
                                                                            "description": "347200-00-Ensat-Gewindebuchse",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0025,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0025,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        },
                                                                        {
                                                                            "rowNumber": 2928,
                                                                            "parentRowNumber": 2924,
                                                                            "articleId": "347200",
                                                                            "description": "347200-00-Ensat-Gewindebuchse",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0025,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0025,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        },
                                                                        {
                                                                            "rowNumber": 2929,
                                                                            "parentRowNumber": 2924,
                                                                            "articleId": "347200",
                                                                            "description": "347200-00-Ensat-Gewindebuchse",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0025,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0025,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        },
                                                                        {
                                                                            "rowNumber": 2930,
                                                                            "parentRowNumber": 2924,
                                                                            "articleId": "347200",
                                                                            "description": "347200-00-Ensat-Gewindebuchse",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0025,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0025,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 2931,
                                                                    "parentRowNumber": 2851,
                                                                    "articleId": "47127794",
                                                                    "description": "47127794-00-Einlaufklotz",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.002,
                                                                    "childrenWeight": 0.002,
                                                                    "totalQuantityWeight": 0.002,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 2932,
                                                                            "parentRowNumber": 2931,
                                                                            "articleId": "162538",
                                                                            "description": "162538-01-Platte",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        },
                                                                        {
                                                                            "rowNumber": 2933,
                                                                            "parentRowNumber": 2931,
                                                                            "articleId": "347196",
                                                                            "description": "347196-00-Ensat-Gewindebuchse",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.001,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.001,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        },
                                                                        {
                                                                            "rowNumber": 2934,
                                                                            "parentRowNumber": 2931,
                                                                            "articleId": "347196",
                                                                            "description": "347196-00-Ensat-Gewindebuchse",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.001,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.001,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 2935,
                                                                    "parentRowNumber": 2851,
                                                                    "articleId": "Magazin_33847_4",
                                                                    "description": "Magazin_33847_4-00-380x290",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 4.4146,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 4.4146,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": []
                                                                }
                                                            ]
                                                        }
                                                    ]
                                                }
                                            ]
                                        },
                                        {
                                            "rowNumber": 2936,
                                            "parentRowNumber": 2161,
                                            "articleId": "33847",
                                            "description": "33847-20DLF5-00-Objektfuehrung",
                                            "hierarchy": 4,
                                            "quantity": 1,
                                            "weight": 4.767499999999996,
                                            "childrenWeight": 4.767499999999996,
                                            "totalQuantityWeight": 4.767499999999996,
                                            "singleQuantityMcf": 16.218956,
                                            "mcf": 16.218956,
                                            "singleQuantityLcf": 0,
                                            "lcf": 0,
                                            "totalQuantityMcf": 16.218956,
                                            "expandable": false,
                                            "children": [
                                                {
                                                    "rowNumber": 2937,
                                                    "parentRowNumber": 2936,
                                                    "articleId": "MM0000007482",
                                                    "description": "MM0000007482-00-Objektfuehrung",
                                                    "hierarchy": 5,
                                                    "quantity": 1,
                                                    "weight": 4.767499999999996,
                                                    "childrenWeight": 4.767499999999996,
                                                    "totalQuantityWeight": 4.767499999999996,
                                                    "singleQuantityMcf": 16.218956,
                                                    "mcf": 16.218956,
                                                    "singleQuantityLcf": 0,
                                                    "lcf": 0,
                                                    "totalQuantityMcf": 16.218956,
                                                    "expandable": false,
                                                    "children": [
                                                        {
                                                            "rowNumber": 2938,
                                                            "parentRowNumber": 2937,
                                                            "articleId": "20120540",
                                                            "description": "20120540-00-Sechskantschraube",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0077,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0077,
                                                            "singleQuantityMcf": 0.039886,
                                                            "mcf": 0.039886,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.039886,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 2939,
                                                            "parentRowNumber": 2937,
                                                            "articleId": "20120540",
                                                            "description": "20120540-00-Sechskantschraube",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0077,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0077,
                                                            "singleQuantityMcf": 0.039886,
                                                            "mcf": 0.039886,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.039886,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 2940,
                                                            "parentRowNumber": 2937,
                                                            "articleId": "20120540",
                                                            "description": "20120540-00-Sechskantschraube",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0077,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0077,
                                                            "singleQuantityMcf": 0.039886,
                                                            "mcf": 0.039886,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.039886,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 2941,
                                                            "parentRowNumber": 2937,
                                                            "articleId": "20120540",
                                                            "description": "20120540-00-Sechskantschraube",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0077,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0077,
                                                            "singleQuantityMcf": 0.039886,
                                                            "mcf": 0.039886,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.039886,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 2942,
                                                            "parentRowNumber": 2937,
                                                            "articleId": "20120540",
                                                            "description": "20120540-00-Sechskantschraube",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0077,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0077,
                                                            "singleQuantityMcf": 0.039886,
                                                            "mcf": 0.039886,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.039886,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 2943,
                                                            "parentRowNumber": 2937,
                                                            "articleId": "20120540",
                                                            "description": "20120540-00-Sechskantschraube",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0077,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0077,
                                                            "singleQuantityMcf": 0.039886,
                                                            "mcf": 0.039886,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.039886,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 2944,
                                                            "parentRowNumber": 2937,
                                                            "articleId": "20120540",
                                                            "description": "20120540-00-Sechskantschraube",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0077,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0077,
                                                            "singleQuantityMcf": 0.039886,
                                                            "mcf": 0.039886,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.039886,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 2945,
                                                            "parentRowNumber": 2937,
                                                            "articleId": "20120540",
                                                            "description": "20120540-00-Sechskantschraube",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0077,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0077,
                                                            "singleQuantityMcf": 0.039886,
                                                            "mcf": 0.039886,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.039886,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 2946,
                                                            "parentRowNumber": 2937,
                                                            "articleId": "20120540",
                                                            "description": "20120540-00-Sechskantschraube",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0077,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0077,
                                                            "singleQuantityMcf": 0.039886,
                                                            "mcf": 0.039886,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.039886,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 2947,
                                                            "parentRowNumber": 2937,
                                                            "articleId": "20120540",
                                                            "description": "20120540-00-Sechskantschraube",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0077,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0077,
                                                            "singleQuantityMcf": 0.039886,
                                                            "mcf": 0.039886,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.039886,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 2948,
                                                            "parentRowNumber": 2937,
                                                            "articleId": "20120540",
                                                            "description": "20120540-00-Sechskantschraube",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0077,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0077,
                                                            "singleQuantityMcf": 0.039886,
                                                            "mcf": 0.039886,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.039886,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 2949,
                                                            "parentRowNumber": 2937,
                                                            "articleId": "20120540",
                                                            "description": "20120540-00-Sechskantschraube",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0077,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0077,
                                                            "singleQuantityMcf": 0.039886,
                                                            "mcf": 0.039886,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.039886,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 2950,
                                                            "parentRowNumber": 2937,
                                                            "articleId": "20120540",
                                                            "description": "20120540-00-Sechskantschraube",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0077,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0077,
                                                            "singleQuantityMcf": 0.039886,
                                                            "mcf": 0.039886,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.039886,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 2951,
                                                            "parentRowNumber": 2937,
                                                            "articleId": "20120540",
                                                            "description": "20120540-00-Sechskantschraube",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0077,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0077,
                                                            "singleQuantityMcf": 0.039886,
                                                            "mcf": 0.039886,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.039886,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 2952,
                                                            "parentRowNumber": 2937,
                                                            "articleId": "20120540",
                                                            "description": "20120540-00-Sechskantschraube",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0077,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0077,
                                                            "singleQuantityMcf": 0.039886,
                                                            "mcf": 0.039886,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.039886,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 2953,
                                                            "parentRowNumber": 2937,
                                                            "articleId": "20120540",
                                                            "description": "20120540-00-Sechskantschraube",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0077,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0077,
                                                            "singleQuantityMcf": 0.039886,
                                                            "mcf": 0.039886,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.039886,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 2954,
                                                            "parentRowNumber": 2937,
                                                            "articleId": "208405",
                                                            "description": "208405-00-Scheibe",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0015,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0015,
                                                            "singleQuantityMcf": 0.00777,
                                                            "mcf": 0.00777,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.00777,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 2955,
                                                            "parentRowNumber": 2937,
                                                            "articleId": "208405",
                                                            "description": "208405-00-Scheibe",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0015,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0015,
                                                            "singleQuantityMcf": 0.00777,
                                                            "mcf": 0.00777,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.00777,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 2956,
                                                            "parentRowNumber": 2937,
                                                            "articleId": "208405",
                                                            "description": "208405-00-Scheibe",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0015,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0015,
                                                            "singleQuantityMcf": 0.00777,
                                                            "mcf": 0.00777,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.00777,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 2957,
                                                            "parentRowNumber": 2937,
                                                            "articleId": "208405",
                                                            "description": "208405-00-Scheibe",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0015,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0015,
                                                            "singleQuantityMcf": 0.00777,
                                                            "mcf": 0.00777,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.00777,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 2958,
                                                            "parentRowNumber": 2937,
                                                            "articleId": "208405",
                                                            "description": "208405-00-Scheibe",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0015,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0015,
                                                            "singleQuantityMcf": 0.00777,
                                                            "mcf": 0.00777,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.00777,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 2959,
                                                            "parentRowNumber": 2937,
                                                            "articleId": "208405",
                                                            "description": "208405-00-Scheibe",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0015,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0015,
                                                            "singleQuantityMcf": 0.00777,
                                                            "mcf": 0.00777,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.00777,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 2960,
                                                            "parentRowNumber": 2937,
                                                            "articleId": "208405",
                                                            "description": "208405-00-Scheibe",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0015,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0015,
                                                            "singleQuantityMcf": 0.00777,
                                                            "mcf": 0.00777,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.00777,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 2961,
                                                            "parentRowNumber": 2937,
                                                            "articleId": "208405",
                                                            "description": "208405-00-Scheibe",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0015,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0015,
                                                            "singleQuantityMcf": 0.00777,
                                                            "mcf": 0.00777,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.00777,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 2962,
                                                            "parentRowNumber": 2937,
                                                            "articleId": "208405",
                                                            "description": "208405-00-Scheibe",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0015,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0015,
                                                            "singleQuantityMcf": 0.00777,
                                                            "mcf": 0.00777,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.00777,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 2963,
                                                            "parentRowNumber": 2937,
                                                            "articleId": "208405",
                                                            "description": "208405-00-Scheibe",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0015,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0015,
                                                            "singleQuantityMcf": 0.00777,
                                                            "mcf": 0.00777,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.00777,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 2964,
                                                            "parentRowNumber": 2937,
                                                            "articleId": "208405",
                                                            "description": "208405-00-Scheibe",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0015,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0015,
                                                            "singleQuantityMcf": 0.00777,
                                                            "mcf": 0.00777,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.00777,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 2965,
                                                            "parentRowNumber": 2937,
                                                            "articleId": "208405",
                                                            "description": "208405-00-Scheibe",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0015,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0015,
                                                            "singleQuantityMcf": 0.00777,
                                                            "mcf": 0.00777,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.00777,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 2966,
                                                            "parentRowNumber": 2937,
                                                            "articleId": "208405",
                                                            "description": "208405-00-Scheibe",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0015,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0015,
                                                            "singleQuantityMcf": 0.00777,
                                                            "mcf": 0.00777,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.00777,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 2967,
                                                            "parentRowNumber": 2937,
                                                            "articleId": "208405",
                                                            "description": "208405-00-Scheibe",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0015,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0015,
                                                            "singleQuantityMcf": 0.00777,
                                                            "mcf": 0.00777,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.00777,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 2968,
                                                            "parentRowNumber": 2937,
                                                            "articleId": "208405",
                                                            "description": "208405-00-Scheibe",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0015,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0015,
                                                            "singleQuantityMcf": 0.00777,
                                                            "mcf": 0.00777,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.00777,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 2969,
                                                            "parentRowNumber": 2937,
                                                            "articleId": "208405",
                                                            "description": "208405-00-Scheibe",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0015,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0015,
                                                            "singleQuantityMcf": 0.00777,
                                                            "mcf": 0.00777,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.00777,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 2970,
                                                            "parentRowNumber": 2937,
                                                            "articleId": "22070610",
                                                            "description": "22070610-00-Fluegelschraube",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0237,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0237,
                                                            "singleQuantityMcf": 0.041001,
                                                            "mcf": 0.041001,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.041001,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 2971,
                                                            "parentRowNumber": 2937,
                                                            "articleId": "22070610",
                                                            "description": "22070610-00-Fluegelschraube",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0237,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0237,
                                                            "singleQuantityMcf": 0.041001,
                                                            "mcf": 0.041001,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.041001,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 2972,
                                                            "parentRowNumber": 2937,
                                                            "articleId": "22070610",
                                                            "description": "22070610-00-Fluegelschraube",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0237,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0237,
                                                            "singleQuantityMcf": 0.041001,
                                                            "mcf": 0.041001,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.041001,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 2973,
                                                            "parentRowNumber": 2937,
                                                            "articleId": "22070610",
                                                            "description": "22070610-00-Fluegelschraube",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0237,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0237,
                                                            "singleQuantityMcf": 0.041001,
                                                            "mcf": 0.041001,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.041001,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 2974,
                                                            "parentRowNumber": 2937,
                                                            "articleId": "46645927",
                                                            "description": "46645927-00-Formatbuegel 2.0",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.1103,
                                                            "childrenWeight": 0.1103,
                                                            "totalQuantityWeight": 0.1103,
                                                            "singleQuantityMcf": 0.5713539999999999,
                                                            "mcf": 0.5713539999999999,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.5713539999999999,
                                                            "expandable": false,
                                                            "children": [
                                                                {
                                                                    "rowNumber": 2975,
                                                                    "parentRowNumber": 2974,
                                                                    "articleId": "142612",
                                                                    "description": "142612-00-Edelstahlblech",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0515,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0515,
                                                                    "singleQuantityMcf": 0.26676999999999995,
                                                                    "mcf": 0.26676999999999995,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.26676999999999995,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2976,
                                                                    "parentRowNumber": 2974,
                                                                    "articleId": "46647477",
                                                                    "description": "46647477-00-Halter",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0294,
                                                                    "childrenWeight": 0.0294,
                                                                    "totalQuantityWeight": 0.0294,
                                                                    "singleQuantityMcf": 0.15229199999999998,
                                                                    "mcf": 0.15229199999999998,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.15229199999999998,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 2977,
                                                                            "parentRowNumber": 2976,
                                                                            "articleId": "1110231",
                                                                            "description": "1110231-00-Flachstahl",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0294,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0294,
                                                                            "singleQuantityMcf": 0.15229199999999998,
                                                                            "mcf": 0.15229199999999998,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.15229199999999998,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 2978,
                                                                    "parentRowNumber": 2974,
                                                                    "articleId": "46647477",
                                                                    "description": "46647477-00-Halter",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0294,
                                                                    "childrenWeight": 0.0294,
                                                                    "totalQuantityWeight": 0.0294,
                                                                    "singleQuantityMcf": 0.15229199999999998,
                                                                    "mcf": 0.15229199999999998,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.15229199999999998,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 2979,
                                                                            "parentRowNumber": 2978,
                                                                            "articleId": "1110231",
                                                                            "description": "1110231-00-Flachstahl",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0294,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0294,
                                                                            "singleQuantityMcf": 0.15229199999999998,
                                                                            "mcf": 0.15229199999999998,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.15229199999999998,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            "rowNumber": 2980,
                                                            "parentRowNumber": 2937,
                                                            "articleId": "46645927",
                                                            "description": "46645927-00-Formatbuegel 2.0",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.1103,
                                                            "childrenWeight": 0.1103,
                                                            "totalQuantityWeight": 0.1103,
                                                            "singleQuantityMcf": 0.5713539999999999,
                                                            "mcf": 0.5713539999999999,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.5713539999999999,
                                                            "expandable": false,
                                                            "children": [
                                                                {
                                                                    "rowNumber": 2981,
                                                                    "parentRowNumber": 2980,
                                                                    "articleId": "142612",
                                                                    "description": "142612-00-Edelstahlblech",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0515,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0515,
                                                                    "singleQuantityMcf": 0.26676999999999995,
                                                                    "mcf": 0.26676999999999995,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.26676999999999995,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2982,
                                                                    "parentRowNumber": 2980,
                                                                    "articleId": "46647477",
                                                                    "description": "46647477-00-Halter",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0294,
                                                                    "childrenWeight": 0.0294,
                                                                    "totalQuantityWeight": 0.0294,
                                                                    "singleQuantityMcf": 0.15229199999999998,
                                                                    "mcf": 0.15229199999999998,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.15229199999999998,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 2983,
                                                                            "parentRowNumber": 2982,
                                                                            "articleId": "1110231",
                                                                            "description": "1110231-00-Flachstahl",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0294,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0294,
                                                                            "singleQuantityMcf": 0.15229199999999998,
                                                                            "mcf": 0.15229199999999998,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.15229199999999998,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 2984,
                                                                    "parentRowNumber": 2980,
                                                                    "articleId": "46647477",
                                                                    "description": "46647477-00-Halter",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0294,
                                                                    "childrenWeight": 0.0294,
                                                                    "totalQuantityWeight": 0.0294,
                                                                    "singleQuantityMcf": 0.15229199999999998,
                                                                    "mcf": 0.15229199999999998,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.15229199999999998,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 2985,
                                                                            "parentRowNumber": 2984,
                                                                            "articleId": "1110231",
                                                                            "description": "1110231-00-Flachstahl",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0294,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0294,
                                                                            "singleQuantityMcf": 0.15229199999999998,
                                                                            "mcf": 0.15229199999999998,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.15229199999999998,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            "rowNumber": 2986,
                                                            "parentRowNumber": 2937,
                                                            "articleId": "46645927",
                                                            "description": "46645927-00-Formatbuegel 2.0",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.1103,
                                                            "childrenWeight": 0.1103,
                                                            "totalQuantityWeight": 0.1103,
                                                            "singleQuantityMcf": 0.5713539999999999,
                                                            "mcf": 0.5713539999999999,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.5713539999999999,
                                                            "expandable": false,
                                                            "children": [
                                                                {
                                                                    "rowNumber": 2987,
                                                                    "parentRowNumber": 2986,
                                                                    "articleId": "142612",
                                                                    "description": "142612-00-Edelstahlblech",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0515,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0515,
                                                                    "singleQuantityMcf": 0.26676999999999995,
                                                                    "mcf": 0.26676999999999995,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.26676999999999995,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2988,
                                                                    "parentRowNumber": 2986,
                                                                    "articleId": "46647477",
                                                                    "description": "46647477-00-Halter",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0294,
                                                                    "childrenWeight": 0.0294,
                                                                    "totalQuantityWeight": 0.0294,
                                                                    "singleQuantityMcf": 0.15229199999999998,
                                                                    "mcf": 0.15229199999999998,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.15229199999999998,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 2989,
                                                                            "parentRowNumber": 2988,
                                                                            "articleId": "1110231",
                                                                            "description": "1110231-00-Flachstahl",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0294,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0294,
                                                                            "singleQuantityMcf": 0.15229199999999998,
                                                                            "mcf": 0.15229199999999998,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.15229199999999998,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 2990,
                                                                    "parentRowNumber": 2986,
                                                                    "articleId": "46647477",
                                                                    "description": "46647477-00-Halter",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0294,
                                                                    "childrenWeight": 0.0294,
                                                                    "totalQuantityWeight": 0.0294,
                                                                    "singleQuantityMcf": 0.15229199999999998,
                                                                    "mcf": 0.15229199999999998,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.15229199999999998,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 2991,
                                                                            "parentRowNumber": 2990,
                                                                            "articleId": "1110231",
                                                                            "description": "1110231-00-Flachstahl",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0294,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0294,
                                                                            "singleQuantityMcf": 0.15229199999999998,
                                                                            "mcf": 0.15229199999999998,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.15229199999999998,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            "rowNumber": 2992,
                                                            "parentRowNumber": 2937,
                                                            "articleId": "46645927",
                                                            "description": "46645927-00-Formatbuegel 2.0",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.1103,
                                                            "childrenWeight": 0.1103,
                                                            "totalQuantityWeight": 0.1103,
                                                            "singleQuantityMcf": 0.5713539999999999,
                                                            "mcf": 0.5713539999999999,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.5713539999999999,
                                                            "expandable": false,
                                                            "children": [
                                                                {
                                                                    "rowNumber": 2993,
                                                                    "parentRowNumber": 2992,
                                                                    "articleId": "142612",
                                                                    "description": "142612-00-Edelstahlblech",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0515,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0515,
                                                                    "singleQuantityMcf": 0.26676999999999995,
                                                                    "mcf": 0.26676999999999995,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.26676999999999995,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 2994,
                                                                    "parentRowNumber": 2992,
                                                                    "articleId": "46647477",
                                                                    "description": "46647477-00-Halter",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0294,
                                                                    "childrenWeight": 0.0294,
                                                                    "totalQuantityWeight": 0.0294,
                                                                    "singleQuantityMcf": 0.15229199999999998,
                                                                    "mcf": 0.15229199999999998,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.15229199999999998,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 2995,
                                                                            "parentRowNumber": 2994,
                                                                            "articleId": "1110231",
                                                                            "description": "1110231-00-Flachstahl",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0294,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0294,
                                                                            "singleQuantityMcf": 0.15229199999999998,
                                                                            "mcf": 0.15229199999999998,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.15229199999999998,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 2996,
                                                                    "parentRowNumber": 2992,
                                                                    "articleId": "46647477",
                                                                    "description": "46647477-00-Halter",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0294,
                                                                    "childrenWeight": 0.0294,
                                                                    "totalQuantityWeight": 0.0294,
                                                                    "singleQuantityMcf": 0.15229199999999998,
                                                                    "mcf": 0.15229199999999998,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.15229199999999998,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 2997,
                                                                            "parentRowNumber": 2996,
                                                                            "articleId": "1110231",
                                                                            "description": "1110231-00-Flachstahl",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0294,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0294,
                                                                            "singleQuantityMcf": 0.15229199999999998,
                                                                            "mcf": 0.15229199999999998,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.15229199999999998,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            "rowNumber": 2998,
                                                            "parentRowNumber": 2937,
                                                            "articleId": "47157196",
                                                            "description": "47157196-00-Fuehrung",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 1.4075,
                                                            "childrenWeight": 1.4075,
                                                            "totalQuantityWeight": 1.4075,
                                                            "singleQuantityMcf": 4.504,
                                                            "mcf": 4.504,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 4.504,
                                                            "expandable": false,
                                                            "children": [
                                                                {
                                                                    "rowNumber": 2999,
                                                                    "parentRowNumber": 2998,
                                                                    "articleId": "162544",
                                                                    "description": "162544-01-Platte",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 1.4075,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 1.4075,
                                                                    "singleQuantityMcf": 4.504,
                                                                    "mcf": 4.504,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 4.504,
                                                                    "expandable": false,
                                                                    "children": []
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            "rowNumber": 3000,
                                                            "parentRowNumber": 2937,
                                                            "articleId": "47157197",
                                                            "description": "47157197-00-Fuehrung",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.6429,
                                                            "childrenWeight": 0.6429,
                                                            "totalQuantityWeight": 0.6429,
                                                            "singleQuantityMcf": 2.02592,
                                                            "mcf": 2.02592,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 2.02592,
                                                            "expandable": false,
                                                            "children": [
                                                                {
                                                                    "rowNumber": 3001,
                                                                    "parentRowNumber": 3000,
                                                                    "articleId": "162544",
                                                                    "description": "162544-01-Platte",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.6331,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.6331,
                                                                    "singleQuantityMcf": 2.02592,
                                                                    "mcf": 2.02592,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 2.02592,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3002,
                                                                    "parentRowNumber": 3000,
                                                                    "articleId": "347204",
                                                                    "description": "347204-00-Ensat-Gewindebuchse",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0049,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0049,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3003,
                                                                    "parentRowNumber": 3000,
                                                                    "articleId": "347204",
                                                                    "description": "347204-00-Ensat-Gewindebuchse",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0049,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0049,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": []
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            "rowNumber": 3004,
                                                            "parentRowNumber": 2937,
                                                            "articleId": "47167196",
                                                            "description": "47167196-00-Fuehrung",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.6377,
                                                            "childrenWeight": 0.6377,
                                                            "totalQuantityWeight": 0.6377,
                                                            "singleQuantityMcf": 2.00928,
                                                            "mcf": 2.00928,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 2.00928,
                                                            "expandable": false,
                                                            "children": [
                                                                {
                                                                    "rowNumber": 3005,
                                                                    "parentRowNumber": 3004,
                                                                    "articleId": "162544",
                                                                    "description": "162544-01-Platte",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.6279,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.6279,
                                                                    "singleQuantityMcf": 2.00928,
                                                                    "mcf": 2.00928,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 2.00928,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3006,
                                                                    "parentRowNumber": 3004,
                                                                    "articleId": "347204",
                                                                    "description": "347204-00-Ensat-Gewindebuchse",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0049,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0049,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3007,
                                                                    "parentRowNumber": 3004,
                                                                    "articleId": "347204",
                                                                    "description": "347204-00-Ensat-Gewindebuchse",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0049,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0049,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": []
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            "rowNumber": 3008,
                                                            "parentRowNumber": 2937,
                                                            "articleId": "47167197",
                                                            "description": "47167197-00-Fuehrung",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 1.3962,
                                                            "childrenWeight": 1.3962,
                                                            "totalQuantityWeight": 1.3962,
                                                            "singleQuantityMcf": 4.467840000000001,
                                                            "mcf": 4.467840000000001,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 4.467840000000001,
                                                            "expandable": false,
                                                            "children": [
                                                                {
                                                                    "rowNumber": 3009,
                                                                    "parentRowNumber": 3008,
                                                                    "articleId": "162544",
                                                                    "description": "162544-01-Platte",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 1.3962,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 1.3962,
                                                                    "singleQuantityMcf": 4.467840000000001,
                                                                    "mcf": 4.467840000000001,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 4.467840000000001,
                                                                    "expandable": false,
                                                                    "children": []
                                                                }
                                                            ]
                                                        }
                                                    ]
                                                }
                                            ]
                                        },
                                        {
                                            "rowNumber": 3010,
                                            "parentRowNumber": 2161,
                                            "articleId": "33847",
                                            "description": "33847-20DLF6-00-Objektfuehrung",
                                            "hierarchy": 4,
                                            "quantity": 1,
                                            "weight": 4.808299999999997,
                                            "childrenWeight": 4.808299999999997,
                                            "totalQuantityWeight": 4.808299999999997,
                                            "singleQuantityMcf": 16.4303,
                                            "mcf": 16.4303,
                                            "singleQuantityLcf": 0,
                                            "lcf": 0,
                                            "totalQuantityMcf": 16.4303,
                                            "expandable": false,
                                            "children": [
                                                {
                                                    "rowNumber": 3011,
                                                    "parentRowNumber": 3010,
                                                    "articleId": "MM0000009693",
                                                    "description": "MM0000009693-00-Objektfuehrung",
                                                    "hierarchy": 5,
                                                    "quantity": 1,
                                                    "weight": 4.808299999999997,
                                                    "childrenWeight": 4.808299999999997,
                                                    "totalQuantityWeight": 4.808299999999997,
                                                    "singleQuantityMcf": 16.4303,
                                                    "mcf": 16.4303,
                                                    "singleQuantityLcf": 0,
                                                    "lcf": 0,
                                                    "totalQuantityMcf": 16.4303,
                                                    "expandable": false,
                                                    "children": [
                                                        {
                                                            "rowNumber": 3012,
                                                            "parentRowNumber": 3011,
                                                            "articleId": "20120540",
                                                            "description": "20120540-00-Sechskantschraube",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0077,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0077,
                                                            "singleQuantityMcf": 0.039886,
                                                            "mcf": 0.039886,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.039886,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 3013,
                                                            "parentRowNumber": 3011,
                                                            "articleId": "20120540",
                                                            "description": "20120540-00-Sechskantschraube",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0077,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0077,
                                                            "singleQuantityMcf": 0.039886,
                                                            "mcf": 0.039886,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.039886,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 3014,
                                                            "parentRowNumber": 3011,
                                                            "articleId": "20120540",
                                                            "description": "20120540-00-Sechskantschraube",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0077,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0077,
                                                            "singleQuantityMcf": 0.039886,
                                                            "mcf": 0.039886,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.039886,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 3015,
                                                            "parentRowNumber": 3011,
                                                            "articleId": "20120540",
                                                            "description": "20120540-00-Sechskantschraube",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0077,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0077,
                                                            "singleQuantityMcf": 0.039886,
                                                            "mcf": 0.039886,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.039886,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 3016,
                                                            "parentRowNumber": 3011,
                                                            "articleId": "20120540",
                                                            "description": "20120540-00-Sechskantschraube",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0077,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0077,
                                                            "singleQuantityMcf": 0.039886,
                                                            "mcf": 0.039886,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.039886,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 3017,
                                                            "parentRowNumber": 3011,
                                                            "articleId": "20120540",
                                                            "description": "20120540-00-Sechskantschraube",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0077,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0077,
                                                            "singleQuantityMcf": 0.039886,
                                                            "mcf": 0.039886,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.039886,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 3018,
                                                            "parentRowNumber": 3011,
                                                            "articleId": "20120540",
                                                            "description": "20120540-00-Sechskantschraube",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0077,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0077,
                                                            "singleQuantityMcf": 0.039886,
                                                            "mcf": 0.039886,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.039886,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 3019,
                                                            "parentRowNumber": 3011,
                                                            "articleId": "20120540",
                                                            "description": "20120540-00-Sechskantschraube",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0077,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0077,
                                                            "singleQuantityMcf": 0.039886,
                                                            "mcf": 0.039886,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.039886,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 3020,
                                                            "parentRowNumber": 3011,
                                                            "articleId": "20120540",
                                                            "description": "20120540-00-Sechskantschraube",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0077,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0077,
                                                            "singleQuantityMcf": 0.039886,
                                                            "mcf": 0.039886,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.039886,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 3021,
                                                            "parentRowNumber": 3011,
                                                            "articleId": "20120540",
                                                            "description": "20120540-00-Sechskantschraube",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0077,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0077,
                                                            "singleQuantityMcf": 0.039886,
                                                            "mcf": 0.039886,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.039886,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 3022,
                                                            "parentRowNumber": 3011,
                                                            "articleId": "20120540",
                                                            "description": "20120540-00-Sechskantschraube",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0077,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0077,
                                                            "singleQuantityMcf": 0.039886,
                                                            "mcf": 0.039886,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.039886,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 3023,
                                                            "parentRowNumber": 3011,
                                                            "articleId": "20120540",
                                                            "description": "20120540-00-Sechskantschraube",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0077,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0077,
                                                            "singleQuantityMcf": 0.039886,
                                                            "mcf": 0.039886,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.039886,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 3024,
                                                            "parentRowNumber": 3011,
                                                            "articleId": "20120540",
                                                            "description": "20120540-00-Sechskantschraube",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0077,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0077,
                                                            "singleQuantityMcf": 0.039886,
                                                            "mcf": 0.039886,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.039886,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 3025,
                                                            "parentRowNumber": 3011,
                                                            "articleId": "20120540",
                                                            "description": "20120540-00-Sechskantschraube",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0077,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0077,
                                                            "singleQuantityMcf": 0.039886,
                                                            "mcf": 0.039886,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.039886,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 3026,
                                                            "parentRowNumber": 3011,
                                                            "articleId": "20120540",
                                                            "description": "20120540-00-Sechskantschraube",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0077,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0077,
                                                            "singleQuantityMcf": 0.039886,
                                                            "mcf": 0.039886,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.039886,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 3027,
                                                            "parentRowNumber": 3011,
                                                            "articleId": "20120540",
                                                            "description": "20120540-00-Sechskantschraube",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0077,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0077,
                                                            "singleQuantityMcf": 0.039886,
                                                            "mcf": 0.039886,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.039886,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 3028,
                                                            "parentRowNumber": 3011,
                                                            "articleId": "208405",
                                                            "description": "208405-00-Scheibe",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0015,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0015,
                                                            "singleQuantityMcf": 0.00777,
                                                            "mcf": 0.00777,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.00777,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 3029,
                                                            "parentRowNumber": 3011,
                                                            "articleId": "208405",
                                                            "description": "208405-00-Scheibe",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0015,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0015,
                                                            "singleQuantityMcf": 0.00777,
                                                            "mcf": 0.00777,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.00777,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 3030,
                                                            "parentRowNumber": 3011,
                                                            "articleId": "208405",
                                                            "description": "208405-00-Scheibe",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0015,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0015,
                                                            "singleQuantityMcf": 0.00777,
                                                            "mcf": 0.00777,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.00777,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 3031,
                                                            "parentRowNumber": 3011,
                                                            "articleId": "208405",
                                                            "description": "208405-00-Scheibe",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0015,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0015,
                                                            "singleQuantityMcf": 0.00777,
                                                            "mcf": 0.00777,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.00777,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 3032,
                                                            "parentRowNumber": 3011,
                                                            "articleId": "208405",
                                                            "description": "208405-00-Scheibe",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0015,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0015,
                                                            "singleQuantityMcf": 0.00777,
                                                            "mcf": 0.00777,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.00777,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 3033,
                                                            "parentRowNumber": 3011,
                                                            "articleId": "208405",
                                                            "description": "208405-00-Scheibe",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0015,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0015,
                                                            "singleQuantityMcf": 0.00777,
                                                            "mcf": 0.00777,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.00777,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 3034,
                                                            "parentRowNumber": 3011,
                                                            "articleId": "208405",
                                                            "description": "208405-00-Scheibe",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0015,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0015,
                                                            "singleQuantityMcf": 0.00777,
                                                            "mcf": 0.00777,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.00777,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 3035,
                                                            "parentRowNumber": 3011,
                                                            "articleId": "208405",
                                                            "description": "208405-00-Scheibe",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0015,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0015,
                                                            "singleQuantityMcf": 0.00777,
                                                            "mcf": 0.00777,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.00777,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 3036,
                                                            "parentRowNumber": 3011,
                                                            "articleId": "208405",
                                                            "description": "208405-00-Scheibe",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0015,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0015,
                                                            "singleQuantityMcf": 0.00777,
                                                            "mcf": 0.00777,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.00777,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 3037,
                                                            "parentRowNumber": 3011,
                                                            "articleId": "208405",
                                                            "description": "208405-00-Scheibe",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0015,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0015,
                                                            "singleQuantityMcf": 0.00777,
                                                            "mcf": 0.00777,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.00777,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 3038,
                                                            "parentRowNumber": 3011,
                                                            "articleId": "208405",
                                                            "description": "208405-00-Scheibe",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0015,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0015,
                                                            "singleQuantityMcf": 0.00777,
                                                            "mcf": 0.00777,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.00777,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 3039,
                                                            "parentRowNumber": 3011,
                                                            "articleId": "208405",
                                                            "description": "208405-00-Scheibe",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0015,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0015,
                                                            "singleQuantityMcf": 0.00777,
                                                            "mcf": 0.00777,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.00777,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 3040,
                                                            "parentRowNumber": 3011,
                                                            "articleId": "208405",
                                                            "description": "208405-00-Scheibe",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0015,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0015,
                                                            "singleQuantityMcf": 0.00777,
                                                            "mcf": 0.00777,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.00777,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 3041,
                                                            "parentRowNumber": 3011,
                                                            "articleId": "208405",
                                                            "description": "208405-00-Scheibe",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0015,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0015,
                                                            "singleQuantityMcf": 0.00777,
                                                            "mcf": 0.00777,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.00777,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 3042,
                                                            "parentRowNumber": 3011,
                                                            "articleId": "208405",
                                                            "description": "208405-00-Scheibe",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0015,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0015,
                                                            "singleQuantityMcf": 0.00777,
                                                            "mcf": 0.00777,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.00777,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 3043,
                                                            "parentRowNumber": 3011,
                                                            "articleId": "208405",
                                                            "description": "208405-00-Scheibe",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0015,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0015,
                                                            "singleQuantityMcf": 0.00777,
                                                            "mcf": 0.00777,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.00777,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 3044,
                                                            "parentRowNumber": 3011,
                                                            "articleId": "22070610",
                                                            "description": "22070610-00-Fluegelschraube",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0237,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0237,
                                                            "singleQuantityMcf": 0.041001,
                                                            "mcf": 0.041001,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.041001,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 3045,
                                                            "parentRowNumber": 3011,
                                                            "articleId": "22070610",
                                                            "description": "22070610-00-Fluegelschraube",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0237,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0237,
                                                            "singleQuantityMcf": 0.041001,
                                                            "mcf": 0.041001,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.041001,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 3046,
                                                            "parentRowNumber": 3011,
                                                            "articleId": "22070610",
                                                            "description": "22070610-00-Fluegelschraube",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0237,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0237,
                                                            "singleQuantityMcf": 0.041001,
                                                            "mcf": 0.041001,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.041001,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 3047,
                                                            "parentRowNumber": 3011,
                                                            "articleId": "22070610",
                                                            "description": "22070610-00-Fluegelschraube",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0237,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0237,
                                                            "singleQuantityMcf": 0.041001,
                                                            "mcf": 0.041001,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.041001,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 3048,
                                                            "parentRowNumber": 3011,
                                                            "articleId": "46645928",
                                                            "description": "46645928-00-Formatbuegel 2.0",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.1205,
                                                            "childrenWeight": 0.1205,
                                                            "totalQuantityWeight": 0.1205,
                                                            "singleQuantityMcf": 0.6241899999999999,
                                                            "mcf": 0.6241899999999999,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.6241899999999999,
                                                            "expandable": false,
                                                            "children": [
                                                                {
                                                                    "rowNumber": 3049,
                                                                    "parentRowNumber": 3048,
                                                                    "articleId": "142612",
                                                                    "description": "142612-00-Edelstahlblech",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0617,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0617,
                                                                    "singleQuantityMcf": 0.31960599999999995,
                                                                    "mcf": 0.31960599999999995,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.31960599999999995,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3050,
                                                                    "parentRowNumber": 3048,
                                                                    "articleId": "46647477",
                                                                    "description": "46647477-00-Halter",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0294,
                                                                    "childrenWeight": 0.0294,
                                                                    "totalQuantityWeight": 0.0294,
                                                                    "singleQuantityMcf": 0.15229199999999998,
                                                                    "mcf": 0.15229199999999998,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.15229199999999998,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 3051,
                                                                            "parentRowNumber": 3050,
                                                                            "articleId": "1110231",
                                                                            "description": "1110231-00-Flachstahl",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0294,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0294,
                                                                            "singleQuantityMcf": 0.15229199999999998,
                                                                            "mcf": 0.15229199999999998,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.15229199999999998,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 3052,
                                                                    "parentRowNumber": 3048,
                                                                    "articleId": "46647477",
                                                                    "description": "46647477-00-Halter",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0294,
                                                                    "childrenWeight": 0.0294,
                                                                    "totalQuantityWeight": 0.0294,
                                                                    "singleQuantityMcf": 0.15229199999999998,
                                                                    "mcf": 0.15229199999999998,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.15229199999999998,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 3053,
                                                                            "parentRowNumber": 3052,
                                                                            "articleId": "1110231",
                                                                            "description": "1110231-00-Flachstahl",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0294,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0294,
                                                                            "singleQuantityMcf": 0.15229199999999998,
                                                                            "mcf": 0.15229199999999998,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.15229199999999998,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            "rowNumber": 3054,
                                                            "parentRowNumber": 3011,
                                                            "articleId": "46645928",
                                                            "description": "46645928-00-Formatbuegel 2.0",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.1205,
                                                            "childrenWeight": 0.1205,
                                                            "totalQuantityWeight": 0.1205,
                                                            "singleQuantityMcf": 0.6241899999999999,
                                                            "mcf": 0.6241899999999999,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.6241899999999999,
                                                            "expandable": false,
                                                            "children": [
                                                                {
                                                                    "rowNumber": 3055,
                                                                    "parentRowNumber": 3054,
                                                                    "articleId": "142612",
                                                                    "description": "142612-00-Edelstahlblech",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0617,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0617,
                                                                    "singleQuantityMcf": 0.31960599999999995,
                                                                    "mcf": 0.31960599999999995,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.31960599999999995,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3056,
                                                                    "parentRowNumber": 3054,
                                                                    "articleId": "46647477",
                                                                    "description": "46647477-00-Halter",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0294,
                                                                    "childrenWeight": 0.0294,
                                                                    "totalQuantityWeight": 0.0294,
                                                                    "singleQuantityMcf": 0.15229199999999998,
                                                                    "mcf": 0.15229199999999998,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.15229199999999998,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 3057,
                                                                            "parentRowNumber": 3056,
                                                                            "articleId": "1110231",
                                                                            "description": "1110231-00-Flachstahl",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0294,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0294,
                                                                            "singleQuantityMcf": 0.15229199999999998,
                                                                            "mcf": 0.15229199999999998,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.15229199999999998,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 3058,
                                                                    "parentRowNumber": 3054,
                                                                    "articleId": "46647477",
                                                                    "description": "46647477-00-Halter",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0294,
                                                                    "childrenWeight": 0.0294,
                                                                    "totalQuantityWeight": 0.0294,
                                                                    "singleQuantityMcf": 0.15229199999999998,
                                                                    "mcf": 0.15229199999999998,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.15229199999999998,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 3059,
                                                                            "parentRowNumber": 3058,
                                                                            "articleId": "1110231",
                                                                            "description": "1110231-00-Flachstahl",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0294,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0294,
                                                                            "singleQuantityMcf": 0.15229199999999998,
                                                                            "mcf": 0.15229199999999998,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.15229199999999998,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            "rowNumber": 3060,
                                                            "parentRowNumber": 3011,
                                                            "articleId": "46645928",
                                                            "description": "46645928-00-Formatbuegel 2.0",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.1205,
                                                            "childrenWeight": 0.1205,
                                                            "totalQuantityWeight": 0.1205,
                                                            "singleQuantityMcf": 0.6241899999999999,
                                                            "mcf": 0.6241899999999999,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.6241899999999999,
                                                            "expandable": false,
                                                            "children": [
                                                                {
                                                                    "rowNumber": 3061,
                                                                    "parentRowNumber": 3060,
                                                                    "articleId": "142612",
                                                                    "description": "142612-00-Edelstahlblech",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0617,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0617,
                                                                    "singleQuantityMcf": 0.31960599999999995,
                                                                    "mcf": 0.31960599999999995,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.31960599999999995,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3062,
                                                                    "parentRowNumber": 3060,
                                                                    "articleId": "46647477",
                                                                    "description": "46647477-00-Halter",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0294,
                                                                    "childrenWeight": 0.0294,
                                                                    "totalQuantityWeight": 0.0294,
                                                                    "singleQuantityMcf": 0.15229199999999998,
                                                                    "mcf": 0.15229199999999998,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.15229199999999998,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 3063,
                                                                            "parentRowNumber": 3062,
                                                                            "articleId": "1110231",
                                                                            "description": "1110231-00-Flachstahl",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0294,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0294,
                                                                            "singleQuantityMcf": 0.15229199999999998,
                                                                            "mcf": 0.15229199999999998,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.15229199999999998,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 3064,
                                                                    "parentRowNumber": 3060,
                                                                    "articleId": "46647477",
                                                                    "description": "46647477-00-Halter",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0294,
                                                                    "childrenWeight": 0.0294,
                                                                    "totalQuantityWeight": 0.0294,
                                                                    "singleQuantityMcf": 0.15229199999999998,
                                                                    "mcf": 0.15229199999999998,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.15229199999999998,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 3065,
                                                                            "parentRowNumber": 3064,
                                                                            "articleId": "1110231",
                                                                            "description": "1110231-00-Flachstahl",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0294,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0294,
                                                                            "singleQuantityMcf": 0.15229199999999998,
                                                                            "mcf": 0.15229199999999998,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.15229199999999998,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            "rowNumber": 3066,
                                                            "parentRowNumber": 3011,
                                                            "articleId": "46645928",
                                                            "description": "46645928-00-Formatbuegel 2.0",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.1205,
                                                            "childrenWeight": 0.1205,
                                                            "totalQuantityWeight": 0.1205,
                                                            "singleQuantityMcf": 0.6241899999999999,
                                                            "mcf": 0.6241899999999999,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.6241899999999999,
                                                            "expandable": false,
                                                            "children": [
                                                                {
                                                                    "rowNumber": 3067,
                                                                    "parentRowNumber": 3066,
                                                                    "articleId": "142612",
                                                                    "description": "142612-00-Edelstahlblech",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0617,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0617,
                                                                    "singleQuantityMcf": 0.31960599999999995,
                                                                    "mcf": 0.31960599999999995,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.31960599999999995,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3068,
                                                                    "parentRowNumber": 3066,
                                                                    "articleId": "46647477",
                                                                    "description": "46647477-00-Halter",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0294,
                                                                    "childrenWeight": 0.0294,
                                                                    "totalQuantityWeight": 0.0294,
                                                                    "singleQuantityMcf": 0.15229199999999998,
                                                                    "mcf": 0.15229199999999998,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.15229199999999998,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 3069,
                                                                            "parentRowNumber": 3068,
                                                                            "articleId": "1110231",
                                                                            "description": "1110231-00-Flachstahl",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0294,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0294,
                                                                            "singleQuantityMcf": 0.15229199999999998,
                                                                            "mcf": 0.15229199999999998,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.15229199999999998,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 3070,
                                                                    "parentRowNumber": 3066,
                                                                    "articleId": "46647477",
                                                                    "description": "46647477-00-Halter",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0294,
                                                                    "childrenWeight": 0.0294,
                                                                    "totalQuantityWeight": 0.0294,
                                                                    "singleQuantityMcf": 0.15229199999999998,
                                                                    "mcf": 0.15229199999999998,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.15229199999999998,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 3071,
                                                                            "parentRowNumber": 3070,
                                                                            "articleId": "1110231",
                                                                            "description": "1110231-00-Flachstahl",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0294,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0294,
                                                                            "singleQuantityMcf": 0.15229199999999998,
                                                                            "mcf": 0.15229199999999998,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.15229199999999998,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            "rowNumber": 3072,
                                                            "parentRowNumber": 3011,
                                                            "articleId": "47157197",
                                                            "description": "47157197-00-Fuehrung",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.6429,
                                                            "childrenWeight": 0.6429,
                                                            "totalQuantityWeight": 0.6429,
                                                            "singleQuantityMcf": 2.02592,
                                                            "mcf": 2.02592,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 2.02592,
                                                            "expandable": false,
                                                            "children": [
                                                                {
                                                                    "rowNumber": 3073,
                                                                    "parentRowNumber": 3072,
                                                                    "articleId": "162544",
                                                                    "description": "162544-01-Platte",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.6331,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.6331,
                                                                    "singleQuantityMcf": 2.02592,
                                                                    "mcf": 2.02592,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 2.02592,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3074,
                                                                    "parentRowNumber": 3072,
                                                                    "articleId": "347204",
                                                                    "description": "347204-00-Ensat-Gewindebuchse",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0049,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0049,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3075,
                                                                    "parentRowNumber": 3072,
                                                                    "articleId": "347204",
                                                                    "description": "347204-00-Ensat-Gewindebuchse",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0049,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0049,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": []
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            "rowNumber": 3076,
                                                            "parentRowNumber": 3011,
                                                            "articleId": "47167196",
                                                            "description": "47167196-00-Fuehrung",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.6377,
                                                            "childrenWeight": 0.6377,
                                                            "totalQuantityWeight": 0.6377,
                                                            "singleQuantityMcf": 2.00928,
                                                            "mcf": 2.00928,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 2.00928,
                                                            "expandable": false,
                                                            "children": [
                                                                {
                                                                    "rowNumber": 3077,
                                                                    "parentRowNumber": 3076,
                                                                    "articleId": "162544",
                                                                    "description": "162544-01-Platte",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.6279,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.6279,
                                                                    "singleQuantityMcf": 2.00928,
                                                                    "mcf": 2.00928,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 2.00928,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3078,
                                                                    "parentRowNumber": 3076,
                                                                    "articleId": "347204",
                                                                    "description": "347204-00-Ensat-Gewindebuchse",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0049,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0049,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3079,
                                                                    "parentRowNumber": 3076,
                                                                    "articleId": "347204",
                                                                    "description": "347204-00-Ensat-Gewindebuchse",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0049,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0049,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": []
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            "rowNumber": 3080,
                                                            "parentRowNumber": 3011,
                                                            "articleId": "47237965",
                                                            "description": "47237965-00-Fuehrung",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 1.4075,
                                                            "childrenWeight": 1.4075,
                                                            "totalQuantityWeight": 1.4075,
                                                            "singleQuantityMcf": 4.504,
                                                            "mcf": 4.504,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 4.504,
                                                            "expandable": false,
                                                            "children": [
                                                                {
                                                                    "rowNumber": 3081,
                                                                    "parentRowNumber": 3080,
                                                                    "articleId": "162544",
                                                                    "description": "162544-01-Platte",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 1.4075,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 1.4075,
                                                                    "singleQuantityMcf": 4.504,
                                                                    "mcf": 4.504,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 4.504,
                                                                    "expandable": false,
                                                                    "children": []
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            "rowNumber": 3082,
                                                            "parentRowNumber": 3011,
                                                            "articleId": "47237966",
                                                            "description": "47237966-00-Fuehrung",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 1.3962,
                                                            "childrenWeight": 1.3962,
                                                            "totalQuantityWeight": 1.3962,
                                                            "singleQuantityMcf": 4.467840000000001,
                                                            "mcf": 4.467840000000001,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 4.467840000000001,
                                                            "expandable": false,
                                                            "children": [
                                                                {
                                                                    "rowNumber": 3083,
                                                                    "parentRowNumber": 3082,
                                                                    "articleId": "162544",
                                                                    "description": "162544-01-Platte",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 1.3962,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 1.3962,
                                                                    "singleQuantityMcf": 4.467840000000001,
                                                                    "mcf": 4.467840000000001,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 4.467840000000001,
                                                                    "expandable": false,
                                                                    "children": []
                                                                }
                                                            ]
                                                        }
                                                    ]
                                                }
                                            ]
                                        },
                                        {
                                            "rowNumber": 3084,
                                            "parentRowNumber": 2161,
                                            "articleId": "33847",
                                            "description": "33847-20DLF7-00-Objektfuehrung",
                                            "hierarchy": 4,
                                            "quantity": 1,
                                            "weight": 4.929499999999999,
                                            "childrenWeight": 4.929499999999999,
                                            "totalQuantityWeight": 4.929499999999999,
                                            "singleQuantityMcf": 17.058116000000002,
                                            "mcf": 17.058116000000002,
                                            "singleQuantityLcf": 0,
                                            "lcf": 0,
                                            "totalQuantityMcf": 17.058116000000002,
                                            "expandable": false,
                                            "children": [
                                                {
                                                    "rowNumber": 3085,
                                                    "parentRowNumber": 3084,
                                                    "articleId": "MM0000009694",
                                                    "description": "MM0000009694-00-Objektfuehrung",
                                                    "hierarchy": 5,
                                                    "quantity": 1,
                                                    "weight": 4.929499999999999,
                                                    "childrenWeight": 4.929499999999999,
                                                    "totalQuantityWeight": 4.929499999999999,
                                                    "singleQuantityMcf": 17.058116000000002,
                                                    "mcf": 17.058116000000002,
                                                    "singleQuantityLcf": 0,
                                                    "lcf": 0,
                                                    "totalQuantityMcf": 17.058116000000002,
                                                    "expandable": false,
                                                    "children": [
                                                        {
                                                            "rowNumber": 3086,
                                                            "parentRowNumber": 3085,
                                                            "articleId": "20120540",
                                                            "description": "20120540-00-Sechskantschraube",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0077,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0077,
                                                            "singleQuantityMcf": 0.039886,
                                                            "mcf": 0.039886,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.039886,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 3087,
                                                            "parentRowNumber": 3085,
                                                            "articleId": "20120540",
                                                            "description": "20120540-00-Sechskantschraube",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0077,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0077,
                                                            "singleQuantityMcf": 0.039886,
                                                            "mcf": 0.039886,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.039886,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 3088,
                                                            "parentRowNumber": 3085,
                                                            "articleId": "20120540",
                                                            "description": "20120540-00-Sechskantschraube",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0077,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0077,
                                                            "singleQuantityMcf": 0.039886,
                                                            "mcf": 0.039886,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.039886,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 3089,
                                                            "parentRowNumber": 3085,
                                                            "articleId": "20120540",
                                                            "description": "20120540-00-Sechskantschraube",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0077,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0077,
                                                            "singleQuantityMcf": 0.039886,
                                                            "mcf": 0.039886,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.039886,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 3090,
                                                            "parentRowNumber": 3085,
                                                            "articleId": "20120540",
                                                            "description": "20120540-00-Sechskantschraube",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0077,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0077,
                                                            "singleQuantityMcf": 0.039886,
                                                            "mcf": 0.039886,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.039886,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 3091,
                                                            "parentRowNumber": 3085,
                                                            "articleId": "20120540",
                                                            "description": "20120540-00-Sechskantschraube",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0077,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0077,
                                                            "singleQuantityMcf": 0.039886,
                                                            "mcf": 0.039886,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.039886,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 3092,
                                                            "parentRowNumber": 3085,
                                                            "articleId": "20120540",
                                                            "description": "20120540-00-Sechskantschraube",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0077,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0077,
                                                            "singleQuantityMcf": 0.039886,
                                                            "mcf": 0.039886,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.039886,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 3093,
                                                            "parentRowNumber": 3085,
                                                            "articleId": "20120540",
                                                            "description": "20120540-00-Sechskantschraube",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0077,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0077,
                                                            "singleQuantityMcf": 0.039886,
                                                            "mcf": 0.039886,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.039886,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 3094,
                                                            "parentRowNumber": 3085,
                                                            "articleId": "20120540",
                                                            "description": "20120540-00-Sechskantschraube",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0077,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0077,
                                                            "singleQuantityMcf": 0.039886,
                                                            "mcf": 0.039886,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.039886,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 3095,
                                                            "parentRowNumber": 3085,
                                                            "articleId": "20120540",
                                                            "description": "20120540-00-Sechskantschraube",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0077,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0077,
                                                            "singleQuantityMcf": 0.039886,
                                                            "mcf": 0.039886,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.039886,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 3096,
                                                            "parentRowNumber": 3085,
                                                            "articleId": "20120540",
                                                            "description": "20120540-00-Sechskantschraube",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0077,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0077,
                                                            "singleQuantityMcf": 0.039886,
                                                            "mcf": 0.039886,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.039886,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 3097,
                                                            "parentRowNumber": 3085,
                                                            "articleId": "20120540",
                                                            "description": "20120540-00-Sechskantschraube",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0077,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0077,
                                                            "singleQuantityMcf": 0.039886,
                                                            "mcf": 0.039886,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.039886,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 3098,
                                                            "parentRowNumber": 3085,
                                                            "articleId": "20120540",
                                                            "description": "20120540-00-Sechskantschraube",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0077,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0077,
                                                            "singleQuantityMcf": 0.039886,
                                                            "mcf": 0.039886,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.039886,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 3099,
                                                            "parentRowNumber": 3085,
                                                            "articleId": "20120540",
                                                            "description": "20120540-00-Sechskantschraube",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0077,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0077,
                                                            "singleQuantityMcf": 0.039886,
                                                            "mcf": 0.039886,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.039886,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 3100,
                                                            "parentRowNumber": 3085,
                                                            "articleId": "20120540",
                                                            "description": "20120540-00-Sechskantschraube",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0077,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0077,
                                                            "singleQuantityMcf": 0.039886,
                                                            "mcf": 0.039886,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.039886,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 3101,
                                                            "parentRowNumber": 3085,
                                                            "articleId": "20120540",
                                                            "description": "20120540-00-Sechskantschraube",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0077,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0077,
                                                            "singleQuantityMcf": 0.039886,
                                                            "mcf": 0.039886,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.039886,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 3102,
                                                            "parentRowNumber": 3085,
                                                            "articleId": "208405",
                                                            "description": "208405-00-Scheibe",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0015,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0015,
                                                            "singleQuantityMcf": 0.00777,
                                                            "mcf": 0.00777,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.00777,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 3103,
                                                            "parentRowNumber": 3085,
                                                            "articleId": "208405",
                                                            "description": "208405-00-Scheibe",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0015,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0015,
                                                            "singleQuantityMcf": 0.00777,
                                                            "mcf": 0.00777,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.00777,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 3104,
                                                            "parentRowNumber": 3085,
                                                            "articleId": "208405",
                                                            "description": "208405-00-Scheibe",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0015,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0015,
                                                            "singleQuantityMcf": 0.00777,
                                                            "mcf": 0.00777,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.00777,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 3105,
                                                            "parentRowNumber": 3085,
                                                            "articleId": "208405",
                                                            "description": "208405-00-Scheibe",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0015,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0015,
                                                            "singleQuantityMcf": 0.00777,
                                                            "mcf": 0.00777,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.00777,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 3106,
                                                            "parentRowNumber": 3085,
                                                            "articleId": "208405",
                                                            "description": "208405-00-Scheibe",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0015,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0015,
                                                            "singleQuantityMcf": 0.00777,
                                                            "mcf": 0.00777,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.00777,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 3107,
                                                            "parentRowNumber": 3085,
                                                            "articleId": "208405",
                                                            "description": "208405-00-Scheibe",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0015,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0015,
                                                            "singleQuantityMcf": 0.00777,
                                                            "mcf": 0.00777,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.00777,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 3108,
                                                            "parentRowNumber": 3085,
                                                            "articleId": "208405",
                                                            "description": "208405-00-Scheibe",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0015,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0015,
                                                            "singleQuantityMcf": 0.00777,
                                                            "mcf": 0.00777,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.00777,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 3109,
                                                            "parentRowNumber": 3085,
                                                            "articleId": "208405",
                                                            "description": "208405-00-Scheibe",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0015,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0015,
                                                            "singleQuantityMcf": 0.00777,
                                                            "mcf": 0.00777,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.00777,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 3110,
                                                            "parentRowNumber": 3085,
                                                            "articleId": "208405",
                                                            "description": "208405-00-Scheibe",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0015,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0015,
                                                            "singleQuantityMcf": 0.00777,
                                                            "mcf": 0.00777,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.00777,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 3111,
                                                            "parentRowNumber": 3085,
                                                            "articleId": "208405",
                                                            "description": "208405-00-Scheibe",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0015,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0015,
                                                            "singleQuantityMcf": 0.00777,
                                                            "mcf": 0.00777,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.00777,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 3112,
                                                            "parentRowNumber": 3085,
                                                            "articleId": "208405",
                                                            "description": "208405-00-Scheibe",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0015,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0015,
                                                            "singleQuantityMcf": 0.00777,
                                                            "mcf": 0.00777,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.00777,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 3113,
                                                            "parentRowNumber": 3085,
                                                            "articleId": "208405",
                                                            "description": "208405-00-Scheibe",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0015,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0015,
                                                            "singleQuantityMcf": 0.00777,
                                                            "mcf": 0.00777,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.00777,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 3114,
                                                            "parentRowNumber": 3085,
                                                            "articleId": "208405",
                                                            "description": "208405-00-Scheibe",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0015,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0015,
                                                            "singleQuantityMcf": 0.00777,
                                                            "mcf": 0.00777,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.00777,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 3115,
                                                            "parentRowNumber": 3085,
                                                            "articleId": "208405",
                                                            "description": "208405-00-Scheibe",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0015,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0015,
                                                            "singleQuantityMcf": 0.00777,
                                                            "mcf": 0.00777,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.00777,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 3116,
                                                            "parentRowNumber": 3085,
                                                            "articleId": "208405",
                                                            "description": "208405-00-Scheibe",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0015,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0015,
                                                            "singleQuantityMcf": 0.00777,
                                                            "mcf": 0.00777,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.00777,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 3117,
                                                            "parentRowNumber": 3085,
                                                            "articleId": "208405",
                                                            "description": "208405-00-Scheibe",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0015,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0015,
                                                            "singleQuantityMcf": 0.00777,
                                                            "mcf": 0.00777,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.00777,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 3118,
                                                            "parentRowNumber": 3085,
                                                            "articleId": "22070610",
                                                            "description": "22070610-00-Fluegelschraube",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0237,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0237,
                                                            "singleQuantityMcf": 0.041001,
                                                            "mcf": 0.041001,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.041001,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 3119,
                                                            "parentRowNumber": 3085,
                                                            "articleId": "22070610",
                                                            "description": "22070610-00-Fluegelschraube",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0237,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0237,
                                                            "singleQuantityMcf": 0.041001,
                                                            "mcf": 0.041001,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.041001,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 3120,
                                                            "parentRowNumber": 3085,
                                                            "articleId": "22070610",
                                                            "description": "22070610-00-Fluegelschraube",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0237,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0237,
                                                            "singleQuantityMcf": 0.041001,
                                                            "mcf": 0.041001,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.041001,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 3121,
                                                            "parentRowNumber": 3085,
                                                            "articleId": "22070610",
                                                            "description": "22070610-00-Fluegelschraube",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0237,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0237,
                                                            "singleQuantityMcf": 0.041001,
                                                            "mcf": 0.041001,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.041001,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 3122,
                                                            "parentRowNumber": 3085,
                                                            "articleId": "46645931",
                                                            "description": "46645931-00-Formatbuegel 2.0",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.1508,
                                                            "childrenWeight": 0.1508,
                                                            "totalQuantityWeight": 0.1508,
                                                            "singleQuantityMcf": 0.781144,
                                                            "mcf": 0.781144,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.781144,
                                                            "expandable": false,
                                                            "children": [
                                                                {
                                                                    "rowNumber": 3123,
                                                                    "parentRowNumber": 3122,
                                                                    "articleId": "142612",
                                                                    "description": "142612-00-Edelstahlblech",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.092,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.092,
                                                                    "singleQuantityMcf": 0.47656,
                                                                    "mcf": 0.47656,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.47656,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3124,
                                                                    "parentRowNumber": 3122,
                                                                    "articleId": "46647477",
                                                                    "description": "46647477-00-Halter",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0294,
                                                                    "childrenWeight": 0.0294,
                                                                    "totalQuantityWeight": 0.0294,
                                                                    "singleQuantityMcf": 0.15229199999999998,
                                                                    "mcf": 0.15229199999999998,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.15229199999999998,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 3125,
                                                                            "parentRowNumber": 3124,
                                                                            "articleId": "1110231",
                                                                            "description": "1110231-00-Flachstahl",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0294,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0294,
                                                                            "singleQuantityMcf": 0.15229199999999998,
                                                                            "mcf": 0.15229199999999998,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.15229199999999998,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 3126,
                                                                    "parentRowNumber": 3122,
                                                                    "articleId": "46647477",
                                                                    "description": "46647477-00-Halter",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0294,
                                                                    "childrenWeight": 0.0294,
                                                                    "totalQuantityWeight": 0.0294,
                                                                    "singleQuantityMcf": 0.15229199999999998,
                                                                    "mcf": 0.15229199999999998,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.15229199999999998,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 3127,
                                                                            "parentRowNumber": 3126,
                                                                            "articleId": "1110231",
                                                                            "description": "1110231-00-Flachstahl",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0294,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0294,
                                                                            "singleQuantityMcf": 0.15229199999999998,
                                                                            "mcf": 0.15229199999999998,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.15229199999999998,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            "rowNumber": 3128,
                                                            "parentRowNumber": 3085,
                                                            "articleId": "46645931",
                                                            "description": "46645931-00-Formatbuegel 2.0",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.1508,
                                                            "childrenWeight": 0.1508,
                                                            "totalQuantityWeight": 0.1508,
                                                            "singleQuantityMcf": 0.781144,
                                                            "mcf": 0.781144,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.781144,
                                                            "expandable": false,
                                                            "children": [
                                                                {
                                                                    "rowNumber": 3129,
                                                                    "parentRowNumber": 3128,
                                                                    "articleId": "142612",
                                                                    "description": "142612-00-Edelstahlblech",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.092,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.092,
                                                                    "singleQuantityMcf": 0.47656,
                                                                    "mcf": 0.47656,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.47656,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3130,
                                                                    "parentRowNumber": 3128,
                                                                    "articleId": "46647477",
                                                                    "description": "46647477-00-Halter",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0294,
                                                                    "childrenWeight": 0.0294,
                                                                    "totalQuantityWeight": 0.0294,
                                                                    "singleQuantityMcf": 0.15229199999999998,
                                                                    "mcf": 0.15229199999999998,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.15229199999999998,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 3131,
                                                                            "parentRowNumber": 3130,
                                                                            "articleId": "1110231",
                                                                            "description": "1110231-00-Flachstahl",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0294,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0294,
                                                                            "singleQuantityMcf": 0.15229199999999998,
                                                                            "mcf": 0.15229199999999998,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.15229199999999998,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 3132,
                                                                    "parentRowNumber": 3128,
                                                                    "articleId": "46647477",
                                                                    "description": "46647477-00-Halter",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0294,
                                                                    "childrenWeight": 0.0294,
                                                                    "totalQuantityWeight": 0.0294,
                                                                    "singleQuantityMcf": 0.15229199999999998,
                                                                    "mcf": 0.15229199999999998,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.15229199999999998,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 3133,
                                                                            "parentRowNumber": 3132,
                                                                            "articleId": "1110231",
                                                                            "description": "1110231-00-Flachstahl",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0294,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0294,
                                                                            "singleQuantityMcf": 0.15229199999999998,
                                                                            "mcf": 0.15229199999999998,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.15229199999999998,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            "rowNumber": 3134,
                                                            "parentRowNumber": 3085,
                                                            "articleId": "46645931",
                                                            "description": "46645931-00-Formatbuegel 2.0",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.1508,
                                                            "childrenWeight": 0.1508,
                                                            "totalQuantityWeight": 0.1508,
                                                            "singleQuantityMcf": 0.781144,
                                                            "mcf": 0.781144,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.781144,
                                                            "expandable": false,
                                                            "children": [
                                                                {
                                                                    "rowNumber": 3135,
                                                                    "parentRowNumber": 3134,
                                                                    "articleId": "142612",
                                                                    "description": "142612-00-Edelstahlblech",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.092,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.092,
                                                                    "singleQuantityMcf": 0.47656,
                                                                    "mcf": 0.47656,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.47656,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3136,
                                                                    "parentRowNumber": 3134,
                                                                    "articleId": "46647477",
                                                                    "description": "46647477-00-Halter",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0294,
                                                                    "childrenWeight": 0.0294,
                                                                    "totalQuantityWeight": 0.0294,
                                                                    "singleQuantityMcf": 0.15229199999999998,
                                                                    "mcf": 0.15229199999999998,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.15229199999999998,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 3137,
                                                                            "parentRowNumber": 3136,
                                                                            "articleId": "1110231",
                                                                            "description": "1110231-00-Flachstahl",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0294,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0294,
                                                                            "singleQuantityMcf": 0.15229199999999998,
                                                                            "mcf": 0.15229199999999998,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.15229199999999998,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 3138,
                                                                    "parentRowNumber": 3134,
                                                                    "articleId": "46647477",
                                                                    "description": "46647477-00-Halter",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0294,
                                                                    "childrenWeight": 0.0294,
                                                                    "totalQuantityWeight": 0.0294,
                                                                    "singleQuantityMcf": 0.15229199999999998,
                                                                    "mcf": 0.15229199999999998,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.15229199999999998,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 3139,
                                                                            "parentRowNumber": 3138,
                                                                            "articleId": "1110231",
                                                                            "description": "1110231-00-Flachstahl",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0294,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0294,
                                                                            "singleQuantityMcf": 0.15229199999999998,
                                                                            "mcf": 0.15229199999999998,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.15229199999999998,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            "rowNumber": 3140,
                                                            "parentRowNumber": 3085,
                                                            "articleId": "46645931",
                                                            "description": "46645931-00-Formatbuegel 2.0",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.1508,
                                                            "childrenWeight": 0.1508,
                                                            "totalQuantityWeight": 0.1508,
                                                            "singleQuantityMcf": 0.781144,
                                                            "mcf": 0.781144,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.781144,
                                                            "expandable": false,
                                                            "children": [
                                                                {
                                                                    "rowNumber": 3141,
                                                                    "parentRowNumber": 3140,
                                                                    "articleId": "142612",
                                                                    "description": "142612-00-Edelstahlblech",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.092,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.092,
                                                                    "singleQuantityMcf": 0.47656,
                                                                    "mcf": 0.47656,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.47656,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3142,
                                                                    "parentRowNumber": 3140,
                                                                    "articleId": "46647477",
                                                                    "description": "46647477-00-Halter",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0294,
                                                                    "childrenWeight": 0.0294,
                                                                    "totalQuantityWeight": 0.0294,
                                                                    "singleQuantityMcf": 0.15229199999999998,
                                                                    "mcf": 0.15229199999999998,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.15229199999999998,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 3143,
                                                                            "parentRowNumber": 3142,
                                                                            "articleId": "1110231",
                                                                            "description": "1110231-00-Flachstahl",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0294,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0294,
                                                                            "singleQuantityMcf": 0.15229199999999998,
                                                                            "mcf": 0.15229199999999998,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.15229199999999998,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 3144,
                                                                    "parentRowNumber": 3140,
                                                                    "articleId": "46647477",
                                                                    "description": "46647477-00-Halter",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0294,
                                                                    "childrenWeight": 0.0294,
                                                                    "totalQuantityWeight": 0.0294,
                                                                    "singleQuantityMcf": 0.15229199999999998,
                                                                    "mcf": 0.15229199999999998,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.15229199999999998,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 3145,
                                                                            "parentRowNumber": 3144,
                                                                            "articleId": "1110231",
                                                                            "description": "1110231-00-Flachstahl",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0294,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0294,
                                                                            "singleQuantityMcf": 0.15229199999999998,
                                                                            "mcf": 0.15229199999999998,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.15229199999999998,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            "rowNumber": 3146,
                                                            "parentRowNumber": 3085,
                                                            "articleId": "47157197",
                                                            "description": "47157197-00-Fuehrung",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.6429,
                                                            "childrenWeight": 0.6429,
                                                            "totalQuantityWeight": 0.6429,
                                                            "singleQuantityMcf": 2.02592,
                                                            "mcf": 2.02592,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 2.02592,
                                                            "expandable": false,
                                                            "children": [
                                                                {
                                                                    "rowNumber": 3147,
                                                                    "parentRowNumber": 3146,
                                                                    "articleId": "162544",
                                                                    "description": "162544-01-Platte",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.6331,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.6331,
                                                                    "singleQuantityMcf": 2.02592,
                                                                    "mcf": 2.02592,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 2.02592,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3148,
                                                                    "parentRowNumber": 3146,
                                                                    "articleId": "347204",
                                                                    "description": "347204-00-Ensat-Gewindebuchse",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0049,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0049,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3149,
                                                                    "parentRowNumber": 3146,
                                                                    "articleId": "347204",
                                                                    "description": "347204-00-Ensat-Gewindebuchse",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0049,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0049,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": []
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            "rowNumber": 3150,
                                                            "parentRowNumber": 3085,
                                                            "articleId": "47167196",
                                                            "description": "47167196-00-Fuehrung",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.6377,
                                                            "childrenWeight": 0.6377,
                                                            "totalQuantityWeight": 0.6377,
                                                            "singleQuantityMcf": 2.00928,
                                                            "mcf": 2.00928,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 2.00928,
                                                            "expandable": false,
                                                            "children": [
                                                                {
                                                                    "rowNumber": 3151,
                                                                    "parentRowNumber": 3150,
                                                                    "articleId": "162544",
                                                                    "description": "162544-01-Platte",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.6279,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.6279,
                                                                    "singleQuantityMcf": 2.00928,
                                                                    "mcf": 2.00928,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 2.00928,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3152,
                                                                    "parentRowNumber": 3150,
                                                                    "articleId": "347204",
                                                                    "description": "347204-00-Ensat-Gewindebuchse",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0049,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0049,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3153,
                                                                    "parentRowNumber": 3150,
                                                                    "articleId": "347204",
                                                                    "description": "347204-00-Ensat-Gewindebuchse",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0049,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0049,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": []
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            "rowNumber": 3154,
                                                            "parentRowNumber": 3085,
                                                            "articleId": "47237967",
                                                            "description": "47237967-00-Fuehrung",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 1.4075,
                                                            "childrenWeight": 1.4075,
                                                            "totalQuantityWeight": 1.4075,
                                                            "singleQuantityMcf": 4.504,
                                                            "mcf": 4.504,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 4.504,
                                                            "expandable": false,
                                                            "children": [
                                                                {
                                                                    "rowNumber": 3155,
                                                                    "parentRowNumber": 3154,
                                                                    "articleId": "162544",
                                                                    "description": "162544-01-Platte",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 1.4075,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 1.4075,
                                                                    "singleQuantityMcf": 4.504,
                                                                    "mcf": 4.504,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 4.504,
                                                                    "expandable": false,
                                                                    "children": []
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            "rowNumber": 3156,
                                                            "parentRowNumber": 3085,
                                                            "articleId": "47237969",
                                                            "description": "47237969-00-Fuehrung",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 1.3962,
                                                            "childrenWeight": 1.3962,
                                                            "totalQuantityWeight": 1.3962,
                                                            "singleQuantityMcf": 4.467840000000001,
                                                            "mcf": 4.467840000000001,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 4.467840000000001,
                                                            "expandable": false,
                                                            "children": [
                                                                {
                                                                    "rowNumber": 3157,
                                                                    "parentRowNumber": 3156,
                                                                    "articleId": "162544",
                                                                    "description": "162544-01-Platte",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 1.3962,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 1.3962,
                                                                    "singleQuantityMcf": 4.467840000000001,
                                                                    "mcf": 4.467840000000001,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 4.467840000000001,
                                                                    "expandable": false,
                                                                    "children": []
                                                                }
                                                            ]
                                                        }
                                                    ]
                                                }
                                            ]
                                        },
                                        {
                                            "rowNumber": 3158,
                                            "parentRowNumber": 2161,
                                            "articleId": "P",
                                            "description": "P-ESA1025-20-00-Einlauf",
                                            "hierarchy": 4,
                                            "quantity": 1,
                                            "weight": 0,
                                            "childrenWeight": 0,
                                            "totalQuantityWeight": 0,
                                            "singleQuantityMcf": 0,
                                            "mcf": 0,
                                            "singleQuantityLcf": 0,
                                            "lcf": 0,
                                            "totalQuantityMcf": 0,
                                            "expandable": false,
                                            "children": [
                                                {
                                                    "rowNumber": 3159,
                                                    "parentRowNumber": 3158,
                                                    "articleId": "P",
                                                    "description": "P-ESA1025-20-0001-00-Einlauf",
                                                    "hierarchy": 5,
                                                    "quantity": 1,
                                                    "weight": 0,
                                                    "childrenWeight": 0,
                                                    "totalQuantityWeight": 0,
                                                    "singleQuantityMcf": 0,
                                                    "mcf": 0,
                                                    "singleQuantityLcf": 0,
                                                    "lcf": 0,
                                                    "totalQuantityMcf": 0,
                                                    "expandable": false,
                                                    "children": []
                                                }
                                            ]
                                        }
                                    ]
                                },
                                {
                                    "rowNumber": 3160,
                                    "parentRowNumber": 2020,
                                    "articleId": "33847",
                                    "description": "33847-20NA-00-Pufferdrehteller",
                                    "hierarchy": 3,
                                    "quantity": 1,
                                    "weight": 222.61669999999984,
                                    "childrenWeight": 222.61669999999984,
                                    "totalQuantityWeight": 222.61669999999984,
                                    "singleQuantityMcf": 294.7297987999997,
                                    "mcf": 294.7297987999997,
                                    "singleQuantityLcf": 0,
                                    "lcf": 0,
                                    "totalQuantityMcf": 294.7297987999997,
                                    "expandable": false,
                                    "children": [
                                        {
                                            "rowNumber": 3161,
                                            "parentRowNumber": 3160,
                                            "articleId": "33847",
                                            "description": "33847-20NA04-00-Füße",
                                            "hierarchy": 4,
                                            "quantity": 1,
                                            "weight": 28.9187,
                                            "childrenWeight": 28.9187,
                                            "totalQuantityWeight": 28.9187,
                                            "singleQuantityMcf": 87.74357600000002,
                                            "mcf": 87.74357600000002,
                                            "singleQuantityLcf": 0,
                                            "lcf": 0,
                                            "totalQuantityMcf": 87.74357600000002,
                                            "expandable": false,
                                            "children": [
                                                {
                                                    "rowNumber": 3162,
                                                    "parentRowNumber": 3161,
                                                    "articleId": "MM0000009382",
                                                    "description": "MM0000009382-00-Füße",
                                                    "hierarchy": 5,
                                                    "quantity": 1,
                                                    "weight": 28.9187,
                                                    "childrenWeight": 28.9187,
                                                    "totalQuantityWeight": 28.9187,
                                                    "singleQuantityMcf": 87.74357600000002,
                                                    "mcf": 87.74357600000002,
                                                    "singleQuantityLcf": 0,
                                                    "lcf": 0,
                                                    "totalQuantityMcf": 87.74357600000002,
                                                    "expandable": false,
                                                    "children": [
                                                        {
                                                            "rowNumber": 3163,
                                                            "parentRowNumber": 3162,
                                                            "articleId": "20120815",
                                                            "description": "20120815-00-Sechskantschraube",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0125,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0125,
                                                            "singleQuantityMcf": 0.06475,
                                                            "mcf": 0.06475,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.06475,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 3164,
                                                            "parentRowNumber": 3162,
                                                            "articleId": "20120815",
                                                            "description": "20120815-00-Sechskantschraube",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0125,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0125,
                                                            "singleQuantityMcf": 0.06475,
                                                            "mcf": 0.06475,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.06475,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 3165,
                                                            "parentRowNumber": 3162,
                                                            "articleId": "20120815",
                                                            "description": "20120815-00-Sechskantschraube",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0125,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0125,
                                                            "singleQuantityMcf": 0.06475,
                                                            "mcf": 0.06475,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.06475,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 3166,
                                                            "parentRowNumber": 3162,
                                                            "articleId": "20121235",
                                                            "description": "20121235-00-Sechskantschraube",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0504,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0504,
                                                            "singleQuantityMcf": 0.261072,
                                                            "mcf": 0.261072,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.261072,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 3167,
                                                            "parentRowNumber": 3162,
                                                            "articleId": "20121235",
                                                            "description": "20121235-00-Sechskantschraube",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0504,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0504,
                                                            "singleQuantityMcf": 0.261072,
                                                            "mcf": 0.261072,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.261072,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 3168,
                                                            "parentRowNumber": 3162,
                                                            "articleId": "20121235",
                                                            "description": "20121235-00-Sechskantschraube",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0504,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0504,
                                                            "singleQuantityMcf": 0.261072,
                                                            "mcf": 0.261072,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.261072,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 3169,
                                                            "parentRowNumber": 3162,
                                                            "articleId": "20121235",
                                                            "description": "20121235-00-Sechskantschraube",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0504,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0504,
                                                            "singleQuantityMcf": 0.261072,
                                                            "mcf": 0.261072,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.261072,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 3170,
                                                            "parentRowNumber": 3162,
                                                            "articleId": "20220825",
                                                            "description": "20220825-00-Zylinderschraube",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.014,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.014,
                                                            "singleQuantityMcf": 0.07252,
                                                            "mcf": 0.07252,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.07252,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 3171,
                                                            "parentRowNumber": 3162,
                                                            "articleId": "20220825",
                                                            "description": "20220825-00-Zylinderschraube",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.014,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.014,
                                                            "singleQuantityMcf": 0.07252,
                                                            "mcf": 0.07252,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.07252,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 3172,
                                                            "parentRowNumber": 3162,
                                                            "articleId": "20220825",
                                                            "description": "20220825-00-Zylinderschraube",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.014,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.014,
                                                            "singleQuantityMcf": 0.07252,
                                                            "mcf": 0.07252,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.07252,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 3173,
                                                            "parentRowNumber": 3162,
                                                            "articleId": "208212",
                                                            "description": "208212-00-Scheibe",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0059,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0059,
                                                            "singleQuantityMcf": 0.030562,
                                                            "mcf": 0.030562,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.030562,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 3174,
                                                            "parentRowNumber": 3162,
                                                            "articleId": "208212",
                                                            "description": "208212-00-Scheibe",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0059,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0059,
                                                            "singleQuantityMcf": 0.030562,
                                                            "mcf": 0.030562,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.030562,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 3175,
                                                            "parentRowNumber": 3162,
                                                            "articleId": "208212",
                                                            "description": "208212-00-Scheibe",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0059,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0059,
                                                            "singleQuantityMcf": 0.030562,
                                                            "mcf": 0.030562,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.030562,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 3176,
                                                            "parentRowNumber": 3162,
                                                            "articleId": "208212",
                                                            "description": "208212-00-Scheibe",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0059,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0059,
                                                            "singleQuantityMcf": 0.030562,
                                                            "mcf": 0.030562,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.030562,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 3177,
                                                            "parentRowNumber": 3162,
                                                            "articleId": "442023",
                                                            "description": "442023-00-Scheibe",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0071,
                                                            "childrenWeight": 0.0071,
                                                            "totalQuantityWeight": 0.0071,
                                                            "singleQuantityMcf": 0.036778,
                                                            "mcf": 0.036778,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.036778,
                                                            "expandable": false,
                                                            "children": [
                                                                {
                                                                    "rowNumber": 3178,
                                                                    "parentRowNumber": 3177,
                                                                    "articleId": "1260025",
                                                                    "description": "1260025-00-Rundstahl",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0071,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0071,
                                                                    "singleQuantityMcf": 0.036778,
                                                                    "mcf": 0.036778,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.036778,
                                                                    "expandable": false,
                                                                    "children": []
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            "rowNumber": 3179,
                                                            "parentRowNumber": 3162,
                                                            "articleId": "442023",
                                                            "description": "442023-00-Scheibe",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0071,
                                                            "childrenWeight": 0.0071,
                                                            "totalQuantityWeight": 0.0071,
                                                            "singleQuantityMcf": 0.036778,
                                                            "mcf": 0.036778,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.036778,
                                                            "expandable": false,
                                                            "children": [
                                                                {
                                                                    "rowNumber": 3180,
                                                                    "parentRowNumber": 3179,
                                                                    "articleId": "1260025",
                                                                    "description": "1260025-00-Rundstahl",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0071,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0071,
                                                                    "singleQuantityMcf": 0.036778,
                                                                    "mcf": 0.036778,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.036778,
                                                                    "expandable": false,
                                                                    "children": []
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            "rowNumber": 3181,
                                                            "parentRowNumber": 3162,
                                                            "articleId": "442023",
                                                            "description": "442023-00-Scheibe",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0071,
                                                            "childrenWeight": 0.0071,
                                                            "totalQuantityWeight": 0.0071,
                                                            "singleQuantityMcf": 0.036778,
                                                            "mcf": 0.036778,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.036778,
                                                            "expandable": false,
                                                            "children": [
                                                                {
                                                                    "rowNumber": 3182,
                                                                    "parentRowNumber": 3181,
                                                                    "articleId": "1260025",
                                                                    "description": "1260025-00-Rundstahl",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0071,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0071,
                                                                    "singleQuantityMcf": 0.036778,
                                                                    "mcf": 0.036778,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.036778,
                                                                    "expandable": false,
                                                                    "children": []
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            "rowNumber": 3183,
                                                            "parentRowNumber": 3162,
                                                            "articleId": "46275354",
                                                            "description": "46275354-01-Rohr",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 8.3425,
                                                            "childrenWeight": 8.3425,
                                                            "totalQuantityWeight": 8.3425,
                                                            "singleQuantityMcf": 0,
                                                            "mcf": 0,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0,
                                                            "expandable": false,
                                                            "children": [
                                                                {
                                                                    "rowNumber": 3184,
                                                                    "parentRowNumber": 3183,
                                                                    "articleId": "125441",
                                                                    "description": "125441-00-Rohr",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 8.3425,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 8.3425,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": []
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            "rowNumber": 3185,
                                                            "parentRowNumber": 3162,
                                                            "articleId": "465000010",
                                                            "description": "465000010-02-Staenderfuss",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 13.3034,
                                                            "childrenWeight": 13.3034,
                                                            "totalQuantityWeight": 13.3034,
                                                            "singleQuantityMcf": 68.911612,
                                                            "mcf": 68.911612,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 68.911612,
                                                            "expandable": false,
                                                            "children": [
                                                                {
                                                                    "rowNumber": 3186,
                                                                    "parentRowNumber": 3185,
                                                                    "articleId": "448489",
                                                                    "description": "448489-03-Gewindebuchse",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 1.1503,
                                                                    "childrenWeight": 1.1503,
                                                                    "totalQuantityWeight": 1.1503,
                                                                    "singleQuantityMcf": 5.958554,
                                                                    "mcf": 5.958554,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 5.958554,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 3187,
                                                                            "parentRowNumber": 3186,
                                                                            "articleId": "1260045",
                                                                            "description": "1260045-00-Rundstahl",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 1.1503,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 1.1503,
                                                                            "singleQuantityMcf": 5.958554,
                                                                            "mcf": 5.958554,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 5.958554,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 3188,
                                                                    "parentRowNumber": 3185,
                                                                    "articleId": "448489",
                                                                    "description": "448489-03-Gewindebuchse",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 1.1503,
                                                                    "childrenWeight": 1.1503,
                                                                    "totalQuantityWeight": 1.1503,
                                                                    "singleQuantityMcf": 5.958554,
                                                                    "mcf": 5.958554,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 5.958554,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 3189,
                                                                            "parentRowNumber": 3188,
                                                                            "articleId": "1260045",
                                                                            "description": "1260045-00-Rundstahl",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 1.1503,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 1.1503,
                                                                            "singleQuantityMcf": 5.958554,
                                                                            "mcf": 5.958554,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 5.958554,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 3190,
                                                                    "parentRowNumber": 3185,
                                                                    "articleId": "448489",
                                                                    "description": "448489-03-Gewindebuchse",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 1.1503,
                                                                    "childrenWeight": 1.1503,
                                                                    "totalQuantityWeight": 1.1503,
                                                                    "singleQuantityMcf": 5.958554,
                                                                    "mcf": 5.958554,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 5.958554,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 3191,
                                                                            "parentRowNumber": 3190,
                                                                            "articleId": "1260045",
                                                                            "description": "1260045-00-Rundstahl",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 1.1503,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 1.1503,
                                                                            "singleQuantityMcf": 5.958554,
                                                                            "mcf": 5.958554,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 5.958554,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 3192,
                                                                    "parentRowNumber": 3185,
                                                                    "articleId": "448489",
                                                                    "description": "448489-03-Gewindebuchse",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 1.1503,
                                                                    "childrenWeight": 1.1503,
                                                                    "totalQuantityWeight": 1.1503,
                                                                    "singleQuantityMcf": 5.958554,
                                                                    "mcf": 5.958554,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 5.958554,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 3193,
                                                                            "parentRowNumber": 3192,
                                                                            "articleId": "1260045",
                                                                            "description": "1260045-00-Rundstahl",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 1.1503,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 1.1503,
                                                                            "singleQuantityMcf": 5.958554,
                                                                            "mcf": 5.958554,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 5.958554,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 3194,
                                                                    "parentRowNumber": 3185,
                                                                    "articleId": "448497",
                                                                    "description": "448497-02-Einpresshuelse",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 3.301,
                                                                    "childrenWeight": 3.301,
                                                                    "totalQuantityWeight": 3.301,
                                                                    "singleQuantityMcf": 17.09918,
                                                                    "mcf": 17.09918,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 17.09918,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 3195,
                                                                            "parentRowNumber": 3194,
                                                                            "articleId": "1255392",
                                                                            "description": "1255392-00-Rohr",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 3.301,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 3.301,
                                                                            "singleQuantityMcf": 17.09918,
                                                                            "mcf": 17.09918,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 17.09918,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 3196,
                                                                    "parentRowNumber": 3185,
                                                                    "articleId": "465001",
                                                                    "description": "465001-01-Ausleger",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 1.3503,
                                                                    "childrenWeight": 1.3503,
                                                                    "totalQuantityWeight": 1.3503,
                                                                    "singleQuantityMcf": 6.994554,
                                                                    "mcf": 6.994554,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 6.994554,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 3197,
                                                                            "parentRowNumber": 3196,
                                                                            "articleId": "142608",
                                                                            "description": "142608-00-Edelstahlblech",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 1.3503,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 1.3503,
                                                                            "singleQuantityMcf": 6.994554,
                                                                            "mcf": 6.994554,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 6.994554,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 3198,
                                                                    "parentRowNumber": 3185,
                                                                    "articleId": "465001",
                                                                    "description": "465001-01-Ausleger",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 1.3503,
                                                                    "childrenWeight": 1.3503,
                                                                    "totalQuantityWeight": 1.3503,
                                                                    "singleQuantityMcf": 6.994554,
                                                                    "mcf": 6.994554,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 6.994554,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 3199,
                                                                            "parentRowNumber": 3198,
                                                                            "articleId": "142608",
                                                                            "description": "142608-00-Edelstahlblech",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 1.3503,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 1.3503,
                                                                            "singleQuantityMcf": 6.994554,
                                                                            "mcf": 6.994554,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 6.994554,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 3200,
                                                                    "parentRowNumber": 3185,
                                                                    "articleId": "465001",
                                                                    "description": "465001-01-Ausleger",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 1.3503,
                                                                    "childrenWeight": 1.3503,
                                                                    "totalQuantityWeight": 1.3503,
                                                                    "singleQuantityMcf": 6.994554,
                                                                    "mcf": 6.994554,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 6.994554,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 3201,
                                                                            "parentRowNumber": 3200,
                                                                            "articleId": "142608",
                                                                            "description": "142608-00-Edelstahlblech",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 1.3503,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 1.3503,
                                                                            "singleQuantityMcf": 6.994554,
                                                                            "mcf": 6.994554,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 6.994554,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 3202,
                                                                    "parentRowNumber": 3185,
                                                                    "articleId": "465001",
                                                                    "description": "465001-01-Ausleger",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 1.3503,
                                                                    "childrenWeight": 1.3503,
                                                                    "totalQuantityWeight": 1.3503,
                                                                    "singleQuantityMcf": 6.994554,
                                                                    "mcf": 6.994554,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 6.994554,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 3203,
                                                                            "parentRowNumber": 3202,
                                                                            "articleId": "142608",
                                                                            "description": "142608-00-Edelstahlblech",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 1.3503,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 1.3503,
                                                                            "singleQuantityMcf": 6.994554,
                                                                            "mcf": 6.994554,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 6.994554,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            "rowNumber": 3204,
                                                            "parentRowNumber": 3162,
                                                            "articleId": "47120405",
                                                            "description": "47120405-00-Fuss",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 6.9468,
                                                            "childrenWeight": 6.9468,
                                                            "totalQuantityWeight": 6.9468,
                                                            "singleQuantityMcf": 17.143284,
                                                            "mcf": 17.143284,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 17.143284,
                                                            "expandable": false,
                                                            "children": [
                                                                {
                                                                    "rowNumber": 3205,
                                                                    "parentRowNumber": 3204,
                                                                    "articleId": "47120346",
                                                                    "description": "47120346-00-Rolle",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 1.7367,
                                                                    "childrenWeight": 1.7367,
                                                                    "totalQuantityWeight": 1.7367,
                                                                    "singleQuantityMcf": 4.285821,
                                                                    "mcf": 4.285821,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 4.285821,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 3206,
                                                                            "parentRowNumber": 3205,
                                                                            "articleId": "298127",
                                                                            "description": "298127-00-Lenkrolle mit Bremse",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 1.3653,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 1.3653,
                                                                            "singleQuantityMcf": 2.361969,
                                                                            "mcf": 2.361969,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 2.361969,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        },
                                                                        {
                                                                            "rowNumber": 3207,
                                                                            "parentRowNumber": 3205,
                                                                            "articleId": "441003",
                                                                            "description": "441003-00-Mutter",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0289,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0289,
                                                                            "singleQuantityMcf": 0.14970199999999995,
                                                                            "mcf": 0.14970199999999995,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.14970199999999995,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        },
                                                                        {
                                                                            "rowNumber": 3208,
                                                                            "parentRowNumber": 3205,
                                                                            "articleId": "46070852",
                                                                            "description": "46070852-06-Schraube",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.3425,
                                                                            "childrenWeight": 0.3425,
                                                                            "totalQuantityWeight": 0.3425,
                                                                            "singleQuantityMcf": 1.77415,
                                                                            "mcf": 1.77415,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 1.77415,
                                                                            "expandable": false,
                                                                            "children": [
                                                                                {
                                                                                    "rowNumber": 3209,
                                                                                    "parentRowNumber": 3208,
                                                                                    "articleId": "1260050",
                                                                                    "description": "1260050-00-Rundstahl",
                                                                                    "hierarchy": 9,
                                                                                    "quantity": 1,
                                                                                    "weight": 0.3425,
                                                                                    "childrenWeight": 0,
                                                                                    "totalQuantityWeight": 0.3425,
                                                                                    "singleQuantityMcf": 1.77415,
                                                                                    "mcf": 1.77415,
                                                                                    "singleQuantityLcf": 0,
                                                                                    "lcf": 0,
                                                                                    "totalQuantityMcf": 1.77415,
                                                                                    "expandable": false,
                                                                                    "children": []
                                                                                }
                                                                            ]
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 3210,
                                                                    "parentRowNumber": 3204,
                                                                    "articleId": "47120346",
                                                                    "description": "47120346-00-Rolle",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 1.7367,
                                                                    "childrenWeight": 1.7367,
                                                                    "totalQuantityWeight": 1.7367,
                                                                    "singleQuantityMcf": 4.285821,
                                                                    "mcf": 4.285821,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 4.285821,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 3211,
                                                                            "parentRowNumber": 3210,
                                                                            "articleId": "298127",
                                                                            "description": "298127-00-Lenkrolle mit Bremse",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 1.3653,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 1.3653,
                                                                            "singleQuantityMcf": 2.361969,
                                                                            "mcf": 2.361969,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 2.361969,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        },
                                                                        {
                                                                            "rowNumber": 3212,
                                                                            "parentRowNumber": 3210,
                                                                            "articleId": "441003",
                                                                            "description": "441003-00-Mutter",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0289,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0289,
                                                                            "singleQuantityMcf": 0.14970199999999995,
                                                                            "mcf": 0.14970199999999995,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.14970199999999995,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        },
                                                                        {
                                                                            "rowNumber": 3213,
                                                                            "parentRowNumber": 3210,
                                                                            "articleId": "46070852",
                                                                            "description": "46070852-06-Schraube",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.3425,
                                                                            "childrenWeight": 0.3425,
                                                                            "totalQuantityWeight": 0.3425,
                                                                            "singleQuantityMcf": 1.77415,
                                                                            "mcf": 1.77415,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 1.77415,
                                                                            "expandable": false,
                                                                            "children": [
                                                                                {
                                                                                    "rowNumber": 3214,
                                                                                    "parentRowNumber": 3213,
                                                                                    "articleId": "1260050",
                                                                                    "description": "1260050-00-Rundstahl",
                                                                                    "hierarchy": 9,
                                                                                    "quantity": 1,
                                                                                    "weight": 0.3425,
                                                                                    "childrenWeight": 0,
                                                                                    "totalQuantityWeight": 0.3425,
                                                                                    "singleQuantityMcf": 1.77415,
                                                                                    "mcf": 1.77415,
                                                                                    "singleQuantityLcf": 0,
                                                                                    "lcf": 0,
                                                                                    "totalQuantityMcf": 1.77415,
                                                                                    "expandable": false,
                                                                                    "children": []
                                                                                }
                                                                            ]
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 3215,
                                                                    "parentRowNumber": 3204,
                                                                    "articleId": "47120346",
                                                                    "description": "47120346-00-Rolle",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 1.7367,
                                                                    "childrenWeight": 1.7367,
                                                                    "totalQuantityWeight": 1.7367,
                                                                    "singleQuantityMcf": 4.285821,
                                                                    "mcf": 4.285821,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 4.285821,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 3216,
                                                                            "parentRowNumber": 3215,
                                                                            "articleId": "298127",
                                                                            "description": "298127-00-Lenkrolle mit Bremse",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 1.3653,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 1.3653,
                                                                            "singleQuantityMcf": 2.361969,
                                                                            "mcf": 2.361969,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 2.361969,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        },
                                                                        {
                                                                            "rowNumber": 3217,
                                                                            "parentRowNumber": 3215,
                                                                            "articleId": "441003",
                                                                            "description": "441003-00-Mutter",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0289,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0289,
                                                                            "singleQuantityMcf": 0.14970199999999995,
                                                                            "mcf": 0.14970199999999995,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.14970199999999995,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        },
                                                                        {
                                                                            "rowNumber": 3218,
                                                                            "parentRowNumber": 3215,
                                                                            "articleId": "46070852",
                                                                            "description": "46070852-06-Schraube",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.3425,
                                                                            "childrenWeight": 0.3425,
                                                                            "totalQuantityWeight": 0.3425,
                                                                            "singleQuantityMcf": 1.77415,
                                                                            "mcf": 1.77415,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 1.77415,
                                                                            "expandable": false,
                                                                            "children": [
                                                                                {
                                                                                    "rowNumber": 3219,
                                                                                    "parentRowNumber": 3218,
                                                                                    "articleId": "1260050",
                                                                                    "description": "1260050-00-Rundstahl",
                                                                                    "hierarchy": 9,
                                                                                    "quantity": 1,
                                                                                    "weight": 0.3425,
                                                                                    "childrenWeight": 0,
                                                                                    "totalQuantityWeight": 0.3425,
                                                                                    "singleQuantityMcf": 1.77415,
                                                                                    "mcf": 1.77415,
                                                                                    "singleQuantityLcf": 0,
                                                                                    "lcf": 0,
                                                                                    "totalQuantityMcf": 1.77415,
                                                                                    "expandable": false,
                                                                                    "children": []
                                                                                }
                                                                            ]
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 3220,
                                                                    "parentRowNumber": 3204,
                                                                    "articleId": "47120346",
                                                                    "description": "47120346-00-Rolle",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 1.7367,
                                                                    "childrenWeight": 1.7367,
                                                                    "totalQuantityWeight": 1.7367,
                                                                    "singleQuantityMcf": 4.285821,
                                                                    "mcf": 4.285821,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 4.285821,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 3221,
                                                                            "parentRowNumber": 3220,
                                                                            "articleId": "298127",
                                                                            "description": "298127-00-Lenkrolle mit Bremse",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 1.3653,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 1.3653,
                                                                            "singleQuantityMcf": 2.361969,
                                                                            "mcf": 2.361969,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 2.361969,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        },
                                                                        {
                                                                            "rowNumber": 3222,
                                                                            "parentRowNumber": 3220,
                                                                            "articleId": "441003",
                                                                            "description": "441003-00-Mutter",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0289,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0289,
                                                                            "singleQuantityMcf": 0.14970199999999995,
                                                                            "mcf": 0.14970199999999995,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.14970199999999995,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        },
                                                                        {
                                                                            "rowNumber": 3223,
                                                                            "parentRowNumber": 3220,
                                                                            "articleId": "46070852",
                                                                            "description": "46070852-06-Schraube",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.3425,
                                                                            "childrenWeight": 0.3425,
                                                                            "totalQuantityWeight": 0.3425,
                                                                            "singleQuantityMcf": 1.77415,
                                                                            "mcf": 1.77415,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 1.77415,
                                                                            "expandable": false,
                                                                            "children": [
                                                                                {
                                                                                    "rowNumber": 3224,
                                                                                    "parentRowNumber": 3223,
                                                                                    "articleId": "1260050",
                                                                                    "description": "1260050-00-Rundstahl",
                                                                                    "hierarchy": 9,
                                                                                    "quantity": 1,
                                                                                    "weight": 0.3425,
                                                                                    "childrenWeight": 0,
                                                                                    "totalQuantityWeight": 0.3425,
                                                                                    "singleQuantityMcf": 1.77415,
                                                                                    "mcf": 1.77415,
                                                                                    "singleQuantityLcf": 0,
                                                                                    "lcf": 0,
                                                                                    "totalQuantityMcf": 1.77415,
                                                                                    "expandable": false,
                                                                                    "children": []
                                                                                }
                                                                            ]
                                                                        }
                                                                    ]
                                                                }
                                                            ]
                                                        }
                                                    ]
                                                }
                                            ]
                                        },
                                        {
                                            "rowNumber": 3225,
                                            "parentRowNumber": 3160,
                                            "articleId": "33847",
                                            "description": "33847-20NA05-00-Aufnahme Objektführung",
                                            "hierarchy": 4,
                                            "quantity": 1,
                                            "weight": 12.254999999999974,
                                            "childrenWeight": 12.254999999999974,
                                            "totalQuantityWeight": 12.254999999999974,
                                            "singleQuantityMcf": 13.862844000000011,
                                            "mcf": 13.862844000000011,
                                            "singleQuantityLcf": 0,
                                            "lcf": 0,
                                            "totalQuantityMcf": 13.862844000000011,
                                            "expandable": false,
                                            "children": [
                                                {
                                                    "rowNumber": 3226,
                                                    "parentRowNumber": 3225,
                                                    "articleId": "MM0000009366",
                                                    "description": "MM0000009366-00-Aufnahme Objektführung",
                                                    "hierarchy": 5,
                                                    "quantity": 1,
                                                    "weight": 12.254999999999974,
                                                    "childrenWeight": 12.254999999999974,
                                                    "totalQuantityWeight": 12.254999999999974,
                                                    "singleQuantityMcf": 13.862844000000011,
                                                    "mcf": 13.862844000000011,
                                                    "singleQuantityLcf": 0,
                                                    "lcf": 0,
                                                    "totalQuantityMcf": 13.862844000000011,
                                                    "expandable": false,
                                                    "children": [
                                                        {
                                                            "rowNumber": 3227,
                                                            "parentRowNumber": 3226,
                                                            "articleId": "47157393",
                                                            "description": "47157393-00-Halter",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 12.254999999999974,
                                                            "childrenWeight": 12.254999999999974,
                                                            "totalQuantityWeight": 12.254999999999974,
                                                            "singleQuantityMcf": 13.862844000000011,
                                                            "mcf": 13.862844000000011,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 13.862844000000011,
                                                            "expandable": false,
                                                            "children": [
                                                                {
                                                                    "rowNumber": 3228,
                                                                    "parentRowNumber": 3227,
                                                                    "articleId": "20120625",
                                                                    "description": "20120625-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0083,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0083,
                                                                    "singleQuantityMcf": 0.042994,
                                                                    "mcf": 0.042994,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.042994,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3229,
                                                                    "parentRowNumber": 3227,
                                                                    "articleId": "20120625",
                                                                    "description": "20120625-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0083,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0083,
                                                                    "singleQuantityMcf": 0.042994,
                                                                    "mcf": 0.042994,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.042994,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3230,
                                                                    "parentRowNumber": 3227,
                                                                    "articleId": "20120625",
                                                                    "description": "20120625-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0083,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0083,
                                                                    "singleQuantityMcf": 0.042994,
                                                                    "mcf": 0.042994,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.042994,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3231,
                                                                    "parentRowNumber": 3227,
                                                                    "articleId": "20120625",
                                                                    "description": "20120625-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0083,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0083,
                                                                    "singleQuantityMcf": 0.042994,
                                                                    "mcf": 0.042994,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.042994,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3232,
                                                                    "parentRowNumber": 3227,
                                                                    "articleId": "20120625",
                                                                    "description": "20120625-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0083,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0083,
                                                                    "singleQuantityMcf": 0.042994,
                                                                    "mcf": 0.042994,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.042994,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3233,
                                                                    "parentRowNumber": 3227,
                                                                    "articleId": "20120625",
                                                                    "description": "20120625-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0083,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0083,
                                                                    "singleQuantityMcf": 0.042994,
                                                                    "mcf": 0.042994,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.042994,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3234,
                                                                    "parentRowNumber": 3227,
                                                                    "articleId": "20120630",
                                                                    "description": "20120630-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0094,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0094,
                                                                    "singleQuantityMcf": 0.048692,
                                                                    "mcf": 0.048692,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.048692,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3235,
                                                                    "parentRowNumber": 3227,
                                                                    "articleId": "20120630",
                                                                    "description": "20120630-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0094,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0094,
                                                                    "singleQuantityMcf": 0.048692,
                                                                    "mcf": 0.048692,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.048692,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3236,
                                                                    "parentRowNumber": 3227,
                                                                    "articleId": "20120630",
                                                                    "description": "20120630-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0094,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0094,
                                                                    "singleQuantityMcf": 0.048692,
                                                                    "mcf": 0.048692,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.048692,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3237,
                                                                    "parentRowNumber": 3227,
                                                                    "articleId": "20120630",
                                                                    "description": "20120630-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0094,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0094,
                                                                    "singleQuantityMcf": 0.048692,
                                                                    "mcf": 0.048692,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.048692,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3238,
                                                                    "parentRowNumber": 3227,
                                                                    "articleId": "20120630",
                                                                    "description": "20120630-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0094,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0094,
                                                                    "singleQuantityMcf": 0.048692,
                                                                    "mcf": 0.048692,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.048692,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3239,
                                                                    "parentRowNumber": 3227,
                                                                    "articleId": "20120630",
                                                                    "description": "20120630-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0094,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0094,
                                                                    "singleQuantityMcf": 0.048692,
                                                                    "mcf": 0.048692,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.048692,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3240,
                                                                    "parentRowNumber": 3227,
                                                                    "articleId": "20120630",
                                                                    "description": "20120630-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0094,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0094,
                                                                    "singleQuantityMcf": 0.048692,
                                                                    "mcf": 0.048692,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.048692,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3241,
                                                                    "parentRowNumber": 3227,
                                                                    "articleId": "20120630",
                                                                    "description": "20120630-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0094,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0094,
                                                                    "singleQuantityMcf": 0.048692,
                                                                    "mcf": 0.048692,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.048692,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3242,
                                                                    "parentRowNumber": 3227,
                                                                    "articleId": "20120630",
                                                                    "description": "20120630-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0094,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0094,
                                                                    "singleQuantityMcf": 0.048692,
                                                                    "mcf": 0.048692,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.048692,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3243,
                                                                    "parentRowNumber": 3227,
                                                                    "articleId": "20120630",
                                                                    "description": "20120630-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0094,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0094,
                                                                    "singleQuantityMcf": 0.048692,
                                                                    "mcf": 0.048692,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.048692,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3244,
                                                                    "parentRowNumber": 3227,
                                                                    "articleId": "20120630",
                                                                    "description": "20120630-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0094,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0094,
                                                                    "singleQuantityMcf": 0.048692,
                                                                    "mcf": 0.048692,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.048692,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3245,
                                                                    "parentRowNumber": 3227,
                                                                    "articleId": "20120630",
                                                                    "description": "20120630-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0094,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0094,
                                                                    "singleQuantityMcf": 0.048692,
                                                                    "mcf": 0.048692,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.048692,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3246,
                                                                    "parentRowNumber": 3227,
                                                                    "articleId": "20120630",
                                                                    "description": "20120630-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0094,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0094,
                                                                    "singleQuantityMcf": 0.048692,
                                                                    "mcf": 0.048692,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.048692,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3247,
                                                                    "parentRowNumber": 3227,
                                                                    "articleId": "20120630",
                                                                    "description": "20120630-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0094,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0094,
                                                                    "singleQuantityMcf": 0.048692,
                                                                    "mcf": 0.048692,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.048692,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3248,
                                                                    "parentRowNumber": 3227,
                                                                    "articleId": "20120630",
                                                                    "description": "20120630-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0094,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0094,
                                                                    "singleQuantityMcf": 0.048692,
                                                                    "mcf": 0.048692,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.048692,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3249,
                                                                    "parentRowNumber": 3227,
                                                                    "articleId": "20120630",
                                                                    "description": "20120630-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0094,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0094,
                                                                    "singleQuantityMcf": 0.048692,
                                                                    "mcf": 0.048692,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.048692,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3250,
                                                                    "parentRowNumber": 3227,
                                                                    "articleId": "20120630",
                                                                    "description": "20120630-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0094,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0094,
                                                                    "singleQuantityMcf": 0.048692,
                                                                    "mcf": 0.048692,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.048692,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3251,
                                                                    "parentRowNumber": 3227,
                                                                    "articleId": "20120630",
                                                                    "description": "20120630-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0094,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0094,
                                                                    "singleQuantityMcf": 0.048692,
                                                                    "mcf": 0.048692,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.048692,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3252,
                                                                    "parentRowNumber": 3227,
                                                                    "articleId": "20120630",
                                                                    "description": "20120630-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0094,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0094,
                                                                    "singleQuantityMcf": 0.048692,
                                                                    "mcf": 0.048692,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.048692,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3253,
                                                                    "parentRowNumber": 3227,
                                                                    "articleId": "20120630",
                                                                    "description": "20120630-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0094,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0094,
                                                                    "singleQuantityMcf": 0.048692,
                                                                    "mcf": 0.048692,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.048692,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3254,
                                                                    "parentRowNumber": 3227,
                                                                    "articleId": "20120630",
                                                                    "description": "20120630-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0094,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0094,
                                                                    "singleQuantityMcf": 0.048692,
                                                                    "mcf": 0.048692,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.048692,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3255,
                                                                    "parentRowNumber": 3227,
                                                                    "articleId": "20120650",
                                                                    "description": "20120650-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0139,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0139,
                                                                    "singleQuantityMcf": 0.072002,
                                                                    "mcf": 0.072002,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.072002,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3256,
                                                                    "parentRowNumber": 3227,
                                                                    "articleId": "20120655",
                                                                    "description": "20120655-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.015,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.015,
                                                                    "singleQuantityMcf": 0.07769999999999999,
                                                                    "mcf": 0.07769999999999999,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.07769999999999999,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3257,
                                                                    "parentRowNumber": 3227,
                                                                    "articleId": "20120655",
                                                                    "description": "20120655-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.015,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.015,
                                                                    "singleQuantityMcf": 0.07769999999999999,
                                                                    "mcf": 0.07769999999999999,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.07769999999999999,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3258,
                                                                    "parentRowNumber": 3227,
                                                                    "articleId": "20120655",
                                                                    "description": "20120655-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.015,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.015,
                                                                    "singleQuantityMcf": 0.07769999999999999,
                                                                    "mcf": 0.07769999999999999,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.07769999999999999,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3259,
                                                                    "parentRowNumber": 3227,
                                                                    "articleId": "20120830",
                                                                    "description": "20120830-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0181,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0181,
                                                                    "singleQuantityMcf": 0.093758,
                                                                    "mcf": 0.093758,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.093758,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3260,
                                                                    "parentRowNumber": 3227,
                                                                    "articleId": "20220625",
                                                                    "description": "20220625-01-Zylinderschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0075,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0075,
                                                                    "singleQuantityMcf": 0.03885,
                                                                    "mcf": 0.03885,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.03885,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3261,
                                                                    "parentRowNumber": 3227,
                                                                    "articleId": "20220625",
                                                                    "description": "20220625-01-Zylinderschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0075,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0075,
                                                                    "singleQuantityMcf": 0.03885,
                                                                    "mcf": 0.03885,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.03885,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3262,
                                                                    "parentRowNumber": 3227,
                                                                    "articleId": "20220630",
                                                                    "description": "20220630-00-Zylinderschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0086,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0086,
                                                                    "singleQuantityMcf": 0.044548,
                                                                    "mcf": 0.044548,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.044548,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3263,
                                                                    "parentRowNumber": 3227,
                                                                    "articleId": "20220630",
                                                                    "description": "20220630-00-Zylinderschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0086,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0086,
                                                                    "singleQuantityMcf": 0.044548,
                                                                    "mcf": 0.044548,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.044548,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3264,
                                                                    "parentRowNumber": 3227,
                                                                    "articleId": "20220630",
                                                                    "description": "20220630-00-Zylinderschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0086,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0086,
                                                                    "singleQuantityMcf": 0.044548,
                                                                    "mcf": 0.044548,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.044548,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3265,
                                                                    "parentRowNumber": 3227,
                                                                    "articleId": "20220630",
                                                                    "description": "20220630-00-Zylinderschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0086,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0086,
                                                                    "singleQuantityMcf": 0.044548,
                                                                    "mcf": 0.044548,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.044548,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3266,
                                                                    "parentRowNumber": 3227,
                                                                    "articleId": "20220670",
                                                                    "description": "20220670-00-Zylinderschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0176,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0176,
                                                                    "singleQuantityMcf": 0.091168,
                                                                    "mcf": 0.091168,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.091168,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3267,
                                                                    "parentRowNumber": 3227,
                                                                    "articleId": "20220670",
                                                                    "description": "20220670-00-Zylinderschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0176,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0176,
                                                                    "singleQuantityMcf": 0.091168,
                                                                    "mcf": 0.091168,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.091168,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3268,
                                                                    "parentRowNumber": 3227,
                                                                    "articleId": "208206",
                                                                    "description": "208206-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0009,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0009,
                                                                    "singleQuantityMcf": 0.004662,
                                                                    "mcf": 0.004662,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.004662,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3269,
                                                                    "parentRowNumber": 3227,
                                                                    "articleId": "208206",
                                                                    "description": "208206-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0009,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0009,
                                                                    "singleQuantityMcf": 0.004662,
                                                                    "mcf": 0.004662,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.004662,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3270,
                                                                    "parentRowNumber": 3227,
                                                                    "articleId": "208206",
                                                                    "description": "208206-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0009,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0009,
                                                                    "singleQuantityMcf": 0.004662,
                                                                    "mcf": 0.004662,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.004662,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3271,
                                                                    "parentRowNumber": 3227,
                                                                    "articleId": "208206",
                                                                    "description": "208206-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0009,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0009,
                                                                    "singleQuantityMcf": 0.004662,
                                                                    "mcf": 0.004662,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.004662,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3272,
                                                                    "parentRowNumber": 3227,
                                                                    "articleId": "208206",
                                                                    "description": "208206-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0009,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0009,
                                                                    "singleQuantityMcf": 0.004662,
                                                                    "mcf": 0.004662,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.004662,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3273,
                                                                    "parentRowNumber": 3227,
                                                                    "articleId": "208206",
                                                                    "description": "208206-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0009,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0009,
                                                                    "singleQuantityMcf": 0.004662,
                                                                    "mcf": 0.004662,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.004662,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3274,
                                                                    "parentRowNumber": 3227,
                                                                    "articleId": "208206",
                                                                    "description": "208206-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0009,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0009,
                                                                    "singleQuantityMcf": 0.004662,
                                                                    "mcf": 0.004662,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.004662,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3275,
                                                                    "parentRowNumber": 3227,
                                                                    "articleId": "208206",
                                                                    "description": "208206-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0009,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0009,
                                                                    "singleQuantityMcf": 0.004662,
                                                                    "mcf": 0.004662,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.004662,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3276,
                                                                    "parentRowNumber": 3227,
                                                                    "articleId": "208206",
                                                                    "description": "208206-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0009,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0009,
                                                                    "singleQuantityMcf": 0.004662,
                                                                    "mcf": 0.004662,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.004662,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3277,
                                                                    "parentRowNumber": 3227,
                                                                    "articleId": "208206",
                                                                    "description": "208206-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0009,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0009,
                                                                    "singleQuantityMcf": 0.004662,
                                                                    "mcf": 0.004662,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.004662,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3278,
                                                                    "parentRowNumber": 3227,
                                                                    "articleId": "208206",
                                                                    "description": "208206-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0009,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0009,
                                                                    "singleQuantityMcf": 0.004662,
                                                                    "mcf": 0.004662,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.004662,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3279,
                                                                    "parentRowNumber": 3227,
                                                                    "articleId": "208206",
                                                                    "description": "208206-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0009,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0009,
                                                                    "singleQuantityMcf": 0.004662,
                                                                    "mcf": 0.004662,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.004662,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3280,
                                                                    "parentRowNumber": 3227,
                                                                    "articleId": "208206",
                                                                    "description": "208206-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0009,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0009,
                                                                    "singleQuantityMcf": 0.004662,
                                                                    "mcf": 0.004662,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.004662,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3281,
                                                                    "parentRowNumber": 3227,
                                                                    "articleId": "208206",
                                                                    "description": "208206-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0009,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0009,
                                                                    "singleQuantityMcf": 0.004662,
                                                                    "mcf": 0.004662,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.004662,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3282,
                                                                    "parentRowNumber": 3227,
                                                                    "articleId": "208206",
                                                                    "description": "208206-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0009,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0009,
                                                                    "singleQuantityMcf": 0.004662,
                                                                    "mcf": 0.004662,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.004662,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3283,
                                                                    "parentRowNumber": 3227,
                                                                    "articleId": "208206",
                                                                    "description": "208206-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0009,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0009,
                                                                    "singleQuantityMcf": 0.004662,
                                                                    "mcf": 0.004662,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.004662,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3284,
                                                                    "parentRowNumber": 3227,
                                                                    "articleId": "208206",
                                                                    "description": "208206-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0009,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0009,
                                                                    "singleQuantityMcf": 0.004662,
                                                                    "mcf": 0.004662,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.004662,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3285,
                                                                    "parentRowNumber": 3227,
                                                                    "articleId": "208206",
                                                                    "description": "208206-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0009,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0009,
                                                                    "singleQuantityMcf": 0.004662,
                                                                    "mcf": 0.004662,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.004662,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3286,
                                                                    "parentRowNumber": 3227,
                                                                    "articleId": "208206",
                                                                    "description": "208206-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0009,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0009,
                                                                    "singleQuantityMcf": 0.004662,
                                                                    "mcf": 0.004662,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.004662,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3287,
                                                                    "parentRowNumber": 3227,
                                                                    "articleId": "208206",
                                                                    "description": "208206-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0009,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0009,
                                                                    "singleQuantityMcf": 0.004662,
                                                                    "mcf": 0.004662,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.004662,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3288,
                                                                    "parentRowNumber": 3227,
                                                                    "articleId": "208206",
                                                                    "description": "208206-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0009,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0009,
                                                                    "singleQuantityMcf": 0.004662,
                                                                    "mcf": 0.004662,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.004662,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3289,
                                                                    "parentRowNumber": 3227,
                                                                    "articleId": "208206",
                                                                    "description": "208206-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0009,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0009,
                                                                    "singleQuantityMcf": 0.004662,
                                                                    "mcf": 0.004662,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.004662,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3290,
                                                                    "parentRowNumber": 3227,
                                                                    "articleId": "208206",
                                                                    "description": "208206-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0009,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0009,
                                                                    "singleQuantityMcf": 0.004662,
                                                                    "mcf": 0.004662,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.004662,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3291,
                                                                    "parentRowNumber": 3227,
                                                                    "articleId": "208206",
                                                                    "description": "208206-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0009,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0009,
                                                                    "singleQuantityMcf": 0.004662,
                                                                    "mcf": 0.004662,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.004662,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3292,
                                                                    "parentRowNumber": 3227,
                                                                    "articleId": "208206",
                                                                    "description": "208206-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0009,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0009,
                                                                    "singleQuantityMcf": 0.004662,
                                                                    "mcf": 0.004662,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.004662,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3293,
                                                                    "parentRowNumber": 3227,
                                                                    "articleId": "208206",
                                                                    "description": "208206-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0009,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0009,
                                                                    "singleQuantityMcf": 0.004662,
                                                                    "mcf": 0.004662,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.004662,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3294,
                                                                    "parentRowNumber": 3227,
                                                                    "articleId": "208206",
                                                                    "description": "208206-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0009,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0009,
                                                                    "singleQuantityMcf": 0.004662,
                                                                    "mcf": 0.004662,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.004662,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3295,
                                                                    "parentRowNumber": 3227,
                                                                    "articleId": "208206",
                                                                    "description": "208206-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0009,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0009,
                                                                    "singleQuantityMcf": 0.004662,
                                                                    "mcf": 0.004662,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.004662,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3296,
                                                                    "parentRowNumber": 3227,
                                                                    "articleId": "208206",
                                                                    "description": "208206-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0009,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0009,
                                                                    "singleQuantityMcf": 0.004662,
                                                                    "mcf": 0.004662,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.004662,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3297,
                                                                    "parentRowNumber": 3227,
                                                                    "articleId": "208206",
                                                                    "description": "208206-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0009,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0009,
                                                                    "singleQuantityMcf": 0.004662,
                                                                    "mcf": 0.004662,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.004662,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3298,
                                                                    "parentRowNumber": 3227,
                                                                    "articleId": "208206",
                                                                    "description": "208206-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0009,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0009,
                                                                    "singleQuantityMcf": 0.004662,
                                                                    "mcf": 0.004662,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.004662,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3299,
                                                                    "parentRowNumber": 3227,
                                                                    "articleId": "208208",
                                                                    "description": "208208-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0017,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0017,
                                                                    "singleQuantityMcf": 0.008806,
                                                                    "mcf": 0.008806,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.008806,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3300,
                                                                    "parentRowNumber": 3227,
                                                                    "articleId": "46043939",
                                                                    "description": "46043939-00-Traverse",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.3438,
                                                                    "childrenWeight": 0.3438,
                                                                    "totalQuantityWeight": 0.3438,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 3301,
                                                                            "parentRowNumber": 3300,
                                                                            "articleId": "119278",
                                                                            "description": "119278-00-Vierkantstange",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.3438,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.3438,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 3302,
                                                                    "parentRowNumber": 3227,
                                                                    "articleId": "46043939",
                                                                    "description": "46043939-00-Traverse",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.3438,
                                                                    "childrenWeight": 0.3438,
                                                                    "totalQuantityWeight": 0.3438,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 3303,
                                                                            "parentRowNumber": 3302,
                                                                            "articleId": "119278",
                                                                            "description": "119278-00-Vierkantstange",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.3438,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.3438,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 3304,
                                                                    "parentRowNumber": 3227,
                                                                    "articleId": "46114434",
                                                                    "description": "46114434-01-Traverse",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0413,
                                                                    "childrenWeight": 0.0413,
                                                                    "totalQuantityWeight": 0.0413,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 3305,
                                                                            "parentRowNumber": 3304,
                                                                            "articleId": "119278",
                                                                            "description": "119278-00-Vierkantstange",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0413,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0413,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 3306,
                                                                    "parentRowNumber": 3227,
                                                                    "articleId": "46363292",
                                                                    "description": "46363292-00-Fluegelschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0337,
                                                                    "childrenWeight": 0.0337,
                                                                    "totalQuantityWeight": 0.0337,
                                                                    "singleQuantityMcf": 0.106256,
                                                                    "mcf": 0.106256,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.106256,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 3307,
                                                                            "parentRowNumber": 3306,
                                                                            "articleId": "220906",
                                                                            "description": "220906-00-Fluegelmutter",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0198,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0198,
                                                                            "singleQuantityMcf": 0.034254,
                                                                            "mcf": 0.034254,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.034254,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        },
                                                                        {
                                                                            "rowNumber": 3308,
                                                                            "parentRowNumber": 3306,
                                                                            "articleId": "46363294",
                                                                            "description": "46363294-00-Welle",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0139,
                                                                            "childrenWeight": 0.0139,
                                                                            "totalQuantityWeight": 0.0139,
                                                                            "singleQuantityMcf": 0.072002,
                                                                            "mcf": 0.072002,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.072002,
                                                                            "expandable": false,
                                                                            "children": [
                                                                                {
                                                                                    "rowNumber": 3309,
                                                                                    "parentRowNumber": 3308,
                                                                                    "articleId": "1260006",
                                                                                    "description": "1260006-00-Rundstahl",
                                                                                    "hierarchy": 9,
                                                                                    "quantity": 1,
                                                                                    "weight": 0.0139,
                                                                                    "childrenWeight": 0,
                                                                                    "totalQuantityWeight": 0.0139,
                                                                                    "singleQuantityMcf": 0.072002,
                                                                                    "mcf": 0.072002,
                                                                                    "singleQuantityLcf": 0,
                                                                                    "lcf": 0,
                                                                                    "totalQuantityMcf": 0.072002,
                                                                                    "expandable": false,
                                                                                    "children": []
                                                                                }
                                                                            ]
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 3310,
                                                                    "parentRowNumber": 3227,
                                                                    "articleId": "46363292",
                                                                    "description": "46363292-00-Fluegelschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0337,
                                                                    "childrenWeight": 0.0337,
                                                                    "totalQuantityWeight": 0.0337,
                                                                    "singleQuantityMcf": 0.106256,
                                                                    "mcf": 0.106256,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.106256,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 3311,
                                                                            "parentRowNumber": 3310,
                                                                            "articleId": "220906",
                                                                            "description": "220906-00-Fluegelmutter",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0198,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0198,
                                                                            "singleQuantityMcf": 0.034254,
                                                                            "mcf": 0.034254,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.034254,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        },
                                                                        {
                                                                            "rowNumber": 3312,
                                                                            "parentRowNumber": 3310,
                                                                            "articleId": "46363294",
                                                                            "description": "46363294-00-Welle",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0139,
                                                                            "childrenWeight": 0.0139,
                                                                            "totalQuantityWeight": 0.0139,
                                                                            "singleQuantityMcf": 0.072002,
                                                                            "mcf": 0.072002,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.072002,
                                                                            "expandable": false,
                                                                            "children": [
                                                                                {
                                                                                    "rowNumber": 3313,
                                                                                    "parentRowNumber": 3312,
                                                                                    "articleId": "1260006",
                                                                                    "description": "1260006-00-Rundstahl",
                                                                                    "hierarchy": 9,
                                                                                    "quantity": 1,
                                                                                    "weight": 0.0139,
                                                                                    "childrenWeight": 0,
                                                                                    "totalQuantityWeight": 0.0139,
                                                                                    "singleQuantityMcf": 0.072002,
                                                                                    "mcf": 0.072002,
                                                                                    "singleQuantityLcf": 0,
                                                                                    "lcf": 0,
                                                                                    "totalQuantityMcf": 0.072002,
                                                                                    "expandable": false,
                                                                                    "children": []
                                                                                }
                                                                            ]
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 3314,
                                                                    "parentRowNumber": 3227,
                                                                    "articleId": "46363292",
                                                                    "description": "46363292-00-Fluegelschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0337,
                                                                    "childrenWeight": 0.0337,
                                                                    "totalQuantityWeight": 0.0337,
                                                                    "singleQuantityMcf": 0.106256,
                                                                    "mcf": 0.106256,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.106256,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 3315,
                                                                            "parentRowNumber": 3314,
                                                                            "articleId": "220906",
                                                                            "description": "220906-00-Fluegelmutter",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0198,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0198,
                                                                            "singleQuantityMcf": 0.034254,
                                                                            "mcf": 0.034254,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.034254,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        },
                                                                        {
                                                                            "rowNumber": 3316,
                                                                            "parentRowNumber": 3314,
                                                                            "articleId": "46363294",
                                                                            "description": "46363294-00-Welle",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0139,
                                                                            "childrenWeight": 0.0139,
                                                                            "totalQuantityWeight": 0.0139,
                                                                            "singleQuantityMcf": 0.072002,
                                                                            "mcf": 0.072002,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.072002,
                                                                            "expandable": false,
                                                                            "children": [
                                                                                {
                                                                                    "rowNumber": 3317,
                                                                                    "parentRowNumber": 3316,
                                                                                    "articleId": "1260006",
                                                                                    "description": "1260006-00-Rundstahl",
                                                                                    "hierarchy": 9,
                                                                                    "quantity": 1,
                                                                                    "weight": 0.0139,
                                                                                    "childrenWeight": 0,
                                                                                    "totalQuantityWeight": 0.0139,
                                                                                    "singleQuantityMcf": 0.072002,
                                                                                    "mcf": 0.072002,
                                                                                    "singleQuantityLcf": 0,
                                                                                    "lcf": 0,
                                                                                    "totalQuantityMcf": 0.072002,
                                                                                    "expandable": false,
                                                                                    "children": []
                                                                                }
                                                                            ]
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 3318,
                                                                    "parentRowNumber": 3227,
                                                                    "articleId": "46363292",
                                                                    "description": "46363292-00-Fluegelschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0337,
                                                                    "childrenWeight": 0.0337,
                                                                    "totalQuantityWeight": 0.0337,
                                                                    "singleQuantityMcf": 0.106256,
                                                                    "mcf": 0.106256,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.106256,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 3319,
                                                                            "parentRowNumber": 3318,
                                                                            "articleId": "220906",
                                                                            "description": "220906-00-Fluegelmutter",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0198,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0198,
                                                                            "singleQuantityMcf": 0.034254,
                                                                            "mcf": 0.034254,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.034254,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        },
                                                                        {
                                                                            "rowNumber": 3320,
                                                                            "parentRowNumber": 3318,
                                                                            "articleId": "46363294",
                                                                            "description": "46363294-00-Welle",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0139,
                                                                            "childrenWeight": 0.0139,
                                                                            "totalQuantityWeight": 0.0139,
                                                                            "singleQuantityMcf": 0.072002,
                                                                            "mcf": 0.072002,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.072002,
                                                                            "expandable": false,
                                                                            "children": [
                                                                                {
                                                                                    "rowNumber": 3321,
                                                                                    "parentRowNumber": 3320,
                                                                                    "articleId": "1260006",
                                                                                    "description": "1260006-00-Rundstahl",
                                                                                    "hierarchy": 9,
                                                                                    "quantity": 1,
                                                                                    "weight": 0.0139,
                                                                                    "childrenWeight": 0,
                                                                                    "totalQuantityWeight": 0.0139,
                                                                                    "singleQuantityMcf": 0.072002,
                                                                                    "mcf": 0.072002,
                                                                                    "singleQuantityLcf": 0,
                                                                                    "lcf": 0,
                                                                                    "totalQuantityMcf": 0.072002,
                                                                                    "expandable": false,
                                                                                    "children": []
                                                                                }
                                                                            ]
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 3322,
                                                                    "parentRowNumber": 3227,
                                                                    "articleId": "46363292",
                                                                    "description": "46363292-00-Fluegelschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0337,
                                                                    "childrenWeight": 0.0337,
                                                                    "totalQuantityWeight": 0.0337,
                                                                    "singleQuantityMcf": 0.106256,
                                                                    "mcf": 0.106256,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.106256,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 3323,
                                                                            "parentRowNumber": 3322,
                                                                            "articleId": "220906",
                                                                            "description": "220906-00-Fluegelmutter",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0198,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0198,
                                                                            "singleQuantityMcf": 0.034254,
                                                                            "mcf": 0.034254,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.034254,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        },
                                                                        {
                                                                            "rowNumber": 3324,
                                                                            "parentRowNumber": 3322,
                                                                            "articleId": "46363294",
                                                                            "description": "46363294-00-Welle",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0139,
                                                                            "childrenWeight": 0.0139,
                                                                            "totalQuantityWeight": 0.0139,
                                                                            "singleQuantityMcf": 0.072002,
                                                                            "mcf": 0.072002,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.072002,
                                                                            "expandable": false,
                                                                            "children": [
                                                                                {
                                                                                    "rowNumber": 3325,
                                                                                    "parentRowNumber": 3324,
                                                                                    "articleId": "1260006",
                                                                                    "description": "1260006-00-Rundstahl",
                                                                                    "hierarchy": 9,
                                                                                    "quantity": 1,
                                                                                    "weight": 0.0139,
                                                                                    "childrenWeight": 0,
                                                                                    "totalQuantityWeight": 0.0139,
                                                                                    "singleQuantityMcf": 0.072002,
                                                                                    "mcf": 0.072002,
                                                                                    "singleQuantityLcf": 0,
                                                                                    "lcf": 0,
                                                                                    "totalQuantityMcf": 0.072002,
                                                                                    "expandable": false,
                                                                                    "children": []
                                                                                }
                                                                            ]
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 3326,
                                                                    "parentRowNumber": 3227,
                                                                    "articleId": "46363292",
                                                                    "description": "46363292-00-Fluegelschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0337,
                                                                    "childrenWeight": 0.0337,
                                                                    "totalQuantityWeight": 0.0337,
                                                                    "singleQuantityMcf": 0.106256,
                                                                    "mcf": 0.106256,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.106256,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 3327,
                                                                            "parentRowNumber": 3326,
                                                                            "articleId": "220906",
                                                                            "description": "220906-00-Fluegelmutter",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0198,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0198,
                                                                            "singleQuantityMcf": 0.034254,
                                                                            "mcf": 0.034254,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.034254,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        },
                                                                        {
                                                                            "rowNumber": 3328,
                                                                            "parentRowNumber": 3326,
                                                                            "articleId": "46363294",
                                                                            "description": "46363294-00-Welle",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0139,
                                                                            "childrenWeight": 0.0139,
                                                                            "totalQuantityWeight": 0.0139,
                                                                            "singleQuantityMcf": 0.072002,
                                                                            "mcf": 0.072002,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.072002,
                                                                            "expandable": false,
                                                                            "children": [
                                                                                {
                                                                                    "rowNumber": 3329,
                                                                                    "parentRowNumber": 3328,
                                                                                    "articleId": "1260006",
                                                                                    "description": "1260006-00-Rundstahl",
                                                                                    "hierarchy": 9,
                                                                                    "quantity": 1,
                                                                                    "weight": 0.0139,
                                                                                    "childrenWeight": 0,
                                                                                    "totalQuantityWeight": 0.0139,
                                                                                    "singleQuantityMcf": 0.072002,
                                                                                    "mcf": 0.072002,
                                                                                    "singleQuantityLcf": 0,
                                                                                    "lcf": 0,
                                                                                    "totalQuantityMcf": 0.072002,
                                                                                    "expandable": false,
                                                                                    "children": []
                                                                                }
                                                                            ]
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 3330,
                                                                    "parentRowNumber": 3227,
                                                                    "articleId": "46363292",
                                                                    "description": "46363292-00-Fluegelschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0337,
                                                                    "childrenWeight": 0.0337,
                                                                    "totalQuantityWeight": 0.0337,
                                                                    "singleQuantityMcf": 0.106256,
                                                                    "mcf": 0.106256,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.106256,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 3331,
                                                                            "parentRowNumber": 3330,
                                                                            "articleId": "220906",
                                                                            "description": "220906-00-Fluegelmutter",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0198,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0198,
                                                                            "singleQuantityMcf": 0.034254,
                                                                            "mcf": 0.034254,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.034254,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        },
                                                                        {
                                                                            "rowNumber": 3332,
                                                                            "parentRowNumber": 3330,
                                                                            "articleId": "46363294",
                                                                            "description": "46363294-00-Welle",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0139,
                                                                            "childrenWeight": 0.0139,
                                                                            "totalQuantityWeight": 0.0139,
                                                                            "singleQuantityMcf": 0.072002,
                                                                            "mcf": 0.072002,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.072002,
                                                                            "expandable": false,
                                                                            "children": [
                                                                                {
                                                                                    "rowNumber": 3333,
                                                                                    "parentRowNumber": 3332,
                                                                                    "articleId": "1260006",
                                                                                    "description": "1260006-00-Rundstahl",
                                                                                    "hierarchy": 9,
                                                                                    "quantity": 1,
                                                                                    "weight": 0.0139,
                                                                                    "childrenWeight": 0,
                                                                                    "totalQuantityWeight": 0.0139,
                                                                                    "singleQuantityMcf": 0.072002,
                                                                                    "mcf": 0.072002,
                                                                                    "singleQuantityLcf": 0,
                                                                                    "lcf": 0,
                                                                                    "totalQuantityMcf": 0.072002,
                                                                                    "expandable": false,
                                                                                    "children": []
                                                                                }
                                                                            ]
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 3334,
                                                                    "parentRowNumber": 3227,
                                                                    "articleId": "46388175",
                                                                    "description": "46388175-00-Halter",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0479,
                                                                    "childrenWeight": 0.0479,
                                                                    "totalQuantityWeight": 0.0479,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 3335,
                                                                            "parentRowNumber": 3334,
                                                                            "articleId": "119278",
                                                                            "description": "119278-00-Vierkantstange",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0479,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0479,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 3336,
                                                                    "parentRowNumber": 3227,
                                                                    "articleId": "46388298",
                                                                    "description": "46388298-00-Traverse",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.4738,
                                                                    "childrenWeight": 0.4738,
                                                                    "totalQuantityWeight": 0.4738,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 3337,
                                                                            "parentRowNumber": 3336,
                                                                            "articleId": "119278",
                                                                            "description": "119278-00-Vierkantstange",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.4738,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.4738,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 3338,
                                                                    "parentRowNumber": 3227,
                                                                    "articleId": "46389253",
                                                                    "description": "46389253-00-Traverse",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.5284,
                                                                    "childrenWeight": 0.5284,
                                                                    "totalQuantityWeight": 0.5284,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 3339,
                                                                            "parentRowNumber": 3338,
                                                                            "articleId": "119278",
                                                                            "description": "119278-00-Vierkantstange",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.5284,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.5284,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 3340,
                                                                    "parentRowNumber": 3227,
                                                                    "articleId": "46389266",
                                                                    "description": "46389266-00-Traverse",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.3441,
                                                                    "childrenWeight": 0.3441,
                                                                    "totalQuantityWeight": 0.3441,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 3341,
                                                                            "parentRowNumber": 3340,
                                                                            "articleId": "119278",
                                                                            "description": "119278-00-Vierkantstange",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.3441,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.3441,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 3342,
                                                                    "parentRowNumber": 3227,
                                                                    "articleId": "46812942",
                                                                    "description": "46812942-00-Traverse",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0811,
                                                                    "childrenWeight": 0.0811,
                                                                    "totalQuantityWeight": 0.0811,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 3343,
                                                                            "parentRowNumber": 3342,
                                                                            "articleId": "119278",
                                                                            "description": "119278-00-Vierkantstange",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0811,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0811,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 3344,
                                                                    "parentRowNumber": 3227,
                                                                    "articleId": "46833637",
                                                                    "description": "46833637-00-Traverse",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.1035,
                                                                    "childrenWeight": 0.1035,
                                                                    "totalQuantityWeight": 0.1035,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 3345,
                                                                            "parentRowNumber": 3344,
                                                                            "articleId": "119278",
                                                                            "description": "119278-00-Vierkantstange",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.1035,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.1035,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 3346,
                                                                    "parentRowNumber": 3227,
                                                                    "articleId": "46839692",
                                                                    "description": "46839692-00-Traverse",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.5282,
                                                                    "childrenWeight": 0.5282,
                                                                    "totalQuantityWeight": 0.5282,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 3347,
                                                                            "parentRowNumber": 3346,
                                                                            "articleId": "119278",
                                                                            "description": "119278-00-Vierkantstange",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.5282,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.5282,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 3348,
                                                                    "parentRowNumber": 3227,
                                                                    "articleId": "46841144",
                                                                    "description": "46841144-00-Traverse",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.346,
                                                                    "childrenWeight": 0.346,
                                                                    "totalQuantityWeight": 0.346,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 3349,
                                                                            "parentRowNumber": 3348,
                                                                            "articleId": "119278",
                                                                            "description": "119278-00-Vierkantstange",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.346,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.346,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 3350,
                                                                    "parentRowNumber": 3227,
                                                                    "articleId": "47157394",
                                                                    "description": "47157394-00-Halter",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 3.2402,
                                                                    "childrenWeight": 3.2402,
                                                                    "totalQuantityWeight": 3.2402,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 3351,
                                                                            "parentRowNumber": 3350,
                                                                            "articleId": "141719",
                                                                            "description": "141719-00-Platte",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 3.2402,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 3.2402,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 3352,
                                                                    "parentRowNumber": 3227,
                                                                    "articleId": "47157417",
                                                                    "description": "47157417-00-Halter",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 1.359,
                                                                    "childrenWeight": 1.359,
                                                                    "totalQuantityWeight": 1.359,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 3353,
                                                                            "parentRowNumber": 3352,
                                                                            "articleId": "141719",
                                                                            "description": "141719-00-Platte",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 1.359,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 1.359,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 3354,
                                                                    "parentRowNumber": 3227,
                                                                    "articleId": "47159644",
                                                                    "description": "47159644-00-Fuehrung",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 1.9963,
                                                                    "childrenWeight": 1.9963,
                                                                    "totalQuantityWeight": 1.9963,
                                                                    "singleQuantityMcf": 6.38816,
                                                                    "mcf": 6.38816,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 6.38816,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 3355,
                                                                            "parentRowNumber": 3354,
                                                                            "articleId": "162544",
                                                                            "description": "162544-01-Platte",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 1.9963,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 1.9963,
                                                                            "singleQuantityMcf": 6.38816,
                                                                            "mcf": 6.38816,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 6.38816,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 3356,
                                                                    "parentRowNumber": 3227,
                                                                    "articleId": "47159759",
                                                                    "description": "47159759-00-Traverse",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.3428,
                                                                    "childrenWeight": 0.3428,
                                                                    "totalQuantityWeight": 0.3428,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 3357,
                                                                            "parentRowNumber": 3356,
                                                                            "articleId": "119278",
                                                                            "description": "119278-00-Vierkantstange",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.3428,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.3428,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 3358,
                                                                    "parentRowNumber": 3227,
                                                                    "articleId": "47159759",
                                                                    "description": "47159759-00-Traverse",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.3428,
                                                                    "childrenWeight": 0.3428,
                                                                    "totalQuantityWeight": 0.3428,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 3359,
                                                                            "parentRowNumber": 3358,
                                                                            "articleId": "119278",
                                                                            "description": "119278-00-Vierkantstange",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.3428,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.3428,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 3360,
                                                                    "parentRowNumber": 3227,
                                                                    "articleId": "47230653",
                                                                    "description": "47230653-00-Halter",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0861,
                                                                    "childrenWeight": 0.0861,
                                                                    "totalQuantityWeight": 0.0861,
                                                                    "singleQuantityMcf": 0.445998,
                                                                    "mcf": 0.445998,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.445998,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 3361,
                                                                            "parentRowNumber": 3360,
                                                                            "articleId": "1110284",
                                                                            "description": "1110284-00-Flachstahl",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0861,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0861,
                                                                            "singleQuantityMcf": 0.445998,
                                                                            "mcf": 0.445998,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.445998,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 3362,
                                                                    "parentRowNumber": 3227,
                                                                    "articleId": "47230653",
                                                                    "description": "47230653-00-Halter",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0861,
                                                                    "childrenWeight": 0.0861,
                                                                    "totalQuantityWeight": 0.0861,
                                                                    "singleQuantityMcf": 0.445998,
                                                                    "mcf": 0.445998,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.445998,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 3363,
                                                                            "parentRowNumber": 3362,
                                                                            "articleId": "1110284",
                                                                            "description": "1110284-00-Flachstahl",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0861,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0861,
                                                                            "singleQuantityMcf": 0.445998,
                                                                            "mcf": 0.445998,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.445998,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 3364,
                                                                    "parentRowNumber": 3227,
                                                                    "articleId": "47230653",
                                                                    "description": "47230653-00-Halter",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0861,
                                                                    "childrenWeight": 0.0861,
                                                                    "totalQuantityWeight": 0.0861,
                                                                    "singleQuantityMcf": 0.445998,
                                                                    "mcf": 0.445998,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.445998,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 3365,
                                                                            "parentRowNumber": 3364,
                                                                            "articleId": "1110284",
                                                                            "description": "1110284-00-Flachstahl",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0861,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0861,
                                                                            "singleQuantityMcf": 0.445998,
                                                                            "mcf": 0.445998,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.445998,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 3366,
                                                                    "parentRowNumber": 3227,
                                                                    "articleId": "47230653",
                                                                    "description": "47230653-00-Halter",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0861,
                                                                    "childrenWeight": 0.0861,
                                                                    "totalQuantityWeight": 0.0861,
                                                                    "singleQuantityMcf": 0.445998,
                                                                    "mcf": 0.445998,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.445998,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 3367,
                                                                            "parentRowNumber": 3366,
                                                                            "articleId": "1110284",
                                                                            "description": "1110284-00-Flachstahl",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0861,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0861,
                                                                            "singleQuantityMcf": 0.445998,
                                                                            "mcf": 0.445998,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.445998,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 3368,
                                                                    "parentRowNumber": 3227,
                                                                    "articleId": "47230653",
                                                                    "description": "47230653-00-Halter",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0861,
                                                                    "childrenWeight": 0.0861,
                                                                    "totalQuantityWeight": 0.0861,
                                                                    "singleQuantityMcf": 0.445998,
                                                                    "mcf": 0.445998,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.445998,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 3369,
                                                                            "parentRowNumber": 3368,
                                                                            "articleId": "1110284",
                                                                            "description": "1110284-00-Flachstahl",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0861,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0861,
                                                                            "singleQuantityMcf": 0.445998,
                                                                            "mcf": 0.445998,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.445998,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 3370,
                                                                    "parentRowNumber": 3227,
                                                                    "articleId": "47230653",
                                                                    "description": "47230653-00-Halter",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0861,
                                                                    "childrenWeight": 0.0861,
                                                                    "totalQuantityWeight": 0.0861,
                                                                    "singleQuantityMcf": 0.445998,
                                                                    "mcf": 0.445998,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.445998,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 3371,
                                                                            "parentRowNumber": 3370,
                                                                            "articleId": "1110284",
                                                                            "description": "1110284-00-Flachstahl",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0861,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0861,
                                                                            "singleQuantityMcf": 0.445998,
                                                                            "mcf": 0.445998,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.445998,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 3372,
                                                                    "parentRowNumber": 3227,
                                                                    "articleId": "47230653",
                                                                    "description": "47230653-00-Halter",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0861,
                                                                    "childrenWeight": 0.0861,
                                                                    "totalQuantityWeight": 0.0861,
                                                                    "singleQuantityMcf": 0.445998,
                                                                    "mcf": 0.445998,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.445998,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 3373,
                                                                            "parentRowNumber": 3372,
                                                                            "articleId": "1110284",
                                                                            "description": "1110284-00-Flachstahl",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0861,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0861,
                                                                            "singleQuantityMcf": 0.445998,
                                                                            "mcf": 0.445998,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.445998,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 3374,
                                                                    "parentRowNumber": 3227,
                                                                    "articleId": "47230653",
                                                                    "description": "47230653-00-Halter",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0861,
                                                                    "childrenWeight": 0.0861,
                                                                    "totalQuantityWeight": 0.0861,
                                                                    "singleQuantityMcf": 0.445998,
                                                                    "mcf": 0.445998,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.445998,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 3375,
                                                                            "parentRowNumber": 3374,
                                                                            "articleId": "1110284",
                                                                            "description": "1110284-00-Flachstahl",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0861,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0861,
                                                                            "singleQuantityMcf": 0.445998,
                                                                            "mcf": 0.445998,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.445998,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 3376,
                                                                    "parentRowNumber": 3227,
                                                                    "articleId": "47230653",
                                                                    "description": "47230653-00-Halter",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0861,
                                                                    "childrenWeight": 0.0861,
                                                                    "totalQuantityWeight": 0.0861,
                                                                    "singleQuantityMcf": 0.445998,
                                                                    "mcf": 0.445998,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.445998,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 3377,
                                                                            "parentRowNumber": 3376,
                                                                            "articleId": "1110284",
                                                                            "description": "1110284-00-Flachstahl",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0861,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0861,
                                                                            "singleQuantityMcf": 0.445998,
                                                                            "mcf": 0.445998,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.445998,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 3378,
                                                                    "parentRowNumber": 3227,
                                                                    "articleId": "47230653",
                                                                    "description": "47230653-00-Halter",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0861,
                                                                    "childrenWeight": 0.0861,
                                                                    "totalQuantityWeight": 0.0861,
                                                                    "singleQuantityMcf": 0.445998,
                                                                    "mcf": 0.445998,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.445998,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 3379,
                                                                            "parentRowNumber": 3378,
                                                                            "articleId": "1110284",
                                                                            "description": "1110284-00-Flachstahl",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0861,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0861,
                                                                            "singleQuantityMcf": 0.445998,
                                                                            "mcf": 0.445998,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.445998,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 3380,
                                                                    "parentRowNumber": 3227,
                                                                    "articleId": "47230776",
                                                                    "description": "47230776-00-Traverse",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.2567,
                                                                    "childrenWeight": 0.2567,
                                                                    "totalQuantityWeight": 0.2567,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 3381,
                                                                            "parentRowNumber": 3380,
                                                                            "articleId": "119278",
                                                                            "description": "119278-00-Vierkantstange",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.2567,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.2567,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                }
                                                            ]
                                                        }
                                                    ]
                                                }
                                            ]
                                        },
                                        {
                                            "rowNumber": 3382,
                                            "parentRowNumber": 3160,
                                            "articleId": "33847",
                                            "description": "33847-20NA06-00-Schnittstelle",
                                            "hierarchy": 4,
                                            "quantity": 1,
                                            "weight": 16.813799999999983,
                                            "childrenWeight": 16.813799999999983,
                                            "totalQuantityWeight": 16.813799999999983,
                                            "singleQuantityMcf": 6.612741999999992,
                                            "mcf": 6.612741999999992,
                                            "singleQuantityLcf": 0,
                                            "lcf": 0,
                                            "totalQuantityMcf": 6.612741999999992,
                                            "expandable": false,
                                            "children": [
                                                {
                                                    "rowNumber": 3383,
                                                    "parentRowNumber": 3382,
                                                    "articleId": "MM0000009143",
                                                    "description": "MM0000009143-00-Schnittstelle",
                                                    "hierarchy": 5,
                                                    "quantity": 1,
                                                    "weight": 16.813799999999983,
                                                    "childrenWeight": 16.813799999999983,
                                                    "totalQuantityWeight": 16.813799999999983,
                                                    "singleQuantityMcf": 6.612741999999992,
                                                    "mcf": 6.612741999999992,
                                                    "singleQuantityLcf": 0,
                                                    "lcf": 0,
                                                    "totalQuantityMcf": 6.612741999999992,
                                                    "expandable": false,
                                                    "children": [
                                                        {
                                                            "rowNumber": 3384,
                                                            "parentRowNumber": 3383,
                                                            "articleId": "20120435",
                                                            "description": "20120435-00-Sechskantschraube",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0044,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0044,
                                                            "singleQuantityMcf": 0.022792,
                                                            "mcf": 0.022792,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.022792,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 3385,
                                                            "parentRowNumber": 3383,
                                                            "articleId": "20120435",
                                                            "description": "20120435-00-Sechskantschraube",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0044,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0044,
                                                            "singleQuantityMcf": 0.022792,
                                                            "mcf": 0.022792,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.022792,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 3386,
                                                            "parentRowNumber": 3383,
                                                            "articleId": "20220412",
                                                            "description": "20220412-00-Zylinderschraube",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0019,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0019,
                                                            "singleQuantityMcf": 0.009842,
                                                            "mcf": 0.009842,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.009842,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 3387,
                                                            "parentRowNumber": 3383,
                                                            "articleId": "20220412",
                                                            "description": "20220412-00-Zylinderschraube",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0019,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0019,
                                                            "singleQuantityMcf": 0.009842,
                                                            "mcf": 0.009842,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.009842,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 3388,
                                                            "parentRowNumber": 3383,
                                                            "articleId": "20220412",
                                                            "description": "20220412-00-Zylinderschraube",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0019,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0019,
                                                            "singleQuantityMcf": 0.009842,
                                                            "mcf": 0.009842,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.009842,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 3389,
                                                            "parentRowNumber": 3383,
                                                            "articleId": "20220412",
                                                            "description": "20220412-00-Zylinderschraube",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0019,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0019,
                                                            "singleQuantityMcf": 0.009842,
                                                            "mcf": 0.009842,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.009842,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 3390,
                                                            "parentRowNumber": 3383,
                                                            "articleId": "20220412",
                                                            "description": "20220412-00-Zylinderschraube",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0019,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0019,
                                                            "singleQuantityMcf": 0.009842,
                                                            "mcf": 0.009842,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.009842,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 3391,
                                                            "parentRowNumber": 3383,
                                                            "articleId": "20220412",
                                                            "description": "20220412-00-Zylinderschraube",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0019,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0019,
                                                            "singleQuantityMcf": 0.009842,
                                                            "mcf": 0.009842,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.009842,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 3392,
                                                            "parentRowNumber": 3383,
                                                            "articleId": "20220412",
                                                            "description": "20220412-00-Zylinderschraube",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0019,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0019,
                                                            "singleQuantityMcf": 0.009842,
                                                            "mcf": 0.009842,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.009842,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 3393,
                                                            "parentRowNumber": 3383,
                                                            "articleId": "20220412",
                                                            "description": "20220412-00-Zylinderschraube",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0019,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0019,
                                                            "singleQuantityMcf": 0.009842,
                                                            "mcf": 0.009842,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.009842,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 3394,
                                                            "parentRowNumber": 3383,
                                                            "articleId": "208204",
                                                            "description": "208204-00-Scheibe",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0003,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0003,
                                                            "singleQuantityMcf": 0.0015539999999999998,
                                                            "mcf": 0.0015539999999999998,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.0015539999999999998,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 3395,
                                                            "parentRowNumber": 3383,
                                                            "articleId": "208204",
                                                            "description": "208204-00-Scheibe",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0003,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0003,
                                                            "singleQuantityMcf": 0.0015539999999999998,
                                                            "mcf": 0.0015539999999999998,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.0015539999999999998,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 3396,
                                                            "parentRowNumber": 3383,
                                                            "articleId": "47234410",
                                                            "description": "47234410-00-Schutz",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.1031,
                                                            "childrenWeight": 0.1031,
                                                            "totalQuantityWeight": 0.1031,
                                                            "singleQuantityMcf": 0.32992,
                                                            "mcf": 0.32992,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.32992,
                                                            "expandable": false,
                                                            "children": [
                                                                {
                                                                    "rowNumber": 3397,
                                                                    "parentRowNumber": 3396,
                                                                    "articleId": "162541",
                                                                    "description": "162541-01-Platte",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.1031,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.1031,
                                                                    "singleQuantityMcf": 0.32992,
                                                                    "mcf": 0.32992,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.32992,
                                                                    "expandable": false,
                                                                    "children": []
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            "rowNumber": 3398,
                                                            "parentRowNumber": 3383,
                                                            "articleId": "47234441",
                                                            "description": "47234441-00-Schnittstelle",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 14.070699999999988,
                                                            "childrenWeight": 14.070699999999988,
                                                            "totalQuantityWeight": 14.070699999999988,
                                                            "singleQuantityMcf": 6.155393999999994,
                                                            "mcf": 6.155393999999994,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 6.155393999999994,
                                                            "expandable": false,
                                                            "children": [
                                                                {
                                                                    "rowNumber": 3399,
                                                                    "parentRowNumber": 3398,
                                                                    "articleId": "20120408",
                                                                    "description": "20120408-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0017,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0017,
                                                                    "singleQuantityMcf": 0.008806,
                                                                    "mcf": 0.008806,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.008806,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3400,
                                                                    "parentRowNumber": 3398,
                                                                    "articleId": "20120408",
                                                                    "description": "20120408-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0017,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0017,
                                                                    "singleQuantityMcf": 0.008806,
                                                                    "mcf": 0.008806,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.008806,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3401,
                                                                    "parentRowNumber": 3398,
                                                                    "articleId": "20120408",
                                                                    "description": "20120408-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0017,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0017,
                                                                    "singleQuantityMcf": 0.008806,
                                                                    "mcf": 0.008806,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.008806,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3402,
                                                                    "parentRowNumber": 3398,
                                                                    "articleId": "20120408",
                                                                    "description": "20120408-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0017,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0017,
                                                                    "singleQuantityMcf": 0.008806,
                                                                    "mcf": 0.008806,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.008806,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3403,
                                                                    "parentRowNumber": 3398,
                                                                    "articleId": "20120410",
                                                                    "description": "20120410-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0019,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0019,
                                                                    "singleQuantityMcf": 0.009842,
                                                                    "mcf": 0.009842,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.009842,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3404,
                                                                    "parentRowNumber": 3398,
                                                                    "articleId": "20120410",
                                                                    "description": "20120410-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0019,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0019,
                                                                    "singleQuantityMcf": 0.009842,
                                                                    "mcf": 0.009842,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.009842,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3405,
                                                                    "parentRowNumber": 3398,
                                                                    "articleId": "20120610",
                                                                    "description": "20120610-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0049,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0049,
                                                                    "singleQuantityMcf": 0.025382,
                                                                    "mcf": 0.025382,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.025382,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3406,
                                                                    "parentRowNumber": 3398,
                                                                    "articleId": "20120610",
                                                                    "description": "20120610-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0049,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0049,
                                                                    "singleQuantityMcf": 0.025382,
                                                                    "mcf": 0.025382,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.025382,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3407,
                                                                    "parentRowNumber": 3398,
                                                                    "articleId": "20120610",
                                                                    "description": "20120610-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0049,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0049,
                                                                    "singleQuantityMcf": 0.025382,
                                                                    "mcf": 0.025382,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.025382,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3408,
                                                                    "parentRowNumber": 3398,
                                                                    "articleId": "20120610",
                                                                    "description": "20120610-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0049,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0049,
                                                                    "singleQuantityMcf": 0.025382,
                                                                    "mcf": 0.025382,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.025382,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3409,
                                                                    "parentRowNumber": 3398,
                                                                    "articleId": "20120610",
                                                                    "description": "20120610-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0049,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0049,
                                                                    "singleQuantityMcf": 0.025382,
                                                                    "mcf": 0.025382,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.025382,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3410,
                                                                    "parentRowNumber": 3398,
                                                                    "articleId": "20120610",
                                                                    "description": "20120610-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0049,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0049,
                                                                    "singleQuantityMcf": 0.025382,
                                                                    "mcf": 0.025382,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.025382,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3411,
                                                                    "parentRowNumber": 3398,
                                                                    "articleId": "20120635",
                                                                    "description": "20120635-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0105,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0105,
                                                                    "singleQuantityMcf": 0.05439,
                                                                    "mcf": 0.05439,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.05439,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3412,
                                                                    "parentRowNumber": 3398,
                                                                    "articleId": "20120635",
                                                                    "description": "20120635-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0105,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0105,
                                                                    "singleQuantityMcf": 0.05439,
                                                                    "mcf": 0.05439,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.05439,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3413,
                                                                    "parentRowNumber": 3398,
                                                                    "articleId": "20220510",
                                                                    "description": "20220510-00-Zylinderschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0028,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0028,
                                                                    "singleQuantityMcf": 0.014504,
                                                                    "mcf": 0.014504,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.014504,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3414,
                                                                    "parentRowNumber": 3398,
                                                                    "articleId": "20220625",
                                                                    "description": "20220625-01-Zylinderschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0075,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0075,
                                                                    "singleQuantityMcf": 0.03885,
                                                                    "mcf": 0.03885,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.03885,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3415,
                                                                    "parentRowNumber": 3398,
                                                                    "articleId": "205206",
                                                                    "description": "205206-00-Sechskantmutter",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0026,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0026,
                                                                    "singleQuantityMcf": 0.013468,
                                                                    "mcf": 0.013468,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.013468,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3416,
                                                                    "parentRowNumber": 3398,
                                                                    "articleId": "208204",
                                                                    "description": "208204-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0003,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0003,
                                                                    "singleQuantityMcf": 0.0015539999999999998,
                                                                    "mcf": 0.0015539999999999998,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.0015539999999999998,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3417,
                                                                    "parentRowNumber": 3398,
                                                                    "articleId": "208204",
                                                                    "description": "208204-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0003,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0003,
                                                                    "singleQuantityMcf": 0.0015539999999999998,
                                                                    "mcf": 0.0015539999999999998,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.0015539999999999998,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3418,
                                                                    "parentRowNumber": 3398,
                                                                    "articleId": "208206",
                                                                    "description": "208206-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0009,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0009,
                                                                    "singleQuantityMcf": 0.004662,
                                                                    "mcf": 0.004662,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.004662,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3419,
                                                                    "parentRowNumber": 3398,
                                                                    "articleId": "208206",
                                                                    "description": "208206-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0009,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0009,
                                                                    "singleQuantityMcf": 0.004662,
                                                                    "mcf": 0.004662,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.004662,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3420,
                                                                    "parentRowNumber": 3398,
                                                                    "articleId": "208206",
                                                                    "description": "208206-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0009,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0009,
                                                                    "singleQuantityMcf": 0.004662,
                                                                    "mcf": 0.004662,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.004662,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3421,
                                                                    "parentRowNumber": 3398,
                                                                    "articleId": "208206",
                                                                    "description": "208206-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0009,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0009,
                                                                    "singleQuantityMcf": 0.004662,
                                                                    "mcf": 0.004662,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.004662,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3422,
                                                                    "parentRowNumber": 3398,
                                                                    "articleId": "208206",
                                                                    "description": "208206-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0009,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0009,
                                                                    "singleQuantityMcf": 0.004662,
                                                                    "mcf": 0.004662,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.004662,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3423,
                                                                    "parentRowNumber": 3398,
                                                                    "articleId": "208206",
                                                                    "description": "208206-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0009,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0009,
                                                                    "singleQuantityMcf": 0.004662,
                                                                    "mcf": 0.004662,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.004662,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3424,
                                                                    "parentRowNumber": 3398,
                                                                    "articleId": "208206",
                                                                    "description": "208206-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0009,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0009,
                                                                    "singleQuantityMcf": 0.004662,
                                                                    "mcf": 0.004662,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.004662,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3425,
                                                                    "parentRowNumber": 3398,
                                                                    "articleId": "208206",
                                                                    "description": "208206-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0009,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0009,
                                                                    "singleQuantityMcf": 0.004662,
                                                                    "mcf": 0.004662,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.004662,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3426,
                                                                    "parentRowNumber": 3398,
                                                                    "articleId": "208210",
                                                                    "description": "208210-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0033,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0033,
                                                                    "singleQuantityMcf": 0.017093999999999998,
                                                                    "mcf": 0.017093999999999998,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.017093999999999998,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3427,
                                                                    "parentRowNumber": 3398,
                                                                    "articleId": "208210",
                                                                    "description": "208210-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0033,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0033,
                                                                    "singleQuantityMcf": 0.017093999999999998,
                                                                    "mcf": 0.017093999999999998,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.017093999999999998,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3428,
                                                                    "parentRowNumber": 3398,
                                                                    "articleId": "208210",
                                                                    "description": "208210-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0033,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0033,
                                                                    "singleQuantityMcf": 0.017093999999999998,
                                                                    "mcf": 0.017093999999999998,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.017093999999999998,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3429,
                                                                    "parentRowNumber": 3398,
                                                                    "articleId": "208210",
                                                                    "description": "208210-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0033,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0033,
                                                                    "singleQuantityMcf": 0.017093999999999998,
                                                                    "mcf": 0.017093999999999998,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.017093999999999998,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3430,
                                                                    "parentRowNumber": 3398,
                                                                    "articleId": "208210",
                                                                    "description": "208210-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0033,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0033,
                                                                    "singleQuantityMcf": 0.017093999999999998,
                                                                    "mcf": 0.017093999999999998,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.017093999999999998,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3431,
                                                                    "parentRowNumber": 3398,
                                                                    "articleId": "208210",
                                                                    "description": "208210-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0033,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0033,
                                                                    "singleQuantityMcf": 0.017093999999999998,
                                                                    "mcf": 0.017093999999999998,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.017093999999999998,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3432,
                                                                    "parentRowNumber": 3398,
                                                                    "articleId": "208404",
                                                                    "description": "208404-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0008,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0008,
                                                                    "singleQuantityMcf": 0.004144,
                                                                    "mcf": 0.004144,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.004144,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3433,
                                                                    "parentRowNumber": 3398,
                                                                    "articleId": "208404",
                                                                    "description": "208404-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0008,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0008,
                                                                    "singleQuantityMcf": 0.004144,
                                                                    "mcf": 0.004144,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.004144,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3434,
                                                                    "parentRowNumber": 3398,
                                                                    "articleId": "208404",
                                                                    "description": "208404-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0008,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0008,
                                                                    "singleQuantityMcf": 0.004144,
                                                                    "mcf": 0.004144,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.004144,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3435,
                                                                    "parentRowNumber": 3398,
                                                                    "articleId": "208404",
                                                                    "description": "208404-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0008,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0008,
                                                                    "singleQuantityMcf": 0.004144,
                                                                    "mcf": 0.004144,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.004144,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3436,
                                                                    "parentRowNumber": 3398,
                                                                    "articleId": "226210125",
                                                                    "description": "226210125-00-Sechskantmutter",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0075,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0075,
                                                                    "singleQuantityMcf": 0.03885,
                                                                    "mcf": 0.03885,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.03885,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3437,
                                                                    "parentRowNumber": 3398,
                                                                    "articleId": "226210125",
                                                                    "description": "226210125-00-Sechskantmutter",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0075,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0075,
                                                                    "singleQuantityMcf": 0.03885,
                                                                    "mcf": 0.03885,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.03885,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3438,
                                                                    "parentRowNumber": 3398,
                                                                    "articleId": "226210125",
                                                                    "description": "226210125-00-Sechskantmutter",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0075,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0075,
                                                                    "singleQuantityMcf": 0.03885,
                                                                    "mcf": 0.03885,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.03885,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3439,
                                                                    "parentRowNumber": 3398,
                                                                    "articleId": "226210125",
                                                                    "description": "226210125-00-Sechskantmutter",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0075,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0075,
                                                                    "singleQuantityMcf": 0.03885,
                                                                    "mcf": 0.03885,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.03885,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3440,
                                                                    "parentRowNumber": 3398,
                                                                    "articleId": "226210125",
                                                                    "description": "226210125-00-Sechskantmutter",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0075,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0075,
                                                                    "singleQuantityMcf": 0.03885,
                                                                    "mcf": 0.03885,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.03885,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3441,
                                                                    "parentRowNumber": 3398,
                                                                    "articleId": "226210125",
                                                                    "description": "226210125-00-Sechskantmutter",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0075,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0075,
                                                                    "singleQuantityMcf": 0.03885,
                                                                    "mcf": 0.03885,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.03885,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3442,
                                                                    "parentRowNumber": 3398,
                                                                    "articleId": "28925011001",
                                                                    "description": "28925011001-02-Fuehrungsrolle",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.1366,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.1366,
                                                                    "singleQuantityMcf": 0.707588,
                                                                    "mcf": 0.707588,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.707588,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3443,
                                                                    "parentRowNumber": 3398,
                                                                    "articleId": "28925011001",
                                                                    "description": "28925011001-02-Fuehrungsrolle",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.1366,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.1366,
                                                                    "singleQuantityMcf": 0.707588,
                                                                    "mcf": 0.707588,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.707588,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3444,
                                                                    "parentRowNumber": 3398,
                                                                    "articleId": "28925011001",
                                                                    "description": "28925011001-02-Fuehrungsrolle",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.1366,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.1366,
                                                                    "singleQuantityMcf": 0.707588,
                                                                    "mcf": 0.707588,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.707588,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3445,
                                                                    "parentRowNumber": 3398,
                                                                    "articleId": "28925011002",
                                                                    "description": "28925011002-02-Fuehrungsrolle",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.1366,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.1366,
                                                                    "singleQuantityMcf": 0.707588,
                                                                    "mcf": 0.707588,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.707588,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3446,
                                                                    "parentRowNumber": 3398,
                                                                    "articleId": "28925011002",
                                                                    "description": "28925011002-02-Fuehrungsrolle",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.1366,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.1366,
                                                                    "singleQuantityMcf": 0.707588,
                                                                    "mcf": 0.707588,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.707588,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3447,
                                                                    "parentRowNumber": 3398,
                                                                    "articleId": "28925011002",
                                                                    "description": "28925011002-02-Fuehrungsrolle",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.1366,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.1366,
                                                                    "singleQuantityMcf": 0.707588,
                                                                    "mcf": 0.707588,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.707588,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3448,
                                                                    "parentRowNumber": 3398,
                                                                    "articleId": "292261900",
                                                                    "description": "292261900-00-Radial-Rillenkugellager",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0126,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0126,
                                                                    "singleQuantityMcf": 0.06526799999999999,
                                                                    "mcf": 0.06526799999999999,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.06526799999999999,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3449,
                                                                    "parentRowNumber": 3398,
                                                                    "articleId": "292261900",
                                                                    "description": "292261900-00-Radial-Rillenkugellager",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0126,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0126,
                                                                    "singleQuantityMcf": 0.06526799999999999,
                                                                    "mcf": 0.06526799999999999,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.06526799999999999,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3450,
                                                                    "parentRowNumber": 3398,
                                                                    "articleId": "292261900",
                                                                    "description": "292261900-00-Radial-Rillenkugellager",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0126,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0126,
                                                                    "singleQuantityMcf": 0.06526799999999999,
                                                                    "mcf": 0.06526799999999999,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.06526799999999999,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3451,
                                                                    "parentRowNumber": 3398,
                                                                    "articleId": "292261900",
                                                                    "description": "292261900-00-Radial-Rillenkugellager",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0126,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0126,
                                                                    "singleQuantityMcf": 0.06526799999999999,
                                                                    "mcf": 0.06526799999999999,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.06526799999999999,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3452,
                                                                    "parentRowNumber": 3398,
                                                                    "articleId": "32312101",
                                                                    "description": "32312101-00-Buegelspanner",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.3759,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.3759,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3453,
                                                                    "parentRowNumber": 3398,
                                                                    "articleId": "47157305",
                                                                    "description": "47157305-00-Anschlussstueck",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 12.2733,
                                                                    "childrenWeight": 12.2733,
                                                                    "totalQuantityWeight": 12.2733,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 3454,
                                                                            "parentRowNumber": 3453,
                                                                            "articleId": "141722",
                                                                            "description": "141722-00-Platte",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 12.2733,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 12.2733,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 3455,
                                                                    "parentRowNumber": 3398,
                                                                    "articleId": "47233746",
                                                                    "description": "47233746-00-Exzenterbolzen",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0232,
                                                                    "childrenWeight": 0.0232,
                                                                    "totalQuantityWeight": 0.0232,
                                                                    "singleQuantityMcf": 0.120176,
                                                                    "mcf": 0.120176,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.120176,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 3456,
                                                                            "parentRowNumber": 3455,
                                                                            "articleId": "1260015",
                                                                            "description": "1260015-00-Rundstahl",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0232,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0232,
                                                                            "singleQuantityMcf": 0.120176,
                                                                            "mcf": 0.120176,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.120176,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 3457,
                                                                    "parentRowNumber": 3398,
                                                                    "articleId": "47233746",
                                                                    "description": "47233746-00-Exzenterbolzen",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0232,
                                                                    "childrenWeight": 0.0232,
                                                                    "totalQuantityWeight": 0.0232,
                                                                    "singleQuantityMcf": 0.120176,
                                                                    "mcf": 0.120176,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.120176,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 3458,
                                                                            "parentRowNumber": 3457,
                                                                            "articleId": "1260015",
                                                                            "description": "1260015-00-Rundstahl",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0232,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0232,
                                                                            "singleQuantityMcf": 0.120176,
                                                                            "mcf": 0.120176,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.120176,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 3459,
                                                                    "parentRowNumber": 3398,
                                                                    "articleId": "47233746",
                                                                    "description": "47233746-00-Exzenterbolzen",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0232,
                                                                    "childrenWeight": 0.0232,
                                                                    "totalQuantityWeight": 0.0232,
                                                                    "singleQuantityMcf": 0.120176,
                                                                    "mcf": 0.120176,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.120176,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 3460,
                                                                            "parentRowNumber": 3459,
                                                                            "articleId": "1260015",
                                                                            "description": "1260015-00-Rundstahl",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0232,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0232,
                                                                            "singleQuantityMcf": 0.120176,
                                                                            "mcf": 0.120176,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.120176,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 3461,
                                                                    "parentRowNumber": 3398,
                                                                    "articleId": "47233746",
                                                                    "description": "47233746-00-Exzenterbolzen",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0232,
                                                                    "childrenWeight": 0.0232,
                                                                    "totalQuantityWeight": 0.0232,
                                                                    "singleQuantityMcf": 0.120176,
                                                                    "mcf": 0.120176,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.120176,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 3462,
                                                                            "parentRowNumber": 3461,
                                                                            "articleId": "1260015",
                                                                            "description": "1260015-00-Rundstahl",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0232,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0232,
                                                                            "singleQuantityMcf": 0.120176,
                                                                            "mcf": 0.120176,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.120176,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 3463,
                                                                    "parentRowNumber": 3398,
                                                                    "articleId": "47234104",
                                                                    "description": "47234104-00-Halter",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.2276,
                                                                    "childrenWeight": 0.2276,
                                                                    "totalQuantityWeight": 0.2276,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 3464,
                                                                            "parentRowNumber": 3463,
                                                                            "articleId": "119343",
                                                                            "description": "119343-00-Flachstange",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.2276,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.2276,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 3465,
                                                                    "parentRowNumber": 3398,
                                                                    "articleId": "47234322",
                                                                    "description": "47234322-00-Blech",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0758,
                                                                    "childrenWeight": 0.0758,
                                                                    "totalQuantityWeight": 0.0758,
                                                                    "singleQuantityMcf": 0.392644,
                                                                    "mcf": 0.392644,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.392644,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 3466,
                                                                            "parentRowNumber": 3465,
                                                                            "articleId": "142613",
                                                                            "description": "142613-00-Edelstahlblech",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0758,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0758,
                                                                            "singleQuantityMcf": 0.392644,
                                                                            "mcf": 0.392644,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.392644,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 3467,
                                                                    "parentRowNumber": 3398,
                                                                    "articleId": "575720",
                                                                    "description": "575720-00-Schelle",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0028,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0028,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3468,
                                                                    "parentRowNumber": 3398,
                                                                    "articleId": "575720",
                                                                    "description": "575720-00-Schelle",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0028,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0028,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": []
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            "rowNumber": 3469,
                                                            "parentRowNumber": 3383,
                                                            "articleId": "54561220",
                                                            "description": "54561220-03-Anbaugehäuse",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0,
                                                            "singleQuantityMcf": 0,
                                                            "mcf": 0,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 3470,
                                                            "parentRowNumber": 3383,
                                                            "articleId": "54561220",
                                                            "description": "54561220-03-Anbaugehäuse",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0,
                                                            "singleQuantityMcf": 0,
                                                            "mcf": 0,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 3471,
                                                            "parentRowNumber": 3383,
                                                            "articleId": "54561221",
                                                            "description": "54561221-01-Tüllengehäuse",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.6328,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.6328,
                                                            "singleQuantityMcf": 0,
                                                            "mcf": 0,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 3472,
                                                            "parentRowNumber": 3383,
                                                            "articleId": "54561221",
                                                            "description": "54561221-01-Tüllengehäuse",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.6328,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.6328,
                                                            "singleQuantityMcf": 0,
                                                            "mcf": 0,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 3473,
                                                            "parentRowNumber": 3383,
                                                            "articleId": "54561223",
                                                            "description": "54561223-01-Schutzdeckel",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.1831,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.1831,
                                                            "singleQuantityMcf": 0,
                                                            "mcf": 0,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 3474,
                                                            "parentRowNumber": 3383,
                                                            "articleId": "56651135",
                                                            "description": "56651135-06-Schlauchverschraub.",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.2549,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.2549,
                                                            "singleQuantityMcf": 0,
                                                            "mcf": 0,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 3475,
                                                            "parentRowNumber": 3383,
                                                            "articleId": "56651135",
                                                            "description": "56651135-06-Schlauchverschraub.",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.2549,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.2549,
                                                            "singleQuantityMcf": 0,
                                                            "mcf": 0,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 3476,
                                                            "parentRowNumber": 3383,
                                                            "articleId": "56651135",
                                                            "description": "56651135-06-Schlauchverschraub.",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.2549,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.2549,
                                                            "singleQuantityMcf": 0,
                                                            "mcf": 0,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 3477,
                                                            "parentRowNumber": 3383,
                                                            "articleId": "57549160",
                                                            "description": "57549160-01-Erweiterung",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.058,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.058,
                                                            "singleQuantityMcf": 0,
                                                            "mcf": 0,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 3478,
                                                            "parentRowNumber": 3383,
                                                            "articleId": "57549160",
                                                            "description": "57549160-01-Erweiterung",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.058,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.058,
                                                            "singleQuantityMcf": 0,
                                                            "mcf": 0,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 3479,
                                                            "parentRowNumber": 3383,
                                                            "articleId": "57550131",
                                                            "description": "57550131-01-Verschraubung",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0943,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0943,
                                                            "singleQuantityMcf": 0,
                                                            "mcf": 0,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 3480,
                                                            "parentRowNumber": 3383,
                                                            "articleId": "57550131",
                                                            "description": "57550131-01-Verschraubung",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0943,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0943,
                                                            "singleQuantityMcf": 0,
                                                            "mcf": 0,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 3481,
                                                            "parentRowNumber": 3383,
                                                            "articleId": "57550241",
                                                            "description": "57550241-01-Verschraubungskörper",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0974,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0974,
                                                            "singleQuantityMcf": 0,
                                                            "mcf": 0,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0,
                                                            "expandable": false,
                                                            "children": []
                                                        }
                                                    ]
                                                }
                                            ]
                                        },
                                        {
                                            "rowNumber": 3482,
                                            "parentRowNumber": 3160,
                                            "articleId": "33847",
                                            "description": "33847-20NA10-00-Grundeinheit",
                                            "hierarchy": 4,
                                            "quantity": 1,
                                            "weight": 29.596700000000016,
                                            "childrenWeight": 29.596700000000016,
                                            "totalQuantityWeight": 29.596700000000016,
                                            "singleQuantityMcf": 15.890618,
                                            "mcf": 15.890618,
                                            "singleQuantityLcf": 0,
                                            "lcf": 0,
                                            "totalQuantityMcf": 15.890618,
                                            "expandable": false,
                                            "children": [
                                                {
                                                    "rowNumber": 3483,
                                                    "parentRowNumber": 3482,
                                                    "articleId": "MM0000007412",
                                                    "description": "MM0000007412-00-Grundeinheit",
                                                    "hierarchy": 5,
                                                    "quantity": 1,
                                                    "weight": 29.596700000000016,
                                                    "childrenWeight": 29.596700000000016,
                                                    "totalQuantityWeight": 29.596700000000016,
                                                    "singleQuantityMcf": 15.890618,
                                                    "mcf": 15.890618,
                                                    "singleQuantityLcf": 0,
                                                    "lcf": 0,
                                                    "totalQuantityMcf": 15.890618,
                                                    "expandable": false,
                                                    "children": [
                                                        {
                                                            "rowNumber": 3484,
                                                            "parentRowNumber": 3483,
                                                            "articleId": "46392100",
                                                            "description": "46392100-00-Halter",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.3699,
                                                            "childrenWeight": 0.3699,
                                                            "totalQuantityWeight": 0.3699,
                                                            "singleQuantityMcf": 1.916082,
                                                            "mcf": 1.916082,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 1.916082,
                                                            "expandable": false,
                                                            "children": [
                                                                {
                                                                    "rowNumber": 3485,
                                                                    "parentRowNumber": 3484,
                                                                    "articleId": "46248899",
                                                                    "description": "46248899-01-Bolzen",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.3699,
                                                                    "childrenWeight": 0.3699,
                                                                    "totalQuantityWeight": 0.3699,
                                                                    "singleQuantityMcf": 1.916082,
                                                                    "mcf": 1.916082,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 1.916082,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 3486,
                                                                            "parentRowNumber": 3485,
                                                                            "articleId": "1260030",
                                                                            "description": "1260030-00-Rundstahl",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.3699,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.3699,
                                                                            "singleQuantityMcf": 1.916082,
                                                                            "mcf": 1.916082,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 1.916082,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            "rowNumber": 3487,
                                                            "parentRowNumber": 3483,
                                                            "articleId": "47221014",
                                                            "description": "47221014-00-Grundaufbau",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 26.68560000000001,
                                                            "childrenWeight": 26.68560000000001,
                                                            "totalQuantityWeight": 26.68560000000001,
                                                            "singleQuantityMcf": 9.209544,
                                                            "mcf": 9.209544,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 9.209544,
                                                            "expandable": false,
                                                            "children": [
                                                                {
                                                                    "rowNumber": 3488,
                                                                    "parentRowNumber": 3487,
                                                                    "articleId": "20120406",
                                                                    "description": "20120406-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0015,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0015,
                                                                    "singleQuantityMcf": 0.00777,
                                                                    "mcf": 0.00777,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.00777,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3489,
                                                                    "parentRowNumber": 3487,
                                                                    "articleId": "20120406",
                                                                    "description": "20120406-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0015,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0015,
                                                                    "singleQuantityMcf": 0.00777,
                                                                    "mcf": 0.00777,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.00777,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3490,
                                                                    "parentRowNumber": 3487,
                                                                    "articleId": "20120406",
                                                                    "description": "20120406-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0015,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0015,
                                                                    "singleQuantityMcf": 0.00777,
                                                                    "mcf": 0.00777,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.00777,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3491,
                                                                    "parentRowNumber": 3487,
                                                                    "articleId": "20120406",
                                                                    "description": "20120406-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0015,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0015,
                                                                    "singleQuantityMcf": 0.00777,
                                                                    "mcf": 0.00777,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.00777,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3492,
                                                                    "parentRowNumber": 3487,
                                                                    "articleId": "20120406",
                                                                    "description": "20120406-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0015,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0015,
                                                                    "singleQuantityMcf": 0.00777,
                                                                    "mcf": 0.00777,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.00777,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3493,
                                                                    "parentRowNumber": 3487,
                                                                    "articleId": "20120406",
                                                                    "description": "20120406-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0015,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0015,
                                                                    "singleQuantityMcf": 0.00777,
                                                                    "mcf": 0.00777,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.00777,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3494,
                                                                    "parentRowNumber": 3487,
                                                                    "articleId": "20120410",
                                                                    "description": "20120410-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0019,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0019,
                                                                    "singleQuantityMcf": 0.009842,
                                                                    "mcf": 0.009842,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.009842,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3495,
                                                                    "parentRowNumber": 3487,
                                                                    "articleId": "20120410",
                                                                    "description": "20120410-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0019,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0019,
                                                                    "singleQuantityMcf": 0.009842,
                                                                    "mcf": 0.009842,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.009842,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3496,
                                                                    "parentRowNumber": 3487,
                                                                    "articleId": "20120410",
                                                                    "description": "20120410-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0019,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0019,
                                                                    "singleQuantityMcf": 0.009842,
                                                                    "mcf": 0.009842,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.009842,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3497,
                                                                    "parentRowNumber": 3487,
                                                                    "articleId": "20120410",
                                                                    "description": "20120410-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0019,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0019,
                                                                    "singleQuantityMcf": 0.009842,
                                                                    "mcf": 0.009842,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.009842,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3498,
                                                                    "parentRowNumber": 3487,
                                                                    "articleId": "20120608",
                                                                    "description": "20120608-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0044,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0044,
                                                                    "singleQuantityMcf": 0.022792,
                                                                    "mcf": 0.022792,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.022792,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3499,
                                                                    "parentRowNumber": 3487,
                                                                    "articleId": "20120608",
                                                                    "description": "20120608-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0044,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0044,
                                                                    "singleQuantityMcf": 0.022792,
                                                                    "mcf": 0.022792,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.022792,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3500,
                                                                    "parentRowNumber": 3487,
                                                                    "articleId": "20120610",
                                                                    "description": "20120610-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0049,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0049,
                                                                    "singleQuantityMcf": 0.025382,
                                                                    "mcf": 0.025382,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.025382,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3501,
                                                                    "parentRowNumber": 3487,
                                                                    "articleId": "20120625",
                                                                    "description": "20120625-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0083,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0083,
                                                                    "singleQuantityMcf": 0.042994,
                                                                    "mcf": 0.042994,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.042994,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3502,
                                                                    "parentRowNumber": 3487,
                                                                    "articleId": "20120625",
                                                                    "description": "20120625-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0083,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0083,
                                                                    "singleQuantityMcf": 0.042994,
                                                                    "mcf": 0.042994,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.042994,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3503,
                                                                    "parentRowNumber": 3487,
                                                                    "articleId": "20120625",
                                                                    "description": "20120625-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0083,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0083,
                                                                    "singleQuantityMcf": 0.042994,
                                                                    "mcf": 0.042994,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.042994,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3504,
                                                                    "parentRowNumber": 3487,
                                                                    "articleId": "20120625",
                                                                    "description": "20120625-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0083,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0083,
                                                                    "singleQuantityMcf": 0.042994,
                                                                    "mcf": 0.042994,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.042994,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3505,
                                                                    "parentRowNumber": 3487,
                                                                    "articleId": "20120630",
                                                                    "description": "20120630-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0094,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0094,
                                                                    "singleQuantityMcf": 0.048692,
                                                                    "mcf": 0.048692,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.048692,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3506,
                                                                    "parentRowNumber": 3487,
                                                                    "articleId": "20120630",
                                                                    "description": "20120630-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0094,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0094,
                                                                    "singleQuantityMcf": 0.048692,
                                                                    "mcf": 0.048692,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.048692,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3507,
                                                                    "parentRowNumber": 3487,
                                                                    "articleId": "20120630",
                                                                    "description": "20120630-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0094,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0094,
                                                                    "singleQuantityMcf": 0.048692,
                                                                    "mcf": 0.048692,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.048692,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3508,
                                                                    "parentRowNumber": 3487,
                                                                    "articleId": "20120630",
                                                                    "description": "20120630-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0094,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0094,
                                                                    "singleQuantityMcf": 0.048692,
                                                                    "mcf": 0.048692,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.048692,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3509,
                                                                    "parentRowNumber": 3487,
                                                                    "articleId": "20120630",
                                                                    "description": "20120630-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0094,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0094,
                                                                    "singleQuantityMcf": 0.048692,
                                                                    "mcf": 0.048692,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.048692,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3510,
                                                                    "parentRowNumber": 3487,
                                                                    "articleId": "20120630",
                                                                    "description": "20120630-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0094,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0094,
                                                                    "singleQuantityMcf": 0.048692,
                                                                    "mcf": 0.048692,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.048692,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3511,
                                                                    "parentRowNumber": 3487,
                                                                    "articleId": "20120635",
                                                                    "description": "20120635-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0105,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0105,
                                                                    "singleQuantityMcf": 0.05439,
                                                                    "mcf": 0.05439,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.05439,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3512,
                                                                    "parentRowNumber": 3487,
                                                                    "articleId": "20120635",
                                                                    "description": "20120635-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0105,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0105,
                                                                    "singleQuantityMcf": 0.05439,
                                                                    "mcf": 0.05439,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.05439,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3513,
                                                                    "parentRowNumber": 3487,
                                                                    "articleId": "20120635",
                                                                    "description": "20120635-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0105,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0105,
                                                                    "singleQuantityMcf": 0.05439,
                                                                    "mcf": 0.05439,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.05439,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3514,
                                                                    "parentRowNumber": 3487,
                                                                    "articleId": "20120635",
                                                                    "description": "20120635-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0105,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0105,
                                                                    "singleQuantityMcf": 0.05439,
                                                                    "mcf": 0.05439,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.05439,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3515,
                                                                    "parentRowNumber": 3487,
                                                                    "articleId": "20120635",
                                                                    "description": "20120635-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0105,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0105,
                                                                    "singleQuantityMcf": 0.05439,
                                                                    "mcf": 0.05439,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.05439,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3516,
                                                                    "parentRowNumber": 3487,
                                                                    "articleId": "20120830",
                                                                    "description": "20120830-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0181,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0181,
                                                                    "singleQuantityMcf": 0.093758,
                                                                    "mcf": 0.093758,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.093758,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3517,
                                                                    "parentRowNumber": 3487,
                                                                    "articleId": "20120830",
                                                                    "description": "20120830-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0181,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0181,
                                                                    "singleQuantityMcf": 0.093758,
                                                                    "mcf": 0.093758,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.093758,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3518,
                                                                    "parentRowNumber": 3487,
                                                                    "articleId": "20120830",
                                                                    "description": "20120830-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0181,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0181,
                                                                    "singleQuantityMcf": 0.093758,
                                                                    "mcf": 0.093758,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.093758,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3519,
                                                                    "parentRowNumber": 3487,
                                                                    "articleId": "20120850",
                                                                    "description": "20120850-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0262,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0262,
                                                                    "singleQuantityMcf": 0.135716,
                                                                    "mcf": 0.135716,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.135716,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3520,
                                                                    "parentRowNumber": 3487,
                                                                    "articleId": "20220450",
                                                                    "description": "20220450-00-Zylinderschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0057,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0057,
                                                                    "singleQuantityMcf": 0.029526,
                                                                    "mcf": 0.029526,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.029526,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3521,
                                                                    "parentRowNumber": 3487,
                                                                    "articleId": "20220450",
                                                                    "description": "20220450-00-Zylinderschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0057,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0057,
                                                                    "singleQuantityMcf": 0.029526,
                                                                    "mcf": 0.029526,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.029526,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3522,
                                                                    "parentRowNumber": 3487,
                                                                    "articleId": "20220450",
                                                                    "description": "20220450-00-Zylinderschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0057,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0057,
                                                                    "singleQuantityMcf": 0.029526,
                                                                    "mcf": 0.029526,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.029526,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3523,
                                                                    "parentRowNumber": 3487,
                                                                    "articleId": "20220450",
                                                                    "description": "20220450-00-Zylinderschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0057,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0057,
                                                                    "singleQuantityMcf": 0.029526,
                                                                    "mcf": 0.029526,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.029526,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3524,
                                                                    "parentRowNumber": 3487,
                                                                    "articleId": "205208",
                                                                    "description": "205208-00-Sechskantmutter",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0056,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0056,
                                                                    "singleQuantityMcf": 0.029008,
                                                                    "mcf": 0.029008,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.029008,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3525,
                                                                    "parentRowNumber": 3487,
                                                                    "articleId": "208204",
                                                                    "description": "208204-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0003,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0003,
                                                                    "singleQuantityMcf": 0.0015539999999999998,
                                                                    "mcf": 0.0015539999999999998,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.0015539999999999998,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3526,
                                                                    "parentRowNumber": 3487,
                                                                    "articleId": "208204",
                                                                    "description": "208204-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0003,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0003,
                                                                    "singleQuantityMcf": 0.0015539999999999998,
                                                                    "mcf": 0.0015539999999999998,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.0015539999999999998,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3527,
                                                                    "parentRowNumber": 3487,
                                                                    "articleId": "208204",
                                                                    "description": "208204-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0003,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0003,
                                                                    "singleQuantityMcf": 0.0015539999999999998,
                                                                    "mcf": 0.0015539999999999998,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.0015539999999999998,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3528,
                                                                    "parentRowNumber": 3487,
                                                                    "articleId": "208204",
                                                                    "description": "208204-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0003,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0003,
                                                                    "singleQuantityMcf": 0.0015539999999999998,
                                                                    "mcf": 0.0015539999999999998,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.0015539999999999998,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3529,
                                                                    "parentRowNumber": 3487,
                                                                    "articleId": "208204",
                                                                    "description": "208204-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0003,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0003,
                                                                    "singleQuantityMcf": 0.0015539999999999998,
                                                                    "mcf": 0.0015539999999999998,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.0015539999999999998,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3530,
                                                                    "parentRowNumber": 3487,
                                                                    "articleId": "208204",
                                                                    "description": "208204-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0003,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0003,
                                                                    "singleQuantityMcf": 0.0015539999999999998,
                                                                    "mcf": 0.0015539999999999998,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.0015539999999999998,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3531,
                                                                    "parentRowNumber": 3487,
                                                                    "articleId": "208204",
                                                                    "description": "208204-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0003,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0003,
                                                                    "singleQuantityMcf": 0.0015539999999999998,
                                                                    "mcf": 0.0015539999999999998,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.0015539999999999998,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3532,
                                                                    "parentRowNumber": 3487,
                                                                    "articleId": "208204",
                                                                    "description": "208204-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0003,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0003,
                                                                    "singleQuantityMcf": 0.0015539999999999998,
                                                                    "mcf": 0.0015539999999999998,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.0015539999999999998,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3533,
                                                                    "parentRowNumber": 3487,
                                                                    "articleId": "208204",
                                                                    "description": "208204-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0003,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0003,
                                                                    "singleQuantityMcf": 0.0015539999999999998,
                                                                    "mcf": 0.0015539999999999998,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.0015539999999999998,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3534,
                                                                    "parentRowNumber": 3487,
                                                                    "articleId": "208204",
                                                                    "description": "208204-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0003,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0003,
                                                                    "singleQuantityMcf": 0.0015539999999999998,
                                                                    "mcf": 0.0015539999999999998,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.0015539999999999998,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3535,
                                                                    "parentRowNumber": 3487,
                                                                    "articleId": "208206",
                                                                    "description": "208206-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0009,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0009,
                                                                    "singleQuantityMcf": 0.004662,
                                                                    "mcf": 0.004662,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.004662,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3536,
                                                                    "parentRowNumber": 3487,
                                                                    "articleId": "208206",
                                                                    "description": "208206-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0009,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0009,
                                                                    "singleQuantityMcf": 0.004662,
                                                                    "mcf": 0.004662,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.004662,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3537,
                                                                    "parentRowNumber": 3487,
                                                                    "articleId": "208206",
                                                                    "description": "208206-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0009,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0009,
                                                                    "singleQuantityMcf": 0.004662,
                                                                    "mcf": 0.004662,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.004662,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3538,
                                                                    "parentRowNumber": 3487,
                                                                    "articleId": "208206",
                                                                    "description": "208206-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0009,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0009,
                                                                    "singleQuantityMcf": 0.004662,
                                                                    "mcf": 0.004662,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.004662,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3539,
                                                                    "parentRowNumber": 3487,
                                                                    "articleId": "208206",
                                                                    "description": "208206-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0009,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0009,
                                                                    "singleQuantityMcf": 0.004662,
                                                                    "mcf": 0.004662,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.004662,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3540,
                                                                    "parentRowNumber": 3487,
                                                                    "articleId": "208206",
                                                                    "description": "208206-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0009,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0009,
                                                                    "singleQuantityMcf": 0.004662,
                                                                    "mcf": 0.004662,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.004662,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3541,
                                                                    "parentRowNumber": 3487,
                                                                    "articleId": "208206",
                                                                    "description": "208206-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0009,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0009,
                                                                    "singleQuantityMcf": 0.004662,
                                                                    "mcf": 0.004662,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.004662,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3542,
                                                                    "parentRowNumber": 3487,
                                                                    "articleId": "208206",
                                                                    "description": "208206-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0009,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0009,
                                                                    "singleQuantityMcf": 0.004662,
                                                                    "mcf": 0.004662,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.004662,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3543,
                                                                    "parentRowNumber": 3487,
                                                                    "articleId": "208206",
                                                                    "description": "208206-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0009,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0009,
                                                                    "singleQuantityMcf": 0.004662,
                                                                    "mcf": 0.004662,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.004662,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3544,
                                                                    "parentRowNumber": 3487,
                                                                    "articleId": "208206",
                                                                    "description": "208206-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0009,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0009,
                                                                    "singleQuantityMcf": 0.004662,
                                                                    "mcf": 0.004662,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.004662,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3545,
                                                                    "parentRowNumber": 3487,
                                                                    "articleId": "208206",
                                                                    "description": "208206-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0009,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0009,
                                                                    "singleQuantityMcf": 0.004662,
                                                                    "mcf": 0.004662,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.004662,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3546,
                                                                    "parentRowNumber": 3487,
                                                                    "articleId": "208206",
                                                                    "description": "208206-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0009,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0009,
                                                                    "singleQuantityMcf": 0.004662,
                                                                    "mcf": 0.004662,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.004662,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3547,
                                                                    "parentRowNumber": 3487,
                                                                    "articleId": "208206",
                                                                    "description": "208206-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0009,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0009,
                                                                    "singleQuantityMcf": 0.004662,
                                                                    "mcf": 0.004662,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.004662,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3548,
                                                                    "parentRowNumber": 3487,
                                                                    "articleId": "208206",
                                                                    "description": "208206-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0009,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0009,
                                                                    "singleQuantityMcf": 0.004662,
                                                                    "mcf": 0.004662,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.004662,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3549,
                                                                    "parentRowNumber": 3487,
                                                                    "articleId": "208208",
                                                                    "description": "208208-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0017,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0017,
                                                                    "singleQuantityMcf": 0.008806,
                                                                    "mcf": 0.008806,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.008806,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3550,
                                                                    "parentRowNumber": 3487,
                                                                    "articleId": "208208",
                                                                    "description": "208208-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0017,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0017,
                                                                    "singleQuantityMcf": 0.008806,
                                                                    "mcf": 0.008806,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.008806,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3551,
                                                                    "parentRowNumber": 3487,
                                                                    "articleId": "208208",
                                                                    "description": "208208-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0017,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0017,
                                                                    "singleQuantityMcf": 0.008806,
                                                                    "mcf": 0.008806,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.008806,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3552,
                                                                    "parentRowNumber": 3487,
                                                                    "articleId": "208406",
                                                                    "description": "208406-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0028,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0028,
                                                                    "singleQuantityMcf": 0.014504,
                                                                    "mcf": 0.014504,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.014504,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3553,
                                                                    "parentRowNumber": 3487,
                                                                    "articleId": "208406",
                                                                    "description": "208406-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0028,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0028,
                                                                    "singleQuantityMcf": 0.014504,
                                                                    "mcf": 0.014504,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.014504,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3554,
                                                                    "parentRowNumber": 3487,
                                                                    "articleId": "208406",
                                                                    "description": "208406-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0028,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0028,
                                                                    "singleQuantityMcf": 0.014504,
                                                                    "mcf": 0.014504,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.014504,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3555,
                                                                    "parentRowNumber": 3487,
                                                                    "articleId": "28551108",
                                                                    "description": "28551108-00-Axial-Kugellager",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.128,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.128,
                                                                    "singleQuantityMcf": 0.219136,
                                                                    "mcf": 0.219136,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.219136,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3556,
                                                                    "parentRowNumber": 3487,
                                                                    "articleId": "305300",
                                                                    "description": "305300-00-Keilriemen",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3557,
                                                                    "parentRowNumber": 3487,
                                                                    "articleId": "305300_XPZ",
                                                                    "description": "305300_XPZ-00-Keilriemen",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.4583,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.4583,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3558,
                                                                    "parentRowNumber": 3487,
                                                                    "articleId": "46000043",
                                                                    "description": "46000043-00-Abdeckblech",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.8026,
                                                                    "childrenWeight": 0.8026,
                                                                    "totalQuantityWeight": 0.8026,
                                                                    "singleQuantityMcf": 4.157468,
                                                                    "mcf": 4.157468,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 4.157468,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 3559,
                                                                            "parentRowNumber": 3558,
                                                                            "articleId": "142603",
                                                                            "description": "142603-00-Edelstahlblech",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.8026,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.8026,
                                                                            "singleQuantityMcf": 4.157468,
                                                                            "mcf": 4.157468,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 4.157468,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 3560,
                                                                    "parentRowNumber": 3487,
                                                                    "articleId": "46000044",
                                                                    "description": "46000044-00-Leiste",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.6503,
                                                                    "childrenWeight": 0.6503,
                                                                    "totalQuantityWeight": 0.6503,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 3561,
                                                                            "parentRowNumber": 3560,
                                                                            "articleId": "141718",
                                                                            "description": "141718-00-Platte",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.6503,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.6503,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 3562,
                                                                    "parentRowNumber": 3487,
                                                                    "articleId": "46062214",
                                                                    "description": "46062214-02-Distanz",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0079,
                                                                    "childrenWeight": 0.0079,
                                                                    "totalQuantityWeight": 0.0079,
                                                                    "singleQuantityMcf": 0.040922,
                                                                    "mcf": 0.040922,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.040922,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 3563,
                                                                            "parentRowNumber": 3562,
                                                                            "articleId": "125506",
                                                                            "description": "125506-00-Rohr",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0079,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0079,
                                                                            "singleQuantityMcf": 0.040922,
                                                                            "mcf": 0.040922,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.040922,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 3564,
                                                                    "parentRowNumber": 3487,
                                                                    "articleId": "46062214",
                                                                    "description": "46062214-02-Distanz",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0079,
                                                                    "childrenWeight": 0.0079,
                                                                    "totalQuantityWeight": 0.0079,
                                                                    "singleQuantityMcf": 0.040922,
                                                                    "mcf": 0.040922,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.040922,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 3565,
                                                                            "parentRowNumber": 3564,
                                                                            "articleId": "125506",
                                                                            "description": "125506-00-Rohr",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0079,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0079,
                                                                            "singleQuantityMcf": 0.040922,
                                                                            "mcf": 0.040922,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.040922,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 3566,
                                                                    "parentRowNumber": 3487,
                                                                    "articleId": "46062217",
                                                                    "description": "46062217-00-Klotz",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.1294,
                                                                    "childrenWeight": 0.1294,
                                                                    "totalQuantityWeight": 0.1294,
                                                                    "singleQuantityMcf": 0.6702919999999999,
                                                                    "mcf": 0.6702919999999999,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.6702919999999999,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 3567,
                                                                            "parentRowNumber": 3566,
                                                                            "articleId": "1110284",
                                                                            "description": "1110284-00-Flachstahl",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.1294,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.1294,
                                                                            "singleQuantityMcf": 0.6702919999999999,
                                                                            "mcf": 0.6702919999999999,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.6702919999999999,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 3568,
                                                                    "parentRowNumber": 3487,
                                                                    "articleId": "46114498",
                                                                    "description": "46114498-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0026,
                                                                    "childrenWeight": 0.0026,
                                                                    "totalQuantityWeight": 0.0026,
                                                                    "singleQuantityMcf": 0.013468,
                                                                    "mcf": 0.013468,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.013468,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 3569,
                                                                            "parentRowNumber": 3568,
                                                                            "articleId": "1260020",
                                                                            "description": "1260020-00-Rundstahl",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0026,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0026,
                                                                            "singleQuantityMcf": 0.013468,
                                                                            "mcf": 0.013468,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.013468,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 3570,
                                                                    "parentRowNumber": 3487,
                                                                    "articleId": "46299026",
                                                                    "description": "46299026-00-Passfeder",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0023,
                                                                    "childrenWeight": 0.0023,
                                                                    "totalQuantityWeight": 0.0023,
                                                                    "singleQuantityMcf": 0.011914,
                                                                    "mcf": 0.011914,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.011914,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 3571,
                                                                            "parentRowNumber": 3570,
                                                                            "articleId": "1110062",
                                                                            "description": "1110062-00-Flachstahl",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0023,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0023,
                                                                            "singleQuantityMcf": 0.011914,
                                                                            "mcf": 0.011914,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.011914,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 3572,
                                                                    "parentRowNumber": 3487,
                                                                    "articleId": "465052",
                                                                    "description": "465052-01-Platte",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 2.883,
                                                                    "childrenWeight": 2.883,
                                                                    "totalQuantityWeight": 2.883,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 3573,
                                                                            "parentRowNumber": 3572,
                                                                            "articleId": "141718",
                                                                            "description": "141718-00-Platte",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 2.883,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 2.883,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 3574,
                                                                    "parentRowNumber": 3487,
                                                                    "articleId": "465058",
                                                                    "description": "465058-02-Lagersaeule",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.789,
                                                                    "childrenWeight": 0.789,
                                                                    "totalQuantityWeight": 0.789,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 3575,
                                                                            "parentRowNumber": 3574,
                                                                            "articleId": "1232120",
                                                                            "description": "1232120-00-Rundstange",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.789,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.789,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 3576,
                                                                    "parentRowNumber": 3487,
                                                                    "articleId": "465059",
                                                                    "description": "465059-00-Distanzring",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0455,
                                                                    "childrenWeight": 0.0455,
                                                                    "totalQuantityWeight": 0.0455,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 3577,
                                                                            "parentRowNumber": 3576,
                                                                            "articleId": "1232080",
                                                                            "description": "1232080-00-Rundstange",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0455,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0455,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 3578,
                                                                    "parentRowNumber": 3487,
                                                                    "articleId": "47220999",
                                                                    "description": "47220999-00-Grundplatte",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 11.1527,
                                                                    "childrenWeight": 11.1527,
                                                                    "totalQuantityWeight": 11.1527,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 3579,
                                                                            "parentRowNumber": 3578,
                                                                            "articleId": "141719",
                                                                            "description": "141719-00-Platte",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 11.1527,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 11.1527,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 3580,
                                                                    "parentRowNumber": 3487,
                                                                    "articleId": "47221002",
                                                                    "description": "47221002-00-Platte",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 2.8952,
                                                                    "childrenWeight": 2.8952,
                                                                    "totalQuantityWeight": 2.8952,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 3581,
                                                                            "parentRowNumber": 3580,
                                                                            "articleId": "141718",
                                                                            "description": "141718-00-Platte",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 2.8952,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 2.8952,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 3582,
                                                                    "parentRowNumber": 3487,
                                                                    "articleId": "47222534",
                                                                    "description": "47222534-00-Riemenscheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.1197,
                                                                    "childrenWeight": 0.1197,
                                                                    "totalQuantityWeight": 0.1197,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 3583,
                                                                            "parentRowNumber": 3582,
                                                                            "articleId": "1232065",
                                                                            "description": "1232065-00-Rundstange",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.1197,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.1197,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 3584,
                                                                    "parentRowNumber": 3487,
                                                                    "articleId": "47224106",
                                                                    "description": "47224106-00-Flansch",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.6591,
                                                                    "childrenWeight": 0.6591,
                                                                    "totalQuantityWeight": 0.6591,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 3585,
                                                                            "parentRowNumber": 3584,
                                                                            "articleId": "1232150",
                                                                            "description": "1232150-00-Rundstange",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.6591,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.6591,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 3586,
                                                                    "parentRowNumber": 3487,
                                                                    "articleId": "47229851",
                                                                    "description": "47229851-00-Platte",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 1.6085,
                                                                    "childrenWeight": 1.6085,
                                                                    "totalQuantityWeight": 1.6085,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 3587,
                                                                            "parentRowNumber": 3586,
                                                                            "articleId": "141718",
                                                                            "description": "141718-00-Platte",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 1.6085,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 1.6085,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 3588,
                                                                    "parentRowNumber": 3487,
                                                                    "articleId": "47230233",
                                                                    "description": "47230233-00-Blech",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.4725,
                                                                    "childrenWeight": 0.4725,
                                                                    "totalQuantityWeight": 0.4725,
                                                                    "singleQuantityMcf": 2.44755,
                                                                    "mcf": 2.44755,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 2.44755,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 3589,
                                                                            "parentRowNumber": 3588,
                                                                            "articleId": "142603",
                                                                            "description": "142603-00-Edelstahlblech",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.4725,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.4725,
                                                                            "singleQuantityMcf": 2.44755,
                                                                            "mcf": 2.44755,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 2.44755,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 3590,
                                                                    "parentRowNumber": 3487,
                                                                    "articleId": "51154654",
                                                                    "description": "51154654-00-Bürstenloser Antrieb",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 3.4736,
                                                                    "childrenWeight": 3.4736,
                                                                    "totalQuantityWeight": 3.4736,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 3591,
                                                                            "parentRowNumber": 3590,
                                                                            "articleId": "PUDY00001702",
                                                                            "description": "PUDY00001702-00-Schneckengetriebe_Hohlwelle",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        },
                                                                        {
                                                                            "rowNumber": 3592,
                                                                            "parentRowNumber": 3590,
                                                                            "articleId": "PUDY00005107",
                                                                            "description": "PUDY00005107-00-BG66x25 dPro",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 3.4736,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 3.4736,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 3593,
                                                                    "parentRowNumber": 3487,
                                                                    "articleId": "56489865",
                                                                    "description": "56489865-00-Kabelstecker",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0715,
                                                                    "childrenWeight": 0.0715,
                                                                    "totalQuantityWeight": 0.0715,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 3594,
                                                                            "parentRowNumber": 3593,
                                                                            "articleId": "PUDY00000070",
                                                                            "description": "PUDY00000070-00-Kabel D11",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0715,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0715,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 3595,
                                                                    "parentRowNumber": 3487,
                                                                    "articleId": "57550123",
                                                                    "description": "57550123-02-Verschraubung",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3596,
                                                                    "parentRowNumber": 3487,
                                                                    "articleId": "575720",
                                                                    "description": "575720-00-Schelle",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0028,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0028,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3597,
                                                                    "parentRowNumber": 3487,
                                                                    "articleId": "575720",
                                                                    "description": "575720-00-Schelle",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0028,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0028,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3598,
                                                                    "parentRowNumber": 3487,
                                                                    "articleId": "575750",
                                                                    "description": "575750-01-Schelle",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.005,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.005,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3599,
                                                                    "parentRowNumber": 3487,
                                                                    "articleId": "575750",
                                                                    "description": "575750-01-Schelle",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.005,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.005,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": []
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            "rowNumber": 3600,
                                                            "parentRowNumber": 3483,
                                                            "articleId": "RTF1200",
                                                            "description": "RTF1200-EK0003-00-Drehtellerfuehrungen",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 2.5412,
                                                            "childrenWeight": 2.5412,
                                                            "totalQuantityWeight": 2.5412,
                                                            "singleQuantityMcf": 4.764992,
                                                            "mcf": 4.764992,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 4.764992,
                                                            "expandable": false,
                                                            "children": [
                                                                {
                                                                    "rowNumber": 3601,
                                                                    "parentRowNumber": 3600,
                                                                    "articleId": "22070615",
                                                                    "description": "22070615-00-Fluegelschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0248,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0248,
                                                                    "singleQuantityMcf": 0.042904,
                                                                    "mcf": 0.042904,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.042904,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3602,
                                                                    "parentRowNumber": 3600,
                                                                    "articleId": "46727912",
                                                                    "description": "46727912-00-Klemmstueck",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.909,
                                                                    "childrenWeight": 0.909,
                                                                    "totalQuantityWeight": 0.909,
                                                                    "singleQuantityMcf": 4.70862,
                                                                    "mcf": 4.70862,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 4.70862,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 3603,
                                                                            "parentRowNumber": 3602,
                                                                            "articleId": "1110228",
                                                                            "description": "1110228-00-Flachstahl",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.7173,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.7173,
                                                                            "singleQuantityMcf": 3.715614,
                                                                            "mcf": 3.715614,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 3.715614,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        },
                                                                        {
                                                                            "rowNumber": 3604,
                                                                            "parentRowNumber": 3602,
                                                                            "articleId": "46033022",
                                                                            "description": "46033022-00-Klemmhebel",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.1917,
                                                                            "childrenWeight": 0.1917,
                                                                            "totalQuantityWeight": 0.1917,
                                                                            "singleQuantityMcf": 0.993006,
                                                                            "mcf": 0.993006,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.993006,
                                                                            "expandable": false,
                                                                            "children": [
                                                                                {
                                                                                    "rowNumber": 3605,
                                                                                    "parentRowNumber": 3604,
                                                                                    "articleId": "1110231",
                                                                                    "description": "1110231-00-Flachstahl",
                                                                                    "hierarchy": 9,
                                                                                    "quantity": 1,
                                                                                    "weight": 0.1917,
                                                                                    "childrenWeight": 0,
                                                                                    "totalQuantityWeight": 0.1917,
                                                                                    "singleQuantityMcf": 0.993006,
                                                                                    "mcf": 0.993006,
                                                                                    "singleQuantityLcf": 0,
                                                                                    "lcf": 0,
                                                                                    "totalQuantityMcf": 0.993006,
                                                                                    "expandable": false,
                                                                                    "children": []
                                                                                }
                                                                            ]
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 3606,
                                                                    "parentRowNumber": 3600,
                                                                    "articleId": "47136775",
                                                                    "description": "47136775-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0026,
                                                                    "childrenWeight": 0.0026,
                                                                    "totalQuantityWeight": 0.0026,
                                                                    "singleQuantityMcf": 0.013468,
                                                                    "mcf": 0.013468,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.013468,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 3607,
                                                                            "parentRowNumber": 3606,
                                                                            "articleId": "1260016",
                                                                            "description": "1260016-00-Rundstahl",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0026,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0026,
                                                                            "singleQuantityMcf": 0.013468,
                                                                            "mcf": 0.013468,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.013468,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 3608,
                                                                    "parentRowNumber": 3600,
                                                                    "articleId": "47144518",
                                                                    "description": "47144518-00-Abweiser",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 1.6048,
                                                                    "childrenWeight": 1.6048,
                                                                    "totalQuantityWeight": 1.6048,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 3609,
                                                                            "parentRowNumber": 3608,
                                                                            "articleId": "162512",
                                                                            "description": "162512-00-Platte",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 1.6048,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 1.6048,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        },
                                                                        {
                                                                            "rowNumber": 3610,
                                                                            "parentRowNumber": 3608,
                                                                            "articleId": "79010013",
                                                                            "description": "79010013-00-Skala 300 lang",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                }
                                                            ]
                                                        }
                                                    ]
                                                }
                                            ]
                                        },
                                        {
                                            "rowNumber": 3611,
                                            "parentRowNumber": 3160,
                                            "articleId": "33847",
                                            "description": "33847-20NA40-00-Niveauerfassung",
                                            "hierarchy": 4,
                                            "quantity": 1,
                                            "weight": 1.0986999999999991,
                                            "childrenWeight": 1.0986999999999991,
                                            "totalQuantityWeight": 1.0986999999999991,
                                            "singleQuantityMcf": 0.5164460000000002,
                                            "mcf": 0.5164460000000002,
                                            "singleQuantityLcf": 0,
                                            "lcf": 0,
                                            "totalQuantityMcf": 0.5164460000000002,
                                            "expandable": false,
                                            "children": [
                                                {
                                                    "rowNumber": 3612,
                                                    "parentRowNumber": 3611,
                                                    "articleId": "MM0000009322",
                                                    "description": "MM0000009322-00-Niveauerfassung",
                                                    "hierarchy": 5,
                                                    "quantity": 1,
                                                    "weight": 1.0986999999999991,
                                                    "childrenWeight": 1.0986999999999991,
                                                    "totalQuantityWeight": 1.0986999999999991,
                                                    "singleQuantityMcf": 0.5164460000000002,
                                                    "mcf": 0.5164460000000002,
                                                    "singleQuantityLcf": 0,
                                                    "lcf": 0,
                                                    "totalQuantityMcf": 0.5164460000000002,
                                                    "expandable": false,
                                                    "children": [
                                                        {
                                                            "rowNumber": 3613,
                                                            "parentRowNumber": 3612,
                                                            "articleId": "20120306",
                                                            "description": "20120306-00-Sechskantschraube",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0007,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0007,
                                                            "singleQuantityMcf": 0.003626,
                                                            "mcf": 0.003626,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.003626,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 3614,
                                                            "parentRowNumber": 3612,
                                                            "articleId": "20120306",
                                                            "description": "20120306-00-Sechskantschraube",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0007,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0007,
                                                            "singleQuantityMcf": 0.003626,
                                                            "mcf": 0.003626,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.003626,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 3615,
                                                            "parentRowNumber": 3612,
                                                            "articleId": "20120306",
                                                            "description": "20120306-00-Sechskantschraube",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0007,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0007,
                                                            "singleQuantityMcf": 0.003626,
                                                            "mcf": 0.003626,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.003626,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 3616,
                                                            "parentRowNumber": 3612,
                                                            "articleId": "20120306",
                                                            "description": "20120306-00-Sechskantschraube",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0007,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0007,
                                                            "singleQuantityMcf": 0.003626,
                                                            "mcf": 0.003626,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.003626,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 3617,
                                                            "parentRowNumber": 3612,
                                                            "articleId": "20120306",
                                                            "description": "20120306-00-Sechskantschraube",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0007,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0007,
                                                            "singleQuantityMcf": 0.003626,
                                                            "mcf": 0.003626,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.003626,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 3618,
                                                            "parentRowNumber": 3612,
                                                            "articleId": "20120310",
                                                            "description": "20120310-00-Sechskantschraube",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.001,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.001,
                                                            "singleQuantityMcf": 0.00518,
                                                            "mcf": 0.00518,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.00518,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 3619,
                                                            "parentRowNumber": 3612,
                                                            "articleId": "20120406",
                                                            "description": "20120406-00-Sechskantschraube",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0015,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0015,
                                                            "singleQuantityMcf": 0.00777,
                                                            "mcf": 0.00777,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.00777,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 3620,
                                                            "parentRowNumber": 3612,
                                                            "articleId": "20120406",
                                                            "description": "20120406-00-Sechskantschraube",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0015,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0015,
                                                            "singleQuantityMcf": 0.00777,
                                                            "mcf": 0.00777,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.00777,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 3621,
                                                            "parentRowNumber": 3612,
                                                            "articleId": "20120635",
                                                            "description": "20120635-00-Sechskantschraube",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0105,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0105,
                                                            "singleQuantityMcf": 0.05439,
                                                            "mcf": 0.05439,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.05439,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 3622,
                                                            "parentRowNumber": 3612,
                                                            "articleId": "20120635",
                                                            "description": "20120635-00-Sechskantschraube",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0105,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0105,
                                                            "singleQuantityMcf": 0.05439,
                                                            "mcf": 0.05439,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.05439,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 3623,
                                                            "parentRowNumber": 3612,
                                                            "articleId": "208203",
                                                            "description": "208203-00-Scheibe",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0001,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0001,
                                                            "singleQuantityMcf": 0.000518,
                                                            "mcf": 0.000518,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.000518,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 3624,
                                                            "parentRowNumber": 3612,
                                                            "articleId": "208203",
                                                            "description": "208203-00-Scheibe",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0001,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0001,
                                                            "singleQuantityMcf": 0.000518,
                                                            "mcf": 0.000518,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.000518,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 3625,
                                                            "parentRowNumber": 3612,
                                                            "articleId": "208203",
                                                            "description": "208203-00-Scheibe",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0001,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0001,
                                                            "singleQuantityMcf": 0.000518,
                                                            "mcf": 0.000518,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.000518,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 3626,
                                                            "parentRowNumber": 3612,
                                                            "articleId": "208203",
                                                            "description": "208203-00-Scheibe",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0001,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0001,
                                                            "singleQuantityMcf": 0.000518,
                                                            "mcf": 0.000518,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.000518,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 3627,
                                                            "parentRowNumber": 3612,
                                                            "articleId": "208203",
                                                            "description": "208203-00-Scheibe",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0001,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0001,
                                                            "singleQuantityMcf": 0.000518,
                                                            "mcf": 0.000518,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.000518,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 3628,
                                                            "parentRowNumber": 3612,
                                                            "articleId": "208203",
                                                            "description": "208203-00-Scheibe",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0001,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0001,
                                                            "singleQuantityMcf": 0.000518,
                                                            "mcf": 0.000518,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.000518,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 3629,
                                                            "parentRowNumber": 3612,
                                                            "articleId": "208204",
                                                            "description": "208204-00-Scheibe",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0003,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0003,
                                                            "singleQuantityMcf": 0.0015539999999999998,
                                                            "mcf": 0.0015539999999999998,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.0015539999999999998,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 3630,
                                                            "parentRowNumber": 3612,
                                                            "articleId": "208204",
                                                            "description": "208204-00-Scheibe",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0003,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0003,
                                                            "singleQuantityMcf": 0.0015539999999999998,
                                                            "mcf": 0.0015539999999999998,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.0015539999999999998,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 3631,
                                                            "parentRowNumber": 3612,
                                                            "articleId": "208206",
                                                            "description": "208206-00-Scheibe",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0009,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0009,
                                                            "singleQuantityMcf": 0.004662,
                                                            "mcf": 0.004662,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.004662,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 3632,
                                                            "parentRowNumber": 3612,
                                                            "articleId": "208206",
                                                            "description": "208206-00-Scheibe",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0009,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0009,
                                                            "singleQuantityMcf": 0.004662,
                                                            "mcf": 0.004662,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.004662,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 3633,
                                                            "parentRowNumber": 3612,
                                                            "articleId": "47229257",
                                                            "description": "47229257-00-Halter",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.3033,
                                                            "childrenWeight": 0.3033,
                                                            "totalQuantityWeight": 0.3033,
                                                            "singleQuantityMcf": 0,
                                                            "mcf": 0,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0,
                                                            "expandable": false,
                                                            "children": [
                                                                {
                                                                    "rowNumber": 3634,
                                                                    "parentRowNumber": 3633,
                                                                    "articleId": "141723",
                                                                    "description": "141723-00-Platte",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.3033,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.3033,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": []
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            "rowNumber": 3635,
                                                            "parentRowNumber": 3612,
                                                            "articleId": "47229357",
                                                            "description": "47229357-00-Blech",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0682,
                                                            "childrenWeight": 0.0682,
                                                            "totalQuantityWeight": 0.0682,
                                                            "singleQuantityMcf": 0.353276,
                                                            "mcf": 0.353276,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.353276,
                                                            "expandable": false,
                                                            "children": [
                                                                {
                                                                    "rowNumber": 3636,
                                                                    "parentRowNumber": 3635,
                                                                    "articleId": "142603",
                                                                    "description": "142603-00-Edelstahlblech",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0682,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0682,
                                                                    "singleQuantityMcf": 0.353276,
                                                                    "mcf": 0.353276,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.353276,
                                                                    "expandable": false,
                                                                    "children": []
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            "rowNumber": 3637,
                                                            "parentRowNumber": 3612,
                                                            "articleId": "573900",
                                                            "description": "573900-00-Automatenschiene",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.6795,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.6795,
                                                            "singleQuantityMcf": 0,
                                                            "mcf": 0,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 3638,
                                                            "parentRowNumber": 3612,
                                                            "articleId": "58223537",
                                                            "description": "58223537-02-Sensorverstärker",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0106,
                                                            "childrenWeight": 0.0106,
                                                            "totalQuantityWeight": 0.0106,
                                                            "singleQuantityMcf": 0,
                                                            "mcf": 0,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0,
                                                            "expandable": false,
                                                            "children": [
                                                                {
                                                                    "rowNumber": 3639,
                                                                    "parentRowNumber": 3638,
                                                                    "articleId": "PUDY00000066",
                                                                    "description": "PUDY00000066-00-Kabel D5",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0106,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0106,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": []
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            "rowNumber": 3640,
                                                            "parentRowNumber": 3612,
                                                            "articleId": "58223582",
                                                            "description": "58223582-02-Kapazitiver Sensor",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0028,
                                                            "childrenWeight": 0.0028,
                                                            "totalQuantityWeight": 0.0028,
                                                            "singleQuantityMcf": 0,
                                                            "mcf": 0,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0,
                                                            "expandable": false,
                                                            "children": [
                                                                {
                                                                    "rowNumber": 3641,
                                                                    "parentRowNumber": 3640,
                                                                    "articleId": "PUDY00000502",
                                                                    "description": "PUDY00000502-00-Kabel D3",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0028,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0028,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": []
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            "rowNumber": 3642,
                                                            "parentRowNumber": 3612,
                                                            "articleId": "PUDY00001023",
                                                            "description": "PUDY00001023-00-Kapazitiver Sensor Stecker",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0028,
                                                            "childrenWeight": 0.0028,
                                                            "totalQuantityWeight": 0.0028,
                                                            "singleQuantityMcf": 0,
                                                            "mcf": 0,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0,
                                                            "expandable": false,
                                                            "children": [
                                                                {
                                                                    "rowNumber": 3643,
                                                                    "parentRowNumber": 3642,
                                                                    "articleId": "PUDY00000502",
                                                                    "description": "PUDY00000502-00-Kabel D3",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0028,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0028,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": []
                                                                }
                                                            ]
                                                        }
                                                    ]
                                                }
                                            ]
                                        },
                                        {
                                            "rowNumber": 3644,
                                            "parentRowNumber": 3160,
                                            "articleId": "33847",
                                            "description": "33847-20NA70-00-Objektausschub",
                                            "hierarchy": 4,
                                            "quantity": 1,
                                            "weight": 1.1945,
                                            "childrenWeight": 1.1945,
                                            "totalQuantityWeight": 1.1945,
                                            "singleQuantityMcf": 6.187509999999998,
                                            "mcf": 6.187509999999998,
                                            "singleQuantityLcf": 0,
                                            "lcf": 0,
                                            "totalQuantityMcf": 6.187509999999998,
                                            "expandable": false,
                                            "children": [
                                                {
                                                    "rowNumber": 3645,
                                                    "parentRowNumber": 3644,
                                                    "articleId": "MM0000009381",
                                                    "description": "MM0000009381-00-Objektausschub",
                                                    "hierarchy": 5,
                                                    "quantity": 1,
                                                    "weight": 1.1945,
                                                    "childrenWeight": 1.1945,
                                                    "totalQuantityWeight": 1.1945,
                                                    "singleQuantityMcf": 6.187509999999998,
                                                    "mcf": 6.187509999999998,
                                                    "singleQuantityLcf": 0,
                                                    "lcf": 0,
                                                    "totalQuantityMcf": 6.187509999999998,
                                                    "expandable": false,
                                                    "children": [
                                                        {
                                                            "rowNumber": 3646,
                                                            "parentRowNumber": 3645,
                                                            "articleId": "20120612",
                                                            "description": "20120612-00-Sechskantschraube",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0053,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0053,
                                                            "singleQuantityMcf": 0.027454,
                                                            "mcf": 0.027454,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.027454,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 3647,
                                                            "parentRowNumber": 3645,
                                                            "articleId": "20120612",
                                                            "description": "20120612-00-Sechskantschraube",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0053,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0053,
                                                            "singleQuantityMcf": 0.027454,
                                                            "mcf": 0.027454,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.027454,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 3648,
                                                            "parentRowNumber": 3645,
                                                            "articleId": "208206",
                                                            "description": "208206-00-Scheibe",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0009,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0009,
                                                            "singleQuantityMcf": 0.004662,
                                                            "mcf": 0.004662,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.004662,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 3649,
                                                            "parentRowNumber": 3645,
                                                            "articleId": "208206",
                                                            "description": "208206-00-Scheibe",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0009,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0009,
                                                            "singleQuantityMcf": 0.004662,
                                                            "mcf": 0.004662,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.004662,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 3650,
                                                            "parentRowNumber": 3645,
                                                            "articleId": "46263958",
                                                            "description": "46263958-00-Auffangbehaelter",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 1.1821,
                                                            "childrenWeight": 1.1821,
                                                            "totalQuantityWeight": 1.1821,
                                                            "singleQuantityMcf": 6.123277999999999,
                                                            "mcf": 6.123277999999999,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 6.123277999999999,
                                                            "expandable": false,
                                                            "children": [
                                                                {
                                                                    "rowNumber": 3651,
                                                                    "parentRowNumber": 3650,
                                                                    "articleId": "142603",
                                                                    "description": "142603-00-Edelstahlblech",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 1.1821,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 1.1821,
                                                                    "singleQuantityMcf": 6.123277999999999,
                                                                    "mcf": 6.123277999999999,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 6.123277999999999,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3652,
                                                                    "parentRowNumber": 3650,
                                                                    "articleId": "163107",
                                                                    "description": "163107-00-Gummiplatte",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": []
                                                                }
                                                            ]
                                                        }
                                                    ]
                                                }
                                            ]
                                        },
                                        {
                                            "rowNumber": 3653,
                                            "parentRowNumber": 3160,
                                            "articleId": "33847",
                                            "description": "33847-20NA85-00-Bodenführung",
                                            "hierarchy": 4,
                                            "quantity": 1,
                                            "weight": 99.03529999999984,
                                            "childrenWeight": 99.03529999999984,
                                            "totalQuantityWeight": 99.03529999999984,
                                            "singleQuantityMcf": 145.98704679999966,
                                            "mcf": 145.98704679999966,
                                            "singleQuantityLcf": 0,
                                            "lcf": 0,
                                            "totalQuantityMcf": 145.98704679999966,
                                            "expandable": false,
                                            "children": [
                                                {
                                                    "rowNumber": 3654,
                                                    "parentRowNumber": 3653,
                                                    "articleId": "MM0000007418",
                                                    "description": "MM0000007418-00-Bodenführung",
                                                    "hierarchy": 5,
                                                    "quantity": 1,
                                                    "weight": 99.03529999999984,
                                                    "childrenWeight": 99.03529999999984,
                                                    "totalQuantityWeight": 99.03529999999984,
                                                    "singleQuantityMcf": 145.98704679999966,
                                                    "mcf": 145.98704679999966,
                                                    "singleQuantityLcf": 0,
                                                    "lcf": 0,
                                                    "totalQuantityMcf": 145.98704679999966,
                                                    "expandable": false,
                                                    "children": [
                                                        {
                                                            "rowNumber": 3655,
                                                            "parentRowNumber": 3654,
                                                            "articleId": "46660683",
                                                            "description": "46660683-00-Drehteller",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 98.16489999999985,
                                                            "childrenWeight": 98.16489999999985,
                                                            "totalQuantityWeight": 98.16489999999985,
                                                            "singleQuantityMcf": 143.23312679999967,
                                                            "mcf": 143.23312679999967,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 143.23312679999967,
                                                            "expandable": false,
                                                            "children": [
                                                                {
                                                                    "rowNumber": 3656,
                                                                    "parentRowNumber": 3655,
                                                                    "articleId": "20120860",
                                                                    "description": "20120860-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0302,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0302,
                                                                    "singleQuantityMcf": 0.156436,
                                                                    "mcf": 0.156436,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.156436,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3657,
                                                                    "parentRowNumber": 3655,
                                                                    "articleId": "20120860",
                                                                    "description": "20120860-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0302,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0302,
                                                                    "singleQuantityMcf": 0.156436,
                                                                    "mcf": 0.156436,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.156436,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3658,
                                                                    "parentRowNumber": 3655,
                                                                    "articleId": "20120860",
                                                                    "description": "20120860-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0302,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0302,
                                                                    "singleQuantityMcf": 0.156436,
                                                                    "mcf": 0.156436,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.156436,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3659,
                                                                    "parentRowNumber": 3655,
                                                                    "articleId": "20120860",
                                                                    "description": "20120860-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0302,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0302,
                                                                    "singleQuantityMcf": 0.156436,
                                                                    "mcf": 0.156436,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.156436,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3660,
                                                                    "parentRowNumber": 3655,
                                                                    "articleId": "208205",
                                                                    "description": "208205-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0004,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0004,
                                                                    "singleQuantityMcf": 0.002072,
                                                                    "mcf": 0.002072,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.002072,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3661,
                                                                    "parentRowNumber": 3655,
                                                                    "articleId": "208205",
                                                                    "description": "208205-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0004,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0004,
                                                                    "singleQuantityMcf": 0.002072,
                                                                    "mcf": 0.002072,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.002072,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3662,
                                                                    "parentRowNumber": 3655,
                                                                    "articleId": "208205",
                                                                    "description": "208205-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0004,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0004,
                                                                    "singleQuantityMcf": 0.002072,
                                                                    "mcf": 0.002072,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.002072,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3663,
                                                                    "parentRowNumber": 3655,
                                                                    "articleId": "208205",
                                                                    "description": "208205-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0004,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0004,
                                                                    "singleQuantityMcf": 0.002072,
                                                                    "mcf": 0.002072,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.002072,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3664,
                                                                    "parentRowNumber": 3655,
                                                                    "articleId": "208205",
                                                                    "description": "208205-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0004,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0004,
                                                                    "singleQuantityMcf": 0.002072,
                                                                    "mcf": 0.002072,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.002072,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3665,
                                                                    "parentRowNumber": 3655,
                                                                    "articleId": "208205",
                                                                    "description": "208205-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0004,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0004,
                                                                    "singleQuantityMcf": 0.002072,
                                                                    "mcf": 0.002072,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.002072,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3666,
                                                                    "parentRowNumber": 3655,
                                                                    "articleId": "208205",
                                                                    "description": "208205-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0004,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0004,
                                                                    "singleQuantityMcf": 0.002072,
                                                                    "mcf": 0.002072,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.002072,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3667,
                                                                    "parentRowNumber": 3655,
                                                                    "articleId": "208205",
                                                                    "description": "208205-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0004,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0004,
                                                                    "singleQuantityMcf": 0.002072,
                                                                    "mcf": 0.002072,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.002072,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3668,
                                                                    "parentRowNumber": 3655,
                                                                    "articleId": "208205",
                                                                    "description": "208205-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0004,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0004,
                                                                    "singleQuantityMcf": 0.002072,
                                                                    "mcf": 0.002072,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.002072,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3669,
                                                                    "parentRowNumber": 3655,
                                                                    "articleId": "208205",
                                                                    "description": "208205-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0004,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0004,
                                                                    "singleQuantityMcf": 0.002072,
                                                                    "mcf": 0.002072,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.002072,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3670,
                                                                    "parentRowNumber": 3655,
                                                                    "articleId": "208205",
                                                                    "description": "208205-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0004,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0004,
                                                                    "singleQuantityMcf": 0.002072,
                                                                    "mcf": 0.002072,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.002072,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3671,
                                                                    "parentRowNumber": 3655,
                                                                    "articleId": "208205",
                                                                    "description": "208205-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0004,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0004,
                                                                    "singleQuantityMcf": 0.002072,
                                                                    "mcf": 0.002072,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.002072,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3672,
                                                                    "parentRowNumber": 3655,
                                                                    "articleId": "208205",
                                                                    "description": "208205-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0004,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0004,
                                                                    "singleQuantityMcf": 0.002072,
                                                                    "mcf": 0.002072,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.002072,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3673,
                                                                    "parentRowNumber": 3655,
                                                                    "articleId": "208205",
                                                                    "description": "208205-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0004,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0004,
                                                                    "singleQuantityMcf": 0.002072,
                                                                    "mcf": 0.002072,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.002072,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3674,
                                                                    "parentRowNumber": 3655,
                                                                    "articleId": "208205",
                                                                    "description": "208205-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0004,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0004,
                                                                    "singleQuantityMcf": 0.002072,
                                                                    "mcf": 0.002072,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.002072,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3675,
                                                                    "parentRowNumber": 3655,
                                                                    "articleId": "208205",
                                                                    "description": "208205-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0004,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0004,
                                                                    "singleQuantityMcf": 0.002072,
                                                                    "mcf": 0.002072,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.002072,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3676,
                                                                    "parentRowNumber": 3655,
                                                                    "articleId": "208205",
                                                                    "description": "208205-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0004,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0004,
                                                                    "singleQuantityMcf": 0.002072,
                                                                    "mcf": 0.002072,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.002072,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3677,
                                                                    "parentRowNumber": 3655,
                                                                    "articleId": "208205",
                                                                    "description": "208205-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0004,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0004,
                                                                    "singleQuantityMcf": 0.002072,
                                                                    "mcf": 0.002072,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.002072,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3678,
                                                                    "parentRowNumber": 3655,
                                                                    "articleId": "208205",
                                                                    "description": "208205-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0004,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0004,
                                                                    "singleQuantityMcf": 0.002072,
                                                                    "mcf": 0.002072,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.002072,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3679,
                                                                    "parentRowNumber": 3655,
                                                                    "articleId": "208205",
                                                                    "description": "208205-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0004,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0004,
                                                                    "singleQuantityMcf": 0.002072,
                                                                    "mcf": 0.002072,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.002072,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3680,
                                                                    "parentRowNumber": 3655,
                                                                    "articleId": "208205",
                                                                    "description": "208205-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0004,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0004,
                                                                    "singleQuantityMcf": 0.002072,
                                                                    "mcf": 0.002072,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.002072,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3681,
                                                                    "parentRowNumber": 3655,
                                                                    "articleId": "208205",
                                                                    "description": "208205-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0004,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0004,
                                                                    "singleQuantityMcf": 0.002072,
                                                                    "mcf": 0.002072,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.002072,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3682,
                                                                    "parentRowNumber": 3655,
                                                                    "articleId": "208205",
                                                                    "description": "208205-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0004,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0004,
                                                                    "singleQuantityMcf": 0.002072,
                                                                    "mcf": 0.002072,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.002072,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3683,
                                                                    "parentRowNumber": 3655,
                                                                    "articleId": "208205",
                                                                    "description": "208205-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0004,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0004,
                                                                    "singleQuantityMcf": 0.002072,
                                                                    "mcf": 0.002072,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.002072,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3684,
                                                                    "parentRowNumber": 3655,
                                                                    "articleId": "208205",
                                                                    "description": "208205-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0004,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0004,
                                                                    "singleQuantityMcf": 0.002072,
                                                                    "mcf": 0.002072,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.002072,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3685,
                                                                    "parentRowNumber": 3655,
                                                                    "articleId": "208205",
                                                                    "description": "208205-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0004,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0004,
                                                                    "singleQuantityMcf": 0.002072,
                                                                    "mcf": 0.002072,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.002072,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3686,
                                                                    "parentRowNumber": 3655,
                                                                    "articleId": "208205",
                                                                    "description": "208205-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0004,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0004,
                                                                    "singleQuantityMcf": 0.002072,
                                                                    "mcf": 0.002072,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.002072,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3687,
                                                                    "parentRowNumber": 3655,
                                                                    "articleId": "208205",
                                                                    "description": "208205-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0004,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0004,
                                                                    "singleQuantityMcf": 0.002072,
                                                                    "mcf": 0.002072,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.002072,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3688,
                                                                    "parentRowNumber": 3655,
                                                                    "articleId": "208205",
                                                                    "description": "208205-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0004,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0004,
                                                                    "singleQuantityMcf": 0.002072,
                                                                    "mcf": 0.002072,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.002072,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3689,
                                                                    "parentRowNumber": 3655,
                                                                    "articleId": "208205",
                                                                    "description": "208205-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0004,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0004,
                                                                    "singleQuantityMcf": 0.002072,
                                                                    "mcf": 0.002072,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.002072,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3690,
                                                                    "parentRowNumber": 3655,
                                                                    "articleId": "208205",
                                                                    "description": "208205-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0004,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0004,
                                                                    "singleQuantityMcf": 0.002072,
                                                                    "mcf": 0.002072,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.002072,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3691,
                                                                    "parentRowNumber": 3655,
                                                                    "articleId": "208205",
                                                                    "description": "208205-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0004,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0004,
                                                                    "singleQuantityMcf": 0.002072,
                                                                    "mcf": 0.002072,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.002072,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3692,
                                                                    "parentRowNumber": 3655,
                                                                    "articleId": "208205",
                                                                    "description": "208205-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0004,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0004,
                                                                    "singleQuantityMcf": 0.002072,
                                                                    "mcf": 0.002072,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.002072,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3693,
                                                                    "parentRowNumber": 3655,
                                                                    "articleId": "208205",
                                                                    "description": "208205-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0004,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0004,
                                                                    "singleQuantityMcf": 0.002072,
                                                                    "mcf": 0.002072,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.002072,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3694,
                                                                    "parentRowNumber": 3655,
                                                                    "articleId": "208205",
                                                                    "description": "208205-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0004,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0004,
                                                                    "singleQuantityMcf": 0.002072,
                                                                    "mcf": 0.002072,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.002072,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3695,
                                                                    "parentRowNumber": 3655,
                                                                    "articleId": "208205",
                                                                    "description": "208205-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0004,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0004,
                                                                    "singleQuantityMcf": 0.002072,
                                                                    "mcf": 0.002072,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.002072,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3696,
                                                                    "parentRowNumber": 3655,
                                                                    "articleId": "208205",
                                                                    "description": "208205-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0004,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0004,
                                                                    "singleQuantityMcf": 0.002072,
                                                                    "mcf": 0.002072,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.002072,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3697,
                                                                    "parentRowNumber": 3655,
                                                                    "articleId": "208205",
                                                                    "description": "208205-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0004,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0004,
                                                                    "singleQuantityMcf": 0.002072,
                                                                    "mcf": 0.002072,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.002072,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3698,
                                                                    "parentRowNumber": 3655,
                                                                    "articleId": "208205",
                                                                    "description": "208205-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0004,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0004,
                                                                    "singleQuantityMcf": 0.002072,
                                                                    "mcf": 0.002072,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.002072,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3699,
                                                                    "parentRowNumber": 3655,
                                                                    "articleId": "208205",
                                                                    "description": "208205-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0004,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0004,
                                                                    "singleQuantityMcf": 0.002072,
                                                                    "mcf": 0.002072,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.002072,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3700,
                                                                    "parentRowNumber": 3655,
                                                                    "articleId": "208205",
                                                                    "description": "208205-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0004,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0004,
                                                                    "singleQuantityMcf": 0.002072,
                                                                    "mcf": 0.002072,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.002072,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3701,
                                                                    "parentRowNumber": 3655,
                                                                    "articleId": "208205",
                                                                    "description": "208205-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0004,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0004,
                                                                    "singleQuantityMcf": 0.002072,
                                                                    "mcf": 0.002072,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.002072,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3702,
                                                                    "parentRowNumber": 3655,
                                                                    "articleId": "208208",
                                                                    "description": "208208-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0017,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0017,
                                                                    "singleQuantityMcf": 0.008806,
                                                                    "mcf": 0.008806,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.008806,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3703,
                                                                    "parentRowNumber": 3655,
                                                                    "articleId": "208208",
                                                                    "description": "208208-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0017,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0017,
                                                                    "singleQuantityMcf": 0.008806,
                                                                    "mcf": 0.008806,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.008806,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3704,
                                                                    "parentRowNumber": 3655,
                                                                    "articleId": "208208",
                                                                    "description": "208208-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0017,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0017,
                                                                    "singleQuantityMcf": 0.008806,
                                                                    "mcf": 0.008806,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.008806,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3705,
                                                                    "parentRowNumber": 3655,
                                                                    "articleId": "208208",
                                                                    "description": "208208-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0017,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0017,
                                                                    "singleQuantityMcf": 0.008806,
                                                                    "mcf": 0.008806,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.008806,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3706,
                                                                    "parentRowNumber": 3655,
                                                                    "articleId": "247205",
                                                                    "description": "247205-00-Sechskantmutter",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0015,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0015,
                                                                    "singleQuantityMcf": 0.00777,
                                                                    "mcf": 0.00777,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.00777,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3707,
                                                                    "parentRowNumber": 3655,
                                                                    "articleId": "247205",
                                                                    "description": "247205-00-Sechskantmutter",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0015,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0015,
                                                                    "singleQuantityMcf": 0.00777,
                                                                    "mcf": 0.00777,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.00777,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3708,
                                                                    "parentRowNumber": 3655,
                                                                    "articleId": "247205",
                                                                    "description": "247205-00-Sechskantmutter",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0015,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0015,
                                                                    "singleQuantityMcf": 0.00777,
                                                                    "mcf": 0.00777,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.00777,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3709,
                                                                    "parentRowNumber": 3655,
                                                                    "articleId": "247205",
                                                                    "description": "247205-00-Sechskantmutter",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0015,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0015,
                                                                    "singleQuantityMcf": 0.00777,
                                                                    "mcf": 0.00777,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.00777,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3710,
                                                                    "parentRowNumber": 3655,
                                                                    "articleId": "247205",
                                                                    "description": "247205-00-Sechskantmutter",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0015,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0015,
                                                                    "singleQuantityMcf": 0.00777,
                                                                    "mcf": 0.00777,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.00777,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3711,
                                                                    "parentRowNumber": 3655,
                                                                    "articleId": "247205",
                                                                    "description": "247205-00-Sechskantmutter",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0015,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0015,
                                                                    "singleQuantityMcf": 0.00777,
                                                                    "mcf": 0.00777,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.00777,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3712,
                                                                    "parentRowNumber": 3655,
                                                                    "articleId": "247205",
                                                                    "description": "247205-00-Sechskantmutter",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0015,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0015,
                                                                    "singleQuantityMcf": 0.00777,
                                                                    "mcf": 0.00777,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.00777,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3713,
                                                                    "parentRowNumber": 3655,
                                                                    "articleId": "247205",
                                                                    "description": "247205-00-Sechskantmutter",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0015,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0015,
                                                                    "singleQuantityMcf": 0.00777,
                                                                    "mcf": 0.00777,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.00777,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3714,
                                                                    "parentRowNumber": 3655,
                                                                    "articleId": "247205",
                                                                    "description": "247205-00-Sechskantmutter",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0015,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0015,
                                                                    "singleQuantityMcf": 0.00777,
                                                                    "mcf": 0.00777,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.00777,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3715,
                                                                    "parentRowNumber": 3655,
                                                                    "articleId": "247205",
                                                                    "description": "247205-00-Sechskantmutter",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0015,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0015,
                                                                    "singleQuantityMcf": 0.00777,
                                                                    "mcf": 0.00777,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.00777,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3716,
                                                                    "parentRowNumber": 3655,
                                                                    "articleId": "247205",
                                                                    "description": "247205-00-Sechskantmutter",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0015,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0015,
                                                                    "singleQuantityMcf": 0.00777,
                                                                    "mcf": 0.00777,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.00777,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3717,
                                                                    "parentRowNumber": 3655,
                                                                    "articleId": "247205",
                                                                    "description": "247205-00-Sechskantmutter",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0015,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0015,
                                                                    "singleQuantityMcf": 0.00777,
                                                                    "mcf": 0.00777,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.00777,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3718,
                                                                    "parentRowNumber": 3655,
                                                                    "articleId": "247205",
                                                                    "description": "247205-00-Sechskantmutter",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0015,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0015,
                                                                    "singleQuantityMcf": 0.00777,
                                                                    "mcf": 0.00777,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.00777,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3719,
                                                                    "parentRowNumber": 3655,
                                                                    "articleId": "247205",
                                                                    "description": "247205-00-Sechskantmutter",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0015,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0015,
                                                                    "singleQuantityMcf": 0.00777,
                                                                    "mcf": 0.00777,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.00777,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3720,
                                                                    "parentRowNumber": 3655,
                                                                    "articleId": "247205",
                                                                    "description": "247205-00-Sechskantmutter",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0015,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0015,
                                                                    "singleQuantityMcf": 0.00777,
                                                                    "mcf": 0.00777,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.00777,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3721,
                                                                    "parentRowNumber": 3655,
                                                                    "articleId": "247205",
                                                                    "description": "247205-00-Sechskantmutter",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0015,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0015,
                                                                    "singleQuantityMcf": 0.00777,
                                                                    "mcf": 0.00777,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.00777,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3722,
                                                                    "parentRowNumber": 3655,
                                                                    "articleId": "247205",
                                                                    "description": "247205-00-Sechskantmutter",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0015,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0015,
                                                                    "singleQuantityMcf": 0.00777,
                                                                    "mcf": 0.00777,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.00777,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3723,
                                                                    "parentRowNumber": 3655,
                                                                    "articleId": "247205",
                                                                    "description": "247205-00-Sechskantmutter",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0015,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0015,
                                                                    "singleQuantityMcf": 0.00777,
                                                                    "mcf": 0.00777,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.00777,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3724,
                                                                    "parentRowNumber": 3655,
                                                                    "articleId": "247205",
                                                                    "description": "247205-00-Sechskantmutter",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0015,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0015,
                                                                    "singleQuantityMcf": 0.00777,
                                                                    "mcf": 0.00777,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.00777,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3725,
                                                                    "parentRowNumber": 3655,
                                                                    "articleId": "247205",
                                                                    "description": "247205-00-Sechskantmutter",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0015,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0015,
                                                                    "singleQuantityMcf": 0.00777,
                                                                    "mcf": 0.00777,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.00777,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3726,
                                                                    "parentRowNumber": 3655,
                                                                    "articleId": "247205",
                                                                    "description": "247205-00-Sechskantmutter",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0015,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0015,
                                                                    "singleQuantityMcf": 0.00777,
                                                                    "mcf": 0.00777,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.00777,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3727,
                                                                    "parentRowNumber": 3655,
                                                                    "articleId": "247205",
                                                                    "description": "247205-00-Sechskantmutter",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0015,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0015,
                                                                    "singleQuantityMcf": 0.00777,
                                                                    "mcf": 0.00777,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.00777,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3728,
                                                                    "parentRowNumber": 3655,
                                                                    "articleId": "247205",
                                                                    "description": "247205-00-Sechskantmutter",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0015,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0015,
                                                                    "singleQuantityMcf": 0.00777,
                                                                    "mcf": 0.00777,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.00777,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3729,
                                                                    "parentRowNumber": 3655,
                                                                    "articleId": "247205",
                                                                    "description": "247205-00-Sechskantmutter",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0015,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0015,
                                                                    "singleQuantityMcf": 0.00777,
                                                                    "mcf": 0.00777,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.00777,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3730,
                                                                    "parentRowNumber": 3655,
                                                                    "articleId": "247205",
                                                                    "description": "247205-00-Sechskantmutter",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0015,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0015,
                                                                    "singleQuantityMcf": 0.00777,
                                                                    "mcf": 0.00777,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.00777,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3731,
                                                                    "parentRowNumber": 3655,
                                                                    "articleId": "247205",
                                                                    "description": "247205-00-Sechskantmutter",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0015,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0015,
                                                                    "singleQuantityMcf": 0.00777,
                                                                    "mcf": 0.00777,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.00777,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3732,
                                                                    "parentRowNumber": 3655,
                                                                    "articleId": "247205",
                                                                    "description": "247205-00-Sechskantmutter",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0015,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0015,
                                                                    "singleQuantityMcf": 0.00777,
                                                                    "mcf": 0.00777,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.00777,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3733,
                                                                    "parentRowNumber": 3655,
                                                                    "articleId": "247205",
                                                                    "description": "247205-00-Sechskantmutter",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0015,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0015,
                                                                    "singleQuantityMcf": 0.00777,
                                                                    "mcf": 0.00777,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.00777,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3734,
                                                                    "parentRowNumber": 3655,
                                                                    "articleId": "247205",
                                                                    "description": "247205-00-Sechskantmutter",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0015,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0015,
                                                                    "singleQuantityMcf": 0.00777,
                                                                    "mcf": 0.00777,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.00777,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3735,
                                                                    "parentRowNumber": 3655,
                                                                    "articleId": "247205",
                                                                    "description": "247205-00-Sechskantmutter",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0015,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0015,
                                                                    "singleQuantityMcf": 0.00777,
                                                                    "mcf": 0.00777,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.00777,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3736,
                                                                    "parentRowNumber": 3655,
                                                                    "articleId": "247205",
                                                                    "description": "247205-00-Sechskantmutter",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0015,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0015,
                                                                    "singleQuantityMcf": 0.00777,
                                                                    "mcf": 0.00777,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.00777,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3737,
                                                                    "parentRowNumber": 3655,
                                                                    "articleId": "247205",
                                                                    "description": "247205-00-Sechskantmutter",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0015,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0015,
                                                                    "singleQuantityMcf": 0.00777,
                                                                    "mcf": 0.00777,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.00777,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3738,
                                                                    "parentRowNumber": 3655,
                                                                    "articleId": "247205",
                                                                    "description": "247205-00-Sechskantmutter",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0015,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0015,
                                                                    "singleQuantityMcf": 0.00777,
                                                                    "mcf": 0.00777,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.00777,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3739,
                                                                    "parentRowNumber": 3655,
                                                                    "articleId": "247205",
                                                                    "description": "247205-00-Sechskantmutter",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0015,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0015,
                                                                    "singleQuantityMcf": 0.00777,
                                                                    "mcf": 0.00777,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.00777,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3740,
                                                                    "parentRowNumber": 3655,
                                                                    "articleId": "247205",
                                                                    "description": "247205-00-Sechskantmutter",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0015,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0015,
                                                                    "singleQuantityMcf": 0.00777,
                                                                    "mcf": 0.00777,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.00777,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3741,
                                                                    "parentRowNumber": 3655,
                                                                    "articleId": "247205",
                                                                    "description": "247205-00-Sechskantmutter",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0015,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0015,
                                                                    "singleQuantityMcf": 0.00777,
                                                                    "mcf": 0.00777,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.00777,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3742,
                                                                    "parentRowNumber": 3655,
                                                                    "articleId": "247205",
                                                                    "description": "247205-00-Sechskantmutter",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0015,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0015,
                                                                    "singleQuantityMcf": 0.00777,
                                                                    "mcf": 0.00777,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.00777,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3743,
                                                                    "parentRowNumber": 3655,
                                                                    "articleId": "247205",
                                                                    "description": "247205-00-Sechskantmutter",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0015,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0015,
                                                                    "singleQuantityMcf": 0.00777,
                                                                    "mcf": 0.00777,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.00777,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3744,
                                                                    "parentRowNumber": 3655,
                                                                    "articleId": "247205",
                                                                    "description": "247205-00-Sechskantmutter",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0015,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0015,
                                                                    "singleQuantityMcf": 0.00777,
                                                                    "mcf": 0.00777,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.00777,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3745,
                                                                    "parentRowNumber": 3655,
                                                                    "articleId": "247205",
                                                                    "description": "247205-00-Sechskantmutter",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0015,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0015,
                                                                    "singleQuantityMcf": 0.00777,
                                                                    "mcf": 0.00777,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.00777,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3746,
                                                                    "parentRowNumber": 3655,
                                                                    "articleId": "247205",
                                                                    "description": "247205-00-Sechskantmutter",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0015,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0015,
                                                                    "singleQuantityMcf": 0.00777,
                                                                    "mcf": 0.00777,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.00777,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3747,
                                                                    "parentRowNumber": 3655,
                                                                    "articleId": "247205",
                                                                    "description": "247205-00-Sechskantmutter",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0015,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0015,
                                                                    "singleQuantityMcf": 0.00777,
                                                                    "mcf": 0.00777,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.00777,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3748,
                                                                    "parentRowNumber": 3655,
                                                                    "articleId": "252057",
                                                                    "description": "252057-00-Verschlussstopfen",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0006,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0006,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3749,
                                                                    "parentRowNumber": 3655,
                                                                    "articleId": "252057",
                                                                    "description": "252057-00-Verschlussstopfen",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0006,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0006,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3750,
                                                                    "parentRowNumber": 3655,
                                                                    "articleId": "252057",
                                                                    "description": "252057-00-Verschlussstopfen",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0006,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0006,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3751,
                                                                    "parentRowNumber": 3655,
                                                                    "articleId": "252057",
                                                                    "description": "252057-00-Verschlussstopfen",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0006,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0006,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3752,
                                                                    "parentRowNumber": 3655,
                                                                    "articleId": "252057",
                                                                    "description": "252057-00-Verschlussstopfen",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0006,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0006,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3753,
                                                                    "parentRowNumber": 3655,
                                                                    "articleId": "252057",
                                                                    "description": "252057-00-Verschlussstopfen",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0006,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0006,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3754,
                                                                    "parentRowNumber": 3655,
                                                                    "articleId": "252057",
                                                                    "description": "252057-00-Verschlussstopfen",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0006,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0006,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3755,
                                                                    "parentRowNumber": 3655,
                                                                    "articleId": "252057",
                                                                    "description": "252057-00-Verschlussstopfen",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0006,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0006,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3756,
                                                                    "parentRowNumber": 3655,
                                                                    "articleId": "252057",
                                                                    "description": "252057-00-Verschlussstopfen",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0006,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0006,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3757,
                                                                    "parentRowNumber": 3655,
                                                                    "articleId": "252057",
                                                                    "description": "252057-00-Verschlussstopfen",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0006,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0006,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3758,
                                                                    "parentRowNumber": 3655,
                                                                    "articleId": "252057",
                                                                    "description": "252057-00-Verschlussstopfen",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0006,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0006,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3759,
                                                                    "parentRowNumber": 3655,
                                                                    "articleId": "252057",
                                                                    "description": "252057-00-Verschlussstopfen",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0006,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0006,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3760,
                                                                    "parentRowNumber": 3655,
                                                                    "articleId": "252057",
                                                                    "description": "252057-00-Verschlussstopfen",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0006,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0006,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3761,
                                                                    "parentRowNumber": 3655,
                                                                    "articleId": "252057",
                                                                    "description": "252057-00-Verschlussstopfen",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0006,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0006,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3762,
                                                                    "parentRowNumber": 3655,
                                                                    "articleId": "252057",
                                                                    "description": "252057-00-Verschlussstopfen",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0006,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0006,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3763,
                                                                    "parentRowNumber": 3655,
                                                                    "articleId": "252057",
                                                                    "description": "252057-00-Verschlussstopfen",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0006,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0006,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3764,
                                                                    "parentRowNumber": 3655,
                                                                    "articleId": "252057",
                                                                    "description": "252057-00-Verschlussstopfen",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0006,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0006,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3765,
                                                                    "parentRowNumber": 3655,
                                                                    "articleId": "252057",
                                                                    "description": "252057-00-Verschlussstopfen",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0006,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0006,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3766,
                                                                    "parentRowNumber": 3655,
                                                                    "articleId": "252057",
                                                                    "description": "252057-00-Verschlussstopfen",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0006,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0006,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3767,
                                                                    "parentRowNumber": 3655,
                                                                    "articleId": "252057",
                                                                    "description": "252057-00-Verschlussstopfen",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0006,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0006,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3768,
                                                                    "parentRowNumber": 3655,
                                                                    "articleId": "252057",
                                                                    "description": "252057-00-Verschlussstopfen",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0006,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0006,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3769,
                                                                    "parentRowNumber": 3655,
                                                                    "articleId": "252057",
                                                                    "description": "252057-00-Verschlussstopfen",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0006,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0006,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3770,
                                                                    "parentRowNumber": 3655,
                                                                    "articleId": "252057",
                                                                    "description": "252057-00-Verschlussstopfen",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0006,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0006,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3771,
                                                                    "parentRowNumber": 3655,
                                                                    "articleId": "252057",
                                                                    "description": "252057-00-Verschlussstopfen",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0006,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0006,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3772,
                                                                    "parentRowNumber": 3655,
                                                                    "articleId": "252057",
                                                                    "description": "252057-00-Verschlussstopfen",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0006,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0006,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3773,
                                                                    "parentRowNumber": 3655,
                                                                    "articleId": "252057",
                                                                    "description": "252057-00-Verschlussstopfen",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0006,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0006,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3774,
                                                                    "parentRowNumber": 3655,
                                                                    "articleId": "252057",
                                                                    "description": "252057-00-Verschlussstopfen",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0006,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0006,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3775,
                                                                    "parentRowNumber": 3655,
                                                                    "articleId": "252057",
                                                                    "description": "252057-00-Verschlussstopfen",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0006,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0006,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3776,
                                                                    "parentRowNumber": 3655,
                                                                    "articleId": "252057",
                                                                    "description": "252057-00-Verschlussstopfen",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0006,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0006,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3777,
                                                                    "parentRowNumber": 3655,
                                                                    "articleId": "252057",
                                                                    "description": "252057-00-Verschlussstopfen",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0006,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0006,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3778,
                                                                    "parentRowNumber": 3655,
                                                                    "articleId": "252057",
                                                                    "description": "252057-00-Verschlussstopfen",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0006,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0006,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3779,
                                                                    "parentRowNumber": 3655,
                                                                    "articleId": "252057",
                                                                    "description": "252057-00-Verschlussstopfen",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0006,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0006,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3780,
                                                                    "parentRowNumber": 3655,
                                                                    "articleId": "252057",
                                                                    "description": "252057-00-Verschlussstopfen",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0006,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0006,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3781,
                                                                    "parentRowNumber": 3655,
                                                                    "articleId": "252057",
                                                                    "description": "252057-00-Verschlussstopfen",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0006,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0006,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3782,
                                                                    "parentRowNumber": 3655,
                                                                    "articleId": "252057",
                                                                    "description": "252057-00-Verschlussstopfen",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0006,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0006,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3783,
                                                                    "parentRowNumber": 3655,
                                                                    "articleId": "252057",
                                                                    "description": "252057-00-Verschlussstopfen",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0006,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0006,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3784,
                                                                    "parentRowNumber": 3655,
                                                                    "articleId": "2826007",
                                                                    "description": "2826007-00-Radial-Rillenkugellager",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.1928,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.1928,
                                                                    "singleQuantityMcf": 0.3300736,
                                                                    "mcf": 0.3300736,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.3300736,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3785,
                                                                    "parentRowNumber": 3655,
                                                                    "articleId": "2826008",
                                                                    "description": "2826008-00-Radial-Rillenkugellager",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.2371,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.2371,
                                                                    "singleQuantityMcf": 0.4059152,
                                                                    "mcf": 0.4059152,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.4059152,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3786,
                                                                    "parentRowNumber": 3655,
                                                                    "articleId": "46062353",
                                                                    "description": "46062353-01-Nabe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 12.9303,
                                                                    "childrenWeight": 12.9303,
                                                                    "totalQuantityWeight": 12.9303,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 3787,
                                                                            "parentRowNumber": 3786,
                                                                            "articleId": "1232300",
                                                                            "description": "1232300-00-Rundstange",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 12.9303,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 12.9303,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 3788,
                                                                    "parentRowNumber": 3655,
                                                                    "articleId": "46062389",
                                                                    "description": "46062389-00-Grundplatte",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 57.274,
                                                                    "childrenWeight": 57.274,
                                                                    "totalQuantityWeight": 57.274,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 3789,
                                                                            "parentRowNumber": 3788,
                                                                            "articleId": "141719",
                                                                            "description": "141719-00-Platte",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 57.274,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 57.274,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 3790,
                                                                    "parentRowNumber": 3655,
                                                                    "articleId": "RTE1200",
                                                                    "description": "RTE1200-A-017,01-02-Drehteller",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 27.3017,
                                                                    "childrenWeight": 27.3017,
                                                                    "totalQuantityWeight": 27.3017,
                                                                    "singleQuantityMcf": 141.42280599999998,
                                                                    "mcf": 141.42280599999998,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 141.42280599999998,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 3791,
                                                                            "parentRowNumber": 3790,
                                                                            "articleId": "142608",
                                                                            "description": "142608-00-Edelstahlblech",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 27.2051,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 27.2051,
                                                                            "singleQuantityMcf": 140.922418,
                                                                            "mcf": 140.922418,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 140.922418,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        },
                                                                        {
                                                                            "rowNumber": 3792,
                                                                            "parentRowNumber": 3790,
                                                                            "articleId": "21320515",
                                                                            "description": "21320515-00-Gewindestift",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0023,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0023,
                                                                            "singleQuantityMcf": 0.011914,
                                                                            "mcf": 0.011914,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.011914,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        },
                                                                        {
                                                                            "rowNumber": 3793,
                                                                            "parentRowNumber": 3790,
                                                                            "articleId": "21320515",
                                                                            "description": "21320515-00-Gewindestift",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0023,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0023,
                                                                            "singleQuantityMcf": 0.011914,
                                                                            "mcf": 0.011914,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.011914,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        },
                                                                        {
                                                                            "rowNumber": 3794,
                                                                            "parentRowNumber": 3790,
                                                                            "articleId": "21320515",
                                                                            "description": "21320515-00-Gewindestift",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0023,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0023,
                                                                            "singleQuantityMcf": 0.011914,
                                                                            "mcf": 0.011914,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.011914,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        },
                                                                        {
                                                                            "rowNumber": 3795,
                                                                            "parentRowNumber": 3790,
                                                                            "articleId": "21320515",
                                                                            "description": "21320515-00-Gewindestift",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0023,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0023,
                                                                            "singleQuantityMcf": 0.011914,
                                                                            "mcf": 0.011914,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.011914,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        },
                                                                        {
                                                                            "rowNumber": 3796,
                                                                            "parentRowNumber": 3790,
                                                                            "articleId": "21320515",
                                                                            "description": "21320515-00-Gewindestift",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0023,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0023,
                                                                            "singleQuantityMcf": 0.011914,
                                                                            "mcf": 0.011914,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.011914,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        },
                                                                        {
                                                                            "rowNumber": 3797,
                                                                            "parentRowNumber": 3790,
                                                                            "articleId": "21320515",
                                                                            "description": "21320515-00-Gewindestift",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0023,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0023,
                                                                            "singleQuantityMcf": 0.011914,
                                                                            "mcf": 0.011914,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.011914,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        },
                                                                        {
                                                                            "rowNumber": 3798,
                                                                            "parentRowNumber": 3790,
                                                                            "articleId": "21320515",
                                                                            "description": "21320515-00-Gewindestift",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0023,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0023,
                                                                            "singleQuantityMcf": 0.011914,
                                                                            "mcf": 0.011914,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.011914,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        },
                                                                        {
                                                                            "rowNumber": 3799,
                                                                            "parentRowNumber": 3790,
                                                                            "articleId": "21320515",
                                                                            "description": "21320515-00-Gewindestift",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0023,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0023,
                                                                            "singleQuantityMcf": 0.011914,
                                                                            "mcf": 0.011914,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.011914,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        },
                                                                        {
                                                                            "rowNumber": 3800,
                                                                            "parentRowNumber": 3790,
                                                                            "articleId": "21320515",
                                                                            "description": "21320515-00-Gewindestift",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0023,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0023,
                                                                            "singleQuantityMcf": 0.011914,
                                                                            "mcf": 0.011914,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.011914,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        },
                                                                        {
                                                                            "rowNumber": 3801,
                                                                            "parentRowNumber": 3790,
                                                                            "articleId": "21320515",
                                                                            "description": "21320515-00-Gewindestift",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0023,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0023,
                                                                            "singleQuantityMcf": 0.011914,
                                                                            "mcf": 0.011914,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.011914,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        },
                                                                        {
                                                                            "rowNumber": 3802,
                                                                            "parentRowNumber": 3790,
                                                                            "articleId": "21320515",
                                                                            "description": "21320515-00-Gewindestift",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0023,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0023,
                                                                            "singleQuantityMcf": 0.011914,
                                                                            "mcf": 0.011914,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.011914,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        },
                                                                        {
                                                                            "rowNumber": 3803,
                                                                            "parentRowNumber": 3790,
                                                                            "articleId": "21320515",
                                                                            "description": "21320515-00-Gewindestift",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0023,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0023,
                                                                            "singleQuantityMcf": 0.011914,
                                                                            "mcf": 0.011914,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.011914,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        },
                                                                        {
                                                                            "rowNumber": 3804,
                                                                            "parentRowNumber": 3790,
                                                                            "articleId": "21320515",
                                                                            "description": "21320515-00-Gewindestift",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0023,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0023,
                                                                            "singleQuantityMcf": 0.011914,
                                                                            "mcf": 0.011914,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.011914,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        },
                                                                        {
                                                                            "rowNumber": 3805,
                                                                            "parentRowNumber": 3790,
                                                                            "articleId": "21320515",
                                                                            "description": "21320515-00-Gewindestift",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0023,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0023,
                                                                            "singleQuantityMcf": 0.011914,
                                                                            "mcf": 0.011914,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.011914,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        },
                                                                        {
                                                                            "rowNumber": 3806,
                                                                            "parentRowNumber": 3790,
                                                                            "articleId": "21320515",
                                                                            "description": "21320515-00-Gewindestift",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0023,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0023,
                                                                            "singleQuantityMcf": 0.011914,
                                                                            "mcf": 0.011914,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.011914,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        },
                                                                        {
                                                                            "rowNumber": 3807,
                                                                            "parentRowNumber": 3790,
                                                                            "articleId": "21320515",
                                                                            "description": "21320515-00-Gewindestift",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0023,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0023,
                                                                            "singleQuantityMcf": 0.011914,
                                                                            "mcf": 0.011914,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.011914,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        },
                                                                        {
                                                                            "rowNumber": 3808,
                                                                            "parentRowNumber": 3790,
                                                                            "articleId": "21320515",
                                                                            "description": "21320515-00-Gewindestift",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0023,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0023,
                                                                            "singleQuantityMcf": 0.011914,
                                                                            "mcf": 0.011914,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.011914,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        },
                                                                        {
                                                                            "rowNumber": 3809,
                                                                            "parentRowNumber": 3790,
                                                                            "articleId": "21320515",
                                                                            "description": "21320515-00-Gewindestift",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0023,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0023,
                                                                            "singleQuantityMcf": 0.011914,
                                                                            "mcf": 0.011914,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.011914,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        },
                                                                        {
                                                                            "rowNumber": 3810,
                                                                            "parentRowNumber": 3790,
                                                                            "articleId": "21320515",
                                                                            "description": "21320515-00-Gewindestift",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0023,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0023,
                                                                            "singleQuantityMcf": 0.011914,
                                                                            "mcf": 0.011914,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.011914,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        },
                                                                        {
                                                                            "rowNumber": 3811,
                                                                            "parentRowNumber": 3790,
                                                                            "articleId": "21320515",
                                                                            "description": "21320515-00-Gewindestift",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0023,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0023,
                                                                            "singleQuantityMcf": 0.011914,
                                                                            "mcf": 0.011914,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.011914,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        },
                                                                        {
                                                                            "rowNumber": 3812,
                                                                            "parentRowNumber": 3790,
                                                                            "articleId": "21320515",
                                                                            "description": "21320515-00-Gewindestift",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0023,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0023,
                                                                            "singleQuantityMcf": 0.011914,
                                                                            "mcf": 0.011914,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.011914,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        },
                                                                        {
                                                                            "rowNumber": 3813,
                                                                            "parentRowNumber": 3790,
                                                                            "articleId": "21320515",
                                                                            "description": "21320515-00-Gewindestift",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0023,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0023,
                                                                            "singleQuantityMcf": 0.011914,
                                                                            "mcf": 0.011914,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.011914,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        },
                                                                        {
                                                                            "rowNumber": 3814,
                                                                            "parentRowNumber": 3790,
                                                                            "articleId": "21320515",
                                                                            "description": "21320515-00-Gewindestift",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0023,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0023,
                                                                            "singleQuantityMcf": 0.011914,
                                                                            "mcf": 0.011914,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.011914,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        },
                                                                        {
                                                                            "rowNumber": 3815,
                                                                            "parentRowNumber": 3790,
                                                                            "articleId": "21320515",
                                                                            "description": "21320515-00-Gewindestift",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0023,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0023,
                                                                            "singleQuantityMcf": 0.011914,
                                                                            "mcf": 0.011914,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.011914,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        },
                                                                        {
                                                                            "rowNumber": 3816,
                                                                            "parentRowNumber": 3790,
                                                                            "articleId": "21320515",
                                                                            "description": "21320515-00-Gewindestift",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0023,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0023,
                                                                            "singleQuantityMcf": 0.011914,
                                                                            "mcf": 0.011914,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.011914,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        },
                                                                        {
                                                                            "rowNumber": 3817,
                                                                            "parentRowNumber": 3790,
                                                                            "articleId": "21320515",
                                                                            "description": "21320515-00-Gewindestift",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0023,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0023,
                                                                            "singleQuantityMcf": 0.011914,
                                                                            "mcf": 0.011914,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.011914,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        },
                                                                        {
                                                                            "rowNumber": 3818,
                                                                            "parentRowNumber": 3790,
                                                                            "articleId": "21320515",
                                                                            "description": "21320515-00-Gewindestift",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0023,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0023,
                                                                            "singleQuantityMcf": 0.011914,
                                                                            "mcf": 0.011914,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.011914,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        },
                                                                        {
                                                                            "rowNumber": 3819,
                                                                            "parentRowNumber": 3790,
                                                                            "articleId": "21320515",
                                                                            "description": "21320515-00-Gewindestift",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0023,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0023,
                                                                            "singleQuantityMcf": 0.011914,
                                                                            "mcf": 0.011914,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.011914,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        },
                                                                        {
                                                                            "rowNumber": 3820,
                                                                            "parentRowNumber": 3790,
                                                                            "articleId": "21320515",
                                                                            "description": "21320515-00-Gewindestift",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0023,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0023,
                                                                            "singleQuantityMcf": 0.011914,
                                                                            "mcf": 0.011914,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.011914,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        },
                                                                        {
                                                                            "rowNumber": 3821,
                                                                            "parentRowNumber": 3790,
                                                                            "articleId": "21320515",
                                                                            "description": "21320515-00-Gewindestift",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0023,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0023,
                                                                            "singleQuantityMcf": 0.011914,
                                                                            "mcf": 0.011914,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.011914,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        },
                                                                        {
                                                                            "rowNumber": 3822,
                                                                            "parentRowNumber": 3790,
                                                                            "articleId": "21320515",
                                                                            "description": "21320515-00-Gewindestift",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0023,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0023,
                                                                            "singleQuantityMcf": 0.011914,
                                                                            "mcf": 0.011914,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.011914,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        },
                                                                        {
                                                                            "rowNumber": 3823,
                                                                            "parentRowNumber": 3790,
                                                                            "articleId": "21320515",
                                                                            "description": "21320515-00-Gewindestift",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0023,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0023,
                                                                            "singleQuantityMcf": 0.011914,
                                                                            "mcf": 0.011914,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.011914,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        },
                                                                        {
                                                                            "rowNumber": 3824,
                                                                            "parentRowNumber": 3790,
                                                                            "articleId": "21320515",
                                                                            "description": "21320515-00-Gewindestift",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0023,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0023,
                                                                            "singleQuantityMcf": 0.011914,
                                                                            "mcf": 0.011914,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.011914,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        },
                                                                        {
                                                                            "rowNumber": 3825,
                                                                            "parentRowNumber": 3790,
                                                                            "articleId": "21320515",
                                                                            "description": "21320515-00-Gewindestift",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0023,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0023,
                                                                            "singleQuantityMcf": 0.011914,
                                                                            "mcf": 0.011914,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.011914,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        },
                                                                        {
                                                                            "rowNumber": 3826,
                                                                            "parentRowNumber": 3790,
                                                                            "articleId": "21320515",
                                                                            "description": "21320515-00-Gewindestift",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0023,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0023,
                                                                            "singleQuantityMcf": 0.011914,
                                                                            "mcf": 0.011914,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.011914,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        },
                                                                        {
                                                                            "rowNumber": 3827,
                                                                            "parentRowNumber": 3790,
                                                                            "articleId": "21320515",
                                                                            "description": "21320515-00-Gewindestift",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0023,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0023,
                                                                            "singleQuantityMcf": 0.011914,
                                                                            "mcf": 0.011914,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.011914,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        },
                                                                        {
                                                                            "rowNumber": 3828,
                                                                            "parentRowNumber": 3790,
                                                                            "articleId": "21320515",
                                                                            "description": "21320515-00-Gewindestift",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0023,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0023,
                                                                            "singleQuantityMcf": 0.011914,
                                                                            "mcf": 0.011914,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.011914,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        },
                                                                        {
                                                                            "rowNumber": 3829,
                                                                            "parentRowNumber": 3790,
                                                                            "articleId": "21320515",
                                                                            "description": "21320515-00-Gewindestift",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0023,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0023,
                                                                            "singleQuantityMcf": 0.011914,
                                                                            "mcf": 0.011914,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.011914,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        },
                                                                        {
                                                                            "rowNumber": 3830,
                                                                            "parentRowNumber": 3790,
                                                                            "articleId": "21320515",
                                                                            "description": "21320515-00-Gewindestift",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0023,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0023,
                                                                            "singleQuantityMcf": 0.011914,
                                                                            "mcf": 0.011914,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.011914,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        },
                                                                        {
                                                                            "rowNumber": 3831,
                                                                            "parentRowNumber": 3790,
                                                                            "articleId": "21320515",
                                                                            "description": "21320515-00-Gewindestift",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0023,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0023,
                                                                            "singleQuantityMcf": 0.011914,
                                                                            "mcf": 0.011914,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.011914,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        },
                                                                        {
                                                                            "rowNumber": 3832,
                                                                            "parentRowNumber": 3790,
                                                                            "articleId": "21320515",
                                                                            "description": "21320515-00-Gewindestift",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0023,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0023,
                                                                            "singleQuantityMcf": 0.011914,
                                                                            "mcf": 0.011914,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.011914,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        },
                                                                        {
                                                                            "rowNumber": 3833,
                                                                            "parentRowNumber": 3790,
                                                                            "articleId": "21320515",
                                                                            "description": "21320515-00-Gewindestift",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0023,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0023,
                                                                            "singleQuantityMcf": 0.011914,
                                                                            "mcf": 0.011914,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.011914,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            "rowNumber": 3834,
                                                            "parentRowNumber": 3654,
                                                            "articleId": "47158650",
                                                            "description": "47158650-00-Platte",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.8704,
                                                            "childrenWeight": 0.8704,
                                                            "totalQuantityWeight": 0.8704,
                                                            "singleQuantityMcf": 2.75392,
                                                            "mcf": 2.75392,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 2.75392,
                                                            "expandable": false,
                                                            "children": [
                                                                {
                                                                    "rowNumber": 3835,
                                                                    "parentRowNumber": 3834,
                                                                    "articleId": "162553",
                                                                    "description": "162553-00-Platte",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.8605999999999999,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.8605999999999999,
                                                                    "singleQuantityMcf": 2.75392,
                                                                    "mcf": 2.75392,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 2.75392,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3836,
                                                                    "parentRowNumber": 3834,
                                                                    "articleId": "347204",
                                                                    "description": "347204-00-Ensat-Gewindebuchse",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0049,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0049,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3837,
                                                                    "parentRowNumber": 3834,
                                                                    "articleId": "347204",
                                                                    "description": "347204-00-Ensat-Gewindebuchse",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0049,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0049,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": []
                                                                }
                                                            ]
                                                        }
                                                    ]
                                                }
                                            ]
                                        },
                                        {
                                            "rowNumber": 3838,
                                            "parentRowNumber": 3160,
                                            "articleId": "33847",
                                            "description": "33847-20NAF1-00-Objektführung",
                                            "hierarchy": 4,
                                            "quantity": 1,
                                            "weight": 11.024500000000002,
                                            "childrenWeight": 11.024500000000002,
                                            "totalQuantityWeight": 11.024500000000002,
                                            "singleQuantityMcf": 5.378393999999998,
                                            "mcf": 5.378393999999998,
                                            "singleQuantityLcf": 0,
                                            "lcf": 0,
                                            "totalQuantityMcf": 5.378393999999998,
                                            "expandable": false,
                                            "children": [
                                                {
                                                    "rowNumber": 3839,
                                                    "parentRowNumber": 3838,
                                                    "articleId": "MM0000007408",
                                                    "description": "MM0000007408-00-Objektführung",
                                                    "hierarchy": 5,
                                                    "quantity": 1,
                                                    "weight": 11.024500000000002,
                                                    "childrenWeight": 11.024500000000002,
                                                    "totalQuantityWeight": 11.024500000000002,
                                                    "singleQuantityMcf": 5.378393999999998,
                                                    "mcf": 5.378393999999998,
                                                    "singleQuantityLcf": 0,
                                                    "lcf": 0,
                                                    "totalQuantityMcf": 5.378393999999998,
                                                    "expandable": false,
                                                    "children": [
                                                        {
                                                            "rowNumber": 3840,
                                                            "parentRowNumber": 3839,
                                                            "articleId": "47165445",
                                                            "description": "47165445-00-Drehtellerfuehrungen",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 11.024500000000002,
                                                            "childrenWeight": 11.024500000000002,
                                                            "totalQuantityWeight": 11.024500000000002,
                                                            "singleQuantityMcf": 5.378393999999998,
                                                            "mcf": 5.378393999999998,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 5.378393999999998,
                                                            "expandable": false,
                                                            "children": [
                                                                {
                                                                    "rowNumber": 3841,
                                                                    "parentRowNumber": 3840,
                                                                    "articleId": "20120515",
                                                                    "description": "20120515-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0039,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0039,
                                                                    "singleQuantityMcf": 0.020202,
                                                                    "mcf": 0.020202,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.020202,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3842,
                                                                    "parentRowNumber": 3840,
                                                                    "articleId": "20120515",
                                                                    "description": "20120515-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0039,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0039,
                                                                    "singleQuantityMcf": 0.020202,
                                                                    "mcf": 0.020202,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.020202,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3843,
                                                                    "parentRowNumber": 3840,
                                                                    "articleId": "20120540",
                                                                    "description": "20120540-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0077,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0077,
                                                                    "singleQuantityMcf": 0.039886,
                                                                    "mcf": 0.039886,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.039886,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3844,
                                                                    "parentRowNumber": 3840,
                                                                    "articleId": "20120540",
                                                                    "description": "20120540-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0077,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0077,
                                                                    "singleQuantityMcf": 0.039886,
                                                                    "mcf": 0.039886,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.039886,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3845,
                                                                    "parentRowNumber": 3840,
                                                                    "articleId": "20120540",
                                                                    "description": "20120540-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0077,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0077,
                                                                    "singleQuantityMcf": 0.039886,
                                                                    "mcf": 0.039886,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.039886,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3846,
                                                                    "parentRowNumber": 3840,
                                                                    "articleId": "20120540",
                                                                    "description": "20120540-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0077,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0077,
                                                                    "singleQuantityMcf": 0.039886,
                                                                    "mcf": 0.039886,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.039886,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3847,
                                                                    "parentRowNumber": 3840,
                                                                    "articleId": "20120540",
                                                                    "description": "20120540-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0077,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0077,
                                                                    "singleQuantityMcf": 0.039886,
                                                                    "mcf": 0.039886,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.039886,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3848,
                                                                    "parentRowNumber": 3840,
                                                                    "articleId": "20120540",
                                                                    "description": "20120540-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0077,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0077,
                                                                    "singleQuantityMcf": 0.039886,
                                                                    "mcf": 0.039886,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.039886,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3849,
                                                                    "parentRowNumber": 3840,
                                                                    "articleId": "20120540",
                                                                    "description": "20120540-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0077,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0077,
                                                                    "singleQuantityMcf": 0.039886,
                                                                    "mcf": 0.039886,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.039886,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3850,
                                                                    "parentRowNumber": 3840,
                                                                    "articleId": "20120540",
                                                                    "description": "20120540-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0077,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0077,
                                                                    "singleQuantityMcf": 0.039886,
                                                                    "mcf": 0.039886,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.039886,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3851,
                                                                    "parentRowNumber": 3840,
                                                                    "articleId": "20120540",
                                                                    "description": "20120540-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0077,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0077,
                                                                    "singleQuantityMcf": 0.039886,
                                                                    "mcf": 0.039886,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.039886,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3852,
                                                                    "parentRowNumber": 3840,
                                                                    "articleId": "20120540",
                                                                    "description": "20120540-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0077,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0077,
                                                                    "singleQuantityMcf": 0.039886,
                                                                    "mcf": 0.039886,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.039886,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3853,
                                                                    "parentRowNumber": 3840,
                                                                    "articleId": "20120540",
                                                                    "description": "20120540-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0077,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0077,
                                                                    "singleQuantityMcf": 0.039886,
                                                                    "mcf": 0.039886,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.039886,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3854,
                                                                    "parentRowNumber": 3840,
                                                                    "articleId": "20120540",
                                                                    "description": "20120540-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0077,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0077,
                                                                    "singleQuantityMcf": 0.039886,
                                                                    "mcf": 0.039886,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.039886,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3855,
                                                                    "parentRowNumber": 3840,
                                                                    "articleId": "20120540",
                                                                    "description": "20120540-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0077,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0077,
                                                                    "singleQuantityMcf": 0.039886,
                                                                    "mcf": 0.039886,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.039886,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3856,
                                                                    "parentRowNumber": 3840,
                                                                    "articleId": "20120540",
                                                                    "description": "20120540-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0077,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0077,
                                                                    "singleQuantityMcf": 0.039886,
                                                                    "mcf": 0.039886,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.039886,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3857,
                                                                    "parentRowNumber": 3840,
                                                                    "articleId": "20120540",
                                                                    "description": "20120540-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0077,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0077,
                                                                    "singleQuantityMcf": 0.039886,
                                                                    "mcf": 0.039886,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.039886,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3858,
                                                                    "parentRowNumber": 3840,
                                                                    "articleId": "20120540",
                                                                    "description": "20120540-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0077,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0077,
                                                                    "singleQuantityMcf": 0.039886,
                                                                    "mcf": 0.039886,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.039886,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3859,
                                                                    "parentRowNumber": 3840,
                                                                    "articleId": "20120540",
                                                                    "description": "20120540-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0077,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0077,
                                                                    "singleQuantityMcf": 0.039886,
                                                                    "mcf": 0.039886,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.039886,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3860,
                                                                    "parentRowNumber": 3840,
                                                                    "articleId": "20120540",
                                                                    "description": "20120540-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0077,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0077,
                                                                    "singleQuantityMcf": 0.039886,
                                                                    "mcf": 0.039886,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.039886,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3861,
                                                                    "parentRowNumber": 3840,
                                                                    "articleId": "20120540",
                                                                    "description": "20120540-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0077,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0077,
                                                                    "singleQuantityMcf": 0.039886,
                                                                    "mcf": 0.039886,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.039886,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3862,
                                                                    "parentRowNumber": 3840,
                                                                    "articleId": "20120540",
                                                                    "description": "20120540-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0077,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0077,
                                                                    "singleQuantityMcf": 0.039886,
                                                                    "mcf": 0.039886,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.039886,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3863,
                                                                    "parentRowNumber": 3840,
                                                                    "articleId": "20120540",
                                                                    "description": "20120540-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0077,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0077,
                                                                    "singleQuantityMcf": 0.039886,
                                                                    "mcf": 0.039886,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.039886,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3864,
                                                                    "parentRowNumber": 3840,
                                                                    "articleId": "20120540",
                                                                    "description": "20120540-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0077,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0077,
                                                                    "singleQuantityMcf": 0.039886,
                                                                    "mcf": 0.039886,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.039886,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3865,
                                                                    "parentRowNumber": 3840,
                                                                    "articleId": "20120540",
                                                                    "description": "20120540-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0077,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0077,
                                                                    "singleQuantityMcf": 0.039886,
                                                                    "mcf": 0.039886,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.039886,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3866,
                                                                    "parentRowNumber": 3840,
                                                                    "articleId": "20120540",
                                                                    "description": "20120540-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0077,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0077,
                                                                    "singleQuantityMcf": 0.039886,
                                                                    "mcf": 0.039886,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.039886,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3867,
                                                                    "parentRowNumber": 3840,
                                                                    "articleId": "20120540",
                                                                    "description": "20120540-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0077,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0077,
                                                                    "singleQuantityMcf": 0.039886,
                                                                    "mcf": 0.039886,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.039886,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3868,
                                                                    "parentRowNumber": 3840,
                                                                    "articleId": "20120540",
                                                                    "description": "20120540-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0077,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0077,
                                                                    "singleQuantityMcf": 0.039886,
                                                                    "mcf": 0.039886,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.039886,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3869,
                                                                    "parentRowNumber": 3840,
                                                                    "articleId": "20120540",
                                                                    "description": "20120540-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0077,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0077,
                                                                    "singleQuantityMcf": 0.039886,
                                                                    "mcf": 0.039886,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.039886,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3870,
                                                                    "parentRowNumber": 3840,
                                                                    "articleId": "20120540",
                                                                    "description": "20120540-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0077,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0077,
                                                                    "singleQuantityMcf": 0.039886,
                                                                    "mcf": 0.039886,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.039886,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3871,
                                                                    "parentRowNumber": 3840,
                                                                    "articleId": "208205",
                                                                    "description": "208205-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0004,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0004,
                                                                    "singleQuantityMcf": 0.002072,
                                                                    "mcf": 0.002072,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.002072,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3872,
                                                                    "parentRowNumber": 3840,
                                                                    "articleId": "208205",
                                                                    "description": "208205-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0004,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0004,
                                                                    "singleQuantityMcf": 0.002072,
                                                                    "mcf": 0.002072,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.002072,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3873,
                                                                    "parentRowNumber": 3840,
                                                                    "articleId": "208405",
                                                                    "description": "208405-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0015,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0015,
                                                                    "singleQuantityMcf": 0.00777,
                                                                    "mcf": 0.00777,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.00777,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3874,
                                                                    "parentRowNumber": 3840,
                                                                    "articleId": "208405",
                                                                    "description": "208405-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0015,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0015,
                                                                    "singleQuantityMcf": 0.00777,
                                                                    "mcf": 0.00777,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.00777,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3875,
                                                                    "parentRowNumber": 3840,
                                                                    "articleId": "208405",
                                                                    "description": "208405-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0015,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0015,
                                                                    "singleQuantityMcf": 0.00777,
                                                                    "mcf": 0.00777,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.00777,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3876,
                                                                    "parentRowNumber": 3840,
                                                                    "articleId": "208405",
                                                                    "description": "208405-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0015,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0015,
                                                                    "singleQuantityMcf": 0.00777,
                                                                    "mcf": 0.00777,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.00777,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3877,
                                                                    "parentRowNumber": 3840,
                                                                    "articleId": "208405",
                                                                    "description": "208405-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0015,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0015,
                                                                    "singleQuantityMcf": 0.00777,
                                                                    "mcf": 0.00777,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.00777,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3878,
                                                                    "parentRowNumber": 3840,
                                                                    "articleId": "208405",
                                                                    "description": "208405-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0015,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0015,
                                                                    "singleQuantityMcf": 0.00777,
                                                                    "mcf": 0.00777,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.00777,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3879,
                                                                    "parentRowNumber": 3840,
                                                                    "articleId": "208405",
                                                                    "description": "208405-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0015,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0015,
                                                                    "singleQuantityMcf": 0.00777,
                                                                    "mcf": 0.00777,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.00777,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3880,
                                                                    "parentRowNumber": 3840,
                                                                    "articleId": "208405",
                                                                    "description": "208405-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0015,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0015,
                                                                    "singleQuantityMcf": 0.00777,
                                                                    "mcf": 0.00777,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.00777,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3881,
                                                                    "parentRowNumber": 3840,
                                                                    "articleId": "208405",
                                                                    "description": "208405-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0015,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0015,
                                                                    "singleQuantityMcf": 0.00777,
                                                                    "mcf": 0.00777,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.00777,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3882,
                                                                    "parentRowNumber": 3840,
                                                                    "articleId": "208405",
                                                                    "description": "208405-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0015,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0015,
                                                                    "singleQuantityMcf": 0.00777,
                                                                    "mcf": 0.00777,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.00777,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3883,
                                                                    "parentRowNumber": 3840,
                                                                    "articleId": "208405",
                                                                    "description": "208405-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0015,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0015,
                                                                    "singleQuantityMcf": 0.00777,
                                                                    "mcf": 0.00777,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.00777,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3884,
                                                                    "parentRowNumber": 3840,
                                                                    "articleId": "208405",
                                                                    "description": "208405-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0015,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0015,
                                                                    "singleQuantityMcf": 0.00777,
                                                                    "mcf": 0.00777,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.00777,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3885,
                                                                    "parentRowNumber": 3840,
                                                                    "articleId": "208405",
                                                                    "description": "208405-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0015,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0015,
                                                                    "singleQuantityMcf": 0.00777,
                                                                    "mcf": 0.00777,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.00777,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3886,
                                                                    "parentRowNumber": 3840,
                                                                    "articleId": "208405",
                                                                    "description": "208405-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0015,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0015,
                                                                    "singleQuantityMcf": 0.00777,
                                                                    "mcf": 0.00777,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.00777,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3887,
                                                                    "parentRowNumber": 3840,
                                                                    "articleId": "208405",
                                                                    "description": "208405-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0015,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0015,
                                                                    "singleQuantityMcf": 0.00777,
                                                                    "mcf": 0.00777,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.00777,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3888,
                                                                    "parentRowNumber": 3840,
                                                                    "articleId": "208405",
                                                                    "description": "208405-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0015,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0015,
                                                                    "singleQuantityMcf": 0.00777,
                                                                    "mcf": 0.00777,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.00777,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3889,
                                                                    "parentRowNumber": 3840,
                                                                    "articleId": "208405",
                                                                    "description": "208405-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0015,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0015,
                                                                    "singleQuantityMcf": 0.00777,
                                                                    "mcf": 0.00777,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.00777,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3890,
                                                                    "parentRowNumber": 3840,
                                                                    "articleId": "208405",
                                                                    "description": "208405-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0015,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0015,
                                                                    "singleQuantityMcf": 0.00777,
                                                                    "mcf": 0.00777,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.00777,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3891,
                                                                    "parentRowNumber": 3840,
                                                                    "articleId": "208405",
                                                                    "description": "208405-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0015,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0015,
                                                                    "singleQuantityMcf": 0.00777,
                                                                    "mcf": 0.00777,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.00777,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3892,
                                                                    "parentRowNumber": 3840,
                                                                    "articleId": "208405",
                                                                    "description": "208405-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0015,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0015,
                                                                    "singleQuantityMcf": 0.00777,
                                                                    "mcf": 0.00777,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.00777,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3893,
                                                                    "parentRowNumber": 3840,
                                                                    "articleId": "208405",
                                                                    "description": "208405-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0015,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0015,
                                                                    "singleQuantityMcf": 0.00777,
                                                                    "mcf": 0.00777,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.00777,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3894,
                                                                    "parentRowNumber": 3840,
                                                                    "articleId": "208405",
                                                                    "description": "208405-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0015,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0015,
                                                                    "singleQuantityMcf": 0.00777,
                                                                    "mcf": 0.00777,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.00777,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3895,
                                                                    "parentRowNumber": 3840,
                                                                    "articleId": "208405",
                                                                    "description": "208405-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0015,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0015,
                                                                    "singleQuantityMcf": 0.00777,
                                                                    "mcf": 0.00777,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.00777,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3896,
                                                                    "parentRowNumber": 3840,
                                                                    "articleId": "208405",
                                                                    "description": "208405-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0015,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0015,
                                                                    "singleQuantityMcf": 0.00777,
                                                                    "mcf": 0.00777,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.00777,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3897,
                                                                    "parentRowNumber": 3840,
                                                                    "articleId": "208405",
                                                                    "description": "208405-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0015,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0015,
                                                                    "singleQuantityMcf": 0.00777,
                                                                    "mcf": 0.00777,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.00777,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3898,
                                                                    "parentRowNumber": 3840,
                                                                    "articleId": "208405",
                                                                    "description": "208405-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0015,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0015,
                                                                    "singleQuantityMcf": 0.00777,
                                                                    "mcf": 0.00777,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.00777,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3899,
                                                                    "parentRowNumber": 3840,
                                                                    "articleId": "208405",
                                                                    "description": "208405-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0015,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0015,
                                                                    "singleQuantityMcf": 0.00777,
                                                                    "mcf": 0.00777,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.00777,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3900,
                                                                    "parentRowNumber": 3840,
                                                                    "articleId": "208405",
                                                                    "description": "208405-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0015,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0015,
                                                                    "singleQuantityMcf": 0.00777,
                                                                    "mcf": 0.00777,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.00777,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3901,
                                                                    "parentRowNumber": 3840,
                                                                    "articleId": "46645927",
                                                                    "description": "46645927-00-Formatbuegel 2.0",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.1103,
                                                                    "childrenWeight": 0.1103,
                                                                    "totalQuantityWeight": 0.1103,
                                                                    "singleQuantityMcf": 0.5713539999999999,
                                                                    "mcf": 0.5713539999999999,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.5713539999999999,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 3902,
                                                                            "parentRowNumber": 3901,
                                                                            "articleId": "142612",
                                                                            "description": "142612-00-Edelstahlblech",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0515,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0515,
                                                                            "singleQuantityMcf": 0.26676999999999995,
                                                                            "mcf": 0.26676999999999995,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.26676999999999995,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        },
                                                                        {
                                                                            "rowNumber": 3903,
                                                                            "parentRowNumber": 3901,
                                                                            "articleId": "46647477",
                                                                            "description": "46647477-00-Halter",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0294,
                                                                            "childrenWeight": 0.0294,
                                                                            "totalQuantityWeight": 0.0294,
                                                                            "singleQuantityMcf": 0.15229199999999998,
                                                                            "mcf": 0.15229199999999998,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.15229199999999998,
                                                                            "expandable": false,
                                                                            "children": [
                                                                                {
                                                                                    "rowNumber": 3904,
                                                                                    "parentRowNumber": 3903,
                                                                                    "articleId": "1110231",
                                                                                    "description": "1110231-00-Flachstahl",
                                                                                    "hierarchy": 9,
                                                                                    "quantity": 1,
                                                                                    "weight": 0.0294,
                                                                                    "childrenWeight": 0,
                                                                                    "totalQuantityWeight": 0.0294,
                                                                                    "singleQuantityMcf": 0.15229199999999998,
                                                                                    "mcf": 0.15229199999999998,
                                                                                    "singleQuantityLcf": 0,
                                                                                    "lcf": 0,
                                                                                    "totalQuantityMcf": 0.15229199999999998,
                                                                                    "expandable": false,
                                                                                    "children": []
                                                                                }
                                                                            ]
                                                                        },
                                                                        {
                                                                            "rowNumber": 3905,
                                                                            "parentRowNumber": 3901,
                                                                            "articleId": "46647477",
                                                                            "description": "46647477-00-Halter",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0294,
                                                                            "childrenWeight": 0.0294,
                                                                            "totalQuantityWeight": 0.0294,
                                                                            "singleQuantityMcf": 0.15229199999999998,
                                                                            "mcf": 0.15229199999999998,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.15229199999999998,
                                                                            "expandable": false,
                                                                            "children": [
                                                                                {
                                                                                    "rowNumber": 3906,
                                                                                    "parentRowNumber": 3905,
                                                                                    "articleId": "1110231",
                                                                                    "description": "1110231-00-Flachstahl",
                                                                                    "hierarchy": 9,
                                                                                    "quantity": 1,
                                                                                    "weight": 0.0294,
                                                                                    "childrenWeight": 0,
                                                                                    "totalQuantityWeight": 0.0294,
                                                                                    "singleQuantityMcf": 0.15229199999999998,
                                                                                    "mcf": 0.15229199999999998,
                                                                                    "singleQuantityLcf": 0,
                                                                                    "lcf": 0,
                                                                                    "totalQuantityMcf": 0.15229199999999998,
                                                                                    "expandable": false,
                                                                                    "children": []
                                                                                }
                                                                            ]
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 3907,
                                                                    "parentRowNumber": 3840,
                                                                    "articleId": "46645927",
                                                                    "description": "46645927-00-Formatbuegel 2.0",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.1103,
                                                                    "childrenWeight": 0.1103,
                                                                    "totalQuantityWeight": 0.1103,
                                                                    "singleQuantityMcf": 0.5713539999999999,
                                                                    "mcf": 0.5713539999999999,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.5713539999999999,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 3908,
                                                                            "parentRowNumber": 3907,
                                                                            "articleId": "142612",
                                                                            "description": "142612-00-Edelstahlblech",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0515,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0515,
                                                                            "singleQuantityMcf": 0.26676999999999995,
                                                                            "mcf": 0.26676999999999995,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.26676999999999995,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        },
                                                                        {
                                                                            "rowNumber": 3909,
                                                                            "parentRowNumber": 3907,
                                                                            "articleId": "46647477",
                                                                            "description": "46647477-00-Halter",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0294,
                                                                            "childrenWeight": 0.0294,
                                                                            "totalQuantityWeight": 0.0294,
                                                                            "singleQuantityMcf": 0.15229199999999998,
                                                                            "mcf": 0.15229199999999998,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.15229199999999998,
                                                                            "expandable": false,
                                                                            "children": [
                                                                                {
                                                                                    "rowNumber": 3910,
                                                                                    "parentRowNumber": 3909,
                                                                                    "articleId": "1110231",
                                                                                    "description": "1110231-00-Flachstahl",
                                                                                    "hierarchy": 9,
                                                                                    "quantity": 1,
                                                                                    "weight": 0.0294,
                                                                                    "childrenWeight": 0,
                                                                                    "totalQuantityWeight": 0.0294,
                                                                                    "singleQuantityMcf": 0.15229199999999998,
                                                                                    "mcf": 0.15229199999999998,
                                                                                    "singleQuantityLcf": 0,
                                                                                    "lcf": 0,
                                                                                    "totalQuantityMcf": 0.15229199999999998,
                                                                                    "expandable": false,
                                                                                    "children": []
                                                                                }
                                                                            ]
                                                                        },
                                                                        {
                                                                            "rowNumber": 3911,
                                                                            "parentRowNumber": 3907,
                                                                            "articleId": "46647477",
                                                                            "description": "46647477-00-Halter",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0294,
                                                                            "childrenWeight": 0.0294,
                                                                            "totalQuantityWeight": 0.0294,
                                                                            "singleQuantityMcf": 0.15229199999999998,
                                                                            "mcf": 0.15229199999999998,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.15229199999999998,
                                                                            "expandable": false,
                                                                            "children": [
                                                                                {
                                                                                    "rowNumber": 3912,
                                                                                    "parentRowNumber": 3911,
                                                                                    "articleId": "1110231",
                                                                                    "description": "1110231-00-Flachstahl",
                                                                                    "hierarchy": 9,
                                                                                    "quantity": 1,
                                                                                    "weight": 0.0294,
                                                                                    "childrenWeight": 0,
                                                                                    "totalQuantityWeight": 0.0294,
                                                                                    "singleQuantityMcf": 0.15229199999999998,
                                                                                    "mcf": 0.15229199999999998,
                                                                                    "singleQuantityLcf": 0,
                                                                                    "lcf": 0,
                                                                                    "totalQuantityMcf": 0.15229199999999998,
                                                                                    "expandable": false,
                                                                                    "children": []
                                                                                }
                                                                            ]
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 3913,
                                                                    "parentRowNumber": 3840,
                                                                    "articleId": "46645927",
                                                                    "description": "46645927-00-Formatbuegel 2.0",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.1103,
                                                                    "childrenWeight": 0.1103,
                                                                    "totalQuantityWeight": 0.1103,
                                                                    "singleQuantityMcf": 0.5713539999999999,
                                                                    "mcf": 0.5713539999999999,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.5713539999999999,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 3914,
                                                                            "parentRowNumber": 3913,
                                                                            "articleId": "142612",
                                                                            "description": "142612-00-Edelstahlblech",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0515,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0515,
                                                                            "singleQuantityMcf": 0.26676999999999995,
                                                                            "mcf": 0.26676999999999995,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.26676999999999995,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        },
                                                                        {
                                                                            "rowNumber": 3915,
                                                                            "parentRowNumber": 3913,
                                                                            "articleId": "46647477",
                                                                            "description": "46647477-00-Halter",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0294,
                                                                            "childrenWeight": 0.0294,
                                                                            "totalQuantityWeight": 0.0294,
                                                                            "singleQuantityMcf": 0.15229199999999998,
                                                                            "mcf": 0.15229199999999998,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.15229199999999998,
                                                                            "expandable": false,
                                                                            "children": [
                                                                                {
                                                                                    "rowNumber": 3916,
                                                                                    "parentRowNumber": 3915,
                                                                                    "articleId": "1110231",
                                                                                    "description": "1110231-00-Flachstahl",
                                                                                    "hierarchy": 9,
                                                                                    "quantity": 1,
                                                                                    "weight": 0.0294,
                                                                                    "childrenWeight": 0,
                                                                                    "totalQuantityWeight": 0.0294,
                                                                                    "singleQuantityMcf": 0.15229199999999998,
                                                                                    "mcf": 0.15229199999999998,
                                                                                    "singleQuantityLcf": 0,
                                                                                    "lcf": 0,
                                                                                    "totalQuantityMcf": 0.15229199999999998,
                                                                                    "expandable": false,
                                                                                    "children": []
                                                                                }
                                                                            ]
                                                                        },
                                                                        {
                                                                            "rowNumber": 3917,
                                                                            "parentRowNumber": 3913,
                                                                            "articleId": "46647477",
                                                                            "description": "46647477-00-Halter",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0294,
                                                                            "childrenWeight": 0.0294,
                                                                            "totalQuantityWeight": 0.0294,
                                                                            "singleQuantityMcf": 0.15229199999999998,
                                                                            "mcf": 0.15229199999999998,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.15229199999999998,
                                                                            "expandable": false,
                                                                            "children": [
                                                                                {
                                                                                    "rowNumber": 3918,
                                                                                    "parentRowNumber": 3917,
                                                                                    "articleId": "1110231",
                                                                                    "description": "1110231-00-Flachstahl",
                                                                                    "hierarchy": 9,
                                                                                    "quantity": 1,
                                                                                    "weight": 0.0294,
                                                                                    "childrenWeight": 0,
                                                                                    "totalQuantityWeight": 0.0294,
                                                                                    "singleQuantityMcf": 0.15229199999999998,
                                                                                    "mcf": 0.15229199999999998,
                                                                                    "singleQuantityLcf": 0,
                                                                                    "lcf": 0,
                                                                                    "totalQuantityMcf": 0.15229199999999998,
                                                                                    "expandable": false,
                                                                                    "children": []
                                                                                }
                                                                            ]
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 3919,
                                                                    "parentRowNumber": 3840,
                                                                    "articleId": "46645927",
                                                                    "description": "46645927-00-Formatbuegel 2.0",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.1103,
                                                                    "childrenWeight": 0.1103,
                                                                    "totalQuantityWeight": 0.1103,
                                                                    "singleQuantityMcf": 0.5713539999999999,
                                                                    "mcf": 0.5713539999999999,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.5713539999999999,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 3920,
                                                                            "parentRowNumber": 3919,
                                                                            "articleId": "142612",
                                                                            "description": "142612-00-Edelstahlblech",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0515,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0515,
                                                                            "singleQuantityMcf": 0.26676999999999995,
                                                                            "mcf": 0.26676999999999995,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.26676999999999995,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        },
                                                                        {
                                                                            "rowNumber": 3921,
                                                                            "parentRowNumber": 3919,
                                                                            "articleId": "46647477",
                                                                            "description": "46647477-00-Halter",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0294,
                                                                            "childrenWeight": 0.0294,
                                                                            "totalQuantityWeight": 0.0294,
                                                                            "singleQuantityMcf": 0.15229199999999998,
                                                                            "mcf": 0.15229199999999998,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.15229199999999998,
                                                                            "expandable": false,
                                                                            "children": [
                                                                                {
                                                                                    "rowNumber": 3922,
                                                                                    "parentRowNumber": 3921,
                                                                                    "articleId": "1110231",
                                                                                    "description": "1110231-00-Flachstahl",
                                                                                    "hierarchy": 9,
                                                                                    "quantity": 1,
                                                                                    "weight": 0.0294,
                                                                                    "childrenWeight": 0,
                                                                                    "totalQuantityWeight": 0.0294,
                                                                                    "singleQuantityMcf": 0.15229199999999998,
                                                                                    "mcf": 0.15229199999999998,
                                                                                    "singleQuantityLcf": 0,
                                                                                    "lcf": 0,
                                                                                    "totalQuantityMcf": 0.15229199999999998,
                                                                                    "expandable": false,
                                                                                    "children": []
                                                                                }
                                                                            ]
                                                                        },
                                                                        {
                                                                            "rowNumber": 3923,
                                                                            "parentRowNumber": 3919,
                                                                            "articleId": "46647477",
                                                                            "description": "46647477-00-Halter",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0294,
                                                                            "childrenWeight": 0.0294,
                                                                            "totalQuantityWeight": 0.0294,
                                                                            "singleQuantityMcf": 0.15229199999999998,
                                                                            "mcf": 0.15229199999999998,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.15229199999999998,
                                                                            "expandable": false,
                                                                            "children": [
                                                                                {
                                                                                    "rowNumber": 3924,
                                                                                    "parentRowNumber": 3923,
                                                                                    "articleId": "1110231",
                                                                                    "description": "1110231-00-Flachstahl",
                                                                                    "hierarchy": 9,
                                                                                    "quantity": 1,
                                                                                    "weight": 0.0294,
                                                                                    "childrenWeight": 0,
                                                                                    "totalQuantityWeight": 0.0294,
                                                                                    "singleQuantityMcf": 0.15229199999999998,
                                                                                    "mcf": 0.15229199999999998,
                                                                                    "singleQuantityLcf": 0,
                                                                                    "lcf": 0,
                                                                                    "totalQuantityMcf": 0.15229199999999998,
                                                                                    "expandable": false,
                                                                                    "children": []
                                                                                }
                                                                            ]
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 3925,
                                                                    "parentRowNumber": 3840,
                                                                    "articleId": "46645927",
                                                                    "description": "46645927-00-Formatbuegel 2.0",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.1103,
                                                                    "childrenWeight": 0.1103,
                                                                    "totalQuantityWeight": 0.1103,
                                                                    "singleQuantityMcf": 0.5713539999999999,
                                                                    "mcf": 0.5713539999999999,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.5713539999999999,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 3926,
                                                                            "parentRowNumber": 3925,
                                                                            "articleId": "142612",
                                                                            "description": "142612-00-Edelstahlblech",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0515,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0515,
                                                                            "singleQuantityMcf": 0.26676999999999995,
                                                                            "mcf": 0.26676999999999995,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.26676999999999995,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        },
                                                                        {
                                                                            "rowNumber": 3927,
                                                                            "parentRowNumber": 3925,
                                                                            "articleId": "46647477",
                                                                            "description": "46647477-00-Halter",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0294,
                                                                            "childrenWeight": 0.0294,
                                                                            "totalQuantityWeight": 0.0294,
                                                                            "singleQuantityMcf": 0.15229199999999998,
                                                                            "mcf": 0.15229199999999998,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.15229199999999998,
                                                                            "expandable": false,
                                                                            "children": [
                                                                                {
                                                                                    "rowNumber": 3928,
                                                                                    "parentRowNumber": 3927,
                                                                                    "articleId": "1110231",
                                                                                    "description": "1110231-00-Flachstahl",
                                                                                    "hierarchy": 9,
                                                                                    "quantity": 1,
                                                                                    "weight": 0.0294,
                                                                                    "childrenWeight": 0,
                                                                                    "totalQuantityWeight": 0.0294,
                                                                                    "singleQuantityMcf": 0.15229199999999998,
                                                                                    "mcf": 0.15229199999999998,
                                                                                    "singleQuantityLcf": 0,
                                                                                    "lcf": 0,
                                                                                    "totalQuantityMcf": 0.15229199999999998,
                                                                                    "expandable": false,
                                                                                    "children": []
                                                                                }
                                                                            ]
                                                                        },
                                                                        {
                                                                            "rowNumber": 3929,
                                                                            "parentRowNumber": 3925,
                                                                            "articleId": "46647477",
                                                                            "description": "46647477-00-Halter",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0294,
                                                                            "childrenWeight": 0.0294,
                                                                            "totalQuantityWeight": 0.0294,
                                                                            "singleQuantityMcf": 0.15229199999999998,
                                                                            "mcf": 0.15229199999999998,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.15229199999999998,
                                                                            "expandable": false,
                                                                            "children": [
                                                                                {
                                                                                    "rowNumber": 3930,
                                                                                    "parentRowNumber": 3929,
                                                                                    "articleId": "1110231",
                                                                                    "description": "1110231-00-Flachstahl",
                                                                                    "hierarchy": 9,
                                                                                    "quantity": 1,
                                                                                    "weight": 0.0294,
                                                                                    "childrenWeight": 0,
                                                                                    "totalQuantityWeight": 0.0294,
                                                                                    "singleQuantityMcf": 0.15229199999999998,
                                                                                    "mcf": 0.15229199999999998,
                                                                                    "singleQuantityLcf": 0,
                                                                                    "lcf": 0,
                                                                                    "totalQuantityMcf": 0.15229199999999998,
                                                                                    "expandable": false,
                                                                                    "children": []
                                                                                }
                                                                            ]
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 3931,
                                                                    "parentRowNumber": 3840,
                                                                    "articleId": "46645927",
                                                                    "description": "46645927-00-Formatbuegel 2.0",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.1103,
                                                                    "childrenWeight": 0.1103,
                                                                    "totalQuantityWeight": 0.1103,
                                                                    "singleQuantityMcf": 0.5713539999999999,
                                                                    "mcf": 0.5713539999999999,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.5713539999999999,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 3932,
                                                                            "parentRowNumber": 3931,
                                                                            "articleId": "142612",
                                                                            "description": "142612-00-Edelstahlblech",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0515,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0515,
                                                                            "singleQuantityMcf": 0.26676999999999995,
                                                                            "mcf": 0.26676999999999995,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.26676999999999995,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        },
                                                                        {
                                                                            "rowNumber": 3933,
                                                                            "parentRowNumber": 3931,
                                                                            "articleId": "46647477",
                                                                            "description": "46647477-00-Halter",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0294,
                                                                            "childrenWeight": 0.0294,
                                                                            "totalQuantityWeight": 0.0294,
                                                                            "singleQuantityMcf": 0.15229199999999998,
                                                                            "mcf": 0.15229199999999998,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.15229199999999998,
                                                                            "expandable": false,
                                                                            "children": [
                                                                                {
                                                                                    "rowNumber": 3934,
                                                                                    "parentRowNumber": 3933,
                                                                                    "articleId": "1110231",
                                                                                    "description": "1110231-00-Flachstahl",
                                                                                    "hierarchy": 9,
                                                                                    "quantity": 1,
                                                                                    "weight": 0.0294,
                                                                                    "childrenWeight": 0,
                                                                                    "totalQuantityWeight": 0.0294,
                                                                                    "singleQuantityMcf": 0.15229199999999998,
                                                                                    "mcf": 0.15229199999999998,
                                                                                    "singleQuantityLcf": 0,
                                                                                    "lcf": 0,
                                                                                    "totalQuantityMcf": 0.15229199999999998,
                                                                                    "expandable": false,
                                                                                    "children": []
                                                                                }
                                                                            ]
                                                                        },
                                                                        {
                                                                            "rowNumber": 3935,
                                                                            "parentRowNumber": 3931,
                                                                            "articleId": "46647477",
                                                                            "description": "46647477-00-Halter",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0294,
                                                                            "childrenWeight": 0.0294,
                                                                            "totalQuantityWeight": 0.0294,
                                                                            "singleQuantityMcf": 0.15229199999999998,
                                                                            "mcf": 0.15229199999999998,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.15229199999999998,
                                                                            "expandable": false,
                                                                            "children": [
                                                                                {
                                                                                    "rowNumber": 3936,
                                                                                    "parentRowNumber": 3935,
                                                                                    "articleId": "1110231",
                                                                                    "description": "1110231-00-Flachstahl",
                                                                                    "hierarchy": 9,
                                                                                    "quantity": 1,
                                                                                    "weight": 0.0294,
                                                                                    "childrenWeight": 0,
                                                                                    "totalQuantityWeight": 0.0294,
                                                                                    "singleQuantityMcf": 0.15229199999999998,
                                                                                    "mcf": 0.15229199999999998,
                                                                                    "singleQuantityLcf": 0,
                                                                                    "lcf": 0,
                                                                                    "totalQuantityMcf": 0.15229199999999998,
                                                                                    "expandable": false,
                                                                                    "children": []
                                                                                }
                                                                            ]
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 3937,
                                                                    "parentRowNumber": 3840,
                                                                    "articleId": "46645927",
                                                                    "description": "46645927-00-Formatbuegel 2.0",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.1103,
                                                                    "childrenWeight": 0.1103,
                                                                    "totalQuantityWeight": 0.1103,
                                                                    "singleQuantityMcf": 0.5713539999999999,
                                                                    "mcf": 0.5713539999999999,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.5713539999999999,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 3938,
                                                                            "parentRowNumber": 3937,
                                                                            "articleId": "142612",
                                                                            "description": "142612-00-Edelstahlblech",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0515,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0515,
                                                                            "singleQuantityMcf": 0.26676999999999995,
                                                                            "mcf": 0.26676999999999995,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.26676999999999995,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        },
                                                                        {
                                                                            "rowNumber": 3939,
                                                                            "parentRowNumber": 3937,
                                                                            "articleId": "46647477",
                                                                            "description": "46647477-00-Halter",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0294,
                                                                            "childrenWeight": 0.0294,
                                                                            "totalQuantityWeight": 0.0294,
                                                                            "singleQuantityMcf": 0.15229199999999998,
                                                                            "mcf": 0.15229199999999998,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.15229199999999998,
                                                                            "expandable": false,
                                                                            "children": [
                                                                                {
                                                                                    "rowNumber": 3940,
                                                                                    "parentRowNumber": 3939,
                                                                                    "articleId": "1110231",
                                                                                    "description": "1110231-00-Flachstahl",
                                                                                    "hierarchy": 9,
                                                                                    "quantity": 1,
                                                                                    "weight": 0.0294,
                                                                                    "childrenWeight": 0,
                                                                                    "totalQuantityWeight": 0.0294,
                                                                                    "singleQuantityMcf": 0.15229199999999998,
                                                                                    "mcf": 0.15229199999999998,
                                                                                    "singleQuantityLcf": 0,
                                                                                    "lcf": 0,
                                                                                    "totalQuantityMcf": 0.15229199999999998,
                                                                                    "expandable": false,
                                                                                    "children": []
                                                                                }
                                                                            ]
                                                                        },
                                                                        {
                                                                            "rowNumber": 3941,
                                                                            "parentRowNumber": 3937,
                                                                            "articleId": "46647477",
                                                                            "description": "46647477-00-Halter",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0294,
                                                                            "childrenWeight": 0.0294,
                                                                            "totalQuantityWeight": 0.0294,
                                                                            "singleQuantityMcf": 0.15229199999999998,
                                                                            "mcf": 0.15229199999999998,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.15229199999999998,
                                                                            "expandable": false,
                                                                            "children": [
                                                                                {
                                                                                    "rowNumber": 3942,
                                                                                    "parentRowNumber": 3941,
                                                                                    "articleId": "1110231",
                                                                                    "description": "1110231-00-Flachstahl",
                                                                                    "hierarchy": 9,
                                                                                    "quantity": 1,
                                                                                    "weight": 0.0294,
                                                                                    "childrenWeight": 0,
                                                                                    "totalQuantityWeight": 0.0294,
                                                                                    "singleQuantityMcf": 0.15229199999999998,
                                                                                    "mcf": 0.15229199999999998,
                                                                                    "singleQuantityLcf": 0,
                                                                                    "lcf": 0,
                                                                                    "totalQuantityMcf": 0.15229199999999998,
                                                                                    "expandable": false,
                                                                                    "children": []
                                                                                }
                                                                            ]
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 3943,
                                                                    "parentRowNumber": 3840,
                                                                    "articleId": "47158994",
                                                                    "description": "47158994-00-Drehtellerfuehrungen",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 2.8195,
                                                                    "childrenWeight": 2.8195,
                                                                    "totalQuantityWeight": 2.8195,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 3944,
                                                                            "parentRowNumber": 3943,
                                                                            "articleId": "162518",
                                                                            "description": "162518-01-Platte",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 2.8195,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 2.8195,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 3945,
                                                                    "parentRowNumber": 3840,
                                                                    "articleId": "47159003",
                                                                    "description": "47159003-00-Drehtellerfuehrungen",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 7.0214,
                                                                    "childrenWeight": 7.0214,
                                                                    "totalQuantityWeight": 7.0214,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 3946,
                                                                            "parentRowNumber": 3945,
                                                                            "articleId": "162518",
                                                                            "description": "162518-01-Platte",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 7.0164,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 7.0164,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        },
                                                                        {
                                                                            "rowNumber": 3947,
                                                                            "parentRowNumber": 3945,
                                                                            "articleId": "347200",
                                                                            "description": "347200-00-Ensat-Gewindebuchse",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0025,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0025,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        },
                                                                        {
                                                                            "rowNumber": 3948,
                                                                            "parentRowNumber": 3945,
                                                                            "articleId": "347200",
                                                                            "description": "347200-00-Ensat-Gewindebuchse",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0025,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0025,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 3949,
                                                                    "parentRowNumber": 3840,
                                                                    "articleId": "47165609",
                                                                    "description": "47165609-00-Objektfuehrung",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0766,
                                                                    "childrenWeight": 0.0766,
                                                                    "totalQuantityWeight": 0.0766,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 3950,
                                                                            "parentRowNumber": 3949,
                                                                            "articleId": "162507",
                                                                            "description": "162507-01-Platte",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0766,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0766,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 3951,
                                                                    "parentRowNumber": 3840,
                                                                    "articleId": "MSD31528_Objekt1",
                                                                    "description": "MSD31528_Objekt1-00",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0229,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0229,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3952,
                                                                    "parentRowNumber": 3840,
                                                                    "articleId": "MSD31528_Objekt1",
                                                                    "description": "MSD31528_Objekt1-00",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0229,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0229,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3953,
                                                                    "parentRowNumber": 3840,
                                                                    "articleId": "MSD31528_Objekt1",
                                                                    "description": "MSD31528_Objekt1-00",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0229,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0229,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": []
                                                                }
                                                            ]
                                                        }
                                                    ]
                                                }
                                            ]
                                        },
                                        {
                                            "rowNumber": 3954,
                                            "parentRowNumber": 3160,
                                            "articleId": "33847",
                                            "description": "33847-20NAF2-00-Objektführung",
                                            "hierarchy": 4,
                                            "quantity": 1,
                                            "weight": 11.425599999999996,
                                            "childrenWeight": 11.425599999999996,
                                            "totalQuantityWeight": 11.425599999999996,
                                            "singleQuantityMcf": 5.748245999999998,
                                            "mcf": 5.748245999999998,
                                            "singleQuantityLcf": 0,
                                            "lcf": 0,
                                            "totalQuantityMcf": 5.748245999999998,
                                            "expandable": false,
                                            "children": [
                                                {
                                                    "rowNumber": 3955,
                                                    "parentRowNumber": 3954,
                                                    "articleId": "MM0000009696",
                                                    "description": "MM0000009696-00-Objektführung",
                                                    "hierarchy": 5,
                                                    "quantity": 1,
                                                    "weight": 11.425599999999996,
                                                    "childrenWeight": 11.425599999999996,
                                                    "totalQuantityWeight": 11.425599999999996,
                                                    "singleQuantityMcf": 5.748245999999998,
                                                    "mcf": 5.748245999999998,
                                                    "singleQuantityLcf": 0,
                                                    "lcf": 0,
                                                    "totalQuantityMcf": 5.748245999999998,
                                                    "expandable": false,
                                                    "children": [
                                                        {
                                                            "rowNumber": 3956,
                                                            "parentRowNumber": 3955,
                                                            "articleId": "47238059",
                                                            "description": "47238059-00-Drehtellerfuehrungen",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 11.425599999999996,
                                                            "childrenWeight": 11.425599999999996,
                                                            "totalQuantityWeight": 11.425599999999996,
                                                            "singleQuantityMcf": 5.748245999999998,
                                                            "mcf": 5.748245999999998,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 5.748245999999998,
                                                            "expandable": false,
                                                            "children": [
                                                                {
                                                                    "rowNumber": 3957,
                                                                    "parentRowNumber": 3956,
                                                                    "articleId": "20120515",
                                                                    "description": "20120515-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0039,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0039,
                                                                    "singleQuantityMcf": 0.020202,
                                                                    "mcf": 0.020202,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.020202,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3958,
                                                                    "parentRowNumber": 3956,
                                                                    "articleId": "20120515",
                                                                    "description": "20120515-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0039,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0039,
                                                                    "singleQuantityMcf": 0.020202,
                                                                    "mcf": 0.020202,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.020202,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3959,
                                                                    "parentRowNumber": 3956,
                                                                    "articleId": "20120540",
                                                                    "description": "20120540-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0077,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0077,
                                                                    "singleQuantityMcf": 0.039886,
                                                                    "mcf": 0.039886,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.039886,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3960,
                                                                    "parentRowNumber": 3956,
                                                                    "articleId": "20120540",
                                                                    "description": "20120540-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0077,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0077,
                                                                    "singleQuantityMcf": 0.039886,
                                                                    "mcf": 0.039886,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.039886,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3961,
                                                                    "parentRowNumber": 3956,
                                                                    "articleId": "20120540",
                                                                    "description": "20120540-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0077,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0077,
                                                                    "singleQuantityMcf": 0.039886,
                                                                    "mcf": 0.039886,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.039886,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3962,
                                                                    "parentRowNumber": 3956,
                                                                    "articleId": "20120540",
                                                                    "description": "20120540-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0077,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0077,
                                                                    "singleQuantityMcf": 0.039886,
                                                                    "mcf": 0.039886,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.039886,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3963,
                                                                    "parentRowNumber": 3956,
                                                                    "articleId": "20120540",
                                                                    "description": "20120540-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0077,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0077,
                                                                    "singleQuantityMcf": 0.039886,
                                                                    "mcf": 0.039886,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.039886,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3964,
                                                                    "parentRowNumber": 3956,
                                                                    "articleId": "20120540",
                                                                    "description": "20120540-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0077,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0077,
                                                                    "singleQuantityMcf": 0.039886,
                                                                    "mcf": 0.039886,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.039886,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3965,
                                                                    "parentRowNumber": 3956,
                                                                    "articleId": "20120540",
                                                                    "description": "20120540-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0077,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0077,
                                                                    "singleQuantityMcf": 0.039886,
                                                                    "mcf": 0.039886,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.039886,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3966,
                                                                    "parentRowNumber": 3956,
                                                                    "articleId": "20120540",
                                                                    "description": "20120540-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0077,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0077,
                                                                    "singleQuantityMcf": 0.039886,
                                                                    "mcf": 0.039886,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.039886,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3967,
                                                                    "parentRowNumber": 3956,
                                                                    "articleId": "20120540",
                                                                    "description": "20120540-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0077,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0077,
                                                                    "singleQuantityMcf": 0.039886,
                                                                    "mcf": 0.039886,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.039886,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3968,
                                                                    "parentRowNumber": 3956,
                                                                    "articleId": "20120540",
                                                                    "description": "20120540-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0077,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0077,
                                                                    "singleQuantityMcf": 0.039886,
                                                                    "mcf": 0.039886,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.039886,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3969,
                                                                    "parentRowNumber": 3956,
                                                                    "articleId": "20120540",
                                                                    "description": "20120540-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0077,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0077,
                                                                    "singleQuantityMcf": 0.039886,
                                                                    "mcf": 0.039886,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.039886,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3970,
                                                                    "parentRowNumber": 3956,
                                                                    "articleId": "20120540",
                                                                    "description": "20120540-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0077,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0077,
                                                                    "singleQuantityMcf": 0.039886,
                                                                    "mcf": 0.039886,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.039886,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3971,
                                                                    "parentRowNumber": 3956,
                                                                    "articleId": "20120540",
                                                                    "description": "20120540-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0077,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0077,
                                                                    "singleQuantityMcf": 0.039886,
                                                                    "mcf": 0.039886,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.039886,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3972,
                                                                    "parentRowNumber": 3956,
                                                                    "articleId": "20120540",
                                                                    "description": "20120540-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0077,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0077,
                                                                    "singleQuantityMcf": 0.039886,
                                                                    "mcf": 0.039886,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.039886,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3973,
                                                                    "parentRowNumber": 3956,
                                                                    "articleId": "20120540",
                                                                    "description": "20120540-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0077,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0077,
                                                                    "singleQuantityMcf": 0.039886,
                                                                    "mcf": 0.039886,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.039886,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3974,
                                                                    "parentRowNumber": 3956,
                                                                    "articleId": "20120540",
                                                                    "description": "20120540-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0077,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0077,
                                                                    "singleQuantityMcf": 0.039886,
                                                                    "mcf": 0.039886,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.039886,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3975,
                                                                    "parentRowNumber": 3956,
                                                                    "articleId": "20120540",
                                                                    "description": "20120540-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0077,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0077,
                                                                    "singleQuantityMcf": 0.039886,
                                                                    "mcf": 0.039886,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.039886,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3976,
                                                                    "parentRowNumber": 3956,
                                                                    "articleId": "20120540",
                                                                    "description": "20120540-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0077,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0077,
                                                                    "singleQuantityMcf": 0.039886,
                                                                    "mcf": 0.039886,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.039886,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3977,
                                                                    "parentRowNumber": 3956,
                                                                    "articleId": "20120540",
                                                                    "description": "20120540-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0077,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0077,
                                                                    "singleQuantityMcf": 0.039886,
                                                                    "mcf": 0.039886,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.039886,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3978,
                                                                    "parentRowNumber": 3956,
                                                                    "articleId": "20120540",
                                                                    "description": "20120540-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0077,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0077,
                                                                    "singleQuantityMcf": 0.039886,
                                                                    "mcf": 0.039886,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.039886,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3979,
                                                                    "parentRowNumber": 3956,
                                                                    "articleId": "20120540",
                                                                    "description": "20120540-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0077,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0077,
                                                                    "singleQuantityMcf": 0.039886,
                                                                    "mcf": 0.039886,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.039886,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3980,
                                                                    "parentRowNumber": 3956,
                                                                    "articleId": "20120540",
                                                                    "description": "20120540-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0077,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0077,
                                                                    "singleQuantityMcf": 0.039886,
                                                                    "mcf": 0.039886,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.039886,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3981,
                                                                    "parentRowNumber": 3956,
                                                                    "articleId": "20120540",
                                                                    "description": "20120540-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0077,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0077,
                                                                    "singleQuantityMcf": 0.039886,
                                                                    "mcf": 0.039886,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.039886,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3982,
                                                                    "parentRowNumber": 3956,
                                                                    "articleId": "20120540",
                                                                    "description": "20120540-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0077,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0077,
                                                                    "singleQuantityMcf": 0.039886,
                                                                    "mcf": 0.039886,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.039886,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3983,
                                                                    "parentRowNumber": 3956,
                                                                    "articleId": "20120540",
                                                                    "description": "20120540-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0077,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0077,
                                                                    "singleQuantityMcf": 0.039886,
                                                                    "mcf": 0.039886,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.039886,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3984,
                                                                    "parentRowNumber": 3956,
                                                                    "articleId": "20120540",
                                                                    "description": "20120540-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0077,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0077,
                                                                    "singleQuantityMcf": 0.039886,
                                                                    "mcf": 0.039886,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.039886,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3985,
                                                                    "parentRowNumber": 3956,
                                                                    "articleId": "20120540",
                                                                    "description": "20120540-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0077,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0077,
                                                                    "singleQuantityMcf": 0.039886,
                                                                    "mcf": 0.039886,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.039886,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3986,
                                                                    "parentRowNumber": 3956,
                                                                    "articleId": "20120540",
                                                                    "description": "20120540-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0077,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0077,
                                                                    "singleQuantityMcf": 0.039886,
                                                                    "mcf": 0.039886,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.039886,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3987,
                                                                    "parentRowNumber": 3956,
                                                                    "articleId": "208205",
                                                                    "description": "208205-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0004,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0004,
                                                                    "singleQuantityMcf": 0.002072,
                                                                    "mcf": 0.002072,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.002072,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3988,
                                                                    "parentRowNumber": 3956,
                                                                    "articleId": "208205",
                                                                    "description": "208205-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0004,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0004,
                                                                    "singleQuantityMcf": 0.002072,
                                                                    "mcf": 0.002072,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.002072,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3989,
                                                                    "parentRowNumber": 3956,
                                                                    "articleId": "208405",
                                                                    "description": "208405-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0015,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0015,
                                                                    "singleQuantityMcf": 0.00777,
                                                                    "mcf": 0.00777,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.00777,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3990,
                                                                    "parentRowNumber": 3956,
                                                                    "articleId": "208405",
                                                                    "description": "208405-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0015,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0015,
                                                                    "singleQuantityMcf": 0.00777,
                                                                    "mcf": 0.00777,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.00777,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3991,
                                                                    "parentRowNumber": 3956,
                                                                    "articleId": "208405",
                                                                    "description": "208405-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0015,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0015,
                                                                    "singleQuantityMcf": 0.00777,
                                                                    "mcf": 0.00777,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.00777,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3992,
                                                                    "parentRowNumber": 3956,
                                                                    "articleId": "208405",
                                                                    "description": "208405-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0015,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0015,
                                                                    "singleQuantityMcf": 0.00777,
                                                                    "mcf": 0.00777,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.00777,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3993,
                                                                    "parentRowNumber": 3956,
                                                                    "articleId": "208405",
                                                                    "description": "208405-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0015,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0015,
                                                                    "singleQuantityMcf": 0.00777,
                                                                    "mcf": 0.00777,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.00777,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3994,
                                                                    "parentRowNumber": 3956,
                                                                    "articleId": "208405",
                                                                    "description": "208405-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0015,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0015,
                                                                    "singleQuantityMcf": 0.00777,
                                                                    "mcf": 0.00777,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.00777,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3995,
                                                                    "parentRowNumber": 3956,
                                                                    "articleId": "208405",
                                                                    "description": "208405-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0015,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0015,
                                                                    "singleQuantityMcf": 0.00777,
                                                                    "mcf": 0.00777,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.00777,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3996,
                                                                    "parentRowNumber": 3956,
                                                                    "articleId": "208405",
                                                                    "description": "208405-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0015,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0015,
                                                                    "singleQuantityMcf": 0.00777,
                                                                    "mcf": 0.00777,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.00777,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3997,
                                                                    "parentRowNumber": 3956,
                                                                    "articleId": "208405",
                                                                    "description": "208405-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0015,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0015,
                                                                    "singleQuantityMcf": 0.00777,
                                                                    "mcf": 0.00777,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.00777,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3998,
                                                                    "parentRowNumber": 3956,
                                                                    "articleId": "208405",
                                                                    "description": "208405-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0015,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0015,
                                                                    "singleQuantityMcf": 0.00777,
                                                                    "mcf": 0.00777,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.00777,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 3999,
                                                                    "parentRowNumber": 3956,
                                                                    "articleId": "208405",
                                                                    "description": "208405-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0015,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0015,
                                                                    "singleQuantityMcf": 0.00777,
                                                                    "mcf": 0.00777,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.00777,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4000,
                                                                    "parentRowNumber": 3956,
                                                                    "articleId": "208405",
                                                                    "description": "208405-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0015,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0015,
                                                                    "singleQuantityMcf": 0.00777,
                                                                    "mcf": 0.00777,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.00777,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4001,
                                                                    "parentRowNumber": 3956,
                                                                    "articleId": "208405",
                                                                    "description": "208405-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0015,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0015,
                                                                    "singleQuantityMcf": 0.00777,
                                                                    "mcf": 0.00777,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.00777,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4002,
                                                                    "parentRowNumber": 3956,
                                                                    "articleId": "208405",
                                                                    "description": "208405-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0015,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0015,
                                                                    "singleQuantityMcf": 0.00777,
                                                                    "mcf": 0.00777,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.00777,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4003,
                                                                    "parentRowNumber": 3956,
                                                                    "articleId": "208405",
                                                                    "description": "208405-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0015,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0015,
                                                                    "singleQuantityMcf": 0.00777,
                                                                    "mcf": 0.00777,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.00777,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4004,
                                                                    "parentRowNumber": 3956,
                                                                    "articleId": "208405",
                                                                    "description": "208405-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0015,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0015,
                                                                    "singleQuantityMcf": 0.00777,
                                                                    "mcf": 0.00777,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.00777,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4005,
                                                                    "parentRowNumber": 3956,
                                                                    "articleId": "208405",
                                                                    "description": "208405-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0015,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0015,
                                                                    "singleQuantityMcf": 0.00777,
                                                                    "mcf": 0.00777,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.00777,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4006,
                                                                    "parentRowNumber": 3956,
                                                                    "articleId": "208405",
                                                                    "description": "208405-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0015,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0015,
                                                                    "singleQuantityMcf": 0.00777,
                                                                    "mcf": 0.00777,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.00777,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4007,
                                                                    "parentRowNumber": 3956,
                                                                    "articleId": "208405",
                                                                    "description": "208405-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0015,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0015,
                                                                    "singleQuantityMcf": 0.00777,
                                                                    "mcf": 0.00777,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.00777,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4008,
                                                                    "parentRowNumber": 3956,
                                                                    "articleId": "208405",
                                                                    "description": "208405-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0015,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0015,
                                                                    "singleQuantityMcf": 0.00777,
                                                                    "mcf": 0.00777,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.00777,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4009,
                                                                    "parentRowNumber": 3956,
                                                                    "articleId": "208405",
                                                                    "description": "208405-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0015,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0015,
                                                                    "singleQuantityMcf": 0.00777,
                                                                    "mcf": 0.00777,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.00777,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4010,
                                                                    "parentRowNumber": 3956,
                                                                    "articleId": "208405",
                                                                    "description": "208405-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0015,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0015,
                                                                    "singleQuantityMcf": 0.00777,
                                                                    "mcf": 0.00777,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.00777,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4011,
                                                                    "parentRowNumber": 3956,
                                                                    "articleId": "208405",
                                                                    "description": "208405-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0015,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0015,
                                                                    "singleQuantityMcf": 0.00777,
                                                                    "mcf": 0.00777,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.00777,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4012,
                                                                    "parentRowNumber": 3956,
                                                                    "articleId": "208405",
                                                                    "description": "208405-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0015,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0015,
                                                                    "singleQuantityMcf": 0.00777,
                                                                    "mcf": 0.00777,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.00777,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4013,
                                                                    "parentRowNumber": 3956,
                                                                    "articleId": "208405",
                                                                    "description": "208405-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0015,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0015,
                                                                    "singleQuantityMcf": 0.00777,
                                                                    "mcf": 0.00777,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.00777,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4014,
                                                                    "parentRowNumber": 3956,
                                                                    "articleId": "208405",
                                                                    "description": "208405-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0015,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0015,
                                                                    "singleQuantityMcf": 0.00777,
                                                                    "mcf": 0.00777,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.00777,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4015,
                                                                    "parentRowNumber": 3956,
                                                                    "articleId": "208405",
                                                                    "description": "208405-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0015,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0015,
                                                                    "singleQuantityMcf": 0.00777,
                                                                    "mcf": 0.00777,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.00777,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4016,
                                                                    "parentRowNumber": 3956,
                                                                    "articleId": "208405",
                                                                    "description": "208405-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0015,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0015,
                                                                    "singleQuantityMcf": 0.00777,
                                                                    "mcf": 0.00777,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.00777,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4017,
                                                                    "parentRowNumber": 3956,
                                                                    "articleId": "46645928",
                                                                    "description": "46645928-00-Formatbuegel 2.0",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.1205,
                                                                    "childrenWeight": 0.1205,
                                                                    "totalQuantityWeight": 0.1205,
                                                                    "singleQuantityMcf": 0.6241899999999999,
                                                                    "mcf": 0.6241899999999999,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.6241899999999999,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 4018,
                                                                            "parentRowNumber": 4017,
                                                                            "articleId": "142612",
                                                                            "description": "142612-00-Edelstahlblech",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0617,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0617,
                                                                            "singleQuantityMcf": 0.31960599999999995,
                                                                            "mcf": 0.31960599999999995,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.31960599999999995,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        },
                                                                        {
                                                                            "rowNumber": 4019,
                                                                            "parentRowNumber": 4017,
                                                                            "articleId": "46647477",
                                                                            "description": "46647477-00-Halter",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0294,
                                                                            "childrenWeight": 0.0294,
                                                                            "totalQuantityWeight": 0.0294,
                                                                            "singleQuantityMcf": 0.15229199999999998,
                                                                            "mcf": 0.15229199999999998,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.15229199999999998,
                                                                            "expandable": false,
                                                                            "children": [
                                                                                {
                                                                                    "rowNumber": 4020,
                                                                                    "parentRowNumber": 4019,
                                                                                    "articleId": "1110231",
                                                                                    "description": "1110231-00-Flachstahl",
                                                                                    "hierarchy": 9,
                                                                                    "quantity": 1,
                                                                                    "weight": 0.0294,
                                                                                    "childrenWeight": 0,
                                                                                    "totalQuantityWeight": 0.0294,
                                                                                    "singleQuantityMcf": 0.15229199999999998,
                                                                                    "mcf": 0.15229199999999998,
                                                                                    "singleQuantityLcf": 0,
                                                                                    "lcf": 0,
                                                                                    "totalQuantityMcf": 0.15229199999999998,
                                                                                    "expandable": false,
                                                                                    "children": []
                                                                                }
                                                                            ]
                                                                        },
                                                                        {
                                                                            "rowNumber": 4021,
                                                                            "parentRowNumber": 4017,
                                                                            "articleId": "46647477",
                                                                            "description": "46647477-00-Halter",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0294,
                                                                            "childrenWeight": 0.0294,
                                                                            "totalQuantityWeight": 0.0294,
                                                                            "singleQuantityMcf": 0.15229199999999998,
                                                                            "mcf": 0.15229199999999998,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.15229199999999998,
                                                                            "expandable": false,
                                                                            "children": [
                                                                                {
                                                                                    "rowNumber": 4022,
                                                                                    "parentRowNumber": 4021,
                                                                                    "articleId": "1110231",
                                                                                    "description": "1110231-00-Flachstahl",
                                                                                    "hierarchy": 9,
                                                                                    "quantity": 1,
                                                                                    "weight": 0.0294,
                                                                                    "childrenWeight": 0,
                                                                                    "totalQuantityWeight": 0.0294,
                                                                                    "singleQuantityMcf": 0.15229199999999998,
                                                                                    "mcf": 0.15229199999999998,
                                                                                    "singleQuantityLcf": 0,
                                                                                    "lcf": 0,
                                                                                    "totalQuantityMcf": 0.15229199999999998,
                                                                                    "expandable": false,
                                                                                    "children": []
                                                                                }
                                                                            ]
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 4023,
                                                                    "parentRowNumber": 3956,
                                                                    "articleId": "46645928",
                                                                    "description": "46645928-00-Formatbuegel 2.0",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.1205,
                                                                    "childrenWeight": 0.1205,
                                                                    "totalQuantityWeight": 0.1205,
                                                                    "singleQuantityMcf": 0.6241899999999999,
                                                                    "mcf": 0.6241899999999999,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.6241899999999999,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 4024,
                                                                            "parentRowNumber": 4023,
                                                                            "articleId": "142612",
                                                                            "description": "142612-00-Edelstahlblech",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0617,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0617,
                                                                            "singleQuantityMcf": 0.31960599999999995,
                                                                            "mcf": 0.31960599999999995,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.31960599999999995,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        },
                                                                        {
                                                                            "rowNumber": 4025,
                                                                            "parentRowNumber": 4023,
                                                                            "articleId": "46647477",
                                                                            "description": "46647477-00-Halter",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0294,
                                                                            "childrenWeight": 0.0294,
                                                                            "totalQuantityWeight": 0.0294,
                                                                            "singleQuantityMcf": 0.15229199999999998,
                                                                            "mcf": 0.15229199999999998,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.15229199999999998,
                                                                            "expandable": false,
                                                                            "children": [
                                                                                {
                                                                                    "rowNumber": 4026,
                                                                                    "parentRowNumber": 4025,
                                                                                    "articleId": "1110231",
                                                                                    "description": "1110231-00-Flachstahl",
                                                                                    "hierarchy": 9,
                                                                                    "quantity": 1,
                                                                                    "weight": 0.0294,
                                                                                    "childrenWeight": 0,
                                                                                    "totalQuantityWeight": 0.0294,
                                                                                    "singleQuantityMcf": 0.15229199999999998,
                                                                                    "mcf": 0.15229199999999998,
                                                                                    "singleQuantityLcf": 0,
                                                                                    "lcf": 0,
                                                                                    "totalQuantityMcf": 0.15229199999999998,
                                                                                    "expandable": false,
                                                                                    "children": []
                                                                                }
                                                                            ]
                                                                        },
                                                                        {
                                                                            "rowNumber": 4027,
                                                                            "parentRowNumber": 4023,
                                                                            "articleId": "46647477",
                                                                            "description": "46647477-00-Halter",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0294,
                                                                            "childrenWeight": 0.0294,
                                                                            "totalQuantityWeight": 0.0294,
                                                                            "singleQuantityMcf": 0.15229199999999998,
                                                                            "mcf": 0.15229199999999998,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.15229199999999998,
                                                                            "expandable": false,
                                                                            "children": [
                                                                                {
                                                                                    "rowNumber": 4028,
                                                                                    "parentRowNumber": 4027,
                                                                                    "articleId": "1110231",
                                                                                    "description": "1110231-00-Flachstahl",
                                                                                    "hierarchy": 9,
                                                                                    "quantity": 1,
                                                                                    "weight": 0.0294,
                                                                                    "childrenWeight": 0,
                                                                                    "totalQuantityWeight": 0.0294,
                                                                                    "singleQuantityMcf": 0.15229199999999998,
                                                                                    "mcf": 0.15229199999999998,
                                                                                    "singleQuantityLcf": 0,
                                                                                    "lcf": 0,
                                                                                    "totalQuantityMcf": 0.15229199999999998,
                                                                                    "expandable": false,
                                                                                    "children": []
                                                                                }
                                                                            ]
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 4029,
                                                                    "parentRowNumber": 3956,
                                                                    "articleId": "46645928",
                                                                    "description": "46645928-00-Formatbuegel 2.0",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.1205,
                                                                    "childrenWeight": 0.1205,
                                                                    "totalQuantityWeight": 0.1205,
                                                                    "singleQuantityMcf": 0.6241899999999999,
                                                                    "mcf": 0.6241899999999999,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.6241899999999999,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 4030,
                                                                            "parentRowNumber": 4029,
                                                                            "articleId": "142612",
                                                                            "description": "142612-00-Edelstahlblech",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0617,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0617,
                                                                            "singleQuantityMcf": 0.31960599999999995,
                                                                            "mcf": 0.31960599999999995,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.31960599999999995,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        },
                                                                        {
                                                                            "rowNumber": 4031,
                                                                            "parentRowNumber": 4029,
                                                                            "articleId": "46647477",
                                                                            "description": "46647477-00-Halter",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0294,
                                                                            "childrenWeight": 0.0294,
                                                                            "totalQuantityWeight": 0.0294,
                                                                            "singleQuantityMcf": 0.15229199999999998,
                                                                            "mcf": 0.15229199999999998,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.15229199999999998,
                                                                            "expandable": false,
                                                                            "children": [
                                                                                {
                                                                                    "rowNumber": 4032,
                                                                                    "parentRowNumber": 4031,
                                                                                    "articleId": "1110231",
                                                                                    "description": "1110231-00-Flachstahl",
                                                                                    "hierarchy": 9,
                                                                                    "quantity": 1,
                                                                                    "weight": 0.0294,
                                                                                    "childrenWeight": 0,
                                                                                    "totalQuantityWeight": 0.0294,
                                                                                    "singleQuantityMcf": 0.15229199999999998,
                                                                                    "mcf": 0.15229199999999998,
                                                                                    "singleQuantityLcf": 0,
                                                                                    "lcf": 0,
                                                                                    "totalQuantityMcf": 0.15229199999999998,
                                                                                    "expandable": false,
                                                                                    "children": []
                                                                                }
                                                                            ]
                                                                        },
                                                                        {
                                                                            "rowNumber": 4033,
                                                                            "parentRowNumber": 4029,
                                                                            "articleId": "46647477",
                                                                            "description": "46647477-00-Halter",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0294,
                                                                            "childrenWeight": 0.0294,
                                                                            "totalQuantityWeight": 0.0294,
                                                                            "singleQuantityMcf": 0.15229199999999998,
                                                                            "mcf": 0.15229199999999998,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.15229199999999998,
                                                                            "expandable": false,
                                                                            "children": [
                                                                                {
                                                                                    "rowNumber": 4034,
                                                                                    "parentRowNumber": 4033,
                                                                                    "articleId": "1110231",
                                                                                    "description": "1110231-00-Flachstahl",
                                                                                    "hierarchy": 9,
                                                                                    "quantity": 1,
                                                                                    "weight": 0.0294,
                                                                                    "childrenWeight": 0,
                                                                                    "totalQuantityWeight": 0.0294,
                                                                                    "singleQuantityMcf": 0.15229199999999998,
                                                                                    "mcf": 0.15229199999999998,
                                                                                    "singleQuantityLcf": 0,
                                                                                    "lcf": 0,
                                                                                    "totalQuantityMcf": 0.15229199999999998,
                                                                                    "expandable": false,
                                                                                    "children": []
                                                                                }
                                                                            ]
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 4035,
                                                                    "parentRowNumber": 3956,
                                                                    "articleId": "46645928",
                                                                    "description": "46645928-00-Formatbuegel 2.0",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.1205,
                                                                    "childrenWeight": 0.1205,
                                                                    "totalQuantityWeight": 0.1205,
                                                                    "singleQuantityMcf": 0.6241899999999999,
                                                                    "mcf": 0.6241899999999999,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.6241899999999999,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 4036,
                                                                            "parentRowNumber": 4035,
                                                                            "articleId": "142612",
                                                                            "description": "142612-00-Edelstahlblech",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0617,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0617,
                                                                            "singleQuantityMcf": 0.31960599999999995,
                                                                            "mcf": 0.31960599999999995,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.31960599999999995,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        },
                                                                        {
                                                                            "rowNumber": 4037,
                                                                            "parentRowNumber": 4035,
                                                                            "articleId": "46647477",
                                                                            "description": "46647477-00-Halter",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0294,
                                                                            "childrenWeight": 0.0294,
                                                                            "totalQuantityWeight": 0.0294,
                                                                            "singleQuantityMcf": 0.15229199999999998,
                                                                            "mcf": 0.15229199999999998,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.15229199999999998,
                                                                            "expandable": false,
                                                                            "children": [
                                                                                {
                                                                                    "rowNumber": 4038,
                                                                                    "parentRowNumber": 4037,
                                                                                    "articleId": "1110231",
                                                                                    "description": "1110231-00-Flachstahl",
                                                                                    "hierarchy": 9,
                                                                                    "quantity": 1,
                                                                                    "weight": 0.0294,
                                                                                    "childrenWeight": 0,
                                                                                    "totalQuantityWeight": 0.0294,
                                                                                    "singleQuantityMcf": 0.15229199999999998,
                                                                                    "mcf": 0.15229199999999998,
                                                                                    "singleQuantityLcf": 0,
                                                                                    "lcf": 0,
                                                                                    "totalQuantityMcf": 0.15229199999999998,
                                                                                    "expandable": false,
                                                                                    "children": []
                                                                                }
                                                                            ]
                                                                        },
                                                                        {
                                                                            "rowNumber": 4039,
                                                                            "parentRowNumber": 4035,
                                                                            "articleId": "46647477",
                                                                            "description": "46647477-00-Halter",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0294,
                                                                            "childrenWeight": 0.0294,
                                                                            "totalQuantityWeight": 0.0294,
                                                                            "singleQuantityMcf": 0.15229199999999998,
                                                                            "mcf": 0.15229199999999998,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.15229199999999998,
                                                                            "expandable": false,
                                                                            "children": [
                                                                                {
                                                                                    "rowNumber": 4040,
                                                                                    "parentRowNumber": 4039,
                                                                                    "articleId": "1110231",
                                                                                    "description": "1110231-00-Flachstahl",
                                                                                    "hierarchy": 9,
                                                                                    "quantity": 1,
                                                                                    "weight": 0.0294,
                                                                                    "childrenWeight": 0,
                                                                                    "totalQuantityWeight": 0.0294,
                                                                                    "singleQuantityMcf": 0.15229199999999998,
                                                                                    "mcf": 0.15229199999999998,
                                                                                    "singleQuantityLcf": 0,
                                                                                    "lcf": 0,
                                                                                    "totalQuantityMcf": 0.15229199999999998,
                                                                                    "expandable": false,
                                                                                    "children": []
                                                                                }
                                                                            ]
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 4041,
                                                                    "parentRowNumber": 3956,
                                                                    "articleId": "46645928",
                                                                    "description": "46645928-00-Formatbuegel 2.0",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.1205,
                                                                    "childrenWeight": 0.1205,
                                                                    "totalQuantityWeight": 0.1205,
                                                                    "singleQuantityMcf": 0.6241899999999999,
                                                                    "mcf": 0.6241899999999999,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.6241899999999999,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 4042,
                                                                            "parentRowNumber": 4041,
                                                                            "articleId": "142612",
                                                                            "description": "142612-00-Edelstahlblech",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0617,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0617,
                                                                            "singleQuantityMcf": 0.31960599999999995,
                                                                            "mcf": 0.31960599999999995,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.31960599999999995,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        },
                                                                        {
                                                                            "rowNumber": 4043,
                                                                            "parentRowNumber": 4041,
                                                                            "articleId": "46647477",
                                                                            "description": "46647477-00-Halter",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0294,
                                                                            "childrenWeight": 0.0294,
                                                                            "totalQuantityWeight": 0.0294,
                                                                            "singleQuantityMcf": 0.15229199999999998,
                                                                            "mcf": 0.15229199999999998,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.15229199999999998,
                                                                            "expandable": false,
                                                                            "children": [
                                                                                {
                                                                                    "rowNumber": 4044,
                                                                                    "parentRowNumber": 4043,
                                                                                    "articleId": "1110231",
                                                                                    "description": "1110231-00-Flachstahl",
                                                                                    "hierarchy": 9,
                                                                                    "quantity": 1,
                                                                                    "weight": 0.0294,
                                                                                    "childrenWeight": 0,
                                                                                    "totalQuantityWeight": 0.0294,
                                                                                    "singleQuantityMcf": 0.15229199999999998,
                                                                                    "mcf": 0.15229199999999998,
                                                                                    "singleQuantityLcf": 0,
                                                                                    "lcf": 0,
                                                                                    "totalQuantityMcf": 0.15229199999999998,
                                                                                    "expandable": false,
                                                                                    "children": []
                                                                                }
                                                                            ]
                                                                        },
                                                                        {
                                                                            "rowNumber": 4045,
                                                                            "parentRowNumber": 4041,
                                                                            "articleId": "46647477",
                                                                            "description": "46647477-00-Halter",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0294,
                                                                            "childrenWeight": 0.0294,
                                                                            "totalQuantityWeight": 0.0294,
                                                                            "singleQuantityMcf": 0.15229199999999998,
                                                                            "mcf": 0.15229199999999998,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.15229199999999998,
                                                                            "expandable": false,
                                                                            "children": [
                                                                                {
                                                                                    "rowNumber": 4046,
                                                                                    "parentRowNumber": 4045,
                                                                                    "articleId": "1110231",
                                                                                    "description": "1110231-00-Flachstahl",
                                                                                    "hierarchy": 9,
                                                                                    "quantity": 1,
                                                                                    "weight": 0.0294,
                                                                                    "childrenWeight": 0,
                                                                                    "totalQuantityWeight": 0.0294,
                                                                                    "singleQuantityMcf": 0.15229199999999998,
                                                                                    "mcf": 0.15229199999999998,
                                                                                    "singleQuantityLcf": 0,
                                                                                    "lcf": 0,
                                                                                    "totalQuantityMcf": 0.15229199999999998,
                                                                                    "expandable": false,
                                                                                    "children": []
                                                                                }
                                                                            ]
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 4047,
                                                                    "parentRowNumber": 3956,
                                                                    "articleId": "46645928",
                                                                    "description": "46645928-00-Formatbuegel 2.0",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.1205,
                                                                    "childrenWeight": 0.1205,
                                                                    "totalQuantityWeight": 0.1205,
                                                                    "singleQuantityMcf": 0.6241899999999999,
                                                                    "mcf": 0.6241899999999999,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.6241899999999999,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 4048,
                                                                            "parentRowNumber": 4047,
                                                                            "articleId": "142612",
                                                                            "description": "142612-00-Edelstahlblech",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0617,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0617,
                                                                            "singleQuantityMcf": 0.31960599999999995,
                                                                            "mcf": 0.31960599999999995,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.31960599999999995,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        },
                                                                        {
                                                                            "rowNumber": 4049,
                                                                            "parentRowNumber": 4047,
                                                                            "articleId": "46647477",
                                                                            "description": "46647477-00-Halter",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0294,
                                                                            "childrenWeight": 0.0294,
                                                                            "totalQuantityWeight": 0.0294,
                                                                            "singleQuantityMcf": 0.15229199999999998,
                                                                            "mcf": 0.15229199999999998,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.15229199999999998,
                                                                            "expandable": false,
                                                                            "children": [
                                                                                {
                                                                                    "rowNumber": 4050,
                                                                                    "parentRowNumber": 4049,
                                                                                    "articleId": "1110231",
                                                                                    "description": "1110231-00-Flachstahl",
                                                                                    "hierarchy": 9,
                                                                                    "quantity": 1,
                                                                                    "weight": 0.0294,
                                                                                    "childrenWeight": 0,
                                                                                    "totalQuantityWeight": 0.0294,
                                                                                    "singleQuantityMcf": 0.15229199999999998,
                                                                                    "mcf": 0.15229199999999998,
                                                                                    "singleQuantityLcf": 0,
                                                                                    "lcf": 0,
                                                                                    "totalQuantityMcf": 0.15229199999999998,
                                                                                    "expandable": false,
                                                                                    "children": []
                                                                                }
                                                                            ]
                                                                        },
                                                                        {
                                                                            "rowNumber": 4051,
                                                                            "parentRowNumber": 4047,
                                                                            "articleId": "46647477",
                                                                            "description": "46647477-00-Halter",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0294,
                                                                            "childrenWeight": 0.0294,
                                                                            "totalQuantityWeight": 0.0294,
                                                                            "singleQuantityMcf": 0.15229199999999998,
                                                                            "mcf": 0.15229199999999998,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.15229199999999998,
                                                                            "expandable": false,
                                                                            "children": [
                                                                                {
                                                                                    "rowNumber": 4052,
                                                                                    "parentRowNumber": 4051,
                                                                                    "articleId": "1110231",
                                                                                    "description": "1110231-00-Flachstahl",
                                                                                    "hierarchy": 9,
                                                                                    "quantity": 1,
                                                                                    "weight": 0.0294,
                                                                                    "childrenWeight": 0,
                                                                                    "totalQuantityWeight": 0.0294,
                                                                                    "singleQuantityMcf": 0.15229199999999998,
                                                                                    "mcf": 0.15229199999999998,
                                                                                    "singleQuantityLcf": 0,
                                                                                    "lcf": 0,
                                                                                    "totalQuantityMcf": 0.15229199999999998,
                                                                                    "expandable": false,
                                                                                    "children": []
                                                                                }
                                                                            ]
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 4053,
                                                                    "parentRowNumber": 3956,
                                                                    "articleId": "46645928",
                                                                    "description": "46645928-00-Formatbuegel 2.0",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.1205,
                                                                    "childrenWeight": 0.1205,
                                                                    "totalQuantityWeight": 0.1205,
                                                                    "singleQuantityMcf": 0.6241899999999999,
                                                                    "mcf": 0.6241899999999999,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.6241899999999999,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 4054,
                                                                            "parentRowNumber": 4053,
                                                                            "articleId": "142612",
                                                                            "description": "142612-00-Edelstahlblech",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0617,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0617,
                                                                            "singleQuantityMcf": 0.31960599999999995,
                                                                            "mcf": 0.31960599999999995,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.31960599999999995,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        },
                                                                        {
                                                                            "rowNumber": 4055,
                                                                            "parentRowNumber": 4053,
                                                                            "articleId": "46647477",
                                                                            "description": "46647477-00-Halter",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0294,
                                                                            "childrenWeight": 0.0294,
                                                                            "totalQuantityWeight": 0.0294,
                                                                            "singleQuantityMcf": 0.15229199999999998,
                                                                            "mcf": 0.15229199999999998,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.15229199999999998,
                                                                            "expandable": false,
                                                                            "children": [
                                                                                {
                                                                                    "rowNumber": 4056,
                                                                                    "parentRowNumber": 4055,
                                                                                    "articleId": "1110231",
                                                                                    "description": "1110231-00-Flachstahl",
                                                                                    "hierarchy": 9,
                                                                                    "quantity": 1,
                                                                                    "weight": 0.0294,
                                                                                    "childrenWeight": 0,
                                                                                    "totalQuantityWeight": 0.0294,
                                                                                    "singleQuantityMcf": 0.15229199999999998,
                                                                                    "mcf": 0.15229199999999998,
                                                                                    "singleQuantityLcf": 0,
                                                                                    "lcf": 0,
                                                                                    "totalQuantityMcf": 0.15229199999999998,
                                                                                    "expandable": false,
                                                                                    "children": []
                                                                                }
                                                                            ]
                                                                        },
                                                                        {
                                                                            "rowNumber": 4057,
                                                                            "parentRowNumber": 4053,
                                                                            "articleId": "46647477",
                                                                            "description": "46647477-00-Halter",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0294,
                                                                            "childrenWeight": 0.0294,
                                                                            "totalQuantityWeight": 0.0294,
                                                                            "singleQuantityMcf": 0.15229199999999998,
                                                                            "mcf": 0.15229199999999998,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.15229199999999998,
                                                                            "expandable": false,
                                                                            "children": [
                                                                                {
                                                                                    "rowNumber": 4058,
                                                                                    "parentRowNumber": 4057,
                                                                                    "articleId": "1110231",
                                                                                    "description": "1110231-00-Flachstahl",
                                                                                    "hierarchy": 9,
                                                                                    "quantity": 1,
                                                                                    "weight": 0.0294,
                                                                                    "childrenWeight": 0,
                                                                                    "totalQuantityWeight": 0.0294,
                                                                                    "singleQuantityMcf": 0.15229199999999998,
                                                                                    "mcf": 0.15229199999999998,
                                                                                    "singleQuantityLcf": 0,
                                                                                    "lcf": 0,
                                                                                    "totalQuantityMcf": 0.15229199999999998,
                                                                                    "expandable": false,
                                                                                    "children": []
                                                                                }
                                                                            ]
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 4059,
                                                                    "parentRowNumber": 3956,
                                                                    "articleId": "47238063",
                                                                    "description": "47238063-00-Drehtellerfuehrungen",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 2.7864,
                                                                    "childrenWeight": 2.7864,
                                                                    "totalQuantityWeight": 2.7864,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 4060,
                                                                            "parentRowNumber": 4059,
                                                                            "articleId": "162518",
                                                                            "description": "162518-01-Platte",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 2.7864,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 2.7864,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 4061,
                                                                    "parentRowNumber": 3956,
                                                                    "articleId": "47238064",
                                                                    "description": "47238064-00-Drehtellerfuehrungen",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 6.9989,
                                                                    "childrenWeight": 6.9989,
                                                                    "totalQuantityWeight": 6.9989,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 4062,
                                                                            "parentRowNumber": 4061,
                                                                            "articleId": "162518",
                                                                            "description": "162518-01-Platte",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 6.9939,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 6.9939,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        },
                                                                        {
                                                                            "rowNumber": 4063,
                                                                            "parentRowNumber": 4061,
                                                                            "articleId": "347200",
                                                                            "description": "347200-00-Ensat-Gewindebuchse",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0025,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0025,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        },
                                                                        {
                                                                            "rowNumber": 4064,
                                                                            "parentRowNumber": 4061,
                                                                            "articleId": "347200",
                                                                            "description": "347200-00-Ensat-Gewindebuchse",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0025,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0025,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 4065,
                                                                    "parentRowNumber": 3956,
                                                                    "articleId": "47238066",
                                                                    "description": "47238066-00-Objektfuehrung",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0713,
                                                                    "childrenWeight": 0.0713,
                                                                    "totalQuantityWeight": 0.0713,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 4066,
                                                                            "parentRowNumber": 4065,
                                                                            "articleId": "162507",
                                                                            "description": "162507-01-Platte",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0713,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0713,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 4067,
                                                                    "parentRowNumber": 3956,
                                                                    "articleId": "OBJEKT SERUM 33847 24X49",
                                                                    "description": "OBJEKT SERUM 33847 24X49-00-Runde Flasche mit Boerdelverschluss",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.1531,
                                                                    "childrenWeight": 0.1531,
                                                                    "totalQuantityWeight": 0.1531,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 4068,
                                                                            "parentRowNumber": 4067,
                                                                            "articleId": "OBJ0000000007255",
                                                                            "description": "OBJ0000000007255-00-LYO-Stopfen",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0132,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0132,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        },
                                                                        {
                                                                            "rowNumber": 4069,
                                                                            "parentRowNumber": 4067,
                                                                            "articleId": "OBJ0000000007336",
                                                                            "description": "OBJ0000000007336-00-Flip-Off Kappe",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0055,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0055,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        },
                                                                        {
                                                                            "rowNumber": 4070,
                                                                            "parentRowNumber": 4067,
                                                                            "articleId": "OBJ0000000010005",
                                                                            "description": "OBJ0000000010005-00-Runde Flasche mit Boerdelverschluss",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.1344,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.1344,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 4071,
                                                                    "parentRowNumber": 3956,
                                                                    "articleId": "OBJEKT SERUM 33847 24X49",
                                                                    "description": "OBJEKT SERUM 33847 24X49-00-Runde Flasche mit Boerdelverschluss",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.1531,
                                                                    "childrenWeight": 0.1531,
                                                                    "totalQuantityWeight": 0.1531,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 4072,
                                                                            "parentRowNumber": 4071,
                                                                            "articleId": "OBJ0000000007255",
                                                                            "description": "OBJ0000000007255-00-LYO-Stopfen",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0132,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0132,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        },
                                                                        {
                                                                            "rowNumber": 4073,
                                                                            "parentRowNumber": 4071,
                                                                            "articleId": "OBJ0000000007336",
                                                                            "description": "OBJ0000000007336-00-Flip-Off Kappe",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0055,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0055,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        },
                                                                        {
                                                                            "rowNumber": 4074,
                                                                            "parentRowNumber": 4071,
                                                                            "articleId": "OBJ0000000010005",
                                                                            "description": "OBJ0000000010005-00-Runde Flasche mit Boerdelverschluss",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.1344,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.1344,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 4075,
                                                                    "parentRowNumber": 3956,
                                                                    "articleId": "OBJEKT SERUM 33847 24X49",
                                                                    "description": "OBJEKT SERUM 33847 24X49-00-Runde Flasche mit Boerdelverschluss",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.1531,
                                                                    "childrenWeight": 0.1531,
                                                                    "totalQuantityWeight": 0.1531,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 4076,
                                                                            "parentRowNumber": 4075,
                                                                            "articleId": "OBJ0000000007255",
                                                                            "description": "OBJ0000000007255-00-LYO-Stopfen",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0132,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0132,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        },
                                                                        {
                                                                            "rowNumber": 4077,
                                                                            "parentRowNumber": 4075,
                                                                            "articleId": "OBJ0000000007336",
                                                                            "description": "OBJ0000000007336-00-Flip-Off Kappe",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0055,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0055,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        },
                                                                        {
                                                                            "rowNumber": 4078,
                                                                            "parentRowNumber": 4075,
                                                                            "articleId": "OBJ0000000010005",
                                                                            "description": "OBJ0000000010005-00-Runde Flasche mit Boerdelverschluss",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.1344,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.1344,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                }
                                                            ]
                                                        }
                                                    ]
                                                }
                                            ]
                                        },
                                        {
                                            "rowNumber": 4079,
                                            "parentRowNumber": 3160,
                                            "articleId": "33847",
                                            "description": "33847-20NAF3-00-Objektführung",
                                            "hierarchy": 4,
                                            "quantity": 1,
                                            "weight": 11.253899999999998,
                                            "childrenWeight": 11.253899999999998,
                                            "totalQuantityWeight": 11.253899999999998,
                                            "singleQuantityMcf": 6.802375999999998,
                                            "mcf": 6.802375999999998,
                                            "singleQuantityLcf": 0,
                                            "lcf": 0,
                                            "totalQuantityMcf": 6.802375999999998,
                                            "expandable": false,
                                            "children": [
                                                {
                                                    "rowNumber": 4080,
                                                    "parentRowNumber": 4079,
                                                    "articleId": "MM0000009697",
                                                    "description": "MM0000009697-00-Objektführung",
                                                    "hierarchy": 5,
                                                    "quantity": 1,
                                                    "weight": 11.253899999999998,
                                                    "childrenWeight": 11.253899999999998,
                                                    "totalQuantityWeight": 11.253899999999998,
                                                    "singleQuantityMcf": 6.802375999999998,
                                                    "mcf": 6.802375999999998,
                                                    "singleQuantityLcf": 0,
                                                    "lcf": 0,
                                                    "totalQuantityMcf": 6.802375999999998,
                                                    "expandable": false,
                                                    "children": [
                                                        {
                                                            "rowNumber": 4081,
                                                            "parentRowNumber": 4080,
                                                            "articleId": "47238061",
                                                            "description": "47238061-00-Drehtellerfuehrungen",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 11.253899999999998,
                                                            "childrenWeight": 11.253899999999998,
                                                            "totalQuantityWeight": 11.253899999999998,
                                                            "singleQuantityMcf": 6.802375999999998,
                                                            "mcf": 6.802375999999998,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 6.802375999999998,
                                                            "expandable": false,
                                                            "children": [
                                                                {
                                                                    "rowNumber": 4082,
                                                                    "parentRowNumber": 4081,
                                                                    "articleId": "20120540",
                                                                    "description": "20120540-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0077,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0077,
                                                                    "singleQuantityMcf": 0.039886,
                                                                    "mcf": 0.039886,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.039886,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4083,
                                                                    "parentRowNumber": 4081,
                                                                    "articleId": "20120540",
                                                                    "description": "20120540-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0077,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0077,
                                                                    "singleQuantityMcf": 0.039886,
                                                                    "mcf": 0.039886,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.039886,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4084,
                                                                    "parentRowNumber": 4081,
                                                                    "articleId": "20120540",
                                                                    "description": "20120540-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0077,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0077,
                                                                    "singleQuantityMcf": 0.039886,
                                                                    "mcf": 0.039886,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.039886,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4085,
                                                                    "parentRowNumber": 4081,
                                                                    "articleId": "20120540",
                                                                    "description": "20120540-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0077,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0077,
                                                                    "singleQuantityMcf": 0.039886,
                                                                    "mcf": 0.039886,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.039886,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4086,
                                                                    "parentRowNumber": 4081,
                                                                    "articleId": "20120540",
                                                                    "description": "20120540-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0077,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0077,
                                                                    "singleQuantityMcf": 0.039886,
                                                                    "mcf": 0.039886,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.039886,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4087,
                                                                    "parentRowNumber": 4081,
                                                                    "articleId": "20120540",
                                                                    "description": "20120540-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0077,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0077,
                                                                    "singleQuantityMcf": 0.039886,
                                                                    "mcf": 0.039886,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.039886,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4088,
                                                                    "parentRowNumber": 4081,
                                                                    "articleId": "20120540",
                                                                    "description": "20120540-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0077,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0077,
                                                                    "singleQuantityMcf": 0.039886,
                                                                    "mcf": 0.039886,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.039886,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4089,
                                                                    "parentRowNumber": 4081,
                                                                    "articleId": "20120540",
                                                                    "description": "20120540-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0077,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0077,
                                                                    "singleQuantityMcf": 0.039886,
                                                                    "mcf": 0.039886,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.039886,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4090,
                                                                    "parentRowNumber": 4081,
                                                                    "articleId": "20120540",
                                                                    "description": "20120540-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0077,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0077,
                                                                    "singleQuantityMcf": 0.039886,
                                                                    "mcf": 0.039886,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.039886,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4091,
                                                                    "parentRowNumber": 4081,
                                                                    "articleId": "20120540",
                                                                    "description": "20120540-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0077,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0077,
                                                                    "singleQuantityMcf": 0.039886,
                                                                    "mcf": 0.039886,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.039886,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4092,
                                                                    "parentRowNumber": 4081,
                                                                    "articleId": "20120540",
                                                                    "description": "20120540-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0077,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0077,
                                                                    "singleQuantityMcf": 0.039886,
                                                                    "mcf": 0.039886,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.039886,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4093,
                                                                    "parentRowNumber": 4081,
                                                                    "articleId": "20120540",
                                                                    "description": "20120540-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0077,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0077,
                                                                    "singleQuantityMcf": 0.039886,
                                                                    "mcf": 0.039886,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.039886,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4094,
                                                                    "parentRowNumber": 4081,
                                                                    "articleId": "20120540",
                                                                    "description": "20120540-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0077,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0077,
                                                                    "singleQuantityMcf": 0.039886,
                                                                    "mcf": 0.039886,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.039886,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4095,
                                                                    "parentRowNumber": 4081,
                                                                    "articleId": "20120540",
                                                                    "description": "20120540-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0077,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0077,
                                                                    "singleQuantityMcf": 0.039886,
                                                                    "mcf": 0.039886,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.039886,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4096,
                                                                    "parentRowNumber": 4081,
                                                                    "articleId": "20120540",
                                                                    "description": "20120540-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0077,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0077,
                                                                    "singleQuantityMcf": 0.039886,
                                                                    "mcf": 0.039886,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.039886,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4097,
                                                                    "parentRowNumber": 4081,
                                                                    "articleId": "20120540",
                                                                    "description": "20120540-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0077,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0077,
                                                                    "singleQuantityMcf": 0.039886,
                                                                    "mcf": 0.039886,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.039886,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4098,
                                                                    "parentRowNumber": 4081,
                                                                    "articleId": "20120540",
                                                                    "description": "20120540-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0077,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0077,
                                                                    "singleQuantityMcf": 0.039886,
                                                                    "mcf": 0.039886,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.039886,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4099,
                                                                    "parentRowNumber": 4081,
                                                                    "articleId": "20120540",
                                                                    "description": "20120540-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0077,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0077,
                                                                    "singleQuantityMcf": 0.039886,
                                                                    "mcf": 0.039886,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.039886,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4100,
                                                                    "parentRowNumber": 4081,
                                                                    "articleId": "20120540",
                                                                    "description": "20120540-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0077,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0077,
                                                                    "singleQuantityMcf": 0.039886,
                                                                    "mcf": 0.039886,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.039886,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4101,
                                                                    "parentRowNumber": 4081,
                                                                    "articleId": "20120540",
                                                                    "description": "20120540-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0077,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0077,
                                                                    "singleQuantityMcf": 0.039886,
                                                                    "mcf": 0.039886,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.039886,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4102,
                                                                    "parentRowNumber": 4081,
                                                                    "articleId": "20120540",
                                                                    "description": "20120540-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0077,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0077,
                                                                    "singleQuantityMcf": 0.039886,
                                                                    "mcf": 0.039886,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.039886,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4103,
                                                                    "parentRowNumber": 4081,
                                                                    "articleId": "20120540",
                                                                    "description": "20120540-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0077,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0077,
                                                                    "singleQuantityMcf": 0.039886,
                                                                    "mcf": 0.039886,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.039886,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4104,
                                                                    "parentRowNumber": 4081,
                                                                    "articleId": "20120540",
                                                                    "description": "20120540-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0077,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0077,
                                                                    "singleQuantityMcf": 0.039886,
                                                                    "mcf": 0.039886,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.039886,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4105,
                                                                    "parentRowNumber": 4081,
                                                                    "articleId": "20120540",
                                                                    "description": "20120540-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0077,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0077,
                                                                    "singleQuantityMcf": 0.039886,
                                                                    "mcf": 0.039886,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.039886,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4106,
                                                                    "parentRowNumber": 4081,
                                                                    "articleId": "20120540",
                                                                    "description": "20120540-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0077,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0077,
                                                                    "singleQuantityMcf": 0.039886,
                                                                    "mcf": 0.039886,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.039886,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4107,
                                                                    "parentRowNumber": 4081,
                                                                    "articleId": "20120540",
                                                                    "description": "20120540-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0077,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0077,
                                                                    "singleQuantityMcf": 0.039886,
                                                                    "mcf": 0.039886,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.039886,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4108,
                                                                    "parentRowNumber": 4081,
                                                                    "articleId": "20120540",
                                                                    "description": "20120540-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0077,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0077,
                                                                    "singleQuantityMcf": 0.039886,
                                                                    "mcf": 0.039886,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.039886,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4109,
                                                                    "parentRowNumber": 4081,
                                                                    "articleId": "20120540",
                                                                    "description": "20120540-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0077,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0077,
                                                                    "singleQuantityMcf": 0.039886,
                                                                    "mcf": 0.039886,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.039886,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4110,
                                                                    "parentRowNumber": 4081,
                                                                    "articleId": "208405",
                                                                    "description": "208405-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0015,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0015,
                                                                    "singleQuantityMcf": 0.00777,
                                                                    "mcf": 0.00777,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.00777,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4111,
                                                                    "parentRowNumber": 4081,
                                                                    "articleId": "208405",
                                                                    "description": "208405-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0015,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0015,
                                                                    "singleQuantityMcf": 0.00777,
                                                                    "mcf": 0.00777,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.00777,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4112,
                                                                    "parentRowNumber": 4081,
                                                                    "articleId": "208405",
                                                                    "description": "208405-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0015,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0015,
                                                                    "singleQuantityMcf": 0.00777,
                                                                    "mcf": 0.00777,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.00777,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4113,
                                                                    "parentRowNumber": 4081,
                                                                    "articleId": "208405",
                                                                    "description": "208405-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0015,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0015,
                                                                    "singleQuantityMcf": 0.00777,
                                                                    "mcf": 0.00777,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.00777,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4114,
                                                                    "parentRowNumber": 4081,
                                                                    "articleId": "208405",
                                                                    "description": "208405-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0015,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0015,
                                                                    "singleQuantityMcf": 0.00777,
                                                                    "mcf": 0.00777,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.00777,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4115,
                                                                    "parentRowNumber": 4081,
                                                                    "articleId": "208405",
                                                                    "description": "208405-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0015,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0015,
                                                                    "singleQuantityMcf": 0.00777,
                                                                    "mcf": 0.00777,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.00777,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4116,
                                                                    "parentRowNumber": 4081,
                                                                    "articleId": "208405",
                                                                    "description": "208405-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0015,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0015,
                                                                    "singleQuantityMcf": 0.00777,
                                                                    "mcf": 0.00777,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.00777,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4117,
                                                                    "parentRowNumber": 4081,
                                                                    "articleId": "208405",
                                                                    "description": "208405-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0015,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0015,
                                                                    "singleQuantityMcf": 0.00777,
                                                                    "mcf": 0.00777,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.00777,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4118,
                                                                    "parentRowNumber": 4081,
                                                                    "articleId": "208405",
                                                                    "description": "208405-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0015,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0015,
                                                                    "singleQuantityMcf": 0.00777,
                                                                    "mcf": 0.00777,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.00777,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4119,
                                                                    "parentRowNumber": 4081,
                                                                    "articleId": "208405",
                                                                    "description": "208405-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0015,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0015,
                                                                    "singleQuantityMcf": 0.00777,
                                                                    "mcf": 0.00777,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.00777,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4120,
                                                                    "parentRowNumber": 4081,
                                                                    "articleId": "208405",
                                                                    "description": "208405-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0015,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0015,
                                                                    "singleQuantityMcf": 0.00777,
                                                                    "mcf": 0.00777,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.00777,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4121,
                                                                    "parentRowNumber": 4081,
                                                                    "articleId": "208405",
                                                                    "description": "208405-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0015,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0015,
                                                                    "singleQuantityMcf": 0.00777,
                                                                    "mcf": 0.00777,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.00777,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4122,
                                                                    "parentRowNumber": 4081,
                                                                    "articleId": "208405",
                                                                    "description": "208405-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0015,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0015,
                                                                    "singleQuantityMcf": 0.00777,
                                                                    "mcf": 0.00777,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.00777,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4123,
                                                                    "parentRowNumber": 4081,
                                                                    "articleId": "208405",
                                                                    "description": "208405-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0015,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0015,
                                                                    "singleQuantityMcf": 0.00777,
                                                                    "mcf": 0.00777,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.00777,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4124,
                                                                    "parentRowNumber": 4081,
                                                                    "articleId": "208405",
                                                                    "description": "208405-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0015,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0015,
                                                                    "singleQuantityMcf": 0.00777,
                                                                    "mcf": 0.00777,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.00777,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4125,
                                                                    "parentRowNumber": 4081,
                                                                    "articleId": "208405",
                                                                    "description": "208405-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0015,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0015,
                                                                    "singleQuantityMcf": 0.00777,
                                                                    "mcf": 0.00777,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.00777,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4126,
                                                                    "parentRowNumber": 4081,
                                                                    "articleId": "208405",
                                                                    "description": "208405-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0015,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0015,
                                                                    "singleQuantityMcf": 0.00777,
                                                                    "mcf": 0.00777,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.00777,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4127,
                                                                    "parentRowNumber": 4081,
                                                                    "articleId": "208405",
                                                                    "description": "208405-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0015,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0015,
                                                                    "singleQuantityMcf": 0.00777,
                                                                    "mcf": 0.00777,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.00777,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4128,
                                                                    "parentRowNumber": 4081,
                                                                    "articleId": "208405",
                                                                    "description": "208405-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0015,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0015,
                                                                    "singleQuantityMcf": 0.00777,
                                                                    "mcf": 0.00777,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.00777,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4129,
                                                                    "parentRowNumber": 4081,
                                                                    "articleId": "208405",
                                                                    "description": "208405-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0015,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0015,
                                                                    "singleQuantityMcf": 0.00777,
                                                                    "mcf": 0.00777,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.00777,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4130,
                                                                    "parentRowNumber": 4081,
                                                                    "articleId": "208405",
                                                                    "description": "208405-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0015,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0015,
                                                                    "singleQuantityMcf": 0.00777,
                                                                    "mcf": 0.00777,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.00777,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4131,
                                                                    "parentRowNumber": 4081,
                                                                    "articleId": "208405",
                                                                    "description": "208405-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0015,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0015,
                                                                    "singleQuantityMcf": 0.00777,
                                                                    "mcf": 0.00777,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.00777,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4132,
                                                                    "parentRowNumber": 4081,
                                                                    "articleId": "208405",
                                                                    "description": "208405-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0015,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0015,
                                                                    "singleQuantityMcf": 0.00777,
                                                                    "mcf": 0.00777,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.00777,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4133,
                                                                    "parentRowNumber": 4081,
                                                                    "articleId": "208405",
                                                                    "description": "208405-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0015,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0015,
                                                                    "singleQuantityMcf": 0.00777,
                                                                    "mcf": 0.00777,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.00777,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4134,
                                                                    "parentRowNumber": 4081,
                                                                    "articleId": "208405",
                                                                    "description": "208405-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0015,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0015,
                                                                    "singleQuantityMcf": 0.00777,
                                                                    "mcf": 0.00777,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.00777,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4135,
                                                                    "parentRowNumber": 4081,
                                                                    "articleId": "208405",
                                                                    "description": "208405-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0015,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0015,
                                                                    "singleQuantityMcf": 0.00777,
                                                                    "mcf": 0.00777,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.00777,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4136,
                                                                    "parentRowNumber": 4081,
                                                                    "articleId": "208405",
                                                                    "description": "208405-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0015,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0015,
                                                                    "singleQuantityMcf": 0.00777,
                                                                    "mcf": 0.00777,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.00777,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4137,
                                                                    "parentRowNumber": 4081,
                                                                    "articleId": "208405",
                                                                    "description": "208405-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0015,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0015,
                                                                    "singleQuantityMcf": 0.00777,
                                                                    "mcf": 0.00777,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.00777,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4138,
                                                                    "parentRowNumber": 4081,
                                                                    "articleId": "46645931",
                                                                    "description": "46645931-00-Formatbuegel 2.0",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.1508,
                                                                    "childrenWeight": 0.1508,
                                                                    "totalQuantityWeight": 0.1508,
                                                                    "singleQuantityMcf": 0.781144,
                                                                    "mcf": 0.781144,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.781144,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 4139,
                                                                            "parentRowNumber": 4138,
                                                                            "articleId": "142612",
                                                                            "description": "142612-00-Edelstahlblech",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.092,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.092,
                                                                            "singleQuantityMcf": 0.47656,
                                                                            "mcf": 0.47656,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.47656,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        },
                                                                        {
                                                                            "rowNumber": 4140,
                                                                            "parentRowNumber": 4138,
                                                                            "articleId": "46647477",
                                                                            "description": "46647477-00-Halter",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0294,
                                                                            "childrenWeight": 0.0294,
                                                                            "totalQuantityWeight": 0.0294,
                                                                            "singleQuantityMcf": 0.15229199999999998,
                                                                            "mcf": 0.15229199999999998,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.15229199999999998,
                                                                            "expandable": false,
                                                                            "children": [
                                                                                {
                                                                                    "rowNumber": 4141,
                                                                                    "parentRowNumber": 4140,
                                                                                    "articleId": "1110231",
                                                                                    "description": "1110231-00-Flachstahl",
                                                                                    "hierarchy": 9,
                                                                                    "quantity": 1,
                                                                                    "weight": 0.0294,
                                                                                    "childrenWeight": 0,
                                                                                    "totalQuantityWeight": 0.0294,
                                                                                    "singleQuantityMcf": 0.15229199999999998,
                                                                                    "mcf": 0.15229199999999998,
                                                                                    "singleQuantityLcf": 0,
                                                                                    "lcf": 0,
                                                                                    "totalQuantityMcf": 0.15229199999999998,
                                                                                    "expandable": false,
                                                                                    "children": []
                                                                                }
                                                                            ]
                                                                        },
                                                                        {
                                                                            "rowNumber": 4142,
                                                                            "parentRowNumber": 4138,
                                                                            "articleId": "46647477",
                                                                            "description": "46647477-00-Halter",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0294,
                                                                            "childrenWeight": 0.0294,
                                                                            "totalQuantityWeight": 0.0294,
                                                                            "singleQuantityMcf": 0.15229199999999998,
                                                                            "mcf": 0.15229199999999998,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.15229199999999998,
                                                                            "expandable": false,
                                                                            "children": [
                                                                                {
                                                                                    "rowNumber": 4143,
                                                                                    "parentRowNumber": 4142,
                                                                                    "articleId": "1110231",
                                                                                    "description": "1110231-00-Flachstahl",
                                                                                    "hierarchy": 9,
                                                                                    "quantity": 1,
                                                                                    "weight": 0.0294,
                                                                                    "childrenWeight": 0,
                                                                                    "totalQuantityWeight": 0.0294,
                                                                                    "singleQuantityMcf": 0.15229199999999998,
                                                                                    "mcf": 0.15229199999999998,
                                                                                    "singleQuantityLcf": 0,
                                                                                    "lcf": 0,
                                                                                    "totalQuantityMcf": 0.15229199999999998,
                                                                                    "expandable": false,
                                                                                    "children": []
                                                                                }
                                                                            ]
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 4144,
                                                                    "parentRowNumber": 4081,
                                                                    "articleId": "46645931",
                                                                    "description": "46645931-00-Formatbuegel 2.0",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.1508,
                                                                    "childrenWeight": 0.1508,
                                                                    "totalQuantityWeight": 0.1508,
                                                                    "singleQuantityMcf": 0.781144,
                                                                    "mcf": 0.781144,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.781144,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 4145,
                                                                            "parentRowNumber": 4144,
                                                                            "articleId": "142612",
                                                                            "description": "142612-00-Edelstahlblech",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.092,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.092,
                                                                            "singleQuantityMcf": 0.47656,
                                                                            "mcf": 0.47656,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.47656,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        },
                                                                        {
                                                                            "rowNumber": 4146,
                                                                            "parentRowNumber": 4144,
                                                                            "articleId": "46647477",
                                                                            "description": "46647477-00-Halter",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0294,
                                                                            "childrenWeight": 0.0294,
                                                                            "totalQuantityWeight": 0.0294,
                                                                            "singleQuantityMcf": 0.15229199999999998,
                                                                            "mcf": 0.15229199999999998,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.15229199999999998,
                                                                            "expandable": false,
                                                                            "children": [
                                                                                {
                                                                                    "rowNumber": 4147,
                                                                                    "parentRowNumber": 4146,
                                                                                    "articleId": "1110231",
                                                                                    "description": "1110231-00-Flachstahl",
                                                                                    "hierarchy": 9,
                                                                                    "quantity": 1,
                                                                                    "weight": 0.0294,
                                                                                    "childrenWeight": 0,
                                                                                    "totalQuantityWeight": 0.0294,
                                                                                    "singleQuantityMcf": 0.15229199999999998,
                                                                                    "mcf": 0.15229199999999998,
                                                                                    "singleQuantityLcf": 0,
                                                                                    "lcf": 0,
                                                                                    "totalQuantityMcf": 0.15229199999999998,
                                                                                    "expandable": false,
                                                                                    "children": []
                                                                                }
                                                                            ]
                                                                        },
                                                                        {
                                                                            "rowNumber": 4148,
                                                                            "parentRowNumber": 4144,
                                                                            "articleId": "46647477",
                                                                            "description": "46647477-00-Halter",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0294,
                                                                            "childrenWeight": 0.0294,
                                                                            "totalQuantityWeight": 0.0294,
                                                                            "singleQuantityMcf": 0.15229199999999998,
                                                                            "mcf": 0.15229199999999998,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.15229199999999998,
                                                                            "expandable": false,
                                                                            "children": [
                                                                                {
                                                                                    "rowNumber": 4149,
                                                                                    "parentRowNumber": 4148,
                                                                                    "articleId": "1110231",
                                                                                    "description": "1110231-00-Flachstahl",
                                                                                    "hierarchy": 9,
                                                                                    "quantity": 1,
                                                                                    "weight": 0.0294,
                                                                                    "childrenWeight": 0,
                                                                                    "totalQuantityWeight": 0.0294,
                                                                                    "singleQuantityMcf": 0.15229199999999998,
                                                                                    "mcf": 0.15229199999999998,
                                                                                    "singleQuantityLcf": 0,
                                                                                    "lcf": 0,
                                                                                    "totalQuantityMcf": 0.15229199999999998,
                                                                                    "expandable": false,
                                                                                    "children": []
                                                                                }
                                                                            ]
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 4150,
                                                                    "parentRowNumber": 4081,
                                                                    "articleId": "46645931",
                                                                    "description": "46645931-00-Formatbuegel 2.0",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.1508,
                                                                    "childrenWeight": 0.1508,
                                                                    "totalQuantityWeight": 0.1508,
                                                                    "singleQuantityMcf": 0.781144,
                                                                    "mcf": 0.781144,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.781144,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 4151,
                                                                            "parentRowNumber": 4150,
                                                                            "articleId": "142612",
                                                                            "description": "142612-00-Edelstahlblech",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.092,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.092,
                                                                            "singleQuantityMcf": 0.47656,
                                                                            "mcf": 0.47656,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.47656,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        },
                                                                        {
                                                                            "rowNumber": 4152,
                                                                            "parentRowNumber": 4150,
                                                                            "articleId": "46647477",
                                                                            "description": "46647477-00-Halter",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0294,
                                                                            "childrenWeight": 0.0294,
                                                                            "totalQuantityWeight": 0.0294,
                                                                            "singleQuantityMcf": 0.15229199999999998,
                                                                            "mcf": 0.15229199999999998,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.15229199999999998,
                                                                            "expandable": false,
                                                                            "children": [
                                                                                {
                                                                                    "rowNumber": 4153,
                                                                                    "parentRowNumber": 4152,
                                                                                    "articleId": "1110231",
                                                                                    "description": "1110231-00-Flachstahl",
                                                                                    "hierarchy": 9,
                                                                                    "quantity": 1,
                                                                                    "weight": 0.0294,
                                                                                    "childrenWeight": 0,
                                                                                    "totalQuantityWeight": 0.0294,
                                                                                    "singleQuantityMcf": 0.15229199999999998,
                                                                                    "mcf": 0.15229199999999998,
                                                                                    "singleQuantityLcf": 0,
                                                                                    "lcf": 0,
                                                                                    "totalQuantityMcf": 0.15229199999999998,
                                                                                    "expandable": false,
                                                                                    "children": []
                                                                                }
                                                                            ]
                                                                        },
                                                                        {
                                                                            "rowNumber": 4154,
                                                                            "parentRowNumber": 4150,
                                                                            "articleId": "46647477",
                                                                            "description": "46647477-00-Halter",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0294,
                                                                            "childrenWeight": 0.0294,
                                                                            "totalQuantityWeight": 0.0294,
                                                                            "singleQuantityMcf": 0.15229199999999998,
                                                                            "mcf": 0.15229199999999998,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.15229199999999998,
                                                                            "expandable": false,
                                                                            "children": [
                                                                                {
                                                                                    "rowNumber": 4155,
                                                                                    "parentRowNumber": 4154,
                                                                                    "articleId": "1110231",
                                                                                    "description": "1110231-00-Flachstahl",
                                                                                    "hierarchy": 9,
                                                                                    "quantity": 1,
                                                                                    "weight": 0.0294,
                                                                                    "childrenWeight": 0,
                                                                                    "totalQuantityWeight": 0.0294,
                                                                                    "singleQuantityMcf": 0.15229199999999998,
                                                                                    "mcf": 0.15229199999999998,
                                                                                    "singleQuantityLcf": 0,
                                                                                    "lcf": 0,
                                                                                    "totalQuantityMcf": 0.15229199999999998,
                                                                                    "expandable": false,
                                                                                    "children": []
                                                                                }
                                                                            ]
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 4156,
                                                                    "parentRowNumber": 4081,
                                                                    "articleId": "46645931",
                                                                    "description": "46645931-00-Formatbuegel 2.0",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.1508,
                                                                    "childrenWeight": 0.1508,
                                                                    "totalQuantityWeight": 0.1508,
                                                                    "singleQuantityMcf": 0.781144,
                                                                    "mcf": 0.781144,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.781144,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 4157,
                                                                            "parentRowNumber": 4156,
                                                                            "articleId": "142612",
                                                                            "description": "142612-00-Edelstahlblech",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.092,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.092,
                                                                            "singleQuantityMcf": 0.47656,
                                                                            "mcf": 0.47656,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.47656,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        },
                                                                        {
                                                                            "rowNumber": 4158,
                                                                            "parentRowNumber": 4156,
                                                                            "articleId": "46647477",
                                                                            "description": "46647477-00-Halter",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0294,
                                                                            "childrenWeight": 0.0294,
                                                                            "totalQuantityWeight": 0.0294,
                                                                            "singleQuantityMcf": 0.15229199999999998,
                                                                            "mcf": 0.15229199999999998,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.15229199999999998,
                                                                            "expandable": false,
                                                                            "children": [
                                                                                {
                                                                                    "rowNumber": 4159,
                                                                                    "parentRowNumber": 4158,
                                                                                    "articleId": "1110231",
                                                                                    "description": "1110231-00-Flachstahl",
                                                                                    "hierarchy": 9,
                                                                                    "quantity": 1,
                                                                                    "weight": 0.0294,
                                                                                    "childrenWeight": 0,
                                                                                    "totalQuantityWeight": 0.0294,
                                                                                    "singleQuantityMcf": 0.15229199999999998,
                                                                                    "mcf": 0.15229199999999998,
                                                                                    "singleQuantityLcf": 0,
                                                                                    "lcf": 0,
                                                                                    "totalQuantityMcf": 0.15229199999999998,
                                                                                    "expandable": false,
                                                                                    "children": []
                                                                                }
                                                                            ]
                                                                        },
                                                                        {
                                                                            "rowNumber": 4160,
                                                                            "parentRowNumber": 4156,
                                                                            "articleId": "46647477",
                                                                            "description": "46647477-00-Halter",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0294,
                                                                            "childrenWeight": 0.0294,
                                                                            "totalQuantityWeight": 0.0294,
                                                                            "singleQuantityMcf": 0.15229199999999998,
                                                                            "mcf": 0.15229199999999998,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.15229199999999998,
                                                                            "expandable": false,
                                                                            "children": [
                                                                                {
                                                                                    "rowNumber": 4161,
                                                                                    "parentRowNumber": 4160,
                                                                                    "articleId": "1110231",
                                                                                    "description": "1110231-00-Flachstahl",
                                                                                    "hierarchy": 9,
                                                                                    "quantity": 1,
                                                                                    "weight": 0.0294,
                                                                                    "childrenWeight": 0,
                                                                                    "totalQuantityWeight": 0.0294,
                                                                                    "singleQuantityMcf": 0.15229199999999998,
                                                                                    "mcf": 0.15229199999999998,
                                                                                    "singleQuantityLcf": 0,
                                                                                    "lcf": 0,
                                                                                    "totalQuantityMcf": 0.15229199999999998,
                                                                                    "expandable": false,
                                                                                    "children": []
                                                                                }
                                                                            ]
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 4162,
                                                                    "parentRowNumber": 4081,
                                                                    "articleId": "46645931",
                                                                    "description": "46645931-00-Formatbuegel 2.0",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.1508,
                                                                    "childrenWeight": 0.1508,
                                                                    "totalQuantityWeight": 0.1508,
                                                                    "singleQuantityMcf": 0.781144,
                                                                    "mcf": 0.781144,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.781144,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 4163,
                                                                            "parentRowNumber": 4162,
                                                                            "articleId": "142612",
                                                                            "description": "142612-00-Edelstahlblech",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.092,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.092,
                                                                            "singleQuantityMcf": 0.47656,
                                                                            "mcf": 0.47656,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.47656,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        },
                                                                        {
                                                                            "rowNumber": 4164,
                                                                            "parentRowNumber": 4162,
                                                                            "articleId": "46647477",
                                                                            "description": "46647477-00-Halter",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0294,
                                                                            "childrenWeight": 0.0294,
                                                                            "totalQuantityWeight": 0.0294,
                                                                            "singleQuantityMcf": 0.15229199999999998,
                                                                            "mcf": 0.15229199999999998,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.15229199999999998,
                                                                            "expandable": false,
                                                                            "children": [
                                                                                {
                                                                                    "rowNumber": 4165,
                                                                                    "parentRowNumber": 4164,
                                                                                    "articleId": "1110231",
                                                                                    "description": "1110231-00-Flachstahl",
                                                                                    "hierarchy": 9,
                                                                                    "quantity": 1,
                                                                                    "weight": 0.0294,
                                                                                    "childrenWeight": 0,
                                                                                    "totalQuantityWeight": 0.0294,
                                                                                    "singleQuantityMcf": 0.15229199999999998,
                                                                                    "mcf": 0.15229199999999998,
                                                                                    "singleQuantityLcf": 0,
                                                                                    "lcf": 0,
                                                                                    "totalQuantityMcf": 0.15229199999999998,
                                                                                    "expandable": false,
                                                                                    "children": []
                                                                                }
                                                                            ]
                                                                        },
                                                                        {
                                                                            "rowNumber": 4166,
                                                                            "parentRowNumber": 4162,
                                                                            "articleId": "46647477",
                                                                            "description": "46647477-00-Halter",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0294,
                                                                            "childrenWeight": 0.0294,
                                                                            "totalQuantityWeight": 0.0294,
                                                                            "singleQuantityMcf": 0.15229199999999998,
                                                                            "mcf": 0.15229199999999998,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.15229199999999998,
                                                                            "expandable": false,
                                                                            "children": [
                                                                                {
                                                                                    "rowNumber": 4167,
                                                                                    "parentRowNumber": 4166,
                                                                                    "articleId": "1110231",
                                                                                    "description": "1110231-00-Flachstahl",
                                                                                    "hierarchy": 9,
                                                                                    "quantity": 1,
                                                                                    "weight": 0.0294,
                                                                                    "childrenWeight": 0,
                                                                                    "totalQuantityWeight": 0.0294,
                                                                                    "singleQuantityMcf": 0.15229199999999998,
                                                                                    "mcf": 0.15229199999999998,
                                                                                    "singleQuantityLcf": 0,
                                                                                    "lcf": 0,
                                                                                    "totalQuantityMcf": 0.15229199999999998,
                                                                                    "expandable": false,
                                                                                    "children": []
                                                                                }
                                                                            ]
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 4168,
                                                                    "parentRowNumber": 4081,
                                                                    "articleId": "46645931",
                                                                    "description": "46645931-00-Formatbuegel 2.0",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.1508,
                                                                    "childrenWeight": 0.1508,
                                                                    "totalQuantityWeight": 0.1508,
                                                                    "singleQuantityMcf": 0.781144,
                                                                    "mcf": 0.781144,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.781144,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 4169,
                                                                            "parentRowNumber": 4168,
                                                                            "articleId": "142612",
                                                                            "description": "142612-00-Edelstahlblech",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.092,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.092,
                                                                            "singleQuantityMcf": 0.47656,
                                                                            "mcf": 0.47656,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.47656,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        },
                                                                        {
                                                                            "rowNumber": 4170,
                                                                            "parentRowNumber": 4168,
                                                                            "articleId": "46647477",
                                                                            "description": "46647477-00-Halter",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0294,
                                                                            "childrenWeight": 0.0294,
                                                                            "totalQuantityWeight": 0.0294,
                                                                            "singleQuantityMcf": 0.15229199999999998,
                                                                            "mcf": 0.15229199999999998,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.15229199999999998,
                                                                            "expandable": false,
                                                                            "children": [
                                                                                {
                                                                                    "rowNumber": 4171,
                                                                                    "parentRowNumber": 4170,
                                                                                    "articleId": "1110231",
                                                                                    "description": "1110231-00-Flachstahl",
                                                                                    "hierarchy": 9,
                                                                                    "quantity": 1,
                                                                                    "weight": 0.0294,
                                                                                    "childrenWeight": 0,
                                                                                    "totalQuantityWeight": 0.0294,
                                                                                    "singleQuantityMcf": 0.15229199999999998,
                                                                                    "mcf": 0.15229199999999998,
                                                                                    "singleQuantityLcf": 0,
                                                                                    "lcf": 0,
                                                                                    "totalQuantityMcf": 0.15229199999999998,
                                                                                    "expandable": false,
                                                                                    "children": []
                                                                                }
                                                                            ]
                                                                        },
                                                                        {
                                                                            "rowNumber": 4172,
                                                                            "parentRowNumber": 4168,
                                                                            "articleId": "46647477",
                                                                            "description": "46647477-00-Halter",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0294,
                                                                            "childrenWeight": 0.0294,
                                                                            "totalQuantityWeight": 0.0294,
                                                                            "singleQuantityMcf": 0.15229199999999998,
                                                                            "mcf": 0.15229199999999998,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.15229199999999998,
                                                                            "expandable": false,
                                                                            "children": [
                                                                                {
                                                                                    "rowNumber": 4173,
                                                                                    "parentRowNumber": 4172,
                                                                                    "articleId": "1110231",
                                                                                    "description": "1110231-00-Flachstahl",
                                                                                    "hierarchy": 9,
                                                                                    "quantity": 1,
                                                                                    "weight": 0.0294,
                                                                                    "childrenWeight": 0,
                                                                                    "totalQuantityWeight": 0.0294,
                                                                                    "singleQuantityMcf": 0.15229199999999998,
                                                                                    "mcf": 0.15229199999999998,
                                                                                    "singleQuantityLcf": 0,
                                                                                    "lcf": 0,
                                                                                    "totalQuantityMcf": 0.15229199999999998,
                                                                                    "expandable": false,
                                                                                    "children": []
                                                                                }
                                                                            ]
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 4174,
                                                                    "parentRowNumber": 4081,
                                                                    "articleId": "46645931",
                                                                    "description": "46645931-00-Formatbuegel 2.0",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.1508,
                                                                    "childrenWeight": 0.1508,
                                                                    "totalQuantityWeight": 0.1508,
                                                                    "singleQuantityMcf": 0.781144,
                                                                    "mcf": 0.781144,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.781144,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 4175,
                                                                            "parentRowNumber": 4174,
                                                                            "articleId": "142612",
                                                                            "description": "142612-00-Edelstahlblech",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.092,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.092,
                                                                            "singleQuantityMcf": 0.47656,
                                                                            "mcf": 0.47656,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.47656,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        },
                                                                        {
                                                                            "rowNumber": 4176,
                                                                            "parentRowNumber": 4174,
                                                                            "articleId": "46647477",
                                                                            "description": "46647477-00-Halter",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0294,
                                                                            "childrenWeight": 0.0294,
                                                                            "totalQuantityWeight": 0.0294,
                                                                            "singleQuantityMcf": 0.15229199999999998,
                                                                            "mcf": 0.15229199999999998,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.15229199999999998,
                                                                            "expandable": false,
                                                                            "children": [
                                                                                {
                                                                                    "rowNumber": 4177,
                                                                                    "parentRowNumber": 4176,
                                                                                    "articleId": "1110231",
                                                                                    "description": "1110231-00-Flachstahl",
                                                                                    "hierarchy": 9,
                                                                                    "quantity": 1,
                                                                                    "weight": 0.0294,
                                                                                    "childrenWeight": 0,
                                                                                    "totalQuantityWeight": 0.0294,
                                                                                    "singleQuantityMcf": 0.15229199999999998,
                                                                                    "mcf": 0.15229199999999998,
                                                                                    "singleQuantityLcf": 0,
                                                                                    "lcf": 0,
                                                                                    "totalQuantityMcf": 0.15229199999999998,
                                                                                    "expandable": false,
                                                                                    "children": []
                                                                                }
                                                                            ]
                                                                        },
                                                                        {
                                                                            "rowNumber": 4178,
                                                                            "parentRowNumber": 4174,
                                                                            "articleId": "46647477",
                                                                            "description": "46647477-00-Halter",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0294,
                                                                            "childrenWeight": 0.0294,
                                                                            "totalQuantityWeight": 0.0294,
                                                                            "singleQuantityMcf": 0.15229199999999998,
                                                                            "mcf": 0.15229199999999998,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.15229199999999998,
                                                                            "expandable": false,
                                                                            "children": [
                                                                                {
                                                                                    "rowNumber": 4179,
                                                                                    "parentRowNumber": 4178,
                                                                                    "articleId": "1110231",
                                                                                    "description": "1110231-00-Flachstahl",
                                                                                    "hierarchy": 9,
                                                                                    "quantity": 1,
                                                                                    "weight": 0.0294,
                                                                                    "childrenWeight": 0,
                                                                                    "totalQuantityWeight": 0.0294,
                                                                                    "singleQuantityMcf": 0.15229199999999998,
                                                                                    "mcf": 0.15229199999999998,
                                                                                    "singleQuantityLcf": 0,
                                                                                    "lcf": 0,
                                                                                    "totalQuantityMcf": 0.15229199999999998,
                                                                                    "expandable": false,
                                                                                    "children": []
                                                                                }
                                                                            ]
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 4180,
                                                                    "parentRowNumber": 4081,
                                                                    "articleId": "47238067",
                                                                    "description": "47238067-00-Drehtellerfuehrungen",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 2.7026,
                                                                    "childrenWeight": 2.7026,
                                                                    "totalQuantityWeight": 2.7026,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 4181,
                                                                            "parentRowNumber": 4180,
                                                                            "articleId": "162518",
                                                                            "description": "162518-01-Platte",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 2.7026,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 2.7026,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 4182,
                                                                    "parentRowNumber": 4081,
                                                                    "articleId": "47238068",
                                                                    "description": "47238068-00-Drehtellerfuehrungen",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 6.9683,
                                                                    "childrenWeight": 6.9683,
                                                                    "totalQuantityWeight": 6.9683,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 4183,
                                                                            "parentRowNumber": 4182,
                                                                            "articleId": "162518",
                                                                            "description": "162518-01-Platte",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 6.9683,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 6.9683,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 4184,
                                                                    "parentRowNumber": 4081,
                                                                    "articleId": "OBJEKT SERUM 33847 42.5X77",
                                                                    "description": "OBJEKT SERUM 33847 42.5X77-00-Runde Flasche mit Boerdelverschluss",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.1349,
                                                                    "childrenWeight": 0.1349,
                                                                    "totalQuantityWeight": 0.1349,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 4185,
                                                                            "parentRowNumber": 4184,
                                                                            "articleId": "OBJ0000000001911",
                                                                            "description": "OBJ0000000001911-01-Flip-Off Kappe",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0076,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0076,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        },
                                                                        {
                                                                            "rowNumber": 4186,
                                                                            "parentRowNumber": 4184,
                                                                            "articleId": "OBJ0000000005763",
                                                                            "description": "OBJ0000000005763-00-Runde Flasche mit Boerdelverschluss",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.1175,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.1175,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        },
                                                                        {
                                                                            "rowNumber": 4187,
                                                                            "parentRowNumber": 4184,
                                                                            "articleId": "OBJ0000000007945",
                                                                            "description": "OBJ0000000007945-00-Stopfen",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0098,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0098,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 4188,
                                                                    "parentRowNumber": 4081,
                                                                    "articleId": "OBJEKT SERUM 33847 42.5X77",
                                                                    "description": "OBJEKT SERUM 33847 42.5X77-00-Runde Flasche mit Boerdelverschluss",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.1349,
                                                                    "childrenWeight": 0.1349,
                                                                    "totalQuantityWeight": 0.1349,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 4189,
                                                                            "parentRowNumber": 4188,
                                                                            "articleId": "OBJ0000000001911",
                                                                            "description": "OBJ0000000001911-01-Flip-Off Kappe",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0076,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0076,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        },
                                                                        {
                                                                            "rowNumber": 4190,
                                                                            "parentRowNumber": 4188,
                                                                            "articleId": "OBJ0000000005763",
                                                                            "description": "OBJ0000000005763-00-Runde Flasche mit Boerdelverschluss",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.1175,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.1175,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        },
                                                                        {
                                                                            "rowNumber": 4191,
                                                                            "parentRowNumber": 4188,
                                                                            "articleId": "OBJ0000000007945",
                                                                            "description": "OBJ0000000007945-00-Stopfen",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0098,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0098,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                }
                                                            ]
                                                        }
                                                    ]
                                                }
                                            ]
                                        },
                                        {
                                            "rowNumber": 4192,
                                            "parentRowNumber": 3160,
                                            "articleId": "P",
                                            "description": "P-ESA1025-33847-20-00-Einlauf",
                                            "hierarchy": 4,
                                            "quantity": 1,
                                            "weight": 0,
                                            "childrenWeight": 0,
                                            "totalQuantityWeight": 0,
                                            "singleQuantityMcf": 0,
                                            "mcf": 0,
                                            "singleQuantityLcf": 0,
                                            "lcf": 0,
                                            "totalQuantityMcf": 0,
                                            "expandable": false,
                                            "children": [
                                                {
                                                    "rowNumber": 4193,
                                                    "parentRowNumber": 4192,
                                                    "articleId": "P",
                                                    "description": "P-ESA1025-33847-20-0001-00-Einlauf",
                                                    "hierarchy": 5,
                                                    "quantity": 1,
                                                    "weight": 0,
                                                    "childrenWeight": 0,
                                                    "totalQuantityWeight": 0,
                                                    "singleQuantityMcf": 0,
                                                    "mcf": 0,
                                                    "singleQuantityLcf": 0,
                                                    "lcf": 0,
                                                    "totalQuantityMcf": 0,
                                                    "expandable": false,
                                                    "children": []
                                                }
                                            ]
                                        }
                                    ]
                                },
                                {
                                    "rowNumber": 4194,
                                    "parentRowNumber": 2020,
                                    "articleId": "33847",
                                    "description": "33847-20SL-01-Schnecke",
                                    "hierarchy": 3,
                                    "quantity": 1,
                                    "weight": 108.9877,
                                    "childrenWeight": 108.9877,
                                    "totalQuantityWeight": 108.9877,
                                    "singleQuantityMcf": 342.40980149,
                                    "mcf": 342.40980149,
                                    "singleQuantityLcf": 0,
                                    "lcf": 0,
                                    "totalQuantityMcf": 342.40980149,
                                    "expandable": false,
                                    "children": [
                                        {
                                            "rowNumber": 4195,
                                            "parentRowNumber": 4194,
                                            "articleId": "33847",
                                            "description": "33847-20SL10-03-Grundeinheit",
                                            "hierarchy": 4,
                                            "quantity": 1,
                                            "weight": 18.948999999999984,
                                            "childrenWeight": 18.948999999999984,
                                            "totalQuantityWeight": 18.948999999999984,
                                            "singleQuantityMcf": 50.85950688999998,
                                            "mcf": 50.85950688999998,
                                            "singleQuantityLcf": 0,
                                            "lcf": 0,
                                            "totalQuantityMcf": 50.85950688999998,
                                            "expandable": false,
                                            "children": [
                                                {
                                                    "rowNumber": 4196,
                                                    "parentRowNumber": 4195,
                                                    "articleId": "MM0000005665",
                                                    "description": "MM0000005665-03-Grundeinheit",
                                                    "hierarchy": 5,
                                                    "quantity": 1,
                                                    "weight": 18.948999999999984,
                                                    "childrenWeight": 18.948999999999984,
                                                    "totalQuantityWeight": 18.948999999999984,
                                                    "singleQuantityMcf": 50.85950688999998,
                                                    "mcf": 50.85950688999998,
                                                    "singleQuantityLcf": 0,
                                                    "lcf": 0,
                                                    "totalQuantityMcf": 50.85950688999998,
                                                    "expandable": false,
                                                    "children": [
                                                        {
                                                            "rowNumber": 4197,
                                                            "parentRowNumber": 4196,
                                                            "articleId": "47100834",
                                                            "description": "47100834-03-Grundeinheit",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 16.908699999999985,
                                                            "childrenWeight": 16.908699999999985,
                                                            "totalQuantityWeight": 16.908699999999985,
                                                            "singleQuantityMcf": 34.92438848999998,
                                                            "mcf": 34.92438848999998,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 34.92438848999998,
                                                            "expandable": false,
                                                            "children": [
                                                                {
                                                                    "rowNumber": 4198,
                                                                    "parentRowNumber": 4197,
                                                                    "articleId": "20120410",
                                                                    "description": "20120410-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0019,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0019,
                                                                    "singleQuantityMcf": 0.009842,
                                                                    "mcf": 0.009842,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.009842,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4199,
                                                                    "parentRowNumber": 4197,
                                                                    "articleId": "20120410",
                                                                    "description": "20120410-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0019,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0019,
                                                                    "singleQuantityMcf": 0.009842,
                                                                    "mcf": 0.009842,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.009842,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4200,
                                                                    "parentRowNumber": 4197,
                                                                    "articleId": "20120410",
                                                                    "description": "20120410-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0019,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0019,
                                                                    "singleQuantityMcf": 0.009842,
                                                                    "mcf": 0.009842,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.009842,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4201,
                                                                    "parentRowNumber": 4197,
                                                                    "articleId": "20120410",
                                                                    "description": "20120410-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0019,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0019,
                                                                    "singleQuantityMcf": 0.009842,
                                                                    "mcf": 0.009842,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.009842,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4202,
                                                                    "parentRowNumber": 4197,
                                                                    "articleId": "20120410",
                                                                    "description": "20120410-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0019,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0019,
                                                                    "singleQuantityMcf": 0.009842,
                                                                    "mcf": 0.009842,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.009842,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4203,
                                                                    "parentRowNumber": 4197,
                                                                    "articleId": "20120410",
                                                                    "description": "20120410-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0019,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0019,
                                                                    "singleQuantityMcf": 0.009842,
                                                                    "mcf": 0.009842,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.009842,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4204,
                                                                    "parentRowNumber": 4197,
                                                                    "articleId": "20120410",
                                                                    "description": "20120410-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0019,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0019,
                                                                    "singleQuantityMcf": 0.009842,
                                                                    "mcf": 0.009842,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.009842,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4205,
                                                                    "parentRowNumber": 4197,
                                                                    "articleId": "20120410",
                                                                    "description": "20120410-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0019,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0019,
                                                                    "singleQuantityMcf": 0.009842,
                                                                    "mcf": 0.009842,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.009842,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4206,
                                                                    "parentRowNumber": 4197,
                                                                    "articleId": "20120635",
                                                                    "description": "20120635-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0105,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0105,
                                                                    "singleQuantityMcf": 0.05439,
                                                                    "mcf": 0.05439,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.05439,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4207,
                                                                    "parentRowNumber": 4197,
                                                                    "articleId": "20120635",
                                                                    "description": "20120635-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0105,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0105,
                                                                    "singleQuantityMcf": 0.05439,
                                                                    "mcf": 0.05439,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.05439,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4208,
                                                                    "parentRowNumber": 4197,
                                                                    "articleId": "20120635",
                                                                    "description": "20120635-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0105,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0105,
                                                                    "singleQuantityMcf": 0.05439,
                                                                    "mcf": 0.05439,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.05439,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4209,
                                                                    "parentRowNumber": 4197,
                                                                    "articleId": "20120635",
                                                                    "description": "20120635-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0105,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0105,
                                                                    "singleQuantityMcf": 0.05439,
                                                                    "mcf": 0.05439,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.05439,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4210,
                                                                    "parentRowNumber": 4197,
                                                                    "articleId": "20120665",
                                                                    "description": "20120665-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0173,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0173,
                                                                    "singleQuantityMcf": 0.08961399999999999,
                                                                    "mcf": 0.08961399999999999,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.08961399999999999,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4211,
                                                                    "parentRowNumber": 4197,
                                                                    "articleId": "20120665",
                                                                    "description": "20120665-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0173,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0173,
                                                                    "singleQuantityMcf": 0.08961399999999999,
                                                                    "mcf": 0.08961399999999999,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.08961399999999999,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4212,
                                                                    "parentRowNumber": 4197,
                                                                    "articleId": "20120815",
                                                                    "description": "20120815-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0125,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0125,
                                                                    "singleQuantityMcf": 0.06475,
                                                                    "mcf": 0.06475,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.06475,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4213,
                                                                    "parentRowNumber": 4197,
                                                                    "articleId": "20120815",
                                                                    "description": "20120815-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0125,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0125,
                                                                    "singleQuantityMcf": 0.06475,
                                                                    "mcf": 0.06475,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.06475,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4214,
                                                                    "parentRowNumber": 4197,
                                                                    "articleId": "20120835",
                                                                    "description": "20120835-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0201,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0201,
                                                                    "singleQuantityMcf": 0.104118,
                                                                    "mcf": 0.104118,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.104118,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4215,
                                                                    "parentRowNumber": 4197,
                                                                    "articleId": "20120835",
                                                                    "description": "20120835-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0201,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0201,
                                                                    "singleQuantityMcf": 0.104118,
                                                                    "mcf": 0.104118,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.104118,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4216,
                                                                    "parentRowNumber": 4197,
                                                                    "articleId": "203204012",
                                                                    "description": "203204012-00-Zylinderschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0018,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0018,
                                                                    "singleQuantityMcf": 0.009324,
                                                                    "mcf": 0.009324,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.009324,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4217,
                                                                    "parentRowNumber": 4197,
                                                                    "articleId": "203204012",
                                                                    "description": "203204012-00-Zylinderschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0018,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0018,
                                                                    "singleQuantityMcf": 0.009324,
                                                                    "mcf": 0.009324,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.009324,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4218,
                                                                    "parentRowNumber": 4197,
                                                                    "articleId": "203204012",
                                                                    "description": "203204012-00-Zylinderschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0018,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0018,
                                                                    "singleQuantityMcf": 0.009324,
                                                                    "mcf": 0.009324,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.009324,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4219,
                                                                    "parentRowNumber": 4197,
                                                                    "articleId": "206210",
                                                                    "description": "206210-01-Stellring",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0186,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0186,
                                                                    "singleQuantityMcf": 0.096348,
                                                                    "mcf": 0.096348,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.096348,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4220,
                                                                    "parentRowNumber": 4197,
                                                                    "articleId": "20720404",
                                                                    "description": "20720404-00-Gewindestift",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0003,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0003,
                                                                    "singleQuantityMcf": 0.0015539999999999998,
                                                                    "mcf": 0.0015539999999999998,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.0015539999999999998,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4221,
                                                                    "parentRowNumber": 4197,
                                                                    "articleId": "20720512",
                                                                    "description": "20720512-00-Gewindestift",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0017,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0017,
                                                                    "singleQuantityMcf": 0.008806,
                                                                    "mcf": 0.008806,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.008806,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4222,
                                                                    "parentRowNumber": 4197,
                                                                    "articleId": "20720512",
                                                                    "description": "20720512-00-Gewindestift",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0017,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0017,
                                                                    "singleQuantityMcf": 0.008806,
                                                                    "mcf": 0.008806,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.008806,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4223,
                                                                    "parentRowNumber": 4197,
                                                                    "articleId": "208204",
                                                                    "description": "208204-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0003,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0003,
                                                                    "singleQuantityMcf": 0.0015539999999999998,
                                                                    "mcf": 0.0015539999999999998,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.0015539999999999998,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4224,
                                                                    "parentRowNumber": 4197,
                                                                    "articleId": "208204",
                                                                    "description": "208204-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0003,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0003,
                                                                    "singleQuantityMcf": 0.0015539999999999998,
                                                                    "mcf": 0.0015539999999999998,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.0015539999999999998,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4225,
                                                                    "parentRowNumber": 4197,
                                                                    "articleId": "208204",
                                                                    "description": "208204-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0003,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0003,
                                                                    "singleQuantityMcf": 0.0015539999999999998,
                                                                    "mcf": 0.0015539999999999998,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.0015539999999999998,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4226,
                                                                    "parentRowNumber": 4197,
                                                                    "articleId": "208204",
                                                                    "description": "208204-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0003,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0003,
                                                                    "singleQuantityMcf": 0.0015539999999999998,
                                                                    "mcf": 0.0015539999999999998,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.0015539999999999998,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4227,
                                                                    "parentRowNumber": 4197,
                                                                    "articleId": "208204",
                                                                    "description": "208204-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0003,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0003,
                                                                    "singleQuantityMcf": 0.0015539999999999998,
                                                                    "mcf": 0.0015539999999999998,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.0015539999999999998,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4228,
                                                                    "parentRowNumber": 4197,
                                                                    "articleId": "208204",
                                                                    "description": "208204-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0003,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0003,
                                                                    "singleQuantityMcf": 0.0015539999999999998,
                                                                    "mcf": 0.0015539999999999998,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.0015539999999999998,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4229,
                                                                    "parentRowNumber": 4197,
                                                                    "articleId": "208204",
                                                                    "description": "208204-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0003,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0003,
                                                                    "singleQuantityMcf": 0.0015539999999999998,
                                                                    "mcf": 0.0015539999999999998,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.0015539999999999998,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4230,
                                                                    "parentRowNumber": 4197,
                                                                    "articleId": "208204",
                                                                    "description": "208204-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0003,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0003,
                                                                    "singleQuantityMcf": 0.0015539999999999998,
                                                                    "mcf": 0.0015539999999999998,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.0015539999999999998,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4231,
                                                                    "parentRowNumber": 4197,
                                                                    "articleId": "208206",
                                                                    "description": "208206-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0009,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0009,
                                                                    "singleQuantityMcf": 0.004662,
                                                                    "mcf": 0.004662,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.004662,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4232,
                                                                    "parentRowNumber": 4197,
                                                                    "articleId": "208206",
                                                                    "description": "208206-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0009,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0009,
                                                                    "singleQuantityMcf": 0.004662,
                                                                    "mcf": 0.004662,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.004662,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4233,
                                                                    "parentRowNumber": 4197,
                                                                    "articleId": "208206",
                                                                    "description": "208206-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0009,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0009,
                                                                    "singleQuantityMcf": 0.004662,
                                                                    "mcf": 0.004662,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.004662,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4234,
                                                                    "parentRowNumber": 4197,
                                                                    "articleId": "208206",
                                                                    "description": "208206-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0009,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0009,
                                                                    "singleQuantityMcf": 0.004662,
                                                                    "mcf": 0.004662,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.004662,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4235,
                                                                    "parentRowNumber": 4197,
                                                                    "articleId": "208206",
                                                                    "description": "208206-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0009,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0009,
                                                                    "singleQuantityMcf": 0.004662,
                                                                    "mcf": 0.004662,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.004662,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4236,
                                                                    "parentRowNumber": 4197,
                                                                    "articleId": "208206",
                                                                    "description": "208206-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0009,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0009,
                                                                    "singleQuantityMcf": 0.004662,
                                                                    "mcf": 0.004662,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.004662,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4237,
                                                                    "parentRowNumber": 4197,
                                                                    "articleId": "208208",
                                                                    "description": "208208-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0017,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0017,
                                                                    "singleQuantityMcf": 0.008806,
                                                                    "mcf": 0.008806,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.008806,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4238,
                                                                    "parentRowNumber": 4197,
                                                                    "articleId": "208208",
                                                                    "description": "208208-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0017,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0017,
                                                                    "singleQuantityMcf": 0.008806,
                                                                    "mcf": 0.008806,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.008806,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4239,
                                                                    "parentRowNumber": 4197,
                                                                    "articleId": "208208",
                                                                    "description": "208208-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0017,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0017,
                                                                    "singleQuantityMcf": 0.008806,
                                                                    "mcf": 0.008806,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.008806,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4240,
                                                                    "parentRowNumber": 4197,
                                                                    "articleId": "208208",
                                                                    "description": "208208-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0017,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0017,
                                                                    "singleQuantityMcf": 0.008806,
                                                                    "mcf": 0.008806,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.008806,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4241,
                                                                    "parentRowNumber": 4197,
                                                                    "articleId": "21220520",
                                                                    "description": "21220520-00-Zylinderstift",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0032,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0032,
                                                                    "singleQuantityMcf": 0.016576,
                                                                    "mcf": 0.016576,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.016576,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4242,
                                                                    "parentRowNumber": 4197,
                                                                    "articleId": "22423584",
                                                                    "description": "22423584-00-Passscheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0025,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0025,
                                                                    "singleQuantityMcf": 0.01295,
                                                                    "mcf": 0.01295,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.01295,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4243,
                                                                    "parentRowNumber": 4197,
                                                                    "articleId": "2302100840",
                                                                    "description": "2302100840-00-Passfeder",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0242,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0242,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4244,
                                                                    "parentRowNumber": 4197,
                                                                    "articleId": "453001",
                                                                    "description": "453001-00-Aufnahmering",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.1164,
                                                                    "childrenWeight": 0.1164,
                                                                    "totalQuantityWeight": 0.1164,
                                                                    "singleQuantityMcf": 0.6029519999999999,
                                                                    "mcf": 0.6029519999999999,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.6029519999999999,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 4245,
                                                                            "parentRowNumber": 4244,
                                                                            "articleId": "1260040",
                                                                            "description": "1260040-00-Rundstahl",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.1164,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.1164,
                                                                            "singleQuantityMcf": 0.6029519999999999,
                                                                            "mcf": 0.6029519999999999,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.6029519999999999,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 4246,
                                                                    "parentRowNumber": 4197,
                                                                    "articleId": "453005",
                                                                    "description": "453005-00-Druckfeder",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 4247,
                                                                            "parentRowNumber": 4246,
                                                                            "articleId": "334265",
                                                                            "description": "334265-00-Druckfeder",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 4248,
                                                                    "parentRowNumber": 4197,
                                                                    "articleId": "453063",
                                                                    "description": "453063-01-Passfeder",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0129,
                                                                    "childrenWeight": 0.0129,
                                                                    "totalQuantityWeight": 0.0129,
                                                                    "singleQuantityMcf": 0.06682199999999999,
                                                                    "mcf": 0.06682199999999999,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.06682199999999999,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 4249,
                                                                            "parentRowNumber": 4248,
                                                                            "articleId": "1110149",
                                                                            "description": "1110149-00-Flachstahl",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0129,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0129,
                                                                            "singleQuantityMcf": 0.06682199999999999,
                                                                            "mcf": 0.06682199999999999,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.06682199999999999,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 4250,
                                                                    "parentRowNumber": 4197,
                                                                    "articleId": "46089018",
                                                                    "description": "46089018-03-Hohlwelle",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.2679,
                                                                    "childrenWeight": 0.2679,
                                                                    "totalQuantityWeight": 0.2679,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 4251,
                                                                            "parentRowNumber": 4250,
                                                                            "articleId": "1211050",
                                                                            "description": "1211050-00-Rundstange",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.2679,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.2679,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 4252,
                                                                    "parentRowNumber": 4197,
                                                                    "articleId": "46089023",
                                                                    "description": "46089023-00-Halter",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.2151,
                                                                    "childrenWeight": 0.2151,
                                                                    "totalQuantityWeight": 0.2151,
                                                                    "singleQuantityMcf": 0.30095569,
                                                                    "mcf": 0.30095569,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.30095569,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 4253,
                                                                            "parentRowNumber": 4252,
                                                                            "articleId": "119339",
                                                                            "description": "119339-00-Vierkantstange",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.1645,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.1645,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        },
                                                                        {
                                                                            "rowNumber": 4254,
                                                                            "parentRowNumber": 4252,
                                                                            "articleId": "20120650",
                                                                            "description": "20120650-00-Sechskantschraube",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0139,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0139,
                                                                            "singleQuantityMcf": 0.072002,
                                                                            "mcf": 0.072002,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.072002,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        },
                                                                        {
                                                                            "rowNumber": 4255,
                                                                            "parentRowNumber": 4252,
                                                                            "articleId": "20120650",
                                                                            "description": "20120650-00-Sechskantschraube",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0139,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0139,
                                                                            "singleQuantityMcf": 0.072002,
                                                                            "mcf": 0.072002,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.072002,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        },
                                                                        {
                                                                            "rowNumber": 4256,
                                                                            "parentRowNumber": 4252,
                                                                            "articleId": "208206",
                                                                            "description": "208206-00-Scheibe",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0009,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0009,
                                                                            "singleQuantityMcf": 0.004662,
                                                                            "mcf": 0.004662,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.004662,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        },
                                                                        {
                                                                            "rowNumber": 4257,
                                                                            "parentRowNumber": 4252,
                                                                            "articleId": "208206",
                                                                            "description": "208206-00-Scheibe",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0009,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0009,
                                                                            "singleQuantityMcf": 0.004662,
                                                                            "mcf": 0.004662,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.004662,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        },
                                                                        {
                                                                            "rowNumber": 4258,
                                                                            "parentRowNumber": 4252,
                                                                            "articleId": "271006",
                                                                            "description": "271006-00-Lagerbuchse",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0105,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0105,
                                                                            "singleQuantityMcf": 0.073813845,
                                                                            "mcf": 0.073813845,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.073813845,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        },
                                                                        {
                                                                            "rowNumber": 4259,
                                                                            "parentRowNumber": 4252,
                                                                            "articleId": "271006",
                                                                            "description": "271006-00-Lagerbuchse",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0105,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0105,
                                                                            "singleQuantityMcf": 0.073813845,
                                                                            "mcf": 0.073813845,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.073813845,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 4260,
                                                                    "parentRowNumber": 4197,
                                                                    "articleId": "46385725",
                                                                    "description": "46385725-00-Welle",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.2692,
                                                                    "childrenWeight": 0.2692,
                                                                    "totalQuantityWeight": 0.2692,
                                                                    "singleQuantityMcf": 1.394456,
                                                                    "mcf": 1.394456,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 1.394456,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 4261,
                                                                            "parentRowNumber": 4260,
                                                                            "articleId": "1224020",
                                                                            "description": "1224020-00-Rundstahl",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.2692,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.2692,
                                                                            "singleQuantityMcf": 1.394456,
                                                                            "mcf": 1.394456,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 1.394456,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 4262,
                                                                    "parentRowNumber": 4197,
                                                                    "articleId": "46647728",
                                                                    "description": "46647728-00-Hebel",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.5987,
                                                                    "childrenWeight": 0.5987,
                                                                    "totalQuantityWeight": 0.5987,
                                                                    "singleQuantityMcf": 3.101266,
                                                                    "mcf": 3.101266,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 3.101266,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 4263,
                                                                            "parentRowNumber": 4262,
                                                                            "articleId": "1110278",
                                                                            "description": "1110278-00-Vierkantstahl",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.5919,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.5919,
                                                                            "singleQuantityMcf": 3.066042,
                                                                            "mcf": 3.066042,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 3.066042,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        },
                                                                        {
                                                                            "rowNumber": 4264,
                                                                            "parentRowNumber": 4262,
                                                                            "articleId": "20120412",
                                                                            "description": "20120412-00-Sechskantschraube",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0021,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0021,
                                                                            "singleQuantityMcf": 0.010878,
                                                                            "mcf": 0.010878,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.010878,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        },
                                                                        {
                                                                            "rowNumber": 4265,
                                                                            "parentRowNumber": 4262,
                                                                            "articleId": "208204",
                                                                            "description": "208204-00-Scheibe",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0003,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0003,
                                                                            "singleQuantityMcf": 0.0015539999999999998,
                                                                            "mcf": 0.0015539999999999998,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.0015539999999999998,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        },
                                                                        {
                                                                            "rowNumber": 4266,
                                                                            "parentRowNumber": 4262,
                                                                            "articleId": "208204",
                                                                            "description": "208204-00-Scheibe",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0003,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0003,
                                                                            "singleQuantityMcf": 0.0015539999999999998,
                                                                            "mcf": 0.0015539999999999998,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.0015539999999999998,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        },
                                                                        {
                                                                            "rowNumber": 4267,
                                                                            "parentRowNumber": 4262,
                                                                            "articleId": "2921624",
                                                                            "description": "2921624-00-Radial-Rillenkugellager",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0041,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0041,
                                                                            "singleQuantityMcf": 0.021238,
                                                                            "mcf": 0.021238,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.021238,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 4268,
                                                                    "parentRowNumber": 4197,
                                                                    "articleId": "46743692",
                                                                    "description": "46743692-00-Griff",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.4065,
                                                                    "childrenWeight": 0.4065,
                                                                    "totalQuantityWeight": 0.4065,
                                                                    "singleQuantityMcf": 2.10567,
                                                                    "mcf": 2.10567,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 2.10567,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 4269,
                                                                            "parentRowNumber": 4268,
                                                                            "articleId": "1260025",
                                                                            "description": "1260025-00-Rundstahl",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.4065,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.4065,
                                                                            "singleQuantityMcf": 2.10567,
                                                                            "mcf": 2.10567,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 2.10567,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 4270,
                                                                    "parentRowNumber": 4197,
                                                                    "articleId": "46797175",
                                                                    "description": "46797175-01-Abdeckhaube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 1.8401,
                                                                    "childrenWeight": 1.8401,
                                                                    "totalQuantityWeight": 1.8401,
                                                                    "singleQuantityMcf": 9.531718,
                                                                    "mcf": 9.531718,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 9.531718,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 4271,
                                                                            "parentRowNumber": 4270,
                                                                            "articleId": "142603",
                                                                            "description": "142603-00-Edelstahlblech",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 1.8401,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 1.8401,
                                                                            "singleQuantityMcf": 9.531718,
                                                                            "mcf": 9.531718,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 9.531718,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 4272,
                                                                    "parentRowNumber": 4197,
                                                                    "articleId": "47085227",
                                                                    "description": "47085227-01-Platte",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 7.3207,
                                                                    "childrenWeight": 7.3207,
                                                                    "totalQuantityWeight": 7.3207,
                                                                    "singleQuantityMcf": 1.2440648,
                                                                    "mcf": 1.2440648,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 1.2440648,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 4273,
                                                                            "parentRowNumber": 4272,
                                                                            "articleId": "141737",
                                                                            "description": "141737-00-Platte",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 6.7965,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 6.7965,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        },
                                                                        {
                                                                            "rowNumber": 4274,
                                                                            "parentRowNumber": 4272,
                                                                            "articleId": "200208120",
                                                                            "description": "200208120-00-Sechskantschraube",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0542,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0542,
                                                                            "singleQuantityMcf": 0.28075599999999995,
                                                                            "mcf": 0.28075599999999995,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.28075599999999995,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        },
                                                                        {
                                                                            "rowNumber": 4275,
                                                                            "parentRowNumber": 4272,
                                                                            "articleId": "200208120",
                                                                            "description": "200208120-00-Sechskantschraube",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0542,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0542,
                                                                            "singleQuantityMcf": 0.28075599999999995,
                                                                            "mcf": 0.28075599999999995,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.28075599999999995,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        },
                                                                        {
                                                                            "rowNumber": 4276,
                                                                            "parentRowNumber": 4272,
                                                                            "articleId": "208208",
                                                                            "description": "208208-00-Scheibe",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0017,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0017,
                                                                            "singleQuantityMcf": 0.008806,
                                                                            "mcf": 0.008806,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.008806,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        },
                                                                        {
                                                                            "rowNumber": 4277,
                                                                            "parentRowNumber": 4272,
                                                                            "articleId": "208208",
                                                                            "description": "208208-00-Scheibe",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0017,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0017,
                                                                            "singleQuantityMcf": 0.008806,
                                                                            "mcf": 0.008806,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.008806,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        },
                                                                        {
                                                                            "rowNumber": 4278,
                                                                            "parentRowNumber": 4272,
                                                                            "articleId": "21240832",
                                                                            "description": "21240832-00-Zylinderstift",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.012,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.012,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        },
                                                                        {
                                                                            "rowNumber": 4279,
                                                                            "parentRowNumber": 4272,
                                                                            "articleId": "21240832",
                                                                            "description": "21240832-00-Zylinderstift",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.012,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.012,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        },
                                                                        {
                                                                            "rowNumber": 4280,
                                                                            "parentRowNumber": 4272,
                                                                            "articleId": "216262",
                                                                            "description": "216262-00-Sicherungsring",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0119,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0119,
                                                                            "singleQuantityMcf": 0.0203728,
                                                                            "mcf": 0.0203728,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.0203728,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        },
                                                                        {
                                                                            "rowNumber": 4281,
                                                                            "parentRowNumber": 4272,
                                                                            "articleId": "216268",
                                                                            "description": "216268-00-Sicherungsring",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0181,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0181,
                                                                            "singleQuantityMcf": 0.030987200000000003,
                                                                            "mcf": 0.030987200000000003,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.030987200000000003,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        },
                                                                        {
                                                                            "rowNumber": 4282,
                                                                            "parentRowNumber": 4272,
                                                                            "articleId": "2826007",
                                                                            "description": "2826007-00-Radial-Rillenkugellager",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.1928,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.1928,
                                                                            "singleQuantityMcf": 0.3300736,
                                                                            "mcf": 0.3300736,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.3300736,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        },
                                                                        {
                                                                            "rowNumber": 4283,
                                                                            "parentRowNumber": 4272,
                                                                            "articleId": "28461909",
                                                                            "description": "28461909-00-Radial-Rillenkugellager",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.1656,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.1656,
                                                                            "singleQuantityMcf": 0.2835072,
                                                                            "mcf": 0.2835072,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.2835072,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 4284,
                                                                    "parentRowNumber": 4197,
                                                                    "articleId": "47085929",
                                                                    "description": "47085929-01-Abdeckhaube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 1.9051,
                                                                    "childrenWeight": 1.9051,
                                                                    "totalQuantityWeight": 1.9051,
                                                                    "singleQuantityMcf": 9.868418,
                                                                    "mcf": 9.868418,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 9.868418,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 4285,
                                                                            "parentRowNumber": 4284,
                                                                            "articleId": "142603",
                                                                            "description": "142603-00-Edelstahlblech",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 1.9051,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 1.9051,
                                                                            "singleQuantityMcf": 9.868418,
                                                                            "mcf": 9.868418,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 9.868418,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 4286,
                                                                    "parentRowNumber": 4197,
                                                                    "articleId": "47086682",
                                                                    "description": "47086682-00-Aufnahme",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 1.1843,
                                                                    "childrenWeight": 1.1843,
                                                                    "totalQuantityWeight": 1.1843,
                                                                    "singleQuantityMcf": 3.73856,
                                                                    "mcf": 3.73856,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 3.73856,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 4287,
                                                                            "parentRowNumber": 4286,
                                                                            "articleId": "162549",
                                                                            "description": "162549-01-Platte",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 1.1683,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 1.1683,
                                                                            "singleQuantityMcf": 3.73856,
                                                                            "mcf": 3.73856,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 3.73856,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        },
                                                                        {
                                                                            "rowNumber": 4288,
                                                                            "parentRowNumber": 4286,
                                                                            "articleId": "347210",
                                                                            "description": "347210-00-Ensat-Gewindebuchse",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.008,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.008,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        },
                                                                        {
                                                                            "rowNumber": 4289,
                                                                            "parentRowNumber": 4286,
                                                                            "articleId": "347210",
                                                                            "description": "347210-00-Ensat-Gewindebuchse",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.008,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.008,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 4290,
                                                                    "parentRowNumber": 4197,
                                                                    "articleId": "47086899",
                                                                    "description": "47086899-00-Aufnahme",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.6029,
                                                                    "childrenWeight": 0.6029,
                                                                    "totalQuantityWeight": 0.6029,
                                                                    "singleQuantityMcf": 1.87808,
                                                                    "mcf": 1.87808,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 1.87808,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 4291,
                                                                            "parentRowNumber": 4290,
                                                                            "articleId": "162549",
                                                                            "description": "162549-01-Platte",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.5869,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.5869,
                                                                            "singleQuantityMcf": 1.87808,
                                                                            "mcf": 1.87808,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 1.87808,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        },
                                                                        {
                                                                            "rowNumber": 4292,
                                                                            "parentRowNumber": 4290,
                                                                            "articleId": "347210",
                                                                            "description": "347210-00-Ensat-Gewindebuchse",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.008,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.008,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        },
                                                                        {
                                                                            "rowNumber": 4293,
                                                                            "parentRowNumber": 4290,
                                                                            "articleId": "347210",
                                                                            "description": "347210-00-Ensat-Gewindebuchse",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.008,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.008,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 4294,
                                                                    "parentRowNumber": 4197,
                                                                    "articleId": "47099514",
                                                                    "description": "47099514-02-Rahmen",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 1.7282,
                                                                    "childrenWeight": 1.7282,
                                                                    "totalQuantityWeight": 1.7282,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 4295,
                                                                            "parentRowNumber": 4294,
                                                                            "articleId": "141718",
                                                                            "description": "141718-00-Platte",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 1.7282,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 1.7282,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 4296,
                                                                    "parentRowNumber": 4197,
                                                                    "articleId": "57550231",
                                                                    "description": "57550231-02-Verschraubungskörper",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0943,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0943,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4297,
                                                                    "parentRowNumber": 4197,
                                                                    "articleId": "AFV6015",
                                                                    "description": "AFV6015-B-020,00-00-Fuehrungsflansch",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0689,
                                                                    "childrenWeight": 0.0689,
                                                                    "totalQuantityWeight": 0.0689,
                                                                    "singleQuantityMcf": 0.029526,
                                                                    "mcf": 0.029526,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.029526,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 4298,
                                                                            "parentRowNumber": 4297,
                                                                            "articleId": "1226040",
                                                                            "description": "1226040-00-Rundstange",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0632,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0632,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        },
                                                                        {
                                                                            "rowNumber": 4299,
                                                                            "parentRowNumber": 4297,
                                                                            "articleId": "20220412",
                                                                            "description": "20220412-00-Zylinderschraube",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0019,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0019,
                                                                            "singleQuantityMcf": 0.009842,
                                                                            "mcf": 0.009842,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.009842,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        },
                                                                        {
                                                                            "rowNumber": 4300,
                                                                            "parentRowNumber": 4297,
                                                                            "articleId": "20220412",
                                                                            "description": "20220412-00-Zylinderschraube",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0019,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0019,
                                                                            "singleQuantityMcf": 0.009842,
                                                                            "mcf": 0.009842,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.009842,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        },
                                                                        {
                                                                            "rowNumber": 4301,
                                                                            "parentRowNumber": 4297,
                                                                            "articleId": "20220412",
                                                                            "description": "20220412-00-Zylinderschraube",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0019,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0019,
                                                                            "singleQuantityMcf": 0.009842,
                                                                            "mcf": 0.009842,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.009842,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 4302,
                                                                    "parentRowNumber": 4197,
                                                                    "articleId": "FFV8024",
                                                                    "description": "FFV8024-FM034,00-00-Welle",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0483,
                                                                    "childrenWeight": 0.0483,
                                                                    "totalQuantityWeight": 0.0483,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 4303,
                                                                            "parentRowNumber": 4302,
                                                                            "articleId": "1219010",
                                                                            "description": "1219010-00-Rundstahl",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0483,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0483,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            "rowNumber": 4304,
                                                            "parentRowNumber": 4196,
                                                            "articleId": "47100839",
                                                            "description": "47100839-01-Antrieb",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 1.1063999999999998,
                                                            "childrenWeight": 1.1063999999999998,
                                                            "totalQuantityWeight": 1.1063999999999998,
                                                            "singleQuantityMcf": 11.111508000000002,
                                                            "mcf": 11.111508000000002,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 11.111508000000002,
                                                            "expandable": false,
                                                            "children": [
                                                                {
                                                                    "rowNumber": 4305,
                                                                    "parentRowNumber": 4304,
                                                                    "articleId": "20120410",
                                                                    "description": "20120410-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0019,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0019,
                                                                    "singleQuantityMcf": 0.009842,
                                                                    "mcf": 0.009842,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.009842,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4306,
                                                                    "parentRowNumber": 4304,
                                                                    "articleId": "20120410",
                                                                    "description": "20120410-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0019,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0019,
                                                                    "singleQuantityMcf": 0.009842,
                                                                    "mcf": 0.009842,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.009842,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4307,
                                                                    "parentRowNumber": 4304,
                                                                    "articleId": "20120840",
                                                                    "description": "20120840-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0221,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0221,
                                                                    "singleQuantityMcf": 0.114478,
                                                                    "mcf": 0.114478,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.114478,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4308,
                                                                    "parentRowNumber": 4304,
                                                                    "articleId": "20720406",
                                                                    "description": "20720406-00-Gewindestift",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0005,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0005,
                                                                    "singleQuantityMcf": 0.00259,
                                                                    "mcf": 0.00259,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.00259,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4309,
                                                                    "parentRowNumber": 4304,
                                                                    "articleId": "208204",
                                                                    "description": "208204-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0003,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0003,
                                                                    "singleQuantityMcf": 0.0015539999999999998,
                                                                    "mcf": 0.0015539999999999998,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.0015539999999999998,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4310,
                                                                    "parentRowNumber": 4304,
                                                                    "articleId": "208204",
                                                                    "description": "208204-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0003,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0003,
                                                                    "singleQuantityMcf": 0.0015539999999999998,
                                                                    "mcf": 0.0015539999999999998,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.0015539999999999998,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4311,
                                                                    "parentRowNumber": 4304,
                                                                    "articleId": "208408",
                                                                    "description": "208408-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.007,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.007,
                                                                    "singleQuantityMcf": 0.03626,
                                                                    "mcf": 0.03626,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.03626,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4312,
                                                                    "parentRowNumber": 4304,
                                                                    "articleId": "367033",
                                                                    "description": "367033-00-Zahnriemen einfach",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4313,
                                                                    "parentRowNumber": 4304,
                                                                    "articleId": "46089021",
                                                                    "description": "46089021-00-Rolle",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0703,
                                                                    "childrenWeight": 0.0703,
                                                                    "totalQuantityWeight": 0.0703,
                                                                    "singleQuantityMcf": 0.07635520000000001,
                                                                    "mcf": 0.07635520000000001,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.07635520000000001,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 4314,
                                                                            "parentRowNumber": 4313,
                                                                            "articleId": "1232036",
                                                                            "description": "1232036-00-Rundstange",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0257,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0257,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        },
                                                                        {
                                                                            "rowNumber": 4315,
                                                                            "parentRowNumber": 4313,
                                                                            "articleId": "216228",
                                                                            "description": "216228-00-Sicherungsring",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0018,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0018,
                                                                            "singleQuantityMcf": 0.0030816,
                                                                            "mcf": 0.0030816,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.0030816,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        },
                                                                        {
                                                                            "rowNumber": 4316,
                                                                            "parentRowNumber": 4313,
                                                                            "articleId": "216228",
                                                                            "description": "216228-00-Sicherungsring",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0018,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0018,
                                                                            "singleQuantityMcf": 0.0030816,
                                                                            "mcf": 0.0030816,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.0030816,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        },
                                                                        {
                                                                            "rowNumber": 4317,
                                                                            "parentRowNumber": 4313,
                                                                            "articleId": "28261902",
                                                                            "description": "28261902-00-Radial-Rillenkugellager",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0205,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0205,
                                                                            "singleQuantityMcf": 0.035096,
                                                                            "mcf": 0.035096,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.035096,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        },
                                                                        {
                                                                            "rowNumber": 4318,
                                                                            "parentRowNumber": 4313,
                                                                            "articleId": "28261902",
                                                                            "description": "28261902-00-Radial-Rillenkugellager",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0205,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0205,
                                                                            "singleQuantityMcf": 0.035096,
                                                                            "mcf": 0.035096,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.035096,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 4319,
                                                                    "parentRowNumber": 4304,
                                                                    "articleId": "46089022",
                                                                    "description": "46089022-00-Bundbolzen",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.08809999999999998,
                                                                    "childrenWeight": 0.08809999999999998,
                                                                    "totalQuantityWeight": 0.08809999999999998,
                                                                    "singleQuantityMcf": 0.4539303999999999,
                                                                    "mcf": 0.4539303999999999,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.4539303999999999,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 4320,
                                                                            "parentRowNumber": 4319,
                                                                            "articleId": "132722",
                                                                            "description": "132722-00-Sechskantstahl",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.06359999999999999,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.06359999999999999,
                                                                            "singleQuantityMcf": 0.3294479999999999,
                                                                            "mcf": 0.3294479999999999,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.3294479999999999,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        },
                                                                        {
                                                                            "rowNumber": 4321,
                                                                            "parentRowNumber": 4319,
                                                                            "articleId": "20120840",
                                                                            "description": "20120840-00-Sechskantschraube",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0221,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0221,
                                                                            "singleQuantityMcf": 0.114478,
                                                                            "mcf": 0.114478,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.114478,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        },
                                                                        {
                                                                            "rowNumber": 4322,
                                                                            "parentRowNumber": 4319,
                                                                            "articleId": "208208",
                                                                            "description": "208208-00-Scheibe",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0017,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0017,
                                                                            "singleQuantityMcf": 0.008806,
                                                                            "mcf": 0.008806,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.008806,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        },
                                                                        {
                                                                            "rowNumber": 4323,
                                                                            "parentRowNumber": 4319,
                                                                            "articleId": "215215",
                                                                            "description": "215215-00-Sicherungsring",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0007,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0007,
                                                                            "singleQuantityMcf": 0.0011984,
                                                                            "mcf": 0.0011984,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.0011984,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 4324,
                                                                    "parentRowNumber": 4304,
                                                                    "articleId": "46089024",
                                                                    "description": "46089024-00-Winkel",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0172,
                                                                    "childrenWeight": 0.0172,
                                                                    "totalQuantityWeight": 0.0172,
                                                                    "singleQuantityMcf": 0.089096,
                                                                    "mcf": 0.089096,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.089096,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 4325,
                                                                            "parentRowNumber": 4324,
                                                                            "articleId": "142603",
                                                                            "description": "142603-00-Edelstahlblech",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0132,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0132,
                                                                            "singleQuantityMcf": 0.06837599999999999,
                                                                            "mcf": 0.06837599999999999,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.06837599999999999,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        },
                                                                        {
                                                                            "rowNumber": 4326,
                                                                            "parentRowNumber": 4324,
                                                                            "articleId": "20120408",
                                                                            "description": "20120408-00-Sechskantschraube",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0017,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0017,
                                                                            "singleQuantityMcf": 0.008806,
                                                                            "mcf": 0.008806,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.008806,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        },
                                                                        {
                                                                            "rowNumber": 4327,
                                                                            "parentRowNumber": 4324,
                                                                            "articleId": "20120408",
                                                                            "description": "20120408-00-Sechskantschraube",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0017,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0017,
                                                                            "singleQuantityMcf": 0.008806,
                                                                            "mcf": 0.008806,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.008806,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        },
                                                                        {
                                                                            "rowNumber": 4328,
                                                                            "parentRowNumber": 4324,
                                                                            "articleId": "208204",
                                                                            "description": "208204-00-Scheibe",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0003,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0003,
                                                                            "singleQuantityMcf": 0.0015539999999999998,
                                                                            "mcf": 0.0015539999999999998,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.0015539999999999998,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        },
                                                                        {
                                                                            "rowNumber": 4329,
                                                                            "parentRowNumber": 4324,
                                                                            "articleId": "208204",
                                                                            "description": "208204-00-Scheibe",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0003,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0003,
                                                                            "singleQuantityMcf": 0.0015539999999999998,
                                                                            "mcf": 0.0015539999999999998,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.0015539999999999998,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 4330,
                                                                    "parentRowNumber": 4304,
                                                                    "articleId": "46384492",
                                                                    "description": "46384492-00-Zahnriemenrad",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.8666,
                                                                    "childrenWeight": 0.8666,
                                                                    "totalQuantityWeight": 0.8666,
                                                                    "singleQuantityMcf": 10.3160064,
                                                                    "mcf": 10.3160064,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 10.3160064,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 4331,
                                                                            "parentRowNumber": 4330,
                                                                            "articleId": "3660093",
                                                                            "description": "3660093-00-Normzahnrad",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.8666,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.8666,
                                                                            "singleQuantityMcf": 10.3160064,
                                                                            "mcf": 10.3160064,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 10.3160064,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 4332,
                                                                    "parentRowNumber": 4304,
                                                                    "articleId": "58225021",
                                                                    "description": "58225021-02-Induktiver Sensor",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0196,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0196,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4333,
                                                                    "parentRowNumber": 4304,
                                                                    "articleId": "58236510",
                                                                    "description": "58236510-02-Kabeldose",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0106,
                                                                    "childrenWeight": 0.0106,
                                                                    "totalQuantityWeight": 0.0106,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 4334,
                                                                            "parentRowNumber": 4333,
                                                                            "articleId": "PUDY00000066",
                                                                            "description": "PUDY00000066-00-Kabel D5",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0106,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0106,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            "rowNumber": 4335,
                                                            "parentRowNumber": 4196,
                                                            "articleId": "47103707",
                                                            "description": "47103707-01-Antrieb",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.9338999999999996,
                                                            "childrenWeight": 0.9338999999999996,
                                                            "totalQuantityWeight": 0.9338999999999996,
                                                            "singleQuantityMcf": 4.8236104,
                                                            "mcf": 4.8236104,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 4.8236104,
                                                            "expandable": false,
                                                            "children": [
                                                                {
                                                                    "rowNumber": 4336,
                                                                    "parentRowNumber": 4335,
                                                                    "articleId": "20220615",
                                                                    "description": "20220615-00-Zylinderschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0054,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0054,
                                                                    "singleQuantityMcf": 0.027972,
                                                                    "mcf": 0.027972,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.027972,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4337,
                                                                    "parentRowNumber": 4335,
                                                                    "articleId": "20220615",
                                                                    "description": "20220615-00-Zylinderschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0054,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0054,
                                                                    "singleQuantityMcf": 0.027972,
                                                                    "mcf": 0.027972,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.027972,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4338,
                                                                    "parentRowNumber": 4335,
                                                                    "articleId": "20220615",
                                                                    "description": "20220615-00-Zylinderschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0054,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0054,
                                                                    "singleQuantityMcf": 0.027972,
                                                                    "mcf": 0.027972,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.027972,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4339,
                                                                    "parentRowNumber": 4335,
                                                                    "articleId": "20220615",
                                                                    "description": "20220615-00-Zylinderschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0054,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0054,
                                                                    "singleQuantityMcf": 0.027972,
                                                                    "mcf": 0.027972,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.027972,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4340,
                                                                    "parentRowNumber": 4335,
                                                                    "articleId": "20220615",
                                                                    "description": "20220615-00-Zylinderschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0054,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0054,
                                                                    "singleQuantityMcf": 0.027972,
                                                                    "mcf": 0.027972,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.027972,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4341,
                                                                    "parentRowNumber": 4335,
                                                                    "articleId": "20220615",
                                                                    "description": "20220615-00-Zylinderschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0054,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0054,
                                                                    "singleQuantityMcf": 0.027972,
                                                                    "mcf": 0.027972,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.027972,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4342,
                                                                    "parentRowNumber": 4335,
                                                                    "articleId": "20220615",
                                                                    "description": "20220615-00-Zylinderschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0054,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0054,
                                                                    "singleQuantityMcf": 0.027972,
                                                                    "mcf": 0.027972,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.027972,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4343,
                                                                    "parentRowNumber": 4335,
                                                                    "articleId": "20220615",
                                                                    "description": "20220615-00-Zylinderschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0054,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0054,
                                                                    "singleQuantityMcf": 0.027972,
                                                                    "mcf": 0.027972,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.027972,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4344,
                                                                    "parentRowNumber": 4335,
                                                                    "articleId": "208206",
                                                                    "description": "208206-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0009,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0009,
                                                                    "singleQuantityMcf": 0.004662,
                                                                    "mcf": 0.004662,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.004662,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4345,
                                                                    "parentRowNumber": 4335,
                                                                    "articleId": "208206",
                                                                    "description": "208206-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0009,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0009,
                                                                    "singleQuantityMcf": 0.004662,
                                                                    "mcf": 0.004662,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.004662,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4346,
                                                                    "parentRowNumber": 4335,
                                                                    "articleId": "208206",
                                                                    "description": "208206-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0009,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0009,
                                                                    "singleQuantityMcf": 0.004662,
                                                                    "mcf": 0.004662,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.004662,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4347,
                                                                    "parentRowNumber": 4335,
                                                                    "articleId": "208206",
                                                                    "description": "208206-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0009,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0009,
                                                                    "singleQuantityMcf": 0.004662,
                                                                    "mcf": 0.004662,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.004662,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4348,
                                                                    "parentRowNumber": 4335,
                                                                    "articleId": "47103396",
                                                                    "description": "47103396-00-Adapterplatte",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.2522,
                                                                    "childrenWeight": 0.2522,
                                                                    "totalQuantityWeight": 0.2522,
                                                                    "singleQuantityMcf": 1.306396,
                                                                    "mcf": 1.306396,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 1.306396,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 4349,
                                                                            "parentRowNumber": 4348,
                                                                            "articleId": "142612",
                                                                            "description": "142612-00-Edelstahlblech",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.2522,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.2522,
                                                                            "singleQuantityMcf": 1.306396,
                                                                            "mcf": 1.306396,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 1.306396,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 4350,
                                                                    "parentRowNumber": 4335,
                                                                    "articleId": "47153557",
                                                                    "description": "47153557-00-Riemenscheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.2751,
                                                                    "childrenWeight": 0.2751,
                                                                    "totalQuantityWeight": 0.2751,
                                                                    "singleQuantityMcf": 3.2747904,
                                                                    "mcf": 3.2747904,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 3.2747904,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 4351,
                                                                            "parentRowNumber": 4350,
                                                                            "articleId": "3663092",
                                                                            "description": "3663092-00-Normzahnrad",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.2751,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.2751,
                                                                            "singleQuantityMcf": 3.2747904,
                                                                            "mcf": 3.2747904,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 3.2747904,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 4352,
                                                                    "parentRowNumber": 4335,
                                                                    "articleId": "51158331",
                                                                    "description": "51158331-00-Servomotor",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.2677,
                                                                    "childrenWeight": 0.2677,
                                                                    "totalQuantityWeight": 0.2677,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 4353,
                                                                            "parentRowNumber": 4352,
                                                                            "articleId": "PUDY00005767",
                                                                            "description": "PUDY00005767-00-Stecker AM8041",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.2677,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.2677,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 4354,
                                                                    "parentRowNumber": 4335,
                                                                    "articleId": "56489934",
                                                                    "description": "56489934-00-Motorleitung 480V",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0921,
                                                                    "childrenWeight": 0.0921,
                                                                    "totalQuantityWeight": 0.0921,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 4355,
                                                                            "parentRowNumber": 4354,
                                                                            "articleId": "PUDY00000071",
                                                                            "description": "PUDY00000071-00-Kabel D12",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0921,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0921,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                }
                                                            ]
                                                        }
                                                    ]
                                                }
                                            ]
                                        },
                                        {
                                            "rowNumber": 4356,
                                            "parentRowNumber": 4194,
                                            "articleId": "33847",
                                            "description": "33847-20SLG1-03-Schnecke",
                                            "hierarchy": 4,
                                            "quantity": 1,
                                            "weight": 6.1709000000000005,
                                            "childrenWeight": 6.1709000000000005,
                                            "totalQuantityWeight": 6.1709000000000005,
                                            "singleQuantityMcf": 18.3289184,
                                            "mcf": 18.3289184,
                                            "singleQuantityLcf": 0,
                                            "lcf": 0,
                                            "totalQuantityMcf": 18.3289184,
                                            "expandable": false,
                                            "children": [
                                                {
                                                    "rowNumber": 4357,
                                                    "parentRowNumber": 4356,
                                                    "articleId": "MM0000004201",
                                                    "description": "MM0000004201-03-Schnecke",
                                                    "hierarchy": 5,
                                                    "quantity": 1,
                                                    "weight": 6.1709000000000005,
                                                    "childrenWeight": 6.1709000000000005,
                                                    "totalQuantityWeight": 6.1709000000000005,
                                                    "singleQuantityMcf": 18.3289184,
                                                    "mcf": 18.3289184,
                                                    "singleQuantityLcf": 0,
                                                    "lcf": 0,
                                                    "totalQuantityMcf": 18.3289184,
                                                    "expandable": false,
                                                    "children": [
                                                        {
                                                            "rowNumber": 4358,
                                                            "parentRowNumber": 4357,
                                                            "articleId": "20120515",
                                                            "description": "20120515-00-Sechskantschraube",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0039,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0039,
                                                            "singleQuantityMcf": 0.020202,
                                                            "mcf": 0.020202,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.020202,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 4359,
                                                            "parentRowNumber": 4357,
                                                            "articleId": "20120515",
                                                            "description": "20120515-00-Sechskantschraube",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0039,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0039,
                                                            "singleQuantityMcf": 0.020202,
                                                            "mcf": 0.020202,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.020202,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 4360,
                                                            "parentRowNumber": 4357,
                                                            "articleId": "20220615",
                                                            "description": "20220615-00-Zylinderschraube",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0054,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0054,
                                                            "singleQuantityMcf": 0.027972,
                                                            "mcf": 0.027972,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.027972,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 4361,
                                                            "parentRowNumber": 4357,
                                                            "articleId": "20220615",
                                                            "description": "20220615-00-Zylinderschraube",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0054,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0054,
                                                            "singleQuantityMcf": 0.027972,
                                                            "mcf": 0.027972,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.027972,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 4362,
                                                            "parentRowNumber": 4357,
                                                            "articleId": "20420512",
                                                            "description": "20420512-00-Senkschraube",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0022,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0022,
                                                            "singleQuantityMcf": 0.011396,
                                                            "mcf": 0.011396,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.011396,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 4363,
                                                            "parentRowNumber": 4357,
                                                            "articleId": "20420512",
                                                            "description": "20420512-00-Senkschraube",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0022,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0022,
                                                            "singleQuantityMcf": 0.011396,
                                                            "mcf": 0.011396,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.011396,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 4364,
                                                            "parentRowNumber": 4357,
                                                            "articleId": "208205",
                                                            "description": "208205-00-Scheibe",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0004,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0004,
                                                            "singleQuantityMcf": 0.002072,
                                                            "mcf": 0.002072,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.002072,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 4365,
                                                            "parentRowNumber": 4357,
                                                            "articleId": "208205",
                                                            "description": "208205-00-Scheibe",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0004,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0004,
                                                            "singleQuantityMcf": 0.002072,
                                                            "mcf": 0.002072,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.002072,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 4366,
                                                            "parentRowNumber": 4357,
                                                            "articleId": "46100934",
                                                            "description": "46100934-01-Einlaufkeil",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0644,
                                                            "childrenWeight": 0.0644,
                                                            "totalQuantityWeight": 0.0644,
                                                            "singleQuantityMcf": 0.215188,
                                                            "mcf": 0.215188,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.215188,
                                                            "expandable": false,
                                                            "children": [
                                                                {
                                                                    "rowNumber": 4367,
                                                                    "parentRowNumber": 4366,
                                                                    "articleId": "162541",
                                                                    "description": "162541-01-Platte",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0598,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0598,
                                                                    "singleQuantityMcf": 0.19136,
                                                                    "mcf": 0.19136,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.19136,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4368,
                                                                    "parentRowNumber": 4366,
                                                                    "articleId": "20220415",
                                                                    "description": "20220415-00-Zylinderschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0023,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0023,
                                                                    "singleQuantityMcf": 0.011914,
                                                                    "mcf": 0.011914,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.011914,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4369,
                                                                    "parentRowNumber": 4366,
                                                                    "articleId": "20220415",
                                                                    "description": "20220415-00-Zylinderschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0023,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0023,
                                                                    "singleQuantityMcf": 0.011914,
                                                                    "mcf": 0.011914,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.011914,
                                                                    "expandable": false,
                                                                    "children": []
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            "rowNumber": 4370,
                                                            "parentRowNumber": 4357,
                                                            "articleId": "46100937",
                                                            "description": "46100937-01-Welle",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0154,
                                                            "childrenWeight": 0.0154,
                                                            "totalQuantityWeight": 0.0154,
                                                            "singleQuantityMcf": 0.0794252,
                                                            "mcf": 0.0794252,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.0794252,
                                                            "expandable": false,
                                                            "children": [
                                                                {
                                                                    "rowNumber": 4371,
                                                                    "parentRowNumber": 4370,
                                                                    "articleId": "1260006",
                                                                    "description": "1260006-00-Rundstahl",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0153,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0153,
                                                                    "singleQuantityMcf": 0.079254,
                                                                    "mcf": 0.079254,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.079254,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4372,
                                                                    "parentRowNumber": 4370,
                                                                    "articleId": "215206",
                                                                    "description": "215206-00-Sicherungsring",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0001,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0001,
                                                                    "singleQuantityMcf": 0.0001712,
                                                                    "mcf": 0.0001712,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.0001712,
                                                                    "expandable": false,
                                                                    "children": []
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            "rowNumber": 4373,
                                                            "parentRowNumber": 4357,
                                                            "articleId": "46100937",
                                                            "description": "46100937-01-Welle",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0154,
                                                            "childrenWeight": 0.0154,
                                                            "totalQuantityWeight": 0.0154,
                                                            "singleQuantityMcf": 0.0794252,
                                                            "mcf": 0.0794252,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.0794252,
                                                            "expandable": false,
                                                            "children": [
                                                                {
                                                                    "rowNumber": 4374,
                                                                    "parentRowNumber": 4373,
                                                                    "articleId": "1260006",
                                                                    "description": "1260006-00-Rundstahl",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0153,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0153,
                                                                    "singleQuantityMcf": 0.079254,
                                                                    "mcf": 0.079254,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.079254,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4375,
                                                                    "parentRowNumber": 4373,
                                                                    "articleId": "215206",
                                                                    "description": "215206-00-Sicherungsring",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0001,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0001,
                                                                    "singleQuantityMcf": 0.0001712,
                                                                    "mcf": 0.0001712,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.0001712,
                                                                    "expandable": false,
                                                                    "children": []
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            "rowNumber": 4376,
                                                            "parentRowNumber": 4357,
                                                            "articleId": "47083049",
                                                            "description": "47083049-01-Einlaufformat",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.8738,
                                                            "childrenWeight": 0.8738,
                                                            "totalQuantityWeight": 0.8738,
                                                            "singleQuantityMcf": 2.758208,
                                                            "mcf": 2.758208,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 2.758208,
                                                            "expandable": false,
                                                            "children": [
                                                                {
                                                                    "rowNumber": 4377,
                                                                    "parentRowNumber": 4376,
                                                                    "articleId": "162544",
                                                                    "description": "162544-01-Platte",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.8598,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.8598,
                                                                    "singleQuantityMcf": 2.75136,
                                                                    "mcf": 2.75136,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 2.75136,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4378,
                                                                    "parentRowNumber": 4376,
                                                                    "articleId": "275104",
                                                                    "description": "275104-00-DU-Buchse",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.001,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.001,
                                                                    "singleQuantityMcf": 0.001712,
                                                                    "mcf": 0.001712,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.001712,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4379,
                                                                    "parentRowNumber": 4376,
                                                                    "articleId": "275104",
                                                                    "description": "275104-00-DU-Buchse",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.001,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.001,
                                                                    "singleQuantityMcf": 0.001712,
                                                                    "mcf": 0.001712,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.001712,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4380,
                                                                    "parentRowNumber": 4376,
                                                                    "articleId": "275104",
                                                                    "description": "275104-00-DU-Buchse",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.001,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.001,
                                                                    "singleQuantityMcf": 0.001712,
                                                                    "mcf": 0.001712,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.001712,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4381,
                                                                    "parentRowNumber": 4376,
                                                                    "articleId": "275104",
                                                                    "description": "275104-00-DU-Buchse",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.001,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.001,
                                                                    "singleQuantityMcf": 0.001712,
                                                                    "mcf": 0.001712,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.001712,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4382,
                                                                    "parentRowNumber": 4376,
                                                                    "articleId": "347200",
                                                                    "description": "347200-00-Ensat-Gewindebuchse",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0025,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0025,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4383,
                                                                    "parentRowNumber": 4376,
                                                                    "articleId": "347200",
                                                                    "description": "347200-00-Ensat-Gewindebuchse",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0025,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0025,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4384,
                                                                    "parentRowNumber": 4376,
                                                                    "articleId": "347200",
                                                                    "description": "347200-00-Ensat-Gewindebuchse",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0025,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0025,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4385,
                                                                    "parentRowNumber": 4376,
                                                                    "articleId": "347200",
                                                                    "description": "347200-00-Ensat-Gewindebuchse",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0025,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0025,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": []
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            "rowNumber": 4386,
                                                            "parentRowNumber": 4357,
                                                            "articleId": "47122726",
                                                            "description": "47122726-00-Fuehrung",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0284,
                                                            "childrenWeight": 0.0284,
                                                            "totalQuantityWeight": 0.0284,
                                                            "singleQuantityMcf": 0.09088000000000002,
                                                            "mcf": 0.09088000000000002,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.09088000000000002,
                                                            "expandable": false,
                                                            "children": [
                                                                {
                                                                    "rowNumber": 4387,
                                                                    "parentRowNumber": 4386,
                                                                    "articleId": "162532",
                                                                    "description": "162532-01-Platte",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0284,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0284,
                                                                    "singleQuantityMcf": 0.09088000000000002,
                                                                    "mcf": 0.09088000000000002,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.09088000000000002,
                                                                    "expandable": false,
                                                                    "children": []
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            "rowNumber": 4388,
                                                            "parentRowNumber": 4357,
                                                            "articleId": "47122750",
                                                            "description": "47122750-00-Fuehrung",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0242,
                                                            "childrenWeight": 0.0242,
                                                            "totalQuantityWeight": 0.0242,
                                                            "singleQuantityMcf": 0.07744000000000001,
                                                            "mcf": 0.07744000000000001,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.07744000000000001,
                                                            "expandable": false,
                                                            "children": [
                                                                {
                                                                    "rowNumber": 4389,
                                                                    "parentRowNumber": 4388,
                                                                    "articleId": "162532",
                                                                    "description": "162532-01-Platte",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0242,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0242,
                                                                    "singleQuantityMcf": 0.07744000000000001,
                                                                    "mcf": 0.07744000000000001,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.07744000000000001,
                                                                    "expandable": false,
                                                                    "children": []
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            "rowNumber": 4390,
                                                            "parentRowNumber": 4357,
                                                            "articleId": "47129780",
                                                            "description": "47129780-01-Schnecke",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 4.4488,
                                                            "childrenWeight": 4.4488,
                                                            "totalQuantityWeight": 4.4488,
                                                            "singleQuantityMcf": 14.2441,
                                                            "mcf": 14.2441,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 14.2441,
                                                            "expandable": false,
                                                            "children": [
                                                                {
                                                                    "rowNumber": 4391,
                                                                    "parentRowNumber": 4390,
                                                                    "articleId": "1617090",
                                                                    "description": "1617090-00-Rundstab",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 4.3234,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 4.3234,
                                                                    "singleQuantityMcf": 13.834880000000002,
                                                                    "mcf": 13.834880000000002,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 13.834880000000002,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4392,
                                                                    "parentRowNumber": 4390,
                                                                    "articleId": "29216000",
                                                                    "description": "29216000-00-Radial-Rillenkugellager",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.025,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.025,
                                                                    "singleQuantityMcf": 0.1295,
                                                                    "mcf": 0.1295,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.1295,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4393,
                                                                    "parentRowNumber": 4390,
                                                                    "articleId": "347205",
                                                                    "description": "347205-00-Ensat-Gewindebuchse",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0058,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0058,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4394,
                                                                    "parentRowNumber": 4390,
                                                                    "articleId": "347205",
                                                                    "description": "347205-00-Ensat-Gewindebuchse",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0058,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0058,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4395,
                                                                    "parentRowNumber": 4390,
                                                                    "articleId": "347205",
                                                                    "description": "347205-00-Ensat-Gewindebuchse",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0058,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0058,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4396,
                                                                    "parentRowNumber": 4390,
                                                                    "articleId": "347205",
                                                                    "description": "347205-00-Ensat-Gewindebuchse",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0058,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0058,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4397,
                                                                    "parentRowNumber": 4390,
                                                                    "articleId": "347205",
                                                                    "description": "347205-00-Ensat-Gewindebuchse",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0058,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0058,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4398,
                                                                    "parentRowNumber": 4390,
                                                                    "articleId": "347205",
                                                                    "description": "347205-00-Ensat-Gewindebuchse",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0058,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0058,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4399,
                                                                    "parentRowNumber": 4390,
                                                                    "articleId": "347205",
                                                                    "description": "347205-00-Ensat-Gewindebuchse",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0058,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0058,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4400,
                                                                    "parentRowNumber": 4390,
                                                                    "articleId": "347205",
                                                                    "description": "347205-00-Ensat-Gewindebuchse",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0058,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0058,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4401,
                                                                    "parentRowNumber": 4390,
                                                                    "articleId": "440117",
                                                                    "description": "440117-01-Lagerschale",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.054,
                                                                    "childrenWeight": 0.054,
                                                                    "totalQuantityWeight": 0.054,
                                                                    "singleQuantityMcf": 0.27972,
                                                                    "mcf": 0.27972,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.27972,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 4402,
                                                                            "parentRowNumber": 4401,
                                                                            "articleId": "1260035",
                                                                            "description": "1260035-00-Rundstahl",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.054,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.054,
                                                                            "singleQuantityMcf": 0.27972,
                                                                            "mcf": 0.27972,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.27972,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            "rowNumber": 4403,
                                                            "parentRowNumber": 4357,
                                                            "articleId": "ESA1025",
                                                            "description": "ESA1025-B-807,20-01-Kulisse",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.1276,
                                                            "childrenWeight": 0.1276,
                                                            "totalQuantityWeight": 0.1276,
                                                            "singleQuantityMcf": 0.660968,
                                                            "mcf": 0.660968,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.660968,
                                                            "expandable": false,
                                                            "children": [
                                                                {
                                                                    "rowNumber": 4404,
                                                                    "parentRowNumber": 4403,
                                                                    "articleId": "20220620",
                                                                    "description": "20220620-00-Zylinderschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0063,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0063,
                                                                    "singleQuantityMcf": 0.032634,
                                                                    "mcf": 0.032634,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.032634,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4405,
                                                                    "parentRowNumber": 4403,
                                                                    "articleId": "21820420",
                                                                    "description": "21820420-00-Passkerbstift",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4406,
                                                                    "parentRowNumber": 4403,
                                                                    "articleId": "453061",
                                                                    "description": "453061-05-Kulisse",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.1213,
                                                                    "childrenWeight": 0.1213,
                                                                    "totalQuantityWeight": 0.1213,
                                                                    "singleQuantityMcf": 0.628334,
                                                                    "mcf": 0.628334,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.628334,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 4407,
                                                                            "parentRowNumber": 4406,
                                                                            "articleId": "1260055",
                                                                            "description": "1260055-00-Rundstahl",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.1213,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.1213,
                                                                            "singleQuantityMcf": 0.628334,
                                                                            "mcf": 0.628334,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.628334,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            "rowNumber": 4408,
                                                            "parentRowNumber": 4357,
                                                            "articleId": "ESA1025",
                                                            "description": "ESA1025-B-817,10-01-Druckfeder",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0,
                                                            "singleQuantityMcf": 0,
                                                            "mcf": 0,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0,
                                                            "expandable": false,
                                                            "children": [
                                                                {
                                                                    "rowNumber": 4409,
                                                                    "parentRowNumber": 4408,
                                                                    "articleId": "334392",
                                                                    "description": "334392-01-Druckfeder",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": []
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            "rowNumber": 4410,
                                                            "parentRowNumber": 4357,
                                                            "articleId": "Normon A 2ml 10.75x57",
                                                            "description": "Normon A 2ml 10.75x57-00-Spiessampulle",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0323,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0323,
                                                            "singleQuantityMcf": 0,
                                                            "mcf": 0,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 4411,
                                                            "parentRowNumber": 4357,
                                                            "articleId": "Normon A 2ml 10.75x57",
                                                            "description": "Normon A 2ml 10.75x57-00-Spiessampulle",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0323,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0323,
                                                            "singleQuantityMcf": 0,
                                                            "mcf": 0,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 4412,
                                                            "parentRowNumber": 4357,
                                                            "articleId": "Normon A 2ml 10.75x57",
                                                            "description": "Normon A 2ml 10.75x57-00-Spiessampulle",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0323,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0323,
                                                            "singleQuantityMcf": 0,
                                                            "mcf": 0,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 4413,
                                                            "parentRowNumber": 4357,
                                                            "articleId": "Normon A 2ml 10.75x57",
                                                            "description": "Normon A 2ml 10.75x57-00-Spiessampulle",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0323,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0323,
                                                            "singleQuantityMcf": 0,
                                                            "mcf": 0,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 4414,
                                                            "parentRowNumber": 4357,
                                                            "articleId": "Normon A 2ml 10.75x57",
                                                            "description": "Normon A 2ml 10.75x57-00-Spiessampulle",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0323,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0323,
                                                            "singleQuantityMcf": 0,
                                                            "mcf": 0,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 4415,
                                                            "parentRowNumber": 4357,
                                                            "articleId": "Normon A 2ml 10.75x57",
                                                            "description": "Normon A 2ml 10.75x57-00-Spiessampulle",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0323,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0323,
                                                            "singleQuantityMcf": 0,
                                                            "mcf": 0,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 4416,
                                                            "parentRowNumber": 4357,
                                                            "articleId": "Normon A 2ml 10.75x57",
                                                            "description": "Normon A 2ml 10.75x57-00-Spiessampulle",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0323,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0323,
                                                            "singleQuantityMcf": 0,
                                                            "mcf": 0,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 4417,
                                                            "parentRowNumber": 4357,
                                                            "articleId": "Normon A 2ml 10.75x57",
                                                            "description": "Normon A 2ml 10.75x57-00-Spiessampulle",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0323,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0323,
                                                            "singleQuantityMcf": 0,
                                                            "mcf": 0,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 4418,
                                                            "parentRowNumber": 4357,
                                                            "articleId": "Normon A 2ml 10.75x57",
                                                            "description": "Normon A 2ml 10.75x57-00-Spiessampulle",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0323,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0323,
                                                            "singleQuantityMcf": 0,
                                                            "mcf": 0,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 4419,
                                                            "parentRowNumber": 4357,
                                                            "articleId": "Normon A 2ml 10.75x57",
                                                            "description": "Normon A 2ml 10.75x57-00-Spiessampulle",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0323,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0323,
                                                            "singleQuantityMcf": 0,
                                                            "mcf": 0,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 4420,
                                                            "parentRowNumber": 4357,
                                                            "articleId": "Normon A 2ml 10.75x57",
                                                            "description": "Normon A 2ml 10.75x57-00-Spiessampulle",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0323,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0323,
                                                            "singleQuantityMcf": 0,
                                                            "mcf": 0,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 4421,
                                                            "parentRowNumber": 4357,
                                                            "articleId": "Normon A 2ml 10.75x57",
                                                            "description": "Normon A 2ml 10.75x57-00-Spiessampulle",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0323,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0323,
                                                            "singleQuantityMcf": 0,
                                                            "mcf": 0,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 4422,
                                                            "parentRowNumber": 4357,
                                                            "articleId": "Normon A 2ml 10.75x57",
                                                            "description": "Normon A 2ml 10.75x57-00-Spiessampulle",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0323,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0323,
                                                            "singleQuantityMcf": 0,
                                                            "mcf": 0,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 4423,
                                                            "parentRowNumber": 4357,
                                                            "articleId": "Normon A 2ml 10.75x57",
                                                            "description": "Normon A 2ml 10.75x57-00-Spiessampulle",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0323,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0323,
                                                            "singleQuantityMcf": 0,
                                                            "mcf": 0,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 4424,
                                                            "parentRowNumber": 4357,
                                                            "articleId": "Normon A 2ml 10.75x57",
                                                            "description": "Normon A 2ml 10.75x57-00-Spiessampulle",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0323,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0323,
                                                            "singleQuantityMcf": 0,
                                                            "mcf": 0,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 4425,
                                                            "parentRowNumber": 4357,
                                                            "articleId": "Normon A 2ml 10.75x57",
                                                            "description": "Normon A 2ml 10.75x57-00-Spiessampulle",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0323,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0323,
                                                            "singleQuantityMcf": 0,
                                                            "mcf": 0,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 4426,
                                                            "parentRowNumber": 4357,
                                                            "articleId": "Normon A 2ml 10.75x57",
                                                            "description": "Normon A 2ml 10.75x57-00-Spiessampulle",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0323,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0323,
                                                            "singleQuantityMcf": 0,
                                                            "mcf": 0,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0,
                                                            "expandable": false,
                                                            "children": []
                                                        }
                                                    ]
                                                }
                                            ]
                                        },
                                        {
                                            "rowNumber": 4427,
                                            "parentRowNumber": 4194,
                                            "articleId": "33847",
                                            "description": "33847-20SLG2-03-Schnecke",
                                            "hierarchy": 4,
                                            "quantity": 1,
                                            "weight": 22.244700000000005,
                                            "childrenWeight": 22.244700000000005,
                                            "totalQuantityWeight": 22.244700000000005,
                                            "singleQuantityMcf": 71.52219840000002,
                                            "mcf": 71.52219840000002,
                                            "singleQuantityLcf": 0,
                                            "lcf": 0,
                                            "totalQuantityMcf": 71.52219840000002,
                                            "expandable": false,
                                            "children": [
                                                {
                                                    "rowNumber": 4428,
                                                    "parentRowNumber": 4427,
                                                    "articleId": "MM0000005927",
                                                    "description": "MM0000005927-03-Schnecke",
                                                    "hierarchy": 5,
                                                    "quantity": 1,
                                                    "weight": 22.244700000000005,
                                                    "childrenWeight": 22.244700000000005,
                                                    "totalQuantityWeight": 22.244700000000005,
                                                    "singleQuantityMcf": 71.52219840000002,
                                                    "mcf": 71.52219840000002,
                                                    "singleQuantityLcf": 0,
                                                    "lcf": 0,
                                                    "totalQuantityMcf": 71.52219840000002,
                                                    "expandable": false,
                                                    "children": [
                                                        {
                                                            "rowNumber": 4429,
                                                            "parentRowNumber": 4428,
                                                            "articleId": "20120515",
                                                            "description": "20120515-00-Sechskantschraube",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0039,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0039,
                                                            "singleQuantityMcf": 0.020202,
                                                            "mcf": 0.020202,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.020202,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 4430,
                                                            "parentRowNumber": 4428,
                                                            "articleId": "20120515",
                                                            "description": "20120515-00-Sechskantschraube",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0039,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0039,
                                                            "singleQuantityMcf": 0.020202,
                                                            "mcf": 0.020202,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.020202,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 4431,
                                                            "parentRowNumber": 4428,
                                                            "articleId": "20220615",
                                                            "description": "20220615-00-Zylinderschraube",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0054,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0054,
                                                            "singleQuantityMcf": 0.027972,
                                                            "mcf": 0.027972,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.027972,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 4432,
                                                            "parentRowNumber": 4428,
                                                            "articleId": "20220615",
                                                            "description": "20220615-00-Zylinderschraube",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0054,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0054,
                                                            "singleQuantityMcf": 0.027972,
                                                            "mcf": 0.027972,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.027972,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 4433,
                                                            "parentRowNumber": 4428,
                                                            "articleId": "20420512",
                                                            "description": "20420512-00-Senkschraube",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0022,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0022,
                                                            "singleQuantityMcf": 0.011396,
                                                            "mcf": 0.011396,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.011396,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 4434,
                                                            "parentRowNumber": 4428,
                                                            "articleId": "20420512",
                                                            "description": "20420512-00-Senkschraube",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0022,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0022,
                                                            "singleQuantityMcf": 0.011396,
                                                            "mcf": 0.011396,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.011396,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 4435,
                                                            "parentRowNumber": 4428,
                                                            "articleId": "208205",
                                                            "description": "208205-00-Scheibe",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0004,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0004,
                                                            "singleQuantityMcf": 0.002072,
                                                            "mcf": 0.002072,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.002072,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 4436,
                                                            "parentRowNumber": 4428,
                                                            "articleId": "208205",
                                                            "description": "208205-00-Scheibe",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0004,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0004,
                                                            "singleQuantityMcf": 0.002072,
                                                            "mcf": 0.002072,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.002072,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 4437,
                                                            "parentRowNumber": 4428,
                                                            "articleId": "46100934",
                                                            "description": "46100934-01-Einlaufkeil",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0644,
                                                            "childrenWeight": 0.0644,
                                                            "totalQuantityWeight": 0.0644,
                                                            "singleQuantityMcf": 0.215188,
                                                            "mcf": 0.215188,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.215188,
                                                            "expandable": false,
                                                            "children": [
                                                                {
                                                                    "rowNumber": 4438,
                                                                    "parentRowNumber": 4437,
                                                                    "articleId": "162541",
                                                                    "description": "162541-01-Platte",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0598,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0598,
                                                                    "singleQuantityMcf": 0.19136,
                                                                    "mcf": 0.19136,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.19136,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4439,
                                                                    "parentRowNumber": 4437,
                                                                    "articleId": "20220415",
                                                                    "description": "20220415-00-Zylinderschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0023,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0023,
                                                                    "singleQuantityMcf": 0.011914,
                                                                    "mcf": 0.011914,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.011914,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4440,
                                                                    "parentRowNumber": 4437,
                                                                    "articleId": "20220415",
                                                                    "description": "20220415-00-Zylinderschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0023,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0023,
                                                                    "singleQuantityMcf": 0.011914,
                                                                    "mcf": 0.011914,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.011914,
                                                                    "expandable": false,
                                                                    "children": []
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            "rowNumber": 4441,
                                                            "parentRowNumber": 4428,
                                                            "articleId": "46100937",
                                                            "description": "46100937-01-Welle",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0154,
                                                            "childrenWeight": 0.0154,
                                                            "totalQuantityWeight": 0.0154,
                                                            "singleQuantityMcf": 0.0794252,
                                                            "mcf": 0.0794252,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.0794252,
                                                            "expandable": false,
                                                            "children": [
                                                                {
                                                                    "rowNumber": 4442,
                                                                    "parentRowNumber": 4441,
                                                                    "articleId": "1260006",
                                                                    "description": "1260006-00-Rundstahl",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0153,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0153,
                                                                    "singleQuantityMcf": 0.079254,
                                                                    "mcf": 0.079254,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.079254,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4443,
                                                                    "parentRowNumber": 4441,
                                                                    "articleId": "215206",
                                                                    "description": "215206-00-Sicherungsring",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0001,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0001,
                                                                    "singleQuantityMcf": 0.0001712,
                                                                    "mcf": 0.0001712,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.0001712,
                                                                    "expandable": false,
                                                                    "children": []
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            "rowNumber": 4444,
                                                            "parentRowNumber": 4428,
                                                            "articleId": "46100937",
                                                            "description": "46100937-01-Welle",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0154,
                                                            "childrenWeight": 0.0154,
                                                            "totalQuantityWeight": 0.0154,
                                                            "singleQuantityMcf": 0.0794252,
                                                            "mcf": 0.0794252,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.0794252,
                                                            "expandable": false,
                                                            "children": [
                                                                {
                                                                    "rowNumber": 4445,
                                                                    "parentRowNumber": 4444,
                                                                    "articleId": "1260006",
                                                                    "description": "1260006-00-Rundstahl",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0153,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0153,
                                                                    "singleQuantityMcf": 0.079254,
                                                                    "mcf": 0.079254,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.079254,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4446,
                                                                    "parentRowNumber": 4444,
                                                                    "articleId": "215206",
                                                                    "description": "215206-00-Sicherungsring",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0001,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0001,
                                                                    "singleQuantityMcf": 0.0001712,
                                                                    "mcf": 0.0001712,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.0001712,
                                                                    "expandable": false,
                                                                    "children": []
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            "rowNumber": 4447,
                                                            "parentRowNumber": 4428,
                                                            "articleId": "47122726",
                                                            "description": "47122726-00-Fuehrung",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0284,
                                                            "childrenWeight": 0.0284,
                                                            "totalQuantityWeight": 0.0284,
                                                            "singleQuantityMcf": 0.09088000000000002,
                                                            "mcf": 0.09088000000000002,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.09088000000000002,
                                                            "expandable": false,
                                                            "children": [
                                                                {
                                                                    "rowNumber": 4448,
                                                                    "parentRowNumber": 4447,
                                                                    "articleId": "162532",
                                                                    "description": "162532-01-Platte",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0284,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0284,
                                                                    "singleQuantityMcf": 0.09088000000000002,
                                                                    "mcf": 0.09088000000000002,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.09088000000000002,
                                                                    "expandable": false,
                                                                    "children": []
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            "rowNumber": 4449,
                                                            "parentRowNumber": 4428,
                                                            "articleId": "47122750",
                                                            "description": "47122750-00-Fuehrung",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0242,
                                                            "childrenWeight": 0.0242,
                                                            "totalQuantityWeight": 0.0242,
                                                            "singleQuantityMcf": 0.07744000000000001,
                                                            "mcf": 0.07744000000000001,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.07744000000000001,
                                                            "expandable": false,
                                                            "children": [
                                                                {
                                                                    "rowNumber": 4450,
                                                                    "parentRowNumber": 4449,
                                                                    "articleId": "162532",
                                                                    "description": "162532-01-Platte",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0242,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0242,
                                                                    "singleQuantityMcf": 0.07744000000000001,
                                                                    "mcf": 0.07744000000000001,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.07744000000000001,
                                                                    "expandable": false,
                                                                    "children": []
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            "rowNumber": 4451,
                                                            "parentRowNumber": 4428,
                                                            "articleId": "47129911",
                                                            "description": "47129911-00-Einlaufformat",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.821,
                                                            "childrenWeight": 0.821,
                                                            "totalQuantityWeight": 0.821,
                                                            "singleQuantityMcf": 2.589248,
                                                            "mcf": 2.589248,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 2.589248,
                                                            "expandable": false,
                                                            "children": [
                                                                {
                                                                    "rowNumber": 4452,
                                                                    "parentRowNumber": 4451,
                                                                    "articleId": "162544",
                                                                    "description": "162544-01-Platte",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.8069999999999999,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.8069999999999999,
                                                                    "singleQuantityMcf": 2.5824,
                                                                    "mcf": 2.5824,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 2.5824,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4453,
                                                                    "parentRowNumber": 4451,
                                                                    "articleId": "275104",
                                                                    "description": "275104-00-DU-Buchse",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.001,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.001,
                                                                    "singleQuantityMcf": 0.001712,
                                                                    "mcf": 0.001712,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.001712,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4454,
                                                                    "parentRowNumber": 4451,
                                                                    "articleId": "275104",
                                                                    "description": "275104-00-DU-Buchse",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.001,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.001,
                                                                    "singleQuantityMcf": 0.001712,
                                                                    "mcf": 0.001712,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.001712,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4455,
                                                                    "parentRowNumber": 4451,
                                                                    "articleId": "275104",
                                                                    "description": "275104-00-DU-Buchse",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.001,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.001,
                                                                    "singleQuantityMcf": 0.001712,
                                                                    "mcf": 0.001712,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.001712,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4456,
                                                                    "parentRowNumber": 4451,
                                                                    "articleId": "275104",
                                                                    "description": "275104-00-DU-Buchse",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.001,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.001,
                                                                    "singleQuantityMcf": 0.001712,
                                                                    "mcf": 0.001712,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.001712,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4457,
                                                                    "parentRowNumber": 4451,
                                                                    "articleId": "347200",
                                                                    "description": "347200-00-Ensat-Gewindebuchse",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0025,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0025,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4458,
                                                                    "parentRowNumber": 4451,
                                                                    "articleId": "347200",
                                                                    "description": "347200-00-Ensat-Gewindebuchse",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0025,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0025,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4459,
                                                                    "parentRowNumber": 4451,
                                                                    "articleId": "347200",
                                                                    "description": "347200-00-Ensat-Gewindebuchse",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0025,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0025,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4460,
                                                                    "parentRowNumber": 4451,
                                                                    "articleId": "347200",
                                                                    "description": "347200-00-Ensat-Gewindebuchse",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0025,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0025,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": []
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            "rowNumber": 4461,
                                                            "parentRowNumber": 4428,
                                                            "articleId": "47130565",
                                                            "description": "47130565-01-Schnecke",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 21.1245,
                                                            "childrenWeight": 21.1245,
                                                            "totalQuantityWeight": 21.1245,
                                                            "singleQuantityMcf": 67.60634000000002,
                                                            "mcf": 67.60634000000002,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 67.60634000000002,
                                                            "expandable": false,
                                                            "children": [
                                                                {
                                                                    "rowNumber": 4462,
                                                                    "parentRowNumber": 4461,
                                                                    "articleId": "1617090",
                                                                    "description": "1617090-00-Rundstab",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 20.9991,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 20.9991,
                                                                    "singleQuantityMcf": 67.19712000000001,
                                                                    "mcf": 67.19712000000001,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 67.19712000000001,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4463,
                                                                    "parentRowNumber": 4461,
                                                                    "articleId": "29216000",
                                                                    "description": "29216000-00-Radial-Rillenkugellager",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.025,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.025,
                                                                    "singleQuantityMcf": 0.1295,
                                                                    "mcf": 0.1295,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.1295,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4464,
                                                                    "parentRowNumber": 4461,
                                                                    "articleId": "347205",
                                                                    "description": "347205-00-Ensat-Gewindebuchse",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0058,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0058,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4465,
                                                                    "parentRowNumber": 4461,
                                                                    "articleId": "347205",
                                                                    "description": "347205-00-Ensat-Gewindebuchse",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0058,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0058,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4466,
                                                                    "parentRowNumber": 4461,
                                                                    "articleId": "347205",
                                                                    "description": "347205-00-Ensat-Gewindebuchse",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0058,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0058,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4467,
                                                                    "parentRowNumber": 4461,
                                                                    "articleId": "347205",
                                                                    "description": "347205-00-Ensat-Gewindebuchse",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0058,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0058,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4468,
                                                                    "parentRowNumber": 4461,
                                                                    "articleId": "347205",
                                                                    "description": "347205-00-Ensat-Gewindebuchse",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0058,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0058,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4469,
                                                                    "parentRowNumber": 4461,
                                                                    "articleId": "347205",
                                                                    "description": "347205-00-Ensat-Gewindebuchse",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0058,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0058,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4470,
                                                                    "parentRowNumber": 4461,
                                                                    "articleId": "347205",
                                                                    "description": "347205-00-Ensat-Gewindebuchse",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0058,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0058,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4471,
                                                                    "parentRowNumber": 4461,
                                                                    "articleId": "347205",
                                                                    "description": "347205-00-Ensat-Gewindebuchse",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0058,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0058,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4472,
                                                                    "parentRowNumber": 4461,
                                                                    "articleId": "440117",
                                                                    "description": "440117-01-Lagerschale",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.054,
                                                                    "childrenWeight": 0.054,
                                                                    "totalQuantityWeight": 0.054,
                                                                    "singleQuantityMcf": 0.27972,
                                                                    "mcf": 0.27972,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.27972,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 4473,
                                                                            "parentRowNumber": 4472,
                                                                            "articleId": "1260035",
                                                                            "description": "1260035-00-Rundstahl",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.054,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.054,
                                                                            "singleQuantityMcf": 0.27972,
                                                                            "mcf": 0.27972,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.27972,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            "rowNumber": 4474,
                                                            "parentRowNumber": 4428,
                                                            "articleId": "ESA1025",
                                                            "description": "ESA1025-B-807,20-01-Kulisse",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.1276,
                                                            "childrenWeight": 0.1276,
                                                            "totalQuantityWeight": 0.1276,
                                                            "singleQuantityMcf": 0.660968,
                                                            "mcf": 0.660968,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.660968,
                                                            "expandable": false,
                                                            "children": [
                                                                {
                                                                    "rowNumber": 4475,
                                                                    "parentRowNumber": 4474,
                                                                    "articleId": "20220620",
                                                                    "description": "20220620-00-Zylinderschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0063,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0063,
                                                                    "singleQuantityMcf": 0.032634,
                                                                    "mcf": 0.032634,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.032634,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4476,
                                                                    "parentRowNumber": 4474,
                                                                    "articleId": "21820420",
                                                                    "description": "21820420-00-Passkerbstift",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4477,
                                                                    "parentRowNumber": 4474,
                                                                    "articleId": "453061",
                                                                    "description": "453061-05-Kulisse",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.1213,
                                                                    "childrenWeight": 0.1213,
                                                                    "totalQuantityWeight": 0.1213,
                                                                    "singleQuantityMcf": 0.628334,
                                                                    "mcf": 0.628334,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.628334,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 4478,
                                                                            "parentRowNumber": 4477,
                                                                            "articleId": "1260055",
                                                                            "description": "1260055-00-Rundstahl",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.1213,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.1213,
                                                                            "singleQuantityMcf": 0.628334,
                                                                            "mcf": 0.628334,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.628334,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            "rowNumber": 4479,
                                                            "parentRowNumber": 4428,
                                                            "articleId": "ESA1025",
                                                            "description": "ESA1025-B-817,10-01-Druckfeder",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0,
                                                            "singleQuantityMcf": 0,
                                                            "mcf": 0,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0,
                                                            "expandable": false,
                                                            "children": [
                                                                {
                                                                    "rowNumber": 4480,
                                                                    "parentRowNumber": 4479,
                                                                    "articleId": "334392",
                                                                    "description": "334392-01-Druckfeder",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": []
                                                                }
                                                            ]
                                                        }
                                                    ]
                                                }
                                            ]
                                        },
                                        {
                                            "rowNumber": 4481,
                                            "parentRowNumber": 4194,
                                            "articleId": "33847",
                                            "description": "33847-20SLG3-03-Schnecke",
                                            "hierarchy": 4,
                                            "quantity": 1,
                                            "weight": 18.6119,
                                            "childrenWeight": 18.6119,
                                            "totalQuantityWeight": 18.6119,
                                            "singleQuantityMcf": 59.89723839999999,
                                            "mcf": 59.89723839999999,
                                            "singleQuantityLcf": 0,
                                            "lcf": 0,
                                            "totalQuantityMcf": 59.89723839999999,
                                            "expandable": false,
                                            "children": [
                                                {
                                                    "rowNumber": 4482,
                                                    "parentRowNumber": 4481,
                                                    "articleId": "MM0000005929",
                                                    "description": "MM0000005929-03-Schnecke",
                                                    "hierarchy": 5,
                                                    "quantity": 1,
                                                    "weight": 18.6119,
                                                    "childrenWeight": 18.6119,
                                                    "totalQuantityWeight": 18.6119,
                                                    "singleQuantityMcf": 59.89723839999999,
                                                    "mcf": 59.89723839999999,
                                                    "singleQuantityLcf": 0,
                                                    "lcf": 0,
                                                    "totalQuantityMcf": 59.89723839999999,
                                                    "expandable": false,
                                                    "children": [
                                                        {
                                                            "rowNumber": 4483,
                                                            "parentRowNumber": 4482,
                                                            "articleId": "20120515",
                                                            "description": "20120515-00-Sechskantschraube",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0039,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0039,
                                                            "singleQuantityMcf": 0.020202,
                                                            "mcf": 0.020202,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.020202,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 4484,
                                                            "parentRowNumber": 4482,
                                                            "articleId": "20120515",
                                                            "description": "20120515-00-Sechskantschraube",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0039,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0039,
                                                            "singleQuantityMcf": 0.020202,
                                                            "mcf": 0.020202,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.020202,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 4485,
                                                            "parentRowNumber": 4482,
                                                            "articleId": "20220615",
                                                            "description": "20220615-00-Zylinderschraube",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0054,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0054,
                                                            "singleQuantityMcf": 0.027972,
                                                            "mcf": 0.027972,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.027972,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 4486,
                                                            "parentRowNumber": 4482,
                                                            "articleId": "20220615",
                                                            "description": "20220615-00-Zylinderschraube",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0054,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0054,
                                                            "singleQuantityMcf": 0.027972,
                                                            "mcf": 0.027972,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.027972,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 4487,
                                                            "parentRowNumber": 4482,
                                                            "articleId": "20420512",
                                                            "description": "20420512-00-Senkschraube",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0022,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0022,
                                                            "singleQuantityMcf": 0.011396,
                                                            "mcf": 0.011396,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.011396,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 4488,
                                                            "parentRowNumber": 4482,
                                                            "articleId": "20420512",
                                                            "description": "20420512-00-Senkschraube",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0022,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0022,
                                                            "singleQuantityMcf": 0.011396,
                                                            "mcf": 0.011396,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.011396,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 4489,
                                                            "parentRowNumber": 4482,
                                                            "articleId": "208205",
                                                            "description": "208205-00-Scheibe",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0004,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0004,
                                                            "singleQuantityMcf": 0.002072,
                                                            "mcf": 0.002072,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.002072,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 4490,
                                                            "parentRowNumber": 4482,
                                                            "articleId": "208205",
                                                            "description": "208205-00-Scheibe",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0004,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0004,
                                                            "singleQuantityMcf": 0.002072,
                                                            "mcf": 0.002072,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.002072,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 4491,
                                                            "parentRowNumber": 4482,
                                                            "articleId": "46100934",
                                                            "description": "46100934-01-Einlaufkeil",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0644,
                                                            "childrenWeight": 0.0644,
                                                            "totalQuantityWeight": 0.0644,
                                                            "singleQuantityMcf": 0.215188,
                                                            "mcf": 0.215188,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.215188,
                                                            "expandable": false,
                                                            "children": [
                                                                {
                                                                    "rowNumber": 4492,
                                                                    "parentRowNumber": 4491,
                                                                    "articleId": "162541",
                                                                    "description": "162541-01-Platte",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0598,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0598,
                                                                    "singleQuantityMcf": 0.19136,
                                                                    "mcf": 0.19136,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.19136,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4493,
                                                                    "parentRowNumber": 4491,
                                                                    "articleId": "20220415",
                                                                    "description": "20220415-00-Zylinderschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0023,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0023,
                                                                    "singleQuantityMcf": 0.011914,
                                                                    "mcf": 0.011914,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.011914,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4494,
                                                                    "parentRowNumber": 4491,
                                                                    "articleId": "20220415",
                                                                    "description": "20220415-00-Zylinderschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0023,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0023,
                                                                    "singleQuantityMcf": 0.011914,
                                                                    "mcf": 0.011914,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.011914,
                                                                    "expandable": false,
                                                                    "children": []
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            "rowNumber": 4495,
                                                            "parentRowNumber": 4482,
                                                            "articleId": "46100937",
                                                            "description": "46100937-01-Welle",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0154,
                                                            "childrenWeight": 0.0154,
                                                            "totalQuantityWeight": 0.0154,
                                                            "singleQuantityMcf": 0.0794252,
                                                            "mcf": 0.0794252,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.0794252,
                                                            "expandable": false,
                                                            "children": [
                                                                {
                                                                    "rowNumber": 4496,
                                                                    "parentRowNumber": 4495,
                                                                    "articleId": "1260006",
                                                                    "description": "1260006-00-Rundstahl",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0153,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0153,
                                                                    "singleQuantityMcf": 0.079254,
                                                                    "mcf": 0.079254,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.079254,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4497,
                                                                    "parentRowNumber": 4495,
                                                                    "articleId": "215206",
                                                                    "description": "215206-00-Sicherungsring",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0001,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0001,
                                                                    "singleQuantityMcf": 0.0001712,
                                                                    "mcf": 0.0001712,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.0001712,
                                                                    "expandable": false,
                                                                    "children": []
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            "rowNumber": 4498,
                                                            "parentRowNumber": 4482,
                                                            "articleId": "46100937",
                                                            "description": "46100937-01-Welle",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0154,
                                                            "childrenWeight": 0.0154,
                                                            "totalQuantityWeight": 0.0154,
                                                            "singleQuantityMcf": 0.0794252,
                                                            "mcf": 0.0794252,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.0794252,
                                                            "expandable": false,
                                                            "children": [
                                                                {
                                                                    "rowNumber": 4499,
                                                                    "parentRowNumber": 4498,
                                                                    "articleId": "1260006",
                                                                    "description": "1260006-00-Rundstahl",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0153,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0153,
                                                                    "singleQuantityMcf": 0.079254,
                                                                    "mcf": 0.079254,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.079254,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4500,
                                                                    "parentRowNumber": 4498,
                                                                    "articleId": "215206",
                                                                    "description": "215206-00-Sicherungsring",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0001,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0001,
                                                                    "singleQuantityMcf": 0.0001712,
                                                                    "mcf": 0.0001712,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.0001712,
                                                                    "expandable": false,
                                                                    "children": []
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            "rowNumber": 4501,
                                                            "parentRowNumber": 4482,
                                                            "articleId": "47122726",
                                                            "description": "47122726-00-Fuehrung",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0284,
                                                            "childrenWeight": 0.0284,
                                                            "totalQuantityWeight": 0.0284,
                                                            "singleQuantityMcf": 0.09088000000000002,
                                                            "mcf": 0.09088000000000002,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.09088000000000002,
                                                            "expandable": false,
                                                            "children": [
                                                                {
                                                                    "rowNumber": 4502,
                                                                    "parentRowNumber": 4501,
                                                                    "articleId": "162532",
                                                                    "description": "162532-01-Platte",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0284,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0284,
                                                                    "singleQuantityMcf": 0.09088000000000002,
                                                                    "mcf": 0.09088000000000002,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.09088000000000002,
                                                                    "expandable": false,
                                                                    "children": []
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            "rowNumber": 4503,
                                                            "parentRowNumber": 4482,
                                                            "articleId": "47122750",
                                                            "description": "47122750-00-Fuehrung",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0242,
                                                            "childrenWeight": 0.0242,
                                                            "totalQuantityWeight": 0.0242,
                                                            "singleQuantityMcf": 0.07744000000000001,
                                                            "mcf": 0.07744000000000001,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.07744000000000001,
                                                            "expandable": false,
                                                            "children": [
                                                                {
                                                                    "rowNumber": 4504,
                                                                    "parentRowNumber": 4503,
                                                                    "articleId": "162532",
                                                                    "description": "162532-01-Platte",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0242,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0242,
                                                                    "singleQuantityMcf": 0.07744000000000001,
                                                                    "mcf": 0.07744000000000001,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.07744000000000001,
                                                                    "expandable": false,
                                                                    "children": []
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            "rowNumber": 4505,
                                                            "parentRowNumber": 4482,
                                                            "articleId": "47130314",
                                                            "description": "47130314-00-Einlaufformat",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.8244,
                                                            "childrenWeight": 0.8244,
                                                            "totalQuantityWeight": 0.8244,
                                                            "singleQuantityMcf": 2.600128,
                                                            "mcf": 2.600128,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 2.600128,
                                                            "expandable": false,
                                                            "children": [
                                                                {
                                                                    "rowNumber": 4506,
                                                                    "parentRowNumber": 4505,
                                                                    "articleId": "162544",
                                                                    "description": "162544-01-Platte",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.8104,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.8104,
                                                                    "singleQuantityMcf": 2.59328,
                                                                    "mcf": 2.59328,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 2.59328,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4507,
                                                                    "parentRowNumber": 4505,
                                                                    "articleId": "275104",
                                                                    "description": "275104-00-DU-Buchse",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.001,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.001,
                                                                    "singleQuantityMcf": 0.001712,
                                                                    "mcf": 0.001712,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.001712,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4508,
                                                                    "parentRowNumber": 4505,
                                                                    "articleId": "275104",
                                                                    "description": "275104-00-DU-Buchse",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.001,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.001,
                                                                    "singleQuantityMcf": 0.001712,
                                                                    "mcf": 0.001712,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.001712,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4509,
                                                                    "parentRowNumber": 4505,
                                                                    "articleId": "275104",
                                                                    "description": "275104-00-DU-Buchse",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.001,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.001,
                                                                    "singleQuantityMcf": 0.001712,
                                                                    "mcf": 0.001712,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.001712,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4510,
                                                                    "parentRowNumber": 4505,
                                                                    "articleId": "275104",
                                                                    "description": "275104-00-DU-Buchse",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.001,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.001,
                                                                    "singleQuantityMcf": 0.001712,
                                                                    "mcf": 0.001712,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.001712,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4511,
                                                                    "parentRowNumber": 4505,
                                                                    "articleId": "347200",
                                                                    "description": "347200-00-Ensat-Gewindebuchse",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0025,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0025,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4512,
                                                                    "parentRowNumber": 4505,
                                                                    "articleId": "347200",
                                                                    "description": "347200-00-Ensat-Gewindebuchse",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0025,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0025,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4513,
                                                                    "parentRowNumber": 4505,
                                                                    "articleId": "347200",
                                                                    "description": "347200-00-Ensat-Gewindebuchse",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0025,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0025,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4514,
                                                                    "parentRowNumber": 4505,
                                                                    "articleId": "347200",
                                                                    "description": "347200-00-Ensat-Gewindebuchse",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0025,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0025,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": []
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            "rowNumber": 4515,
                                                            "parentRowNumber": 4482,
                                                            "articleId": "47130592",
                                                            "description": "47130592-01-Schnecke",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 17.4883,
                                                            "childrenWeight": 17.4883,
                                                            "totalQuantityWeight": 17.4883,
                                                            "singleQuantityMcf": 55.9705,
                                                            "mcf": 55.9705,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 55.9705,
                                                            "expandable": false,
                                                            "children": [
                                                                {
                                                                    "rowNumber": 4516,
                                                                    "parentRowNumber": 4515,
                                                                    "articleId": "1617090",
                                                                    "description": "1617090-00-Rundstab",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 17.3629,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 17.3629,
                                                                    "singleQuantityMcf": 55.56128,
                                                                    "mcf": 55.56128,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 55.56128,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4517,
                                                                    "parentRowNumber": 4515,
                                                                    "articleId": "29216000",
                                                                    "description": "29216000-00-Radial-Rillenkugellager",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.025,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.025,
                                                                    "singleQuantityMcf": 0.1295,
                                                                    "mcf": 0.1295,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.1295,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4518,
                                                                    "parentRowNumber": 4515,
                                                                    "articleId": "347205",
                                                                    "description": "347205-00-Ensat-Gewindebuchse",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0058,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0058,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4519,
                                                                    "parentRowNumber": 4515,
                                                                    "articleId": "347205",
                                                                    "description": "347205-00-Ensat-Gewindebuchse",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0058,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0058,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4520,
                                                                    "parentRowNumber": 4515,
                                                                    "articleId": "347205",
                                                                    "description": "347205-00-Ensat-Gewindebuchse",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0058,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0058,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4521,
                                                                    "parentRowNumber": 4515,
                                                                    "articleId": "347205",
                                                                    "description": "347205-00-Ensat-Gewindebuchse",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0058,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0058,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4522,
                                                                    "parentRowNumber": 4515,
                                                                    "articleId": "347205",
                                                                    "description": "347205-00-Ensat-Gewindebuchse",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0058,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0058,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4523,
                                                                    "parentRowNumber": 4515,
                                                                    "articleId": "347205",
                                                                    "description": "347205-00-Ensat-Gewindebuchse",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0058,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0058,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4524,
                                                                    "parentRowNumber": 4515,
                                                                    "articleId": "347205",
                                                                    "description": "347205-00-Ensat-Gewindebuchse",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0058,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0058,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4525,
                                                                    "parentRowNumber": 4515,
                                                                    "articleId": "347205",
                                                                    "description": "347205-00-Ensat-Gewindebuchse",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0058,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0058,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4526,
                                                                    "parentRowNumber": 4515,
                                                                    "articleId": "440117",
                                                                    "description": "440117-01-Lagerschale",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.054,
                                                                    "childrenWeight": 0.054,
                                                                    "totalQuantityWeight": 0.054,
                                                                    "singleQuantityMcf": 0.27972,
                                                                    "mcf": 0.27972,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.27972,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 4527,
                                                                            "parentRowNumber": 4526,
                                                                            "articleId": "1260035",
                                                                            "description": "1260035-00-Rundstahl",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.054,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.054,
                                                                            "singleQuantityMcf": 0.27972,
                                                                            "mcf": 0.27972,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.27972,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            "rowNumber": 4528,
                                                            "parentRowNumber": 4482,
                                                            "articleId": "ESA1025",
                                                            "description": "ESA1025-B-807,20-01-Kulisse",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.1276,
                                                            "childrenWeight": 0.1276,
                                                            "totalQuantityWeight": 0.1276,
                                                            "singleQuantityMcf": 0.660968,
                                                            "mcf": 0.660968,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.660968,
                                                            "expandable": false,
                                                            "children": [
                                                                {
                                                                    "rowNumber": 4529,
                                                                    "parentRowNumber": 4528,
                                                                    "articleId": "20220620",
                                                                    "description": "20220620-00-Zylinderschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0063,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0063,
                                                                    "singleQuantityMcf": 0.032634,
                                                                    "mcf": 0.032634,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.032634,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4530,
                                                                    "parentRowNumber": 4528,
                                                                    "articleId": "21820420",
                                                                    "description": "21820420-00-Passkerbstift",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4531,
                                                                    "parentRowNumber": 4528,
                                                                    "articleId": "453061",
                                                                    "description": "453061-05-Kulisse",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.1213,
                                                                    "childrenWeight": 0.1213,
                                                                    "totalQuantityWeight": 0.1213,
                                                                    "singleQuantityMcf": 0.628334,
                                                                    "mcf": 0.628334,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.628334,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 4532,
                                                                            "parentRowNumber": 4531,
                                                                            "articleId": "1260055",
                                                                            "description": "1260055-00-Rundstahl",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.1213,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.1213,
                                                                            "singleQuantityMcf": 0.628334,
                                                                            "mcf": 0.628334,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.628334,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            "rowNumber": 4533,
                                                            "parentRowNumber": 4482,
                                                            "articleId": "ESA1025",
                                                            "description": "ESA1025-B-817,10-01-Druckfeder",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0,
                                                            "singleQuantityMcf": 0,
                                                            "mcf": 0,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0,
                                                            "expandable": false,
                                                            "children": [
                                                                {
                                                                    "rowNumber": 4534,
                                                                    "parentRowNumber": 4533,
                                                                    "articleId": "334392",
                                                                    "description": "334392-01-Druckfeder",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": []
                                                                }
                                                            ]
                                                        }
                                                    ]
                                                }
                                            ]
                                        },
                                        {
                                            "rowNumber": 4535,
                                            "parentRowNumber": 4194,
                                            "articleId": "33847",
                                            "description": "33847-20SLG4-03-Schnecke",
                                            "hierarchy": 4,
                                            "quantity": 1,
                                            "weight": 12.1184,
                                            "childrenWeight": 12.1184,
                                            "totalQuantityWeight": 12.1184,
                                            "singleQuantityMcf": 42.0381384,
                                            "mcf": 42.0381384,
                                            "singleQuantityLcf": 0,
                                            "lcf": 0,
                                            "totalQuantityMcf": 42.0381384,
                                            "expandable": false,
                                            "children": [
                                                {
                                                    "rowNumber": 4536,
                                                    "parentRowNumber": 4535,
                                                    "articleId": "MM0000005933",
                                                    "description": "MM0000005933-03-Schnecke",
                                                    "hierarchy": 5,
                                                    "quantity": 1,
                                                    "weight": 12.1184,
                                                    "childrenWeight": 12.1184,
                                                    "totalQuantityWeight": 12.1184,
                                                    "singleQuantityMcf": 42.0381384,
                                                    "mcf": 42.0381384,
                                                    "singleQuantityLcf": 0,
                                                    "lcf": 0,
                                                    "totalQuantityMcf": 42.0381384,
                                                    "expandable": false,
                                                    "children": [
                                                        {
                                                            "rowNumber": 4537,
                                                            "parentRowNumber": 4536,
                                                            "articleId": "20120540",
                                                            "description": "20120540-00-Sechskantschraube",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0077,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0077,
                                                            "singleQuantityMcf": 0.039886,
                                                            "mcf": 0.039886,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.039886,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 4538,
                                                            "parentRowNumber": 4536,
                                                            "articleId": "20120540",
                                                            "description": "20120540-00-Sechskantschraube",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0077,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0077,
                                                            "singleQuantityMcf": 0.039886,
                                                            "mcf": 0.039886,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.039886,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 4539,
                                                            "parentRowNumber": 4536,
                                                            "articleId": "20220615",
                                                            "description": "20220615-00-Zylinderschraube",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0054,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0054,
                                                            "singleQuantityMcf": 0.027972,
                                                            "mcf": 0.027972,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.027972,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 4540,
                                                            "parentRowNumber": 4536,
                                                            "articleId": "20220615",
                                                            "description": "20220615-00-Zylinderschraube",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0054,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0054,
                                                            "singleQuantityMcf": 0.027972,
                                                            "mcf": 0.027972,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.027972,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 4541,
                                                            "parentRowNumber": 4536,
                                                            "articleId": "20420512",
                                                            "description": "20420512-00-Senkschraube",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0022,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0022,
                                                            "singleQuantityMcf": 0.011396,
                                                            "mcf": 0.011396,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.011396,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 4542,
                                                            "parentRowNumber": 4536,
                                                            "articleId": "20420512",
                                                            "description": "20420512-00-Senkschraube",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0022,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0022,
                                                            "singleQuantityMcf": 0.011396,
                                                            "mcf": 0.011396,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.011396,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 4543,
                                                            "parentRowNumber": 4536,
                                                            "articleId": "208205",
                                                            "description": "208205-00-Scheibe",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0004,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0004,
                                                            "singleQuantityMcf": 0.002072,
                                                            "mcf": 0.002072,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.002072,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 4544,
                                                            "parentRowNumber": 4536,
                                                            "articleId": "208205",
                                                            "description": "208205-00-Scheibe",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0004,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0004,
                                                            "singleQuantityMcf": 0.002072,
                                                            "mcf": 0.002072,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.002072,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 4545,
                                                            "parentRowNumber": 4536,
                                                            "articleId": "46100934",
                                                            "description": "46100934-01-Einlaufkeil",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0644,
                                                            "childrenWeight": 0.0644,
                                                            "totalQuantityWeight": 0.0644,
                                                            "singleQuantityMcf": 0.215188,
                                                            "mcf": 0.215188,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.215188,
                                                            "expandable": false,
                                                            "children": [
                                                                {
                                                                    "rowNumber": 4546,
                                                                    "parentRowNumber": 4545,
                                                                    "articleId": "162541",
                                                                    "description": "162541-01-Platte",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0598,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0598,
                                                                    "singleQuantityMcf": 0.19136,
                                                                    "mcf": 0.19136,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.19136,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4547,
                                                                    "parentRowNumber": 4545,
                                                                    "articleId": "20220415",
                                                                    "description": "20220415-00-Zylinderschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0023,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0023,
                                                                    "singleQuantityMcf": 0.011914,
                                                                    "mcf": 0.011914,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.011914,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4548,
                                                                    "parentRowNumber": 4545,
                                                                    "articleId": "20220415",
                                                                    "description": "20220415-00-Zylinderschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0023,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0023,
                                                                    "singleQuantityMcf": 0.011914,
                                                                    "mcf": 0.011914,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.011914,
                                                                    "expandable": false,
                                                                    "children": []
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            "rowNumber": 4549,
                                                            "parentRowNumber": 4536,
                                                            "articleId": "46100937",
                                                            "description": "46100937-01-Welle",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0154,
                                                            "childrenWeight": 0.0154,
                                                            "totalQuantityWeight": 0.0154,
                                                            "singleQuantityMcf": 0.0794252,
                                                            "mcf": 0.0794252,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.0794252,
                                                            "expandable": false,
                                                            "children": [
                                                                {
                                                                    "rowNumber": 4550,
                                                                    "parentRowNumber": 4549,
                                                                    "articleId": "1260006",
                                                                    "description": "1260006-00-Rundstahl",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0153,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0153,
                                                                    "singleQuantityMcf": 0.079254,
                                                                    "mcf": 0.079254,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.079254,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4551,
                                                                    "parentRowNumber": 4549,
                                                                    "articleId": "215206",
                                                                    "description": "215206-00-Sicherungsring",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0001,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0001,
                                                                    "singleQuantityMcf": 0.0001712,
                                                                    "mcf": 0.0001712,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.0001712,
                                                                    "expandable": false,
                                                                    "children": []
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            "rowNumber": 4552,
                                                            "parentRowNumber": 4536,
                                                            "articleId": "46100937",
                                                            "description": "46100937-01-Welle",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0154,
                                                            "childrenWeight": 0.0154,
                                                            "totalQuantityWeight": 0.0154,
                                                            "singleQuantityMcf": 0.0794252,
                                                            "mcf": 0.0794252,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.0794252,
                                                            "expandable": false,
                                                            "children": [
                                                                {
                                                                    "rowNumber": 4553,
                                                                    "parentRowNumber": 4552,
                                                                    "articleId": "1260006",
                                                                    "description": "1260006-00-Rundstahl",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0153,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0153,
                                                                    "singleQuantityMcf": 0.079254,
                                                                    "mcf": 0.079254,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.079254,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4554,
                                                                    "parentRowNumber": 4552,
                                                                    "articleId": "215206",
                                                                    "description": "215206-00-Sicherungsring",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0001,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0001,
                                                                    "singleQuantityMcf": 0.0001712,
                                                                    "mcf": 0.0001712,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.0001712,
                                                                    "expandable": false,
                                                                    "children": []
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            "rowNumber": 4555,
                                                            "parentRowNumber": 4536,
                                                            "articleId": "47122726",
                                                            "description": "47122726-00-Fuehrung",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0284,
                                                            "childrenWeight": 0.0284,
                                                            "totalQuantityWeight": 0.0284,
                                                            "singleQuantityMcf": 0.09088000000000002,
                                                            "mcf": 0.09088000000000002,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.09088000000000002,
                                                            "expandable": false,
                                                            "children": [
                                                                {
                                                                    "rowNumber": 4556,
                                                                    "parentRowNumber": 4555,
                                                                    "articleId": "162532",
                                                                    "description": "162532-01-Platte",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0284,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0284,
                                                                    "singleQuantityMcf": 0.09088000000000002,
                                                                    "mcf": 0.09088000000000002,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.09088000000000002,
                                                                    "expandable": false,
                                                                    "children": []
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            "rowNumber": 4557,
                                                            "parentRowNumber": 4536,
                                                            "articleId": "47122750",
                                                            "description": "47122750-00-Fuehrung",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0242,
                                                            "childrenWeight": 0.0242,
                                                            "totalQuantityWeight": 0.0242,
                                                            "singleQuantityMcf": 0.07744000000000001,
                                                            "mcf": 0.07744000000000001,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.07744000000000001,
                                                            "expandable": false,
                                                            "children": [
                                                                {
                                                                    "rowNumber": 4558,
                                                                    "parentRowNumber": 4557,
                                                                    "articleId": "162532",
                                                                    "description": "162532-01-Platte",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0242,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0242,
                                                                    "singleQuantityMcf": 0.07744000000000001,
                                                                    "mcf": 0.07744000000000001,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.07744000000000001,
                                                                    "expandable": false,
                                                                    "children": []
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            "rowNumber": 4559,
                                                            "parentRowNumber": 4536,
                                                            "articleId": "47130595",
                                                            "description": "47130595-00-Einlaufformat",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.7845,
                                                            "childrenWeight": 0.7845,
                                                            "totalQuantityWeight": 0.7845,
                                                            "singleQuantityMcf": 2.4724480000000004,
                                                            "mcf": 2.4724480000000004,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 2.4724480000000004,
                                                            "expandable": false,
                                                            "children": [
                                                                {
                                                                    "rowNumber": 4560,
                                                                    "parentRowNumber": 4559,
                                                                    "articleId": "162544",
                                                                    "description": "162544-01-Platte",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.7705,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.7705,
                                                                    "singleQuantityMcf": 2.4656,
                                                                    "mcf": 2.4656,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 2.4656,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4561,
                                                                    "parentRowNumber": 4559,
                                                                    "articleId": "275104",
                                                                    "description": "275104-00-DU-Buchse",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.001,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.001,
                                                                    "singleQuantityMcf": 0.001712,
                                                                    "mcf": 0.001712,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.001712,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4562,
                                                                    "parentRowNumber": 4559,
                                                                    "articleId": "275104",
                                                                    "description": "275104-00-DU-Buchse",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.001,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.001,
                                                                    "singleQuantityMcf": 0.001712,
                                                                    "mcf": 0.001712,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.001712,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4563,
                                                                    "parentRowNumber": 4559,
                                                                    "articleId": "275104",
                                                                    "description": "275104-00-DU-Buchse",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.001,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.001,
                                                                    "singleQuantityMcf": 0.001712,
                                                                    "mcf": 0.001712,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.001712,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4564,
                                                                    "parentRowNumber": 4559,
                                                                    "articleId": "275104",
                                                                    "description": "275104-00-DU-Buchse",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.001,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.001,
                                                                    "singleQuantityMcf": 0.001712,
                                                                    "mcf": 0.001712,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.001712,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4565,
                                                                    "parentRowNumber": 4559,
                                                                    "articleId": "347200",
                                                                    "description": "347200-00-Ensat-Gewindebuchse",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0025,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0025,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4566,
                                                                    "parentRowNumber": 4559,
                                                                    "articleId": "347200",
                                                                    "description": "347200-00-Ensat-Gewindebuchse",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0025,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0025,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4567,
                                                                    "parentRowNumber": 4559,
                                                                    "articleId": "347200",
                                                                    "description": "347200-00-Ensat-Gewindebuchse",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0025,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0025,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4568,
                                                                    "parentRowNumber": 4559,
                                                                    "articleId": "347200",
                                                                    "description": "347200-00-Ensat-Gewindebuchse",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0025,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0025,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": []
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            "rowNumber": 4569,
                                                            "parentRowNumber": 4536,
                                                            "articleId": "47130621",
                                                            "description": "47130621-00-Distanz",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0112,
                                                            "childrenWeight": 0.0112,
                                                            "totalQuantityWeight": 0.0112,
                                                            "singleQuantityMcf": 0,
                                                            "mcf": 0,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0,
                                                            "expandable": false,
                                                            "children": [
                                                                {
                                                                    "rowNumber": 4570,
                                                                    "parentRowNumber": 4569,
                                                                    "articleId": "1232015",
                                                                    "description": "1232015-00-Rundstange",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0112,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0112,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": []
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            "rowNumber": 4571,
                                                            "parentRowNumber": 4536,
                                                            "articleId": "47130621",
                                                            "description": "47130621-00-Distanz",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0112,
                                                            "childrenWeight": 0.0112,
                                                            "totalQuantityWeight": 0.0112,
                                                            "singleQuantityMcf": 0,
                                                            "mcf": 0,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0,
                                                            "expandable": false,
                                                            "children": [
                                                                {
                                                                    "rowNumber": 4572,
                                                                    "parentRowNumber": 4571,
                                                                    "articleId": "1232015",
                                                                    "description": "1232015-00-Rundstange",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0112,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0112,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": []
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            "rowNumber": 4573,
                                                            "parentRowNumber": 4536,
                                                            "articleId": "47130634",
                                                            "description": "47130634-01-Schnecke",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 11.0047,
                                                            "childrenWeight": 11.0047,
                                                            "totalQuantityWeight": 11.0047,
                                                            "singleQuantityMcf": 38.199712,
                                                            "mcf": 38.199712,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 38.199712,
                                                            "expandable": false,
                                                            "children": [
                                                                {
                                                                    "rowNumber": 4574,
                                                                    "parentRowNumber": 4573,
                                                                    "articleId": "1617090",
                                                                    "description": "1617090-00-Rundstab",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 9.3759,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 9.3759,
                                                                    "singleQuantityMcf": 30.00288,
                                                                    "mcf": 30.00288,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 30.00288,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4575,
                                                                    "parentRowNumber": 4573,
                                                                    "articleId": "21020450",
                                                                    "description": "21020450-00-Kegelstift",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0064,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0064,
                                                                    "singleQuantityMcf": 0.033152,
                                                                    "mcf": 0.033152,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.033152,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4576,
                                                                    "parentRowNumber": 4573,
                                                                    "articleId": "347205",
                                                                    "description": "347205-00-Ensat-Gewindebuchse",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0058,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0058,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4577,
                                                                    "parentRowNumber": 4573,
                                                                    "articleId": "347205",
                                                                    "description": "347205-00-Ensat-Gewindebuchse",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0058,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0058,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4578,
                                                                    "parentRowNumber": 4573,
                                                                    "articleId": "347205",
                                                                    "description": "347205-00-Ensat-Gewindebuchse",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0058,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0058,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4579,
                                                                    "parentRowNumber": 4573,
                                                                    "articleId": "347205",
                                                                    "description": "347205-00-Ensat-Gewindebuchse",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0058,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0058,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4580,
                                                                    "parentRowNumber": 4573,
                                                                    "articleId": "347205",
                                                                    "description": "347205-00-Ensat-Gewindebuchse",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0058,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0058,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4581,
                                                                    "parentRowNumber": 4573,
                                                                    "articleId": "347205",
                                                                    "description": "347205-00-Ensat-Gewindebuchse",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0058,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0058,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4582,
                                                                    "parentRowNumber": 4573,
                                                                    "articleId": "347205",
                                                                    "description": "347205-00-Ensat-Gewindebuchse",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0058,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0058,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4583,
                                                                    "parentRowNumber": 4573,
                                                                    "articleId": "347205",
                                                                    "description": "347205-00-Ensat-Gewindebuchse",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0058,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0058,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4584,
                                                                    "parentRowNumber": 4573,
                                                                    "articleId": "47130635",
                                                                    "description": "47130635-00-Welle",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 1.576,
                                                                    "childrenWeight": 1.576,
                                                                    "totalQuantityWeight": 1.576,
                                                                    "singleQuantityMcf": 8.16368,
                                                                    "mcf": 8.16368,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 8.16368,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 4585,
                                                                            "parentRowNumber": 4584,
                                                                            "articleId": "1260020",
                                                                            "description": "1260020-00-Rundstahl",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 1.576,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 1.576,
                                                                            "singleQuantityMcf": 8.16368,
                                                                            "mcf": 8.16368,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 8.16368,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            "rowNumber": 4586,
                                                            "parentRowNumber": 4536,
                                                            "articleId": "ESA1025",
                                                            "description": "ESA1025-B-807,20-01-Kulisse",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.1276,
                                                            "childrenWeight": 0.1276,
                                                            "totalQuantityWeight": 0.1276,
                                                            "singleQuantityMcf": 0.660968,
                                                            "mcf": 0.660968,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.660968,
                                                            "expandable": false,
                                                            "children": [
                                                                {
                                                                    "rowNumber": 4587,
                                                                    "parentRowNumber": 4586,
                                                                    "articleId": "20220620",
                                                                    "description": "20220620-00-Zylinderschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0063,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0063,
                                                                    "singleQuantityMcf": 0.032634,
                                                                    "mcf": 0.032634,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.032634,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4588,
                                                                    "parentRowNumber": 4586,
                                                                    "articleId": "21820420",
                                                                    "description": "21820420-00-Passkerbstift",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4589,
                                                                    "parentRowNumber": 4586,
                                                                    "articleId": "453061",
                                                                    "description": "453061-05-Kulisse",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.1213,
                                                                    "childrenWeight": 0.1213,
                                                                    "totalQuantityWeight": 0.1213,
                                                                    "singleQuantityMcf": 0.628334,
                                                                    "mcf": 0.628334,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.628334,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 4590,
                                                                            "parentRowNumber": 4589,
                                                                            "articleId": "1260055",
                                                                            "description": "1260055-00-Rundstahl",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.1213,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.1213,
                                                                            "singleQuantityMcf": 0.628334,
                                                                            "mcf": 0.628334,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.628334,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            "rowNumber": 4591,
                                                            "parentRowNumber": 4536,
                                                            "articleId": "ESA1025",
                                                            "description": "ESA1025-B-817,10-01-Druckfeder",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0,
                                                            "singleQuantityMcf": 0,
                                                            "mcf": 0,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0,
                                                            "expandable": false,
                                                            "children": [
                                                                {
                                                                    "rowNumber": 4592,
                                                                    "parentRowNumber": 4591,
                                                                    "articleId": "334392",
                                                                    "description": "334392-01-Druckfeder",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": []
                                                                }
                                                            ]
                                                        }
                                                    ]
                                                }
                                            ]
                                        },
                                        {
                                            "rowNumber": 4593,
                                            "parentRowNumber": 4194,
                                            "articleId": "33847",
                                            "description": "33847-20SLG5-00-Schnecke",
                                            "hierarchy": 4,
                                            "quantity": 1,
                                            "weight": 8.382499999999999,
                                            "childrenWeight": 8.382499999999999,
                                            "totalQuantityWeight": 8.382499999999999,
                                            "singleQuantityMcf": 27.464917,
                                            "mcf": 27.464917,
                                            "singleQuantityLcf": 0,
                                            "lcf": 0,
                                            "totalQuantityMcf": 27.464917,
                                            "expandable": false,
                                            "children": [
                                                {
                                                    "rowNumber": 4594,
                                                    "parentRowNumber": 4593,
                                                    "articleId": "MM0000007133",
                                                    "description": "MM0000007133-00-Schnecke",
                                                    "hierarchy": 5,
                                                    "quantity": 1,
                                                    "weight": 8.382499999999999,
                                                    "childrenWeight": 8.382499999999999,
                                                    "totalQuantityWeight": 8.382499999999999,
                                                    "singleQuantityMcf": 27.464917,
                                                    "mcf": 27.464917,
                                                    "singleQuantityLcf": 0,
                                                    "lcf": 0,
                                                    "totalQuantityMcf": 27.464917,
                                                    "expandable": false,
                                                    "children": [
                                                        {
                                                            "rowNumber": 4595,
                                                            "parentRowNumber": 4594,
                                                            "articleId": "20220615",
                                                            "description": "20220615-00-Zylinderschraube",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0054,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0054,
                                                            "singleQuantityMcf": 0.027972,
                                                            "mcf": 0.027972,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.027972,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 4596,
                                                            "parentRowNumber": 4594,
                                                            "articleId": "20220615",
                                                            "description": "20220615-00-Zylinderschraube",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0054,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0054,
                                                            "singleQuantityMcf": 0.027972,
                                                            "mcf": 0.027972,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.027972,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 4597,
                                                            "parentRowNumber": 4594,
                                                            "articleId": "29216000",
                                                            "description": "29216000-00-Radial-Rillenkugellager",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.025,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.025,
                                                            "singleQuantityMcf": 0.1295,
                                                            "mcf": 0.1295,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.1295,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 4598,
                                                            "parentRowNumber": 4594,
                                                            "articleId": "440117",
                                                            "description": "440117-01-Lagerschale",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.054,
                                                            "childrenWeight": 0.054,
                                                            "totalQuantityWeight": 0.054,
                                                            "singleQuantityMcf": 0.27972,
                                                            "mcf": 0.27972,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.27972,
                                                            "expandable": false,
                                                            "children": [
                                                                {
                                                                    "rowNumber": 4599,
                                                                    "parentRowNumber": 4598,
                                                                    "articleId": "1260035",
                                                                    "description": "1260035-00-Rundstahl",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.054,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.054,
                                                                    "singleQuantityMcf": 0.27972,
                                                                    "mcf": 0.27972,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.27972,
                                                                    "expandable": false,
                                                                    "children": []
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            "rowNumber": 4600,
                                                            "parentRowNumber": 4594,
                                                            "articleId": "47167433",
                                                            "description": "47167433-00-Objektfuehrung",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 4.415499999999998,
                                                            "childrenWeight": 4.415499999999998,
                                                            "totalQuantityWeight": 4.415499999999998,
                                                            "singleQuantityMcf": 14.734945000000002,
                                                            "mcf": 14.734945000000002,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 14.734945000000002,
                                                            "expandable": false,
                                                            "children": [
                                                                {
                                                                    "rowNumber": 4601,
                                                                    "parentRowNumber": 4600,
                                                                    "articleId": "20120406",
                                                                    "description": "20120406-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0015,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0015,
                                                                    "singleQuantityMcf": 0.00777,
                                                                    "mcf": 0.00777,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.00777,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4602,
                                                                    "parentRowNumber": 4600,
                                                                    "articleId": "20120406",
                                                                    "description": "20120406-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0015,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0015,
                                                                    "singleQuantityMcf": 0.00777,
                                                                    "mcf": 0.00777,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.00777,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4603,
                                                                    "parentRowNumber": 4600,
                                                                    "articleId": "20120512",
                                                                    "description": "20120512-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0033,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0033,
                                                                    "singleQuantityMcf": 0.017093999999999998,
                                                                    "mcf": 0.017093999999999998,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.017093999999999998,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4604,
                                                                    "parentRowNumber": 4600,
                                                                    "articleId": "20120512",
                                                                    "description": "20120512-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0033,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0033,
                                                                    "singleQuantityMcf": 0.017093999999999998,
                                                                    "mcf": 0.017093999999999998,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.017093999999999998,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4605,
                                                                    "parentRowNumber": 4600,
                                                                    "articleId": "20120540",
                                                                    "description": "20120540-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0077,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0077,
                                                                    "singleQuantityMcf": 0.039886,
                                                                    "mcf": 0.039886,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.039886,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4606,
                                                                    "parentRowNumber": 4600,
                                                                    "articleId": "20120540",
                                                                    "description": "20120540-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0077,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0077,
                                                                    "singleQuantityMcf": 0.039886,
                                                                    "mcf": 0.039886,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.039886,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4607,
                                                                    "parentRowNumber": 4600,
                                                                    "articleId": "20120540",
                                                                    "description": "20120540-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0077,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0077,
                                                                    "singleQuantityMcf": 0.039886,
                                                                    "mcf": 0.039886,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.039886,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4608,
                                                                    "parentRowNumber": 4600,
                                                                    "articleId": "20120540",
                                                                    "description": "20120540-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0077,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0077,
                                                                    "singleQuantityMcf": 0.039886,
                                                                    "mcf": 0.039886,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.039886,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4609,
                                                                    "parentRowNumber": 4600,
                                                                    "articleId": "20120540",
                                                                    "description": "20120540-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0077,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0077,
                                                                    "singleQuantityMcf": 0.039886,
                                                                    "mcf": 0.039886,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.039886,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4610,
                                                                    "parentRowNumber": 4600,
                                                                    "articleId": "20120540",
                                                                    "description": "20120540-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0077,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0077,
                                                                    "singleQuantityMcf": 0.039886,
                                                                    "mcf": 0.039886,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.039886,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4611,
                                                                    "parentRowNumber": 4600,
                                                                    "articleId": "20120540",
                                                                    "description": "20120540-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0077,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0077,
                                                                    "singleQuantityMcf": 0.039886,
                                                                    "mcf": 0.039886,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.039886,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4612,
                                                                    "parentRowNumber": 4600,
                                                                    "articleId": "20120540",
                                                                    "description": "20120540-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0077,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0077,
                                                                    "singleQuantityMcf": 0.039886,
                                                                    "mcf": 0.039886,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.039886,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4613,
                                                                    "parentRowNumber": 4600,
                                                                    "articleId": "20120680",
                                                                    "description": "20120680-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0207,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0207,
                                                                    "singleQuantityMcf": 0.107226,
                                                                    "mcf": 0.107226,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.107226,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4614,
                                                                    "parentRowNumber": 4600,
                                                                    "articleId": "20220425",
                                                                    "description": "20220425-00-Zylinderschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0032,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0032,
                                                                    "singleQuantityMcf": 0.016576,
                                                                    "mcf": 0.016576,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.016576,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4615,
                                                                    "parentRowNumber": 4600,
                                                                    "articleId": "20220425",
                                                                    "description": "20220425-00-Zylinderschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0032,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0032,
                                                                    "singleQuantityMcf": 0.016576,
                                                                    "mcf": 0.016576,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.016576,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4616,
                                                                    "parentRowNumber": 4600,
                                                                    "articleId": "20220530",
                                                                    "description": "20220530-00-Zylinderschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0059,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0059,
                                                                    "singleQuantityMcf": 0.030562,
                                                                    "mcf": 0.030562,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.030562,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4617,
                                                                    "parentRowNumber": 4600,
                                                                    "articleId": "20420512",
                                                                    "description": "20420512-00-Senkschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0022,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0022,
                                                                    "singleQuantityMcf": 0.011396,
                                                                    "mcf": 0.011396,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.011396,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4618,
                                                                    "parentRowNumber": 4600,
                                                                    "articleId": "20420512",
                                                                    "description": "20420512-00-Senkschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0022,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0022,
                                                                    "singleQuantityMcf": 0.011396,
                                                                    "mcf": 0.011396,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.011396,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4619,
                                                                    "parentRowNumber": 4600,
                                                                    "articleId": "205205",
                                                                    "description": "205205-00-Sechskantmutter",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0013,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0013,
                                                                    "singleQuantityMcf": 0.006734,
                                                                    "mcf": 0.006734,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.006734,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4620,
                                                                    "parentRowNumber": 4600,
                                                                    "articleId": "205206",
                                                                    "description": "205206-00-Sechskantmutter",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0026,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0026,
                                                                    "singleQuantityMcf": 0.013468,
                                                                    "mcf": 0.013468,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.013468,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4621,
                                                                    "parentRowNumber": 4600,
                                                                    "articleId": "208204",
                                                                    "description": "208204-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0003,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0003,
                                                                    "singleQuantityMcf": 0.0015539999999999998,
                                                                    "mcf": 0.0015539999999999998,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.0015539999999999998,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4622,
                                                                    "parentRowNumber": 4600,
                                                                    "articleId": "208204",
                                                                    "description": "208204-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0003,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0003,
                                                                    "singleQuantityMcf": 0.0015539999999999998,
                                                                    "mcf": 0.0015539999999999998,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.0015539999999999998,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4623,
                                                                    "parentRowNumber": 4600,
                                                                    "articleId": "208205",
                                                                    "description": "208205-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0004,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0004,
                                                                    "singleQuantityMcf": 0.002072,
                                                                    "mcf": 0.002072,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.002072,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4624,
                                                                    "parentRowNumber": 4600,
                                                                    "articleId": "208205",
                                                                    "description": "208205-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0004,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0004,
                                                                    "singleQuantityMcf": 0.002072,
                                                                    "mcf": 0.002072,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.002072,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4625,
                                                                    "parentRowNumber": 4600,
                                                                    "articleId": "208405",
                                                                    "description": "208405-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0015,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0015,
                                                                    "singleQuantityMcf": 0.00777,
                                                                    "mcf": 0.00777,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.00777,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4626,
                                                                    "parentRowNumber": 4600,
                                                                    "articleId": "208405",
                                                                    "description": "208405-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0015,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0015,
                                                                    "singleQuantityMcf": 0.00777,
                                                                    "mcf": 0.00777,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.00777,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4627,
                                                                    "parentRowNumber": 4600,
                                                                    "articleId": "208405",
                                                                    "description": "208405-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0015,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0015,
                                                                    "singleQuantityMcf": 0.00777,
                                                                    "mcf": 0.00777,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.00777,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4628,
                                                                    "parentRowNumber": 4600,
                                                                    "articleId": "208405",
                                                                    "description": "208405-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0015,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0015,
                                                                    "singleQuantityMcf": 0.00777,
                                                                    "mcf": 0.00777,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.00777,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4629,
                                                                    "parentRowNumber": 4600,
                                                                    "articleId": "208405",
                                                                    "description": "208405-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0015,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0015,
                                                                    "singleQuantityMcf": 0.00777,
                                                                    "mcf": 0.00777,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.00777,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4630,
                                                                    "parentRowNumber": 4600,
                                                                    "articleId": "208405",
                                                                    "description": "208405-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0015,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0015,
                                                                    "singleQuantityMcf": 0.00777,
                                                                    "mcf": 0.00777,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.00777,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4631,
                                                                    "parentRowNumber": 4600,
                                                                    "articleId": "208405",
                                                                    "description": "208405-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0015,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0015,
                                                                    "singleQuantityMcf": 0.00777,
                                                                    "mcf": 0.00777,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.00777,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4632,
                                                                    "parentRowNumber": 4600,
                                                                    "articleId": "208405",
                                                                    "description": "208405-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0015,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0015,
                                                                    "singleQuantityMcf": 0.00777,
                                                                    "mcf": 0.00777,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.00777,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4633,
                                                                    "parentRowNumber": 4600,
                                                                    "articleId": "22070610",
                                                                    "description": "22070610-00-Fluegelschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0237,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0237,
                                                                    "singleQuantityMcf": 0.041001,
                                                                    "mcf": 0.041001,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.041001,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4634,
                                                                    "parentRowNumber": 4600,
                                                                    "articleId": "22070610",
                                                                    "description": "22070610-00-Fluegelschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0237,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0237,
                                                                    "singleQuantityMcf": 0.041001,
                                                                    "mcf": 0.041001,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.041001,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4635,
                                                                    "parentRowNumber": 4600,
                                                                    "articleId": "22070625",
                                                                    "description": "22070625-00-Fluegelschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0271,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0271,
                                                                    "singleQuantityMcf": 0.046883,
                                                                    "mcf": 0.046883,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.046883,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4636,
                                                                    "parentRowNumber": 4600,
                                                                    "articleId": "334195",
                                                                    "description": "334195-00-Druckfeder",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0023,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0023,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4637,
                                                                    "parentRowNumber": 4600,
                                                                    "articleId": "334195",
                                                                    "description": "334195-00-Druckfeder",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0023,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0023,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4638,
                                                                    "parentRowNumber": 4600,
                                                                    "articleId": "46591089",
                                                                    "description": "46591089-00-Sechskantmutter",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0003,
                                                                    "childrenWeight": 0.0003,
                                                                    "totalQuantityWeight": 0.0003,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 4639,
                                                                            "parentRowNumber": 4638,
                                                                            "articleId": "1615018",
                                                                            "description": "1615018-00-Rundstab",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0003,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0003,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 4640,
                                                                    "parentRowNumber": 4600,
                                                                    "articleId": "46645927",
                                                                    "description": "46645927-00-Formatbuegel 2.0",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.1103,
                                                                    "childrenWeight": 0.1103,
                                                                    "totalQuantityWeight": 0.1103,
                                                                    "singleQuantityMcf": 0.5713539999999999,
                                                                    "mcf": 0.5713539999999999,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.5713539999999999,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 4641,
                                                                            "parentRowNumber": 4640,
                                                                            "articleId": "142612",
                                                                            "description": "142612-00-Edelstahlblech",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0515,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0515,
                                                                            "singleQuantityMcf": 0.26676999999999995,
                                                                            "mcf": 0.26676999999999995,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.26676999999999995,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        },
                                                                        {
                                                                            "rowNumber": 4642,
                                                                            "parentRowNumber": 4640,
                                                                            "articleId": "46647477",
                                                                            "description": "46647477-00-Halter",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0294,
                                                                            "childrenWeight": 0.0294,
                                                                            "totalQuantityWeight": 0.0294,
                                                                            "singleQuantityMcf": 0.15229199999999998,
                                                                            "mcf": 0.15229199999999998,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.15229199999999998,
                                                                            "expandable": false,
                                                                            "children": [
                                                                                {
                                                                                    "rowNumber": 4643,
                                                                                    "parentRowNumber": 4642,
                                                                                    "articleId": "1110231",
                                                                                    "description": "1110231-00-Flachstahl",
                                                                                    "hierarchy": 9,
                                                                                    "quantity": 1,
                                                                                    "weight": 0.0294,
                                                                                    "childrenWeight": 0,
                                                                                    "totalQuantityWeight": 0.0294,
                                                                                    "singleQuantityMcf": 0.15229199999999998,
                                                                                    "mcf": 0.15229199999999998,
                                                                                    "singleQuantityLcf": 0,
                                                                                    "lcf": 0,
                                                                                    "totalQuantityMcf": 0.15229199999999998,
                                                                                    "expandable": false,
                                                                                    "children": []
                                                                                }
                                                                            ]
                                                                        },
                                                                        {
                                                                            "rowNumber": 4644,
                                                                            "parentRowNumber": 4640,
                                                                            "articleId": "46647477",
                                                                            "description": "46647477-00-Halter",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0294,
                                                                            "childrenWeight": 0.0294,
                                                                            "totalQuantityWeight": 0.0294,
                                                                            "singleQuantityMcf": 0.15229199999999998,
                                                                            "mcf": 0.15229199999999998,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.15229199999999998,
                                                                            "expandable": false,
                                                                            "children": [
                                                                                {
                                                                                    "rowNumber": 4645,
                                                                                    "parentRowNumber": 4644,
                                                                                    "articleId": "1110231",
                                                                                    "description": "1110231-00-Flachstahl",
                                                                                    "hierarchy": 9,
                                                                                    "quantity": 1,
                                                                                    "weight": 0.0294,
                                                                                    "childrenWeight": 0,
                                                                                    "totalQuantityWeight": 0.0294,
                                                                                    "singleQuantityMcf": 0.15229199999999998,
                                                                                    "mcf": 0.15229199999999998,
                                                                                    "singleQuantityLcf": 0,
                                                                                    "lcf": 0,
                                                                                    "totalQuantityMcf": 0.15229199999999998,
                                                                                    "expandable": false,
                                                                                    "children": []
                                                                                }
                                                                            ]
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 4646,
                                                                    "parentRowNumber": 4600,
                                                                    "articleId": "46645927",
                                                                    "description": "46645927-00-Formatbuegel 2.0",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.1103,
                                                                    "childrenWeight": 0.1103,
                                                                    "totalQuantityWeight": 0.1103,
                                                                    "singleQuantityMcf": 0.5713539999999999,
                                                                    "mcf": 0.5713539999999999,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.5713539999999999,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 4647,
                                                                            "parentRowNumber": 4646,
                                                                            "articleId": "142612",
                                                                            "description": "142612-00-Edelstahlblech",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0515,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0515,
                                                                            "singleQuantityMcf": 0.26676999999999995,
                                                                            "mcf": 0.26676999999999995,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.26676999999999995,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        },
                                                                        {
                                                                            "rowNumber": 4648,
                                                                            "parentRowNumber": 4646,
                                                                            "articleId": "46647477",
                                                                            "description": "46647477-00-Halter",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0294,
                                                                            "childrenWeight": 0.0294,
                                                                            "totalQuantityWeight": 0.0294,
                                                                            "singleQuantityMcf": 0.15229199999999998,
                                                                            "mcf": 0.15229199999999998,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.15229199999999998,
                                                                            "expandable": false,
                                                                            "children": [
                                                                                {
                                                                                    "rowNumber": 4649,
                                                                                    "parentRowNumber": 4648,
                                                                                    "articleId": "1110231",
                                                                                    "description": "1110231-00-Flachstahl",
                                                                                    "hierarchy": 9,
                                                                                    "quantity": 1,
                                                                                    "weight": 0.0294,
                                                                                    "childrenWeight": 0,
                                                                                    "totalQuantityWeight": 0.0294,
                                                                                    "singleQuantityMcf": 0.15229199999999998,
                                                                                    "mcf": 0.15229199999999998,
                                                                                    "singleQuantityLcf": 0,
                                                                                    "lcf": 0,
                                                                                    "totalQuantityMcf": 0.15229199999999998,
                                                                                    "expandable": false,
                                                                                    "children": []
                                                                                }
                                                                            ]
                                                                        },
                                                                        {
                                                                            "rowNumber": 4650,
                                                                            "parentRowNumber": 4646,
                                                                            "articleId": "46647477",
                                                                            "description": "46647477-00-Halter",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0294,
                                                                            "childrenWeight": 0.0294,
                                                                            "totalQuantityWeight": 0.0294,
                                                                            "singleQuantityMcf": 0.15229199999999998,
                                                                            "mcf": 0.15229199999999998,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.15229199999999998,
                                                                            "expandable": false,
                                                                            "children": [
                                                                                {
                                                                                    "rowNumber": 4651,
                                                                                    "parentRowNumber": 4650,
                                                                                    "articleId": "1110231",
                                                                                    "description": "1110231-00-Flachstahl",
                                                                                    "hierarchy": 9,
                                                                                    "quantity": 1,
                                                                                    "weight": 0.0294,
                                                                                    "childrenWeight": 0,
                                                                                    "totalQuantityWeight": 0.0294,
                                                                                    "singleQuantityMcf": 0.15229199999999998,
                                                                                    "mcf": 0.15229199999999998,
                                                                                    "singleQuantityLcf": 0,
                                                                                    "lcf": 0,
                                                                                    "totalQuantityMcf": 0.15229199999999998,
                                                                                    "expandable": false,
                                                                                    "children": []
                                                                                }
                                                                            ]
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 4652,
                                                                    "parentRowNumber": 4600,
                                                                    "articleId": "47122726",
                                                                    "description": "47122726-00-Fuehrung",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0284,
                                                                    "childrenWeight": 0.0284,
                                                                    "totalQuantityWeight": 0.0284,
                                                                    "singleQuantityMcf": 0.09088000000000002,
                                                                    "mcf": 0.09088000000000002,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.09088000000000002,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 4653,
                                                                            "parentRowNumber": 4652,
                                                                            "articleId": "162532",
                                                                            "description": "162532-01-Platte",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0284,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0284,
                                                                            "singleQuantityMcf": 0.09088000000000002,
                                                                            "mcf": 0.09088000000000002,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.09088000000000002,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 4654,
                                                                    "parentRowNumber": 4600,
                                                                    "articleId": "47122750",
                                                                    "description": "47122750-00-Fuehrung",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0242,
                                                                    "childrenWeight": 0.0242,
                                                                    "totalQuantityWeight": 0.0242,
                                                                    "singleQuantityMcf": 0.07744000000000001,
                                                                    "mcf": 0.07744000000000001,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.07744000000000001,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 4655,
                                                                            "parentRowNumber": 4654,
                                                                            "articleId": "162532",
                                                                            "description": "162532-01-Platte",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0242,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0242,
                                                                            "singleQuantityMcf": 0.07744000000000001,
                                                                            "mcf": 0.07744000000000001,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.07744000000000001,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 4656,
                                                                    "parentRowNumber": 4600,
                                                                    "articleId": "47157176",
                                                                    "description": "47157176-00-Fuehrung",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 1.1087,
                                                                    "childrenWeight": 1.1087,
                                                                    "totalQuantityWeight": 1.1087,
                                                                    "singleQuantityMcf": 3.5478400000000003,
                                                                    "mcf": 3.5478400000000003,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 3.5478400000000003,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 4657,
                                                                            "parentRowNumber": 4656,
                                                                            "articleId": "162544",
                                                                            "description": "162544-01-Platte",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 1.1087,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 1.1087,
                                                                            "singleQuantityMcf": 3.5478400000000003,
                                                                            "mcf": 3.5478400000000003,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 3.5478400000000003,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 4658,
                                                                    "parentRowNumber": 4600,
                                                                    "articleId": "47159191",
                                                                    "description": "47159191-00-Fuehrung",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.639,
                                                                    "childrenWeight": 0.639,
                                                                    "totalQuantityWeight": 0.639,
                                                                    "singleQuantityMcf": 2.01344,
                                                                    "mcf": 2.01344,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 2.01344,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 4659,
                                                                            "parentRowNumber": 4658,
                                                                            "articleId": "162544",
                                                                            "description": "162544-01-Platte",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.6292,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.6292,
                                                                            "singleQuantityMcf": 2.01344,
                                                                            "mcf": 2.01344,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 2.01344,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        },
                                                                        {
                                                                            "rowNumber": 4660,
                                                                            "parentRowNumber": 4658,
                                                                            "articleId": "347204",
                                                                            "description": "347204-00-Ensat-Gewindebuchse",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0049,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0049,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        },
                                                                        {
                                                                            "rowNumber": 4661,
                                                                            "parentRowNumber": 4658,
                                                                            "articleId": "347204",
                                                                            "description": "347204-00-Ensat-Gewindebuchse",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0049,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0049,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 4662,
                                                                    "parentRowNumber": 4600,
                                                                    "articleId": "47159215",
                                                                    "description": "47159215-00-Einlaufformat",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 1.9734,
                                                                    "childrenWeight": 1.9734,
                                                                    "totalQuantityWeight": 1.9734,
                                                                    "singleQuantityMcf": 6.260680000000001,
                                                                    "mcf": 6.260680000000001,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 6.260680000000001,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 4663,
                                                                            "parentRowNumber": 4662,
                                                                            "articleId": "162544",
                                                                            "description": "162544-01-Platte",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 1.942,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 1.942,
                                                                            "singleQuantityMcf": 6.2144,
                                                                            "mcf": 6.2144,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 6.2144,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        },
                                                                        {
                                                                            "rowNumber": 4664,
                                                                            "parentRowNumber": 4662,
                                                                            "articleId": "20440410",
                                                                            "description": "20440410-00-Senkschraube",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0012,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0012,
                                                                            "singleQuantityMcf": 0.006215999999999999,
                                                                            "mcf": 0.006215999999999999,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.006215999999999999,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        },
                                                                        {
                                                                            "rowNumber": 4665,
                                                                            "parentRowNumber": 4662,
                                                                            "articleId": "20440410",
                                                                            "description": "20440410-00-Senkschraube",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0012,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0012,
                                                                            "singleQuantityMcf": 0.006215999999999999,
                                                                            "mcf": 0.006215999999999999,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.006215999999999999,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        },
                                                                        {
                                                                            "rowNumber": 4666,
                                                                            "parentRowNumber": 4662,
                                                                            "articleId": "21220308",
                                                                            "description": "21220308-00-Zylinderstift",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0005,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0005,
                                                                            "singleQuantityMcf": 0.00259,
                                                                            "mcf": 0.00259,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.00259,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        },
                                                                        {
                                                                            "rowNumber": 4667,
                                                                            "parentRowNumber": 4662,
                                                                            "articleId": "21220308",
                                                                            "description": "21220308-00-Zylinderstift",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0005,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0005,
                                                                            "singleQuantityMcf": 0.00259,
                                                                            "mcf": 0.00259,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.00259,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        },
                                                                        {
                                                                            "rowNumber": 4668,
                                                                            "parentRowNumber": 4662,
                                                                            "articleId": "272252",
                                                                            "description": "272252-00-Gleitlager",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0004,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0004,
                                                                            "singleQuantityMcf": 0.000692,
                                                                            "mcf": 0.000692,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.000692,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        },
                                                                        {
                                                                            "rowNumber": 4669,
                                                                            "parentRowNumber": 4662,
                                                                            "articleId": "272252",
                                                                            "description": "272252-00-Gleitlager",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0004,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0004,
                                                                            "singleQuantityMcf": 0.000692,
                                                                            "mcf": 0.000692,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.000692,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        },
                                                                        {
                                                                            "rowNumber": 4670,
                                                                            "parentRowNumber": 4662,
                                                                            "articleId": "272252",
                                                                            "description": "272252-00-Gleitlager",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0004,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0004,
                                                                            "singleQuantityMcf": 0.000692,
                                                                            "mcf": 0.000692,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.000692,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        },
                                                                        {
                                                                            "rowNumber": 4671,
                                                                            "parentRowNumber": 4662,
                                                                            "articleId": "272252",
                                                                            "description": "272252-00-Gleitlager",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0004,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0004,
                                                                            "singleQuantityMcf": 0.000692,
                                                                            "mcf": 0.000692,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.000692,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        },
                                                                        {
                                                                            "rowNumber": 4672,
                                                                            "parentRowNumber": 4662,
                                                                            "articleId": "2925695",
                                                                            "description": "2925695-00-Radial-Rillenkugellager",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0025,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0025,
                                                                            "singleQuantityMcf": 0.01295,
                                                                            "mcf": 0.01295,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.01295,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        },
                                                                        {
                                                                            "rowNumber": 4673,
                                                                            "parentRowNumber": 4662,
                                                                            "articleId": "2925695",
                                                                            "description": "2925695-00-Radial-Rillenkugellager",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0025,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0025,
                                                                            "singleQuantityMcf": 0.01295,
                                                                            "mcf": 0.01295,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.01295,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        },
                                                                        {
                                                                            "rowNumber": 4674,
                                                                            "parentRowNumber": 4662,
                                                                            "articleId": "347195",
                                                                            "description": "347195-00-Ensat-Gewindebuchse",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0014,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0014,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        },
                                                                        {
                                                                            "rowNumber": 4675,
                                                                            "parentRowNumber": 4662,
                                                                            "articleId": "347195",
                                                                            "description": "347195-00-Ensat-Gewindebuchse",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0014,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0014,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        },
                                                                        {
                                                                            "rowNumber": 4676,
                                                                            "parentRowNumber": 4662,
                                                                            "articleId": "347195",
                                                                            "description": "347195-00-Ensat-Gewindebuchse",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0014,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0014,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        },
                                                                        {
                                                                            "rowNumber": 4677,
                                                                            "parentRowNumber": 4662,
                                                                            "articleId": "347195",
                                                                            "description": "347195-00-Ensat-Gewindebuchse",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0014,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0014,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        },
                                                                        {
                                                                            "rowNumber": 4678,
                                                                            "parentRowNumber": 4662,
                                                                            "articleId": "347200",
                                                                            "description": "347200-00-Ensat-Gewindebuchse",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0025,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0025,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        },
                                                                        {
                                                                            "rowNumber": 4679,
                                                                            "parentRowNumber": 4662,
                                                                            "articleId": "347200",
                                                                            "description": "347200-00-Ensat-Gewindebuchse",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0025,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0025,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        },
                                                                        {
                                                                            "rowNumber": 4680,
                                                                            "parentRowNumber": 4662,
                                                                            "articleId": "347200",
                                                                            "description": "347200-00-Ensat-Gewindebuchse",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0025,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0025,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        },
                                                                        {
                                                                            "rowNumber": 4681,
                                                                            "parentRowNumber": 4662,
                                                                            "articleId": "347200",
                                                                            "description": "347200-00-Ensat-Gewindebuchse",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0025,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0025,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        },
                                                                        {
                                                                            "rowNumber": 4682,
                                                                            "parentRowNumber": 4662,
                                                                            "articleId": "347205",
                                                                            "description": "347205-00-Ensat-Gewindebuchse",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0058,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0058,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 4683,
                                                                    "parentRowNumber": 4600,
                                                                    "articleId": "47161500",
                                                                    "description": "47161500-00-Klotz",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.1394,
                                                                    "childrenWeight": 0.1394,
                                                                    "totalQuantityWeight": 0.1394,
                                                                    "singleQuantityMcf": 0.42464,
                                                                    "mcf": 0.42464,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.42464,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 4684,
                                                                            "parentRowNumber": 4683,
                                                                            "articleId": "162547",
                                                                            "description": "162547-01-Platte",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.13269999999999998,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.13269999999999998,
                                                                            "singleQuantityMcf": 0.42464,
                                                                            "mcf": 0.42464,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.42464,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        },
                                                                        {
                                                                            "rowNumber": 4685,
                                                                            "parentRowNumber": 4683,
                                                                            "articleId": "347201",
                                                                            "description": "347201-00-Ensat-Gewindebuchse",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0018,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0018,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        },
                                                                        {
                                                                            "rowNumber": 4686,
                                                                            "parentRowNumber": 4683,
                                                                            "articleId": "347204",
                                                                            "description": "347204-00-Ensat-Gewindebuchse",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0049,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0049,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 4687,
                                                                    "parentRowNumber": 4600,
                                                                    "articleId": "47161509",
                                                                    "description": "47161509-00-Blech",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0333,
                                                                    "childrenWeight": 0.0333,
                                                                    "totalQuantityWeight": 0.0333,
                                                                    "singleQuantityMcf": 0.172494,
                                                                    "mcf": 0.172494,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.172494,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 4688,
                                                                            "parentRowNumber": 4687,
                                                                            "articleId": "142602",
                                                                            "description": "142602-00-Edelstahlblech",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0333,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0333,
                                                                            "singleQuantityMcf": 0.172494,
                                                                            "mcf": 0.172494,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.172494,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 4689,
                                                                    "parentRowNumber": 4600,
                                                                    "articleId": "47230819",
                                                                    "description": "47230819-00-Welle",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0216,
                                                                    "childrenWeight": 0.0216,
                                                                    "totalQuantityWeight": 0.0216,
                                                                    "singleQuantityMcf": 0.111888,
                                                                    "mcf": 0.111888,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.111888,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 4690,
                                                                            "parentRowNumber": 4689,
                                                                            "articleId": "1224008",
                                                                            "description": "1224008-00-Rundstahl",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0216,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0216,
                                                                            "singleQuantityMcf": 0.111888,
                                                                            "mcf": 0.111888,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.111888,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 4691,
                                                                    "parentRowNumber": 4600,
                                                                    "articleId": "47230819",
                                                                    "description": "47230819-00-Welle",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0216,
                                                                    "childrenWeight": 0.0216,
                                                                    "totalQuantityWeight": 0.0216,
                                                                    "singleQuantityMcf": 0.111888,
                                                                    "mcf": 0.111888,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.111888,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 4692,
                                                                            "parentRowNumber": 4691,
                                                                            "articleId": "1224008",
                                                                            "description": "1224008-00-Rundstahl",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0216,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0216,
                                                                            "singleQuantityMcf": 0.111888,
                                                                            "mcf": 0.111888,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.111888,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            "rowNumber": 4693,
                                                            "parentRowNumber": 4594,
                                                            "articleId": "47213458",
                                                            "description": "47213458-00-Schnecke",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 3.6726,
                                                            "childrenWeight": 3.6726,
                                                            "totalQuantityWeight": 3.6726,
                                                            "singleQuantityMcf": 11.60384,
                                                            "mcf": 11.60384,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 11.60384,
                                                            "expandable": false,
                                                            "children": [
                                                                {
                                                                    "rowNumber": 4694,
                                                                    "parentRowNumber": 4693,
                                                                    "articleId": "1617090",
                                                                    "description": "1617090-00-Rundstab",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 3.6262,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 3.6262,
                                                                    "singleQuantityMcf": 11.60384,
                                                                    "mcf": 11.60384,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 11.60384,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4695,
                                                                    "parentRowNumber": 4693,
                                                                    "articleId": "347205",
                                                                    "description": "347205-00-Ensat-Gewindebuchse",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0058,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0058,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4696,
                                                                    "parentRowNumber": 4693,
                                                                    "articleId": "347205",
                                                                    "description": "347205-00-Ensat-Gewindebuchse",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0058,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0058,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4697,
                                                                    "parentRowNumber": 4693,
                                                                    "articleId": "347205",
                                                                    "description": "347205-00-Ensat-Gewindebuchse",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0058,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0058,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4698,
                                                                    "parentRowNumber": 4693,
                                                                    "articleId": "347205",
                                                                    "description": "347205-00-Ensat-Gewindebuchse",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0058,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0058,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4699,
                                                                    "parentRowNumber": 4693,
                                                                    "articleId": "347205",
                                                                    "description": "347205-00-Ensat-Gewindebuchse",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0058,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0058,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4700,
                                                                    "parentRowNumber": 4693,
                                                                    "articleId": "347205",
                                                                    "description": "347205-00-Ensat-Gewindebuchse",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0058,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0058,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4701,
                                                                    "parentRowNumber": 4693,
                                                                    "articleId": "347205",
                                                                    "description": "347205-00-Ensat-Gewindebuchse",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0058,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0058,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4702,
                                                                    "parentRowNumber": 4693,
                                                                    "articleId": "347205",
                                                                    "description": "347205-00-Ensat-Gewindebuchse",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0058,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0058,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": []
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            "rowNumber": 4703,
                                                            "parentRowNumber": 4594,
                                                            "articleId": "ESA1025",
                                                            "description": "ESA1025-B-807,20-01-Kulisse",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.1276,
                                                            "childrenWeight": 0.1276,
                                                            "totalQuantityWeight": 0.1276,
                                                            "singleQuantityMcf": 0.660968,
                                                            "mcf": 0.660968,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.660968,
                                                            "expandable": false,
                                                            "children": [
                                                                {
                                                                    "rowNumber": 4704,
                                                                    "parentRowNumber": 4703,
                                                                    "articleId": "20220620",
                                                                    "description": "20220620-00-Zylinderschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0063,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0063,
                                                                    "singleQuantityMcf": 0.032634,
                                                                    "mcf": 0.032634,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.032634,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4705,
                                                                    "parentRowNumber": 4703,
                                                                    "articleId": "21820420",
                                                                    "description": "21820420-00-Passkerbstift",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4706,
                                                                    "parentRowNumber": 4703,
                                                                    "articleId": "453061",
                                                                    "description": "453061-05-Kulisse",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.1213,
                                                                    "childrenWeight": 0.1213,
                                                                    "totalQuantityWeight": 0.1213,
                                                                    "singleQuantityMcf": 0.628334,
                                                                    "mcf": 0.628334,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.628334,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 4707,
                                                                            "parentRowNumber": 4706,
                                                                            "articleId": "1260055",
                                                                            "description": "1260055-00-Rundstahl",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.1213,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.1213,
                                                                            "singleQuantityMcf": 0.628334,
                                                                            "mcf": 0.628334,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.628334,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            "rowNumber": 4708,
                                                            "parentRowNumber": 4594,
                                                            "articleId": "Objekt Serum 32679 16x38.5",
                                                            "description": "Objekt Serum 32679 16x38.5-00-Runde Flasche mit Boerdelverschluss",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0154,
                                                            "childrenWeight": 0.0154,
                                                            "totalQuantityWeight": 0.0154,
                                                            "singleQuantityMcf": 0,
                                                            "mcf": 0,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0,
                                                            "expandable": false,
                                                            "children": [
                                                                {
                                                                    "rowNumber": 4709,
                                                                    "parentRowNumber": 4708,
                                                                    "articleId": "OBJ0000000010155",
                                                                    "description": "OBJ0000000010155-00-Runde Flasche mit Boerdelverschluss",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0154,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0154,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": []
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            "rowNumber": 4710,
                                                            "parentRowNumber": 4594,
                                                            "articleId": "Objekt Serum 32679 16x38.5",
                                                            "description": "Objekt Serum 32679 16x38.5-00-Runde Flasche mit Boerdelverschluss",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0154,
                                                            "childrenWeight": 0.0154,
                                                            "totalQuantityWeight": 0.0154,
                                                            "singleQuantityMcf": 0,
                                                            "mcf": 0,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0,
                                                            "expandable": false,
                                                            "children": [
                                                                {
                                                                    "rowNumber": 4711,
                                                                    "parentRowNumber": 4710,
                                                                    "articleId": "OBJ0000000010155",
                                                                    "description": "OBJ0000000010155-00-Runde Flasche mit Boerdelverschluss",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0154,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0154,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": []
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            "rowNumber": 4712,
                                                            "parentRowNumber": 4594,
                                                            "articleId": "Objekt Serum 32679 16x38.5",
                                                            "description": "Objekt Serum 32679 16x38.5-00-Runde Flasche mit Boerdelverschluss",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0154,
                                                            "childrenWeight": 0.0154,
                                                            "totalQuantityWeight": 0.0154,
                                                            "singleQuantityMcf": 0,
                                                            "mcf": 0,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0,
                                                            "expandable": false,
                                                            "children": [
                                                                {
                                                                    "rowNumber": 4713,
                                                                    "parentRowNumber": 4712,
                                                                    "articleId": "OBJ0000000010155",
                                                                    "description": "OBJ0000000010155-00-Runde Flasche mit Boerdelverschluss",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0154,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0154,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": []
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            "rowNumber": 4714,
                                                            "parentRowNumber": 4594,
                                                            "articleId": "Objekt Serum 32679 16x38.5",
                                                            "description": "Objekt Serum 32679 16x38.5-00-Runde Flasche mit Boerdelverschluss",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0154,
                                                            "childrenWeight": 0.0154,
                                                            "totalQuantityWeight": 0.0154,
                                                            "singleQuantityMcf": 0,
                                                            "mcf": 0,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0,
                                                            "expandable": false,
                                                            "children": [
                                                                {
                                                                    "rowNumber": 4715,
                                                                    "parentRowNumber": 4714,
                                                                    "articleId": "OBJ0000000010155",
                                                                    "description": "OBJ0000000010155-00-Runde Flasche mit Boerdelverschluss",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0154,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0154,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": []
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            "rowNumber": 4716,
                                                            "parentRowNumber": 4594,
                                                            "articleId": "Objekt Serum 32679 16x38.5",
                                                            "description": "Objekt Serum 32679 16x38.5-00-Runde Flasche mit Boerdelverschluss",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0154,
                                                            "childrenWeight": 0.0154,
                                                            "totalQuantityWeight": 0.0154,
                                                            "singleQuantityMcf": 0,
                                                            "mcf": 0,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0,
                                                            "expandable": false,
                                                            "children": [
                                                                {
                                                                    "rowNumber": 4717,
                                                                    "parentRowNumber": 4716,
                                                                    "articleId": "OBJ0000000010155",
                                                                    "description": "OBJ0000000010155-00-Runde Flasche mit Boerdelverschluss",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0154,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0154,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": []
                                                                }
                                                            ]
                                                        }
                                                    ]
                                                }
                                            ]
                                        },
                                        {
                                            "rowNumber": 4718,
                                            "parentRowNumber": 4194,
                                            "articleId": "33847",
                                            "description": "33847-20SLG6-00-Schnecke",
                                            "hierarchy": 4,
                                            "quantity": 1,
                                            "weight": 8.4358,
                                            "childrenWeight": 8.4358,
                                            "totalQuantityWeight": 8.4358,
                                            "singleQuantityMcf": 25.472669,
                                            "mcf": 25.472669,
                                            "singleQuantityLcf": 0,
                                            "lcf": 0,
                                            "totalQuantityMcf": 25.472669,
                                            "expandable": false,
                                            "children": [
                                                {
                                                    "rowNumber": 4719,
                                                    "parentRowNumber": 4718,
                                                    "articleId": "MM0000009650",
                                                    "description": "MM0000009650-00-Schnecke",
                                                    "hierarchy": 5,
                                                    "quantity": 1,
                                                    "weight": 8.4358,
                                                    "childrenWeight": 8.4358,
                                                    "totalQuantityWeight": 8.4358,
                                                    "singleQuantityMcf": 25.472669,
                                                    "mcf": 25.472669,
                                                    "singleQuantityLcf": 0,
                                                    "lcf": 0,
                                                    "totalQuantityMcf": 25.472669,
                                                    "expandable": false,
                                                    "children": [
                                                        {
                                                            "rowNumber": 4720,
                                                            "parentRowNumber": 4719,
                                                            "articleId": "20220615",
                                                            "description": "20220615-00-Zylinderschraube",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0054,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0054,
                                                            "singleQuantityMcf": 0.027972,
                                                            "mcf": 0.027972,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.027972,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 4721,
                                                            "parentRowNumber": 4719,
                                                            "articleId": "20220615",
                                                            "description": "20220615-00-Zylinderschraube",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0054,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0054,
                                                            "singleQuantityMcf": 0.027972,
                                                            "mcf": 0.027972,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.027972,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 4722,
                                                            "parentRowNumber": 4719,
                                                            "articleId": "29216000",
                                                            "description": "29216000-00-Radial-Rillenkugellager",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.025,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.025,
                                                            "singleQuantityMcf": 0.1295,
                                                            "mcf": 0.1295,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.1295,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 4723,
                                                            "parentRowNumber": 4719,
                                                            "articleId": "440117",
                                                            "description": "440117-01-Lagerschale",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.054,
                                                            "childrenWeight": 0.054,
                                                            "totalQuantityWeight": 0.054,
                                                            "singleQuantityMcf": 0.27972,
                                                            "mcf": 0.27972,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.27972,
                                                            "expandable": false,
                                                            "children": [
                                                                {
                                                                    "rowNumber": 4724,
                                                                    "parentRowNumber": 4723,
                                                                    "articleId": "1260035",
                                                                    "description": "1260035-00-Rundstahl",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.054,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.054,
                                                                    "singleQuantityMcf": 0.27972,
                                                                    "mcf": 0.27972,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.27972,
                                                                    "expandable": false,
                                                                    "children": []
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            "rowNumber": 4725,
                                                            "parentRowNumber": 4719,
                                                            "articleId": "47235598",
                                                            "description": "47235598-00-Objektfuehrung",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 4.324899999999999,
                                                            "childrenWeight": 4.324899999999999,
                                                            "totalQuantityWeight": 4.324899999999999,
                                                            "singleQuantityMcf": 14.485417000000004,
                                                            "mcf": 14.485417000000004,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 14.485417000000004,
                                                            "expandable": false,
                                                            "children": [
                                                                {
                                                                    "rowNumber": 4726,
                                                                    "parentRowNumber": 4725,
                                                                    "articleId": "20120406",
                                                                    "description": "20120406-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0015,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0015,
                                                                    "singleQuantityMcf": 0.00777,
                                                                    "mcf": 0.00777,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.00777,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4727,
                                                                    "parentRowNumber": 4725,
                                                                    "articleId": "20120406",
                                                                    "description": "20120406-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0015,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0015,
                                                                    "singleQuantityMcf": 0.00777,
                                                                    "mcf": 0.00777,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.00777,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4728,
                                                                    "parentRowNumber": 4725,
                                                                    "articleId": "20120512",
                                                                    "description": "20120512-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0033,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0033,
                                                                    "singleQuantityMcf": 0.017093999999999998,
                                                                    "mcf": 0.017093999999999998,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.017093999999999998,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4729,
                                                                    "parentRowNumber": 4725,
                                                                    "articleId": "20120512",
                                                                    "description": "20120512-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0033,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0033,
                                                                    "singleQuantityMcf": 0.017093999999999998,
                                                                    "mcf": 0.017093999999999998,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.017093999999999998,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4730,
                                                                    "parentRowNumber": 4725,
                                                                    "articleId": "20120540",
                                                                    "description": "20120540-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0077,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0077,
                                                                    "singleQuantityMcf": 0.039886,
                                                                    "mcf": 0.039886,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.039886,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4731,
                                                                    "parentRowNumber": 4725,
                                                                    "articleId": "20120540",
                                                                    "description": "20120540-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0077,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0077,
                                                                    "singleQuantityMcf": 0.039886,
                                                                    "mcf": 0.039886,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.039886,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4732,
                                                                    "parentRowNumber": 4725,
                                                                    "articleId": "20120540",
                                                                    "description": "20120540-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0077,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0077,
                                                                    "singleQuantityMcf": 0.039886,
                                                                    "mcf": 0.039886,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.039886,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4733,
                                                                    "parentRowNumber": 4725,
                                                                    "articleId": "20120540",
                                                                    "description": "20120540-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0077,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0077,
                                                                    "singleQuantityMcf": 0.039886,
                                                                    "mcf": 0.039886,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.039886,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4734,
                                                                    "parentRowNumber": 4725,
                                                                    "articleId": "20120540",
                                                                    "description": "20120540-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0077,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0077,
                                                                    "singleQuantityMcf": 0.039886,
                                                                    "mcf": 0.039886,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.039886,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4735,
                                                                    "parentRowNumber": 4725,
                                                                    "articleId": "20120540",
                                                                    "description": "20120540-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0077,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0077,
                                                                    "singleQuantityMcf": 0.039886,
                                                                    "mcf": 0.039886,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.039886,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4736,
                                                                    "parentRowNumber": 4725,
                                                                    "articleId": "20120540",
                                                                    "description": "20120540-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0077,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0077,
                                                                    "singleQuantityMcf": 0.039886,
                                                                    "mcf": 0.039886,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.039886,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4737,
                                                                    "parentRowNumber": 4725,
                                                                    "articleId": "20120540",
                                                                    "description": "20120540-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0077,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0077,
                                                                    "singleQuantityMcf": 0.039886,
                                                                    "mcf": 0.039886,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.039886,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4738,
                                                                    "parentRowNumber": 4725,
                                                                    "articleId": "20120680",
                                                                    "description": "20120680-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0207,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0207,
                                                                    "singleQuantityMcf": 0.107226,
                                                                    "mcf": 0.107226,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.107226,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4739,
                                                                    "parentRowNumber": 4725,
                                                                    "articleId": "20220425",
                                                                    "description": "20220425-00-Zylinderschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0032,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0032,
                                                                    "singleQuantityMcf": 0.016576,
                                                                    "mcf": 0.016576,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.016576,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4740,
                                                                    "parentRowNumber": 4725,
                                                                    "articleId": "20220425",
                                                                    "description": "20220425-00-Zylinderschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0032,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0032,
                                                                    "singleQuantityMcf": 0.016576,
                                                                    "mcf": 0.016576,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.016576,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4741,
                                                                    "parentRowNumber": 4725,
                                                                    "articleId": "20220530",
                                                                    "description": "20220530-00-Zylinderschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0059,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0059,
                                                                    "singleQuantityMcf": 0.030562,
                                                                    "mcf": 0.030562,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.030562,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4742,
                                                                    "parentRowNumber": 4725,
                                                                    "articleId": "20420512",
                                                                    "description": "20420512-00-Senkschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0022,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0022,
                                                                    "singleQuantityMcf": 0.011396,
                                                                    "mcf": 0.011396,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.011396,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4743,
                                                                    "parentRowNumber": 4725,
                                                                    "articleId": "20420512",
                                                                    "description": "20420512-00-Senkschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0022,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0022,
                                                                    "singleQuantityMcf": 0.011396,
                                                                    "mcf": 0.011396,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.011396,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4744,
                                                                    "parentRowNumber": 4725,
                                                                    "articleId": "205205",
                                                                    "description": "205205-00-Sechskantmutter",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0013,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0013,
                                                                    "singleQuantityMcf": 0.006734,
                                                                    "mcf": 0.006734,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.006734,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4745,
                                                                    "parentRowNumber": 4725,
                                                                    "articleId": "205206",
                                                                    "description": "205206-00-Sechskantmutter",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0026,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0026,
                                                                    "singleQuantityMcf": 0.013468,
                                                                    "mcf": 0.013468,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.013468,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4746,
                                                                    "parentRowNumber": 4725,
                                                                    "articleId": "208204",
                                                                    "description": "208204-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0003,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0003,
                                                                    "singleQuantityMcf": 0.0015539999999999998,
                                                                    "mcf": 0.0015539999999999998,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.0015539999999999998,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4747,
                                                                    "parentRowNumber": 4725,
                                                                    "articleId": "208204",
                                                                    "description": "208204-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0003,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0003,
                                                                    "singleQuantityMcf": 0.0015539999999999998,
                                                                    "mcf": 0.0015539999999999998,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.0015539999999999998,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4748,
                                                                    "parentRowNumber": 4725,
                                                                    "articleId": "208205",
                                                                    "description": "208205-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0004,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0004,
                                                                    "singleQuantityMcf": 0.002072,
                                                                    "mcf": 0.002072,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.002072,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4749,
                                                                    "parentRowNumber": 4725,
                                                                    "articleId": "208205",
                                                                    "description": "208205-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0004,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0004,
                                                                    "singleQuantityMcf": 0.002072,
                                                                    "mcf": 0.002072,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.002072,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4750,
                                                                    "parentRowNumber": 4725,
                                                                    "articleId": "208405",
                                                                    "description": "208405-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0015,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0015,
                                                                    "singleQuantityMcf": 0.00777,
                                                                    "mcf": 0.00777,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.00777,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4751,
                                                                    "parentRowNumber": 4725,
                                                                    "articleId": "208405",
                                                                    "description": "208405-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0015,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0015,
                                                                    "singleQuantityMcf": 0.00777,
                                                                    "mcf": 0.00777,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.00777,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4752,
                                                                    "parentRowNumber": 4725,
                                                                    "articleId": "208405",
                                                                    "description": "208405-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0015,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0015,
                                                                    "singleQuantityMcf": 0.00777,
                                                                    "mcf": 0.00777,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.00777,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4753,
                                                                    "parentRowNumber": 4725,
                                                                    "articleId": "208405",
                                                                    "description": "208405-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0015,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0015,
                                                                    "singleQuantityMcf": 0.00777,
                                                                    "mcf": 0.00777,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.00777,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4754,
                                                                    "parentRowNumber": 4725,
                                                                    "articleId": "208405",
                                                                    "description": "208405-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0015,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0015,
                                                                    "singleQuantityMcf": 0.00777,
                                                                    "mcf": 0.00777,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.00777,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4755,
                                                                    "parentRowNumber": 4725,
                                                                    "articleId": "208405",
                                                                    "description": "208405-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0015,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0015,
                                                                    "singleQuantityMcf": 0.00777,
                                                                    "mcf": 0.00777,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.00777,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4756,
                                                                    "parentRowNumber": 4725,
                                                                    "articleId": "208405",
                                                                    "description": "208405-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0015,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0015,
                                                                    "singleQuantityMcf": 0.00777,
                                                                    "mcf": 0.00777,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.00777,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4757,
                                                                    "parentRowNumber": 4725,
                                                                    "articleId": "208405",
                                                                    "description": "208405-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0015,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0015,
                                                                    "singleQuantityMcf": 0.00777,
                                                                    "mcf": 0.00777,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.00777,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4758,
                                                                    "parentRowNumber": 4725,
                                                                    "articleId": "22070610",
                                                                    "description": "22070610-00-Fluegelschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0237,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0237,
                                                                    "singleQuantityMcf": 0.041001,
                                                                    "mcf": 0.041001,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.041001,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4759,
                                                                    "parentRowNumber": 4725,
                                                                    "articleId": "22070610",
                                                                    "description": "22070610-00-Fluegelschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0237,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0237,
                                                                    "singleQuantityMcf": 0.041001,
                                                                    "mcf": 0.041001,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.041001,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4760,
                                                                    "parentRowNumber": 4725,
                                                                    "articleId": "22070625",
                                                                    "description": "22070625-00-Fluegelschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0271,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0271,
                                                                    "singleQuantityMcf": 0.046883,
                                                                    "mcf": 0.046883,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.046883,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4761,
                                                                    "parentRowNumber": 4725,
                                                                    "articleId": "334195",
                                                                    "description": "334195-00-Druckfeder",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0023,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0023,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4762,
                                                                    "parentRowNumber": 4725,
                                                                    "articleId": "334195",
                                                                    "description": "334195-00-Druckfeder",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0023,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0023,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4763,
                                                                    "parentRowNumber": 4725,
                                                                    "articleId": "46591089",
                                                                    "description": "46591089-00-Sechskantmutter",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0003,
                                                                    "childrenWeight": 0.0003,
                                                                    "totalQuantityWeight": 0.0003,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 4764,
                                                                            "parentRowNumber": 4763,
                                                                            "articleId": "1615018",
                                                                            "description": "1615018-00-Rundstab",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0003,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0003,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 4765,
                                                                    "parentRowNumber": 4725,
                                                                    "articleId": "46645928",
                                                                    "description": "46645928-00-Formatbuegel 2.0",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.1205,
                                                                    "childrenWeight": 0.1205,
                                                                    "totalQuantityWeight": 0.1205,
                                                                    "singleQuantityMcf": 0.6241899999999999,
                                                                    "mcf": 0.6241899999999999,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.6241899999999999,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 4766,
                                                                            "parentRowNumber": 4765,
                                                                            "articleId": "142612",
                                                                            "description": "142612-00-Edelstahlblech",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0617,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0617,
                                                                            "singleQuantityMcf": 0.31960599999999995,
                                                                            "mcf": 0.31960599999999995,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.31960599999999995,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        },
                                                                        {
                                                                            "rowNumber": 4767,
                                                                            "parentRowNumber": 4765,
                                                                            "articleId": "46647477",
                                                                            "description": "46647477-00-Halter",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0294,
                                                                            "childrenWeight": 0.0294,
                                                                            "totalQuantityWeight": 0.0294,
                                                                            "singleQuantityMcf": 0.15229199999999998,
                                                                            "mcf": 0.15229199999999998,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.15229199999999998,
                                                                            "expandable": false,
                                                                            "children": [
                                                                                {
                                                                                    "rowNumber": 4768,
                                                                                    "parentRowNumber": 4767,
                                                                                    "articleId": "1110231",
                                                                                    "description": "1110231-00-Flachstahl",
                                                                                    "hierarchy": 9,
                                                                                    "quantity": 1,
                                                                                    "weight": 0.0294,
                                                                                    "childrenWeight": 0,
                                                                                    "totalQuantityWeight": 0.0294,
                                                                                    "singleQuantityMcf": 0.15229199999999998,
                                                                                    "mcf": 0.15229199999999998,
                                                                                    "singleQuantityLcf": 0,
                                                                                    "lcf": 0,
                                                                                    "totalQuantityMcf": 0.15229199999999998,
                                                                                    "expandable": false,
                                                                                    "children": []
                                                                                }
                                                                            ]
                                                                        },
                                                                        {
                                                                            "rowNumber": 4769,
                                                                            "parentRowNumber": 4765,
                                                                            "articleId": "46647477",
                                                                            "description": "46647477-00-Halter",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0294,
                                                                            "childrenWeight": 0.0294,
                                                                            "totalQuantityWeight": 0.0294,
                                                                            "singleQuantityMcf": 0.15229199999999998,
                                                                            "mcf": 0.15229199999999998,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.15229199999999998,
                                                                            "expandable": false,
                                                                            "children": [
                                                                                {
                                                                                    "rowNumber": 4770,
                                                                                    "parentRowNumber": 4769,
                                                                                    "articleId": "1110231",
                                                                                    "description": "1110231-00-Flachstahl",
                                                                                    "hierarchy": 9,
                                                                                    "quantity": 1,
                                                                                    "weight": 0.0294,
                                                                                    "childrenWeight": 0,
                                                                                    "totalQuantityWeight": 0.0294,
                                                                                    "singleQuantityMcf": 0.15229199999999998,
                                                                                    "mcf": 0.15229199999999998,
                                                                                    "singleQuantityLcf": 0,
                                                                                    "lcf": 0,
                                                                                    "totalQuantityMcf": 0.15229199999999998,
                                                                                    "expandable": false,
                                                                                    "children": []
                                                                                }
                                                                            ]
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 4771,
                                                                    "parentRowNumber": 4725,
                                                                    "articleId": "46645928",
                                                                    "description": "46645928-00-Formatbuegel 2.0",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.1205,
                                                                    "childrenWeight": 0.1205,
                                                                    "totalQuantityWeight": 0.1205,
                                                                    "singleQuantityMcf": 0.6241899999999999,
                                                                    "mcf": 0.6241899999999999,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.6241899999999999,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 4772,
                                                                            "parentRowNumber": 4771,
                                                                            "articleId": "142612",
                                                                            "description": "142612-00-Edelstahlblech",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0617,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0617,
                                                                            "singleQuantityMcf": 0.31960599999999995,
                                                                            "mcf": 0.31960599999999995,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.31960599999999995,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        },
                                                                        {
                                                                            "rowNumber": 4773,
                                                                            "parentRowNumber": 4771,
                                                                            "articleId": "46647477",
                                                                            "description": "46647477-00-Halter",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0294,
                                                                            "childrenWeight": 0.0294,
                                                                            "totalQuantityWeight": 0.0294,
                                                                            "singleQuantityMcf": 0.15229199999999998,
                                                                            "mcf": 0.15229199999999998,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.15229199999999998,
                                                                            "expandable": false,
                                                                            "children": [
                                                                                {
                                                                                    "rowNumber": 4774,
                                                                                    "parentRowNumber": 4773,
                                                                                    "articleId": "1110231",
                                                                                    "description": "1110231-00-Flachstahl",
                                                                                    "hierarchy": 9,
                                                                                    "quantity": 1,
                                                                                    "weight": 0.0294,
                                                                                    "childrenWeight": 0,
                                                                                    "totalQuantityWeight": 0.0294,
                                                                                    "singleQuantityMcf": 0.15229199999999998,
                                                                                    "mcf": 0.15229199999999998,
                                                                                    "singleQuantityLcf": 0,
                                                                                    "lcf": 0,
                                                                                    "totalQuantityMcf": 0.15229199999999998,
                                                                                    "expandable": false,
                                                                                    "children": []
                                                                                }
                                                                            ]
                                                                        },
                                                                        {
                                                                            "rowNumber": 4775,
                                                                            "parentRowNumber": 4771,
                                                                            "articleId": "46647477",
                                                                            "description": "46647477-00-Halter",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0294,
                                                                            "childrenWeight": 0.0294,
                                                                            "totalQuantityWeight": 0.0294,
                                                                            "singleQuantityMcf": 0.15229199999999998,
                                                                            "mcf": 0.15229199999999998,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.15229199999999998,
                                                                            "expandable": false,
                                                                            "children": [
                                                                                {
                                                                                    "rowNumber": 4776,
                                                                                    "parentRowNumber": 4775,
                                                                                    "articleId": "1110231",
                                                                                    "description": "1110231-00-Flachstahl",
                                                                                    "hierarchy": 9,
                                                                                    "quantity": 1,
                                                                                    "weight": 0.0294,
                                                                                    "childrenWeight": 0,
                                                                                    "totalQuantityWeight": 0.0294,
                                                                                    "singleQuantityMcf": 0.15229199999999998,
                                                                                    "mcf": 0.15229199999999998,
                                                                                    "singleQuantityLcf": 0,
                                                                                    "lcf": 0,
                                                                                    "totalQuantityMcf": 0.15229199999999998,
                                                                                    "expandable": false,
                                                                                    "children": []
                                                                                }
                                                                            ]
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 4777,
                                                                    "parentRowNumber": 4725,
                                                                    "articleId": "47122726",
                                                                    "description": "47122726-00-Fuehrung",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0284,
                                                                    "childrenWeight": 0.0284,
                                                                    "totalQuantityWeight": 0.0284,
                                                                    "singleQuantityMcf": 0.09088000000000002,
                                                                    "mcf": 0.09088000000000002,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.09088000000000002,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 4778,
                                                                            "parentRowNumber": 4777,
                                                                            "articleId": "162532",
                                                                            "description": "162532-01-Platte",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0284,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0284,
                                                                            "singleQuantityMcf": 0.09088000000000002,
                                                                            "mcf": 0.09088000000000002,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.09088000000000002,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 4779,
                                                                    "parentRowNumber": 4725,
                                                                    "articleId": "47122750",
                                                                    "description": "47122750-00-Fuehrung",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0242,
                                                                    "childrenWeight": 0.0242,
                                                                    "totalQuantityWeight": 0.0242,
                                                                    "singleQuantityMcf": 0.07744000000000001,
                                                                    "mcf": 0.07744000000000001,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.07744000000000001,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 4780,
                                                                            "parentRowNumber": 4779,
                                                                            "articleId": "162532",
                                                                            "description": "162532-01-Platte",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0242,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0242,
                                                                            "singleQuantityMcf": 0.07744000000000001,
                                                                            "mcf": 0.07744000000000001,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.07744000000000001,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 4781,
                                                                    "parentRowNumber": 4725,
                                                                    "articleId": "47161500",
                                                                    "description": "47161500-00-Klotz",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.1394,
                                                                    "childrenWeight": 0.1394,
                                                                    "totalQuantityWeight": 0.1394,
                                                                    "singleQuantityMcf": 0.42464,
                                                                    "mcf": 0.42464,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.42464,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 4782,
                                                                            "parentRowNumber": 4781,
                                                                            "articleId": "162547",
                                                                            "description": "162547-01-Platte",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.13269999999999998,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.13269999999999998,
                                                                            "singleQuantityMcf": 0.42464,
                                                                            "mcf": 0.42464,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.42464,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        },
                                                                        {
                                                                            "rowNumber": 4783,
                                                                            "parentRowNumber": 4781,
                                                                            "articleId": "347201",
                                                                            "description": "347201-00-Ensat-Gewindebuchse",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0018,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0018,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        },
                                                                        {
                                                                            "rowNumber": 4784,
                                                                            "parentRowNumber": 4781,
                                                                            "articleId": "347204",
                                                                            "description": "347204-00-Ensat-Gewindebuchse",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0049,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0049,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 4785,
                                                                    "parentRowNumber": 4725,
                                                                    "articleId": "47161509",
                                                                    "description": "47161509-00-Blech",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0333,
                                                                    "childrenWeight": 0.0333,
                                                                    "totalQuantityWeight": 0.0333,
                                                                    "singleQuantityMcf": 0.172494,
                                                                    "mcf": 0.172494,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.172494,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 4786,
                                                                            "parentRowNumber": 4785,
                                                                            "articleId": "142602",
                                                                            "description": "142602-00-Edelstahlblech",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0333,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0333,
                                                                            "singleQuantityMcf": 0.172494,
                                                                            "mcf": 0.172494,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.172494,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 4787,
                                                                    "parentRowNumber": 4725,
                                                                    "articleId": "47230819",
                                                                    "description": "47230819-00-Welle",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0216,
                                                                    "childrenWeight": 0.0216,
                                                                    "totalQuantityWeight": 0.0216,
                                                                    "singleQuantityMcf": 0.111888,
                                                                    "mcf": 0.111888,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.111888,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 4788,
                                                                            "parentRowNumber": 4787,
                                                                            "articleId": "1224008",
                                                                            "description": "1224008-00-Rundstahl",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0216,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0216,
                                                                            "singleQuantityMcf": 0.111888,
                                                                            "mcf": 0.111888,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.111888,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 4789,
                                                                    "parentRowNumber": 4725,
                                                                    "articleId": "47230819",
                                                                    "description": "47230819-00-Welle",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0216,
                                                                    "childrenWeight": 0.0216,
                                                                    "totalQuantityWeight": 0.0216,
                                                                    "singleQuantityMcf": 0.111888,
                                                                    "mcf": 0.111888,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.111888,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 4790,
                                                                            "parentRowNumber": 4789,
                                                                            "articleId": "1224008",
                                                                            "description": "1224008-00-Rundstahl",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0216,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0216,
                                                                            "singleQuantityMcf": 0.111888,
                                                                            "mcf": 0.111888,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.111888,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 4791,
                                                                    "parentRowNumber": 4725,
                                                                    "articleId": "47235599",
                                                                    "description": "47235599-00-Fuehrung",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 1.0668,
                                                                    "childrenWeight": 1.0668,
                                                                    "totalQuantityWeight": 1.0668,
                                                                    "singleQuantityMcf": 3.41376,
                                                                    "mcf": 3.41376,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 3.41376,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 4792,
                                                                            "parentRowNumber": 4791,
                                                                            "articleId": "162544",
                                                                            "description": "162544-01-Platte",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 1.0668,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 1.0668,
                                                                            "singleQuantityMcf": 3.41376,
                                                                            "mcf": 3.41376,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 3.41376,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 4793,
                                                                    "parentRowNumber": 4725,
                                                                    "articleId": "47235600",
                                                                    "description": "47235600-00-Fuehrung",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.6501,
                                                                    "childrenWeight": 0.6501,
                                                                    "totalQuantityWeight": 0.6501,
                                                                    "singleQuantityMcf": 2.04896,
                                                                    "mcf": 2.04896,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 2.04896,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 4794,
                                                                            "parentRowNumber": 4793,
                                                                            "articleId": "162544",
                                                                            "description": "162544-01-Platte",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.6403,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.6403,
                                                                            "singleQuantityMcf": 2.04896,
                                                                            "mcf": 2.04896,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 2.04896,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        },
                                                                        {
                                                                            "rowNumber": 4795,
                                                                            "parentRowNumber": 4793,
                                                                            "articleId": "347204",
                                                                            "description": "347204-00-Ensat-Gewindebuchse",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0049,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0049,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        },
                                                                        {
                                                                            "rowNumber": 4796,
                                                                            "parentRowNumber": 4793,
                                                                            "articleId": "347204",
                                                                            "description": "347204-00-Ensat-Gewindebuchse",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0049,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0049,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 4797,
                                                                    "parentRowNumber": 4725,
                                                                    "articleId": "47235601",
                                                                    "description": "47235601-00-Einlaufformat",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 1.8932,
                                                                    "childrenWeight": 1.8932,
                                                                    "totalQuantityWeight": 1.8932,
                                                                    "singleQuantityMcf": 6.004040000000001,
                                                                    "mcf": 6.004040000000001,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 6.004040000000001,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 4798,
                                                                            "parentRowNumber": 4797,
                                                                            "articleId": "162544",
                                                                            "description": "162544-01-Platte",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 1.8618,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 1.8618,
                                                                            "singleQuantityMcf": 5.95776,
                                                                            "mcf": 5.95776,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 5.95776,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        },
                                                                        {
                                                                            "rowNumber": 4799,
                                                                            "parentRowNumber": 4797,
                                                                            "articleId": "20440410",
                                                                            "description": "20440410-00-Senkschraube",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0012,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0012,
                                                                            "singleQuantityMcf": 0.006215999999999999,
                                                                            "mcf": 0.006215999999999999,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.006215999999999999,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        },
                                                                        {
                                                                            "rowNumber": 4800,
                                                                            "parentRowNumber": 4797,
                                                                            "articleId": "20440410",
                                                                            "description": "20440410-00-Senkschraube",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0012,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0012,
                                                                            "singleQuantityMcf": 0.006215999999999999,
                                                                            "mcf": 0.006215999999999999,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.006215999999999999,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        },
                                                                        {
                                                                            "rowNumber": 4801,
                                                                            "parentRowNumber": 4797,
                                                                            "articleId": "21220308",
                                                                            "description": "21220308-00-Zylinderstift",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0005,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0005,
                                                                            "singleQuantityMcf": 0.00259,
                                                                            "mcf": 0.00259,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.00259,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        },
                                                                        {
                                                                            "rowNumber": 4802,
                                                                            "parentRowNumber": 4797,
                                                                            "articleId": "21220308",
                                                                            "description": "21220308-00-Zylinderstift",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0005,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0005,
                                                                            "singleQuantityMcf": 0.00259,
                                                                            "mcf": 0.00259,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.00259,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        },
                                                                        {
                                                                            "rowNumber": 4803,
                                                                            "parentRowNumber": 4797,
                                                                            "articleId": "272252",
                                                                            "description": "272252-00-Gleitlager",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0004,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0004,
                                                                            "singleQuantityMcf": 0.000692,
                                                                            "mcf": 0.000692,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.000692,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        },
                                                                        {
                                                                            "rowNumber": 4804,
                                                                            "parentRowNumber": 4797,
                                                                            "articleId": "272252",
                                                                            "description": "272252-00-Gleitlager",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0004,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0004,
                                                                            "singleQuantityMcf": 0.000692,
                                                                            "mcf": 0.000692,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.000692,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        },
                                                                        {
                                                                            "rowNumber": 4805,
                                                                            "parentRowNumber": 4797,
                                                                            "articleId": "272252",
                                                                            "description": "272252-00-Gleitlager",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0004,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0004,
                                                                            "singleQuantityMcf": 0.000692,
                                                                            "mcf": 0.000692,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.000692,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        },
                                                                        {
                                                                            "rowNumber": 4806,
                                                                            "parentRowNumber": 4797,
                                                                            "articleId": "272252",
                                                                            "description": "272252-00-Gleitlager",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0004,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0004,
                                                                            "singleQuantityMcf": 0.000692,
                                                                            "mcf": 0.000692,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.000692,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        },
                                                                        {
                                                                            "rowNumber": 4807,
                                                                            "parentRowNumber": 4797,
                                                                            "articleId": "2925695",
                                                                            "description": "2925695-00-Radial-Rillenkugellager",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0025,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0025,
                                                                            "singleQuantityMcf": 0.01295,
                                                                            "mcf": 0.01295,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.01295,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        },
                                                                        {
                                                                            "rowNumber": 4808,
                                                                            "parentRowNumber": 4797,
                                                                            "articleId": "2925695",
                                                                            "description": "2925695-00-Radial-Rillenkugellager",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0025,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0025,
                                                                            "singleQuantityMcf": 0.01295,
                                                                            "mcf": 0.01295,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.01295,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        },
                                                                        {
                                                                            "rowNumber": 4809,
                                                                            "parentRowNumber": 4797,
                                                                            "articleId": "347195",
                                                                            "description": "347195-00-Ensat-Gewindebuchse",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0014,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0014,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        },
                                                                        {
                                                                            "rowNumber": 4810,
                                                                            "parentRowNumber": 4797,
                                                                            "articleId": "347195",
                                                                            "description": "347195-00-Ensat-Gewindebuchse",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0014,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0014,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        },
                                                                        {
                                                                            "rowNumber": 4811,
                                                                            "parentRowNumber": 4797,
                                                                            "articleId": "347195",
                                                                            "description": "347195-00-Ensat-Gewindebuchse",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0014,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0014,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        },
                                                                        {
                                                                            "rowNumber": 4812,
                                                                            "parentRowNumber": 4797,
                                                                            "articleId": "347195",
                                                                            "description": "347195-00-Ensat-Gewindebuchse",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0014,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0014,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        },
                                                                        {
                                                                            "rowNumber": 4813,
                                                                            "parentRowNumber": 4797,
                                                                            "articleId": "347200",
                                                                            "description": "347200-00-Ensat-Gewindebuchse",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0025,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0025,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        },
                                                                        {
                                                                            "rowNumber": 4814,
                                                                            "parentRowNumber": 4797,
                                                                            "articleId": "347200",
                                                                            "description": "347200-00-Ensat-Gewindebuchse",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0025,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0025,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        },
                                                                        {
                                                                            "rowNumber": 4815,
                                                                            "parentRowNumber": 4797,
                                                                            "articleId": "347200",
                                                                            "description": "347200-00-Ensat-Gewindebuchse",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0025,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0025,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        },
                                                                        {
                                                                            "rowNumber": 4816,
                                                                            "parentRowNumber": 4797,
                                                                            "articleId": "347200",
                                                                            "description": "347200-00-Ensat-Gewindebuchse",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0025,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0025,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        },
                                                                        {
                                                                            "rowNumber": 4817,
                                                                            "parentRowNumber": 4797,
                                                                            "articleId": "347205",
                                                                            "description": "347205-00-Ensat-Gewindebuchse",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0058,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0058,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            "rowNumber": 4818,
                                                            "parentRowNumber": 4719,
                                                            "articleId": "47235603",
                                                            "description": "47235603-00-Schnecke",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 3.128,
                                                            "childrenWeight": 3.128,
                                                            "totalQuantityWeight": 3.128,
                                                            "singleQuantityMcf": 9.86112,
                                                            "mcf": 9.86112,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 9.86112,
                                                            "expandable": false,
                                                            "children": [
                                                                {
                                                                    "rowNumber": 4819,
                                                                    "parentRowNumber": 4818,
                                                                    "articleId": "1617090",
                                                                    "description": "1617090-00-Rundstab",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 3.0816,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 3.0816,
                                                                    "singleQuantityMcf": 9.86112,
                                                                    "mcf": 9.86112,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 9.86112,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4820,
                                                                    "parentRowNumber": 4818,
                                                                    "articleId": "347205",
                                                                    "description": "347205-00-Ensat-Gewindebuchse",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0058,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0058,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4821,
                                                                    "parentRowNumber": 4818,
                                                                    "articleId": "347205",
                                                                    "description": "347205-00-Ensat-Gewindebuchse",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0058,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0058,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4822,
                                                                    "parentRowNumber": 4818,
                                                                    "articleId": "347205",
                                                                    "description": "347205-00-Ensat-Gewindebuchse",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0058,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0058,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4823,
                                                                    "parentRowNumber": 4818,
                                                                    "articleId": "347205",
                                                                    "description": "347205-00-Ensat-Gewindebuchse",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0058,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0058,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4824,
                                                                    "parentRowNumber": 4818,
                                                                    "articleId": "347205",
                                                                    "description": "347205-00-Ensat-Gewindebuchse",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0058,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0058,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4825,
                                                                    "parentRowNumber": 4818,
                                                                    "articleId": "347205",
                                                                    "description": "347205-00-Ensat-Gewindebuchse",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0058,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0058,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4826,
                                                                    "parentRowNumber": 4818,
                                                                    "articleId": "347205",
                                                                    "description": "347205-00-Ensat-Gewindebuchse",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0058,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0058,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4827,
                                                                    "parentRowNumber": 4818,
                                                                    "articleId": "347205",
                                                                    "description": "347205-00-Ensat-Gewindebuchse",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0058,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0058,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": []
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            "rowNumber": 4828,
                                                            "parentRowNumber": 4719,
                                                            "articleId": "ESA1025",
                                                            "description": "ESA1025-B-807,20-01-Kulisse",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.1276,
                                                            "childrenWeight": 0.1276,
                                                            "totalQuantityWeight": 0.1276,
                                                            "singleQuantityMcf": 0.660968,
                                                            "mcf": 0.660968,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.660968,
                                                            "expandable": false,
                                                            "children": [
                                                                {
                                                                    "rowNumber": 4829,
                                                                    "parentRowNumber": 4828,
                                                                    "articleId": "20220620",
                                                                    "description": "20220620-00-Zylinderschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0063,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0063,
                                                                    "singleQuantityMcf": 0.032634,
                                                                    "mcf": 0.032634,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.032634,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4830,
                                                                    "parentRowNumber": 4828,
                                                                    "articleId": "21820420",
                                                                    "description": "21820420-00-Passkerbstift",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4831,
                                                                    "parentRowNumber": 4828,
                                                                    "articleId": "453061",
                                                                    "description": "453061-05-Kulisse",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.1213,
                                                                    "childrenWeight": 0.1213,
                                                                    "totalQuantityWeight": 0.1213,
                                                                    "singleQuantityMcf": 0.628334,
                                                                    "mcf": 0.628334,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.628334,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 4832,
                                                                            "parentRowNumber": 4831,
                                                                            "articleId": "1260055",
                                                                            "description": "1260055-00-Rundstahl",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.1213,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.1213,
                                                                            "singleQuantityMcf": 0.628334,
                                                                            "mcf": 0.628334,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.628334,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            "rowNumber": 4833,
                                                            "parentRowNumber": 4719,
                                                            "articleId": "OBJEKT SERUM 33847 24X49",
                                                            "description": "OBJEKT SERUM 33847 24X49-00-Runde Flasche mit Boerdelverschluss",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.1531,
                                                            "childrenWeight": 0.1531,
                                                            "totalQuantityWeight": 0.1531,
                                                            "singleQuantityMcf": 0,
                                                            "mcf": 0,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0,
                                                            "expandable": false,
                                                            "children": [
                                                                {
                                                                    "rowNumber": 4834,
                                                                    "parentRowNumber": 4833,
                                                                    "articleId": "OBJ0000000007255",
                                                                    "description": "OBJ0000000007255-00-LYO-Stopfen",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0132,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0132,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4835,
                                                                    "parentRowNumber": 4833,
                                                                    "articleId": "OBJ0000000007336",
                                                                    "description": "OBJ0000000007336-00-Flip-Off Kappe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0055,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0055,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4836,
                                                                    "parentRowNumber": 4833,
                                                                    "articleId": "OBJ0000000010005",
                                                                    "description": "OBJ0000000010005-00-Runde Flasche mit Boerdelverschluss",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.1344,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.1344,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": []
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            "rowNumber": 4837,
                                                            "parentRowNumber": 4719,
                                                            "articleId": "OBJEKT SERUM 33847 24X49",
                                                            "description": "OBJEKT SERUM 33847 24X49-00-Runde Flasche mit Boerdelverschluss",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.1531,
                                                            "childrenWeight": 0.1531,
                                                            "totalQuantityWeight": 0.1531,
                                                            "singleQuantityMcf": 0,
                                                            "mcf": 0,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0,
                                                            "expandable": false,
                                                            "children": [
                                                                {
                                                                    "rowNumber": 4838,
                                                                    "parentRowNumber": 4837,
                                                                    "articleId": "OBJ0000000007255",
                                                                    "description": "OBJ0000000007255-00-LYO-Stopfen",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0132,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0132,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4839,
                                                                    "parentRowNumber": 4837,
                                                                    "articleId": "OBJ0000000007336",
                                                                    "description": "OBJ0000000007336-00-Flip-Off Kappe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0055,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0055,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4840,
                                                                    "parentRowNumber": 4837,
                                                                    "articleId": "OBJ0000000010005",
                                                                    "description": "OBJ0000000010005-00-Runde Flasche mit Boerdelverschluss",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.1344,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.1344,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": []
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            "rowNumber": 4841,
                                                            "parentRowNumber": 4719,
                                                            "articleId": "OBJEKT SERUM 33847 24X49",
                                                            "description": "OBJEKT SERUM 33847 24X49-00-Runde Flasche mit Boerdelverschluss",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.1531,
                                                            "childrenWeight": 0.1531,
                                                            "totalQuantityWeight": 0.1531,
                                                            "singleQuantityMcf": 0,
                                                            "mcf": 0,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0,
                                                            "expandable": false,
                                                            "children": [
                                                                {
                                                                    "rowNumber": 4842,
                                                                    "parentRowNumber": 4841,
                                                                    "articleId": "OBJ0000000007255",
                                                                    "description": "OBJ0000000007255-00-LYO-Stopfen",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0132,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0132,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4843,
                                                                    "parentRowNumber": 4841,
                                                                    "articleId": "OBJ0000000007336",
                                                                    "description": "OBJ0000000007336-00-Flip-Off Kappe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0055,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0055,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4844,
                                                                    "parentRowNumber": 4841,
                                                                    "articleId": "OBJ0000000010005",
                                                                    "description": "OBJ0000000010005-00-Runde Flasche mit Boerdelverschluss",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.1344,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.1344,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": []
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            "rowNumber": 4845,
                                                            "parentRowNumber": 4719,
                                                            "articleId": "OBJEKT SERUM 33847 24X49",
                                                            "description": "OBJEKT SERUM 33847 24X49-00-Runde Flasche mit Boerdelverschluss",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.1531,
                                                            "childrenWeight": 0.1531,
                                                            "totalQuantityWeight": 0.1531,
                                                            "singleQuantityMcf": 0,
                                                            "mcf": 0,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0,
                                                            "expandable": false,
                                                            "children": [
                                                                {
                                                                    "rowNumber": 4846,
                                                                    "parentRowNumber": 4845,
                                                                    "articleId": "OBJ0000000007255",
                                                                    "description": "OBJ0000000007255-00-LYO-Stopfen",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0132,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0132,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4847,
                                                                    "parentRowNumber": 4845,
                                                                    "articleId": "OBJ0000000007336",
                                                                    "description": "OBJ0000000007336-00-Flip-Off Kappe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0055,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0055,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4848,
                                                                    "parentRowNumber": 4845,
                                                                    "articleId": "OBJ0000000010005",
                                                                    "description": "OBJ0000000010005-00-Runde Flasche mit Boerdelverschluss",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.1344,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.1344,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": []
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            "rowNumber": 4849,
                                                            "parentRowNumber": 4719,
                                                            "articleId": "OBJEKT SERUM 33847 24X49",
                                                            "description": "OBJEKT SERUM 33847 24X49-00-Runde Flasche mit Boerdelverschluss",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.1531,
                                                            "childrenWeight": 0.1531,
                                                            "totalQuantityWeight": 0.1531,
                                                            "singleQuantityMcf": 0,
                                                            "mcf": 0,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0,
                                                            "expandable": false,
                                                            "children": [
                                                                {
                                                                    "rowNumber": 4850,
                                                                    "parentRowNumber": 4849,
                                                                    "articleId": "OBJ0000000007255",
                                                                    "description": "OBJ0000000007255-00-LYO-Stopfen",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0132,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0132,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4851,
                                                                    "parentRowNumber": 4849,
                                                                    "articleId": "OBJ0000000007336",
                                                                    "description": "OBJ0000000007336-00-Flip-Off Kappe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0055,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0055,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4852,
                                                                    "parentRowNumber": 4849,
                                                                    "articleId": "OBJ0000000010005",
                                                                    "description": "OBJ0000000010005-00-Runde Flasche mit Boerdelverschluss",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.1344,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.1344,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": []
                                                                }
                                                            ]
                                                        }
                                                    ]
                                                }
                                            ]
                                        },
                                        {
                                            "rowNumber": 4853,
                                            "parentRowNumber": 4194,
                                            "articleId": "33847",
                                            "description": "33847-20SLG7-00-Schnecke",
                                            "hierarchy": 4,
                                            "quantity": 1,
                                            "weight": 14.0745,
                                            "childrenWeight": 14.0745,
                                            "totalQuantityWeight": 14.0745,
                                            "singleQuantityMcf": 46.826215,
                                            "mcf": 46.826215,
                                            "singleQuantityLcf": 0,
                                            "lcf": 0,
                                            "totalQuantityMcf": 46.826215,
                                            "expandable": false,
                                            "children": [
                                                {
                                                    "rowNumber": 4854,
                                                    "parentRowNumber": 4853,
                                                    "articleId": "MM0000009661",
                                                    "description": "MM0000009661-00-Schnecke",
                                                    "hierarchy": 5,
                                                    "quantity": 1,
                                                    "weight": 14.0745,
                                                    "childrenWeight": 14.0745,
                                                    "totalQuantityWeight": 14.0745,
                                                    "singleQuantityMcf": 46.826215,
                                                    "mcf": 46.826215,
                                                    "singleQuantityLcf": 0,
                                                    "lcf": 0,
                                                    "totalQuantityMcf": 46.826215,
                                                    "expandable": false,
                                                    "children": [
                                                        {
                                                            "rowNumber": 4855,
                                                            "parentRowNumber": 4854,
                                                            "articleId": "20220615",
                                                            "description": "20220615-00-Zylinderschraube",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0054,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0054,
                                                            "singleQuantityMcf": 0.027972,
                                                            "mcf": 0.027972,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.027972,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 4856,
                                                            "parentRowNumber": 4854,
                                                            "articleId": "20220615",
                                                            "description": "20220615-00-Zylinderschraube",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.0054,
                                                            "childrenWeight": 0,
                                                            "totalQuantityWeight": 0.0054,
                                                            "singleQuantityMcf": 0.027972,
                                                            "mcf": 0.027972,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.027972,
                                                            "expandable": false,
                                                            "children": []
                                                        },
                                                        {
                                                            "rowNumber": 4857,
                                                            "parentRowNumber": 4854,
                                                            "articleId": "47235921",
                                                            "description": "47235921-00-Schnecke",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 9.3076,
                                                            "childrenWeight": 9.3076,
                                                            "totalQuantityWeight": 9.3076,
                                                            "singleQuantityMcf": 32.768596,
                                                            "mcf": 32.768596,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 32.768596,
                                                            "expandable": false,
                                                            "children": [
                                                                {
                                                                    "rowNumber": 4858,
                                                                    "parentRowNumber": 4857,
                                                                    "articleId": "1617090",
                                                                    "description": "1617090-00-Rundstab",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 7.679,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 7.679,
                                                                    "singleQuantityMcf": 24.5728,
                                                                    "mcf": 24.5728,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 24.5728,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4859,
                                                                    "parentRowNumber": 4857,
                                                                    "articleId": "21020450",
                                                                    "description": "21020450-00-Kegelstift",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0064,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0064,
                                                                    "singleQuantityMcf": 0.033152,
                                                                    "mcf": 0.033152,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.033152,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4860,
                                                                    "parentRowNumber": 4857,
                                                                    "articleId": "347205",
                                                                    "description": "347205-00-Ensat-Gewindebuchse",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0058,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0058,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4861,
                                                                    "parentRowNumber": 4857,
                                                                    "articleId": "347205",
                                                                    "description": "347205-00-Ensat-Gewindebuchse",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0058,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0058,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4862,
                                                                    "parentRowNumber": 4857,
                                                                    "articleId": "347205",
                                                                    "description": "347205-00-Ensat-Gewindebuchse",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0058,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0058,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4863,
                                                                    "parentRowNumber": 4857,
                                                                    "articleId": "347205",
                                                                    "description": "347205-00-Ensat-Gewindebuchse",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0058,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0058,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4864,
                                                                    "parentRowNumber": 4857,
                                                                    "articleId": "347205",
                                                                    "description": "347205-00-Ensat-Gewindebuchse",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0058,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0058,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4865,
                                                                    "parentRowNumber": 4857,
                                                                    "articleId": "347205",
                                                                    "description": "347205-00-Ensat-Gewindebuchse",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0058,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0058,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4866,
                                                                    "parentRowNumber": 4857,
                                                                    "articleId": "347205",
                                                                    "description": "347205-00-Ensat-Gewindebuchse",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0058,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0058,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4867,
                                                                    "parentRowNumber": 4857,
                                                                    "articleId": "347205",
                                                                    "description": "347205-00-Ensat-Gewindebuchse",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0058,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0058,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4868,
                                                                    "parentRowNumber": 4857,
                                                                    "articleId": "47130635",
                                                                    "description": "47130635-01-Welle",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 1.5758,
                                                                    "childrenWeight": 1.5758,
                                                                    "totalQuantityWeight": 1.5758,
                                                                    "singleQuantityMcf": 8.162644,
                                                                    "mcf": 8.162644,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 8.162644,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 4869,
                                                                            "parentRowNumber": 4868,
                                                                            "articleId": "1260020",
                                                                            "description": "1260020-00-Rundstahl",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 1.5758,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 1.5758,
                                                                            "singleQuantityMcf": 8.162644,
                                                                            "mcf": 8.162644,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 8.162644,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            "rowNumber": 4870,
                                                            "parentRowNumber": 4854,
                                                            "articleId": "47235922",
                                                            "description": "47235922-00-Objektfuehrung",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 3.954,
                                                            "childrenWeight": 3.954,
                                                            "totalQuantityWeight": 3.954,
                                                            "singleQuantityMcf": 13.340707,
                                                            "mcf": 13.340707,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 13.340707,
                                                            "expandable": false,
                                                            "children": [
                                                                {
                                                                    "rowNumber": 4871,
                                                                    "parentRowNumber": 4870,
                                                                    "articleId": "20120406",
                                                                    "description": "20120406-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0015,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0015,
                                                                    "singleQuantityMcf": 0.00777,
                                                                    "mcf": 0.00777,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.00777,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4872,
                                                                    "parentRowNumber": 4870,
                                                                    "articleId": "20120406",
                                                                    "description": "20120406-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0015,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0015,
                                                                    "singleQuantityMcf": 0.00777,
                                                                    "mcf": 0.00777,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.00777,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4873,
                                                                    "parentRowNumber": 4870,
                                                                    "articleId": "20120512",
                                                                    "description": "20120512-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0033,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0033,
                                                                    "singleQuantityMcf": 0.017093999999999998,
                                                                    "mcf": 0.017093999999999998,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.017093999999999998,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4874,
                                                                    "parentRowNumber": 4870,
                                                                    "articleId": "20120512",
                                                                    "description": "20120512-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0033,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0033,
                                                                    "singleQuantityMcf": 0.017093999999999998,
                                                                    "mcf": 0.017093999999999998,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.017093999999999998,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4875,
                                                                    "parentRowNumber": 4870,
                                                                    "articleId": "20120540",
                                                                    "description": "20120540-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0077,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0077,
                                                                    "singleQuantityMcf": 0.039886,
                                                                    "mcf": 0.039886,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.039886,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4876,
                                                                    "parentRowNumber": 4870,
                                                                    "articleId": "20120540",
                                                                    "description": "20120540-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0077,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0077,
                                                                    "singleQuantityMcf": 0.039886,
                                                                    "mcf": 0.039886,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.039886,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4877,
                                                                    "parentRowNumber": 4870,
                                                                    "articleId": "20120540",
                                                                    "description": "20120540-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0077,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0077,
                                                                    "singleQuantityMcf": 0.039886,
                                                                    "mcf": 0.039886,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.039886,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4878,
                                                                    "parentRowNumber": 4870,
                                                                    "articleId": "20120540",
                                                                    "description": "20120540-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0077,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0077,
                                                                    "singleQuantityMcf": 0.039886,
                                                                    "mcf": 0.039886,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.039886,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4879,
                                                                    "parentRowNumber": 4870,
                                                                    "articleId": "20120540",
                                                                    "description": "20120540-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0077,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0077,
                                                                    "singleQuantityMcf": 0.039886,
                                                                    "mcf": 0.039886,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.039886,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4880,
                                                                    "parentRowNumber": 4870,
                                                                    "articleId": "20120540",
                                                                    "description": "20120540-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0077,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0077,
                                                                    "singleQuantityMcf": 0.039886,
                                                                    "mcf": 0.039886,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.039886,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4881,
                                                                    "parentRowNumber": 4870,
                                                                    "articleId": "20120540",
                                                                    "description": "20120540-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0077,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0077,
                                                                    "singleQuantityMcf": 0.039886,
                                                                    "mcf": 0.039886,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.039886,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4882,
                                                                    "parentRowNumber": 4870,
                                                                    "articleId": "20120540",
                                                                    "description": "20120540-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0077,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0077,
                                                                    "singleQuantityMcf": 0.039886,
                                                                    "mcf": 0.039886,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.039886,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4883,
                                                                    "parentRowNumber": 4870,
                                                                    "articleId": "20120670",
                                                                    "description": "20120670-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0184,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0184,
                                                                    "singleQuantityMcf": 0.095312,
                                                                    "mcf": 0.095312,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.095312,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4884,
                                                                    "parentRowNumber": 4870,
                                                                    "articleId": "20220425",
                                                                    "description": "20220425-00-Zylinderschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0032,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0032,
                                                                    "singleQuantityMcf": 0.016576,
                                                                    "mcf": 0.016576,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.016576,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4885,
                                                                    "parentRowNumber": 4870,
                                                                    "articleId": "20220425",
                                                                    "description": "20220425-00-Zylinderschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0032,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0032,
                                                                    "singleQuantityMcf": 0.016576,
                                                                    "mcf": 0.016576,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.016576,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4886,
                                                                    "parentRowNumber": 4870,
                                                                    "articleId": "20220525",
                                                                    "description": "20220525-00-Zylinderschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0051,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0051,
                                                                    "singleQuantityMcf": 0.026418,
                                                                    "mcf": 0.026418,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.026418,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4887,
                                                                    "parentRowNumber": 4870,
                                                                    "articleId": "20420512",
                                                                    "description": "20420512-00-Senkschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0022,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0022,
                                                                    "singleQuantityMcf": 0.011396,
                                                                    "mcf": 0.011396,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.011396,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4888,
                                                                    "parentRowNumber": 4870,
                                                                    "articleId": "20420512",
                                                                    "description": "20420512-00-Senkschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0022,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0022,
                                                                    "singleQuantityMcf": 0.011396,
                                                                    "mcf": 0.011396,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.011396,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4889,
                                                                    "parentRowNumber": 4870,
                                                                    "articleId": "205205",
                                                                    "description": "205205-00-Sechskantmutter",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0013,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0013,
                                                                    "singleQuantityMcf": 0.006734,
                                                                    "mcf": 0.006734,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.006734,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4890,
                                                                    "parentRowNumber": 4870,
                                                                    "articleId": "205206",
                                                                    "description": "205206-00-Sechskantmutter",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0026,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0026,
                                                                    "singleQuantityMcf": 0.013468,
                                                                    "mcf": 0.013468,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.013468,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4891,
                                                                    "parentRowNumber": 4870,
                                                                    "articleId": "208204",
                                                                    "description": "208204-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0003,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0003,
                                                                    "singleQuantityMcf": 0.0015539999999999998,
                                                                    "mcf": 0.0015539999999999998,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.0015539999999999998,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4892,
                                                                    "parentRowNumber": 4870,
                                                                    "articleId": "208204",
                                                                    "description": "208204-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0003,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0003,
                                                                    "singleQuantityMcf": 0.0015539999999999998,
                                                                    "mcf": 0.0015539999999999998,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.0015539999999999998,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4893,
                                                                    "parentRowNumber": 4870,
                                                                    "articleId": "208205",
                                                                    "description": "208205-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0004,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0004,
                                                                    "singleQuantityMcf": 0.002072,
                                                                    "mcf": 0.002072,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.002072,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4894,
                                                                    "parentRowNumber": 4870,
                                                                    "articleId": "208205",
                                                                    "description": "208205-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0004,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0004,
                                                                    "singleQuantityMcf": 0.002072,
                                                                    "mcf": 0.002072,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.002072,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4895,
                                                                    "parentRowNumber": 4870,
                                                                    "articleId": "208405",
                                                                    "description": "208405-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0015,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0015,
                                                                    "singleQuantityMcf": 0.00777,
                                                                    "mcf": 0.00777,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.00777,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4896,
                                                                    "parentRowNumber": 4870,
                                                                    "articleId": "208405",
                                                                    "description": "208405-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0015,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0015,
                                                                    "singleQuantityMcf": 0.00777,
                                                                    "mcf": 0.00777,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.00777,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4897,
                                                                    "parentRowNumber": 4870,
                                                                    "articleId": "208405",
                                                                    "description": "208405-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0015,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0015,
                                                                    "singleQuantityMcf": 0.00777,
                                                                    "mcf": 0.00777,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.00777,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4898,
                                                                    "parentRowNumber": 4870,
                                                                    "articleId": "208405",
                                                                    "description": "208405-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0015,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0015,
                                                                    "singleQuantityMcf": 0.00777,
                                                                    "mcf": 0.00777,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.00777,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4899,
                                                                    "parentRowNumber": 4870,
                                                                    "articleId": "208405",
                                                                    "description": "208405-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0015,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0015,
                                                                    "singleQuantityMcf": 0.00777,
                                                                    "mcf": 0.00777,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.00777,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4900,
                                                                    "parentRowNumber": 4870,
                                                                    "articleId": "208405",
                                                                    "description": "208405-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0015,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0015,
                                                                    "singleQuantityMcf": 0.00777,
                                                                    "mcf": 0.00777,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.00777,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4901,
                                                                    "parentRowNumber": 4870,
                                                                    "articleId": "208405",
                                                                    "description": "208405-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0015,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0015,
                                                                    "singleQuantityMcf": 0.00777,
                                                                    "mcf": 0.00777,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.00777,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4902,
                                                                    "parentRowNumber": 4870,
                                                                    "articleId": "208405",
                                                                    "description": "208405-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0015,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0015,
                                                                    "singleQuantityMcf": 0.00777,
                                                                    "mcf": 0.00777,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.00777,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4903,
                                                                    "parentRowNumber": 4870,
                                                                    "articleId": "22070610",
                                                                    "description": "22070610-00-Fluegelschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0237,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0237,
                                                                    "singleQuantityMcf": 0.041001,
                                                                    "mcf": 0.041001,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.041001,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4904,
                                                                    "parentRowNumber": 4870,
                                                                    "articleId": "22070610",
                                                                    "description": "22070610-00-Fluegelschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0237,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0237,
                                                                    "singleQuantityMcf": 0.041001,
                                                                    "mcf": 0.041001,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.041001,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4905,
                                                                    "parentRowNumber": 4870,
                                                                    "articleId": "22070625",
                                                                    "description": "22070625-00-Fluegelschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0271,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0271,
                                                                    "singleQuantityMcf": 0.046883,
                                                                    "mcf": 0.046883,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.046883,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4906,
                                                                    "parentRowNumber": 4870,
                                                                    "articleId": "334195",
                                                                    "description": "334195-00-Druckfeder",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0023,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0023,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4907,
                                                                    "parentRowNumber": 4870,
                                                                    "articleId": "334195",
                                                                    "description": "334195-00-Druckfeder",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0023,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0023,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4908,
                                                                    "parentRowNumber": 4870,
                                                                    "articleId": "46591089",
                                                                    "description": "46591089-00-Sechskantmutter",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0003,
                                                                    "childrenWeight": 0.0003,
                                                                    "totalQuantityWeight": 0.0003,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 4909,
                                                                            "parentRowNumber": 4908,
                                                                            "articleId": "1615018",
                                                                            "description": "1615018-00-Rundstab",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0003,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0003,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 4910,
                                                                    "parentRowNumber": 4870,
                                                                    "articleId": "46645931",
                                                                    "description": "46645931-00-Formatbuegel 2.0",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.1508,
                                                                    "childrenWeight": 0.1508,
                                                                    "totalQuantityWeight": 0.1508,
                                                                    "singleQuantityMcf": 0.781144,
                                                                    "mcf": 0.781144,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.781144,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 4911,
                                                                            "parentRowNumber": 4910,
                                                                            "articleId": "142612",
                                                                            "description": "142612-00-Edelstahlblech",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.092,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.092,
                                                                            "singleQuantityMcf": 0.47656,
                                                                            "mcf": 0.47656,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.47656,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        },
                                                                        {
                                                                            "rowNumber": 4912,
                                                                            "parentRowNumber": 4910,
                                                                            "articleId": "46647477",
                                                                            "description": "46647477-00-Halter",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0294,
                                                                            "childrenWeight": 0.0294,
                                                                            "totalQuantityWeight": 0.0294,
                                                                            "singleQuantityMcf": 0.15229199999999998,
                                                                            "mcf": 0.15229199999999998,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.15229199999999998,
                                                                            "expandable": false,
                                                                            "children": [
                                                                                {
                                                                                    "rowNumber": 4913,
                                                                                    "parentRowNumber": 4912,
                                                                                    "articleId": "1110231",
                                                                                    "description": "1110231-00-Flachstahl",
                                                                                    "hierarchy": 9,
                                                                                    "quantity": 1,
                                                                                    "weight": 0.0294,
                                                                                    "childrenWeight": 0,
                                                                                    "totalQuantityWeight": 0.0294,
                                                                                    "singleQuantityMcf": 0.15229199999999998,
                                                                                    "mcf": 0.15229199999999998,
                                                                                    "singleQuantityLcf": 0,
                                                                                    "lcf": 0,
                                                                                    "totalQuantityMcf": 0.15229199999999998,
                                                                                    "expandable": false,
                                                                                    "children": []
                                                                                }
                                                                            ]
                                                                        },
                                                                        {
                                                                            "rowNumber": 4914,
                                                                            "parentRowNumber": 4910,
                                                                            "articleId": "46647477",
                                                                            "description": "46647477-00-Halter",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0294,
                                                                            "childrenWeight": 0.0294,
                                                                            "totalQuantityWeight": 0.0294,
                                                                            "singleQuantityMcf": 0.15229199999999998,
                                                                            "mcf": 0.15229199999999998,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.15229199999999998,
                                                                            "expandable": false,
                                                                            "children": [
                                                                                {
                                                                                    "rowNumber": 4915,
                                                                                    "parentRowNumber": 4914,
                                                                                    "articleId": "1110231",
                                                                                    "description": "1110231-00-Flachstahl",
                                                                                    "hierarchy": 9,
                                                                                    "quantity": 1,
                                                                                    "weight": 0.0294,
                                                                                    "childrenWeight": 0,
                                                                                    "totalQuantityWeight": 0.0294,
                                                                                    "singleQuantityMcf": 0.15229199999999998,
                                                                                    "mcf": 0.15229199999999998,
                                                                                    "singleQuantityLcf": 0,
                                                                                    "lcf": 0,
                                                                                    "totalQuantityMcf": 0.15229199999999998,
                                                                                    "expandable": false,
                                                                                    "children": []
                                                                                }
                                                                            ]
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 4916,
                                                                    "parentRowNumber": 4870,
                                                                    "articleId": "46645931",
                                                                    "description": "46645931-00-Formatbuegel 2.0",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.1508,
                                                                    "childrenWeight": 0.1508,
                                                                    "totalQuantityWeight": 0.1508,
                                                                    "singleQuantityMcf": 0.781144,
                                                                    "mcf": 0.781144,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.781144,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 4917,
                                                                            "parentRowNumber": 4916,
                                                                            "articleId": "142612",
                                                                            "description": "142612-00-Edelstahlblech",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.092,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.092,
                                                                            "singleQuantityMcf": 0.47656,
                                                                            "mcf": 0.47656,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.47656,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        },
                                                                        {
                                                                            "rowNumber": 4918,
                                                                            "parentRowNumber": 4916,
                                                                            "articleId": "46647477",
                                                                            "description": "46647477-00-Halter",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0294,
                                                                            "childrenWeight": 0.0294,
                                                                            "totalQuantityWeight": 0.0294,
                                                                            "singleQuantityMcf": 0.15229199999999998,
                                                                            "mcf": 0.15229199999999998,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.15229199999999998,
                                                                            "expandable": false,
                                                                            "children": [
                                                                                {
                                                                                    "rowNumber": 4919,
                                                                                    "parentRowNumber": 4918,
                                                                                    "articleId": "1110231",
                                                                                    "description": "1110231-00-Flachstahl",
                                                                                    "hierarchy": 9,
                                                                                    "quantity": 1,
                                                                                    "weight": 0.0294,
                                                                                    "childrenWeight": 0,
                                                                                    "totalQuantityWeight": 0.0294,
                                                                                    "singleQuantityMcf": 0.15229199999999998,
                                                                                    "mcf": 0.15229199999999998,
                                                                                    "singleQuantityLcf": 0,
                                                                                    "lcf": 0,
                                                                                    "totalQuantityMcf": 0.15229199999999998,
                                                                                    "expandable": false,
                                                                                    "children": []
                                                                                }
                                                                            ]
                                                                        },
                                                                        {
                                                                            "rowNumber": 4920,
                                                                            "parentRowNumber": 4916,
                                                                            "articleId": "46647477",
                                                                            "description": "46647477-00-Halter",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0294,
                                                                            "childrenWeight": 0.0294,
                                                                            "totalQuantityWeight": 0.0294,
                                                                            "singleQuantityMcf": 0.15229199999999998,
                                                                            "mcf": 0.15229199999999998,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.15229199999999998,
                                                                            "expandable": false,
                                                                            "children": [
                                                                                {
                                                                                    "rowNumber": 4921,
                                                                                    "parentRowNumber": 4920,
                                                                                    "articleId": "1110231",
                                                                                    "description": "1110231-00-Flachstahl",
                                                                                    "hierarchy": 9,
                                                                                    "quantity": 1,
                                                                                    "weight": 0.0294,
                                                                                    "childrenWeight": 0,
                                                                                    "totalQuantityWeight": 0.0294,
                                                                                    "singleQuantityMcf": 0.15229199999999998,
                                                                                    "mcf": 0.15229199999999998,
                                                                                    "singleQuantityLcf": 0,
                                                                                    "lcf": 0,
                                                                                    "totalQuantityMcf": 0.15229199999999998,
                                                                                    "expandable": false,
                                                                                    "children": []
                                                                                }
                                                                            ]
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 4922,
                                                                    "parentRowNumber": 4870,
                                                                    "articleId": "47122726",
                                                                    "description": "47122726-00-Fuehrung",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0284,
                                                                    "childrenWeight": 0.0284,
                                                                    "totalQuantityWeight": 0.0284,
                                                                    "singleQuantityMcf": 0.09088000000000002,
                                                                    "mcf": 0.09088000000000002,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.09088000000000002,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 4923,
                                                                            "parentRowNumber": 4922,
                                                                            "articleId": "162532",
                                                                            "description": "162532-01-Platte",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0284,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0284,
                                                                            "singleQuantityMcf": 0.09088000000000002,
                                                                            "mcf": 0.09088000000000002,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.09088000000000002,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 4924,
                                                                    "parentRowNumber": 4870,
                                                                    "articleId": "47122750",
                                                                    "description": "47122750-00-Fuehrung",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0242,
                                                                    "childrenWeight": 0.0242,
                                                                    "totalQuantityWeight": 0.0242,
                                                                    "singleQuantityMcf": 0.07744000000000001,
                                                                    "mcf": 0.07744000000000001,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.07744000000000001,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 4925,
                                                                            "parentRowNumber": 4924,
                                                                            "articleId": "162532",
                                                                            "description": "162532-01-Platte",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0242,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0242,
                                                                            "singleQuantityMcf": 0.07744000000000001,
                                                                            "mcf": 0.07744000000000001,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.07744000000000001,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 4926,
                                                                    "parentRowNumber": 4870,
                                                                    "articleId": "47130621",
                                                                    "description": "47130621-00-Distanz",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0112,
                                                                    "childrenWeight": 0.0112,
                                                                    "totalQuantityWeight": 0.0112,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 4927,
                                                                            "parentRowNumber": 4926,
                                                                            "articleId": "1232015",
                                                                            "description": "1232015-00-Rundstange",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0112,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0112,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 4928,
                                                                    "parentRowNumber": 4870,
                                                                    "articleId": "47130621",
                                                                    "description": "47130621-00-Distanz",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0112,
                                                                    "childrenWeight": 0.0112,
                                                                    "totalQuantityWeight": 0.0112,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 4929,
                                                                            "parentRowNumber": 4928,
                                                                            "articleId": "1232015",
                                                                            "description": "1232015-00-Rundstange",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0112,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0112,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 4930,
                                                                    "parentRowNumber": 4870,
                                                                    "articleId": "47161500",
                                                                    "description": "47161500-00-Klotz",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.1394,
                                                                    "childrenWeight": 0.1394,
                                                                    "totalQuantityWeight": 0.1394,
                                                                    "singleQuantityMcf": 0.42464,
                                                                    "mcf": 0.42464,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.42464,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 4931,
                                                                            "parentRowNumber": 4930,
                                                                            "articleId": "162547",
                                                                            "description": "162547-01-Platte",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.13269999999999998,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.13269999999999998,
                                                                            "singleQuantityMcf": 0.42464,
                                                                            "mcf": 0.42464,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.42464,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        },
                                                                        {
                                                                            "rowNumber": 4932,
                                                                            "parentRowNumber": 4930,
                                                                            "articleId": "347201",
                                                                            "description": "347201-00-Ensat-Gewindebuchse",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0018,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0018,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        },
                                                                        {
                                                                            "rowNumber": 4933,
                                                                            "parentRowNumber": 4930,
                                                                            "articleId": "347204",
                                                                            "description": "347204-00-Ensat-Gewindebuchse",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0049,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0049,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 4934,
                                                                    "parentRowNumber": 4870,
                                                                    "articleId": "47161509",
                                                                    "description": "47161509-00-Blech",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0333,
                                                                    "childrenWeight": 0.0333,
                                                                    "totalQuantityWeight": 0.0333,
                                                                    "singleQuantityMcf": 0.172494,
                                                                    "mcf": 0.172494,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.172494,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 4935,
                                                                            "parentRowNumber": 4934,
                                                                            "articleId": "142602",
                                                                            "description": "142602-00-Edelstahlblech",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0333,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0333,
                                                                            "singleQuantityMcf": 0.172494,
                                                                            "mcf": 0.172494,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.172494,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 4936,
                                                                    "parentRowNumber": 4870,
                                                                    "articleId": "47230819",
                                                                    "description": "47230819-00-Welle",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0216,
                                                                    "childrenWeight": 0.0216,
                                                                    "totalQuantityWeight": 0.0216,
                                                                    "singleQuantityMcf": 0.111888,
                                                                    "mcf": 0.111888,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.111888,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 4937,
                                                                            "parentRowNumber": 4936,
                                                                            "articleId": "1224008",
                                                                            "description": "1224008-00-Rundstahl",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0216,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0216,
                                                                            "singleQuantityMcf": 0.111888,
                                                                            "mcf": 0.111888,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.111888,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 4938,
                                                                    "parentRowNumber": 4870,
                                                                    "articleId": "47230819",
                                                                    "description": "47230819-00-Welle",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0216,
                                                                    "childrenWeight": 0.0216,
                                                                    "totalQuantityWeight": 0.0216,
                                                                    "singleQuantityMcf": 0.111888,
                                                                    "mcf": 0.111888,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.111888,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 4939,
                                                                            "parentRowNumber": 4938,
                                                                            "articleId": "1224008",
                                                                            "description": "1224008-00-Rundstahl",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0216,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0216,
                                                                            "singleQuantityMcf": 0.111888,
                                                                            "mcf": 0.111888,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.111888,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 4940,
                                                                    "parentRowNumber": 4870,
                                                                    "articleId": "47235923",
                                                                    "description": "47235923-00-Fuehrung",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.9746,
                                                                    "childrenWeight": 0.9746,
                                                                    "totalQuantityWeight": 0.9746,
                                                                    "singleQuantityMcf": 3.11872,
                                                                    "mcf": 3.11872,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 3.11872,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 4941,
                                                                            "parentRowNumber": 4940,
                                                                            "articleId": "162544",
                                                                            "description": "162544-01-Platte",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.9746,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.9746,
                                                                            "singleQuantityMcf": 3.11872,
                                                                            "mcf": 3.11872,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 3.11872,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 4942,
                                                                    "parentRowNumber": 4870,
                                                                    "articleId": "47235924",
                                                                    "description": "47235924-00-Fuehrung",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.6742,
                                                                    "childrenWeight": 0.6742,
                                                                    "totalQuantityWeight": 0.6742,
                                                                    "singleQuantityMcf": 2.12608,
                                                                    "mcf": 2.12608,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 2.12608,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 4943,
                                                                            "parentRowNumber": 4942,
                                                                            "articleId": "162544",
                                                                            "description": "162544-01-Platte",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.6644,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.6644,
                                                                            "singleQuantityMcf": 2.12608,
                                                                            "mcf": 2.12608,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 2.12608,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        },
                                                                        {
                                                                            "rowNumber": 4944,
                                                                            "parentRowNumber": 4942,
                                                                            "articleId": "347204",
                                                                            "description": "347204-00-Ensat-Gewindebuchse",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0049,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0049,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        },
                                                                        {
                                                                            "rowNumber": 4945,
                                                                            "parentRowNumber": 4942,
                                                                            "articleId": "347204",
                                                                            "description": "347204-00-Ensat-Gewindebuchse",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0049,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0049,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 4946,
                                                                    "parentRowNumber": 4870,
                                                                    "articleId": "47235925",
                                                                    "description": "47235925-00-Einlaufformat",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 1.5105,
                                                                    "childrenWeight": 1.5105,
                                                                    "totalQuantityWeight": 1.5105,
                                                                    "singleQuantityMcf": 4.7794,
                                                                    "mcf": 4.7794,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 4.7794,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 4947,
                                                                            "parentRowNumber": 4946,
                                                                            "articleId": "162544",
                                                                            "description": "162544-01-Platte",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 1.4791,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 1.4791,
                                                                            "singleQuantityMcf": 4.73312,
                                                                            "mcf": 4.73312,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 4.73312,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        },
                                                                        {
                                                                            "rowNumber": 4948,
                                                                            "parentRowNumber": 4946,
                                                                            "articleId": "20440410",
                                                                            "description": "20440410-00-Senkschraube",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0012,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0012,
                                                                            "singleQuantityMcf": 0.006215999999999999,
                                                                            "mcf": 0.006215999999999999,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.006215999999999999,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        },
                                                                        {
                                                                            "rowNumber": 4949,
                                                                            "parentRowNumber": 4946,
                                                                            "articleId": "20440410",
                                                                            "description": "20440410-00-Senkschraube",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0012,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0012,
                                                                            "singleQuantityMcf": 0.006215999999999999,
                                                                            "mcf": 0.006215999999999999,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.006215999999999999,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        },
                                                                        {
                                                                            "rowNumber": 4950,
                                                                            "parentRowNumber": 4946,
                                                                            "articleId": "21220308",
                                                                            "description": "21220308-00-Zylinderstift",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0005,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0005,
                                                                            "singleQuantityMcf": 0.00259,
                                                                            "mcf": 0.00259,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.00259,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        },
                                                                        {
                                                                            "rowNumber": 4951,
                                                                            "parentRowNumber": 4946,
                                                                            "articleId": "21220308",
                                                                            "description": "21220308-00-Zylinderstift",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0005,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0005,
                                                                            "singleQuantityMcf": 0.00259,
                                                                            "mcf": 0.00259,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.00259,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        },
                                                                        {
                                                                            "rowNumber": 4952,
                                                                            "parentRowNumber": 4946,
                                                                            "articleId": "272252",
                                                                            "description": "272252-00-Gleitlager",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0004,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0004,
                                                                            "singleQuantityMcf": 0.000692,
                                                                            "mcf": 0.000692,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.000692,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        },
                                                                        {
                                                                            "rowNumber": 4953,
                                                                            "parentRowNumber": 4946,
                                                                            "articleId": "272252",
                                                                            "description": "272252-00-Gleitlager",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0004,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0004,
                                                                            "singleQuantityMcf": 0.000692,
                                                                            "mcf": 0.000692,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.000692,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        },
                                                                        {
                                                                            "rowNumber": 4954,
                                                                            "parentRowNumber": 4946,
                                                                            "articleId": "272252",
                                                                            "description": "272252-00-Gleitlager",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0004,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0004,
                                                                            "singleQuantityMcf": 0.000692,
                                                                            "mcf": 0.000692,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.000692,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        },
                                                                        {
                                                                            "rowNumber": 4955,
                                                                            "parentRowNumber": 4946,
                                                                            "articleId": "272252",
                                                                            "description": "272252-00-Gleitlager",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0004,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0004,
                                                                            "singleQuantityMcf": 0.000692,
                                                                            "mcf": 0.000692,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.000692,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        },
                                                                        {
                                                                            "rowNumber": 4956,
                                                                            "parentRowNumber": 4946,
                                                                            "articleId": "2925695",
                                                                            "description": "2925695-00-Radial-Rillenkugellager",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0025,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0025,
                                                                            "singleQuantityMcf": 0.01295,
                                                                            "mcf": 0.01295,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.01295,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        },
                                                                        {
                                                                            "rowNumber": 4957,
                                                                            "parentRowNumber": 4946,
                                                                            "articleId": "2925695",
                                                                            "description": "2925695-00-Radial-Rillenkugellager",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0025,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0025,
                                                                            "singleQuantityMcf": 0.01295,
                                                                            "mcf": 0.01295,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.01295,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        },
                                                                        {
                                                                            "rowNumber": 4958,
                                                                            "parentRowNumber": 4946,
                                                                            "articleId": "347195",
                                                                            "description": "347195-00-Ensat-Gewindebuchse",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0014,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0014,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        },
                                                                        {
                                                                            "rowNumber": 4959,
                                                                            "parentRowNumber": 4946,
                                                                            "articleId": "347195",
                                                                            "description": "347195-00-Ensat-Gewindebuchse",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0014,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0014,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        },
                                                                        {
                                                                            "rowNumber": 4960,
                                                                            "parentRowNumber": 4946,
                                                                            "articleId": "347195",
                                                                            "description": "347195-00-Ensat-Gewindebuchse",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0014,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0014,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        },
                                                                        {
                                                                            "rowNumber": 4961,
                                                                            "parentRowNumber": 4946,
                                                                            "articleId": "347195",
                                                                            "description": "347195-00-Ensat-Gewindebuchse",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0014,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0014,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        },
                                                                        {
                                                                            "rowNumber": 4962,
                                                                            "parentRowNumber": 4946,
                                                                            "articleId": "347200",
                                                                            "description": "347200-00-Ensat-Gewindebuchse",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0025,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0025,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        },
                                                                        {
                                                                            "rowNumber": 4963,
                                                                            "parentRowNumber": 4946,
                                                                            "articleId": "347200",
                                                                            "description": "347200-00-Ensat-Gewindebuchse",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0025,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0025,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        },
                                                                        {
                                                                            "rowNumber": 4964,
                                                                            "parentRowNumber": 4946,
                                                                            "articleId": "347200",
                                                                            "description": "347200-00-Ensat-Gewindebuchse",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0025,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0025,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        },
                                                                        {
                                                                            "rowNumber": 4965,
                                                                            "parentRowNumber": 4946,
                                                                            "articleId": "347200",
                                                                            "description": "347200-00-Ensat-Gewindebuchse",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0025,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0025,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        },
                                                                        {
                                                                            "rowNumber": 4966,
                                                                            "parentRowNumber": 4946,
                                                                            "articleId": "347205",
                                                                            "description": "347205-00-Ensat-Gewindebuchse",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0058,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0058,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            "rowNumber": 4967,
                                                            "parentRowNumber": 4854,
                                                            "articleId": "ESA1025",
                                                            "description": "ESA1025-B-807,20-01-Kulisse",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.1276,
                                                            "childrenWeight": 0.1276,
                                                            "totalQuantityWeight": 0.1276,
                                                            "singleQuantityMcf": 0.660968,
                                                            "mcf": 0.660968,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.660968,
                                                            "expandable": false,
                                                            "children": [
                                                                {
                                                                    "rowNumber": 4968,
                                                                    "parentRowNumber": 4967,
                                                                    "articleId": "20220620",
                                                                    "description": "20220620-00-Zylinderschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0063,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0063,
                                                                    "singleQuantityMcf": 0.032634,
                                                                    "mcf": 0.032634,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.032634,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4969,
                                                                    "parentRowNumber": 4967,
                                                                    "articleId": "21820420",
                                                                    "description": "21820420-00-Passkerbstift",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4970,
                                                                    "parentRowNumber": 4967,
                                                                    "articleId": "453061",
                                                                    "description": "453061-05-Kulisse",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.1213,
                                                                    "childrenWeight": 0.1213,
                                                                    "totalQuantityWeight": 0.1213,
                                                                    "singleQuantityMcf": 0.628334,
                                                                    "mcf": 0.628334,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.628334,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 4971,
                                                                            "parentRowNumber": 4970,
                                                                            "articleId": "1260055",
                                                                            "description": "1260055-00-Rundstahl",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.1213,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.1213,
                                                                            "singleQuantityMcf": 0.628334,
                                                                            "mcf": 0.628334,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.628334,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            "rowNumber": 4972,
                                                            "parentRowNumber": 4854,
                                                            "articleId": "OBJEKT SERUM 33847 42.5X77",
                                                            "description": "OBJEKT SERUM 33847 42.5X77-00-Runde Flasche mit Boerdelverschluss",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.1349,
                                                            "childrenWeight": 0.1349,
                                                            "totalQuantityWeight": 0.1349,
                                                            "singleQuantityMcf": 0,
                                                            "mcf": 0,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0,
                                                            "expandable": false,
                                                            "children": [
                                                                {
                                                                    "rowNumber": 4973,
                                                                    "parentRowNumber": 4972,
                                                                    "articleId": "OBJ0000000001911",
                                                                    "description": "OBJ0000000001911-01-Flip-Off Kappe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0076,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0076,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4974,
                                                                    "parentRowNumber": 4972,
                                                                    "articleId": "OBJ0000000005763",
                                                                    "description": "OBJ0000000005763-00-Runde Flasche mit Boerdelverschluss",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.1175,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.1175,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4975,
                                                                    "parentRowNumber": 4972,
                                                                    "articleId": "OBJ0000000007945",
                                                                    "description": "OBJ0000000007945-00-Stopfen",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0098,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0098,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": []
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            "rowNumber": 4976,
                                                            "parentRowNumber": 4854,
                                                            "articleId": "OBJEKT SERUM 33847 42.5X77",
                                                            "description": "OBJEKT SERUM 33847 42.5X77-00-Runde Flasche mit Boerdelverschluss",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.1349,
                                                            "childrenWeight": 0.1349,
                                                            "totalQuantityWeight": 0.1349,
                                                            "singleQuantityMcf": 0,
                                                            "mcf": 0,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0,
                                                            "expandable": false,
                                                            "children": [
                                                                {
                                                                    "rowNumber": 4977,
                                                                    "parentRowNumber": 4976,
                                                                    "articleId": "OBJ0000000001911",
                                                                    "description": "OBJ0000000001911-01-Flip-Off Kappe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0076,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0076,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4978,
                                                                    "parentRowNumber": 4976,
                                                                    "articleId": "OBJ0000000005763",
                                                                    "description": "OBJ0000000005763-00-Runde Flasche mit Boerdelverschluss",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.1175,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.1175,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4979,
                                                                    "parentRowNumber": 4976,
                                                                    "articleId": "OBJ0000000007945",
                                                                    "description": "OBJ0000000007945-00-Stopfen",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0098,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0098,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": []
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            "rowNumber": 4980,
                                                            "parentRowNumber": 4854,
                                                            "articleId": "OBJEKT SERUM 33847 42.5X77",
                                                            "description": "OBJEKT SERUM 33847 42.5X77-00-Runde Flasche mit Boerdelverschluss",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.1349,
                                                            "childrenWeight": 0.1349,
                                                            "totalQuantityWeight": 0.1349,
                                                            "singleQuantityMcf": 0,
                                                            "mcf": 0,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0,
                                                            "expandable": false,
                                                            "children": [
                                                                {
                                                                    "rowNumber": 4981,
                                                                    "parentRowNumber": 4980,
                                                                    "articleId": "OBJ0000000001911",
                                                                    "description": "OBJ0000000001911-01-Flip-Off Kappe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0076,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0076,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4982,
                                                                    "parentRowNumber": 4980,
                                                                    "articleId": "OBJ0000000005763",
                                                                    "description": "OBJ0000000005763-00-Runde Flasche mit Boerdelverschluss",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.1175,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.1175,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4983,
                                                                    "parentRowNumber": 4980,
                                                                    "articleId": "OBJ0000000007945",
                                                                    "description": "OBJ0000000007945-00-Stopfen",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0098,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0098,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": []
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            "rowNumber": 4984,
                                                            "parentRowNumber": 4854,
                                                            "articleId": "OBJEKT SERUM 33847 42.5X77",
                                                            "description": "OBJEKT SERUM 33847 42.5X77-00-Runde Flasche mit Boerdelverschluss",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.1349,
                                                            "childrenWeight": 0.1349,
                                                            "totalQuantityWeight": 0.1349,
                                                            "singleQuantityMcf": 0,
                                                            "mcf": 0,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0,
                                                            "expandable": false,
                                                            "children": [
                                                                {
                                                                    "rowNumber": 4985,
                                                                    "parentRowNumber": 4984,
                                                                    "articleId": "OBJ0000000001911",
                                                                    "description": "OBJ0000000001911-01-Flip-Off Kappe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0076,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0076,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4986,
                                                                    "parentRowNumber": 4984,
                                                                    "articleId": "OBJ0000000005763",
                                                                    "description": "OBJ0000000005763-00-Runde Flasche mit Boerdelverschluss",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.1175,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.1175,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4987,
                                                                    "parentRowNumber": 4984,
                                                                    "articleId": "OBJ0000000007945",
                                                                    "description": "OBJ0000000007945-00-Stopfen",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0098,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0098,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": []
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            "rowNumber": 4988,
                                                            "parentRowNumber": 4854,
                                                            "articleId": "OBJEKT SERUM 33847 42.5X77",
                                                            "description": "OBJEKT SERUM 33847 42.5X77-00-Runde Flasche mit Boerdelverschluss",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 0.1349,
                                                            "childrenWeight": 0.1349,
                                                            "totalQuantityWeight": 0.1349,
                                                            "singleQuantityMcf": 0,
                                                            "mcf": 0,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0,
                                                            "expandable": false,
                                                            "children": [
                                                                {
                                                                    "rowNumber": 4989,
                                                                    "parentRowNumber": 4988,
                                                                    "articleId": "OBJ0000000001911",
                                                                    "description": "OBJ0000000001911-01-Flip-Off Kappe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0076,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0076,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4990,
                                                                    "parentRowNumber": 4988,
                                                                    "articleId": "OBJ0000000005763",
                                                                    "description": "OBJ0000000005763-00-Runde Flasche mit Boerdelverschluss",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.1175,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.1175,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 4991,
                                                                    "parentRowNumber": 4988,
                                                                    "articleId": "OBJ0000000007945",
                                                                    "description": "OBJ0000000007945-00-Stopfen",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0098,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0098,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": []
                                                                }
                                                            ]
                                                        }
                                                    ]
                                                }
                                            ]
                                        },
                                        {
                                            "rowNumber": 4992,
                                            "parentRowNumber": 4194,
                                            "articleId": "P",
                                            "description": "P-ESA1025-20-00-Einlauf",
                                            "hierarchy": 4,
                                            "quantity": 1,
                                            "weight": 0,
                                            "childrenWeight": 0,
                                            "totalQuantityWeight": 0,
                                            "singleQuantityMcf": 0,
                                            "mcf": 0,
                                            "singleQuantityLcf": 0,
                                            "lcf": 0,
                                            "totalQuantityMcf": 0,
                                            "expandable": false,
                                            "children": [
                                                {
                                                    "rowNumber": 4993,
                                                    "parentRowNumber": 4992,
                                                    "articleId": "P",
                                                    "description": "P-ESA1025-20-0001-00-Einlauf",
                                                    "hierarchy": 5,
                                                    "quantity": 1,
                                                    "weight": 0,
                                                    "childrenWeight": 0,
                                                    "totalQuantityWeight": 0,
                                                    "singleQuantityMcf": 0,
                                                    "mcf": 0,
                                                    "singleQuantityLcf": 0,
                                                    "lcf": 0,
                                                    "totalQuantityMcf": 0,
                                                    "expandable": false,
                                                    "children": []
                                                }
                                            ]
                                        }
                                    ]
                                },
                                {
                                    "rowNumber": 4994,
                                    "parentRowNumber": 2020,
                                    "articleId": "P",
                                    "description": "P-ESA1025-20-00-Einlauf",
                                    "hierarchy": 3,
                                    "quantity": 1,
                                    "weight": 0,
                                    "childrenWeight": 0,
                                    "totalQuantityWeight": 0,
                                    "singleQuantityMcf": 0,
                                    "mcf": 0,
                                    "singleQuantityLcf": 0,
                                    "lcf": 0,
                                    "totalQuantityMcf": 0,
                                    "expandable": false,
                                    "children": [
                                        {
                                            "rowNumber": 4995,
                                            "parentRowNumber": 4994,
                                            "articleId": "P",
                                            "description": "P-ESA1025-20-0001-00-Einlauf",
                                            "hierarchy": 4,
                                            "quantity": 1,
                                            "weight": 0,
                                            "childrenWeight": 0,
                                            "totalQuantityWeight": 0,
                                            "singleQuantityMcf": 0,
                                            "mcf": 0,
                                            "singleQuantityLcf": 0,
                                            "lcf": 0,
                                            "totalQuantityMcf": 0,
                                            "expandable": false,
                                            "children": []
                                        }
                                    ]
                                }
                            ]
                        },
                        {
                            "rowNumber": 4996,
                            "parentRowNumber": 0,
                            "articleId": "33847",
                            "description": "33847-22-00-Durchtransport",
                            "hierarchy": 2,
                            "quantity": 1,
                            "weight": 8.411900000000003,
                            "childrenWeight": 8.411900000000003,
                            "totalQuantityWeight": 8.411900000000003,
                            "singleQuantityMcf": 2.8049430000000006,
                            "mcf": 2.8049430000000006,
                            "singleQuantityLcf": 0,
                            "lcf": 0,
                            "totalQuantityMcf": 2.8049430000000006,
                            "expandable": false,
                            "children": [
                                {
                                    "rowNumber": 4997,
                                    "parentRowNumber": 4996,
                                    "articleId": "33847",
                                    "description": "33847-22BM-00-Antrieb M",
                                    "hierarchy": 3,
                                    "quantity": 1,
                                    "weight": 8.411900000000003,
                                    "childrenWeight": 8.411900000000003,
                                    "totalQuantityWeight": 8.411900000000003,
                                    "singleQuantityMcf": 2.8049430000000006,
                                    "mcf": 2.8049430000000006,
                                    "singleQuantityLcf": 0,
                                    "lcf": 0,
                                    "totalQuantityMcf": 2.8049430000000006,
                                    "expandable": false,
                                    "children": [
                                        {
                                            "rowNumber": 4998,
                                            "parentRowNumber": 4997,
                                            "articleId": "33847",
                                            "description": "33847-22BM10-02-Grundeinheit",
                                            "hierarchy": 4,
                                            "quantity": 1,
                                            "weight": 8.411900000000003,
                                            "childrenWeight": 8.411900000000003,
                                            "totalQuantityWeight": 8.411900000000003,
                                            "singleQuantityMcf": 2.8049430000000006,
                                            "mcf": 2.8049430000000006,
                                            "singleQuantityLcf": 0,
                                            "lcf": 0,
                                            "totalQuantityMcf": 2.8049430000000006,
                                            "expandable": false,
                                            "children": [
                                                {
                                                    "rowNumber": 4999,
                                                    "parentRowNumber": 4998,
                                                    "articleId": "MM0000005669",
                                                    "description": "MM0000005669-02-Antriebseinheit",
                                                    "hierarchy": 5,
                                                    "quantity": 1,
                                                    "weight": 8.411900000000003,
                                                    "childrenWeight": 8.411900000000003,
                                                    "totalQuantityWeight": 8.411900000000003,
                                                    "singleQuantityMcf": 2.8049430000000006,
                                                    "mcf": 2.8049430000000006,
                                                    "singleQuantityLcf": 0,
                                                    "lcf": 0,
                                                    "totalQuantityMcf": 2.8049430000000006,
                                                    "expandable": false,
                                                    "children": [
                                                        {
                                                            "rowNumber": 5000,
                                                            "parentRowNumber": 4999,
                                                            "articleId": "47103695",
                                                            "description": "47103695-02-Antrieb",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 2.609200000000001,
                                                            "childrenWeight": 2.609200000000001,
                                                            "totalQuantityWeight": 2.609200000000001,
                                                            "singleQuantityMcf": 2.728279000000001,
                                                            "mcf": 2.728279000000001,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 2.728279000000001,
                                                            "expandable": false,
                                                            "children": [
                                                                {
                                                                    "rowNumber": 5001,
                                                                    "parentRowNumber": 5000,
                                                                    "articleId": "20120625",
                                                                    "description": "20120625-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0083,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0083,
                                                                    "singleQuantityMcf": 0.042994,
                                                                    "mcf": 0.042994,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.042994,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 5002,
                                                                    "parentRowNumber": 5000,
                                                                    "articleId": "20120625",
                                                                    "description": "20120625-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0083,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0083,
                                                                    "singleQuantityMcf": 0.042994,
                                                                    "mcf": 0.042994,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.042994,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 5003,
                                                                    "parentRowNumber": 5000,
                                                                    "articleId": "20120625",
                                                                    "description": "20120625-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0083,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0083,
                                                                    "singleQuantityMcf": 0.042994,
                                                                    "mcf": 0.042994,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.042994,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 5004,
                                                                    "parentRowNumber": 5000,
                                                                    "articleId": "20120625",
                                                                    "description": "20120625-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0083,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0083,
                                                                    "singleQuantityMcf": 0.042994,
                                                                    "mcf": 0.042994,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.042994,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 5005,
                                                                    "parentRowNumber": 5000,
                                                                    "articleId": "20120855",
                                                                    "description": "20120855-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0282,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0282,
                                                                    "singleQuantityMcf": 0.14607599999999998,
                                                                    "mcf": 0.14607599999999998,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.14607599999999998,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 5006,
                                                                    "parentRowNumber": 5000,
                                                                    "articleId": "20120855",
                                                                    "description": "20120855-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0282,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0282,
                                                                    "singleQuantityMcf": 0.14607599999999998,
                                                                    "mcf": 0.14607599999999998,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.14607599999999998,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 5007,
                                                                    "parentRowNumber": 5000,
                                                                    "articleId": "20120855",
                                                                    "description": "20120855-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0282,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0282,
                                                                    "singleQuantityMcf": 0.14607599999999998,
                                                                    "mcf": 0.14607599999999998,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.14607599999999998,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 5008,
                                                                    "parentRowNumber": 5000,
                                                                    "articleId": "20120855",
                                                                    "description": "20120855-00-Sechskantschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0282,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0282,
                                                                    "singleQuantityMcf": 0.14607599999999998,
                                                                    "mcf": 0.14607599999999998,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.14607599999999998,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 5009,
                                                                    "parentRowNumber": 5000,
                                                                    "articleId": "208206",
                                                                    "description": "208206-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0009,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0009,
                                                                    "singleQuantityMcf": 0.004662,
                                                                    "mcf": 0.004662,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.004662,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 5010,
                                                                    "parentRowNumber": 5000,
                                                                    "articleId": "208206",
                                                                    "description": "208206-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0009,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0009,
                                                                    "singleQuantityMcf": 0.004662,
                                                                    "mcf": 0.004662,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.004662,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 5011,
                                                                    "parentRowNumber": 5000,
                                                                    "articleId": "208206",
                                                                    "description": "208206-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0009,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0009,
                                                                    "singleQuantityMcf": 0.004662,
                                                                    "mcf": 0.004662,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.004662,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 5012,
                                                                    "parentRowNumber": 5000,
                                                                    "articleId": "208206",
                                                                    "description": "208206-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0009,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0009,
                                                                    "singleQuantityMcf": 0.004662,
                                                                    "mcf": 0.004662,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.004662,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 5013,
                                                                    "parentRowNumber": 5000,
                                                                    "articleId": "208208",
                                                                    "description": "208208-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0017,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0017,
                                                                    "singleQuantityMcf": 0.008806,
                                                                    "mcf": 0.008806,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.008806,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 5014,
                                                                    "parentRowNumber": 5000,
                                                                    "articleId": "208208",
                                                                    "description": "208208-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0017,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0017,
                                                                    "singleQuantityMcf": 0.008806,
                                                                    "mcf": 0.008806,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.008806,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 5015,
                                                                    "parentRowNumber": 5000,
                                                                    "articleId": "208208",
                                                                    "description": "208208-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0017,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0017,
                                                                    "singleQuantityMcf": 0.008806,
                                                                    "mcf": 0.008806,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.008806,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 5016,
                                                                    "parentRowNumber": 5000,
                                                                    "articleId": "208208",
                                                                    "description": "208208-00-Scheibe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0017,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0017,
                                                                    "singleQuantityMcf": 0.008806,
                                                                    "mcf": 0.008806,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.008806,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 5017,
                                                                    "parentRowNumber": 5000,
                                                                    "articleId": "264422",
                                                                    "description": "264422-01-Spannsatz",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0176,
                                                                    "childrenWeight": 0.0176,
                                                                    "totalQuantityWeight": 0.0176,
                                                                    "singleQuantityMcf": 0.091168,
                                                                    "mcf": 0.091168,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.091168,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 5018,
                                                                            "parentRowNumber": 5017,
                                                                            "articleId": "20220520",
                                                                            "description": "20220520-00-Zylinderschraube",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0044,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0044,
                                                                            "singleQuantityMcf": 0.022792,
                                                                            "mcf": 0.022792,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.022792,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        },
                                                                        {
                                                                            "rowNumber": 5019,
                                                                            "parentRowNumber": 5017,
                                                                            "articleId": "20220520",
                                                                            "description": "20220520-00-Zylinderschraube",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0044,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0044,
                                                                            "singleQuantityMcf": 0.022792,
                                                                            "mcf": 0.022792,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.022792,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        },
                                                                        {
                                                                            "rowNumber": 5020,
                                                                            "parentRowNumber": 5017,
                                                                            "articleId": "20220520",
                                                                            "description": "20220520-00-Zylinderschraube",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0044,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0044,
                                                                            "singleQuantityMcf": 0.022792,
                                                                            "mcf": 0.022792,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.022792,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        },
                                                                        {
                                                                            "rowNumber": 5021,
                                                                            "parentRowNumber": 5017,
                                                                            "articleId": "20220520",
                                                                            "description": "20220520-00-Zylinderschraube",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0044,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0044,
                                                                            "singleQuantityMcf": 0.022792,
                                                                            "mcf": 0.022792,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.022792,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 5022,
                                                                    "parentRowNumber": 5000,
                                                                    "articleId": "46091330",
                                                                    "description": "46091330-00-Distanzbolzen",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0934,
                                                                    "childrenWeight": 0.0934,
                                                                    "totalQuantityWeight": 0.0934,
                                                                    "singleQuantityMcf": 0.09935975,
                                                                    "mcf": 0.09935975,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.09935975,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 5023,
                                                                            "parentRowNumber": 5022,
                                                                            "articleId": "132217",
                                                                            "description": "132217-00-Sechskantstahl",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0917,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0917,
                                                                            "singleQuantityMcf": 0.09055375,
                                                                            "mcf": 0.09055375,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.09055375,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        },
                                                                        {
                                                                            "rowNumber": 5024,
                                                                            "parentRowNumber": 5022,
                                                                            "articleId": "20120845",
                                                                            "description": "20120845-00-Sechskantschraube",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        },
                                                                        {
                                                                            "rowNumber": 5025,
                                                                            "parentRowNumber": 5022,
                                                                            "articleId": "208208",
                                                                            "description": "208208-00-Scheibe",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0017,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0017,
                                                                            "singleQuantityMcf": 0.008806,
                                                                            "mcf": 0.008806,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.008806,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 5026,
                                                                    "parentRowNumber": 5000,
                                                                    "articleId": "46091330",
                                                                    "description": "46091330-00-Distanzbolzen",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0934,
                                                                    "childrenWeight": 0.0934,
                                                                    "totalQuantityWeight": 0.0934,
                                                                    "singleQuantityMcf": 0.09935975,
                                                                    "mcf": 0.09935975,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.09935975,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 5027,
                                                                            "parentRowNumber": 5026,
                                                                            "articleId": "132217",
                                                                            "description": "132217-00-Sechskantstahl",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0917,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0917,
                                                                            "singleQuantityMcf": 0.09055375,
                                                                            "mcf": 0.09055375,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.09055375,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        },
                                                                        {
                                                                            "rowNumber": 5028,
                                                                            "parentRowNumber": 5026,
                                                                            "articleId": "20120845",
                                                                            "description": "20120845-00-Sechskantschraube",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        },
                                                                        {
                                                                            "rowNumber": 5029,
                                                                            "parentRowNumber": 5026,
                                                                            "articleId": "208208",
                                                                            "description": "208208-00-Scheibe",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0017,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0017,
                                                                            "singleQuantityMcf": 0.008806,
                                                                            "mcf": 0.008806,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.008806,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 5030,
                                                                    "parentRowNumber": 5000,
                                                                    "articleId": "46091330",
                                                                    "description": "46091330-00-Distanzbolzen",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0934,
                                                                    "childrenWeight": 0.0934,
                                                                    "totalQuantityWeight": 0.0934,
                                                                    "singleQuantityMcf": 0.09935975,
                                                                    "mcf": 0.09935975,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.09935975,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 5031,
                                                                            "parentRowNumber": 5030,
                                                                            "articleId": "132217",
                                                                            "description": "132217-00-Sechskantstahl",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0917,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0917,
                                                                            "singleQuantityMcf": 0.09055375,
                                                                            "mcf": 0.09055375,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.09055375,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        },
                                                                        {
                                                                            "rowNumber": 5032,
                                                                            "parentRowNumber": 5030,
                                                                            "articleId": "20120845",
                                                                            "description": "20120845-00-Sechskantschraube",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        },
                                                                        {
                                                                            "rowNumber": 5033,
                                                                            "parentRowNumber": 5030,
                                                                            "articleId": "208208",
                                                                            "description": "208208-00-Scheibe",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0017,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0017,
                                                                            "singleQuantityMcf": 0.008806,
                                                                            "mcf": 0.008806,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.008806,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 5034,
                                                                    "parentRowNumber": 5000,
                                                                    "articleId": "46091330",
                                                                    "description": "46091330-00-Distanzbolzen",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0934,
                                                                    "childrenWeight": 0.0934,
                                                                    "totalQuantityWeight": 0.0934,
                                                                    "singleQuantityMcf": 0.09935975,
                                                                    "mcf": 0.09935975,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.09935975,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 5035,
                                                                            "parentRowNumber": 5034,
                                                                            "articleId": "132217",
                                                                            "description": "132217-00-Sechskantstahl",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0917,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0917,
                                                                            "singleQuantityMcf": 0.09055375,
                                                                            "mcf": 0.09055375,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.09055375,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        },
                                                                        {
                                                                            "rowNumber": 5036,
                                                                            "parentRowNumber": 5034,
                                                                            "articleId": "20120845",
                                                                            "description": "20120845-00-Sechskantschraube",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        },
                                                                        {
                                                                            "rowNumber": 5037,
                                                                            "parentRowNumber": 5034,
                                                                            "articleId": "208208",
                                                                            "description": "208208-00-Scheibe",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0017,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0017,
                                                                            "singleQuantityMcf": 0.008806,
                                                                            "mcf": 0.008806,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0.008806,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 5038,
                                                                    "parentRowNumber": 5000,
                                                                    "articleId": "46498749",
                                                                    "description": "46498749-01-Stirnrad",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.835,
                                                                    "childrenWeight": 0.835,
                                                                    "totalQuantityWeight": 0.835,
                                                                    "singleQuantityMcf": 1.42952,
                                                                    "mcf": 1.42952,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 1.42952,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 5039,
                                                                            "parentRowNumber": 5038,
                                                                            "articleId": "1214100",
                                                                            "description": "1214100-00-Rundstahl",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.835,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.835,
                                                                            "singleQuantityMcf": 1.42952,
                                                                            "mcf": 1.42952,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 1.42952,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 5040,
                                                                    "parentRowNumber": 5000,
                                                                    "articleId": "47209132",
                                                                    "description": "47209132-00-Platte",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 1.2266,
                                                                    "childrenWeight": 1.2266,
                                                                    "totalQuantityWeight": 1.2266,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 5041,
                                                                            "parentRowNumber": 5040,
                                                                            "articleId": "141725",
                                                                            "description": "141725-00-Platte",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 1.2266,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 1.2266,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            "rowNumber": 5042,
                                                            "parentRowNumber": 4999,
                                                            "articleId": "47103702",
                                                            "description": "47103702-02-Antrieb",
                                                            "hierarchy": 6,
                                                            "quantity": 1,
                                                            "weight": 5.8027000000000015,
                                                            "childrenWeight": 5.8027000000000015,
                                                            "totalQuantityWeight": 5.8027000000000015,
                                                            "singleQuantityMcf": 0.076664,
                                                            "mcf": 0.076664,
                                                            "singleQuantityLcf": 0,
                                                            "lcf": 0,
                                                            "totalQuantityMcf": 0.076664,
                                                            "expandable": false,
                                                            "children": [
                                                                {
                                                                    "rowNumber": 5043,
                                                                    "parentRowNumber": 5042,
                                                                    "articleId": "20220515",
                                                                    "description": "20220515-00-Zylinderschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0037,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0037,
                                                                    "singleQuantityMcf": 0.019166,
                                                                    "mcf": 0.019166,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.019166,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 5044,
                                                                    "parentRowNumber": 5042,
                                                                    "articleId": "20220515",
                                                                    "description": "20220515-00-Zylinderschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0037,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0037,
                                                                    "singleQuantityMcf": 0.019166,
                                                                    "mcf": 0.019166,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.019166,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 5045,
                                                                    "parentRowNumber": 5042,
                                                                    "articleId": "20220515",
                                                                    "description": "20220515-00-Zylinderschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0037,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0037,
                                                                    "singleQuantityMcf": 0.019166,
                                                                    "mcf": 0.019166,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.019166,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 5046,
                                                                    "parentRowNumber": 5042,
                                                                    "articleId": "20220515",
                                                                    "description": "20220515-00-Zylinderschraube",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0037,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 0.0037,
                                                                    "singleQuantityMcf": 0.019166,
                                                                    "mcf": 0.019166,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0.019166,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 5047,
                                                                    "parentRowNumber": 5042,
                                                                    "articleId": "34202502503",
                                                                    "description": "34202502503-00-Planetengetriebe",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 5.5464,
                                                                    "childrenWeight": 0,
                                                                    "totalQuantityWeight": 5.5464,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": []
                                                                },
                                                                {
                                                                    "rowNumber": 5048,
                                                                    "parentRowNumber": 5042,
                                                                    "articleId": "51158325",
                                                                    "description": "51158325-00-Servomotor",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.17,
                                                                    "childrenWeight": 0.17,
                                                                    "totalQuantityWeight": 0.17,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 5049,
                                                                            "parentRowNumber": 5048,
                                                                            "articleId": "PUDY00003261",
                                                                            "description": "PUDY00003261-00-Servomotor_Stecker",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.17,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.17,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "rowNumber": 5050,
                                                                    "parentRowNumber": 5042,
                                                                    "articleId": "56489933",
                                                                    "description": "56489933-00-Motorleitung 480V",
                                                                    "hierarchy": 7,
                                                                    "quantity": 1,
                                                                    "weight": 0.0715,
                                                                    "childrenWeight": 0.0715,
                                                                    "totalQuantityWeight": 0.0715,
                                                                    "singleQuantityMcf": 0,
                                                                    "mcf": 0,
                                                                    "singleQuantityLcf": 0,
                                                                    "lcf": 0,
                                                                    "totalQuantityMcf": 0,
                                                                    "expandable": false,
                                                                    "children": [
                                                                        {
                                                                            "rowNumber": 5051,
                                                                            "parentRowNumber": 5050,
                                                                            "articleId": "PUDY00000070",
                                                                            "description": "PUDY00000070-00-Kabel D11",
                                                                            "hierarchy": 8,
                                                                            "quantity": 1,
                                                                            "weight": 0.0715,
                                                                            "childrenWeight": 0,
                                                                            "totalQuantityWeight": 0.0715,
                                                                            "singleQuantityMcf": 0,
                                                                            "mcf": 0,
                                                                            "singleQuantityLcf": 0,
                                                                            "lcf": 0,
                                                                            "totalQuantityMcf": 0,
                                                                            "expandable": false,
                                                                            "children": []
                                                                        }
                                                                    ]
                                                                }
                                                            ]
                                                        }
                                                    ]
                                                }
                                            ]
                                        },
                                        {
                                            "rowNumber": 5052,
                                            "parentRowNumber": 4997,
                                            "articleId": "P",
                                            "description": "P-ESA1025-33847-22-00-Durchtransport",
                                            "hierarchy": 4,
                                            "quantity": 1,
                                            "weight": 0,
                                            "childrenWeight": 0,
                                            "totalQuantityWeight": 0,
                                            "singleQuantityMcf": 0,
                                            "mcf": 0,
                                            "singleQuantityLcf": 0,
                                            "lcf": 0,
                                            "totalQuantityMcf": 0,
                                            "expandable": false,
                                            "children": [
                                                {
                                                    "rowNumber": 5053,
                                                    "parentRowNumber": 5052,
                                                    "articleId": "P",
                                                    "description": "P-ESA1025-33847-22-0001-00-Durchtransport",
                                                    "hierarchy": 5,
                                                    "quantity": 1,
                                                    "weight": 0,
                                                    "childrenWeight": 0,
                                                    "totalQuantityWeight": 0,
                                                    "singleQuantityMcf": 0,
                                                    "mcf": 0,
                                                    "singleQuantityLcf": 0,
                                                    "lcf": 0,
                                                    "totalQuantityMcf": 0,
                                                    "expandable": false,
                                                    "children": []
                                                }
                                            ]
                                        }
                                    ]
                                }
                            ]
                        }
                    ]
                }
            ]
    },
    fetchScopeDetails: {  //DO NOT USE!!! KEPT FOR REFERENCE
        scope_1_summarized: 3.1,
        scope_2_summarized: 1.2006000000000001,
        scope_3_summarized: 21.30044169995507,
        scope_3_details: {
            scope_3_1: {
                material: [ /* change , API 1 */
                    {
                        x_key: 'Lithium', co2: 9.4, gewicht: 1.06
                    },
                    {
                        x_key: 'Edelstahl', co2: 7.5, gewicht: 3.6
                    },
                    {
                        x_key: 'PE', co2: 3.8, gewicht: 1.5
                    },
                    {
                        x_key: 'Sonstiges', co2: 4.6, gewicht: 0.8
                    }
                ],
                warrengruppen: [{/* change , API 2 */
                    x_key: '27-05 Akkumulator, Batterie', co2: 10.6, gewicht: 19
                },
                {
                    x_key: '35-06 Eisenmetalle', co2: 4.5, gewicht: 7
                },
                {
                    x_key: 'Halbzeug(Kunststoff)', co2: 1.4, gewicht: 4
                },
                {
                    x_key: 'Sonstiges', co2: 6, gewicht: 2
                }]

            },
            scope_3_3: {
                scope_3_3_summarized: 3.5,
                kategories:
                {
                    pcf_upstream_emissions_of_purchased_fuels: 1.0,
                    pcf_upstream_emissions_of_purchased_electricity: 1.0,
                    pcf_transmission_and_distribution_losses: 1.2,
                    pcf_generation_of_purchased_electricity_sold_to_end_users: 0.3
                }

            },
            scope_3_4: {
                upstream_transportation_cities: [{ x_key: "Landsberg", co2: 0.1 }, { x_key: "Nürnberg", co2: 0.2 }, { x_key: "Stuttgart", co2: 0.5 }, { x_key: "Berlin", co2: 1 }, { x_key: "Amsterdam", co2: 2 }],
                upstream_transportation_type: [{ x_key: "LKW", co2: 3 }, { x_key: "Schiff", co2: 1 }, { x_key: "Flugzeug", co2: 1 }, { x_key: "Zug", co2: 0.5 }, { x_key: "LKW/Flugzeug", co2: 0.1 }],
                upstream_transportation_countries: [{ x_key: "Deutschland", co2: 1.8 }, { x_key: "Niederlande", co2: 2 }],
                upstream_transportation_ie: [{ x_key: "Extern", co2: 3.1 }, { x_key: "Intern", co2: 0.7 }],
                upstream_transportation_suppliers: [{ x_key: "Lieferant 1", co2: 0.1 }, { x_key: "Lieferant 2", co2: 0.2 }, { x_key: "Lieferant 3", co2: 0.5 }, { x_key: "Lieferant 4", co2: 1 }, { x_key: "Lieferant 5", co2: 2 }],
            },
            scope_3_5: {
                waste_summarized: 1.9,
                kategories: [
                    { waste_name: "Metall", waste_value: 0.26 },
                    { waste_name: "Elektro", waste_value: 0.86 },
                    { waste_name: "Plastik", waste_value: 0.74 },

                    { waste_name: "xxx", waste_value: 0.1 },

                    { waste_name: "yyy", waste_value: 0.1 },

                ]
            }
        },

    },
    topNPCFByComponentData: {
        result: [{
            "rank": 0,
            "component": "PCB",
            "mcf": 9.50032,
            "lcf": 0
        },
        {
            "rank": 1,
            "component": "Li-Ion-3.6V-2950mAh",
            "mcf": 8.477952,
            "lcf": 0
        },
        {
            "rank": 2,
            "component": "Cell-holder",
            "mcf": 0.5208,
            "lcf": 0
        }]
    },

    scope_3_1_material: [ /* change , API 1 */
        {
            x_key: 'Lithium', co2: 9.4, gewicht: 1.06
        },
        {
            x_key: 'Edelstahl', co2: 7.5, gewicht: 3.6
        },
        {
            x_key: 'PE', co2: 3.8, gewicht: 1.5
        },
        {
            x_key: 'Sonstiges', co2: 4.6, gewicht: 0.8
        }
    ],

    scope_3_1_warrengruppen: [{/* change , API 2 */
        x_key: '27-05 Akkumulator, Batterie', co2: 10.6, gewicht: 19
    },
    {
        x_key: '35-06 Eisenmetalle', co2: 4.5, gewicht: 7
    },
    {
        x_key: 'Halbzeug(Kunststoff)', co2: 1.4, gewicht: 4
    },
    {
        x_key: 'Sonstiges', co2: 6, gewicht: 2
    }],
    scope_3_3: {
        scope_3_3_summarized: 3.5,
        kategories:
        {
            pcf_upstream_emissions_of_purchased_fuels: 1.0,
            pcf_upstream_emissions_of_purchased_electricity: 1.0,
            pcf_transmission_and_distribution_losses: 1.2,
            pcf_generation_of_purchased_electricity_sold_to_end_users: 0.3
        }

    },
    /* scope 3_4 *************************** */
    upstream_transportation_cities: [{ x_key: "Landsberg", co2: 0.1 }, { x_key: "Nürnberg", co2: 0.2 }, { x_key: "Stuttgart", co2: 0.323232 }, { x_key: "Berlin", co2: 1 }, { x_key: "Amsterdam", co2: 2 }],

    upstream_transportation_type: [{ x_key: "LKW", co2: 3 }, { x_key: "Schiff", co2: 1 }, { x_key: "Flugzeug", co2: 1 }, { x_key: "Zug", co2: 0.5 }, { x_key: "LKW/Flugzeug", co2: 0.1 }],

    upstream_transportation_countries: [{ x_key: "Deutschland", co2: 1.8 }, { x_key: "Niederlande", co2: 2 }],

    upstream_transportation_ie: [{ x_key: "Extern", co2: 3.1 }, { x_key: "Intern", co2: 0.7 }],

    upstream_transportation_suppliers: [{ x_key: "Lieferant 1", co2: 0.2 }, { x_key: "Lieferant 2", co2: 0.2 }, { x_key: "Lieferant 3", co2: 0.5 }, { x_key: "Lieferant 4", co2: 1 }, { x_key: "Lieferant 5", co2: 2 }],


    scope_3_5: {
        waste_summarized: 1.9, //from pcf overview, but DW will update
        kategories: [
            { waste_name: "Metall", waste_value: 0.26 },
            { waste_name: "Elektro", waste_value: 0.86 },
            { waste_name: "Plastik", waste_value: 0.74 },

            { waste_name: "xxx", waste_value: 0.1 },

            { waste_name: "yyy", waste_value: 0.1 },

        ]
    },
    data_quality: {     /* Datenlage  table 2*/
        pcf_data_quality_initially_usable: 48,
        pcf_data_quality_final_usable: 98,
        pcf_data_quality_incomple_entries: 2,
    },
    supplier_data: [
        {
            city: 'Nürnberg',
            suppliername: ['FESTO', 'Rapido'],
            total_lcf: 0.97,
            mcf_descriptions: [{ components_per_supplier: 'STATOR 4-POLIG', co2: 0.23 },
            { components_per_supplier: 'ROTOR', co2: 0.25 },
            { components_per_supplier: 'ZAHNRADPUMPE Z 5,2 D', co2: 0.68 },
            { components_per_supplier: 'STATOR 4-PO343LIG', co2: 0.23 },
            { components_per_supplier: 'STATOR 4-PO34LIG', co2: 0.234 }, { components_per_supplier: 'STATO3453R 4-POLIG', co2: 0.23 },
            { components_per_supplier: 'STATOR 34534-POLIG', co2: 0.23 }
            ],
            mcf_descriptions_1: {
                components_per_supplier: ['STATOR 4-POLIG', 'ROTOR', 'ZAHNRADPUMPE Z 5,2 D', 'S2TATOR 4-POLIG', 'ROT2OR', 'ZAHN222RADPUMPE Z 5,2 D'],
                co2: [0.23, 0.25, 0.68, 0.23, 0.25, 0.68]
            },
            latitude: 49.452103,
            longitude: 11.076665
        },
        {
            city: 'Landsberg',
            suppliername: ['FESTO'],
            total_lcf: 0.97,
            mcf_descriptions: [{ components_per_supplier: 'STATOR 4-POLIG', co2: 0.23 },
            { components_per_supplier: 'ROTOR', co2: 0.25 },
            { components_per_supplier: 'ZAHNRADPUMPE Z 5,2 D', co2: 0.68 }
            ],
            mcf_descriptions_1: {
                components_per_supplier: ['STATOR 4-POLIG', 'ROTOR', 'ZAHNRADPUMPE Z 5,2 D'],
                co2: [0.23, 0.25, 0.68]
            },
            latitude: 48.047878,
            longitude: 10.882490
        },
        {
            city: 'Stuttgart',
            suppliername: ['FESTO'],
            total_lcf: 0.97,
            mcf_descriptions: [{ components_per_supplier: 'STATOR 4-POLIG', co2: 0.23 },
            { components_per_supplier: 'ROTOR', co2: 0.25 },
            { components_per_supplier: 'ZAHNRADPUMPE Z 5,2 D', co2: 0.68 }
            ],
            mcf_descriptions_1: {
                components_per_supplier: ['STATOR 4-POLIG', 'ROTOR', 'ZAHNRADPUMPE Z 5,2 D'],
                co2: [0.23, 0.25, 0.68]
            },
            latitude: 48.775845,
            longitude: 9.182932
        },
        {
            city: 'Berlin',
            suppliername: ['FESTO'],
            total_lcf: 0.97,
            mcf_descriptions: [{ components_per_supplier: 'STATOR 4-POLIG', co2: 0.23 },
            { components_per_supplier: 'ROTOR', co2: 0.25 },
            { components_per_supplier: 'ZAHNRADPUMPE Z 5,2 D', co2: 0.68 }
            ],
            mcf_descriptions_1: {
                components_per_supplier: ['STATOR 4-POLIG', 'ROTOR', 'ZAHNRADPUMPE Z 5,2 D'],
                co2: [0.23, 0.25, 0.68]
            },
            latitude: 52.520008,
            longitude: 13.404954
        },
        {
            city: 'Amsterdam',
            suppliername: ['FESTO'],
            total_lcf: 0.97,
            mcf_descriptions: [{ components_per_supplier: 'STATOR 4-POLIG', co2: 0.23 },
            { components_per_supplier: 'ROTOR', co2: 0.25 },
            { components_per_supplier: 'ZAHNRADPUMPE Z 5,2 D', co2: 0.68 }
            ],
            mcf_descriptions_1: {
                components_per_supplier: ['STATOR 4-POLIG', 'ROTOR', 'ZAHNRADPUMPE Z 5,2 D'],
                co2: [0.23, 0.25, 0.68]
            },
            latitude: 52.3730796,
            longitude: 4.8924534
        },
    ],
    data_quality_final_usable_table: {
        list_of_materials: [
            {
                article_Id: '4714 4114-00', description: 'STATOR 4-POLIG', modus: 'BESTEHT AUS',
                assumption: [{ key: 'Stahlblech', value: 90.00 }, { key: 'Elektrische Komponente', value: 10.00 }]
            },

            { article_Id: '4714 4367-00', description: 'ROTOR', 'modus': 'BESTEHT AUS', assumption: [{ key: 'Stahlblech', value: 90.00 }, { key: 'Elektrische Komponente', value: 10.00 }] },
            { article_Id: '4714 4114-00', description: 'STATOR 4-POLIG', 'modus': 'BESTEHT AUS', assumption: [{ key: 'Stahlblech', value: 90.00 }, { key: 'Elektrische Komponente', value: 10.00 }] },
            { article_Id: '4714 4114-00', description: 'STATOR 4-POLIG', 'modus': 'BESTEHT AUS', assumption: [{ key: 'Stahlblech', value: 90.00 }, { key: 'Elektrische Komponente', value: 10.00 }] },
            { article_Id: '4714 4114-00', description: 'STATOR 4-POLIG', 'modus': 'BESTEHT AUS', assumption: [{ key: 'Stahlblech', value: 90.00 }, { key: 'Elektrische Komponente', value: 10.00 }] },
            { article_Id: '4714 4114-00', description: 'STATOR 4-POLIG', 'modus': 'BESTEHT AUS', assumption: [{ key: 'Stahlblech', value: 90.00 }, { key: 'Elektrische Komponente', value: 10.00 }] }
        ],
        max_material_count: 4
    },
    data_quality_incomple_entries_table: [{ article_Id: '6075 0202-00', description: 'NADELROLLE', material_01: '' },
    { article_Id: '6075 0202-00', description: 'NADELROLLE', material_01: '' }

    ],


}
export default dummy_states;

