import React, { useState, startTransition } from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Toolbar from '@mui/material/Toolbar';
import CssBaseline from '@mui/material/CssBaseline';
import content from '../static_content';
import '../styles/side-bar.css';
import { useNavigate } from 'react-router-dom';
import { Tooltip } from '@mui/material';


const drawerWidth = 260;


/* export default function TemporaryDrawer() {

    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const [selectedIndex, setSelectedIndex] = useState(0);
    const handleItemClick = (index, path) => {
        startTransition(() => {
            setLoading(false);
            setSelectedIndex(index);
            navigate(path);
        });
    }
    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <Drawer
                variant="permanent"
                sx={{
                    width: drawerWidth,
                    flexShrink: 0,
                    '& .MuiDrawer-paper': {
                        width: drawerWidth,
                        boxSizing: 'border-box',
                        overflowX: 'hidden',
                        transition: 'width 0.3s',
                    },
                }}
            >
            
                <Toolbar
                    className="logo"
                    sx={{
                        padding: '0px 25px',
                    }}>
                    <img
                        src="/telusio_blue.png"
                        alt="Logo"
                        style={{

                            width: '144px',
                            height: '37.432px',
                        }}
                    />
                </Toolbar>

                <List className="sidebar">
                    {content.global_sidebar.map((item, index) => (
                        <ListItem
                            key={index}
                            disablePadding
                            sx={{
                                justifyContent: 'flex-start',
                                alignItems: 'center',
                                paddingY: '6px',
                                height: '44px',
                                fontWeight: selectedIndex === index ? 600 : 400,
                            }}
                        >
                            <ListItemButton
                                sx={{
                                    justifyContent: 'flex-start',
                                    alignItems: 'center',
                                    padding: '0px 25px',
                                    backgroundColor: selectedIndex === index ? '#f0f0f0' : 'transparent',
                                    height: '44px',
                                    fontWeight: selectedIndex === index ? 600 : 400,

                                }}
                                onClick={() => handleItemClick(index, item.path)}
                            >{loading && <div>Loading...</div>}
                                <ListItemIcon
                                    sx={{
                                        color: '#001D5B',
                                        width: '20px',
                                        height: '20px',
                                        minWidth: '0',
                                        marginRight: '16px',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center'
                                    }}
                                    className='icon-wrapper'
                                >
                                    {item.icon}
                                </ListItemIcon>
                                <ListItemText
                                    primary={item.key}
                                    sx={{
                                        fontSize: '14px',

                                        display: 'flex',
                                        alignItems: 'center',
                                        fontWeight: selectedIndex === index ? 600 : 400,
                                    }}
                                />
                            </ListItemButton>
                        </ListItem>
                    ))}
                </List>
            </Drawer>
        </Box>
    ); 
    
}*/

export default function TemporaryDrawer() {

    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const [selectedIndex, setSelectedIndex] = useState(0);

    const handleItemClick = (index, path, isAvailable) => {
        if (isAvailable) {
            startTransition(() => {
                setLoading(false);
                setSelectedIndex(index);
                navigate(path);
            });
        }
    };

    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <Drawer
                variant="permanent"
                sx={{
                    width: drawerWidth,
                    flexShrink: 0,
                    '& .MuiDrawer-paper': {
                        width: drawerWidth,
                        boxSizing: 'border-box',
                        overflowX: 'hidden',
                        transition: 'width 0.3s',
                    },
                }}
            >
                {/* Logo Section */}
                <Toolbar
                    className="logo"
                    sx={{
                        padding: '0px 25px',
                    }}>
                    <img
                        src="/telusio_blue.png"
                        alt="Logo"
                        style={{
                            width: '144px',
                            height: '37.432px',
                        }}
                    />
                </Toolbar>

                <List className="sidebar">
                    {content.global_sidebar.map((item, index) => (
                        <Tooltip
                            key={index}
                            title={!item.available ? content.future_scope_tooltip : ""}
                            arrow
                            disableHoverListener={item.available} // Disable tooltip if available
                        >
                            <ListItem
                                disablePadding
                                sx={{
                                    justifyContent: 'flex-start',
                                    alignItems: 'center',
                                    paddingY: '6px',
                                    height: '44px',
                                    fontWeight: selectedIndex === index ? 600 : 400,
                                    cursor: item.available ? 'pointer' : 'not-allowed' // Show disabled cursor if not available
                                }}
                            >
                                <ListItemButton
                                    sx={{
                                        justifyContent: 'flex-start',
                                        alignItems: 'center',
                                        padding: '0px 25px',
                                        backgroundColor: selectedIndex === index ? '#f0f0f0' : 'transparent',
                                        height: '44px',
                                        fontWeight: selectedIndex === index ? 600 : 400,
                                    }}
                                    onClick={() => handleItemClick(index, item.path, item.available)}
                                >
                                    {loading && <div>Loading...</div>}
                                    <ListItemIcon
                                        sx={{
                                            color: '#001D5B',
                                            width: '20px',
                                            height: '20px',
                                            minWidth: '0',
                                            marginRight: '16px',
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center'
                                        }}
                                        className='icon-wrapper'
                                    >
                                        {item.icon}
                                    </ListItemIcon>
                                    <ListItemText
                                        primary={item.key}
                                        sx={{
                                            fontSize: '14px',
                                            display: 'flex',
                                            alignItems: 'center',
                                            fontWeight: selectedIndex === index ? 600 : 400,
                                        }}
                                    />
                                </ListItemButton>
                            </ListItem>
                        </Tooltip>
                    ))}
                </List>
            </Drawer>
        </Box>
    );
}


