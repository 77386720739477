import React, { useState, useEffect } from 'react';
import { styled, alpha } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import InputBase from '@mui/material/InputBase';
import Badge from '@mui/material/Badge';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import MenuOpenOutlinedIcon from '@mui/icons-material/MenuOpenOutlined';
import SearchIcon from '@mui/icons-material/Search';
import AccountCircle from '@mui/icons-material/AccountCircle';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import MoreIcon from '@mui/icons-material/MoreVert';
import Typography from '@mui/material/Typography';
import '../styles/navbar.css';
import LanguageOutlinedIcon from '@mui/icons-material/LanguageOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
/* import Autocomplete from '@mui/material/Autocomplete';
import TextField from "@mui/material/TextField";

const categories = []; */
const drawerWidth = 260;
const role = 'Admin';
const Search = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
        backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(1),
        width: 'auto',
    },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: '#001D5B',
    zIndex: '1'
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    '& .MuiInputBase-input': {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        transition: theme.transitions.create('width'),
        width: '100%',
        border: '1px solid #F0F0F0',
        borderRadius: '4px',
        background: 'var(--Neutral-1, #FFF)',
        color: '#001D5B',
        justifyContent: 'flex-start',
        alignItems: 'center', gap: '10px', display: 'inline-flex',
        [theme.breakpoints.up('md')]: {
            width: '34ch',
        },
        [theme.breakpoints.up('lg')]: {
            width: '367px',
        },
    }


}));

export default function PrimarySearchAppBar() {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);

    const isMenuOpen = Boolean(anchorEl);
    const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

    const handleLanguageMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMobileMenuClose = () => {
        setMobileMoreAnchorEl(null);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
        handleMobileMenuClose();
    };

    const handleMobileMenuOpen = (event) => {
        setMobileMoreAnchorEl(event.currentTarget);
    };

    const menuId = 'language-menu';
    const renderlanguages = (
        <Menu
            anchorEl={anchorEl}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
            id={menuId}
            keepMounted
            transformOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
            open={isMenuOpen}
            onClose={handleMenuClose}
        >
            <MenuItem onClick={handleMenuClose}>German</MenuItem>
            <MenuItem onClick={handleMenuClose}>English</MenuItem>
        </Menu>
    );

    const mobileMenuId = 'primary-search-account-menu-mobile';
    const renderMobileMenu = (
        <Menu
            anchorEl={mobileMoreAnchorEl}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            id={mobileMenuId}
            keepMounted
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            open={isMobileMenuOpen}
            onClose={handleMobileMenuClose}

        >
            <MenuItem sx={{
                display: 'flex',
                padding: '12px',
                justifyContent: 'flex-end',
                alignItems: 'flex-start',
                borderRadius: '4px',
                backgroundColor: '#F5F5F5'
            }}>
                <IconButton size="large" aria-label="show 4 new mails" color="secondary">
                    <Badge sx={{ color: '#001D5B' }}>
                        <EmailOutlinedIcon />
                    </Badge>
                </IconButton>
                <p>Feedback</p>
            </MenuItem>
            <MenuItem>
                <IconButton
                    size="large"
                    aria-label="show languages"

                >
                    <Badge sx={{ color: '#001D5B' }}>
                        <LanguageOutlinedIcon />
                    </Badge>
                </IconButton>
                <p>Languages</p>
            </MenuItem>
            <MenuItem>
                <IconButton
                    size="large"
                    aria-label="show settings"

                >
                    <Badge sx={{ color: '#001D5B' }}>
                        <SettingsOutlinedIcon />
                    </Badge>
                </IconButton>
                <p>Settings</p>
            </MenuItem>
            <MenuItem>
                <IconButton
                    size="large"
                    aria-label="account of current user"
                    aria-controls="primary-search-account-menu"
                    aria-haspopup="true"
                    color='#001D5B'
                >
                    <AccountCircle />
                </IconButton>
                <p>Profile</p>
            </MenuItem>
        </Menu >
    );
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        // Clean up the event listener on component unmount
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
    return (
        <Box sx={{
            flexGrow: 1
        }} >
            <AppBar position="fixed" sx={{
                width: `calc(${window.innerWidth}px - ${drawerWidth}px)`, ml: `${drawerWidth}px`, border: '1px solid #F0F0F0',
                background: '#FFF',
                boxShadow: '0px 0px 0px 1px rgba(24, 144, 255, 0.20)', zIndex: '1201'
            }}>
                <Toolbar>
                    <IconButton
                        size="medium"
                        edge="start"
                        sx={{ color: '#001D5B', borderRadius: '4px' }}
                        aria-label="expand or collapse"
                    >
                        <MenuOpenOutlinedIcon />
                    </IconButton>

                    {/* 
                    <Autocomplete
                        freeSolo
                        sx={{
                            color: '#001D5B'
                        }}
                        id="search-page"
                        disableClearable
                        options={categories.map((option) => option.title)}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Search input"
                                InputProps={{
                                    ...params.InputProps,
                                    type: 'search',
                                }}
                            />
                        )}>
                        /></Autocomplete>

 */}
                    {/* Imolement autocomplete for search */}
                    <Search sx={{
                        color: '#001D5B'
                    }}>
                        <SearchIconWrapper>
                            <SearchIcon />
                        </SearchIconWrapper>
                        <StyledInputBase
                            placeholder="Search…"
                            inputProps={{ 'aria-label': 'search' }}
                        />
                    </Search>
                    <Box sx={{ flexGrow: 1 }} />
                    <Box sx={{
                        display: {
                            xs: 'none', md: 'flex', lg: 'flex', gap: '10px'
                        }
                    }}>
                        <IconButton size="large" aria-label="show feedback" sx={{ borderRadius: '4px', backgroundColor: '#F5F5F5' }}>
                            <Badge sx={{ color: '#001D5B' }}>
                                <EmailOutlinedIcon />
                            </Badge>
                        </IconButton>
                        <IconButton size="large" aria-label="settings" sx={{ borderRadius: '4px', backgroundColor: '#F5F5F5' }}>
                            <Badge sx={{ color: '#001D5B' }}>
                                <SettingsOutlinedIcon />
                            </Badge>
                        </IconButton>
                        <IconButton
                            size="large"
                            sx={{ borderRadius: '4px', backgroundColor: '#F5F5F5' }}
                            aria-controls={menuId}
                            aria-haspopup="true"
                            onClick={handleLanguageMenuOpen}
                        >
                            <Badge sx={{ color: '#001D5B' }}>
                                <LanguageOutlinedIcon />
                            </Badge>
                        </IconButton>
                        <IconButton
                            size="large"
                            edge="end"
                            aria-label="account of current user"
                            sx={{ borderRadius: '4px', backgroundColor: '#F5F5F5' }}
                        >
                            <Badge sx={{ color: '#001D5B', gap: '10px' }}>
                                <AccountCircle />
                                <Typography component="div" sx={{ flexGrow: 1 }}>
                                    {role}
                                </Typography>
                            </Badge>

                        </IconButton>
                        <Badge sx={{ paddingLeft: '10px' }}>
                            <img
                                src="/client_logo.png"
                                alt="Client Logo"
                                style={{
                                    padding: '0 12px',
                                    width: '85.83px',
                                    borderRadius: '4px',

                                    objectFit: 'contain',

                                }}
                            />
                        </Badge>
                    </Box>
                    <Box sx={{ display: { xs: 'flex', md: 'none', lg: 'none' } }}>
                        <IconButton
                            size="large"
                            aria-label="show more"
                            aria-controls={mobileMenuId}
                            aria-haspopup="true"
                            onClick={handleMobileMenuOpen}
                            color='#001D5B'
                        >
                            <MoreIcon />
                        </IconButton>
                    </Box>
                </Toolbar>
            </AppBar>
            {renderMobileMenu}
            {renderlanguages}
        </Box >
    );
}
