import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import AuthApi from "../../api/auth.api";

export const login = createAsyncThunk(
    'auth/login',
    async ({ email, password }) => {
        return await AuthApi.login(email, password);
    }
);

export const logout = createAsyncThunk(
    'auth/logout',
    () => {
        window.localStorage.removeItem('token');
    }
);

export const AuthSlice = createSlice({
    name: 'auth',
    initialState: {
        email: '',
        password: '',
        isLoading: false,
        isLoggedIn: false,
        error: {
            status: false,
            message: ''
        }
    },
    extraReducers: builder => {
        builder
            .addCase(login.pending, (state, action) => {
                state.isLoading = true;
            })
            .addCase(login.rejected, (state, action) => {
                // action.payload => rejected value if resolved with a `rejectWithValue(value)`
                // if not handled with rejectWithValue, action.error
                // TODO(test the behavior)
                state.isLoading = false;
            })
            .addCase(login.fulfilled, (state, action) => {
                window.localStorage.setItem('token', action.payload.token);
                state.isLoading = false;
                state.isLoggedIn = true;
            })
    }
});

// export const { setEmail, setPassword } = AuthSlice.actions;
export default AuthSlice.reducer;
export const selectEmail = state => state.auth.email;
export const selectPassword = state => state.auth.password;
export const selectIsLoading = state => state.auth.isLoading;
export const selectIsLoggedIn = state => state.auth.isLoggedIn;