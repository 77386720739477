import React from 'react';

import '../styles/main-body.css';

import { Outlet } from 'react-router-dom';

const MainContent = ({ children }) => {

    return (

        <Outlet />

    );
}

export default MainContent;