// src/components/Layout.js

import React from 'react';
import MainContent from './maincontent';
import "../styles/global-layout.css"
import PrimarySearchAppBar from './navbar';
import TemporaryDrawer from './sidebar';

const Layout = () => {
  return (
    <div className="layout">

      <TemporaryDrawer />
      <div className="main-content">
        <PrimarySearchAppBar />

        <div className="content">
          <MainContent />
        </div>
      </div>
    </div>
  );
};

export default Layout;


/* 
export default function AutoGrid() {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={3}>
        <Grid item xs>
          <Item>xs</Item>
        </Grid>
        <Grid item xs={6}>
          <Item>xs=6</Item>
        </Grid>
        <Grid item xs>
          <Item>xs</Item>
        </Grid>
      </Grid>
    </Box>
  );
}
    */