/**
 * Extracts the values from the .env file and provides them as importable JS object
 */

const exp = {
    env: process.env.REACT_APP_ENV,
    backendBaseUrl: process.env.REACT_APP_BACKEND_BASE_URL,
    analyticsModuleSleepDurationMs: {
        initial: 2000,
        factor: 2,
        max: 8000
    },
    temporaryBearerToken: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiJkYmIyNDZmZS0yODk1LTExZWYtOTllYi04YWE4NWUxMDY3MmMiLCJlbWFpbCI6ImRld2FuZ2dhLndpbmFzZm9yY2VwdGEra2JAdGVsdXNpby5jb20iLCJyb2xlIjoiQURNSU4iLCJvcmdhbml6YXRpb25JZCI6ImNkNDE4NGVhLTI4OTUtMTFlZi05OWViLThhYTg1ZTEwNjcyYyIsIm9yZ2FuaXphdGlvbiI6eyJuYW1lIjoiSEFXRSJ9LCJpYXQiOjE3MTg3MTUxOTF9.9E8hmaCuT57lNeZZvdKwl4XjcbyXrHmiVvYZ9FmQaqI'
}

export default exp
