import { red } from '@mui/material/colors';
import { createTheme } from '@mui/material/styles';

// A custom theme for this app
const theme = createTheme({
    typography: {
        fontFamily: 'Barlow, sans-serif', // Override the default Roboto with Barlow
    },
    palette: {
        primary: {
            main: '#001D5B',
        },
        secondary: {
            main: '#19857b',
        },
        error: {
            main: red.A400,
        },
       
    },
    Tabs: {
        cardBg: 'rgba(0, 0, 80, 0.9)',
        itemColor: 'rgba(255, 255, 255, 1)',
        itemActiveColor: 'rgba(0, 0, 100, 0.8)',
        itemSelectedColor: 'rgba(0, 0, 100, 0.8)',
        itemHoverColor: 'rgba(255, 255, 255, 0.5)'
    },
    Tree: {
        titleHeight: 35,
        nodeHoverBg: 'rgba(0, 0, 100, 0.2)',
        nodeSelectedBg: 'rgba(0, 0, 100, 0.4)'
    },
    Button: {
        defaultBg: 'rgba(0, 0, 100, 1)',
        defaultColor: 'rgba(255, 255, 255, 1)',
        defaultHoverBorderColor: 'rgba(0, 0, 100, 1)',
        defaultHoverColor: 'rgba(0, 0, 100, 1)'
    }
});

export default theme;
