import React from 'react';
import { Outlet, Navigate } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';

export const isTokenExpired = (token) => {
    if (!token) {
        return true;
    }

    try {
        const decodedToken = jwtDecode(token);
        const currentTime = Date.now() / 1000; // Current time in seconds

        return decodedToken.exp < currentTime;
    } catch (error) {
        return true;
    }
};

const ProtectedRoute = () => {
    const token = window.localStorage.getItem('token');
    const isAuthenticated = token && !isTokenExpired(token);

    if (!isAuthenticated) {
        window.localStorage.removeItem('token');
        return <Navigate to='/login' />;
    }

    return <Outlet />;
};


export default ProtectedRoute;