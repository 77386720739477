import './App.css';

import { BrowserRouter, Routes, Route } from 'react-router-dom'
import { ConfigProvider } from './api/config-context';
import React, { lazy } from 'react';
import { Navigate } from 'react-router-dom';
import ProtectedRouteComponent from './components/ProtectedRoute';
import Layout from './main_layout/layout';
import '@fontsource/barlow'; // Defaults to weight 400

const LoginPage = lazy(() => import('./pages/login.page'));
const ProductsListPage = lazy(() => import('./pages/product/products_list'));
const ProductOverviewPage = lazy(() => import('./pages/product/product_overview'));
const LogoutComponent = lazy(() => import('./components/Logout'));

function App() {
    return (
        <React.StrictMode>
            <ConfigProvider>
                <BrowserRouter>

                    <Routes>
                        <Route path='/login' element={<LoginPage />} />

                        <Route path='/' element={<Layout />} >
                            <Route element={<ProtectedRouteComponent />} >
                                <Route index element={<Navigate to="/home" replace />} />
                                <Route path='/home' element={<ProductsListPage />} />
                                <Route path="product/:title" element={<ProductOverviewPage />} />
                                <Route path='/logout' element={<LogoutComponent />} />
                            </Route>
                        </Route>

                        <Route path="*" element={<Navigate to="/home" replace />} />

                    </Routes>
                </BrowserRouter>
            </ConfigProvider >
        </React.StrictMode>
    );
}

export default App;