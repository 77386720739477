import config from '../config';

const login = async (email, password) => {
    const resp=  await fetch(`${config.backendBaseUrl}/user/login`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({ email, password })
    });

    if (!resp.ok) {
        const errorData = await resp.json()
        throw new Error(errorData.message || 'unknown error :(')
    }

    return resp.json();
};

export default {
    login
}