
import config from '../config';
import { CONFIG_MODES } from './config-context';



const apiConfig = (endpoint, options = {}, type = '', dummyData = [], configMode) => {
  switch (type) {
    case 'Product':
      const { method = 'GET', headers = {}, body } = options;

      // Define common headers
      const defaultHeaders = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${window.localStorage.getItem('token')}`,
      };

      // Merge default headers with any custom headers
      const mergedHeaders = {
        ...defaultHeaders,
        ...headers,
      };

      const fetchOptions = {
        method,
        headers: mergedHeaders,
        body: body ? JSON.stringify(body) : undefined,
      };
      return configureResponse(endpoint, fetchOptions, dummyData, configMode);

    case 'Analytics':
   

      break;
    default:
      console.log('Type is not specified!!!')
      break;
  }
};

const configureResponse = async (endpoint, fetchOptions, dummyData, configMode) => {
  switch (configMode) {
    case CONFIG_MODES.DUMMY_ONLY:
      // Only return dummy data, no API call
      return dummyData;

    case CONFIG_MODES.API_ONLY:
      // Only call the API, no dummy data fallback
      return await fetchDataFromAPI(endpoint, fetchOptions);

    case CONFIG_MODES.MIXED:
      // Call the API and merge the response with dummy data if necessary
      return await fetchAndMergeData(endpoint, fetchOptions, dummyData);

    default:
      throw new Error('Invalid config mode');
  }

};

const fetchDataFromAPI = async (endpoint, fetchOptions) => {
  const response = await fetch(config.backendBaseUrl + endpoint, fetchOptions);

  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData.message || 'Unknown error :(');
  }

  return response.json();
};


const mergeDeep = (dummyData, apiData) => {
  // Check if both dummyData and apiData are objects or arrays
  if (typeof dummyData === 'object' && dummyData !== null && !Array.isArray(dummyData)) {
    const merged = { ...dummyData };

    for (const key in apiData) {
      if (apiData.hasOwnProperty(key)) {
        if (apiData[key] != null) { // Check if API data is not null or undefined
          merged[key] = mergeDeep(dummyData[key], apiData[key]); // Recursively merge
        }
      }
    }

    return merged;
  }

  // Handle case where dummyData and apiData are arrays
  if (Array.isArray(dummyData) && Array.isArray(apiData)) {
    const limitedArray = apiData.map((item, index) => mergeDeep(dummyData[index], item));
    return limitedArray;
  }

  return apiData != null ? apiData : dummyData;
};


const fetchAndMergeData = async (endpoint, fetchOptions, dummyData) => {
  try {
    const apiData = await fetchDataFromAPI(endpoint, fetchOptions);
    // Logic to merge API data with dummy data
    if (apiData) {
      const mergedData = mergeDeep(dummyData, apiData);
      return mergedData;
    }
    else
      return dummyData;

  } catch (error) {
    // If the API fails, return dummy data
    return dummyData;
  }
};

export default apiConfig;


